export class SubsystemSelectionItem {
    id: number;
    name: string;
    impersonationAllowed?: boolean;
    selected: boolean;

    static fromJSON(jsonObject: any): SubsystemSelectionItem {
        const subsystem = new SubsystemSelectionItem();
        subsystem.id = jsonObject.id;
        subsystem.name = jsonObject.name;
        subsystem.impersonationAllowed = jsonObject.impersonationAllowed;
        subsystem.selected = false;
        return subsystem;
    }
}
