import {PlatformLocation} from "@angular/common";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {finalize, map, mergeMap, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {ColorInterface, getColorFormattedNameWithGroup} from '../../../../window-designer/catalog-data/color-interface';
import {AddonAvailableIn} from "../../../../window-designer/enums/addon-available-in.enum";
import {AddonCategoryEnum, AddonCategoryTarget} from "../../../../window-designer/enums/AddonCategoryEnum";
import {AddonFor} from "../../../../window-designer/enums/AddonFor";
import {QuantityType} from '../../../../window-designer/enums/QuantityType';
import {ApplicationPrivilege} from '../../../auth/application-privilege';
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper, FileState} from "../../../common/dataServiceHelper";
import {MotlawaIntegrationInfo} from '../../../common/MotlawaIntegrationInfo';
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {SelectItemImpl} from '../../../common/service/select.item.impl';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {Currencies} from "../../../currencies";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {AddonField, CatalogTab} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ListOfIds} from '../../ListOfIds';
import {SubsystemService} from "../../subsystem/subsystem.service";
import {SupplierService} from "../../supplier/supplier.service";
import {AddonFieldUsage} from "../catalog-field-usage";
import {AddonHelperInterface} from "../catalog-field-usage-helper-interfaces";
import {Color} from "../color/color";
import {ColorService} from "../color/color.service";
import {GateSystemService} from "../gate-system/gate-system.service";
import {LinkableEntities} from "../link-selection/link-selection.component";
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {ProductTypeGroup} from "../window-system-definition/product-type-group";
import {Addon} from "./addon";
import {AddonsValidatorService} from "./addons-validator.service";
import {AddonsService} from "./addons.service";
import {PriceType} from "./PriceType";
import {ConfigSystemService} from "../config-system/config-system.service";

@Component({
    selector: 'app-addons',
    templateUrl: './addons.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [AddonsService, DataServiceHelper, SupplierService, WindowSystemDefinitionService, ColorService,
        TranslatedSelectItemService, GateSystemService, ConfigSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, AddonHelperInterface {

    @Input()
    addonCategoryTarget: AddonCategoryTarget;

    allowedCategories: AddonCategoryEnum[];

    filterCategory: Observable<SelectItem[]>;
    filterAddonFor: Observable<SelectItem[]>;

    userLang;
    langTranslateSubscription: Subscription;
    availableInsideColors: SelectItem[];
    availableOutsideColors: SelectItem[];
    availableCoreColors: SelectItem[];

    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayDialog: boolean;

    addons: Addon[];
    newAddon: boolean;
    addon: Addon;
    addonFor = AddonFor;
    file: File;
    tile: FileState;
    motlawaInfo: MotlawaIntegrationInfo;
    selectedAddon;
    availableCategories: Observable<SelectItem[]>;
    availableAddonFor: Observable<SelectItem[]>;
    availableQuantityTypes: Observable<SelectItem[]>;
    availableSuppliers: SelectItem[] = [];
    selectedWindowSystems: number[];
    windowSystems: ItemForCatalogLinking[];
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    incomingSupplierId: number;

    validateGeneralDataStep: WizardStepValidator;
    subsystemCurrency: Currencies;

    LinkableEntity = LinkableEntities;
    gateSystems: ItemForCatalogLinking[];
    selectedGateSystems: number[];
    configAddonSystems: ItemForCatalogLinking[];
    selectedConfigAddonSystems: number[];

    WindowSystemTypeGroup = ProductTypeGroup;

    @ViewChild('dt') datatable: DataTable;

    private allActiveColors: Observable<ColorInterface[]>;
    private readonly gateSystemService: GateSystemService;
    private readonly configAddonSystemService: ConfigSystemService;

    additionalIcon: File;
    editPermits: FieldLimitation[] = [];
    fieldUsage: AddonFieldUsage;
    CatalogTab = CatalogTab;
    AddonField = AddonField;
    filteredAvailableInOptions: SelectItem[];
    gateSidebarCategory = AddonCategoryEnum.GATE_SIDEBAR;

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                public router: Router,
                private route: ActivatedRoute,
                private location: PlatformLocation,
                public permissions: Permissions,
                public supplierService: SupplierService,
                public addonService: AddonsService,
                public colorService: ColorService,
                public windowSystemDefinitionService: WindowSystemDefinitionService,
                private subsystemService: SubsystemService,
                private editCatalogPermitsService: EditCatalogPermitsService,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'AddonsComponent', true);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
        this.gateSystemService = injector.get(GateSystemService);
        this.configAddonSystemService = injector.get(ConfigSystemService);
        this.userLang = translate.currentLang;
        this.langTranslateSubscription = translate.onLangChange
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((event) => {
                this.userLang = event.lang;
                this.reloadDatatable();
            });
        this.validationErrors = {};
        this.addon = new Addon();
        this.validateGeneralDataStep = () => this.validateGeneralDataPage();
        this.initDefaultSortOrder();
        this.fieldUsage = new AddonFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.allowedCategories = this.addonCategoryTarget.addonCategories;
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
            this.filterCategory = this.translatedSelectItemService.buildSortedDropdown(this.allowedCategories, 'ADDONS.CATEGORIES.', '');
            this.availableCategories = this.filterCategory.pipe(map(items => items.slice(1)));
        } else {
            this.filterCategory = this.translatedSelectItemService.buildSortedDropdown([AddonCategoryEnum.OTHER], 'ADDONS.CATEGORIES.',
                undefined);
            this.availableCategories = this.filterCategory;
        }

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
            this.filterAddonFor = this.translatedSelectItemService.buildSortedDropdown(this.addonCategoryTarget.addonFor, 'ADDONS.FOR.', '');
            this.availableAddonFor = this.filterAddonFor.pipe(map(items => items.slice(1).filter(item => item.value !== 'GATE_SIDEBAR')));
        } else {
            this.filterAddonFor = this.translatedSelectItemService.buildSortedDropdown([AddonFor.BULK], 'ADDONS.FOR.', undefined);
            this.availableAddonFor = this.filterAddonFor;
        }
        this.availableQuantityTypes = this.translatedSelectItemService.buildSortedDropdown(QuantityType, 'ADDONS.QUANTITY_TYPE.FULL_NAME.',
            undefined);
        this.incomingSupplierId = this.route.snapshot.params['supplierId'];
        this.windowSystemDefinitionService.getSystemsForCatalogLinking().subscribe(data => {
            this.windowSystems = data;
        });
        this.allActiveColors = this.colorService.getAllActiveColors().pipe(shareReplay(1));
        if (this.isPermitted({roles: ['ROLE_OPERATOR']})) {
            this.subsystemService.getDefaultCurrency().subscribe(currency => {
                this.subsystemCurrency = currency;
            });
        }
        this.gateSystemService.getGatesForCatalogLinking().subscribe(gateSystems => {
            this.gateSystems = gateSystems;
            this.changeDetector.markForCheck();
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ADDONS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
        this.configAddonSystemService.getConfigAddonsForCatalogLinking().subscribe(configAddonSystems => {
            this.configAddonSystems = configAddonSystems;
            this.changeDetector.markForCheck();
        });
    }

    ngOnDestroy() {
        this.langTranslateSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    removeFiltersNotSetOnTableColumns() {
        this.incomingSupplierId = undefined;
        this.location.replaceState(null, null, 'features/window-system;tabName=addons');
        this.reloadDatatable();
        return false;
    }

    filterSupplierId(event: LazyLoadEvent): void {
        if (this.incomingSupplierId) {
            event.filters['incomingSupplierId'] = {value: this.incomingSupplierId.toString(), matchMode: undefined};
        }
    }

    filterAddonSource(event: LazyLoadEvent): void {
        event.filters['forOffer'] = {value: 'false'};
        event.filters['includeGeneralAddons'] = {value: `${this.isPermitted({roles: ['ROLE_KOORDYNATOR']})}`};
        event.filters['includeSubsystemAddons'] = {value: `${this.isPermitted({roles: ['ROLE_OPERATOR']})}`};
        let categoryFilter = event.filters['category'];
        if (categoryFilter == undefined || !categoryFilter.value) {
            event.filters['category'] = {value: `${this.allowedCategories.join()}`};
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        event = {...event, filters: {...event.filters}}; // copy event so that hidden/forced filters are not visible in table headers
        this.filterSupplierId(event);
        this.filterAddonSource(event);
        event.filters['withColors'] = {value: 'false'};
        return this.addonService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.totalRecords = data.totalRecords;
                    this.addons = data.data;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedAddon = this.restoreSelectionAfterLoad(this.selectedAddon, this.addons, event);
                },
                error: error => {
                    console.error('AddonsComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('AddonsComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    showDialogToAdd() {
        this.file = null;
        this.tile = null;
        this.validationErrors = {};
        this.prepareDataForAddon(null);
    }

    showDialogToCopy() {
        if (this.selectedAddon) {
            this.validationErrors = {};
            this.file = undefined;
            this.tile = undefined;
            this.prepareDataForAddon(this.selectedAddon.id);
        }
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.file = undefined;
        this.tile = undefined;
        this.prepareDataForAddon(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    isPermitted(requiredPermission: { roles: ApplicationPrivilege[] }) {
        return this.permissions.isPermitted(requiredPermission);
    }

    cancel() {
        if (this.copyMode) {
            this.restoreSelectionAndResetHotkeysAfterCopyCanceled(this.datatable, this.selectedAddon);
        }
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newAddon = false;
        this.resetFile();
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedAddon);
    }

    resetFile() {
        this.file = null;
        this.tile = null;
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newAddon = false;
        this.resetFile();
        this.reloadDatatable();
    }

    prepareDataForAddon(addonId: number) {
        forkJoin({
            suppliers: this.supplierService.getSupplierNames(),
            colors: this.allActiveColors,
            addon: (addonId != undefined ? this.addonService.getItem(addonId, false, undefined, undefined, true) : of(new Addon())),
            image: (addonId != undefined ? this.addonService.getImageForItemAsFile(addonId) : of<File>(undefined)),
            tile: (addonId != undefined ? this.addonService.getTileForItemAsFile(addonId) : of<File>(undefined)),
            additionalIcon: (addonId != undefined ? this.addonService.getAdditionalIconAsFile(addonId) : of<File>(undefined)),
            linkedSystems: (addonId != undefined ? this.addonService.getLinkedSystems(addonId) : of<number[]>([])),
            motlawaInfo: (addonId != undefined && this.isPermitted({roles: ['ROLE_KOORDYNATOR']})
                ? this.addonService.getMotlawaInfo(addonId)
                : of(new MotlawaIntegrationInfo())),
            linkedGateSystems: (addonId != undefined ? this.addonService.getLinkedGateSystems(addonId) : of([])),
            linkedConfigAddonSystems: (addonId != undefined ? this.addonService.getLinkedConfigSystems(addonId) : of([]))
        }).subscribe({
            next: data => {
                this.availableSuppliers = data.suppliers.map(supplier => new SelectItemImpl(supplier.companyName, supplier));
                this.newAddon = addonId == undefined;
                this.addon = data.addon;

                if (this.newAddon && this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                    this.addon.sortIndex = 1;
                }
                this.fillColorsList(data.colors);
                if (this.copyMode) {
                    this.addon.id = undefined;
                }
                if (this.newAddon) {
                    if (this.isPermitted({roles: ['ROLE_OPERATOR']})) {
                        this.addon.category = AddonCategoryEnum[AddonCategoryEnum.OTHER];
                        this.addon.addonFor = AddonFor[AddonFor.BULK];
                        this.addon.availableIn = AddonAvailableIn.BOTH;
                        this.addon.price.type = PriceType[PriceType.AMOUNT];
                        this.addon.quantityType = null;
                    }
                } else {
                    let supplier = this.availableSuppliers.find(s => s.value.id === this.addon.supplier.id);
                    this.addon.supplier = supplier ? supplier.value : undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
                this.selectedGateSystems = data.linkedGateSystems;
                this.selectedConfigAddonSystems = data.linkedConfigAddonSystems;
                this.file = data.image;
                this.tile = {file: data.tile, needSave: false};
                this.motlawaInfo = data.motlawaInfo;
                this.additionalIcon = data.additionalIcon;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    submit() {
        if (this.addon.pcn === '') {
            this.addon.pcn = undefined;
        }

        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            if (!Number.isNaN(Number(this.addon.defaultQuantity))) {
                this.addon.defaultQuantity = +this.addon.defaultQuantity.toString().replace(",", ".");
            }
            let observable: Observable<number>;
            const editLinksAfterSave = (entityId: number) => {
                const ids = new ListOfIds();
                ids.ids = this.selectedWindowSystems;
                return forkJoin({
                    windowSystemLinks: this.addonService.editLinks(entityId, ids),
                    gateSystemLinks: this.addonService.editGateSystemLinks(entityId, this.selectedGateSystems),
                    configAddonSystemLinks: this.addonService.editConfigSystemLinks(entityId, this.selectedConfigAddonSystems),
                }).pipe(
                    map(() => entityId)
                );
            };

            if (this.copyMode) {
                this.sanitizeAddonNameAndShortcut();
                observable = this.addonService.copyItem(this.selectedAddon.id, this.addon, this.file, this.tile, this.additionalIcon,
                    this.motlawaInfo);
            } else {
                observable = this.addonService.saveItem(this.addon, this.file, this.tile, this.additionalIcon, this.motlawaInfo);
            }

            if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                observable = observable.pipe(mergeMap(editLinksAfterSave));
            }

            observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                complete: () => {
                    this.showSuccessMessage();
                    this.hideDetails();
                },
                error: err => {
                    this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private sanitizeAddonNameAndShortcut() {
        if (this.addon.subsystemId != null) {
            this.addon.name = new MultilanguageField(this.addon.name[this.translate.currentLang]);
            this.addon.shortcut = new MultilanguageField(this.addon.shortcut[this.translate.currentLang]);
        }
    }

    showSuccessMessage() {
        if (this.newAddon) {
            this.growlMessageController.info('ADDONS.CREATED');
        } else {
            this.growlMessageController.info('ADDONS.UPDATED');
        }
    }

    validateGeneralDataPage(): Observable<boolean> {
        this.validationErrors = {};
        if (this.addon.pcn === '') {
            this.addon.pcn = undefined;
        }

        const generalDataValidationErrors = {};
        new AddonsValidatorService(generalDataValidationErrors)
            .validateGeneralDataPage(this.addon, this.userLang, !this.isPermitted({roles: ['ROLE_KOORDYNATOR']}));

        if (ValidationErrorsHelper.validationErrorsPresent(generalDataValidationErrors)) {
            this.validationErrors = Object.assign({}, generalDataValidationErrors);
            this.changeDetector.markForCheck();
            return of(false);
        }

        this.sanitizeAddonNameAndShortcut();
        return this.addonService.validate(this.addon).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }), map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    private fillColorsList(data: ColorInterface[]): void {
        this.fillAvailableInsideColors(data);
        this.fillAvailableOutsideColors(data);
        this.fillAvailableCoreColors(data);
    }

    private fillAvailableInsideColors(data: ColorInterface[]): void {
        this.availableInsideColors = this.getAvailableColors(data, c => c.inside,
            this.addon != undefined ? this.addon.insideColors : undefined);
    }

    private fillAvailableOutsideColors(data: ColorInterface[]): void {
        this.availableOutsideColors = this.getAvailableColors(data, c => c.outside,
            this.addon != undefined ? this.addon.outsideColors : undefined);
    }

    private fillAvailableCoreColors(data: ColorInterface[]): void {
        this.availableCoreColors = this.getAvailableColors(data, c => c.core,
            this.addon != undefined ? this.addon.coreColors : undefined);
    }

    private getAvailableColors(data: ColorInterface[], colorFilter: (c: Color) => boolean,
                               addonColors: ColorInterface[]): SelectItem[] {
        return data.filter(colorFilter).map(c => {
            let existingColor: ColorInterface = undefined;
            if (addonColors != undefined) {
                existingColor = addonColors.find(ac => ac.id === c.id);
            }
            if (existingColor != undefined) {
                return {label: getColorFormattedNameWithGroup(existingColor, this.userLang), value: existingColor};
            } else {
                return {label: getColorFormattedNameWithGroup(c, this.userLang), value: c};
            }
        });
    }

    protected setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    onFileChange(newFile) {
        this.file = newFile;
        if (!newFile) {
            this.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    onTileChange(newFile: File) {
        this.tile.file = newFile;
        this.tile.needSave = true;
        if (!newFile) {
            this.tile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    onAdditionalIconChange(newFile) {
        this.additionalIcon = newFile;
        if (!newFile) {
            this.additionalIcon = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    isWindowAddon() {
        return this.addonCategoryTarget.target === 'Window';
    }

    isGateAddon() {
        return this.addonCategoryTarget.target === 'Gate';
    }

    isConfigAddon() {
        return this.addonCategoryTarget.target === 'Config';
    }

    isWingFunctionsGroup(): boolean {
        return false;
    }

    isChangeQuantityDisabled(): boolean {
        return false;
    }

    isRemovableDisabled(): boolean {
        return false;
    }

    isWindowSystemSelectionDisabled(): boolean {
        return false;
    }

    showCustomValueField(): boolean {
        return false;
    }

    canHavePrice(): boolean {
        return false;
    }
}
