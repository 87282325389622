import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Country} from '../../../common/enums/country';
import {ShippingPriceTable} from './shippingPriceTable';
import {ShippingPriceTableInfoList} from './shippingPriceTableInfo';

@Injectable()
export class ShippingPriceTableService {

    private static readonly API_URL = 'shipping';

    constructor(private http: HttpClient) {
    }

    getPriceTable(country: Country): Observable<ShippingPriceTable> {
        return this.http.get<ShippingPriceTable>(`${ShippingPriceTableService.API_URL}/pricetable/${country}`);
    }

    getShippingPriceTableInfoList(): Observable<ShippingPriceTableInfoList> {
        return this.http.get<ShippingPriceTableInfoList>(`${ShippingPriceTableService.API_URL}/pricetables`);
    }

    savePriceTable(priceTable: ShippingPriceTable): Observable<void> {
        return this.http.post<void>(`${ShippingPriceTableService.API_URL}/pricetable`, priceTable);
    }
}
