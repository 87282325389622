import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MultilanguageFieldInterface} from '../../../window-designer/catalog-data/multilanguage-field-interface';

@Injectable()
@Pipe({
    name: 'multilanguageTranslate',
    pure: false
})
export class MultilanguageTranslatePipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(text: MultilanguageFieldInterface, ...args: any[]): string {
        if (text) {
            return text[this.translate.currentLang];
        } else {
            return "";
        }
    }
}
