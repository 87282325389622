import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ValidationErrorsHelper} from '../ValidationErrorsHelper';
import {SavedFilter} from './saved-filter';
import {SavedFilterService} from './saved-filter.service';

@Component({
    selector: 'app-saved-filter',
    templateUrl: './saved-filter.component.html',
    styleUrls: ['../../features/shared-styles.css', '../../second-level-menu.css', './saved-filter.component.css'],
    providers: [SavedFilterService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedFilterComponent implements OnInit {

    @Input()
    viewId: string;

    @Input()
    currentTableFilters: { [component: string]: string } = {};

    @Input()
    currentTableVisibleColumns: { [component: string]: string } = {};

    @Output()
    onCreateSuccess: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onDeleteSuccess: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onCreateError: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onDeleteError: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onFilterSelected: EventEmitter<SavedFilter> = new EventEmitter<SavedFilter>();

    @Output()
    onFilterUnselected: EventEmitter<string> = new EventEmitter<string>();

    filters: SavedFilter[] = [];
    filtersToDelete: SavedFilter[] = [];
    showCreateFilterDialog = false;
    showDeleteFilterDialog = false;
    newFilterName: string;
    newFilterDescription: string;
    validationErrors: any = {};
    selectedFilter: SavedFilter;

    constructor(private savedFilterService: SavedFilterService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.loadFiltersForView(this.viewId);
    }

    loadFiltersForView(viewId: string): void {
        this.savedFilterService.getFiltersForView(viewId).subscribe(response => {
            this.filters = response.data;
            this.reselectFilter();
            this.changeDetector.markForCheck();
        });
    }

    private reselectFilter(): void {
        if (this.selectedFilter) {
            let foundFilter = this.filters.find(filter => this.selectedFilter.id === filter.id);

            if (foundFilter) {
                this.selectedFilter = foundFilter;
            } else {
                this.unselectFilter();
            }
        }
    }

    createFilter(): void {
        this.showCreateFilterDialog = true;
        this.newFilterName = null;
        this.newFilterDescription = null;
        this.changeDetector.markForCheck();
    }

    hideCreateFilterDialog(): void {
        this.showCreateFilterDialog = false;
        this.changeDetector.markForCheck();
    }

    confirmSaveFilter(): void {
        this.validateFilterToSave();

        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            return;
        }

        let filterToSave = new SavedFilter(null, this.newFilterName, this.newFilterDescription, this.viewId, this.currentTableFilters[this.viewId], this.currentTableVisibleColumns[this.viewId]);

        this.savedFilterService.saveItem(filterToSave).subscribe({
            next: () => {
                this.hideCreateFilterDialog();
                this.loadFiltersForView(this.viewId);
                this.onCreateSuccess.emit();
            },
            error: () => {
                this.hideCreateFilterDialog();
                this.onCreateError.emit();
            }
        });
    }

    private validateFilterToSave(): void {
        if (!this.newFilterName) {
            this.validationErrors['newFilterName'] = 'error.savedFilter.newFilterName.not_empty';
        }
    }

    selectFilter(filter: SavedFilter): void {
        if (filter == this.selectedFilter) {
            this.unselectFilter();
        } else {
            this.selectedFilter = filter;
            this.onFilterSelected.emit(filter);
        }
    }

    selectedFilterModified(): void {
        this.selectedFilter = null;
        this.changeDetector.markForCheck();
    }

    private unselectFilter(): void {
        if (this.selectedFilter != undefined) {
            const viewId = this.selectedFilter.viewId;
            this.selectedFilter = null;
            this.onFilterUnselected.emit(viewId);
        }
    }

    isFilterSelected(filter: SavedFilter): boolean {
        return this.selectedFilter == filter;
    }

    deleteFilter(): void {
        if (this.filters.length > 0) {
            this.filtersToDelete = [];
            this.filters.forEach(filter => {
                filter.selected = false;
                this.filtersToDelete.push(filter);
            });

            this.showDeleteFilterDialog = true;
            this.changeDetector.markForCheck();
        }
    }

    isAnyFilterToDeleteSelected(): boolean {
        return this.filtersToDelete.filter(filter => filter.selected).length > 0;
    }

    confirmDeleteFilter(): void {
        let idsToDelete = this.filtersToDelete.filter(filter => filter.selected).map(filter => filter.id);

        this.savedFilterService.deleteItems(idsToDelete).subscribe({
            next: () => {
                this.loadFiltersForView(this.viewId);
                this.hideDeleteFilterDialog();
                this.onDeleteSuccess.emit();
            },
            error: () => {
                this.hideDeleteFilterDialog();
                this.onDeleteError.emit();
            }
        });
    }

    hideDeleteFilterDialog(): void {
        this.showDeleteFilterDialog = false;
        this.changeDetector.markForCheck();
    }

    getTooltipMessage(filter: SavedFilter): string {
        if (filter.description == undefined) {
            return filter.name;
        }

        return filter.name + ' - ' + filter.description;
    }
}
