import {Grill} from "./Grill";
import {GrillType} from "./GrillType";

export class LineGrill extends Grill {
    type: GrillType.LINE_GRILL;

    constructor() {
        super(GrillType.LINE_GRILL);
    }
}
