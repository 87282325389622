import {CrudItem, JsonDeserializable} from "../../common/crud-common/crudItem";
import {getTomorrowDateUTC} from './tomorrow-date-helper';

export enum PricelistStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    GENERATING = "GENERATING",
    GENERATION_FAILED = "GENERATION_FAILED"
}

export enum PricelistTarget {
    WINDOWS = "WINDOWS",
    CONFIGS = "CONFIGS",
}

@JsonDeserializable
export class Pricelist implements CrudItem {

    id: number;
    name: string;
    status: PricelistStatus;
    validFrom: Date;
    target: PricelistTarget;
    current: boolean;

    constructor() {
        this.id = undefined;
        this.name = '';
        this.status = PricelistStatus.ACTIVE;
        this.validFrom = getTomorrowDateUTC();
        this.current = false;
    }

    public static fromJSON(jsonObject: any): Pricelist {
        let pricelist = new Pricelist();
        pricelist.id = jsonObject.id;
        pricelist.name = jsonObject.name;
        pricelist.status = jsonObject.status;
        pricelist.validFrom = new Date(jsonObject.validFrom);
        pricelist.target = jsonObject.target;
        pricelist.current = jsonObject.current;
        return pricelist;
    }
}
