<p-dialog [resizable]="false" [modal]="true" (onHide)="close()" [visible]="true" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.ARCHIVED_VERSIONS.HEADER' | translate }}
        </div>
    </p-header>

    <div>
        <div class="scrolled-dialog">
            <p-dataTable [value]="dialogData.versions" id="archivedVersionsListTable">
                <p-column header="{{'OFFER.POSITIONS.ARCHIVED_VERSIONS.ACTIONS' | translate}}">
                    <ng-template let-item="rowData" pTemplate="body">
                        <span>
                            <app-simple-button [buttonId]="'restore_version_' + item.versionNumber"
                                               label="{{ (canRestore ? 'OFFER.POSITIONS.ARCHIVED_VERSIONS.RESTORE' : 'OFFER.POSITIONS.RESTORE_ARCHIVED_DISABLED') | translate }}"
                                               icon="restore" type="action" (onClick)="restore(item.id)"
                                               [inlineLabel]="false" [disabled]="!canRestore"></app-simple-button>
                            <app-simple-button [buttonId]="'print_version_' + item.versionNumber"
                                               label="{{ 'OFFER.POSITIONS.ARCHIVED_VERSIONS.PRINT' | translate }}"
                                               icon="print" type="action" (onClick)="print(item.id)"
                                               [inlineLabel]="false"></app-simple-button>
                        </span>
                    </ng-template>
                </p-column>
                <p-column field="versionNumber" header="{{'OFFER.POSITIONS.ARCHIVED_VERSIONS.NUMBER' | translate}}"></p-column>
                <p-column field="author" header="{{'OFFER.POSITIONS.ARCHIVED_VERSIONS.AUTHOR' | translate}}"></p-column>
                <p-column field="versionNumber" header="{{'OFFER.POSITIONS.ARCHIVED_VERSIONS.DATE' | translate}}">
                    <ng-template let-item="rowData" pTemplate="body">
                        <span>{{ item.date | momentCurrentLang | amDateFormat: 'L LT' }}</span>
                    </ng-template>
                </p-column>
                <p-column field="description" header="{{'OFFER.POSITIONS.ARCHIVED_VERSIONS.DESCRIPTION' | translate }}">
                    <ng-template let-item="rowData" pTemplate="body">
                        <span>{{ (item.applicationVersionAutoCreated ? 'OFFER.POSITIONS.ARCHIVED_VERSIONS.APPLICATION_VERSION_AUTO_CREATED' : '') | translate }}</span>
                    </ng-template>
                </p-column>
            </p-dataTable>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'OFFER.ACTIONS.DIALOGS.BUTTON_CLOSE' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>

</p-dialog>
