import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AddonsService} from '../../../window-system/addons/addons.service';
import {ColorService} from '../../../window-system/color/color.service';
import {DecorativeFillingsService} from '../../../window-system/decorative-filling/decorative-filling.service';
import {GlassService} from '../../../window-system/glass/glass.service';
import {GlazingBeadService} from '../../../window-system/glazing-bead/glazing-bead.service';
import {GrillService} from '../../../window-system/grill/grill.service';
import {ProfileService} from '../../../window-system/profile/profile.service';
import {EntranceGlazingPackageService} from "../../../window-system/entrance-glazing-package/entrance-glazing-package.service";

@Injectable()
export class SidebarFieldImageService {

    constructor(private profileService: ProfileService,
                private addonService: AddonsService,
                private colorService: ColorService,
                private decorativeFillingsService: DecorativeFillingsService,
                private glassService: GlassService,
                private glazingBeadService: GlazingBeadService,
                private grillService: GrillService,
                private entranceGlazingPackageService: EntranceGlazingPackageService) {
    }

    getProfileImage(profileId: number): Observable<string> {
        return this.profileService.getImage(profileId);
    }

    getAddonImage(addonId: number): Observable<string> {
        return this.addonService.getImageForItem(addonId);
    }

    getColorImage(colorId: number): Observable<string> {
        return this.colorService.getImage(colorId);
    }

    getDecorativeFillingImage(decorativeFillingId: number): Observable<string> {
        return this.decorativeFillingsService.getImage(decorativeFillingId);
    }

    getGlassImage(glassId: number): Observable<string> {
        return this.glassService.getImage(glassId);
    }

    getGlazingBeadImage(glazingBeadId: number): Observable<string> {
        return this.glazingBeadService.getImage(glazingBeadId);
    }

    getGrillImage(grillId: number): Observable<string> {
        return this.grillService.getImage(grillId);
    }

    getEntranceGlazingPackageImage(glazingPackageId: number): Observable<string> {
        return this.entranceGlazingPackageService.getImage(glazingPackageId);
    }
}
