<div [ngClass]="{'new-form-60vw': permissions.isPermitted({roles:['ROLE_KOORDYNATOR']}),
                 'new-form-600': !permissions.isPermitted({roles:['ROLE_KOORDYNATOR']})}">

    <app-link-selection [disabled]="disabled"
                        [linkableItems]="windowSystems"
                        [ngModel]="linkedSystemIds"
                        (ngModelChange)="onSelectedSystemsChange($event)"
                        [showToggleAllButton]="true"
                        [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
</div>
