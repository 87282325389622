import {Component, DoCheck, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {forkJoin, from, Observable, of, Subject, Subscription} from 'rxjs';
import {finalize, map, mergeMap, share, takeUntil} from "rxjs/operators";
import * as _ from 'underscore';
import {AbstractWindowDesigner} from '../../../../../window-designer/abstract-window-designer';
import {AllAddons} from '../../../../../window-designer/all-addons';
import {CachingWindowDesignerDataService} from "../../../../../window-designer/caching-window-designer-data-service";
import {CustomTranslationsInterface} from "../../../../../window-designer/catalog-data/custom-translations-interface";
import {DistanceFrameInterface} from '../../../../../window-designer/catalog-data/distance-frame-interface';
import {Glazing} from "../../../../../window-designer/catalog-data/glazing";
import {PositionListAddon} from "../../../../../window-designer/catalog-data/position-list-addon";
import {ProfileType} from '../../../../../window-designer/catalog-data/profile-interface';
import {WindowSystemType} from "../../../../../window-designer/catalog-data/window-system-interface";
import {DrawingDataChangeHelper} from "../../../../../window-designer/drawing-data-change-helper";
import {AreaSpecification} from "../../../../../window-designer/drawing-data/AreaSpecification";
import {CutData} from "../../../../../window-designer/drawing-data/CutData";
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {FillingType} from "../../../../../window-designer/drawing-data/FillingType";
import {Grill} from "../../../../../window-designer/drawing-data/Grill";
import {GrillType} from "../../../../../window-designer/drawing-data/GrillType";
import {HandleDirection} from "../../../../../window-designer/drawing-data/HandleDirection";
import {HandleState} from "../../../../../window-designer/drawing-data/HandleState";
import {LineCutData} from "../../../../../window-designer/drawing-data/LineCutData";
import {LineGrill} from "../../../../../window-designer/drawing-data/LineGrill";
import {Mullion} from "../../../../../window-designer/drawing-data/Mullion";
import {SubWindowData} from "../../../../../window-designer/drawing-data/SubWindowData";
import {Veneer} from "../../../../../window-designer/drawing-data/Veneer";
import {WindowAddon} from "../../../../../window-designer/drawing-data/WindowAddon";
import {WindowData} from "../../../../../window-designer/drawing-data/WindowData";
import {WindowShape} from "../../../../../window-designer/drawing-data/WindowShape";
import {WindowShapeType} from "../../../../../window-designer/drawing-data/WindowShapeType";
import {DataKeys, DrawingUtil, MinMaxXY, Point} from "../../../../../window-designer/drawing-util";
import {ConfigurableAddon} from '../../../../../window-designer/entities/ConfigurableAddon';
import {WindowSystemDefaults} from '../../../../../window-designer/entities/window-system-defaults';
import {GlazingHelper} from "../../../../../window-designer/glazing-helper";
import {Guides} from "../../../../../window-designer/guides";
import {GuidesDialogData} from "../../../../../window-designer/guides-dialog-data";
import {PainterMode} from "../../../../../window-designer/painters/PainterMode";
import {PainterParams} from "../../../../../window-designer/painters/PainterParams";
import {ScalingPainter} from "../../../../../window-designer/painters/ScalingPainter";
import {WindowParams} from "../../../../../window-designer/painters/WindowParams";
import {PendingGrillData} from "../../../../../window-designer/pending-grill-data";
import {ShadingCalculator} from "../../../../../window-designer/ShadingCalculator";
import {SubwindowTypes} from "../../../../../window-designer/subwindow-types";
import {
    AddonDefaultQuantityCalculator
} from "../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {AlignmentTool} from '../../../../../window-designer/utils/AlignmentTool';
import {AreaUtils} from "../../../../../window-designer/utils/AreaUtils";
import {CutsUtil} from "../../../../../window-designer/utils/cutUtils";
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {FloatOps} from "../../../../../window-designer/utils/float-ops";
import {GrillHelper} from "../../../../../window-designer/utils/grill-helper";
import {GrillPositionValidator} from '../../../../../window-designer/utils/grill-position-validator';
import {GrillSegmentGenerator} from "../../../../../window-designer/utils/GrillSegmentGenerator";
import {MullionHelper} from "../../../../../window-designer/utils/MullionHelper";
import {MullionUtils} from "../../../../../window-designer/utils/MullionUtils";
import {OperationResult} from "../../../../../window-designer/utils/OperationResult";
import {PolygonPoint, PolygonPointUtil} from "../../../../../window-designer/utils/PolygonPoint";
import {PositionsHelper} from "../../../../../window-designer/utils/positions-helper";
import {PricingUtils} from "../../../../../window-designer/utils/PricingUtils";
import {UpsellingUtils} from "../../../../../window-designer/utils/UpsellingUtils";
import {UwUtils} from '../../../../../window-designer/utils/UwUtils';
import {VisibilitySettings} from "../../../../../window-designer/utils/VisibilitySettings";
import {WindowAddonUtils} from '../../../../../window-designer/utils/WindowAddonUtils';
import {WindowTypeCodeParser} from "../../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {WindowCommonData} from "../../../../../window-designer/window-common-data";
import {WindowDataFactory} from "../../../../../window-designer/window-data-factory";
import {WindowDesignerDataServiceInterface} from '../../../../../window-designer/window-designer-data.service-interface';
import {Tool, WindowDesignerInterface} from '../../../../../window-designer/window-designer-interface';
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";
import {WindowAttributes} from "../../../../../window-designer/window-types/window-attributes";
import {WindowTypeCode} from "../../../../../window-designer/window-types/window-type-code";
import {StorageService} from "../../../../auth/storage.service";
import {UserUiConfigService} from "../../../../auth/uiconfig/userUiConfig.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {FocusOnElement} from "../../../../common/FocusOnElement";
import {GrowlMessageController} from "../../../../common/growl-message/growl-message-controller";
import {MessageParams} from '../../../../common/growl-message/MessageParams';
import {MissingProfitMarginHandlerService} from '../../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service';
import {MultilanguageField} from '../../../../supportedLanguages';
import {ErrorReportService} from "../../../admin-panel/error-browser/error-report.service";
import {AffectedElement, ErrorCategory} from "../../../admin-panel/error-browser/errorReport";
import {CatalogConfiguration} from "../../../catalog-creator/catalog-configuration";
import {CatalogConfigurationService} from "../../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../../catalog-creator/CatalogPropertyTarget";
import {WindowSystemDefaultsState} from "../../../settings/system-defaults/system-default-state";
import {AddonCategoryGroupService} from "../../../window-system/addon-category-group/addon-category-group.service";
import {AddonsService} from "../../../window-system/addons/addons.service";
import {BusinessTypeService} from "../../../window-system/business-type/business-type.service";
import {BusinessType} from "../../../window-system/business-type/BusinessType";
import {BusinessTypeBasic} from "../../../window-system/business-type/BusinessTypeBasic";
import {Color} from '../../../window-system/color/color';
import {ConfigSystem} from "../../../window-system/config-system/config-system";
import {DistanceFrame} from "../../../window-system/distance-frame/distanceFrame";
import {GlassWithPosition} from "../../../window-system/glass/glassWithPositions";
import {Grill as GrillDto} from "../../../window-system/grill/grill";
import {Profile} from "../../../window-system/profile/profile";
import {DataModificationTarget, isUpsellingMode} from "../../../window-system/webshop-charge/WebshopCharge";
import {WindowSystemDefinition} from '../../../window-system/window-system-definition/window-system-definition';
import {
    WindowSystemDefinitionDrawingToolsEnabler
} from '../../../window-system/window-system-definition/window-system-definition-drawing-tools-enabler';
import {WindowSystemDefinitionService} from "../../../window-system/window-system-definition/window-system-definition.service";
import {PositionType} from '../../AbstractPosition';
import {CategoryWithAutoOptions} from "../../config-editor/config-editor.component";
import {MessageSeverity, PositionMessage} from '../../offers/message';
import {Position} from "../../offers/position/position-list/position";
import {PositionService} from "../../offers/position/position.service";
import {VeneerEvent} from "../../veneer/VeneerEvent";
import {AddWindowEvent} from '../add-subwindow-dialog/add-subwindow-dialog.component';
import {DecorativeFillingWithColors} from '../DecorativeFillingWithColors';
import {ChangeBusinessTypeUtils} from "../drawing-tool/ChangeBusinessTypeUtils";
import {ConfigAddonValidator} from '../drawing-tool/ConfigAddonValidator';
import {ConfigurableAddonUtils} from "../drawing-tool/ConfigurableAddonUtils";
import {ExtendGrillsTool} from "../drawing-tool/ExtendGrillsTool";
import {MirrorTool} from "../drawing-tool/MirrorTool";
import {OfferComponentsCounter} from "../drawing-tool/OfferComponentsCounter";
import {PendingGrillStage} from "../drawing-tool/PendingGrillStage";
import {TrimTool} from "../drawing-tool/TrimTool";
import {WindowTabData} from "../drawing-tool/WindowTabData";
import {HistoryService} from "../history/history.service";
import {FillingColorType} from "../sidebar/FillingColorType";
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {GlazingPackagePositionModel} from "../sidebar/pricing/glazing-package-position-model";
import {Pricing} from "../sidebar/pricing/Pricing";
import {PricingService} from "../sidebar/pricing/pricing.service";
import {Product} from "../sidebar/pricing/Product";
import {ResponseStatusFlags, ResponseStatusHelper} from "../sidebar/ResponseStatusFlags";
import {WindowEditorField} from "../window-editor-field";
import {WindowEditorOfferData, WindowEditorPositionData} from '../window-editor-offer-interfaces';
import {WindowEditorWindowSystemInterface} from '../window-editor-window-system-interface';
import {DrawingDataDiffer, DrawingDataFieldChange} from './drawing-data-differ.service';
import {WindowDesignerDataService} from './window-designer-data.service';

class MessagesFromCurrentPricing {
    list: PositionMessage[] = [];

    containsMessageOfType(messageType: MessageSeverity): boolean {
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].severity === messageType) {
                return true;
            }
        }
        return false;
    }
}

export class CatalogData {
    constructor(public selectedWindowSystem: WindowSystemDefinition,
                public windowSystemDefaults: WindowSystemDefaultsState,
                public grills: GrillDto[],
                public angledGrills: GrillDto[],
                public mullions: Profile[],
                public glasses: GlassWithPosition[],
                public frames: DistanceFrame[],
                public decorativeFillings: DecorativeFillingWithColors[],
                public colors: Color[],
                public windowSystems: WindowEditorWindowSystemInterface[],
                public addons: AllAddons,
                public windowSystemDrawingToolsEnabler: WindowSystemDefinitionDrawingToolsEnabler,
                public configurableAddonDefinitions: ConfigSystem[],
                public businessTypes: BusinessTypeBasic[] = []) {
    }
}

export class WindowDesignerComponentInitData extends CatalogData {
    constructor(public offer: WindowEditorOfferData,
                public position: WindowEditorPositionData,
                public drawingData: DrawingData,
                public configurableAddonPositions: WindowEditorPositionData[],
                public subwindowTypes: SubwindowTypes,
                public customTranslations: CustomTranslationsInterface,
                selectedWindowSystem: WindowSystemDefinition,
                windowSystemDefaults: WindowSystemDefaultsState,
                windowSystemDrawingToolsEnabler: WindowSystemDefinitionDrawingToolsEnabler,
                grills: GrillDto[],
                angledGrills: GrillDto[],
                mullions: Profile[],
                glasses: GlassWithPosition[],
                frames: DistanceFrame[],
                decorativeFillings: DecorativeFillingWithColors[],
                colors: Color[],
                windowSystems: WindowEditorWindowSystemInterface[],
                addons: AllAddons,
                configurableAddonDefinitions: ConfigSystem[]) {
        super(selectedWindowSystem, windowSystemDefaults, grills, angledGrills, mullions, glasses, frames, decorativeFillings, colors,
            windowSystems, addons, windowSystemDrawingToolsEnabler, configurableAddonDefinitions);
    }
}

@Component({
    selector: 'window-designer',
    templateUrl: './window-designer.component.html',
    styleUrls: ['./window-designer.component.css', '../../../shared-styles.css', '../common/designer-length-unit-hider.directive.css'],
    providers: [WindowDesignerDataService, DataServiceHelper, PositionService, WindowSystemDefinitionService,
        BusinessTypeService, PricingService, UserUiConfigService, StorageService, AddonsService, HistoryService,
        ErrorReportService, CatalogConfigurationService, AddonCategoryGroupService]
})
export class WindowDesignerComponent extends AbstractWindowDesigner implements OnInit, OnDestroy, DoCheck, WindowDesignerInterface {

    private static readonly VIEW_NAME = 'WindowDesignerComponent';

    static GLAZING_GLASS_NUMBER_MAX = 4;
    static SNAP_ELEM_SELECTED_CSS_CLASS = "selected-snap-item";

    private static readonly LAST_WINDOW_SYSTEM_ID_PROPERTY_KEY = "lastWindowSystemId";
    private static readonly OPERATION_IN_PROGRESS = "windowDesignerOperationInProgress";

    @Output() drawingDataChanged = new EventEmitter<DrawingDataFieldChange[]>();
    @Output() onGrillAdded = new EventEmitter<void>();
    @Output() designerInitialized = new EventEmitter<boolean>();
    @Output() newWindowAdded = new EventEmitter<void>();
    @Output() windowElementSelected = new EventEmitter<{ data: { type: string, elements: Snap.Element[] } }>();
    @Output() enterPressed = new EventEmitter<void>();
    @Output() onChangeDisplayAddWindowDialog = new EventEmitter<boolean>();
    @Output() refilterGlazingBeads = new EventEmitter<void>();
    @Output() cuttingFinished = new EventEmitter<void>();
    @Output() revalidateRequiredFields = new EventEmitter<void>();
    @Output() mouseCoordsChange = new EventEmitter<Point>();
    @Output() openConfigAddonList = new EventEmitter<void>();
    @Input() visibilitySettings: VisibilitySettings;
    @Input() requiredFieldFilled = false;
    @Output() businessTypesLoadInProgressChange = new EventEmitter<boolean>();
    @Output() onSubwindowTypeChange = new EventEmitter<string>();
    @Output() onTotalSizeChange = new EventEmitter<{ oldWidth: number, oldHeight: number, newWidth: number, newHeight: number }>();
    @Input() isTerrace: boolean;

    showExitWithMessagesDialog: boolean;
    catalogLoaded = new Subject<WindowDesignerComponentInitData>();
    private enterHotkey: Hotkey;
    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
    profitMarginPresent: boolean;

    static get plusButtonCircleElementAttributes() {
        return {
            fill: '#ffffff',
            stroke: '#0099ff',
            strokeWidth: 2
        };
    }
    catalogConfiguration: CatalogConfiguration;
    commonData = new WindowCommonData();
    guidesDialogData: GuidesDialogData;
    addNewWindowData: (addWindowEvent: AddWindowEvent) => void;
    pendingOperationLineHelper: Snap.Element;
    pendingCut: LineCutData;
    pendingGrillData: PendingGrillData;
    pendingGrillStage = PendingGrillStage.NONE;
    pendingGrillValidationErrors: { [field: string]: string } = {};
    pendingHandleDirection: HandleDirection;
    debouncedRedrawWindow = _.debounce(this.redrawWindow, 300);
    offer: WindowEditorOfferData;
    debounceSaveStep = _.debounce(this.saveStep, 100, true);
    offerPosition: WindowEditorPositionData;
    selectedTabIndicator;
    windowTabsData: WindowTabData[];
    public currentlySelectedTab = 0;
    isPricingCurrentTab: boolean;
    businessTypes: BusinessTypeBasic[];
    windowSystems: WindowEditorWindowSystemInterface[];
    windowSystem: WindowSystemDefinition;
    windowSystemDefaults: WindowSystemDefaults;
    addedWindowAddons: PositionListAddon[] = [];
    configurableAddonDefinitions: ConfigSystem[];
    windowBusinessTypes: BusinessType[] = [];
    windowSizeValidationError: Error & { params?: MessageParams } = null;
    langTranslateSubscription: Subscription;
    unsavedChanges = false;
    offerContainsGrills: boolean;
    globalOnMouseMove: (event: MouseEvent) => void;
    mouseMoveHooks: { [hookName: string]: (point: Point, shiftKey: boolean) => void } = {};
    recentPricingProducts: Product[];
    recentValidationMessages: PositionMessage[] = [];
    pricing: Pricing;
    configurableAddonPositions: ConfigurableAddonPositionModel[] = [];
    glazingPackagePositions: GlazingPackagePositionModel[] = [];
    categoriesWithAutoOptions: CategoryWithAutoOptions[] = [];
    businessTypeChangeSubwindowId: string = undefined;
    windowSystemDrawingToolsEnabler: WindowSystemDefinitionDrawingToolsEnabler;

    public PositionType: string[];
    public FillingType: FillingType[];
    public FillingTypeGlassOnly: FillingType[];
    public FillingTypeWithoutDecorativeFilling: FillingType[];
    public glazingGlassNumber: number[];

    public previewVisible = true;

    addVertically: boolean;

    clickedSnapElements: { type: string, elements: Snap.Element[] } = {
        type: null,
        elements: []
    };

    public pricingStatus: ResponseStatusFlags = new ResponseStatusFlags();
    public validationStatus: ResponseStatusFlags = new ResponseStatusFlags();

    @Input() sidebarOnlyMode = false;
    @Input() readOnlyMode = true;
    @Input() dataModificationMode: DataModificationTarget | undefined;

    // parameteres needed for redrawing previews process
    @Input() redrawPreviewMode = false;

    @ViewChild('addWindowDialog') addWindowDialog;
    @ViewChild('svgElement', {static: true}) svgElement: ElementRef<SVGSVGElement>;

    private saveInProgress = false;

    private drawingDataDiffer = new DrawingDataDiffer();
    private drawingDataDifferOnRedraw = new DrawingDataDiffer();

    isSavingHistory = true;
    upsellingSubwindowDummy: SubWindowData = UpsellingUtils.upsellingDummySubwindow();

    constructor(public positionService: PositionService,
                public hotkeyService: HotkeysService,
                public pricingService: PricingService,
                public translate: TranslateService,
                public router: Router,
                private route: ActivatedRoute,
                private windowSystemDefinitionService: WindowSystemDefinitionService,
                private catalogConfigurationService: CatalogConfigurationService,
                private businessTypeService: BusinessTypeService,
                private userUiConfigService: UserUiConfigService,
                private addonService: AddonsService,
                public zone: NgZone,
                private historyService: HistoryService,
                private errorReportService: ErrorReportService,
                private blockUiController: BlockUiController,
                private errors: CommonErrorHandler,
                private growls: GrowlMessageController,
                private readonly addonCategoryGroupService: AddonCategoryGroupService,
                private missingProfitMarginHandlerService: MissingProfitMarginHandlerService,
                private windowDesignerDataService: WindowDesignerDataService) {
        super(window);

        // Conversion is required so we can use them in selects
        this.PositionType = this.convertEnumToArray(PositionType);
        this.FillingType = Object.values(FillingType);
        this.FillingTypeGlassOnly = [FillingType.GLASS];
        this.FillingTypeWithoutDecorativeFilling = Object.values(FillingType);
        this.FillingTypeWithoutDecorativeFilling.splice(
            this.FillingTypeWithoutDecorativeFilling.indexOf(FillingType.DECORATIVE_FILLING), 1);
        this.glazingGlassNumber = _.range(1, WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX + 1);

        // Field is updated on every setCommonValues run
        this.offerContainsGrills = false;

        this.enterHotkey = new Hotkey('enter', () => {
            if (this.isAddWindowDialogDisplayed()) {
                this.addWindowDialog.addWindowSystem();
            } else if (this.guidesDialogData.displayDialog) {
                this.displayChangeSizeEvent();
            } else {
                this.enterPressed.emit();
            }
            return false;
        }, ['INPUT']);

        this.getData();
    }

    elementSelected(elem: Snap.Element | Snap.Element[] | string, addToAlreadySelected: boolean) {
        let selectedObjectRefs: any[] = this.getSelectedObjectReferences();
        if (!addToAlreadySelected) {
            this.resetSelectedElements();
        }
        if (elem instanceof Array) {
            elem.forEach(val => {
                this.addElemToClickedList(val, selectedObjectRefs, addToAlreadySelected);

            });
        } else if (typeof elem === "string") {
            let matchingElements = this.svg.selectAll("." + (elem as string));
            matchingElements.forEach(el => {
                this.addElemToClickedList(el, selectedObjectRefs, addToAlreadySelected);
            });
        } else {
            this.addElemToClickedList(elem, selectedObjectRefs, addToAlreadySelected);
        }
        this.windowElementSelected.emit({data: this.clickedSnapElements});
    }

    resetSelectedElements() {
        this.clickedSnapElements = {type: null, elements: []};
        let previouslySelected = this.svg.selectAll("." + WindowDesignerComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        previouslySelected.forEach(el => {
            el.removeClass(WindowDesignerComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        });
    }

    addCategoriesWithAutoOptions(categoriesWithAutoOptions: CategoryWithAutoOptions[] = []) {
        let presentSymbols = this.categoriesWithAutoOptions.map(cat => cat.symbol);
        let newCategories = categoriesWithAutoOptions.filter(category => !presentSymbols.includes(category.symbol));
        this.categoriesWithAutoOptions.push(...newCategories);
    }

    private addElemToClickedList(elem: Snap.Element, selectedObjectRefs: any[], addToAlreadySelected: boolean) {
        if (this.clickedSnapElements.type) {
            if (elem.hasClass(this.clickedSnapElements.type)) {
                if (addToAlreadySelected && this.alreadySelected(elem, selectedObjectRefs)) {
                    this.removeSelection(elem);
                } else {
                    this.addClassToElementAndItsChildren(elem);
                    this.clickedSnapElements.elements.push(elem);
                }
            }
        } else {
            this.clickedSnapElements.type = WindowParams.getSnapElemType(elem);
            this.addClassToElementAndItsChildren(elem);
            this.clickedSnapElements.elements.push(elem);
        }
    }

    private addClassToElementAndItsChildren(elem: Snap.Element) {
        elem.addClass(WindowDesignerComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        for (let child of elem.children()) {
            child.addClass(WindowDesignerComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        }
    }

    ngOnInit() {
        // Snap uses timeouts internally to invalidate caches
        // patch it to run outside angular instead of wrapping every single snap api use with zone
        (Snap as any).setTimeout = (handler: (...args: any[]) => void, timeout: number) =>
            this.zone.runOutsideAngular(() => setTimeout(handler, timeout));
        this.hotkeyService.add(this.enterHotkey);
        this.svg = Snap(this.svgElement.nativeElement);
        this.svg.click(() => {
            this.resetSelectedElements();
            this.windowElementSelected.emit({data: this.clickedSnapElements});
        });
        let positionId: any = 'new';
        let offerId = undefined;
        this.route.params.forEach((params: Params) => {
            positionId = params['positionId'];
            offerId = params['offerId'];
        });

        // in redrawPreviewMode we need no more work to be done, we can terminate ngOnInit
        if (this.redrawPreviewMode) {
            return;
        }

        let newPosition = positionId === 'new';
        forkJoin({
            loadData: this.catalogLoaded,
            catalogConfiguration: this.loadCatalogConfiguration(),
            businessTypes: newPosition ? this.businessTypeService.getTypesForAddDialog() : of([]),
        }).subscribe({
            next: data => {
                this.catalogConfiguration = data.catalogConfiguration;
                this.businessTypes = data.businessTypes;
                let loadData: WindowDesignerComponentInitData = data.loadData;
                this.setCatalogData(loadData);
                this.offer = loadData.offer;
                this.offerPosition = loadData.position;
                this.subwindowTypes = loadData.subwindowTypes;
                this.customTranslations = loadData.customTranslations;
                this.configurableAddonPositions = loadData.configurableAddonPositions
                    .filter(child => child.type === PositionType.CONFIGURABLE_ADDON || child.type === PositionType.CONFIG_SYSTEM)
                    .map(configurableAddonPosition => {
                        let configurableAddon: ConfigurableAddon = JSON.parse(configurableAddonPosition.data);
                        configurableAddon.configurableAddonDefinitionType = configurableAddonPosition.configurableAddonDefinitionType;
                        return new ConfigurableAddonPositionModel(configurableAddonPosition, configurableAddon);
                    });
                let addonAutoOptionGrouper = (aggr, addon) => {
                    aggr[addon.id] = addon.autoOption;
                    return aggr;
                };
                forkJoin(_.chain(this.configurableAddonPositions)
                    .filter(pos => !pos.deprecated)
                    .map(pos => pos.configurableAddon.definitionId)
                    .uniq()
                    .map(configSystemId => this.addonCategoryGroupService.getGroupsForConfigDeep(configSystemId, null, true))
                    .value()
                ).subscribe(categoryGroups => {
                    this.categoriesWithAutoOptions = _.chain(categoryGroups)
                        .flatten()
                        .map(group => group.categories)
                        .flatten()
                        .filter(category => category.hasAutoOption)
                        .map(category => ({symbol: category.symbol, addons: category.addons.reduce(addonAutoOptionGrouper, {})}))
                        .value();
                });
                this.glazingPackagePositions = loadData.configurableAddonPositions
                    .filter(child => child.type === PositionType.SYSTEM)
                    .map(child => new GlazingPackagePositionModel(child, JSON.parse(child.data)));
                if (newPosition) {
                    this.prepareTabsData();
                    this.guides = new Guides(this);
                    this.setupWindowSystemsAndTypesDialog();
                } else if (!this.sidebarOnlyMode) {
                    this.setDrawingData(loadData.drawingData);
                    this.guides = new Guides(this);
                    this.designerInitialized.emit(false);
                    this.setCommonValues();
                    this.prepareTabsData();
                    this.windowSystemDrawingToolsEnabler = this.getWindowSystem().windowSystemDrawingToolsEnabler;
                    this.emitCommonBusinessType(loadData.drawingData);
                    this.redrawWindow(true, true);
                    this.getBusinessTypes();
                } else /*this.sidebarOnlyMode*/ {
                    this.setDrawingData(loadData.drawingData);
                    this.prepareTabsData();
                    this.designerInitialized.emit(true); // global settings and model editor must set this to true to load catalog data
                }
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.info('WindowDesignerComponent ngOnInit observables complete');
            }
        });

        this.globalOnMouseMove = (event: MouseEvent) => {
            let point: Point = this.calculateSvgCoordsFromMouseEvent(event);
            this.mouseCoordsChange.emit(new Point(
                Math.abs(this.totalBoundingBox.minX) + point.x | 0, // tslint:disable-line:no-bitwise
                Math.abs(this.totalBoundingBox.minY) + point.y | 0  // tslint:disable-line:no-bitwise
            ));
            for (let hookName in this.mouseMoveHooks) {
                let hook = this.mouseMoveHooks[hookName];
                if (hook != undefined) {
                    hook(point, event.shiftKey);
                }
            }
        };
        this.zone.runOutsideAngular(() => this.svg.mousemove(this.globalOnMouseMove));
    }

    private loadCatalogConfiguration(): Observable<CatalogConfiguration> {
        return this.catalogConfiguration == null ?
            this.catalogConfigurationService.getForTarget(CatalogPropertyTarget.WINDOW_SYSTEMS) : of(null);
    }

    emitCommonBusinessType(drawingData: DrawingData) {
        if (drawingData.windows != null) {
            let usedBusinessTypes = _.uniq(drawingData.windows.map(w => w.typeCode));
            if (usedBusinessTypes.length === 1) {
                let commonBusinessType = usedBusinessTypes[0];
                this.drawingDataChanged.emit([{path: 'businessType', newValue: commonBusinessType}]);
                return;
            }
        }
        this.drawingDataChanged.emit([{path: 'businessType', newValue: undefined}]);
    }

    public setCommonValues(): void {
        let commonValues = {
            glazingBeadId: [],
            fillingType: [],
            fillingWidth: [],
            fillingId: [],
            decorativeFillingId: [],
            externalColorId: [],
            internalColorId: [],
            coreColorId: [],

            glazingGlassQuantity: [],
            glass1id: [],
            glass2id: [],
            glass3id: [],
            glass4id: [],
            frame1id: [],
            frame2id: [],
            frame3id: [],

            glazingPackageId: [],
            glazingCategoryId: [],
            glazingFrameCategoryId: [],

            grillId: [],
            grillColorId: [],
            mullionId: []
        };

        for (let window of this.data.windows) {
            for (let subWindow of window.subWindows) {
                for (let mullion of subWindow.mullions) {
                    commonValues.mullionId.push(mullion.id);
                }

                for (let area of subWindow.areasSpecification) {
                    commonValues.glazingBeadId.push(area.glazingBead.id);
                    commonValues.fillingType.push(area.filling.type);
                    commonValues.fillingWidth.push(area.filling.width);
                    commonValues.fillingId.push(area.filling.fillingId);
                    commonValues.decorativeFillingId.push(area.filling.decorativeFillingId);
                    commonValues.externalColorId.push(area.filling.externalColorId);
                    commonValues.internalColorId.push(area.filling.internalColorId);
                    commonValues.coreColorId.push(area.filling.coreColorId);

                    commonValues.glazingGlassQuantity.push(area.glazing.glazingGlassQuantity);
                    commonValues.glass1id.push(area.glazing.glass1id);
                    commonValues.glass2id.push(area.glazing.glass2id);
                    commonValues.glass3id.push(area.glazing.glass3id);
                    commonValues.glass4id.push(area.glazing.glass4id);
                    commonValues.frame1id.push(area.glazing.frame1id);
                    commonValues.frame2id.push(area.glazing.frame2id);
                    commonValues.frame3id.push(area.glazing.frame3id);

                    commonValues.glazingPackageId.push(area.glazingPackageId);
                    commonValues.glazingCategoryId.push(area.glazingCategoryId);
                    commonValues.glazingFrameCategoryId.push(area.glazingFrameCategoryId);

                    for (let grill of area.grills) {
                        commonValues.grillId.push(grill.id);
                        commonValues.grillColorId.push(grill.colorId);
                    }
                }
            }
        }

        let getUniqueValue = (values: any[]): any => {
            let uniqueValues = _.uniq(values);
            if (uniqueValues.length === 1) {
                return uniqueValues[0];
            }
            return undefined;
        };

        let commonData = new WindowCommonData();
        for (let property in commonValues) {
            commonData[property] = getUniqueValue(commonValues[property]);
        }

        this.offerContainsGrills = commonValues.grillId.length > 0;
        this.commonData.copyFrom(commonData);
    }

    public getSvgsForRedrawing(data: string, windowDesignerDataService: WindowDesignerDataServiceInterface): Observable<{
        thumbnail: string,
        technical: string,
        thumbnailRender: string,
        conjunction: string,
        regular: string
    }> {
        this.setDrawingData(JSON.parse(data));
        const colorId = this.data.specification.colorIdInternal != undefined
            ? this.data.specification.colorIdInternal
            : this.data.specification.colorIdCore;
        this.guides = new Guides(this);
        return forkJoin({
            windowSystem: windowDesignerDataService.getWindowSystem(this.data.windowSystemId),
            grills: windowDesignerDataService.getGrills(this.data.windowSystemId),
            glasses: windowDesignerDataService.getGlasses(this.data.windowSystemId),
            profiles: windowDesignerDataService.getProfiles(this.data.windowSystemId),
            decorativeFillings: windowDesignerDataService.getDecorativeFillings(this.data.windowSystemId),
            subwindowTypes: windowDesignerDataService.getSubwindowTypes(),
            customTranslations: windowDesignerDataService.getCustomTranslations(),
            color: windowDesignerDataService.getColorById(colorId)
        }).pipe(
            map(staticData => {
                // windowDesignerDataService.getWindowSystem actually returns a full WindowSystemDefinition,
                // only shows interface for sharing with window-designer api reasons
                this.windowSystem = Object.assign(new WindowSystemDefinition(), staticData.windowSystem);
                this.isTerrace = this.windowSystem.systemType === WindowSystemType.TERRACE.type;
                this.staticData.grills = staticData.grills.filter(grill => grill.active);
                this.staticData.angledGrills = this.staticData.grills.filter(grill => grill.angled);
                this.staticData.glasses = staticData.glasses;
                this.staticData.mullions = staticData.profiles.data.filter(
                    e => e.type === ProfileType.DECORATIVE_MULLION || e.type === ProfileType.CONSTRUCTIONAL_MULLION);
                this.staticData.decorativeFillings = staticData.decorativeFillings.data;
                this.staticData.colors = [staticData.color];
                this.profileCompositionDistances.prepareSystem(staticData.windowSystem);
                this.profileCompositionDistances.prepareProfileDistances(staticData.profiles.data, this.data.specification);
                this.subwindowTypes = new SubwindowTypes(staticData.subwindowTypes);
                this.customTranslations = staticData.customTranslations;
                this.guides.rebuildStructureGuides();
                MullionUtils.repositionMullionsAndGrills(this.data, this.profileCompositionDistances, null, null, true);

                let thumbnail = this.getSvg(PainterMode.THUMBNAIL);
                let technical = this.getSvg(PainterMode.TECHNICAL);
                let conjunction = this.getSvg(PainterMode.WEBSHOP);
                let render = this.getSvg(PainterMode.THUMBNAIL, true);
                let regular = this.getSvg(PainterMode.REGULAR);
                return {
                    thumbnail: thumbnail,
                    technical: technical,
                    thumbnailRender: render,
                    conjunction: conjunction,
                    regular: regular
                };
            }));
    }

    public saveSvgForRedrawing(bulkChangeId: number, offerPositionId: number, data: string,
                               windowDesignerDataService: WindowDesignerDataServiceInterface): Observable<void> {
        return this.getSvgsForRedrawing(data, windowDesignerDataService)
            .pipe(mergeMap(svgs => this.positionService.addPreviewToBulkChange(bulkChangeId, offerPositionId, svgs)));
    }

    public setAllGlassType(window: number, glassNumber: number, glassId: number, skipRevalidation = false): void {
        if (glassNumber) {
            this.setAttributeValueInAllAreas(window, 'glazing', 'glass' + glassNumber + 'id', glassId, true, skipRevalidation);
        } else {
            for (let i = 1; i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX + 1; i++) {
                this.setAttributeValueInAllAreas(window, 'glazing', 'glass' + i + 'id', glassId, true, skipRevalidation);
            }
        }
    }

    public setAllFrames(window: number, frameNumber: number, frameId: number, skipRevalidation = false): void {
        this.setAttributeValueInAllAreas(window, 'glazing', 'frame' + frameNumber + 'id', frameId, true, skipRevalidation);
    }

    public setAllFillingType(window: number, type: FillingType): void {
        this.setAllGlazingGlassQuantity(null, 0);
        this.setAttributeValueInAllAreas(window, 'filling', 'type', type, true);
    }

    public setAllGlazingGlassQuantity(window: number, quantity: number): void {
        // Reset selected glasses
        for (let i = 1; i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX + 1; i++) {
            this.setAttributeValueInAllAreas(window, 'glazing', 'glass' + i + 'id', undefined, false);
        }

        // Reset selected frames
        for (let i = 1; i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX; i++) {
            this.setAttributeValueInAllAreas(window, 'glazing', 'frame' + i + 'id', undefined, false);
        }

        // Set value
        this.setAttributeValueInAllAreas(window, 'glazing', 'glazingGlassQuantity', quantity, true);
    }

    public setAllDecorativeFillingId(window: number, id: number): void {
        this.setAttributeValueInAllAreas(window, 'filling', 'decorativeFillingId', id, true);
    }

    public setAllFillingId(window: number, id: number): void {
        this.setAttributeValueInAllAreas(window, 'filling', 'fillingId', id, true);
    }

    public setAllFillingWidth(window: number, width: number): void {
        this.setAttributeValueInAllAreas(window, 'filling', 'width', width, true);
    }

    public setAllFillingColor(window: number, color: number, colorType: FillingColorType): void {
        switch (colorType) {
            case FillingColorType.CORE:
                this.setAttributeValueInAllAreas(window, 'filling', 'coreColorId', color, true);
                break;
            case FillingColorType.INTERNAL:
                this.setAttributeValueInAllAreas(window, 'filling', 'internalColorId', color, true);
                break;
            case FillingColorType.EXTERNAL:
                this.setAttributeValueInAllAreas(window, 'filling', 'externalColorId', color, true);
                break;
        }
    }

    private setAttributeValueInAllAreas(window: number, attribute: string, subAttribute: string,
                                        value: any, redrawWindow: boolean, skipRevalidation = false): void {
        if (window != undefined) {
            for (let subWindow of this.data.windows[window].subWindows) {
                for (let area of subWindow.areasSpecification) {
                    area[attribute][subAttribute] = value;
                }
            }
        } else {
            for (let i = 0; i < this.data.windows.length; i++) {
                for (let subWindow of this.data.windows[i].subWindows) {
                    for (let area of subWindow.areasSpecification) {
                        area[attribute][subAttribute] = value;
                    }
                }
            }
        }

        if (redrawWindow && !this.sidebarOnlyMode) {
            this.debouncedRedrawWindow(skipRevalidation, !this.isPricingTabOpen(),
                !skipRevalidation ? () => this.revalidateRequiredFields.emit() : undefined);
        }
    }

    ngOnDestroy() {
        if (this.globalOnMouseMove != undefined) {
            this.svg.unmousemove(this.globalOnMouseMove);
        }
        this.hotkeyService.remove(this.enterHotkey);
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    ngDoCheck(): void {
        const changes = this.drawingDataDiffer.diff(this.data);
        if (changes.length > 0) {
            this.drawingDataChanged.emit(changes);
        }
    }

    enableCutMode() {
        this.mode = Tool.CUT;
        this.redrawWindow(true, true);
        this.resetPendingCut();
    }

    getSvg(mode: PainterMode, shaded = false): string {
        if (shaded && !ShadingCalculator.isShadingSupported(this.data)) {
            return null;
        }
        this.svg.clear();
        this.drawWindow([], this.isTerrace, mode, shaded);
        let style = this.generateStyleSheet();
        let styleNode = Snap.parse(`<defs><style type="text/css"><![CDATA[${style}]]></style>`);
        this.svg.append(Snap(styleNode.node));
        return new XMLSerializer().serializeToString(this.svg.node);
    }

    cancelMode(clearPendingData = true) {
        this.mode = Tool.SELECT;
        this.resetPendingCut();
        if (clearPendingData) {
            this.pendingGrillData = new PendingGrillData();
        }
        this.pendingGrillStage = PendingGrillStage.NONE;
        this.mouseMoveHooks = {};
        if (typeof this.pendingOperationLineHelper !== 'undefined') {
            this.pendingOperationLineHelper.remove();
        }
        this.pendingOperationLineHelper = undefined;
        this.redrawWindow(true, true);
    }

    exit() {
        this.goToPositionList();
    }

    public saveAndExit(): void {
        if (this.saveInProgress || this.showExitWithMessagesDialog || this.readOnlyMode) {
            return;
        }
        if (!this.profileCompositionDistances.validate(this.data)) {
            this.growls.error("OFFER.DRAWING.RESIZE.DISTANCES_NOT_SET");
            return;
        }
        if (!ConfigAddonValidator.validateConfigAddons(this.data, this.configurableAddonPositions,
            this.configurableAddonDefinitions, this.staticData.grills as GrillDto[])) {
            this.growls.error("OFFER.DRAWING.CONFIG_ADDONS_NOT_AVAILABLE_IN_AREA");
            return;
        }
        if (!this.windowSystem.active) {
            this.growls.error("OFFER.DRAWING.WINDOW_SYSTEM_NOT_AVAILABLE");
            return;
        }
        this.saveInProgress = true;
        this.cancelMode(false);
        let width = this.totalBoundingBox.maxX - this.totalBoundingBox.minX;
        let height = this.totalBoundingBox.maxY - this.totalBoundingBox.minY;
        if (this.data.glazingPackageForAreaId == undefined) {
            this.offerPosition.dimensions = width + "x" + height;
        } else {
            const area = this.data.windows[0].subWindows[0].areasSpecification[0];
            this.offerPosition.dimensions = `${area.realPackageWidth}x${area.realPackageHeight}`;
        }
        this.data.specification.ufData = UwUtils.shouldCalculateUw(this.data) ?
            UwUtils.getFlattenedWindowUfData(this.data, this.profileCompositionDistances, this.totalBoundingBox,
                this.staticData.mullions, this.isValidationDisabled()) : [];
        GrillHelper.normalizeSegmentGeneratedIds(this.data);
        this.mapAddonsToWindowAddons();
        const windowSystem = this.getWindowSystem();
        let preparedData = PricingUtils.enhanceForPricing(this.data, windowSystem, this.profileCompositionDistances);
        this.offerPosition.data = JSON.stringify(preparedData);
        this.offerPosition.windowSystemId = this.data.windowSystemId;

        ConfigurableAddonUtils.resizeAll(this.data, this.configurableAddonPositions, this.profileCompositionDistances,
            this.isValidationDisabled(), this.totalBoundingBox);
        ConfigurableAddonUtils.changeGlazingBeads(this.data, this.configurableAddonPositions);
        this.offerPosition.configurableAddons = this.configurableAddonPositions.map(item => {
            let configurableAddonPosition = item.position;
            configurableAddonPosition.data = JSON.stringify(item.configurableAddon);
            configurableAddonPosition.configSystemId = item.configurableAddon && item.configurableAddon.configData.configSystemId;
            return configurableAddonPosition;
        });
        this.pricingService.evaluate(false, preparedData, this.profileCompositionDistances, this.offerPosition.offerId,
            this.offerPosition.id, windowSystem, true, true, undefined, this.offerPosition.validationDisabled).subscribe({
            next: data => {
                const hasMessages = data.products.some(product => product.messages.length > 0) ||
                    data.validationMessages.length > 0;
                const hasErrors = Pricing.containsForValidation(data, MessageSeverity.ERROR) ||
                    Pricing.containsForValidation(data, MessageSeverity.BLOCKER);
                if (hasErrors || !hasMessages) {
                    this.saveItem();
                } else {
                    this.saveInProgress = false;
                    this.showExitWithMessagesDialog = true;
                    this.recentValidationMessages = data.validationMessages;
                    this.recentPricingProducts = data.products;
                }
            },
            error: error => {
                this.saveInProgress = false;
                this.errors.handle(error);
            }
        });
    }

    confirmExitWithMessages(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.saveItem();
    }

    closeExitWithMessagesDialog() {
        this.saveInProgress = false;
        this.showExitWithMessagesDialog = false;
    }

    saveItem() {
        let thumbnail = this.getSvg(PainterMode.THUMBNAIL);
        let technical = this.getSvg(PainterMode.TECHNICAL);
        let conjunction = this.getSvg(PainterMode.WEBSHOP);
        let render = this.getSvg(PainterMode.THUMBNAIL, true);
        let regular = this.getSvg(PainterMode.REGULAR);
        this.redrawWindow(true, true);
        let saveObservable = this.positionService.saveItem(this.offerPosition as Position, {
            thumbnail: thumbnail,
            technical: technical,
            thumbnailRender: render,
            conjunction: conjunction,
            regular: regular
        });
        const glazingPackagePositions = this.glazingPackagePositions.filter(gpp => gpp.position.id == undefined);
        if (glazingPackagePositions.length !== 0) {
            this.previewVisible = false;
            const catalogDataCache = new CachingWindowDesignerDataService(this.windowDesignerDataService);
            for (const gp of glazingPackagePositions) {
                saveObservable = saveObservable.pipe(mergeMap(newOfferPositionId => {
                    const area = gp.window.windows[0].subWindows[0].areasSpecification[0];
                    gp.position.windowSystemId = gp.window.windowSystemId;
                    gp.position.dimensions = `${area.realPackageWidth}x${area.realPackageHeight}`;
                    gp.position.otherInfo = `Pakiet szybowy o wymiarze ${area.realPackageWidth}x${area.realPackageHeight}`;
                    (gp.position as Position).parentOfferPositionId = newOfferPositionId;
                    return forkJoin({
                        svgs: this.getSvgsForRedrawing(gp.position.data, catalogDataCache),
                        newOfferPositionId: of(newOfferPositionId)
                    }).pipe(mergeMap(data => this.positionService.saveItem(gp.position as Position, data.svgs)
                        .pipe(map(() => data.newOfferPositionId))));
                }));
            }
        }
        saveObservable.subscribe({
            next: newOfferPositionId => {
                console.log("this.positionService.saveItem success!");
                this.growls.clearStickies();
                let navigateObservable = of(true);
                if (this.offerPosition.id === undefined) {
                    this.offerPosition.id = newOfferPositionId;
                    navigateObservable = navigateObservable.pipe(mergeMap(() =>
                        from(this.router.navigate([`../../${this.offerPosition.id}/windowdesigner`],
                            {relativeTo: this.route, replaceUrl: true}))));
                }
                navigateObservable.subscribe({
                    complete: () => {
                        this.saveInProgress = false;
                        this.goToPositionList();
                    }
                });
            },
            error: () => {
                this.saveInProgress = false;
            }
        });
    }

    private goToPositionList() {
        this.growls.clearStickies();
        const matrixParams = {
            ..._.omit(this.route.parent.snapshot.params, ['offerId']),
            lastSelection: this.offerPosition.id
        };
        this.router.navigate(['../..', matrixParams], {relativeTo: this.route});
    }

    public convertEnumToArray(_enum: any, withoutElement?: any): string[] {
        return Object.keys(_enum).filter(element => !withoutElement || withoutElement !== element);
    }

    moveSelectedTabIndicator(index: number): void {
        this.recalculateSelectedTabIndicatorPosition(index);
        this.selectedTabIndicator.display = true;
    }

    private recalculateSelectedTabIndicatorPosition(index: number) {
        let points = this.windowTabsData[index - 1].windowData.points;
        let center = this.getCenterPointForWindow(points);
        this.selectedTabIndicator.x = center.x;
        this.selectedTabIndicator.y = center.y;
    }

    initPendingGrill(mode: GrillType): void {
        this.clearPendingGrillErrors();
        this.pendingGrillData.grill = GrillHelper.newGrill(mode);
        this.setGrillMode(mode);
    }

    setGrillMode(mode: GrillType): void {
        switch (mode) {
            case GrillType.MULLION:
                this.mode = Tool.MULLION;
                break;
            case GrillType.LINE_GRILL:
                this.mode = Tool.GRILL;
                break;
            case GrillType.GRID_CROSS_ANGLED:
            case GrillType.GRID_CROSS_SIMPLE:
            case GrillType.GRID_RHOMBUS:
            case GrillType.GRID_STANDARD:
                this.mode = Tool.GRILL_TEMPLATE;
                break;
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
    }

    enableHandleMode(direction: HandleDirection): void {
        this.mode = Tool.HANDLE;
        this.pendingHandleDirection = direction;
        this.redrawWindow(true, true);
    }

    alignmentTool(event: MouseEvent, alignToGlasses: boolean, forceVertical: boolean): void {
        if (this.mode === Tool.SELECT && this.data && this.data.windows &&
            this.profileCompositionDistances.validate(this.data)) {
            this.saveStepInHistory(this.data);
            try {
                let subwindows = [];
                let selection = this.clickedSnapElements;
                if (selection.type === WindowParams.FRAME_ELEM && selection.elements.length > 0) {
                    selection.elements.forEach(el => subwindows.push(el.data(DataKeys.SUBWINDOW)));
                } else {
                    this.data.windows.forEach(w => subwindows = subwindows.concat.apply(subwindows, w.subWindows));
                }
                let operationResult = AlignmentTool.use(alignToGlasses, subwindows, this.guides, this.data.cuts,
                    this.totalBoundingBox, this.profileCompositionDistances, forceVertical, this.data.shape);
                this.processNewCreatedChanges(operationResult);
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
            }
            this.redrawWindow(false, false);
        }
    }

    mirrorTool(callback: () => void): void {
        this.saveStepInHistory(this.data);
        try {
            let oldData = DrawingData.copy(this.data);
            let operationResult = MirrorTool.use(this.data, this.profileCompositionDistances,
                this.isValidationDisabled(), this.staticData.decorativeFillings);

            MirrorTool.flipConfigAddons(this.configurableAddonPositions, this.categoriesWithAutoOptions);
            this.recalculateTotalBoundingBox();
            operationResult.merge(
                AlignmentTool.autoAlign(this.getWindowSystem(), this.profileCompositionDistances, this.data, oldData));
            this.guides.rebuildStructureGuides();
            this.processNewCreatedChanges(operationResult);
            this.prepareTabsData();
            callback();
        } catch (e) {
            this.errors.handleFE(e);
            this.cancelLast();
        }
        this.redrawWindow(false, false);
    }

    extendHorizontalGrills() {
        if (this.mode === Tool.SELECT && this.data && this.data.windows) {
            this.saveStepInHistory(this.data);
            try {
                ExtendGrillsTool.use(this.data, this.totalBoundingBox, this.profileCompositionDistances);
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
            }
            this.redrawWindow(false, false);
        }
    }

    trimTool() {
        if (this.mode === Tool.SELECT && this.data && this.data.windows && this.data.shape.type === WindowShapeType.RECTANGULAR) {
            this.saveStepInHistory(this.data);
            try {
                let oldSubwindowsCount = OfferComponentsCounter.singleDataSubwindowsCount(this.data);
                let neededCuts = TrimTool.use(this.data, this.getWindowSystem());
                this.addNewCuts(neededCuts);
                let newSubwindowsCount = OfferComponentsCounter.singleDataSubwindowsCount(this.data);
                if (oldSubwindowsCount !== newSubwindowsCount) {
                    TrimTool.throwTrimError();
                }
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
            }
            this.redrawWindow(false, false);
        }
    }

    // NOTE: Put here all the stuff that needs to be done after reloading the DrawingData
    setDrawingData(newDrawingData: DrawingData) {
        super.setDrawingData(newDrawingData);
        this.loadWindowAddons();
    }

    setConfigAddons(newConfigAddons: ConfigurableAddonPositionModel[]) {
        this.configurableAddonPositions = [];
        newConfigAddons.forEach(model => this.configurableAddonPositions.push(
            new ConfigurableAddonPositionModel(model.position, model.configurableAddon)));
    }

    cancelAddingGrill() {
        this.mode = Tool.SELECT;
    }

    setPendingGrillOrMullionData(): void {
        MullionUtils.setGrillOrMullionData(this.pendingGrillData.grill, this.staticData, this.profileCompositionDistances);
    }

    finalizeAddingGrill(): boolean {
        if (this.isErrorsPresent()) {
            return false;
        }
        let success = false;
        switch (this.pendingGrillData.grill.type) {
            case GrillType.MULLION:
                success = true;
                break;
            case GrillType.LINE_GRILL:
                this.tryToFitGrillInDistanceFrames(this.pendingGrillData.area, this.pendingGrillData.grill.id);
                success = true;
                break;
            case GrillType.GRID_STANDARD:
            case GrillType.GRID_CROSS_SIMPLE:
            case GrillType.GRID_CROSS_ANGLED:
                success = this.finalizeAddingGrillGrid();
                break;
            case GrillType.GRID_RHOMBUS:
                GrillHelper.setSizeForRhombusGrill(this.pendingGrillData.grill);
                success = this.finalizeAddingGrillGrid();
                break;
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
        this.pendingGrillData = new PendingGrillData(this.pendingGrillData);
        this.setPendingGrillOrMullionData();
        return success;
    }

    finalizeAddingGrillGrid(): boolean {
        this.saveStepInHistory();
        try {
            PositionsHelper.winglessModeProcessing(this.pendingGrillData.grill, this.pendingGrillData.area,
                this.pendingGrillData.subWindow, this.data.cuts,
                this.totalBoundingBox, this.profileCompositionDistances);
            this.pendingGrillData.area.grills.push(this.pendingGrillData.grill);
            PositionsHelper.updateAbsolutePositions(this.pendingGrillData.subWindow,
                WindowCalculator.getTotalGlazingBeads(this.pendingGrillData.subWindow, this.data.cuts,
                    this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled()),
                this.profileCompositionDistances);
            this.pendingGrillStage = PendingGrillStage.NONE;
            this.tryToFitGrillInDistanceFrames(this.pendingGrillData.area, this.pendingGrillData.grill.id);
            this.redrawWindow(!this.isPricingTabOpen(), !this.isPricingTabOpen());
            return true;
        } catch (e) {
            this.errors.handleFE(e);
            this.cancelLast();
        }
        return false;
    }

    canChangeWindowShape(): boolean {
        return (!WindowShape.isRectangular(this.data.shape) || this.data.cuts.length === 0)
            && !this.isAnyGrillPresent() && !WindowShape.isEllipse(this.data.shape);
    }

    changeWindowShape(newWindowShapeType: WindowShapeType): void {
        if (!this.canChangeWindowShape()) {
            let err = new Error("IllegalStateException: changeWindowShape invoked when the shape cannot be changed");
            err.name = ErrorNames.ILLEGAL_OPERATION_INVOKED;
            throw err;
        }
        let oldDataCopy = DrawingData.copy(this.data);
        this.saveStepInHistory();
        this.onWindowShapeChange(newWindowShapeType);
        AreaUtils.recalculateAreaSizes(this.data.windows, this.data.cuts, this.totalBoundingBox, this.profileCompositionDistances,
            this.isValidationDisabled());
        let operationResult = DrawingDataChangeHelper.processRequiredChanges(oldDataCopy, this.data);
        this.processNewCreatedChanges(operationResult);
        this.redrawWindow(false, false);
    }

    public isErrorsPresent(): boolean {
        let errors = GrillSegmentGenerator.validate(this.pendingGrillData.grill, this.pendingGrillData.svgTarget);
        this.clearPendingGrillErrors();
        for (let key in errors) {
            this.pendingGrillValidationErrors[key] = errors[key];
        }
        let errorsPresent = Object.keys(this.pendingGrillValidationErrors)
            .filter(key => this.pendingGrillValidationErrors[key] != undefined).length > 0;
        if (this.mode === Tool.GRILL || this.mode === Tool.MULLION) {
            this.pendingGrillStage =
                errorsPresent ? PendingGrillStage.SELECT_PARAMETERS : PendingGrillStage.SELECT_AREA;
        }
        return errorsPresent;
    }

    private clearPendingGrillErrors() {
        for (let key in this.pendingGrillValidationErrors) {
            this.pendingGrillValidationErrors[key] = undefined;
        }
    }

    isAnyGrillPresent() {
        let hasGrill = (window: WindowData) => {
            for (let subWindow of window.subWindows) {
                if (subWindow.mullions.length > 0 || GrillHelper.subwindowContainsGrills(subWindow)) {
                    return true;
                }
            }
            return false;
        };

        return this.data && this.data.windows && this.data.windows.some(hasGrill);
    }

    isAnyHorizontalGrillPresent() {
        let hasGrill = (window: WindowData) => {
            for (let subWindow of window.subWindows) {
                if (subWindow.mullions.length > 0) {
                    return true;
                }
                for (let as of subWindow.areasSpecification) {
                    if (GrillHelper.areaHasGrills(as)) {
                        return true;
                    }
                }
            }
            return false;
        };

        return this.data && this.data.windows && this.data.windows.some(hasGrill);
    }

    private unfinishedCutsNotPresent(): boolean {
        return this.data && this.data.cuts !== undefined;
    }

    public canUseGrillTool(): boolean {
        return this.unfinishedCutsNotPresent() && this.commonData.fillingType !== FillingType.NO_FILLING;
    }

    public canUseMullionTool(): boolean {
        return this.unfinishedCutsNotPresent();
    }

    public canUseCutTool(): boolean {
        return this.data.windowSystemId != undefined && WindowShape.isRectangular(this.data.shape)
            && !this.isAnyGrillPresent();
    }

    public canUseGrillExtendTool(): boolean {
        return this.data.windowSystemId != undefined && GrillHelper.constructionContainsHorizontalGrills(this.data);
    }

    public canUseTrimTool(): boolean {
        return this.canUseCutTool() && TrimTool.canUse(this.data, this.getWindowSystem());
    }

    saveStep(drawingData: DrawingData, commonData: WindowCommonData, configurableAddonPositions: ConfigurableAddonPositionModel[]) {
        this.historyService.saveStep(drawingData, commonData, configurableAddonPositions);
    }

    saveStepInHistory(data?: DrawingData): void {
        if (!this.isSavingHistory || this.readOnlyMode) {
            return;
        }

        if (data === undefined) {
            data = this.data;
        }

        this.debounceSaveStep(data, this.commonData, this.configurableAddonPositions);

        this.unsavedChanges = true;
    }

    /**
     * Moves history back.
     */
    undoLast(callback?) {
        if (!this.historyService.canUndo()) {
            console.info("undoLast(): Cannot undo last operation");
            return;
        }
        let oldWindowCount = this.data.windows.length;

        this.historyService.undoLast(this);

        this.undoRedoCancelLast();
        if (this.data.windows.length !== oldWindowCount) {
            this.newWindowAdded.emit();
        }
        this.redrawWindow(true, true, callback);
    }

    /**
     * Removes last history step.
     */
    cancelLast(): void {
        if (!this.historyService.canCancel()) {
            console.info("cancelLast(): Cannot cancel last operation");
            return;
        }
        let oldWindowCount = this.data.windows.length;

        this.historyService.cancelLast(this);

        this.undoRedoCancelLast();
        if (this.data.windows.length !== oldWindowCount) {
            this.newWindowAdded.emit();
        }
    }

    /**
     * Moves history forward.
     */
    redoLast(callback?: () => void) {
        if (!this.historyService.canRedo()) {
            console.info("redoLast(): Cannot redo last operation");
            return;
        }
        let oldWindowCount = this.data.windows.length;

        this.historyService.redoLast(this);

        this.undoRedoCancelLast();
        if (this.data.windows.length !== oldWindowCount) {
            this.newWindowAdded.emit();
        }
        this.redrawWindow(false, false, callback);
        if (oldWindowCount === 0) {
            this.designerInitialized.emit(true);
        }
    }

    private undoRedoCancelLast() {
        this.selectedTabIndicator.display = false;
        if (this.data.windows.length === 0) {
            this.setupWindowSystemsAndTypesDialog();
            this.unsavedChanges = false;
        }
        this.prepareTabsData();
        ConfigurableAddonUtils.resizeAll(this.data, this.configurableAddonPositions, this.profileCompositionDistances,
            this.isValidationDisabled());
        ConfigurableAddonUtils.changeGlazingBeads(this.data, this.configurableAddonPositions);
    }

    isHistoryPresent() {
        return this.historyService.canUndo();
    }

    isFutureHistoryPresent() {
        return this.historyService.canRedo();
    }

    canUndo() {
        return (this.mode === Tool.SELECT || this.mode === Tool.MULLION || this.mode === Tool.GRILL_TEMPLATE || this.mode === Tool.GRILL) &&
            this.isHistoryPresent();
    }

    canRedo() {
        return this.mode === Tool.SELECT && this.isFutureHistoryPresent();
    }

    public getData() {
        this.windowTabsData = [];
        this.selectedTabIndicator = {
            display: false,
            x: 0,
            y: 0
        };
        this.totalBoundingBox = {
            minX: 0,
            maxX: 0,
            minY: 0,
            maxY: 0
        };
        this.guidesDialogData = new GuidesDialogData();
        this.historyService.clearHistory();
        this.setDrawingData(this.getDefaultDrawingData());
        this.resetPendingCut();
        this.pendingGrillData = new PendingGrillData();
    }

    private getDefaultDrawingData(): DrawingData {
        return new DrawingData();
    }

    private resetPendingCut(): void {
        this.pendingCut = new LineCutData([], undefined);
    }

    public runWindowsArrayGarbageCollector(): OperationResult {
        let deletedConfigurableAddonIds = [];
        // if all X or Y values are equal, then area is invalid
        let isWindowAreaInvalid = (points: number[]) => {
            let allXequal = true;
            let allYequal = true;
            for (let i = 3; i < points.length; i += 2) {
                if (points[i - 3] !== points[i - 1]) {
                    allXequal = false;
                }
                if (points[i - 2] !== points[i]) {
                    allYequal = false;
                }
            }
            return allXequal || allYequal;
        };
        for (let wIdx = 0; wIdx < this.data.windows.length; ++wIdx) {
            let window = this.data.windows[wIdx];
            for (let subIdx = 0; subIdx < window.subWindows.length; subIdx++) {
                let subWindow = window.subWindows[subIdx];
                let afterCuts = CutsUtil.applyCuts(subWindow.points, this.data.cuts, 0);
                if (!afterCuts || afterCuts.length < 6 || isWindowAreaInvalid(afterCuts)) {
                    deletedConfigurableAddonIds.push(...window.subWindows[subIdx].configurableAddonIds);
                    deletedConfigurableAddonIds.push(..._.flatten(
                        window.subWindows[subIdx].areasSpecification.map(area => area.configurableAddonIds)));
                    window.subWindows.splice(subIdx, 1);
                    subIdx--;
                }
            }
            if (window.subWindows.length === 0) {
                this.data.windows.splice(wIdx, 1);
                if (this.recentPricingProducts != undefined) {
                    this.recentPricingProducts.splice(wIdx, 1);
                }
                wIdx--;
            }
        }
        if (this.data.windows.length === 0) {
            deletedConfigurableAddonIds.push(...this.data.configurableAddonIds);
            this.data.configurableAddonIds = [];
            this.cancelMode();
            this.data.cuts = [];
        }
        this.prepareTabsData();
        return new OperationResult(deletedConfigurableAddonIds, deletedConfigurableAddonIds.length !== 0);
    }

    public redrawWindow(omitValidation, omitPricing, callback?: () => void): Observable<void> {
        omitValidation = this.data.windows.length === 0 ? true : omitValidation;

        if (this.drawingDataDifferOnRedraw.diff(this.data).length > 0) {
            AddonDefaultQuantityCalculator.recalculateAll(this.data, this.subwindowTypes, this.addedWindowAddons, this.staticData);
            AreaUtils.recalculateAreasNumbers(this.data);
            AreaUtils.setTotalPerimeter(this.data);
            AreaUtils.setSubwindowShapes(this.data);
            AreaUtils.setGlazingsESG(this.data, this.staticData.glasses);
        }

        let selectedObjects: any[];
        if (!omitValidation && !this.sidebarOnlyMode && this.profileCompositionDistances.validate(this.data)) {
            this.blockUiController.block(WindowDesignerComponent.OPERATION_IN_PROGRESS);
            this.mapAddonsToWindowAddons();
            const redrawResult = new Observable<void>(subscriber => {
                return this.pricingService.evaluate(this.readOnlyMode, this.data, this.profileCompositionDistances,
                    this.offerPosition.offerId, this.offerPosition.id, this.getWindowSystem(), omitPricing, false, undefined,
                    this.offerPosition.validationDisabled).subscribe({
                    next: pricing => {
                        const hasBlockers = Pricing.containsBlockers(pricing);
                        const willUndo = hasBlockers && this.requiredFieldFilled &&
                            (this.pricing == undefined || !Pricing.containsBlockers(this.pricing));
                        this.handleBlockersIfPresent(pricing, willUndo);
                        selectedObjects = this.getSelectedObjectReferences();
                        this.svg.clear();
                        this.drawWindow(selectedObjects, this.isTerrace);
                        if (!willUndo) {
                            this.onPricingResults(pricing, omitPricing);
                        }
                        if (!hasBlockers) {
                            if (callback) {
                                callback();
                            }
                            subscriber.next();
                            subscriber.complete();
                        } else {
                            subscriber.error(Pricing.collectProductMessagesofType(pricing, MessageSeverity.BLOCKER));
                        }
                        this.blockUiController.unblock(WindowDesignerComponent.OPERATION_IN_PROGRESS);
                    },
                    error: error => {
                        this.blockUiController.unblock(WindowDesignerComponent.OPERATION_IN_PROGRESS);
                        this.errors.handle(error);
                    }
                });
            }).pipe(
                share()
            );
            redrawResult.subscribe({
                // ignore error - already handled by pricingService.evaluate
                error: () => {
                }
            });
            return redrawResult;
        }
        selectedObjects = this.getSelectedObjectReferences();
        this.svg.clear();
        if (this.pricing == undefined || !Pricing.containsBlockers(this.pricing)) {
            this.drawWindow(selectedObjects, this.isTerrace);
            if (callback) {
                callback();
            }
        }
        return of(undefined);
    }

    private handleBlockersIfPresent(pricing: Pricing, undoOnBlocker: boolean): void {
        if (Pricing.containsBlockers(pricing)) {
            Pricing.collectProductMessagesofType(pricing, MessageSeverity.BLOCKER)
                .forEach(blocker => this.growls.blocker(blocker.messageCode, blocker.messageCodeParams));
            if (undoOnBlocker) {
                this.cancelLast();
            }
        }
    }

    private onPricingResults(pricing: Pricing, omitPricing: boolean): void {
        if (this.requiredFieldFilled) {
            this.popGrowlsIfNewMessagesFromPricingArrive(pricing);
            this.updateValidationPricingStatuses(pricing, omitPricing);
            this.recentValidationMessages = pricing.validationMessages;
            this.recentPricingProducts = pricing.products;
        }
        this.pricing = pricing;
    }

    public updateValidationPricingStatuses(pricing: Pricing, omitPricing: boolean) {
        ResponseStatusHelper.updateValidationStatuses(this.validationStatus, pricing);
        // if we want to price construction or currently open tab is pricing, we need to set pricing statuses as well
        if (!omitPricing || this.currentlySelectedTab === this.windowTabsData.length + 1 /*Pricing tab*/) {
            ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricing);
        }
    }

    private popGrowlsIfNewMessagesFromPricingArrive(pricing: Pricing): void {
        let msgsFromCurrentPricing: MessagesFromCurrentPricing = new MessagesFromCurrentPricing();
        // messages from products
        for (let productNo = 0; productNo < pricing.products.length; productNo++) {
            let product = pricing.products[productNo];
            let newProductMessages = product.messages;

            if (this.recentPricingProducts && this.recentPricingProducts.length > productNo) {
                let oldProductMessages = this.recentPricingProducts[productNo].messages;
                newProductMessages.forEach(newMessage => {
                    if (!this.messagesArrayContainsMessage(oldProductMessages, newMessage)) {
                        msgsFromCurrentPricing.list.push(newMessage);
                    }
                });
            } else {
                newProductMessages.forEach(msg => msgsFromCurrentPricing.list.push(msg));
            }
        }

        // general messages concerning the whole drawing data
        pricing.validationMessages.forEach(msg => {
            if (!this.messagesArrayContainsMessage(this.recentValidationMessages, msg)) {
                msgsFromCurrentPricing.list.push(msg);
            }
        });

        if (msgsFromCurrentPricing.containsMessageOfType(MessageSeverity.BLOCKER)) {
            this.growls.error('OFFER.DRAWING.PRICING.BLOCKER');
        }

        if (msgsFromCurrentPricing.containsMessageOfType(MessageSeverity.ERROR)) {
            this.growls.error('OFFER.DRAWING.PRICING.ERROR');
        }
        if (msgsFromCurrentPricing.containsMessageOfType(MessageSeverity.WARNING)) {
            this.growls.warning('OFFER.DRAWING.PRICING.WARNING');
        }
        if (msgsFromCurrentPricing.containsMessageOfType(MessageSeverity.INFO)) {
            this.growls.info('OFFER.DRAWING.PRICING.INFO');
        }
    }

    private messagesArrayContainsMessage(list: PositionMessage[], entity: PositionMessage): boolean {
        if (list) {
            for (let listMsg of list) {
                if (PositionMessage.equals(entity, listMsg)) {
                    return true;
                }
            }
        }
        return false;
    }

    displayChangeSizeEvent() {
        const resizeNewSizeElement = document.getElementById('resizeNewSize');
        if (resizeNewSizeElement != undefined) {
            resizeNewSizeElement.blur(); // need this or we will lose validation error on blur when submitted using enter
        }
        this.guidesDialogData.saveDisabled = true;
        this.guides.changeSize(this.getWindowSystem()).pipe(
            finalize(() => {
                this.guidesDialogData.saveDisabled = false;
            }))
            .subscribe({
                next: result => {
                    this.processNewCreatedChanges(result);
                    this.guidesDialogData.displayDialog = false;
                },
                error: error => {
                    this.windowSizeValidationError = error;
                    console.warn(error);
                }
            });
    }

    cancelGuideEditing() {
        this.clearNewWindowErrors();
        this.guidesDialogData.displayDialog = false;
        this.guides.cleanupEditing();
    }

    calculateSvgCoordsFromMouseEvent(event: MouseEvent): Point {
        let svg = this.svg.node as unknown as SVGSVGElement;
        let point = svg.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(svg.getScreenCTM().inverse());
        return new Point(point.x, point.y);
    }

    private getAxisAlignmentWhenKeyPressed(lineBeginning: Point, actualCoords: Point, keyPressed: boolean,
                                           angleStepDegrees?: number) {
        let lineEnd = new Point(actualCoords.x, actualCoords.y);
        if (keyPressed) {
            let x = actualCoords.x - lineBeginning.x;
            let y = actualCoords.y - lineBeginning.y;
            if (angleStepDegrees == undefined) {
                angleStepDegrees = 15;
            }
            const originalAngle = Math.atan2(y, x) / Math.PI * 180;
            let angle = FloatOps.round(originalAngle / angleStepDegrees) * angleStepDegrees;
            if (!(angle % 90)) {
                if (angle % 180) { // 90, 270
                    lineEnd.x = lineBeginning.x;
                    lineEnd.y = actualCoords.y;
                } else {
                    lineEnd.x = actualCoords.x;
                    lineEnd.y = lineBeginning.y;
                }
            } else if (FloatOps.ne(angle, originalAngle)) {
                /*
                 -----
                 |2|3|
                 -----
                 |1|0|
                 -----
                 */
                let oddQuarter = Math.floor(angle / 90) % 2 !== 0;
                // angle>original in quarters 0&2 or angle<original in quarters 1&3
                if (FloatOps.gt(angle, originalAngle) !== oddQuarter) {
                    lineEnd.x = actualCoords.x;
                    lineEnd.y = lineBeginning.y + x * Math.tan(angle * Math.PI / 180);
                } else {
                    lineEnd.x = lineBeginning.x + y / Math.tan(angle * Math.PI / 180);
                    lineEnd.y = actualCoords.y;
                }
            }
        }
        return lineEnd;
    }

    onFrameClickCutHandler(event: MouseEvent): void {
        if (this.mode !== Tool.CUT) {
            return;
        }
        let clicked = this.calculateSvgCoordsFromMouseEvent(event);
        let alignToAxis = !event.shiftKey;

        if (this.pendingCut.points.length === 2) {
            let startPoint = new Point(this.pendingCut.points[0], this.pendingCut.points[1]);
            clicked = this.getAxisAlignmentWhenKeyPressed(startPoint, clicked, alignToAxis);
        }
        let point = {x: 0, y: 0, length: 0, distance: 0};

        let target: Snap.Element = Snap(event.target);
        let isPointEqualsToPendingCutFirstPoint = (p) => (this.pendingCut.points.length === 2)
            &&
            (p.x === this.pendingCut.points[0]) && (p.y === this.pendingCut.points[1]);
        if ((event.target as Element).getAttribute("guidancePoints") === "true") {
            point.x = FloatOps.round(+(event.target as Element).getAttribute("cx"));
            point.y = FloatOps.round(+(event.target as Element).getAttribute("cy"));
            if (isPointEqualsToPendingCutFirstPoint(point)) {
                return;
            }
        } else {
            point = Snap.closestPoint(target, clicked.x, clicked.y);
            point.x = FloatOps.round(point.x);
            point.y = FloatOps.round(point.y);
            if (isPointEqualsToPendingCutFirstPoint(point)) {
                return;
            } else if (alignToAxis && this.pendingCut.points.length === 2) {          // when axis alignment is on (SHIFT not pressed)
                let path = target.node as unknown as SVGPathElement;
                let getXY = (index: number) => {
                    if (index === path.pathSegList.numberOfItems - 1) {
                        // SVGPathSeg.PATHSEG_CLOSEPATH - doesn't have own x and y, goes back to starting point
                        index = 0;
                    } else if (index === -1) {
                        // when current index is 0, previous index needs to be last segment with x and y
                        index = path.pathSegList.numberOfItems - 2;
                    }
                    let seg = path.pathSegList.getItem(index) as SVGPathSegMovetoAbs | SVGPathSegLinetoAbs;
                    return [seg.x, seg.y];
                };
                let segIndex = path.getPathSegAtLength(point.length);
                let frameLine = [...getXY(segIndex), ...getXY(segIndex - 1)];

                let intersectionPoint = DrawingUtil.lineIntersection(
                    frameLine[0], frameLine[1], frameLine[2], frameLine[3],                         // frame line
                    this.pendingCut.points[0], this.pendingCut.points[1], clicked.x, clicked.y      // drawn cut line
                );
                if (intersectionPoint.intersects) {
                    point.x = FloatOps.round(intersectionPoint.x);
                    point.y = FloatOps.round(intersectionPoint.y);
                }
            }
        }

        if (this.pendingCut.points.length === 0) {
            this.pendingCut.points.push(point.x, point.y);
            this.pendingOperationLineHelper = this.svg.line(point.x, point.y, clicked.x, clicked.y);
            this.pendingOperationLineHelper.attr({
                stroke: '#00ff00',
                strokeWidth: this.getOnePercentOfCanvasSize(),
                pointerEvents: 'none'
            });
            this.mouseMoveHooks['cutMouseMove'] = (svgCoords: Point, shiftKey: boolean) => {
                svgCoords = this.getAxisAlignmentWhenKeyPressed(
                    {x: this.pendingCut.points[0], y: this.pendingCut.points[1]},
                    svgCoords, !shiftKey);
                this.pendingOperationLineHelper.attr({
                    x2: svgCoords.x,
                    y2: svgCoords.y
                });
            };
        } else if (this.pendingCut.points.length === 2) {
            let x1 = this.pendingCut.points[0];
            let y1 = this.pendingCut.points[1];
            if (this.checkIsCutOnEdge(x1, y1, point.x, point.y)) {
                this.growls.error('error.validation.cut_not_possible_on_edge');
                return;
            }
            this.pendingCut.points.push(point.x, point.y);
            this.mouseMoveHooks['cutMouseMove'] = undefined;
            this.pendingOperationLineHelper.remove();
            this.pendingOperationLineHelper = undefined;
        } else {
            return;
        }
        this.redrawWindow(false, false);
    }

    private checkIsCutOnEdge(x1: number, y1: number, x2: number, y2: number) {
        let framePoints = WindowCalculator.getOuterFramePoints(this.data.windows,
            this.data.cuts);
        for (let i = 0; i < framePoints.length; i += 2) {
            let frameX1 = DrawingUtil.getPoint(framePoints, i - 2);
            let frameY1 = DrawingUtil.getPoint(framePoints, i - 1);
            let frameX2 = DrawingUtil.getPoint(framePoints, i);
            let frameY2 = DrawingUtil.getPoint(framePoints, i + 1);
            let line = [frameX1, frameY1, frameX2, frameY2];
            if (DrawingUtil.distanceFromLine(line, [x1, y1]) < 1
                && DrawingUtil.distanceFromLine(line, [x2, y2]) < 1) {
                return true;
            }
        }
        return false;
    }

    onClickLineGrillHandler(event: MouseEvent): void {
        if ((this.mode !== Tool.GRILL && this.mode !== Tool.MULLION) ||
            this.pendingGrillStage !== PendingGrillStage.SELECT_AREA) {
            return;
        }

        let target: Snap.Element = Snap(event.target);

        let clicked = this.calculateSvgCoordsFromMouseEvent(event);
        let newGrill;
        switch (this.pendingGrillData.grill.type) {
            case GrillType.LINE_GRILL:
                if ((target.data(DataKeys.AREA) as AreaSpecification).filling.type !== FillingType.GLASS) {
                    return;
                }
                newGrill = (this.pendingGrillData.grill as LineGrill);
                break;
            case GrillType.MULLION:
                newGrill = (this.pendingGrillData.grill as Mullion);
                break;
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
        let points = this.pendingGrillData.points;
        let alignToAxis = !event.shiftKey;

        if (points.length === 2) {
            let startPoint = new Point(points[0], points[1]);
            clicked = this.getAxisAlignmentWhenKeyPressed(startPoint, clicked, alignToAxis, 15);
            if (this.isTerrace && FloatOps.ne(clicked.y, startPoint.y)) {
                let err = new Error();
                err.message = "WindowDesignerComponent.onClickLineGrillHandler: " + "Przewiązka w zabudowach tarasowych może być tylko pozioma";
                err.name = ErrorNames.MULLION_CAN_BE_HORIZONTAL_ONLY;
                this.errors.handleFE(err);
                return;
            }
        }
        let exactPoint;
        let point = {x: 0, y: 0, length: 0, distance: 0};
        // ignore guidance points if grill cannot be angled
        if ((event.target as Element).getAttribute("guidancePoints") === "true" && (points.length === 0)) {
            exactPoint = {
                x: Number((event.target as Element).getAttribute("cx")),
                y: Number((event.target as Element).getAttribute("cy"))
            };
        } else {
            let clickTarget: Snap.Element;
            switch (WindowParams.getSnapElemType(target)) {
                case WindowParams.MUNTIN_ELEM:
                    clickTarget = this.svg.path(
                        DrawingUtil.pathStringFromPolygonPoints(target.data(DataKeys.GRILL_SEGMENT).points, true));
                    break;
                case WindowParams.GLAZING_BEAD_ELEM:
                    if (target.type !== 'path') {
                        clickTarget = this.svg.path(
                            DrawingUtil.pathStringFromPolygonPoints(
                                PolygonPointUtil.toNumbersArray(target.data(DataKeys.GLAZING_BEAD)), true));
                    } else {
                        clickTarget = target;
                    }
                    break;
                case WindowParams.INNER_FRAME_ELEM:
                    clickTarget = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(
                        PolygonPointUtil.toNumbersArray(target.data(DataKeys.INNER_FRAME)), true));
                    break;
                case WindowParams.MULLION_ELEM:
                    clickTarget = this.svg.path(
                        DrawingUtil.pathStringFromPolygonPoints(target.data(DataKeys.GRILL_SEGMENT).points, true));
                    break;
                default:
                    console.error("Not supported event target type: " + WindowParams.getSnapElemType(target));
                    return;
            }
            point = Snap.closestPoint(clickTarget, clicked.x, clicked.y);
            point.x = FloatOps.round(point.x);
            point.y = FloatOps.round(point.y);
            let path = clickTarget.node as unknown as SVGPathElement;
            let segIndex = path.getPathSegAtLength(point.length);
            if (segIndex === 0) {
                segIndex++;
            }
            let seg = path.pathSegList.getItem(segIndex);
            let prevSeg = path.pathSegList.getItem(segIndex - 1) as any;

            // when axis alignment is on (SHIFT NOT pressed)
            if (alignToAxis && points.length === 2) {
                let lineSeg;

                if (seg.pathSegType === SVGPathSeg.PATHSEG_LINETO_ABS) {
                    lineSeg = (seg as SVGPathSegLinetoAbs);
                } else if (seg.pathSegType === SVGPathSeg.PATHSEG_CLOSEPATH) {
                    lineSeg = (path.pathSegList.getItem(0) as SVGPathSegMovetoAbs);
                }
                let intersection = DrawingUtil.lineIntersection(
                    prevSeg.x, prevSeg.y, lineSeg.x, lineSeg.y,                         // frame line
                    points[0], points[1], clicked.x, clicked.y      // drawn cut line
                );
                if (!intersection.intersects) {
                    return;
                }
                exactPoint = {
                    x: intersection.x,
                    y: intersection.y
                };
            } else {
                if (seg.pathSegType === SVGPathSeg.PATHSEG_LINETO_ABS) {
                    let lineSeg = seg as SVGPathSegLinetoAbs;
                    let t = DrawingUtil.lineSegmentParameter(prevSeg.x, prevSeg.y, lineSeg.x, lineSeg.y, point.x,
                        point.y);
                    exactPoint = {
                        x: prevSeg.x + t * (lineSeg.x - prevSeg.x),
                        y: prevSeg.y + t * (lineSeg.y - prevSeg.y)
                    };
                } else if (seg.pathSegType === SVGPathSeg.PATHSEG_CLOSEPATH) {
                    let startSeg = path.pathSegList.getItem(0) as SVGPathSegMovetoAbs;
                    let t = DrawingUtil.lineSegmentParameter(prevSeg.x, prevSeg.y, startSeg.x, startSeg.y, point.x,
                        point.y);
                    exactPoint = {
                        x: prevSeg.x + t * (startSeg.x - prevSeg.x),
                        y: prevSeg.y + t * (startSeg.y - prevSeg.y)
                    };
                } else {
                    exactPoint = clickTarget.getPointAtLength(point.length);
                }
            }
        }
        let omitValidation = false;

        let targetKey;
        let pushGrillTo: (Grill | Mullion)[];
        let segmentGenerator: (obj: Grill | Mullion, points: number[]) => void;
        switch (this.mode) {
            case Tool.GRILL:
                targetKey = DataKeys.AREA;
                this.pendingGrillData.area = (target.data(DataKeys.AREA) as AreaSpecification);
                pushGrillTo = this.pendingGrillData.area.grills;
                segmentGenerator = GrillSegmentGenerator.generateLineGrillSegments;
                break;
            case Tool.MULLION:
                if ((newGrill as Mullion).isConstructional && target.data(DataKeys.MULLION) &&
                    !target.data(DataKeys.MULLION).isConstructional) {
                    return;
                }
                targetKey = DataKeys.SUBWINDOW;
                pushGrillTo = (target.data(DataKeys.SUBWINDOW) as SubWindowData).mullions;
                segmentGenerator = GrillSegmentGenerator.generateMullionSegments;
                break;
            default:
                let err = new Error("Unsupported mode " + this.mode);
                err.name = ErrorNames.ILLEGAL_OPERATION_INVOKED;
                throw err;
        }

        if (points.length === 0) {
            points.push(exactPoint.x, exactPoint.y);
            PositionsHelper.addRelativePosition(newGrill, target, points, this.mode === Tool.MULLION);
            this.pendingOperationLineHelper = this.svg.line(exactPoint.x, exactPoint.y, point.x, point.y);
            this.pendingOperationLineHelper.attr({
                stroke: '#00ff00',
                strokeWidth: newGrill.width,
                pointerEvents: 'none'
            });
            this.pendingOperationLineHelper.data(targetKey, target.data(targetKey));
            this.mouseMoveHooks['grillMouseMove'] = (svgCoords: Point, shiftKey: boolean) => {
                svgCoords = this.getAxisAlignmentWhenKeyPressed(
                    {x: points[0], y: points[1]},
                    svgCoords, !shiftKey, 15);
                this.pendingOperationLineHelper.attr({
                    x2: svgCoords.x,
                    y2: svgCoords.y
                });
            };
            omitValidation = true;
        } else if (points.length === 2) {
            if (this.pendingOperationLineHelper.data(targetKey) !== target.data(targetKey)) {
                return;
            }

            this.saveStepInHistory();
            try {
                PositionsHelper.addRelativePosition(newGrill, target, [exactPoint.x, exactPoint.y],
                    this.mode === Tool.MULLION);
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
                return;
            }
            points.push(exactPoint.x, exactPoint.y);
            segmentGenerator(newGrill, points);
            pushGrillTo.push(newGrill);
            this.mouseMoveHooks['grillMouseMove'] = undefined;
            this.pendingOperationLineHelper.remove();
            this.pendingOperationLineHelper = undefined;
            try {
                this.onGrillAdded.emit();
                let subwindow = target.data(DataKeys.SUBWINDOW);
                if (this.mode === Tool.MULLION) {
                    let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, this.data.cuts,
                        this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                    let result = AreaUtils.splitIntersectedAreas(subwindow, newGrill as Mullion,
                        totalGlazingBead, this.profileCompositionDistances);
                    result.merge(
                        MullionUtils.updateAbsoluteMullionPositions(subwindow, this.data.cuts, this.totalBoundingBox,
                            this.profileCompositionDistances, this.data.shape, false, this.isValidationDisabled(), true));
                    this.processNewCreatedChanges(result);
                }
                if (this.mode === Tool.GRILL) {
                    let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, this.data.cuts,
                        this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                    PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, this.profileCompositionDistances, true);
                }
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
                return;
            }
        } else {
            return;
        }
        this.redrawWindow(omitValidation, false);
    }

    addGrillTemplateFieldMouseHandlers(part: Snap.Element, subWindow: SubWindowData, area: AreaSpecification, glazingBead: number[]): void {
        this.zone.runOutsideAngular(() => {
            part.mouseover(() => {
                part.addClass('glass-selected');
            });
            part.mouseout(() => {
                part.removeClass('glass-selected');
            });
        });

        part.click(event => {
            if (!this.isErrorsPresent()) {
                let clickedPoint = this.calculateSvgCoordsFromMouseEvent(event);
                PositionsHelper.findGrillGridReferencePoints(this.pendingGrillData.grill, glazingBead, area, clickedPoint);
                this.pendingGrillData.subWindow = subWindow;
                this.pendingGrillData.area = area;
                this.pendingGrillData.svgTarget = part;
                if (!GrillPositionValidator.isFittingGridPossible(this.pendingGrillData)) {
                    this.growls.error(ErrorNames.GRILL_TO_CLOSE_TO_FRAME);
                } else {
                    this.onGrillAdded.emit();
                }
            }

        });
    }

    addSelectionClickHandler(elem: Snap.Element, multiSelectionClassName: string) {
        if (this.mode === Tool.SELECT) {
            elem.click(event => {
                event.stopPropagation();
                this.elementSelected(event.shiftKey ? multiSelectionClassName : elem, event.ctrlKey);
                return;
            });
        } else {
            elem.attr({pointerEvents: 'none'});
        }
    }

    private shortenExistingCut(existingCut: LineCutData, newCut: LineCutData,
                               intersectionPoint: number[]): void {
        let p1IsAbove = DrawingUtil.isPointAboveLine(existingCut.points.slice(0, 2), newCut.points);
        let isSideTop = newCut.side === 'top';
        let [x, y] = intersectionPoint;

        if (p1IsAbove === isSideTop) {
            existingCut.points[0] = x;
            existingCut.points[1] = y;
        } else {
            existingCut.points[2] = x;
            existingCut.points[3] = y;
        }
    }

    private splitIfMultipleCut(newCutIndex: number) {
        let cuts = this.data.cuts as LineCutData[];
        let newCut = cuts[newCutIndex];

        const cutsShareEndPoint = (cut1: LineCutData, cut2: LineCutData): boolean => {
            for (let c1 = 0; c1 < 2; ++c1) {
                for (let c2 = 0; c2 < 2; ++c2) {
                    if (DrawingUtil.distance(cut1.points.slice(c1 * 2, c1 * 2 + 2), cut2.points.slice(c2 * 2, c2 * 2 + 2)) === 0) {
                        return true;
                    }
                }
            }
            return false;
        };

        for (let i = 0; i < this.data.cuts.length; i++) {
            if (i !== newCutIndex) {
                let existingCut = cuts[i];
                if (cutsShareEndPoint(existingCut, newCut)) {
                    continue;
                }
                let dist1 = DrawingUtil.distanceFromLine(existingCut.points, newCut.points.slice(0, 2));
                let dist2 = DrawingUtil.distanceFromLine(existingCut.points, newCut.points.slice(2, 4));

                const ATTRACTION_THRESHOLD = 1; // px

                if (dist1 <= ATTRACTION_THRESHOLD) {
                    let x = newCut.points[0];
                    let y = newCut.points[1];
                    this.shortenExistingCut(existingCut, newCut, [x, y]);
                } else if (dist2 <= ATTRACTION_THRESHOLD) {
                    let x = newCut.points[2];
                    let y = newCut.points[3];
                    this.shortenExistingCut(existingCut, newCut, [x, y]);
                } else {
                    let intersectionResult = DrawingUtil.lineIntersection(existingCut.points, newCut.points);
                    if (intersectionResult.onLine1) {
                        let x = FloatOps.round(intersectionResult.x);
                        let y = FloatOps.round(intersectionResult.y);
                        this.shortenExistingCut(existingCut, newCut, [x, y]);
                    }
                }
            }
        }
    }

    OnWindowResize(event: any): void {
        this.redrawWindow(true, true);
    }

    getBusinessTypes(event: AddWindowEvent = null) {
        this.windowBusinessTypes = [];
        if (this.data.windowSystemId != undefined) {
            this.businessTypesLoadInProgressChange.emit(true);
            this.businessTypeService.getBusinessTypesByWindowSystem(this.data.windowSystemId).pipe(
                finalize(() => this.businessTypesLoadInProgressChange.emit(false)))
                .subscribe({
                    next: data => {
                        this.windowBusinessTypes = data;
                        if (event != null) {
                            this.addWindowSystem(event);
                            this.mode = undefined;
                            this.onChangeDisplayAddWindowDialog.emit(false);
                        }
                    },
                    error: error => {
                        this.errors.handle(error);
                    },
                    complete: () => {
                        console.info('WindowDesignerComponent `getBusinessTypes` completed!');
                    }
                });
        }
    }

    setCatalogData(catalogData: CatalogData): void {
        this.windowSystem = catalogData.selectedWindowSystem;
        this.windowSystemDefaults = catalogData.windowSystemDefaults != undefined ? catalogData.windowSystemDefaults.value : undefined;
        if (this.windowSystem) {
            this.topMullionDimension = this.windowSystem.supplier.topMullionDimensions;
            this.ventilationAlwaysOnFrame = this.windowSystem.ventilationAlwaysOnFrame;
        }
        this.staticData.grills = catalogData.grills;
        this.staticData.angledGrills = catalogData.angledGrills;
        this.staticData.mullions = catalogData.mullions;
        this.staticData.glasses = catalogData.glasses;
        this.staticData.frames = catalogData.frames;
        this.staticData.decorativeFillings = catalogData.decorativeFillings;
        this.staticData.colors = catalogData.colors;
        this.windowSystems = catalogData.windowSystems;
        this.allAddons = catalogData.addons;
        this.windowSystemDrawingToolsEnabler = catalogData.windowSystemDrawingToolsEnabler;
        this.configurableAddonDefinitions = catalogData.configurableAddonDefinitions;
    }

    onWindowSystemSelectionChanged(event: AddWindowEvent = null): void {
        if (!this.sidebarOnlyMode && this.data.windowSystemId != undefined && !this.readOnlyMode) {
            this.windowSystemDefinitionService.validateMarginExistance(this.data.windowSystemId, this.offerPosition.offerId)
            .pipe(
                this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                    ...this.offer,
                    identifier: {target: 'WINDOW_SYSTEM', windowSystemId: this.data.windowSystemId}
                })
            ).subscribe({
                next: profitMarginValid => this.profitMarginPresent = profitMarginValid,
                error: error => this.errors.handle(error)
            });
        }
        this.getBusinessTypes(event);
    }

    private setupWindowSystemsAndTypesDialog() {
        if (this.data.windowSystemId == undefined) {
            this.data.windowSystemId = this.getLastWindowSystemIdFromUserPreferences();
        }
        this.openAddNewWindowDialog();
        this.blockUiController.unblock('WindowEditorInit');
    }

    businessTypeSelected(addWindowEvent: AddWindowEvent): void {
        switch (this.mode) {
            case Tool.NEW_SUBWINDOW:
                this.addWindowSystem(addWindowEvent);
                break;
            case Tool.CHANGE_BUSINESS_TYPE:
                this.changeBusinessType(addWindowEvent.type);
                break;
            default:
                throw new Error("Unsupported tool for handling business type selection: " + this.mode);
        }
    }

    addWindowSystem(addWindowEvent: AddWindowEvent) {
        let isFirstWindow = this.data.windows.length === 0;
        const windowSystemObservable = isFirstWindow
            ? this.windowSystemDefinitionService.getSystem(this.data.windowSystemId)
            : of(this.windowSystem);
        windowSystemObservable.subscribe(windowSystem => {
            this.windowSystem = windowSystem;
            if (isFirstWindow) {
                this.addNewWindowData = (event: AddWindowEvent) => {
                    let attributes = WindowTypeCodeParser.parseTypeCode(event.type);
                    let newWindow = WindowDataFactory.createWindowData(attributes, this.getWindowSystem(), addWindowEvent.windowCount,
                        this.totalBoundingBox, 'center');
                    let success = this.addWindows(newWindow, attributes, this.windowSystem.allowFestAtHinges);
                    if (!success) {
                        return;
                    }
                    this.prepareTabsData();
                    if (this.data.windows && this.data.windows.length > 0) {
                        this.designerInitialized.emit(true);
                    }
                };
                this.profileCompositionDistances.prepareSystem(this.getWindowSystem());
                this.saveLastWindowSystemIdInUserPreferences(this.data.windowSystemId);
            }
            this.saveStepInHistory();
            if (isFirstWindow) {
                this.addNewWindowData(addWindowEvent);
            } else {
                let oldData = DrawingData.copy(this.data);
                this.addNewWindowData(addWindowEvent);
                let operationResult = AlignmentTool.autoAlign(this.getWindowSystem(), this.profileCompositionDistances,
                    this.data, oldData);
                this.processNewCreatedChanges(operationResult);
                this.guides.rebuildStructureGuides();
            }
            this.changeDisplayAddWindowDialog(false);
            this.debouncedRedrawWindow(false, !this.isPricingTabOpen());
            FocusOnElement.tryToFocus(WindowEditorField.WIDTH + '_id', 0, 3, 100);
            if (!isFirstWindow) {
                this.newWindowAdded.emit();
            } else {
                this.emitCommonBusinessType(this.data);
            }
        });
    }

    openBusinessTypeChangeDialog(subwindowGeneratedId: string): void {
        this.businessTypeChangeSubwindowId = subwindowGeneratedId;
        this.mode = Tool.CHANGE_BUSINESS_TYPE;
    }

    changeBusinessType(incomingBusinessType: WindowTypeCode): void {
        let window = this.data.windows.find(
            w => w.subWindows.some(subwindow => subwindow.generatedId === this.businessTypeChangeSubwindowId));
        let newAttributes = WindowTypeCodeParser.parseTypeCode(incomingBusinessType);
        if (window.typeCode !== newAttributes.typeCode) {
            this.saveStepInHistory(this.data);
            let configAddonsSideChanged: boolean;
            try {
                let oldData = DrawingData.copy(this.data);
                ChangeBusinessTypeUtils.change(window, newAttributes, this.data, this.getWindowSystem());
                configAddonsSideChanged = ChangeBusinessTypeUtils.changeConfigAddonsSideAttribute(window, this.configurableAddonPositions, this.categoriesWithAutoOptions);

                let oldMaps = PositionsHelper.getFramesAndGlazingBeads(this.data, this.profileCompositionDistances,
                    this.isValidationDisabled());
                let operationResult = AlignmentTool.autoAlign(this.getWindowSystem(), this.profileCompositionDistances,
                    this.data, oldData);
                operationResult.merge(
                    MullionUtils.repositionMullionsAndGrills(this.data, this.profileCompositionDistances,
                        oldMaps.frames, oldMaps.glazingBeads, this.isValidationDisabled()));
                this.processNewCreatedChanges(operationResult);
                this.prepareTabsData();
                this.guides.rebuildStructureGuides();
                this.onSubwindowTypeChange.emit(this.businessTypeChangeSubwindowId);
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
                configAddonsSideChanged = false;
            }
            this.redrawWindow(false, false);
            if (configAddonsSideChanged) {
                this.growls.info("OFFER.DRAWING.CONFIGURABLE_ADDONS_SIDE_CHANGED");
            }
        }
        this.mode = Tool.SELECT;
        this.businessTypeChangeSubwindowId = undefined;
    }

    isAddNewWindowDialogDisplayed(): boolean {
        return this.mode === Tool.NEW_WINDOW;
    }

    openAddNewWindowDialog(): void {
        this.mode = Tool.NEW_WINDOW;
        this.onChangeDisplayAddWindowDialog.emit(true);
    }

    largeImageGetter(): (itemId: number) => Observable<string> {
        return (itemId: number) => {
            return this.windowSystemDefinitionService.getWindowEditorImage(itemId);
        };
    }

    windowSelected(event: {systemId: number, businessType: WindowTypeCode}): void {
        if (event == null) {
            this.exit();
            return;
        }
        this.data.windowSystemId = event.systemId;
        this.onWindowSystemSelectionChanged({
            type: event.businessType,
            windowCount: 1
        });
    }

    changeDisplayAddWindowDialog(newValue: boolean) {
        this.onChangeDisplayAddWindowDialog.emit(newValue);
        setTimeout(() => {
            this.mode = newValue ? Tool.NEW_SUBWINDOW : Tool.SELECT;
            this.businessTypeChangeSubwindowId = undefined;
            if (this.data.windows.length === 0) {
                if (!newValue) {
                    this.exit();
                }
                if (this.mode !== Tool.SELECT || this.sidebarOnlyMode) {
                    this.svg.clear();
                }
                if (!this.sidebarOnlyMode) {
                    this.blockUiController.unblock('WindowEditorInit');
                }
            }
        }, 100);
    }

    isAddWindowDialogDisplayed(): boolean {
        return this.mode === Tool.NEW_SUBWINDOW || this.mode === Tool.CHANGE_BUSINESS_TYPE;
    }

    private saveLastWindowSystemIdInUserPreferences(id: number) {
        this.userUiConfigService.saveConfigForTheView(WindowDesignerComponent.VIEW_NAME,
            WindowDesignerComponent.LAST_WINDOW_SYSTEM_ID_PROPERTY_KEY, id);
    }

    private getLastWindowSystemIdFromUserPreferences(): number {
        return this.userUiConfigService.getConfigForTheView(WindowDesignerComponent.VIEW_NAME,
            WindowDesignerComponent.LAST_WINDOW_SYSTEM_ID_PROPERTY_KEY);
    }

    clearNewWindowErrors() {
        this.windowSizeValidationError = null;
    }

    prepareTabsData() {
        let windowTabsBefore = this.windowTabsData.length;
        this.windowTabsData = [];
        let typeCodeCount = [];
        if (isUpsellingMode(this.dataModificationMode)) {
            this.windowTabsData.push({
                windowData: this.upsellingSubwindowDummy,
                type: SubWindowTypeCode[this.upsellingSubwindowDummy.typeCode]
            });
        } else {
            this.data.windows.forEach(w => {
                w.subWindows.forEach(sw => {
                    sw.subWindowNameSuffix = '';
                    this.windowTabsData.push({
                        windowData: sw,
                        type: SubWindowTypeCode[sw.typeCode]
                    });
                    typeCodeCount[sw.typeCode] = typeof typeCodeCount[sw.typeCode] === "undefined" ? 1 : typeCodeCount[sw.typeCode] + 1;
                });
            });
        }

        let windowTabsAfter = this.windowTabsData.length;
        if (this.currentlySelectedTab > windowTabsBefore) {
            this.currentlySelectedTab += windowTabsAfter - windowTabsBefore; // retain pricing/validation tab selection
        } else if (this.currentlySelectedTab === windowTabsBefore && windowTabsBefore > windowTabsAfter) {
            this.currentlySelectedTab = 0; // go to general tab if subwindow tab we were on was deleted
        }

        for (let typeCode in typeCodeCount) {
            if (typeCodeCount[typeCode] > 1) {
                let count = 1;
                this.windowTabsData.forEach(windowTab => {
                    if (windowTab.type === typeCode) {
                        windowTab.windowData.subWindowNameSuffix = `_${count}`;
                        ++count;
                    }
                });
            }
        }
        if (this.currentlySelectedTab > this.windowTabsData.length + 2) {
            this.currentlySelectedTab = 0;
        }
        if (this.windowTabsData.length > 0 && this.currentlySelectedTab > 0 &&
            this.currentlySelectedTab <= this.windowTabsData.length) {
            this.recalculateSelectedTabIndicatorPosition(this.currentlySelectedTab);
            this.selectedTabIndicator.display = true;
        }
        this.refilterGlazingBeads.emit();
    }

    selectedTabChanged(tabIndex: number) {
        this.currentlySelectedTab = tabIndex;
    }

    wasTabSelected(tabIndex): boolean {
        return this.currentlySelectedTab === tabIndex + 1;
    }

    private createCutSideButton(x: number, y: number, r: number, side: string) {
        let circle = this.svg.circle(x, y, r)
            .attr(WindowDesignerComponent.plusButtonCircleElementAttributes);
        let minusLine = this.svg.line(x - r / 2, y, x + r / 2, y)
            .attr(WindowDesignerComponent.plusButtonCircleElementAttributes);
        let button = this.svg.group(circle, minusLine);
        button.click(() => {
            this.saveStepInHistory();
            try {
                this.pendingCut.side = side;
                this.addNewCuts([this.pendingCut]);
                this.resetPendingCut();
                this.redrawWindow(false, !this.isPricingTabOpen(), () => {
                    this.cuttingFinished.emit();
                });
            } catch (e) {
                this.errors.handleFE(e);
                this.cancelLast();
            }
        });
    }

    private addNewCuts(newCuts: LineCutData[]) {
        if (newCuts.length > 0) {
            newCuts.forEach(newCut => {
                this.removeUnusedCuts(this.data.cuts as LineCutData[], newCut);
                this.data.cuts.push(newCut);
                this.transformWindowCutOffPoints(this.data.windows, this.data.cuts);
                this.splitIfMultipleCut(this.data.cuts.length - 1);
                let results = this.runWindowsArrayGarbageCollector();
                this.processNewCreatedChanges(results);
            });
            this.guides.rebuildStructureGuides();
            AreaUtils.recalculateAreaSizes(this.data.windows, this.data.cuts, this.totalBoundingBox, this.profileCompositionDistances,
                this.isValidationDisabled());
        }
    }

    private createAddWindowButton(totalBoundingBox: MinMaxXY, circleSize: number, side: 'top' | 'right' | 'bottom' | 'left' | 'center') {
        if (this.readOnlyMode) {
            return;
        }
        let distanceFromWindows = 2.1;
        let x = (totalBoundingBox.maxX + totalBoundingBox.minX) / 2;
        let y = (totalBoundingBox.maxY + totalBoundingBox.minY) / 2;
        let newWindowPosition = {
            left: totalBoundingBox.minX, right: totalBoundingBox.maxX,
            top: totalBoundingBox.minY, bottom: totalBoundingBox.maxY
        };
        let windowSystem = this.getWindowSystem();
        switch (side) {
            case 'top':
                y = totalBoundingBox.minY - circleSize * distanceFromWindows;
                newWindowPosition.top = totalBoundingBox.minY - WindowDataFactory.getDefaultHeight(windowSystem);
                newWindowPosition.bottom = totalBoundingBox.minY;
                break;
            case 'right':
                x = totalBoundingBox.maxX + circleSize * distanceFromWindows;
                newWindowPosition.left = totalBoundingBox.maxX;
                newWindowPosition.right = totalBoundingBox.maxX + WindowDataFactory.getDefaultWidth(windowSystem);
                break;
            case 'bottom':
                y = totalBoundingBox.maxY + circleSize * distanceFromWindows;
                newWindowPosition.top = totalBoundingBox.maxY;
                newWindowPosition.bottom = totalBoundingBox.maxY + WindowDataFactory.getDefaultHeight(windowSystem);
                break;
            case 'left':
                x = totalBoundingBox.minX - circleSize * distanceFromWindows;
                newWindowPosition.left = totalBoundingBox.minX - WindowDataFactory.getDefaultWidth(windowSystem);
                newWindowPosition.right = totalBoundingBox.minX;
                break;
            case 'center':
                newWindowPosition.left = 0;
                newWindowPosition.right = WindowDataFactory.getDefaultWidth(windowSystem);
                newWindowPosition.top = 0;
                newWindowPosition.bottom = WindowDataFactory.getDefaultHeight(windowSystem);
                break;
        }
        let circle = this.svg.circle(x, y, circleSize);
        let plusVLine = this.svg.line(x, y - circleSize, x, y + circleSize);
        let plusHLine = this.svg.line(x - circleSize, y, x + circleSize, y);
        let button = this.svg.group(circle, plusVLine, plusHLine)
            .attr(WindowDesignerComponent.plusButtonCircleElementAttributes);

        button.click(() => {
            this.addVertically = side === 'right' || side === 'left';
            this.changeDisplayAddWindowDialog(true);
            this.addNewWindowData = (addWindowEvent: AddWindowEvent) => {
                const clickHandler_windowSystem = this.getWindowSystem();
                let attributes = WindowTypeCodeParser.parseTypeCode(addWindowEvent.type);
                let newWindows = WindowDataFactory.createWindowData(attributes, clickHandler_windowSystem, addWindowEvent.windowCount,
                    totalBoundingBox, side);
                if (this.data.windows.length > 0) {
                    for (let newWindow of newWindows) {
                        WindowDataFactory.fillWindowWithCommonData(newWindow, this.commonData);
                    }
                }
                this.tryToAddWindows(newWindows, attributes, side);
            };
        });
    }

    private tryToAddWindows(newWindows: WindowData[], attributes: WindowAttributes,
        side: 'top' | 'right' | 'bottom' | 'left' | 'center'): void {
        this.saveStepInHistory();
        if (this.addWindows(newWindows, attributes, this.windowSystem.allowFestAtHinges, side)) {
            this.prepareTabsData();
        } else {
            this.undoLast();
        }
    }

    private getSelectedObjectReferences(): any[] {
        let selectedObjects = [];
        for (let element of this.clickedSnapElements.elements) {
            let object = this.getObjectReference(element);
            if (object) {
                selectedObjects.push(object);
            }
        }
        return selectedObjects;
    }

    protected reselectRecreatedSnapElements(selectedObjects: any[]): void {
        this.clickedSnapElements.elements = [];
        if (selectedObjects && selectedObjects.length > 0) {
            let selectedObjectsIds = selectedObjects.map(obj => obj['generatedId']).filter(id => id);
            let matchingElements = this.svg.selectAll("." + this.clickedSnapElements.type);
            matchingElements.forEach(el => {
                if (_.contains(selectedObjectsIds, this.getObjectReference(el)['generatedId'])) {
                    this.addElemToClickedList(el, [], true);
                }
            });
        }
    }

    public deleteSelectedItems(): void {
        if (this.isDeletableItemSelected()) {
            this.blockUiController.block(WindowDesignerComponent.OPERATION_IN_PROGRESS);
            let deleteDone = false;
            let previousState = JSON.stringify(this.data);
            let uniqueToDelete = this.clickedSnapElements.elements.map(el => {
                return {elem: el, objRef: this.getObjectReference(el)};
            }).filter((v, i, a) => i === 0 || !a.slice(0, i).map(s => s.objRef).includes(v.objRef));
            uniqueToDelete.forEach(td => {
                let elem = td.elem;
                let objRef = td.objRef;
                let sub: SubWindowData = elem.data(DataKeys.SUBWINDOW);
                if (this.clickedSnapElements.type === WindowParams.HANDLE_ELEM) {
                    if (sub.handle.state !== HandleState.CUSTOM) {
                        sub.handle.state = HandleState.DELETED;
                    } else {
                        sub.handle = undefined;
                    }
                    deleteDone = true;
                } else if (this.clickedSnapElements.type === WindowParams.MUNTIN_ELEM) {
                    let area: AreaSpecification = elem.data(DataKeys.AREA);
                    if (GrillHelper.isDeletable(objRef, area)) {
                        area.grills = area.grills.filter(g => g !== objRef);
                        let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(sub, this.data.cuts,
                            this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                        PositionsHelper.updateAbsolutePositions(sub, totalGlazingBeads, this.profileCompositionDistances);
                        deleteDone = true;
                    }

                    // Refresh common values so "no filling" can be selected
                    if (this.currentlySelectedTab === 0) {
                        this.setCommonValues();
                    }
                } else if (this.clickedSnapElements.type === WindowParams.MULLION_ELEM) {
                    if (MullionHelper.isDeletable(objRef, sub)) {
                        let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(sub, this.data.cuts,
                            this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                        let totalInnerFrame = WindowCalculator.getTotalFrameInnerEdgePoints(sub, this.data.cuts,
                            this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                        let totalRealPackagePoints = WindowCalculator.getTotalRealGlazingPackagePoints(sub, this.data.cuts,
                            this.totalBoundingBox, this.profileCompositionDistances);
                        let result = AreaUtils.mergeAreasBackTogether(objRef, sub, totalGlazingBead, totalInnerFrame,
                            totalRealPackagePoints, this.profileCompositionDistances);
                        this.processNewCreatedChanges(result);
                        AreaUtils.setNewAreaSizes(sub, totalGlazingBead, totalInnerFrame, totalRealPackagePoints,
                            this.profileCompositionDistances);
                        deleteDone = true;
                    }
                } else if (this.clickedSnapElements.type === WindowParams.VENTILATION_ELEM) {
                    sub.ventilator.addonId = undefined;
                    sub.drip.addonId = undefined;
                    sub.coupler.addonId = undefined;
                    this.setCommonValues();
                    deleteDone = true;
                }
            });
            if (deleteDone) {
                this.resetSelectedElements();
                this.saveStepInHistory(JSON.parse(previousState));
                this.redrawWindow(false, false);
            }
        }
    }

    isDeletableItemSelected(): boolean {
        if (this.clickedSnapElements && this.clickedSnapElements.type && this.clickedSnapElements.elements) {
            if (this.clickedSnapElements.type === WindowParams.HANDLE_ELEM) {
                return this.clickedSnapElements.elements.length > 0;
            }
            if (this.clickedSnapElements.type === WindowParams.MUNTIN_ELEM &&
                this.clickedSnapElements.elements.length > 0) {
                let uniqueGrills = new Set<number[]>();
                this.clickedSnapElements.elements.forEach(grill => {
                    uniqueGrills.add(grill.data(DataKeys.GRILL));
                });

                if (uniqueGrills.size === 1) {
                    let el = this.clickedSnapElements.elements[0];
                    let area = el.data(DataKeys.AREA);
                    let grill = el.data(DataKeys.GRILL);
                    return GrillHelper.isDeletable(grill, area);
                }

                return false;
            }
            if (this.clickedSnapElements.type === WindowParams.MULLION_ELEM &&
                this.clickedSnapElements.elements.length === 1) {
                let el = this.clickedSnapElements.elements[0];
                let mullion = el.data(DataKeys.MULLION);
                let subwindow = el.data(DataKeys.SUBWINDOW);
                return MullionHelper.isDeletable(mullion, subwindow);
            }
            if (this.clickedSnapElements.type === WindowParams.VENTILATION_ELEM) {
                return this.clickedSnapElements.elements.length === 1;
            }
        }
        return false;
    }

    deleteAllGrills(): void {
        let needRedraw = false;
        for (let window of this.data.windows) {
            for (let subWindow of window.subWindows) {
                for (let area of subWindow.areasSpecification) {
                    if (GrillHelper.areaHasGrills(area)) {
                        needRedraw = true;
                        area.grills.length = 0;
                        area.fields.length = 0;
                    }
                }
            }
        }
        if (needRedraw) {
            this.redrawWindow(false, false);
        }
    }

    private getObjectReference(element: Snap.Element) {
        let type = WindowParams.getSnapElemType(element);
        switch (type) {
            case WindowParams.GLAZING_BEAD_ELEM:
                return element.data(DataKeys.GLAZING_BEAD);
            case WindowParams.MUNTIN_ELEM:
                return element.data(DataKeys.GRILL);
            case WindowParams.MULLION_ELEM:
                return element.data(DataKeys.MULLION);
            case WindowParams.GLASS_ELEM:
                return element.data(DataKeys.AREA);
            case WindowParams.FRAME_ELEM:
                return element.data(DataKeys.SUBWINDOW);
            case WindowParams.WING_ELEM:
                return element.data(DataKeys.WING);
            case WindowParams.HANDLE_ELEM:
                return element.data(DataKeys.HANDLE);
            case WindowParams.VENTILATION_ELEM:
                return element.data(DataKeys.SUBWINDOW);
            default:
                console.error("Wrong object reference type " + type);
                break;
        }
    }

    private transformWindowCutOffPoints(windows: WindowData[], cuts: CutData[]) {
        let oldBox = DrawingUtil.calculateTotalBoundingBox(windows);
        let outerFrame = [oldBox.minX,
            oldBox.maxY,
            oldBox.maxX,
            oldBox.maxY,
            oldBox.maxX,
            oldBox.minY,
            oldBox.minX,
            oldBox.minY];
        let newBox = DrawingUtil.calculatePolygonTotalBoundingBox(CutsUtil.applyCuts(outerFrame, cuts, 0));
        windows.forEach(w => {
            w.subWindows.forEach(sw => {
                for (let i = 0; i < sw.points.length; i++) {
                    let min = ((i % 2) === 0) ? newBox.minX : newBox.minY;
                    let max = ((i % 2) === 0) ? newBox.maxX : newBox.maxY;
                    sw.points[i] = Math.min(Math.max(sw.points[i], min), max);
                }
            });
        });
    }

    private removeUnusedCuts(cuts: LineCutData[], pendingCut: LineCutData) {
        let isSideTop = pendingCut.side === 'top';
        let index = 0;
        let isPointCutOff = (point: number[]) => {
            return _.isEqual(point, pendingCut.points.slice(0, 2) ||
                _.isEqual(point, pendingCut.points.slice(2, 4))) ||
                DrawingUtil.isPointAboveLine(point, pendingCut.points) === isSideTop;
        };
        while (cuts.length > 0 && index < cuts.length) {
            if (isPointCutOff(cuts[index].points.slice(0, 2)) && isPointCutOff(cuts[index].points.slice(2, 4))) {
                cuts.splice(+index, 1);
            } else {
                index++;
            }
        }
    }

    public isGlazingOrnament(glazing: Glazing): boolean {
        for (let i = 1; i <= glazing.glazingGlassQuantity; i++) {
            let glassId = glazing['glass' + i + 'id'];
            if (glassId) {
                let glass = this.staticData.glasses.find(g => g.id === glassId);
                if (glass && glass.ornament) {
                    return true;
                }
            }
        }
        return false;
    }

    isDefaultGlazing(area: AreaSpecification): boolean {
        const windowSystem = this.getWindowSystem();
        if (WindowSystemType.getByName(windowSystem.systemType).predefinedGlazing) {
            if (this.windowSystemDefaults != undefined) {
                return area.glazingCategoryId === this.windowSystemDefaults.glazingCategoryId
                    && area.glazingFrameCategoryId === this.windowSystemDefaults.glazingFrameCategoryId
                    && area.glazingPackageId === this.windowSystemDefaults.glazingPackageId;
            }
            return false;
        }
        return GlazingHelper.hasDefaultGlasses(windowSystem, area.glazing);
    }

    public addWindowAddonToData(windowAddon: WindowAddon, positionListAddon: PositionListAddon): void {
        if (this.data.addons.indexOf(windowAddon) < 0) {
            this.saveStepInHistory(this.data);
        }
        WindowAddonUtils.addWindowAddonToData(windowAddon, positionListAddon, this.data, this.addedWindowAddons);
    }

    public editWindowAddonInData(windowAddon: WindowAddon): boolean {
        if (WindowAddonUtils.editWindowAddonInData(windowAddon, this.data)) {
            this.saveStepInHistory(this.data);
            return true;
        }
        return false;
    }

    public removeWindowAddonToData(addonId: number): void {
        WindowAddonUtils.removeWindowAddonToData(addonId, this.data, this.addedWindowAddons);
        this.saveStepInHistory(this.data);
    }

    veneerChangeEvent(event: VeneerEvent) {
        this.saveStepInHistory(this.data);
        try {
            let result: OperationResult;
            if (event.isVeneer) {
                event.mullion.veneer =
                    new Veneer(event.veneer.systemId, event.veneer.frameId, event.veneer.isWinged, event.veneer.distance, event.veneer.valid);
                result =
                    MullionUtils.convertPositionToVeneer(event.mullion, event.distance, event.subwindow, this.data.cuts,
                        this.totalBoundingBox, this.profileCompositionDistances, this.data.shape);
            } else {
                event.mullion.veneer = new Veneer();
                result =
                    MullionUtils.convertPositionFromVeneer(event.mullion, event.subwindow, this.data.cuts,
                        this.totalBoundingBox, this.profileCompositionDistances, this.data.shape);
            }
            this.processNewCreatedChanges(result);
        } catch (e) {
            this.errors.handleFE(e);
            this.cancelLast();
        }
        return this.redrawWindow(false, !this.isPricingTabOpen());
    }

    focusDimensionFieldSentEvent(): void {
        FocusOnElement.tryToFocus('resizeNewSize', 0, 3, 100);
    }

    public tryToFitGrillInDistanceFrames(area: AreaSpecification, grillId: number): void {
        if (area.glazing.glazingGlassQuantity > 1) {
            let grillToUse = this.staticData.grills.find(grill => grill.id === grillId);
            let outerFrameId = AreaUtils.getOuterFrameId(area);
            let outerFrame = this.staticData.frames.find(frame => frame.id === outerFrameId);
            let requiredFrameThickness = grillToUse.minFrameWidth;
            if (outerFrame.thickness >= requiredFrameThickness) {
                return;
            } else {
                let possibleAllowedFrames = this.staticData.frames.filter(
                    frame =>
                        frame.frameGroup === outerFrame.frameGroup && frame.type === outerFrame.type);
                if (possibleAllowedFrames && possibleAllowedFrames.length > 0) {
                    let thickerFramesForOuter = possibleAllowedFrames.filter(
                        frame =>
                            frame.thickness >= requiredFrameThickness).sort((frame1, frame2) => {
                        return frame1.thickness - frame2.thickness;
                    });
                    if (thickerFramesForOuter && thickerFramesForOuter.length > 0) {
                        for (let i = 0; i < thickerFramesForOuter.length; i++) {
                            let glazingWithSwapedFrames = this.tryToSwapFrames(area.glazing, thickerFramesForOuter[i],
                                possibleAllowedFrames);
                            if (glazingWithSwapedFrames) {
                                area.glazing = glazingWithSwapedFrames;
                                this.growls.warning(ErrorNames.FRAMES_CHANGED_TO_FIT_GRILL,
                                    {areaIndex: area.ordinalNumber.toString()});
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    private tryToSwapFrames(glazing: Glazing, newOutsideFrame: DistanceFrameInterface,
                            possibleAllowedFrames: DistanceFrameInterface[]): Glazing {
        let newGlazing = this.copyGlazing(glazing);
        this.setOuterFrame(newGlazing, newOutsideFrame);
        if (this.isGlazingWitdhValid(newGlazing)) {
            return newGlazing;
        }
        let outerFramePosition = glazing.glazingGlassQuantity - 1;
        possibleAllowedFrames.sort((frame1, frame2) => {
            return frame2.thickness - frame1.thickness;
        });
        for (let i = 1; i < outerFramePosition; i++) {
            for (let frame1Sub of possibleAllowedFrames) {
                newGlazing['frame' + i + 'id'] = frame1Sub.id;
                if (this.isGlazingWitdhValid(newGlazing)) {
                    return newGlazing;
                }
                for (let j = i + 1; j < outerFramePosition; j++) {
                    for (let frame2Sub of possibleAllowedFrames) {
                        newGlazing['frame' + j + 'id'] = frame2Sub.id;
                        if (this.isGlazingWitdhValid(newGlazing)) {
                            return newGlazing;
                        }
                    }
                }
            }
        }
        return null;
    }

    private isGlazingWitdhValid(newGlazing: Glazing): boolean {
        let totalWidth = GlazingHelper.getGlazingTotalWidth(newGlazing, this.staticData.glasses,
            this.staticData.frames);
        let widths = GlazingHelper.parseGlazingWidths(this.getWindowSystem().glazingWidths);
        return !GlazingHelper.isGlazingWithOutOfRange(widths, totalWidth);
    }

    private setOuterFrame(glazing: Glazing, newOutsideFrame: DistanceFrameInterface): void {
        switch (glazing.glazingGlassQuantity) {
            case 4:
                glazing.frame3id = newOutsideFrame.id;
                break;
            case 3:
                glazing.frame2id = newOutsideFrame.id;
                break;
            case 2:
                glazing.frame1id = newOutsideFrame.id;
                break;
        }
    }

    private copyGlazing(glazing: Glazing): Glazing {
        return _.clone(glazing) as Glazing;
    }

    loadWindowAddons() {
        let ids = this.prepareAddedAddonsIdsForRequest();
        if (ids != null && ids.length > 0) {
            return this.addonService.getItemsByIds(ids, true).pipe(
                takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: data => this.addedWindowAddons = WindowAddonUtils.wrapAddedAddons(data, this.data, this.translate.currentLang, this.growls),
                    error: error => this.errors.handle(error),
                    complete: () => console.info('WindowDesignerComponent `loadWindowAddons` completed!')
                });
        }
    }

    private prepareAddedAddonsIdsForRequest() {
        return this.data.addons.map(addon => addon.addonId);
    }

    private processNewCreatedChanges(operationResult: OperationResult): void {
        this.markRemovedConfigurableAddons(operationResult.deletedConfigurableAddonIds);
        if (operationResult.areasChanged) {
            this.refilterGlazingBeads.emit();
        }
        operationResult.validationGrowls.forEach(message => this.growls.warning(message));
    }

    public markRemovedConfigurableAddons(deletedConfigurableAddonIds: number[]): void {
        if (deletedConfigurableAddonIds && deletedConfigurableAddonIds.length) {
            this.growls.warning(ErrorNames.CONFIG_ADDONS_AUTOMATICALLY_REMOVED);
            this.configurableAddonPositions.filter(model => _.contains(deletedConfigurableAddonIds, model.position.id))
                .forEach(model => model.configurableAddon = undefined);
            this.configurableAddonPositions = this.configurableAddonPositions.filter(
                model => !_.contains(deletedConfigurableAddonIds, model.position.assignedId));
        }
    }

    public deleteAllConfigAddons(): void {
        ConfigurableAddonUtils.deleteAllAddons(this.data, this.configurableAddonPositions);
    }

    public deleteAllWindowAddons(): void {
        this.data.addons = [];
        this.addedWindowAddons = [];
    }

    public configAddonIconClicked(): void {
        this.openConfigAddonList.emit();
    }

    private alreadySelected(elem: Snap.Element, selectedObjectRefs: any[]): boolean {
        let elemRef = this.getObjectReference(elem);
        return selectedObjectRefs.indexOf(elemRef) !== -1;
    }

    private removeSelection(elem: Snap.Element): void {
        let type = WindowParams.getSnapElemType(elem);
        if (type === WindowParams.MUNTIN_ELEM) {
            // for grill templates we must compare object refs, because elem point to single grill segment
            let newClickedElements = [];
            this.clickedSnapElements.elements.forEach(e => {
                if (!_.isEqual(this.getObjectReference(e), this.getObjectReference(elem))) {
                    newClickedElements.push(e);
                }
            });
            this.clickedSnapElements.elements = newClickedElements;
        } else {
            this.clickedSnapElements.elements.splice(this.clickedSnapElements.elements.indexOf(elem), 1);
            elem.removeClass(WindowDesignerComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        }
    }

    updateUsedGlobalSettingsFlagToChanged(): void {
        if (this.data.usedGlobalSettingsLevel) {
            this.data.usedGlobalSettingsChanged = true;
        }
    }

    getTotalBoundingClientRect(): DOMRect {
        if (this.redrawPreviewMode) {
            return new DOMRect(0, 0, 1920, 1000);
        }
        return this.totalBoundingClientRect;
    }

    protected paintPendingOperationHelper(): void {
        if (this.pendingOperationLineHelper !== undefined) {
            this.svg.add(this.pendingOperationLineHelper);
        }
    }

    protected paintSelectedTabIndicator(): void {
        if (this.selectedTabIndicator.display) {
            this.svg.circle(this.selectedTabIndicator.x, this.selectedTabIndicator.y,
                this.getOnePercentOfCanvasSize())
                .attr(WindowParams.TabIndicator.Attributes);
        }
    }

    protected paintWindowAddingButtons(): void {
        if (this.mode === Tool.SELECT && this.data.cuts.length === 0 && !this.isAnyGrillPresent()) {
            let windowSystem = this.getWindowSystem();
            if (this.data.windows.length === 0) {
                this.createAddWindowButton(this.totalBoundingBox, 10, 'center');
            } else if (windowSystem.canCombineBusinessTypes) {
                let circleSize = this.getOnePercentOfCanvasSize();
                this.createAddWindowButton(this.totalBoundingBox, circleSize, 'top');
                this.createAddWindowButton(this.totalBoundingBox, circleSize, 'right');
                this.createAddWindowButton(this.totalBoundingBox, circleSize, 'bottom');
                this.createAddWindowButton(this.totalBoundingBox, circleSize, 'left');
            }
        }
    }

    protected paintCutButtons(): void {
        let circleSize = this.getOnePercentOfCanvasSize();
        let cutPoints = this.pendingCut.points;
        if (cutPoints.length === 4) {
            let x1 = cutPoints[0];
            let y1 = cutPoints[1];
            let x2 = cutPoints[2];
            let y2 = cutPoints[3];

            let cx = (x2 + x1) / 2;
            let cy = (y2 + y1) / 2;

            this.svg.line(x1, y1, x2, y2).attr({
                stroke: '#000000'
            });

            let angle = DrawingUtil.atan2normalized(y2 - y1, x2 - x1);
            let dist = circleSize * 4.1;

            this.createCutSideButton(Math.sin(angle) * dist + cx, -Math.cos(angle) * dist + cy, circleSize, 'top');
            this.createCutSideButton(-Math.sin(angle) * dist + cx, Math.cos(angle) * dist + cy, circleSize,
                'bottom');
        }
    }

    protected paintGuidancePoints(polygonMap: Map<AreaSpecification, number[]>,
                                  polygonMapFull: Map<AreaSpecification, PolygonPoint[]>): void {
        if (this.shouldPaintGuidancePoints()) {
            let guidancePoints = this.guides.generateGuidancePoints(this.mode, polygonMap, polygonMapFull,
                this.pendingGrillData, this.staticData.grills);
            guidancePoints.forEach(guidancePoint => {
                let guidanceCircle = this.svg.circle(guidancePoint.x, guidancePoint.y,
                    this.getOnePercentOfCanvasSize());
                guidanceCircle.attr(WindowParams.GuidanceCircle.Attributes);
                if (this.mode === Tool.CUT) {
                    guidanceCircle.click(event => this.onFrameClickCutHandler(event));
                }
                if (this.mode === Tool.GRILL || this.mode === Tool.MULLION) {
                    guidancePoint.origin.breadcrumb.forEach(bc => {
                        guidanceCircle.data(bc.key, bc.object);
                    });
                    guidanceCircle.addClass(guidancePoint.origin.type);
                    guidanceCircle.click(event => this.onClickLineGrillHandler(event));
                }
            });
        }
    }

    private shouldPaintGuidancePoints(): boolean {
        return (this.mode === Tool.CUT && this.pendingCut.points.length !== 4)
            || this.mode === Tool.GRILL
            || this.mode === Tool.MULLION;
    }

    private reportBusinessTypeNameMissing(businessType: BusinessType) {
        let lang = new AffectedElement();
        lang.translationKey = this.translate.currentLang;
        let type = new AffectedElement();
        type.translatedKeys = businessType.names;
        type.showAllLanguages = true;
        this.errorReportService.reportError(ErrorCategory.BUSINESSTYPE_NAME_NOT_SET, [lang, type]).subscribe({
            next: () => console.info('ErrorBrowserComponent `reportError` completed!'),
            error: error => this.errors.handle(error)
        });
    }

    public getWindowSystem(): WindowSystemDefinition {
        return this.windowSystem != undefined ? this.windowSystem : new WindowSystemDefinition();
    }

    isPricingTabOpen(): boolean {
        return this.isPricingCurrentTab;
    }

    showError(error: string, params?: MessageParams): void {
        this.growls.error(error, params);
    }

    protected getCurrentLanguage(): keyof MultilanguageField {
        return this.translate.currentLang as keyof MultilanguageField;
    }

    getEmptyBoundingBox(): MinMaxXY {
        let svgContainer = document.getElementById('window-svg-container');
        if (svgContainer != undefined) {
            return new MinMaxXY(0, svgContainer.clientWidth, 0, svgContainer.clientHeight);
        }
        return new MinMaxXY(0, 0, 0, 0);
    }

    canClickGuides(): boolean {
        return !this.readOnlyMode;
    }

    isValidationDisabled(): boolean {
        return this.offerPosition != undefined && this.offerPosition.validationDisabled;
    }

    protected paintDebugElements(params: PainterParams) {
        let copiedData: DrawingData = JSON.parse(JSON.stringify(this.data));
        copiedData['__ABAKUS_DEBUG__'] = 1;
        const enhanced = PricingUtils.enhanceForPricing(copiedData, undefined, this.profileCompositionDistances);

        const visualizePoint = (p: PolygonPoint, color: string): void => {
            ScalingPainter.circle(this.svg, [p.x, p.y, 10], {
                fill: color
            }, params);
        };

        const visualizePolygon = (poly: number[], color: string): void => {
            ScalingPainter.path(this.svg, poly, {
                fill: 'none',
                stroke: color,
                strokeWidth: '4'
            }, params);
        };

        const pointsWithNonstandardAngles: PolygonPoint[] = enhanced['__ABAKUS_NONSTANDARD_ANGLE_FRAME_POINTS__'];
        pointsWithNonstandardAngles.forEach(p => visualizePoint(p, '#FF0000'));

        for (let w of enhanced.windows) {
            for (let sw of w.subWindows) {
                const swPointsWithNonstandardAngles: PolygonPoint[] = sw['__ABAKUS_NONSTANDARD_WING_POINTS__'] || [];
                swPointsWithNonstandardAngles.forEach(p => visualizePoint(p, '#FF7F00'));

                const swMullions: number[][] = sw['__ABAKUS_MULLIONS__'] || [];
                swMullions.forEach(poly => visualizePolygon(poly, '#7F00FF'));
            }
        }

        const fakeMullions: number[][] = enhanced['__ABAKUS_MULLIONS__'] || [];
        fakeMullions.forEach(poly => visualizePolygon(poly, '#0000FF'));
    }

    notifySizeChanged(oldBoundingBox: MinMaxXY, newBoundingBox: MinMaxXY): void {
        this.onTotalSizeChange.emit({
            oldWidth: oldBoundingBox.maxX - oldBoundingBox.minX,
            oldHeight: oldBoundingBox.maxY - oldBoundingBox.minY,
            newWidth: newBoundingBox.maxX - newBoundingBox.minX,
            newHeight: newBoundingBox.maxY - newBoundingBox.minY
        });
    }

    setDefaultDecorativeGlazingData(window: number, glassQuantity: number) {
        const glazing = this.windowSystem.decorativeGlazingPackage['glazing' + glassQuantity];
        for (let i = 1; i <= 4; i++) {
            this.setAttributeValueInAllAreas(window, 'glazing', 'glass' + i + 'id', glazing['glass' + i + 'id'], true);
            if (i !== 4) {
                this.setAttributeValueInAllAreas(window, 'glazing', 'frame' + i + 'id', glazing['frame' + i + 'id'], true);
            }
        }
        this.setAttributeValueInAllAreas(window, 'glazing', 'glazingGlassQuantity', glassQuantity, true);
    }
}
