<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'CONFIG_SLIDER_ELEMENT.TITLE.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button (onClick)="showHideFilters()"
                                   buttonId="toggle-filter-button"
                                   icon="filter_list" label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}" type="filter"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToCopy()" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   buttonId="copy" icon="content_copy" label="{{ 'CONFIG_SLIDER_ELEMENT.TITLE.COPY' | translate }}"
                                   type="action"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToAdd()" [hotkey]="newElementHotkey" [size]="40"
                                   align="right" buttonId="add" icon="add" label="{{ 'CONFIG_SLIDER_ELEMENT.TITLE.ADD' | translate }}"
                                   type="main-action"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt (onLazyLoad)="loadItemsLazy($event)" (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedItem" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 [lazy]="true" [paginator]="true" [responsive]="true" [rowsPerPageOptions]="rowsPerPageOptions"
                 [rows]="chosenRowsPerPage" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [totalRecords]="totalRecords" [value]="itemList" id="configTypeDescriptorsTable"
                 selectionMode="single">
        <p-column [filter]="showFilters" [sortable]="true" field="symbol"
                  filterField="symbol" filterMatchMode="contains" header="{{ 'CONFIG_SLIDER_ELEMENT.FORM.SYMBOL' | translate }}"
                  sortField="symbol">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.symbol }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="configSystemName"
                  filterField="configSystemName" filterMatchMode="contains" header="{{ 'CONFIG_SLIDER_ELEMENT.FORM.CONFIG_SYSTEM_NAME' | translate }}"
                  sortField="{{ 'configSystemName.' + translate.currentLang }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.configSystemName[translate.currentLang] }}
            </ng-template>
        </p-column>

        <p-column [sortable]="true" field="sortIndex" header="{{ 'CONFIG_SLIDER_ELEMENT.FORM.SORT_INDEX' | translate }}"></p-column>

        <p-column [defaultFilterValue]="defaultActiveFilter" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters"
                  field="active" header="{{ 'CONFIG_SLIDER_ELEMENT.FORM.STATUS' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'CONFIG_SLIDER_ELEMENT.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'CONFIG_SLIDER_ELEMENT.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard (onCancel)="cancel()" (onComplete)="submit()" *ngIf="newItem"
            header="{{ 'CONFIG_SLIDER_ELEMENT.TITLE.ADD' | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_SLIDER_ELEMENT.STEPS.DATA' | translate }}">
        <app-config-slider-element-form [item]="item" [configSystems]="configSystems" [validationErrors]="validationErrors"
        [image]="file" (imageLargeChange)="file = $event"></app-config-slider-element-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog (onCancel)="cancel()" (onComplete)="submit()"
                   *ngIf="!newItem && displayDialog" contentStyleClass="scrolled-dialog-60vw" header="{{  (copyMode ? 'CONFIG_SLIDER_ELEMENT.TITLE.COPY' : 'CONFIG_SLIDER_ELEMENT.TITLE.EDIT') | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_SLIDER_ELEMENT.STEPS.DATA' | translate }}">
        <app-config-slider-element-form [item]="item" [configSystems]="configSystems" [validationErrors]="validationErrors"
                                        [image]="file" (imageLargeChange)="file = $event"></app-config-slider-element-form>
    </app-wizard-step>
</app-wizard-dialog>
