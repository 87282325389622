import {ChangeDetectorRef, Directive, Injector, Input, OnInit, Type} from "@angular/core";
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import * as _ from 'underscore';
import {CascadeValueCalculator} from '../../common/cascade-value-calculator';
import {CrudCommonComponent} from "../../common/crud-common/crud.component";
import {CrudService} from "../../common/crud-common/crud.service";
import {CrudItem} from '../../common/crud-common/crudItem';
import {ValidationErrorsHelper} from "../../common/ValidationErrorsHelper";
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from './profit-margin/profitMargin';

export interface HasSaveTable {
    saveTable(id?: number): Observable<void>;
}

export type OwningEntityType = 'subsystemGroup' | 'subsystem' | 'clientGroup' | 'client';

@Directive()
export abstract class AbstractProfitMarginComponent<Item extends CrudItem, Service extends CrudService<Item>>
    extends CrudCommonComponent<Item, Service> implements OnInit {

    globalProfitMargin: string;
    editedItem;
    showTable: boolean;
    backupedRowData: any;
    editedRowDataRef: any;
    editedList: any[];
    pushToGroupMembers: boolean;

    @Input()
    entityType: OwningEntityType;

    protected constructor(injector: Injector,
                          changeDetector: ChangeDetectorRef,
                          serviceType: Type<Service>,
                          translationKey: string,
                          entityName: string) {
        super(injector, changeDetector, false, serviceType, translationKey, entityName);
        this.showTable = false;
    }

    ngOnInit() {
        // dont call super.ngOnInit here because we dont want this component to capture hotkeys
        this.globalProfitMargin = "";
        this.fromRecord = 1;
        this.toRecord = this.getRowsPerPageValue();
        this.editedList = [];
    }

    abstract getDatatable(): DataTable;

    submit() {
    }

    showDialogToAdd() {
    }

    prepareItemForRequest() {
        return this.editedItem;
    }

    beforeEdit(event) {
        // clone to save data before edit
        this.backupedRowData = _.clone(event.data);
        // assign to have reference to edited value (will contain current value after save)
        this.editedRowDataRef = event.data;
    }

    editProfitMargin(event?: { data: Item }): void {
        this.loadEdited(event);
        let valueString = normalizeProfitMarginValueString(this.editedItem.valueString);
        if (!isValidProfitMarginValueString(valueString)) {
            this.editedRowDataRef.valueString = this.backupedRowData.valueString;
        } else {
            this.editedItem.valueString = valueString;
            this.editedRowDataRef.valueString = valueString;
            this.editedItem.value = CascadeValueCalculator.calculateDiscountValue(valueString);
            this.editedRowDataRef.value = CascadeValueCalculator.calculateDiscountValue(valueString);
        }
        this.unselectRow();
    }

    updateTotalPageCount() {
        this.totalRecords = this.getDatatable().totalRecords;
        if (this.fromRecord > this.totalRecords) {
            this.fromRecord = 1;
        }
        this.toRecord = this.getDatatable().rows + this.fromRecord - 1;
        if (this.toRecord > this.totalRecords) {
            this.toRecord = this.totalRecords;
        }
    }

    public setPages(event: any): void {
        this.fromRecord = event.first + 1;
        this.toRecord = this.getDatatable().rows + this.fromRecord - 1;
        if (this.toRecord > this.totalRecords) {
            this.toRecord = this.totalRecords;
        }
    }

    applyGlobalProfitMargin(event?: Event) {
        event.stopPropagation();
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);
        this.globalProfitMargin = normalizeProfitMarginValueString(this.globalProfitMargin);
        if (isValidProfitMarginValueString(this.globalProfitMargin)) {
            let listToApply;
            if (this.getDatatable().filteredValue) {
                listToApply = this.getDatatable().filteredValue;
            } else {
                listToApply = this.itemList;
            }
            for (let i = 0; i < listToApply.length; i++) {
                listToApply[i].valueString = this.globalProfitMargin;
                // should use calculateMarginValue after this is fixed on backend
                listToApply[i].value = CascadeValueCalculator.calculateDiscountValue(this.globalProfitMargin);
            }
            this.globalProfitMargin = "";
        } else {
            this.validationErrors['globalMarginUpdateInput'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
        }
        this.changeDetector.markForCheck();
    }

    protected composeEditedList() {
        for (let i = 0; i < this.itemList.length; i++) {
            if (typeof (this.itemList[i]) === "object") {
                const obj = {
                    data: this.itemList[i]
                };
                this.beforeEdit(obj);
                this.editProfitMargin(obj);
                if (this.editedItem.id != undefined || this.editedItem.valueString != undefined) {
                    this.editedList.push(JSON.parse(JSON.stringify(this.editedItem)));
                }
            }
        }
    }

    protected unselectRow() {
        this.getDatatable().selection = null;
        this.getDatatable().selectionChange.emit(null);
    }

    abstract loadEdited(event: { data: Item });
}
