import {VisibilitySettings} from "../../../../../../../window-designer/utils/VisibilitySettings";

export const standaloneGlazingPackageWindowDesignerVisibilitySettings = (() => {
    const visibilitySettings = new VisibilitySettings();
    visibilitySettings.coordinatesValues = false;
    visibilitySettings.dimensionValues = false;
    visibilitySettings.totalDimensionLines = false;
    visibilitySettings.frameDimensionLines = false;
    visibilitySettings.mullionPositions = false;
    visibilitySettings.muntinPositions = false;
    visibilitySettings.toolDimensionLines = false;
    visibilitySettings.pointerPosition = false;
    visibilitySettings.angleValues = true;
    visibilitySettings.configAddonIcons = false;
    visibilitySettings.quarterNames = false;
    visibilitySettings.areaNumbers = false;
    visibilitySettings.leadingSubwindowIndicator = false;
    return visibilitySettings;
})();
