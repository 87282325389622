import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map} from 'rxjs/operators';
import {Permissions} from "../../auth/permission.service";
import {CommonErrorHandler} from '../../common/CommonErrorHandler';
import {CrudCommonComponent} from "../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../common/dataServiceHelper";
import {Country} from '../../common/enums/country';
import {CrudComponent} from "../../common/service/crud.component";
import {DataTableColumnBuilder} from "../../common/service/data.table.column.builder";
import {ExportComponent} from "../../common/service/export.component";
import {TranslatedSelectItemService} from '../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../common/service/translated.select.item";
import {ValidationService} from "../../common/service/validation.service";
import {CatalogTab} from '../window-system/window-system.component';
import {Supplier, SupplierMotlawaIntegrationInfo} from "./supplier";
import {SupplierService} from "./supplier.service";

@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['../shared-styles.css', './supplier.component.css'],
    providers: [SupplierService, DataServiceHelper, ValidationService, ExportComponent, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierComponent extends CrudComponent implements OnInit {

    supplier: Supplier;
    motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo;
    suppliers: Supplier[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    roles;

    displayDialog = false;
    lastLoadEvent: LazyLoadEvent;
    selectedSupplier: Supplier;
    filterCountry: Observable<SelectItem[]>;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;

    subsituteSuppliers: SelectItem[];
    displaySubstitueSupplierDialog: boolean;

    @ViewChild('dt', {static: true}) datatable;

    constructor(public permissions: Permissions,
                public router: Router,
                public supplierService: SupplierService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'SupplierComponent', false);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    getExportData(): Observable<object[]> {
        return this.supplierService.getSuppliers(0, undefined, this.lastLoadEvent.filters, this.lastLoadEvent.sortField,
            this.lastLoadEvent.sortOrder).pipe(map(listing => listing.data), map(supplierData => {
            supplierData.forEach(supplier => {
                (supplier as any).active = this.getTranslatedLabelForAttrValue(supplier, "active");
            });
            return supplierData;
        }));
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterCountry = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', '');
        this.filterActive = CrudCommonComponent.buildActiveDropdown("GENERAL.STATUS_SINGULAR");
        this.defaultActiveFilter = this.filterActive[1];
        this.createTable();
    }

    loadSuppliersLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.lastLoadEvent = event;
        return this.supplierService.getSuppliers(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.totalRecords = data.totalRecords;
                    this.suppliers = data.data;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedSupplier = this.restoreSelectionAfterLoad(this.selectedSupplier, this.suppliers, event);
                },
                error: error => {
                    console.error('SupplierComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('SupplierComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    cancel() {
        this.setDisplayDialog(false);
        this.setDisplaySubstitueSupplierDialog(false);
        this.subsituteSuppliers = [];
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedSupplier);
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.supplier = new Supplier();
        this.prepareDataForSupplier(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    getSupplier(supplierId: number) {
        if (supplierId) {
            forkJoin({
                supplier: this.supplierService.getSupplier(supplierId),
                motlawaIntegrationInfo: this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']})
                    ? this.supplierService.getSupplierMotlawaIntegrationInfo(supplierId)
                    : of<SupplierMotlawaIntegrationInfo>(undefined)
            }).subscribe({
                next: data => {
                    this.supplier = data.supplier;
                    this.motlawaIntegrationInfo = data.motlawaIntegrationInfo;
                    this.setDisplayDialog(true);
                },
                error: error => {
                    this.errors.handle(error);
                },
                complete: () => {
                    console.debug('getSupplier` completed!');
                }
            });
        } else {
            this.setDisplayDialog(true);
        }
    }

    prepareDataForSupplier(supplierId: number) {
        this.getSupplier(supplierId);
    }

    hideSupplierDetails() {
        this.loadSuppliersLazy(this.lastLoadEvent);
        this.setDisplayDialog(false);
        this.setDisplaySubstitueSupplierDialog(false);
        this.subsituteSuppliers = [];
    }

    submit(): void {
    }

    actionOnClick(actionName: string, actionSupplier: Supplier) {
        switch (actionName) {
            case 'SHOW_ADDONS':
                return this.navigateToCatalog('addons', actionSupplier.id);
            case 'SHOW_SYSTEMS':
                return this.navigateToCatalog('windowSystems', actionSupplier.id);
        }
    }

    private navigateToCatalog(tabName: CatalogTab, supplierId) {
        this.router.navigate(['features/window-system', {
            tabName: tabName,
            supplierId: supplierId
        }]);
        return false;
    }

    showFailedToMoveProductsMsg() {
        this.growlMessageController.info('SUPPLIER-DETAILS.FAILED_TO_MOVE_PRODUCTS');
    }

    showFailedToSaveSupplierMsg() {
        this.growlMessageController.error('SUPPLIER-DETAILS.FAILED_TO_SAVE_SUPPLIER');
    }

    showUpdateSuccessMessage() {
        this.growlMessageController.info('SUPPLIER-DETAILS.SUPPLIER_UPDATED');
    }

    showSelectNewSupplierDialog(substituteSuppliers: Supplier[]) {
        this.setDisplayDialog(false);

        this.subsituteSuppliers = substituteSuppliers.map(supplier => {
            return {label: supplier.companyName, value: supplier};
        });

        this.setDisplaySubstitueSupplierDialog(true);
    }

    showNoSuitableSupplierError() {
        this.growlMessageController.error('SUPPLIER-DETAILS.NO_SUBSTITUTE_SUPPLIER');
        this.changeDetector.markForCheck();
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('id', 'SUPPLIER-DETAILS.FORM.ID', true).makeFilterable().makeSortable()
            .add('companyName', 'SUPPLIER-DETAILS.FORM.COMPANY-NAME', true).makeFilterable().makeSortable()
            .add('address.street', 'SUPPLIER-DETAILS.FORM.ADDRESS.STREET', true).makeFilterable().makeSortable()
            .add('address.city', 'SUPPLIER-DETAILS.FORM.ADDRESS.CITY', true).makeFilterable().makeSortable()
            .add('address.zip', 'SUPPLIER-DETAILS.FORM.ADDRESS.ZIP', true).makeFilterable().makeSortable()
            .add('address.country', 'SUPPLIER-DETAILS.FORM.ADDRESS.COUNTRY', true).makeFilterable().makeSortable()
            .add('contact.name', 'SUPPLIER-DETAILS.FORM.CONTACT.NAME', true).makeFilterable().makeSortable()
            .add('contact.phone', 'SUPPLIER-DETAILS.FORM.CONTACT.PHONE', true).makeFilterable().makeSortable()
            .add('contact.email', 'SUPPLIER-DETAILS.FORM.CONTACT.EMAIL', true).makeFilterable().makeSortable()
            .add('contact.fax', 'SUPPLIER-DETAILS.FORM.CONTACT.FAX', true).makeFilterable().makeSortable()
            .add('active', 'SUPPLIER-DETAILS.FORM.STATUS', true).setFilterValues(this.filterActive)
            .setDefaultFilterValue(this.defaultActiveFilter);

        super.init(builder.build());
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    private setDisplaySubstitueSupplierDialog(display: boolean): void {
        if (this.displaySubstitueSupplierDialog !== display) {
            this.displaySubstitueSupplierDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    showDialogToAdd(): void {
        this.router.navigate(['/features/create-supplier']);
    }

    onUpdateSupplierSuccess(): void {
        this.hideSupplierDetails();
        this.showUpdateSuccessMessage();
    }
}
