import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MailBoxConfiguration} from '../subsystem-webshop-mail-configuration/mail-box-configuration';

@JsonDeserializable
export class MailSettings extends MailBoxConfiguration {
    senderAddress: string;
    bccAddresses: string;

    static fromJSON(jsonObject: any): MailSettings {
        const settings = new MailSettings();
        Object.assign(settings, MailBoxConfiguration.fromJSON(jsonObject));
        settings.senderAddress = jsonObject['senderAddress'];
        settings.bccAddresses = jsonObject['bccAddresses'];
        return settings;
    }
}
