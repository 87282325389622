<div class="new-form-600">
    <ng-container *ngIf="areaBarsOptions != undefined">
        <app-radio-button inputId="areaBarCode"
                          [(validationMessageKey)]="validationErrors['productConfiguration.areaBarCode']"
                          [options]="areaBarsOptions" [required]="true"
                          [(ngModel)]="item.productConfiguration.areaBarCode">
            <ng-template pTemplate="label"></ng-template>
            <ng-template pTemplate="option-label" let-webshopAreaBarsCode>
                <img class="webshop-area-bars-image" [src]="getIcon(webshopAreaBarsCode.value)"
                     [alt]="webshopAreaBarsCode.label">
            </ng-template>
        </app-radio-button>
    </ng-container>
</div>
