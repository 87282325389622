<div class="new-form-600">
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'PROMOTION_INFORMATION.FORM.VALID_FROM' | translate }}"
                      [maxDate]="promotionInformation.showExpiresSoonDate"
                      [(validationMessageKey)]="validationErrors['validFrom']" [required]="true" [showTime]="true"
                      [(ngModel)]="promotionInformation.validFrom"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="showExpiresSoonDate"
                      label="{{ 'PROMOTION_INFORMATION.FORM.SHOW_EXPIRES_SOON_DATE' | translate }}"
                      [minDate]="promotionInformation.validFrom" [maxDate]="promotionInformation.validTo"
                      [(validationMessageKey)]="validationErrors['showExpiresSoonDate']" [required]="true"
                      [showTime]="true" [(ngModel)]="promotionInformation.showExpiresSoonDate"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validTo" label="{{ 'PROMOTION_INFORMATION.FORM.VALID_TO' | translate }}"
                      [minDate]="promotionInformation.showExpiresSoonDate"
                      [(validationMessageKey)]="validationErrors['validTo']" [required]="true" [showTime]="true"
                      [(ngModel)]="promotionInformation.validTo"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'PROMOTION_INFORMATION.FORM.ACTIVE' | translate }}"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="promotionInformation.active"></app-checkbox>
    </div>
    <div class="new-form-row" *ngFor="let language of supportedLanguages">
        <app-text-area [inputId]="'text.' + language.code" [label]="language.name | translate"
                       [validationMessageKey]="getTextErrorKey(validationErrors['text[' + language.code + ']'])"
                       (validationMessageKeyChange)="validationErrors['text[' + language.code + ']'] = $event"
                       [maxlength]="150" [(ngModel)]="promotionInformation.text[language.code]"></app-text-area>
    </div>
</div>
