import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {UpsellingProposition} from "./upselling-proposition";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";

@Injectable()
export class UpsellingPropositionService {

    private static readonly API_URL = 'upsellingProposition';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
        sortColumn: string, sortOrder: number): Observable<Listing<UpsellingProposition>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<UpsellingProposition>>(UpsellingPropositionService.API_URL, {params: params});
    }

    public getItem(id: number): Observable<UpsellingProposition> {
        return this.http.get<UpsellingProposition>(`${UpsellingPropositionService.API_URL}/${id}`);
    }

    public addItem(item: UpsellingProposition, file?: File): Observable<number> {
        return this.save(item, file);
    }

    public editItem(id: number, item: UpsellingProposition, file?: File): Observable<number> {
        return this.save(item, file);
    }

    private save(item: UpsellingProposition, file?: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('upsellingProposition', new Blob([body], {type: 'application/json'}));
        if (file) {
            formData.append('file', file);
        }
        return this.http.post<number>(UpsellingPropositionService.API_URL, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(UpsellingPropositionService.API_URL, response)));
    }

    getImageAsFile(id: number): Observable<File> {
        return this.http.get(`${UpsellingPropositionService.API_URL}/${id}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImage(id: number): Observable<string> {
        return this.http.get(`${UpsellingPropositionService.API_URL}/${id}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    setAvailabilityInSubsystem(ids: number[], available: boolean): Observable<void> {
        const headers = this.dataServiceHelper.prepareHeaders({ids, available});
        return this.http.post<void>(`${UpsellingPropositionService.API_URL}/setAvailabilityInSubsystem`, {}, {headers});
    }

    validateData(item: UpsellingProposition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${UpsellingPropositionService.API_URL}/validateData`, item));
    }

    validateCharges(item: UpsellingProposition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${UpsellingPropositionService.API_URL}/validateCharges`, item));
    }

    validateGlazings(item: UpsellingProposition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${UpsellingPropositionService.API_URL}/validateGlazings`, item));
    }

    validateConfigAddons(item: UpsellingProposition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${UpsellingPropositionService.API_URL}/validateConfigAddons`, item));
    }

    getAvailableInSubsystem(): Observable<number[]> {
        return this.http.get<number[]>(`${UpsellingPropositionService.API_URL}/available`);
    }
}
