export class FinalizeBulkChange {

    bulkChangeId: number;
    excludedPositionIds: number[];

    constructor(bulkChangeId: number,
                excludedPositionIds: number[]) {
        this.bulkChangeId = bulkChangeId;
        this.excludedPositionIds = excludedPositionIds;
    }
}
