import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OnceFlag} from '../../shared/once-flag';

@Component({
    selector: 'app-confirm-action-dialog',
    templateUrl: './confirm-action-dialog.component.html'
})
export class ConfirmActionDialogComponent {

    @Input() header: string;
    @Input() question: string;
    @Input() questions: string[];
    @Input() confirmButtonLabel: string;
    @Input() discardButtonLabel: string;
    @Input() dialogId: string;

    @Output() onConfirmed = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    private readonly dialogHideHelper = new OnceFlag();

    cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }

    confirm() {
        this.dialogHideHelper.call(() => this.onConfirmed.emit());
    }
}
