import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WindowSystemTypeData} from "../../../../window-designer/catalog-data/window-system-interface";
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudItem} from '../../../common/crud-common/crudItem';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ListOfIds} from '../../ListOfIds';
import {WindowEditorWindowSystemInterface} from '../../offer/window-editor/window-editor-window-system-interface';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {BusinessType} from './BusinessType';
import {BusinessTypeBasic} from "./BusinessTypeBasic";
import {BusinessTypeList} from './BusinessTypeList';

@Injectable()
export class BusinessTypeService extends SingleSystemCheckboxCrudService<BusinessType> {

    private static readonly API_URL = 'businessTypes';

    constructor(http: HttpClient) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<BusinessTypeList> {

        let params = new DataServiceHelper().prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<BusinessTypeList>(BusinessTypeService.API_URL, {params: params});
    }

    getItem(itemId: number): Observable<BusinessType> {
        return undefined;
    }

    addItem(item: BusinessType): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: BusinessType): Observable<number> {
        return undefined;
    }

    edit(item: CrudItem, linkedSystemIds: ListOfIds): Observable<number> {
        let formData = new FormData();
        formData.append('businessType', new Blob([JSON.stringify(item)], {
            type: 'application/json'
        }));
        formData.append('linkedSystemIds', new Blob([JSON.stringify(linkedSystemIds)], {
            type: 'application/json'
        }));
        return this.http.post<void>(`${BusinessTypeService.API_URL}/${item.id}`, formData).pipe(map(() => item.id));
    }

    getBusinessTypesByWindowSystem(windowSystemId: number): Observable<BusinessType[]> {
        return this.http.get<Listing<BusinessType>>(`system/${windowSystemId}/business-types`)
            .pipe(map(data => data.data));
    }

    getWindowSystemsByBusinessTypes(businessTypes: string[],
                                    systemTypes: WindowSystemTypeData[]): Observable<WindowEditorWindowSystemInterface[]> {
        let params: { [param: string]: string | string[] } = {};
        params['businessTypes'] = businessTypes.join();
        params['systemTypes'] = systemTypes.map(t => t.type).join();
        return this.http.get<WindowEditorWindowSystemInterface[]>(`${BusinessTypeService.API_URL}/linked`, {params: params});
    }

    validate(businessType: BusinessType) {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${BusinessTypeService.API_URL}/validate`, businessType));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${BusinessTypeService.API_URL}/names`, {params: params});
    }

    getTypesForAddDialog(): Observable<BusinessTypeBasic[]> {
        return this.http.get<BusinessTypeBasic[]>(`${BusinessTypeService.API_URL}/forAddDialog`);
    }
}
