<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>

        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <span class="new-form-field-error-icon new-form-field-error-icon-select material-icons">error</span>
    <div *ngIf="!hasValidationError" class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
    <div *ngIf="noLongerAvailable" class="new-form-field-error-message">
        {{ 'ERROR.DESIGNER.NO_LONGER_AVAILABLE' | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container">
        <div *ngIf="fakeSelect" class="fake-select-overlay" (click)="handleClickIfFakeSelect($event)"></div>
        <select #select [attr.id]="inputId" [disabled]="disabled || fakeSelect" (change)="handleChange($event)"
                (focus)="handleFocus($event)" (blur)="handleBlur($event)" (click)="handleClick($event)"
                [ngClass]="{'fake-select-unlock': fakeSelect && !disabled}">
            <option *ngIf="!allowSelectingNone && value == undefined" [value]="NO_SELECTION_MARKER" selected hidden>
                {{ placeholder }}
            </option>
            <option *ngFor="let option of formattedOptions; trackBy: optionTracker; let i = index"
                    [disabled]="option.disabled || (checkAvailability && !option.available)"
                    [value]="option.nativeValue"
                    [ngStyle]="{'font-weight': option.bold ? '600' : undefined, 'text-align': option.center ? 'center' : undefined}"
                    [ngClass]="{'fake-select-option-unlock': fakeSelect && !disabled}">
                {{ translateLabels ? (option.label | translate) : option.label }}
            </option>
        </select>
        <ng-container *ngIf="validationMessageKey != undefined || hasValidationError || noLongerAvailable">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
