<div id="designer-container">
    <app-drawing-tool-controls [offer]="offer" [productionOrder]="productionOrder" [readOnlyMode]="readOnlyMode"
                               [mode]="DrawingToolControlsMode.GATE" (onExit)="exit()"
                               (onSaveAndExit)="saveAndExit()"></app-drawing-tool-controls>

    <app-exit-without-saving-confirmation-dialog [visible]="showExitWithoutSavingConfirmationDialog"
                                                 (hideDialog)="showExitWithoutSavingConfirmationDialog = false"
                                                 (exitWithoutSaving)="exitWithoutSaving()"></app-exit-without-saving-confirmation-dialog>

    <app-add-gate-dialog *ngIf="showAddDialog && gates != undefined" [displayDialog]="showAddDialog"
                         [suppliers]="suppliers" [items]="gates" [catalogConfiguration]="catalogConfiguration"
                         [largeImageGetter]="largeImageGetter()"
                         [data]="data" (dialogClosed)="gateSystemSelected($event)"></app-add-gate-dialog>

    <app-window-component-preview-dialog
            [(previewData)]="windowComponentPreviewData"></app-window-component-preview-dialog>

    <app-missing-profit-margin-handler></app-missing-profit-margin-handler>

    <span class="view-label view-label-gate" [ngClass]="{ 'sidebar-hidden': !showSidebar }">{{ 'WINDOW_PREVIEW.VIEW_FROM_OUTSIDE_LABEL' | translate }}</span>
    <div class="preview-container">
        <span *ngIf="showGateDisclaimer" class="view-label disclaimer">{{ 'GATE_PREVIEW.DISCLAIMER' | translate }}</span>
        <div class="gates-preview-container gate-cross-view"><div #gatePainter app-gate-painter [imageTemplate]="railSystemImageTemplate"></div></div>
        <div class="gates-preview-container gate-front-view"><svg app-gate-print-painter (xmlOutput)="printXMLs.next($event)" height="100%"></svg></div>
    </div>

    <!-- Details panel (sidebar) -->
    <div id="details-panel" *ngIf="catalogLoadedForGateSystemId != undefined"
         [ngClass]="{ 'hide-sidebar': !showSidebar }">
        <div id="details" [hidden]="!showSidebar">
            <p-tabView id="details-tabs" (activeIndexChange)="handleSidebarActiveIndexChange($event)">
                <!--All-->
                <p-tabPanel header="{{ 'OFFER.TABS.GENERAL' | translate }}" class="sidebar-entries"
                            [headerStyleClass]="generalTabContainsErrors() ? 'errors-on-tab': ''">
                    <app-gate-sidebar [position]="offerPosition != undefined ? offerPosition : productionOrderPosition"
                                      [addonCategoryGroups]="visibleAddonCategoryGroups" [fields]="fieldUsage" [data]="data"
                                      [selectedGateSystem]="selectedGateSystem" [validationErrors]="validationErrors"
                                      [readOnlyMode]="readOnlyMode" [colors]="colors" [defaultsLevels]="defaultsLevels"
                                      [(defaultsLevel)]="defaultsLevel"
                                      [(defaultsOverrideLowerLevel)]="defaultsOverrideLowerLevel"
                                      (onGateSystemChange)="openChangeSystemDialog()"
                                      (afterFieldBlur)="handleSidebarFieldBlur($event)"
                                      (afterFieldChange)="handleSidebarFieldChange($event)"
                                      (onShowDescriptionDialog)="onShowDescriptionDialog()"
                                      (onShowAddonsDialog)="onShowAddonsDialog()"
                                      (onShowImage)="windowComponentPreviewData = $event"
                                      (defaultsSavedClick)="handleDefaultsSavedClick()"></app-gate-sidebar>
                </p-tabPanel>

                <!--Pricing-->
                <p-tabPanel header="{{ 'OFFER.TABS.PRICING.TITLE' | translate }}"
                            [headerStyleClass]="getTabpanelHeaderStyle(pricingStatus) + ' pricingTab'">
                    <app-pricing #pricing [offer]="offer" [productionOrder]="productionOrder"
                                 (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, false)"
                                 (setErrorToPricingStatus)="pricingStatus.errors = true" [readOnlyMode]="readOnlyMode"
                                 [isGate]="true"></app-pricing>
                </p-tabPanel>

                <!--Validation-->
                <p-tabPanel header="{{ 'OFFER.TABS.VALIDATION.TITLE' | translate }}"
                            [headerStyleClass]="getTabpanelHeaderStyle(backendValidationStatus)">
                    <app-sidebar-validation [pricingProducts]="pricing.pricing?.products || []"
                                            [validationMessages]="pricing.pricing?.validationMessages || []"
                                            [readOnlyMode]="readOnlyMode"
                                            (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, true)"
                                            (setErrorToValidationStatus)="backendValidationStatus.errors = true"></app-sidebar-validation>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="spacer" *ngIf="!showSidebar"></div>
        <app-sidebar-hider [showSidebar]="showSidebar" [sidebarOnlyMode]="false"
                           (onClick)="showSidebar = !showSidebar"></app-sidebar-hider>
    </div>

    <app-addons-tab-view *ngIf="dialogs.addons" [systemId]="data.gateSystemId" [windowAddonData]="data"
                         (onSubmit)="windowAddonsSave($event)" (onClose)="closeAddonsDialog()"
                         (onRemove)="windowAddonsRemove($event)"
                         [addedWindowAddons]="addedGateAddons"
                         [offerId]="offer.id"
                         [offerPositionId]="offerPosition?.id"
                         [systemType]="SystemType.GATE"
                         [readOnlyMode]="readOnlyMode">
    </app-addons-tab-view>

    <app-show-description *ngIf="dialogs.description" [visible]="true"
                          [position]="offerPosition" [readOnlyMode]="readOnlyMode"
                          [persistOnSave]="false"
                          (onClose)="dialogs.description = false;"></app-show-description>

    <!-- REPLACE DEFAULTS DIALOG -->
    <p-dialog #globalConfirmDialog id="gate-system-defaults-replace-dialog" [(visible)]="dialogs.confirmReplacing"
              [resizable]="false" [modal]="true" [focusOnShow]="false"
              header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" (onHide)="dialogs.confirmReplacing = false">

        <div class="new-form-400">
            <p>{{ 'OFFER.TABS.SECTION.DEFAULTS.CONFIRM.BODY' | translate }}</p>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="gate-system-defaults-replace-confirm-button"
                                   label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                                   (onClick)="saveDefaults()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                                   (onClick)="globalConfirmDialog.close($event)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>

    <!--Exit with messages confirmation dialog-->
    <p-dialog [(visible)]="showExitWithMessagesDialog" [closable]="false"
              [focusOnShow]="false" [modal]="true" [resizable]="false"
              header="{{'OFFER.MENU.EXIT_WITH_MESSAGES' | translate}}" id="exit-with-warnings-confirmation-dialog">

        <div class="new-form-600">
            <app-sidebar-validation [pricingProducts]="recentPricingProducts"
                                    [validationMessages]="recentValidationMessages">
            </app-sidebar-validation>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button (onClick)="confirmExitWithMessages()" [size]="40" label="{{ 'GENERAL.YES' | translate }}"
                                   type="main-action"></app-simple-button>
                <app-simple-button (onClick)="closeExitWithMessagesDialog()" [size]="40" label="{{ 'GENERAL.NO' | translate }}"
                                   type="cancel"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
