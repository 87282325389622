export class GrillSegmentDto {

    id: number;
    angled: boolean;
    length: number;

    constructor(id: number, angled: boolean, length: number) {
        this.id = id;
        this.angled = angled;
        this.length = length;
    }
}
