import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../../common/dataServiceHelper";
import {AssemblyUnit} from "../../../../../window-system/assembly/assembly";
import {Transport} from '../../../../../window-system/transport/transport';
import {TransportService} from '../../../../../window-system/transport/transport.service';
import {PositionService} from "../../position.service";
import {TransportData} from '../add-transport/transport-data';
import {EditAssemblyOrTransportDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-edit-transport',
    templateUrl: './edit-transport.component.html',
    styleUrls: ['./edit-transport-component.css', '../../../../../shared-styles.css'],
    providers: [TransportService, PositionService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTransportComponent implements OnInit {

    @Input()
    dialogData: EditAssemblyOrTransportDialogData;

    @Input()
    readOnlyMode: boolean;

    @Output()
    readonly onSubmit = new EventEmitter<void>();

    @Output()
    readonly onClose = new EventEmitter<void>();

    visible = false;
    transport = new Transport();
    validationErrors: { [field: string]: string } = {};

    constructor(private transportService: TransportService,
                private positionService: PositionService,
                public translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngOnInit(): void {
        let transportData: TransportData = JSON.parse(this.dialogData.editedPosition.data);
        this.transportService.getItem(transportData.transportId).subscribe({
            next: data => {
                this.transport = data;
                this.visible = true;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    submit(): void {
        if (this.readOnlyMode || !this.validateForm()) {
            return;
        }
        this.positionService.saveItem(this.dialogData.editedPosition).subscribe({
            complete: () => {
                this.onSubmit.emit();
                this.visible = false;
            },
            error: error => this.errors.handle(error)
        });
    }

    calculateDisplayPrice(isGross: boolean, quantity: number): number {
        let value = this.transport.value * quantity;
        if (isGross) {
            value *= 1 + this.dialogData.editedPosition.sellPrice.vatPercent / 100;
        }
        return value;
    }

    isNotMeter(transport: Transport): boolean {
        return transport.assemblyUnit !== AssemblyUnit.METER;
    }

    fillQuantityFromOffer(): void {
        this.transportService.calculateQuantityForOffer(this.transport.id, this.dialogData.editedPosition.offerId).subscribe({
            next: quantity => {
                this.dialogData.editedPosition.quantity = quantity;
                this.clearError('quantity');
            },
            error: error => this.errors.handle(error)
        });
    }

    private validateForm(): boolean {
        this.validationErrors = {};
        let quantity = this.dialogData.editedPosition.quantity;
        if (isNaN(parseFloat(quantity as any)) || !isFinite(quantity)) {
            this.validationErrors['quantity'] = 'error.transportDto.quantity.not_empty';
        } else if (quantity <= 0) {
            this.validationErrors['quantity'] = 'error.transportDto.quantity.below_min';
        }
        return Object.keys(this.validationErrors).every(key => this.validationErrors[key] == undefined);
    }

    clearError(field: string) {
        this.validationErrors[field] = undefined;
        this.changeDetector.markForCheck();
    }
}
