import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {Observable} from 'rxjs';
import {GateEditorField} from '../../gate-editor-field';
import {GateEditorFieldContentProvider} from '../../gate-editor-field-content-provider';
import {GateSidebarFieldImageService} from '../gate-sidebar-field-image.service';

@Component({
    selector: 'app-gate-panel-type-selection-dialog',
    templateUrl: './gate-panel-type-selection-dialog.component.html',
    styleUrls: ['./gate-panel-type-selection-dialog.component.css']
})
export class GatePanelTypeSelectionDialogComponent implements OnInit {

    @Input()
    gatePanelTypeId: number;

    @Input()
    checkAvailability = false;

    @Output()
    readonly gatePanelTypeIdChange = new EventEmitter<number>();

    @Output()
    readonly onClose = new EventEmitter<void>();

    @ViewChild('dialog', {static: true})
    dialog: Dialog;

    visible = true;

    selectedGatePanelTypeId: number;

    constructor(public readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider,
                public readonly imageService: GateSidebarFieldImageService) {
    }

    ngOnInit(): void {
        this.selectedGatePanelTypeId = this.gatePanelTypeId;
    }

    get options(): Observable<SelectItem[]> {
        return this.gateEditorFieldContentProvider.getItemsStream(GateEditorField.GATE_PANEL_TYPE);
    }

    submit(event: MouseEvent): void {
        this.gatePanelTypeIdChange.emit(this.selectedGatePanelTypeId);
        this.close(event);
    }

    close(event: MouseEvent): void {
        this.dialog.close(event);
    }

    centerDialog() {
        setTimeout(() => this.dialog.center(), 1);
    }

    isOptionUnavailable(option: SelectItem): boolean {
        return this.checkAvailability && !option.available;
    }

    handleOnClick(option: SelectItem): void {
        if (this.isOptionUnavailable(option)) {
            return;
        }
        this.selectedGatePanelTypeId = option.value;
    }
}
