<div class="new-form-400">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'SUBSYSTEM_GROUP.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="100" [required]="true"
                        [disabled]="!canEdit" [(ngModel)]="subsystemGroup.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="bulkAddonProfitMargin"
                        label="{{ 'SUBSYSTEM_GROUP.FORM.BULK_ADDON_PROFIT_MARGIN' | translate }}"
                        [(validationMessageKey)]="validationErrors['bulkAddonProfitMargin']" [maxlength]="250"
                        [disabled]="!canEdit" [(ngModel)]="subsystemGroup.bulkAddonProfitMargin"></app-input-text>
    </div>
</div>
