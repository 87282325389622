import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {EMPTY} from "rxjs";
import {mergeMap} from 'rxjs/operators';
import {Permissions} from "../../../auth/permission.service";
import {Listing} from '../../../common/crud-common/crudItemList';
import {ResponseError} from "../../../common/error.handler";
import {ValidationService} from "../../../common/service/validation.service";
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardDialogComponent} from '../../../form-inputs/wizard/wizard-dialog.component';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {OnceFlag} from '../../../shared/once-flag';
import {ErrorResponse} from "../../errors/errorResponse";
import {SupplierAnnotationsFormComponent} from '../form/supplier-annotations-form.component';
import {SupplierFormValidator} from "../form/supplier-form.validator";
import {Supplier, SupplierMotlawaIntegrationInfo} from "../supplier";
import {SupplierService} from "../supplier.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-edit-supplier-dialog',
    templateUrl: './edit-supplier-dialog.component.html',
    styleUrls: ['./edit-supplier-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSupplierDialogComponent implements OnInit, AfterViewChecked {

    readonly STEPS = {
        DETAILS: 'details',
        ADDRESS: 'address',
        ANNOTATIONS: 'annotations',
        MOTLAWA_INTEGRATION: 'motlawa_integration'
    };

    @Input()
    supplier: Supplier;

    @Input()
    motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo;

    @Output()
    readonly onHide = new EventEmitter<void>();

    @Output()
    readonly onSuccess = new EventEmitter<void>();

    @Output()
    readonly onShowSubstituteSuppliersDialog = new EventEmitter<Supplier[]>();

    @Output()
    readonly onNoSuitableSuppliers = new EventEmitter<void>();

    validationErrors: any = {};

    private supplierFormValidator: SupplierFormValidator;
    validateSupplierDetailsStep: WizardStepValidator;
    validateAddressStep: WizardStepValidator;

    @ViewChild(WizardDialogComponent, {static: true}) wizard: WizardDialogComponent;

    @ViewChild("supplierAnnotationsForm") supplierAnnotationsForm: SupplierAnnotationsFormComponent;

    private annotationsRewritten = false;

    private dialogHideHelper = new OnceFlag();

    constructor(private permissions: Permissions,
                private supplierService: SupplierService,
                private changeDetector: ChangeDetectorRef,
                private validationService: ValidationService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.supplierFormValidator = new SupplierFormValidator(this.supplier, this.validationService, this.supplierService,
            this.translate, this.permissions, validationErrors => {
                // change *-form component inputs to trigger change detection
                this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                this.changeDetector.markForCheck();
            });
        this.validateSupplierDetailsStep = () => this.supplierFormValidator.validateDetails();
        this.validateAddressStep = () => this.supplierFormValidator.validateAddress();
    }

    ngAfterViewChecked(): void {
        // write values to show correct text formatting after selecting annotation wizard step for the first time
        if (this.wizard.getCurrentStepId() === this.STEPS.ANNOTATIONS && !this.annotationsRewritten) {
            this.supplierAnnotationsForm.supplierHeaderComment.writeValue(this.supplier.headerComment);
            this.supplierAnnotationsForm.supplierFooterComment.writeValue(this.supplier.footerComment);
            this.supplierAnnotationsForm.offerComment.forEach(editor => {
                let lang = editor.el.nativeElement.id;
                editor.writeValue(this.supplier.offerComment[lang]);
            });
            this.annotationsRewritten = true;
        }
    }

    hide(): void {
        this.emitOnHide();
    }

    private emitOnHide(): void {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    submit(): void {
        this.validateForm();

        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            if (this.supplier.id && !this.supplier.active) {
                this.supplierService.hasAnyProduct(this.supplier.id).pipe(mergeMap(hasProducts => {
                    if (!hasProducts) {
                        this.saveSupplier();

                        return EMPTY;
                    }

                    return this.supplierService.getAllOwnSuppliers();
                })).subscribe({
                    next: (supplierList: Listing<Supplier>) => {
                        let subsituteSuppliers = supplierList.data.filter(s => s.id !== this.supplier.id && s.active);

                        if (subsituteSuppliers.length > 0) {
                            this.emitOnShowSubstituteSuppliersDialog(subsituteSuppliers);
                        } else {
                            this.emitOnNoSuitableSuppliers();
                        }
                    },
                    error: () => {
                        this.emitOnNoSuitableSuppliers();
                    }
                });
            } else {
                this.saveSupplier();
            }
        }
    }

    private saveSupplier() {
        this.supplierService.saveSupplier(this.supplier, this.motlawaIntegrationInfo).subscribe({
            next: () => {
                this.emitOnSuccess();
            },
            error: (errorMessage: HttpErrorResponse) => {
                let errorBody = new ErrorResponse(errorMessage.error);

                if (errorBody.is400()) {
                    this.validationErrors = {};

                    for (let property in errorBody.invalidFields) {
                        this.validationErrors[property] = errorBody.invalidFields[property];
                    }

                    this.changeDetector.markForCheck();
                } else {
                    throw new ResponseError(errorMessage);
                }
            }
        });
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    private emitOnSuccess(): void {
        this.dialogHideHelper.call(() => this.onSuccess.emit());
    }

    private validateForm(): void {
        this.validationErrors = {};
        this.supplierFormValidator.validateAddress();
        this.supplierFormValidator.validateDetails();
    }

    private emitOnShowSubstituteSuppliersDialog(substituteSuppliers: Supplier[]): void {
        this.dialogHideHelper.call(() => this.onShowSubstituteSuppliersDialog.emit(substituteSuppliers));
    }

    private emitOnNoSuitableSuppliers(): void {
        this.onNoSuitableSuppliers.emit();
    }
}
