<app-wizard-dialog #wizard header="{{ getHeader() | translate }}"
                   (onCancel)="onCancel.emit()" (onComplete)="submit()" contentStyleClass="new-form-60vw no-padding printable-editor">
    <app-wizard-step *ngIf="productionOrder != null"
                     label="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.HEADER_COMMENT' | translate }}"
                     [id]="STEPS.HEADER">
        <p-editor styleClass="annotation-editor" [(ngModel)]="headerComment"></p-editor>
    </app-wizard-step>
    <app-wizard-step *ngIf="productionOrder != null"
                     label="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.FOOTER_COMMENT' | translate }}"
                     [id]="STEPS.FOOTER">
        <p-editor #footerCommentEditor styleClass="annotation-editor" [(ngModel)]="footerComment"></p-editor>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="wizard-dialog-save" label="{{'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" (onClick)="wizard.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}"
                               type="cancel" [size]="40" (onClick)="wizard.close()"></app-simple-button>
            <app-simple-button buttonId="change-annotation-load-default"
                               label="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.LOAD_DEFAULT' | translate }}"
                               type="action" [size]="40" (onClick)="reloadDefaultValue()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
