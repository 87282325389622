<p-blockUI [blocked]="loadingData">
    <div id="loader"></div>
</p-blockUI>
<p-dialog *ngIf="!loadingData" [resizable]="false" [visible]="true" [modal]="true" (onHide)="resetDialog()"
          [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_WINDOW_SYSTEM_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-select inputId="windowSystem" label="{{ 'OFFER.TABS.SYSTEM' | translate }}"
                        [(validationMessageKey)]="validationErrors['windowSystem']" [options]="windowSystems"
                        [optionFormatter]="windowSystemsOptionFormatter"
                        [(ngModel)]="selectedWindowSystem"></app-select>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
