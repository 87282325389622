<!--Table-->
<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{'DECORATIVE_FILLING.TITLE' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'DECORATIVE_FILLING.FORM.COPY_TITLE' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'DECORATIVE_FILLING.FORM.ADD_TITLE' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="decorativeFillingTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(DecorativeFillingField.NAME)"
                          header="{{'DECORATIVE_FILLING.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column *ngIf="fieldUsage.show(DecorativeFillingField.SORT_INDEX)"
                          header="{{'DECORATIVE_FILLING.FORM.SORT_INDEX'|translate}}" [rowspan]="2" [sortable]="true" field="sortIndex"></p-column>
                <p-column *ngIf="fieldUsage.show(DecorativeFillingField.SHORTCUT)"
                          header="{{'DECORATIVE_FILLING.FORM.SHORTCUT'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters"
                          filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(DecorativeFillingField.SYMBOL)"
                          header="{{'DECORATIVE_FILLING.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters"
                          filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'DECORATIVE_FILLING.FORM.SYSTEMS'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(DecorativeFillingField.ACTIVE)"
                          header="{{'COLOR.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <!--Name-->
        <p-column *ngIf="fieldUsage.show(DecorativeFillingField.NAME)"
                  field="name" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.names[userLang]}}
            </ng-template>
        </p-column>
        <!--SortIndex-->
        <p-column [sortable]="true" field="sortIndex"></p-column>
        <!--shortcut-->
        <p-column *ngIf="fieldUsage.show(DecorativeFillingField.SHORTCUT)" [filter]="showFilters" [sortable]="true" field="shortcut" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.shortcut[userLang]}}
            </ng-template>
        </p-column>

        <!--Symbol-->
        <p-column *ngIf="fieldUsage.show(DecorativeFillingField.SYMBOL)" [filter]="showFilters" [sortable]="true" field="symbol" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.symbol}}
            </ng-template>
        </p-column>

        <!--System - PCV-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.pcv)"
                                       (ngModelChange)="materialChange(item, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminium)"
                                       (ngModelChange)="materialChange(item, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.wood)"
                                       (ngModelChange)="materialChange(item, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium and wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminiumWoodMix)"
                                       (ngModelChange)="materialChange(item, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--Status-->
        <p-column *ngIf="fieldUsage.show(DecorativeFillingField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'DECORATIVE_FILLING.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'DECORATIVE_FILLING.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'DECORATIVE_FILLING.FORM.ADD_TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'DECORATIVE_FILLING.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-decorative-filling-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                     [availableOutsideColors]="availableOutsideColors"
                                     [availableInsideColors]="availableInsideColors"
                                     [availableCoreColors]="availableCoreColors" [file]="file"
                                     (fileChange)="onFileChange($event)"></app-decorative-filling-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'DECORATIVE_FILLING.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'DECORATIVE_FILLING.FORM.COPY_TITLE' : 'DECORATIVE_FILLING.FORM.EDIT_TITLE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'DECORATIVE_FILLING.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-decorative-filling-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                     [availableOutsideColors]="availableOutsideColors"
                                     [availableInsideColors]="availableInsideColors"
                                     [availableCoreColors]="availableCoreColors" [file]="file"
                                     (fileChange)="onFileChange($event)"></app-decorative-filling-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'DECORATIVE_FILLING.STEPS.SYSTEMS' | translate }}"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [id]="STEPS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
