import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {CrudItem} from '../../../common/crud-common/crudItem';
import {WindowSystemMaterialLinks} from '../single-system-checkbox-crud/window-system-material-links';

export class BusinessTypeWithMaterials implements CrudItem, WindowSystemMaterialLinks {
    id: number;
    names: any;
    type: WindowTypeCode;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    active: boolean;
    sortIndex: number;
    minWidthInWebshop: number;
    maxWidthInWebshop: number;
    minHeightInWebshop: number;
    maxHeightInWebshop: number;
    numberOfRails: number;

    constructor() {
        this.id = undefined;
        this.names = {};
    }
}
