import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemWebshopMailConfiguration} from './subsystem-webshop-mail-configuration';

@Injectable()
export class SubsystemWebshopMailConfigurationService {

    private static readonly API_URL = 'subsystemWebshopMailConfiguration';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItem(): Observable<SubsystemWebshopMailConfiguration> {
        return this.http.get<SubsystemWebshopMailConfiguration>(SubsystemWebshopMailConfigurationService.API_URL)
            .pipe(map(response => SubsystemWebshopMailConfiguration.fromJSON(response)));
    }

    saveItem(item: SubsystemWebshopMailConfiguration): Observable<number> {
        return this.http.post(SubsystemWebshopMailConfigurationService.API_URL, item, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(SubsystemWebshopMailConfigurationService.API_URL, response)));
    }
}
