import {PainterMode} from "./PainterMode";
import {MinMaxXY} from "../drawing-util";
import {OpeningOption} from "../catalog-data/window-system-interface";

export class PainterParams {

    private static readonly THUMBNAIL_MM_SIZE = 55;
    private static readonly SHADED_THUMBNAIL_MM_SIZE = 110;
    private static readonly TECHNICAL_VERTICAL_MM_HEIGHT = 271.6;
    private static readonly TECHNICAL_VERTICAL_MM_WIDTH = 166.2;
    private static readonly TECHNICAL_HORIZONTAL_MM_HEIGHT = 184.6;
    private static readonly TECHNICAL_HORIZONTAL_MM_WIDTH = 244.4;
    private static readonly WEBSHOP_THUMBNAIL_SIZE_IN_PX = 335; // --thumbnail-size: 335px;
    private static readonly PX_PER_MILIMETER = 8; // stroke-width 1 equals 1/8th of mm, stroke-width 2 equals 1/4th amd so on

    scale: number;
    readonly mode: PainterMode;
    readonly floatingElementsScale: number;
    readonly shaded: boolean;
    readonly viewFromOutside: boolean;
    readonly openingOption: OpeningOption;
    readonly totalWidth: number;
    readonly topEdgeDimension: boolean;
    readonly ventilationAlwaysOnFrame: boolean;
    readonly paintOnlyStructureGuides: boolean;

    constructor(mode: PainterMode, getViewBox?: () => MinMaxXY, shaded = false, viewFromOutside = false,
                openingOption: string = undefined, totalBoundingBox: MinMaxXY = undefined, topEdgeDimentions = false,
                ventilationAlwaysOnFrame = false, paintOnlyStructureGuides = false) {
        this.mode = mode;
        this.scale = 1;
        this.shaded = shaded;
        this.viewFromOutside = viewFromOutside;
        if (viewFromOutside) {
            this.totalWidth = totalBoundingBox.maxX - totalBoundingBox.minX;
        }
        if (!this.isRegularMode() && !this.isMode(PainterMode.WEBSHOP)) {
            this.scale = this.calculateScale(mode, shaded, getViewBox());
        }
        this.floatingElementsScale = this.calculateFloatingElementsScale(mode, totalBoundingBox);
        let openingOptionValue = OpeningOption[openingOption];
        if (openingOption != undefined && openingOptionValue === undefined) {
            throw new Error('PainterParams: Unsupported openingOption: ' + openingOption);
        }
        this.openingOption = openingOptionValue || OpeningOption.INSIDE;
        this.topEdgeDimension = topEdgeDimentions;
        this.ventilationAlwaysOnFrame = ventilationAlwaysOnFrame;
        this.paintOnlyStructureGuides = paintOnlyStructureGuides;
    }

    isMode(...modes: PainterMode[]): boolean {
        return modes.some(m => m === this.mode);
    }

    isRegularMode(): boolean {
        return this.isMode(PainterMode.REGULAR);
    }

    isShaded(): boolean {
        return this.shaded;
    }

    calculateScale(mode: PainterMode, shaded: boolean, viewBox: MinMaxXY): number {
        let targetWidth;
        let targetHeight;
        let width = viewBox.maxX - viewBox.minX;
        let height = viewBox.maxY - viewBox.minY;
        switch (mode) {
            case PainterMode.THUMBNAIL:
                let targetSize = shaded ? PainterParams.SHADED_THUMBNAIL_MM_SIZE : PainterParams.THUMBNAIL_MM_SIZE;
                targetWidth = targetSize;
                targetHeight = targetSize;
                break;
            case PainterMode.TECHNICAL:
                if (width > height) {
                    targetWidth = PainterParams.TECHNICAL_HORIZONTAL_MM_WIDTH;
                    targetHeight = PainterParams.TECHNICAL_HORIZONTAL_MM_HEIGHT;
                } else {
                    targetWidth = PainterParams.TECHNICAL_VERTICAL_MM_WIDTH;
                    targetHeight = PainterParams.TECHNICAL_VERTICAL_MM_HEIGHT;
                }
                break;
            default:
                throw new Error('PainterParams: No print size available for chosen mode.');
        }
        return Math.min(
            this.convertScale(targetWidth, width),
            this.convertScale(targetHeight, height)
        );
    }

    calculateFloatingElementsScale(mode: PainterMode, totalBoundingBox: MinMaxXY): number {
        if (this.isMode(PainterMode.WEBSHOP) && totalBoundingBox != undefined) {
            return Math.max(
                (totalBoundingBox.maxX - totalBoundingBox.minX) / PainterParams.WEBSHOP_THUMBNAIL_SIZE_IN_PX,
                (totalBoundingBox.maxY - totalBoundingBox.minY) / PainterParams.WEBSHOP_THUMBNAIL_SIZE_IN_PX
            );
        } else {
            return 1;
        }
    }

    private convertScale(targetSizeInMilimeters: number, viewBoxInPixels: number): number {
        return (targetSizeInMilimeters * PainterParams.PX_PER_MILIMETER) / viewBoxInPixels;
    }
}
