import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WebshopCustomPage, WebshopCustomPageLinkLocation, WebshopCustomPageType} from './webshop-custom-page';
import {WebshopCustomPageService} from './webshop-custom-page.service';

@Component({
    selector: 'app-webshop-custom-page',
    templateUrl: './webshop-custom-page.component.html',
    styleUrls: ['./webshop-custom-page.component.css'],
    providers: [WebshopCustomPageService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopCustomPageComponent extends CrudCommonComponent<WebshopCustomPage, WebshopCustomPageService> implements OnInit {

    @ViewChild(DataTable)
    dataTable: DataTable;

    pageTypeFilter: Observable<SelectItem[]>;
    pageTypeOptions: Observable<SelectItem[]>;
    linkLocationFilter: Observable<SelectItem[]>;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, WebshopCustomPageService, 'WEBSHOP_CUSTOM_PAGE', 'WebshopCustomPage');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.pageTypeFilter = this.translatedSelectItemService.buildUnsortedDropdown(WebshopCustomPageType,
            'WEBSHOP_CUSTOM_PAGE.TYPE.', '');
        this.pageTypeOptions = this.pageTypeFilter.pipe(map(options => options.filter(option => !!option.value)));
        this.linkLocationFilter = this.translatedSelectItemService.buildUnsortedDropdown(WebshopCustomPageLinkLocation,
            'WEBSHOP_CUSTOM_PAGE.LINK_LOCATION.', '');
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): WebshopCustomPage {
        const webshopCustomPage = new WebshopCustomPage();
        webshopCustomPage.type = WebshopCustomPageType.TEXT;
        webshopCustomPage.linkLocation = WebshopCustomPageLinkLocation.NONE;
        return webshopCustomPage;
    }

    protected validateForm(): void {
        const validationErrors = {};
        if (this.item.path == undefined || this.item.path.trim().length === 0) {
            validationErrors['path'] = 'errors.webshopCustomPageDto.path.not_null';
        } else if (!/^[A-Za-z0-9_-]+$/.test(this.item.path)) {
            validationErrors['path'] = 'errors.webshopCustomPageDto.path.pattern_not_matched';
        }
        if (this.item.name == undefined || this.item.name.trim().length === 0) {
            validationErrors['name'] = 'errors.webshopCustomPageDto.name.not_null';
        }
        if (this.item.type == undefined) {
            validationErrors['type'] = 'errors.webshopCustomPageDto.type.not_null';
        }
        if (this.item.linkLocation == undefined) {
            validationErrors['linkLocation'] = 'errors.webshopCustomPageDto.linkLocation.not_null';
        }
        if (this.item.type === WebshopCustomPageType.EXTERNAL) {
            if (this.item.src == undefined || this.item.src.trim().length === 0) {
                validationErrors['src'] = 'errors.webshopCustomPageDto.src.not_null';
            }
        } else {
            if (this.item.content == undefined || this.item.content.trim().length === 0) {
                validationErrors['content'] = 'errors.webshopCustomPageDto.content.not_null';
            }
        }
        this.validationErrors = validationErrors;
        this.changeDetector.markForCheck();
    }

    isText(): boolean {
        return this.item.type === WebshopCustomPageType.TEXT;
    }

    isHtml(): boolean {
        return this.item.type === WebshopCustomPageType.HTML;
    }

    isExternal(): boolean {
        return this.item.type === WebshopCustomPageType.EXTERNAL;
    }

    isShownInHeader(): boolean {
        return this.item.linkLocation === WebshopCustomPageLinkLocation.HEADER;
    }

    handleShownInHeaderChange(showInHeader: boolean): void {
        this.item.linkLocation = showInHeader ? WebshopCustomPageLinkLocation.HEADER : WebshopCustomPageLinkLocation.NONE;
    }
}
