<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.SUBSYSTEM_NOTES_COUNTER.TITLE' | translate }}</div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <!--CSV export-->
                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('ADMIN_PANEL.SUBSYSTEM_NOTES_COUNTER.TITLE')"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="datatable-container">
        <p-table #dt [value]="items" [rows]="chosenRowsPerPage" [attr.id]="'subsystemNotesCounter'"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions" [paginator]="false" [first]="chosenPageNumber"
                 [metaKeySelection]="true" (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="columnByField['subsystemName']" pSortableColumn="subsystemName"
                        [ngClass]="columnHeaderClasses('subsystemIds')">
                        <span>{{ 'OFFER.FORM.SUBSYSTEM' | translate }}</span>
                        <p-sortIcon field="subsystemName"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="availableSubsystems"
                                           defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                           (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th *ngIf="columnByField['login']" pSortableColumn="login"
                        [ngClass]="columnHeaderClasses('login')">
                        <span>{{ 'USER-DETAILS.FORM.LOGIN' | translate }}</span>
                        <p-sortIcon field="login"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['login'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'login', 'contains')">
                    </th>
                    <ng-container *ngFor="let year of years">
                        <th *ngIf="columnByField[year]" [pSortableColumn]="year"
                            [ngClass]="columnHeaderClasses(year)">
                            <span>{{ year | translate }}</span>
                            <p-sortIcon [field]="year"></p-sortIcon>
                            <input class="p-column-filter p-inputtext p-component"
                                   [ngClass]="{ 'p-column-filter-invisible': true }"
                                   [attr.value]="columnByField[year].defaultFilterValue?.value" type="number"
                                   (click)="handleInputFilterClick($event)">
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-last="last">
                <ng-container *vLet="(item.subsystemName == null || item.subsystemName == '') as isLastRow">
                    <tr [attr.tabindex]="rowIndex" class="datatable-data"
                        [ngClass]="{'summaryRow': isLastRow}">
                        <td *ngIf="columnByField['subsystemName']">
                            {{ item.subsystemName }}
                        </td>
                        <td *ngIf="columnByField['login']">
                            {{ isLastRow ? ('SUBSYSTEM_NOTES_COUNTER.SUM' | translate) : item.login }}
                        </td>
                        <ng-container *ngFor="let year of years">
                            <td *ngIf="columnByField[year]">
                                {{ item.notesCountByYear[year] }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>

            </ng-template>
        </p-table>
    </div>
</div>
