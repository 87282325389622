<p-dataView layout="list" [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5]" [alwaysShowPaginator]="false"
            [value]="addresses" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}">
    <ng-template let-address let-index="rowIndex" pTemplate="listItem">
        <div class="subsystem-address-list-item"
             [ngClass]="{ 'subsystem-address-list-item': true,
                          'subsystem-address-list-item-selected': selectable && selectedAddress?.id === address.id && selectedAddress?.tempId === address.tempId,
                          'subsystem-address-list-item-error': hasValidationErrors(index) }"
             (click)="handleSelectClicked(address)">
            <div class="subsystem-address-list-item-contents">
                <div>
                    <span class="subsystem-address-field-label">{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}: </span>
                    <span class="subsystem-address-field-value">{{ address.address.street }}</span>
                </div>
                <div>
                    <span class="subsystem-address-field-label">{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}: </span>
                    <span class="subsystem-address-field-value">{{ address.address.city }}</span>
                </div>
                <div>
                    <span class="subsystem-address-field-label">{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}: </span>
                    <span class="subsystem-address-field-value" *ngIf="address.address.country != undefined">{{ ('COUNTRIES.' + address.address.country) | translate }}</span>
                </div>
                <div>
                    <span class="subsystem-address-field-label">{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}: </span>
                    <span class="subsystem-address-field-value">{{ address.address.zip }}</span>
                </div>
            </div>
            <div *ngIf="canEdit || selectable" class="subsystem-address-list-item-controls">
                <app-simple-button *ngIf="canEdit" buttonId="subsystem-address-edit-{{ index }}"
                                   label="{{ 'SUBSYSTEM-DETAILS.FORM.EDIT_ADDRESS' | translate }}" [inlineLabel]="false"
                                   icon="create" type="action" [size]="28" fixedWidth="28px" align="right"
                                   (onClick)="handleEditClicked(address)"></app-simple-button>
                <app-simple-button *ngIf="canEdit" buttonId="subsystem-address-delete-{{ index }}"
                                   label="{{ 'SUBSYSTEM-DETAILS.FORM.DELETE_ADDRESS' | translate }}"
                                   [disabled]="!address.removable || (usedAddresses && usedAddresses.includes(address))"
                                   [inlineLabel]="false" icon="delete" type="delete" [size]="28" fixedWidth="28px"
                                   align="right" (onClick)="handleDeleteClicked(address)"></app-simple-button>
            </div>
        </div>
    </ng-template>
</p-dataView>
