import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from "../../../shared/validator/input-validator";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {AddonCategoryGroupField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {CatalogSection} from "../addons/CatalogSection";
import {AddonCategoryGroupFieldUsage} from "../catalog-field-usage";
import {AddonCategoryGroup} from "./addon-category-group";
import {AddonCategoryGroupService} from './addon-category-group.service';

@Component({
    selector: 'app-addon-category-group',
    templateUrl: './addon-category-group.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [AddonCategoryGroupService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonCategoryGroupComponent extends CrudCommonComponent<AddonCategoryGroup, AddonCategoryGroupService> implements OnInit {

    @Input()
    target: CatalogSection;

    item: AddonCategoryGroup;
    validateDataStep: WizardStepValidator;
    validationErrors: { [field: string]: string } = {};
    editPermits: FieldLimitation[] = [];
    fieldUsage: AddonCategoryGroupFieldUsage;
    AddonCategoryGroupField = AddonCategoryGroupField;

    readonly STEPS = {
        DATA: 'DATA'
    };

    @ViewChild('dt') datatable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, AddonCategoryGroupService, 'ADDON_CATEGORY_GROUP', 'AddonCategoryGroup');
        this.item = new AddonCategoryGroup();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new AddonCategoryGroupFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.item = this.getNewItem();
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ADDON_CATEGORY_GROUPS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new AddonCategoryGroup();
        this.getItem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    getNewItem(): AddonCategoryGroup {
        let item = new AddonCategoryGroup();
        item.target = this.target;
        return item;
    }

    getItem(itemId: number) {
        this.itemService.getItem(itemId).subscribe({
            next: item => {
                this.item = item;
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors);

        if (!this.item.name[this.userLang]) {
            this.validationErrors[`name[${this.userLang}]`] = `error.addonCategoryGroupDto.name[${this.userLang}].not_empty`;
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.addonCategoryGroupDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.add();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    loadItemsLazy(event: LazyLoadEvent) {
        event.filters['target'] = {value: this.target};
        super.loadItemsLazy(event);
    }
}
