import {JsonDeserializable} from "../../../common/crud-common/crudItem";

@JsonDeserializable
export class ProductionOrderConversionPoint {

    date: string;
    totalOffers: number;
    totalProductionOrders: number;

    static fromJSON(jsonObject: any): ProductionOrderConversionPoint {
        return Object.assign(new ProductionOrderConversionPoint(), jsonObject);
    }
}
