import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

export type SafeValueType = 'html' | 'style' | 'url' | 'resourceUrl';

@Pipe({
    name: 'appSafeValue',
    pure: true
})
export class SafeValuePipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(value: any, type: SafeValueType) {
        switch (type) {
            case 'html':
                return value != undefined ? this.domSanitizer.bypassSecurityTrustHtml(value) : '';
            case 'style':
                return this.domSanitizer.bypassSecurityTrustStyle(value);
            case 'url':
                return this.domSanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
        }
        return undefined;
    }
}
