import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {Offer} from '../offer';
import {WindowEditorProductionOrderData} from '../window-editor/window-editor-offer-interfaces';
import {ProductionOrder} from './ProductionOrder';
import {TemporaryWindowSystemDealerDiscountValue} from './temporary-window-system-dealer-discount-value';
import {TemporaryDealerDiscount} from './TemporaryDealerDiscount';

@Injectable()
export class ProductionOrderService {

    private static readonly SERVICE_PATH = 'productionorders';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItem(itemId: number): Observable<ProductionOrder> {
        return this.http.get<ProductionOrder>(`${ProductionOrderService.SERVICE_PATH}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ProductionOrder>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(ProductionOrderService.SERVICE_PATH, {params: params}).pipe(
            map(result => Listing.fromJSON(ProductionOrder, result)));
    }

    changeDealerDiscount(tempDiscount: TemporaryDealerDiscount): Observable<void> {
        return this.http.post<void>(`${ProductionOrderService.SERVICE_PATH}/tempDealerDiscount`, tempDiscount);
    }

    getDefaultHeaderComment(productionOrderId: number): Observable<string> {
        return this.http.get(`${ProductionOrderService.SERVICE_PATH}/${productionOrderId}/defaultHeaderComment`, {responseType: 'text'});
    }

    getDefaultFooterComment(productionOrderId: number): Observable<string> {
        return this.http.get(`${ProductionOrderService.SERVICE_PATH}/${productionOrderId}/defaultFooterComment`, {responseType: 'text'});
    }

    updateProductionOrderAnnotations(productionOrder: ProductionOrder): Observable<void> {
        return this.http.put<void>(`${ProductionOrderService.SERVICE_PATH}/${productionOrder.id}/annotations`, productionOrder);
    }

    updateDeliveryDescription(productionOrder: ProductionOrder): Observable<void> {
        return this.http.put<void>(`${ProductionOrderService.SERVICE_PATH}/${productionOrder.id}/deliveryDescription`,
            productionOrder);
    }

    getWindowSystemDealerDiscountValues(productionOrderId: number): Observable<TemporaryWindowSystemDealerDiscountValue[]> {
        return this.http.get<TemporaryWindowSystemDealerDiscountValue[]>(
            `${ProductionOrderService.SERVICE_PATH}/${productionOrderId}/windowSystemDealerDiscounts`);
    }

    changeWindowSystemDealerDiscounts(productionOrderId: number,
                                      dealerDiscountValues: TemporaryWindowSystemDealerDiscountValue[]): Observable<void> {
        return this.http.post<void>(`${ProductionOrderService.SERVICE_PATH}/${productionOrderId}/windowSystemDealerDiscounts`,
            {values: dealerDiscountValues});
    }

    getLastRealtedOffer(productionOrderId: number): Observable<Offer> {
        return this.http.get<Offer>(`${ProductionOrderService.SERVICE_PATH}/${productionOrderId}/lastRelatedOffer`);
    }

    getProductionOrderForWindowEditor(itemId: number): Observable<WindowEditorProductionOrderData> {
        return this.http.get<WindowEditorProductionOrderData>(`${ProductionOrderService.SERVICE_PATH}/${itemId}/forWindowEditor`);
    }
}
