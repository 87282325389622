<div *ngIf="!disabled" class="new-form-row" [ngClass]="{'new-form-row-narrow-inputs': groupProfitMargins != undefined}">
    <div></div> <!--just a spacer for flexbox-->
    <app-input-text inputId="setAllInput" [(validationMessageKey)]="validationErrors['setAllInput']" [maxlength]="250"
                    [(ngModel)]="setAllValue"></app-input-text>
    <div class="setAll-button-wrapper">
        <app-simple-button buttonId="setAllButton" label="{{ 'PROFIT_MARGIN.FORM.SIMPLE_SET_ALL_VALUES' | translate }}"
                           icon="beenhere" type="action" [size]="40" (onClick)="setAllValues()"></app-simple-button>
    </div>
</div>
<div class="new-form-row" [ngClass]="{'new-form-row-narrow-inputs': groupProfitMargins != undefined}"
     *ngFor="let supplier of suppliers">
    <app-input-text [inputId]="'supplierProfitMarginInput_' + supplier.id"
                    [label]="supplier.name | multilanguageTranslate"
                    [(validationMessageKey)]="validationErrors[validationPrefix + 'supplierProfitMargins[' + supplier.id + ']']"
                    [maxlength]="250" [required]="groupProfitMargins == undefined" [disabled]="disabled"
                    [(ngModel)]="profitMargins.supplierProfitMargins[supplier.id]"></app-input-text>
    <app-input-text *ngIf="groupProfitMargins != undefined"
                    [inputId]="'supplierGroupProfitMarginInput_' + supplier.id"
                    label="{{ 'PROFIT_MARGIN.FORM.GROUP_PROFIT_MARGIN' | translate }}" [disabled]="true"
                    [(ngModel)]="groupProfitMargins.supplierProfitMargins[supplier.id]"></app-input-text>
</div>

