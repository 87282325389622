<p-dialog [modal]="true" (onHide)="resetDialog()" [visible]="true" [resizable]="false" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.CORRECTION_ORDER_GENERATION.HEADER' | translate }}
        </div>
    </p-header>

    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <div class="new-form-row">
            <p-dataTable [value]="suppliers">
                <p-column field="companyName" header="{{'OFFER.POSITIONS.DIALOGS.CORRECTION_ORDER_GENERATION.COMPANY_NAME' | translate}}"></p-column>
                <p-column [style]="{'vertical-align':'middle'}">
                    <ng-template pTemplate="header">
                        <app-tristate-checkbox [ngModel]="allSelectedState" (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
                    </ng-template>
                    <ng-template let-supplier="rowData" pTemplate="body">
                        <app-checkbox [inputId]="'supplier_' + supplier.id" [(ngModel)]="supplier.selected"></app-checkbox>
                    </ng-template>
                </p-column>
            </p-dataTable>
        </div>
        <div class="new-form-field-warning-message">
            <p>{{canSubmit() ? '' : ('OFFER.POSITIONS.DIALOGS.CORRECTION_ORDER_GENERATION.WARNING' | translate)}}</p>
        </div>
        <div class="new-form-row">
            <p>{{'OFFER.POSITIONS.DIALOGS.CORRECTION_ORDER_GENERATION.BODY' | translate}}</p>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()" [disabled]="!canSubmit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>

</p-dialog>
