import {Pipe, PipeTransform} from '@angular/core';
import {RowLevel, TreeItem} from "../detailed-pricing.component";

@Pipe({
    name: 'showZeroPrices'
})
export class ShowZeroPricesPipe implements PipeTransform {

    transform(show0Prices: boolean, item: TreeItem): boolean {
        if ((item.rowLevel !== RowLevel.TOGGLE_COMPONENT && item.rowLevel !== RowLevel.COMPONENT) || show0Prices) {
            return true;
        }
        return !item.has0PricesOnly;
    }

}
