<div class="flex-row-container">
    <div class="second-level-menu" *ngIf="catalogFetched">
        <div class="title">
            <div class="text">{{ 'NAVIGATION.ITEMS.TRANSLATIONS' | translate }}</div>
        </div>

        <div *ngFor="let item of catalog.translationGroups" class="navigation-element"
             [class.active]="isActiveSubComponent(item.entityName)" (click)="navigateToSubComponent(item.entityName)">
            <div class="name">{{ getEntityNameLabel(item) | translate }}</div>
        </div>
        <div class="navigation-element" [class.active]="isActiveSubComponent('ui-translations')"
             (click)="navigateToSubComponent('ui-translations')">
            <div class="name">{{ 'TRANSLATIONS.UI_TRANSLATIONS.UI_TRANSLATIONS' | translate }}</div>
        </div>
    </div>

    <div class="content">
        <div *ngIf="!isActiveSubComponent('ui-translations')" class="cover-behind-tabview-nav"></div>
        <ng-container *ngIf="catalogFetched">
            <p-accordion *ngIf="activeTranslationEntity">
                <p-accordionTab *ngFor="let field of activeTranslationEntity.fields; let fieldIndex = index"
                                [selected]="true" [id]="activeTranslationEntity.entityName + '.'+ field.fieldName">

                    <p-header>
                        <div class="accordion-tab-header">
                        <span class="left-side">
                            {{ 'TRANSLATIONS.' + activeTranslationEntity.entityName + '.' + field.fieldName | translate }}
                        </span>

                            <div class="accordion-tab-header-filter">
                                <app-simple-button
                                        label="{{ (field.filterVisible ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                        icon="filter_list" type="filter" align="right"
                                        (onClick)="toggleFilters($event, field)"></app-simple-button>
                            </div>
                        </div>
                    </p-header>

                    <p-dataTable *ngIf="field && field.translations && field.pagination" [value]="field.translations"
                                 [totalRecords]="field.pagination.totalRecords" selectionMode="single"
                                 [seqNum]="fieldIndex+1"
                                 (onRowDblclick)="selectDataRow($event.data, activeTranslationEntity.entityName, field.fieldName)"
                                 [responsive]="true" [rows]="chosenRowsPerPage"
                                 [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [lazy]="true"
                                 (onLazyLoad)="loadTranslationsLazy($event, activeTranslationEntity.entityName, field.fieldName)"
                                 [(selection)]="selectedTranslation" dataKey="id"
                                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">

                        <p-column *ngFor="let lang of supportedLanguages" field="{{ lang.code }}"
                                  header="{{ lang.name | translate }}" [filter]="field.filterVisible" sortable="true">

                            <ng-template let-translation="rowData" pTemplate="body">
                                {{translation.translations[lang.code]}}
                            </ng-template>
                        </p-column>
                        <ng-template pTemplate="paginatorLeft">
                     <span>{{'GENERAL.TOTAL_RECORDS' | translate:{
                         from: field.pagination.fromRecord,
                         to: field.pagination.toRecord,
                         total: field.pagination.totalRecords
                     } }}</span>
                        </ng-template>
                    </p-dataTable>
                </p-accordionTab>
            </p-accordion>
            <app-ui-translations *ngIf="isActiveSubComponent('ui-translations')"></app-ui-translations>
        </ng-container>

        <div class="dialog-window" *ngIf="displayDialog">
            <p-dialog [(visible)]="displayDialog" [resizable]="false" (onHide)="cancel()" [modal]="true" [focusOnShow]="false">
                <p-header>
                    <div class="p-dialog-title">
                        {{ 'TRANSLATIONS.' + selectedEntityName +'.' + selectedEntityName | translate}} - {{ 'TRANSLATIONS.' + selectedEntityName +'.' + selectedFieldName | translate}}
                    </div>
                </p-header>
                <div class="new-form-600">
                    <div *ngFor="let lang of supportedLanguages" class="new-form-row">
                        <app-input-text [inputId]="lang.code" label="{{ lang.name | translate }}"
                                        [(validationMessageKey)]="validationErrors[lang.code]"
                                        [maxlength]="selectedFieldName == 'shortcut' ? 30 : 100"
                                        [(ngModel)]="selected.translations[lang.code]"
                                        [disabled]="!permissions.canEditTranslation()"></app-input-text>
                    </div>
                </div>
                <p-footer>
                    <div class="p-dialog-buttonpane">
                        <app-simple-button *ngIf="permissions.canEditTranslation()" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                           (onClick)="submit()"></app-simple-button>
                        <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                           (onClick)="cancel()"></app-simple-button>
                    </div>
                </p-footer>
            </p-dialog>
        </div>
    </div>
</div>
