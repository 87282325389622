import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Permissions} from "../../../auth/permission.service";
import {ResponseError} from "../../../common/error.handler";
import {OnceFlag} from '../../../shared/once-flag';
import {ErrorResponse} from "../../errors/errorResponse";
import {Supplier, SupplierMotlawaIntegrationInfo} from "../supplier";
import {SupplierService} from "../supplier.service";

@Component({
    selector: 'app-substitute-supplier-dialog',
    templateUrl: './substitute-supplier-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubstituteSupplierDialogComponent {

    @Input()
    substituteSuppliers: SelectItem[];

    @Input()
    supplier: Supplier;

    @Input()
    motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo;

    @Output()
    onHide = new EventEmitter<void>();

    @Output()
    onSuccess = new EventEmitter<void>();

    @Output()
    onMoveProductsError = new EventEmitter<void>();

    @Output()
    onSaveSupplierError = new EventEmitter<void>();

    selectedSubstituteSupplier: Supplier;

    validationErrors: any = {};

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private permissions: Permissions,
                private supplierService: SupplierService) {
    }

    hide(): void {
        this.emitOnHide();
    }

    private emitOnHide(): void {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    isNotPermitted(requiredPermission): boolean {
        return !this.isPermitted(requiredPermission);
    }

    moveProductsToSupplier(): void {
        if (this.selectedSubstituteSupplier) {
            this.supplierService.moveProductsBetweenSuppliers(this.supplier.id, this.selectedSubstituteSupplier.id).subscribe({
                next: data => {
                    if (data) {
                        this.saveSupplier();
                    } else {
                        this.emitOnMoveProductsError();
                    }
                },
                error: () => {
                    this.emitOnMoveProductsError();
                }
            });
        } else {
            this.validationErrors['selectedSubstituteSupplier'] = 'error.supplierDto.selectedSubstituteSupplier.not_empty';
        }
    }

    private emitOnSuccess(): void {
        this.dialogHideHelper.call(() => this.onSuccess.emit());
    }

    private saveSupplier() {
        this.supplierService.saveSupplier(this.supplier, this.motlawaIntegrationInfo).subscribe({
            next: () => {
                this.emitOnSuccess();
            },
            error: (errorMessage: HttpErrorResponse) => {
                let errorBody = new ErrorResponse(errorMessage.error);

                if (errorBody.is400()) {
                    this.emitOnSaveSupplierError();
                } else {
                    throw new ResponseError(errorMessage);
                }
            }
        });
    }

    private emitOnMoveProductsError(): void {
        this.onMoveProductsError.emit();
    }

    private emitOnSaveSupplierError(): void {
        this.onSaveSupplierError.emit();
    }
}
