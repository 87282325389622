export class WindowSystemDefinitionDrawingToolsEnabler {
    canUseShape: boolean;
    canUseCut: boolean;
    canUseTrim: boolean;
    canUseAddMullion: boolean;
    canUseMirror: boolean;
    canUseAlignment: boolean;
    canUseAddLineGrill: boolean;
    canUseAddGridGrill: boolean;
    canUseExtendHorizontalGrills: boolean;
    canUseAddHandle: boolean;
    canUseAddConfigAddon: boolean;
    canUseAddStandaloneGlazingPackage: boolean;
}
