import {ChangeDetectorRef, Directive, Injector, Input, OnChanges, OnInit, SimpleChanges, Type} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {finalize, mergeMap, share} from 'rxjs/operators';
import {AddonCategoryEnum} from "../../../window-designer/enums/AddonCategoryEnum";
import {ConfigurableAddonType} from "../../features/offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonType";
import {AbstractProfitMarginComponent, HasSaveTable} from "../../features/subsystem/base-profit-margin.component";
import {BaseAddonProfitMarginService} from "./base-addon-profit-margin.service";
import {BaseAddonProfitMargin} from './baseAddonProfitMargin';

@Directive()
export abstract class BaseAddonProfitMarginComponent<Item extends BaseAddonProfitMargin, Service extends BaseAddonProfitMarginService<Item>>
    extends AbstractProfitMarginComponent<Item, Service> implements OnInit, OnChanges, HasSaveTable {

    @Input() userCanEdit: boolean;
    @Input() clientId: number;
    @Input() clientGroupId: number;
    @Input() subsystemId: number;
    @Input() subsystemGroupId: number;
    @Input() newElement: boolean;
    @Input() globalMarginUpdateButtonId: string;
    @Input() globalMarginUpdateInputId: string;

    filterCategory: Observable<SelectItem[]>;

    protected constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                serviceType: Type<Service>) {
        super(injector, changeDetector, serviceType, 'ADDON_PRICE_MARGINS', "AddonPriceMargins");
        this.showTable = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterCategory = this.translatedSelectItemService.buildSortedDropdown(AddonCategoryEnum, 'ADDONS.CATEGORIES.', '');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('subsystemGroupId' in changes || 'subsystemId' in changes || 'clientGroupId' in changes || 'clientId' in changes) {
            this.loadItems(true);
        }
    }

    getConfigAddonTypes(): string[] {
        return Object.keys(ConfigurableAddonType).filter((type) => isNaN(<any>type) && type !== ConfigurableAddonType[ConfigurableAddonType.GATE]);
    }

    loadEdited(event: { data: Item }) {
        this.editedItem = this.getNewItem();
        let rowData = event ? event.data : this.editedRowDataRef;
        this.editedItem.id = rowData.id;
        this.fillElementId(rowData);
        this.editedItem.addonCategory = rowData.addonCategory;
        this.editedItem.isConfigAddon = rowData.isConfigAddon;
        this.editedItem.isBulkConfigAddon = rowData.isBulkConfigAddon;
        rowData.value = undefined;
        if (rowData.valueString != undefined) {
            this.editedItem.valueString = rowData.valueString;
        }
    }

    abstract getNewItem(): Item;

    abstract fillElementId(event): void;

    protected loadItems(keepProfitMargins = false) {
        this.itemService.getAllItems(this.clientId, this.clientGroupId, this.subsystemId, this.subsystemGroupId)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    if (this.itemList != undefined && keepProfitMargins) {
                        data.data.forEach(record => {
                            let old = this.itemList.find(o => o.id == record.id);
                            record.value = old.value;
                            record.valueString = old.valueString;
                        });
                    }
                    this.itemList = data.data;
                    this.totalRecords = data.totalRecords;
                    this.showTable = true;
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    console.error('AddonProfitMarginComponent' + ' `getAllItems` error:', error);
                    this.setErrors(error);
                },
                complete: () => {
                    console.info('AddonProfitMarginComponent' + ' `getAllItems` completed!');
                }
            });
    }

    public saveTable(id?: number): Observable<void> {
        let groupId;
        if (id != undefined) {
            if (this.entityType === 'clientGroup') {
                this.clientGroupId = id;
                groupId = id;
            }
            if (this.entityType === 'client') {
                this.clientId = id;
            }
            if (this.entityType === 'subsystemGroup') {
                this.subsystemGroupId = id;
                groupId = id;
            }
            if (this.entityType === 'subsystem') {
                this.subsystemId = id;
            }
        }
        this.composeEditedList();
        const observable = this.itemService.save(this.editedList).pipe(mergeMap(() => {
            if ((this.entityType === 'subsystemGroup' || this.entityType === 'clientGroup') && this.pushToGroupMembers) {
                return this.itemService.overrideGroupMemberMargins(groupId, this.editedList);
            }
            return of(undefined);
        }), share());
        observable.subscribe({
            next: () => {
                this.unselectRow();
            },
            error: error => {
                console.error('ProfitMargin' + ' `saveTable` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('ProfitMargin' + ' `saveTable` completed!');
            }
        });
        return observable;
    }
}
