import {Handle} from "./drawing-data/Handle";
import {HandleDirection} from "./drawing-data/HandleDirection";
import {HandleState} from "./drawing-data/HandleState";
import {DrawingUtil} from "./drawing-util";
import {HandleParams} from "./painters/HandleParams";
import {ErrorNames} from "./utils/ErrorNames";

export class HandleHelper {

    public static isPresent(handle: Handle) {
        return handle != undefined && handle.state !== HandleState.DELETED;
    }

    public static getShadedPoints(handle: Handle, point: { x: number, y: number }): HandlePolygons {
        return {
            handle: this.getPoints(handle, point),
            foundation: this.getPoints(handle, point, HandleParams.FoundationWidth, HandleParams.FoundationLength,
                HandleParams.FoundationProportion)
        };
    }

    public static getPoints(handle: Handle, point: { x: number, y: number }, width = HandleParams.Width,
                             length = HandleParams.Length, proportion = HandleParams.Proportion): number[] {
        let x;
        let y;
        let w;
        let h: number;
        switch (handle.direction) {
            case HandleDirection.DOWN:
                x = point.x - (width / 2);
                y = point.y - (length * proportion);
                w = width;
                h = length;
                break;
            case HandleDirection.UP:
                x = point.x - (width / 2);
                y = point.y - (length * (1 - proportion));
                w = width;
                h = length;
                break;
            case HandleDirection.LEFT:
                x = point.x - (length * (1 - proportion));
                y = point.y - (width / 2);
                w = length;
                h = width;
                break;
            case HandleDirection.RIGHT:
                x = point.x - (length * proportion);
                y = point.y - (width / 2);
                w = length;
                h = width;
                break;
        }

        return [
            x, y,
            x, y + h,
            x + w, y + h,
            x + w, y
        ];
    }

    public static changeRelativePosition(handle: Handle, centerFramePoints: number[], centerPoint: number[], newAbsolutePosition: number,
                                         vertical: boolean) {
        let lineSegment = HandleHelper.findIntersectingLine(handle, centerFramePoints, centerPoint);
        let perpendicularLine = [
            vertical ? 0 : newAbsolutePosition,
            vertical ? newAbsolutePosition : 0,
            vertical ? 1 : newAbsolutePosition,
            vertical ? newAbsolutePosition : 1];
        let intersections = DrawingUtil.polygonLineIntersections(lineSegment, perpendicularLine, true);
        if (intersections.length > 0) {
            handle.positionAngle =
                DrawingUtil.atan2normalized(intersections[0].y - centerPoint[1], intersections[0].x - centerPoint[0]) * 180 / Math.PI;
        } else {
            let err = new Error("New position would go outside of the referenced object bounds");
            err.name = ErrorNames.RESIZE_FAILED;
            throw err;

        }
    }

    public static findIntersectingLine(handle: Handle, framePoints: number[], centerPoint: number[]) {
        let line = HandleHelper.prepareLineAtHandleAngle(handle, centerPoint);
        for (let i = 0; i < framePoints.length; i += 2) {
            let lineSegment = framePoints.slice(i, i + 4);
            if (lineSegment.length === 2) {
                lineSegment = lineSegment.concat(framePoints.slice(0, 2));
            }
            let intersection = DrawingUtil.lineIntersection(line, lineSegment);
            if (intersection.onLine1 && intersection.onLine2) {
                return lineSegment;
            }
        }
        let err = new Error("Can't find intersecting line segment");
        err.name = ErrorNames.GENERAL_ERROR;
        throw err;
    }

    public static relativeToAbsolute(handle: Handle, framePoints: number[], centerPoint: number[]) {
        let line = HandleHelper.prepareLineAtHandleAngle(handle, centerPoint);
        let intersections = DrawingUtil.polygonLineIntersections(framePoints, line);
        if (intersections.length !== 1) {
            let err = new Error("Wrong intersections found!");
            err.name = ErrorNames.INTERSECTIONS_CALCULATION_FAILED;
            throw err;
        }
        return {x: intersections[0].x, y: intersections[0].y};
    }

    private static prepareLineAtHandleAngle(handle: Handle, centerPoint: number[]): number[] {
        let vectorMultiplier = 10000; // just to be sure we hit the frame with resulting line segment
        let vector = {
            x: Math.cos(handle.positionAngle / 180 * Math.PI),
            y: Math.sin(handle.positionAngle / 180 * Math.PI)
        };
        return [
            centerPoint[0],
            centerPoint[1],
            (centerPoint[0] + (vector.x * vectorMultiplier)),
            (centerPoint[1] + (vector.y * vectorMultiplier))];
    }
}

export class HandlePolygons {
    handle: number[];
    foundation: number[];
}
