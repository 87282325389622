import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {SellerSettingsService} from '../seller-settings/seller-settings.service';

@Injectable()
export class PrintoutDataServiceService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getFiles(isSeller: boolean, itemId: number): Observable<{ [type in PrintoutFileType]?: File }> {
        let apiUrl = isSeller ? SellerSettingsService.API_URL : SubsystemService.API_URL;
        return this.http.get<{ [type in PrintoutFileType]?: PrintoutFile }>(`${apiUrl}/${itemId}/printoutFiles`).pipe(
            mergeMap(response => {
                let imageBuffers = {};
                Object.keys(PrintoutFileType).forEach(printoutFile => imageBuffers[printoutFile] = this.toRequest(response[printoutFile].image));
                return forkJoin(imageBuffers).pipe(map(imageBuffers => {
                    const files = {};
                    Object.keys(PrintoutFileType).forEach(printoutFile => files[printoutFile] = this.toFile(imageBuffers[printoutFile], response[printoutFile].name, response[printoutFile].type))
                    return files;
                }));
            })
        );
    }

    private toRequest(image: string | undefined) {
        return image != undefined ? this.dataServiceHelper.getArrayBufferFromDataUri(image) : of<ArrayBuffer>(undefined);
    }

    private toFile(content: ArrayBuffer, name: string | undefined, type: string | undefined) {
        return content != undefined ? new File([content], name, {type: type}) : undefined;
    }
}

export enum PrintoutFileType {
    TEMPLATE = 'TEMPLATE',
    CONFIRMATION_TEMPLATE = 'CONFIRMATION_TEMPLATE',
    LOGO_GLAMOUR = 'LOGO_GLAMOUR',
    GLAMOUR_TITLE_PAGE = 'GLAMOUR_TITLE_PAGE',
    GLAMOUR_ANNOTATIONS = 'GLAMOUR_ANNOTATIONS'
}

class PrintoutFile {
    name: string;
    type: string;
    image: string;
}
