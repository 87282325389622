import {WebshopCharge} from "../../../window-system/webshop-charge/WebshopCharge";

export class UpsellingPickListCharge {

    id: number;
    filter: string;
    name: string;
    windowSystemName: string;
    windowSystemId: number;
    sortIndex: number;
    warnInSource: boolean;
    warnInTarget: boolean;

    constructor(charge: WebshopCharge, lang: string) {
        this.id = charge.id;
        this.name = charge.name[lang];
        this.windowSystemName = charge.windowSystemName[lang];
        this.filter = `${this.name} ${this.windowSystemName}`;
        this.windowSystemId = charge.windowSystemId;
        this.sortIndex = charge.sortIndex;
    }
}
