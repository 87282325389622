import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {GateImagePaintParams} from '../../../../../gate-painter/gate-image-paint-params';

@Injectable()
export class GatePainterService implements OnDestroy {

    private readonly paintEventsSource = new Subject<GateImagePaintParams>();
    public readonly paintEvents = this.paintEventsSource.asObservable();

    private readonly xmlRequestsSource = new Subject<GateImagePaintParams>();
    public readonly xmlRequests = this.xmlRequestsSource.asObservable();

    ngOnDestroy(): void {
        this.paintEventsSource.complete();
    }

    paint(params: GateImagePaintParams): void {
        this.paintEventsSource.next(params);
    }

    requestXML(params: GateImagePaintParams): void {
        this.xmlRequestsSource.next(params);
    }
}
