<div class="new-form-600">
    <button hidden></button> <!-- todo: remove with fix VIN-3882 -->
    <div class="new-form-row">
        <app-select inputId="subsystemGroup" label="{{ 'SUBSYSTEM-DETAILS.FORM.SUBSYSTEM_GROUP' | translate }}"
                    [(validationMessageKey)]="validationErrors['subsystemGroupId']" [options]="subsystemGroups"
                    [disabled]="disabledFields" [(ngModel)]="subsystem.subsystemGroupId"
                    (ngModelChange)="subsystemGroupChanged($event)" [required]="true"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="name"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.NAME' | translate }}"
                        [maxlength]="100" [disabled]="disabledFields"
                        [(validationMessageKey)]="validationErrors['name']"
                        [(ngModel)]="subsystem.name" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="symbol"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.SYMBOL' | translate }}"
                        [maxlength]="100" [disabled]="disabledFields"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [(ngModel)]="subsystem.symbol" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="identificator"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.IDENTIFICATOR' | translate }}"
                        [maxlength]="30" [disabled]="disabledFields"
                        [(validationMessageKey)]="validationErrors['identificator']"
                        [(ngModel)]="subsystem.identificator"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="phoneNumber"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.PHONE_NUMBER' | translate }}"
                        [maxlength]="50" [disabled]="disabledFields" [required]="true"
                        [(validationMessageKey)]="validationErrors['phoneNumber']"
                        [(ngModel)]="subsystem.phoneNumber"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="email"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.EMAIL' | translate }}"
                        [maxlength]="255" [disabled]="disabledFields"
                        [(validationMessageKey)]="validationErrors['email']"
                        [(ngModel)]="subsystem.email"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="defaultCurrency" label="{{ 'SUBSYSTEM-DETAILS.FORM.DEFAULT_CURRENCY' | translate }}"
                    [(validationMessageKey)]="validationErrors['defaultCurrency']" [options]="selectCurrencies | async"
                    [disabled]="disabledFields"
                    [(ngModel)]="subsystem.defaultCurrency" [required]="true"></app-select>
    </div>
    <div class="new-form-row">
        <app-select inputId="type" label="{{ 'SUBSYSTEM-DETAILS.FORM.TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors['type']" [options]="subsystemTypes | async"
                    [disabled]="disabledFields" [(ngModel)]="subsystem.type" [required]="true"></app-select>
    </div>
    <div *ngIf="subsystem.webShopEnabled" class="new-form-row">
        <app-input-text inputId="account"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.ACCOUNT' | translate }}"
                        [maxlength]="50" [disabled]="disabledFields" [required]="false"
                        [(validationMessageKey)]="validationErrors['account']"
                        [(ngModel)]="subsystem.account"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="clientManager" label="{{ 'SUBSYSTEM-DETAILS.FORM.CLIENT_MANAGER_LOGIN' | translate }}"
                    [(validationMessageKey)]="validationErrors['clientManager.login']" [options]="availableManagers"
                    [optionFormatter]="clientManagerFormatter" [optionKey]="clientManagerKey"
                    (ngModelChange)="clientManagerChanged($event)" [(ngModel)]="subsystem.clientManager"
                    [disabled]="disabledFields" [required]="true"></app-select>
    </div>
    <div class="new-form-row">
        <app-select [(ngModel)]="subsystem.subClientManager" [(validationMessageKey)]="validationErrors['subClientManager']"
                    [allowSelectingNone]="true" [disabled]="disabledFields && !permissions.isOpiekun()"
                    [optionFormatter]="clientManagerFormatter" [optionKey]="clientManagerKey"
                    [options]="availableSubManagers" [required]="true"
                    inputId="subClientManager" label="{{ 'SUBSYSTEM-DETAILS.FORM.SUB_CLIENT_MANAGER_LOGIN' | translate }}"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="vatSell"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.VAT_SELL' | translate }}"
                        [min]="0" [disabled]="disabledFields || permissions.isOperator()"
                        [(validationMessageKey)]="validationErrors['vatSell']" [required]="true"
                        [(ngModel)]="subsystem.vatSell"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="defaultOfferDurationDays"
                          label="{{ 'SUBSYSTEM-DETAILS.FORM.DEFAULT_OFFER_DURATION_DAYS' | translate }}"
                          [(validationMessageKey)]="validationErrors['defaultOfferDurationDays']" [min]="1" [max]="31"
                          [step]="1" [(ngModel)]="subsystem.defaultOfferDurationDays"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="bulkAddonProfitMargin"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.BULK_ADDON_PROFIT_MARGIN' | translate }}"
                        [(validationMessageKey)]="validationErrors['bulkAddonProfitMargin']" [maxlength]="250"
                        [disabled]="disabledFields" [(ngModel)]="subsystem.bulkAddonProfitMargin"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="enableSalesTargets && permissions.canEditSubsystemSalesTarget()">
        <app-input-number inputId="currentYearSalesTargetInput"
                          label="{{ 'SUBSYSTEM-DETAILS.FORM.CURRENT_YEAR_SALES_TARGET' | translate }}"
                          [(validationMessageKey)]="validationErrors['currentYearSalesTarget']" [min]="0"
                          [allowOnlyIntegers]="true" [(ngModel)]="subsystem.currentYearSalesTarget"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-radio-button label="{{ 'SUBSYSTEM-DETAILS.FORM.TRANSPORT_TYPE.LABEL' | translate }}"
                          inputId="transportType" [options]="transportTypeOptions | async"
                          [disabled]="disabledFields || permissions.isOperator()" [required]="true"
                          [(ngModel)]="subsystem.transportType"
                          [(validationMessageKey)]="validationErrors['transportType']"></app-radio-button>
    </div>
    <div class="new-form-row" *ngIf="subsystem.transportType === TransportType.SPLITTED">
        <app-input-number inputId="euTransportPercent" [allowOnlyIntegers]="true"
                          label="{{ 'SUBSYSTEM-DETAILS.FORM.EU_TRANSPORT' | translate }}"
                          [min]="0" [max]="100" [step]="1" [disabled]="disabledFields || permissions.isOperator()"
                          [(validationMessageKey)]="validationErrors['euTransportPercent']" [required]="true"
                          [(ngModel)]="subsystem.euTransportPercent" (ngModelChange)="calculateNonEuPercent()"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="subsystem.transportType === TransportType.SPLITTED">
        <app-input-number inputId="nonEuTransportPercent"
                          label="{{ 'SUBSYSTEM-DETAILS.FORM.NON_EU_TRANSPORT' | translate }}"
                          [min]="0" [max]="100" [disabled]="true"
                          [(validationMessageKey)]="validationErrors['nonEuTransportPercent']" [required]="true"
                          [ngModel]="subsystem.nonEuTransportPercent"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="subsystem.id && permissions.canCreateSubsystem()">
        <app-checkbox inputId="impersonationAllowed" label="{{ 'SUBSYSTEM-DETAILS.FORM.IMPERSONATE' | translate }}"
                      [(ngModel)]="subsystem.impersonationAllowed" [disabled]="disabledFields"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="subsystem.id && permissions.canCreateSubsystem()">
        <app-checkbox inputId="webShopEnabled" label="{{ 'SUBSYSTEM-DETAILS.FORM.WEB_SHOP_ENABLED' | translate }}"
                      [(ngModel)]="subsystem.webShopEnabled" [disabled]="webshopAlreadyActive || disabledFields"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="permissions.canCreateSubsystem()">
        <app-checkbox inputId="enableRot" label="{{ 'SUBSYSTEM-DETAILS.FORM.ENABLE_ROT' | translate }}"
                      [(ngModel)]="subsystem.enableRot" [disabled]="disabledFields"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="subsystem.id && permissions.canCreateSubsystem()">
        <app-checkbox inputId="active" label="{{ 'SUBSYSTEM-DETAILS.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="subsystem.active" [disabled]="permissions.isOperator()"></app-checkbox>
    </div>

</div>
