import {PainterParams} from "./PainterParams";
import {PainterMode} from "./PainterMode";

export interface WindowPaintAttributes {
    fill?: string | Snap.Element;
    fillOpacity?: string;
    pointerEvents?: string;
    stroke?: string;
    strokeWidth?: string;
    strokeDasharray?: string;
    strokeLinecap?: string;
    strokeOpacity?: string;
    mask?: Snap.Element;

    guidancePoints?: string;
}

export class WindowParams {

    private static readonly BLACK = `#000000`;
    private static readonly WHITE = `#ffffff`;
    private static readonly RENDER_BACKGROUND_PLACEHOLDER = `BG_COLOR`;
    private static readonly REGULAR_STROKE_WIDTH = 2;
    private static readonly SMALLER_STROKE_WIDTH = 1;

    public static GlazingBeadClickableModeGrill = class {
        public static readonly Attributes: WindowPaintAttributes = {
            pointerEvents: 'stroke',
            fill: 'none'
        };
    };

    public static TabIndicator = class {
        public static Attributes: WindowPaintAttributes = {
            fill: WindowParams.BLACK,
            pointerEvents: 'none'
        };
    };

    public static GuidanceCircle = class {
        public static Attributes: WindowPaintAttributes = {
            stroke: '#22CC22',
            fill: 'none',
            pointerEvents: 'fill',
            guidancePoints: 'true'
        };
    };

    public static Ventilation = class {
        public static Attributes: WindowPaintAttributes = {
            stroke: '#010101',
            strokeWidth: '3',
            fill: '#FFFFFF'
        };
    };

    public static OverlayMask = class {
        public static Show: WindowPaintAttributes = {
            stroke: WindowParams.WHITE,
            fill: WindowParams.WHITE,
            fillOpacity: '1'
        };

        public static Hide: WindowPaintAttributes = {
            stroke: WindowParams.BLACK,
            fill: WindowParams.BLACK,
            fillOpacity: '1'
        };
    };

    public static FRAME_ELEM = 'mainFrameElement';
    public static WING_ELEM = 'wingFrameElement';
    public static INNER_FRAME_ELEM = 'innerFrameElement';
    public static GLAZING_BEAD_ELEM = 'glazingBeadElement';
    public static GLASS_ELEM = 'glassElement';
    public static MUNTIN_ELEM = 'muntinElement';
    public static GRILL_COLORED_ELEM = 'grillColoredElement';
    public static GRILL_ID_ELEM = 'grillElement-';
    public static MULLION_ELEM = 'mullionElement';
    public static HANDLE_ELEM = 'handleElement';
    public static VENTILATION_ELEM = "ventilationElement";
    public static FITTINGS_ELEM = "fittingsElement";

    public static get none(): WindowPaintAttributes {
        return {
            fill: 'none',
            strokeWidth: '0px'
        };
    }

    public static get white(): WindowPaintAttributes {
        return {
            fill: WindowParams.WHITE,
            strokeWidth: '0px'
        };
    }

    public static get black(): WindowPaintAttributes {
        return {
            fill: WindowParams.BLACK,
            strokeWidth: '0px'
        };
    }

    public static get mask(): WindowPaintAttributes {
        return {
            pointerEvents: 'none',
            fillOpacity: '1',
            strokeOpacity: '1'
        };
    }

    public static get transparent(): WindowPaintAttributes {
        return {
            fill: 'none',
            strokeWidth: '0px'
        };
    }

    public static get strokeAccent(): WindowPaintAttributes {
        return {
            fill: 'none',
            stroke: `#000000`,
            strokeOpacity: '0.3',
            strokeWidth: `${WindowParams.REGULAR_STROKE_WIDTH * 2}px`
        };
    }

    public static get topdownView(): WindowPaintAttributes {
        return {
            fill: 'none',
            stroke: `#000000`,
            strokeOpacity: '1',
            strokeWidth: `${WindowParams.REGULAR_STROKE_WIDTH}px`
        };
    }

    public static get clickableOverlay(): WindowPaintAttributes {
        return {
            strokeWidth: '0px',
            pointerEvents: 'fill'
        };
    }

    public static wingShadow(opacity: number): WindowPaintAttributes {
        return {
            fill: WindowParams.BLACK,
            fillOpacity: `${opacity}`,
            strokeWidth: '0px'
        };
    }

    public static handle(params: PainterParams): WindowPaintAttributes {
        return {
            fill: WindowParams.getBackgroundColor(params),
            fillOpacity: WindowParams.getBackgroundOpacity(params),
            strokeWidth: '0px'
        };
    }

    public static smallerOnThumbnail(params: PainterParams): WindowPaintAttributes {
        return {
            fill: WindowParams.WHITE,
            stroke: WindowParams.BLACK,
            strokeWidth: `${params.isMode(PainterMode.THUMBNAIL) ? WindowParams.SMALLER_STROKE_WIDTH : WindowParams.REGULAR_STROKE_WIDTH}px`
        };
    }

    public static regular(params?: PainterParams, transparentBackground = false): WindowPaintAttributes {
        return {
            fill: WindowParams.getBackgroundColor(params, transparentBackground),
            fillOpacity: WindowParams.getBackgroundOpacity(params, transparentBackground),
            stroke: WindowParams.BLACK,
            strokeOpacity: params != null && params.isShaded() ? '0.05' : '1',
            strokeWidth: `${WindowParams.REGULAR_STROKE_WIDTH}px`
        };
    }

    public static grills(params?: PainterParams): WindowPaintAttributes {
        return {
            stroke: WindowParams.BLACK,
            strokeOpacity: params != null && params.isShaded() ? '0.20' : '1',
            fill: WindowParams.getBackgroundColor(params),
            fillOpacity: WindowParams.getBackgroundOpacity(params),
            pointerEvents: params != null && params.isRegularMode() ? 'fill' : 'none'
        };
    }

    public static grillsColored(params?: PainterParams): WindowPaintAttributes {
        return {
            stroke: `#0A24A9`,
            strokeOpacity: params != null && params.isShaded() ? '0.20' : '1',
            fill: `#0A24A9`,
            fillOpacity: WindowParams.getBackgroundOpacity(params),
            pointerEvents: params != null && params.isRegularMode() ? 'fill' : 'none'
        };
    }

    public static getBackgroundColor(params?: PainterParams, transparentBackground = false): string {
        if (params != null && params.isShaded()) {
            return transparentBackground ? WindowParams.BLACK : WindowParams.RENDER_BACKGROUND_PLACEHOLDER;
        }
        return WindowParams.WHITE;
    }

    public static getBackgroundOpacity(params?: PainterParams, transparentBackground = false): string {
        return params != null && params.isShaded() && transparentBackground ? '0' : '1';
    }

    public static movableBar(params: PainterParams) {
        return {
            stroke: WindowParams.BLACK,
            strokeWidth: `${params.isMode(PainterMode.THUMBNAIL) ? 1 : 4}px`,
            strokeDasharray: `1px ${params.isMode(PainterMode.THUMBNAIL) ? 5 : 15}px`,
            strokeLinecap: 'round'
        };
    }

    public static getSnapElemType(val: Snap.Element) {
        if (val.hasClass(WindowParams.GLASS_ELEM)) {
            return WindowParams.GLASS_ELEM;
        }
        if (val.hasClass(WindowParams.GLAZING_BEAD_ELEM)) {
            return WindowParams.GLAZING_BEAD_ELEM;
        }
        if (val.hasClass(WindowParams.FRAME_ELEM)) {
            return WindowParams.FRAME_ELEM;
        }
        if (val.hasClass(WindowParams.MUNTIN_ELEM)) {
            return WindowParams.MUNTIN_ELEM;
        }
        if (val.hasClass(WindowParams.MULLION_ELEM)) {
            return WindowParams.MULLION_ELEM;
        }
        if (val.hasClass(WindowParams.WING_ELEM)) {
            return WindowParams.WING_ELEM;
        }
        if (val.hasClass(WindowParams.HANDLE_ELEM)) {
            return WindowParams.HANDLE_ELEM;
        }
        if (val.hasClass(WindowParams.INNER_FRAME_ELEM)) {
            return WindowParams.INNER_FRAME_ELEM;
        }
        if (val.hasClass(WindowParams.VENTILATION_ELEM)) {
            return WindowParams.VENTILATION_ELEM;
        }
        return null;
    }
}
