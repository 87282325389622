import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {SavedFilter} from './saved-filter';
import {SavedFilterList} from './saved-filter-list';

@Injectable()
export class SavedFilterService {

    constructor(private http: HttpClient) {
    }

    static applyMultiSelectFilters<T extends string>(target: T[], filter: FilterMetadata): void {
        target.splice(0, target.length);

        if (filter != null) {
            filter.value.split(';').forEach(filterValue => {
                if (filterValue) {
                    target.push(filterValue);
                }
            });
        }
    }

    getFiltersForView(viewId: string): Observable<SavedFilterList> {
        return this.http.get<SavedFilterList>(`savedFilter/filters/${viewId}`);
    }

    saveItem(item: SavedFilter): Observable<void> {
        return this.http.post<void>('savedFilter/save', item);
    }

    deleteItems(filterIds: number[]): Observable<void> {
        return this.http.post<void>('savedFilter/delete', filterIds);
    }
}
