import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WindowSystemType} from "../../../../../window-designer/catalog-data/window-system-interface";
import {MultilanguageFieldSelectItem} from "../../../../common/service/select-item-multilanguage-field-translate.pipe";
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowSystemDefinition} from '../window-system-definition';

@Component({
    selector: 'app-window-system-definition-imposition-form',
    templateUrl: './window-system-definition-imposition-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionImpositionFormComponent {

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    copyMode = false;

    @Input()
    disabled: boolean;

    @Input()
    windowSystems: MultilanguageFieldSelectItem[];

    get isRegularWindow(): boolean {
        return WindowSystemType.getWindowSystemTypes().findIndex(type => type.type === this.windowSystem.systemType) >= 0;
    }
}
