import { Pipe, PipeTransform } from '@angular/core';
import {PricingModification} from "../pricing-modification";

@Pipe({
    name: 'modificationValue'
})
export class ModificationValuePipe implements PipeTransform {

    transform(item: PricingModification): string {
        if (!item.value && item.value !== 0) {
            return '';
        }
        let value = item.value + '%';
        if (item.cascade) {
            value = value + ' (' + item.modificationComponents + ')';
        }
        return value;
    }

}
