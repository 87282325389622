import {ColorInterface} from '../../../../../../../window-designer/catalog-data/color-interface';
import {MultilanguageField} from "../../../../../../supportedLanguages";
import {QuantityType} from "../../../../../../../window-designer/enums/QuantityType";

export class BulkAddonData {
    constructor(
        public addonId: number,
        public category: string,
        public pcn: string,
        public supplierId: number,
        public price: number,
        public selectedInsideColor: ColorInterface,
        public selectedOutsideColor: ColorInterface,
        public selectedCoreColor: ColorInterface,
        public quantityType: QuantityType) {
    }
}
