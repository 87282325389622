import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SubsystemNote} from "../../subsystem-note";
import {SelectItem} from "primeng/api/selectitem";
import {SubsystemNotesService} from "../subsystem-notes.service";

@Component({
    selector: 'app-subsystem-notes-form',
    templateUrl: './subsystem-notes-form.component.html',
})
export class SubsystemNotesFormComponent implements OnInit {

    @Input()
    item: SubsystemNote;

    @Input()
    edit: boolean;

    @Input()
    validationErrors;

    @Input()
    selectCategories: SelectItem[];

    ready = false;
    note: SubsystemNote;

    constructor(private changeDetector: ChangeDetectorRef, private subsystemNotesService: SubsystemNotesService) {
    }

    ngOnInit(): void {
        if (this.edit) {
            this.subsystemNotesService.getItem(this.item.id).subscribe((note: SubsystemNote) => {
                this.note = note;
                this.ready = true;
                this.changeDetector.markForCheck();
            });
        } else {
            this.note = this.item;
            this.ready = true;
        }
    }

    changeCategory(categoryId: number) {
        if (this.note) {
            this.note.categoryId = categoryId;
            this.item.categoryId = categoryId;
        }
    }

    changeDate(date: Date) {
        if (this.note) {
            this.note.date = date;
            this.item.date = date;
        }
    }

    changeNote(note: string) {
        if (this.note) {
            this.note.note = note;
            this.item.note = note;
        }
    }
}
