import {ConjunctionData} from "./conjunction-data";
import {MaterialType} from "../../../../common/enums/MaterialType";

export class ConjunctionDto {
    id: number;
    supplierId: number;
    material: MaterialType;
    systemThickness: number;
    data: string;
    svg: string;

    constructor() {
        this.data = JSON.stringify(new ConjunctionData());
    }
}
