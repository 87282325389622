import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Pricelist, PricelistStatus} from '../../pricelist';
import {PriceListsDefinitionComponent} from "../price-lists-definition.component";

@Component({
    selector: 'app-pricelist-form',
    templateUrl: './pricelist-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricelistFormComponent implements OnInit {

    @Input()
    pricelist: Pricelist;

    @Input()
    validationErrors: any;

    @Input()
    canEdit: boolean;

    minValidFromDate: Date;

    ngOnInit(): void {
        this.initValidFromDate();
    }

    getActiveCheckboxState(item: Pricelist): boolean {
        return item.status === PricelistStatus.ACTIVE;
    }

    handleActiveCheckboxChange(item: Pricelist, active: boolean): void {
        item.status = active ? PricelistStatus.ACTIVE : PricelistStatus.INACTIVE;
    }

    isPricetableGenerationFailed(item: Pricelist): boolean {
        return item.status === PricelistStatus.GENERATION_FAILED;
    }

    get canEditDate() {
        return this.pricelist.id == null || (this.pricelist.validFrom != undefined && this.pricelist.validFrom > new Date());
    }

    private initValidFromDate(): void {
        if (this.canEditDate) {
            this.minValidFromDate = new Date(Date.now() + PriceListsDefinitionComponent.MILLIS_IN_5_MINUTES * 2);
        }
    }
}
