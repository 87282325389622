import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {WindowParams} from "./WindowParams";

export class ConfigAddonIconPainter {

    public static paint(box: MinMaxXY, svg: Snap.Paper, onePercentOfCanvas: number, globalAddon: boolean): Snap.Element {
        let distance = onePercentOfCanvas;
        let size = onePercentOfCanvas * 3;
        let left = box.maxX - distance - size;
        let right = box.maxX - distance;
        let top = globalAddon ? (box.minY - distance - size) : (box.minY + distance);
        let bottom = globalAddon ? (box.minY - distance) : (box.minY + distance + size);
        let iconPoints = [left, top, right, top,
            right, bottom, left, bottom];
        let path = DrawingUtil.pathStringFromPolygonPoints(iconPoints, true);
        let group = svg.g();
        let icon = svg.path(path);
        icon.attr(WindowParams.regular());
        group.add(icon);
        let step = size / 4;
        for (let y = top + step; y < bottom; y += step) {
            let line = svg.line(left + step, y, right - step, y);
            line.attr(WindowParams.regular());
            group.add(line);
        }
        return group;
    }
}
