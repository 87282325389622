<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.GENERAL' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [paginator]="true" [rows]="20" [totalRecords]="allHelp.length" [rowsPerPageOptions]="[20]"
                 [responsive]="true" selectionMode="single" [(selection)]="help"
                 (onRowDblclick)="loadHelp($event.data.id)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 dataKey="language" [value]="allHelp">
        <p-column field="language" header="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.LANGUAGE' | translate }}">
            <ng-template let-rowData="rowData" pTemplate="body">
                {{ (rowData.language) | translate }}
            </ng-template>
        </p-column>
    </p-dataTable>
</div>
<app-wizard-dialog *ngIf="displayDialog" header="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.EDIT' | translate }}"
                   (onCancel)="displayDialog = false" (onComplete)="save()">
    <app-wizard-step>
        <div class="settings-form-wrapper">
            <div class="new-form-600">
                <div class="new-form-row">
                    <app-select inputId="webshop-sizing-language"
                                label="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.LANGUAGE' | translate }}"
                                [(validationMessageKey)]="validationErrors['language']" [options]="supportedLanguages"
                                [translateLabels]="true" [required]="true" [(ngModel)]="help.language"></app-select>
                </div>
                <div class="new-form-row">
                    <app-file-upload inputId="image" [ngModel]="imageFile"
                                     (ngModelChange)="handleImageFileChange($event)"
                                     label="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.IMAGE' | translate }}"
                                     [maxSize]="10485760" [maxWidth]="3000" [maxHeight]="3000"
                                     [addButtonLabel]="'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.SELECT_IMAGE_UPLOAD'"
                                     [changeButtonLabel]="'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.CHANGE_IMAGE_UPLOAD'"
                                     [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
                </div>
                <div class="new-form-row">
                    <app-input-text inputId="webshop-sizing-file-preview"
                                    label="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.FILE' | translate }}"
                                    [ngModel]="help.amazonS3FileName" disabled></app-input-text>
                </div>
                <div *ngIf="canUploadToAmazon" class="new-form-row">
                    <app-file-upload inputId="webshop-sizing-file-upload-input"
                                     label="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.UPLOAD_FILE' | translate }}"
                                     addButtonLabel="{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.SELECT_FILE_UPLOAD' | translate }}"
                                     [(validationMessageKey)]="validationErrors['amazonS3File']" [maxSize]="10485760"
                                     [image]="false" accept="video/*" [ngModel]="uploadedFile"
                                     (ngModelChange)="handleUploadedFileChange($event)"></app-file-upload>
                </div>
                <div class="new-form-row">
                    <div>
                        <h3>{{ 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.FORM.CHOOSE_FILE_ON_AMAZON' | translate }}</h3>
                        <p-listbox [options]="unusedAmazonS3Files" [ngModel]="selectedUnusedFile"
                                   (ngModelChange)="handleUnusedFileSelectionChange($event)"></p-listbox>
                    </div>
                </div>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
