import {WindowShape} from "./WindowShape";
import {WindowShapeType} from "./WindowShapeType";

export class ArchThreeCenteredWindowShape extends WindowShape {
    rx1: number;
    rx2: number;
    ry: number;

    constructor(rx1: number, rx2: number, ry: number) {
        super(WindowShapeType.ARCH_THREE_CENTERED);
        this.rx1 = rx1;
        this.rx2 = rx2;
        this.ry = ry;
    }
}
