import {Mullion} from "../../../../window-designer/drawing-data/Mullion";
import {SubWindowData} from "../../../../window-designer/drawing-data/SubWindowData";
import {Veneer} from "../../../../window-designer/drawing-data/Veneer";

export class VeneerEvent {
    mullion: Mullion;
    isVeneer: boolean;
    distance: number;
    veneer: Veneer;
    subwindow: SubWindowData;

    constructor(mullion: Mullion,
                isVeneer: boolean,
                distance: number,
                veneer: Veneer,
                subwindow: SubWindowData) {
        this.mullion = mullion;
        this.isVeneer = isVeneer;
        this.distance = distance;
        this.veneer = veneer;
        this.subwindow = subwindow;
    }
}