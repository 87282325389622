export class PaletteAdditionalElement {
    id: number;
    description: string;
    paletteId: number;

    constructor (description: string, paletteId: number) {
        this.id = undefined;
        this.description = description;
        this.paletteId = paletteId;
    }
}