import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Counts} from '../features/home/Counts';
import {CountsRequest} from '../features/home/CountsRequest';

@Injectable()
export class DataService {

    constructor(private http: HttpClient) {
    }

    getDashboardCounts(counts: CountsRequest): Observable<Counts> {
        return this.http.post<Counts>('dashboardCounts', counts);
    }
}
