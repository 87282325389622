import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class ApplicationResource {
    id: number;

    static fromJSON(jsonObject: object): ApplicationResource {
        const applicationResource = new ApplicationResource();
        applicationResource.id = jsonObject['id'];
        return applicationResource;
    }
}

export enum KnownApplicationResources {
    LOGIN_BACKGROUND = 1,
    DASHBOARD_BACKGROUND = 2,
    GLAMOUR_TITLE_PAGE_DEFAULT_BACKGROUND = 3,
    GATE_CONTROL_MANUAL_ICON = 4,
    GATE_CONTROL_DRIVE_ICON = 5,
    GATE_LINTEL_IMAGE = 6
}
