import {RandomIdGenerator} from "../utils/RandomIdGenerator";
import {QuantityType} from "../enums/QuantityType";

export class WindowAddon {
    generatedId: string;
    lockRecalculateQuantity = false;
    defaultAddon = false;

    constructor(public addonId?: number, public quantityType?: QuantityType, public description?: string, public quantity?: number) {
        if (addonId != null) {
            this.generatedId = RandomIdGenerator.generate();
        }
    }
}
