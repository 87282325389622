export enum ComplaintStatus {

    NEW = "NEW",
    TO_VERIFY = "TO_VERIFY",
    TO_REVISE = "TO_REVISE",
    EXAMINATION_IN_PROGRESS = "EXAMINATION_IN_PROGRESS",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    DELETED = "DELETED"
}
