import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RotSettings} from "./RotSettings";

@Injectable()
export class RotSettingsService {

    constructor(private http: HttpClient) {
    }

    getRotSettings(): Observable<RotSettings> {
        return this.http.get<object>('rotSettings').pipe(map(RotSettings.fromJSON));
    }

    updateRotSettings(rotSettings: RotSettings): Observable<void> {
        return this.http.post<void>('rotSettings', rotSettings);
    }
}
