import {Component, Input} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {WindowSystemType} from '../../../../../window-designer/catalog-data/window-system-interface';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowSystemDefinition} from '../window-system-definition';

@Component({
    selector: 'app-window-system-definition-angles-form',
    templateUrl: './window-system-definition-angles-form.component.html'
})
export class WindowSystemDefinitionAnglesFormComponent {

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    glazingPackages: SelectItem[];

    @Input()
    decorativeGlazingPackages: SelectItem[];

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    copyMode = false;

    @Input()
    disabled: boolean;

    get isPredefinedGlazing(): boolean {
        const windowSystemType = WindowSystemType.getByName(this.windowSystem.systemType);
        return windowSystemType != undefined && WindowSystemType.isPredefinedGlazing(windowSystemType);
    }
}
