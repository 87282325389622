<div class="new-form-60vw">
    <div class="new-form-row" *ngIf="fieldUsage.show(ConfigDependentOptionField.SYSTEM)">
        <app-select inputId="configSystemSelect"
                    label="{{ 'CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.CONFIG_SYSTEM' | translate }}" autofocus
                    [(validationMessageKey)]="validationErrors['configSystemId']" [options]="configSystems | async"
                    [disabled]="fieldUsage.disabled(ConfigDependentOptionField.SYSTEM)"
                    [required]="true"
                    [optionFormatter]="formatConfigSystemOption" [ngModel]="item.configSystemId"
                    (ngModelChange)="handleConfigSystemChange($event)"></app-select>
    </div>

    <div *ngIf="fieldUsage.show(ConfigDependentOptionField.NAME)" class="new-form-row">
        <app-input-text inputId="name" label="{{ 'CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.NAME' | translate }}"
                        [disabled]="fieldUsage.disabled(ConfigDependentOptionField.NAME)"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="255"
                        [(ngModel)]="item.name"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(ConfigDependentOptionField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="name"
                      label="{{ 'CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(ConfigDependentOptionField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(ConfigDependentOptionField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(ConfigDependentOptionField.SORT_INDEX)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                          [max]="999999" [step]="1" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
