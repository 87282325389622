import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DataServiceHelper} from "../../../../../../common/dataServiceHelper";
import {Currencies} from "../../../../../../currencies";
import {ProductionOrdersPosition} from '../../../../production-orders/production-orders-position-list/productionOrdersPosition';
import {Position} from '../position';
import {OpenOfferChargeDataDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-open-offer-charge',
    templateUrl: './open-offer-charge.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    providers: [DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenOfferChargeComponent implements OnInit {

    @Input()
    dialogData: OpenOfferChargeDataDialogData;

    @Input()
    readOnlyMode: boolean;

    @Output()
    onClose = new EventEmitter();

    visible = false;
    currency: Currencies;
    validationErrors: { [field: string]: string } = {};

    constructor(public translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if ('pricingCurrency' in (this.dialogData.offerPosition as any)) {
            this.currency = this.dialogData.offerPosition['pricingCurrency'];
        } else {
            this.currency = Currencies.PLN;
        }
        this.visible = true;
    }

    close(): void {
        if (this.visible) {
            this.visible = false;
            this.onClose.emit();
            this.changeDetector.markForCheck();
        }
    }

    get sellValue() {
        return (this.dialogData.offerPosition as Position | ProductionOrdersPosition).sellValue;
    }
}
