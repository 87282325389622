<div *ngFor="let webshopAreaBarsCode of webshopAreaBarCodes | async" class="area-bars-container">
    <app-checkbox [inputId]="webshopAreaBarsCode.value + '_checkbox'" [label]="webshopAreaBarsCode.label"
                  [ngModel]="isSelected(webshopAreaBarsCode.value)"
                  (ngModelChange)="changeSelection(webshopAreaBarsCode.value, $event)">
        <ng-template pTemplate="label" let-inputId="inputId">
            <label [attr.for]="inputId" class="new-form-checkbox-label"><img [src]="getIcon(webshopAreaBarsCode.value)"
                                                                             [alt]="webshopAreaBarsCode.label"></label>
        </ng-template>
    </app-checkbox>
</div>
