<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'PRICESHEET.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="100" [required]="true"
                        [disabled]="!canEdit" [(ngModel)]="pricesheet.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="type" label="{{ 'PRICESHEET.FORM.TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors['type']" [options]="types" [required]="true"
                    [disabled]="!canEdit" [translateLabels]="true" [(ngModel)]="pricesheet.type"></app-select>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'PRICESHEET.FORM.VALID_FROM' | translate }}"
                      [(validationMessageKey)]="validationErrors['validFrom']" [inline]="true"
                      [minDate]="minValidFromDate" [showTime]="true" [disabled]="!canEdit"
                      [(ngModel)]="pricesheet.validFrom"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-file-upload inputId="fileInput" label="{{ 'PRICESHEET.FORM.SELECT_FILE_UPLOAD' | translate }}"
                         [(validationMessageKey)]="validationErrors['file']" [required]="true"
                         addButtonLabel="PRICESHEET.FORM.SELECT_FILE_UPLOAD"
                         changeButtonLabel="PRICESHEET.FORM.CHANGE_FILE_UPLOAD" [maxSize]="7000000" [image]="false"
                         accept=".xls,.xlsx" [disabled]="!canEdit" [ngModel]="file"
                         (ngModelChange)="fileChange.emit($event)"></app-file-upload>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'PRICESHEET.FORM.ACTIVE' | translate }}" [disabled]="!canEdit"
                      [(ngModel)]="pricesheet.active"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="informUsers" label="{{ 'PRICESHEET.FORM.INFORM_USERS' | translate }}"
                      [disabled]="!canEdit" [(ngModel)]="pricesheet.informUsers"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="pricesheet.informUsers">
        <div class="new-form-field">
            <div class="new-form-field-label-container"></div>
            <div class="new-form-field-input-container">
                <div class="app-filter-multi-select">
                    <p-multiSelect *ngIf="!isConfigAddons" [options]="notifySelectItems"
                                   defaultLabel="{{ 'PRICESHEET.FORM.SELECT_SUPPLIERS' | translate }}"
                                   [disabled]="!canEdit" [(ngModel)]="pricesheet.informUsersSupplierIds"
                                   appendTo="body"></p-multiSelect>
                    <p-multiSelect *ngIf="isConfigAddons" [options]="notifySelectItems"
                                   defaultLabel="{{ 'PRICESHEET.FORM.SELECT_SYSTEMS' | translate }}"
                                   [disabled]="!canEdit" [(ngModel)]="pricesheet.informUsersSystemIds"
                                   appendTo="body"></p-multiSelect>
                </div>
            </div>
        </div>
    </div>
</div>
