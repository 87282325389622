import * as _ from 'underscore';
import {ArchThreeCenteredWindowShape} from "../../../../../window-designer/drawing-data/ArchThreeCenteredWindowShape";
import {AreaSpecification} from "../../../../../window-designer/drawing-data/AreaSpecification";
import {CutData} from "../../../../../window-designer/drawing-data/CutData";
import {DefiningMullion} from "../../../../../window-designer/drawing-data/DefiningMullion";
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {Grill} from "../../../../../window-designer/drawing-data/Grill";
import {Handle} from "../../../../../window-designer/drawing-data/Handle";
import {LineCutData} from "../../../../../window-designer/drawing-data/LineCutData";
import {Mullion} from "../../../../../window-designer/drawing-data/Mullion";
import {PositionReferenceType} from "../../../../../window-designer/drawing-data/PositionReferenceType";
import {RelativePosition} from "../../../../../window-designer/drawing-data/RelativePosition";
import {SubWindowData} from "../../../../../window-designer/drawing-data/SubWindowData";
import {WindowData} from "../../../../../window-designer/drawing-data/WindowData";
import {WindowShape} from "../../../../../window-designer/drawing-data/WindowShape";
import {WindowShapeType} from "../../../../../window-designer/drawing-data/WindowShapeType";
import {DrawingUtil, MinMaxXY} from "../../../../../window-designer/drawing-util";
import {AddonAttribute} from '../../../../../window-designer/entities/AddonAttribute';
import {ProfilesCompositionDistances} from '../../../../../window-designer/profiles-composition-distances';
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {FloatOps} from "../../../../../window-designer/utils/float-ops";
import {GrillHelper} from "../../../../../window-designer/utils/grill-helper";
import {MullionHelper} from "../../../../../window-designer/utils/MullionHelper";
import {MullionUtils} from "../../../../../window-designer/utils/MullionUtils";
import {OperationResult} from "../../../../../window-designer/utils/OperationResult";
import {PositionsHelper} from "../../../../../window-designer/utils/positions-helper";
import {WindowShapeUtil} from "../../../../../window-designer/utils/WindowShapeUtil";
import {WindowTypeCodeParser} from "../../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {OpeningDirection} from '../../../../../window-designer/window-types/opening-direction';
import {OpeningDirectionUtils} from "../../../../../window-designer/window-types/opening-direction-utils";
import {SubwindowAttributes} from "../../../../../window-designer/window-types/subwindow-attributes";
import {WindowAttributes} from "../../../../../window-designer/window-types/window-attributes";
import {FillingType} from "../../../../../window-designer/drawing-data/FillingType";
import {DecorativeFillingUtils} from "../../../../../window-designer/utils/DecorativeFillingUtils";
import {DecorativeFillingInterface} from "../../../../../window-designer/catalog-data/decorative-filling-interface";
import {ConfigurableAddonOpeningsUtils} from '../../../../../window-designer/utils/ConfigurableAddonOpeningsUtils';
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {GrillSegment} from "../../../../../window-designer/drawing-data/GrillSegment";
import {FieldWithPosition} from "../../../../../window-designer/drawing-data/FieldWithPosition";
import {TotalGlazingBeads} from "../../../../../window-designer/utils/total-glazing-beads";
import {SortingUtil} from "../../../../../window-designer/utils/SortingUtil";
import {CategoryWithAutoOptions} from "../../config-editor/config-editor.component";
import {ConfigurableAddonUtils} from "./ConfigurableAddonUtils";
import {AutoOption} from "../../../window-system/addons/addon";

export class MirrorTool {

    public static use(data: DrawingData, profileCompositionDistances: ProfilesCompositionDistances,
                      skipValidation: boolean, decorativeFillings: DecorativeFillingInterface[]): OperationResult {
        let oldTotalBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        let newTotalBoundingBox = this.flipBoundingBox(oldTotalBoundingBox);
        let oldMaps = PositionsHelper.getFramesAndGlazingBeads(data, profileCompositionDistances, skipValidation);
        let oldCuts = JSON.parse(JSON.stringify(data.cuts));
        this.mirrorShape(data, newTotalBoundingBox);
        this.mirrorCutsPositions(data.cuts);
        data.windows.forEach(window => {
            let attributes = WindowTypeCodeParser.parseTypeCode(window.typeCode);
            for (let i = 0; i < attributes.subwindows.length; i++) {
                let subwindow = window.subWindows[i];
                let oldFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, oldCuts,
                    oldTotalBoundingBox, profileCompositionDistances, skipValidation);
                this.mirrorSubwindowType(subwindow, attributes.subwindows[i]);
                this.flipSubwindowProfilesComposition(subwindow);
                this.flipSubwindowPoints(subwindow);
                let newFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, data.cuts,
                    newTotalBoundingBox, profileCompositionDistances, skipValidation);
                let newTotalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, data.cuts,
                    newTotalBoundingBox, profileCompositionDistances, skipValidation);
                this.flipSubwindowsContents(subwindow, oldFramePoints, newFramePoints, newTotalGlazingBeads,
                    oldMaps.glazingBeads, data.cuts, newTotalBoundingBox, profileCompositionDistances, data.shape,
                    skipValidation, decorativeFillings);
            }
            this.mirrorWindowType(window, attributes);
        });
        data.windows.sort(SortingUtil.sortWindows);
        return MullionUtils.repositionMullionsAndGrills(data, profileCompositionDistances, oldMaps.frames,
            oldMaps.glazingBeads, true);
    }

    public static flipConfigAddons(configAddonPositions: ConfigurableAddonPositionModel[], categoriesWithAutoOptions: CategoryWithAutoOptions[] = []): void {
        let autoCategorySymbols = categoriesWithAutoOptions.map(category => category.symbol);
        _.flatten(configAddonPositions
            .map(pos => pos.configurableAddon)
            .filter(addon => autoCategorySymbols.some(autoCategorySymbol => addon.configData.sidebarAddons[autoCategorySymbol] != null)))
            .forEach(addon => {
                categoriesWithAutoOptions.forEach(category => {
                    let currentValueId = addon.configData.sidebarAddons[category.symbol];
                    if (currentValueId == null) {
                        return;
                    }
                    let currentAutoOption = category.addons[currentValueId];
                    let sideValue = currentAutoOption === AutoOption.LEFT ? AutoOption.RIGHT : AutoOption.LEFT;
                    ConfigurableAddonUtils.setStrona([category], addon, sideValue);
                });
            });
    }

    private static flipLineCut(cut: LineCutData): void {
        cut.points = DrawingUtil.flipXCoordinates(cut.points);
        this.flipSide(cut);
    }

    private static flipSubwindowPoints(subwindow: SubWindowData): void {
        let oldPoints = subwindow.points;
        subwindow.points = this.rearrangeSubwindowPointsClockwise(DrawingUtil.flipXCoordinates(oldPoints));
    }

    private static flipSubwindowsContents(subwindow: SubWindowData, oldFramePoints: number[],
                                          newFramePoints: number[], newTotalGlazingBeads: TotalGlazingBeads,
                                          oldGlazingBeads: Map<AreaSpecification, number[]>, cuts: CutData[],
                                          totalBoundingBox: MinMaxXY,
                                          profileCompositionDistances: ProfilesCompositionDistances,
                                          shape: WindowShape, skipValidation: boolean,
                                          decorativeFillings: DecorativeFillingInterface[]): void {
        this.flipHandle(subwindow.handle);
        subwindow.mullions.forEach(mullion => this.flipMullion(mullion, oldFramePoints, newFramePoints, subwindow));
        subwindow.areasSpecification.forEach(area => {
            if (GrillHelper.areaHasGrills(area)) {
                MullionUtils.updateAbsoluteMullionPositions(subwindow, cuts, totalBoundingBox, profileCompositionDistances, shape,
                    undefined, skipValidation);
                let newGlazingBeadPoints = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                    newTotalGlazingBeads.regular, profileCompositionDistances);
                area.grills.forEach(grill => this.flipGrill(grill, oldGlazingBeads.get(area), newGlazingBeadPoints));
                let grillsDependingOnGrids = area.grills.filter(
                    grill => grill.positions.some(pos => this.isPositionReliantOnGrid(pos, area.grills)));
                if (grillsDependingOnGrids.length > 0) {
                    this.additionalFlippingForGrillGridsDependancies(grillsDependingOnGrids, area, newGlazingBeadPoints,
                        newTotalGlazingBeads);
                }
            }
            if (area.filling.type === FillingType.DECORATIVE_FILLING) {
                let filling = decorativeFillings.find(f => f.id === area.filling.decorativeFillingId);
                if (filling != null && DecorativeFillingUtils.typeCanBeFlipped(filling.type)) {
                    area.filling.flipDecorativeFilling = !area.filling.flipDecorativeFilling;
                }
            }
        });
    }

    private static flipHandle(handle: Handle): void {
        if (handle != undefined) {
            if (handle.positionAngle >= 180) {
                handle.positionAngle -= 180;
            } else {
                handle.positionAngle += 180;
            }
       }
    }

    private static rearrangeSubwindowPointsClockwise(pts: number[]): number[] {
        return [pts[2], pts[3], pts[0], pts[1], pts[6], pts[7], pts[4], pts[5]];
    }

    private static flipGrill(grill: Grill, oldPolygon: number[], newPolygon: number[]): void {
        grill.positions.forEach(position => {
            switch (position.type) {
                case PositionReferenceType.GRILL:
                case PositionReferenceType.MULLION:
                case PositionReferenceType.VENEER:
                    break;
                case PositionReferenceType.GLAZING_BEAD:
                case PositionReferenceType.INNER_FRAME:
                    this.flipRelativePosition(position, oldPolygon, newPolygon);
                    break;
                default:
                    this.error("Unsupported position reference type: " + position.type);
                    break;
            }
        });
    }

    private static flipRelativePosition(position: RelativePosition, oldPolygon: number[], newPolygon: number[]): void {
        let originalPoint = this.getPolygonSideReferencePoint(oldPolygon, position.id);
        let newId = undefined;
        for (let i = 0; i < newPolygon.length / 2; i++) {
            let possibleNewPoint = this.getPolygonSideReferencePoint(newPolygon, i + 1);
            if (this.isSamePointFlipped(originalPoint, possibleNewPoint)) {
                newId = i;
                break;
            }
        }
        if (newId == undefined) {
            throw new Error("Mirroring relative position failed.");
        }
        position.id = newId;
        position.percent = 1 - position.percent;
    }

    private static flipMullion(mullion: Mullion, oldFramePoints: number[], newFramePoints: number[],
                               subwindow: SubWindowData) {
        this.flipGrill(mullion, oldFramePoints, newFramePoints);
        if (!MullionHelper.isVeneer(mullion)) {
            MullionUtils.findDefiningMullions(mullion, subwindow).forEach(defMullion => this.flipSide(defMullion));
        }
    }

    private static isSamePointFlipped(pointA: number[], pointB: number[]): boolean {
        return FloatOps.eq(pointA[0], -pointB[0]) && FloatOps.eq(pointA[1], pointB[1]);
    }

    private static getPolygonSideReferencePoint(polygon: number[], side: number): number[] {
        let sideShift = side * 2;
        return [DrawingUtil.getPoint(polygon, sideShift), DrawingUtil.getPoint(polygon, sideShift + 1)];
    }

    private static mirrorCutsPositions(cuts: CutData[]): void {
        cuts.filter(cut => CutData.isLine(cut)).forEach(c => this.flipLineCut(c as LineCutData));
    }

    private static flipBoundingBox(oldBox: MinMaxXY): MinMaxXY {
        return new MinMaxXY(-oldBox.maxX, -oldBox.minX, oldBox.minY, oldBox.maxY);
    }

    private static flipSide(object: LineCutData | DefiningMullion): void {
        switch (object.side) {
            case 'top':
                object.side = 'bottom';
                break;
            case 'bottom':
                object.side = 'top';
                break;
            default:
                this.error("Unsupported cut side: " + object.side);
                break;
        }
    }

    private static flipSubwindowProfilesComposition(subwindow: SubWindowData) {
        let oldLeft = subwindow.profilesComposition.left;
        subwindow.profilesComposition.left = subwindow.profilesComposition.right;
        subwindow.profilesComposition.right = oldLeft;
    }

    private static mirrorShape(data: DrawingData, newTotalBoundingBox: MinMaxXY): void {
        let windowShape = data.shape;
        switch (windowShape.type) {
            case WindowShapeType.RECTANGULAR:
                break;
            case WindowShapeType.ELLIPSE:
            case WindowShapeType.ARCH_ELLIPTICAL:
            case WindowShapeType.ARCH_SEGMENTAL:
                this.generateNewArcCuts(data, newTotalBoundingBox);
                break;
            case WindowShapeType.ARCH_THREE_CENTERED:
                let shape = windowShape as ArchThreeCenteredWindowShape;
                let temp = shape.rx1;
                shape.rx1 = shape.rx2;
                shape.rx2 = temp;
                this.generateNewArcCuts(data, newTotalBoundingBox);
                break;
            default:
                this.error("Unsupported window shape type: " + windowShape.type);
                break;
        }
    }

    private static mirrorWindowType(window: WindowData, attributes: WindowAttributes): void {
        if (!attributes.vertical) {
            window.subWindows.reverse();
            attributes.subwindows.reverse();
        }
        window.typeCode = WindowTypeCodeParser.buildTypeCodeFromAttributes(attributes);
    }

    private static mirrorSubwindowType(subwindow: SubWindowData, subwindowAttribues: SubwindowAttributes) {
        OpeningDirectionUtils.reverseSubwindowOpeningDirection(subwindowAttribues);
        subwindow.typeCode = subwindowAttribues.type;
    }

    private static generateNewArcCuts(data: DrawingData, newTotalBoundingBox: MinMaxXY): void {
        data.cuts.length = 0;
        data.cuts.push(...WindowShapeUtil.generateCutsForWindowShape(data.shape, newTotalBoundingBox));
    }

    private static error(consoleString: string): void {
        let err = new Error(consoleString);
        err.name = ErrorNames.MIRROR_TOOL_FAILED;
        throw err;
    }

    private static isPositionReliantOnGrid(position: RelativePosition, grills: Grill[]): boolean {
        return position.type === PositionReferenceType.GRILL &&
            GrillHelper.isGrid(GrillHelper.findGrillBySegmentId(grills, position.id));
    }

    private static additionalFlippingForGrillGridsDependancies(grillsDependingOnGrids: Grill[], area: AreaSpecification,
                                                               glazingBead: number[], totalGlazingBeads: TotalGlazingBeads): void {
        let segmentsByIds: Map<number, GrillSegment> = new Map();
        let oldSegmentPointsByIds: Map<number, number[]> = new Map();
        let fields: FieldWithPosition[] = [new FieldWithPosition(glazingBead)];
        for (let grill of area.grills) {
            for (let seg of grill.drawingSegments) {
                oldSegmentPointsByIds.set(seg.id, GrillHelper.expectLineSegment(seg).points);
            }
            if (_.contains(grillsDependingOnGrids, grill)) {
                for (let pos of grill.positions) {
                    if (this.isPositionReliantOnGrid(pos, area.grills)) {
                        let index = grill.positions.indexOf(pos);
                        let oldPosition = grill.positionsPoints.slice(index * 2, index * 2 + 2);
                        let success = false;
                        segmentsByIds.forEach((segment: GrillSegment, id: number) => {
                            let lineSegment = GrillHelper.expectLineSegment(segment);
                            let newPosition = DrawingUtil.flipXCoordinates(oldPosition);
                            if (FloatOps.le(DrawingUtil.distanceFromLineSegment(lineSegment.points, newPosition),
                                DrawingUtil.distanceFromLineSegment(oldSegmentPointsByIds.get(lineSegment.id),
                                    oldPosition))) {
                                pos.id = id;
                                pos.percent = PositionsHelper.lineAbsToPercent(lineSegment.points, newPosition);
                                success = true;
                            }
                        });
                        if (!success) {
                            this.error('Could not find reflection for segment id: ' + pos.id);
                        }
                    }
                }
            }
            PositionsHelper.updateAbsoluteGrillPosition(grill, area, totalGlazingBeads, glazingBead, segmentsByIds, fields);
        }
    }
}
