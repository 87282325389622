import {AfterContentInit, ContentChildren, Directive, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {WizardStepComponent} from './wizard-step.component';

export class WizardStepChangeEvent {
    constructor(public oldIndex: number,
                public oldId: string,
                public newIndex: number,
                public newId: string) {
    }

    isBack(): boolean {
        return this.oldIndex != undefined && this.oldIndex > this.newIndex;
    }

    isForward(): boolean {
        return this.oldIndex != undefined && this.oldIndex < this.newIndex;
    }
}

@Directive()
export class WizardBase implements AfterContentInit {

    @Input()
    header: string;

    @Input()
    nextButtonLabel: string;

    @Input()
    displayStepsProgression = true;

    @Output()
    onCancel = new EventEmitter<void>();

    @Output()
    currentStepChange = new EventEmitter<WizardStepChangeEvent>();

    @Output()
    onComplete = new EventEmitter<void>();

    @ContentChildren(WizardStepComponent)
    steps: QueryList<WizardStepComponent>;

    private currentStepIndex;

    ngAfterContentInit(): void {
        this.steps.changes.subscribe(steps => this.setupSteps(steps));
        this.setupSteps(this.steps.toArray());
    }

    protected setupSteps(steps: WizardStepComponent[]) {
        if (this.currentStepIndex == undefined) {
            this.setCurrentStepIndex(0);
        }
        steps.forEach((step: WizardStepComponent, index: number) => {
            step.active = this.currentStepIndex === index;
        });
    }

    nextStep(): void {
        let currentStep = this.getCurrentStep();
        currentStep.validateStep().subscribe(validationOk => {
            if (validationOk) {
                this.setCurrentStepIndex(this.currentStepIndex + 1);
            }
        });
    }

    previousStep(): void {
        this.setCurrentStepIndex(this.currentStepIndex - 1);
    }

    setStepById(id: string): void {
        let steps = this.steps.toArray();
        let newStepIndex = steps.findIndex(step => step.id === id);
        if (newStepIndex === -1) {
            console.error(`Wizard step with id ${id} not found!`);
            return;
        }
        let validations = [of(true)]; // must contain at least one entry to call subscription
        for (let i = this.currentStepIndex; i < newStepIndex; ++i) {
            validations.push(steps[i].validateStep());
        }
        forkJoin(validations).subscribe(validationResults => {
            for (let i = 0; i < validations.length; ++i) {
                if (!validationResults[i]) {
                    let failedStep = steps[this.currentStepIndex + i];
                    console.error(`Wizard cannot jump to step id ${id} because it failed validation of step ${failedStep.id}`);
                    return;
                }
            }
            this.setCurrentStepIndex(newStepIndex);
        });
    }

    setCurrentStepIndex(value: number): void {
        if (value < 0) {
            this.onCancel.emit();
        } else if (value >= this.steps.length) {
            this.onComplete.emit();
        } else {
            let oldStep = this.getCurrentStep();
            let oldIndex = this.currentStepIndex;
            this.currentStepIndex = value;
            let newStep = this.getCurrentStep();
            this.currentStepChange.emit(
                new WizardStepChangeEvent(oldIndex, oldStep != undefined ? oldStep.id : undefined, value, newStep.id));
            if (this.steps != undefined) {
                this.steps.forEach((step: WizardStepComponent, index: number) => {
                    step.active = value === index;
                });
            }
        }
    }

    getCurrentStepIndex(): number {
        return this.currentStepIndex;
    }

    getCurrentStepId(): string {
        const currentStep = this.getCurrentStep();
        return currentStep != undefined ? currentStep.id : undefined;
    }

    private getCurrentStep(): WizardStepComponent {
        if (this.currentStepIndex >= 0 && this.currentStepIndex < this.steps.length) {
            return this.steps.toArray()[this.currentStepIndex];
        }
        return undefined;
    }

    protected getStepIndexById(id: string): number {
        return this.steps.toArray().findIndex(step => step.id === id);
    }
}
