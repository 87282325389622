import {Observable, of} from 'rxjs';

export abstract class ImagesValidator {

    private static readonly DEFAULT_MAX_FILE_SIZE = 150000;
    private static readonly DEFAULT_MAX_FILE_WIDTH = 400;
    private static readonly DEFAULT_MAX_FILE_HEIGHT = 400;
    private static readonly DEFAULT_FILE_TYPES = ["image/jpeg", "image/png", "image/jpeg;charset=utf-8", "image/png;charset=utf-8"];

    public static validationErrors(file: File, maxSize?: number, maxWidth?: number, maxHeight?: number, widthToHeightRatio?: number,
                                   expectedTypes?: string[]): Observable<string> {
        maxSize = maxSize || this.DEFAULT_MAX_FILE_SIZE;
        maxWidth = maxWidth || this.DEFAULT_MAX_FILE_WIDTH;
        maxHeight = maxHeight || this.DEFAULT_MAX_FILE_HEIGHT;
        expectedTypes = expectedTypes || this.DEFAULT_FILE_TYPES;
        if (file && file.size > 0) {
            if (!expectedTypes.includes(file.type)) {
                return of('error.fileUpload.invalid_format');
            }
            if (file.size > maxSize) {
                return of('error.fileUpload.not_in_range');
            }
            return this.validateResolution(file, maxWidth, maxHeight, widthToHeightRatio);
        }
        return of(undefined);
    }

    private static validateResolution(file: File, maxWidth: number, maxHeight: number,
                                      widthToHeightRatio: number | undefined): Observable<string> {
        return new Observable(subscriber => {
            let image = new Image();
            image.onload = () => {
                if (image.width > maxWidth || image.height > maxHeight) {
                    subscriber.next('error.subsystemDto.image.too_big_resolution');
                } else if (!image.width || !image.height) {
                    // some kind of non-image with jpg extension
                    subscriber.next('error.fileUpload.invalid_format');
                } else {
                    if (Math.abs(image.width - image.height * widthToHeightRatio ) > 2) {
                        subscriber.next(widthToHeightRatio === 1
                            ? 'error.subsystemDto.image.not_square'
                            : 'error.fileUpload.invalid_width_to_height_ratio');
                    } else {
                        subscriber.next(undefined);
                    }
                }
                subscriber.complete();
                URL.revokeObjectURL(image.src);
            };
            image.onerror = (event: ErrorEvent) => {
                subscriber.error(event);
            };
            image.src = URL.createObjectURL(file);
        });
    }
}
