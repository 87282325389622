<div class="new-form-row">
    <app-input-text [inputId]="inputId" [label]="label"
                    [validationMessageKey]="transformedValidationErrors[translate.currentLang]"
                    (validationMessageKeyChange)="handleValidationMessageChange($event, translate.currentLang)"
                    [disabled]="disabled" [maxlength]="maxlength" [required]="required"
                    [ngModel]="field != undefined ? field[translate.currentLang] : ''"
                    (ngModelChange)="handleValueChange($event, translate.currentLang)">
        <ng-template pTemplate="label" let-label="label">
            <div class="new-form-field-label-container translation-label-with-link"
                 [ngClass]="{'translation-label-validation-error': hasExtraLanguagesValidationErrors()}">
                <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
                <a href="#" (click)="toggleTranslationFields()">{{ 'NAVIGATION.ITEMS.TRANSLATIONS' | translate }}</a>
            </div>
        </ng-template>
    </app-input-text>
</div>
<div [@showTranslationFields]="'' + showTranslationFields" (@showTranslationFields.done)="animating = false">
    <div *ngFor="let lang of getExtraLanguages()" class="new-form-row"
         [ngStyle]="{visibility: !showTranslationFields || animating ? 'hidden' : 'visible'}">
        <app-input-text [inputId]="inputId + '_' + lang.code" [label]="lang.name | translate"
                        [validationMessageKey]="transformedValidationErrors[lang.code]"
                        (validationMessageKeyChange)="handleValidationMessageChange($event, lang.code)"
                        [disabled]="disabled" [maxlength]="maxlength"
                        [ngModel]="field != undefined ? field[lang.code] : ''"
                        (ngModelChange)="handleValueChange($event, lang.code)"></app-input-text>
    </div>
</div>
