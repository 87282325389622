import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import * as _ from 'underscore';
import {DrawingData} from "../../../../window-designer/drawing-data/drawing-data";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {
    ConfigurableAddonDefinitionType,
    configurableAddonDefinitionTypeToTranslationKey
} from "../../../../window-designer/enums/ConfigurableAddonDefinitionType";
import {ProfilesCompositionDistances} from '../../../../window-designer/profiles-composition-distances';
import {UserUiConfigService} from "../../../auth/uiconfig/userUiConfig.service";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {OnceFlag} from '../../../shared/once-flag';
import {ConfigurableAddonUtils} from "../window-editor/drawing-tool/ConfigurableAddonUtils";
import {WindowTabData} from "../window-editor/drawing-tool/WindowTabData";
import {ConfigurableAddonPositionModel} from '../window-editor/sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-config-addons-list',
    templateUrl: './config-addons-list.component.html',
    styleUrls: ['./config-addons-list.component.css', '../../shared-styles.css'],
    providers: [UserUiConfigService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAddonsListComponent extends ComponentWithUserConfigAndPaginator implements OnInit, AfterViewInit {

    @ViewChild('dt', {static: true}) datatable: DataTable;

    @Output() onClose = new EventEmitter();
    @Output() onEdit = new EventEmitter<ConfigurableAddonPositionModel>();
    @Output() beforeRemove = new EventEmitter();
    @Output() afterRemove = new EventEmitter();

    @Input() displayDeprecated: boolean;
    @Input() configAddonModels: ConfigurableAddonPositionModel[];
    @Input() drawingData: DrawingData;
    @Input() windowTabsData: WindowTabData[];
    @Input() profileCompositionDistances: ProfilesCompositionDistances;
    @Input() readOnlyMode: boolean;

    filterApplication: Observable<SelectItem[]>;
    filterType: Observable<SelectItem[]>;
    nonDeletedModels = [];
    mappedReferencedInfo = [];

    private translatedSelectItemService: TranslatedSelectItemService;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                public translate: TranslateService) {
        super(injector, changeDetector, 'ConfigAddonsListComponent', false);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        return undefined;
    }

    submit() {
        return undefined;
    }

    showDialogToAdd() {
    }

    ngOnInit() {
        this.prepareAddons();
        this.prepareInfo();
        this.prepareDataTable();
        this.prepareFilters();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.hideDataLoadingIndicator();
    }

    emitCloseDialog() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    deleteAddon(addonModel: ConfigurableAddonPositionModel) {
        if (this.readOnlyMode) {
            return;
        }
        this.beforeRemove.emit();
        ConfigurableAddonUtils.deleteAddon(addonModel, this.drawingData, this.configAddonModels);
        this.afterRemove.emit();
        this.prepareDataTable();
    }

    editAddon(event) {
        let addonModel = event.data;
        this.onEdit.emit(addonModel);
    }

    private prepareDataTable() {
        this.nonDeletedModels = this.configAddonModels.filter(model => model.configurableAddon);
    }

    private prepareFilters() {
        this.filterApplication =
            this.translatedSelectItemService.buildSortedDropdown(ConfigAddonApplication, 'OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.', '');
        this.filterType =
            this.translatedSelectItemService.buildSortedDropdown(
                _.uniq(
                    this.nonDeletedModels.map(model => model.configurableAddon.configurableAddonDefinitionType)),
                configurableAddonDefinitionTypeToTranslationKey, '');
    }

    private prepareInfo() {
        this.mappedReferencedInfo = [];
        let mapInfo = (id: number, referencedElement: string) => {
            this.mappedReferencedInfo[id] = referencedElement;
        };
        this.drawingData.configurableAddonIds.forEach(id => mapInfo(id, ""));
        this.windowTabsData.forEach(tabData => {
            let subwindow = tabData.windowData;
            subwindow.configurableAddonIds.forEach(id => mapInfo(id, tabData.windowData.subWindowNameSuffix));
            subwindow.areasSpecification.forEach(area => {
                let areaSufix = area.ordinalNumber.toString();
                area.configurableAddonIds.forEach(id => mapInfo(id, areaSufix));
            });
        });
    }

    private prepareAddons() {
        ConfigurableAddonUtils.resizeAll(this.drawingData, this.configAddonModels, this.profileCompositionDistances, false);
        ConfigurableAddonUtils.saveAllModelsToPositions(this.configAddonModels);
    }

    getDefinitionName(configurableAddonDefinitionType: ConfigurableAddonDefinitionType): string {
        return configurableAddonDefinitionTypeToTranslationKey(configurableAddonDefinitionType);
    }
}
