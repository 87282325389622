import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-field-label-with-button',
    templateUrl: './field-label-with-button.component.html',
    styleUrls: ['./field-label-with-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FieldLabelWithButtonComponent {

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    buttonLabel: string;

    @Input()
    disabled: boolean;

    @Input()
    icon: string;

    @Output()
    onButtonClick = new EventEmitter<MouseEvent>();

    @Output()
    onLabelClick = new EventEmitter<MouseEvent>();

    buttonClick(event: MouseEvent): void {
        this.onButtonClick.emit(event);
    }

    labelClick(event: MouseEvent): void {
        this.onLabelClick.emit(event);
    }
}
