import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {PrimeTemplate} from 'primeng/api';
import {Dialog} from 'primeng/dialog';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../block-ui/block-ui-controller';
import {WizardBase} from './wizard-base';
import {WizardStepComponent} from './wizard-step.component';

@Component({
    selector: 'app-wizard-dialog',
    templateUrl: './wizard-dialog.component.html',
    styleUrls: ['./wizard.component.css'],
    // must not be OnPush - handle components used in wizard-step with ChangeDetectionStrategy.Default
    changeDetection: ChangeDetectionStrategy.Default
})
export class WizardDialogComponent extends WizardBase implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

    private static readonly VALIDATE_ALL_STEPS_BLOCK_ID = 'WizardDialogComponent validateAllSteps';

    @Input()
    dialogStyleClass: string;

    @Input()
    contentStyleClass: string;

    @ViewChild('dialog')
    dialog: Dialog;

    @ViewChild('defaultFooter', {read: TemplateRef})
    defaultFooter: TemplateRef<any>;

    @ContentChildren(PrimeTemplate)
    templates: QueryList<PrimeTemplate>;

    footerTemplate: TemplateRef<any>;
    afterHeaderTemplate: TemplateRef<any>;

    visible: boolean;

    stepsValid: boolean[] = [];

    private stepValueSubscriptions: Subscription[] = [];
    private hotkeys: Hotkey[] = [];

    constructor(private hotkeysService: HotkeysService,
                private changeDetector: ChangeDetectorRef,
                private blockUiController: BlockUiController) {
        super();
    }

    ngAfterContentInit(): void {
        this.initTemplates();
        super.ngAfterContentInit();
        // this kind of hack is needed to smoothly display the dialog in center
        setTimeout(() => {
            this.handleVisibleChange(true);
        }, 1);
    }

    ngOnInit(): void {
        this.hotkeys.push(new Hotkey('enter', (): boolean => {
            this.submit();
            this.changeDetector.markForCheck();
            return false;
        }, ['INPUT', 'SELECT']));
        this.hotkeys.push(new Hotkey('ctrl+left', () => {
            if (this.getCurrentStepIndex() !== 0) {
                this.setStep(this.getCurrentStepIndex() - 1);
                this.changeDetector.markForCheck();
            }
            return false;
        }, undefined, 'GENERAL.HOTKEYS.TAB_LEFT'));
        this.hotkeys.push(new Hotkey('ctrl+right', () => {
            if (this.getCurrentStepIndex() + 1 < this.steps.length) {
                this.setStep(this.getCurrentStepIndex() + 1);
                this.changeDetector.markForCheck();
            }
            return false;
        }, undefined, 'GENERAL.HOTKEYS.TAB_RIGHT'));
    }

    ngAfterViewInit(): void {
        if (this.footerTemplate == undefined) {
            this.footerTemplate = this.defaultFooter;
        }
    }

    ngOnDestroy(): void {
        this.stepValueSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.hotkeysService.remove(this.hotkeys);
    }

    submit(): void {
        this.blockUiController.block(WizardDialogComponent.VALIDATE_ALL_STEPS_BLOCK_ID);
        this.validateAllSteps().pipe(
            finalize(() => {
                this.blockUiController.unblock(WizardDialogComponent.VALIDATE_ALL_STEPS_BLOCK_ID);
            })
        ).subscribe(stepsValid => {
            this.stepsValid = stepsValid;
            if (stepsValid.every(valid => valid)) {
                this.onComplete.emit();
            } else {
                this.changeDetector.markForCheck();
            }
        });
    }

    close(): void {
        this.onCancel.emit();
    }

    buildTabId(step: WizardStepComponent): string {
        return 'tab_' + step.id;
    }

    setStep(index: number): boolean {
        this.setCurrentStepIndex(index);
        return false;
    }

    preventDragging(event: MouseEvent): void {
        event.stopPropagation();
    }

    getDialogStyleClass(): string {
        return 'wizard-dialog ' + (this.dialogStyleClass || '');
    }

    centerDialog(): void {
        this.dialog.center();
    }

    handleVisibleChange(visible: boolean) {
        this.visible = visible;
        if (visible) {
            this.hotkeysService.add(this.hotkeys);
        } else {
            this.hotkeysService.remove(this.hotkeys);
        }
        this.changeDetector.markForCheck();
    }

    protected setupSteps(steps: WizardStepComponent[]) {
        super.setupSteps(steps);
        this.stepValueSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.stepValueSubscriptions = [];
        this.stepsValid = [];
        steps.forEach(step => {
            step.displayHeader = false;
            this.stepsValid.push(true);
            this.stepValueSubscriptions.push(step.valueChange.subscribe(change => {
                const stepIndex = this.getStepIndexById(change.stepId);
                if (stepIndex !== -1) {
                    // clear error from current step - mirror field behavior of clearing errors on input change
                    this.stepsValid[stepIndex] = step.hasValidationErrors(change.input.inputId);
                    this.changeDetector.markForCheck();
                }
            }));
        });
    }

    private initTemplates(): void {
        const setupTemplates = labelTemplates => {
            this.footerTemplate = this.defaultFooter;
            labelTemplates.forEach(template => {
                switch (template.getType()) {
                    case 'footer':
                        this.footerTemplate = template.template;
                        break;
                    case 'afterHeader':
                        this.afterHeaderTemplate = template.template;
                        break;
                    default:
                        break;
                }
            });
        };
        this.templates.changes.subscribe(setupTemplates);
        setupTemplates(this.templates.toArray());
    }

    private validateAllSteps(): Observable<boolean[]> {
        return forkJoin(this.steps.map(step => step.validateStep()));
    }
}
