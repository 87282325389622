import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs/operators";
import {JwtHelperService} from '../../../auth/jwt-helper.service';
import {LogoutHelperService} from '../../../auth/logout-helper.service';

@Component({
    selector: 'app-no-privileges-error',
    templateUrl: './no-privileges-error.component.html',
    styleUrls: ['./no-privileges-error.component.css']
})
export class NoPrivilegesErrorComponent implements OnInit {

    previousUrl: string;
    showLoginButton = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private logoutHelper: LogoutHelperService,
                private jwtHelper: JwtHelperService) {
    }

    ngOnInit(): void {
        this.route.params.pipe(take(1)).subscribe(data => {
            this.previousUrl = data['previousUrl'];
            this.showLoginButton = (data['showLoginButton'] || '').toLowerCase() === 'true';
        });
    }

    getErrorImageUrl(): string {
        return 'assets/images/error-page-broken-window.jpg';
    }

    canShowGoBackButton(): boolean {
        return this.previousUrl != undefined && this.previousUrl !== '/' && this.previousUrl !== '/features';
    }

    goBack(): void {
        this.router.navigateByUrl(this.previousUrl);
    }

    redirectToMain(): void {
        this.router.navigate([this.getMainPageUrl()]);
    }

    private getMainPageUrl(): string {
        return this.jwtHelper.isTokenExpired() ? '/' : '/features';
    }

    goToLogin(): void {
        this.logoutHelper.logout();
    }
}
