export class GatePrintConstants {
    static readonly HORIZONTAL_WALL_THICKNESS = 250;
    static readonly PERSPECTIVE_SPACING = 50;
    static readonly LINTEL_HEIGHT = 250;
    static readonly RULERS_SPACING = 250;
    static readonly SVG_PADDING_LEFT = GatePrintConstants.RULERS_SPACING * 3;
    static readonly SVG_PADDING_RIGHT = 250;
    static readonly SVG_PADDING_TOP = 250;
    static readonly SVG_PADDING_BOTTOM = GatePrintConstants.RULERS_SPACING * 3;
}

export class GatePrintAttributes {

    static get wallBorder() {
        return {
            stroke: '#000000',
            strokeWidth: 4
        };
    }

    static get wallColor() {
        return '#DF8249';
    }

    static get perspective() {
        return {
            fill: '#cf8659',
            stroke: '#000000',
            strokeWidth: 2
        };
    }

    static panel(color: string, darkPanel: boolean) {
        const lineColor = darkPanel ? '#ffffff' : '#000000';
        return {
            fill: color,
            stroke: lineColor,
            strokeWidth: 3
        };
    }

    static panelTexture(texture: Snap.Element) {
        return {
            fillOpacity: 0,
            filter: texture
        };
    }

    static panelStampingLine(effect: Snap.Element, darkPanel: boolean) {
        const color = darkPanel ? '#ffffff' : '#000000';
        return {
            stroke: color,
            strokeWidth: 1,
            filter: effect
        };
    }

    static get lintelHeightTextBackground() {
        return '#C3C3C3';
    }

    static get dimensionsLines() {
        return {
            stroke: '#030303',
            strokeWidth: 2
        };
    }

    // required viewBox for a group containing these paths: "0 0 1032 1280"
    static get brickPaths(): string[] {
        return [`M4015 12791 c4 -7 26 -22 49 -32 l41 -19 -67 0 c-52 0 -68 3 -68 14 0 19 -30 24 -196 32 l-140 6 -28 -25 c-22 -21 -26 -29 -17 -44 9 -16 10 -16
                 11 6 0 23 1 24 68 18 37 -4 107 -7 155 -7 53 0 86 -4 82 -9 -12 -20 37 -31 134 -31 77 0 103 -4 115 -16 9 -8 16 -19 16 -24 0 -14 57 -31 96 -28 36 3 39
                 5 36 30 -1 16 2 37 8 48 10 19 8 20 -47 20 -80 0 -138 11 -121 21 19 13 -4 26 -60 34 -24 4 -51 9 -60 13 -11 4 -13 1 -7 -7z`,
            `M2628 12780 c-54 -3 -101 -9 -105 -13 -3 -4 85 -7 197 -7 112 0 201 -2 199 -4 -2 -2 -83 -18 -179 -35 -96 -18 -176 -33 -178 -35 -2 -2 44 -1 102
                 1 94 5 106 4 106 -11 0 -25 42 -36 136 -36 80 0 84 1 84 23 1 12 7 33 15 47 20 34 19 40 -7 41 -22 0 -22 1 2 10 17 7 26 7 28 0 2 -6 25 -11 50 -12 l47 -1
                 -44 -9 c-24 -5 -46 -11 -49 -14 -6 -6 22 -71 43 -98 12 -17 15 -17 60 4 56 26 78 57 69 93 -5 21 -12 26 -38 26 -18 0 -37 5 -43 11 -22 22 -294 33 -495 19z`,
            `M3323 12777 c-56 -7 -105 -15 -109 -19 -4 -5 75 -8 176 -8 173 0 183 1 167 17 -21 22 -100 25 -234 10z`,
            `M9 12762 c6 -11 29 -27 52 -36 41 -17 42 -17 20 -32 -44 -31 -18 -54 62 -54 20 0 37 -5 37 -10 0 -19 -54 -32 -110 -27 l-55 6 45 -24 c85 -45 156
                 -26 162 43 l3 37 48 -3 c39 -3 53 0 72 18 49 46 111 7 93 -60 l-10 -35 -12 35 c-6 19 -18 35 -26 35 -21 0 -18 -70 5 -95 27 -30 60 -24 105 17 32 28 44 33
                 53 24 9 -9 9 -16 -1 -31 -10 -16 -10 -20 0 -21 7 0 40 -6 73 -13 l60 -13 -106 -8 c-59 -4 -130 -4 -158 0 -46 6 -53 10 -65 40 -8 18 -23 36 -35 40 -23 7 -60
                 -10 -79 -37 -25 -35 -67 -48 -157 -48 l-85 0 0 -954 c0 -639 3 -964 10 -983 6 -15 18 -34 27 -40 14 -11 14 -13 -7 -21 -15 -6 -19 -11 -12 -16 17 -10 15 -36
                 -3 -36 -13 0 -15 -114 -15 -965 l0 -965 26 0 c50 0 122 53 103 77 -16 19 6 25 87 21 l68 -3 -112 -58 c-134 -70 -146 -97 -42 -97 42 0 57 -12 37 -27 -21 -17
                 -61 -22 -100 -13 -31 7 -36 6 -27 -5 18 -22 97 -48 122 -41 39 10 51 23 57 62 l6 39 57 3 c40 2 61 8 73 22 38 42 102 7 88 -49 -7 -33 -23 -51 -23 -27 0 9
                 -5 26 -11 37 -8 16 -13 18 -25 8 -8 -6 -14 -18 -14 -26 0 -28 32 -82 51 -88 22 -7 66 17 87 47 18 24 52 24 52 -1 0 -11 -6 -21 -12 -24 -7 -2 7 -9 32 -16
                 109 -28 107 -29 -37 -30 -78 -1 -149 2 -158 5 -8 4 -22 22 -30 40 -12 29 -19 34 -47 34 -26 0 -42 -9 -71 -40 -44 -45 -79 -55 -173 -47 l-64 5 0 -903 0
                 -903 37 9 c65 15 123 11 160 -12 28 -17 46 -20 93 -17 38 3 66 11 79 22 19 17 23 17 117 -6 104 -25 135 -48 65 -48 -53 0 -61 -17 -14 -29 77 -19 114 -22
                 153 -11 47 13 48 30 2 42 -37 10 -41 23 -9 32 38 10 253 7 286 -5 26 -9 40 -8 82 10 28 12 65 21 82 19 27 -2 31 -7 29 -25 -4 -27 13 -30 54 -8 21 11 62 15
                 144 15 146 0 191 7 197 31 5 17 9 17 51 -8 40 -24 60 -27 171 -33 138 -8 169 -16 198 -53 l19 -25 28 34 c40 50 66 58 161 44 44 -6 104 -18 133 -26 48 -14
                 53 -17 52 -42 -3 -40 -3 -41 35 -32 30 7 39 5 55 -12 19 -20 20 -20 85 -3 52 14 69 24 76 42 11 31 36 39 144 47 55 5 100 13 115 23 18 12 46 16 110 15 61
                 -1 100 3 139 17 42 15 61 17 80 9 36 -14 395 -12 451 2 25 7 81 16 125 21 44 6 97 19 118 30 l39 20 46 -31 c34 -23 56 -30 82 -28 29 3 35 7 33 25 l-3 22
                 33 -19 c17 -10 72 -28 120 -40 96 -22 152 -46 181 -79 22 -25 46 -26 101 -6 26 9 58 12 93 8 44 -5 56 -3 75 15 29 27 60 38 132 46 74 9 139 -5 171 -35 21
                 -20 34 -23 84 -21 59 3 59 2 85 -38 21 -33 31 -40 56 -40 33 0 33 -3 13 -67 -3 -10 9 -2 28 19 19 20 38 54 44 74 11 47 28 52 189 61 119 6 136 0 72 -24
                 -32 -11 -32 -11 20 -12 60 -1 83 6 83 25 0 18 15 18 45 -2 22 -15 28 -14 75 1 43 14 58 15 101 5 65 -16 160 -16 197 -1 34 14 60 5 126 -48 l39 -31 -36 0
                 c-28 0 -40 6 -53 25 -21 32 -40 32 -48 0 -9 -35 14 -79 42 -83 16 -3 22 -10 22 -28 0 -18 5 -24 23 -24 21 -1 20 -2 -10 -28 l-32 -27 44 -59 45 -60 -2 70
                 c-2 78 6 97 65 164 l39 45 -28 3 c-40 5 -58 21 -43 38 6 8 20 14 30 14 10 0 26 12 35 26 16 23 22 25 60 19 32 -4 50 -2 69 11 31 20 117 14 168 -13 29 -14
                 52 -14 302 2 306 19 936 18 990 -2 17 -6 42 -14 57 -17 36 -8 55 -29 67 -74 l10 -37 163 3 c99 3 195 -1 245 -9 l82 -12 102 69 101 69 -104 6 c-56 4 -123
                 13 -148 22 -73 25 -110 41 -110 48 0 9 136 59 159 59 18 0 58 -36 50 -45 -3 -2 -22 0 -43 6 -39 11 -53 8 -42 -10 11 -17 244 -60 331 -61 35 0 62 -8 90
                 -25 29 -18 54 -25 93 -25 37 0 52 -4 50 -12 -7 -19 -57 -29 -143 -27 -54 2 -80 -2 -81 -9 -1 -7 -2 -26 -3 -44 -1 -30 -2 -31 -20 -14 -19 17 -21 16 -63
                 -21 l-43 -38 -905 5 c-995 5 -949 2 -971 67 -13 41 -48 66 -86 61 -20 -2 -29 -9 -31 -26 -4 -27 14 -52 37 -52 9 0 23 -5 31 -10 13 -9 12 -12 -4 -25 -13
                 -10 -44 -15 -92 -15 -119 0 -160 28 -110 75 42 39 27 64 -18 30 -23 -17 -79 -20 -107 -5 -25 14 -24 -2 2 -26 45 -40 43 -49 -21 -88 -32 -20 -70 -50 -84
                 -66 -17 -21 -34 -30 -55 -30 -42 0 -40 -22 4 -56 36 -27 45 -56 23 -71 -7 -4 -36 -6 -64 -5 -37 3 -55 -1 -67 -13 -23 -22 -22 -29 12 -67 40 -47 51 -71 51
                 -114 0 -52 15 -34 34 41 20 76 20 85 -3 85 -29 0 -81 21 -81 32 0 11 100 5 133 -8 20 -7 21 -27 6 -184 -10 -111 -28 -181 -29 -115 -1 60 -10 75 -46 75
                 -36 0 -40 7 -46 75 -3 25 -7 18 -32 -45 -27 -70 -28 -78 -18 -134 14 -71 27 -89 48 -62 8 11 14 31 15 45 1 14 5 37 9 51 5 17 8 -1 9 -57 l1 -83 -72 0 -73
                 -1 27 -20 c14 -11 37 -28 50 -39 l23 -19 -31 8 c-38 11 -54 -1 -54 -40 0 -35 20 -50 76 -57 29 -3 50 -11 57 -23 8 -13 19 -16 34 -13 13 4 23 2 23 -4 0 -19
                 -30 -41 -68 -52 -58 -16 -71 -12 -39 11 54 41 22 64 -48 35 -42 -18 -47 -54 -14 -85 18 -16 20 -26 14 -57 l-7 -38 41 37 c70 63 113 42 46 -23 -41 -40 -44
                 -59 -12 -88 40 -38 51 -29 43 36 l-6 57 25 -28 c33 -37 42 -77 60 -253 28 -272 53 -368 115 -446 36 -44 36 -48 8 -49 -18 0 -15 -5 17 -19 57 -27 150
                 -34 250 -20 47 7 89 9 92 6 4 -3 1 -17 -5 -32 -9 -17 -18 -24 -30 -20 -21 7 -47 -8 -38 -22 15 -25 135 -19 144 7 6 19 40 12 69 -13 22 -20 26 -27 16 -37
                 -19 -20 -16 -29 11 -29 37 0 56 10 56 30 0 20 19 30 56 30 33 0 22 16 -15 23 -17 4 -47 13 -68 22 -59 25 -26 34 149 42 191 7 284 -1 291 -27 4 -16 19 -18
                 120 -21 122 -4 152 -14 128 -43 -7 -9 -34 -16 -64 -18 l-52 -4 37 -17 c30 -14 66 -17 182 -17 l145 0 21 -30 c30 -42 76 -41 126 2 34 30 36 31 29 9 -7 -22
                 -5 -22 34 9 48 39 41 50 -31 50 -55 0 -94 13 -125 42 -27 25 -64 19 -78 -13 -17 -37 -35 -34 -52 9 -8 22 -25 45 -37 50 -18 9 -14 11 25 11 67 1 159 -18
                 201 -41 32 -18 47 -20 117 -15 61 5 84 3 94 -7 8 -8 24 -16 37 -18 16 -2 26 -12 30 -28 3 -14 14 -26 24 -28 15 -3 16 1 11 31 -6 30 -4 36 15 42 13 4 19
                 12 16 21 -10 27 43 36 184 31 76 -2 146 -9 158 -16 18 -9 32 -8 69 4 53 18 111 13 120 -11 3 -9 -6 -27 -22 -44 l-27 -28 28 -18 c17 -12 52 -20 95 -22 66
                 -4 68 -4 104 32 22 22 45 36 61 36 29 0 64 16 64 30 0 6 -18 10 -40 10 -28 0 -40 4 -40 14 0 18 22 26 76 26 48 0 84 -14 97 -37 8 -12 12 -12 27 2 47 42
                 213 63 240 30 11 -13 16 -12 41 9 17 14 47 27 72 30 77 10 527 14 533 5 3 -5 9 -28 13 -51 5 -25 9 377 10 938 l1 981 -52 24 c-67 31 -108 65 -108 90 0 11
                 -12 32 -27 46 l-28 26 25 -8 c50 -15 115 -74 122 -110 2 -11 11 -21 21 -23 15 -3 17 7 17 101 0 84 3 106 15 111 13 5 15 121 15 961 l0 955 -27 11 c-30 11
                 -57 5 -51 -13 3 -7 15 -15 29 -18 l24 -6 -27 -18 c-73 -48 -288 -43 -385 8 -26 14 -69 29 -95 32 -74 10 -90 45 -27 59 l30 7 -33 16 c-25 13 -34 24 -36
                 49 l-3 32 218 -1 c191 -2 223 0 261 16 38 16 45 17 60 4 9 -9 27 -21 40 -27 l22 -12 0 940 c0 937 0 940 -20 951 -12 6 -40 14 -63 18 l-42 8 -3 85 -3 86
                 66 -1 65 -1 0 976 c0 1069 5 989 -56 989 -33 0 -31 -26 4 -38 l27 -9 -35 -19 c-30 -16 -56 -19 -170 -19 -131 0 -137 1 -207 33 -39 17 -84 32 -100 32 -16 0
                 -38 6 -48 14 -16 12 -17 16 -6 30 7 9 28 16 45 17 l31 1 -34 12 c-18 6 -39 20 -47 30 -13 18 -15 18 -39 2 -25 -16 -25 -19 -11 -35 36 -39 8 -94 -57 -112
                 -54 -15 -289 -15 -343 0 -32 8 -43 17 -48 36 -10 39 -56 25 -56 -16 0 -24 -20 -29 -130 -29 l-98 0 4 78 5 77 -23 -53 c-13 -29 -35 -68 -50 -87 -25 -30 -36
                 -35 -105 -46 -69 -11 -82 -16 -114 -49 -19 -20 -48 -58 -63 -85 -25 -43 -27 -53 -21 -109 8 -77 1 -96 -35 -96 -40 0 -80 17 -80 33 0 36 -86 72 -107 45 -6
                 -7 -14 -36 -18 -64 l-7 -51 63 -25 c59 -24 68 -32 116 -103 60 -88 61 -95 17 -212 -24 -66 -89 -199 -106 -220 -25 -31 -32 -11 -69 202 -20 115 -49 195 -69
                 195 -6 0 -10 16 -10 35 0 20 -7 46 -15 59 -11 16 -17 62 -21 152 -7 141 -21 190 -56 203 -13 4 -57 11 -98 15 -56 5 -88 14 -127 36 l-53 30 -20 -20 c-29
                 -29 -170 -29 -218 0 -41 25 -54 25 -120 0 -63 -24 -110 -25 -215 -4 -70 14 -98 15 -218 4 -153 -13 -228 -8 -275 21 -39 24 -62 26 -44 4 7 -9 10 -19 6
                 -23 -12 -12 -773 -14 -956 -3 -147 9 -175 13 -209 33 -44 25 -59 21 -41 -11 13 -26 7 -26 -27 -3 -21 13 -49 17 -123 17 -84 0 -95 -2 -94 -17 2 -24 -18
                 -23 -68 3 -46 23 -79 22 -198 -6 -65 -15 -421 -34 -409 -21 3 4 46 11 95 17 49 5 107 14 128 20 63 17 24 31 -66 23 -52 -4 -78 -3 -78 4 0 6 4 13 9 17 15
                 9 211 42 216 37 3 -3 5 -18 5 -34 0 -29 -1 -29 70 -32 38 -1 38 4 -5 32 -34 22 -35 24 -35 87 0 51 -3 65 -15 65 -9 0 -15 -9 -15 -24 0 -34 -47 -52 -92
                 -37 -18 7 -94 14 -168 16 -127 4 -129 3 -41 -5 138 -12 246 -60 137 -60 -33 0 -52 -7 -75 -26 -44 -36 -75 -43 -270 -54 -196 -11 -246 -21 -254 -49 -3 -11
                 -21 -27 -41 -35 -38 -16 -50 -41 -44 -92 2 -22 9 -30 28 -32 14 -2 31 -9 38 -14 18 -15 15 -73 -8 -122 -37 -82 -50 -350 -19 -407 8 -15 5 -37 -12 -92 -19
                 -61 -20 -77 -10 -112 6 -22 17 -64 23 -93 12 -50 11 -55 -7 -68 -29 -22 -35 -17 -35 27 0 32 -3 40 -17 37 -9 -2 -22 -19 -28 -38 -13 -37 -40 -48 -40 -16
                 0 15 -12 22 -54 32 -35 8 -61 20 -72 35 -18 24 -19 24 -19 -43 0 -68 12 -118 29 -118 6 0 7 15 4 35 -4 27 -2 36 11 41 23 9 28 2 37 -57 5 -28 16 -60 25
                 -70 9 -10 14 -27 11 -38 -2 -11 3 -25 14 -35 28 -23 26 -114 -2 -150 -42 -54 -68 -27 -80 85 -16 143 -49 158 -49 22 0 -39 -4 -74 -9 -77 -16 -11 -24 141
                 -19 391 4 197 2 246 -9 250 -7 3 -13 14 -13 24 0 11 -6 30 -14 42 -9 14 -16 64 -19 137 -3 73 -11 129 -21 152 -10 24 -19 86 -24 170 l-7 132 -88 52 c-133
                 79 -215 98 -412 98 -134 0 -173 -3 -242 -22 -96 -26 -133 -27 -174 -6 -32 16 -129 20 -195 8 -32 -6 -36 -5 -31 10 8 21 -19 21 -180 0 -74 -9 -150 -13 -191
                 -10 -86 8 -126 8 -240 0 -75 -5 -94 -9 -102 -24 -9 -17 -39 -30 -103 -44 l-28 -7 17 32 c18 37 22 87 11 142 l-8 37 75 0 c58 0 83 -5 110 -21 l35 -20 12 23
                 c20 39 51 68 73 67 21 0 21 0 -1 -16 -13 -8 -23 -22 -23 -31 0 -12 8 -13 38 -7 20 4 57 10 80 14 27 5 53 18 67 34 l24 25 -162 6 c-89 3 -605 8 -1145 12
                 -978 6 -984 6 -973 -14z m1754 -31 c6 -25 -19 -61 -43 -61 -24 0 -51 27 -44 43 5 13 46 34 70 36 7 1 14 -8 17 -18z m-637 -10 c13 -10 20 -21 17 -25 -11
                 -10 -58 -7 -71 6 -35 35 12 52 54 19z m-185 -12 c64 -14 84 -32 80 -71 -3 -29 13 -58 31 -58 5 0 7 9 3 19 -7 23 3 27 104 36 56 6 75 4 82 -6 15 -25 -11 -48
                 -78 -69 -37 -11 -75 -28 -84 -36 -13 -12 -49 -16 -151 -20 l-133 -4 71 14 c97 19 112 24 124 46 11 21 -4 66 -25 74 -16 6 -57 -22 -50 -34 9 -14 -20 -30 -75
                 -40 -70 -13 -84 -2 -29 24 38 19 44 26 43 51 0 17 -4 42 -8 58 -7 24 -6 27 17 27 14 0 50 -5 78 -11z m-241 -17 c0 -15 -65 -45 -85 -40 -16 4 -10 9 25 26 50
                 24 60 26 60 14z m870 -79 c0 -10 -9 -33 -20 -51 -25 -41 -25 -44 3 -37 32 8 81 -9 85 -28 4 -21 -10 -21 -101 -1 -86 20 -122 37 -138 65 -8 16 -7 25 7 40
                 23 26 28 24 41 -12 10 -26 16 -30 44 -27 28 2 34 8 41 36 9 33 38 45 38 15z m6680 -1123 c-6 -11 -15 -20 -21 -20 -22 0 -21 35 2 79 l24 46 3 -42 c2 -23
                 -2 -51 -8 -63z m253 -104 c8 -53 -9 -163 -29 -193 -35 -54 -71 -50 -145 16 -59 52 -75 88 -60 134 12 34 15 36 109 60 53 14 102 26 108 26 6 1 14 -19 17
                 -43z m-4336 -325 c-5 -153 -19 -210 -32 -137 -10 51 -1 368 10 386 17 27 27 -86 22 -249z m4321 -27 c-7 -27 -17 -52 -23 -56 -18 -14 -33 -8 -40 15 -7 21
                 -8 20 -14 -8 -5 -23 -17 -35 -51 -52 -55 -26 -60 -21 -59 72 0 71 1 71 29 0 l12 -30 19 38 c13 25 26 37 40 37 12 0 35 12 51 26 41 33 52 20 36 -42z
                 m-4508 -58 c-3 -70 -1 -76 33 -91 l32 -14 -45 2 -45 2 -6 55 c-6 59 4 105 22 95 6 -4 10 -26 9 -49z m6 -211 c39 -26 96 -41 114 -30 7 4 10 16 7 26 -6 23
                 -3 23 26 5 12 -8 58 -24 102 -36 117 -31 114 -29 68 -50 -60 -27 -66 -56 -18 -97 49 -42 63 -42 31 0 -15 19 -26 39 -26 45 0 15 27 28 74 35 32 5 45 2 73
                 -20 32 -25 37 -26 66 -14 43 18 100 25 142 17 33 -6 35 -9 35 -46 0 -27 -8 -48 -26 -69 -31 -38 -40 -38 -70 -6 -31 33 -64 33 -64 1 0 -35 -15 -40 -40
                 -14 -19 20 -29 22 -110 20 -88 -3 -90 -2 -122 28 -17 17 -42 33 -55 36 -23 6 -355 -9 -382 -17 -16 -5 -24 11 -13 28 12 19 149 52 248 59 l89 7 -19 -22 -19
                 -21 49 0 c27 0 55 4 62 8 14 9 6 42 -12 54 -6 4 -166 8 -356 9 -190 0 -340 2 -335 5 6 2 48 8 95 13 156 18 267 39 296 55 39 21 47 20 90 -9z m-3586 -45 c0
                 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m5098 -12 c-25 -7 -47 -6 -65 0 -24 9 -18 10 37 10 61 0 63 -1 28 -10z m4176 -1 c312 -3
                 360 -5 370 -19 7 -9 27 -22 46 -30 19 -8 41 -22 49 -31 12 -15 10 -17 -23 -17 -31 0 -39 -5 -62 -41 -23 -37 -29 -41 -55 -35 -16 3 -29 2 -29 -3 0 -11 43
                 -28 95 -39 32 -7 9 -10 -105 -15 -206 -10 -288 -9 -270 3 8 5 27 10 42 10 33 0 58 9 58 21 0 10 -205 12 -367 2 -112 -6 -134 -15 -68 -27 69 -13 41 -21 -63
                 -18 -61 2 -110 9 -114 15 -7 11 -231 12 -275 1 -37 -10 -27 5 16 22 27 10 41 23 43 37 3 20 22 34 78 55 l25 9 -25 13 c-13 7 -43 13 -67 14 -42 1 -50 9 -35
                 33 6 10 17 8 47 -7 27 -14 59 -20 103 -20 64 0 64 0 42 -18 -30 -25 -18 -42 28 -42 37 0 82 22 82 40 0 21 -58 45 -134 56 -45 7 -103 15 -129 19 -41 7 -53
                 5 -77 -14 -21 -15 -37 -19 -55 -15 -17 4 -33 -1 -51 -15 -19 -15 -40 -21 -73 -20 l-46 1 60 21 c57 20 58 21 30 27 l-30 6 38 15 c32 13 82 15 290 12 139 -2
                 413 -4 611 -6z m-4749 -9 c-22 -5 -50 -11 -63 -14 -13 -3 -20 -9 -16 -16 5 -7 34 -9 89 -5 46 3 86 3 90 -1 4 -4 142 -5 308 -3 166 1 376 0 467 -4 l166 -7 2
                 -26 c1 -14 3 -27 5 -28 7 -7 72 32 69 41 -4 14 99 34 176 35 49 0 62 -3 62 -15 0 -11 11 -15 40 -15 34 0 42 -4 55 -30 8 -16 15 -37 15 -47 0 -15 2 -16
                 10 -3 5 8 10 36 10 63 0 47 0 47 34 47 19 0 37 4 41 10 9 15 137 5 172 -13 28 -15 47 -16 140 -9 59 5 121 5 137 1 40 -11 66 -38 66 -66 0 -32 26 -80 50 -93
                 23 -13 27 2 5 20 -24 20 -17 39 21 55 50 21 87 19 102 -5 7 -12 23 -20 38 -20 17 0 28 7 31 20 4 16 14 20 49 20 33 0 46 -5 54 -20 6 -11 17 -20 25 -20 8 0
                 24 9 35 20 25 25 36 25 83 1 45 -23 47 -15 7 24 -16 16 -30 32 -30 36 0 11 90 20 285 27 106 4 192 12 209 20 15 7 74 15 130 19 83 5 113 2 157 -12 81 -26
                 85 -38 12 -32 -35 3 -65 1 -68 -3 -11 -18 96 -97 144 -106 24 -5 30 -12 33 -40 l4 -34 -211 1 c-557 1 -1424 18 -1480 29 -71 14 -105 33 -105 61 0 17 -3
                 18 -22 8 -13 -7 -53 -43 -89 -80 l-66 -69 -7 -117 c-5 -82 -13 -131 -26 -161 -11 -24 -17 -48 -14 -53 3 -5 -3 -22 -13 -39 -18 -31 -18 -32 12 -104 7 -15 5
                 -17 -13 -12 -15 5 -22 2 -27 -14 -8 -25 -23 -25 -105 0 -85 26 -87 25 -45 -11 19 -17 39 -45 43 -63 10 -40 39 -46 52 -12 13 34 40 24 40 -15 0 -34 -29 -57
                 -85 -66 l-39 -6 59 -23 c90 -34 113 -32 133 14 16 37 16 37 24 -133 4 -93 12 -187 18 -207 8 -29 7 -45 -5 -74 -20 -48 -19 -59 6 -112 21 -42 21 -48 9 -150
                 -15 -125 -8 -187 26 -237 13 -20 24 -40 24 -45 0 -6 8 -10 18 -10 9 0 33 -10 52 -22 19 -11 46 -22 60 -23 14 -2 75 -10 135 -19 61 -8 126 -16 145 -16 20 0
                 62 -7 93 -15 53 -14 60 -13 83 1 26 18 107 16 234 -5 87 -15 473 -12 730 4 237 15 287 14 370 -11 25 -7 113 -18 195 -23 83 -6 160 -13 173 -17 16 -5 22
                 -2 22 9 0 9 10 22 22 28 32 17 191 39 287 39 74 0 86 -3 113 -25 16 -14 42 -25 57 -25 16 0 37 -10 50 -24 l23 -25 29 21 c27 20 32 21 98 9 72 -13 91 -10
                 91 14 0 14 50 35 84 35 19 -1 19 -2 -7 -25 l-28 -25 48 -1 48 -1 -37 -15 c-54 -22 -63 -63 -14 -63 65 0 59 -96 -8 -120 -49 -18 -242 -24 -324 -10 -61 10
                 -74 16 -84 38 -19 38 -36 38 -53 -2 -18 -42 -42 -49 -155 -44 l-75 3 3 75 3 75 -36 -73 c-20 -40 -46 -79 -58 -86 -12 -8 -49 -17 -82 -21 -87 -10 -118 -33
                 -175 -130 -28 -47 -30 -55 -24 -121 7 -79 1 -90 -49 -86 -37 3 -67 19 -67 36 0 38 -104 63 -114 29 -13 -47 -17 -110 -11 -159 6 -51 8 -54 28 -47 32 10 71
                 10 76 -2 2 -5 -12 -18 -32 -27 -27 -14 -34 -22 -30 -38 4 -14 0 -20 -11 -20 -23 0 -20 -12 14 -55 17 -21 30 -42 30 -47 0 -18 -60 23 -74 51 -9 16 -27 43
                 -42 60 -18 22 -25 39 -21 57 2 15 -2 31 -10 40 -17 17 -32 122 -33 227 0 73 -20 131 -48 142 -9 4 -54 11 -99 15 -61 6 -95 15 -131 36 -46 26 -51 27 -66
                 11 -36 -35 -164 -34 -232 2 -33 18 -40 18 -82 5 -125 -39 -127 -39 -201 -20 -62 16 -94 17 -275 11 l-205 -7 -52 26 c-52 28 -72 28 -50 2 22 -26 -44 -30
                 -494 -28 -492 1 -612 9 -667 42 -38 22 -61 13 -38 -15 16 -19 6 -19 -31 1 -35 17 -154 30 -193 20 -21 -5 -26 -11 -21 -26 8 -26 0 -25 -59 6 -54 29 -84 29
                 -186 -2 -40 -11 -107 -17 -245 -20 l-190 -3 106 14 c59 8 119 20 135 26 l29 12 -29 8 c-16 4 -51 4 -78 -1 -55 -11 -89 -2 -66 17 11 9 175 43 207 43 6 0
                 11 -15 13 -32 3 -32 4 -33 58 -36 l55 -3 -41 28 -41 28 29 23 c17 14 28 33 28 48 0 54 -81 88 -88 36 -6 -43 -83 -57 -106 -19 -29 47 80 78 354 102 96 8 185
                 18 198 21 18 5 22 2 22 -18 0 -13 7 -34 16 -47 21 -30 13 -37 -71 -58 l-70 -17 49 -8 c27 -4 98 -8 158 -10 l110 -3 24 30 23 30 32 -19 c61 -36 239 -12
                 274 37 8 12 15 31 15 42 -1 18 -4 17 -25 -10 -13 -16 -27 -26 -31 -22 -8 7 70 86 124 127 l33 25 -18 122 -17 123 38 39 38 39 -1 105 c-1 62 3 108 9 112 17
                 10 12 22 -25 56 l-35 33 0 438 0 438 45 58 45 57 -57 56 -58 56 -270 6 c-148 3 -284 10 -302 15 -17 6 -41 20 -53 32 -21 21 -29 22 -94 16 -75 -7 -102 -20
                 -67 -31 34 -11 24 -22 -25 -29 -73 -10 -345 -7 -384 4 -62 17 -72 31 -85 111 l-11 74 -37 -3 c-20 -2 -37 -1 -37 2 0 14 104 35 175 34 72 0 76 -1 40 -10z
                 m-4705 -92 l0 -84 43 -5 c23 -4 93 -9 155 -13 l114 -7 -4 24 c-3 20 -25 39 -63 52 -5 2 9 25 33 52 23 26 42 52 42 57 0 6 114 8 286 6 l285 -3 -31 -27
                 c-23 -22 -40 -28 -75 -28 -44 0 -45 -1 -45 -32 0 -63 0 -63 207 -63 103 0 200 4 216 9 22 6 27 5 27 -8 0 -8 12 -22 28 -30 l27 -15 -47 -1 c-26 0 -193 -4
                 -370 -10 -178 -5 -452 -12 -610 -15 l-288 -6 0 33 c0 18 7 42 15 52 9 12 15 42 15 77 0 53 6 65 33 68 4 1 7 -37 7 -83z m2230 60 c0 -2 -7 -17 -15 -32 -31
                 -60 -30 -60 143 -67 87 -3 187 -13 224 -21 51 -13 87 -14 158 -8 84 7 124 1 86 -14 -23 -9 -20 -22 7 -27 12 -2 -129 -2 -313 0 -330 5 -430 12 -456 34 -29
                 24 -9 99 26 99 11 0 25 9 32 20 10 15 24 20 60 20 27 0 48 -2 48 -4z m678 -69 c2 -46 -1 -67 -8 -62 -5 3 -10 15 -10 25 0 43 -123 81 -195 61 -50 -13 -56 -7
                 -14 15 39 19 115 33 179 30 l45 -1 3 -68z m-1623 29 c30 -14 55 -28 55 -32 0 -10 -150 5 -172 17 -15 8 -17 13 -7 25 17 21 61 17 124 -10z m2008 3 c-15 -15
                 -79 -28 -93 -19 -24 15 2 30 53 30 36 0 48 -3 40 -11z m-135 -16 c-3 -19 -56 -30 -86 -18 -14 5 -13 9 8 20 37 21 82 19 78 -2z m-21 -102 c21 -5 47 -12 58
                 -14 11 -3 -32 -5 -95 -5 -106 -1 -112 0 -83 13 17 7 34 22 38 34 6 20 6 20 25 1 10 -10 36 -23 57 -29z m556 3 c63 -11 41 -25 -35 -22 -46 2 -73 7 -76 16 -4
                 13 46 16 111 6z m-2855 -1897 c2 -9 -4 -20 -13 -23 -18 -7 -58 24 -49 38 10 16 59 4 62 -15z m701 -44 c14 -50 0 -75 -42 -71 -27 2 -32 7 -32 28 0 15 6 25
                 15 25 8 0 16 9 18 20 9 56 25 56 41 -2z m2171 28 c43 -8 49 -37 8 -34 -22 1 -28 -3 -28 -18 0 -21 10 -23 145 -20 85 2 95 -1 114 -36 13 -26 81 -48 118
                 -38 21 5 24 10 18 36 -5 22 -2 36 10 49 9 10 15 29 13 42 -2 19 2 23 22 23 38 0 39 -34 1 -65 -27 -23 -29 -28 -17 -45 13 -18 14 -18 41 3 42 34 78 29 101
                 -13 6 -11 23 -24 38 -30 34 -12 48 6 40 53 l-6 32 63 0 c88 0 185 -17 228 -40 l36 -19 -47 -1 c-34 0 -54 -6 -76 -25 -47 -40 -77 -46 -288 -60 -194 -12 -246
                 -23 -241 -47 1 -7 -13 -19 -32 -28 -43 -22 -61 -46 -61 -85 0 -35 16 -56 36 -48 44 17 58 -64 24 -139 -36 -82 -48 -343 -18 -404 7 -16 4 -39 -12 -90 l-21
                 -69 23 -88 c24 -91 20 -118 -16 -125 -13 -3 -16 5 -16 38 0 33 -3 41 -16 38 -9 -2 -22 -19 -28 -38 -6 -21 -19 -36 -30 -38 -15 -3 -17 0 -12 14 8 22 -9 34
                 -52 34 -22 0 -41 9 -58 25 -14 14 -27 25 -29 25 -9 0 -5 -119 5 -144 13 -35 31 -33 24 2 -4 19 0 32 11 42 22 18 35 -1 35 -51 0 -21 8 -47 20 -62 11 -14
                 20 -36 20 -50 0 -13 7 -30 15 -37 19 -16 20 -104 2 -140 -14 -29 -48 -37 -65 -17 -7 8 -17 50 -22 93 -11 89 -18 114 -30 114 -14 0 -20 -30 -20 -101 0 -86
                 -15 -90 -24 -8 -9 87 -6 444 3 504 10 56 6 75 -15 75 -8 0 -14 10 -14 25 0 14 -6 36 -14 48 -9 14 -15 62 -18 139 -3 71 -9 122 -16 131 -15 17 -32 153 -32
                 248 l0 67 -67 40 c-146 88 -256 116 -448 116 -102 0 -148 -5 -214 -23 -107 -28 -148 -30 -196 -7 -33 16 -53 18 -132 12 -80 -6 -92 -4 -87 9 8 19 -10 19
                 -146 -1 -128 -18 -262 -20 -301 -4 -21 9 -36 9 -60 1 -18 -6 -65 -11 -105 -11 -62 0 -77 -3 -101 -24 -15 -13 -46 -29 -68 -35 -61 -17 -68 -15 -49 15 19 28
                 24 101 11 152 l-9 32 90 0 c104 0 160 9 140 21 -7 5 -38 9 -68 9 -70 0 -136 18 -122 32 12 12 235 6 265 -7 23 -10 22 -21 -3 -28 -20 -5 -27 -31 -12 -41 5
                 -3 30 1 57 9 26 8 61 15 76 15 19 0 34 8 45 25 20 31 76 47 152 43 l57 -3 -30 -16 c-16 -9 -50 -20 -75 -24 -60 -10 -70 -20 -70 -69 l0 -41 21 33 c12 18 32
                 35 48 38 52 11 108 35 131 56 13 12 44 25 69 29 60 10 443 10 504 0 75 -12 78 -29 8 -38 -23 -3 -44 -9 -47 -14 -7 -11 44 -109 57 -109 5 0 32 11 59 25 52
                 26 65 48 54 89 -9 37 19 44 186 52 151 8 180 1 196 -48 8 -22 8 -22 14 7 3 16 14 34 26 40 22 11 245 8 304 -4z m197 -2 c13 -5 21 -13 18 -19 -11 -18 -59
                 -10 -100 16 l-40 25 49 -6 c27 -4 60 -11 73 -16z m-3457 -14 c0 -35 41 -34 69 1 19 25 51 28 51 5 0 -19 -150 -121 -177 -121 -22 0 -14 16 15 29 l27 13 -22
                 15 c-27 18 -30 45 -7 67 23 24 44 20 44 -9z m332 -1 c4 -3 5 -19 1 -36 -4 -22 -2 -33 10 -39 11 -7 14 -18 10 -42 -4 -25 0 -37 18 -52 l22 -20 -5 22 c-8 30
                 -4 31 88 43 65 8 81 8 93 -5 23 -23 -9 -50 -89 -74 -38 -12 -70 -26 -72 -32 -3 -10 -156 -28 -247 -29 -61 0 -48 7 34 20 121 18 147 37 122 88 -16 35 -60
                 38 -65 4 -4 -29 -27 -40 -96 -48 -61 -7 -80 9 -25 21 17 4 40 16 50 26 18 18 18 20 -1 69 -23 58 -25 86 -7 94 13 6 147 -3 159 -10z m568 -139 c0 -9 -9 -34
                 -20 -55 l-20 -40 39 0 c53 0 71 -8 71 -32 0 -16 -5 -19 -23 -14 -12 3 -47 10 -76 17 -71 15 -112 33 -135 58 -18 19 -18 22 -2 46 20 30 36 32 36 5 0 -11 11
                 -27 25 -36 31 -20 65 -10 65 20 0 22 17 46 32 46 4 0 8 -7 8 -15z m6686 -1122 c-7 -24 -30 -31 -42 -12 -3 6 4 34 17 62 l24 51 3 -39 c2 -21 1 -49 -2 -62z
                 m43 -225 c1 -42 -11 -55 -29 -33 -16 19 -15 60 1 100 l12 30 7 -30 c4 -16 8 -47 9 -67z m-4131 -188 c-5 -180 -20 -250 -33 -146 -9 70 -1 343 11 371 16 40
                 26 -56 22 -225z m4328 -17 c-16 -70 -17 -90 -7 -115 15 -41 29 -34 33 15 2 28 8 42 17 42 22 0 32 -52 25 -122 l-6 -63 -38 0 c-63 0 -100 -11 -100 -29 0 -10
                 -9 -27 -20 -39 -37 -40 -87 -54 -270 -77 l-35 -4 30 25 c44 38 89 54 140 52 62 -2 76 14 82 96 5 61 4 66 -15 66 -26 0 -32 18 -31 93 l1 62 21 -47 21 -48
                 21 38 c14 26 28 38 47 40 14 2 34 12 44 23 26 29 48 25 40 -8z m-4516 -102 c0 -60 11 -80 45 -82 11 -1 5 -5 -15 -9 -22 -5 -40 -5 -47 2 -13 11 -19 139 -7
                 151 15 15 24 -8 24 -62z m1535 -251 c14 -6 0 -9 -45 -9 -45 0 -59 3 -45 9 11 5 31 9 45 9 14 0 34 -4 45 -9z m2779 -72 c47 -21 44 -78 -3 -63 -25 8 -45 41
                 -37 61 7 17 8 17 40 2z m1239 0 c21 -11 21 -11 2 -19 -11 -4 -32 -8 -46 -8 -14 -1 -34 -10 -43 -20 -17 -19 -17 -20 13 -36 36 -18 38 -25 11 -56 -16 -20
                 -19 -21 -20 -6 0 23 -39 37 -106 37 -77 0 -120 20 -129 60 -4 17 -4 34 0 38 4 4 61 10 128 13 67 3 132 6 145 7 13 1 33 -3 45 -10z m-1344 -7 c21 -14 3 -31
                 -32 -31 -38 0 -62 13 -53 29 8 13 65 14 85 2z m1740 -20 c21 -6 22 -8 8 -19 -9 -8 -13 -20 -11 -27 10 -24 -68 -41 -129 -28 -42 9 -56 8 -74 -3 -21 -13
                 -23 -13 -23 4 0 23 -19 42 -35 36 -18 -7 -28 13 -13 22 32 20 224 30 277 15z m-1324 -25 c0 -26 -78 -54 -100 -36 -18 15 -20 46 -2 54 6 3 32 4 57 3 37 -2
                 45 -5 45 -21z m481 -61 c20 -14 49 -25 65 -25 33 0 45 -15 29 -35 -29 -35 -193 -7 -259 43 -23 19 -23 19 27 12 52 -6 92 2 92 20 0 14 5 13 46 -15z
                 m-2696 -852 c0 -8 -5 -24 -11 -35 -11 -21 -12 -21 -25 -3 -17 23 -18 52 -1 58 18 6 37 -3 37 -20z m2770 -1154 c0 -26 -121 -52 -135 -28 -10 15 -1 21 42 29
                 58 12 93 11 93 -1z`,
            `M4540 10607 c-39 -19 -42 -22 -21 -25 25 -4 107 23 116 39 11 17 -50 9 -95 -14z`,
            `M9554 10686 c-36 -16 -58 -42 -28 -33 11 4 32 9 48 13 28 7 29 6 22 -20 -6 -23 -4 -27 11 -24 9 2 24 16 33 31 13 24 13 29 0 37 -21 13 -49 12 -86 -4z`,
            `M4906 10602 c-48 -17 -74 -42 -42 -42 21 0 106 42 106 52 0 11 -7 10 -64 -10z`,
            `M7876 10611 c-26 -10 -12 -17 52 -26 56 -7 82 -25 82 -56 0 -27 15 -24 28 6 17 38 15 50 -11 62 -33 15 -128 24 -151 14z`,
            `M5993 10553 c-30 -6 -29 -19 1 -27 29 -7 80 10 71 24 -6 10 -31 11 -72 3z`,
            `M6715 8660 c-64 -11 -34 -90 35 -90 49 0 73 19 68 55 -2 25 -8 31 -38 35 -19 3 -48 3 -65 0z`,
            `M5169 8603 c-1 -5 0 -18 0 -30 1 -12 -8 -28 -21 -37 -22 -16 -21 -17 37 -14 53 3 60 6 63 24 2 14 -7 29 -24 43 -27 21 -53 28 -55 14z`,
            `M5322 8580 c14 -4 41 -4 60 0 28 7 24 8 -27 8 -46 0 -54 -2 -33 -8z`,
            `M9505 8560 c-4 -7 0 -19 8 -27 21 -21 70 -9 65 15 -4 20 -62 29 -73 12z`,
            `M800 10610 c-21 -13 15 -50 50 -50 33 0 76 32 66 48 -9 14 -95 16 -116 2z`,
            `M2925 8578 c-104 -15 -340 -60 -345 -64 -2 -3 39 -2 90 2 93 6 95 6 105 -17 8 -20 20 -25 77 -33 84 -10 138 -2 138 22 0 10 9 35 20 57 11 22 20 41 20 42 0 5 -40 1 -105 -9z`,
            `M3348 8543 c-14 -2 -32 -11 -38 -18 -19 -23 28 -35 140 -35 107 0 129 10 90 42 -17 14 -38 18 -94 17 -39 -1 -83 -4 -98 -6z`,
            `M3960 8493 c0 -11 70 -65 98 -75 23 -9 31 -17 27 -29 -4 -11 3 -23 17 -33 33 -23 41 -20 34 16 -8 38 -33 68 -57 68 -10 0 -26 11 -34 24 -10 16 -28 25 -50 28 -19 2 -35 3 -35 1z`,
            `M1995 8429 c-23 -5 -16 -7 28 -8 31 0 57 1 57 3 0 8 -56 11 -85 5z`,
            `M3995 8230 c-8 -26 3 -60 20 -60 19 0 28 35 15 60 -14 25 -27 26 -35 0z`,
            `M3952 7634 c-16 -11 -22 -26 -22 -52 0 -36 -9 -83 -25 -131 -16 -50 18 -7 51 65 47 101 45 152 -4 118z`,
            `M8412 6791 c2 -30 8 -56 14 -58 18 -6 24 24 12 59 -19 57 -29 57 -26 -1z`,
            `M2350 12770 c-18 -6 -6 -8 45 -10 l70 -2 -35 -13 c-19 -7 -48 -14 -63 -14 -50 -2 -67 -17 -67 -63 l0 -42 28 30 c17 20 47 37 84 48 31 9 67 25 80 36 l23 19 -22 1 c-12 0 -25 5 -28 10 -7 12 -79 12 -115 0z`,
            `M4328 12775 c-3 -3 -3 -15 -1 -26 7 -27 48 -22 48 6 0 19 -33 33 -47 20z`,
            `M7128 12743 c-27 -7 -23 -58 4 -77 46 -32 118 -8 118 39 0 14 -8 29 -19 35 -20 10 -71 12 -103 3z`,
            `M9509 12734 c-11 -14 -10 -18 6 -30 10 -8 22 -14 25 -14 3 0 15 6 25 14 16 12 17 16 6 30 -7 9 -21 16 -31 16 -10 0 -24 -7 -31 -16z`,
            `M3377 12721 c-60 -7 -90 -28 -61 -44 43 -24 244 -19 244 6 0 33 -81 50 -183 38z`,
            `M4348 12707 c-20 -16 -25 -26 -18 -37 8 -13 13 -13 39 3 45 27 87 23 103 -10 11 -23 20 -28 48 -28 35 0 35 0 32 39 -3 25 1 42 10 47 7 5 -28 9 -87 8 -88 0 -103 -2 -127 -22z`,
            `M10205 12720 c-4 -6 12 -10 40 -10 28 0 44 4 40 10 -3 6 -21 10 -40 10 -19 0 -37 -4 -40 -10z`,
            `M3998 12630 c24 -21 53 -40 65 -42 16 -2 22 -10 22 -28 0 -29 48 -61 53 -35 6 27 -23 70 -60 88 -21 10 -38 25 -38 32 0 16 -22 25 -58 25 -27 -1 -26 -2 16 -40z`,
            `M1975 12600 c-4 -6 13 -10 44 -10 28 0 51 5 51 10 0 6 -20 10 -44 10 -25 0 -48 -4 -51 -10z`,
            `M3997 12413 c-12 -11 -7 -50 7 -62 12 -10 17 -8 25 7 18 35 -8 80 -32 55z`,
            `M3940 11800 c-14 -14 -18 -28 -14 -44 5 -20 -2 -58 -23 -130 -4 -13 -1 -16 7 -11 18 11 80 150 80 179 0 32 -22 34 -50 6z`,
            `M10223 8543 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z`,
            `M902 6498 c-18 -18 -15 -36 8 -50 26 -17 25 -22 -7 -37 l-27 -13 24 -19 c33 -24 83 -34 109 -20 16 9 21 21 21 51 0 48 -17 61 -42 31 -12 -16 -19 -19 -22 -10 -21 77 -37 94 -64 67z`,
            `M2286 6451 c-19 -21 -20 -33 -4 -49 16 -16 38 3 38 34 0 31 -14 37 -34 15z`,
            `M5988 6409 c-25 -14 -22 -34 8 -58 46 -36 94 -23 94 26 0 37 -61 56 -102 32z`,
            `M602 6387 c-8 -9 -9 -24 -2 -48 6 -18 10 -37 10 -41 0 -15 -238 -8 -319 8 -79 16 -79 16 -110 -7 -20 -15 -57 -27 -104 -34 l-72 -11 -3 -942 -2
                 -942 22 -5 c117 -26 349 -33 367 -11 7 8 37 18 67 22 30 3 65 15 78 25 15 12 40 19 69 19 24 0 70 9 103 20 49 17 63 19 87 9 36 -15 122 -16 353 -5 191 9
                 254 5 254 -19 0 -8 -4 -15 -10 -15 -5 0 -10 -5 -10 -11 0 -24 73 0 110 36 20 19 41 35 47 35 7 0 31 -23 55 -51 l43 -51 116 7 c97 6 118 5 131 -8 13 -14 18
                 -12 41 15 24 26 30 29 59 21 18 -4 66 -8 106 -8 39 0 72 -1 72 -2 0 -1 -26 -10 -57 -19 -51 -16 -55 -18 -33 -25 61 -17 101 -10 141 25 45 41 92 56 179
                 56 45 0 79 -7 110 -22 40 -18 71 -22 260 -29 190 -6 254 -14 310 -36 10 -4 6 -8 -14 -12 -42 -9 -54 -33 -33 -65 45 -68 110 -70 68 -3 l-22 35 31 7 c16 4
                 37 16 45 28 13 18 13 24 0 43 l-14 21 123 7 c137 8 288 -1 312 -18 10 -8 35 -10 72 -5 95 12 122 11 129 -7 4 -10 -1 -22 -12 -30 -17 -12 -16 -14 11 -24
                 16 -6 52 -13 79 -15 50 -4 50 -4 47 -35 -4 -39 16 -37 38 4 14 27 14 29 -18 56 l-34 28 214 7 c117 4 324 5 459 3 218 -3 247 -5 263 -20 24 -24 51 -23 76
                 4 27 29 115 39 251 28 123 -10 158 -17 181 -38 15 -12 27 -13 75 -4 32 6 128 16 213 22 336 22 319 20 347 46 15 14 38 24 56 24 16 0 45 10 64 21 31 20 41
                 21 89 12 52 -9 54 -9 48 11 -22 71 -79 156 -104 156 -16 0 -18 4 -10 28 5 15 13 99 19 187 6 88 16 175 22 193 10 29 9 39 -9 71 -24 42 -37 159 -47 412 -5
                 148 -13 224 -31 294 -7 30 -7 30 14 12 20 -18 20 -18 27 3 4 11 3 51 -1 87 l-7 67 -17 -54 -16 -55 -8 119 -7 119 -91 86 -91 86 -381 0 c-349 0 -384 2
                 -414 18 -33 18 -34 18 -69 -3 -34 -20 -45 -21 -192 -14 -86 3 -183 2 -216 -3 -36 -6 -282 -7 -620 -4 -559 7 -560 7 -602 30 -46 25 -96 22 -107 -5 -8 -20
                 -23 -18 -59 8 -46 33 -145 32 -207 -1 -38 -21 -62 -25 -157 -29 -122 -4 -231 10 -275 36 -26 15 -30 15 -61 -3 -28 -18 -50 -20 -197 -20 -145 1 -172 3 -225
                 23 -70 26 -116 28 -175 7 -65 -23 -80 -20 -80 14 0 33 -24 48 -42 25 -49 -60 -75 -68 -110 -37 -18 16 -25 16 -130 -6 l-110 -23 -22 20 -21 21 -63 -31 -63
                 -30 -46 17 c-44 17 -47 17 -77 -1 -41 -24 -268 -31 -406 -12 -86 12 -95 15 -91 33 10 45 6 80 -10 83 -9 1 -21 -3 -27 -11z m-262 -1982 c17 -20 5 -45 -20
                 -45 -11 0 -23 7 -26 15 -6 15 11 45 26 45 4 0 13 -7 20 -15z`,
            `M715 6390 c-14 -6 -4 -9 35 -8 42 0 49 2 30 8 -32 10 -41 10 -65 0z`,
            `M5986 6252 c-7 -11 41 -65 77 -87 26 -17 27 -16 27 2 0 26 -26 61 -60 78 -34 17 -37 18 -44 7z`,
            `M6321 4515 c-59 -42 -64 -59 -20 -74 32 -11 119 -15 119 -6 0 9 -55 105 -60 104 -3 0 -20 -11 -39 -24z`,
            `M965 4400 c-14 -6 5 -9 57 -9 42 -1 80 4 83 9 8 12 -111 12 -140 0z`,
            `M10196 4385 c-4 -12 -32 -15 -160 -15 -164 0 -217 -9 -196 -35 6 -7 24 -15 38 -17 22 -2 26 -8 24 -25 -3 -20 1 -23 28 -23 27 0 31 3 28 23 -3 22 -1 22 134 27 147 5 165 12 154 54 -7 29 -40 36 -50 11z`,
            `M7298 4315 c-16 -30 -28 -56 -28 -57 0 -2 21 -4 46 -4 29 -1 56 -9 73 -22 28 -20 28 -20 57 -1 18 12 47 19 77 19 49 0 87 13 87 30 0 6 -44 10 -104 10 -126 0 -154 9 -162 50 -4 16 -9 30 -13 30 -3 0 -18 -25 -33 -55z`,
            `M9449 4344 c-8 -9 -32 -25 -54 -35 l-40 -18 43 0 c56 -1 82 13 82 44 0 29 -12 32 -31 9z`,
            `M4157 4331 c8 -29 -14 -61 -42 -61 -18 0 -25 5 -25 19 0 24 -30 35 -69 26 -35 -7 -57 -30 -67 -69 l-7 -29 118 6 c148 7 161 16 126 82 -24 46 -43 60 -34 26z`,
            `M6625 4339 c-111 -25 -129 -55 -28 -46 l66 7 -23 -25 -23 -25 25 0 c29 0 88 49 88 73 0 27 -34 32 -105 16z`,
            `M5842 4308 c-35 -35 13 -59 72 -34 20 8 36 19 36 24 0 23 -87 31 -108 10z`,
            `M8620 4297 c0 -21 151 -53 178 -38 13 7 11 11 -9 21 -24 14 -169 27 -169 17z`,
            `M2125 4280 c-10 -16 15 -41 39 -38 32 5 28 42 -6 46 -14 2 -29 -2 -33 -8z`,
            `M125 4268 c-37 -21 -46 -48 -16 -48 26 0 66 34 57 48 -9 15 -15 15 -41 0z`,
            `M320 4270 c-13 -9 -13 -11 0 -20 19 -12 90 -13 90 -1 0 9 -11 14 -60 27 -8 2 -22 -1 -30 -6z`,
            `M4550 4247 l-45 -22 32 -3 c28 -3 113 30 113 43 0 13 -60 2 -100 -18z`,
            `M9774 4219 c-55 -29 -63 -39 -31 -39 33 0 74 18 81 36 9 23 -9 24 -50 3z`,
            `M9264 4204 c9 -8 16 -19 16 -24 0 -30 -211 -42 -264 -15 -15 8 -38 15 -51 15 -26 0 -50 -24 -40 -40 8 -13 -32 -28 -62 -22 -13 2 -28 13 -34 23
                 -9 18 -11 18 -47 -1 -27 -15 -56 -20 -110 -20 -59 0 -79 -4 -99 -20 -26 -20 -63 -27 -63 -11 0 5 10 16 23 24 l22 16 -25 21 c-31 25 -104 46 -188 55 -44 4
                 -62 3 -57 -4 3 -6 14 -11 23 -11 9 0 23 -4 31 -9 14 -9 -13 -28 -75 -51 -23 -9 -25 -8 -18 12 7 23 6 23 -15 4 -26 -24 -27 -46 -1 -93 25 -46 25 -62 1
                 -138 l-19 -59 -6 40 c-4 22 -15 54 -25 71 -19 30 -87 69 -142 80 -41 8 -79 46 -79 81 0 48 -45 74 -52 30 -5 -35 -72 -46 -140 -22 -50 17 -126 18 -1558 16
                 -828 -2 -1533 -5 -1568 -8 -55 -6 -60 -8 -45 -20 17 -13 17 -13 -2 -14 -11 0 -46 16 -79 36 -56 35 -60 36 -85 21 -38 -22 -171 -37 -337 -37 -77 0 -176 -3
                 -218 -6 l-77 -7 19 -21 c44 -51 48 -61 15 -36 -26 19 -52 25 -140 32 -61 5 -121 16 -138 25 -24 13 -72 17 -235 19 -113 1 -225 7 -250 13 -43 10 -86 8
                 -459 -19 -79 -6 -199 -10 -267 -9 -109 1 -128 -1 -159 -20 -44 -26 -53 -26 -85 -1 -30 23 -56 26 -63 6 -14 -35 -200 -44 -327 -16 -37 8 -118 11 -246 8
                 -159 -3 -195 -6 -217 -21 -44 -29 -117 -17 -117 20 0 13 -5 12 -26 -8 -22 -21 -35 -24 -92 -23 -37 0 -96 9 -131 19 l-63 18 -17 -22 c-17 -22 -18 -22 -78 -7
                 -80 21 -132 20 -156 -1 -16 -16 -41 -18 -176 -18 -148 0 -159 1 -211 27 -30 14 -83 30 -117 35 l-63 10 0 -2064 0 -2063 238 0 c277 0 499 14 547 35 24 9
                 35 21 35 34 0 27 -23 27 -90 -1 -49 -20 -53 -21 -62 -4 -7 12 -17 16 -33 12 -29 -7 -55 2 -55 19 0 14 18 20 90 30 30 4 75 0 132 -13 47 -11 90 -16 95 -11
                 4 4 30 1 56 -9 l49 -17 30 38 c32 40 48 44 109 25 l39 -11 -27 -25 c-26 -24 -26 -25 -8 -38 33 -25 188 -14 313 22 42 12 51 18 43 28 -8 10 -7 16 3 25 11
                 10 16 9 23 -4 7 -12 15 -14 34 -7 17 7 33 5 54 -6 25 -13 60 -14 220 -8 104 4 234 11 288 17 55 5 102 8 104 5 3 -3 -10 -24 -29 -48 -19 -24 -38 -51 -41 -60
                 -8 -24 24 -34 40 -11 25 36 34 43 61 43 51 0 72 11 72 36 0 28 12 31 34 9 9 -10 17 -11 21 -5 3 6 -1 13 -9 16 -9 3 -13 10 -10 15 9 15 42 10 52 -6 7 -13
                 14 -14 36 -5 42 15 77 13 70 -5 -8 -20 20 -20 36 0 9 10 9 15 1 15 -6 0 -11 5 -11 10 0 6 15 10 33 10 25 0 42 -9 72 -40 21 -22 48 -40 58 -40 18 0 19 5 13
                 55 -7 64 -17 61 120 40 54 -8 157 -15 228 -15 74 0 126 -4 120 -9 -17 -15 -118 -30 -139 -19 -14 6 -12 2 7 -15 l26 -25 -167 -4 c-130 -3 -173 -7 -186
                 -18 -14 -11 -52 -16 -159 -17 l-141 -2 55 15 c96 27 101 33 28 33 -69 1 -123 -12 -114 -28 8 -13 -18 -21 -88 -28 -170 -16 -10 -33 322 -33 l196 0 -22 21
                 c-27 25 -21 39 18 39 35 0 45 -13 29 -41 l-11 -22 161 6 c143 4 163 7 187 26 18 14 41 21 70 21 85 0 43 17 -58 25 l-103 7 55 29 c65 33 91 36 107 9 7 -10
                 30 -24 51 -31 33 -9 45 -9 74 5 19 9 50 17 69 16 19 0 53 3 76 6 39 6 42 8 37 29 -4 18 0 27 20 40 22 15 42 16 128 11 186 -12 237 -18 254 -31 17 -12 15
                 -15 -17 -48 -18 -20 -49 -54 -68 -76 l-33 -41 165 0 c190 0 188 -1 195 90 l4 58 44 0 c50 1 51 -11 2 -24 -32 -8 -32 -8 17 -12 37 -2 52 1 61 13 10 14 12
                 14 17 0 5 -11 12 -13 30 -6 18 7 32 5 56 -10 41 -25 78 -24 93 1 9 13 24 20 45 20 40 0 50 -14 22 -33 -52 -37 16 -90 117 -91 60 -1 64 1 97 36 26 28 43
                 38 66 38 19 0 31 -5 31 -13 0 -46 255 -76 494 -58 140 11 165 21 127 52 -18 14 -22 25 -17 44 11 42 42 49 131 31 141 -29 310 -13 361 34 24 24 40 25 48 5
                 8 -22 43 -18 56 5 13 24 5 41 -15 34 -20 -8 -35 35 -35 102 0 50 10 68 25 44 16 -26 25 -4 24 59 -2 77 3 91 32 91 25 0 24 16 -5 50 -40 49 -66 278 -66 596
                 0 235 14 365 46 429 l25 51 -30 111 c-26 93 -51 170 -86 254 -4 10 0 19 9 22 25 10 44 -2 57 -36 11 -29 13 -30 16 -10 2 12 -5 43 -16 69 -12 26 -21 48 -21
                 50 0 2 14 1 31 -3 30 -6 31 -5 27 28 -2 28 0 34 17 34 17 0 21 -8 25 -53 l5 -53 33 34 c25 26 32 40 27 56 -10 31 4 26 65 -25 60 -50 122 -83 158 -84 28 0
                 28 -8 -2 -39 -48 -51 -69 -125 -71 -251 0 -72 -6 -124 -14 -138 -7 -14 -18 -104 -25 -225 -9 -149 -17 -210 -29 -234 -32 -61 -16 -186 25 -196 20 -6 21
                 -10 14 -104 -7 -96 -21 -133 -45 -119 -6 4 -11 14 -11 22 0 26 -18 14 -33 -24 -11 -25 -16 -30 -16 -17 -1 32 -30 65 -57 65 l-25 0 22 -29 c23 -31 23 -54 2
                 -185 l-6 -40 35 40 c19 21 44 57 55 79 l21 39 7 -64 c7 -58 12 -68 57 -116 56 -60 53 -64 -46 -64 -49 0 -69 -5 -90 -21 -26 -21 -34 -53 -16 -64 6 -3 10 1
                 10 10 0 21 41 35 98 34 l47 -1 -50 -16 c-41 -14 -51 -22 -58 -49 -14 -47 -2 -85 26 -81 32 4 28 -24 -5 -40 -34 -16 -36 -30 -4 -38 42 -10 70 0 84 29 19
                 39 56 34 60 -8 5 -39 19 -55 48 -55 32 0 30 13 -6 43 -43 37 -37 42 12 10 36 -24 62 -31 187 -46 80 -10 205 -20 276 -22 72 -3 154 -8 184 -12 41 -5 52 -3
                 47 6 -4 6 -16 9 -26 6 -10 -3 -21 -2 -24 3 -9 14 172 36 309 36 101 1 143 -3 187 -18 50 -16 67 -17 135 -7 43 6 184 12 313 13 287 3 286 4 276 -84 l-6 -60
                 53 4 c64 5 113 28 109 50 -1 10 4 33 12 52 16 38 56 57 79 38 7 -6 28 -7 52 -3 22 4 96 4 165 0 104 -6 653 -4 1105 3 103 2 331 -15 341 -25 3 -2 -13 -7
                 -34 -11 -44 -6 -92 -40 -92 -62 0 -10 8 -5 24 12 l23 28 32 -27 c38 -32 40 -32 69 -11 27 18 30 61 5 67 -11 3 -7 5 10 6 l27 1 0 1010 0 1011 -21 -11
                 c-11 -7 -23 -9 -26 -6 -3 3 -8 36 -11 75 l-5 69 32 7 31 7 0 926 0 927 -229 -4 c-238 -3 -547 11 -660 30 -47 8 -73 19 -88 35 -15 17 -33 24 -58 24 -33 0
                 -35 -1 -21 -16z m-919 -273 c11 -2 23 -8 27 -14 4 -6 30 -10 58 -10 l52 1 -32 -19 c-44 -27 -91 -130 -98 -214 -5 -62 -4 -65 27 -94 23 -21 31 -38 31 -63 0
                 -35 23 -78 35 -66 4 3 15 61 25 128 22 141 36 190 55 190 25 0 27 -21 4 -46 -19 -20 -20 -24 -5 -24 38 0 49 -113 16 -168 -11 -18 -20 -47 -20 -65 0 -20
                 -8 -39 -20 -50 -13 -11 -20 -29 -20 -54 l0 -38 -19 32 c-35 56 -88 56 -76 -1 3 -17 10 -60 15 -95 5 -35 14 -68 20 -75 17 -17 40 20 41 68 l0 41 18 -40 c36
                 -80 36 -84 -1 -100 -19 -7 -46 -17 -61 -20 -24 -6 -27 -4 -27 19 0 16 -6 26 -15 26 -19 0 -29 41 -22 88 8 47 -5 71 -27 53 -23 -20 -21 -108 3 -145 20 -29
                 20 -30 -6 -95 -28 -73 -46 -251 -25 -251 14 0 32 32 33 62 1 13 5 -10 9 -50 9 -81 18 -97 60 -105 18 -4 35 -17 44 -34 l15 -28 1 31 c0 52 12 94 25 94 24 0
                 46 -81 51 -189 5 -95 4 -104 -10 -88 -35 44 -84 -34 -60 -97 7 -18 39 -22 49 -6 12 19 35 10 35 -13 0 -32 -45 -98 -87 -127 -78 -53 -215 -29 -250 46 -15
                 31 -15 37 6 101 28 84 39 197 21 208 -8 5 -16 1 -22 -9 -5 -9 -14 -16 -19 -16 -15 0 -1 41 22 65 18 19 18 20 -3 42 -21 22 -21 26 -10 131 17 151 18 238 8
                 565 l-8 288 30 32 c16 18 35 42 41 54 14 28 14 70 0 79 -16 9 -4 113 18 161 l17 38 6 -65 c4 -55 8 -65 25 -69z m195 100 c0 -10 -8 -29 -18 -41 -16 -19
                 -22 -21 -40 -11 -42 22 -21 71 30 71 21 0 28 -5 28 -19z m-4675 -103 l11 -73 16 35 c21 47 81 114 97 109 8 -3 2 -18 -18 -46 -38 -53 -50 -104 -51 -213 0
                 -89 12 -149 32 -161 8 -5 2 -18 -20 -44 -29 -33 -32 -41 -32 -105 0 -43 -4 -70 -12 -72 -8 -3 -6 -14 8 -36 14 -25 16 -36 8 -50 -23 -35 -39 -19 -46 46
                 -4 35 -14 75 -23 88 -19 30 -19 33 4 27 30 -8 33 11 11 70 l-21 55 21 53 c24 64 25 96 5 136 -26 52 -33 284 -8 259 4 -3 11 -39 18 -78z m4666 -908 c12 -79
                 8 -155 -9 -172 -19 -19 -32 37 -32 137 0 149 20 166 41 35z m-4623 -93 c17 -20 25 -110 17 -192 l-7 -77 -24 53 c-18 38 -21 54 -13 60 8 5 3 18 -19 45
                 -34 40 -33 54 1 54 20 0 20 2 -8 25 -25 21 -28 26 -15 35 21 14 56 12 68 -3z m798 -611 c18 -14 18 -15 -9 -25 -37 -14 -167 -14 -167 -1 0 33 139 53 176 26z
                 m798 -49 c29 -38 33 -79 11 -117 l-14 -25 -1 35 c-1 59 -9 64 -82 51 -77 -13 -218 -15 -218 -2 0 5 15 14 33 21 31 12 225 68 239 69 4 1 18 -14 32 -32z
                 m2383 14 c-2 -10 0 -25 5 -34 12 -22 -5 -25 -177 -31 -124 -5 -246 -23 -263 -41 -4 -3 -11 -20 -17 -37 -23 -70 -55 -30 -55 68 0 30 1 54 3 54 1 1 101 5
                 222 10 121 5 234 13 250 19 17 5 32 10 34 10 2 1 1 -7 -2 -18z m1031 -13 c-19 -6 -37 -6 -55 0 -25 9 -21 10 27 10 48 0 52 -1 28 -10z m-4188 -88 c56 -25 65
                 -38 36 -52 -9 -5 -24 -12 -33 -17 -10 -4 -18 -17 -18 -27 0 -29 -12 -41 -49 -48 -47 -9 -124 10 -163 40 l-32 25 29 48 c16 26 38 55 49 65 27 25 55 17 59
                 -17 6 -52 72 -23 72 31 0 26 0 26 18 -7 9 -19 24 -38 32 -41z m-2186 7 c18 -9 18 -11 3 -22 -25 -18 -21 -25 13 -25 37 0 38 -6 5 -32 -25 -20 -70 -96 -62
                 -104 2 -3 16 0 31 6 34 13 75 -5 57 -23 -23 -23 -1194 -8 -1217 16 -8 8 12 59 42 102 l22 33 -24 5 c-40 7 -207 -13 -230 -28 -12 -7 -27 -34 -34 -59 l-13
                 -46 -126 1 -126 2 87 8 c48 4 96 10 107 14 39 12 21 25 -36 26 -32 0 -66 4 -76 8 -17 6 -17 8 -3 20 22 19 20 38 -6 44 -30 8 -58 -10 -58 -37 0 -26 -17
                 -36 -61 -36 -17 0 -40 -5 -51 -11 -17 -9 -18 -13 -7 -21 10 -6 -2 -8 -36 -4 -27 4 -53 13 -57 20 -5 8 -31 22 -59 31 l-51 17 44 44 43 44 495 -2 495 -3
                 -35 -23 -35 -22 50 2 c49 3 50 4 49 34 l-2 32 36 -24 c40 -27 92 -32 92 -9 0 19 9 19 34 0 28 -21 104 -19 300 10 237 34 351 38 400 12z m681 -2 c198 -10
                 427 -46 457 -74 11 -10 -232 -1 -287 11 -91 19 -189 21 -256 3 -35 -8 -65 -18 -68 -21 -13 -13 29 -24 84 -22 46 2 60 7 60 18 0 8 9 15 20 15 14 0 26 -13 40
                 -44 l20 -43 179 7 c120 5 176 4 172 -3 -13 -22 -155 -36 -351 -37 -177 0 -204 2 -228 18 -33 21 -43 17 -52 -23 -8 -38 -15 -37 -40 3 -19 30 -24 32 -68 29
                 -49 -4 -58 5 -31 32 10 10 33 16 65 16 98 0 115 36 47 95 l-43 36 80 -5 c44 -3 134 -8 200 -11z m-385 -30 c10 -12 8 -16 -12 -24 -43 -16 -127 11 -96 30
                 22 14 95 10 108 -6z m5010 5 c12 -8 11 -14 -9 -40 -12 -17 -21 -33 -19 -35 1 -2 30 8 62 21 33 13 68 24 78 24 16 0 17 -3 8 -21 -10 -17 -8 -21 10 -26 15
                 -4 20 -11 17 -25 -5 -16 -1 -18 27 -12 25 4 35 2 39 -10 13 -34 -27 -38 -170 -20 -110 14 -139 15 -155 5 -24 -15 -202 -17 -240 -2 -71 27 -33 49 109 61
                 137 12 169 23 182 61 10 30 33 37 61 19z m-2892 -16 c12 -8 22 -19 22 -24 0 -13 -50 -69 -72 -81 -20 -11 -20 -10 -4 12 24 35 20 40 -23 33 -25 -4 -41 -2
                 -46 6 -9 15 12 30 43 30 15 0 26 7 29 20 6 24 21 25 51 4z m3552 -3 c0 -9 -14 -13 -92 -26 -47 -7 -59 11 -16 24 43 12 108 13 108 2z m-3065 -21 c50 -5 147
                 -13 218 -16 96 -5 127 -9 127 -19 0 -8 -6 -17 -12 -22 -7 -4 -116 -8 -243 -10 -248 -2 -300 5 -300 42 0 17 6 19 33 17 23 -2 34 2 38 13 5 11 13 15 28 10 11
                 -3 62 -10 111 -15z m-4096 -83 c1 -36 0 -37 -26 -31 -16 4 -30 11 -32 16 -2 5 -14 14 -28 19 -37 14 -27 45 14 42 18 -2 36 4 44 13 12 14 14 14 20 -3 4 -10
                 8 -35 8 -56z m-868 47 c-27 -12 -32 -19 -28 -39 3 -20 0 -25 -18 -25 -16 0 -21 6 -21 22 1 19 -8 26 -49 40 l-50 16 100 0 c96 0 99 0 66 -14z m6298 -39
                 c20 3 44 8 54 11 9 3 17 1 17 -5 0 -17 -99 -41 -169 -41 l-66 1 33 29 c32 30 33 30 63 14 21 -11 43 -14 68 -9z m2081 16 c0 -5 -9 -13 -20 -16 -64 -20 34
                 -40 148 -30 43 4 96 16 119 27 45 21 63 18 63 -11 0 -26 -28 -30 -211 -38 -217 -8 -285 5 -235 46 24 20 136 38 136 22z m750 -20 c0 -12 -96 -44 -113
                 -38 -14 5 69 46 96 47 9 0 17 -4 17 -9z m-1312 -20 c17 -10 -77 -24 -102 -16 -9 3 -23 7 -32 10 -8 3 -12 10 -8 16 7 12 117 4 142 -10z m-5570 -8 c-4 -21
                 -49 -39 -73 -29 -17 6 -17 7 0 26 23 26 78 28 73 3z m5797 7 c-3 -5 -29 -10 -56 -10 -31 0 -48 4 -44 10 3 6 29 10 56 10 31 0 48 -4 44 -10z m-1132 -17
                 c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m-3292 -1843 c44 -24 3 -58 -47 -40 -26 10 -32 35 -11 43 20 9 37 8 58 -3z m868 -22 c-18 -35 -59
                 -54 -59 -27 0 6 6 9 13 6 6 -2 20 10 30 26 21 34 35 30 16 -5z m393 15 c8 -10 18 -26 21 -36 6 -16 0 -18 -51 -17 -31 1 -129 -1 -217 -4 -117 -3 -145 -2
                 -105 5 30 5 69 6 87 4 20 -4 45 1 65 11 42 22 137 53 164 53 11 1 28 -7 36 -16z m-1872 -13 c-39 -19 -49 -20 -92 -9 -26 7 -48 16 -48 19 0 3 42 7 93 8
                 l92 2 -45 -20z m-2050 0 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15 0 15 -2 2 -10z m3040 0 c0 -5 -10 -10 -22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z m310
                 5 c0 -9 -66 -39 -102 -45 -62 -12 -49 4 32 38 26 11 70 16 70 7z m237 -21 c-7 -7 -37 7 -37 17 0 6 9 5 21 -1 11 -7 19 -14 16 -16z m-3707 -18 c0 -20 -42
                 -28 -81 -14 -38 13 -25 21 41 26 29 2 40 -1 40 -12z m-960 -4 c0 -17 -39 -44 -55 -39 -15 7 -20 28 -8 40 9 10 63 9 63 -1z`,
            `M8368 2683 c-1 -5 -2 -33 -2 -63 -1 -31 -9 -70 -19 -89 -23 -46 -22 -49 9 -20 48 43 71 138 42 167 -14 14 -27 16 -30 5z`,
            `M683 2164 c-16 -7 -17 -40 -3 -49 15 -9 62 23 57 38 -4 13 -35 19 -54 11z`,
            `M1863 2073 c-40 -5 -59 -26 -39 -42 11 -10 23 -8 55 8 22 12 42 25 46 31 6 10 -2 10 -62 3z`,
            `M1592 2064 c15 -14 153 -43 163 -33 20 21 -26 39 -99 39 -38 0 -67 -3 -64 -6z`,
            `M3670 140 c-8 -5 -24 -12 -35 -16 -17 -7 -15 -9 12 -12 19 -2 39 -12 47 -24 11 -16 29 -22 68 -25 59 -5 130 7 135 23 7 20 -87 41 -192 44 -23 0 -27 3 -15 10 19 12 1 12 -20 0z`,
            `M3383 79 c-36 -10 -45 -29 -14 -29 13 0 21 -8 23 -22 2 -19 8 -22 33 -20 17 2 38 10 47 19 9 8 36 17 60 20 57 8 86 22 67 34 -18 11 -175 10 -216 -2z`,
            `M4385 72 c-38 -13 -50 -22 -50 -37 0 -16 8 -21 35 -23 40 -3 80 26 80 58 0 23 0 23 -65 2z`,
            `M1563 73 c-20 -7 -16 -43 5 -44 51 -4 76 15 48 37 -20 14 -32 16 -53 7z`,
            `M905 50 c-10 -17 10 -27 67 -35 52 -7 145 2 153 15 7 12 -31 20 -126 27 -64 4 -88 3 -94 -7z`];
    }
}
