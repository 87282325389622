import * as _ from 'underscore';
import {DrawingData} from "../drawing-data/drawing-data";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {ConfigAddonOpening} from "../entities/ConfigAddonOpening";
import {ConfigAddonOpeningType} from "../enums/ConfigAddonOpeningType";
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {SubwindowPolygons} from "../subwindow-polygons";
import {WindowCalculator} from "../window-calculator";

export class ConfigurableAddonOpeningsUtils {

    public static prepareSubwindowsPolygonsMap(data: DrawingData, totalBoundingBox: MinMaxXY,
                                               profileCompositionDistances: ProfilesCompositionDistances,
                                               skipValidation: boolean) {
        let map = [];
        let allSubwindows = _.chain(data.windows).map(window => window.subWindows).flatten().value();
        allSubwindows.forEach(subwindow => map[subwindow.generatedId] =
            WindowCalculator.getSubwindowPolygons(subwindow, data.cuts, totalBoundingBox, profileCompositionDistances, skipValidation));
        return map;
    }

    public static getSubwindowsXPoints(subwindow: SubWindowData): number[] {
        return this.getSubwindowsAxisPoints(subwindow, 0);
    }

    public static getSubwindowsYPoints(subwindow: SubWindowData): number[] {
        return this.getSubwindowsAxisPoints(subwindow, 1);
    }

    private static getSubwindowsAxisPoints(subwindow: SubWindowData, axisIndex: number): number[] {
        return subwindow.points.filter((value, index) => index % 2 === axisIndex);
    }

    public static mapOpening(type: ConfigAddonOpeningType, polygon: number[]): ConfigAddonOpening {
        if (polygon == undefined) {
            return undefined;
        }
        if (polygon.length === 0) {
            return new ConfigAddonOpening(type, undefined, undefined, false);
        }
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(polygon);
        let isRectangular = DrawingUtil.isPolygonRectangular(polygon);
        return new ConfigAddonOpening(type, box.maxX - box.minX, box.maxY - box.minY, isRectangular);
    }

    public static joinSubwindowPolygonPoints(topSubwindows: SubWindowData[], bottomSubwindows: SubWindowData[],
                                             leftSubwindows: SubWindowData[], rightSubwindows: SubWindowData[],
                                             subwindowsPolygonsMap: SubwindowPolygons[],
                                             type: ConfigAddonOpeningType): number[] {
        let undefinedPolygonPresent = false;

        let mapSubwindows = (subwindows: SubWindowData[], attribute: string) => subwindows.map(
            subwindow => {
                let polygon = this.getPolygonByOpeningType(subwindowsPolygonsMap[subwindow.generatedId], type);
                if (polygon == undefined) {
                    undefinedPolygonPresent = true;
                    return [];
                }
                return DrawingUtil.calculatePolygonTotalBoundingBox(polygon)[attribute];
            });

        let minX = this.getCommonElementOrUndefined(mapSubwindows(topSubwindows, 'minX'));
        let maxX = this.getCommonElementOrUndefined(mapSubwindows(bottomSubwindows, 'maxX'));
        let minY = this.getCommonElementOrUndefined(mapSubwindows(leftSubwindows, 'minY'));
        let maxY = this.getCommonElementOrUndefined(mapSubwindows(rightSubwindows, 'maxY'));

        if (undefinedPolygonPresent) {
            return undefined;
        }
        if (!this.isValidPosition(minX) ||
            !this.isValidPosition(maxX) ||
            !this.isValidPosition(minY) ||
            !this.isValidPosition(maxY)) {
            return [];
        }
        return [minX, minY, maxX, minY, maxX, maxY, minX, maxY];
    }

    private static isValidPosition(position: number): boolean {
        return position != undefined && position !== Infinity && position !== -Infinity;
    }

    private static getPolygonByOpeningType(subwindowPolygons: SubwindowPolygons,
                                           type: ConfigAddonOpeningType): number[] {
        let points;
        switch (type) {
            case ConfigAddonOpeningType.FRAME:
                points = subwindowPolygons.framePoints;
                break;
            case ConfigAddonOpeningType.FRAME_OPENING:
                points = subwindowPolygons.frameOpeningPoints;
                break;
            case ConfigAddonOpeningType.WING:
                points = subwindowPolygons.wingPoints;
                break;
            case ConfigAddonOpeningType.PLUGIN:
                points = subwindowPolygons.pluginPoints;
                break;
            case ConfigAddonOpeningType.GLASS:
                points = subwindowPolygons.glassPoints;
                break;
            default:
                throw new Error("Unsupported opening type: " + ConfigAddonOpeningType[type]);
        }
        return points;
    }

    public static getCommonElementOrUndefined(elements: number[]): number {
        if (elements.length === 0) {
            return undefined;
        }
        return elements.reduce((a, b) => a === b ? a : undefined);
    }
}
