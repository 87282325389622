import {MultilanguageField} from "../../../supportedLanguages";

export class Comment {
    id: number;
    comment: string;
    author: string;
    creationDate: Date;
    offerId: number;
    offerPositionId: number;
    commentType = CommentType.USER;
    complaintId: number;
    complaintPositionId: number;
    unreadByVenska: boolean;
    unreadBySubsystem: boolean;
}

export class PositionComment {
    id: number;
    name: MultilanguageField;
    printOrder: number;
    comments: Comment[] = [];
}

export enum CommentType {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}
