<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.GENERAL' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [paginator]="true" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedItem" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder" dataKey="id" [value]="itemList">
        <p-headerColumnGroup>
            <p-row>
                <p-column header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.DOCUMENT_TYPE' | translate }}" [rowspan]="2" field="documentType"
                          [sortable]="true" [filter]="showFilters" [filterValues]="documentTypeFilter | async"></p-column>
                <p-column header="{{'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column header="{{'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.LANGUAGES'|translate}}" [colspan]="supportedLanguages.length"></p-column>
                <p-column header="{{'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.STATUS'|translate}}" [rowspan]="2" field="active"
                          [sortable]="false" [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngFor="let lang of supportedLanguages" header="{{ lang.code | uppercase }}"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <p-column field="documentType">
            <ng-template pTemplate="body" let-template="rowData">
                {{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.DOCUMENT_TYPES.' + template.documentType | translate }}
            </ng-template>
        </p-column>
        <p-column field="name">
            <ng-template let-template="rowData" pTemplate="body">
                {{template.name}}
            </ng-template>
        </p-column>
        <p-column *ngFor="let lang of supportedLanguages" [field]="lang.code">
            <ng-template let-template="rowData" pTemplate="body">
                <app-checkbox [ngModel]="contains(template, lang)" disabled></app-checkbox>
            </ng-template>
        </p-column>
        <p-column field="active" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-template="rowData" pTemplate="body">
                {{('SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.' + (template.active ? 'ACTIVE' : 'INACTIVE')) | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>
                {{ 'GENERAL.TOTAL_RECORDS' | translate: {from: fromRecord, to: toRecord, total: totalRecords} }}
            </span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.STEPS.DATA' | translate }}" [validate]="validateDataStep">
        <app-document-template-form [item]="item" [documentTypeOptions]="documentTypeOptions | async"
                                    [documentTemplateTypeOptions]="documentTemplateTypeOptions | async"
                                    [languageOptions]="languageOptions | async"
                                    [(file)]="file"
                                    [validationErrors]="validationErrors"></app-document-template-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [validate]="validateDataStep">
        <app-document-template-form [item]="item" [documentTypeOptions]="documentTypeOptions | async"
                                    [documentTemplateTypeOptions]="documentTemplateTypeOptions | async"
                                    [languageOptions]="languageOptions | async"
                                    [(file)]="file"
                                    [validationErrors]="validationErrors"></app-document-template-form>
    </app-wizard-step>
</app-wizard-dialog>
