import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DocumentType} from './DocumentType';
import {PrintOptions} from './print-options';
import {PrintSubsystemConfig} from "./print-subsystem-config";

@Injectable()
export class PrintService {

    static readonly SERVICE_PATH = 'print';
    static readonly RETAIL_OFFER_SUFFIX = 'retailOffer';
    static readonly SALES_OFFER_SUFFIX = 'salesOffer';
    static readonly OFFER_GLAMOUR_SUFFIX = 'offerGlamour';
    static readonly OFFER_ORDER_SUFFIX = 'offer';
    static readonly ORDER_CONFIRMATION_SUFFIX = 'orderConfirmation';
    static readonly PROD_ORDER_SUFFIX = 'productionOrder';
    static readonly TECHNICAL_DRAWING = 'technicalDrawing';
    static readonly DELIVERY_LIST_SUFFIX = 'deliveryList';
    static readonly CHECKLIST_SUFFIX = 'checklist';
    static readonly COMPLAINT_SUFFIX = 'complaint';
    static readonly CONJUNCTION_SUFFIX = 'conjunction';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    private static getCommonFormData(printOptions: PrintOptions): FormData {
        let formData = new FormData();
        formData.append('printOptions', new Blob([JSON.stringify(printOptions)], {type: 'application/json'}));
        return formData;
    }

    private static getUrl(id: number, pathSuffix: string): string {
        return `${PrintService.SERVICE_PATH}/${pathSuffix}/${id}`;
    }

    public printOffer(offerId: number, printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);

        let pathSuffix;
        if (printOptions.documentType === DocumentType.RETAIL_OFFER) {
            pathSuffix = PrintService.RETAIL_OFFER_SUFFIX;
        } else if (printOptions.documentType === DocumentType.OFFER) {
            pathSuffix = PrintService.SALES_OFFER_SUFFIX;
        } else if (printOptions.documentType === DocumentType.GLAMOUR_OFFER) {
            pathSuffix = PrintService.OFFER_GLAMOUR_SUFFIX;
        } else if (printOptions.documentType === DocumentType.ORDER_CONFIRMATION) {
            pathSuffix = PrintService.ORDER_CONFIRMATION_SUFFIX;
        } else {
            pathSuffix = PrintService.OFFER_ORDER_SUFFIX;
        }

        let url = PrintService.getUrl(offerId, pathSuffix);
        return this.executePrintRequest(url, formData);
    }

    public printProductionOrder(productionOrderId: number, printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        let url = PrintService.getUrl(productionOrderId, PrintService.PROD_ORDER_SUFFIX);
        return this.executePrintRequest(url, formData);
    }

    public printTechnicalDrawing(productionOrderId: number, positionIds: number[], printOptions: PrintOptions,
                                 isProductionOrder: boolean): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        if (positionIds != null) {
            formData.append('positionIds', new Blob([JSON.stringify(positionIds)], {type: 'application/json'}));
        }
        formData.append('isProductionOrder', "" + isProductionOrder);
        let url = PrintService.getUrl(productionOrderId, PrintService.TECHNICAL_DRAWING);
        return this.executePrintRequest(url, formData);
    }

    public printDeliveryList(deliveryListId: number, printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        let url = PrintService.getUrl(deliveryListId, PrintService.DELIVERY_LIST_SUFFIX);
        return this.executePrintRequest(url, formData);
    }

    public printChecklist(deliveryListId: number, prodOrdersIds: number[], printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        if (prodOrdersIds != null && prodOrdersIds.length !== 0) {
            formData.append('prodOrdersIds', new Blob([JSON.stringify(prodOrdersIds)], {type: 'application/json'}));
        }
        let url = PrintService.getUrl(deliveryListId, PrintService.CHECKLIST_SUFFIX);
        return this.executePrintRequest(url, formData);
    }

    public printComplaint(complaintId: number, positionIds: number[], printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        if (positionIds != null) {
            formData.append('positionIds', new Blob([JSON.stringify(positionIds)], {type: 'application/json'}));
        }
        let url = PrintService.getUrl(complaintId, PrintService.COMPLAINT_SUFFIX);
        return this.executePrintRequest(url, formData);
    }

    public printConjunction(conjunctionId: number, printOptions: PrintOptions): Observable<File> {
        const formData: FormData = PrintService.getCommonFormData(printOptions);
        let url = PrintService.getUrl(conjunctionId, PrintService.CONJUNCTION_SUFFIX);
        return this.executePrintRequest(url, formData);
    }

    private executePrintRequest(url: string, formData: FormData) {
        return this.http.post(url, formData, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    public getPrintDialogConfig(): Observable<PrintSubsystemConfig> {
        return this.http.get<PrintSubsystemConfig>(`${PrintService.SERVICE_PATH}/getPrintDialogConfig`);
    }
}
