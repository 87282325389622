import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {configurableAddonDefinitionTypeToTranslationKey} from '../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataTableColumn} from '../../../common/service/data.table.column';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {AbstractSellDealerDiscountComponent} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component';
import {SubsystemConfigurableAddonSellDealerDiscount} from './subsystem-configurable-addon-sell-dealer-discount';
import {SubsystemConfigurableAddonSellDealerDiscountService} from './subsystem-configurable-addon-sell-dealer-discount.service';
import {SubsystemGroupConfigurableAddonSellDealerDiscount} from "../../subsystem-group/subsystem-group-configurable-addon-sell-dealer-discount/subsystem-group-configurable-addon-sell-dealer-discount";

@Component({
    selector: 'app-subsystem-configurable-addon-sell-dealer-discount',
    templateUrl: '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.html',
    styleUrls: ['../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.css'],
    providers: [SubsystemConfigurableAddonSellDealerDiscountService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemConfigurableAddonSellDealerDiscountComponent
    extends AbstractSellDealerDiscountComponent<SubsystemConfigurableAddonSellDealerDiscount, SubsystemConfigurableAddonSellDealerDiscountService> {

    constructor(private translate: TranslateService,
                service: SubsystemConfigurableAddonSellDealerDiscountService,
                errorHandler: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(service, errorHandler, changeDetector);
    }

    getExtraColumns(): DataTableColumn[] {
        return DataTableColumnBuilder.create()
            .add('systemName', 'SELL_DEALER_DISCOUNT.FORM.CONFIGURABLE_ADDON_NAME')
            .build();
    }

    formatExtraColumn(column: DataTableColumn, item: SubsystemGroupConfigurableAddonSellDealerDiscount): Observable<any> {
        if (column.field === 'systemName') {
            return of(item.systemName[this.translate.currentLang]);
        }
        return super.formatExtraColumn(column, item);
    }

    isGroupValueVisible(): boolean {
        return true;
    }
}
