<p-dialog [resizable]="false" [visible]="dataInitialized" [modal]="true" (onHide)="close()" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.SHOW_SUMMARY.TITLE' | translate }}
        </div>
    </p-header>

    <div class="scrolled-dialog scrolled-dialog-900">
        <p-dataTable [value]="summary" [seqNum]="99">
            <p-column field="buyValue.netValue" header="{{'OFFER.POSITIONS.FORM.PRICE_BUY_NET' | translate}}">
                <ng-template let-item="rowData" pTemplate="body">
                    <span>{{formatPriceInSelectedCurrency(item.buy.netValue)}}</span>
                    <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">
                            {{formatPriceInOfferCurrency(item.buy.netValue)}}
                        </span>
                </ng-template>
            </p-column>
            <p-column field="buyValue.grossValue"
                      header="{{'OFFER.POSITIONS.FORM.PRICE_BUY_GROSS' | translate}}">
                <ng-template let-item="rowData" pTemplate="body">
                    <span>{{formatPriceInSelectedCurrency(item.buy.grossValue)}}</span>
                    <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">
                            {{formatPriceInOfferCurrency(item.buy.grossValue)}}
                        </span>
                </ng-template>
            </p-column>
            <p-column field="venskaBuyValue.netValue"
                      header="{{'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_NET' | translate}}">
                <ng-template let-item="rowData" pTemplate="body">
                    <span>{{formatPriceInSelectedCurrency(item.venskaBuy.netValue)}}</span>
                    <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">
                            {{formatPriceInOfferCurrency(item.venskaBuy.netValue)}}
                        </span>
                </ng-template>
            </p-column>
            <p-column field="venskaBuyValue.grossValue"
                      header="{{'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_GROSS' | translate}}">
                <ng-template let-item="rowData" pTemplate="body">
                    <span>{{formatPriceInSelectedCurrency(item.venskaBuy.grossValue)}}</span>
                    <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">
                            {{formatPriceInOfferCurrency(item.venskaBuy.grossValue)}}
                        </span>
                </ng-template>
            </p-column>
            <p-column field="netIncome" header="{{'OFFER.POSITIONS.FORM.NET_INCOME' | translate}}">
                <ng-template let-item="rowData" pTemplate="body">
                    <span>{{formatPriceInSelectedCurrency(item.netIncome)}}</span>
                    <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">
                            {{formatPriceInOfferCurrency(item.netIncome)}}
                        </span>
                </ng-template>
            </p-column>
        </p-dataTable>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
