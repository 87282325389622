import {Transport} from "./transport";

export class TransportValidator {

    static validate(assembly: Transport): { [field: string]: string } {
        let validationErrors = {};
        if (!assembly.name) {
            validationErrors['name'] = 'error.assemblyDto.name.not_empty';
        } else if (assembly.name.length > 100) {
            validationErrors['name'] = 'error.assemblyDto.name.not_in_range';
        }
        if (!assembly.assemblyUnit) {
            validationErrors['assemblyUnit'] = 'error.assemblyDto.assemblyUnit.not_empty';
        }
        if (isNaN(parseFloat(<any>assembly.value)) || !isFinite(assembly.value)) {
            validationErrors['value'] = 'error.assemblyDto.value.not_empty';
        } else if (assembly.value < 0) {
            validationErrors['value'] = 'error.assemblyDto.value.below_min';
        }
        return validationErrors;
    }
}
