import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {ConfigTypeDescriptor} from "./config-type-descriptor";
import {ConfigTypeDescriptorService} from "./config-type-descriptor.service";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {Observable, of} from "rxjs";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {finalize, map, tap} from "rxjs/operators";
import {MultilanguageField} from "../../../supportedLanguages";
import {SelectItem} from "primeng/api/selectitem";
import {ConfigDescriptorLevel} from "../../../common/enums/ConfigDescriptorLevel.enum";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";

@Component({
    selector: 'app-config-type-descriptor',
    templateUrl: './config-type-descriptor.component.html',
    providers: [ConfigTypeDescriptorService, TranslatedSelectItemService]
})
export class ConfigTypeDescriptorComponent extends CrudCommonComponent<ConfigTypeDescriptor, ConfigTypeDescriptorService>
    implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    filterLevels: Observable<SelectItem[]>;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private configTypeDescriptorService: ConfigTypeDescriptorService,
    ) {
        super(injector, changeDetector, true, ConfigTypeDescriptorService, 'CONFIG_TYPE_DESCRIPTOR', 'ConfigTypeDescriptor');
        this.validateDataStep = () => this.validateForm();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterLevels = this.translatedSelectItemService.buildSortedDropdown(ConfigDescriptorLevel, 'CONFIG_TYPE_DESCRIPTOR.FORM.DESCRIPTOR_LEVEL.', '');
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): ConfigTypeDescriptor {
        return new ConfigTypeDescriptor();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.prepareDataForDescriptor(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    prepareDataForDescriptor(descriptorId: number) {
        const observable = descriptorId != undefined ? this.configTypeDescriptorService.getItem(descriptorId)
            : of(new ConfigTypeDescriptor());
        observable.subscribe({
            next: data => {
                this.newItem = descriptorId == undefined;
                this.item = data;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.configTypeDescriptorDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.configTypeDescriptorDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] =
            MultiValidator.of('error.configTypeDescriptorDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.configTypeDescriptorDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        this.validationErrors['descriptorLevel'] =
            MultiValidator.of('error.configTypeDescriptorDto.descriptorLevel')
                .withNotNullValidator()
                .validate(this.item.descriptorLevel);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);
                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copy(this.selectedItem.id, this.item);
                } else {
                    observable = this.itemService.saveDescriptor(this.item);
                }

                observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.prepareDataForDescriptor(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.prepareDataForDescriptor(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.reloadDatatable();
    }
}
