import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import * as _ from 'underscore';
import {SubWindowTypeCode} from "../../../../window-designer/window-types/subwindow-type-code";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {SubwindowTypeField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {SubwindowTypeFieldUsage} from "../catalog-field-usage";
import {SubwindowTypeService} from "./subwindow-type.service";
import {SubwindowType} from "./SubwindowType";

@Component({
    selector: 'app-subwindow-type',
    templateUrl: './subwindow-type.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [SubwindowTypeService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubwindowTypeComponent extends CrudCommonComponent<SubwindowType, SubwindowTypeService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA'
    };

    item: SubwindowType;
    validateDataStep: WizardStepValidator;
    filterTypes: Observable<SelectItem[]>;
    @ViewChild('dt', {static: true}) datatable;

    editPermits: FieldLimitation[] = [];
    fieldUsage: SubwindowTypeFieldUsage;
    SubwindowTypeField = SubwindowTypeField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, false, SubwindowTypeService, 'SUBWINDOWTYPE', 'SubwindowType');
        this.item = new SubwindowType();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new SubwindowTypeFieldUsage(this);
    }

    ngOnInit() {
        this.initShownColumns();
        super.onLanguageChange();
        this.filterTypes = this.translatedSelectItemService.buildSortedDropdown(SubWindowTypeCode, '', '');
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.SUBWINDOW_TYPES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    showDialogToAdd() {
        super.showDialogToAdd();
        this.setDisplayDialog(false);
    }

    submit() {
        this.edit();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        super.onRowSelect(event);
        this.setDisplayDialog(false);
        this.item = this.cloneSubwindowType(event.data);
        this.setDisplayDialog(true);
    }

    cloneSubwindowType(item: SubwindowType): SubwindowType {
        let newItem = <SubwindowType>_.clone(item);
        newItem.names = <MultilanguageField>_.clone(item.names);
        newItem.type = <SubWindowTypeCode>_.clone(item.type);
        newItem.functionsQuantity = <number>_.clone(item.functionsQuantity);
        return newItem;
    }

    validateForm(): boolean {
        const validationErrors = {};
        if (!this.item.names[this.userLang]) {
            validationErrors[`name[${this.userLang}]`] = `error.subwindowTypeDto.name[${this.userLang}].not_empty`;
        }
        let qn = this.item.functionsQuantity;
        if (qn == undefined) {
            validationErrors['functionsQuantity'] = 'error.subwindowTypeDto.functionsQuantity.not_empty';
        } else if (0 > qn || qn > 10) {
            validationErrors['functionsQuantity'] = 'error.subwindowTypeDto.functionsQuantity.not_in_range';
        }

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return false;
        }
        return true;
    }

    getNewItem(): SubwindowType {
        return new SubwindowType();
    }

    prepareItemForRequest(): SubwindowType {
        let requestItem: SubwindowType = this.getNewItem();
        requestItem.id = this.item.id;
        requestItem.names = this.item.names;
        requestItem.type = this.item.type;
        requestItem.functionsQuantity = this.item.functionsQuantity;
        return requestItem;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "type";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
