import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of} from 'rxjs';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowDependentOptionField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {WindowEditorFieldContentProvider} from '../../../offer/window-editor/window-editor-field-content-provider';
import {WindowDependentOptionFieldUsage} from "../../catalog-field-usage";
import {WindowDependentOptionHelperInterface} from "../../catalog-field-usage-helper-interfaces";
import {WindowSystemDefinition} from '../../window-system-definition/window-system-definition';
import {WindowDesignerCatalogDependentOptionsSet} from '../window-designer-catalog-dependent-options-set';

@Component({
    selector: 'app-window-designer-catalog-dependent-option-form',
    templateUrl: './window-designer-catalog-dependent-option-form.component.html'
})
export class WindowDesignerCatalogDependentOptionFormComponent implements WindowDependentOptionHelperInterface {

    @Input()
    item: WindowDesignerCatalogDependentOptionsSet;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    windowSystems: Observable<WindowSystemDefinition[]>;

    @Input()
    nextStepsFilled: boolean;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: WindowDependentOptionFieldUsage;
    WindowDependentOptionField = WindowDependentOptionField;

    constructor(private readonly translate: TranslateService,
                private readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider) {
        this.fieldUsage = new WindowDependentOptionFieldUsage(this);
    }

    formatWindowSystemOption = (windowSystem: WindowSystemDefinition): SelectItem => {
        return {
            label: windowSystem.names[this.translate.currentLang],
            value: windowSystem.id
        };
    }

    handleWindowSystemChange(windowSystemId: number): void {
        this.item.windowSystemId = windowSystemId;
        forkJoin({
            doesNotWorkWithoutThisDummy: of(`FIX: error TS2339: Property 'windowSystemModel' does not exist on type 'never'.`),
            ...this.windowEditorFieldContentProvider.getCatalogForWindowSystemDataSources(windowSystemId, undefined, false, null)
        }).subscribe(catalogData => {
            this.windowEditorFieldContentProvider.initCatalog(catalogData);
        });
    }
}
