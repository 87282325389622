<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISCONTINUED_CATALOG_ITEM_INFO.LIST' | translate }}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles: ['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="id"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="catalogItemType" [ngClass]="columnHeaderClasses('catalogItemType')">
                    <span>{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.CATALOG_ITEM_TYPE' | translate }}</span>
                    <p-sortIcon field="catalogItemType"></p-sortIcon>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="activeFilter" [options]="filterCatalogItemTypeItems | async"
                                    [ngModel]="filterCatalogItemTypeState"
                                    (ngModelChange)="handleFilterCatalogItemTypeChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>
                <th [ngClass]="columnHeaderClasses('catalogItemName')">
                    <span>{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.CATALOG_ITEM' | translate }}</span>
                </th>
                <th pSortableColumn="unavailableFrom" [ngClass]="columnHeaderClasses('unavailableFrom')">
                    <span>{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.UNAVAILABLE_FROM' | translate }}</span>
                    <p-sortIcon field="unavailableFrom"></p-sortIcon>
                    <div app-table-date-range-filter="unavailableFrom"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
                <th pSortableColumn="nameModificationDate" [ngClass]="columnHeaderClasses('nameModificationDate')">
                    <span>{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.NAME_MODIFICATION_DATE' | translate }}</span>
                    <p-sortIcon field="nameModificationDate"></p-sortIcon>
                    <div app-table-date-range-filter="nameModificationDate"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: item, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex" [app-table-keyboard-navigation]="item"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: item, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td>{{ ('CATALOG_ITEM_TYPE.' + item.catalogItemType) | translate }}</td>
                <!--
                  | multilanguageTranslate is for catalog items that implement HasName on backend
                  | translate is for configurable addons
                -->
                <td>{{ item.catalogItemName | multilanguageTranslate | translate }}</td>
                <td>{{ item.unavailableFrom | momentCurrentLang | amDateFormat: 'L' }}</td>
                <td>{{ item.nameModificationDate | momentCurrentLang | amDateFormat: 'L' }}</td>
                <td>
                    <app-simple-button icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                       label="{{'DISCONTINUED_CATALOG_ITEM_INFO.FORM.DELETE' | translate }}"
                                       tooltipPosition="left"
                                       (onClick)="deleteDiscontinuedItem(item)"></app-simple-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>

<app-wizard *ngIf="newItem && displayDialog" header="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-discontinued-catalog-item-info-form [isNew]="true" [discontinuedCatalogItemInfo]="item"
                                                 [editable]="item.unavailableFrom == null || item.unavailableFrom > minDate" [minDate]="minDate"
                                                 [validationErrors]="validationErrors"
                                                 [catalogItemTypes]="catalogItemTypeItems | async"
                                                 [catalogItems]="getCatalogItems(item) | async"
                                                 (selectedCatalogItemsChange)="creationSelectedCatalogItems = $event"></app-discontinued-catalog-item-info-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.EDIT' | translate }}" (onCancel)="cancel()"
                   (onComplete)="submit()" contentStyleClass="scrolled-dialog-600">
    <app-wizard-step label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-discontinued-catalog-item-info-form [isNew]="false" [discontinuedCatalogItemInfo]="item"
                                                 [editable]="item.unavailableFrom == null || item.unavailableFrom > minDate" [minDate]="minDate"
                                                 [validationErrors]="validationErrors"
                                                 [catalogItemTypes]="catalogItemTypeItems | async"
                                                 [catalogItems]="getCatalogItems(item) | async"></app-discontinued-catalog-item-info-form>
    </app-wizard-step>
</app-wizard-dialog>
