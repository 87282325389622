import {Component, Input} from '@angular/core';
import {ShippingContainer} from '../ShippingContainer';
import {ShippingItem} from "../ShippingItem";
import {ShippingItemFloorAffiliation} from "../shipping-simulation/ShippingItemFloorAffiliation";

@Component({
    selector: 'app-shipping-container-drawer',
    templateUrl: './shipping-container-drawer.component.html',
    styleUrls: ['../shipping-drawer.css']
})
export class ShippingContainerDrawerComponent {

    @Input()
    container: ShippingContainer;

    @Input()
    floorIndex: number;

    @Input()
    scale: number;

    getSize(attribute: string): number {
        return Math.round(this.container.size[attribute] * this.scale);
    }

    getFloorAffiliation(item: ShippingItem): ShippingItemFloorAffiliation {
        let floorPosition = this.container.floorPositions[this.floorIndex];
        if (floorPosition === item.position.y) {
            return ShippingItemFloorAffiliation.FULL;
        } else if (item.position.y < floorPosition && floorPosition < (item.position.y + item.size.y)) {
            return ShippingItemFloorAffiliation.THROUGH;
        } else {
            return ShippingItemFloorAffiliation.NONE;
        }
    }
}
