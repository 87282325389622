import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {SubsystemNote} from "../subsystem-note";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {map} from "rxjs/operators";
import {Listing} from "../../../common/crud-common/crudItemList";
import {SubsystemNotesCounterDataRow} from "../../admin-panel/subsystem-notes-counter/subsystem-notes-counter-data-row";

@Injectable()
export class SubsystemNotesService implements CrudService<SubsystemNote> {

    private static readonly API_URL = 'subsystemNote';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(note: SubsystemNote): Observable<number> {
        return this.http.post<void>(SubsystemNotesService.API_URL, note, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(SubsystemNotesService.API_URL, response)));
    }

    editItem(noteId: number, note: SubsystemNote): Observable<number> {
        note.date = new Date(note.date);
        return this.http.put<void>(`${SubsystemNotesService.API_URL}/${noteId}`, note)
            .pipe(map(() => noteId));
    }

    getItem(noteId: number): Observable<SubsystemNote> {
        return this.http.get<object>(`${SubsystemNotesService.API_URL}/note/${noteId}`)
            .pipe(map(response => SubsystemNote.fromJSON(response)));
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<SubsystemNote>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(SubsystemNotesService.API_URL, {params: params})
            .pipe(map(response => Listing.fromJSON(SubsystemNote, response)));
    }

    deleteItem(noteId: number) {
        return this.http.delete<void>(`${SubsystemNotesService.API_URL}/${noteId}`);
    }

    getNotesCounter(filters: { [p: string]: FilterMetadata }, sortColumn: string,
                    sortOrder: number): Observable<SubsystemNotesCounterDataRow[]> {
        let params = this.dataServiceHelper.prepareSearchParams(null, null, filters, sortColumn, sortOrder);
        return this.http.get<SubsystemNotesCounterDataRow[]>(`subsystemNotesCounter`, {params: params});
    }
}
