import {CrudItem} from "../../../common/crud-common/crudItem";
import {getTomorrowDateUTC} from '../tomorrow-date-helper';

export class Pricesheet implements CrudItem {

    id: number;
    active: boolean;
    name: string;
    type: string;
    validFrom: Date;
    informUsers: boolean;
    informUsersSupplierIds: number[];
    informUsersSystemIds: number[];
    fileName: string;
    lastModifiedDate: Date;
    lastNotificationDate: Date;

    constructor() {
        this.id = undefined;
        this.name = '';
        this.type = '';
        this.active = true;
        this.informUsers = false;
        this.informUsersSupplierIds = [];
        this.informUsersSystemIds = [];
        this.validFrom = getTomorrowDateUTC();
        this.lastModifiedDate = null;
        this.lastNotificationDate = null;
    }

    public static fromJSON(jsonObject: any): Pricesheet {
        let pricesheet = new Pricesheet();
        pricesheet.id = jsonObject.id;
        pricesheet.active = jsonObject.active;
        pricesheet.name = jsonObject.name;
        pricesheet.type = jsonObject.type;
        pricesheet.validFrom = new Date(jsonObject.validFrom);
        pricesheet.informUsers = jsonObject.informUsers;
        pricesheet.informUsersSupplierIds = jsonObject.informUsersSupplierIds != undefined ? [...jsonObject.informUsersSupplierIds] : [];
        pricesheet.informUsersSystemIds = jsonObject.informUsersSystemIds != undefined ? [...jsonObject.informUsersSystemIds] : [];
        pricesheet.fileName = jsonObject.fileName;
        pricesheet.lastModifiedDate = new Date(jsonObject.lastModifiedDate);
        pricesheet.lastNotificationDate = new Date(jsonObject.lastNotificationDate);

        return pricesheet;
    }
}

export enum PricesheetCategory {
    PRICING = "PRICING",
    VALIDATION = "VALIDATION",
    CONFIG_ADDONS = "CONFIG_ADDONS",
    UW = "UW",
    GATE_SYSTEMS = "GATE_SYSTEMS"
}
