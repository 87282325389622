import {ChangeDetectorRef, Directive, EventEmitter, Injector, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DropDownExtraOptions} from '../../../../../../shared/drop-down-extra-options';
import {DropDownExtraOptionsLabels, DropDownExtraOptionsProvider} from '../../../../../../shared/DropDownExtraOptionsProvider';
import {DrawingData} from '../../../../../../window-designer/drawing-data/drawing-data';
import {FillingType} from '../../../../../../window-designer/drawing-data/FillingType';
import {WindowAddon} from '../../../../../../window-designer/drawing-data/WindowAddon';
import {WindowAddonCalculationMode} from '../../../../../../window-designer/enums/WindowAddonCalculationMode';
import {StaticDataHelper} from '../../../../../../window-designer/static-data-helper';
import {AddonQuantityDataSource} from '../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonQuantityDataSource';
import {WindowAddonMapper} from '../../../../../../window-designer/utils/WindowAddonMapper';
import {WindowCalculator} from '../../../../../../window-designer/window-calculator';
import {BlockUiController} from '../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../../common/CommonErrorHandler';
import {CrudItem} from '../../../../../common/crud-common/crudItem';
import {SelectItemImpl} from '../../../../../common/service/select.item.impl';
import {SelectItemExtended} from '../../../../../form-inputs/inputs/select/select.component';
import {OnceFlag} from '../../../../../shared/once-flag';
import {Addon} from '../../../../window-system/addons/addon';
import {AddonsService} from '../../../../window-system/addons/addons.service';
import {DecorativeFillingsService} from '../../../../window-system/decorative-filling/decorative-filling.service';
import {GlassService} from '../../../../window-system/glass/glass.service';
import {Profile} from '../../../../window-system/profile/profile';
import {Seal} from '../../../../window-system/seal/Seal';
import {PositionType} from '../../../AbstractPosition';
import {BulkChangeFrontendWarning} from './BulkChangeFrontendWarning';
import {OfferPositionModel} from './OfferPositionModel';
import {Position} from './position';

@Directive()
export abstract class CommonBulkChangeComponent {

    protected static readonly LOAD_DATA_ID = 'BulkChangeComponent loadData';

    @Output()
    onSubmit = new EventEmitter<BulkChangeFrontendWarning[]>();

    @Output()
    onClose = new EventEmitter();

    protected translate: TranslateService;
    protected addonsService: AddonsService;
    protected glassService: GlassService;
    protected decorativeFillingService: DecorativeFillingsService;
    protected changeDetector: ChangeDetectorRef;
    protected errors: CommonErrorHandler;
    protected blockUiController: BlockUiController;
    protected dropDownExtraOptionLabelsProvider: DropDownExtraOptionsProvider;

    submitInProgress = false;
    validationErrors: { [field: string]: string } = {};
    dataReady = false;
    readonly windowSystemIds = new Set<number>();
    readonly glassIds = new Set<number>();
    readonly decorativeFillingIds = new Set<number>();

    protected readonly dialogHideHelper = new OnceFlag();
    protected staticData = new StaticDataHelper();
    protected positionsToUpdate: OfferPositionModel[];

    protected constructor(injector: Injector) {
        this.translate = injector.get(TranslateService);
        this.addonsService = injector.get(AddonsService);
        this.glassService = injector.get(GlassService);
        this.decorativeFillingService = injector.get(DecorativeFillingsService);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.errors = injector.get(CommonErrorHandler);
        this.blockUiController = injector.get(BlockUiController);
        this.dropDownExtraOptionLabelsProvider = new DropDownExtraOptionsProvider(this.translate);
    }

    static copyOfferPosition(offerPosition: Position): OfferPositionModel {
        let position = new Position(offerPosition.name, offerPosition.type, null, null, offerPosition.quantity, null, null, null, null,
            null, null, null, null, null, null, offerPosition.data, offerPosition.offerId, null);
        position.id = offerPosition.id;
        position.parentOfferPositionId = offerPosition.parentOfferPositionId;
        position.printOrder = offerPosition.printOrder;
        position.windowSystemId = offerPosition.windowSystemId;
        return new OfferPositionModel(position);
    }

    initStaticData(includeAllSubwindows = true) {
        this.positionsToUpdate = this.getSelectedPositions()
            .filter(position => position.type === PositionType.SYSTEM)
            .map(position => CommonBulkChangeComponent.copyOfferPosition(position))
            .filter(data => includeAllSubwindows || WindowCalculator.hasNonFixedSubwindows(data.mappedData as DrawingData));

        this.collectGlassAndDecorativeFillingIds();

        this.blockUiController.block(CommonBulkChangeComponent.LOAD_DATA_ID);
    }

    protected collectGlassAndDecorativeFillingIds(): void {
        for (let updatedPosition of this.positionsToUpdate) {
            this.windowSystemIds.add(updatedPosition.offerPosition.windowSystemId);
            const drawingData = updatedPosition.mappedData as DrawingData;
            for (let w of drawingData.windows) {
                for (let subWindow of w.subWindows) {
                    for (let areaSpecification of subWindow.areasSpecification) {
                        if (areaSpecification.filling.type === FillingType.GLASS
                            || areaSpecification.filling.type === FillingType.DECORATIVE_FILLING) {
                            const glazing = areaSpecification.glazing;
                            if (glazing.glass1id != undefined) {
                                this.glassIds.add(glazing.glass1id);
                            }
                            if (glazing.glass2id != undefined) {
                                this.glassIds.add(glazing.glass2id);
                            }
                            if (glazing.glass3id != undefined) {
                                this.glassIds.add(glazing.glass3id);
                            }
                            if (glazing.glass4id != undefined) {
                                this.glassIds.add(glazing.glass4id);
                            }
                        }
                        if (areaSpecification.filling.type === FillingType.DECORATIVE_FILLING) {
                            this.decorativeFillingIds.add(areaSpecification.filling.decorativeFillingId);
                        }
                    }
                }
            }
        }
    }

    protected abstract getSelectedPositions(): Position[];
    abstract submitDialog(): void;

    protected submitValueToMappedData<T>(oldValue: T, newValue: T | DropDownExtraOptions): T;
    protected submitValueToMappedData(oldValue: number, newValue: CrudItem | number | DropDownExtraOptions): number;
    protected submitValueToMappedData(oldValue: any, newValue: any): any {
        if (newValue === DropDownExtraOptions.DO_NOT_CHANGE) {
            return oldValue;
        } else if (newValue === DropDownExtraOptions.CLEAN_OLD_VALUE) {
            return undefined;
        } else if (newValue.id) {
            return newValue.id;
        }
        return newValue;
    }

    protected selectAddonValue(addon: WindowAddon, selectedValue: Addon | DropDownExtraOptions, dataSource: AddonQuantityDataSource): WindowAddon {
        if (selectedValue === DropDownExtraOptions.CLEAN_OLD_VALUE) {
            return new WindowAddon();
        }
        if (selectedValue !== DropDownExtraOptions.DO_NOT_CHANGE && selectedValue !== DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE) {
            if (addon == undefined) {
                addon = new WindowAddon();
            }
            WindowAddonMapper.mapToWindowAddon(addon, selectedValue, WindowAddonCalculationMode.GLOBAL, dataSource);
        }
        return addon;
    }

    protected buildAddonSelectItems(addons: Addon[], extraOptionLabel?: DropDownExtraOptionsLabels,
                                    boldAddons?: Addon[]): SelectItemExtended[] {
        const selectItems: SelectItemExtended[] = (addons || []).map(addon => {
            return {
                label: addon.name[this.translate.currentLang],
                value: addon,
                bold: boldAddons != undefined && boldAddons.findIndex(ba => ba.id === addon.id) >= 0
            };
        });
        selectItems.sort((a, b) => (a.bold ? 0 : 1) - (b.bold ? 0 : 1));
        return this.buildSelectItems(selectItems, extraOptionLabel, true);
    }

    protected buildProfileSelectItems(profiles: Profile[], extraOptionLabel?: DropDownExtraOptionsLabels,
                                      boldProfiles?: Profile[]): SelectItemExtended[] {
        const selectItems: SelectItemExtended[] = (profiles || []).map(profile => {
            return {
                label: profile.names[this.translate.currentLang],
                value: profile,
                bold: boldProfiles != undefined && boldProfiles.findIndex(bp => bp.id === profile.id) >= 0
            };
        });
        selectItems.sort((a, b) => (a.bold ? 0 : 1) - (b.bold ? 0 : 1));
        return this.buildSelectItems(selectItems, extraOptionLabel, true);
    }

    protected buildSealSelectItems(seals: Seal[], extraOptionLabel?: DropDownExtraOptionsLabels,
                                   boldSeals?: Seal[]): SelectItemExtended[] {
        const selectItems: SelectItemExtended[] = (seals || []).map(seal => {
            return {
                label: seal.names[this.translate.currentLang],
                value: seal,
                bold: boldSeals != undefined && boldSeals.findIndex(bp => bp.id === seal.id) >= 0
            };
        });
        selectItems.sort((a, b) => (a.bold ? 0 : 1) - (b.bold ? 0 : 1));
        return this.buildSelectItems(selectItems, extraOptionLabel, true);
    }

    protected buildEnumSelectItems<T extends string, TEnumValue extends number | string>(prefix: string,
                                                                                         values: { [key in T]: TEnumValue },
                                                                                         extraOptionLabel?: DropDownExtraOptionsLabels): SelectItemExtended[] {
        const selectItems = (Object.keys(values) || []).map(key => {
            return {label: prefix + key, value: values[key]};
        });
        return this.buildSelectItems(selectItems, extraOptionLabel, false);
    }

    private buildSelectItems(selectItems: SelectItemExtended[], extraOptionLabel: DropDownExtraOptionsLabels,
                             splitByBold: boolean): SelectItemExtended[] {
        if (selectItems.length > 0) {
            if (extraOptionLabel != null) {
                selectItems.unshift({
                    label: this.dropDownExtraOptionLabelsProvider.getLabel(extraOptionLabel),
                    value: DropDownExtraOptions.CLEAN_OLD_VALUE,
                    bold: splitByBold
                });
            }
            selectItems.unshift(new SelectItemImpl(this.dropDownExtraOptionLabelsProvider.getDoNotChangeLabel(),
                DropDownExtraOptions.DO_NOT_CHANGE));
        }
        if (splitByBold) {
            const firstBold = selectItems.findIndex(si => si.bold
                && si.value !== DropDownExtraOptions.DO_NOT_CHANGE
                && si.value !== DropDownExtraOptions.CLEAN_OLD_VALUE);
            if (firstBold >= 0) {
                selectItems.unshift({
                    label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'),
                    value: undefined,
                    bold: true,
                    center: true,
                    disabled: true
                });
            }
            const firstNonBold = selectItems.findIndex(si => !si.bold
                && si.value !== DropDownExtraOptions.DO_NOT_CHANGE
                && si.value !== DropDownExtraOptions.CLEAN_OLD_VALUE);
            if (firstNonBold >= 0) {
                selectItems.splice(firstNonBold, 0, {
                    label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'),
                    value: undefined,
                    bold: true,
                    center: true,
                    disabled: true
                });
            }
        }
        return selectItems;
    }

    resetDialog() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

}
