import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {Rack} from './rack';

@Injectable()
export class RackService implements CrudService<Rack> {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Rack>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<Rack>>('racks', {params: params});
    }

    getItem(itemId: number): Observable<Rack> {
        return this.http.get<Rack>(`racks/${itemId}`);
    }

    addItem(item: Rack): Observable<number> {
        // handled by saveRack
        return this.saveRack(item);
    }

    editItem(itemId: number, item: Rack): Observable<number> {
        // handled by saveRack
        return this.saveRack(item);
    }

    saveRack(item: Rack): Observable<number> {
        return this.http.post<void>('racks', item, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('racks', response)));
    }

    copy(id: number, item: Rack): Observable<number> {
        let headers = {};
        headers['Content-Type'] = 'application/json';
        let options = {headers: headers};
        return this.http.post(`racks/${id}/copy`, item, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('racks', response)));
    }

    validateGeneralData(item: Rack): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post<void>(`racks/validateGeneralData`, item));
    }
}
