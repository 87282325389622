import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ChangeLanguageService {

    languagedChanged: Subject<string> = new Subject<string>();

    constructor(private http: HttpClient) {
    }

    prepareStatement(newLanguage): Observable<void> {
        return this.http.post<void>('user/lang', newLanguage);
    }

    changeLng(newLanguage): void {
        this.prepareStatement(newLanguage).subscribe({
            next: response => {
                this.languagedChanged.next(newLanguage);
                console.log(response);
            },
            error: errorMessage => {
                console.log(errorMessage);
            }
        });
    }
}
