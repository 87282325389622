import {Component, Input} from '@angular/core';
import {SubsystemNoteCategory} from "../subsystem-note-category";

@Component({
  selector: 'app-subsystem-notes-categories-form',
  templateUrl: './subsystem-notes-categories-form.component.html'
})
export class SubsystemNotesCategoriesFormComponent {

    @Input()
    category: SubsystemNoteCategory;

    @Input()
    validationErrors;

}
