<div class="dimension-price-container">
    <!--Toolbar-->
    <div class="table-header">
        <div class="table-header-buttons">
            <div class="flex-row">
                <div class="profit-margin-global-input-container">
                    <app-input-number inputId="globalDimensionPriceUpdateInput"
                                      [disabled]="disabled"
                                      [(validationMessageKey)]="validationErrors['globalDimensionPriceUpdateInput']"
                                      [(ngModel)]="globalPrice">
                        <ng-template pTemplate="label"></ng-template>
                    </app-input-number>
                </div>
                <app-simple-button buttonId="globalDimensionPriceUpdateButton"
                                   [disabled]="disabled"
                                   label="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.SET_ALL_VALUES' | translate }}" icon="beenhere"
                                   type="action" [size]="40" (onClick)="applyGlobalPrice()"></app-simple-button>
            </div>
        </div>
    </div>

    <!--Table-->
    <p-dataTable #dt *ngIf="showTable" [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [editable]="true" [responsive]="true" [(selection)]="selectedItem"
                 (onEditComplete)="editDimensionPrice($event)" (onEditInit)="beforeEdit($event)"
                 (onEditExit)="editDimensionPrice($event)" (onPage)="setPages($event)" (onFilter)="updateTotalPageCount()">

        <p-column field="roofWindowsDimension.width" header="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.WIDTH' | translate }}"
                  [sortable]="true" [editable]="false" [filter]="true" filterMatchMode="equals">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.roofWindowsDimension.width}}
            </ng-template>
        </p-column>

        <p-column field="roofWindowsDimension.height" header="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.HEIGHT' | translate }}"
                  [sortable]="true" [editable]="false" [filter]="true" filterMatchMode="equals">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.roofWindowsDimension.height}}
            </ng-template>
        </p-column>

        <p-column field="price" header="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.PRICE' | translate }}" [editable]="!disabled" [style]="{'width':'15%', 'padding-right':'32px'}">
            <ng-template let-item="rowData" pTemplate="body">
                <div>
                    <span class="editable-cell-value-span">{{ item.price }}</span>
                    <span class="material-icons editable-cell-value-span-icon"></span>
                </div>
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>

    <p *ngIf="!showTable">{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.DATA_LOADING' | translate }}</p>
</div>
