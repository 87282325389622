import {DealerDiscount} from "../dealer-discount";
import {MultilanguageField} from "../../../../supportedLanguages";

export class DealerDiscountPerConfigurableAddon extends DealerDiscount {
    systemId: number;
    systemName: MultilanguageField;

    constructor() {
        super();
        this.systemId = undefined;
        this.systemName = new MultilanguageField();
    }

    public static fromJSON(jsonObject: any): DealerDiscountPerConfigurableAddon {
        let dealerDiscount = new DealerDiscountPerConfigurableAddon();
        dealerDiscount.id = jsonObject.id;
        dealerDiscount.systemName = MultilanguageField.fromJSON(jsonObject.systemName);
        dealerDiscount.active = jsonObject.active;
        dealerDiscount.cascade = jsonObject.cascade;
        dealerDiscount.systemId = jsonObject.systemId;
        dealerDiscount.discountValue = jsonObject.discountValue;
        dealerDiscount.calculatedDiscountValue = jsonObject.calculatedDiscountValue;
        dealerDiscount.validFrom = new Date(jsonObject.validFrom);
        dealerDiscount.isCurrent = jsonObject.isCurrent;
        return dealerDiscount;
    }
}
