import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ConfigEditorFieldContentProvider} from '../../../offer/config-editor/config-editor-field-content-provider';
import {ConfigDesignerCatalogDependentOption} from './config-designer-catalog-dependent-option';
import {
    ConfigDesignerCatalogDependentOptionFormDependencyComponent
} from './config-designer-catalog-dependent-option-form-dependency.component';

@Component({
    selector: 'app-config-designer-catalog-dependent-option-form-required',
    templateUrl: './config-designer-catalog-dependent-option-form-dependency.component.html',
    styleUrls: ['./config-designer-catalog-dependent-option-form-dependency.component.css']
})
export class ConfigDesignerCatalogDependentOptionFormRequiredComponent extends ConfigDesignerCatalogDependentOptionFormDependencyComponent
    implements OnInit {

    constructor(translate: TranslateService,
                translatedSelectItemService: TranslatedSelectItemService,
                configEditorFieldContentProvider: ConfigEditorFieldContentProvider) {
        super(translate, translatedSelectItemService, configEditorFieldContentProvider, true);
    }

    getInput(option: ConfigDesignerCatalogDependentOption) {
        return {
            inputId: option.requiredInputId,
            inputValue: option.requiredInputValue,
            whenMatched: undefined
        };
    }
}
