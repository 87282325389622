<p-dialog *ngIf="dataReady" [resizable]="false" [visible]="true" [modal]="true" (onHide)="resetDialog()"
          [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_FITTINGS_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div *ngIf="!anyFittingAvailable">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_FITTINGS_CHANGE.NO_AVAILABLE_FITTING_MESSAGE' | translate }}
        </div>
        <!--FITTING_BRAKE-->
        <div class="new-form-row" *ngIf="availableFittingBrakes?.length > 0">
            <app-select inputId="fittingBrakeIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_BRAKE' | translate }}"
                        [options]="availableFittingBrakes" [(ngModel)]="fittingBrake"></app-select>
        </div>

        <!--FITTING_SLIDING-->
        <div class="new-form-row" *ngIf="availableFittingSlidings?.length > 0">
            <app-select inputId="fittingSlidingIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_SLIDING' | translate }}"
                        [options]="availableFittingSlidings" [(ngModel)]="fittingSliding"></app-select>
        </div>

        <!--FITTING_TYPE-->
        <div class="new-form-row" *ngIf="availableFittingTypes?.length > 0">
            <app-select inputId="fittingTypeIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_TYPE' | translate }}"
                        [options]="availableFittingTypes" [(ngModel)]="fittingType"></app-select>
        </div>

        <!--FITTING_ESPAGNOLETTE_TYPE-->
        <div class="new-form-row" *ngIf="availableFittingEspagnoletteTypes?.length > 0">
            <app-select inputId="fittingTypeIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_ESPAGNOLETTE_TYPE' | translate }}"
                        [options]="availableFittingEspagnoletteTypes"
                        [(ngModel)]="fittingEspagnoletteType"></app-select>
        </div>

        <!--FITTING_VERANDA-->
        <div class="new-form-row" *ngIf="availableFittingVerandas?.length > 0">
            <app-select inputId="fittingVerandaIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_VERANDA' | translate }}"
                        [options]="availableFittingVerandas" [(ngModel)]="fittingVeranda"></app-select>
        </div>

        <!--FITTING_INSERTION-->
        <div class="new-form-row" *ngIf="availableFittingInsertions?.length > 0">
            <app-select inputId="fittingInsertionIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_INSERTION' | translate }}"
                        [options]="availableFittingInsertions" [(ngModel)]="fittingInsertion"></app-select>
        </div>

        <!--FITTING_MAIN_INSERTION-->
        <div class="new-form-row" *ngIf="availableFittingMainInsertions?.length > 0">
            <app-select inputId="fittingMainInsertionIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_MAIN_INSERTION' | translate }}"
                        [options]="availableFittingMainInsertions" [(ngModel)]="fittingMainInsertion"></app-select>
        </div>

        <!--FITTING_ADDITIONAL_INSERTION-->
        <div class="new-form-row" *ngIf="availableFittingAdditionalInsertions?.length > 0">
            <app-select inputId="fittingAdditionalInsertionIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_ADDITIONAL_INSERTION' | translate }}"
                        [options]="availableFittingAdditionalInsertions"
                        [(ngModel)]="fittingAdditionalInsertion"></app-select>
        </div>

        <!--FITTING_LOCK-->
        <div class="new-form-row" *ngIf="availableFittingLocks?.length > 0">
            <app-select inputId="fittingLockIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK' | translate }}"
                        [options]="availableFittingLocks" [(ngModel)]="fittingLock"></app-select>
        </div>

        <!--FITTING_LOCK_TERRACE-->
        <ng-container *ngIf="availableFittingLocksTerrace?.length > 0">
            <div class="new-form-row">
                <app-select inputId="fittingLockTerraceIdSelect"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE' | translate }}"
                            [options]="availableFittingLocksTerrace" [(ngModel)]="fittingLockTerrace"></app-select>
            </div>

            <!--FITTING_LOCK_TERRACE-->
            <div class="new-form-row">
                <app-select inputId="fittingLockTerraceLocationSelect"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE_LOCATION' | translate }}"
                            [options]="availableFittingLocksTerraceLocations" [translateLabels]="true"
                            [(ngModel)]="fittingLockTerraceLocation"></app-select>
            </div>
        </ng-container>

        <!--FITTING_AUTOMATIC_DRIVE-->
        <div class="new-form-row" *ngIf="availableFittingAutomaticDrives?.length > 0">
            <app-select inputId="fittingAutomaticDriveIdSelect"
                        label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_AUTOMATIC_DRIVE' | translate }}"
                        [options]="availableFittingAutomaticDrives" [(ngModel)]="fittingAutomaticDrive"></app-select>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" id="changeFittingsSaveButton" type="main-action" [size]="40"
                               (onClick)="submitDialog()" [disabled]="!anyFittingAvailable"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" buttonid="cancelButton" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
