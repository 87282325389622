import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class Template implements CrudItem {
    id: number;
    content: MultilanguageField;
    templateType: TemplateType;

    constructor() {
        this.content = new MultilanguageField();
        this.templateType = TemplateType.OTHER_INFO;
    }

    static fromJSON(jsonObject: any): Template {
        let template = new Template();
        template.id = jsonObject.id;
        template.content = MultilanguageField.fromJSON(jsonObject.content);
        template.templateType = TemplateType[<string>jsonObject.templateType];
        return template;
    }
}

export enum TemplateType {
    OTHER_INFO = <any>"OTHER_INFO",
    COMMENT = <any>"COMMENT"
}
