<div class="new-form-400 window-system-shape-dialog-width">
    <div *ngIf="!availableSubwindowTypes || availableSubwindowTypes.length === 0">
        {{ 'WINDOW-SYSTEM-DEFINITION.SHAPES.NO_AVAILABLE_SUBWINDOW_TYPE' | translate }}
    </div>
    <fieldset *ngFor="let type of availableSubwindowTypes; let i = index;">
        <legend><span class="window-system-shape-business-type">{{getTypeName(type)}}</span></legend>
        <div class="new-form-row">
            <app-checkbox [inputId]="type + 'arcable'"
                          [disabled]="disabled"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.SHAPES.CAN_BE_ARCED' | translate }}"
                          [(ngModel)]="windowSystem.subwindowTypesLimitations[type].arcable"></app-checkbox>
        </div>
        <div class="new-form-row">
            <app-input-number [inputId]="type + 'maxCutCount'" [min]="0" [max]="99" [required]="true"
                              [disabled]="disabled"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.SHAPES.MAX_CUT_COUNT' | translate }}"
                              [(validationMessageKey)]="validationErrors['subwindowTypesLimitations[' + type + '].maxCutCount']"
                              [(ngModel)]="windowSystem.subwindowTypesLimitations[type].maxCutCount"></app-input-number>
        </div>
        <div class="new-form-row">
            <app-input-number [inputId]="type + 'minSideLength'" [min]="0" [max]="90" [required]="true"
                              [disabled]="disabled"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MIN_SIDE_LEGNTH' | translate }}"
                              [(validationMessageKey)]="validationErrors['subwindowTypesLimitations[' + type + '].minSideLength']"
                              [(ngModel)]="windowSystem.subwindowTypesLimitations[type].minSideLength"></app-input-number>
        </div>
    </fieldset>
</div>
