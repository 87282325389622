import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from "rxjs";
import {SupportedLanguages} from '../../../../supportedLanguages';
import {CatalogOption} from '../catalog-option';

@Component({
    selector: 'app-catalog-option-form',
    templateUrl: './catalog-option-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogOptionFormComponent implements OnInit {

    supportedLanguages = SupportedLanguages.languages;

    @Input() item: CatalogOption;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() availableTargets: Observable<SelectItem[]>;
    @Input() canUploadToAmazon = false;
    @Input() amazonS3Files: SelectItem[] = [];

    @Output() uploadedFileChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() readonly fileChange = new EventEmitter<File>();
    @Output() readonly getFilePreview: EventEmitter<void> = new EventEmitter<void>();

    uploadedFile: File;
    selectedFile: string;

    ngOnInit() {
        this.selectedFile = this.item.amazonS3FileName;
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    handleUploadedFileChange(file: File): void {
        this.uploadedFile = file || new File([], null);
        this.uploadedFileChange.emit(this.uploadedFile);
        this.selectedFile = undefined;
        this.item.amazonS3FileName = this.uploadedFile.name;
    }

    handleUnusedFileSelectionChange(file: string): void {
        this.uploadedFile = undefined;
        this.uploadedFileChange.emit(this.uploadedFile);
        this.selectedFile = file;
        this.item.amazonS3FileName = this.selectedFile;
    }
}
