import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {Subscription} from 'rxjs';
import {finalize, mergeMap, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {Permissions} from '../../auth/permission.service';
import {CommonErrorHandler} from "../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../common/dataServiceHelper";
import {SecondLevelMenu} from '../../common/second-level-menu/SecondLevelMenu';
import {OnceFlag} from '../../shared/once-flag';
import {SupportedLanguages} from "../../supportedLanguages";
import {TranslationCatalog} from "./translation-catalog";
import {TranslationEntity} from "./translation-entity";
import {TranslationField} from "./translation-field";
import {TranslationItem} from "./translation-item";
import {TranslationsService} from "./translations.service";

@Component({
    selector: 'app-translations',
    templateUrl: './translations.component.html',
    styleUrls: ['./translations.component.css', '../shared-styles.css', '../../second-level-menu.css'],
    providers: [TranslationsService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, SecondLevelMenu<string> {

    catalog: TranslationCatalog;
    errors: CommonErrorHandler;
    permissions: Permissions;
    supportedLanguages = SupportedLanguages.languages;
    selected: TranslationItem;
    selectedFieldName: string;
    selectedEntityName: string;
    selectedTranslation: TranslationItem;
    displayDialog = false;
    catalogFetched = false;
    lastLoadEvent: LazyLoadEvent;
    enterForDialog;

    activeTranslationEntity: TranslationEntity;
    activeSubComponent: string;
    private sideNavItemsTranslateSubscription: Subscription;
    private paramMapSubscription: Subscription;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public translationsService: TranslationsService,
                private route: ActivatedRoute,
                private router: Router,
                public translate: TranslateService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'TranslationsComponent', false);
        this.errors = injector.get(CommonErrorHandler);
        this.permissions = injector.get(Permissions);
        this.catalog = new TranslationCatalog();
        this.enterForDialog = new Hotkey('enter', (): boolean => {
            this.submit();
            return false;
        }, ['INPUT']);
    }

    showDialogToAdd() {
    }

    ngOnInit() {
        this.translationsService.getCatalog().pipe(
            tap(data => {
                this.catalog.init(data);
                this.sideNavItemsTranslateSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                    this.catalog.translationGroups.sort((a, b) => {
                        const aName = this.translate.getParsedResult(event.translations, this.getEntityNameLabel(a));
                        const bName = this.translate.getParsedResult(event.translations, this.getEntityNameLabel(b));
                        return aName.localeCompare(bName);
                    });
                });
            }),
            mergeMap(() => {
                return this.translate.get(this.catalog.translationGroups.map(item => this.getEntityNameLabel(item)));
            })
        ).subscribe({
            next: translations => {
                this.catalog.translationGroups.sort((a, b) => {
                    const aName = translations[this.getEntityNameLabel(a)];
                    const bName = translations[this.getEntityNameLabel(b)];
                    return aName.localeCompare(bName);
                });
                this.paramMapSubscription = this.route.paramMap.subscribe(params => {
                    const component = params.get('component') || this.catalog.translationGroups[0].entityName;
                    this.switchToSubComponent(component);
                });
                this.catalogFetched = true;
            },
            error: error => {
                console.error('TranslationsComponent `getCatalog` error:', error);
                this.growlMessageController.error(error);
            },
            complete: () => {
                console.info('TranslationsComponent `getCatalog` completed!');
                this.changeDetector.markForCheck();
            }
        });
        this.setDisplayDialog(false);
        this.selectedEntityName = '';
        this.selectedFieldName = '';
    }

    ngOnDestroy() {
        if (this.sideNavItemsTranslateSubscription != undefined) {
            this.sideNavItemsTranslateSubscription.unsubscribe();
        }
        if (this.paramMapSubscription != undefined) {
            this.paramMapSubscription.unsubscribe();
        }
        this.hotkeysService.remove(this.enterForDialog);
        super.ngOnDestroy();
    }

    closeDialogAndReload() {
        this.hotkeysService.remove(this.enterForDialog);
        this.setDisplayDialog(false);
        this.loadTranslationsLazy(this.lastLoadEvent, this.selectedEntityName, this.selectedFieldName);
    }

    loadTranslationsLazy(event: LazyLoadEvent, entityName: string, fieldName: string) {
        super.loadItemsLazy(event);
        this.lastLoadEvent = event;
        return this.translationsService.getTranslations(event.first, event.rows, event.filters, event.sortField, event.sortOrder,
            entityName, fieldName)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.fillCatalog(entityName, fieldName, data.data, data.totalRecords, event.first, event.rows);
                },
                error: error => {
                    console.error('TranslationsComponent `getPage` error:', error);
                    this.growlMessageController.error(error);
                },
                complete: () => {
                    console.info('TranslationsComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    fillCatalog(entityName: string, fieldName: string, data: TranslationItem[], totalRecords: number, first: number, rows: number) {
        this.catalog.fillWithTranslations(entityName, fieldName, data, totalRecords, first, rows);
    }

    selectDataRow(item: TranslationItem, entityName: string, fieldName: string) {
        this.validationErrors = {};
        this.selectedEntityName = entityName;
        this.selectedFieldName = fieldName;
        this.selected = new TranslationItem();
        this.selected.id = item.id;
        this.selected.translations = _.clone(item.translations);
        this.setDisplayDialog(true);
        this.focusOnElementWithId(this.getFirstInputId());
        setTimeout(() => this.hotkeysService.add(this.enterForDialog));
    }

    getFirstInputId() {
        return this.supportedLanguages[0].code;
    }

    onRowSelect(event) {
    }

    cancel() {
        this.dialogHideHelper.call(() => this.closeDialogAndReload());
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        this.translationsService.save(this.selectedEntityName, this.selectedFieldName, this.selected).subscribe({
            next: () => {
                this.dialogHideHelper.call(() => {
                    this.setSaveInProgress(false);
                    this.closeDialogAndReload();
                    this.growlMessageController.info('TRANSLATIONS.SAVED');
                });
            },
            error: error => {
                this.setSaveInProgress(false);
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            },
            complete: () => {
                console.info('Save translation completed!');
            }
        });
    }

    getDatatable(): DataTable {
        return undefined;
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
            if (display) {
                this.dialogHideHelper.reset();
            }
        }
    }

    switchToSubComponent(item: string): void {
        this.activeSubComponent = item;
        this.activeTranslationEntity = this.catalog.translationGroups.find(entity => entity.entityName === item);
    }

    navigateToSubComponent(item: string): void {
        this.router.navigate(['.', {component: item}], {relativeTo: this.route});
    }

    isActiveSubComponent(item: string): boolean {
        return this.activeSubComponent === item;
    }

    toggleFilters(event, field: TranslationField): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        field.filterVisible = !field.filterVisible;
    }

    getEntityNameLabel(entity: TranslationEntity) {
        return 'TRANSLATIONS.' + entity.entityName + '.' + entity.entityName;
    }
}
