import { Pipe, PipeTransform } from '@angular/core';
import {MotlawaRowDiffType} from "./MotlawaRowDiffType";

@Pipe({
    name: 'renderDiffs'
})
export class RenderMotlawaDiffsPipe implements PipeTransform {

    transform(showDiff: boolean, rowDiffs: Map<number, MotlawaRowDiffType>, cellDiffs: Map<number, number[]>,
              row: number, col: number): string {
        if (showDiff) {
            if (rowDiffs && rowDiffs.get(row)) {
                return rowDiffs.get(row);
            }
            if (cellDiffs && cellDiffs.get(row) && cellDiffs.get(row).includes(col)) {
                return 'MODIFIED';
            }
        }
        return null;
    }

}
