<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(GatePanelTypeField.NAME)"
                            inputId="name" label="{{ 'GATE_PANEL_TYPE.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GatePanelTypeField.NAME)"
                            [maxlength]="LIMITS.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.name"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(GatePanelTypeField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'GATE_PANEL_TYPE.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GatePanelTypeField.SHORTCUT)"
                            [maxlength]="LIMITS.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(GatePanelTypeField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'GATE_PANEL_TYPE.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(GatePanelTypeField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true"></app-input-text>
    </div>
    <div *ngIf="fieldUsage.show(GatePanelTypeField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GATE_PANEL_TYPE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GatePanelTypeField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GatePanelTypeField.HEIGHT)">
        <app-input-number inputId="height" label="{{ 'GATE_PANEL_TYPE.FORM.HEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(GatePanelTypeField.HEIGHT)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['height']" [min]="1"
                          [max]="1000" [step]="1" [(ngModel)]="item.height"
                          [required]="true"></app-input-number>
    </div>
    <div *ngIf="fieldUsage.show(GatePanelTypeField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'GATE_PANEL_TYPE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GatePanelTypeField.SORT_INDEX)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                          [max]="99999999999" [step]="1" [(ngModel)]="item.sortIndex"
                          [required]="true"></app-input-number>
    </div>

    <ng-container  *ngIf="fieldUsage.show(GatePanelTypeField.TYPE_IMAGE)">
        <div class="horizontal-block" *ngFor="let type of availableStampingTypes">
            <label class="types-radio">
                <input [disabled]="fieldUsage.disabled(GatePanelTypeField.TYPE_IMAGE)" type="radio" name="fillingType"
                       value="{{ type.type }}" [ngModel]="item.stampingType"
                       (ngModelChange)="handleStampingTypeChange($event)" />
                <img class="image" src="{{ type.image }}" alt="{{ type.type }}" title="{{ type.type }}">
            </label>
        </div>
        <div *ngIf="validationErrors['stampingType']" class="new-form-field-error-message" style="padding-left: 8px;">
            {{ validationErrors['stampingType'] | translate }}
        </div>
    </ng-container>

    <p-accordion>
        <p-accordionTab *ngIf="fieldUsage.show(GatePanelTypeField.LOW_RESOLUTION_PREVIEW_IMAGE) || fieldUsage.show(GatePanelTypeField.DESIGNER_COMMENT)"
                        header="{{ 'GATE_PANEL_TYPE.FORM.DIALOG_DATA' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['lowResolutionPreviewImage'] != undefined}">

            <div class="new-form-row" *ngIf="fieldUsage.show(GatePanelTypeField.LOW_RESOLUTION_PREVIEW_IMAGE)">
                <app-file-upload inputId="image" [ngModel]="lowResolutionPreviewImage"
                                 [disabled]="fieldUsage.disabled(GatePanelTypeField.LOW_RESOLUTION_PREVIEW_IMAGE)"
                                 (ngModelChange)="lowResolutionPreviewImageChange.emit($event)" [maxSize]="150000"
                                 [maxWidth]="400" [maxHeight]="400"
                                 [addButtonLabel]="'GATE_PANEL_TYPE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GATE_PANEL_TYPE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GATE_PANEL_TYPE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['lowResolutionPreviewImage']"></app-file-upload>
            </div>

            <ng-container  *ngIf="fieldUsage.show(GatePanelTypeField.DESIGNER_COMMENT)">
                <div class="multilang-text-area" >
                    <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                        <app-text-area inputId="{{'designerComment_' + lang.code}}"
                                       label="{{ 'GATE_PANEL_TYPE.FORM.DESIGNER_COMMENT' | translate: {lang: lang.code} }}"
                                       [disabled]="fieldUsage.disabled(GatePanelTypeField.DESIGNER_COMMENT)"
                                       [(validationMessageKey)]="validationErrors['designerComment']" [maxlength]="1000"
                                       [required]="false" [(ngModel)]="item.designerComment && item.designerComment[lang.code]"></app-text-area>
                    </div>
                </div>

            </ng-container>
        </p-accordionTab>
        <p-accordionTab *ngIf="fieldUsage.show(GatePanelTypeField.HIGH_RESOLUTION_PREVIEW_IMAGE)"
                        header="{{ 'GATE_PANEL_TYPE.FORM.HIGH_RESOLUTION_PREVIEW_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['highResolutionPreviewImage'] != undefined}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="highResolutionPreviewImage"
                                 [disabled]="fieldUsage.disabled(GatePanelTypeField.HIGH_RESOLUTION_PREVIEW_IMAGE)"
                                 (ngModelChange)="highResolutionPreviewImageChange.emit($event)" [maxSize]="5242880"
                                 [maxWidth]="1500" [maxHeight]="1000"
                                 [addButtonLabel]="'GATE_PANEL_TYPE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GATE_PANEL_TYPE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GATE_PANEL_TYPE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['highResolutionPreviewImage']"></app-file-upload>
            </div>
        </p-accordionTab>

        <p-accordionTab *ngIf="fieldUsage.show(GatePanelTypeField.PRINT_DATA)"
                        header="{{ 'GATE_PANEL_TYPE.FORM.PRINT_DATA' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['additionalIcon'] || validationErrors['additionalComment']}">
            <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                <app-text-area inputId="{{'additionalComment_' + lang.code}}"
                               label="{{ 'GATE_PANEL_TYPE.FORM.ADDITIONAL_COMMENT' | translate: {lang: lang.code} }}"
                               [disabled]="fieldUsage.disabled(GatePanelTypeField.PRINT_DATA)"
                               [(validationMessageKey)]="validationErrors['additionalComment']" [maxlength]="1000"
                               [required]="false" [(ngModel)]="item.additionalComment && item.additionalComment[lang.code]"></app-text-area>
            </div>

            <app-file-upload inputId="additionalIcon" [ngModel]="additionalIcon"
                             (ngModelChange)="additionalIconChange.emit($event)"
                             [disabled]="fieldUsage.disabled(GatePanelTypeField.PRINT_DATA)"
                             [maxSize]="10000" [maxWidth]="100" [maxHeight]="100"
                             [addButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                             [changeButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                             [deleteButtonLabel]="'CATALOG_OPTION.FORM.DELETE_FILE_UPLOAD'"
                             [(validationMessageKey)]="validationErrors['additionalIcon']"></app-file-upload>
        </p-accordionTab>
    </p-accordion>
</div>
