import {TranslationEntity} from "./translation-entity";
import {TranslationItem} from "./translation-item";

export class TranslationCatalog {
    translationGroups: TranslationEntity[];

    constructor() {
        this.translationGroups = [];
    }

    public init(data: TranslationCatalog): void {
        this.translationGroups = data.translationGroups
            .map(translationGroup => {
                translationGroup.fields.forEach(field => {
                    field.pagination = {
                        totalRecords: 0,
                        fromRecord: 0,
                        toRecord: 0
                    };
                    field.translations = [];
                });
                return translationGroup;
            });
    }

    public fillWithTranslations(entityName: string, fieldName: string, translations: TranslationItem[], totalRecords: number,
                                first: number, rows: number) {
        let translationGroup = this.translationGroups.find(group => group.entityName === entityName);
        if (translationGroup != undefined) {
            let translationField = translationGroup.fields.find(field => field.fieldName === fieldName);
            if (translationField != undefined) {
                translationField.translations = translations;
                translationField.pagination = {
                    totalRecords: totalRecords,
                    fromRecord: Math.min(first + 1, totalRecords),
                    toRecord: Math.min(first + rows, totalRecords)
                };
            }
        }
    }
}
