import {Routes} from "@angular/router";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {GeneralErrorComponent} from "./general-error/general-error.component";
import {NoPrivilegesErrorComponent} from "./no-privileges-error/no-privileges-error.component";
import {MaintenancePageComponent} from "../../common/maintenance-page/maintenance-page.component";

export const errorRoutes: Routes = [
    {
        path: 'no-privileges-error',
        component: NoPrivilegesErrorComponent
    },
    {
        path: 'general-error',
        component: GeneralErrorComponent
    },
    {
        path: 'maintenance-page',
        component: MaintenancePageComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
