import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class CatalogItemTag implements CrudItem {
    id: number;
    tagText: MultilanguageField;
    color: string;
    active: boolean;
    sortIndex: number;

    static fromJSON(jsonObject: any): CatalogItemTag {
        const catalogItemTag = new CatalogItemTag();
        catalogItemTag.id = jsonObject.id;
        catalogItemTag.tagText = MultilanguageField.fromJSON(jsonObject.tagText);
        catalogItemTag.active = jsonObject.active;
        catalogItemTag.color = jsonObject.color;
        catalogItemTag.sortIndex = jsonObject.sortIndex;
        return catalogItemTag;
    }
}
