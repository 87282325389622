import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class RailSystem implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    active = true;
    sortIndex: number;
    image: string;
    designerImage: string;
    additionalComment: MultilanguageField;

    static fromJSON(jsonObject): RailSystem {
        const rail = new RailSystem();
        rail.id = jsonObject.id;
        rail.name = MultilanguageField.fromJSON(jsonObject.name);
        rail.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        rail.symbol = jsonObject.symbol;
        rail.active = jsonObject.active;
        rail.sortIndex = jsonObject.sortIndex;
        rail.image = jsonObject.image;
        rail.designerImage = jsonObject.designerImage;
        rail.additionalComment = jsonObject['additionalComment'];
        return rail;
    }
}
