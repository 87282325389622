<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.LIST' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="webshopGlazingPackagesPerSubsystem" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedWebshopGlazingPackagePerSubsystem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="name" header="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.NAME' | translate }}"
                  [sortable]="true"
                  [filter]="showFilters"
                  disabled="true"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.webshopGlazingPackage.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="enabledInWebshop" header="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.STATUS' | translate }}" [sortable]="false"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{ (item.enabledInWebshop ? 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.ACTIVE' : 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.INACTIVE') | translate }}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard-dialog *ngIf="displayDialog"
                   header="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.EDIT_TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()"
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.DATA' | translate }}"
                     [id]="STEPS.DATA" [validate]="VALIDATORS.DATA">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-input-text inputId="webshopGlazingPackageName" disabled="true"
                                label="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.NAME' | translate }}"
                                [ngModel]="selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage.names[userLang]"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-text-area  inputId="webshopGlazingPackageDescription" disabled="true"
                                label="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.DESCRIPTION' | translate }}"
                                [ngModel]="selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage.description[userLang]"></app-text-area>
            </div>

            <div class="new-form-row">
                <app-checkbox inputId="enabledInWebshop"
                              label="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.ENABLED_IN_WEBSHOP' | translate }}"
                              [(ngModel)]="selectedWebshopGlazingPackagePerSubsystem.enabledInWebshop"></app-checkbox>
            </div>
        </div>

    </app-wizard-step>

    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.FORM.GLAZING' | translate }}"
                     [id]="STEPS.GLAZING" [validate]="VALIDATORS.GLAZING">
        <app-glazing-packages-form *ngIf="!hasPredefinedGlazingPackage(selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage)"
                                   [item]="selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage"
                                   [supportPackagesForAreaRanges]="true"
                                   [validationErrors]="glazingValidationErrors"
                                   [glasses]="glasses"
                                   [frames]="frames"
                                   [target]="glazingPackageTargets.WEBSHOP_GLAZING"
                                   [readOnly]="true"></app-glazing-packages-form>
        <app-webshop-glazing-package-glazing-form *ngIf="hasPredefinedGlazingPackage(selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage)"
                                                  [readOnly]="true"
                                                  [item]="selectedWebshopGlazingPackagePerSubsystem.webshopGlazingPackage"
                                                  [validationErrors]="validationErrors"
                                                  [graspGlazingPackages]="graspGlazingPackages"></app-webshop-glazing-package-glazing-form>
    </app-wizard-step>
</app-wizard-dialog>
