import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {SubsystemNoteCategory} from "./subsystem-note-category";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {map} from "rxjs/operators";

@Injectable()
export class SubsystemNotesCategoriesService implements CrudService<SubsystemNoteCategory> {

    private static readonly API_URL = 'subsystemNoteCategory';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(category: SubsystemNoteCategory): Observable<number> {
        return this.http.post<void>(SubsystemNotesCategoriesService.API_URL, category, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(SubsystemNotesCategoriesService.API_URL, response)));
    }

    editItem(categoryId: number, category: SubsystemNoteCategory): Observable<number> {
        return this.http.put<void>(`${SubsystemNotesCategoriesService.API_URL}/${categoryId}`, category)
            .pipe(map(() => categoryId));
    }

    getItem(categoryId: number): Observable<SubsystemNoteCategory> {
        return this.http.get<object>(`${SubsystemNotesCategoriesService.API_URL}/${categoryId}`)
            .pipe(map(response => SubsystemNoteCategory.fromJSON(response)));
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number, dateFormat?): Observable<Listing<SubsystemNoteCategory>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(SubsystemNotesCategoriesService.API_URL, {params: params})
            .pipe(map(response => Listing.fromJSON(SubsystemNoteCategory, response)));
    }

    getActiveItems() {
        return this.http.get<Listing<object>>(`${SubsystemNotesCategoriesService.API_URL}/active`)
            .pipe(map(response => Listing.fromJSON(SubsystemNoteCategory, response)));
    }
}
