import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {Observable, of, Subject} from "rxjs";
import {CechaGrupa} from "../../../../window-designer/enums/CechaGrupa";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {RequiresExitConfirmation} from "../../../common/component-exit-confirmation/requires-exit-confirmation";
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {Role} from "../role/role";
import {RoleService} from "../role/role.service";
import {CatalogElement} from "./catalog-element.enum";
import {
    AddonCategoryField,
    AddonCategoryGroupField,
    AddonField,
    BusinessTypeField,
    CatalogTab,
    ColorField,
    ConfigAddonField,
    DecorativeFillingField,
    DimensionsField,
    DistanceFrameField,
    EntranceGlazingPackageField,
    EntranceModelField,
    GateDependentOptionField,
    GateDependentOptionTab,
    GatePanelTypeField,
    GateSystemField,
    GateWallField,
    GlassField,
    GlazingBeadField,
    GraspDistanceFrameCategoryField,
    GraspGlazingCategoryField,
    GraspGlazingPackageField,
    GrillField,
    MaterialField,
    OtherFillingField,
    ProfileField,
    RackField,
    RailSystemField,
    RoofGlazingPackageField,
    RoofSystemField,
    SealField,
    SubwindowTypeField,
    SystemGlazingPackageField,
    WindowDependentOptionField,
    WindowSystemBasicField,
    WindowSystemField,
    WindowSystemTab
} from "./catalog-field.enum";
import {EditCatalogPermits} from "./edit-catalog-permits";
import {EditCatalogPermitsService} from "./edit-catalog-permits.service";
import {FieldLimitation} from "./field-limitation";

export enum CatalogEditLimitation {
    DISABLED = 'DISABLED', HIDDEN = 'HIDDEN', FULL_EDIT = 'FULL_EDIT'
}

class CopyConfig {
    selected: boolean;
    copyFrom: string;
}

interface UnsavedElement {
    catalogElement: string;
    field: string;
}

@Component({
    selector: 'app-edit-catalog-permits',
    templateUrl: './edit-catalog-permits.component.html',
    styleUrls: ['./edit-catalog-permits.component.css'],
    providers: [RoleService]
})
export class EditCatalogPermitsComponent implements RequiresExitConfirmation, OnInit {

    editOptions: SelectItem[] = [];
    catalogItems: string[] = [];
    copyConfig: Map<string, CopyConfig> = new Map<string, CopyConfig>();
    fieldsByItem: Map<string, FieldLimitation[]> = new Map<string, FieldLimitation[]>();
    permitsByRole: EditCatalogPermits[] = [];
    catalogItemsFormatter: (item: string) => SelectItem;
    selectedRole: Role;
    roles: SelectItem[];
    unsavedChangesIn: UnsavedElement[] = [];
    unsavedChangesDialogVisible = false;
    showUnsavedAccordion = false;
    exitConfirmation = new Subject<boolean>();

    private growlMessageController: GrowlMessageController;

    constructor(injector: Injector,
                private roleService: RoleService,
                private editCatalogPermitsService: EditCatalogPermitsService,
                private errors: CommonErrorHandler,
                private changeDetector: ChangeDetectorRef) {
        this.growlMessageController = injector.get(GrowlMessageController);
        this.catalogItemsFormatter = (item: string) => {
            return {
                label: 'NAVIGATION.ITEMS.WINDOW-SYSTEM.' + item + '.LIST',
                value: item,
            };
        };
    }

    ngOnInit() {
        this.roleService.getItems(null, null, {}, null, null).subscribe({
            next: roles => {
                this.roles = roles.data.map(role => {
                    return {
                        label: role.roleName,
                        value: role
                    };
                });
                this.selectedRole = this.roles[0].value;
                this.loadPermitsByRole();
            },
            error: error => {
                console.error('EditCatalogPermitsComponent `getRoles` error:', error);
                this.errors.handle(error);
            }
        });
        this.buildAccordions();
        this.buildOptions();
    }

    onExitAttempt(): Observable<boolean> {
        if (this.unsavedChangesIn.length > 0) {
            this.unsavedChangesDialogVisible = true;
            this.showUnsavedAccordion = false;
            this.changeDetector.markForCheck();
            return this.exitConfirmation.asObservable();
        }
        return of(true);
    }

    loadPermitsByRole(): void {
        this.editCatalogPermitsService.getPermitsByRole(this.selectedRole.id).subscribe({
            next: permits => {
                this.permitsByRole = permits;
                this.buildAccordions();
                for (let permit of permits) {
                    let fields = this.fieldsByItem.get(permit.catalogElement);
                    let configData: FieldLimitation[] = permit.fieldsLimitations;
                    fields.map(field => {
                        let configLimitation = configData.filter(data => data.fieldName === field.fieldName)[0];
                        field.limitation = configLimitation !== undefined ? configLimitation.limitation : CatalogEditLimitation.FULL_EDIT;
                    });
                }
                this.changeDetector.markForCheck();
            },
            error: error => {
                console.error('EditCatalogPermitsComponent `getPermits` error:', error);
                this.errors.handle(error);
            }
        });
    }

    buildAccordions(): void {
        this.catalogItems = [];
        this.fieldsByItem.clear();
        for (let item in CatalogElement) {
            this.catalogItems.push(item);
            this.copyConfig.set(item, {selected: false, copyFrom: null});
            this.setFields(item);
        }
    }

    buildOptions() {
        for (let option  in CatalogEditLimitation) {
            if (isNaN(Number(option))) {
                this.editOptions.push(new SelectItemImpl(
                    'SETTINGS.SECTION.EDIT_PERMISSIONS.EDIT_CONFIG_OPTION.' + option, option, true));
            }
        }
    }

    copyPermits(currentItem: string) {
        const copyFrom = this.copyConfig.get(currentItem).copyFrom;
        this.fieldsByItem.get(copyFrom).filter(field =>
            this.fieldsByItem.get(currentItem).some(field2 => field2.fieldName === field.fieldName))
            .forEach(field => {
                this.fieldsByItem.get(currentItem).find(field2 => field2.fieldName === field.fieldName).limitation = field.limitation;
                this.unsavedChangesIn.push({ catalogElement: currentItem, field: field.fieldName });
            });
    }

    savePermits(item) {
        const permits = new EditCatalogPermits();
        permits.catalogElement = item;
        permits.roleId = this.selectedRole.id;
        permits.fieldsLimitations = this.fieldsByItem.get(item).filter(limitation =>
                this.unsavedChangesIn.some(unsaved => unsaved.catalogElement === item && unsaved.field === limitation.fieldName) ||
            (limitation.limitation !== CatalogEditLimitation.FULL_EDIT && limitation.limitation !== null))
            .map(limitation => {
                const copy = {...limitation};
                if (copy.limitation === CatalogEditLimitation.FULL_EDIT || copy.limitation === null) {
                    copy.limitation = undefined;
                }
                return copy;
            });
        this.editCatalogPermitsService.saveItem(permits).subscribe({
            next: () => {
                this.growlMessageController.info('SETTINGS.SECTION.EDIT_PERMISSIONS.UPDATED');
                this.unsavedChangesIn = this.unsavedChangesIn.filter(element => element.catalogElement !== item);
                this.changeDetector.markForCheck();
            },
            error: error => {
                console.error('EditCatalogPermitsComponent `savePermits` error:', error);
                this.errors.handle(error);
            }
        });
    }

    selectRole(role: Role) {
        this.selectedRole = role;
        if (this.selectedRole != undefined) {
            this.loadPermitsByRole();
        }
    }

    setFields(catalogItem: string) {
        let fields: FieldLimitation[] = [];
        switch (catalogItem) {
            case CatalogElement.ADDONS:
                Object.keys(AddonField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ADDON_CATEGORY_GROUPS:
                Object.keys(AddonCategoryGroupField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ADDON_CATEGORIES:
                Object.keys(AddonCategoryField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.ADDONS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.CONFIG_ADDONS:
                Object.keys(ConfigAddonField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(CechaGrupa).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.COLORS:
                Object.keys(ColorField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.COLOR_USAGE));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GLAZING_BEADS:
                Object.keys(GlazingBeadField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.MATERIALS:
                Object.keys(MaterialField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.WINDOW_DEPENDENT_OPTIONS:
                Object.keys(WindowDependentOptionField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.SYSTEM_GLAZING_PACKAGES:
                Object.keys(SystemGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.SYSTEM_DECORATIVE_GLAZING_PACKAGES:
                Object.keys(SystemGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.TERRACE_GLAZING_PACKAGES:
                Object.keys(SystemGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.GLAZING));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.PROFILES:
                Object.keys(ProfileField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.DISTANCE_FRAMES:
                Object.keys(DistanceFrameField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.POSITION));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.WINDOW_SYSTEMS:
                Object.keys(WindowSystemBasicField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(WindowSystemField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(WindowSystemTab).forEach(key => {
                    if (key !== WindowSystemTab.DIMENSION_PRICE) {
                        fields.push(this.defaultFieldLimitation(key));
                    }
                });
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GRILLS:
                Object.keys(GrillField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GLASSES:
                Object.keys(GlassField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.POSITION));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.BUSINESS_TYPES:
                Object.keys(BusinessTypeField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.SUBWINDOW_TYPES:
                Object.keys(SubwindowTypeField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.SEALS:
                Object.keys(SealField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.POSITION));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.OTHER_FILLINGS:
                Object.keys(OtherFillingField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.DECORATIVE_FILLINGS:
                Object.keys(DecorativeFillingField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.SYSTEM_GROUP));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ROOF_GLAZING_PACKAGES:
                Object.keys(RoofGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ROOF_SYSTEMS:
                Object.keys(RoofSystemField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(WindowSystemBasicField).forEach(key => {
                    if (key !== WindowSystemBasicField.POSSIBLE_OPENING) {
                        fields.push(this.defaultFieldLimitation(key));
                    }
                });
                Object.keys(WindowSystemTab).forEach(key => {
                    if (key !== WindowSystemTab.WEB_SHOP) {
                        fields.push(this.defaultFieldLimitation(key));
                    }
                });
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ROOF_DIMENSIONS:
                Object.keys(DimensionsField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ENTRANCE_MODELS:
                Object.keys(EntranceModelField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GRASP_GLAZING_CATEGORIES:
                Object.keys(GraspGlazingCategoryField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GRASP_DISTANCE_FRAME_CATEGORIES:
                Object.keys(GraspDistanceFrameCategoryField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GRASP_GLAZING_PACKAGES:
                Object.keys(GraspGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ENTRANCE_GLAZING_PACKAGES:
                Object.keys(EntranceGlazingPackageField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ENTRANCE_SYSTEMS:
                Object.keys(WindowSystemBasicField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(WindowSystemTab).forEach(key => {
                    if (key !== WindowSystemTab.WEB_SHOP && key !== WindowSystemTab.GLAMOUR_PRINT_INFO) {
                        fields.push(this.defaultFieldLimitation(key));
                    }
                });
                fields.push(this.defaultFieldLimitation(CatalogTab.DESCRIPTION));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.ENTRANCE_DIMENSIONS:
                Object.keys(DimensionsField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GATE_SYSTEMS:
                Object.keys(GateSystemField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.DESCRIPTION));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GATE_WALLS:
                Object.keys(GateWallField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GATE_DEPENDENT_OPTIONS:
                Object.keys(GateDependentOptionField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                Object.keys(GateDependentOptionTab).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.GATE_PANEL_TYPES:
                Object.keys(GatePanelTypeField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.RAIL_SYSTEMS:
                Object.keys(RailSystemField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                fields.push(this.defaultFieldLimitation(CatalogTab.LINKED_SYSTEMS));
                this.fieldsByItem.set(catalogItem, fields);
                break;
            case CatalogElement.RACKS:
                Object.keys(RackField).forEach(key => fields.push(this.defaultFieldLimitation(key)));
                this.fieldsByItem.set(catalogItem, fields);
                break;
        }
    }

    defaultFieldLimitation(fieldName: string): FieldLimitation {
        return {fieldName: fieldName, limitation: CatalogEditLimitation.FULL_EDIT};
    }

    changeLimitation(catalogElement: string, field: string) {
        this.unsavedChangesIn.push({ catalogElement: catalogElement, field: field });
    }

    leaveWithoutSaving() {
        this.exitConfirmation.next(true);
    }

    unsavedAccordion(item: string): boolean {
        return this.showUnsavedAccordion && this.unsavedChangesIn.some(element => element.catalogElement === item);
    }
}
