import {HttpErrorResponse} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin} from 'rxjs';
import {defaultIfEmpty, finalize, map, mergeMap, tap} from 'rxjs/operators';
import {Permissions} from "../../../auth/permission.service";
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {ConfigurableAddonsService} from "../../offer/offers/position/config-addons.service";
import {SubsystemGroupService} from '../../subsystem-group/subsystem-group.service';
import {User} from '../../user/user';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {HasSaveTable} from '../base-profit-margin.component';
import {ProfitMarginTarget} from "../profit-margin/profit-margin-target";
import {normalizeProfitMarginValueString} from '../profit-margin/profitMargin';
import {Subsystem} from '../subsystem';
import {SubsystemValidator} from '../subsystem-validator';
import {SubsystemService} from '../subsystem.service';
import {TabularAddress} from '../tabularAddress';

@Component({
    selector: 'app-edit-subsystem',
    templateUrl: './edit-subsystem.component.html',
    providers: [SubsystemService, SubsystemGroupService, DataServiceHelper, ConfigurableAddonsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSubsystemComponent implements OnInit, OnDestroy {

    private static readonly SUBMIT_BLOCK_ID = 'EditSubsystemComponent submit';

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly STEPS = {
        GENERAL: 'general',
        ADDRESS_LIST: 'address-list',
        ADDRESS: 'address',
        SUPPLIERS: 'suppliers',
        SYSTEM_MARGINS: 'profit-margins-system',
        ROOF_MARGINS: 'profit-margins-roof-system',
        ENTRANCE_MARGINS: 'profit-margins-entrance-system',
        ADDON_MARGINS: 'profit-margins-addon',
        CONFIG_ADDON_MARGINS: 'profit-margins-config-addon',
        BULK_CONFIG_ADDON_MARGINS: 'profit-margins-bulk-config-addon',
        GATES_MARGINS: 'profit-margins-gates',
        CHARGE_MARGINS: 'profit-margins-catalog-charge',
        SYSTEM_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-system',
        CONFIG_ADDON_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-config-addon',
        SUPPLIER_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-supplier',
        USER_ACTIVITY_REPORT: 'user-activity-report'
    };

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly confAddGlobalMarginButtonId = 'confAddGlobalMarginButtonId';
    readonly confAddGlobalMarginInputId = 'confAddGlobalMarginInputId';
    readonly bulkConfAddGlobalMarginButtonId = 'bulkConfAddGlobalMarginButtonId';
    readonly bulkConfAddGlobalMarginInputId = 'bulkConfAddGlobalMarginInputId';
    readonly addonGlobalMarginButtonId = 'addonGlobalMarginButtonId';
    readonly addonGlobalMarginInputId = 'addonGlobalMarginInputId';
    readonly catalogChargeGlobalMarginButtonId = 'catalogChargeGlobalMarginButtonId';
    readonly catalogChargeGlobalMarginInputId = 'catalogChargeGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';
    readonly systemGlobalDiscountButtonId = 'systemGlobalDiscountButtonId';
    readonly systemGlobalDiscountInputId = 'systemGlobalDiscountInputId';
    readonly configAddonGlobalDiscountButtonId = 'configAddonGlobalDiscountButtonId';
    readonly configAddonGlobalDiscountInputId = 'configAddonGlobalDiscountInputId';
    readonly supplierGlobalDiscountButtonId = 'supplierGlobalDiscountButtonId';
    readonly supplierGlobalDiscountInputId = 'supplierGlobalDiscountInputId';

    readonly route: ActivatedRoute;
    possibleTargets = ProfitMarginTarget;

    @Input() subsystemGroups: SelectItem[];
    @Input() subsystem: Subsystem;
    @Input() addresses: TabularAddress[] = [];
    @Input() availableManagers: User[];
    selectedCorrespondenceAddress: TabularAddress;
    selectedDeliveryAddress: TabularAddress;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    @Input() disabledFields = false;

    @Output() onCancel = new EventEmitter();
    @Output() onSave = new EventEmitter();

    subsystemValidator: SubsystemValidator;
    generalStepValidator: WizardStepValidator;
    addressListStepValidator: WizardStepValidator;
    addressStepValidator: WizardStepValidator;
    validationErrors: ValidationErrors = {};
    protected enterHotkey: Hotkey;

    constructor(public translate: TranslateService,
                protected hotkeysService: HotkeysService,
                public changeDetector: ChangeDetectorRef,
                public subsystemService: SubsystemService,
                private errors: CommonErrorHandler,
                private configurableAddonService: ConfigurableAddonsService,
                public blockUiController: BlockUiController,
                public permissions: Permissions,
                injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.subsystemValidator = new SubsystemValidator(subsystemService);
        this.enterHotkey = new Hotkey('enter', (event: any): boolean => {
            if (event.target && event.target.className && event.target.className.includes('p-datatable-cell-editor')) {
                return false;
            }
            this.submit();
            return false;
        }, ['INPUT']);
        this.generalStepValidator = () => {
            return this.subsystemValidator.validateDetails(this.subsystem).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
        this.addressListStepValidator = () => {
            return this.subsystemValidator.validateAddresses(this.addresses).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
        this.addressStepValidator = () => {
            return this.subsystemValidator.validateAddress(this.subsystem, this.addresses, this.selectedCorrespondenceAddress,
                this.selectedDeliveryAddress).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.enterHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.enterHotkey);
    }

    handleError(err: HttpErrorResponse | Error): void {
        this.validationErrors = this.errors.handle(err);
        this.changeDetector.markForCheck();
    }

    public cancel() {
        this.onCancel.emit();
    }

    submit() {
        this.blockUiController.block(EditSubsystemComponent.SUBMIT_BLOCK_ID);

        if (!!this.subsystem.bulkAddonProfitMargin) {
            this.subsystem.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.subsystem.bulkAddonProfitMargin);
        }
        this.subsystemService.save(this.subsystem, this.addresses, this.selectedCorrespondenceAddress,
            this.selectedDeliveryAddress).pipe(
            mergeMap(subsystemId => {
                const observables = this.permissions.canEditSubsystem() ?
                    this.childTables.map(table => table.saveTable(subsystemId)) : [];
                return forkJoin(observables).pipe(
                    defaultIfEmpty(null),
                    map(() => subsystemId)
                );
            }),
            finalize(() => this.blockUiController.unblock(EditSubsystemComponent.SUBMIT_BLOCK_ID))).subscribe({
            next: () => this.onSave.emit(),
            error: error => this.handleError(error)
        });
    }

    getDialogHeader(): string {
        if (!this.disabledFields) {
            return 'SUBSYSTEM-DETAILS.EDIT-TITLE';
        }
        return 'SUBSYSTEM-DETAILS.VIEW-TITLE';
    }
}
