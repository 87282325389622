import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ColorInterface} from '../../../window-designer/catalog-data/color-interface';

@Component({
    selector: 'app-colors-selection',
    templateUrl: './colors-selection.component.html'
})
export class ColorsSelectionComponent {

    @Input() coreColorsHeader: string;
    @Input() coreColorRequired = true;
    @Input() showCoreColors = true;
    @Input() coreColorsDisabled = false;
    @Input() coreColorsOptions: SelectItem[] = [];
    @Input() coreColorsModel: ColorInterface[] = [];
    @Input() coreColorValidationMessage: string;

    @Input() insideColorsHeader: string;
    @Input() insideColorRequired = false;
    @Input() showInsideColors = true;
    @Input() insideColorsDisabled = false;
    @Input() insideColorsOptions: SelectItem[] = [];
    @Input() insideColorsModel: ColorInterface[] = [];
    @Input() insideColorValidationMessage: string;

    @Input() outsideColorsHeader: string;
    @Input() outsideColorRequired = false;
    @Input() showOutsideColors = true;
    @Input() outsideColorsDisabled = false;
    @Input() outsideColorsOptions: SelectItem[] = [];
    @Input() outsideColorsModel: ColorInterface[] = [];
    @Input() outsideColorValidationMessage: string;

    @Output() onCoreColorChange = new EventEmitter<ColorInterface[]>();
    @Output() onInsideColorChange = new EventEmitter<ColorInterface[]>();
    @Output() onOutsideColorChange = new EventEmitter<ColorInterface[]>();

    coreColorChanged() {
        this.onCoreColorChange.emit(this.coreColorsModel);
    }

    insideColorChanged() {
        this.onInsideColorChange.emit(this.insideColorsModel);
    }

    outsideColorChanged() {
        this.onOutsideColorChange.emit(this.outsideColorsModel);
    }
}
