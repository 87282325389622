import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataTableColumn} from '../../../common/service/data.table.column';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {AbstractSellDealerDiscountComponent} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component';
import {SubsystemSupplierSellDealerDiscount} from './subsystem-supplier-sell-dealer-discount';
import {SubsystemSupplierSellDealerDiscountService} from './subsystem-supplier-sell-dealer-discount.service';

@Component({
    selector: 'app-subsystem-supplier-sell-dealer-discount',
    templateUrl: '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.html',
    styleUrls: ['../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.css'],
    providers: [SubsystemSupplierSellDealerDiscountService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemSupplierSellDealerDiscountComponent
    extends AbstractSellDealerDiscountComponent<SubsystemSupplierSellDealerDiscount, SubsystemSupplierSellDealerDiscountService> {

    constructor(service: SubsystemSupplierSellDealerDiscountService,
                errorHandler: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(service, errorHandler, changeDetector);
    }

    getExtraColumns(): DataTableColumn[] {
        return DataTableColumnBuilder.create()
            .add('supplierName', 'SELL_DEALER_DISCOUNT.FORM.SUPPLIER_NAME').makeFilterable().setFilterMatchMode('contains')
            .build();
    }

    isGroupValueVisible(): boolean {
        return true;
    }
}
