import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {WindowSystemTypeData} from "../../../../../window-designer/catalog-data/window-system-interface";
import {ItemForCatalogLinking} from "../../single-system-checkbox-crud/item-for-catalog-linking";
import {ProductTypeGroup} from '../../window-system-definition/product-type-group';
import {GraspGlazingPackage} from "../grasp-glazing-package";

@Component({
    selector: 'app-grasp-glazing-package-systems-form',
    templateUrl: './grasp-glazing-package-systems-form.component.html',
    styleUrls: ['../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraspGlazingPackageSystemsFormComponent {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    @Input()
    item: GraspGlazingPackage;

    @Input()
    windowSystems: ItemForCatalogLinking[];

    @Input()
    linkedSystemIds: number[];

    @Input()
    disabled: boolean;

    @Output()
    linkedSystemIdsChange = new EventEmitter<number[]>();

    constructor(public changeDetector: ChangeDetectorRef) {
    }

    onSelectedSystemsChange(linkedSystemIds: number[]) {
        this.linkedSystemIdsChange.emit(linkedSystemIds);
        this.linkedSystemIds = linkedSystemIds;
        this.changeDetector.markForCheck();
    }

    predefinedGlazingFilter(ws: WindowSystemTypeData): boolean {
        return ws.predefinedGlazing;
    }
}
