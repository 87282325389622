import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {forkJoin, from, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import shortid from 'shortid';
import {fromError, StackFrame} from 'stacktrace-js';
import {environment} from '../../environments/environment';
import {ErrorResponse} from '../features/errors/errorResponse';

class ErrorReport {

    constructor(public errorId: string,
                public message: string,
                public stack: StackFrame[],
                public version: string,
                public commit: string,
                public pathname: string) {
    }
}

export class ResponseError extends Error {
    name: string;
    message: string;
    stack?: string;
    response: HttpErrorResponse;

    constructor(response: HttpErrorResponse) {
        let message = new ErrorResponse(response.error).message;
        super(message); // does not work, just here to shut up tsc
        Object.setPrototypeOf(this, ResponseError.prototype);
        // hack: extending Error does not work properly with es5 target - calling super() creates a new Error instead of adjusting 'this'
        this.name = 'ResponseError';
        this.message = message;
        if (this.message == null) {
            this.stack = JSON.stringify(response.error);
        } else {
            this.stack = new Error(message).stack;
        }
        this.response = response;
    }
}

interface SimpleVersion {
    version: string;
    commit: string;
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    private http: HttpClient;
    private router: Router;
    private zone: NgZone;

    constructor(injector: Injector) {
        super();
        // work around cyclic dependencies in angular
        setTimeout(() => {
            this.http = injector.get(HttpClient);
            this.router = injector.get(Router);
            this.zone = injector.get(NgZone);
        });
    }

    handleError(error: any): void {
        let errorInstance = this.extractError(error);
        let errorId = shortid.generate();
        if (errorInstance != undefined) {
            // log to console
            console.error(errorInstance);

            if (this.isBackendServerDown(errorInstance)) {
                this.redirectToMaintenancePage();
            } else {
                // and report to backend if we can
                forkJoin({stack: from(fromError(errorInstance)), version: this.getApplicationVersion()}).pipe(
                    mergeMap(data => {
                        return this.http.post<void>('errorReporting',
                            new ErrorReport(errorId, errorInstance.message, data.stack, data.version.version, data.version.commit, location.pathname));
                    })).subscribe({
                    complete: () => this.redirectToErrorPage(errorId),
                    error: (errorResponse: HttpErrorResponse) => {
                        console.error('FAILED TO REPORT ERROR TO SERVER',
                            errorResponse instanceof HttpErrorResponse ? errorResponse.error : errorResponse);
                        this.redirectToErrorPage(errorId);
                    }
                });
            }
        } else {
            console.error(error);
            this.redirectToErrorPage(errorId);
        }
    }

    private isBackendServerDown(error: any): boolean {
        let resp = error.rejection;
        if (resp instanceof HttpErrorResponse) {
            if (resp.status === 0) {
                return true;
            }
        }
        return false;
    }

    private extractError(obj: any): Error {
        if (obj.rejection && obj.rejection instanceof Error) {
            let innerError = this.extractError(obj.rejection);
            if (innerError != undefined) {
                return innerError;
            }
        }
        if (obj.originalError != undefined) {
            let innerError = this.extractError(obj.originalError);
            if (innerError != undefined) {
                return innerError;
            }
        }
        if (obj instanceof Error) {
            return obj;
        }
        return undefined;
    }

    private redirectToErrorPage(errorId: string): void {
        setTimeout(() => {
            this.zone.run(() => this.router.navigate(['general-error'], {
                queryParams: {errorId: errorId},
                skipLocationChange: true
            }));
        });
    }

    private redirectToMaintenancePage(): void {
        setTimeout(() => {
            this.zone.run(() => this.router.navigate(['maintenance-page'], {
                skipLocationChange: true
            }));
        });
    }

    private getApplicationVersion(): Observable<SimpleVersion> {
        let versionInfo: SimpleVersion = {version: environment.version, commit: undefined};
        return of(versionInfo);
    }
}
