<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_SALE.LIST' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_SALE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="windowSystemName" header="{{ 'WEBSHOP_SALE.FORM.WINDOW_SYSTEM_NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.windowSystemName[translate.currentLang]}}
            </ng-template>
        </p-column>
        <p-column field="rabate" header="{{ 'WEBSHOP_SALE.FORM.RABATE' | translate }}" [sortable]="true"></p-column>

        <p-column field="validFrom" header="{{ 'WEBSHOP_SALE.FORM.VALID_FROM' | translate }}"
                  [sortable]="true" [filter]="showFilters" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.validFrom | momentCurrentLang | amDateFormat: 'L' }}
            </ng-template>
        </p-column>

        <p-column field="validTo" header="{{ 'WEBSHOP_SALE.FORM.VALID_TO' | translate }}"
                  [sortable]="true" [filter]="showFilters" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.validTo | momentCurrentLang | amDateFormat: 'L' }}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_SALE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">

    <app-wizard-step label="{{ 'WEBSHOP_SALE.DATA' | translate }}" [validate]="VALIDATORS.DATA">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-select inputId="windowModel"
                            label="{{ 'WEBSHOP_SALE.FORM.WINDOW_SYSTEM_NAME' | translate }}"
                            [options]="availableSystems"
                            [ngModel]="item.windowSystemId"
                            (ngModelChange)="onModelChange($event)"
                            [(validationMessageKey)]="validationErrors['windowSystemId']"
                            [required]="true">
                </app-select>
            </div>

            <div class="new-form-row">
                <app-input-number inputId="rabate"
                                  label="{{ 'WEBSHOP_SALE.FORM.RABATE' | translate }}"
                                  [(validationMessageKey)]="validationErrors['rabate']" [min]="1" [max]="100" [step]="1"
                                  [(ngModel)]="item.rabate" [required]="true"></app-input-number>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="validFrom" label="{{ 'WEBSHOP_SALE.FORM.VALID_FROM' | translate }}"
                              [(validationMessageKey)]="validationErrors['validFrom']" [inline]="true" [utc]="true"
                              [maxDate]="maxValidFrom"
                              [required]="true" [ngModel]="item.validFrom" (ngModelChange)="setValidFrom($event)"></app-calendar>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="validTo" label="{{ 'WEBSHOP_SALE.FORM.VALID_TO' | translate }}"
                              [(validationMessageKey)]="validationErrors['validTo']" [inline]="true" [utc]="true"
                              [minDate]="minValidTo"
                              [required]="true" [ngModel]="item.validTo" (ngModelChange)="setValidTo($event)"></app-calendar>
            </div>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="displayDialog"
                   header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_SALE.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()"
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step [id]="STEPS.DATA" [validate]="VALIDATORS.DATA" label="{{ 'WEBSHOP_SALE.DATA' | translate }}">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-select inputId="windowSystem"
                            label="{{ 'WEBSHOP_SALE.FORM.WINDOW_SYSTEM_NAME' | translate }}"
                            [options]="availableSystems"
                            [ngModel]="item.windowSystemId"
                            (ngModelChange)="onModelChange($event)"
                            [(validationMessageKey)]="validationErrors['windowSystemId']"
                            [required]="true">
                </app-select>
            </div>

            <div class="new-form-row">
                <app-input-number inputId="rabate"
                                  label="{{ 'WEBSHOP_SALE.FORM.RABATE' | translate }}"
                                  [(validationMessageKey)]="validationErrors['rabate']" [min]="1" [max]="100" [step]="1"
                                  [(ngModel)]="item.rabate" [required]="true"></app-input-number>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="validFrom" label="{{ 'WEBSHOP_SALE.FORM.VALID_FROM' | translate }}"
                              [(validationMessageKey)]="validationErrors['validFrom']" [inline]="true" [utc]="true"
                              [maxDate]="maxValidFrom"
                              [required]="true" [ngModel]="item.validFrom" (ngModelChange)="setValidFrom($event)"></app-calendar>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="validTo" label="{{ 'WEBSHOP_SALE.FORM.VALID_TO' | translate }}"
                              [(validationMessageKey)]="validationErrors['validTo']" [inline]="true" [utc]="true"
                              [minDate]="minValidTo"
                              [required]="true" [ngModel]="item.validTo" (ngModelChange)="setValidTo($event)"></app-calendar>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
