<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.UPSELLING_PROPOSITIONS.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="upsellingPropositionTable">
        <p-column field="name" header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column field="sortIndex" header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.SORT_INDEX' | translate }}"
                  [sortable]="true"></p-column>
        <p-column field="active" header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.STATUS' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.' + (item.active ? 'ACTIVE' : 'INACTIVE') | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-upselling-proposition-form [item]="item" [validationErrors]="validationErrors" [file]="file"
                                        (fileChange)="onFileChange($event)"></app-upselling-proposition-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.CHARGES.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.CHARGES' | translate }}"
                     [validate]="STEPS.CHARGES.validate">
        <app-upselling-proposition-charges [item]="item" [validationErrors]="validationErrors"
                                           [allActiveCharges]="charges" [userLang]="userLang"></app-upselling-proposition-charges>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.GLAZINGS.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.GLAZINGS' | translate }}"
                     [validate]="STEPS.GLAZINGS.validate">
        <app-upselling-proposition-glazings [item]="item" [validationErrors]="validationErrors" [windowSystemNames]="systemNames"
                                           [allActiveGlazings]="glazings" [userLang]="userLang"></app-upselling-proposition-glazings>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.CONFIG_ADDONS.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.CONFIG_ADDONS' | translate }}"
                     [validate]="STEPS.CONFIG_ADDONS.validate">
        <app-upselling-proposition-config-addons [item]="item" [validationErrors]="validationErrors"
                                                 [allActiveConfigurableAddonUpsellingSettings]="configurableAddonUpsellingSettings"
                                                 [linkedWindowSystems]="configurableAddonLinkedWindowSystems"
                                                 [windowSystemNames]="systemNames"></app-upselling-proposition-config-addons>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-upselling-proposition-form [item]="item" [validationErrors]="validationErrors" [file]="file"
                                        (fileChange)="onFileChange($event)"></app-upselling-proposition-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.CHARGES.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.CHARGES' | translate }}"
                     [validate]="STEPS.CHARGES.validate">
        <app-upselling-proposition-charges [item]="item" [validationErrors]="validationErrors"
                                           [allActiveCharges]="charges" [userLang]="userLang"></app-upselling-proposition-charges>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.GLAZINGS.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.GLAZINGS' | translate }}"
                     [validate]="STEPS.GLAZINGS.validate">
        <app-upselling-proposition-glazings [item]="item" [validationErrors]="validationErrors" [windowSystemNames]="systemNames"
                                            [allActiveGlazings]="glazings" [userLang]="userLang"></app-upselling-proposition-glazings>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.CONFIG_ADDONS.id"
                     label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.STEPS.CONFIG_ADDONS' | translate }}"
                     [validate]="STEPS.CONFIG_ADDONS.validate">
        <app-upselling-proposition-config-addons [item]="item" [validationErrors]="validationErrors"
                                                 [allActiveConfigurableAddonUpsellingSettings]="configurableAddonUpsellingSettings"
                                                 [linkedWindowSystems]="configurableAddonLinkedWindowSystems"
                                                 [windowSystemNames]="systemNames"></app-upselling-proposition-config-addons>
    </app-wizard-step>
</app-wizard-dialog>
