<div class="hotkeys-help-container" [@fadeIn]="'' + helpVisible" style="display:none">
    <div class="hotkeys-help">
        <h4 class="hotkeys-help-title">{{ 'GENERAL.HOTKEYS.HELP_TITLE' | translate }}</h4>
        <table>
            <tbody>
            <tr *ngFor="let hotkeyPair of hotkeys">
                <ng-container *ngIf="hotkeyPair[0] != undefined">
                    <td class="hotkeys-help-keys">
                        <span *ngFor="let key of hotkeyPair[0].formatted" class="hotkeys-help-key">{{ key }}</span>
                    </td>
                    <td class="hotkeys-help-text">{{ getHotkeyDescription(hotkeyPair[0]) | translate }}</td>
                </ng-container>
                <ng-container *ngIf="hotkeyPair[1] != undefined">
                    <td class="hotkeys-help-keys">
                        <span *ngFor="let key of hotkeyPair[1].formatted" class="hotkeys-help-key">{{ key }}</span>
                    </td>
                    <td class="hotkeys-help-text">{{ getHotkeyDescription(hotkeyPair[1]) | translate }}</td>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </div>
</div>
