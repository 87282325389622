import {DistanceFrameInterface} from '../catalog-data/distance-frame-interface';
import {GlassInterface} from '../catalog-data/glass-interface';
import {GrillInterface} from '../catalog-data/grill-interface';
import {ProfileInterface} from '../catalog-data/profile-interface';
import {WindowSystemInterface} from '../catalog-data/window-system-interface';
import {DrawingData} from "../drawing-data/drawing-data";
import {DrawingUtil} from "../drawing-util";
import {ProfilesCompositionDistances} from "../profiles-composition-distances";
import {WindowCalculator} from "../window-calculator";
import {AreaUtils} from "./AreaUtils";
import {CutsUtil} from './cutUtils';
import {GrillSegmentIdGenerator} from './GrillSegmentIdGenerator';
import {GrillUtils} from './GrillUtils';
import {MullionUtils} from "./MullionUtils";
import {PositionsHelper} from "./positions-helper";

export class DrawingDataValidator {

    public static validateSinglePositionFailFast(data: DrawingData,
                                         glasses: GlassInterface[], profiles: ProfileInterface[], system: WindowSystemInterface,
                                         grills: GrillInterface[], frames: DistanceFrameInterface[]) {
        DrawingDataValidator.validatePosition(data, glasses, profiles, system, grills, frames);
    }

    public static validatePosition(data: DrawingData, glasses: GlassInterface[], profiles: ProfileInterface[],
                                   system: WindowSystemInterface,
                                   grills: GrillInterface[], frames: DistanceFrameInterface[], errorsArray?: string[]) {
        let returnOnFirstError = errorsArray == null;
        const profileCompositionDistances = new ProfilesCompositionDistances();
        profileCompositionDistances.prepareSystem(system);
        profileCompositionDistances.prepareProfileDistances(profiles, data.specification);
        let missingCompositionErrors = profileCompositionDistances.validateForErrors(data);
        if (missingCompositionErrors.length > 0) {
            if (returnOnFirstError) {
                throw new Error(missingCompositionErrors.toString());
            }
            missingCompositionErrors.forEach(error =>
                errorsArray.push(error)
            );
            return;
        }
        AreaUtils.setGlazingsESG(data, glasses);
        GrillSegmentIdGenerator.init(data);
        GrillUtils.initializeGrillsAngularity(data, grills);
        MullionUtils.repositionMullionsAndGrills(data, profileCompositionDistances, null, null, true);
        let totalBbox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        data.windows.forEach(window => {
            for (let subwindow of window.subWindows) {
                try {
                    let relevantCuts = data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
                    let totalInnerEdgePoints = WindowCalculator.getTotalFrameInnerEdgePointsFull(subwindow, relevantCuts, totalBbox,
                        profileCompositionDistances, false);
                    let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, data.cuts, totalBbox,
                        profileCompositionDistances, false);
                    MullionUtils.validateMullionPositions(subwindow, totalInnerEdgePoints, totalGlazingBeads.regular,
                        profileCompositionDistances, data.shape);
                    PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, profileCompositionDistances);
                } catch (e) {
                    if (returnOnFirstError) {
                        throw new Error(e.name);
                    }
                    errorsArray.push(e.name);
                }
            }
        });
    }
}
