<div [ngClass]="{ 'long-remaining': !showWarningColor,
                                    'short-remaining': showWarningColor }" class="container">
    <div class="time-component">
        <div class="time-value">{{ days }}</div>
        <div class="time-label">{{ 'COUNTDOWN.DAYS' | translate }}</div>
    </div>
    <div class="time-component">
        <div class="time-value">{{ hours }}</div>
        <div class="time-label">{{ 'COUNTDOWN.HOURS' | translate }}</div>
    </div>
    <div class="time-component">
        <div class="time-value">{{ minutes }}</div>
        <div class="time-label">{{ 'COUNTDOWN.MINUTES' | translate }}</div>
    </div>
    <div class="time-component">
        <div class="time-value">{{ seconds }}</div>
        <div class="time-label">{{ 'COUNTDOWN.SECONDS' | translate }}</div>
    </div>
</div>
