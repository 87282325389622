<div class="new-form-600">
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'PROMOTION_CODE.FORM.VALID_FROM' | translate }}"
                      [maxDate]="promotionCode.validTo" [(validationMessageKey)]="validationErrors['validFrom']"
                      [required]="true" [showTime]="true" [(ngModel)]="promotionCode.validFrom"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validTo" label="{{ 'PROMOTION_CODE.FORM.VALID_TO' | translate }}"
                      [minDate]="promotionCode.validFrom" [(validationMessageKey)]="validationErrors['validTo']"
                      [required]="true" [showTime]="true" [(ngModel)]="promotionCode.validTo"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="code" label="{{ 'PROMOTION_CODE.FORM.CODE' | translate }}"
                        [(validationMessageKey)]="validationErrors['code']" [maxlength]="100" [required]="true"
                        [(ngModel)]="promotionCode.code"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="value" label="{{ 'PROMOTION_CODE.FORM.VALUE' | translate }}"
                          [(validationMessageKey)]="validationErrors['value']" [min]="0" [max]="100" [required]="true"
                          [(ngModel)]="promotionCode.value"></app-input-number>
    </div>
</div>
