import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable} from "rxjs";
import {finalize, map} from 'rxjs/operators';
import {Permissions} from "../../../auth/permission.service";
import {StorageService} from "../../../auth/storage.service";
import {UserUiConfigService} from "../../../auth/uiconfig/userUiConfig.service";
import {ButtonWithMenuElementSelectedEvent} from "../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../common/button-with-menu/MenuType";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {ExchangeService} from "../../../common/exchange.service";
import {SavedFilterService} from "../../../common/saved-filter/saved-filter.service";
import {CrudComponent} from "../../../common/service/crud.component";
import {DataTableColumnBuilder, SavedShownColumns} from "../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../common/service/export.component";
import {Currencies} from "../../../currencies";
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {SubsystemService} from '../../subsystem/subsystem.service';
import {HasSavedFilter} from '../has-saved-filter';
import {PositionListComponent} from '../offers/position/position-list/position-list.component';
import {PrintableItem, PrintStatus} from '../print-dialog/print-dialog.component';
import {PrintableSection} from '../print-dialog/printable-section.enum';
import {ProductionOrderService} from "./production-order-service";
import {ProductionOrder} from "./ProductionOrder";
import {TemporaryDealerDiscount} from './TemporaryDealerDiscount';

@Component({
    selector: 'app-production-orders',
    templateUrl: './production-orders.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [ProductionOrderService, DataServiceHelper, ExportComponent, UserUiConfigService, ExchangeService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionOrdersComponent extends CrudComponent implements OnInit, OnDestroy, HasSavedFilter {

    readonly PRODUCTION_ORDERS_TABLE_ID = 'productionOrdersTable';

    private ignoreStoredFilter: boolean;
    filterByPaymentPackage: string = undefined;
    private showPageWarning = false;

    menuType = MenuType;

    private lastLoadEvent: LazyLoadEvent;
    selectedCurrency = Currencies.PLN;
    private selectedItems: ProductionOrder[] = [];
    printableItems: PrintableItem[] = [];
    selectedItem: ProductionOrder;
    allSelectedState = TristateCheckboxState.UNCHECKED;
    productionOrders: ProductionOrder[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    filterByCurrentUser: boolean;

    availableSubsystems: SelectItem[] = [];
    selectedSubsystems: string[] = [];

    showPrintButton = false;
    printDialogVisible = false;
    PrintableSection = PrintableSection;

    @ViewChild('dt', {static: true}) datatable: Table;
    filterByOfferNumber: string = undefined;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    private spaceHotkey: Hotkey;

    showChangeDealerDiscountDialog: boolean;
    showChangeSystemDealerDiscountDialog: boolean;
    showChangeAnnotationDialog: boolean;
    currentActionOrder: ProductionOrder;
    currentActionTemporaryDealerDiscount: TemporaryDealerDiscount;

    constructor(public permissions: Permissions,
                public router: Router,
                private route: ActivatedRoute,
                public productionOrderService: ProductionOrderService,
                private subsystemService: SubsystemService,
                private exchangeService: ExchangeService,
                public storage: StorageService,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'ProductionOrdersComponent', false);
        this.langTranslateSubscription.push(this.translate.onLangChange.subscribe(() => {
            this.loadItemsLazy(this.lastLoadEvent);
        }));
        this.spaceHotkey = new Hotkey('space', (): boolean => {
            this.changeSelectionOfItem();
            return false;
        });
    }

    getDatatable(): TableToDatatableInterfaceAdapter {
        return TableToDatatableInterfaceAdapter.create(this.datatable);
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        if (this.filterByOfferNumber != undefined && shownColumns != undefined && shownColumns.offerNumbers == undefined) {
            shownColumns.offerNumbers = {};
        }
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns);

        builder
            .add('offerNumbers', 'OFFER.PRODUCTION_ORDERS.OFFER_NUMBER', true)
            .add('orderNumber', 'OFFER.PRODUCTION_ORDERS.PRODUCTION_ORDER_NUMBER', true)
            .add('creationDate', 'OFFER.PRODUCTION_ORDERS.CREATION_DATE', true).setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('paymentPackageName', 'OFFER.PRODUCTION_ORDERS.PAYMENT_PACKAGE_NAME', true)
            .add('paletteName', 'OFFER.PRODUCTION_ORDERS.PALETTE_NAME', true)
            .add('supplierCompanyName', 'OFFER.PRODUCTION_ORDERS.SUPPLIER_NAME', true)
            .add('subsystemName', 'OFFER.PRODUCTION_ORDERS.SUBSYSTEM', true)
            .add('clientManagerName', 'OFFER.PRODUCTION_ORDERS.CLIENT_MANAGER', true)
            .add('subClientManagerName', 'OFFER.PRODUCTION_ORDERS.SUB_CLIENT_MANAGER', false)
            .add('venskaBuyValue.netValue', 'OFFER.PRODUCTION_ORDERS.VENSKA_BUY_NET', true)
            .add('venskaBuyValue.grossValue', 'OFFER.PRODUCTION_ORDERS.VENSKA_BUY_GROSS', true)
            .add('buyValue.netValue', 'OFFER.PRODUCTION_ORDERS.BUY_NET', true)
            .add('buyValue.grossValue', 'OFFER.PRODUCTION_ORDERS.BUY_GROSS', true);

        super.init(builder.build());
        this.saveShownColumns();
    }

    private resetDefaultFilterSelection() {
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        } else {
            if (this.filterByPaymentPackage) {
                this.filterByCurrentUser = false;
            } else if (this.filterByOfferNumber) {
                if (!this.columnByField['offerNumbers']) {
                    this.onDisplayedColumnsChange([...this.selectedColumns, 'offerNumbers']);
                }
                this.filterByCurrentUser = false;
                this.lastLoadEvent = {};
                let filter = {filters: {offerNumbers: {value: this.filterByOfferNumber}}};
                this.applySavedFilter(filter);
            }
        }
    }

    ngOnInit() {
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.filterByPaymentPackage = this.route.snapshot.queryParamMap.get('paymentPackageId');
        this.showPageWarning = this.route.snapshot.paramMap.has('showPageWarning');
        this.filterByOfferNumber = this.route.snapshot.queryParamMap.get('offerNumber');
        super.ngOnInit();
        this.createTable();
        this.resetDefaultFilterSelection();
        this.initLastSelectedItem();
        this.initFilterByCurrentUser();
        forkJoin({
            exchangeRates: this.exchangeService.initializeExchangeRates(),
            subsystems: this.subsystemService.getSelectionItems()
        }).subscribe(data => {
            this.exchangeService.storeExchangeRates(data.exchangeRates);
            this.availableSubsystems = data.subsystems;
            this.hotkeysService.add(this.spaceHotkey);
        });
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.spaceHotkey);
        super.ngOnDestroy();
    }

    private changeSelectionOfItem() {
        if (this.selectedItem) {
            this.selectItem(this.selectedItem);
        }
    }

    getExportData(): Observable<object[]> {
        return this.productionOrderService.getItems(0, null, this.lastLoadEvent.filters, this.lastLoadEvent.sortField,
            this.lastLoadEvent.sortOrder).pipe(
            map(list => list.data));
    }

    removeFiltersNotSetOnTableColumns(): void {
        this.filterByCurrentUser = false;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    enableFilterByCurrentUser(): void {
        this.filterByCurrentUser = true;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    disableFilterByPaymentPackageId(): void {
        this.filterByPaymentPackage = undefined;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    private initFilterByCurrentUser(): void {
        if (this.filterByCurrentUser == undefined) {
            this.filterByCurrentUser = true;
        }
        if (this.route.snapshot.paramMap.has('forCurrentUser')) {
            this.filterByCurrentUser = this.route.snapshot.paramMap.get('forCurrentUser') === 'true';
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.datatable.filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    private filterPaymentPackage(event: LazyLoadEvent): void {
        event.filters['paymentPackageId'] = {value: this.filterByPaymentPackage, matchMode: undefined};
    }

    private filterCurrentUser(event: LazyLoadEvent): void {
        event.filters['forCurrentUser'] = {value: this.filterByCurrentUser, matchMode: undefined};
    }

    private filterExchangeRate(event: LazyLoadEvent): void {
        this.exchangeService.applyRateToRangeFilters(['venskaBuyValue.netValue', 'venskaBuyValue.grossValue', 'buyValue.netValue',
            'buyValue.grossValue'], event, this.selectedCurrency);
    }

    getPriceInSelectedCurrency(price: number): string {
        return this.exchangeService.getPLNPriceInCurrency(price, this.selectedCurrency);
    }

    redirectToPositionList(productionOrder: ProductionOrder): void {
        const activeFilters = this.lastLoadEvent.filters;
        let queryParams;
        if (activeFilters.hasOwnProperty('offerNumbers')) {
            queryParams = {[PositionListComponent.BACK_TO_FILTERED]: this.lastLoadEvent.filters['offerNumbers'].value};
        }
        this.router.navigate(['/features', productionOrder.id, 'productionOrderPosition'], {queryParams});
    }

    rowTrackById(index: number, item: ProductionOrder) {
        return item.id;
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true) {
        super.loadItemsLazy(event);
        this.filterCurrentUser(event);
        this.storeLazyLoadEvent(event, this.storage);
        this.filterPaymentPackage(event);
        this.filterExchangeRate(event);
        if (event.origin == undefined) {
            event.origin = this.getDatatable();
        }
        this.lastLoadEvent = event;
        this.onDataLazyLoad.emit(this.lastLoadEvent);
        if (updateOriginalTableFilters) {
            this.onUpdateOriginalFilters.emit(this.lastLoadEvent);
        }
        this.allSelectedState = TristateCheckboxState.UNCHECKED;
        this.selectedItems = [];
        this.handlePrintButtonState();
        return this.productionOrderService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.productionOrders = data.data;
                    this.totalRecords = data.totalRecords;
                    this.totalRecordsChange.emit(this.totalRecords);
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    const selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.productionOrders, event);
                    if (this.showPageWarning && this.selectedItem != undefined && this.selectedItem.id != undefined
                        && selectedItem != undefined && this.selectedItem.id !== selectedItem.id) {
                        this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.LIST.SELECTION_ON_OTHER_PAGE');
                    }
                    this.showPageWarning = false;
                    this.selectedItem = selectedItem;
                },
                error: error => {
                    console.error('ProductionOrdersComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('ProductionOrdersComponent getPage completed');
                    this.changeDetector.markForCheck();
                }
            });
    }

    private initLastSelectedItem(): void {
        if (this.route.snapshot.paramMap.has('lastSelection')) {
            this.selectedItem = new ProductionOrder();
            this.selectedItem.id = +this.route.snapshot.paramMap.get('lastSelection');
        }
    }

    selectAllChange() {
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.productionOrders);
        }
        this.handlePrintButtonState();

        this.changeDetector.markForCheck();
    }

    isSelectedItem(item: ProductionOrder): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: ProductionOrder) {
        let index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }
        this.handlePrintButtonState();

        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.productionOrders.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    showDialogToAdd() {
        console.log("showDialogToAdd not implemented yet");
    }

    submit() {
        console.log("submit not implemented yet");
    }

    onRowSelect(event) {
        console.log("onRowSelect not implemented yet");
    }

    private getOrderSuffix(order: ProductionOrder) {
        return '___' + order.id;
    }

    buildRowActions(productionOrder: ProductionOrder): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let orderSuffix: string = this.getOrderSuffix(productionOrder);

        if (productionOrder.correctionId == undefined && !productionOrder.orderCanceled) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_DEALER_DISCOUNT')
                .setIdentifier('CHANGE_DEALER_DISCOUNT' + orderSuffix)
                .build());

            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_SYSTEM_DEALER_DISCOUNT')
                .setIdentifier('CHANGE_SYSTEM_DEALER_DISCOUNT' + orderSuffix)
                .build());
        }

        rowActions.push(new MenuElementBuilder()
            .setTranslationKey('OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.TITLE')
            .setIdentifier('CHANGE_ANNOTATIONS' + orderSuffix)
            .build());

        return rowActions;
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent) {
        let data = event.identifier.split('___');
        let action = data[0];

        let orderId = +data[1];
        let order = this.productionOrders.find(productionOrder => productionOrder.id === orderId);

        if (order) {
            switch (action) {
                case 'CHANGE_DEALER_DISCOUNT':
                    this.openChangeDealerDiscountDialog(order);
                    break;
                case 'CHANGE_SYSTEM_DEALER_DISCOUNT':
                    this.openChangeSystemDealerDiscountDialog(order);
                    break;
                case 'CHANGE_ANNOTATIONS':
                    this.openChangeAnnotationsDialog(order);
                    break;
            }
        } else {
            console.error("Action called for non existing positionId " + orderId);
        }
    }

    private handlePrintButtonState() {
        this.showPrintButton = this.selectedItems.length > 0 && this.selectedItems.length <= 20;
    }

    openChangeDealerDiscountDialog(productionOrder: ProductionOrder): void {
        this.currentActionOrder = productionOrder;
        this.setShowChangeDealerDiscountDialog(true);
    }

    openChangeSystemDealerDiscountDialog(productionOrder: ProductionOrder): void {
        this.currentActionOrder = productionOrder;
        this.setShowChangeSystemDealerDiscountDialog(true);
    }

    openChangeAnnotationsDialog(productionOrder: ProductionOrder) {
        this.currentActionOrder = productionOrder;
        this.setShowChangeAnnotationsDialog(true);
    }

    private setShowChangeAnnotationsDialog(visible: boolean) {
        this.showChangeAnnotationDialog = visible;
        this.changeDetector.markForCheck();
    }

    private setShowChangeDealerDiscountDialog(visible: boolean) {
        this.currentActionTemporaryDealerDiscount = {...this.currentActionOrder.temporaryDiscount};
        this.showChangeDealerDiscountDialog = visible;
        this.changeDetector.markForCheck();
    }

    private setShowChangeSystemDealerDiscountDialog(visible: boolean) {
        this.showChangeSystemDealerDiscountDialog = visible;
        this.changeDetector.markForCheck();
    }

    handleChangeDiscountSave() {
        this.setShowChangeDealerDiscountDialog(false);
        this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.CHANGE_DEALER_DISCOUNT.SUCCESS');
        this.currentActionOrder = undefined;
        this.currentActionTemporaryDealerDiscount = undefined;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    handleChangeAnnotationsSave() {
        this.setShowChangeAnnotationsDialog(false);
        this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.SUCCESS');
        this.currentActionOrder = undefined;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    handleChangeDiscountError(error) {
        this.setShowChangeDealerDiscountDialog(false);
        this.currentActionOrder = undefined;
        this.currentActionTemporaryDealerDiscount = undefined;
        console.error(error);
        this.errors.handle(error);
    }

    handleChangeDiscountCancel() {
        this.setShowChangeDealerDiscountDialog(false);
        this.currentActionOrder = undefined;
        this.currentActionTemporaryDealerDiscount = undefined;
    }

    handleChangeAnnotationsCancel() {
        this.setShowChangeAnnotationsDialog(false);
        this.currentActionOrder = undefined;
    }

    handleChangeSystemDealerDiscountDialogClose(saved: boolean) {
        this.setShowChangeSystemDealerDiscountDialog(false);
        this.currentActionOrder = undefined;
        if (saved) {
            this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.CHANGE_SYSTEM_DEALER_DISCOUNT.SUCCESS');
            this.loadItemsLazy(this.lastLoadEvent);
        }
    }

    postionHasTemporaryDealerDiscount(item: ProductionOrder): boolean {
        return (item.temporaryDiscount != undefined && item.temporaryDiscount.discountValue != undefined)
            || item.hasTemporaryWindowSystemDealerDiscount;
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().resetFilters();
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        // delete legacy filter
        delete filter.filters.subsystemName;

        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    private applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['forCurrentUser'];
        if (currentUserFilter != undefined) {
            this.filterByCurrentUser = '' + currentUserFilter.value === 'true';
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    public openPrintDialog() {
        this.printableItems = this.selectedItems.map(item => new PrintableItem(item.id, item.orderNumber));
        this.printDialogVisible = true;
    }

    handlePrintDialogClose(status: PrintStatus) {
        if (status === PrintStatus.PRINTED) {
            this.selectedItems.forEach(item => item.printed = true);
        }
        this.printDialogVisible = false;
        this.changeDetector.markForCheck();
    }
}
