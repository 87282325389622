import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {FilterMetadata, FilterService} from 'primeng/api';
import {Table} from 'primeng/table';
import {DatatableHelper} from '../DatatableHelper';
import {DateRangeFilter} from '../date-range-filter';
import {TranslatedSelectItemService} from '../service/translated-select-item.service';
import {AbstractDateRangeFilterComponent} from './abstract-date-range-filter.component';

@Component({
    selector: '[app-table-date-range-filter]',
    templateUrl: './date-range-filter.component.html',
    styleUrls: ['./date-range-filter.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDateRangeFilterComponent extends AbstractDateRangeFilterComponent {

    // tslint:disable-next-line:no-input-rename
    @Input('app-table-date-range-filter')
    field: string;

    constructor(translatedSelectItemService: TranslatedSelectItemService,
                changeDetector: ChangeDetectorRef,
                private readonly table: Table,
                filterService: FilterService) {
        super(translatedSelectItemService, changeDetector);

        // patch datatable with dateRange filtering capability
        DatatableHelper.initializeDateRangeFiltering(filterService);
    }

    get hostElement(): ElementRef {
        return this.table.el;
    }

    get filter(): FilterMetadata | FilterMetadata[] {
        return this.table.filters[this.field];
    }

    doFilter(filter: DateRangeFilter): void {
        this.table.filter(filter, this.field, 'dateRange');
    }
}
