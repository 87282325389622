import {ChangeDetectorRef, Directive, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'underscore';
import {Permissions} from '../../../auth/permission.service';
import {PositionMessage} from '../../offer/offers/message';

export class PricingTableDataRow {
    [spot: number]: string; // at index 0 stands 'codes' string

    constructor(values: any[]) {
        values.forEach((value, index) => this[index] = value);
    }
}

@Directive()
export abstract class PricingTableComponent implements OnInit {

    @Output() readonly close = new EventEmitter();
    data: PricingTableDataRow[] = [];
    error: PositionMessage;
    startCols: number;

    activeCellValid = true;
    isNew: boolean;
    columns: number[] = [];

    protected constructor(public permissions: Permissions,
                public translate: TranslateService,
                public changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.init();
    }

    protected init() {
        this.data = this.prepareData();
        const maxColumns = !this.isNew ? this.getMaxColumnsInTable() : 5;
        this.startCols = maxColumns;
        this.buildColumns(maxColumns);
        this.changeDetector.markForCheck();
    }

    protected abstract prepareData(): PricingTableDataRow[];

    protected abstract setErrorCode(row: number, column: number): string;

    protected getMaxColumnsInTable(): number {
        return this.data.reduce((max, row) => {
            const maxSpotsInRow: number = +_.max(Object.keys(row).map(key => +key));
            return maxSpotsInRow > max ? maxSpotsInRow : max;
        }, 0);
    }

    private buildColumns(colsNum: number) {
        this.columns = _.range(0, colsNum + 1);
    }

    cancel() {
        this.close.emit();
    }
}
