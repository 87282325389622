<p-dialog [header]="previewData?.header" [resizable]="false" [modal]="true" [(visible)]="visible"
          [focusOnShow]="false" appendTo="body" [style]="{ 'width.px': 400 }">
    <img #imageElement [src]="imagePreview" />
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
