import {GrillType} from "./GrillType";
import {GenericGrillGrid} from "./GenericGrillGrid";

export class CrossGrillAngled extends GenericGrillGrid {
    type: GrillType.GRID_CROSS_ANGLED;

    constructor() {
        super(GrillType.GRID_CROSS_ANGLED);
    }
}
