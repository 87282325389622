import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ItemForCatalogLinking} from '../../window-system/single-system-checkbox-crud/item-for-catalog-linking';
import {WindowSystemDefinitionService} from '../../window-system/window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {ConfigSystemUpsellingSettings} from './config-system-upselling-settings';
import {ConfigSystemUpsellingSettingsService} from './config-system-upselling-settings.service';
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";

@Component({
    selector: 'app-configurable-addon-upselling-settings',
    templateUrl: './configurable-addon-upselling-settings.component.html',
    providers: [ConfigSystemUpsellingSettingsService, ConfigSystemService, TranslatedSelectItemService,
        WindowSystemDefinitionService]
})
export class ConfigurableAddonUpsellingSettingsComponent
    extends CrudCommonComponent<ConfigSystemUpsellingSettings, ConfigSystemUpsellingSettingsService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateData()
        },
        SYSTEMS: {
            id: 'SYSTEMS',
            validate: () => of(true)
        }
    };

    private readonly configSystemService: ConfigSystemService;
    private readonly windowSystemDefinitionService: WindowSystemDefinitionService;

    configSystems: ItemForCatalogLinking[];

    windowSystems: ItemForCatalogLinking[];
    selectedWindowSystems: number[];

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, ConfigSystemUpsellingSettingsService, 'ADMIN_PANEL.UPSELLING_SETTINGS', 'UpsellingSettings');
        this.configSystemService = injector.get(ConfigSystemService);
        this.windowSystemDefinitionService = injector.get(WindowSystemDefinitionService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        forkJoin({
            configSystems: this.configSystemService.getConfigAddonsForCatalogLinking(),
            windowSystems: this.windowSystemDefinitionService.getSystemsForCatalogLinking()
        }).subscribe(data => {
            this.configSystems = data.configSystems;
            this.windowSystems = data.windowSystems;
            this.changeDetector.markForCheck();
        });
    }

    getNewItem(): ConfigSystemUpsellingSettings {
        return new ConfigSystemUpsellingSettings();
    }

    loadEditedItem(event: { data: ConfigSystemUpsellingSettings }): Observable<any> {
        return forkJoin({
            item: this.itemService.getItem(event.data.id),
            linkedSystems: this.itemService.getLinkedSystems(event.data.id)
        }).pipe(map(data => {
            this.selectedWindowSystems = data.linkedSystems;
            return data.item;
        }));
    }

    protected validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);
        return this.validateData();
    }

    private validateData(): Observable<boolean> {
        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validateData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            let saveObservable: Observable<number>;
            if (this.itemId != undefined) {
                saveObservable = this.itemService.editItem(this.itemId, this.item);
            } else {
                saveObservable = this.itemService.addItem(this.item);
            }
            saveObservable
                .pipe(mergeMap(upsellingSettingsId => {
                    return this.itemService.editLinks(upsellingSettingsId, this.selectedWindowSystems)
                        .pipe(map(() => upsellingSettingsId));
                }))
                .subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }
}
