import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Permissions} from "../../auth/permission.service";
import {NavigationLink} from "../../NavigationLink";
import {SidenavController} from '../../sidenav-controller';

type AdminPanelOption = 'role' | 'lastActivityTime' | 'lastLoginTime' | 'userConsents' | 'errorBrowser' | 'redrawWindows'
    | 'scheduledJobs' | 'offerSupplierCounter' | 'offerSubsystemCounter' | 'subsystemNotesCounter'
    | 'configurableAddonUpsellingSettings' | 'applicationStatus'
    | 'windowUpsellingSettings' | 'upsellingPropositions' | 'applicationResources' | 'glazingUpsellingSettings' | 'markProducts'
    | 'editPermissions' | 'applicationInfo';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['../../second-level-menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPanelComponent implements OnInit, OnDestroy {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItems: NavigationLink<AdminPanelOption>[];
    upsellingNavItems: NavigationLink<AdminPanelOption>[];

    constructor(private permissions: Permissions,
                private sidenavController: SidenavController,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.prepareSideNavItems();
        this.activatedRoute.url.subscribe(url => {
            if (!this.activatedRoute.firstChild) {
                this.router.navigate(['.', this.sideNavItems[0].cssClass], {relativeTo: this.activatedRoute, replaceUrl: true});
            }
        });
        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.changeDetector.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this.showMenuSubscription != undefined) {
            this.showMenuSubscription.unsubscribe();
        }
    }

    prepareSideNavItems() {
        this.sideNavItems = [];
        this.sideNavItems.push(new NavigationLink('', 'role', "", {roles: ['ROLE_EDIT_ROLE', 'ROLE_READ_ROLE']}, 'ADMIN_PANEL.ROLE.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "scheduledJobs", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.SCHEDULED_JOB.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "lastActivityTime", "", {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}, 'ADMIN_PANEL.LAST_ACTIVITY_TIMES'));
        this.sideNavItems.push(new NavigationLink('', "lastLoginTime", "", {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}, 'ADMIN_PANEL.LAST_LOGIN_TIMES'));
        this.sideNavItems.push(new NavigationLink('', "userConsents", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.USER_CONSENTS'));
        this.sideNavItems.push(new NavigationLink('', "errorBrowser", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.ERROR_BROWSER.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "offerSupplierCounter", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.OFFER_SUPPLIER_COUNTER.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "offerSubsystemCounter", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "subsystemNotesCounter", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.SUBSYSTEM_NOTES_COUNTER.TITLE'));
        this.sideNavItems.push(new NavigationLink('', "applicationResources", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.APPLICATION_RESOURCE.TITLE'));
        this.sideNavItems.push(new NavigationLink('', 'applicationStatus', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.MAINTENANCE_SETTINGS.GENERAL'));
        this.sideNavItems.push(new NavigationLink('', 'redrawWindows', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.REDRAW_WINDOWS.GENERAL'));
        this.sideNavItems.push(new NavigationLink('', 'markProducts', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.MARK_PRODUCTS.GENERAL'));
        this.sideNavItems.push(new NavigationLink('', 'editPermissions', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.EDIT_PERMISSIONS.GENERAL'));
        this.sideNavItems.push(new NavigationLink('', 'applicationInfo', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.APPLICATION-INFO.GENERAL'));

        this.sideNavItems = this.sideNavItems.filter((item) => {
            return this.permissions.isPermitted(item.roles);
        });

        this.upsellingNavItems = [];
        this.upsellingNavItems.push(new NavigationLink('', "configurableAddonUpsellingSettings", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.UPSELLING_SETTINGS.TITLE'));
        this.upsellingNavItems.push(new NavigationLink('', "windowUpsellingSettings", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.WINDOW_UPSELLING_SETTINGS.LIST'));
        this.upsellingNavItems.push(new NavigationLink('', "glazingUpsellingSettings", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.GLAZING_UPSELLING_SETTINGS.LIST'));
        this.upsellingNavItems.push(new NavigationLink('', "upsellingPropositions", "", {roles: ['ROLE_KOORDYNATOR']}, 'ADMIN_PANEL.UPSELLING_PROPOSITIONS.TITLE'));

        this.upsellingNavItems = this.upsellingNavItems.filter((item) => {
            return this.permissions.isPermitted(item.roles);
        });
    }
}
