import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

export interface CrudItem {
    id: number;
}

export interface ListingItem<T> {
    fromJSON(jsonObject: any): T;
}

// This class decorator forces implementing static fromJSON
export function JsonDeserializable<T>(constructor: ListingItem<T>) {
}

export function mapItemToJson<R>(itemType: ListingItem<R>): OperatorFunction<object, R | undefined> {
    return map((obj: object | undefined) => obj != undefined ? itemType.fromJSON(obj) : undefined);
}

export function mapItemArrayToJson<R>(itemType: ListingItem<R>): OperatorFunction<object[], R[] | undefined> {
    return map((obj: object[] | undefined) => obj != undefined ? obj.map(itemType.fromJSON) : undefined);
}
