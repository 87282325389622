<div class="new-form-600">

    <app-translation-fields *ngIf="fieldUsage.show(AddonCategoryGroupField.NAME)"
                            inputId="name" label="{{ 'ADDON_CATEGORY_GROUP.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"
                            [disabled]="fieldUsage.disabled(AddonCategoryGroupField.NAME)"></app-translation-fields>

    <ng-container *ngIf="fieldUsage.show(AddonCategoryGroupField.SORT_INDEX)">
        <div class="new-form-row">
            <app-input-number [disabled]="fieldUsage.disabled(AddonCategoryGroupField.SORT_INDEX)"
                              label="{{ 'ADDON_CATEGORY_GROUP.FORM.SORT_INDEX' | translate }}"
                              [allowOnlyIntegers]="true"
                              [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                              [(ngModel)]="item.sortIndex" [required]="true"
                              inputId="sortIndex"></app-input-number>
        </div>

        <div *ngIf="fieldUsage.show(AddonCategoryGroupField.CATEGORIES)" class="new-form-row">
            <p-accordion>
                <p-accordionTab header="{{ 'ADDON_CATEGORY_GROUP.FORM.CATEGORIES' | translate }}" [selected]="true">
                    <p-listbox id="categories" [options]="getCategories()" [multiple]="true" [disabled]="true"></p-listbox>
                </p-accordionTab>
            </p-accordion>
        </div>
    </ng-container>
</div>
