<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(SealField.NAME)"
                            inputId="name" label="{{ 'SEAL.FORM.NAME' | translate }}" validationKeyPrefix="names"
                            [disabled]="fieldUsage.disabled(SealField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.names"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(SealField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'SEAL.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(SealField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(SealField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol"
                        label="{{ 'SEAL.FORM.SYMBOL' | translate }}"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [disabled]="fieldUsage.disabled(SealField.SYMBOL)"
                        [maxlength]="50" [required]="true"
                        [(ngModel)]="item.symbol">
        </app-input-text>
    </div>
    <div *ngIf="fieldUsage.show(SealField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active"
                      label="{{ 'SEAL.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(SealField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(SealField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'SEAL.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(SealField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>
</div>
