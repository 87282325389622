import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../common/validation-errors';
import {Material} from '../material';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {MaterialColorImages} from "./material-color-images";
import {SelectItemFormatters} from "../../../offer/window-editor/sidebar/select-item-formatters";
import {TranslateService} from "@ngx-translate/core";
import {CatalogItemNameAndActive} from "../../../../common/crud-common/catalog-item-name-and-active";
import {MaterialService} from "../material.service";
import {DataServiceHelper, FileState} from "../../../../common/dataServiceHelper";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {finalize} from "rxjs/operators";
import {ValidationErrorsHelper} from "../../../../common/ValidationErrorsHelper";
import {OnceFlag} from "../../../../shared/once-flag";
import {ListboxSelectionItem} from "../../../../common/listbox-selection/listbox-selection.component";

@Component({
    selector: 'app-material-color-images-form',
    templateUrl: './material-color-images-form.component.html',
    styleUrls: ['./material-color-images-form.css'],
    providers: [MaterialService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialColorImagesFormComponent implements OnInit {

    public static readonly PROCESSING = 'MaterialColorImagesFormComponent processing';

    @Input()
    material: Material;

    @Input()
    colors: CatalogItemNameAndActive[] = [];

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    onClose = new EventEmitter<void>();

    private readonly dialogHideHelper = new OnceFlag();

    inputSelectedMap: Map<number, string> = new Map<number, string>();
    inputSelectedMapHexes: Map<number, string> = new Map<number, string>();
    validationErrors: ValidationErrors = {};

    selectItemFormatters: SelectItemFormatters;
    dataInitialized = false;

    allColorsOptions: SelectItem[] = [];
    availableColorsOptions: SelectItem[] = [];
    selectedNewColor: ListboxSelectionItem;
    selectedFile: File;

    hex: string;

    constructor(
        public translate: TranslateService,
        private materialService: MaterialService,
        private changeDetector: ChangeDetectorRef,
        private blockUiController: BlockUiController
    ) {
        this.selectItemFormatters = new SelectItemFormatters(this.translate);
    }

    ngOnInit(): void {
        this.blockUiController.block(MaterialColorImagesFormComponent.PROCESSING);
        this.materialService.getColorLinksForMaterials([this.material.id])
            .pipe(finalize(() => this.blockUiController.unblock(MaterialColorImagesFormComponent.PROCESSING)))
            .subscribe(materialColorLinks => {
                    materialColorLinks.forEach(link => {
                        this.inputSelectedMap.set(link.colorId, link.image);
                        this.inputSelectedMapHexes.set(link.colorId, link.hex);
                    });
                    this.allColorsOptions = this.colors
                        .map(color => this.selectItemFormatters.formatNameAndActiveOption(color));
                    this.filterAvailableColorOptions();
                    this.dataInitialized = true;
                    this.changeDetector.markForCheck();
                }
            );
    }

    trackByColorId = (index: number, colorId: number) => {
        return colorId;
    }

    private filterAvailableColorOptions(): void {
        let linkedColors = Array.from(this.inputSelectedMap.keys());
        this.availableColorsOptions = this.allColorsOptions
            .filter(option => option.available)
            .filter(option => !linkedColors.includes(option.value))
            .map(option => ({label: option.label, value: new ListboxSelectionItem(option.value, [option.label])}));
    }

    remove(colorId: number) {
        this.blockUiController.block(MaterialColorImagesFormComponent.PROCESSING);
        this.materialService.unlinkColorFromMaterial(this.material.id, colorId)
            .pipe(finalize(() => this.blockUiController.unblock(MaterialColorImagesFormComponent.PROCESSING)))
            .subscribe(
                () => {
                    this.inputSelectedMap.delete(colorId);
                    this.inputSelectedMapHexes.delete(colorId);
                    this.filterAvailableColorOptions();
                    this.changeDetector.markForCheck();
                }
            );
    }

    add() {
        if (!this.validate()) {
            return;
        }
        let item = new MaterialColorImages();
        item.materialId = this.material.id;
        item.colorId = this.selectedNewColor.id;
        if (this.hex != null) {
            item.hex = this.hex.trim();
        }
        let file: FileState = this.selectedFile == null ? null : {file: this.selectedFile, needSave: true};
        this.blockUiController.block(MaterialColorImagesFormComponent.PROCESSING);
        this.materialService.linkColorWithMaterial(item, file)
            .pipe(finalize(() => this.blockUiController.unblock(MaterialColorImagesFormComponent.PROCESSING)))
            .subscribe(
                compressedImage => {
                    this.selectedNewColor = null;
                    this.selectedFile = null;
                    this.clearErrors();
                    this.inputSelectedMap.set(item.colorId, compressedImage);
                    this.inputSelectedMapHexes.set(item.colorId, this.hex);
                    this.filterAvailableColorOptions();
                    this.hex = null;
                    this.changeDetector.markForCheck();
                }
            );
    }

    handleColorChange(selectedItem: ListboxSelectionItem) {
        let foundColor = this.availableColorsOptions.find(color => color.value.id === selectedItem.id).value;
        this.selectedNewColor = foundColor;
        this.clearErrors();
    }

    onImageChange(file: File) {
        this.selectedFile = file;
        this.clearErrors();
    }

    validate(): boolean {
        if (this.selectedFile == null && (this.hex == null || this.hex.trim() === '')) {
            this.validationErrors['file'] = 'error.fileUpload.not_null';
        }
        if (this.selectedNewColor == null) {
            this.validationErrors['color'] = 'error.materialColorImages.color.not_null';
        }
        if (this.hex != null && !/^#[0-9A-Fa-f]{6}$/.test(this.hex.trim())) {
            this.validationErrors['hex'] = 'error.materialColorImages.hex.pattern_not_matched';
        }
        return !this.containsErrors();
    }

    containsErrors() {
        return ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    clearErrors() {
        this.validationErrors = {};
    }

    getAddedColorsKeys(): number[] {
        return Array.from(this.inputSelectedMap.keys()).reverse();
    }

    closeDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }
}
