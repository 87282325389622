import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {JsonDeserializable} from '../../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../../supportedLanguages';
import {ConfigEditorField} from '../../../offer/config-editor/config-editor-field';
import {
    PositionDesignerCatalogDependentOption,
    WindowDesignerCatalogDependentOptionAction
} from '../../window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option';

@JsonDeserializable
export class ConfigDesignerCatalogDependentOption implements PositionDesignerCatalogDependentOption<ConfigEditorField | string> {
    id: number;
    configSystemId: number;
    configSystemName: MultilanguageFieldInterface;
    requiredInputId: ConfigEditorField | string;
    requiredInputValue: string;
    requiredInputValueName?: MultilanguageFieldInterface;
    dependentInputId: ConfigEditorField | string;
    dependentInputValue: string;
    dependentInputValueName?: MultilanguageFieldInterface;
    whenMatched: WindowDesignerCatalogDependentOptionAction;

    static fromJSON(jsonObject: object): ConfigDesignerCatalogDependentOption {
        const option = new ConfigDesignerCatalogDependentOption();
        option.id = jsonObject['id'];
        option.configSystemId = jsonObject['configSystemId'];
        if (jsonObject['configSystemName'] != undefined) {
            option.configSystemName = MultilanguageField.fromJSON(jsonObject['configSystemName']);
        }
        option.requiredInputId = jsonObject['requiredInputId'];
        option.requiredInputValue = jsonObject['requiredInputValue'];
        if (jsonObject['requiredInputValueName'] != undefined) {
            option.requiredInputValueName = MultilanguageField.fromJSON(jsonObject['requiredInputValueName']);
        }
        option.dependentInputId = jsonObject['dependentInputId'];
        option.dependentInputValue = jsonObject['dependentInputValue'];
        if (jsonObject['dependentInputValueName'] != undefined) {
            option.dependentInputValueName = MultilanguageField.fromJSON(jsonObject['dependentInputValueName']);
        }
        if (jsonObject['whenMatched'] != undefined) {
            option.whenMatched = WindowDesignerCatalogDependentOptionAction[jsonObject['whenMatched'] as string];
        }
        return option;
    }
}
