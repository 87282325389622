export interface GlazingBeadInterface {
    id: number;
    active: boolean;
    acceptableFillingWidth: {from: number, to: number};
}

export function isFillingWidthAcceptableForGlazingBead(fillingWidth: number, glazingBead: GlazingBeadInterface) {
    const {from, to} = glazingBead.acceptableFillingWidth;
    return from <= fillingWidth && fillingWidth <= to;
}
