import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {UnitConverter} from "../app/common/unit-converter";
import {GrillInterface} from './catalog-data/grill-interface';
import {WindowSystemInterface} from "./catalog-data/window-system-interface";
import {DrawingDataChangeHelper} from "./drawing-data-change-helper";
import {AreaSpecification} from "./drawing-data/AreaSpecification";
import {CutData} from "./drawing-data/CutData";
import {DrawingData} from "./drawing-data/drawing-data";
import {GenericGrillGrid} from "./drawing-data/GenericGrillGrid";
import {Grill} from "./drawing-data/Grill";
import {GrillSegment} from "./drawing-data/GrillSegment";
import {GrillType} from "./drawing-data/GrillType";
import {Guide} from "./drawing-data/Guide";
import {GuideData} from "./drawing-data/GuideData";
import {GuideHelper} from "./drawing-data/GuideHelper";
import {GuidesData} from "./drawing-data/GuidesData";
import {LineCutData} from "./drawing-data/LineCutData";
import {LineGrillSegment} from "./drawing-data/LineGrillSegment";
import {PositionReferenceType} from "./drawing-data/PositionReferenceType";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {WindowData} from "./drawing-data/WindowData";
import {WindowShape} from "./drawing-data/WindowShape";
import {DataKeys, DrawingUtil, MinMaxXY, Point} from "./drawing-util";
import {ElementsPositionsForGuides} from "./elements-positions-for-guides";
import {GuidesDataHelper} from "./guides-data-helper";
import {GuidesDialogData} from "./guides-dialog-data";
import {HandleHelper} from "./handle-helper";
import {PainterMode} from "./painters/PainterMode";
import {PainterParams} from "./painters/PainterParams";
import {ScalingPainter} from "./painters/ScalingPainter";
import {TextPainter} from "./painters/TextPainter";
import {WindowParams} from "./painters/WindowParams";
import {PendingGrillData} from './pending-grill-data';
import {ProfilesCompositionDistances} from "./profiles-composition-distances";
import {AlignmentTool} from './utils/AlignmentTool';
import {AngleConverter} from './utils/angle-converter';
import {AreaUtils} from "./utils/AreaUtils";
import {CutsUtil} from "./utils/cutUtils";
import {ErrorNames} from "./utils/ErrorNames";
import {FloatOps} from "./utils/float-ops";
import {GrillHelper} from "./utils/grill-helper";
import {GrillUtils} from "./utils/GrillUtils";
import {MullionUtils} from "./utils/MullionUtils";
import {OperationResult} from "./utils/OperationResult";
import {PolygonPoint, PolygonPointUtil} from "./utils/PolygonPoint";
import {PositionsHelper} from "./utils/positions-helper";
import {WindowShapeUtil} from "./utils/WindowShapeUtil";
import {WindowCalculator} from "./window-calculator";
import {Tool, WindowDesignerInterface} from './window-designer-interface';

export class GuidePoint {
    x: number;
    y: number;
    origin: PointOrigin;

    constructor(x: number, y: number, origin?: PointOrigin) {
        this.x = x;
        this.y = y;
        this.origin = origin ? origin : new PointOrigin();
    }
}

export class BreadcrumbPair {
    key: string;
    object: any;

    constructor(key: string, object: any) {
        this.key = key;
        this.object = object;
    }
}

export class PointOrigin {
    type: string;
    breadcrumb: BreadcrumbPair[];

    constructor(type?: string, breadcrumb?: BreadcrumbPair[]) {
        this.type = type ? type : undefined;
        this.breadcrumb = breadcrumb ? breadcrumb : [];
    }
}

export class Guides {

    // Percents of window max(width, height) size
    static GUIDES_THUMBNAIL_FONT_SIZE = 6;
    static DIMENSIONS_GROUP_CLASS = 'dimensions-group';
    static STRUCTURE_DIMENSIONS_GROUP_CLASS = 'structure-dimensions-group';
    static TERRACE_DIMENSIONS_GROUP_CLASS = 'terrace-dimensions-group';
    static MULLION_GROUP_TOP_CLASS = 'mullion-group-top';
    static MULLION_GROUP_MIDDLE_CLASS = 'mullion-group-middle';
    static MILLIMETERS_UNIT_CLASS = 'millimeters-display';
    static INCHES_UNIT_CLASS = 'inches-display';

    private isChangeVertical: boolean;
    private isMainGuideEdited: boolean;
    private eventGuideId: string;
    private currentSubwindowPoints: MinMaxXY;
    private hideStructureGuideLines: boolean;
    private toolElementData: {
        selectedElementPosition: number,
        deltaMultiplier: number,
        vertical: boolean
    };

    static get rulerAttr() {
        return {
            stroke: '#030303'
        };
    }

    static get renderRulerAttr() {
        return {
            stroke: '#393939',
            fill: 'none'
        };
    }

    static get guideAttr() {
        return {
            stroke: '#010101',
            strokeWidth: 1,
            strokeDasharray: '15px, 15px',
            strokeLinecap: 'round',
            pointerEvents: 'none'
        };
    }

    static get inactiveGuideAttr() {
        return {
            stroke: '#D1D1D1',
            strokeWidth: 1
        };
    }

    offerComponent: WindowDesignerInterface;
    yMin: number;
    yMax: number;
    xMin: number;
    xMax: number;
    vRulerLength: number;
    hRulerLength: number;
    distanceFromWindows: number;
    rulerWidth: number;

    constructor(offerComponent: WindowDesignerInterface) {
        this.offerComponent = offerComponent;
        offerComponent.recalculateTotalBoundingBox();
        this.rebuildStructureGuides();
    }

    public static getWindowBiggerEdge(offerComponent: WindowDesignerInterface): number {
        return Math.max(
            Math.abs(offerComponent.totalBoundingBox.minX - offerComponent.totalBoundingBox.maxX),
            Math.abs(offerComponent.totalBoundingBox.minY - offerComponent.totalBoundingBox.maxY));
    }

    public static drawSingleHorizontalGuide(svg: Snap.Paper, xMin: number, xMax: number, yCenter: number, ySpread: number): Snap.Paper {
        let g = svg.g();
        g.add(svg.line(xMin, yCenter + (ySpread / 2), xMax, yCenter + (ySpread / 2)));
        g.add(svg.line(xMin, yCenter, xMin, yCenter + ySpread));
        g.add(svg.line(xMax, yCenter, xMax, yCenter + ySpread));
        return g;
    }

    public static drawSingleVerticalGuide(svg: Snap.Paper, xCenter: number, yMin: number, yMax: number, xSpread: number): Snap.Paper {
        let g = svg.g();
        g.add(svg.line(xCenter + (xSpread / 2), yMin, xCenter + (xSpread / 2), yMax));
        g.add(svg.line(xCenter, yMin, xCenter + xSpread, yMin));
        g.add(svg.line(xCenter, yMax, xCenter + xSpread, yMax));
        return g;
    }

    public addGuidesForWindow(window: WindowData, cuts: CutData[]): void {
        let guides = this.offerComponent.data.guides;
        for (let subWindow of window.subWindows) {
            let framePoints = CutsUtil.applyCuts(subWindow.points, cuts, 0);
            GuidesDataHelper.addStructureGuides(guides.horizontal, framePoints.filter((n, index) => {
                return ((index % 2) === 0);
            }), subWindow.generatedId);
            GuidesDataHelper.addStructureGuides(guides.vertical, framePoints.filter((n, index) => {
                return ((index % 2) === 1);
            }), subWindow.generatedId);
        }
    }

    private addGuidesForCurrentShape(): void {
        let guides = this.offerComponent.data.guides;
        let boundingBox = DrawingUtil.calculateTotalBoundingBox(this.offerComponent.data.windows);
        let shape = this.offerComponent.data.shape;
        if (WindowShape.isArchElliptical(shape) || WindowShape.isArchSegmental(shape)) {
            if (FloatOps.gt(shape.arcHeight, 0)) {
                GuidesDataHelper.addStructureGuides(guides.vertical, [boundingBox.minY + shape.arcHeight]);
            }
        } else if (WindowShape.isArchThreeCentered(shape) && FloatOps.gt(shape.ry, 0)
            && (FloatOps.gt(shape.rx1, 0) || FloatOps.gt(shape.rx2, 0))) {
            GuidesDataHelper.addStructureGuides(guides.vertical, [boundingBox.minY + shape.ry]);
            if (FloatOps.gt(shape.rx1, 0)) {
                GuidesDataHelper.addStructureGuides(guides.horizontal, [boundingBox.minX + shape.rx1]);
            }
            if (FloatOps.gt(shape.rx2, 0)) {
                GuidesDataHelper.addStructureGuides(guides.horizontal, [boundingBox.maxX - shape.rx2]);
            }
        }
    }

    public drawHorizontalDimensionLine(svg: Snap.Paper, x0: number, x1: number, y: number, params: PainterParams): Snap.Paper {
        let rulerWidth = this.rulerWidth;
        let line = [x0, y + (rulerWidth / 2), x1, y + (rulerWidth / 2)];
        let start = [x0, y, x0, y + rulerWidth];
        let end = [x1, y, x1, y + rulerWidth];
        let g = svg.g();
        g.add(ScalingPainter.line(svg, line, Guides.rulerAttr, params));
        g.add(ScalingPainter.line(svg, start, Guides.rulerAttr, params));
        g.add(ScalingPainter.line(svg, end, Guides.rulerAttr, params));
        return g;
    }

    public drawVerticalDimensionLine(svg: Snap.Paper, x: number, y0: number, y1: number, params: PainterParams): Snap.Paper {
        let rulerWidth = this.rulerWidth;
        let line = [x + (rulerWidth / 2), y0, x + (rulerWidth / 2), y1];
        let start = [x, y0, x + rulerWidth, y0];
        let end = [x, y1, x + rulerWidth, y1];
        let g = svg.g();
        g.add(ScalingPainter.line(svg, line, Guides.rulerAttr, params));
        g.add(ScalingPainter.line(svg, start, Guides.rulerAttr, params));
        g.add(ScalingPainter.line(svg, end, Guides.rulerAttr, params));
        return g;
    }

    public recalculateDistanceFromWindows(): void {
        this.recalculateFirstRulerDistance();
        this.addThumbnailDetailsDistanceFromWindows();
    }

    private recalculateFirstRulerDistance(): void {
        this.rulerWidth = this.offerComponent.getOnePercentOfCanvasSize() * 2;
        this.distanceFromWindows = this.rulerWidth * 4.5;
    }

    private addThumbnailDetailsDistanceFromWindows(): void {
        this.distanceFromWindows +=
            Guides.getWindowBiggerEdge(this.offerComponent) * 0.01 * Guides.GUIDES_THUMBNAIL_FONT_SIZE * 1.15;
    }

    public drawGuides(params: PainterParams) {
        if (params.isMode(PainterMode.WEBSHOP)) {
            this.distanceFromWindows = 0;
            return;
        }
        let svg = this.offerComponent.svg;
        this.recalculateFirstRulerDistance();

        let vGuides = this.offerComponent.data.guides.vertical;
        let hGuides = this.offerComponent.data.guides.horizontal;

        this.xMin = hGuides.main[0].position;
        this.xMax = GuideHelper.end(hGuides.main[0]);
        this.yMin = vGuides.main[0].position;
        this.yMax = GuideHelper.end(vGuides.main[0]);
        this.vRulerLength = this.xMax - this.xMin;
        this.hRulerLength = this.yMax - this.yMin;

        this.hideStructureGuideLines = false;

        let positions = this.findElementsPositions(params.topEdgeDimension);

        if (params.isRegularMode()) {
            if (!params.paintOnlyStructureGuides) {
                if (this.offerComponent.isTerrace) {
                    let dimensionsGroup = svg.g().addClass(Guides.TERRACE_DIMENSIONS_GROUP_CLASS);
                    this.drawThumbnailDetails(svg, positions, params, dimensionsGroup, false);
                }
                this.drawToolRulers(svg, params);
                this.drawStructureRulers(svg, vGuides.structure, hGuides.structure, positions, params);
                this.drawMainRulers(svg, vGuides.main, hGuides.main, params);
            } else {
                this.drawStructureRulers(svg, vGuides.structure, hGuides.structure, positions, params);
            }
        } else if (!params.paintOnlyStructureGuides) {
            let dimensionsGroup = svg.g().addClass(Guides.DIMENSIONS_GROUP_CLASS);
            if (params.isShaded()) {
                this.drawRenderThumbnailGuides(svg, positions, params, dimensionsGroup);
            } else {
                this.drawThumbnailDetails(svg, positions, params, dimensionsGroup, true);
                this.drawThumbnailTotalsAndCuts(svg, positions, params, dimensionsGroup);

                let hasGrillDimensions = false;
                for (let window of this.offerComponent.data.windows) {
                    for (let subwindow of window.subWindows) {
                        for (let areaSpec of subwindow.areasSpecification) {
                            const winglessGrills = areaSpec.grills.some(grill => (grill as GenericGrillGrid).winglessMode);
                            if (winglessGrills) {
                                hasGrillDimensions = true;
                                break;
                            }
                        }
                    }
                }
                if (hasGrillDimensions) {
                    this.drawWinglessModeGrillGridGuide(svg, positions, params);
                }
            }
        }
    }

    private onClickGuideTextHandler(event: MouseEvent, vertical: boolean, guide: Guide): void {
        if (this.offerComponent.mode === Tool.SELECT) {
            this.onGuideClickAction(event, vertical, guide);
        }
    }

    private onClickToolGuideTextHandler(vertical: boolean, size: number, selectedElementPosition?: number, deltaMultiplier?: number): void {
        if (this.offerComponent.mode === Tool.SELECT && this.offerComponent.requiredFieldFilled) {
            this.isMainGuideEdited = false;
            this.isChangeVertical = vertical;
            let dialogData = new GuidesDialogData();
            dialogData.oldValue = FloatOps.round(size);
            dialogData.newValue = FloatOps.round(size);
            dialogData.displayDialog = true;
            dialogData.hideLock = true;
            dialogData.isChangeVertical = vertical;
            this.offerComponent.guidesDialogData = dialogData;
            this.toolElementData = {
                selectedElementPosition: selectedElementPosition,
                deltaMultiplier: deltaMultiplier,
                vertical: vertical
            };
        }
    }

    private onClickMainGuideHandler(event: MouseEvent, vertical: boolean): void {
        if (this.offerComponent.mode === Tool.SELECT) {
            let guides = this.offerComponent.data.guides;
            let guide = vertical ? guides.vertical.main[0] : guides.horizontal.main[0];
            this.onGuideClickAction(event, vertical, guide);
        }
    }

    private onGuideClickAction(event: MouseEvent, vertical: boolean, guide: Guide): void {
        if (event.altKey) {
            guide.locked = !guide.locked;
            this.offerComponent.redrawWindow(true, true);
        } else {
            this.eventGuideId = guide.generatedId;
            this.isChangeVertical = vertical;
            let guides = this.offerComponent.data.guides;
            this.isMainGuideEdited = guide === (vertical ? guides.vertical : guides.horizontal).main[0];
            let dialogData = new GuidesDialogData();
            dialogData.oldValue = guide.size | 0; // tslint:disable-line:no-bitwise
            dialogData.newValue = guide.size | 0; // tslint:disable-line:no-bitwise
            dialogData.isChangeVertical = vertical;
            dialogData.hideLock = false;
            dialogData.locked = guide.locked;
            dialogData.oldLocked = guide.locked;
            dialogData.displayDialog = true;
            this.offerComponent.guidesDialogData = dialogData;
        }
    }

    public cleanupEditing() {
        this.eventGuideId = undefined;
        this.toolElementData = undefined;
    }

    public changeSizeIgnoringLocks(windowSystem: WindowSystemInterface, vertical: boolean, newValue: number): Observable<OperationResult> {
        this.isMainGuideEdited = true;
        this.offerComponent.guidesDialogData.newValue = newValue;
        let dialogWasLocked = this.offerComponent.guidesDialogData.locked;
        this.offerComponent.guidesDialogData.locked = false;
        this.isChangeVertical = vertical;
        let eventGuide: Guide;
        if (vertical) {
            eventGuide = this.offerComponent.data.guides.vertical.main[0];
        } else {
            eventGuide = this.offerComponent.data.guides.horizontal.main[0];
        }
        let wasLocked = eventGuide.locked;
        eventGuide.locked = false;
        this.offerComponent.guidesDialogData.oldValue = eventGuide.size;
        return this.changeSize(windowSystem, eventGuide).pipe(
            finalize(
                () => {
                    eventGuide.locked = wasLocked;
                    this.offerComponent.guidesDialogData.locked = dialogWasLocked;
                    this.offerComponent.recalculateTotalBoundingBox();
                    this.isMainGuideEdited = false;
                }));
    }

    public changeSize(windowSystem: WindowSystemInterface, eventGuide?: Guide): Observable<OperationResult> {
        let guidesDialogData = this.offerComponent.guidesDialogData;
        let valueDelta = guidesDialogData.newValue - guidesDialogData.oldValue;
        eventGuide = eventGuide ? eventGuide : this.getAllGuides().find(g => g.generatedId === this.eventGuideId);
        let lockChange = eventGuide != null
            ? eventGuide.locked !== guidesDialogData.locked
            : guidesDialogData.oldLocked !== guidesDialogData.locked;

        if (valueDelta === 0 && !lockChange) {
            return observableOf(new OperationResult()) // nothing to do
                .pipe(tap(() => this.cleanupEditing()));
        }

        this.offerComponent.saveStepInHistory(this.offerComponent.data);
        if (eventGuide != undefined) {
            eventGuide.locked = guidesDialogData.locked;
        }
        let operationResult = new OperationResult();
        try {
            if (valueDelta !== 0) {
                if (this.toolElementData) {
                    operationResult = this.changeToolSize(valueDelta);
                } else {
                    let oldData = DrawingData.copy(this.offerComponent.data);
                    operationResult = this.changeSizeInternal(valueDelta, eventGuide, this.isChangeVertical);
                    operationResult.merge(this.offerComponent.runWindowsArrayGarbageCollector());
                    if (this.isMainGuideEdited) {
                        operationResult.merge(
                            AlignmentTool.autoAlign(windowSystem, this.offerComponent.profileCompositionDistances,
                                this.offerComponent.data, oldData));
                        this.rebuildStructureGuides();
                    }
                }
            }
        } catch (error) {
            this.offerComponent.cancelLast();
            this.offerComponent.redrawWindow(true, !this.offerComponent.isPricingTabOpen());
            return observableThrowError(() => error);
        }
        return this.offerComponent.redrawWindow(false, !this.offerComponent.isPricingTabOpen()).pipe(
            map(() => operationResult),
            tap(() => this.cleanupEditing()));
    }

    private changeSizeInternal(valueDelta: number, eventGuide: Guide, isChangeVertical: boolean, ignoreLocks = false): OperationResult {
        let data = this.offerComponent.data;
        let oldDataCopy = DrawingData.copy(data);
        let guides = isChangeVertical ? data.guides.vertical : data.guides.horizontal;
        this.transformGuides(valueDelta, guides, eventGuide, isChangeVertical, ignoreLocks);
        let operationResult = this.moveElements(oldDataCopy, isChangeVertical);
        operationResult.merge(DrawingDataChangeHelper.processRequiredChanges(oldDataCopy, data));
        GuidesDataHelper.removeEmptyGuides(guides);
        this.removeDegeneratedCuts(data);
        return operationResult;
    }

    private transformGuides(valueDelta: number, guides: GuideData, eventGuide: Guide, isChangeVertical: boolean,
                            ignoreLocks = false): void {
        if (!ignoreLocks) {
            this.transformGuidesWithLocks(valueDelta, guides, eventGuide);
        }
        GuideHelper.resize(eventGuide, valueDelta);
        GuidesDataHelper.recalculatePositions(guides);
        GuidesDataHelper.checkTotalSize(guides, isChangeVertical);
    }

    private transformGuidesWithLocks(valueDelta: number, guides: GuideData, eventGuide: Guide): void {
        let unlockedGuides = guides.structure.filter((g, index, arr) => !g.locked && g !== eventGuide && index !== arr.length - 1);
        let unlockedCount = unlockedGuides.length;
        if (this.isMainGuideEdited || guides.main[0].locked) {
            if (unlockedCount === 0) {
                let err = new Error("Guides.transformGuides: Wszystkie wymiary są zablokowane - nie można nic zmienić");
                err.name = ErrorNames.RESIZE_FAILED_ALL_DIMENSIONS_LOCKED;
                throw err;

            }
            let remainingDelta = this.distributeDeltaMod(unlockedGuides, valueDelta);
            let totalSize = unlockedGuides.reduce((a, b) => a + b.size, 0);

            for (let guide of unlockedGuides) {
                let guidePercentSize = guide.size / totalSize;
                let delta = remainingDelta * guidePercentSize;
                delta = +delta.toFixed(0);
                totalSize -= guide.size;
                remainingDelta -= delta;
                GuideHelper.resize(guide, this.isMainGuideEdited ? delta : -delta);
            }
        }
    }

    private distributeDeltaMod(unlockedGuides: Guide[], valueDelta: number) {
        let remainingDelta = valueDelta;
        if (this.isMainGuideEdited) {
            let subwindowsSizes: { subwindowGeneratedId: string, size: number }[] = [];
            unlockedGuides.forEach(guide => {
                let found = subwindowsSizes.find(ss => ss.subwindowGeneratedId === guide.subwindowGeneratedId);
                if (found == null) {
                    found = {subwindowGeneratedId: guide.subwindowGeneratedId, size: guide.size};
                    subwindowsSizes.push(found);
                } else {
                    found.size += guide.size;
                }
            });
            let mod = valueDelta % subwindowsSizes.length;
            remainingDelta -= mod;
            let subwindowsBySize = _.sortBy(subwindowsSizes, "size").map(o => o.subwindowGeneratedId);
            if (mod < 0) {
                subwindowsBySize.reverse();
            }
            subwindowsBySize.slice(0, Math.abs(mod)).forEach(subwindowId => {
                unlockedGuides.find(guide => guide.subwindowGeneratedId === subwindowId).size += (mod >= 0 ? 1 : -1);
            });
        }
        return remainingDelta;
    }

    public generateCutGuidancePoints(): GuidePoint[] {
        let frame = WindowCalculator.getOuterFramePoints(this.offerComponent.data.windows, this.offerComponent.data.cuts);
        let guidancePoints: GuidePoint[] = [];
        this.generateStandardGuidePoints(frame, guidancePoints);

        return guidancePoints.filter((value, index, self) => self.findIndex(p => p.x === value.x && p.y === value.y) === index);
    }

    private generateStandardGuidePoints(points: number[], guidancePoints: GuidePoint[]): void {
        let guides = this.offerComponent.data.guides;
        for (let k = 0; k < points.length; k += 2) {
            guidancePoints.push(new GuidePoint(points[k], points[k + 1]));

            let x2: number;
            let y2: number;
            if (k === 0) {
                x2 = points[points.length - 2];
                y2 = points[points.length - 1];
            } else {
                x2 = points[k - 2];
                y2 = points[k - 1];
            }
            if (points[k + 1] !== y2) {
                for (let i = 0; i < guides.vertical.structure.length - 1; ++i) {
                    let gPosition = guides.vertical.structure[i].position;
                    let result = DrawingUtil.lineIntersection(points[k], points[k + 1], x2, y2, this.xMin, gPosition, this.xMax, gPosition);
                    if (result.intersects && result.onLine1) {
                        guidancePoints.push(new GuidePoint(result.x, gPosition));
                    }
                }
            }
            if (points[k] !== x2) {
                for (let i = 0; i < guides.horizontal.structure.length - 1; ++i) {
                    let gPosition = guides.horizontal.structure[i].position;
                    let result = DrawingUtil.lineIntersection(points[k], points[k + 1], x2, y2, gPosition, this.yMin, gPosition, this.yMax);
                    if (result.intersects && result.onLine1) {
                        guidancePoints.push(new GuidePoint(gPosition, result.y));
                    }
                }
            }
        }
    }

    private generateAreasGuidePoints(guidancePoints: GuidePoint[],
                                     polygonMapFull: Map<AreaSpecification, PolygonPoint[]>,
                                     pendingGrillData: PendingGrillData,
                                     catalogGrillsForWindowSystem: GrillInterface[]): void {
        this.offerComponent.data.windows.forEach(window => {
            window.subWindows.forEach(subWindow => {
                subWindow.areasSpecification.filter(
                    as => AreaUtils.canPendingGrillBeAdded(as, pendingGrillData, catalogGrillsForWindowSystem))
                    .forEach(as => {
                        let polygonFull = polygonMapFull.get(as);
                        let origin = new PointOrigin(WindowParams.GLAZING_BEAD_ELEM, [
                            new BreadcrumbPair(DataKeys.SUBWINDOW, subWindow),
                            new BreadcrumbPair(DataKeys.GLAZING_BEAD, polygonFull),
                            new BreadcrumbPair(DataKeys.AREA, as)]);
                        guidancePoints.push(...this.generateGuidancePointsOnPolygon(polygonFull, origin));
                    });
            });
        });
    }

    private generateGuidancePointsOnPolygon(polygon: PolygonPoint[], origin: PointOrigin, onVertices = true,
                                            onEdgeMiddles = true): GuidePoint[] {
        return this.getPointsToGenerateOnPolygon(polygon, onVertices, onEdgeMiddles)
            .map(p => new GuidePoint(p.x, p.y, origin));
    }

    private getPointsToGenerateOnPolygon(polygon: PolygonPoint[], onVertices = true,
                                         onEdgeMiddles = true): PolygonPoint[] {
        let points = [];
        for (let i = 0; i < polygon.length; i++) {
            let point1 = DrawingUtil.getPoint(polygon, i);
            let point2 = DrawingUtil.getPoint(polygon, i + 1);
            let point3 = DrawingUtil.getPoint(polygon, i + 2);
            if (onVertices && (!point1.isArc || !point3.isArc)) {
                points.push(point2);
            }
            if (onEdgeMiddles && (!point1.isArc || !point2.isArc)) {
                points.push(new PolygonPoint((point1.x + point2.x) / 2, (point1.y + point2.y) / 2, false));
            }
        }
        return points;
    }

    public generateGuidancePoints(mode: Tool, polygonMap: Map<AreaSpecification, number[]>,
                                  polygonMapFull: Map<AreaSpecification, PolygonPoint[]>,
                                  pendingGrillData: PendingGrillData,
                                  catalogGrillsForWindowSystem: GrillInterface[]): GuidePoint[] {
        let guidancePoints: GuidePoint[] = [];
        switch (mode) {
            case Tool.CUT:
                guidancePoints = this.generateCutGuidancePoints();
                break;
            case Tool.GRILL:
                this.generateAreasGuidePoints(guidancePoints, polygonMapFull, pendingGrillData,
                    catalogGrillsForWindowSystem);
                this.generateGrillToBeadGuidancePoints(guidancePoints, polygonMap, polygonMapFull, pendingGrillData,
                    catalogGrillsForWindowSystem);
                this.generateGrillToGrillGuidancePoints(guidancePoints, polygonMap, pendingGrillData,
                    catalogGrillsForWindowSystem);
                break;
            case Tool.MULLION:
                this.generateMullionGuidancePoints(guidancePoints);
                break;
            default:
                let err = new Error("Unsupported type: generateGuidancePoints()");
                err.name = ErrorNames.GENERAL_ERROR;
                throw err;
        }
        return guidancePoints.filter(
            (value, index, self) => self.findIndex(v => v.x === value.x && v.y === value.y) === index);
    }

    private generateGrillToBeadGuidancePoints(guidancePoints: GuidePoint[],
                                              glazingBeadsMap: Map<AreaSpecification, number[]>,
                                              glazingBeadsFullMap: Map<AreaSpecification, PolygonPoint[]>,
                                              pendingGrillData: PendingGrillData,
                                              catalogGrillsForWindowSystem: GrillInterface[]): void {
        let xCoords: number[] = [];
        let yCoords: number[] = [];
        let crossingLines: number[][] = [];
        let boundingBox = this.offerComponent.totalBoundingBox;
        this.offerComponent.data.windows.forEach(window => {
            window.subWindows.forEach(subWindow => {
                subWindow.areasSpecification.forEach(as => {
                    let glazingBeadEdges = DrawingUtil.getAllPolygonEdges(glazingBeadsMap.get(as));
                    as.grills.forEach(grill => {
                        grill.drawingSegments.forEach(segment => {
                            if (GrillSegment.isLine(segment)) {
                                for (let edge of glazingBeadEdges) {
                                    let intersection = DrawingUtil.lineIntersection(segment.points, edge);
                                    if (intersection.onLine1 && intersection.onLine2) {
                                        this.addCoordsIfNotOnPerpendicularLine(xCoords, yCoords,
                                            [+intersection.x.toFixed(4), +intersection.y.toFixed(4)], edge);
                                    }
                                }
                            }
                        });
                    });
                });
            });
        });
        for (let x of xCoords) {
            let line = [x, boundingBox.maxY, x, boundingBox.minY];
            crossingLines.push(line);
        }
        for (let y of yCoords) {
            let line = [boundingBox.minX, y, boundingBox.maxX, y];
            crossingLines.push(line);
        }
        this.offerComponent.data.windows.forEach(window => {
            window.subWindows.forEach(subWindow => {
                subWindow.areasSpecification.filter(
                    as => AreaUtils.canPendingGrillBeAdded(as, pendingGrillData, catalogGrillsForWindowSystem))
                    .forEach(as => {
                        let glazingBead = glazingBeadsFullMap.get(as);
                        for (let i = 0; i < glazingBead.length; i++) {
                            let vertexA = DrawingUtil.getPoint(glazingBead, i);
                            let vertexB = DrawingUtil.getPoint(glazingBead, i + 1);
                            if (!vertexA.isArc || !vertexB.isArc) {
                                let edge = [vertexA.x, vertexA.y, vertexB.x, vertexB.y];
                                for (let line of crossingLines) {
                                    let intersection = DrawingUtil.lineIntersection(edge, line);
                                    if (intersection.onLine1 && intersection.onLine2) {
                                        let origin = new PointOrigin(WindowParams.GLAZING_BEAD_ELEM, [
                                            new BreadcrumbPair(DataKeys.SUBWINDOW, subWindow),
                                            new BreadcrumbPair(DataKeys.GLAZING_BEAD, glazingBeadsFullMap.get(as)),
                                            new BreadcrumbPair(DataKeys.AREA, as)]);
                                        let tempPoint = new GuidePoint(+intersection.x.toFixed(4),
                                            +intersection.y.toFixed(4), origin);
                                        guidancePoints.push(tempPoint);
                                    }
                                }
                            }
                        }
                    });
            });
        });
    }

    private generateGrillToGrillGuidancePoints(guidancePoints: GuidePoint[],
                                               glazingBeadsMap: Map<AreaSpecification, number[]>,
                                               pendingGrillData: PendingGrillData,
                                               catalogGrillsForWindowSystem: GrillInterface[]): void {
        for (let w of this.offerComponent.data.windows) {
            for (let subWindow of w.subWindows) {
                for (let as of subWindow.areasSpecification) {
                    if (AreaUtils.canPendingGrillBeAdded(as, pendingGrillData, catalogGrillsForWindowSystem)) {
                        let innermostFramePoints = glazingBeadsMap.get(as);
                        for (let grill of as.grills.filter(
                            g => GrillHelper.canDependOn(pendingGrillData.grill, g))) {
                            for (let segment of grill.drawingSegments) {
                                if (GrillSegment.isLine(segment)) {
                                    for (let otherGrill of as.grills) {
                                        for (let otherGrillSegment of otherGrill.drawingSegments) {
                                            if (GrillSegment.isLine(otherGrillSegment)) {
                                                let intersection = DrawingUtil.lineIntersection(segment.points, otherGrillSegment.points);
                                                if (intersection.onLine1 && intersection.onLine2) {
                                                    let origin = new PointOrigin(WindowParams.MUNTIN_ELEM, [
                                                        new BreadcrumbPair(DataKeys.SUBWINDOW, subWindow),
                                                        new BreadcrumbPair(DataKeys.AREA, as),
                                                        new BreadcrumbPair(DataKeys.GRILL, grill),
                                                        new BreadcrumbPair(DataKeys.GRILL_SEGMENT, segment)]);
                                                    let x = +intersection.x.toFixed(4);
                                                    let y = +intersection.y.toFixed(4);
                                                    if (DrawingUtil.isPointInPolygon([x, y], innermostFramePoints)) {
                                                        let newPoint = new GuidePoint(x, y, origin);
                                                        guidancePoints.push(newPoint);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    let err = new Error("Guides.generateGrillToGrillGuidancePoints():"
                                        + ` Not implemented for grill segment type: ${segment.type}`);
                                    err.name = ErrorNames.NOT_IMPLEMENTED;
                                    throw err;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private generateMullionGuidancePoints(guidancePoints: GuidePoint[]): void {
        let bBox = this.offerComponent.totalBoundingBox;
        let xs: number[] = [];
        let ys: number[] = [];
        this.offerComponent.data.windows.forEach(window => {
            window.subWindows.forEach(subwindow => {
                let totalInnerFrame = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow,
                    this.offerComponent.data.cuts, bBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled());
                this.generateMullionToFramePositions(subwindow, xs, ys, totalInnerFrame);
            });
        });

        let minDistance = (MullionUtils.getExternalWidth(this.offerComponent.pendingGrillData.grill.width,
            this.offerComponent.profileCompositionDistances) / 2) + 1;
        let pushIntersectingPointsForFrame = (totalInnerFrame: number[], line: number[], origin: PointOrigin, corners: PolygonPoint[]) => {
            let intersections = DrawingUtil.polygonLineIntersections(totalInnerFrame, line);
            intersections.forEach(intersection => {
                if (intersection.onLine1 && intersection.onLine2) {
                    if (MullionUtils.validatePointFarEnoughFromPoints([intersection.x, intersection.y], corners, minDistance)) {
                        guidancePoints.push(new GuidePoint(intersection.x, intersection.y, origin));
                    }
                }
            });
        };
        this.offerComponent.data.windows.forEach(window => {
            window.subWindows.filter(sw => MullionUtils.canHaveMullions(sw) &&
                MullionUtils.isSubwindowValidForNewMullion(this.offerComponent.pendingOperationLineHelper, sw)).forEach(subwindow => {
                let relevantCuts = this.offerComponent.data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
                let totalInnerFrameFull = WindowCalculator.getTotalFrameInnerEdgePointsFull(subwindow,
                    relevantCuts, bBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled());
                let totalInnerFrame = PolygonPointUtil.toNumbersArray(totalInnerFrameFull);
                let origin = new PointOrigin(WindowParams.INNER_FRAME_ELEM, [
                    new BreadcrumbPair(DataKeys.SUBWINDOW, subwindow),
                    new BreadcrumbPair(DataKeys.INNER_FRAME, totalInnerFrameFull)]);
                let subwindowCorners: PolygonPoint[] = [];
                subwindow.areasSpecification.forEach(area => {
                    let frameInnerFull = WindowCalculator.getFrameInnerEdgePointsFull(subwindow, area.definingMullions,
                        totalInnerFrameFull);
                    let areaCorners = MullionUtils.getAllCorners(frameInnerFull, this.offerComponent.data.shape);
                    subwindowCorners.push(...areaCorners);
                    let localPoints = this.getPointsToGenerateOnPolygon(frameInnerFull, false);
                    localPoints.forEach(p => {
                        let line = [p.x - 1, p.y - 1, p.x + 1, p.y + 1];
                        pushIntersectingPointsForFrame(totalInnerFrame, line, origin, areaCorners);
                    });
                });
                xs.forEach(x => {
                    let line = [x, bBox.minY, x, bBox.maxY];
                    pushIntersectingPointsForFrame(totalInnerFrame, line, origin, subwindowCorners);
                });
                ys.forEach(y => {
                    let line = [bBox.minX, y, bBox.maxX, y];
                    pushIntersectingPointsForFrame(totalInnerFrame, line, origin, subwindowCorners);
                });
            });
        });
    }

    private generateMullionToFramePositions(subWindow: SubWindowData, xs: number[], ys: number[], totalInnerFrame: number[]) {
        let frameEdges = DrawingUtil.getAllPolygonEdges(totalInnerFrame);
        subWindow.mullions.forEach(mullion => {
            let segment = GrillHelper.getExpectedLineSegment(mullion);
            for (let frameEdge of frameEdges) {
                let intersection = DrawingUtil.lineIntersection(segment.points, frameEdge);
                if (intersection.onLine1 && intersection.onLine2) {
                    this.addCoordsIfNotOnPerpendicularLine(xs, ys,
                        [+intersection.x.toFixed(4), +intersection.y.toFixed(4)], frameEdge);
                }
            }
        });
    }

    rebuildStructureGuides(): void {
        let data = this.offerComponent.data;
        if (data) {
            let oldGuides = data.guides == null ? null : JSON.parse(JSON.stringify(data.guides));
            data.guides = new GuidesData();
            // Structure points
            let cuts = WindowShape.isRectangular(data.shape) ? data.cuts : [];
            for (let window of data.windows) {
                this.addGuidesForWindow(window, cuts);
            }
            this.addGuidesForCurrentShape();
            if (oldGuides != null) {
                this.repopulateGuidesLocks(data.guides, oldGuides);
            }
        }
    }

    private repopulateGuidesLocks(newGuides: GuidesData, oldGuides: GuidesData): void {
        if (newGuides.horizontal.main.length === oldGuides.horizontal.main.length &&
            newGuides.vertical.main.length === oldGuides.vertical.main.length &&
            newGuides.horizontal.structure.length === oldGuides.horizontal.structure.length &&
            newGuides.vertical.structure.length === oldGuides.vertical.structure.length) {
            this.repopulateGuideLocks(newGuides.horizontal.main, oldGuides.horizontal.main);
            this.repopulateGuideLocks(newGuides.vertical.main, oldGuides.vertical.main);
            this.repopulateGuideLocks(newGuides.horizontal.structure, oldGuides.horizontal.structure);
            this.repopulateGuideLocks(newGuides.vertical.structure, oldGuides.vertical.structure);
        }
    }

    private repopulateGuideLocks(newGuides: Guide[], oldGuides: Guide[]): void {
        for (let i in newGuides) {
            newGuides[i].locked = oldGuides[i].locked;
        }
    }

    private moveElements(oldData: DrawingData, isChangeVertical: boolean): OperationResult {
        let data = this.offerComponent.data;
        let oldGuides = isChangeVertical ? oldData.guides.vertical : oldData.guides.horizontal;
        let newGuides = isChangeVertical ? data.guides.vertical : data.guides.horizontal;
        let initialPointIndex = isChangeVertical ? 1 : 0;
        let oldMaps = PositionsHelper.getFramesAndGlazingBeads(data, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let oldBoundingBox = this.offerComponent.totalBoundingBox;

        let transformPoint = (point: number) => this.transformPoint(oldGuides.structure, newGuides.structure, point, true);

        // Okna:
        for (let window of data.windows) {
            for (let subWindow of window.subWindows) {
                for (let i = initialPointIndex; i < subWindow.points.length; i += 2) {
                    subWindow.points[i] = transformPoint(subWindow.points[i]);
                }
            }
        }

        this.offerComponent.prepareTabsData();
        let newBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);

        // Ciecia:
        this.moveCuts(data, oldBoundingBox, newBoundingBox, isChangeVertical, transformPoint);

        // Szprosy:
        return MullionUtils.repositionMullionsAndGrills(data, this.offerComponent.profileCompositionDistances, oldMaps.frames,
            oldMaps.glazingBeads, this.offerComponent.isValidationDisabled(), this.offerComponent.isTerrace);
    }

    private moveCuts(data: DrawingData, oldBoundingBox: MinMaxXY, newBoundingBox: MinMaxXY,
                     isChangeVertical: boolean, transformPoint: (point: number) => number): void {
        if (WindowShape.isRectangular(data.shape)) {
            let initialPointIndex = isChangeVertical ? 1 : 0;
            const cutAngles: {oldAngle: number, newAngle: number}[] = [];
            for (let c = 0; c < data.cuts.length; ++c) {
                const cut = data.cuts[c] as LineCutData;
                cutAngles[c] = {
                    oldAngle: DrawingUtil.atan2normalized(cut.points[3] - cut.points[1], cut.points[2] - cut.points[0]),
                    newAngle: undefined
                };
                for (let i = initialPointIndex; i < cut.points.length; i += 2) {
                    cut.points[i] = transformPoint(cut.points[i]);
                }
                cutAngles[c].newAngle = DrawingUtil.atan2normalized(cut.points[3] - cut.points[1], cut.points[2] - cut.points[0]);
            }
            // compare relative cut angles - make sure two cuts dont start making one line (0 or 180 degrees)
            // and that they also dont change their side
            if (cutAngles.length > 1) {
                for (let i = 0; i < cutAngles.length; ++i) {
                    const intersection = DrawingUtil.lineIntersection(
                        (DrawingUtil.getPoint(data.cuts, i) as LineCutData).points,
                        (DrawingUtil.getPoint(data.cuts, i + 1) as LineCutData).points
                    );

                    if (!intersection.onLine1 && !intersection.onLine2) {
                        // cuts don't touch, don't validate angles
                        continue;
                    }

                    const angles = DrawingUtil.getPoint(cutAngles, i);
                    const nextCutAngles = DrawingUtil.getPoint(cutAngles, i + 1);

                    const oldDelta = nextCutAngles.oldAngle - angles.oldAngle;
                    const newDelta = nextCutAngles.newAngle - angles.newAngle;

                    // logic from WindowPainter.paintAngleValues
                    if ((FloatOps.round(AngleConverter.toDeg(Math.abs(Math.PI - DrawingUtil.normalizeAngle(newDelta)))) % 180) === 0) {
                        const err = new Error('Two cuts cannot form a single line!');
                        err.name = ErrorNames.RESIZE_FAILED_CUTS_LAND_ON_SINGLE_LINE;
                        throw err;
                    }

                    if ((oldDelta < 0) !== (newDelta < 0)) {
                        const err = new Error('Two cuts cannot have angle > 180 degrees');
                        err.name = ErrorNames.RESIZE_FAILED_CUTS_ANGLE_CROSSES_180;
                        throw err;
                    }
                }
            }
        } else {
            this.moveWindowShape(data.shape, oldBoundingBox, newBoundingBox, isChangeVertical, transformPoint);
            data.cuts.length = 0;
            data.cuts.push(...WindowShapeUtil.generateCutsForWindowShape(data.shape, newBoundingBox));
        }
    }

    private moveWindowShape(shape: WindowShape, oldBoundingBox: MinMaxXY, newBoundingBox: MinMaxXY,
                            isChangeVertical: boolean, transformPoint: (point: number) => number): void {
        if (WindowShape.isArchElliptical(shape) || WindowShape.isArchSegmental(shape)) {
            if (isChangeVertical && FloatOps.gt(shape.arcHeight, 0)) {
                shape.arcHeight = transformPoint(oldBoundingBox.minY + shape.arcHeight) - newBoundingBox.minY;
            }
        } else if (WindowShape.isArchThreeCentered(shape)) {
            if (isChangeVertical) {
                if (FloatOps.gt(shape.ry, 0)) {
                    shape.ry = transformPoint(oldBoundingBox.minY + shape.ry) - newBoundingBox.minY;
                }
            } else {
                if (FloatOps.gt(shape.rx1, 0)) {
                    shape.rx1 = transformPoint(oldBoundingBox.minX + shape.rx1) - newBoundingBox.minX;
                }
                if (FloatOps.gt(shape.rx2, 0)) {
                    shape.rx2 = -(transformPoint(oldBoundingBox.maxX - shape.rx2) - newBoundingBox.maxX);
                }
            }
        }
    }

    private transformPoint(oldGuides: Guide[], newGuides: Guide[], point: number, onlyOnGuides: boolean): number {
        if (oldGuides.length !== newGuides.length || oldGuides.length === 0) {
            let err = new Error('Guides.transformPoint: Guide tables should always have equal positive length: '
                + `${oldGuides.length} != ${newGuides.length}`);
            err.name = ErrorNames.RESIZE_FAILED;
            throw err;
        }
        let delta;
        for (let k = 0; k < oldGuides.length; k++) {
            delta = oldGuides[k].position - point;
            if (delta === 0) {
                return newGuides[k].position;
            } else if (!onlyOnGuides && delta > 0) {
                if (k === 0) {
                    console.warn("Guides.transformPoint: Point is out of range (< min): " + point);
                    return newGuides[k].position - delta;
                }
                return newGuides[k].position - (delta / oldGuides[k - 1].size * newGuides[k - 1].size);
            }
        }
        console.warn("Guides.transformPoint: Point is out of range (> max): " + point);
        return newGuides[newGuides.length - 1].position - delta;
    }

    private drawDistanceText(x: number, y: number, number: number, horizontal: boolean, params: PainterParams,
                             className: string = null): Snap.Element[] {
        let rulerWidth = this.rulerWidth;
        let posX = (horizontal ? x : x - (rulerWidth / 2));
        let poxY = (horizontal ? y - (rulerWidth / 2) : y);
        let texts = this.drawTextInAllUnits(TextPainter.forDrawing(this.offerComponent, params), number, [posX, poxY], false, !horizontal);
        if (className) {
            texts.forEach(text => text.addClass(className));
        }
        return texts;
    }

    private drawTextInAllUnits(textPainter: TextPainter, length: number, position: number[], locked = false, vertical = false,
                            useSmallerFont = false): Snap.Element[] {
        const texts: Snap.Element[] = [];

        const mmText = textPainter.paintText('' + length, position, locked, vertical, useSmallerFont);
        mmText.addClass(Guides.MILLIMETERS_UNIT_CLASS);
        texts.push(mmText);

        const inches = UnitConverter.millimitersToInches(length);
        let inchesText = `${inches.full}`;
        if (inches.sixteenths !== 0) {
            inchesText += ` ${inches.sixteenths}/16`;
        }
        const inText = textPainter.paintText(inchesText, position, locked, vertical, useSmallerFont);
        inText.addClass(Guides.INCHES_UNIT_CLASS);
        texts.push(inText);

        return texts;
    }

    private isSegmentOnVerticalGuideEnds(data: DrawingData, points: number[]): boolean {
        let isSegmentHorizontal = points[1] === points[3];
        let onGuideEnds = points[1] === data.guides.vertical.main[0].position || points[1] === data.guides.vertical.main[1].position;
        return isSegmentHorizontal && onGuideEnds;
    }

    private isSegmentOnHorizontalGuideEnds(data: DrawingData, points: number[]): boolean {
        let isSegmentVertical = points[0] === points[2];
        let onGuideEnds = points[0] === data.guides.horizontal.main[0].position || points[0] === data.guides.horizontal.main[1].position;
        return isSegmentVertical && onGuideEnds;
    }

    private removeDegeneratedCuts(data: DrawingData) {
        let cuts = data.cuts;
        let isCutDegenerated = (cut: CutData) => {
            return CutData.isLine(cut) && (this.isSegmentOnHorizontalGuideEnds(data, cut.points)
                || this.isSegmentOnVerticalGuideEnds(data, cut.points));
        };
        for (let i = 0; i < cuts.length;) {
            let cut = cuts[i];
            if (isCutDegenerated(cut)) {
                cuts.splice(i, 1);
            } else {
                ++i;
            }
        }
    }

    private drawToolRulers(svg: Snap.Paper, params: PainterParams): void {
        if (this.offerComponent.visibilitySettings.toolDimensionLines) {
            let computedRulerHeight = 0;
            let selection = this.offerComponent.clickedSnapElements;
            let toolPoints = this.decideWhatRulersToShow(selection);
            if (toolPoints.left.length === 0 && toolPoints.top.length === 0) {
                return;
            }
            let subWindow = this.currentSubwindowPoints;
            // Vertical
            if (toolPoints.left.length > 0) {
                let secondPoint = toolPoints.left.length === 2 ? toolPoints.left[1] : toolPoints.left[0];
                computedRulerHeight =
                    this.drawToolRuler(svg, true, true, subWindow.maxY, toolPoints.left[0], toolPoints.left.length ===
                        2, params, toolPoints.left[0], 1).x;
                this.drawToolRuler(svg, true, true, secondPoint, subWindow.minY, true, params, secondPoint, -1);
                this.hideStructureGuideLines = true;
            } else {
                computedRulerHeight = this.drawToolRuler(svg, false, true, this.yMin, this.yMax, true, params).x;
            }
            // Horizontal
            if (toolPoints.top.length > 0) {
                let secondPoint = toolPoints.top.length === 2 ? toolPoints.top[1] : toolPoints.top[0];
                computedRulerHeight =
                    this.drawToolRuler(svg, true, false, subWindow.minX, toolPoints.top[0], toolPoints.top.length === 2,
                        params, toolPoints.top[0], 1).y;
                this.drawToolRuler(svg, true, false, secondPoint, subWindow.maxX, true, params, secondPoint, -1);
                this.hideStructureGuideLines = true;
            } else {
                computedRulerHeight = this.drawToolRuler(svg, false, false, this.xMin, this.xMax, true, params).y;
            }
            this.distanceFromWindows += computedRulerHeight + this.rulerWidth / 2;
        }
    }

    private drawToolRuler(svg: Snap.Paper, active: boolean, vertical: boolean, start: number, end: number, drawEnd: boolean,
                          params: PainterParams, selectedElementPosition?: number, deltaMultiplier?: number): Point {
        let g = svg.g();
        let dottedLineGroup = svg.g();
        let farPoint = (vertical ? this.offerComponent.totalBoundingBox.minX : this.offerComponent.totalBoundingBox.minY)
            - this.distanceFromWindows;
        let nearPoint = farPoint + this.rulerWidth;
        let middlePoint = (farPoint + nearPoint) / 2;
        let guideEnd = (vertical ? this.xMax : this.yMax) + this.distanceFromWindows;
        g.add(svg.line(vertical ? middlePoint : start, vertical ? start : middlePoint, vertical ? middlePoint : end,
            vertical ? end : middlePoint));
        g.add(svg.line(vertical ? farPoint : start, vertical ? start : farPoint, vertical ? nearPoint : start,
            vertical ? start : nearPoint));
        if (active) {
            dottedLineGroup.add(svg.line(vertical ? nearPoint : start, vertical ? start : nearPoint,
                vertical ? guideEnd : start, vertical ? start : guideEnd).attr(Guides.guideAttr));
        }
        if (drawEnd) {
            g.add(svg.line(vertical ? farPoint : end, vertical ? end : farPoint, vertical ? nearPoint : end, vertical ? end : nearPoint));
            if (active) {
                dottedLineGroup.add(svg.line(vertical ? nearPoint : end, vertical ? end : nearPoint, vertical ? guideEnd : end,
                    vertical ? end : guideEnd).attr(Guides.guideAttr));
            }
        }

        let textPainter = TextPainter.forDrawing(this.offerComponent, params);
        let texts = this.drawTextInAllUnits(textPainter,
            FloatOps.round((end - start)),
            vertical ? [farPoint, (end + start) / 2] : [(end + start) / 2, farPoint],
            false, vertical);
        texts.forEach(text => {
            if (active && this.offerComponent.canClickGuides()) {
                text.click(event => {
                    event.stopPropagation();
                    this.onClickToolGuideTextHandler(vertical, (end - start), selectedElementPosition, deltaMultiplier);
                });
            }
            g.add(text);
        });
        g.attr(active ? Guides.rulerAttr : Guides.inactiveGuideAttr);

        return vertical
            ? new Point(this.rulerWidth + textPainter.getFontSize(false), end - start)
            : new Point(end - start, this.rulerWidth + textPainter.getFontSize(false));
    }

    private drawStructureRulers(svg: Snap.Paper, vGuides: Guide[], hGuides: Guide[],
                                positions: ElementsPositionsForGuides, params: PainterParams): void {
        if (!this.offerComponent.visibilitySettings.frameDimensionLines) {
            return;
        }
        let computedRulerHeight = 0;
        if (!params.paintOnlyStructureGuides) {
            let textPainter = TextPainter.forDrawing(this.offerComponent, params);
            let parentGroup = svg.g();
            parentGroup.addClass(Guides.DIMENSIONS_GROUP_CLASS);
            let g = parentGroup.g();
            g.addClass(Guides.STRUCTURE_DIMENSIONS_GROUP_CLASS);
            let dottedLineGroup = svg.g();
            g.add(dottedLineGroup);
            if (hGuides.length > 0) {
                let hStructureBegin = this.offerComponent.totalBoundingBox.minY - this.distanceFromWindows;
                let hStructureEnd = this.yMax + this.distanceFromWindows;
                for (let i = 0; i < hGuides.length; i++) {
                    let guide = hGuides[i];
                    if (i < hGuides.length - 1) {
                        g.add(svg.line(guide.position, hStructureBegin + (this.rulerWidth / 2),
                            GuideHelper.end(guide), hStructureBegin + (this.rulerWidth / 2)).attr(Guides.rulerAttr));
                        if (this.offerComponent.visibilitySettings.dimensionValues) {
                            let texts = this.drawTextInAllUnits(textPainter, Math.round(guide.size),
                                [GuideHelper.middle(guide), hStructureBegin], guide.locked);
                            texts.forEach(text => {
                                if (this.offerComponent.canClickGuides()) {
                                    text.click(event => this.onClickGuideTextHandler(event, false, guide));
                                }
                                g.add(text);
                            });
                        }
                    }
                    if (!this.hideStructureGuideLines) {
                        dottedLineGroup.add(svg.line(guide.position, hStructureBegin, guide.position, hStructureEnd)
                            .attr(Guides.guideAttr));
                    }
                    g.add(svg.line(guide.position, hStructureBegin, guide.position, hStructureBegin + this.rulerWidth)
                        .attr(Guides.rulerAttr));
                    if (this.offerComponent.visibilitySettings.coordinatesValues) {
                        g.add(this.drawDistanceText(guide.position, hStructureBegin, guide.position - this.xMin, true, params));
                    }
                }
                computedRulerHeight = this.rulerWidth;
                if (this.offerComponent.visibilitySettings.dimensionValues) {
                    computedRulerHeight += textPainter.getFontSize(false);
                }
            }
            if (vGuides.length > 0) {
                let vStructureBegin = this.offerComponent.totalBoundingBox.minX - this.distanceFromWindows;
                let vStructureEnd = this.xMax + this.distanceFromWindows;
                for (let i = 0; i < vGuides.length; i++) {
                    let guide = vGuides[i];
                    if (i < vGuides.length - 1) {
                        g.add(svg.line(vStructureBegin + (this.rulerWidth / 2), guide.position,
                            vStructureBegin + (this.rulerWidth / 2), this.yMax).attr(Guides.rulerAttr));
                        if (this.offerComponent.visibilitySettings.dimensionValues) {
                            let texts = this.drawTextInAllUnits(textPainter, Math.round(guide.size),
                                [vStructureBegin, GuideHelper.middle(guide)], guide.locked, true);
                            texts.forEach(text => {
                                if (this.offerComponent.canClickGuides()) {
                                    text.click(event => this.onClickGuideTextHandler(event, true, guide));
                                }
                                g.add(text);
                            });
                        }
                    }
                    if (!this.hideStructureGuideLines) {
                        dottedLineGroup.add(svg.line(vStructureBegin, guide.position, vStructureEnd, guide.position)
                            .attr(Guides.guideAttr));
                    }
                    g.add(svg.line(vStructureBegin, guide.position, vStructureBegin + this.rulerWidth, guide.position)
                        .attr(Guides.rulerAttr));
                    if (this.offerComponent.visibilitySettings.coordinatesValues) {
                        g.add(this.drawDistanceText(vStructureBegin, guide.position, guide.position - this.yMin, false, params));
                    }
                }
            }
            g.add(this.drawMullionsAndGrillsPositions(positions, params));
        } else {
            computedRulerHeight = this.rulerWidth;
        }
        this.distanceFromWindows += computedRulerHeight + (this.rulerWidth / 2);
    }

    private drawMainRulers(svg: Snap.Paper, vGuides: Guide[], hGuides: Guide[], params: PainterParams): void {
        // total dimensions rulers
        if (this.offerComponent.visibilitySettings.totalDimensionLines) {
            let textPainter = TextPainter.forDrawing(this.offerComponent, params);
            let g: Snap.Element = svg.g();
            g.addClass(Guides.DIMENSIONS_GROUP_CLASS);
            let topRulerPosY = this.offerComponent.totalBoundingBox.minY - this.distanceFromWindows;
            let leftRulerPosX = this.offerComponent.totalBoundingBox.minX - this.distanceFromWindows;
            g.add(this.drawHorizontalDimensionLine(svg, this.xMin, this.xMax, topRulerPosY, params));
            // dimensions
            let dimensionValuesHeight = 0;
            if (this.offerComponent.visibilitySettings.dimensionValues) {
                let texts = this.drawTextInAllUnits(textPainter, this.vRulerLength, [this.xMin + (this.vRulerLength / 2), topRulerPosY],
                    hGuides[0].locked);
                texts.forEach(text => {
                    if (this.offerComponent.canClickGuides()) {
                        text.click(event => this.onClickMainGuideHandler(event, false));
                    }
                    g.add(text);
                });
                dimensionValuesHeight = Math.max(...texts.map(text => textPainter.measureText(text).y));
            }

            // coordinates
            let coordinatesValuesHeight = 0;
            if (this.offerComponent.visibilitySettings.coordinatesValues) {
                let texts = this.drawDistanceText(this.xMin, topRulerPosY, 0, true, params);
                texts.forEach(text => g.add(text));
                this.drawDistanceText(this.xMax, topRulerPosY, this.vRulerLength, true, params).forEach(text => g.add(text));
                coordinatesValuesHeight = this.rulerWidth / 2 + Math.max(...texts.map(text => textPainter.measureText(text).y));
            }
            g.add(this.drawVerticalDimensionLine(svg, leftRulerPosX, this.yMin, this.yMax, params));
            // dimensions
            if (this.offerComponent.visibilitySettings.dimensionValues) {
                let texts = this.drawTextInAllUnits(textPainter, this.hRulerLength, [leftRulerPosX, this.yMin + (this.hRulerLength / 2)],
                    vGuides[0].locked, true);
                texts.forEach(text => {
                    if (this.offerComponent.canClickGuides()) {
                        text.click(event => this.onClickMainGuideHandler(event, true));
                    }
                    g.add(text);
                });
            }
            // coordinates
            if (this.offerComponent.visibilitySettings.coordinatesValues) {
                this.drawDistanceText(leftRulerPosX, this.yMin, 0, false, params).forEach(text => g.add(text));
                this.drawDistanceText(leftRulerPosX, this.yMax, this.hRulerLength, false, params).forEach(text => g.add(text));
            }
            this.distanceFromWindows += this.rulerWidth + Math.max(dimensionValuesHeight, coordinatesValuesHeight);
        }
    }

    private decideWhatRulersToShow(selection: { type: string; elements: Snap.Element[] }): { left: number[], top: number[] } {
        let emptyResult: { left: number[], top: number[] } = {left: [], top: []};
        if (this.offerComponent.mode === Tool.SELECT && selection.elements.length > 0) {
            switch (selection.type) {
                case WindowParams.MULLION_ELEM:
                    return this.showRulersForGrillsOrMullions(selection.elements, DataKeys.MULLION, DataKeys.INNER_FRAME,
                        PolygonPointUtil.toNumbersArray);
                case WindowParams.MUNTIN_ELEM:
                    return this.showRulersForGrillsOrMullions(selection.elements, DataKeys.GRILL, DataKeys.GLAZING_BEAD,
                        PolygonPointUtil.toNumbersArray);
                case WindowParams.HANDLE_ELEM:
                    let matchingCoords: Point = {x: undefined, y: undefined};
                    for (let element of selection.elements) {
                        let selectedHandle = element.data(DataKeys.HANDLE);
                        let subWindow = element.data(DataKeys.SUBWINDOW);
                        let cuts = this.offerComponent.data.cuts;
                        let framePoints = WindowCalculator.getWingCenterPoints(subWindow, cuts, this.offerComponent.totalBoundingBox,
                            this.offerComponent.profileCompositionDistances, this.offerComponent.isValidationDisabled());
                        let centerPoint = WindowCalculator.getSubWindowCenterPoint(subWindow, cuts, this.offerComponent.totalBoundingBox,
                            this.offerComponent.profileCompositionDistances, this.offerComponent.isValidationDisabled());
                        let point = HandleHelper.relativeToAbsolute(selectedHandle, framePoints, centerPoint);
                        let pathIntersectingSegment = HandleHelper.findIntersectingLine(selectedHandle, framePoints, centerPoint);
                        this.currentSubwindowPoints = DrawingUtil.calculateMinMaxFromPolygon(subWindow.points);
                        if (element === selection.elements[0]) {
                            if (!DrawingUtil.isLineHorizontal(pathIntersectingSegment)) {
                                matchingCoords.y = point.y;
                            }
                            if (!DrawingUtil.isLineVertical(pathIntersectingSegment)) {
                                matchingCoords.x = point.x;
                            }
                        } else {
                            if (FloatOps.round(matchingCoords.x) !== FloatOps.round(point.x)) {
                                matchingCoords.x = undefined;
                            }
                            if (FloatOps.round(matchingCoords.y) !== FloatOps.round(point.y)) {
                                matchingCoords.y = undefined;
                            }
                        }
                    }
                    return {
                        left: matchingCoords.y ? [matchingCoords.y] : [],
                        top: matchingCoords.x ? [matchingCoords.x] : []
                    };
                default:
                    return emptyResult;
            }
        }
        return emptyResult;
    }

    private checkIfGrillsAreOnTheSameLine(grillsWithSubwindows: { grill: Grill, subwindow: SubWindowData }[],
                                          polygonMap: Map<SubWindowData, number[]>): { left: number[], top: number[] } {
        let matchingXCoords: number[] = [];
        let matchingYCoords: number[] = [];
        this.currentSubwindowPoints = DrawingUtil.calculateMinMaxFromPolygon(grillsWithSubwindows[0].subwindow.points);
        for (let gws of grillsWithSubwindows) {
            let grill = gws.grill;
            let subWindow = gws.subwindow;
            switch (grill.type) {
                case GrillType.LINE_GRILL:
                case GrillType.MULLION:
                    let xCoords: number[] = [];
                    let yCoords: number[] = [];
                    let segment = grill.drawingSegments[0];
                    let polygon = polygonMap.get(subWindow);
                    if (GrillSegment.isLine(segment)) {
                        for (let positionIndex in grill.positions) {
                            let skipPerpendicularityCheck;
                            let line = [];
                            switch (grill.positions[positionIndex].type) {
                                case (PositionReferenceType.VENEER):
                                case (PositionReferenceType.WEBSHOP_VENEER_HORIZONTAL):
                                case (PositionReferenceType.WEBSHOP_VENEER_VERTICAL):
                                    return {left: [], top: []};
                                case (PositionReferenceType.INNER_FRAME):
                                case (PositionReferenceType.GLAZING_BEAD):
                                    skipPerpendicularityCheck = false;
                                    line = PositionsHelper.findLineOnPolygon(grill.positions[positionIndex].id, polygon);
                                    break;
                                case (PositionReferenceType.MULLION):
                                case (PositionReferenceType.GRILL):
                                    skipPerpendicularityCheck = true;
                                    line = segment.points;
                                    break;
                                default:
                                    let err = new Error("Not supported reference type");
                                    err.name = ErrorNames.GENERAL_ERROR;
                                    throw err;
                            }
                            let point = segment.points.slice(+positionIndex * 2, +positionIndex * 2 + 2);
                            if (!DrawingUtil.isPointAPolygonVertex(point, polygon)) {
                                this.addCoordsIfNotOnPerpendicularLine(xCoords, yCoords, point, line, skipPerpendicularityCheck);
                            }
                        }
                    } else {
                        console.warn("Unsupported grill segment type (checkIfGrillsAreOnTheSameLine)");
                    }
                    if (gws === grillsWithSubwindows[0]) {
                        matchingXCoords = xCoords;
                        matchingYCoords = yCoords;
                    } else {
                        matchingXCoords = this.updateMatchingCoords(matchingXCoords, xCoords);
                        matchingYCoords = this.updateMatchingCoords(matchingYCoords, yCoords);
                    }
                    break;
                default:
                    console.warn("Unsupported grill type (checkIfGrillsAreOnTheSameLine)");
                    return {left: [], top: []};
            }
        }

        return {left: matchingYCoords.sort((a, b) => a - b), top: matchingXCoords.sort((a, b) => a - b)};
    }

    private addUniqueCoord(coords: number[], pos: number) {
        if (coords.indexOf(pos) === -1) {
            coords.push(pos);
        }
    }

    private changeToolSize(valueDelta: number): OperationResult {
        let operationResult = new OperationResult();
        let selection = this.offerComponent.clickedSnapElements;
        if (this.offerComponent.mode !== Tool.SELECT || selection.elements.length === 0) {
            console.warn("changeToolSize(): No element selected or selection mode not active");
            const error = new Error('changeToolSize(): No element selected or selection mode not active');
            error.name = ErrorNames.SELECTED_ELEMENT_DOES_NOT_EXIST;
            throw error;
        }
        let newPosition = this.toolElementData.selectedElementPosition + (valueDelta * this.toolElementData.deltaMultiplier);
        switch (selection.type) {
            case WindowParams.MUNTIN_ELEM:
                let subwindows: Set<SubWindowData> = new Set();
                selection.elements.forEach(element => {
                    let grill = element.data(DataKeys.GRILL);
                    subwindows.add(element.data(DataKeys.SUBWINDOW));
                    if (grill.type === GrillType.LINE_GRILL) {
                        this.changeRelativePositionsForSingleGrill(grill, element, newPosition);
                    }
                });
                subwindows.forEach(subwindow => {
                    let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, this.offerComponent.data.cuts,
                        this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                        this.offerComponent.isValidationDisabled());
                    PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, this.offerComponent.profileCompositionDistances);
                });
                break;
            case WindowParams.HANDLE_ELEM:
                selection.elements.forEach(element => {
                    let handle = element.data(DataKeys.HANDLE);
                    let subWindow = element.data(DataKeys.SUBWINDOW);
                    let framePoints = WindowCalculator.getWingCenterPoints(subWindow, this.offerComponent.data.cuts,
                        this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                        this.offerComponent.isValidationDisabled());
                    let centerPoint = WindowCalculator.getSubWindowCenterPoint(subWindow, this.offerComponent.data.cuts,
                        this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                        this.offerComponent.isValidationDisabled());
                    HandleHelper.changeRelativePosition(handle, framePoints, centerPoint, newPosition, this.toolElementData.vertical);
                });
                break;
            case WindowParams.MULLION_ELEM:
                let subwindowInnerframesMap: Set<SubWindowData> = new Set<SubWindowData>();
                selection.elements.forEach(element => {
                    let mullion = element.data(DataKeys.MULLION);
                    let subwindow = element.data(DataKeys.SUBWINDOW);
                    subwindowInnerframesMap.add(subwindow);
                    this.changeRelativePositionsForSingleGrill(mullion, element, newPosition);
                });
                subwindowInnerframesMap.forEach(subwindow => {
                    let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow,
                        this.offerComponent.data.cuts,
                        this.offerComponent.totalBoundingBox,
                        this.offerComponent.profileCompositionDistances,
                        this.offerComponent.isValidationDisabled());
                    operationResult.merge(
                        MullionUtils.updateAbsoluteMullionPositions(subwindow, this.offerComponent.data.cuts,
                            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                            this.offerComponent.data.shape, true,
                            this.offerComponent.isValidationDisabled() || this.offerComponent.isTerrace));
                    PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads,
                        this.offerComponent.profileCompositionDistances);
                });
                break;
            default:
                console.warn("Moving elements is not implemented for ", selection.type);
                break;
        }
        return operationResult;
    }

    private addCoordsIfNotOnPerpendicularLine(xCoords: number[], yCoords: number[], point: number[], line: number[],
                                              skipPerpendicularityCheck = false) {
        if (skipPerpendicularityCheck || !DrawingUtil.isLineHorizontal(line)) {
            this.addUniqueCoord(yCoords, FloatOps.round(point[1]));
        }
        if (skipPerpendicularityCheck || !DrawingUtil.isLineVertical(line)) {
            this.addUniqueCoord(xCoords, FloatOps.round(point[0]));
        }
    }

    private updateMatchingCoords(matchingCoords: number[], coords: number[]) {
        return matchingCoords.filter(n => coords.indexOf(FloatOps.round(n)) !== -1);
    }

    private changeRelativePositionsForSingleGrill(grill: Grill, element: Snap.Element, newPosition: number) {
        let segment = grill.drawingSegments[0] as LineGrillSegment;
        for (let i = this.toolElementData.vertical ? 1 : 0; i < 4; i += 2) {
            if (Math.abs(segment.points[i] - this.toolElementData.selectedElementPosition) < 1) { // threshold = 1
                GrillUtils.changeRelativePosition(grill, element, Math.floor(i / 2), newPosition,
                    !this.toolElementData.vertical);
            }
        }
    }

    private showRulersForGrillsOrMullions(elements: Snap.Element[], objectKey: string, polygonKey: string,
                                          polygonDataTransform: (data: any) => number[]) {
        let grillsWithSubwindows: { grill: Grill, subwindow: SubWindowData }[] = [];
        let polygonMap = new Map<SubWindowData, number[]>();
        for (let element of elements) {
            let subWindow = element.data(DataKeys.SUBWINDOW);
            let selected = element.data(objectKey);
            grillsWithSubwindows.push({grill: selected, subwindow: subWindow});
            if (!polygonMap.get(subWindow)) {
                let polygon = polygonDataTransform(element.data(polygonKey));
                polygonMap.set(subWindow, polygon);
            }
        }
        if (grillsWithSubwindows.length > 0) {
            return this.checkIfGrillsAreOnTheSameLine(grillsWithSubwindows, polygonMap);
        }
    }

    private drawMullionsAndGrillsPositions(positions: ElementsPositionsForGuides, params: PainterParams): Snap.Element {
        let group = this.offerComponent.svg.g();
        if (this.offerComponent.visibilitySettings.mullionPositions || this.offerComponent.visibilitySettings.muntinPositions) {
            let selectionPresent = this.offerComponent.clickedSnapElements.elements.length > 0;
            if (selectionPresent) {
                return group;
            }
            if (this.offerComponent.visibilitySettings.mullionPositions) {
                group.add(this.drawPositions(positions.xMullions, positions.yMullions, params,
                    true, true, Guides.MULLION_GROUP_MIDDLE_CLASS));
                group.add(this.drawPositions(positions.xMullionsTop, positions.yMullionsTop, params,
                    true, true, Guides.MULLION_GROUP_TOP_CLASS));
            }
            if (this.offerComponent.visibilitySettings.muntinPositions) {
                let allGrillsX = _.unique([...positions.xLineGrills, ...positions.xGrillGrids]);
                let allGrillsY = _.unique([...positions.yLineGrills, ...positions.yGrillGrids]);
                group.add(this.drawPositions(allGrillsX, allGrillsY, params));
            }
        }
        return group;
    }

    private drawPositions(xCoords: number[], yCoords: number[], params: PainterParams, drawText = true, drawLine = true,
                          className = null): Snap.Element {
        let svg = this.offerComponent.svg;
        let group = svg.g();
        let bbox = this.offerComponent.totalBoundingBox;
        let textPainter = TextPainter.forDrawing(this.offerComponent, params);
        let distanceFromWindows = this.distanceFromWindows;
        let xLineStart = this.offerComponent.totalBoundingBox.minX - distanceFromWindows +
            (params.isRegularMode() ? (this.rulerWidth / 2) : 0);
        let xLineEnd = this.offerComponent.totalBoundingBox.minX - distanceFromWindows + this.rulerWidth;
        let yLineStart = this.offerComponent.totalBoundingBox.minY - distanceFromWindows +
            (params.isRegularMode() ? (this.rulerWidth / 2) : 0);
        let yLineEnd = this.offerComponent.totalBoundingBox.minY - distanceFromWindows + this.rulerWidth;
        xCoords.forEach(x => {
            this.drawPosition(true, x, yLineStart, yLineEnd, bbox, svg, textPainter, group, drawText, params, drawLine);
        });
        yCoords.forEach(y => {
            this.drawPosition(false, y, xLineStart, xLineEnd, bbox, svg, textPainter, group, drawText, params, drawLine);
        });
        if (className) {
            group.addClass(className);
        }
        return group;
    }

    private drawPosition(vertical: boolean, pos: number, lineStart: number, lineEnd: number, bbox: MinMaxXY,
                         svg: Snap.Paper, textPainter: TextPainter, group: Snap.Element, drawText = true,
                         params: PainterParams, drawLine = true): void {
        let x1 = vertical ? pos : lineStart;
        let y1 = vertical ? lineStart : pos;
        let x2 = vertical ? pos : lineEnd;
        let y2 = vertical ? lineEnd : pos;
        let min = vertical ? bbox.minX : bbox.minY;
        let textValue = (vertical ? x1 : y1) - min;
        if (textValue < 0) {
            return;
        }
        if (drawLine) {
            let line = [x1, y1, x2, y2];
            group.add(ScalingPainter.line(svg, line, {stroke: '#030303'}, params));
        }
        if (!drawText) {
            return;
        }
        if (!params.isRegularMode()) {
            this.drawTextInAllUnits(textPainter, textValue, [x1, y1], false, !vertical, false)
                .forEach(t => group.add(t));
            return;
        }
        if (vertical) {
            this.drawTextInAllUnits(textPainter, textValue, [x1, y1 + lineEnd - lineStart], false, false).forEach(text => {
                group.add(text);
                let size = +/^(.*?)(?:px)?$/.exec(text.attr('font-size'))[1];
                text.transform('t0,' + size);
                text.attr({strokeWidth: 0, fontStyle: 'italic', pointerEvents: 'none'});
            });
        } else {
            this.drawTextInAllUnits(textPainter, textValue, [x1 + lineEnd - lineStart, y1], false, true).forEach(text => {
                group.add(text);
                let size = +/^(.*?)(?:px)?$/.exec(text.attr('font-size'))[1];
                text.transform(this.offerComponent.createSnapMatrix().translate(size, 0).rotate(-90, x1 + lineEnd - lineStart, y1));
                text.attr({strokeWidth: 0, fontStyle: 'italic', pointerEvents: 'none'});
            });
        }
    }

    private findElementsPositions(topEdgeDimension: boolean): ElementsPositionsForGuides {
        let positions = new ElementsPositionsForGuides();
        let cuts: CutData[] = this.offerComponent.data.cuts;
        let bbox: MinMaxXY = this.offerComponent.totalBoundingBox;
        let windows: WindowData[] = this.offerComponent.data.windows;
        let addPositions = (element: Grill, box: MinMaxXY, xCoords: number[], yCoords: number[], polygon: PolygonPoint[], mullionYCoords: number[] = []) => {
            element.drawingSegments.map(s => GrillHelper.expectLineSegment(s)).forEach(segment => {
                let points = [segment.points.slice(0, 2), segment.points.slice(2, 4)];
                if (mullionYCoords.length > 0) {
                    points[0][1] = mullionYCoords[0];
                    points[1][1] = mullionYCoords[3];
                }
                points.forEach(point => {
                    if (!DrawingUtil.isPointOnArc(point, polygon)) {
                        if (!FloatOps.eq(point[0], box.minX) && !FloatOps.eq(point[0], box.maxX) &&
                            (element.type === GrillType.MULLION ?
                                !FloatOps.eq(element.positionsPoints[1], element.positionsPoints[3]) : true)) {
                            this.addUniqueCoord(xCoords, FloatOps.round(point[0]));
                        }
                        if (!FloatOps.eq(point[1], box.minY) && !FloatOps.eq(point[1], box.maxY) &&
                            (element.type === GrillType.MULLION ?
                                !FloatOps.eq(element.positionsPoints[0], element.positionsPoints[2]) : true)) {
                            this.addUniqueCoord(yCoords, FloatOps.round(point[1]));
                        }
                    }
                });
            });
        };
        windows.forEach(w => {
            w.subWindows.forEach(sw => {
                this.addUniqueCoord(positions.xSubwindows, FloatOps.round(sw.points[0]));
                this.addUniqueCoord(positions.ySubwindows, FloatOps.round(sw.points[1]));
                this.addUniqueCoord(positions.xSubwindows, FloatOps.round(sw.points[2]));
                this.addUniqueCoord(positions.ySubwindows, FloatOps.round(sw.points[3]));
                let totalInnerFrameFull = WindowCalculator.getTotalFrameInnerEdgePointsFull(sw, cuts, bbox,
                    this.offerComponent.profileCompositionDistances, this.offerComponent.isValidationDisabled());
                let totalGlazingBeadFull = WindowCalculator.getTotalGlazingBeadsPointsFull(sw, cuts, bbox,
                    this.offerComponent.profileCompositionDistances, this.offerComponent.isValidationDisabled());
                if (this.offerComponent.visibilitySettings.mullionPositions) {
                    let totalInnerFrame = PolygonPointUtil.toNumbersArray(totalInnerFrameFull);
                    let box = DrawingUtil.calculatePolygonTotalBoundingBox(totalInnerFrame);
                    sw.mullions.forEach(m => {
                        addPositions(m, box, positions.xMullions, positions.yMullions, totalInnerFrameFull);
                        if (topEdgeDimension) {
                            const mullionPoints = MullionUtils.getMullionPoints(m, totalInnerFrame, sw,
                                new ProfilesCompositionDistances(), !WindowCalculator.isSubWindowF(sw));
                            const yCoords = DrawingUtil.getYCoordinates(mullionPoints);
                            addPositions(m, box, positions.xMullionsTop, positions.yMullionsTop, totalInnerFrameFull, yCoords);
                        }
                    });
                }
                if (this.offerComponent.visibilitySettings.muntinPositions) {
                    sw.areasSpecification.forEach(a => {
                        let glazingBeadFull = WindowCalculator.getGlazingBeadPointsFull(sw, a.definingMullions, totalGlazingBeadFull,
                            this.offerComponent.profileCompositionDistances);
                        let glazingBead = PolygonPointUtil.toNumbersArray(glazingBeadFull);
                        let glazingBeadBox = DrawingUtil.calculatePolygonTotalBoundingBox(glazingBead);
                        a.grills.forEach(g => {
                            if (g.type === GrillType.LINE_GRILL) {
                                addPositions(g, glazingBeadBox, positions.xLineGrills, positions.yLineGrills, glazingBeadFull);
                            } else {
                                let box = DrawingUtil.calculatePolygonTotalBoundingBox(GrillHelper.expectSingleParentField(g).positions);
                                addPositions(g, box, positions.xGrillGrids, positions.yGrillGrids, glazingBeadFull);
                            }
                        });
                    });
                }
            });
        });

        if (WindowShape.isRectangular(this.offerComponent.data.shape)) {
            cuts.filter(cut => CutData.isLine(cut))
                .forEach((cut: LineCutData) => {
                    this.addUniqueCoord(positions.xCuts, FloatOps.round(cut.points[0]));
                    this.addUniqueCoord(positions.yCuts, FloatOps.round(cut.points[1]));
                    this.addUniqueCoord(positions.xCuts, FloatOps.round(cut.points[2]));
                    this.addUniqueCoord(positions.yCuts, FloatOps.round(cut.points[3]));
                });
        } else {
            let outerFrame = CutsUtil.applyCutsFull(DrawingUtil.getPolygonFromBBox(bbox), cuts, 0);
            for (let i = 0; i < outerFrame.length; i++) {
                let previous = DrawingUtil.getPoint(outerFrame, i - 1);
                let current = DrawingUtil.getPoint(outerFrame, i);
                let next = DrawingUtil.getPoint(outerFrame, i + 1);
                if (current.isArc && (!previous.isArc || !next.isArc)) {
                    this.addUniqueCoord(positions.xCuts, FloatOps.round(current.x));
                    this.addUniqueCoord(positions.yCuts, FloatOps.round(current.y));
                }
            }
        }
        return positions;
    }

    private getAllGuides(): Guide[] {
        let guides = this.offerComponent.data.guides;
        return [...guides.horizontal.main, ...guides.horizontal.structure, ...guides.vertical.main, ...guides.vertical.structure];
    }

    private drawThumbnailDetails(svg: Snap.Paper, positions: ElementsPositionsForGuides, params: PainterParams,
                                 parentGroup: Snap.Element, addDistance: boolean): void {
        let xs = [
            ...positions.xSubwindows,
            ...positions.xMullions,
            ...positions.xLineGrills
        ];
        let ys = [
            ...positions.ySubwindows,
            ...positions.yMullions,
            ...positions.yLineGrills
        ];
        if (xs.length > 0 || ys.length > 0) {
            this.drawThumbnailGuides(svg, xs, ys, params, parentGroup, Guides.MULLION_GROUP_MIDDLE_CLASS);
            this.drawThumbnailGuides(svg, positions.xMullionsTop, positions.yMullionsTop, params, parentGroup,
                Guides.MULLION_GROUP_TOP_CLASS);
            if (addDistance) {
                this.addThumbnailDetailsDistanceFromWindows();
            }
        }
    }

    private drawThumbnailTotalsAndCuts(svg: Snap.Paper, positions: ElementsPositionsForGuides, params: PainterParams,
                                       parentGroup: Snap.Element): void {
        this.drawThumbnailGuides(svg, positions.xCuts, positions.yCuts, params, parentGroup);
    }

    private drawWinglessModeGrillGridGuide(svg: Snap.Paper, positions: ElementsPositionsForGuides, params: PainterParams): void {
        const group = svg.g();
        let allGrillsX = _.unique([...positions.xLineGrills, ...positions.xGrillGrids]);
        let allGrillsY = _.unique([...positions.yLineGrills, ...positions.yGrillGrids]);
        group.add(this.drawPositions(allGrillsX, [], params, true, false).transform("translate(0,45)"));
        group.add(this.drawPositions([], allGrillsY, params, true, false).transform("translate(45,0)"));
    }

    private drawThumbnailGuides(svg: Snap.Paper, xs: number[], ys: number[], params: PainterParams, parentGroup: Snap.Element,
                                mullionGroup: string = null): void {
        xs = _.unique([this.offerComponent.totalBoundingBox.minX,
            ...xs,
            this.offerComponent.totalBoundingBox.maxX]).sort((a, b) => a - b);
        ys = _.unique([this.offerComponent.totalBoundingBox.minY,
            ...ys,
            this.offerComponent.totalBoundingBox.maxY]).sort((a, b) => a - b);
        let previewTopRulerPosY = this.offerComponent.totalBoundingBox.minY - this.distanceFromWindows;
        let previewLeftRulerPosX = this.offerComponent.totalBoundingBox.minX - this.distanceFromWindows;
        if (params.isShaded()) {
            let textPainter = TextPainter.forDrawing(this.offerComponent, params);
            for (let i = 0; i < xs.length - 1; i++) {
                parentGroup.add(this.drawSingleRenderLine(previewTopRulerPosY, xs[i], xs[i + 1], params, textPainter));
            }
            for (let i = 0; i < ys.length - 1; i++) {
                parentGroup.add(this.drawSingleRenderLine(previewLeftRulerPosX, ys[i], ys[i + 1], params, textPainter, true));
            }
        } else {
            parentGroup.add(this.drawHorizontalDimensionLine(svg, this.xMin, this.xMax, previewTopRulerPosY, params));
            parentGroup.add(this.drawVerticalDimensionLine(svg, previewLeftRulerPosX, this.yMin, this.yMax, params));
            parentGroup.add(this.drawPositions(xs, ys, params, false, true, mullionGroup));
            for (let i = 0; i < xs.length - 1; i++) {
                let distance = xs[i + 1] - xs[i];
                let position = (xs[i] + xs[i + 1]) / 2;
                this.drawDistanceText(position, previewTopRulerPosY, distance, true, params, mullionGroup)
                    .forEach(text => parentGroup.add(text));
            }
            for (let i = 0; i < ys.length - 1; i++) {
                let distance = ys[i + 1] - ys[i];
                let position = (ys[i] + ys[i + 1]) / 2;
                this.drawDistanceText(previewLeftRulerPosX, position, distance, false, params, mullionGroup)
                    .forEach(text => parentGroup.add(text));
            }
        }
    }

    private drawRenderThumbnailGuides(svg: Snap.Paper, positions: ElementsPositionsForGuides, params: PainterParams,
                                      parentGroup: Snap.Element): void {
        let xs = [
            ...positions.xSubwindows,
            ...positions.xMullions,
            ...positions.xCuts
        ];
        let ys = [
            ...positions.ySubwindows,
            ...positions.yMullions,
            ...positions.xCuts
        ];
        this.distanceFromWindows = this.distanceFromWindows / 2;
        this.drawThumbnailGuides(svg, xs, ys, params, parentGroup, Guides.MULLION_GROUP_MIDDLE_CLASS);
    }

    private drawSingleRenderLine(rulerPos: number, min: number, max: number, params: PainterParams,
                                 textPainter: TextPainter, vertical = false): Snap.Paper {
        let svg = this.offerComponent.svg;
        let spacingDistance = 6 / params.scale;
        let arrowHead = 4 / params.scale;
        let textBox = 80 / params.scale;
        let minLine: number[];
        let maxLine: number[];
        let minArrow: number[];
        let maxArrow: number[];
        let textElements: Snap.Element[];
        if (vertical) {
            minLine = [
                rulerPos, min + spacingDistance,
                rulerPos, (min + max - textBox) / 2
            ];
            maxLine = [
                rulerPos, (min + max + textBox) / 2,
                rulerPos, max - spacingDistance
            ];
            minArrow = [
                rulerPos - arrowHead, min + spacingDistance + arrowHead,
                rulerPos, min + spacingDistance,
                rulerPos + arrowHead, min + spacingDistance + arrowHead
            ];
            maxArrow = [
                rulerPos - arrowHead, max - spacingDistance - arrowHead,
                rulerPos, max - spacingDistance,
                rulerPos + arrowHead, max - spacingDistance - arrowHead
            ];
            textElements = this.drawTextInAllUnits(textPainter, max - min, [rulerPos, (min + max) / 2], false, true);
        } else {
            minLine = [
                min + spacingDistance, rulerPos,
                (min + max - textBox) / 2, rulerPos
            ];
            maxLine = [
                (min + max + textBox) / 2, rulerPos,
                max - spacingDistance, rulerPos
            ];
            minArrow = [
                min + spacingDistance + arrowHead, rulerPos - arrowHead,
                min + spacingDistance, rulerPos,
                min + spacingDistance + arrowHead, rulerPos + arrowHead
            ];
            maxArrow = [
                max - spacingDistance - arrowHead, rulerPos - arrowHead,
                max - spacingDistance, rulerPos,
                max - spacingDistance - arrowHead, rulerPos + arrowHead
            ];
            textElements = this.drawTextInAllUnits(textPainter, max - min, [(min + max) / 2, rulerPos]);
        }
        let g = svg.g();
        textElements.forEach(textElement => {
            textPainter.centerVertically(textElement);
            textElement.addClass('render-guide');
            g.add(textElement);
        });
        g.add(ScalingPainter.line(svg, minLine, Guides.renderRulerAttr, params));
        g.add(ScalingPainter.line(svg, maxLine, Guides.renderRulerAttr, params));
        g.add(ScalingPainter.path(svg, minArrow, Guides.renderRulerAttr, params, false));
        g.add(ScalingPainter.path(svg, maxArrow, Guides.renderRulerAttr, params, false));
        return g;
    }
}
