<p-dialog id="exit-confirmation-dialog" [(visible)]="visible"
          [closable]="false" [resizable]="false" [modal]="true" [focusOnShow]="false"
          header="{{'OFFER.MENU.CONFIRM_EXIT_WITHOUT_SAVING.HEADER' | translate}}">

    <div class="new-form-600">
        <p>{{'OFFER.MENU.CONFIRM_EXIT_WITHOUT_SAVING.BODY' | translate}}</p>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="exitWithoutSaving.emit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="hideDialog.emit()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
