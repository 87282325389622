import {JsonDeserializable} from '../common/crud-common/crudItem';

@JsonDeserializable
export class ApplicationServerStatus {
    id: number;
    hostname: string;
    applicationName: string;
    applicationVersion: string;
    lastPing: Date;

    static fromJSON(jsonObject: any): ApplicationServerStatus {
        const applicationServerStatus = new ApplicationServerStatus();
        applicationServerStatus.id = jsonObject['id'];
        applicationServerStatus.hostname = jsonObject['hostname'];
        applicationServerStatus.applicationName = jsonObject['applicationName'];
        applicationServerStatus.applicationVersion = jsonObject['applicationVersion'];
        if (jsonObject['lastPing'] != undefined) {
            applicationServerStatus.lastPing = new Date(jsonObject['lastPing']);
        }
        return applicationServerStatus;
    }
}
