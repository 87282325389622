import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

export enum SupportedDisplayUnitSystem {
    METRIC,
    IMPERIAL
}

@Injectable()
export class DisplayUnitService implements OnDestroy {

    private readonly subject: Subject<SupportedDisplayUnitSystem>;
    readonly system: Observable<SupportedDisplayUnitSystem>;
    private readonly subscription: Subscription;

    constructor(translate: TranslateService) {
        this.subject = new BehaviorSubject<SupportedDisplayUnitSystem>(this.getSystemForLanguage(translate.currentLang));
        this.system = this.subject.asObservable();
        this.subscription = translate.onLangChange.subscribe(event => {
            this.subject.next(this.getSystemForLanguage(event.lang));
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subject.complete();
    }

    private getSystemForLanguage(lang: string): SupportedDisplayUnitSystem {
        if (lang === 'ca') {
            return SupportedDisplayUnitSystem.IMPERIAL;
        }
        return SupportedDisplayUnitSystem.METRIC;
    }
}
