import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'underscore';
import {SelectItemFormatters} from "../../offer/window-editor/sidebar/select-item-formatters";
import {CatalogConfiguration} from "../catalog-configuration";
import {CatalogOption} from "../catalog-option/catalog-option";
import {CatalogProperty} from "../catalog-property/catalog-property";

@Component({
    selector: 'app-catalog-configuration-wizard-step',
    templateUrl: './catalog-configuration-wizard-step.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogConfigurationWizardStepComponent implements OnInit {

    @Input()
    catalogConfiguration: CatalogConfiguration;

    @Input()
    value: { [propertyId: number]: number; };

    @Input()
    disabled: boolean;

    properties: CatalogProperty[];
    optionsPerProperty: CatalogOption[][];

    translate: TranslateService;
    selectItemFormatters: SelectItemFormatters;

    constructor(injector: Injector) {
        this.translate = injector.get(TranslateService);
        this.selectItemFormatters = new SelectItemFormatters(this.translate);
    }

    ngOnInit(): void {
        this.properties = this.catalogConfiguration.properties.sort(this.sortByIndex);
        this.optionsPerProperty = [];
        this.properties.forEach(p => {
            this.optionsPerProperty[p.id] =
                this.catalogConfiguration.options.filter(o => _.contains(p.options, o.id)).sort(this.sortByIndex);
        });
    }

    private sortByIndex(a: CatalogProperty | CatalogOption, b: CatalogProperty | CatalogOption): number {
        return a.sortIndex - b.sortIndex;
    }
}
