import * as _ from "underscore";
import {CrossGrillAngled} from "../drawing-data/CrossGrillAngled";
import {CrossGrillSimple} from "../drawing-data/CrossGrillSimple";
import {Grill} from "../drawing-data/Grill";
import {GrillGrid} from "../drawing-data/GrillGrid";
import {GrillGridWithRhombusOnIntersections} from "../drawing-data/GrillGridWithRhombusOnIntersections";
import {GrillSegment} from "../drawing-data/GrillSegment";
import {GrillType} from "../drawing-data/GrillType";
import {LineCutData} from "../drawing-data/LineCutData";
import {LineGrill} from "../drawing-data/LineGrill";
import {LineGrillSegment} from "../drawing-data/LineGrillSegment";
import {Mullion} from "../drawing-data/Mullion";
import {PricingGrillSegment} from "../drawing-data/PricingGrillSegment";
import {DrawingUtil, IntersectionResult, MinMaxXY, Point} from "../drawing-util";
import {CutsUtil} from "./cutUtils";
import {ErrorNames} from "./ErrorNames";
import {FloatOps} from "./float-ops";
import {GrillHelper} from "./grill-helper";
import {PolygonPoint} from "./PolygonPoint";
import {GenericGrillGrid} from "../drawing-data/GenericGrillGrid";
import {TotalGlazingBeads} from "./total-glazing-beads";

export abstract class GrillSegmentGenerator {

    private static RIGHT_ANGLE_THRESHOLD = 0.002; // ~0.115 degree

    public static updateGridGrillSegments(grill: GenericGrillGrid, totalGlazingBeads: TotalGlazingBeads): void {
        let parentField = GrillHelper.expectSingleParentField(grill).positions;
        let polygon = grill.winglessMode ? totalGlazingBeads.forcedForF : parentField;

        if (polygon == null || polygon.length < 6) {
            let err = new Error("GrillSegmentGenerator.updateGridGrillSegments(): Polygon is: " + polygon);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        switch (grill.type) {
            case GrillType.GRID_STANDARD:
                this.updateGrillGridSegments(grill as GrillGrid, polygon);
                break;
            case GrillType.GRID_CROSS_ANGLED:
                this.updateCrossGrillAngledSegments(grill as CrossGrillAngled, polygon);
                break;
            case GrillType.GRID_CROSS_SIMPLE:
                this.updateCrossGrillSimpleSegments(grill as CrossGrillSimple, polygon);
                break;
            case GrillType.GRID_RHOMBUS:
                this.updateGrillGridWithRhombusOnIntersectionsSegments(
                    grill as GrillGridWithRhombusOnIntersections, polygon);
                break;
            default:
                let err = new Error(
                    "GrillSegmentGenerator: updateGridGrillSegments() is not implemented for grill type: " +
                    grill.type);
                err.name = ErrorNames.NOT_IMPLEMENTED;
                throw err;
        }
        if (grill.winglessMode) {
            GrillHelper.trimGrillSegmentsToPolygon(grill, parentField);
        }
    }

    public static validate(grill: Grill, targetArea: Snap.Element): { [error: string]: string } {
        let errors: { [error: string]: string } = {};
        switch (grill.type) {
            case GrillType.LINE_GRILL:
                errors = this.validateLineGrill(grill as LineGrill);
                break;
            case GrillType.GRID_STANDARD:
                errors = this.validateGrillGrid(grill as GrillGrid);
                break;
            case GrillType.GRID_CROSS_ANGLED:
                errors = this.validateCrossGrillAngled(grill as CrossGrillAngled);
                break;
            case GrillType.GRID_CROSS_SIMPLE:
                errors = this.validateCrossGrillSimple(grill as CrossGrillSimple);
                break;
            case GrillType.GRID_RHOMBUS:
                errors = this.validateGrillGridWithRhombusOnIntersections(grill as GrillGridWithRhombusOnIntersections,
                    targetArea);
                break;
            case GrillType.MULLION:
                errors = this.validateMullion(grill as Mullion);
                break;
            default:
                let err = new Error(
                    "GrillSegmentGenerator: validate() is not implemented for grill type: " + grill.type);
                err.name = ErrorNames.NOT_IMPLEMENTED;
                throw err;
        }
        if (grill.type !== GrillType.MULLION && !grill.colorId) {
            errors['color'] = 'error.drawingGrillParameters.color';
        }
        return errors;
    }

    private static validateGrillId(errors: { [error: string]: string }, grill: Grill): void {
        if (!grill.id || !grill.width) {
            errors['grill'] = 'error.drawingGrillParameters.grill';
        }
    }

    // ==========================================================================================================
    // ----------------------------------------- Pricing Segments -----------------------------------------------
    // ==========================================================================================================

    public static createPricingSegments(grills: Grill[], polygon: PolygonPoint[], addNewGrillMode = false,
                                        skipValidation = false, skipSplittingSegments = false): void {
        let intersectionsBySegmentId: { [id: number]: IntersectionInfo[] } = this.calculateAllSegmentIntersectionPointsGroupedByIds(
            grills);
        grills.forEach(grill => {
            grill.pricingSegments = [];
            grill.drawingSegments.forEach(segment => {
                let splitPoints = skipSplittingSegments ? [] :
                    (intersectionsBySegmentId[segment.id] || []).filter(i => !i.onVertex).map(i => i.point, []);
                grill.pricingSegments.push(...this.getSplittedSegments(
                    GrillHelper.expectLineSegment(segment), splitPoints));
            });
        });
        this.setSegmentsAngularity(grills, polygon, intersectionsBySegmentId);
        if (!skipValidation) {
            this.checkSegmentsAngularity(grills, addNewGrillMode);
        }
    }

    private static setSegmentsAngularity(grills: Grill[], polygon: PolygonPoint[],
                                         intersectionsBySegmentId: { [id: number]: IntersectionInfo[] }): void {
        for (let grillIndex in grills) {
            let grill = grills[grillIndex];
            let possibleIntersectingSegments = _.flatten(
                grill.drawingSegments.filter(GrillSegment.isLine).map(
                    s => (intersectionsBySegmentId[s.id] || []).map(i => i.intersectingSegment, []), []));
            for (let segment of grill.pricingSegments) {
                let currentSegment = GrillHelper.expectPricingSegment(segment);
                currentSegment.angled = false;
                if (grill.type === GrillType.GRID_RHOMBUS) {
                    currentSegment.angled = true;
                    continue;
                }
                let intersectionCheckPoints = DrawingUtil.extrapolateSegment(currentSegment.points);
                let sideA = DrawingUtil.getParallelLine(intersectionCheckPoints, grill.width / 2);
                let sideB = DrawingUtil.getParallelLine(intersectionCheckPoints, -grill.width / 2);

                for (let i = 0; i < polygon.length; i ++) {
                    let line = [
                        DrawingUtil.getPoint(polygon, i).x,
                        DrawingUtil.getPoint(polygon, i).y,
                        DrawingUtil.getPoint(polygon, i + 1).x,
                        DrawingUtil.getPoint(polygon, i + 1).y
                    ];
                    let intersections = [DrawingUtil.lineIntersection(line, sideA), DrawingUtil.lineIntersection(line, sideB)];
                    if (intersections.some(intersection => intersection.onLine1 && intersection.onLine2)) {
                        let onArcCut = DrawingUtil.getPoint(polygon, i).isArc && DrawingUtil.getPoint(polygon, i + 1).isArc;
                        if (onArcCut || !this.validateRightAngle(sideA, sideB, line)) {
                            currentSegment.angled = true;
                            break;
                        }
                    }
                }
                if (currentSegment.angled) {
                    continue;
                }
                for (let otherSegment of possibleIntersectingSegments) {
                    let otherPoints = DrawingUtil.extrapolateSegment(otherSegment.points);
                    let otherGrill = GrillHelper.findGrillBySegmentId(grills, otherSegment.id);
                    let otherGrillIndex = grills.indexOf(otherGrill);
                    if (+grillIndex < otherGrillIndex) {
                        continue;
                    }
                    let otherSides = [
                        DrawingUtil.getParallelLine(otherPoints, otherGrill.width / 2),
                        DrawingUtil.getParallelLine(otherPoints, -otherGrill.width / 2)
                    ];
                    for (let otherSide of otherSides) {
                        let intersections = [
                            DrawingUtil.lineIntersection(otherSide, sideA),
                            DrawingUtil.lineIntersection(otherSide, sideB)
                        ];
                        if (intersections.some(intersection => intersection.onLine1 && intersection.onLine2)) {
                            if (!this.validateRightAngle(sideA, sideB, otherSide)) {
                                currentSegment.angled = true;
                                break;
                            }
                        }
                    }
                    if (currentSegment.angled) {
                        break;
                    }
                }
            }
        }
    }

    private static checkSegmentsAngularity(grills: Grill[], addNewGrillMode = false): void {
        grills.forEach(grill => GrillHelper.checkAngularity(grill, addNewGrillMode));
    }

    private static validateRightAngle(sideA: number[], sideB: number[], otherLine: number[]): boolean {
        return Math.abs(DrawingUtil.getAngleBetweenTwoLines(sideA, otherLine) - (Math.PI / 2)) < this.RIGHT_ANGLE_THRESHOLD &&
            Math.abs(DrawingUtil.getAngleBetweenTwoLines(sideB, otherLine) - (Math.PI / 2)) < this.RIGHT_ANGLE_THRESHOLD;
    }

    private static calculateAllSegmentIntersectionPointsGroupedByIds(grills: Grill[]): { [id: number]: IntersectionInfo[] } {
        let intersectionsBySegmentId: { [id: number]: IntersectionInfo[] } = {};
        let addIntersection = (id: number, intersection: IntersectionResult, intersectingSegment: LineGrillSegment, onVertex: boolean) => {
            if (intersectionsBySegmentId[id] == undefined) {
                intersectionsBySegmentId[id] = [new IntersectionInfo(intersection, intersectingSegment, onVertex)];
            } else {
                intersectionsBySegmentId[id].push(new IntersectionInfo(intersection, intersectingSegment, onVertex));
            }
        };
        for (let currentGrillIndex = 0; currentGrillIndex < grills.length; currentGrillIndex++) {
            let currentGrill = grills[currentGrillIndex];
            for (let otherGrillIndex = 0; otherGrillIndex <= currentGrillIndex; otherGrillIndex++) {
                let otherGrill = grills[otherGrillIndex];
                for (let currentSegmentIndex = 0; currentSegmentIndex < currentGrill.drawingSegments.length; currentSegmentIndex++) {
                    let currentSegment = GrillHelper.expectLineSegment(currentGrill.drawingSegments[currentSegmentIndex]);
                    for (let otherSegmentIndex = 0; otherSegmentIndex < otherGrill.drawingSegments.length; otherSegmentIndex++) {
                        let otherSegment = GrillHelper.expectLineSegment(otherGrill.drawingSegments[otherSegmentIndex]);
                        if (currentGrillIndex === otherGrillIndex && currentSegmentIndex <= otherSegmentIndex) {
                            break;
                        }
                        let currentSegmentPoints = DrawingUtil.extrapolateSegment(currentSegment.points);
                        let otherSegmentPoints = DrawingUtil.extrapolateSegment(otherSegment.points);
                        let otherSegmentSideA = DrawingUtil.getParallelLine(otherSegmentPoints, otherGrill.width / 2);
                        let otherSegmentSideB = DrawingUtil.getParallelLine(otherSegmentPoints, -otherGrill.width / 2);
                        let sideIntersections = [DrawingUtil.lineIntersection(currentSegmentPoints, otherSegmentSideA),
                            DrawingUtil.lineIntersection(currentSegmentPoints, otherSegmentSideB)];
                        let intersection = DrawingUtil.lineIntersection(currentSegmentPoints, otherSegmentPoints);
                        if (sideIntersections.some(int => int.onLine1 && int.onLine2)) {
                            let intersectionPoint = [intersection.x, intersection.y];
                            addIntersection(currentSegment.id, intersection, otherSegment,
                                DrawingUtil.isPointAPolygonVertex(intersectionPoint, currentSegment.points, true));
                            addIntersection(otherSegment.id, intersection, currentSegment,
                                DrawingUtil.isPointAPolygonVertex(intersectionPoint, otherSegment.points, true));
                        }
                    }
                }
            }
        }
        return intersectionsBySegmentId;
    }

    private static getSortedAndUniqueSplitPoints(segment: LineGrillSegment, splitPoints: Point[]): Point[] {
        let p = segment.points;
        let segmentVertexes = [new Point(p[0], p[1]), new Point(p[2], p[3])];
        let axis: keyof Point = GrillHelper.isSegmentVertical(segment) ? 'y' : 'x';
        segmentVertexes.sort((a, b) => a[axis] - b[axis]);
        splitPoints.sort((a, b) => a[axis] - b[axis]);
        if (splitPoints.length === 0 || (segmentVertexes[0][axis] < splitPoints[0][axis])) {
            splitPoints.unshift(segmentVertexes[0]);
        }
        if (splitPoints.length === 0 || (segmentVertexes[1][axis] > splitPoints[splitPoints.length - 1][axis])) {
            splitPoints.push(segmentVertexes[1]);
        }
        return splitPoints.filter((v, i, a) => i === 0 || !(FloatOps.eq(a[i - 1].x, v.x) && FloatOps.eq(a[i - 1].y, v.y)));
    }

    private static getSplittedSegments(segment: LineGrillSegment, splitPoints: Point[]): PricingGrillSegment[] {
        splitPoints = this.getSortedAndUniqueSplitPoints(segment, splitPoints);
        let splittedSegments = [];
        for (let i = 0; i < splitPoints.length - 1; i++) {
            let position = [splitPoints[i].x, splitPoints[i].y, splitPoints[i + 1].x, splitPoints[i + 1].y];
            if (DrawingUtil.segmentsLength(position) > 1) {
                splittedSegments.push(new PricingGrillSegment(position));
            }
        }
        return splittedSegments;
    }

    private static calculateDistanceBetweenGrills(bounds: MinMaxXY,
                                                  grill: GrillGrid | GrillGridWithRhombusOnIntersections,
                                                  vertical: boolean): number {
        let totalSize = vertical ? bounds.maxY - bounds.minY : bounds.maxX - bounds.minX;
        let fieldCount = vertical ? grill.rows : grill.columns;
        return ((totalSize - ((fieldCount - 1) * grill.width)) / fieldCount) + grill.width;
    }

    // ==========================================================================================================
    // --------------------------------------------- LineGrill --------------------------------------------------
    // ==========================================================================================================

    public static generateLineGrillSegments(grill: LineGrill, linePoints: number[]): void {
        grill.drawingSegments.push(new LineGrillSegment(linePoints));
    }

    public static updateLineGrillSegments(grill: LineGrill): void {
        let linePoints = grill.positionsPoints;
        if (grill.drawingSegments.length === 0) {
            this.generateLineGrillSegments(grill, linePoints);
        }
        GrillHelper.getExpectedLineSegment(grill).points = linePoints;
    }

    public static getGrillSegmentPolygon(segment: LineGrillSegment, grillSegmentParent: Grill): number[] {
        let top = DrawingUtil.getParallelLine(segment.points, grillSegmentParent.width / 2);
        let bottom = DrawingUtil.getParallelLine(segment.points, -grillSegmentParent.width / 2);
        if (grillSegmentParent.type === GrillType.GRID_RHOMBUS) {
            top = DrawingUtil.extrapolateSegment(top, grillSegmentParent.width / 2);
            bottom = DrawingUtil.extrapolateSegment(bottom, grillSegmentParent.width / 2);
        } else {
            top = DrawingUtil.extrapolateSegmentByFactor(top, 1.5);
            bottom = DrawingUtil.extrapolateSegmentByFactor(bottom, 1.5);
        }
        return [top[0], top[1], top[2], top[3], bottom[2], bottom[3], bottom[0], bottom[1]];
    }

    private static validateLineGrill(grill: LineGrill): { [error: string]: string } {
        let errors = {};
        GrillSegmentGenerator.validateGrillId(errors, grill);
        return errors;
    }

    // ==========================================================================================================
    // --------------------------------------------- GrillGrid --------------------------------------------------
    // ==========================================================================================================

    private static updateGrillGridSegments(grill: GrillGrid, targetPolygon: number[]): void {
        let segments = grill.drawingSegments as LineGrillSegment[];
        let bounds = DrawingUtil.calculatePolygonTotalBoundingBox(targetPolygon);
        let xDistanceBetweenGrills = this.calculateDistanceBetweenGrills(bounds, grill, false);
        let yDistanceBetweenGrills = this.calculateDistanceBetweenGrills(bounds, grill, true);
        let lines = [];
        let x = bounds.minX - (grill.width / 2);
        let y = bounds.minY - (grill.width / 2);
        for (let i = 1; i < grill.rows; ++i) {
            y += yDistanceBetweenGrills;
            let xs = DrawingUtil.getMinAndMax(targetPolygon, y, false);
            lines.push([xs[0], y, xs[1], y]);
        }
        for (let i = 1; i < grill.columns; ++i) {
            x += xDistanceBetweenGrills;
            let ys = DrawingUtil.getMinAndMax(targetPolygon, x, true);
            lines.push([x, ys[0], x, ys[1]]);
        }
        if (segments.length !== lines.length) {
            // generate new segments
            segments = grill.drawingSegments = lines.map(line => new LineGrillSegment(line));
        } else {
            // just update points
            lines.forEach((line, idx) => segments[idx].points = line);
        }
    }

    private static validateGrillGrid(grill: GrillGrid): { [error: string]: string } {
        let errors: { [error: string]: string } = {};
        GrillSegmentGenerator.validateGrillId(errors, grill);
        if (!grill.rows && grill.rows !== 0) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.not_empty';
        } else if (!Number.isInteger(+grill.rows)) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.not_an_integer';
        } else if (grill.rows < 1) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.greater_than_zero';
        }
        if (!grill.columns && grill.columns !== 0) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.not_empty';
        } else if (!Number.isInteger(+grill.columns)) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.not_an_integer';
        } else if (grill.columns < 1) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.greater_than_zero';
        }

        if (grill.columns && grill.rows && grill.columns * grill.rows < 2 && !isNaN(grill.columns) &&
            !isNaN(grill.rows)) {
            errors['gridSize'] = 'error.drawingGrillParameters.gridSize.incorrect_dimensions';
        }
        return errors;
    }

    // ==========================================================================================================
    // ------------------------------------------ CrossGrillAngled ----------------------------------------------
    // ==========================================================================================================

    private static updateCrossGrillAngledSegments(grill: CrossGrillAngled, targetPolygon: number[]): void {
        let segments = grill.drawingSegments as LineGrillSegment[];
        let bounds = DrawingUtil.calculatePolygonTotalBoundingBox(targetPolygon);
        let inter1 = DrawingUtil.polygonLineIntersections(targetPolygon,
            [bounds.minX, bounds.minY, bounds.maxX, bounds.maxY]);
        let inter2 = DrawingUtil.polygonLineIntersections(targetPolygon,
            [bounds.maxX, bounds.minY, bounds.minX, bounds.maxY]);
        if (segments.length === 0) {
            // generate new segments
            grill.drawingSegments.push(new LineGrillSegment([inter1[0].x, inter1[0].y, inter1[1].x, inter1[1].y]));
            grill.drawingSegments.push(new LineGrillSegment([inter2[0].x, inter2[0].y, inter2[1].x, inter2[1].y]));
        } else {
            // just update points
            segments[0].points = [inter1[0].x, inter1[0].y, inter1[1].x, inter1[1].y];
            segments[1].points = [inter2[0].x, inter2[0].y, inter2[1].x, inter2[1].y];
        }
    }

    private static validateCrossGrillAngled(grill: CrossGrillAngled): { [error: string]: string } {
        let errors = {};
        GrillSegmentGenerator.validateGrillId(errors, grill);
        return errors;
    }

    // ==========================================================================================================
    // ------------------------------------------ CrossGrillSimple ----------------------------------------------
    // ==========================================================================================================

    private static updateCrossGrillSimpleSegments(grill: CrossGrillSimple, targetPolygon: number[]): void {
        let segments = grill.drawingSegments as LineGrillSegment[];
        let bounds = DrawingUtil.calculatePolygonTotalBoundingBox(targetPolygon);
        let cx = (bounds.minX + bounds.maxX) / 2;
        let cy = (bounds.minY + bounds.maxY) / 2;
        let inter1 = DrawingUtil.polygonLineIntersections(targetPolygon, [bounds.minX, cy, bounds.maxX, cy]);
        let inter2 = DrawingUtil.polygonLineIntersections(targetPolygon, [cx, bounds.minY, cx, bounds.maxY]);
        if (segments.length === 0) {
            // generate new segments
            grill.drawingSegments.push(new LineGrillSegment([inter1[0].x, inter1[0].y, inter1[1].x, inter1[1].y]));
            grill.drawingSegments.push(new LineGrillSegment([inter2[0].x, inter2[0].y, inter2[1].x, inter2[1].y]));
        } else {
            // just update points
            segments[0].points = [inter1[0].x, inter1[0].y, inter1[1].x, inter1[1].y];
            segments[1].points = [inter2[0].x, inter2[0].y, inter2[1].x, inter2[1].y];
        }
    }

    private static validateCrossGrillSimple(grill: CrossGrillSimple): { [error: string]: string } {
        let errors = {};
        GrillSegmentGenerator.validateGrillId(errors, grill);
        return errors;
    }

    // ==========================================================================================================
    // --------------------------------- GrillGridWithRhombusOnIntersections ------------------------------------
    // ==========================================================================================================

    private static getRhombusesCoordinates(bounds: MinMaxXY, grill: GrillGridWithRhombusOnIntersections,
                                      distanceBetweenGrills: number, vertical: boolean): number[] {
        let min = (vertical ? bounds.minY : bounds.minX) - (grill.width / 2);
        let count = vertical ? grill.rows : grill.columns;
        return _.range(1, count).map(val => min + (val * distanceBetweenGrills));
    }

    private static preparePerpendicularSegments(bounds: MinMaxXY, grill: GrillGridWithRhombusOnIntersections,
                                                xRhombusesCoordinates: number[], yRhombusesCoordinates: number[]): number[][] {
        let segmentPositions = [];
        let halfRhombusWidth = grill.rhombusWidth / 2;
        let halfRhombusHeight = grill.rhombusHeight / 2;
        for (let xCoordinateIndex = 0; xCoordinateIndex <= xRhombusesCoordinates.length; xCoordinateIndex++) {
            let xCoordinate = xRhombusesCoordinates[xCoordinateIndex];
            for (let yCoordinateIndex = 0; yCoordinateIndex <= yRhombusesCoordinates.length; yCoordinateIndex++) {
                let yCoordinate = yRhombusesCoordinates[yCoordinateIndex];
                if (yCoordinateIndex < yRhombusesCoordinates.length) {
                    let xStart = xCoordinateIndex === 0 ? bounds.minX :
                        xRhombusesCoordinates[xCoordinateIndex - 1] + halfRhombusWidth;
                    let xEnd = xCoordinateIndex === xRhombusesCoordinates.length ? bounds.maxX :
                        xCoordinate - halfRhombusWidth;
                    segmentPositions.push([xStart, yCoordinate, xEnd, yCoordinate]);
                }
                if (xCoordinateIndex < xRhombusesCoordinates.length) {
                    let yStart = yCoordinateIndex === 0 ? bounds.minY :
                        yRhombusesCoordinates[yCoordinateIndex - 1] + halfRhombusHeight;
                    let yEnd = yCoordinateIndex === yRhombusesCoordinates.length ? bounds.maxY :
                        yCoordinate - halfRhombusHeight;
                    segmentPositions.push([xCoordinate, yStart, xCoordinate, yEnd]);
                }
            }
        }
        return segmentPositions;
    }

    private static prepareOuterFields(bounds: MinMaxXY, grill: GrillGridWithRhombusOnIntersections,
                                      xRhombusesCoordinates: number[], yRhombusesCoordinates: number[]): number[][] {
        let outerFields = [];
        let halfGrillWidth = grill.width / 2;
        for (let xCoordinateIndex = 0; xCoordinateIndex <= xRhombusesCoordinates.length; xCoordinateIndex++) {
            let top = xCoordinateIndex === 0 ? bounds.minX :
                xRhombusesCoordinates[xCoordinateIndex - 1] + halfGrillWidth;
            let bottom = xCoordinateIndex === xRhombusesCoordinates.length ? bounds.maxX :
                xRhombusesCoordinates[xCoordinateIndex] - halfGrillWidth;
            for (let yCoordinateIndex = 0; yCoordinateIndex <= yRhombusesCoordinates.length; yCoordinateIndex++) {
                let left = yCoordinateIndex === 0 ? bounds.minY :
                    yRhombusesCoordinates[yCoordinateIndex - 1] + halfGrillWidth;
                let right = yCoordinateIndex === yRhombusesCoordinates.length ? bounds.maxY :
                    yRhombusesCoordinates[yCoordinateIndex] - halfGrillWidth;
                outerFields.push([
                    top, left,
                    top, right,
                    bottom, right,
                    bottom, left
                ]);
            }
        }
        return outerFields;
    }

    private static trimFieldWithLine(outerFieldsToTrim: number[][], fieldIndex: number, line: number[],
                                     cutside: string): void {
        let field = outerFieldsToTrim[fieldIndex];
        if (DrawingUtil.polygonLineIntersectionCount(field, line) === 2) {
            outerFieldsToTrim[fieldIndex] = CutsUtil.applyCut(field, new LineCutData([...line], cutside), 0);
        }
    }

    private static trimFieldsWithRhombus(topLeftSegment: number[], topRightSegment: number[],
                                         bottomLeftSegment: number[], bottomRightSegment: number[],
                                         halfGrillWidth: number, outerFieldsToTrim: number[][]): void {
        let topLeftOuterLine = DrawingUtil.getParallelLine(topLeftSegment, halfGrillWidth);
        let topRightOuterLine = DrawingUtil.getParallelLine(topRightSegment, -halfGrillWidth);
        let bottomLeftOuterLine = DrawingUtil.getParallelLine(bottomLeftSegment, -halfGrillWidth);
        let bottomRightOuterLine = DrawingUtil.getParallelLine(bottomRightSegment, halfGrillWidth);

        for (let fieldIndex = 0; fieldIndex < outerFieldsToTrim.length; fieldIndex++) {
            this.trimFieldWithLine(outerFieldsToTrim, fieldIndex, topLeftOuterLine, 'top');
            this.trimFieldWithLine(outerFieldsToTrim, fieldIndex, topRightOuterLine, 'bottom');
            this.trimFieldWithLine(outerFieldsToTrim, fieldIndex, bottomLeftOuterLine, 'bottom');
            this.trimFieldWithLine(outerFieldsToTrim, fieldIndex, bottomRightOuterLine, 'top');
        }
    }

    private static prepareRhombusesAndTrimFields(grill: GrillGridWithRhombusOnIntersections, xRhombusesCoordinates: number[],
                                    yRhombusesCoordinates: number[],
                                    outerFieldsToTrim: number[][]): { segments: number[][], fields: number[][] } {
        let segmentPositions = [];
        let innerFields = [];
        let halfRhombusWidth = grill.rhombusWidth / 2;
        let halfRhombusHeight = grill.rhombusHeight / 2;
        let halfGrillWidth = grill.width / 2;
        for (let xCoordinate of xRhombusesCoordinates) {
            for (let yCoordinate of yRhombusesCoordinates) {
                let topLeftSegment = [xCoordinate,
                    yCoordinate - halfRhombusHeight,
                    xCoordinate - halfRhombusWidth,
                    yCoordinate];
                let topRightSegment = [xCoordinate,
                    yCoordinate - halfRhombusHeight,
                    xCoordinate + halfRhombusWidth,
                    yCoordinate];
                let bottomLeftSegment = [xCoordinate,
                    yCoordinate + halfRhombusHeight,
                    xCoordinate - halfRhombusWidth,
                    yCoordinate];
                let bottomRightSegment = [xCoordinate,
                    yCoordinate + halfRhombusHeight,
                    xCoordinate + halfRhombusWidth,
                    yCoordinate];
                segmentPositions.push(topLeftSegment, topRightSegment, bottomLeftSegment, bottomRightSegment);

                let topLeftInnerLine = DrawingUtil.getParallelLine(topLeftSegment, -halfGrillWidth);
                let topRightInnerLine = DrawingUtil.getParallelLine(topRightSegment, halfGrillWidth);
                let bottomLeftInnerLine = DrawingUtil.getParallelLine(bottomLeftSegment, halfGrillWidth);
                let bottomRightInnerLine = DrawingUtil.getParallelLine(bottomRightSegment, -halfGrillWidth);

                let topInnerPoint = DrawingUtil.lineIntersection(topLeftInnerLine, topRightInnerLine);
                let rightInnerPoint = DrawingUtil.lineIntersection(topRightInnerLine, bottomRightInnerLine);
                let bottomInnerPoint = DrawingUtil.lineIntersection(bottomRightInnerLine, bottomLeftInnerLine);
                let leftInnerPoint = DrawingUtil.lineIntersection(bottomLeftInnerLine, topLeftInnerLine);

                innerFields.push([
                    topInnerPoint.x, topInnerPoint.y,
                    rightInnerPoint.x, rightInnerPoint.y,
                    bottomInnerPoint.x, bottomInnerPoint.y,
                    leftInnerPoint.x, leftInnerPoint.y
                ]);

                this.trimFieldsWithRhombus(
                    topLeftSegment, topRightSegment, bottomLeftSegment, bottomRightSegment, halfGrillWidth, outerFieldsToTrim);
            }
        }
        return {segments: segmentPositions, fields: [...innerFields, ...outerFieldsToTrim]};
    }

    private static updateGrillGridWithRhombusOnIntersectionsSegments(grill: GrillGridWithRhombusOnIntersections,
                                                                     targetPolygon: number[]): void {
        let segments = grill.drawingSegments as LineGrillSegment[];
        let bounds = DrawingUtil.calculatePolygonTotalBoundingBox(targetPolygon);
        let xDistanceBetweenGrills = this.calculateDistanceBetweenGrills(bounds, grill, false);
        let yDistanceBetweenGrills = this.calculateDistanceBetweenGrills(bounds, grill, true);
        let xCoords = this.getRhombusesCoordinates(bounds, grill, xDistanceBetweenGrills, false);
        let yCoords = this.getRhombusesCoordinates(bounds, grill, yDistanceBetweenGrills, true);
        let outerFields = this.prepareOuterFields(bounds, grill, xCoords, yCoords);
        let rhombuses = this.prepareRhombusesAndTrimFields(grill, xCoords, yCoords, outerFields);

        let segmentPoints = [
            ...this.preparePerpendicularSegments(bounds, grill, xCoords, yCoords),
            ...rhombuses.segments
        ];
        grill.splittedFields = rhombuses.fields;

        let linesQuantity = 6 * grill.rows * grill.columns - 5 * grill.rows - 5 * grill.columns + 4;
        if (segments.length !== linesQuantity) {
            segments = grill.drawingSegments = segmentPoints.map(line => new LineGrillSegment(line));
        } else {
            segmentPoints.forEach((line, idx) => segments[idx].points = line);
        }
    }

    private static validateGrillGridWithRhombusOnIntersections(grill: GrillGridWithRhombusOnIntersections,
                                                               targetArea: Snap.Element): { [error: string]: string } {
        let errors: { [error: string]: string } = {};
        GrillSegmentGenerator.validateGrillId(errors, grill);
        if (!grill.rows && grill.rows !== 0) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.not_empty';
        } else if (!Number.isInteger(+grill.rows)) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.not_an_integer';
        } else if (grill.rows < 2) {
            errors['gridRows'] = 'error.drawingGrillParameters.gridRows.greater_than_one';
        }
        if (!grill.columns && grill.columns !== 0) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.not_empty';
        } else if (!Number.isInteger(+grill.columns)) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.not_an_integer';
        } else if (grill.columns < 2) {
            errors['gridColumns'] = 'error.drawingGrillParameters.gridColumns.greater_than_one';
        }
        if (!grill.rhombusWidth && grill.rhombusWidth !== 0) {
            errors['rhombusWidth'] = 'error.drawingGrillParameters.rhombusWidth.not_empty';
        } else if (!Number.isInteger(+grill.rhombusWidth) || +grill.rhombusWidth < 1) {
            errors['rhombusWidth'] = 'error.drawingGrillParameters.rhombusWidth.not_an_integer';
        }
        if (!grill.rhombusHeight && grill.rhombusHeight !== 0) {
            errors['rhombusHeight'] = 'error.drawingGrillParameters.rhombusHeight.not_empty';
        } else if (!Number.isInteger(+grill.rhombusHeight) || +grill.rhombusHeight < 1) {
            errors['rhombusHeight'] = 'error.drawingGrillParameters.rhombusHeight.not_an_integer';
        }
        if (targetArea && Object.keys(errors).length === 0) {
            grill.rhombusWidth = +grill.rhombusWidth;
            grill.rhombusHeight = +grill.rhombusHeight;
            let size = targetArea.getBBox();
            let minSizeConstraint = Math.sqrt(
                Math.pow(grill.rhombusHeight / 2, 2) + Math.pow(grill.rhombusWidth / 2, 2));
            if (grill.width >= minSizeConstraint) {
                errors['rhombusWidth'] = 'error.drawingGrillParameters.rhombusWidth.too_small';
                errors['rhombusHeight'] = 'error.drawingGrillParameters.rhombusHeight.too_small';
            }
            if (grill.rhombusWidth + grill.width >= size.width / grill.columns) {
                errors['rhombusWidth'] = 'error.drawingGrillParameters.rhombusWidth.too_large';
            }
            if (grill.rhombusHeight + grill.width >= size.height / grill.rows) {
                errors['rhombusHeight'] = 'error.drawingGrillParameters.rhombusHeight.too_large';
            }
        }
        return errors;
    }

    // ==========================================================================================================
    // --------------------------------------------- Mullion --------------------------------------------------
    // ==========================================================================================================

    public static generateMullionSegments(mullion: Mullion, linePoints: number[]): void {
        mullion.drawingSegments.push(new LineGrillSegment(linePoints));
    }

    public static updateMullionSegments(mullion: Mullion): void {
        if (mullion.drawingSegments.length === 0) {
            // generate new segments
            this.generateMullionSegments(mullion, mullion.positionsPoints);
        }
        let segment = GrillHelper.getExpectedLineSegment(mullion);
        segment.points = mullion.positionsPoints;
    }

    private static validateMullion(mullion: Mullion): { [error: string]: string } {
        let errors: { [error: string]: string } = {};
        if (!mullion.id || !mullion.width) {
            errors['mullion'] = 'error.drawingMullionParameters.mullion';
        }
        return errors;
    }
}

class IntersectionInfo {
    point: Point;
    intersectingSegment: LineGrillSegment;
    onVertex: boolean;

    constructor(intersection: IntersectionResult, intersectingSegment: LineGrillSegment, onVertex: boolean) {
        this.point = new Point(intersection.x, intersection.y);
        this.intersectingSegment = intersectingSegment;
        this.onVertex = onVertex;
    }
}
