import {animate, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BlockUI} from 'primeng/blockui';
import {Subscription} from 'rxjs';
import {BlockUiController} from './block-ui-controller';

@Component({
    selector: 'app-block-ui',
    templateUrl: './block-ui.component.html',
    styleUrls: ['./block-ui.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeIn', [
            state('false', style({
                opacity: 0
            })),
            state('true', style({
                opacity: 0.9
            })),
            transition('false => true', animate('2s ease-in'))
        ])
    ]
})
export class BlockUiComponent implements OnInit, OnDestroy, AfterViewInit {

    blocked = false;

    @ViewChild(BlockUI)
    block: BlockUI;

    private blockSubscription: Subscription;

    constructor(private blockUiController: BlockUiController,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.blockUiController.visibilityChange.subscribe(blocked => {
            this.blocked = blocked;
            this.changeDetector.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this.blockSubscription != undefined) {
            this.blockSubscription.unsubscribe();
        }
        if (this.block && this.blocked) {
            this.block.unblock();
        }
    }

    ngAfterViewInit(): void {
        // remove default block div
        this.block.mask.nativeElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    }
}
