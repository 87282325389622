import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ApplicationFeatures} from "../../../application-info/application-features";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {ConfigurableAddonsService} from "../../offer/offers/position/config-addons.service";
import {SubsystemGroupService} from '../../subsystem-group/subsystem-group.service';
import {User} from "../../user/user";
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {HasSaveTable} from '../base-profit-margin.component';
import {normalizeProfitMarginValueString} from '../profit-margin/profitMargin';
import {Subsystem} from "../subsystem";
import {SubsystemValidator} from "../subsystem-validator";
import {SubsystemService} from "../subsystem.service";
import {TabularAddress} from '../tabularAddress';
import {ProfitMarginTarget} from "../profit-margin/profit-margin-target";

@Component({
    selector: 'app-create-subsystem',
    templateUrl: './create-subsystem.component.html',
    providers: [SubsystemService, SubsystemGroupService, DataServiceHelper, ConfigurableAddonsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSubsystemComponent implements OnInit {

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly confAddGlobalMarginButtonId = 'confAddGlobalMarginButtonId';
    readonly confAddGlobalMarginInputId = 'confAddGlobalMarginInputId';
    readonly bulkConfAddGlobalMarginButtonId = 'bulkConfAddGlobalMarginButtonId';
    readonly bulkConfAddGlobalMarginInputId = 'bulkConfAddGlobalMarginInputId';
    readonly addonGlobalMarginButtonId = 'addonGlobalMarginButtonId';
    readonly addonGlobalMarginInputId = 'addonGlobalMarginInputId';
    readonly catalogChargeGlobalMarginButtonId = 'catalogChargeGlobalMarginButtonId';
    readonly catalogChargeGlobalMarginInputId = 'catalogChargeGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';
    readonly systemGlobalDiscountButtonId = 'systemGlobalDiscountButtonId';
    readonly systemGlobalDiscountInputId = 'systemGlobalDiscountInputId';
    readonly configAddonGlobalDiscountButtonId = 'configAddonGlobalDiscountButtonId';
    readonly configAddonGlobalDiscountInputId = 'configAddonGlobalDiscountInputId';
    readonly supplierGlobalDiscountButtonId = 'supplierGlobalDiscountButtonId';
    readonly supplierGlobalDiscountInputId = 'supplierGlobalDiscountInputId';

    readonly STEPS = {
        FILL_DETAILS: 'fill-details',
        FILL_ADDRESS_LIST: 'fill-address-list',
        FILL_ADDRESS: 'fill-address',
        FILL_SUPPLIERS: 'fill-suppliers',
        FILL_PROFIT_MARGINS_SYSTEMS: 'fill-profit-margin-systems',
        FILL_PROFIT_MARGINS_ROOF_SYSTEMS: 'fill-profit-margin-roof-systems',
        FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS: 'fill-profit-margin-entrance-systems',
        FILL_PROFIT_MARGINS_ADDONS: 'fill-profit-margin-addons',
        FILL_PROFIT_MARGINS_CONFIG_ADDONS: 'fill-profit-margin-config-addons',
        BULK_CONFIG_ADDON_MARGINS: 'fill-profit-margin-bulk-config-addons',
        FILL_PROFIT_MARGINS_CATALOG: 'fill-profit-margin-catalog',
        FILL_PROFIT_MARGINS_GATES: 'fill-profit-margin-gates',
        FILL_SELL_DEALER_DISCOUNT_SYSTEM: 'fill-sell-dealer-discount-system',
        FILL_SELL_DEALER_DISCOUNT_CONFIG_ADDON: 'fill-sell-dealer-discount-config-addon',
        FILL_SELL_DEALER_DISCOUNT_SUPPLIER: 'fill-sell-dealer-discount-supplier'
    };

    readonly route: ActivatedRoute;
    possibleTargets = ProfitMarginTarget;

    subsystem: Subsystem = new Subsystem();
    addresses: TabularAddress[] = [];
    selectedCorrespondenceAddress: TabularAddress;
    selectedDeliveryAddress: TabularAddress;
    validationErrors: ValidationErrors = {};

    validateDetailsStep: WizardStepValidator;
    validateAddressListStep: WizardStepValidator;
    validateAddressStep: WizardStepValidator;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    subsystemValidator: SubsystemValidator;
    saveInProgress = false;
    availableManagers: User[];
    subsystemGroups: SelectItem[];

    constructor(public translate: TranslateService,
                private router: Router,
                public changeDetector: ChangeDetectorRef,
                public subsystemService: SubsystemService,
                private subsystemGroupService: SubsystemGroupService,
                private configurableAddonService: ConfigurableAddonsService,
                private errors: CommonErrorHandler,
                injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.subsystemValidator = new SubsystemValidator(subsystemService);
        this.validateDetailsStep = () => {
            this.validationErrors = {};
            return this.subsystemValidator.validateDetails(this.subsystem).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
        this.validateAddressListStep = () => {
            return this.subsystemValidator.validateAddresses(this.addresses).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
        this.validateAddressStep = () => {
            this.validationErrors = {};
            return this.subsystemValidator.validateAddress(this.subsystem, this.addresses, this.selectedCorrespondenceAddress,
                this.selectedDeliveryAddress).pipe(
                tap(validationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(validationErrors => !ValidationErrorsHelper.validationErrorsPresent(validationErrors)));
        };
    }

    ngOnInit(): void {
        this.getAvailableManagers();
        this.subsystemGroupService.getItems(undefined, undefined, {}, 'id', 1).subscribe(subsystemGroups => {
            this.subsystemGroups =
                subsystemGroups.data.map(subsystemGroup => ({label: subsystemGroup.name, value: subsystemGroup.id}))
                    .sort((a, b) => a.label.localeCompare(b.label));
            this.changeDetector.markForCheck();
        });
    }

    getAvailableManagers() {
        this.subsystemService.getClientManagers().subscribe({
            next: data => {
                this.availableManagers = data.data.slice(0);
            },
            error: error => {
                this.handleError(error);
            },
            complete: () => {
                console.debug('read available managers` completed!');
            }
        });
    }

    exitWizard(): void {
        this.saveInProgress = false;
        this.router.navigate(['/features/subsystem']);
    }

    handleError(err: HttpErrorResponse | Error): void {
        this.saveInProgress = false;
        this.validationErrors = this.errors.handle(err);
    }

    submit() {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;

        if (!!this.subsystem.bulkAddonProfitMargin) {
            this.subsystem.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.subsystem.bulkAddonProfitMargin);
        }

        this.subsystemService.save(this.subsystem, this.addresses, this.selectedCorrespondenceAddress, this.selectedDeliveryAddress)
            .pipe(mergeMap(subsystemId => {
                return forkJoin(this.childTables.map(table => table.saveTable(subsystemId))).pipe(map(() => subsystemId));
            })).subscribe({
            next: () => this.exitWizard(),
            error: error => this.handleError(error)
        });
    }

    onStepChange(): void {
        this.changeDetector.markForCheck();
    }
}
