import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TabularAddress} from './tabularAddress';

@Injectable()
export class AddressService {

    private static readonly API_URL = 'tabularAddress';

    constructor(private http: HttpClient) {
    }

    getUsableAddresses(subsystemId?: number, offerId?: number): Observable<TabularAddress[]> {
        let offerParam = offerId != null ? {params: {offerId: '' + offerId}} : {};
        let subsystemParam = subsystemId != null ? {params: {subsystemId: '' + subsystemId}} : {};
        let params = {...offerParam, ... subsystemParam};
        return this.http.get<TabularAddress[]>(`${AddressService.API_URL}/addresses`, params)
            .pipe(map(response => response.map(TabularAddress.fromJSON)));
    }

    getUsableDeliveryAddress(subsystemId: number, offerId?: number): Observable<TabularAddress> {
        let params = offerId != null ? {params: {offerId: '' + offerId}} : {};
        return this.http.get<TabularAddress>(`${AddressService.API_URL}/${subsystemId}/deliveryAddress`, params)
            .pipe(map(response => TabularAddress.fromJSON(response)));
    }

    getAddress(tabularAddressId: number): Observable<TabularAddress> {
        return this.http.get<TabularAddress>(`${AddressService.API_URL}/${tabularAddressId}`)
            .pipe(map(response => TabularAddress.fromJSON(response)));
    }
}
