import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Permissions} from '../../../../auth/permission.service';
import {Privilege} from '../../../../auth/privilege';
import {ValidationErrors} from '../../../../common/validation-errors';
import {Role} from '../role';

@Component({
    selector: 'app-role-form',
    templateUrl: './role-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleFormComponent {

    @Input()
    role: Role;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    privileges: Privilege[];

    constructor(private permissions: Permissions) {
    }

    get canEdit() {
        return this.permissions.canEditRole() && !this.role.primary;
    }

    isPrivilegeSelected(privilegeId): boolean {
        return this.role.privileges.includes(privilegeId);
    }

    setPrivilegeSelected(privilegeId: number, selected: boolean): void {
        if (selected) {
            this.role.privileges = [...this.role.privileges, privilegeId];
        } else {
            this.role.privileges = this.role.privileges.filter(selectedPrivilegeId => privilegeId !== selectedPrivilegeId);
        }
    }
}
