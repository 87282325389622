import {RandomIdGenerator} from "../utils/RandomIdGenerator";

export class GlazingBead {
    generatedId: string;
    id: number;

    constructor(id?: number) {
        this.generatedId = RandomIdGenerator.generate();
        this.id = id;
    }
}
