import {MultilanguageFieldInterface} from '../../../window-designer/catalog-data/multilanguage-field-interface';
import {ConfigurableAddonDefinitionType} from "../../../window-designer/enums/ConfigurableAddonDefinitionType";

export interface AbstractPosition {
    id: number;
    type: PositionType;
    name?: MultilanguageFieldInterface;
    dimensions?: string;
    quantity: number;
    data: string;
    otherInfo: string;
    configurableAddonDefinitionType: ConfigurableAddonDefinitionType;
    windowSystemId?: number;
    gateSystemId?: number;
    configSystemId?: number;
}

export interface AbstractPositionList {
    data: AbstractPosition[];
    totalRecords: number;
    windowPositionsCount: number;
}

export enum PositionType {
    SYSTEM = 'SYSTEM',
    CONFIGURABLE_ADDON = 'CONFIGURABLE_ADDON', // deprecated
    BULK_ADDON = 'BULK_ADDON',
    ASSEMBLY = 'ASSEMBLY',
    ROOF_SYSTEM = 'ROOF_SYSTEM',
    TRANSPORT = 'TRANSPORT',
    ENTRANCE_DOOR_SYSTEM = 'ENTRANCE_DOOR_SYSTEM',
    GATE_SYSTEM = 'GATE_SYSTEM',
    CONFIG_SYSTEM = 'CONFIG_SYSTEM',
    OFFER_CHARGE = 'OFFER_CHARGE',
    STANDALONE_GLAZING_PACKAGE = "STANDALONE_GLAZING_PACKAGE"
}

const WINDOW_POSITION_TYPES = [PositionType.SYSTEM, PositionType.ROOF_SYSTEM, PositionType.ENTRANCE_DOOR_SYSTEM,
    PositionType.STANDALONE_GLAZING_PACKAGE];

export function isWindowPositionType(type: PositionType): type is PositionType.SYSTEM | PositionType.ROOF_SYSTEM
    | PositionType.ENTRANCE_DOOR_SYSTEM | PositionType.STANDALONE_GLAZING_PACKAGE {
    return WINDOW_POSITION_TYPES.includes(type);
}

export function isFullScreenDesignerWindowPositionType(type: PositionType): boolean {
    return [...WINDOW_POSITION_TYPES, PositionType.GATE_SYSTEM].includes(type);
}

export enum PositionTarget {
    OFFER = 'OFFER',
    PRODUCTION_ORDER = 'PRODUCTION_ORDER'
}
