import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {DocumentTemplate} from './document-template';

@Injectable()
export class DocumentTemplateService implements CrudService<DocumentTemplate> {

    static SERVICE_PATH = 'documentTemplate';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<DocumentTemplate>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(DocumentTemplateService.SERVICE_PATH, {params: params}).pipe(
            map(response => Listing.fromJSON(DocumentTemplate, response)));
    }

    getItem(itemId: number): Observable<DocumentTemplate> {
        return this.http.get<object>(`${DocumentTemplateService.SERVICE_PATH}/${itemId}`).pipe(
            map(response => DocumentTemplate.fromJSON(response)));
    }

    addItem(item: DocumentTemplate): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: DocumentTemplate): Observable<number> {
        return undefined;
    }

    saveItem(item: DocumentTemplate, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('documentTemplateDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(`${DocumentTemplateService.SERVICE_PATH}`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(`${DocumentTemplateService.SERVICE_PATH}`, response)));
    }

    getFileForItem(itemId: number): Observable<File> {
        return this.http.get(`${DocumentTemplateService.SERVICE_PATH}/${itemId}/file`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    validate(item: DocumentTemplate): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${DocumentTemplateService.SERVICE_PATH}/validate`, item));
    }

    getDefaultTemplate(): Observable<File> {
        return this.http.get(`${DocumentTemplateService.SERVICE_PATH}/defaultTemplate`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDefaultConfirmationTemplate(): Observable<File> {
        return this.http.get(`${DocumentTemplateService.SERVICE_PATH}/defaultConfirmationTemplate`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDefaultGlamourAnnotations(): Observable<File> {
        return this.http.get(`${DocumentTemplateService.SERVICE_PATH}/glamourAnnotations`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }
}
