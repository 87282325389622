import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class CatalogChargeProfitMargin implements CrudItem {
    id: number;
    catalogItemType: CatalogChargeProfitMarginType;
    value: number;
    valueString: string;
    groupValue: number;

    static fromJSON(jsonObject: any): CatalogChargeProfitMargin {
        let catalogChargeProfitMargin = new CatalogChargeProfitMargin();
        catalogChargeProfitMargin.id = jsonObject.id;
        catalogChargeProfitMargin.catalogItemType = CatalogChargeProfitMarginType[<string>jsonObject.catalogItemType];
        catalogChargeProfitMargin.value = jsonObject.value;
        catalogChargeProfitMargin.valueString = jsonObject.valueString;
        catalogChargeProfitMargin.groupValue = jsonObject.groupValue;
        return catalogChargeProfitMargin;
    }
}

export enum CatalogChargeProfitMarginType {
    GENERAL_WINDOW_CHARGE,
    COLOR,
    OTHER_FILLING,
    GLASS,
    GRILL,
    GLAZING,
    SEAL,
    PROFILE_MULLION,
    SHAPE,
    PROFILE,
    FITTING,
    GLAZING_BEAD,
    DECORATIVE_FILLING,
    DISTANCE_FRAME
}
