import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";
import {ValidationErrors} from "../../../../common/validation-errors";
import {SubwindowTypeLimitations} from "../SubwindowTypeLimitations";
import {WindowSystemDefinition} from '../window-system-definition';
import {SubwindowTypes} from "../../../../../window-designer/subwindow-types";

@Component({
    selector: 'app-window-system-definition-shape-form',
    templateUrl: './window-system-definition-shape-form.component.html',
    styleUrls: ['./window-system-definition-shape-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionShapeFormComponent implements OnInit {

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    availableSubwindowTypes: SubWindowTypeCode[];

    @Input()
    subwindowTypes: SubwindowTypes;

    @Input()
    userLang: string;

    @Input()
    disabled: boolean;

    ngOnInit(): void {
        let limitations = this.windowSystem.subwindowTypesLimitations;
        this.availableSubwindowTypes.forEach(type => {
            limitations[type] = limitations[type] || new SubwindowTypeLimitations();
        });
    }

    getTypeName(type: SubWindowTypeCode): string {
        return this.subwindowTypes.get(type).names[this.userLang];
    }
}
