import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {Country} from '../../../common/enums/country';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SubsystemPartnerWebshopInfo} from './subsystem-partner-webshop-info';
import {SubsystemPartnerWebshopInfoService} from './subsystem-partner-webshop-info.service';

@Component({
    selector: 'app-subsystem-partner-webshop-info',
    templateUrl: './subsystem-partner-webshop-info.component.html',
    styleUrls: ['../settings.component.css'],
    providers: [SubsystemPartnerWebshopInfoService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemPartnerWebshopInfoComponent implements OnInit {

    private readonly SUBSYSTEM_WEBSHOP_INFO_SAVING_ID = "SubsystemPartnerWebshopInfoSavingId";
    private readonly SUBSYSTEM_WEBSHOP_INFO_LOADING_ID = "SubsystemPartnerWebshopInfoLoadingId";

    readonly LOGO_SIZE_MULTIPLIER = 5;

    subsystemWebshopInfo: SubsystemPartnerWebshopInfo;
    validationErrors = {};
    countries: Observable<SelectItem[]>;

    logo?: File;

    private initialPartnerWebshopInfo: SubsystemPartnerWebshopInfo;
    private initialLogo: File;
    showUnsavedConfirmationDialog = false;

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private subsystemWebshopInfoService: SubsystemPartnerWebshopInfoService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler,
                protected changeDetector: ChangeDetectorRef,
                public permissions: Permissions,
                private blockUiController: BlockUiController,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.subsystemWebshopInfo = new SubsystemPartnerWebshopInfo();
    }

    ngOnInit() {
        this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_INFO_LOADING_ID);
        this.subsystemWebshopInfoService.getItem().pipe(
            finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_INFO_LOADING_ID))
        ).subscribe({
            next: data => {
                this.subsystemWebshopInfo = data;
                this.initialPartnerWebshopInfo = SubsystemPartnerWebshopInfo.fromJSON(data); // copy for comparison
                this.logo = data.logo;
                this.initialLogo = data.logo;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    save() {
        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_INFO_SAVING_ID);
            this.subsystemWebshopInfoService.saveItem(this.subsystemWebshopInfo, this.logo).pipe(
                finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_INFO_SAVING_ID))
            ).subscribe({
                next: () => {
                    this.showSuccessMessage();
                    this.initialPartnerWebshopInfo = SubsystemPartnerWebshopInfo.fromJSON(this.subsystemWebshopInfo);
                    this.initialLogo = this.logo;
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private showSuccessMessage(): void {
        this.growlMessageController.info("SUBSYSTEM_WEBSHOP_INFO_SETTINGS.SAVING_SUCCESS_MESSAGE");
    }

    handleLogoChange(newFile: File | undefined) {
        this.logo = newFile;
        if (!newFile) {
            this.logo = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    private hasChanges(): boolean {
        return this.logo !== this.initialLogo
            || this.subsystemWebshopInfo.phoneNumber !== this.initialPartnerWebshopInfo.phoneNumber
            || this.subsystemWebshopInfo.phoneNumber2 !== this.initialPartnerWebshopInfo.phoneNumber2
            || this.subsystemWebshopInfo.webshopCompanyName !== this.initialPartnerWebshopInfo.webshopCompanyName
            || this.subsystemWebshopInfo.companyWebsiteUrl !== this.initialPartnerWebshopInfo.companyWebsiteUrl
            || this.subsystemWebshopInfo.email !== this.initialPartnerWebshopInfo.email
            || this.subsystemWebshopInfo.street !== this.initialPartnerWebshopInfo.street
            || this.subsystemWebshopInfo.city !== this.initialPartnerWebshopInfo.city
            || this.subsystemWebshopInfo.country !== this.initialPartnerWebshopInfo.country
            || this.subsystemWebshopInfo.zip !== this.initialPartnerWebshopInfo.zip
            || this.subsystemWebshopInfo.cardTitle !== this.initialPartnerWebshopInfo.cardTitle;
    }

    requestNavigationToPrintoutData(): boolean {
        if (this.hasChanges()) {
            this.showUnsavedConfirmationDialog = true;
            this.changeDetector.markForCheck();
            return false;
        }
        this.navigateToPrintoutData();
        return false;
    }

    navigateToPrintoutData(): void {
        this.showUnsavedConfirmationDialog = false;
        this.router.navigate(['.', {tabName: 'printoutData'}], {relativeTo: this.activatedRoute});
        this.changeDetector.markForCheck();
    }
}
