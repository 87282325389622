import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudItem, ListingItem} from './crudItem';

export interface CrudItemList {
    data: CrudItem[];
    totalRecords: number;
}

export class Listing<T> {
    data: T[];
    totalRecords: number;

    static fromJSON<U>(itemType: ListingItem<U>, jsonObject: Listing<object>): Listing<U> {
        let listing = new Listing<U>();
        listing.data = jsonObject.data.map(itemType.fromJSON);
        listing.totalRecords = jsonObject.totalRecords;
        return listing;
    }
}

export function mapListingToJson<R>(itemType: ListingItem<R>): OperatorFunction<Listing<object>, Listing<R>> {
    return map((obj: Listing<object>) => Listing.fromJSON(itemType, obj));
}

export class ScrollingListing<T> {
    data: T[];
    nextOffset: number;

    static fromJSON<U>(itemType: ListingItem<U>, jsonObject: ScrollingListing<object>): ScrollingListing<U> {
        let listing = new ScrollingListing<U>();
        listing.data = jsonObject.data.map(itemType.fromJSON);
        listing.nextOffset = jsonObject.nextOffset;
        return listing;
    }
}

export function mapScrollingListingToJson<R>(itemType: ListingItem<R>): OperatorFunction<ScrollingListing<object>, ScrollingListing<R>> {
    return map((obj: ScrollingListing<object>) => ScrollingListing.fromJSON(itemType, obj));
}
