import {Country} from '../../../common/enums/country';
import {PricingTableDataRow} from '../pricing-table/pricing-table.component';

export class ShippingPriceTable {
    country: Country;
    dataByCodes: PricingTableDataRow[];

    constructor() {
        this.dataByCodes = []
    }
}