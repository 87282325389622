import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, finalize, mergeMap} from 'rxjs/operators';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {FocusOnElement} from '../../../common/FocusOnElement';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationService} from '../../../common/service/validation.service';
import {ZipCodeValidator} from '../../../common/zip-code-validator';
import {Currencies} from '../../../currencies';
import {WizardStepChangeEvent} from '../../../form-inputs/wizard/wizard-base';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {SidenavController} from '../../../sidenav-controller';
import {ClientGroupService} from '../../client-group/client-group.service';
import {ClientGroup} from '../../client-group/ClientGroup';
import {HasSaveTable} from '../../subsystem/base-profit-margin.component';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';
import {SupplierService} from '../../supplier/supplier.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {AddClientSubmitEvent, AddClientSubmitEventType} from '../add-client-submit-event';
import {Client} from '../client';
import {ClientService} from '../client.service';
import {SimpleProfitMarginSource, validateSimpleProfitMargins} from '../simple-profit-margin-source/simple-profit-margin-source';
import {ProfitMarginTarget} from "../../subsystem/profit-margin/profit-margin-target";

@Component({
    selector: 'app-create-client',
    templateUrl: './create-client.component.html',
    styleUrls: ['./create-client.component.css'],
    providers: [ValidationService, ClientService, DataServiceHelper, ClientGroupService, SupplierService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateClientComponent implements OnInit, OnDestroy {

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly confAddGlobalMarginButtonId = 'confAddGlobalMarginButtonId';
    readonly confAddGlobalMarginInputId = 'confAddGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';

    readonly STEPS = {
        FILL_DETAILS: 'fill-details',
        FILL_PROFIT_MARGINS_SYSTEMS: 'fill-profit-margin-systems',
        FILL_PROFIT_MARGINS_ROOF_SYSTEMS: 'fill-profit-margin-roof-systems',
        FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS: 'fill-profit-margin-entrance-systems',
        FILL_PROFIT_MARGINS_CONFIG_ADDONS: 'fill-profit-margin-config-addons',
        FILL_PROFIT_MARGINS_GATES: 'fill-profit-margin-gates'
    };

    readonly route: ActivatedRoute;

    possibleTargets = ProfitMarginTarget;

    client: Client;
    existingGroups: ClientGroup[] = [];
    validationErrors = {};
    displayDialog = false;
    @Output() onSubmit: EventEmitter<AddClientSubmitEvent> = new EventEmitter<AddClientSubmitEvent>();
    suppliers: SupplierInterface[];
    groupSimpleProfitMarginSource?: SimpleProfitMarginSource;
    simpleProfitMarginSource?: SimpleProfitMarginSource;

    selectCurrencies: SelectItem[] = [];
    private enterHotkey: Hotkey;
    validateClientDetailsStep: WizardStepValidator;
    dataReady = false;
    saveInProgress = false;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    constructor(public translate: TranslateService,
                public validationService: ValidationService,
                private sidenavController: SidenavController,
                private growlMessageController: GrowlMessageController,
                private router: Router,
                public clientService: ClientService,
                private clientGroupService: ClientGroupService,
                public permissions: Permissions,
                public hotkeysService: HotkeysService,
                public changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private supplierService: SupplierService,
                injector: Injector) {
        this.validateClientDetailsStep = () => this.validateClientDetails();
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        for (let item in Currencies) {
            this.selectCurrencies.push({label: item, value: item});
        }
        this.sidenavController.hide();
        this.prepareDataForClient();
    }

    prepareDataForClient(): void {
        forkJoin({
            clientGroups: this.clientGroupService.getClientGroups(undefined, undefined, undefined, undefined, undefined),
            client: of(new Client()),
            suppliers: this.supplierService.getSuppliersForSimpleProfitMargins()
        }).subscribe({
            next: data => {
                this.existingGroups = data.clientGroups.data;
                this.client = data.client;
                this.suppliers = data.suppliers;
                this.dataReady = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                FocusOnElement.tryToFocus('groupName', 0, 3, 100);
                console.debug('prepareDataForClient` completed!');
            }
        });
    }

    clearError(controlName) {
        this.validationErrors[controlName] = undefined;
    }

    ngOnDestroy(): void {
        this.sidenavController.show();
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    submit() {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        if (!!this.client.bulkAddonProfitMargin) {
            this.client.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.client.bulkAddonProfitMargin);
        }
        this.clientService.saveClient(this.client)
            .pipe(finalize(() => this.saveInProgress = false),
                mergeMap(clientId => forkJoin([...this.childTables.map(table => table.saveTable(clientId)),
                    this.clientService.saveSimpleProfitMarginSource(clientId, this.simpleProfitMarginSource)
                ])))
            .subscribe({
                next: () => {
                    this.prepareSuccessMessage();
                    this.notifyParent('success');
                },
                error: errorMessage => {
                    this.validationErrors = this.errors.handle(errorMessage);
                    this.changeDetector.markForCheck();
                }
            });
    }

    onStepChange(event: WizardStepChangeEvent): void {
        this.changeDetector.markForCheck();
    }

    reloadProfitMargins(clientGroupId: number): void {
        if (clientGroupId != undefined) {
            this.clientGroupService.getSimpleProfitMarginSource(clientGroupId).subscribe(groupSimpleProfitMarginSource => {
                this.groupSimpleProfitMarginSource = groupSimpleProfitMarginSource;
                if (groupSimpleProfitMarginSource != undefined) {
                    if (this.simpleProfitMarginSource == undefined) {
                        this.simpleProfitMarginSource = new SimpleProfitMarginSource();
                    }
                } else {
                    this.simpleProfitMarginSource = undefined;
                    Object.keys(this.validationErrors)
                        .filter(key => key.startsWith('simpleProfitMarginSource'))
                        .forEach(key => this.validationErrors[key] = undefined);
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    public notifyParent(notificationType: AddClientSubmitEventType) {
        this.router.navigate(['/features/client']);
    }

    exitWizard(): void {
        this.router.navigate(['/features/client']);
    }

    prepareSuccessMessage() {
        this.growlMessageController.info('CLIENT-DETAILS.CLIENT_CREATED');
    }

    hide() {
        this.hotkeysService.remove(this.enterHotkey);
    }

    validateClientDetails(): Observable<boolean> {
        this.validationService.validateNotEmpty(this.client, ['name'], this.validationErrors, 'clientDto');
        if (this.client.email && !/^.+@.+$/.test(this.client.email)) {
            this.validationErrors['email'] = 'error.clientDto.email.not_well_formatted';
        }
        if (!this.client.groupId) {
            this.validationErrors['groupId'] = 'error.clientDto.groupId.not_null';
        }
        if (this.client.address.zip != undefined &&
            !new ZipCodeValidator().validate(this.client.address.country, this.client.address.zip)) {
            this.validationErrors['address.zip'] = 'error.clientDto.address.zip.pattern_not_matched';
        }
        if (this.simpleProfitMarginSource != undefined) {
            Object.assign(this.validationErrors, validateSimpleProfitMargins(this.simpleProfitMarginSource, this.suppliers, false));
        }
        if (!!this.client.bulkAddonProfitMargin
            && !isValidProfitMarginValueString(normalizeProfitMarginValueString(this.client.bulkAddonProfitMargin))) {
            this.validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
        }
        if (this.validationErrorsPresent()) {
            return of(false);
        }
        return this.clientService.validate(this.client).pipe(catchError(response => {
            this.validationErrors = this.errors.handle(response);
            return of(false);
        }));
    }

    private validationErrorsPresent(): boolean {
        return Object.keys(this.validationErrors).filter(key => this.validationErrors[key] != undefined).length > 0;
    }
}
