import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ProfileType} from '../../../../window-designer/catalog-data/profile-interface';
import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, ProfileField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ProfileFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {Profile} from './profile';
import {ProfileService} from './profile.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css', '../../shared-styles.css'],
    providers: [ProfileService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent extends SingleSystemCheckboxCrudComponent<Profile, ProfileService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    readonly entranceSystemTypeGroups = [ProductTypeGroup.ENTRANCE];

    filterType: Observable<SelectItem[]>;
    availableProfileTypes: Observable<SelectItem[]>;
    item: Profile;
    @ViewChild('dt') datatable;
    validateDataStep: WizardStepValidator;
    validateSystemStep: WizardStepValidator;

    editPermits: FieldLimitation[] = [];
    fieldUsage: ProfileFieldUsage;
    CatalogTab = CatalogTab;
    ProfileField = ProfileField;

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS',
        ENTRANCE_SYSTEMS: 'ENTRANCE_SYSTEMS'
    };

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, ProfileService, 'PROFILE', 'Profile');
        this.item = new Profile();
        this.validateDataStep = () => this.validateForm();
        this.validateSystemStep = () => this.validateSystems();
        this.initDefaultSortOrder();
        this.fieldUsage = new ProfileFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterType = this.translatedSelectItemService.buildSortedDropdown(ProfileType, 'PROFILE.FORM.TYPE.', '');
        this.availableProfileTypes = this.filterType.pipe(map(types => types.slice(1)));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.PROFILES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'profile';
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new Profile();
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.item = new Profile();
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    getNewItem(): Profile {
        this.selectedWindowSystems = [];
        return new Profile();
    }

    getItemWithImage(profileId: number) {
        forkJoin({
            item: this.itemService.getItem(profileId),
            file: this.itemService.getImageAsFile(profileId),
            linkedSystems: this.getLinkedWindowSystems(profileId)
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.file;
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }

    validateSystems(): Observable<boolean> {
        if (this.selectedWindowSystems && this.selectedWindowSystems.length > 0) {
            this.item.systemIds = this.selectedWindowSystems;

            return this.itemService.validateSystems(this.item).pipe(
                tap(backendValidationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
        }

        return of(true);
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        this.item.systemIds = [];
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        if (!this.item.names[this.userLang] || this.item.names[this.userLang].trim() === '') {
            this.validationErrors[`names[${this.userLang}]`] = `error.profileDto.names[${this.userLang}].not_empty`;
        }
        if (!this.item.symbol || this.item.symbol.trim() === '') {
            this.validationErrors['symbol'] = 'error.profileDto.symbol.not_empty';
        }
        this.validationErrors['compositionDistances.defaultWidth'] = MultiValidator.of('error.profileDto.compositionDistances.defaultWidth')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 99999, false, true)
            .validate(this.item.compositionDistances.defaultWidth);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.profileDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        this.validationErrors = {};
        let observable: Observable<number>;
        if (this.copyMode) {
            observable = this.itemService.copy(this.selectedItem.id, this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
        } else {
            observable = this.itemService.saveItem(this.item, this.file).pipe(
                mergeMap(this.editLinksAfterSave()));
        }
        observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    getLockedWindowSystemIds(): number[] {
        return this.item.type === ProfileType.THRESHOLD
            ? this.windowSystems.filter(ws => !WindowSystemType.getByName(ws.systemType).doors).map(ws => ws.id)
            : [];
    }
}
