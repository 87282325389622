<p-dataTable #dataTable [value]="tableData.positions" [rows]="tableData.rowsPerPage"
             [rowsPerPageOptions]="limitedRowsPerPageOptions" [seqNum]="seqNum" [paginator]="true"
             (onRowClick)="emitSelectionChange($event.data)" (onRowDblclick)="emitRowDblclick($event)" selectionMode="single"
             (selectionChange)="emitSelectionChange($event)" (onRowKeyDown)="handleRowKeyDown($event)"
             [(selection)]="tableData.selectedPosition" [lazy]="true"
             [totalRecords]="tableData.totalRecords" [responsive]="true"
             (onLazyLoad)="emitLazyLoad($event)" dataKey="id" [rowTrackBy]="rowTrackBy"
             [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">
    <p-column [style]="{'vertical-align':'middle'}">
        <ng-template pTemplate="header">
            <app-tristate-checkbox [ngModel]="tableData.allSelectedState"
                                   (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
        </ng-template>
        <ng-template let-position="rowData" pTemplate="body">
            <span class="flex-row">
                <app-checkbox [ngModel]="isSelectedItem(position)"
                              [disabled]="position.type === positionType.OFFER_CHARGE"
                              (ngModelChange)="selectItem(position)"></app-checkbox>
                <i *ngIf="position.pricingOutdated" (click)="actionClicked('POSITION_MESSAGES', position)"
                   class="fa font-awesome fas fa-exclamation-circle pricing_outdated-severity-color comment-icon-alignment"
                   title="{{ 'GENERAL.POSITION_PRICING_OUTDATED' | translate }}"></i>
                <i *ngIf="!position.pricingOutdated && position.highestMessageSeverity != null"
                   (click)="actionClicked('POSITION_MESSAGES', position)"
                   class="fa font-awesome fas fa-exclamation-circle comment-icon-alignment"
                   [ngClass]="position.highestMessageSeverity.toLowerCase() + '-severity-color'"
                   title="{{ ('GENERAL.MESSAGE.' + position.highestMessageSeverity) | translate }}"></i>

                <i *ngIf="showUnreadCommentIcon(position)" class="fa font-awesome fas fa-comment error-severity-color comment-icon-alignment" title="{{'GENERAL.OFFER_UNREAD_COMMENT_EXIST'|translate}}"></i>
                <i *ngIf="showCommentExistsIcon(position)" class="fa font-awesome fas fa-comment comment-icon-alignment" title="{{'GENERAL.OFFER_COMMENT_EXIST'|translate}}"></i>
                <i *ngIf="position.validationDisabled" class="fa font-awesome fas fa-chain-broken comment-icon-alignment" title="{{ 'OFFER.POSITIONS.VALIDATION_DISABLED' | translate }}"></i>
            </span>
        </ng-template>
    </p-column>

    <!-- ACTIONS -->
    <p-column header="{{ 'OFFER.POSITIONS.FORM.ACTIONS' | translate }}" [sortable]="false">
        <ng-template let-position="rowData" pTemplate="body">
            <div class="table-row-action-buttons">
                <app-simple-button [buttonId]="'edit_' + position.id" icon="edit" type="main-action" [size]="36"
                                   label="{{'OFFER.POSITIONS.ACTIONS.TOOLTIPS.EDIT_OFFER_POSITION' | translate}}" [inlineLabel]="false"
                                   (onClick)="actionClicked('EDIT', position)"></app-simple-button>
                <button-with-menu [buttonId]="'more_' + position.id" leftButtonIcon="more_horiz" buttonType="filter"
                                  [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                  buttonLabel="{{'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate}}" [buttonInlineLabel]="false"
                                  [menuElementBuilder]="buildRowActionBuilder(position)"
                                  (menuElementSelected)="handleRowAction($event)"></button-with-menu>
            </div>
        </ng-template>
    </p-column>

    <p-column field="printOrder" [sortable]="true"
              header="{{'OFFER.POSITIONS.FORM.PRINT_ORDER' | translate }}"></p-column>

    <p-column *ngIf="columnByField['windowSystemName']" field="windowSystemName"
              [header]="columnByField['windowSystemName'].header | translate"
              [sortable]="columnByField['windowSystemName'].sortable" [filter]="isFilterable(columnByField['windowSystemName'].filterable)">

        <ng-template let-position="rowData" pTemplate="body">
            <div>{{ position.windowSystemName[userLang] }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['name']" field="name" [header]="columnByField['name'].header | translate"
              [sortable]="columnByField['name'].sortable" [filter]="isFilterable(columnByField['name'].filterable)">
        <ng-template let-position="rowData" pTemplate="body">
            <div>{{ position.name[userLang] }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['parentPrintOrder'] && anyPositionHasParent()" field="parentPrintOrder"
              [header]="columnByField['parentPrintOrder'].header | translate"
              [sortable]="columnByField['parentPrintOrder'].sortable"
              [filter]="isFilterable(columnByField['parentPrintOrder'].filterable)">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ position.parentPrintOrder ? '#' + position.parentPrintOrder : '' }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['dimensions']" field="dimensions"
              [header]="columnByField['dimensions'].header | translate"
              [sortable]="columnByField['dimensions'].sortable"
              [filter]="isFilterable(columnByField['dimensions'].filterable)">
        <ng-template let-position="rowData" pTemplate="body">
            <ng-container *ngIf="['CONFIGURABLE_ADDON', 'CONFIG_SYSTEM'].includes(position.type) && !position.parentPrintOrder; else plainDimensions">
                <span>{{ getDimensionsForConfigAddonFromDeletedWindow(position) }}</span>
            </ng-container>
            <ng-template #plainDimensions>
                <span>{{ position.dimensions | convertMillimetersToInches | async }}</span>
            </ng-template>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['quantity']" field="quantity" [header]="columnByField['quantity'].header | translate"
              [sortable]="columnByField['quantity'].sortable"
              [filter]="isFilterable(columnByField['quantity'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

    <p-column *ngIf="columnByField['unit']" header="{{ 'OFFER.POSITIONS.FORM.UNIT' | translate }}">
        <ng-template let-position="rowData" pTemplate="body">
            <div>{{ getQuantityTypeForPosition(position) | translate }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['profitMargin'] && tableData.showProfitMarginColumn" field="profitMargin"
              [header]="columnByField['profitMargin'].header | translate"
              [sortable]="columnByField['profitMargin'].sortable"
              [filter]="isFilterable(columnByField['profitMargin'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

    <p-column *ngIf="columnByField['rabate']" field="rabate" [header]="columnByField['rabate'].header | translate"
              [sortable]="columnByField['rabate'].sortable"
              [filter]="isFilterable(columnByField['rabate'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

    <p-column *ngIf="columnByField['sellPrice.netValue']" field="sellPrice.netValue"
              [header]="columnByField['sellPrice.netValue'].header | translate"
              [sortable]="columnByField['sellPrice.netValue'].sortable"
              [filter]="isFilterable(columnByField['sellPrice.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.sellPrice.netValue, position.pricingCurrency, true)}}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.sellPrice.netValue, position.pricingCurrency, true) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['sellPrice.vatPercent']" field="sellPrice.vatPercent"
              [header]="columnByField['sellPrice.vatPercent'].header | translate"
              [sortable]="columnByField['sellPrice.vatPercent'].sortable"
              [filter]="isFilterable(columnByField['sellPrice.vatPercent'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

    <p-column *ngIf="columnByField['buyPrice.vatPercent']" field="buyPrice.vatPercent"
              [header]="columnByField['buyPrice.vatPercent'].header | translate"
              [sortable]="columnByField['buyPrice.vatPercent'].sortable"
              [filter]="isFilterable(columnByField['buyPrice.vatPercent'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

    <p-column *ngIf="columnByField['sellPrice.grossValue']" field="sellPrice.grossValue"
              [header]="columnByField['sellPrice.grossValue'].header | translate"
              [sortable]="columnByField['sellPrice.grossValue'].sortable"
              [filter]="isFilterable(columnByField['sellPrice.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.sellPrice.grossValue, position.pricingCurrency, true) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.sellPrice.grossValue, position.pricingCurrency, true) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['sellValue.netValue']" field="sellValue.netValue"
              [header]="columnByField['sellValue.netValue'].header | translate"
              [sortable]="columnByField['sellValue.netValue'].sortable"
              [filter]="isFilterable(columnByField['sellValue.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.sellValue.netValue, position.pricingCurrency, true) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.sellValue.netValue, position.pricingCurrency, true) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['sellValue.grossValue']" field="sellValue.grossValue"
              [header]="columnByField['sellValue.grossValue'].header | translate"
              [sortable]="columnByField['sellValue.grossValue'].sortable"
              [filter]="isFilterable(columnByField['sellValue.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.sellValue.grossValue, position.pricingCurrency, true) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.sellValue.grossValue, position.pricingCurrency, true) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['buyPrice.netValue']" field="buyPrice.netValue"
              [header]="columnByField['buyPrice.netValue'].header | translate"
              [sortable]="columnByField['buyPrice.netValue'].sortable"
              [filter]="isFilterable(columnByField['buyPrice.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.buyPrice.netValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.buyPrice.netValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['buyPrice.grossValue']" field="buyPrice.grossValue"
              [header]="columnByField['buyPrice.grossValue'].header | translate"
              [sortable]="columnByField['buyPrice.grossValue'].sortable"
              [filter]="isFilterable(columnByField['buyPrice.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.buyPrice.grossValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.buyPrice.grossValue, position.pricingCurrency) }} </span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['buyValue.netValue']" field="buyValue.netValue"
              [header]="columnByField['buyValue.netValue'].header | translate"
              [sortable]="columnByField['buyValue.netValue'].sortable"
              [filter]="isFilterable(columnByField['buyValue.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.buyValue.netValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.buyValue.netValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['buyValue.grossValue']" field="buyValue.grossValue"
              [header]="columnByField['buyValue.grossValue'].header | translate"
              [sortable]="columnByField['buyValue.grossValue'].sortable"
              [filter]="isFilterable(columnByField['buyValue.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.buyValue.grossValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.buyValue.grossValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <ng-container *ngIf="offer.sellerClientId != undefined">
        <p-column *ngIf="columnByField['retailSellPrice.netValue']" field="retailSellPrice.netValue"
                  [header]="columnByField['retailSellPrice.netValue'].header | translate"
                  [sortable]="columnByField['retailSellPrice.netValue'].sortable"
                  [filter]="isFilterable(columnByField['retailSellPrice.netValue'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.retailSellPrice.netValue, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.retailSellPrice.netValue, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="columnByField['retailSellPrice.vatPercent']" field="retailSellPrice.vatPercent"
                  [header]="columnByField['retailSellPrice.vatPercent'].header | translate"
                  [sortable]="columnByField['retailSellPrice.vatPercent'].sortable"
                  [filter]="isFilterable(columnByField['retailSellPrice.vatPercent'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1"></p-column>

        <p-column *ngIf="columnByField['retailSellPrice.grossValue']" field="retailSellPrice.grossValue"
                  [header]="columnByField['retailSellPrice.grossValue'].header | translate"
                  [sortable]="columnByField['retailSellPrice.grossValue'].sortable"
                  [filter]="isFilterable(columnByField['retailSellPrice.grossValue'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.retailSellPrice.grossValue, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.retailSellPrice.grossValue, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="columnByField['retailSellValue.netValue']" field="retailSellValue.netValue"
                  [header]="columnByField['retailSellValue.netValue'].header | translate"
                  [sortable]="columnByField['retailSellValue.netValue'].sortable"
                  [filter]="isFilterable(columnByField['retailSellValue.netValue'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.retailSellValue.netValue, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.retailSellValue.netValue, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="columnByField['retailSellValue.grossValue']" field="retailSellValue.grossValue"
                  [header]="columnByField['retailSellValue.grossValue'].header | translate"
                  [sortable]="columnByField['retailSellValue.grossValue'].sortable"
                  [filter]="isFilterable(columnByField['retailSellValue.grossValue'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.retailSellValue.grossValue, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.retailSellValue.grossValue, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="columnByField['netRetailIncome']" field="netRetailIncome"
                  [header]="columnByField['netRetailIncome'].header | translate" [sortable]="columnByField['netRetailIncome'].sortable"
                  [filter]="isFilterable(columnByField['netRetailIncome'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.netRetailIncome, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.netRetailIncome, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="columnByField['grossRetailIncome']" field="grossRetailIncome"
                  [header]="columnByField['grossRetailIncome'].header | translate" [sortable]="columnByField['grossRetailIncome'].sortable"
                  [filter]="isFilterable(columnByField['grossRetailIncome'].filterable)" filterType="number"
                  [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
            <ng-template let-position="rowData" pTemplate="body">
                <span>{{ formatPriceInSelectedCurrency(position.grossRetailIncome, position.pricingCurrency) }}</span>
                <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.grossRetailIncome, position.pricingCurrency) }}</span>
            </ng-template>
        </p-column>
    </ng-container>

    <p-column *ngIf="columnByField['netIncome']" field="netIncome"
              [header]="columnByField['netIncome'].header | translate" [sortable]="columnByField['netIncome'].sortable"
              [filter]="isFilterable(columnByField['netIncome'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.netIncome, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.netIncome, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['grossIncome']" field="grossIncome"
              [header]="columnByField['grossIncome'].header | translate"
              [sortable]="columnByField['grossIncome'].sortable"
              [filter]="isFilterable(columnByField['grossIncome'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.grossIncome, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.grossIncome, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['venskaBuyPrice.netValue']" field="venskaBuyPrice.netValue"
              [header]="columnByField['venskaBuyPrice.netValue'].header | translate"
              [sortable]="columnByField['venskaBuyPrice.netValue'].sortable"
              [filter]="isFilterable(columnByField['venskaBuyPrice.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.venskaBuyPrice.netValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.venskaBuyPrice.netValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['venskaBuyPrice.grossValue']" field="venskaBuyPrice.grossValue"
              [header]="columnByField['venskaBuyPrice.grossValue'].header | translate"
              [sortable]="columnByField['venskaBuyPrice.grossValue'].sortable"
              [filter]="isFilterable(columnByField['venskaBuyPrice.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.venskaBuyPrice.grossValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.venskaBuyPrice.grossValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['venskaBuyValue.netValue']" field="venskaBuyValue.netValue"
              [header]="columnByField['venskaBuyValue.netValue'].header | translate"
              [sortable]="columnByField['venskaBuyValue.netValue'].sortable"
              [filter]="isFilterable(columnByField['venskaBuyValue.netValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.venskaBuyValue.netValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.venskaBuyValue.netValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['venskaBuyValue.grossValue']" field="venskaBuyValue.grossValue"
              [header]="columnByField['venskaBuyValue.grossValue'].header | translate"
              [sortable]="columnByField['venskaBuyValue.grossValue'].sortable"
              [filter]="isFilterable(columnByField['venskaBuyValue.grossValue'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.venskaBuyValue.grossValue, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.venskaBuyValue.grossValue, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['netVenskaIncome']" field="netVenskaIncome"
              [header]="columnByField['netVenskaIncome'].header | translate"
              [sortable]="columnByField['netVenskaIncome'].sortable"
              [filter]="isFilterable(columnByField['netVenskaIncome'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.netVenskaIncome, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.netVenskaIncome, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['grossVenskaIncome']" field="grossVenskaIncome"
              [header]="columnByField['grossVenskaIncome'].header | translate"
              [sortable]="columnByField['grossVenskaIncome'].sortable"
              [filter]="isFilterable(columnByField['grossVenskaIncome'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0" [filterNumericStep]="1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ formatPriceInSelectedCurrency(position.grossVenskaIncome, position.pricingCurrency) }}</span>
            <span [ngClass]="selectedCurrency != offer.currency ? 'secondary-price' : 'display-none'">{{ formatPriceInOfferCurrency(position.grossVenskaIncome, position.pricingCurrency) }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['dealerDiscount'] && tableData.showDealerDiscountColumn" field="dealerDiscount"
              [header]="columnByField['dealerDiscount'].header | translate"
              [sortable]="columnByField['dealerDiscount'].sortable"
              [filter]="isFilterable(columnByField['dealerDiscount'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0.0" [filterNumericStep]="0.1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ position.calculatedDealerDiscount + ' ('+ position.dealerDiscount + ')' }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['tempDealerDiscount'] && tableData.showDealerDiscountColumn" field="tempDealerDiscount"
              [header]="columnByField['tempDealerDiscount'].header | translate"
              [sortable]="columnByField['tempDealerDiscount'].sortable"
              [filter]="isFilterable(columnByField['tempDealerDiscount'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0.0" [filterNumericStep]="0.1">
        <ng-template let-position="rowData" pTemplate="body">
            <span>{{ position.calculatedTempDealerDiscount != undefined ? (position.calculatedTempDealerDiscount + ' ('+ position.tempDealerDiscount + ')') : undefined }}</span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['basePrice']" field="basePrice"
              [header]="columnByField['basePrice'].header | translate" [sortable]="columnByField['basePrice'].sortable"
              [filter]="isFilterable(columnByField['basePrice'].filterable)" filterType="number"
              [filterAllowDecimals]="true" [filterNumericMinValue]="0.0" [filterNumericStep]="0.1"></p-column>

    <p-column *ngIf="columnByField['realDimensions'] && tableData.showRealDimensionsColumn" field="realDimensions"
              [header]="columnByField['realDimensions'].header | translate"></p-column>

    <ng-template pTemplate="paginatorLeft">
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: tableData.fromRecord, to: tableData.toRecord, total: tableData.totalRecords} }}</span>
    </ng-template>
</p-dataTable>
