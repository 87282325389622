import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {WebshopAreaBarsCode} from '../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {PriceWithTax} from '../../offer/offer';
import {WebshopBusinessType} from '../webshop-business-type/webshop-business-type';
import {WindowSystemModel} from '../window-system-model/WindowSystemModel';

@JsonDeserializable
export class WebshopBusinessTypeWithPrice {
    priceWithTax: PriceWithTax;
    businessType: WebshopBusinessType;

    static fromJSON(jsonObject: any): WebshopBusinessTypeWithPrice {
        const businessTypeWithPrice = new WebshopBusinessTypeWithPrice();
        if (jsonObject.priceWithTax != undefined) {
            businessTypeWithPrice.priceWithTax = PriceWithTax.fromJSON(jsonObject.priceWithTax);
        }
        if (jsonObject.businessType != undefined) {
            businessTypeWithPrice.businessType = WebshopBusinessType.fromJSON(jsonObject.businessType);
        }
        return businessTypeWithPrice;
    }
}

@JsonDeserializable
export class WebshopWindowSystemModelWithPrice {
    priceWithTaxBeforeRabate?: PriceWithTax;
    rabate?: number;
    priceWithTax: PriceWithTax;
    windowSystemModel: WindowSystemModel;
    description: MultilanguageField;
    longDescription: MultilanguageField;
    tagIds: number[];
    material: string;
    sliderImageIds: number[];

    static fromJSON(jsonObject: any): WebshopWindowSystemModelWithPrice {
        const obj = new WebshopWindowSystemModelWithPrice();
        if (jsonObject.priceWithTaxBeforeRabate != undefined) {
            obj.priceWithTaxBeforeRabate = PriceWithTax.fromJSON(jsonObject.priceWithTaxBeforeRabate);
        }
        obj.rabate = jsonObject.rabate;
        if (jsonObject.priceWithTax != undefined) {
            obj.priceWithTax = PriceWithTax.fromJSON(jsonObject.priceWithTax);
        }
        obj.windowSystemModel = jsonObject.windowSystemModel;
        obj.description = jsonObject.description != undefined
            ? MultilanguageField.fromJSON(jsonObject.description)
            : new MultilanguageField();
        obj.longDescription = jsonObject.longDescription != undefined
            ? MultilanguageField.fromJSON(jsonObject.longDescription)
            : new MultilanguageField();
        obj.tagIds = jsonObject.tagIds != undefined ? [...jsonObject.tagIds] : [];
        obj.sliderImageIds = jsonObject.sliderImageIds != undefined ? [...jsonObject.sliderImageIds] : [];
        return obj;
    }
}

@JsonDeserializable
export class WebshopGlazingWithPrice {
    id: number;
    name: MultilanguageField;
    glazingGlassQuantity: number;
    useWarmDistanceFrames: boolean;
    price: PriceWithTax;
    sortIndex: number;

    static fromJSON(jsonObject: any): WebshopGlazingWithPrice {
        const glazing = new WebshopGlazingWithPrice();
        glazing.id = jsonObject.id;
        if (jsonObject.name != undefined) {
            glazing.name = MultilanguageField.fromJSON(jsonObject.name);
        }
        glazing.glazingGlassQuantity = jsonObject.glazingGlassQuantity;
        glazing.useWarmDistanceFrames = jsonObject.useWarmDistanceFrames;
        if (jsonObject.price != undefined) {
            glazing.price = PriceWithTax.fromJSON(jsonObject.price);
        }
        glazing.sortIndex = jsonObject.sortIndex;
        return glazing;
    }
}

@JsonDeserializable
export class WebshopColorWithPrice {
    priceWithTax: PriceWithTax;
    color: ColorInterface;

    static fromJSON(jsonObject: any): WebshopColorWithPrice {
        const color = new WebshopColorWithPrice();
        if (jsonObject.priceWithTax != undefined) {
            color.priceWithTax = PriceWithTax.fromJSON(jsonObject.priceWithTax);
        }
        if (jsonObject.color != undefined) {
            color.color = jsonObject.color;
        }
        return color;
    }
}

@JsonDeserializable
export class WebshopAreaBarsWithPrice {
    priceWithTax?: PriceWithTax;
    areaBarCode: WebshopAreaBarsCode;

    static fromJSON(jsonObject: any): WebshopAreaBarsWithPrice {
        const webshopAreaBars = new WebshopAreaBarsWithPrice();
        if (jsonObject.priceWithTax != undefined) {
            webshopAreaBars.priceWithTax = PriceWithTax.fromJSON(jsonObject.priceWithTax);
        }
        webshopAreaBars.areaBarCode = jsonObject.areaBarCode;
        return webshopAreaBars;
    }
}

@JsonDeserializable
export class WebshopChargeWithPrice {
    id: number;
    name: MultilanguageField;
    info: MultilanguageField;
    value: WindowSystemDefaults;
    price: PriceWithTax;
    windowBasePrice: PriceWithTax;

    static fromJSON(jsonObject: any): WebshopChargeWithPrice {
        const charge = new WebshopChargeWithPrice();
        charge.id = jsonObject.id;
        if (jsonObject.name != undefined) {
            charge.name = MultilanguageField.fromJSON(jsonObject.name);
        }
        if (jsonObject.info != undefined) {
            charge.info = MultilanguageField.fromJSON(jsonObject.info);
        }
        charge.value = jsonObject.value;
        if (jsonObject.price != undefined) {
            charge.price = PriceWithTax.fromJSON(jsonObject.price);
        }
        if (jsonObject.windowBasePrice != undefined) {
            charge.windowBasePrice = PriceWithTax.fromJSON(jsonObject.windowBasePrice);
        }
        return charge;
    }
}
