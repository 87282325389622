import {EventEmitter, Injectable, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class SidenavController {

    public readonly visibilityChange: Observable<boolean>;
    private readonly visibilityState: EventEmitter<boolean>;
    private hideStack: number;
    public readonly contentChange: Observable<TemplateRef<any>>;
    private readonly contentState: EventEmitter<TemplateRef<any>>;

    constructor() {
        this.visibilityState = new EventEmitter<boolean>(true);
        this.visibilityChange = this.visibilityState.asObservable();
        this.hideStack = 0;
        this.contentState = new EventEmitter<TemplateRef<any>>();
        this.contentChange = this.contentState.asObservable();
    }

    public hide(): void {
        ++this.hideStack;
        this.visibilityState.next(false);
    }

    public show(): void {
        this.hideStack = Math.max(this.hideStack - 1, 0);
        this.visibilityState.next(this.hideStack === 0);
    }

    public replaceContent(template: TemplateRef<any>): void {
        this.contentState.next(template);
    }
}

let sidenavControllerInstance: SidenavController = undefined;
export function sidenavControllerFactory(): SidenavController {
    if (sidenavControllerInstance == undefined) {
        sidenavControllerInstance = new SidenavController();
    }
    return sidenavControllerInstance;
}
