<div class="new-form-600">

    <!-- TYPE -->
    <div class="new-form-row" *ngIf="fieldUsage.show(SubwindowTypeField.TYPE)">
        <app-input-text inputId="type" label="{{ 'SUBWINDOWTYPE.FORM.CODE' | translate }}"
                        [disabled]="fieldUsage.disabled(SubwindowTypeField.TYPE)"
                        [ngModel]="item.type">
        </app-input-text>
    </div>

    <!-- NAME -->
    <app-translation-fields *ngIf="fieldUsage.show(SubwindowTypeField.NAME)"
                            inputId="name" label="{{ 'SUBWINDOWTYPE.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(SubwindowTypeField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.names"></app-translation-fields>

    <!-- SORT INDEX -->
    <div class="new-form-row">
        <app-input-number *ngIf="fieldUsage.show(SubwindowTypeField.FUNCTIONS_QUANTITY)"
                          inputId="functionsQuantity"
                          label="{{ 'SUBWINDOWTYPE.FORM.FUNCTIONS_QUANTITY' | translate }}"
                          [disabled]="fieldUsage.disabled(SubwindowTypeField.FUNCTIONS_QUANTITY)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['functionsQuantity']" [min]="0" [max]="10" [step]="1"
                          [(ngModel)]="item.functionsQuantity" [required]="true"></app-input-number>
    </div>
</div>
