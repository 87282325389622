import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Permissions} from '../../../auth/permission.service';
import {PrintHistoryEntry} from '../offer';
import {OffersService} from '../offer-service';
import {PrintService} from "../print-dialog/print-service";

@Component({
    selector: 'app-print-history',
    templateUrl: './print-history.component.html',
    styleUrls: ['./print-history.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PrintService, OffersService]
})
export class PrintHistoryComponent implements OnInit {

    @Input()
    offerId: number;

    @Output()
    onPrintClick = new EventEmitter<PrintHistoryEntry>();

    history: PrintHistoryEntry[] = [];

    constructor(private readonly offerService: OffersService,
                public readonly permissions: Permissions,
                private readonly translateService: TranslateService,
                public printService: PrintService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.offerService.getPrintHistory(this.offerId).subscribe(history => {
            this.history = history;
            this.changeDetector.markForCheck();
        });
    }

    print(entry: PrintHistoryEntry): void {
        this.onPrintClick.emit(entry);
    }
}
