import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Permissions} from '../../../auth/permission.service';
import {OfferStatus} from '../../../common/enums/OfferStatus';
import {Offer} from '../offer';
import {OffersService} from '../offer-service';
import {PaymentPackage} from '../payment-package/payment-package';
import {PaymentPackageService} from '../payment-package/payment-package.service';
import {StatusTransition} from './StatusTransition';
import {StatusTransitionHelper} from './StatusTransitionHelper';

@Injectable()
export class StatusTransitionDialogService {

    constructor(private permissions: Permissions,
                private offerService: OffersService,
                private paymentPackageService: PaymentPackageService) {
    }

    public createPossibleTransitions(offer: Offer, successCallback?: (actionName: string) => void,
                                     errorCallback?: (actionName: string, error: HttpErrorResponse) => void): StatusTransition[] {
        let possibleTransitions = [];
        let addTransition = (label: string, icon: string, apiCommand: (additionalParam?: any) => any, id: string) => {
            StatusTransitionHelper.genericAddTransition(possibleTransitions, label, icon, apiCommand, id, successCallback, errorCallback);
        };

        if (this.isPermitted({roles: ['ROLE_OPERATOR']})
            && (offer.status === OfferStatus.TO_SEND || offer.status === OfferStatus.REVIEWED
                || offer.status === OfferStatus.VERIFICATION_REJECTED)) {
            addTransition(
                'OFFER.ACTIONS.DIALOGS.BUTTON_SEND_TO_REVIEW',
                'comment',
                () => this.offerService.sendTheOfferToReview(offer.id),
                'SEND_TO_REVIEW'
            );
            StatusTransitionHelper.genericAddTransition(possibleTransitions,
                'OFFER.ACTIONS.DIALOGS.BUTTON_SEND_TO_VERIFY',
                'rate_review',
                () => this.offerService.sendTheOfferToVerify(offer.id),
                'SEND_TO_VERIFY',
                successCallback,
                errorCallback,
                offer.id);
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})
            && (offer.status === OfferStatus.TO_REVIEW || offer.status === OfferStatus.TO_VERIFY)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.SEND_BACK_TO_OPERATOR',
                'call_received',
                () => this.offerService.sendTheOfferBackToOperator(offer.id),
                'SEND_BACK_TO_OPERATOR'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPERATOR']})
            && (offer.status === OfferStatus.TO_SEND
                || offer.status === OfferStatus.REVIEWED
                || offer.status === OfferStatus.VERIFICATION_REJECTED)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.SEND_BACK_TO_HANDLOWIEC',
                'call_received',
                () => this.offerService.sendTheOfferBackToHandlowiec(offer.id),
                'SEND_BACK_TO_HANDLOWIEC'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPERATOR']})
            && (offer.status === OfferStatus.VERIFICATION_FOR_APPROVAL)) {
            addTransition(
                'OFFER.ACTIONS.DIALOGS.BUTTON_REJECT_CHANGES',
                'clear',
                () => this.offerService.rejectOfferChanges(offer.id),
                'REJECT_CHANGES'
            );
            addTransition(
                'OFFER.ACTIONS.DIALOGS.BUTTON_ACCEPT_CHANGES',
                'navigate_next',
                () => this.offerService.acceptOfferChanges(offer.id),
                'ACCEPT_CHANGES'
            );
        }

        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})
            && (offer.status === OfferStatus.NEW_OFFER || offer.status === OfferStatus.PARTNER_NEW_OFFER || offer.status === OfferStatus.TO_REVISE)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.ACCEPT_NEW_OFFER',
                'navigate_next',
                () => this.offerService.acceptNewOffer(offer.id),
                'ACCEPT_NEW_OFFER'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})
            && (offer.status === OfferStatus.VERIFICATION_APPROVED || offer.status === OfferStatus.TO_VERIFY)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.ACCEPT_VERIFIED_OFFER',
                'check',
                () => this.offerService.acceptOffer(offer.id),
                'ACCEPT_VERIFIED_OFFER'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})
            && (offer.status === OfferStatus.WAITING_FOR_PAYMENT || offer.status === OfferStatus.SUSPENDED
                || offer.status === OfferStatus.VERIFIED)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.REVERT_TO_SEND',
                'navigate_before',
                () => this.offerService.revertStatusToSend(offer.id),
                'REVERT_TO_SEND'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && offer.status === OfferStatus.CORRECTION) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.CONFIRM_CORRECTION',
                'navigate_before',
                null,
                'CONFIRM_CORRECTION'
            );
        }

        return possibleTransitions;
    }

    public createPaymentPackagePossibleTransitions(paymentPackage: PaymentPackage,
                                                   successCallback?: (actionName: string) => void,
                                                   errorCallback?: (actionName: string, error: HttpErrorResponse) => void): StatusTransition[] {
        let possibleTransitions = [];
        let addTransition = (label: string, icon: string, apiCommand: () => any, id: string) => {
            StatusTransitionHelper.genericAddTransition(possibleTransitions, label, icon, apiCommand, id, successCallback, errorCallback);
        };

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && (paymentPackage.status == undefined)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.WAITING_FOR_PAYMENT',
                'payment',
                () => this.paymentPackageService.setStatus(paymentPackage.id, OfferStatus.WAITING_FOR_PAYMENT),
                'WAITING_FOR_PAYMENT'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) &&
            (paymentPackage.status === OfferStatus.WAITING_FOR_PAYMENT)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.PAID',
                'monetization_on',
                () => this.paymentPackageService.setStatus(paymentPackage.id, OfferStatus.PAID),
                'PAID'
            );
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.SUSPENDED',
                'money_off',
                () => this.paymentPackageService.setStatus(paymentPackage.id, OfferStatus.SUSPENDED),
                'SUSPENDED'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) &&
            (paymentPackage.status === OfferStatus.SUSPENDED)) {
            addTransition(
                'OFFER.ACTIONS.TOOLTIPS.RESTORE',
                'monetization_on',
                () => this.paymentPackageService.setStatus(paymentPackage.id, OfferStatus.WAITING_FOR_PAYMENT),
                'WAITING_FOR_PAYMENT'
            );
        }

        return possibleTransitions;
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }
}
