<div class="component-container">
    <div *ngIf="backendInfo != undefined" class="application-info-container">
        <h2>
            Backend info
        </h2>
        <div class="application-info-rows">
            <div class="application-info-property">Version</div>
            <div>{{ backendInfo.version }}</div>
            <div class="application-info-property">Build user</div>
            <div>{{ backendInfo.buildUserName }} &lt;{{ backendInfo.buildUserEmail }}&gt;</div>
            <div class="application-info-property">Build date</div>
            <div>{{ backendInfo.buildTime }}</div>
            <div class="application-info-property">Git branch</div>
            <div>{{ backendInfo.branch }}</div>
            <div class="application-info-property">Git describe</div>
            <div>{{ backendInfo.describe }}</div>
            <div class="application-info-property">Git commit</div>
            <div>{{ backendInfo.commitId }}</div>
            <div class="application-info-property">Git commit author</div>
            <div>{{ backendInfo.commitUserName }} &lt;{{ backendInfo.commitUserEmail }}&gt;</div>
            <div class="application-info-property">Git commit message</div>
            <div>{{ backendInfo.commitMessageShort }}</div>
            <div class="application-info-property">Git commit date</div>
            <div>{{ backendInfo.commitTime }}</div>
        </div>
    </div>
    <div *ngIf="frontendInfo != undefined" class="application-info-container">
        <h2>
            Frontend info from git.json
        </h2>
        <div class="application-info-rows">
            <div class="application-info-property">Version</div>
            <div>{{ frontendInfo.version }}</div>
            <div class="application-info-property">Build user</div>
            <div>{{ frontendInfo.buildUserName }} &lt;{{ frontendInfo.buildUserEmail }}&gt;</div>
            <div class="application-info-property">Build date</div>
            <div>{{ frontendInfo.buildTime }}</div>
            <div class="application-info-property">Git branch</div>
            <div>{{ frontendInfo.branch }}</div>
            <div class="application-info-property">Git describe</div>
            <div>{{ frontendInfo.describe }}</div>
            <div class="application-info-property">Git commit</div>
            <div>{{ frontendInfo.commitId }}</div>
            <div class="application-info-property">Git commit author</div>
            <div>{{ frontendInfo.commitUserName }} &lt;{{ frontendInfo.commitUserEmail }}&gt;</div>
            <div class="application-info-property">Git commit message</div>
            <div>{{ frontendInfo.commitMessageShort }}</div>
            <div class="application-info-property">Git commit date</div>
            <div>{{ frontendInfo.commitTime }}</div>
        </div>
    </div>
    <div *ngIf="frontendVersion != undefined" class="application-info-container">
        <h2>
            Frontend version from package.json
        </h2>
        <div class="application-info-rows">
            <div class="application-info-property">Version</div>
            <div>{{ frontendVersion }}</div>
        </div>
    </div>
    <div class="application-info-container">
        <h2>
            Backend servers
        </h2>
        <p-table #table (onLazyLoad)="loadItemsLazy($event)" (onPage)="chosenPageNumber = $event.first"
                 [filterDelay]="400" [first]="chosenPageNumber" [lazy]="true"
                 [metaKeySelection]="true" [paginator]="false" [responsive]="true" [rowTrackBy]="rowTrackById"
                 [rowsPerPageOptions]="rowsPerPageOptions" [rows]="chosenRowsPerPage"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder" [totalRecords]="applicationServerStatuses?.length" [value]="applicationServerStatuses"
                 dataKey="id">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="columnHeaderClasses('hostname')">
                        <span>Hostname</span>
                    </th>
                    <th [ngClass]="columnHeaderClasses('applicationName')">
                        <span>Application name</span>
                    </th>
                    <th [ngClass]="columnHeaderClasses('applicationVersion')">
                        <span>Application version</span>
                    </th>
                    <th [ngClass]="columnHeaderClasses('lastPing')">
                        <span>Last status update</span>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template let-applicationServerStatus let-rowIndex="rowIndex" pTemplate="body">
                <tr [app-table-keyboard-navigation]="applicationServerStatus"
                    [attr.tabindex]="rowIndex" [ngClass]="{'datatable-data': true, 'status-online': isOnline(applicationServerStatus), 'status-offline': !isOnline(applicationServerStatus)}"
                    [pSelectableRowIndex]="rowIndex"
                    [pSelectableRow]="applicationServerStatus">
                    <td>{{ applicationServerStatus.hostname }}</td>
                    <td>{{ applicationServerStatus.applicationName }}</td>
                    <td>{{ applicationServerStatus.applicationVersion }}</td>
                    <td>{{ applicationServerStatus.lastPing | momentCurrentLang | amDateFormat: 'L LT' }}</td>
                    <td>
                        <app-simple-button (onClick)="deleteSelectedApplicationServer(applicationServerStatus)" [inlineLabel]="false" [size]="36" icon="cancel"
                                           label="{{ 'GENERAL.DELETE' | translate }}"
                                           type="delete"></app-simple-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
