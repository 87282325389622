<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{'SEAL.TITLE' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'SEAL.FORM.COPY' | translate }}" icon="content_copy"
                                   type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'SEAL.FORM.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="sealsTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(SealField.NAME)"
                          header="{{'SEAL.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column *ngIf="fieldUsage.show(SealField.SORT_INDEX)"
                          header="{{'SEAL.FORM.SORT_INDEX'|translate}}" [rowspan]="2" [sortable]="true" field="sortIndex"></p-column>
                <p-column *ngIf="fieldUsage.show(SealField.SHORTCUT)"
                          header="{{'SEAL.FORM.SHORTCUT'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(SealField.SYMBOL)"
                          header="{{'SEAL.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"
                          header="{{'SEAL.FORM.POSITION'|translate}}" [colspan]="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'SEAL.FORM.SYSTEMS'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(SealField.ACTIVE)"
                          header="{{'OTHER_FILLING.FORM.STATUS'|translate}}" [rowspan]="2" field="active"
                          [sortable]="false" [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="{{'SEAL.FORM.INNER'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="{{'SEAL.FORM.OUTER'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>
        <p-column *ngIf="fieldUsage.show(SealField.NAME)" field="name">
            <ng-template let-seal="rowData" pTemplate="body">
                {{seal.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="sortIndex"></p-column>
        <p-column field="shortcut">
            <ng-template let-seal="rowData" pTemplate="body">
                {{seal.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(SealField.SYMBOL)" field="symbol">
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="innerSeal">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="seal.innerSeal"
                              (ngModelChange)="editEventSeal(seal, 'innerSeal')"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="outerSeal">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="seal.outerSeal"
                              (ngModelChange)="editEventSeal(seal, 'outerSeal')"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(seal.pcv)"
                                       (ngModelChange)="materialChange(seal, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(seal.aluminium)"
                                       (ngModelChange)="materialChange(seal, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(seal.wood)"
                                       (ngModelChange)="materialChange(seal, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-seal="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(seal.aluminiumWoodMix)"
                                       (ngModelChange)="materialChange(seal, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(SealField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-seal="rowData" pTemplate="body">
                {{(seal.active ? 'SEAL.FORM.ACTIVE' : 'SEAL.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem && isPermitted({roles:['ROLE_KOORDYNATOR']})" header="{{ 'SEAL.FORM.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step label="{{ 'SEAL.FORM.ADD-TITLE' | translate }}" [validate]="validateStepData">
        <app-seal-form [editPermits]="editPermits" [validationErrors]="validationErrors" [item]="item"></app-seal-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" label="{{ 'SEAL.FORM.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [ngModel]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ (copyMode ? 'SEAL.FORM.COPY' : 'SEAL.FORM.EDIT-TITLE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'SEAL.FORM.ADD-TITLE' | translate }}" [validate]="validateStepData">
        <app-seal-form [editPermits]="editPermits" [validationErrors]="validationErrors" [item]="item"></app-seal-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" label="{{ 'SEAL.FORM.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
