import {ChangeDetectorRef, Injectable, Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api/menuitem";
import {SelectItem} from 'primeng/api/selectitem';
import {TranslatedMenuItem} from './translated-menu-item';
import {TranslatedSelectItem} from "./translated.select.item";

interface BaseTranslatedItem {
    labelKey?: string;
    label?: string;
}

@Injectable()
@Pipe({
    name: 'selectItemTranslate',
    pure: false
})
export class SelectItemTranslatePipe implements PipeTransform {
    items: BaseTranslatedItem[];

    constructor(private translate: TranslateService, private changeDetectorRef: ChangeDetectorRef) {
    }

    transform(items: TranslatedSelectItem[], ...args: any[]): TranslatedSelectItem[];
    transform(items: TranslatedMenuItem[], ...args: any[]): TranslatedMenuItem[];
    transform(items: SelectItem[], ...args: any[]): SelectItem[];
    transform(items: MenuItem[], ...args: any[]): MenuItem[];
    transform(items: BaseTranslatedItem[], ...args: any[]): BaseTranslatedItem[] {
        if (items == undefined || items.length === 0) {
            return items;
        }
        this.items = items;
        const translationKeys = this.items.filter(item => 'labelKey' in item)
            .map(item => item.labelKey);
        this.translate.get(translationKeys)
            .subscribe(labels => {
                Object.keys(labels).forEach(
                    key => this.items.find((item: any) => item['labelKey'] === key).label = (labels[key] == undefined ? key : labels[key]));
                this.changeDetectorRef.markForCheck();
            });
        return this.items;
    }
}
