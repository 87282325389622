<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(DecorativeFillingField.NAME)"
                            inputId="name" label="{{ 'DECORATIVE_FILLING.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(DecorativeFillingField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.names"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(DecorativeFillingField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'DECORATIVE_FILLING.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(DecorativeFillingField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(DecorativeFillingField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'DECORATIVE_FILLING.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(DecorativeFillingField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true">
        </app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.THICKNESS)">
        <app-input-number inputId="thickness" label="{{ 'DECORATIVE_FILLING.FORM.THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.THICKNESS)"
                          [(validationMessageKey)]="validationErrors['thickness']" [min]="1"
                          [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE" [maxlength]="100"
                          [(ngModel)]="item.thickness" [required]="true">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.UNIT_WEIGHT)">
        <app-input-number inputId="unitWeight" label="{{ 'DECORATIVE_FILLING.FORM.UNIT_WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.UNIT_WEIGHT)"
                          [(validationMessageKey)]="validationErrors['unitWeight']" [min]="0" [max]="9999"
                          [required]="true" [(ngModel)]="item.unitWeight"></app-input-number>
    </div>
    <div class="new-form-row"*ngIf="fieldUsage.show(DecorativeFillingField.GLAZING_WIDTHS)">
        <app-input-text inputId="glazingWidths"
                        label="{{ 'DECORATIVE_FILLING.FORM.GLAZING_WIDTHS' | translate }}"
                        [disabled]="fieldUsage.disabled(DecorativeFillingField.GLAZING_WIDTHS)"
                        [(validationMessageKey)]="validationErrors['glazingWidths']" [maxlength]="100"
                        [(ngModel)]="item.glazingWidths" [required]="true">
        </app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.GLAZING_SURFACE)">
        <app-input-number inputId="glazingSurface"
                          label="{{ 'DECORATIVE_FILLING.FORM.GLAZING_SURFACE' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.GLAZING_SURFACE)"
                          [(validationMessageKey)]="validationErrors['glazingSurface']" [maxlength]="100"
                          [min]="INPUT_NUMBER_DEFAULT_MIN_VALUE" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.glazingSurface">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.MINIMAL_WIDTH)">
        <app-input-number inputId="minimalWidth"
                          label="{{ 'DECORATIVE_FILLING.FORM.MINIMAL_WIDTH' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.MINIMAL_WIDTH)"
                          [(validationMessageKey)]="validationErrors['minimalWidth']" [maxlength]="5"
                          [min]="INPUT_NUMBER_DEFAULT_MIN_VALUE" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.minimalWidth" [required]="true">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.MAXIMUM_WIDTH)">
        <app-input-number inputId="maximumWidth"
                          label="{{ 'DECORATIVE_FILLING.FORM.MAXIMUM_WIDTH' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.MAXIMUM_WIDTH)"
                          [(validationMessageKey)]="validationErrors['maximumWidth']" [maxlength]="5"
                          [min]="INPUT_NUMBER_DEFAULT_MIN_VALUE" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.maximumWidth" [required]="true">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.MINIMAL_HEIGHT)">
        <app-input-number inputId="minimalHeight"
                          label="{{ 'DECORATIVE_FILLING.FORM.MINIMAL_HEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.MINIMAL_HEIGHT)"
                          [(validationMessageKey)]="validationErrors['minimalHeight']" [maxlength]="5"
                          [min]="INPUT_NUMBER_DEFAULT_MIN_VALUE" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.minimalHeight" [required]="true">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.MAXIMUM_HEIGHT)">
        <app-input-number inputId="maximumHeight"
                          label="{{ 'DECORATIVE_FILLING.FORM.MAXIMUM_HEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.MAXIMUM_HEIGHT)"
                          [(validationMessageKey)]="validationErrors['maximumHeight']" [maxlength]="5"
                          [min]="INPUT_NUMBER_DEFAULT_MIN_VALUE" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.maximumHeight" [required]="true">
        </app-input-number>
    </div>

    <!-- KOLOR RDZENIA -->
    <div class="new-form-row" *ngIf="fieldUsage.show(DecorativeFillingField.CORE_COLORS)">
        <app-select inputId="coreColor" label="{{ 'DECORATIVE_FILLING.FORM.CORE_COLORS' | translate }}"
                    [disabled]="fieldUsage.disabled(DecorativeFillingField.CORE_COLORS)"
                    [options]="availableCoreColors" [(ngModel)]="item.coreColor"
                    [(validationMessageKey)]="validationErrors['coreColor']" [required]="true"></app-select>
    </div>

    <!-- SORT INDEX -->
    <div *ngIf="fieldUsage.show(DecorativeFillingField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'DECORATIVE_FILLING.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(DecorativeFillingField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(DecorativeFillingField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'DECORATIVE_FILLING.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(DecorativeFillingField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>
    <!--Decorative filling type-->
    <ng-container *ngIf="fieldUsage.show(DecorativeFillingField.TYPE_IMAGE)">
        <div class="horizontal-block" *ngFor="let type of availableTypes">
            <label class="types-radio">
                <input [disabled]="fieldUsage.disabled(DecorativeFillingField.TYPE_IMAGE)"
                       type="radio" name="fillingType" value={{type}} [(ngModel)]="item.type"/>
                <img class="image" src="{{getTypeIconUrl(type)}}"/>
            </label>
        </div>
    </ng-container>

    <div class="new-form-row">
        <p-accordion>
            <app-colors-selection *ngIf="fieldUsage.show(DecorativeFillingField.COLOR_SELECTION)"
                                  [coreColorsDisabled]="fieldUsage.disabled(DecorativeFillingField.IMAGE_SECTION)"
                                  [insideColorsDisabled]="fieldUsage.disabled(DecorativeFillingField.IMAGE_SECTION)"
                                  [outsideColorsDisabled]="fieldUsage.disabled(DecorativeFillingField.IMAGE_SECTION)"
                                  [showCoreColors]="false"
                                  [insideColorsHeader]="'DECORATIVE_FILLING.FORM.INSIDE_COLORS'"
                                  [insideColorsModel]="item.insideColors"
                                  (onInsideColorChange)="item.insideColors = $event"
                                  [insideColorsOptions]="availableInsideColors"
                                  [outsideColorsHeader]="'DECORATIVE_FILLING.FORM.OUTSIDE_COLORS'"
                                  [outsideColorsModel]="item.outsideColors"
                                  (onOutsideColorChange)="item.outsideColors = $event"
                                  [outsideColorsOptions]="availableOutsideColors"></app-colors-selection>

            <p-accordionTab *ngIf="fieldUsage.show(DecorativeFillingField.IMAGE_SECTION)"
                            header="{{ 'DECORATIVE_FILLING.FORM.IMAGE_SECTION' | translate }}"
                            [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(DecorativeFillingField.IMAGE_SECTION)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'DECORATIVE_FILLING.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'DECORATIVE_FILLING.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'DECORATIVE_FILLING.FORM.DELETE_FILE_UPLOAD'"
                                 (ngModelChange)="onFileChange($event)"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>

            </p-accordionTab>
        </p-accordion>
    </div>
</div>
