<div class="new-form-600 subsystem-selection-form">
    <div class="subsystem-selection-filter">
        <span class="material-icons">search</span>
        <input #filterInput type="text" class="p-inputtext p-component" id="subsystem-selection-search"
               (input)="list.onFilter($event)" placeholder="{{ 'GENERAL.SEARCH' | translate }}">
    </div>
    <div class="new-form-field">
        <div class="new-form-field-input-container">
            <p-listbox #list [ngModel]="selectedSubsystem" (ngModelChange)="handleSubsystemSelectionChange($event)"
                       [options]="subsystems" styleClass="subsystem-selection-list"
                       [filterValue]="filterInput.value"
                       (onDblClick)="handleDblClick($event)">
                <ng-template pTemplate="item" let-subsystem>
                    <div class="subsystem-selection-column">
                        {{ subsystem.label }}
                    </div>
                </ng-template>
            </p-listbox>
            <div class="new-form-field-error-message" *ngIf="validationErrors['subsystemId'] != undefined">
                {{ validationErrors['subsystemId'] | translate }}
            </div>
        </div>
    </div>
</div>
