import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class ConfigSystemUpsellingSettings implements CrudItem {

    id: number;
    configSystemId: number;
    configSystemName: MultilanguageField;
    sortIndex = 1;
    active: boolean;
    name: string;

    static fromJSON(jsonObject: any): ConfigSystemUpsellingSettings {
        const upsellingSettings = new ConfigSystemUpsellingSettings();
        upsellingSettings.id = jsonObject.id;
        upsellingSettings.configSystemId = jsonObject.configSystemId;
        upsellingSettings.configSystemName = jsonObject.configSystemName;
        upsellingSettings.sortIndex = jsonObject.sortIndex;
        upsellingSettings.active = jsonObject.active;
        upsellingSettings.name = jsonObject.name;
        return upsellingSettings;
    }
}
