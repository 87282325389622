<p-accordion>
    <ng-container *ngFor="let packageForAreaRange of packagesForAreasToShow">
        <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]}">
            <p-header>
                <span [innerHTML]="'GLAZING_PACKAGES_FOR_AREA_RANGE.FORM.RANGE' | translate: {bottomAreaLimit: (packageForAreaRange.bottomAreaLimit || 0).toFixed(2), topAreaLimit: (packageForAreaRange.topAreaLimit || 0).toFixed(2) }"></span>
            </p-header>
            <div class="new-form-row">
                <app-input-number inputId="bottomAreaLimit" label="{{ 'GLAZING_PACKAGES_FOR_AREA_RANGE.FORM.BOTTOM_AREA_LIMIT' | translate }}"
                                  [disabled]="readOnlyMode"
                                  [(validationMessageKey)]="validationErrors?.glazingPackageForAreaRanges && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId] && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]['bottomAreaLimit']" [min]="0" [max]="1000"
                                  [required]="true" [(ngModel)]="packageForAreaRange.bottomAreaLimit"></app-input-number>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="topAreaLimit" label="{{ 'GLAZING_PACKAGES_FOR_AREA_RANGE.FORM.TOP_AREA_LIMIT' | translate }}"
                                  [disabled]="readOnlyMode"
                                  [(validationMessageKey)]="validationErrors?.glazingPackageForAreaRanges && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId] && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]['topAreaLimit']" [min]="0" [max]="1000"
                                  [required]="true" [(ngModel)]="packageForAreaRange.topAreaLimit"></app-input-number>
            </div>
            <app-glass-selection [glasses]="glasses" [frames]="frames" [readOnlyMode]="readOnlyMode"
                                 [commonData]="packageForAreaRange.glazing" [glazingGlassNumber]="[glassCount]"
                                 [glazingWidths]="glazingWidths" [catalogAdd]="addMode"></app-glass-selection>
            <div *ngIf="validationErrors?.glazingPackageForAreaRanges && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId] && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]['glazing']" class="new-form-row">
                <div class="new-form-field">
                    <div></div>
                    <div>
                        <div class="new-form-field-error-message">
                            {{ validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]['glazing'] | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="new-form-row">
                <app-select inputId="glazingBead" label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD' | translate }}"
                            [disabled]="readOnlyMode"
                            [(validationMessageKey)]="validationErrors?.glazingPackageForAreaRanges && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId] && validationErrors?.glazingPackageForAreaRanges[packageForAreaRange.tempId]['glazingBeadId']"
                            [options]="getAvailableGlazingBeads(packageForAreaRange)" [ngModel]="packageForAreaRange.glazingBeadId"
                            (ngModelChange)="updateGlazingBead($event, packageForAreaRange)"></app-select>
            </div>
            <div *ngIf="!readOnlyMode" class="new-form-row">
                <app-simple-button buttonId="removeButton"
                                   label="{{ 'GLAZING_PACKAGES_FOR_AREA_RANGE.FORM.REMOVE' | translate }}"
                                   type="delete" [size]="40"
                                   (onClick)="remove(packageForAreaRange)"></app-simple-button>
            </div>
        </p-accordionTab>
    </ng-container>
</p-accordion>
<div *ngIf="!readOnlyMode" class="new-form-row">
    <app-simple-button buttonId="addRangeButton_{{glassCount}}"
                       label="{{ 'GLAZING_PACKAGES_FOR_AREA_RANGE.FORM.ADD' | translate }}"
                       type="main-action" [size]="40"
                       (onClick)="add()"></app-simple-button>
</div>
