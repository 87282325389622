<div id="designer-container">
    <app-drawing-tool-controls [offer]="offer"
                               [headerSuffix]="headerSuffix"
                               [productionOrder]="productionOrder"
                               [readOnlyMode]="readOnlyMode"
                               [mode]="DrawingToolControlsMode.ROOF"
                               (onExit)="exit()"
                               (onSaveAndExit)="saveAndExit()">
    </app-drawing-tool-controls>

    <app-exit-without-saving-confirmation-dialog [visible]="showExitWithoutSavingConfirmationDialog"
                                                 (hideDialog)="showExitWithoutSavingConfirmationDialog = false"
                                                 (exitWithoutSaving)="exitWithoutSaving()"></app-exit-without-saving-confirmation-dialog>

    <ng-container *ngIf="isRoof && showAddDialog && windowSystems != undefined">
        <app-add-roof-window-dialog [displayDialog]="isRoof && showAddDialog"
                                [items]="windowSystems" [catalogConfiguration]="catalogConfiguration"
                                [largeImageGetter]="largeImageGetter()"
                                [data]="getRoofData()" (dialogClosed)="systemSelected($event)"></app-add-roof-window-dialog>
    </ng-container>

    <ng-container *ngIf="isEntrance && showAddDialog && windowSystems != undefined">
        <app-add-entrance-window-dialog [displayDialog]="isEntrance && showAddDialog"
                                    [windowSystems]="windowSystems" [items]="entranceModels"
                                    [catalogConfiguration]="catalogConfiguration"
                                    [data]="getEntranceData()" (dialogClosed)="entranceSystemSelected($event)"></app-add-entrance-window-dialog>
    </ng-container>

    <app-window-component-preview-dialog
            [(previewData)]="windowComponentPreviewData"></app-window-component-preview-dialog>

    <ng-container *vLet="isEntranceData(data) ? getEntranceData() : null as entranceData">
        <div class="preview-container">
            <span *ngIf="isEntranceData(data)" class="view-label">{{ 'WINDOW_PREVIEW.VIEW_FROM_OUTSIDE_LABEL' | translate }}</span>
            <img *ngIf="systemPreviewImage" id="systemPreviewImage" [src]="systemPreviewImage" [ngClass]="{'flipped': entranceData?.side === DoorSide.LEFT}">
        </div>

        <div *ngIf="data" id="details-panel" [ngClass]="{'hide-sidebar': !showSidebar }">
        <div id="details" [hidden]="!showSidebar">
            <p-tabView id="details-tabs" (onChange)="tabChanged($event)" #tabView>

                <p-tabPanel header="{{ 'OFFER.TABS.GENERAL' | translate}}" class="sidebar-entries"
                            [headerStyleClass]="getTabpanelHeaderStyle(generalStatus)">

                    <div class="new-form-row">
                        <div class="input-with-action-container">
                            <div class="new-form-field">
                                <label>{{ 'OFFER.POSITIONS.FORM.SYSTEM' | translate }}</label>
                                {{ selectedSystem?.names[translate.currentLang] }}
                            </div>
                            <div *ngIf="validationErrors['systemId'] != undefined" class="new-form-field-error-message">
                                {{ validationErrors['systemId'] | translate }}
                            </div>
                        </div>
                        <div class="new-form-field flex-row">
                            <span class="spacer"></span>
                            <app-simple-button buttonId="changeSystem" [disabled]="readOnlyMode"
                                               label="{{ 'OFFER.TABS.SECTION.MAIN.BUSINESS_TYPE_CHANGE' | translate }}"
                                               [inlineLabel]="false" icon="swap_horiz" type="filter" align="right"
                                               (onClick)="openAddDialog()"></app-simple-button>
                        </div>
                    </div>

                    <div *ngIf="fieldUsage.show(Field.MODEL)" class="new-form-row">
                        <div class="input-with-action-container">
                            <div class="new-form-field">
                                <label>{{ 'OFFER.POSITIONS.FORM.ENTRANCE_MODEL' | translate }}</label>
                                {{ selectedModel?.name[translate.currentLang] }}
                            </div>
                        </div>
                        <div *ngIf="fieldUsage.show(Field.MODEL)" class="new-form-field flex-row input-action-button-container">
                            <app-simple-button buttonId="flipped" [disabled]="readOnlyMode"
                                               label="{{ 'OFFER.TABS.SECTION.ENTRANCE.FLIP' | translate }}"
                                               [inlineLabel]="false" icon="compare_arrows" type="filter" [size]="40" align="right"
                                               (onClick)="flip(entranceData)"></app-simple-button>
                        </div>
                    </div>

                    <div class="new-form-row">
                        <app-input-number inputId="quantity" label="{{ 'OFFER.TABS.SECTION.MAIN.QUANTITY' | translate }}"
                                          (onBlur)="updateOfferPositionQuantity()"
                                          [min]="1" [max]="1000000"
                                          [disabled]="readOnlyMode"
                                          [ngModel]="selectedQuantity"
                                          (ngModelChange)="offerPosition.quantity = $event"></app-input-number>
                    </div>

                    <div *ngIf="dimensions != undefined" class="new-form-row">
                        <app-select inputId="dimensionsId"
                                    label="{{ 'WINDOW_DIMENSIONS.SHORT_TITLE' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.DIMENSIONS)"
                                    [disabled]="readOnlyMode"
                                    [ngModel]="selectedDimension?.id"
                                    [options]="dimensions"
                                    [optionFormatter]="selectItemFormatters.windowDimensionsFormatter"
                                    (ngModelChange)="setDimensions($event)"></app-select>
                    </div>
                    <div class="new-form-row dimension-inputs">
                        <app-input-number label="{{ 'WINDOW_DIMENSIONS.FORM.WIDTH' | translate }}"
                                          [disabled]="!isDimensionOverride"
                                          [hasValidationError]="isDimensionOverride && fieldUsage.required(Field.WIDTH)"
                                          [validationMessageKey]="validationErrors['width']"
                                          [ngModel]="selectedDimension?.width" [min]="selectedSystem?.dimensionStep"
                                          [step]="selectedSystem?.dimensionStep" [shouldRoundToStep]="true"
                                          (ngModelChange)="setWidth($event)"></app-input-number>
                        <app-input-number label="{{ 'WINDOW_DIMENSIONS.FORM.HEIGHT' | translate }}"
                                          [disabled]="!isDimensionOverride"
                                          [hasValidationError]="isDimensionOverride && fieldUsage.required(Field.HEIGHT)"
                                          [validationMessageKey]="validationErrors['height']"
                                          [ngModel]="selectedDimension?.height" [min]="selectedSystem?.dimensionStep"
                                          [step]="selectedSystem?.dimensionStep" [shouldRoundToStep]="true"
                                          (ngModelChange)="setHeight($event)"></app-input-number>
                    </div>

                    <div *ngIf="isRoof" class="new-form-row">
                        <app-input-text label="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES' | translate }}"
                                        [disabled]="true"
                                        [ngModel]="selectedGlazingPackage?.glasses[translate.currentLang]"
                                        [validationMessageKey]="validationErrors['glazingPackageId']"></app-input-text>
                    </div>
                    <div *ngIf="fieldUsage.show(Field.GLASS)" class="new-form-row">
                        <app-select inputId="glazingPackageId" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.GLASS) ||
                                            (data.glazingPackageId | isNotAvailable:(glazingPackages
                                                | mapToSelectItems:selectItemFormatters.entranceGlazingPackageFormatter):true)"
                                    [noLongerAvailable]="data.glazingPackageId | isNotAvailable:(glazingPackages
                                        | mapToSelectItems:selectItemFormatters.entranceGlazingPackageFormatter):true"
                                    [checkAvailability]="true"
                                    [disabled]="readOnlyMode"
                                    [options]="glazingPackages"
                                    [optionFormatter]="selectItemFormatters.entranceGlazingPackageFormatter"
                                    [(ngModel)]="data.glazingPackageId"
                                    (ngModelChange)="removeFieldFromUnavailable(Field.GLASS)">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [disabled]="data.glazingPackageId == undefined || data.glazingPackageId === 0"
                                                     (onShowImage)="handleShowImage(imageService.getEntranceGlazingPackageImage(data.glazingPackageId), label)"></app-sidebar-field-label>
                        </ng-template>
                        </app-select>
                    </div>

                    <div *ngIf="fieldUsage.show(Field.PROFILE)" class="new-form-row">
                        <app-select inputId="frameProfileId" label="{{ 'OFFER.TABS.SECTION.MAIN.PROFILE' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.PROFILE) ||
                                            (entranceData.frameProfileId | isNotAvailable:(profiles
                                                | mapToSelectItems:selectItemFormatters.profileOptionFormatter):true)"
                                    [noLongerAvailable]="entranceData.frameProfileId | isNotAvailable:(profiles
                                        | mapToSelectItems:selectItemFormatters.profileOptionFormatter):true"
                                    [checkAvailability]="true"
                                    [options]="profiles" [disabled]="readOnlyMode"
                                    [optionFormatter]="selectItemFormatters.profileOptionFormatter"
                                    [(ngModel)]="entranceData.frameProfileId"
                                    (ngModelChange)="removeFieldFromUnavailable(Field.PROFILE)">
                            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         [disabled]="entranceData.frameProfileId == undefined || entranceData.frameProfileId === 0"
                                                         (onShowImage)="handleShowImage(imageService.getProfileImage(entranceData.frameProfileId), label)"></app-sidebar-field-label>
                            </ng-template>
                        </app-select>
                    </div>

                    <div class="new-form-row" *ngIf="fieldUsage.show(Field.OPENING)">
                        <app-select inputId="openingSelect" label="{{ 'OFFER.OPENING.SECTION_TITLE' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.OPENING)"
                                    [options]="openings"
                                    [optionFormatter]="selectItemFormatters.doorOpeningFormatter"
                                    [translateLabels]="true" [disabled]="readOnlyMode"
                                    [ngModel]="entranceData.opening" (ngModelChange)="setOpening($event)"
                        ></app-select>
                    </div>
                    <div *ngIf="fieldUsage.show(Field.EXTERNAL_COLOR)" class="new-form-row">
                        <div class="input-with-action-container">
                            <app-select inputId="externalColorId" label="{{ 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR' | translate }}"
                                        [hasValidationError]="fieldUsage.required(Field.EXTERNAL_COLOR) ||
                                            (entranceData.externalColorId | isNotAvailable:externalColors:true)"
                                        [noLongerAvailable]="entranceData.externalColorId | isNotAvailable:externalColors:true"
                                        [checkAvailability]="true"
                                        [options]="externalColors" [disabled]="readOnlyMode"
                                        [translateLabels]="true" [allowSelectingNone]="externalColorNoneSelectable"
                                        (ngModelChange)="handleColorSelected($event, Field.EXTERNAL_COLOR)"
                                        [ngModel]="entranceData.externalColorId">
                                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                             [disabled]="entranceData.externalColorId == undefined || entranceData.externalColorId === 0"
                                                             (onShowImage)="handleShowImage(imageService.getColorImage(entranceData.externalColorId), label)"></app-sidebar-field-label>
                                </ng-template>
                            </app-select>
                        </div>
                        <div *ngIf="externalColors" class="new-form-field flex-row input-action-button-container">
                            <app-simple-button buttonId="applyExternalToInternalColorButton"
                                               label="{{ 'OFFER.TABS.SECTION.MAIN.APPPLY_EXTERNAL_TO_INTERNAL_COLOR' | translate }}"
                                               [inlineLabel]="false" icon="arrow_downward" type="filter" align="right" [disabled]="readOnlyMode"
                                               (onClick)="applyExternalToInternalColor()"></app-simple-button>
                        </div>
                    </div>
                    <div *ngIf="fieldUsage.show(Field.INTERNAL_COLOR)" class="new-form-row">
                        <app-select inputId="internalColorId" label="{{ 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.INTERNAL_COLOR) ||
                                        (entranceData.internalColorId | isNotAvailable:internalColors:true)"
                                    [noLongerAvailable]="entranceData.internalColorId | isNotAvailable:internalColors:true"
                                    [checkAvailability]="true"
                                    [options]="internalColors" [disabled]="readOnlyMode"
                                    [translateLabels]="true" [allowSelectingNone]="internalColorNoneSelectable"
                                    (ngModelChange)="handleColorSelected($event, Field.INTERNAL_COLOR)"
                                    [ngModel]="entranceData.internalColorId">
                            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         [disabled]="entranceData.internalColorId == undefined || entranceData.internalColorId === 0"
                                                         (onShowImage)="handleShowImage(imageService.getColorImage(entranceData.internalColorId), label)"></app-sidebar-field-label>
                            </ng-template>
                        </app-select>
                    </div>

                    <div *ngIf="fieldUsage.show(Field.ENTRANCE_DOOR_FITTING)" class="new-form-row">
                        <app-select-or-checkbox inputId="entranceLock" label="{{ 'OFFER.TABS.SECTION.ENTRANCE_LOCK' | translate }}"
                                    [hasValidationError]="fieldUsage.required(Field.ENTRANCE_DOOR_FITTING) ||
                                        (entranceData.entranceLock.addonId | isNotAvailable:(entranceLocks
                                            | mapToSelectItems:selectItemFormatters.addonOptionFormatter))"
                                    [noLongerAvailable]="entranceData.entranceLock.addonId | isNotAvailable:(entranceLocks
                                        | mapToSelectItems:selectItemFormatters.addonOptionFormatter)"
                                    [checkAvailability]="true"
                                    [disabled]="readOnlyMode"
                                    [options]="entranceLocks"
                                    [optionFormatter]="selectItemFormatters.addonOptionFormatter"
                                    (valueChange)="setField($event, Field.ENTRANCE_DOOR_FITTING)"
                                    [value]="entranceData.entranceLock.addonId">
                            <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                         [disabled]="disabled"></app-sidebar-field-label>
                            </ng-template>
                        </app-select-or-checkbox>
                    </div>

                    <div class="new-form-row">
                        <app-select-or-checkbox *ngIf="fieldUsage.show(Field.FLASHING)" inputId="flashingAKPSelect"
                                    label="{{ 'OFFER.TABS.SECTION.ROOF.FLASHING' | translate }}"
                                    [value]="data['flashing']?.addonId" (valueChange)="setField($event, Field.FLASHING)"
                                    [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                                    [options]="availableFlashings" [disabled]="readOnlyMode" [required]="false"
                                    [optionFormatter]="selectItemFormatters.addonOptionFormatter"
                                    [hasValidationError]="data['flashing']?.addonId | isNotAvailable:(availableFlashings
                                        | mapToSelectItems:selectItemFormatters.addonOptionFormatter):true"
                                    [noLongerAvailable]="data['flashing']?.addonId | isNotAvailable:(availableFlashings
                                        | mapToSelectItems:selectItemFormatters.addonOptionFormatter):true"
                                    [checkAvailability]="true">
                            <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                         [disabled]="disabled"></app-sidebar-field-label>
                            </ng-template>
                        </app-select-or-checkbox>
                    </div>

                    <div class="new-form-row">
                        <app-select-or-checkbox *ngIf="fieldUsage.show(Field.FLASHING_AKP)" inputId="flashingAKPSelect"
                                    label="{{ 'OFFER.TABS.SECTION.ROOF.FLASHING_AKP' | translate }}"
                                    [value]="data['flashingAkp']?.addonId" (valueChange)="setField($event, Field.FLASHING_AKP)"
                                    [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                                    [options]="availableAKPFlashings" [disabled]="readOnlyMode" [required]="false"
                                    [optionFormatter]="selectItemFormatters.addonOptionFormatter"
                                    [hasValidationError]="data['flashingAkp']?.addonId | isNotAvailable:(availableAKPFlashings
                                        | mapToSelectItems:selectItemFormatters.addonOptionFormatter):true"
                                    [noLongerAvailable]="data['flashingAkp']?.addonId | isNotAvailable:(availableAKPFlashings
                                        | mapToSelectItems:selectItemFormatters.addonOptionFormatter):true"
                                    [checkAvailability]="true">
                            <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                         [disabled]="disabled"></app-sidebar-field-label>
                            </ng-template>
                        </app-select-or-checkbox>
                    </div>

                    <div class="addons-button-container">
                        <p-accordion>
                            <p-accordionTab header="{{ 'OFFER.TABS.SECTION.ADDONS.SECTION_TITLE' | translate }}"
                                            *ngIf="fieldUsage.showSection(SidebarSection.ADDONS)"
                                            id="addonsAccordion" name='addonsAccordion' class="p-accordion-button"
                                            (click)="showAddonsTabView()">
                            </p-accordionTab>

                            <p-accordionTab header="{{ 'OFFER.TABS.SECTION.DESCRIPTION.SECTION_TITLE' | translate }}"
                                            id="descriptionAccordion" name='descriptionAccordion'
                                            class="p-accordion-button" (click)="openDescriptionDialog()">
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="{{'OFFER.TABS.PRICING.TITLE' | translate}}"
                            [headerStyleClass]="getTabpanelHeaderStyle(pricingStatus)">
                    <app-pricing #pricing
                                 [offer]="offer"
                                 [productionOrder]="productionOrder"
                                 [readOnlyMode]="readOnlyMode"
                                 [windowSystemTypeGroup]="windowSystemTypeGroup"
                                 (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, false)"
                                 (setErrorToPricingStatus)="pricingStatus.errors = true"></app-pricing>
                </p-tabPanel>

                <p-tabPanel header="{{'OFFER.TABS.VALIDATION.TITLE' | translate}}"
                            [headerStyleClass]="getTabpanelHeaderStyle(validationStatus)">
                    <app-sidebar-validation #validation
                                            [validationMessages]="pricing.pricing?.validationMessages || []"
                                            [productionOrder]="productionOrder"
                                            [readOnlyMode]="readOnlyMode"
                                            (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, true)"
                                            (setErrorToValidationStatus)="validationStatus.errors = true">
                    </app-sidebar-validation>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="spacer" *ngIf="!showSidebar"></div>
        <app-sidebar-hider [showSidebar]="showSidebar"
                           (onClick)="showSidebar = !showSidebar"></app-sidebar-hider>
    </div>
    </ng-container>
    <app-addons-tab-view *ngIf="isAddonsTabViewVisible" [systemId]="data.systemId" [windowAddonData]="data"
                         [roofWindowsDimension]="selectedDimension"
                         (onSubmit)="windowAddonsSave($event)" (onClose)="closeAddonsTabView()"
                         (onRemove)="windowAddonsRemove($event)"
                         [addedWindowAddons]="addedWindowAddons"
                         [offerId]="offer.id"
                         [offerPositionId]="offerPosition?.id"
                         [readOnlyMode]="readOnlyMode">
    </app-addons-tab-view>

    <!--Exit with messages confirmation dialog-->
    <p-dialog [(visible)]="showExitWithMessagesDialog" [closable]="false"
              [focusOnShow]="false" [modal]="true" [resizable]="false"
              header="{{'OFFER.MENU.EXIT_WITH_MESSAGES' | translate}}" id="exit-with-warnings-confirmation-dialog">

        <div class="new-form-600">
            <app-sidebar-validation [pricingProducts]="recentPricingProducts"
                                    [validationMessages]="recentValidationMessages">
            </app-sidebar-validation>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button (onClick)="confirmExitWithMessages()" [size]="40" label="{{ 'GENERAL.YES' | translate }}"
                                   type="main-action"></app-simple-button>
                <app-simple-button (onClick)="closeExitWithMessagesDialog()" [size]="40" label="{{ 'GENERAL.NO' | translate }}"
                                   type="cancel"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>

<app-show-description *ngIf="showDescriptionDialog" [visible]="true"
                      [position]="offerPosition" [readOnlyMode]="readOnlyMode"
                      [persistOnSave]="false"
                      (onClose)="showDescriptionDialog = false;"></app-show-description>

<app-ral-color-selection-dialog *ngIf="selectingRalColors != undefined" [header]="selectingRalColorHeader | translate"
                                [colors]="selectingRalColors" (onColorSelect)="handleRalColorSelected($event)"
                                (onCancel)="handleRalColorSelectionCancel()"></app-ral-color-selection-dialog>

<app-missing-profit-margin-handler></app-missing-profit-margin-handler>
