import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'app-upselling-float-button',
    templateUrl: './upselling-float-button.component.html',
    styleUrls: ['./upselling-float-button.component.css', '../../../../../../../common/float-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpsellingFloatButtonComponent {

    @Output()
    onClick: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    visible: boolean;

    @Input()
    count: number;

    @Input()
    bottomPosition = 132;

    constructor() {
    }

    click(): void {
        this.onClick.emit();
    }
}
