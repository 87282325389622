import {ErrorNames} from "../utils/ErrorNames";
import {Guide} from "./Guide";

export class GuideHelper {
    public static end(guide: Guide): number {
        return guide.position + guide.size;
    }

    public static middle(guide: Guide): number {
        return guide.position + (guide.size / 2);
    }

    public static resize(guide: Guide, change: number) {
        if (guide.size + change < 0) {
            let err = new Error("GuidesHelper.resize: Niedozwolona operacja - proba ustawienia ujemnego rozmiaru");
            err.name = ErrorNames.RESIZE_FAILED;
            throw err;
        }
        guide.size += change;
    }
}
