<ng-container *ngIf="history && history.length > 0; else emptyHistory">
    <div class="datatable-container">
        <p-table #dt [value]="history" [attr.id]="'PRINT_HISTORY_TABLE'"
                 [paginator]="false" [metaKeySelection]="true" [lazy]="false"
                 selectionMode="single" dataKey="id" [responsive]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        {{ 'OFFER.FORM.ACTIONS' | translate }}
                    </th>
                    <th>
                        <span>{{ 'PRINT_HISTORY.PRINT_TIMESTAMP' | translate }}</span>
                    </th>
                    <th>
                        <span>{{ 'PRINT_HISTORY.USER_NAME' | translate }}</span>
                    </th>
                    <th>
                            <span>{{ 'PRINT_HISTORY.EXCHANGE_RATE' | translate }}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entry let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" [pSelectableRow]="entry" [pSelectableRowIndex]="rowIndex" class="datatable-data">
                    <td>
                        <app-simple-button label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.ACTIONS.PRINT' | translate }}"
                                           type="action"
                                           (onClick)="print(entry)"
                                           [icon]="'print'"
                                           [inlineLabel]="false"></app-simple-button>
                    </td>
                    <td>
                        {{ entry.printTimestamp | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td>
                        {{ entry.userName + (entry.impersonatorUserName ? ' (' + entry.impersonatorUserName + ')' : '') }}
                    </td>
                    <td>
                        {{ entry.exchangeRate }} {{entry.currency}}/PLN
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</ng-container>
<ng-template #emptyHistory>
    <div [ngStyle]="{'padding.px': 20}">{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</div>
</ng-template>

