<div class="new-form-row">
    <app-select inputId="GLAZING_PACKAGE_QUANTITY_id"
                label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_PACKAGE_QUANTITY' | translate }}"
                [options]="quantityOptions"
                (beginInvalidValueCorrection)="beginInvalidValueCorrection.emit($event)"
                (endInvalidValueCorrection)="endInvalidValueCorrection.emit($event)"
                (onFocus)="onSelectFocus($event)" [required]="true"
                (ngModelChange)="quantityChanged.emit($event)"
                [ngModel]="selectedQuantity" [disabled]="readOnlyMode"
                [(validationMessageKey)]="validationErrors['quantity']"
                [hasValidationError]="quantityHasValidationErrors"
                [ngClass]="{'has-new-options': quantityNewOptions}"
                (onClick)="quantityClicked.emit($event)"></app-select>
</div>

<div class="new-form-row">
    <app-select inputId="GLAZING_PACKAGE_CATEGORY_id"
                label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_CATEGORY' | translate }}"
                [options]="categoryOptions"
                (beginInvalidValueCorrection)="beginInvalidValueCorrection.emit($event)"
                (endInvalidValueCorrection)="endInvalidValueCorrection.emit($event)"
                (onFocus)="onSelectFocus($event)" [required]="true"
                (ngModelChange)="categoryChanged.emit($event)"
                [ngModel]="glazingCategoryId" [disabled]="readOnlyMode"
                [(validationMessageKey)]="validationErrors['category']"
                [hasValidationError]="categoryHasValidationErrors ||
                                        (glazingCategoryId | isNotAvailable:categoryOptions:true)"
                [noLongerAvailable]="glazingCategoryId | isNotAvailable:categoryOptions:true"
                [checkAvailability]="true"
                [ngClass]="{'has-new-options': categoryNewOptions}"
                (onClick)="categoryClicked.emit($event)"></app-select>
</div>

<div class="new-form-row">
    <app-select inputId="GLAZING_PACKAGE_FRAME_CATEGORY_id"
                label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_FRAME_CATEGORY' | translate }}"
                [options]="frameCategoryOptions"
                (beginInvalidValueCorrection)="beginInvalidValueCorrection.emit($event)"
                (endInvalidValueCorrection)="endInvalidValueCorrection.emit($event)"
                (onFocus)="onSelectFocus($event)" [required]="true"
                (ngModelChange)="frameCategoryChanged.emit($event)"
                [ngModel]="glazingFrameCategoryId" [disabled]="readOnlyMode"
                [(validationMessageKey)]="validationErrors['frameCategory']"
                [hasValidationError]="frameCategoryHasValidationErrors ||
                                        (glazingFrameCategoryId | isNotAvailable:frameCategoryOptions:true)"
                [noLongerAvailable]="glazingFrameCategoryId | isNotAvailable:frameCategoryOptions:true"
                [checkAvailability]="true"
                [ngClass]="{'has-new-options': frameCategoryNewOptions}"
                (onClick)="frameCategoryClicked.emit($event)"></app-select>
</div>

<div class="new-form-row">
    <app-select inputId="GLAZING_PACKAGE_id"
                label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_PACKAGE' | translate }}"
                [options]="glazingPackageOptions"
                (beginInvalidValueCorrection)="beginInvalidValueCorrection.emit($event)"
                (endInvalidValueCorrection)="endInvalidValueCorrection.emit($event)"
                (onFocus)="onSelectFocus($event)" [required]="true"
                (ngModelChange)="glazingPackageChanged.emit($event)"
                [ngModel]="glazingPackageId" [disabled]="readOnlyMode"
                [(validationMessageKey)]="validationErrors['glazingPackage']"
                [hasValidationError]="glazingPackageHasValidationErrors ||
                                        (glazingPackageId | isNotAvailable:glazingPackageOptions:true)"
                [noLongerAvailable]="glazingPackageId | isNotAvailable:glazingPackageOptions:true"
                [checkAvailability]="true"
                [ngClass]="{'has-new-options': glazingPackageNewOptions}"
                (onClick)="glazingPackageClicked.emit($event)"></app-select>
</div>
