import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Renderer2, RendererStyleFlags2} from '@angular/core';
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {Unsubscribable} from 'rxjs';
import {WizardBase, WizardStepChangeEvent} from './wizard-base';
import {FULLSCREEN_CONTROLLER, WizardFullscreenController} from './wizard-fullscreen-controller';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.css']
})
export class WizardComponent extends WizardBase implements OnInit, OnDestroy {

    @Input()
    hideSidenav = true;

    private didHideSidenav = false;
    private canvas = document.createElement("canvas");
    private stepSubscription: Unsubscribable;
    protected enterHotkey: Hotkey;

    constructor(
        @Inject(FULLSCREEN_CONTROLLER) private fullscreenContollers: WizardFullscreenController[],
        private changeDetector: ChangeDetectorRef,
        private hotkeysService: HotkeysService,
        private renderer: Renderer2
    ) {
        super();
        this.enterHotkey = new Hotkey('enter', (): boolean => {
            if (document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            this.nextStep();
            this.changeDetector.markForCheck();
            return false;
        }, ['INPUT', 'SELECT']);
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.enterHotkey);
        if (this.hideSidenav) {
            this.fullscreenContollers.forEach(controller => controller.setFullscreen());
            this.didHideSidenav = true;
            this.renderer.setStyle(document.documentElement, 'overflow-y', 'hidden', RendererStyleFlags2.Important);
            this.changeDetector.markForCheck();
        }
        this.stepSubscription = this.currentStepChange.subscribe((event: WizardStepChangeEvent) => {
            if (event.isBack()) {
                let steps = this.steps.toArray();
                for (let stepIndex = event.newIndex + 1; stepIndex <= event.oldIndex; ++stepIndex) {
                    steps[stepIndex].clearValidationErrors();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.didHideSidenav) {
            this.fullscreenContollers.forEach(controller => controller.unsetFullscreen());
            this.renderer.removeStyle(document.documentElement, 'overflow-y');
            this.changeDetector.markForCheck();
        }
        this.stepSubscription.unsubscribe();
        this.hotkeysService.remove(this.enterHotkey);
    }

    isTooltipHidden(stepIndex: number): boolean {
        let el = document.getElementById("wizardStepLabel" + stepIndex);
        if (el == null) {
            return true;
        }
        let style = getComputedStyle(el);
        let stepWidth = style.getPropertyValue('--wizard-step-width');
        let fullText = el.textContent.trim();

        let context = this.canvas.getContext("2d");
        context.font = style.font;
        let metrics = context.measureText(fullText);
        return metrics.width < parseInt(stepWidth);
    }
}
