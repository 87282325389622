import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../common/crud-common/crud.service';
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {SubsystemGroup} from './subsystem-group';

@Injectable()
export class SubsystemGroupService implements CrudService<SubsystemGroup> {

    private static readonly API_URL = 'subsystemgroup';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<SubsystemGroup>> {
        const search = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(SubsystemGroupService.API_URL, {params: search}).pipe(
            map(response => Listing.fromJSON(SubsystemGroup, response)));
    }

    getItem(itemId: number): Observable<SubsystemGroup> {
        return this.http.get<object>(`${SubsystemGroupService.API_URL}/${itemId}`).pipe(
            map(response => SubsystemGroup.fromJSON(response)));
    }

    addItem(item: SubsystemGroup): Observable<number> {
        return this.http.post<void>(SubsystemGroupService.API_URL, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(SubsystemGroupService.API_URL, response)));
    }

    editItem(itemId: number, item: SubsystemGroup): Observable<number> {
        return this.http.put<void>(`${SubsystemGroupService.API_URL}/${itemId}`, item).pipe(
            map(() => itemId));
    }

    removeItem(itemId: number): Observable<void> {
        return this.http.delete<void>(`${SubsystemGroupService.API_URL}/${itemId}`);
    }

    editBulkAddonProfitMargin(itemId: number, profitMarginValue: string): Observable<void> {
        return this.http.put<void>(`${SubsystemGroupService.API_URL}/${itemId}/bulkAddonProfitMargin`, profitMarginValue, {
            headers: {'Content-Type': 'text/plain'},
        });
    }
}
