import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class Palette {

    id: number;
    name: string;
    quantity: number;
    productionOrderIds: number[];
    paletteAdditionalElementsIds: number[];
    creationDate: Date;

    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.quantity = undefined;
        this.productionOrderIds = [];
        this.creationDate = undefined;
    }

    static fromJSON(jsonObject: any): Palette {
        const palette = new Palette();
        palette.id = jsonObject.id;
        palette.name = jsonObject.name;
        palette.quantity = jsonObject.quantity;
        palette.productionOrderIds = jsonObject.productionOrderIds;
        palette.paletteAdditionalElementsIds = jsonObject.paletteAdditionalElementsIds;
        palette.creationDate = jsonObject.creationDate && new Date(jsonObject.creationDate);
        return palette;
    }
}
