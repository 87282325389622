export enum WindowEditorField {
    QUANTITY = 'QUANTITY',
    WINDOW_SYSTEM = 'WINDOW_SYSTEM',
    WIDTH = 'WIDTH',
    HEIGHT = 'HEIGHT',
    PROFILE = 'PROFILE',
    WELD_TYPE = 'WELD_TYPE',
    FRAME_ENHANCEMENT = 'FRAME_ENHANCEMENT',
    VIEW = 'VIEW',
    WINDOW_FUNCTION = 'WINDOW_FUNCTION',
    COVERS = 'COVERS',
    MILLINGS = 'MILLINGS',
    MILLINGS_NORWEGIAN = 'MILLINGS_NORWEGIAN',
    UNDER_WINDOW_BEAD = 'UNDER_WINDOW_BEAD',
    HANDLES = 'HANDLES',
    UNDERWINDOW_PROFILE = 'UNDERWINDOW_PROFILE',
    DOORSTEP = 'DOORSTEP',
    OPENING = 'OPENING',
    SEALS_INTERNAL = 'SEALS_INTERNAL',
    SEALS_EXTERNAL = 'SEALS_EXTERNAL',
    CORE_COLOR = 'CORE_COLOR',
    EXTERNAL_COLOR = 'EXTERNAL_COLOR',
    INTERNAL_COLOR = 'INTERNAL_COLOR',
    FILLING_WIDTH = 'FILLING_WIDTH',
    FILLING_NAME = 'FILLING_NAME',
    FILLING_NAME_EXTERNAL = 'FILLING_NAME_EXTERNAL',
    FILLING_NAME_INTERNAL = 'FILLING_NAME_INTERNAL',
    DECORATIVE_FILLING = 'DECORATIVE_FILLING',
    FILLING_INTERNAL_COLOR = 'FILLING_INTERNAL_COLOR',
    FILLING_EXTERNAL_COLOR = 'FILLING_EXTERNAL_COLOR',
    GLAZING_BEAD = 'GLAZING_BEAD',
    FITTING_BRAKE = 'FITTING_BRAKE',
    FITTING_SLIDING = 'FITTING_SLIDING',
    FITTING_TYPE = 'FITTING_TYPE',
    FITTING_ESPAGNOLETTE_TYPE = 'FITTING_ESPAGNOLETTE_TYPE',
    FITTING_VERANDA = 'FITTING_VERANDA',
    FITTING_INSERTION = 'FITTING_INSERTION',
    FITTING_MAIN_INSERTION = 'FITTING_MAIN_INSERTION',
    FITTING_ADDITIONAL_INSERTION = 'FITTING_ADDITIONAL_INSERTION',
    FITTING_LOCK = 'FITTING_LOCK',
    FITTING_LOCK_TERRACE = 'FITTING_LOCK_TERRACE',
    FITTING_LOCK_TERRACE_LOCATION = 'FITTING_LOCK_TERRACE_LOCATION',
    SHAPE_TYPE = 'SHAPE_TYPE',
    SHAPE_TYPE_NOT_RECTANGULAR = 'SHAPE_TYPE_NOT_RECTANGULAR',
    SHAPE_TYPE_ARC = 'SHAPE_TYPE_ARC',
    FILLING_TYPE = 'FILLING_TYPE',
    TERRACE_GLAZING_PACKAGE = 'TERRACE_GLAZING_PACKAGE',
    TERRACE_COLOR = 'TERRACE_COLOR',
    FILLING_TYPE_W_MUNTINS = 'FILLING_TYPE_W_MUNTINS',
    FILLING_TYPE_WO_MUNTINS = 'FILLING_TYPE_WO_MUNTINS',
    GLASS_SELECTOR = 'GLASS_SELECTOR',
    GLASS = 'GLASS',
    DISTANCE_FRAME = 'DISTANCE_FRAME',
    GRILL = 'GRILL',
    GRILL_ANGLED = 'GRILL_ANGLED',
    GRILL_COLOR = 'GRILL_COLOR',
    MULLION = 'MULLION',
    MULLION_ANGLED = 'MULLION_ANGLED',
    VENTILATOR = 'VENTILATOR',
    DRIP = 'DRIP',
    COUPLER = 'COUPLER',
    BUSINESS_TYPE = 'BUSINESS_TYPE',
    DIMENSIONS = 'DIMENSIONS',
    EXTERNAL_COLOR_OTHER_INFO = 'EXTERNAL_COLOR_OTHER_INFO',
    INTERNAL_COLOR_OTHER_INFO = 'INTERNAL_COLOR_OTHER_INFO',
    FILLING_TYPE_WO_DECORATIVE_FILLINGS = 'FILLING_TYPE_WO_DECORATIVE_FILLINGS',
    FITTING_AUTOMATIC_DRIVE = 'FITTING_AUTOMATIC_DRIVE',
    FLASHING = 'FLASHING',
    ENTRANCE_DOOR_FITTING = 'ENTRANCE_DOOR_FITTING',
    FLASHING_AKP = 'FLASHING_AKP',
    DECORATIVE_FILLING_FLIP = 'DECORATIVE_FILLING_FLIP',
    WEBSHOP_GLAZING_PACKAGE = 'WEBSHOP_GLAZING_PACKAGE',
    WEBSHOP_CHARGE_NAME = 'WEBSHOP_CHARGE_NAME',
    WIDTH_GREATER_THAN = 'WIDTH_GREATER_THAN',
    WIDTH_LESS_THAN = 'WIDTH_LESS_THAN',
    HEIGHT_GREATER_THAN = 'HEIGHT_GREATER_THAN',
    HEIGHT_LESS_THAN = 'HEIGHT_LESS_THAN',
    TERRACE_HANDLE = 'TERRACE_HANDLE',
    TERRACE_HANDLE_LAYOUT = 'TERRACE_HANDLE_LAYOUT',
    CHANNEL_SECTION = 'CHANNEL_SECTION',
    CONSTRUCTIONAL_MULLION = 'CONSTRUCTIONAL_MULLION',
    MOVABLE_POST = 'MOVABLE_POST',
    GLAZING_PACKAGE = 'GLAZING_PACKAGE',
    GLAZING_PACKAGE_QUANTITY = 'GLAZING_PACKAGE_QUANTITY',
    GLAZING_PACKAGE_CATEGORY = 'GLAZING_PACKAGE_CATEGORY',
    GLAZING_PACKAGE_FRAME_CATEGORY = 'GLAZING_PACKAGE_FRAME_CATEGORY',
    PREDEFINED_GLAZING_PACKAGE = 'PREDEFINED_GLAZING_PACKAGE',
    MODEL = 'MODEL'
}
