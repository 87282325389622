import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {SelectItem} from "primeng/api/selectitem";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {callWizardStepValidators, WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {SubsystemNoteCategory} from "../../window-system/subsystem-notes-categories/subsystem-note-category";
import {SubsystemNotesCategoriesService} from "../../window-system/subsystem-notes-categories/subsystem-notes-categories.service";
import {SubsystemNote} from "../subsystem-note";
import {SubsystemService} from "../subsystem.service";
import {SubsystemNotesService} from "./subsystem-notes.service";

@Component({
    selector: 'app-subsystem-notes',
    templateUrl: './subsystem-notes.component.html',
    styleUrls: ['./subsystem-notes.component.css'],
    providers: [TranslatedSelectItemService, SubsystemNotesService, SubsystemNotesCategoriesService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemNotesComponent extends CrudCommonComponent<SubsystemNote, SubsystemNotesService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    availableCategories: SelectItem[] = [];
    allCategories: SubsystemNoteCategory[] = [];
    displayConfirmDialog = false;
    noteToDelete: number;
    private subsystemId: number;
    subsystemName: string;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private subsystemNotesCategoriesService: SubsystemNotesCategoriesService,
        private subsystemNotesService: SubsystemNotesService,
        private subsystemService: SubsystemService
    ) {
        super(injector, changeDetector, false, SubsystemNotesService, 'SUBSYSTEM_NOTES', 'SubsystemNotes');
        this.validateDataStep = () => this.validateData().pipe(map(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
        }));
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.subsystemId = params['subsystemId'];
            this.subsystemService.getItem(this.subsystemId).subscribe(subsystem => {
                this.subsystemName = subsystem.name;
            });
        });
        this.setCategories();
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): SubsystemNote {
        const note = new SubsystemNote();
        note.subsystemId = this.subsystemId;
        return note;
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.itemId = event.data.id;
        this.item = new SubsystemNote();
        this.newItem = false;
        this.selectedItem = event.data;
        this.item = event.data;
        this.keepSelectedItemIndex(event);
        this.setDisplayDialog(true);
    }

    protected validateForm(): Observable<boolean> {
        return callWizardStepValidators([this.validateDataStep])
            .pipe(map(results => results.every(result => result)));
    }

    private validateData(): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {};
        if (this.item.categoryId == undefined) {
            validationErrors['category'] = 'error.subsystemNoteDto.category.not_null';
        }
        if (this.item.date == undefined) {
            validationErrors['date'] = 'error.subsystemNoteDto.date.not_null';
        }
        if (this.item.note == undefined) {
            validationErrors['note'] = 'error.subsystemNoteDto.note.not_null';
        }
        return of<ValidationErrors>(validationErrors);
    }

    deleteNote(noteId: number) {
        this.noteToDelete = noteId;
        this.showConfirmDialog(true);
    }

    delete() {
        this.showConfirmDialog(false);
        this.subsystemNotesService.deleteItem(this.noteToDelete).subscribe({
            next: () => {
                this.showConfirmDialog(false);
                this.reloadDatatable();
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    cancelDelete() {
        this.showConfirmDialog(false);
        this.noteToDelete = undefined;
    }

    showConfirmDialog(show: boolean) {
        if (this.displayConfirmDialog !== show) {
            this.displayConfirmDialog = show;
            this.changeDetector.markForCheck();
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        event.filters['subsystemId'] = {value: this.subsystemId, matchMode: undefined};
        super.loadItemsLazy(event);
    }

    private setCategories() {
        this.subsystemNotesCategoriesService.getActiveItems()
            .subscribe(data => {
                this.allCategories = data.data;
                this.availableCategories = data.data.map(category => new SelectItemImpl(category.name[this.userLang], category.id));
                this.availableCategories.unshift(new SelectItemImpl(undefined, ""));
            });
    }
}
