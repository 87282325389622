export class Veneer {
    active: boolean;
    systemId: number;
    frameId: number;
    isWinged: boolean;
    distance: number;
    valid?: boolean;

    constructor(systemId?: number, frameId?: number, isWinged?: boolean, distance?: number, valid = false) {
        this.active = (systemId && distance && (isWinged !== undefined));
        this.systemId = systemId;
        this.frameId = frameId;
        this.isWinged = isWinged;
        this.distance = distance;
        this.valid = valid;
    }
}
