<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="name" header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item?.name }}
            </ng-template>
        </p-column>
        <p-column field="configName" header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item?.configSystemName[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column field="active" header="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.FORM.STATUS' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{ (item.active ? 'GENERAL.STATUS_SINGULAR.ACTIVE' : 'GENERAL.STATUS_SINGULAR.INACTIVE') | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-upselling-settings-form [upsellingSettings]="item" [validationErrors]="validationErrors"
                                     [configSystems]="configSystems"></app-upselling-settings-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.STEPS.SYSTEMS' | translate }}"
                     [id]="STEPS.SYSTEMS.id" [validate]="STEPS.SYSTEMS.validate">
        <div class="new-form-60vw">
            <app-link-selection [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [(ngModel)]="selectedWindowSystems"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-upselling-settings-form [upsellingSettings]="item" [validationErrors]="validationErrors"
                                     [configSystems]="configSystems"></app-upselling-settings-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS.id"
                     [validate]="STEPS.SYSTEMS.validate">
        <div class="new-form-60vw">
            <app-link-selection [linkableItems]="windowSystems" [showToggleAllButton]="true"
                                [(ngModel)]="selectedWindowSystems"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
