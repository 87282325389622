import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {SupportedLanguages} from '../../../supportedLanguages';
import {WebshopHelp, WebshopHelpType} from '../webshop-help/webshop-help';
import {WebshopHelpService} from '../webshop-help/webshop-help.service';

@Component({
    selector: 'app-webshop-sizing-help',
    templateUrl: './webshop-sizing-help.component.html',
    providers: [WebshopHelpService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopSizingHelpComponent implements OnInit, OnDestroy {

    readonly newElementHotkey: Hotkey;

    allHelp: WebshopHelp[] = [];

    supportedLanguages: SelectItem[];
    canUploadToAmazon = false;
    unusedAmazonS3Files: SelectItem[] = [];

    help: WebshopHelp = new WebshopHelp();
    imageFile?: File;
    uploadedFile: File;
    selectedUnusedFile: string;
    displayDialog = false;
    validationErrors: ValidationErrors = {};
    saveInProgress = false;

    constructor(private readonly webshopHelpService: WebshopHelpService,
                private readonly hotkeysService: HotkeysService,
                private readonly growls: GrowlMessageController,
                private readonly errors: CommonErrorHandler,
                private readonly changeDetector: ChangeDetectorRef) {
        this.newElementHotkey = new Hotkey('alt+n', () => {
            this.doShowDialogToAdd();
            return false;
        }, undefined, 'GENERAL.HOTKEYS.NEW');
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.newElementHotkey);
        this.supportedLanguages = SupportedLanguages.languages.map(lang => ({label: lang.name, value: lang.code}));
        this.loadAllHelp();
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.newElementHotkey);

    }

    private loadAllHelp(): void {
        this.webshopHelpService.getAll(WebshopHelpType.WINDOW_SIZING).subscribe({
            next: data => {
                this.allHelp = data;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    doShowDialogToAdd(): void {
        forkJoin({
            canUpload: this.webshopHelpService.canUploadToAmazonS3(),
            unusedFiles: this.webshopHelpService.getUnusedAmazonS3Files()
        }).subscribe({
            next: data => {
                this.help = new WebshopHelp();
                this.help.type = WebshopHelpType.WINDOW_SIZING;
                this.imageFile = undefined;
                this.uploadedFile = undefined;
                this.selectedUnusedFile = undefined;
                this.canUploadToAmazon = data.canUpload;
                this.unusedAmazonS3Files = data.unusedFiles.map(unusedFile => ({label: unusedFile, value: unusedFile}));
                this.validationErrors = {};
                this.displayDialog = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    loadHelp(id: number): void {
        forkJoin({
            help: this.webshopHelpService.getHelp(id),
            image: this.webshopHelpService.getHelpImage(id),
            canUpload: this.webshopHelpService.canUploadToAmazonS3(),
            unusedFiles: this.webshopHelpService.getUnusedAmazonS3Files()
        }).subscribe({
            next: data => {
                this.help = data.help;
                this.imageFile = data.image;
                this.uploadedFile = undefined;
                this.selectedUnusedFile = undefined;
                this.canUploadToAmazon = data.canUpload;
                this.unusedAmazonS3Files = data.unusedFiles.map(unusedFile => ({label: unusedFile, value: unusedFile}));
                if (!this.canUploadToAmazon) {
                    if (this.unusedAmazonS3Files.findIndex(af => af.value === this.help.amazonS3FileName) < 0) {
                        this.help.amazonS3FileName = undefined;
                    }
                }
                this.validationErrors = {};
                this.displayDialog = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    save(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.validateForm().pipe(
            finalize(() => this.saveInProgress = false),
            mergeMap(validationErrors => {
                if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                    this.validationErrors = validationErrors;
                    return of(undefined);
                }
                return this.webshopHelpService.saveHelp(this.help, this.imageFile,
                    this.uploadedFile != undefined ? this.uploadedFile : this.selectedUnusedFile)
                    .pipe(tap(() => {
                        this.growls.info('SETTINGS.SECTION.WEBSHOP_SIZING_HELP.DATA_UPDATED');
                        this.displayDialog = false;
                        this.loadAllHelp();
                        this.changeDetector.markForCheck();
                    }));
            }))
            .subscribe({
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
    }

    handleImageFileChange(file: File): void {
        this.imageFile = file || new File([], null);
    }

    handleUploadedFileChange(file: File): void {
        this.uploadedFile = file || new File([], null);
        this.selectedUnusedFile = undefined;
        this.help.amazonS3FileName = this.uploadedFile.name;
        this.changeDetector.markForCheck();
    }

    handleUnusedFileSelectionChange(file: string): void {
        this.uploadedFile = undefined;
        this.selectedUnusedFile = file;
        this.help.amazonS3FileName = this.selectedUnusedFile;
        this.changeDetector.markForCheck();
    }

    private validateForm(): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {
            language: MultiValidator.of('error.webshopHelpDto.language')
                .withNotNullValidator()
                .withSizeValidator(2, 2)
                .validate(this.help.language)
        };
        if ((this.help.id == undefined && this.uploadedFile == undefined && this.selectedUnusedFile == undefined)
            || (this.uploadedFile != undefined && this.uploadedFile.size <= 0)
            || (this.selectedUnusedFile != undefined && this.selectedUnusedFile.length <= 0)) {
            validationErrors['amazonS3File'] = 'error.webshopHelpDto.amazonS3File.not_null';
        } else if (this.uploadedFile != undefined && this.uploadedFile.size > 10485760) {
            validationErrors['amazonS3File'] = 'error.fileUpload.not_in_range';
        }
        if (this.imageFile == undefined || this.imageFile.size <= 0) {
            validationErrors['image'] = 'error.webshopHelpDto.image.not_null';
        }
        return ImagesValidator.validationErrors(this.imageFile, 10485760, 3000, 3000).pipe(map(imageValidationError => {
            if (validationErrors['image'] == undefined) {
                validationErrors['image'] = imageValidationError;
            }
            return validationErrors;
        }));
    }
}
