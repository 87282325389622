<div class="new-form-60vw">
    <p-accordion>
        <p-accordionTab header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.GLAMOUR_HORIZONTAL_PRINT_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['horizontalPrintImage'] != undefined}">
            <div>
                <app-file-upload inputId="horizontalPrintImage" [ngModel]="horizontalPrintImageFile"
                                 (ngModelChange)="handleHorizontalPrintImageFileChanged($event)"
                                 [disabled]="disabled"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 addButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.SELECT_GLAMOUR_PRINT_IMAGE"
                                 changeButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.CHANGE_GLAMOUR_PRINT_IMAGE"
                                 deleteButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.DELETE_GLAMOUR_PRINT_IMAGE"
                                 [widthToHeightRatio]="2.4"
                                 [(validationMessageKey)]="validationErrors['horizontalPrintImage']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>

    <p-accordion>
        <p-accordionTab header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.GLAMOUR_VERTICAL_PRINT_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['verticalPrintImage'] != undefined}">
            <div>
                <app-file-upload inputId="verticalPrintImage" [ngModel]="verticalPrintImageFile"
                                 (ngModelChange)="handleVerticalPrintImageFileChanged($event)"
                                 [disabled]="disabled"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 addButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.SELECT_GLAMOUR_PRINT_IMAGE"
                                 changeButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.CHANGE_GLAMOUR_PRINT_IMAGE"
                                 deleteButtonLabel="OFFER.POSITIONS.ADDON_CONFIG.FORM.DELETE_GLAMOUR_PRINT_IMAGE"
                                 [widthToHeightRatio]="0.6"
                                 [(validationMessageKey)]="validationErrors['verticalPrintImage']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>

    <p-accordion>
        <p-accordionTab header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.GLAMOUR_DESCRIPTION' | translate }}" [selected]="true">
            <div class="new-form-600">
                <div class="new-form-row">
                    <h3>{{ 'GLAMOUR_BOOKLET_DESCRIPTION.SEPARATOR_NOTE' | translate }}: ▼</h3>
                </div>

                <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                    <app-text-area inputId="{{'desc_' + lang.code}}"
                                   label="{{ 'GLAMOUR_BOOKLET_DESCRIPTION.FIELD_LABEL' | translate: {lang: lang.code} }}"
                                   [disabled]="disabled"
                                   [ngModel]="glamourDescription[lang.code]" (ngModelChange)="handleGlamourDescriptionChanged($event, lang.code)">
                    </app-text-area>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>

</div>
