<div class="datatable-container">
    <p-dataTable #dt [value]="addons" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="!tabViewAddedMode" [lazy]="!tabViewAddedMode" [totalRecords]="totalRecords"
                 selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onFilter)="updateFilterValue()"
                 (onLazyLoad)="loadAddonsLazy($event)" [responsive]="true"
                 [expandableRows]="true"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 [(selection)]="selectedAddon" dataKey="id"
                 [seqNum]="seqNum">
        <p-column *ngIf="bulkAddonsOnly" field="checked" header="" [sortable]="false" [filter]="false"
                  [style]="{'width': '40px'}">
            <ng-template pTemplate="header">
                <app-tristate-checkbox [(ngModel)]="allSelected"
                                       (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
            </ng-template>
            <ng-template let-position="rowData" pTemplate="body">
                    <span class="flex-row">
                        <app-checkbox [ngModel]="isSelectedItem(position)" id="{{'selectCheckbox_'+position.id}}"
                                      (ngModelChange)="selectItem(position)"></app-checkbox>
                        <i class="material-icons" *ngIf="isSelectedItem(position) && !dt.isRowExpanded(position)"
                            (click)="dt.toggleRow(position,$event)">expand_more</i>
                        <i class="material-icons" *ngIf="isSelectedItem(position) && dt.isRowExpanded(position)"
                           (click)="dt.toggleRow(position,$event)">expand_less</i>
                        <app-simple-button icon="image" type="filter" [size]="28"
                                           (onClick)="handleShowImage(position.id, position.name[userLang])"></app-simple-button>
                    </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="!bulkAddonsOnly" field="actions" header="" [sortable]="false"
                  [style]="{'width': '50px'}" styleClass="action-tabview-column">
            <ng-template let-addon="rowData" pTemplate="body">
                <app-simple-button *ngIf="!tabViewAddedMode" buttonId="add" icon="add" type="confirm"
                                   (onClick)="addWindowAddon(addon)"></app-simple-button>
                <app-simple-button *ngIf="!isDeleteButtonDisabled(addon)"
                                   buttonId="remove" icon="remove" type="delete"
                                   (onClick)="deleteWindowAddon(addon)"></app-simple-button>
                <app-simple-button icon="image" type="filter" [size]="28"
                                   (onClick)="handleShowImage(addon.id, addon.name[userLang])"></app-simple-button>
            </ng-template>
        </p-column>
        <p-column *ngIf="!tabViewAddedMode" field="name" header="{{ 'ADDONS.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="true"
                  filterMatchMode="contains" [defaultFilterValue]="{ value: searchedName }">
            <ng-template let-addon="rowData" pTemplate="body">
                {{addon.name[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="tabViewAddedMode" field="name.{{userLang}}" header="{{ 'ADDONS.FORM.NAME' | translate }}"
                  [sortable]="true"
                  [filter]="true"
                  filterMatchMode="contains">
        </p-column>
        <p-column *ngIf="tabViewAddedMode" field="category" header="{{ 'ADDONS.FORM.CATEGORY' | translate }}"
                  [sortable]="true"
                  [filter]="true"
                  [filterValues]="filterCategory | async">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ ('ADDONS.CATEGORIES.'+addon.category) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="bulkAddonsOnly" field="pcn" header="{{ 'ADDONS.FORM.PCN' | translate }}" [sortable]="true"
                  [filter]="true"
                  filterMatchMode="contains">
        </p-column>
        <!--COLUMN IS FILTERED DIFFERENTLY DEPENDING ON USER ROLE-->
        <p-column *ngIf="bulkAddonsOnly && isPermitted({roles:['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                  field="supplier.companyName" header="{{ 'ADDONS.FORM.SUPPLIER' | translate }}"
                  [sortable]="true"
                  [filter]="true"
                  filterMatchMode="contains">
            <ng-template let-addon="rowData" pTemplate="body">
                {{addon.supplier.companyName}} <span class="secondary-text">{{' (' + addon.supplier.name[userLang] + ')'}}</span>
            </ng-template>
        </p-column>
        <p-column *ngIf="bulkAddonsOnly && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})"
                  field="supplier.name" header="{{ 'ADDONS.FORM.SUPPLIER' | translate }}"
                  [sortable]="true"
                  [filter]="true"
                  filterMatchMode="contains">
            <ng-template let-addon="rowData" pTemplate="body">
                {{addon.supplier.name[userLang]}}
            </ng-template>
        </p-column>
        <!---->
        <p-column field="price" header="{{ 'ADDONS.FORM.PRICE' | translate }}" [sortable]="false"
                  [filter]="false">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ addon.price != undefined ?
                (addon.price.type == 'AMOUNT' || addon.price.type == 'RIGID_PRICE' ? (getPriceInSubsystemDefaultCurrency(addon) | price: subsystemDefaultCurrency) :
                (addon.price.type == 'PERCENT' && addon.price.value != undefined ? addon.price.value.toFixed(2) + '%' :
                (bulkAddonsOnly ? ('ADDONS.PRICE_TYPE.PRICE_GROUP_COLUMN_BULK' | translate) :
                ('ADDONS.PRICE_TYPE.PRICE_GROUP_COLUMN' | translate)))) :
                ('ADDONS.FORM.NO_PRICE' | translate)}}
            </ng-template>
        </p-column>
        <p-column *ngIf="!bulkAddonsOnly" field="addonFor" header="{{ 'ADDONS.FORM.ADDON_FOR' | translate }}"
                  [sortable]="false" [filter]="true"
                  [filterValues]="filterAddonFor | async">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ ('ADDONS.FOR.'+addon.addonFor) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="!sidebarOnlyMode" field="quantity" header="{{ 'OFFER.POSITIONS.FORM.QUANTITY' | translate }}" [sortable]="false"
                  [filter]="false" [style]="{ 'width': '120px', 'min-width': '120px' }">
            <ng-template let-addon="rowData" pTemplate="body">
                <app-input-number inputId="quantity" class="addon-quantity" [validationMessageKey]="validationErrors[addon.id + '_quantity']"
                                  [disabled]="(bulkAddonsOnly && !isSelectedItem(addon)) || !addon.changeQuantity || readOnlyMode"
                                  [min]="0" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                  (onBlur)="editAddonInAddMode(addon)"
                                  [ngModel]="addon.quantity" (ngModelChange)="onQuantityChange($event, addon)"
                                  [allowOnlyIntegers]="isAllowOnlyIntegers(addon)">
                    <ng-template pTemplate="label"></ng-template>
                </app-input-number>
            </ng-template>
        </p-column>
        <p-column *ngIf="tabViewAddedMode" field="lockRecalculateQuantity" [sortable]="false" [filter]="false">
            <ng-template let-addon="rowData" pTemplate="body">
                <div *ngIf="!sidebarOnlyMode && addon.changeQuantity"
                     pTooltip="{{'OFFER.POSITIONS.FORM.LOCK_RECALCULATE_QUANTITY' | translate}}">

                    <app-checkbox [inputId]="'recalculateLockCheckbox' + addon.id" [disabled]="readOnlyMode"
                                  [ngModel]="addon.lockRecalculateQuantity"
                                  (ngModelChange)="addon.lockRecalculateQuantity = $event; editWindowAddon(addon)">

                        <ng-template pTemplate="label" let-inputId="inputId">
                            <label [attr.for]="inputId" class="new-form-checkbox-label">
                                <span class="material-icons resize-lock-label">{{ addon.lockRecalculateQuantity ? 'lock' : 'lock_open' }}</span>
                            </label>
                        </ng-template>
                    </app-checkbox>
                </div>
            </ng-template>
        </p-column>
        <p-column field="quantityType" header="{{ 'ADDONS.FORM.QUANTITY_TYPE' | translate }}" [sortable]="false"
                  [filter]="false">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ ('ADDONS.QUANTITY_TYPE.FULL_NAME.' + addon.quantityType) | translate }}
            </ng-template>
        </p-column>

        <p-column *ngIf="!sidebarOnlyMode && bulkAddonsOnly" field="comment"
                  header="{{ 'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION' | translate }}"
                  [sortable]="false" [filter]="false">
            <ng-template let-addon="rowData" pTemplate="body">
                <div *ngIf="!bulkAddonsOnly || addon.checked">
                    <textarea pInputTextArea id="comment" rows="1" class="p-inputtext description" maxlength="500"
                              [disabled]="readOnlyMode" [(ngModel)]="addon.otherInfo" (blur)="editWindowAddon(addon)"></textarea>
                </div>
            </ng-template>
        </p-column>
        <ng-template let-addon pTemplate="rowexpansion">
            <div>
                <div *ngIf="!sidebarOnlyMode" class="new-form-row">
                    <app-text-area inputId="comment" [(ngModel)]="addon.otherInfo" (onBlur)="editWindowAddon(addon)"
                                   label="{{ 'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION' | translate }}"
                                   [maxlength]="500" [disabled]="readOnlyMode"></app-text-area>
                </div>
                <div>
                    <div class="new-form-row">
                        <app-select
                                *ngIf="addon.chooseColor && addon.availableCoreColors && addon.availableCoreColors.length > 0"
                                inputId="coreColorSelect" [disabled]="readOnlyMode"
                                label="{{ 'ADDONS.FORM.CORE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_coreColor']"
                                [options]="addon.availableCoreColors"
                                [(ngModel)]="addon.selectedCoreColor"></app-select>
                    </div>
                    <div class="new-form-row">
                        <app-select
                                *ngIf="addon.chooseColor && addon.availableOutsideColors && addon.availableOutsideColors.length > 0"
                                inputId="outsideColorSelect" [disabled]="readOnlyMode"
                                label="{{ 'ADDONS.FORM.OUTSIDE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_outsideCoverColor']"
                                [options]="addon.availableOutsideColors"
                                [allowSelectingNone]="true"
                                [(ngModel)]="addon.selectedOutsideColor"></app-select>
                    </div>
                    <div class="new-form-row">
                        <app-select
                                *ngIf="addon.chooseColor && addon.availableInsideColors && addon.availableInsideColors.length > 0"
                                inputId="insideColorSelect" [disabled]="readOnlyMode"
                                label="{{ 'ADDONS.FORM.INSIDE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_insideCoverColor']"
                                [options]="addon.availableInsideColors"
                                [allowSelectingNone]="true"
                                [(ngModel)]="addon.selectedInsideColor"></app-select>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-window-component-preview-dialog [(previewData)]="addonPreviewData"></app-window-component-preview-dialog>
