<div *ngIf="!newItem && !displayEditingWizard" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.OFFERS.PAYMENT_PACKAGES' | translate }}</div>
        <div class="table-header-buttons" >
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button id="clearFilters" *ngIf="filterByCurrentUser && (permissions.isKoordynator() || permissions.isOpiekun())"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="removeFiltersNotSetOnTableColumns()"></app-simple-button>
                <app-simple-button *ngIf="!filterByCurrentUser && (permissions.isKoordynator() || permissions.isOpiekun())"
                                   label="{{ 'PAYMENT_PACKAGE.SHOW_OWN' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByCurrentUser()"></app-simple-button>
                <p-multiSelect id="chooseColumnsMenu" defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                               [displaySelectedLabel]="false" [options]="columnOptions | selectItemTranslate"
                               [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>
                <app-simple-button *ngIf="isPermittedToAdd()" buttonId="add" label="{{ 'PAYMENT_PACKAGE.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" [disabled]="subsystems == undefined" align="right"
                                   [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt id="paymentPackageTable" [attr.id]="TABLE_ID" [paginator]="false" [rows]="chosenRowsPerPage"
                 [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedPaymentPackage" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder" dataKey="id"
                 [value]="paymentPackages">

        <p-column>
            <ng-template let-paymentPackage="rowData" pTemplate="body">
                <i *ngIf="!paymentPackage.fullyPrinted && paymentPackage.status === 'PAID'"
                   class="material-icons warning-severity-color"
                   title="{{ 'PAYMENT_PACKAGE.LIST.NOT_FULLY_PRINTED_WARNING' | translate }}">report_problem</i>
            </ng-template>
        </p-column>

        <!-- ACTIONS -->
        <p-column header="{{ 'OFFER.FORM.ACTIONS' | translate }}" [sortable]="false">
            <ng-template let-paymentPackage="rowData" pTemplate="body">
                <div class="table-row-action-buttons">
                    <app-simple-button *ngIf="isEditEnabled(paymentPackage)" [buttonId]="'show_' + paymentPackage.id"
                                       icon="edit" type="main-action" [size]="36"
                                       label="{{'PAYMENT_PACKAGE.ACTIONS.TOOLTIPS.EDIT_PAYMENT_PACKAGE' | translate}}" [inlineLabel]="false"
                                       (onClick)="showEditDialog(paymentPackage.id)"></app-simple-button>

                    <!--invisible button for proper alignment of other buttons-->
                    <app-simple-button *ngIf="!isEditEnabled(paymentPackage) && !isChangeStatusEnabled(paymentPackage)"
                                       icon="edit" type="invisible" [size]="36" ></app-simple-button>

                    <button-with-menu [buttonId]="'more_' + paymentPackage.id" rightButtonIcon="more_horiz"
                                      buttonLabel="{{'PAYMENT_PACKAGE.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate}}" [buttonInlineLabel]="false"
                                      buttonType="filter" [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                      [menuElements]="buildRowActions(paymentPackage)"
                                      (menuElementSelected)="handleRowAction($event)"></button-with-menu>

                    <app-simple-button *ngIf="isChangeStatusEnabled(paymentPackage) && !isEditEnabled(paymentPackage)"
                                       buttonId="changeStatus"
                                       [label]="getChangeStatusActionTooltip(paymentPackage) | translate"
                                       [inlineLabel]="false" icon="attach_money" type="main-action" [size]="36"
                                       (onClick)="changeStatusAction(paymentPackage)"></app-simple-button>
                </div>
            </ng-template>
        </p-column>

        <ng-container *ngFor="let column of columns;">
            <p-column *ngIf="column.field === 'name'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue"></p-column>
            <p-column *ngIf="column.field === 'netValue'
                          || column.field === 'grossValue'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    {{ paymentPackage[column.field].toFixed(2)}} PLN
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field === 'creationDate' || column.field === 'completionDate'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue" styleClass="datatable-date-range-column">
                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!column.filter"></div>
                </ng-template>
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    {{ paymentPackage[column.field] | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>

            <!-- STATUS -->
            <p-column *ngIf="column.field == 'status'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="false" [defaultFilterValue]="column.defaultFilterValue">

                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="availableStatuses | selectItemTranslate"
                                       (onChange)="loadItemsLazy(dt.createLazyLoadMetadata())"
                                       defaultLabel="{{ 'OFFER.FORM.STATUS' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false"
                                       [(ngModel)]="selectedStatuses"></p-multiSelect>
                    </div>
                </ng-template>

                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    <div [ngClass]="'status-color ' + paymentPackage.status">
                        {{ paymentPackage.status ? ('OFFER.STATUS.' + paymentPackage.status | translate) : ''}}
                    </div>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'ownerName'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    {{ paymentPackage.ownerFirstName + ' ' + paymentPackage.ownerLastName }}
                </ng-template>
            </p-column>

            <p-column *ngIf="(permissions.isKoordynator() || permissions.isOpiekun()) && column.field === 'subsystemName'"
                      [field]="column.field" [header]="column.header | translate" [sortable]="true"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="subsystems" (onChange)="loadItemsLazy(dt.createLazyLoadMetadata())"
                                       defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false"
                                       [(ngModel)]="selectedSubsystems"></p-multiSelect>
                    </div>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'clientManagerName'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    {{ paymentPackage.clientManagerFirstName + ' ' + paymentPackage.clientManagerLastName}}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'subClientManagerName'" [defaultFilterValue]="column.defaultFilterValue"
                      [field]="column.field" [filter]="column.filter" [header]="column.header | translate"
                      [sortable]="column.sortable">
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    <span *ngIf="paymentPackage.subClientManagerFirstName && paymentPackage.subClientManagerLastName">
                        {{ paymentPackage.subClientManagerFirstName + ' ' + paymentPackage.subClientManagerLastName}}
                    </span>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'transportValue'
                          || column.field === 'paymentPackageCorrection'" [field]="column.field"
                      [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-paymentPackage="rowData" pTemplate="body">
                    <ng-container *ngIf="paymentPackage[column.field] != undefined">
                        {{ paymentPackage[column.field].toFixed(2)}} PLN
                    </ng-container>
                </ng-template>
            </p-column>
        </ng-container>
    </p-dataTable>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
<app-wizard #wizard *ngIf="newItem || displayEditingWizard" header="{{ (newItem ? 'PAYMENT_PACKAGE.FORM.NEW_PAYMENT_PACKAGE' : 'PAYMENT_PACKAGE.FORM.EDIT_PAYMENT_PACKAGE') | translate }}"
            (onCancel)="cancel()" (currentStepChange)="handleWizardStepChange($event)" (onComplete)="submit()">
    <app-wizard-step *ngIf="newItem" label="{{ 'PAYMENT_PACKAGE.FORM.STEPS.SUBSYSTEM' | translate }}" [id]="STEPS.SUBSYSTEM.id"
                     [validate]="STEPS.SUBSYSTEM.validator">
        <app-payment-package-subsystem-selection-form [paymentPackage]="editedPaymentPackage" [subsystems]="subsystems"
                                                      [validationErrors]="validationErrors"
                                                      (onDblClick)="wizard.nextStep()"></app-payment-package-subsystem-selection-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'PAYMENT_PACKAGE.FORM.STEPS.DETAILS' | translate }}" [id]="STEPS.DETAILS.id"
                     [validate]="STEPS.DETAILS.validator">
        <app-payment-package-form #createDetails [paymentPackage]="editedPaymentPackage"
                                  [validationErrors]="validationErrors"></app-payment-package-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="!isPaid(editedPaymentPackage)" label="{{ 'PAYMENT_PACKAGE.FORM.STEPS.ORDERS' | translate }}" [id]="STEPS.ORDERS.id">
        <app-payment-package-picklist #selectOrders [paymentPackage]="editedPaymentPackage"
                                      [validationErrors]="validationErrors"></app-payment-package-picklist>
    </app-wizard-step>
</app-wizard>

<app-offer-status-transition-dialog *ngIf="visibleDialog === 'STATUS_CHANGE'"
                                    [possibleTransitions]="possibleTransitions"
                                    infoLabelPrefix="OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.CONTENT."
                                    (onHide)="hideDialog()"
                                    (onChangeStatus)="changeStatus($event)"></app-offer-status-transition-dialog>

<app-motlawa-bulk-addons-distribution *ngIf="visibleDialog === 'MOTLAWA_ADDONS_DISTRIBUTION'" (onHide)="hideDialog()"
                                      (onConfirm)="generateMotlawaData(motlawaIntegrationDialogsData.paymentPackageId)"
                                      [dialogsData]="motlawaIntegrationDialogsData"></app-motlawa-bulk-addons-distribution>

<app-motlawa-data *ngIf="visibleDialog === 'MOTLAWA_DATA'" (onHide)="hideDialog()"
                  [dialogData]="motlawaIntegrationDialogsData"></app-motlawa-data>
