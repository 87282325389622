<app-wizard-dialog #wizard header="{{ 'SUPPLIER-DETAILS.EDIT-SUPPLIER-TITLE' | translate }}" (onCancel)="hide()"
                   (onComplete)="submit()" contentStyleClass="scrolled-dialog-60vw height-60vw-no-padding">
    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-DETAILS-TITLE' | translate }}" [id]="STEPS.DETAILS"
                     [validate]="validateSupplierDetailsStep">
        <app-supplier-details-form [supplier]="supplier"
                                   [validationErrors]="validationErrors"></app-supplier-details-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-ADDRESS-TITLE' | translate }}" [id]="STEPS.ADDRESS"
                     [validate]="validateAddressStep">
        <app-supplier-address-form [validationErrors]="validationErrors"
                                   [supplier]="supplier"></app-supplier-address-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                     label="{{ 'SUPPLIER-DETAILS.STEP-ANNOTATIONS-TITLE' | translate }}" [id]="STEPS.ANNOTATIONS">
        <app-supplier-annotations-form #supplierAnnotationsForm [supplier]="supplier"
                                       [validationErrors]="validationErrors"></app-supplier-annotations-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                     label="{{ 'MOTLAWA_INTEGRATION_FORM.WIZARD-STEP-TITLE' | translate }}" [id]="STEPS.MOTLAWA_INTEGRATION">
        <app-supplier-motlawa-integration-form [motlawaIntegrationInfo]="motlawaIntegrationInfo"
                                               [validationErrors]="validationErrors"></app-supplier-motlawa-integration-form>
    </app-wizard-step>
</app-wizard-dialog>
