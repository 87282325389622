<div class="new-form-400">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'USER-GROUP.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']"
                        [(ngModel)]="userGroup.name" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row">
        <div class="new-form-field">
            <label>{{ 'USER-GROUP.FORM.USERS' | translate }}*</label>
            <div class="user-group-user-list">
                <app-checkbox *ngFor="let user of users" [inputId]="'user_' + user.id" [ngClass]="{'inactive-user': !user.active}"
                              [label]="user.firstName + ' ' + user.lastName" [(ngModel)]="user.selected"></app-checkbox>
                <div *ngIf="validationErrors['linkedUsers'] != undefined" class="new-form-field-error-message">
                    {{ validationErrors['linkedUsers'] | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
