import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApplicationStatusService} from '../../features/admin-panel/application-status/application-status.service';
import {CommonErrorHandler} from '../CommonErrorHandler';
import {GrowlMessageController} from '../growl-message/growl-message-controller';
import {ValidationErrors} from '../validation-errors';
import {RedrawWindowsService} from './redraw-windows.service';

@Component({
    selector: 'app-redraw-windows-component',
    templateUrl: './redraw-windows-page.component.html',
    styleUrls: ['./redraw-windows-page.component.css'],
    providers: [RedrawWindowsService, ApplicationStatusService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedrawWindowsPageComponent implements OnInit {

    isRedrawInProgress = true;
    verified = false;
    withSvg = false;
    offerPositionId: number;

    offerCreatedFromDate: Date;
    offerCreatedToDate: Date;
    validationErrors: ValidationErrors = {};

    constructor(private redrawWindowsService: RedrawWindowsService,
                private applicationStatusService: ApplicationStatusService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private growls: GrowlMessageController) {
    }

    ngOnInit(): void {
        this.checkStatus();
    }

    private checkStatus() {
        this.applicationStatusService.isRedrawWindowsInProgress().subscribe(isRedrawInProgress => {
            this.isRedrawInProgress = isRedrawInProgress;
            this.changeDetector.markForCheck();
        });
    }

    clearDates() {
        this.offerCreatedFromDate = null;
        this.offerCreatedToDate = null;
        this.verified = false;
    }

    executeDrawingDatasUpdate() {
        if (!this.validateDetails()) {
            return;
        }
        this.isRedrawInProgress = true;
        this.applicationStatusService.isRedrawWindowsInProgress().subscribe(
        () => {
            this.growls.info("REDRAW_WINDOWS.INFO", null, true);
            this.redrawWindowsService.execute(this.withSvg, this.offerCreatedFromDate, this.offerCreatedToDate)
                .subscribe({
                    next: () => this.checkStatus(),
                    error: error => {
                        this.errors.handle(error);
                        this.isRedrawInProgress = false;
                    }
                });
            }
        );
    }

    private validateDetails(): boolean {
        this.validationErrors = {};
        if (this.offerCreatedToDate != null && this.offerCreatedFromDate != null) {
            if (this.offerCreatedFromDate > this.offerCreatedToDate) {
                this.validationErrors['offerCreatedFromDate'] = 'error.offerDto.validTo.over_max';
            }
        }
        return Object.keys(this.validationErrors).length === 0;
    }
}
