import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import * as _ from 'underscore';
import {DropDownExtraOptions} from '../../../../../../../shared/drop-down-extra-options';
import {DropDownExtraOptionsLabels} from '../../../../../../../shared/DropDownExtraOptionsProvider';
import {ProfileType} from '../../../../../../../window-designer/catalog-data/profile-interface';
import {OpeningOption} from '../../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from '../../../../../../../window-designer/drawing-data/drawing-data';
import {TerraceHandleLayout} from '../../../../../../../window-designer/drawing-data/TerraceHandleLayout';
import {AddonCategoryEnum} from '../../../../../../../window-designer/enums/AddonCategoryEnum';
import {AddonFor} from '../../../../../../../window-designer/enums/AddonFor';
import {ProfilesCompositionDistances} from '../../../../../../../window-designer/profiles-composition-distances';
import {AddonDefaultQuantityCalculator} from '../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator';
import {AlignmentTool} from '../../../../../../../window-designer/utils/AlignmentTool';
import {GrillSegmentIdGenerator} from '../../../../../../../window-designer/utils/GrillSegmentIdGenerator';
import {MullionUtils} from '../../../../../../../window-designer/utils/MullionUtils';
import {PricingUtils} from '../../../../../../../window-designer/utils/PricingUtils';
import {WindowCalculator} from '../../../../../../../window-designer/window-calculator';
import {SelectItemImpl} from '../../../../../../common/service/select.item.impl';
import {Addon} from '../../../../../window-system/addons/addon';
import {AddonsService} from '../../../../../window-system/addons/addons.service';
import {DecorativeFillingsService} from '../../../../../window-system/decorative-filling/decorative-filling.service';
import {DecorativeFilling} from '../../../../../window-system/decorative-filling/decorativeFilling';
import {Glass} from '../../../../../window-system/glass/glass';
import {Profile} from '../../../../../window-system/profile/profile';
import {ProfileService} from '../../../../../window-system/profile/profile.service';
import {Seal} from '../../../../../window-system/seal/Seal';
import {SealService} from '../../../../../window-system/seal/seal.service';
import {WindowSystemDefinitionService} from '../../../../../window-system/window-system-definition/window-system-definition.service';
import {PositionType} from '../../../../AbstractPosition';
import {BulkChangeFrontendWarning} from '../BulkChangeFrontendWarning';
import {BulkChangesHelper} from '../BulkChangesHelper';
import {CommonBulkChangeComponent} from '../common-bulk-change.component';
import {OfferPositionModel} from '../OfferPositionModel';
import {Position} from '../position';
import {BulkGeneralChangeDialogData} from '../position-list-dialogs';

@Component({
    selector: 'app-bulk-general-change',
    templateUrl: './bulk-general-change.component.html',
    providers: [ProfileService, AddonsService, SealService, DecorativeFillingsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkGeneralChangeComponent extends CommonBulkChangeComponent implements OnInit {

    @Input()
    dialogData: BulkGeneralChangeDialogData;

    submitInProgress = false;

    profiles: SelectItem[] = [];
    enhancements: SelectItem[] = [];
    covers: SelectItem[] = [];
    availableWeldTypes: SelectItem[] = [];
    availableHandles: SelectItem[] = [];
    availableTerraceHandles: SelectItem[] = [];
    availableTerraceHandleLayouts: SelectItem[] = [];
    availableDoorsteps: SelectItem[] = [];
    underWindowProfiles: SelectItem[] = [];
    availableMillings: SelectItem[] = [];
    availableMillingsNorwegian: SelectItem[] = [];
    availableUnderWindowBeads: SelectItem[] = [];
    doorOpening: SelectItem[] = [];
    externalSeals: SelectItem[] = [];
    internalSeals: SelectItem[] = [];
    availableChannelSections: SelectItem[] = [];
    availableConstructionalMullions: SelectItem[] = [];
    availableMovablePosts: SelectItem[] = [];

    chosenProfile: Profile | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenWeldType: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenEnhancement: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenCover: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenHandle: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenTerraceHandle: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenTerraceHandleLayout: TerraceHandleLayout | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenDoorsteps: Profile | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    underWindowProfile: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenMilling: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenMillingNorwegian: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenUnderWindowBead: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenOpening: string | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenExternalSeal: Seal | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenInternalSeal: Seal | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenChannelSection: Profile | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenConstructionalMullion: Profile | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    chosenMovablePost: Profile | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;

    constructor(injector: Injector,
                protected profileService: ProfileService,
                protected sealService: SealService,
                protected windowSystemDefinitionService: WindowSystemDefinitionService) {
        super(injector);
    }

    ngOnInit(): void {
        this.initStaticData(true);
        const addonsFilters = {
            active: {value: 'true'},
            forOffer: {value: 'true'},
            includeGeneralAddons: {value: 'true'},
            includeSubsystemAddons: {value: 'true'},
            withColors: {value: 'false'},
            withPrices: {value: 'false'},
            windowSystemId: {value: Array.from(this.windowSystemIds)}
        };
        forkJoin({
            profiles: this.profileService.getAllActiveProfiles(Array.from(this.windowSystemIds), false),
            boldProfiles: this.profileService.getAllActiveProfiles(Array.from(this.windowSystemIds), true),
            addons: this.addonsService.getItems(0, undefined, {
                ...addonsFilters,
                requireAllSystems: {value: 'false'}
            }, 'sortIndex', 1),
            boldAddons: this.addonsService.getItems(0, undefined, {
                ...addonsFilters,
                requireAllSystems: {value: 'true'}
            }, 'sortIndex', 1),
            seals: this.sealService.getAllActiveSeals(Array.from(this.windowSystemIds), false),
            boldSeals: this.sealService.getAllActiveSeals(Array.from(this.windowSystemIds), true),
            glasses: this.glassIds.size > 0 ? this.glassService.getGlasses(Array.from(this.glassIds)) : of<Glass[]>([]),
            decorativeFillings: this.decorativeFillingIds.size ? this.decorativeFillingService.getDecorativeFillings(Array.from(this.decorativeFillingIds)) : of<DecorativeFilling[]>([])
        }).pipe(
            finalize(() => this.blockUiController.unblock(CommonBulkChangeComponent.LOAD_DATA_ID))
        ).subscribe({
            next: data => {
                let profilesByType = _.groupBy(data.profiles.data, 'type');
                let boldProfilesByType = _.groupBy(data.boldProfiles.data, 'type');
                const buildProfileSelectItems = (type: ProfileType, extra?: DropDownExtraOptionsLabels) => {
                    return this.buildProfileSelectItems(profilesByType[type], extra, boldProfilesByType[type]);
                };
                this.profiles = buildProfileSelectItems(ProfileType.FRAME);
                this.availableDoorsteps = buildProfileSelectItems(ProfileType.THRESHOLD, DropDownExtraOptionsLabels.CLEAN_OLD_VALUE);
                this.availableChannelSections = buildProfileSelectItems(ProfileType.CHANNEL_SECTION);
                this.availableConstructionalMullions = buildProfileSelectItems(ProfileType.CONSTRUCTIONAL_MULLION);
                this.availableMovablePosts = buildProfileSelectItems(ProfileType.MOVABLE_POST);

                let addonsByType = _.groupBy(data.addons.data.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]), 'category');
                let boldAddonsByType = _.groupBy(data.boldAddons.data.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]), 'category');
                const buildAddonSelectItems = (category: AddonCategoryEnum, extra?: DropDownExtraOptionsLabels) => {
                    return this.buildAddonSelectItems(addonsByType[category], extra, boldAddonsByType[category]);
                };
                this.availableWeldTypes = buildAddonSelectItems(AddonCategoryEnum.WELD_TYPE);
                this.enhancements = buildAddonSelectItems(AddonCategoryEnum.ENHANCEMENTS);
                this.covers = buildAddonSelectItems(AddonCategoryEnum.COVER);
                this.availableHandles = buildAddonSelectItems(AddonCategoryEnum.HANDLE);
                this.availableTerraceHandles = buildAddonSelectItems(AddonCategoryEnum.TERRACE_HANDLE);
                this.availableTerraceHandleLayouts = this.buildEnumSelectItems('TERRACE_HANDLE_LAYOUT.', TerraceHandleLayout);
                this.underWindowProfiles = buildAddonSelectItems(AddonCategoryEnum.UNDERWINDOW_PROFILE,
                    DropDownExtraOptionsLabels.CLEAN_OLD_VALUE);
                this.availableMillings = buildAddonSelectItems(AddonCategoryEnum.MILLING,
                    DropDownExtraOptionsLabels.CLEAN_OLD_VALUE);
                this.availableMillingsNorwegian = buildAddonSelectItems(AddonCategoryEnum.MILLING_NORWEGIAN,
                    DropDownExtraOptionsLabels.CLEAN_OLD_VALUE);
                this.availableUnderWindowBeads = buildAddonSelectItems(AddonCategoryEnum.UNDER_WINDOW_BEAD,
                    DropDownExtraOptionsLabels.CLEAN_OLD_VALUE);

                this.doorOpening = [
                    new SelectItemImpl(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE),
                    new SelectItemImpl('OFFER.OPENING.INSIDE', OpeningOption.INSIDE),
                    new SelectItemImpl('OFFER.OPENING.OUTSIDE', OpeningOption.OUTSIDE)
                ];
                this.externalSeals = this.buildSealSelectItems(data.seals.data.filter(seal => seal.outerSeal),
                    undefined, data.boldSeals.data.filter(seal => seal.outerSeal));
                this.internalSeals = this.buildSealSelectItems(data.seals.data.filter(seal => seal.innerSeal),
                    undefined, data.boldSeals.data.filter(seal => seal.innerSeal));

                this.staticData.glasses = data.glasses;
                this.staticData.decorativeFillings = data.decorativeFillings;
            },
            error: (error) => {
                this.errors.handle(error);
            },
            complete: () => {
                this.dataReady = true;
                this.changeDetector.markForCheck();
            }
        });
    }

    protected getSelectedPositions(): Position[] {
        return this.dialogData.selectedOfferPositions;
    }

    submitDialog(): void {
        this.updateOffers().subscribe({
            next: result => {
                this.dialogData.modifiedPositions = result.modifiedPositions;
                this.dialogHideHelper.call(() => this.onSubmit.emit(result.warnings));
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private filteredConfigs(windowPositions: OfferPositionModel[]): Position[] {
        return this.dialogData.allOfferPositions
            .filter(pos => pos.type === PositionType.CONFIG_SYSTEM)
            .filter(pos => windowPositions.some(winPos => winPos.offerPosition.id === pos.parentOfferPositionId));
    }

    private updateOffers(): Observable<{ modifiedPositions: Position[], warnings: BulkChangeFrontendWarning[] }> {
        if (this.submitInProgress) {
            return EMPTY;
        }
        let updatedDatas = this.positionsToUpdate;
        this.submitInProgress = true;

        let warnings = [];
        for (let updateData of updatedDatas) {
            let drawingData = updateData.mappedData as DrawingData;
            const specification = drawingData.specification;
            specification.frameProfileId = this.submitValueToMappedData(specification.frameProfileId, this.chosenProfile);
            const dataSource = AddonDefaultQuantityCalculator.prepareDataSource(drawingData, this.staticData);

            if (WindowCalculator.hasSubwindowsAllowingHandles(drawingData.windows)) {
                specification.handleType.addonId = this.submitValueToMappedData(specification.handleType.addonId, this.chosenHandle);
            } else {
                if (this.chosenHandle !== DropDownExtraOptions.DO_NOT_CHANGE) {
                    let handlesNotChangedWarn = 'OFFER.POSITIONS.DIALOGS.BULK_GENERAL_CHANGE.NO_HANDLES';
                    warnings.push(new BulkChangeFrontendWarning(updateData.offerPosition.id, handlesNotChangedWarn));
                }
            }

            if (WindowCalculator.hasNonFixedSubwindows(drawingData)) {
                specification.opening = this.submitValueToMappedData(specification.opening, this.chosenOpening);
            } else {
                if (this.chosenOpening !== DropDownExtraOptions.DO_NOT_CHANGE) {
                    let openingNotChangedWarn = 'OFFER.POSITIONS.DIALOGS.BULK_GENERAL_CHANGE.NO_OPENING';
                    warnings.push(new BulkChangeFrontendWarning(updateData.offerPosition.id, openingNotChangedWarn));
                }
            }

            specification.sealExternalId = this.submitValueToMappedData(specification.sealExternalId, this.chosenExternalSeal);
            specification.sealInternalId = this.submitValueToMappedData(specification.sealInternalId, this.chosenInternalSeal);
            specification.cover = this.selectAddonValue(specification.cover, this.chosenCover, dataSource);
            specification.doorstepId = this.submitValueToMappedData(specification.doorstepId, this.chosenDoorsteps);
            specification.frameEnhancement = this.selectAddonValue(specification.frameEnhancement, this.chosenEnhancement, dataSource);
            specification.underwindowProfile = this.selectAddonValue(specification.underwindowProfile, this.underWindowProfile, dataSource);
            specification.milling = this.selectAddonValue(specification.milling, this.chosenMilling, dataSource);
            specification.millingNorwegian = this.selectAddonValue(specification.millingNorwegian, this.chosenMillingNorwegian, dataSource);
            specification.underWindowBead = this.selectAddonValue(specification.underWindowBead, this.chosenUnderWindowBead, dataSource);
            specification.weldType = this.selectAddonValue(specification.weldType, this.chosenWeldType, dataSource);
            specification.terraceHandle = this.selectAddonValue(specification.terraceHandle, this.chosenTerraceHandle, dataSource);
            specification.terraceHandleLayout = this.submitValueToMappedData(specification.terraceHandleLayout, this.chosenTerraceHandleLayout);
            specification.channelSectionId = this.submitValueToMappedData(specification.channelSectionId, this.chosenChannelSection);
            specification.constructionalMullionId = this.submitValueToMappedData(specification.constructionalMullionId, this.chosenConstructionalMullion);
            specification.movablePostId = this.submitValueToMappedData(specification.movablePostId, this.chosenMovablePost);
            if (specification.terraceHandle == undefined || specification.terraceHandle.addonId == undefined) {
                specification.terraceHandleLayout = undefined;
            }

            updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
        }

        let get = (array: any[], id: number) => array.find(e => e.id === id);
        let offerConfigs = this.filteredConfigs(updatedDatas).map(CommonBulkChangeComponent.copyOfferPosition);
        const datasBySystemId = BulkChangesHelper.getDatasGroupedBySystemIds(
            updatedDatas.filter(ud => BulkChangesHelper.constructionSpecificationChanged(ud)));
        if (datasBySystemId.size === 0) {
            return of({modifiedPositions: [...updatedDatas.map(updateData => updateData.offerPosition)], warnings: warnings});
        }

        const observables = Array.from(datasBySystemId.keys()).map(wsId => {
            return forkJoin({
                system: this.windowSystemDefinitionService.getSystem(wsId),
                profiles: this.windowSystemDefinitionService.getSystemsProfiles(wsId)
            });
        });
        return forkJoin(observables).pipe(mergeMap(
            response => {
                const bySystemId = BulkChangesHelper.getSystemsAndProfilesGroupedBySystemId(response);
                let updatedConfigs: Position[] = [];

                datasBySystemId.forEach((positions, wsId) => {
                    const windowSystem = bySystemId.get(wsId).system;
                    const profiles = bySystemId.get(wsId).profiles;
                    const profileCompositionDistances = new ProfilesCompositionDistances();
                    const oldProfileCompositionDistances = new ProfilesCompositionDistances();
                    profileCompositionDistances.prepareSystem(windowSystem);
                    oldProfileCompositionDistances.prepareSystem(windowSystem);

                    positions.forEach(updateData => {
                        let mappedData = updateData.mappedData as DrawingData;
                        const mappedDataBeforeChanges = updateData.mappedDataBeforeChanges as DrawingData;
                        const frame = get(profiles, mappedData.specification.frameProfileId);
                        const oldFrame = get(profiles, mappedDataBeforeChanges.specification.frameProfileId);
                        if (frame == undefined) {
                            // will be rejected with nice validation message from backend on next step
                            return;
                        }
                        profileCompositionDistances.prepareProfileDistances(profiles, mappedData.specification);

                        GrillSegmentIdGenerator.init(mappedData);

                        if (oldFrame != null) {
                            oldProfileCompositionDistances.prepareProfileDistances(profiles, mappedDataBeforeChanges.specification);
                            AlignmentTool.autoAlign(windowSystem, profileCompositionDistances,
                                mappedData, mappedDataBeforeChanges,
                                oldProfileCompositionDistances);
                        }
                        MullionUtils.repositionMullionsAndGrills(mappedData, profileCompositionDistances, null, null, true);
                        MullionUtils.updateAllMullionPositions(mappedData, profileCompositionDistances, undefined, true);

                        mappedData = PricingUtils.enhanceForPricing(mappedData, windowSystem,
                            profileCompositionDistances, false);
                        updateData.mappedData = mappedData;
                        BulkChangesHelper.getResizedConfigurableAddonsForWindow(offerConfigs, profileCompositionDistances, updateData)
                            .forEach(config => {
                                config.position.data = JSON.stringify(config.configurableAddon);
                                updatedConfigs.push(config.position as Position); // input was a full Position object, safe cast
                            });
                        PricingUtils.mapGrillAndMullionSegmentsToDtos(updateData.mappedData);
                        updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
                    });
                });

                return of({
                    modifiedPositions: [...updatedDatas.map(updateData => updateData.offerPosition), ...updatedConfigs],
                    warnings: warnings
                });
            }));
    }
}
