<app-wizard #wizard header="{{ 'CLIENT-GROUP.FORM.ADD' | translate }}" (onCancel)="exitWizard()"
            (currentStepChange)="onStepChange($event)" (onComplete)="submit()">
    <app-wizard-step label="{{ 'CLIENT-DETAILS.FORM.GENERAL' | translate }}" [id]="STEPS.FILL_DETAILS"
                     [validate]="validateClientGroupDetailsStep">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-input-text inputId="name" [maxlength]="120" label="{{ 'CLIENT-GROUP.FORM.NAME' | translate }}"
                                [(validationMessageKey)]="validationErrors['name']" [(ngModel)]="clientGroup.name"
                                [required]="true"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="defaultOfferDurationDays"
                                  label="{{ 'CLIENT-GROUP.FORM.DEFAULT_OFFER_DURATION_DAYS' | translate }}"
                                  [(validationMessageKey)]="validationErrors['defaultOfferDurationDays']" [min]="1"
                                  [max]="31" [step]="1"
                                  [(ngModel)]="clientGroup.defaultOfferDurationDays"></app-input-number>
            </div>
            <app-simple-profit-margin-source *ngIf="simpleProfitMarginSource" [profitMargins]="simpleProfitMarginSource"
                                             [suppliers]="suppliers" [validationErrors]="validationErrors"
                                             validationPrefix="simpleProfitMarginSource."
                                             (onSetAllClicked)="clientGroup.bulkAddonProfitMargin = $event"></app-simple-profit-margin-source>
            <div class="new-form-row">
                <app-input-text inputId="bulkAddonProfitMargin"
                                label="{{ 'CLIENT-GROUP.FORM.BULK_ADDON_PROFIT_MARGIN' | translate }}"
                                [(validationMessageKey)]="validationErrors['bulkAddonProfitMargin']" [maxlength]="250"
                                [(ngModel)]="clientGroup.bulkAddonProfitMargin"></app-input-text>
            </div>
            <div class="new-form-row" *ngIf="!permissions.isHandlowiec()">
                <app-checkbox inputId="enableSimpleProfitMarginSourceCheckbox"
                              label="{{ 'CLIENT-GROUP.FORM.USE_SIMPLE_PROFIT_MARGINS' | translate }}"
                              [ngModel]="simpleProfitMarginSource != undefined"
                              (ngModelChange)="handleEnableSimpleProfitMarginSourceChange($event)"></app-checkbox>
            </div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div class="new-form-60vw">
            <app-profit-margin #childTable [clientGroupId]="clientGroup.id" entityType="clientGroup"
                               [userCanEdit]="true" [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.ROOF_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ROOF_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div class="new-form-60vw">
            <app-profit-margin #childTable [clientGroupId]="clientGroup.id" entityType="clientGroup"
                               [userCanEdit]="true" [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.ENTRANCE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div class="new-form-60vw">
            <app-profit-margin #childTable [clientGroupId]="clientGroup.id" entityType="clientGroup"
                               [userCanEdit]="true" [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_CONFIG_ADDONS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div class="new-form-60vw">
            <app-profit-margin #childTable entityType="clientGroup"
                               class="app-client-group-addon-profit-margin-config-addons"
                               [clientGroupId]="clientGroup.id" [target]="possibleTargets.CONFIG"
                               [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="clientGroupConfAddGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="clientGroupConfAddGlobalMarginInputId"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_GATES"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div class="new-form-60vw">
            <app-profit-margin #childTable entityType="clientGroup" [clientGroupId]="clientGroup.id" [target]="possibleTargets.GATE"
                               [userCanEdit]="true" [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="gatesGlobalMarginInputId"></app-profit-margin>
        </div>
    </app-wizard-step>
</app-wizard>
