import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {CrudCommonComponent} from "../../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {Pricesheet, PricesheetCategory} from "../pricesheet";
import {PricesheetsService} from "../pricesheets.service";

@Component({
    selector: 'app-pricesheet-tab',
    templateUrl: './pricesheet-tab.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [PricesheetsService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricesheetTabComponent extends CrudCommonComponent<Pricesheet, PricesheetsService> implements OnInit {

    filterType: TranslatedSelectItem[] = [];
    @Input()
    active: boolean;
    @Input()
    category: PricesheetCategory;
    @Input()
    showFilters: boolean;
    @Input()
    seqNum: number;
    @Input()
    selectedItem: Pricesheet;
    @Output()
    selectedItemChange = new EventEmitter<Pricesheet>();
    @Output()
    onRowSelectEmitter = new EventEmitter();
    @Output()
    addNewItem = new EventEmitter();

    @ViewChild('dt') datatable: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, PricesheetsService, 'PRICESHEET', 'PricingSheet');
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit(): void {
        this.initShownColumns();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    showDialogToAdd(): void {
        this.addNewItem.emit();
    }

    getNewItem(): Pricesheet {
        console.log("unused method, do not call");
        return undefined;
    }

    submit(): void {
        console.log("unused method, do not call");
        return undefined;
    }

    onRowSelect(event) {
        this.onRowSelectEmitter.emit(event);
        this.keepSelectedItemIndex(event);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        if (!event) {
            event = this.getDatatable().createLazyLoadMetadata();
        }
        if (!event.filters) {
            event.filters = {};
        }
        event.filters['category'] = {value: this.category};
        super.loadItemsLazy(event);
    }

    reloadTable() {
        this.reloadDatatable();
    }
}
