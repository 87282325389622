import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../../../block-ui/block-ui-controller';
import {OnceFlag} from '../../../../shared/once-flag';
import {ErrorResponse} from '../../../errors/errorResponse';
import {ProductionOrderService} from '../production-order-service';
import {TemporaryDealerDiscount} from '../TemporaryDealerDiscount';

@Component({
    selector: 'app-change-dealer-discount',
    templateUrl: './change-dealer-discount.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [ProductionOrderService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeDealerDiscountComponent {

    @Input() temporaryDiscount: TemporaryDealerDiscount;
    @Output() onSave = new EventEmitter<void>();
    @Output() onError = new EventEmitter<any>();
    @Output() onCancel = new EventEmitter<void>();

    validationErrors = {};

    private readonly BLOCK_NAME = 'ChangeDealerDiscountSave';

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private productionOrderService: ProductionOrderService,
                private blockUiController: BlockUiController,
                private changeDetector: ChangeDetectorRef) {
    }

    submit(): void {
        this.blockUiController.block(this.BLOCK_NAME);
        this.productionOrderService.changeDealerDiscount(this.temporaryDiscount)
            .pipe(finalize(() => this.blockUiController.unblock(this.BLOCK_NAME)))
            .subscribe({
                next: () => {
                    this.dialogHideHelper.call(() => this.onSave.emit());
                },
                error: (error: HttpErrorResponse) => {
                    let errorResponse = new ErrorResponse(error.error);
                    if (errorResponse.is400()) {
                        this.validationErrors = {};
                        for (let property in errorResponse.invalidFields) {
                            this.validationErrors[property] = errorResponse.invalidFields[property];
                        }
                        this.changeDetector.markForCheck();
                    } else {
                        this.dialogHideHelper.call(() => this.onError.emit(error));
                    }
                },
                complete: () => {
                    console.info('ChangeDealerDiscountComponent submit done');
                }
            });
    }

    clearError(controlName): void {
        if (this.validationErrors[controlName] != undefined) {
            this.validationErrors[controlName] = undefined;
            this.changeDetector.markForCheck();
        }
    }

    cancel(): void {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }
}
