import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-global-change-confirmation',
    templateUrl: './global-change-confirmation.component.html',
    styleUrls: ['./global-change-confirmation.component.css']
})
export class GlobalChangeConfirmationComponent {

    @Input()
    visible: boolean;
    @Input()
    allPositionsContainErrors: boolean;
    @Input()
    somePositionsContainErrors: boolean;

    @Output()
    readonly visibleChange = new EventEmitter<boolean>();
    @Output()
    readonly onSubmit = new EventEmitter<boolean>();
}
