import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {ApplicationFeatures} from '../../application-info/application-features';
import {Permissions} from '../../auth/permission.service';
import {StorageKey, StorageService} from '../../auth/storage.service';
import {UserUiConfigService} from "../../auth/uiconfig/userUiConfig.service";
import {MenuElement, MenuElementBuilder, MenuElementType} from "../../common/button-with-menu/MenuElement";
import {MenuType} from "../../common/button-with-menu/MenuType";
import {FoldCorner} from "../../common/card-fold/card-fold.component";
import {CommonErrorHandler} from '../../common/CommonErrorHandler';
import {DataService} from '../../common/data.service';
import {OfferStatus} from '../../common/enums/OfferStatus';
import {SidenavController} from '../../sidenav-controller';
import {AccessData} from '../AccessData';
import {KnownApplicationResources} from '../admin-panel/application-resource/application-resource';
import {ApplicationResourceService} from '../admin-panel/application-resource/application-resource.service';
import {Counts} from "./Counts";
import {CountsRequest} from "./CountsRequest";
import {ComplaintStatus} from "../offer/complaint/complaint-status";
import {CreateOfferMode} from '../offer/offers/create-offer/create-offer-mode';
import {News} from '../settings/news/news';
import {NewsService} from '../settings/news/news-service';
import {NewsViewMode} from "../settings/news/newsViewMode";
import {PromotionInformation} from '../settings/promotion-information/promotion-information';
import {PromotionInformationService} from '../settings/promotion-information/promotion-information.service';
import {SubsystemService} from '../subsystem/subsystem.service';
import {UserService} from "../user/user.service";
import {TranslatedSelectItem} from "../../common/service/translated.select.item";

declare const jQuery: any;

export interface QuickMenuButton {
    id: string;
    label: string;
    info: string;

    display(): boolean;

    action(): void;

    count?(): number;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css', '../shared-styles.css'],
    providers: [DataService, UserService, NewsService, SubsystemService, PromotionInformationService]
})
export class HomeComponent implements OnInit, OnDestroy {

    private static readonly VIEW_NAME = 'HomeComponent';
    private static readonly SAVED_CONFIG = {
        QUICK_MENU_ITEMS: 'quickMenuItems',
        SHOW_REPORTS: 'showReports'
    };

    readonly RECOMMENDED_SCREEN_WIDTH = 1920;
    readonly RECOMMENDED_SCREEN_HEIGHT = 1080;

    quickMenuButtons: QuickMenuButton[][];
    countsRequest: CountsRequest = new CountsRequest();
    counts: Counts = new Counts();

    showNews = false;
    news: News;

    menuType = MenuType;
    langMenuElements: MenuElement[] = [];

    promotionInformation: PromotionInformation;
    promoShortTime = true;
    promoFoldCorner = FoldCorner.TOP_LEFT;
    promoFoldColor: string;
    quickMenuInfoButtonClicked: QuickMenuButton;
    private readonly subscriptions = new Subscription();

    currentWeek: string;

    showReports = false;

    savedQuickMenuItems = [];
    quickMenuSelectItems: TranslatedSelectItem[] = [];
    defaultQuickMenuConfig = true;
    constructor(private storage: StorageService,
                private dataService: DataService,
                private router: Router,
                private permissions: Permissions,
                public translate: TranslateService,
                private fullscreenControllers: SidenavController,
                private newsService: NewsService,
                private userService: UserService,
                private route: ActivatedRoute,
                private promotionInformationService: PromotionInformationService,
                private subsystemService: SubsystemService,
                private userUiConfigService: UserUiConfigService,
                private errors: CommonErrorHandler,
                private changeDetector: ChangeDetectorRef) {
    }

    get backgroundImageUrl(): string {
        return ApplicationResourceService.getItemUrlByName(KnownApplicationResources.DASHBOARD_BACKGROUND);
    }

    get canSeeReports(): boolean {
        return (this.route.snapshot.data['features'] as ApplicationFeatures).enableProductionOrderStatistics &&
            (this.permissions.isKoordynator() || this.permissions.isOpiekun() || this.permissions.isOperator());
    }

    ngOnInit(): void {
        this.savedQuickMenuItems = this.userUiConfigService.getConfigForTheView(HomeComponent.VIEW_NAME,
            HomeComponent.SAVED_CONFIG.QUICK_MENU_ITEMS);
        this.defaultQuickMenuConfig = this.savedQuickMenuItems == undefined;

        this.fullscreenControllers.replaceContent(undefined);
        const showNewsRouteParam = this.route.snapshot.paramMap.get('showNews');
        if (showNewsRouteParam === 'true') {
            this.newsService.getCurrentNews().subscribe({
                next: news => {
                    this.news = news;
                    this.showNews = true;
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status !== 404) {
                        this.errors.handle(error);
                    }
                }
            });
        }
        this.promotionInformationService.getActivePromotions().subscribe(promotions => {
            this.promotionInformation = promotions[0];
        });
        this.prepareLangMenu();
        this.setupQuickMenu();
        this.loadMenuItemCounts();
        const venskaLogoClickHandler = () => {
            this.loadMenuItemCounts();
        };
        jQuery('#navigation-venska-logo').on('click', venskaLogoClickHandler);
        this.subscriptions.add(() => jQuery('#navigation-venska-logo').off('click', venskaLogoClickHandler));

        this.subscriptions.add(this.translate.stream('DASHBOARD.WEEK_LABEL').subscribe(weekLabel => {
            const now = moment();
            this.currentWeek = now.week() + " " + weekLabel + " " + now.year();
        }));
        if (this.canSeeReports) {
            this.showReports = this.userUiConfigService.getConfigForTheView(HomeComponent.VIEW_NAME, HomeComponent.SAVED_CONFIG.SHOW_REPORTS);
            if (this.showReports == undefined) {
                this.showReports = true;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goToComponentWithStatus(component: string, offerStatus: (OfferStatus | ComplaintStatus)[], filterByCurrentUser = true): void {
        this.router.navigate([AccessData.path.offer(), {
            statusFilter: offerStatus,
            component: component,
            ignoreStoredFilter: true,
            forCurrentUser: filterByCurrentUser
        }]);
    }

    goToOffersWithStatus(offerStatus: OfferStatus[], filterByCurrentUser = true): void {
        this.goToComponentWithStatus('offer', offerStatus, filterByCurrentUser);
    }

    goToOrdersWithStatus(offerStatus: OfferStatus[], filterByCurrentUser = true) {
        this.goToComponentWithStatus('order', offerStatus, filterByCurrentUser);
    }

    goToComplaintsWithStatus(complaintStatus: ComplaintStatus[], filterByCurrentUser = true) {
        this.goToComponentWithStatus('complaint', complaintStatus, filterByCurrentUser);
    }

    goToPaymentPackagesWithStatus(offerStatus: OfferStatus[], filterByCurrentUser = true): void {
        this.goToComponentWithStatus('payment-package', offerStatus, filterByCurrentUser);
    }

    isBelowRecommendedResolution(): boolean {
        const width = screen.width * window.devicePixelRatio;
        const height = screen.height * window.devicePixelRatio;
        return width < this.RECOMMENDED_SCREEN_WIDTH || height < this.RECOMMENDED_SCREEN_HEIGHT;
    }

    isNan(value: number): boolean {
        return Number.isNaN(value);
    }

    toggleReports(): void {
        this.showReports = !this.showReports;
        this.userUiConfigService.saveConfigForTheView('HomeComponent', 'showReports', this.showReports);
    }

    getNewsTitle(): string {
        return this.news.title[this.storage.get(StorageKey.INTERFACE_LANGUAGE)];
    }

    getNewsContent(): string {
        return this.news.content[this.storage.get(StorageKey.INTERFACE_LANGUAGE)];
    }

    remindNewsLater() {
        this.showNews = false;
    }

    confirmNews() {
        this.userService.markNewsAsRead(this.news.id).subscribe(() => {
            this.showNews = false;
            this.changeDetector.markForCheck();
        });
    }

    openSelectedNews(newsId: number) {
        this.newsService.getItem(newsId, NewsViewMode.MEDIUM).subscribe((news: News) => {
            this.news = news;
            this.showNews = true;
        });
    }

    handleShortTimeRemaining(isShortTime: boolean) {
        this.promoShortTime = isShortTime;
        this.promoFoldColor = this.promoShortTime ? '#AB373C' : '#16229e';
        this.changeDetector.detectChanges();
    }

    private setupQuickMenu(): void {
        this.quickMenuButtons = [];
        if (this.permissions.isKoordynator() || this.permissions.isOpiekun()) {
            this.setupQuickMenuForOpiekunAndKoordynator();
        } else if (this.permissions.isOperator()) {
            this.setupQuickMenuForOperator();
            this.subsystemService.currentUserSubsystemHasPartners().subscribe(
                isWebshopPartner => {
                    if (isWebshopPartner) {
                        if (this.defaultQuickMenuConfig) {
                            this.savedQuickMenuItems.push('PARTNER_NEW_OFFER');
                        }
                        let webshopPartnerButton = this.createOffersMenuButton([OfferStatus.PARTNER_NEW_OFFER], false);
                        const extraGroup = [];
                        this.addQuickMenuButtonWithConfigSetup(extraGroup, webshopPartnerButton);
                        this.quickMenuButtons = [...this.quickMenuButtons, extraGroup];
                        this.loadMenuItemCounts();
                    }
                }
            );
        } else if (this.permissions.isHandlowiec() || this.permissions.isSprzedawca()) {
            this.setupQuickMenuForHandlowiecOrSprzedawca();
        }
    }

    private setupQuickMenuForOpiekunAndKoordynator(): void {
        if (!this.savedQuickMenuItems) {
            this.savedQuickMenuItems = ['CREATE_PAYMENT_PACKAGE', 'TO_REVIEW', 'TO_VERIFY', 'VERIFIED', 'COMPLAINT_EXAMINATION_IN_PROGRESS_TO_VERIFY', 'WAITING_FOR_PAYMENT', 'PAYMENT_PACKAGE_NOT_FULLY_PRINTED'];
        }
        let firstGroup = [];
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createAddPaymentPackageMenuButton());
        let secondGroup = [];
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOffersMenuButton([OfferStatus.TO_REVIEW]));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOffersMenuButton([OfferStatus.TO_VERIFY, OfferStatus.VERIFICATION_APPROVED]));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOrdersMenuButton(OfferStatus.VERIFIED));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createComplaintsMenuButton([ComplaintStatus.EXAMINATION_IN_PROGRESS, ComplaintStatus.TO_VERIFY]));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createPaymentPackagesMenuButton([OfferStatus.WAITING_FOR_PAYMENT]));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createUnprintedPaymentPackagesMenuButton());
        this.quickMenuButtons = [firstGroup, secondGroup];
    }

    private setupQuickMenuForOperator(): void {
        if (!this.savedQuickMenuItems) {
            this.savedQuickMenuItems = ['CREATE_OFFER', 'NEW_OFFER', 'NEW_WEBSHOP_OFFERS', 'TO_SEND', 'MY_CLIENTS', 'REVIEWED', 'OPERATOR_TO_VERIFY__VERIFICATION_APPROVED', 'VERIFIED', 'VERIFICATION_FOR_APPROVAL', 'COMPLAINT_TO_REVISE', 'WAITING_FOR_PAYMENT'];
        }
        let firstGroup = [];
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createAddOfferMenuButton());
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createOffersMenuButton([OfferStatus.NEW_OFFER]));
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createNewWebshopOffersMenuButton());
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createOffersMenuButton([OfferStatus.TO_SEND], false));
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createShowClientsMenuButton());
        let secondGroup = [];
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOffersMenuButton([OfferStatus.REVIEWED], false));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOffersMenuButton([OfferStatus.TO_VERIFY, OfferStatus.VERIFICATION_APPROVED], false, 'OPERATOR_TO_VERIFY__VERIFICATION_APPROVED'));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOrdersMenuButton(OfferStatus.VERIFIED));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createOffersMenuButton([OfferStatus.VERIFICATION_FOR_APPROVAL], false));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createComplaintsMenuButton([ComplaintStatus.TO_REVISE]));
        this.addQuickMenuButtonWithConfigSetup(secondGroup, this.createPaymentPackagesMenuButton([OfferStatus.WAITING_FOR_PAYMENT]));
        this.quickMenuButtons = [firstGroup, secondGroup];
    }

    private setupQuickMenuForHandlowiecOrSprzedawca(): void {
        if (!this.savedQuickMenuItems) {
            this.savedQuickMenuItems = ['CREATE_OFFER', 'NEW_OFFER', 'NEW_WEBSHOP_OFFERS', 'TO_REVISE', 'MY_CLIENTS'];
        }
        let firstGroup = [];
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createAddOfferMenuButton());
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createOffersMenuButton([OfferStatus.NEW_OFFER]));
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createNewWebshopOffersMenuButton());
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createOffersMenuButton([OfferStatus.TO_REVISE]));
        this.addQuickMenuButtonWithConfigSetup(firstGroup, this.createShowClientsMenuButton());
        this.quickMenuButtons = [firstGroup];
    }

    private addQuickMenuButtonWithConfigSetup(groupToAdd: any[], buttonToAdd: QuickMenuButton) {
        if (!this.savedQuickMenuItems || this.savedQuickMenuItems.some(item => item === buttonToAdd.id)) {
            groupToAdd.push(buttonToAdd);
        }
        if (!this.quickMenuSelectItems.some(item => item.value === buttonToAdd.id)) {
            this.quickMenuSelectItems.push({label: null, labelKey: buttonToAdd.label, value: buttonToAdd.id});
        }
    }

    private createAddOfferMenuButton(): QuickMenuButton {
        return this.createMenuButton('CREATE_OFFER', () => this.router.navigate(['/features/create-offer', {mode: CreateOfferMode.NEW}]));
    }

    private createShowClientsMenuButton(): QuickMenuButton {
        return this.createMenuButton('MY_CLIENTS', () => this.router.navigate([AccessData.path.client()]));
    }

    private createAddPaymentPackageMenuButton(): QuickMenuButton {
        return this.createMenuButton('CREATE_PAYMENT_PACKAGE',
            () => this.router.navigate([AccessData.path.offer(), {component: 'payment-package', mode: 'new'}]));
    }

    private createOffersMenuButton(statuses: OfferStatus[], filterByCurrentUser = true, labelOverrideKey?: string): QuickMenuButton {
        statuses.forEach(status => this.countsRequest.offerCountsByStatus[status] = filterByCurrentUser);

        return this.createMenuButton(
            labelOverrideKey || OfferStatus[statuses[0]],
            () => this.goToOffersWithStatus(statuses, filterByCurrentUser),
            () => statuses.map(status => this.counts.offerCountsByStatus[status]).reduce((total, count) => total + count, 0)
        );
    }

    private createOrdersMenuButton(status: OfferStatus, filterByCurrentUser = true): QuickMenuButton {
        this.countsRequest.offerCountsByStatus[status] = filterByCurrentUser;
        return this.createMenuButton(
            OfferStatus[status],
            () => this.goToOrdersWithStatus([status], filterByCurrentUser),
            () => this.counts.offerCountsByStatus[status]
        );
    }

    private createNewWebshopOffersMenuButton(): QuickMenuButton {
        return this.createMenuButton('NEW_WEBSHOP_OFFERS',
            () => {
                this.router.navigate([AccessData.path.offer(), {
                    statusFilter: [OfferStatus.NEW_OFFER],
                    component: 'offer',
                    ignoreStoredFilter: true
                }], {
                    queryParams: {
                        filterByClientGroup: 'Webshop'
                    }
                });
            },
            () => this.counts.newWebshopOffers,
            () => this.counts.newWebshopOffers != undefined);
    }

    private createComplaintsMenuButton(statuses: ComplaintStatus[], filterByCurrentUser = true): QuickMenuButton {
        statuses.forEach(status => this.countsRequest.complaintCountsByStatus[status] = filterByCurrentUser);
        return this.createMenuButton(
            statuses.reduce((label, status) => label + '_' + status, 'COMPLAINT'),
            () => this.goToComplaintsWithStatus(statuses, filterByCurrentUser),
            () => statuses.map(status => this.counts.complaintCountsByStatus[status]).reduce((total, count) => total + count, 0)
        );
    }

    private createPaymentPackagesMenuButton(statuses: OfferStatus[], filterByCurrentUser = true,
                                            labelOverrideKey?: string): QuickMenuButton {
        statuses.forEach(status => this.countsRequest.paymentPackageCountsByStatus[status] = filterByCurrentUser);

        return this.createMenuButton(
            labelOverrideKey || OfferStatus[statuses[0]],
            () => this.goToPaymentPackagesWithStatus(statuses, filterByCurrentUser),
            () => statuses.map(status => this.counts.paymentPackageCountsByStatus[status]).reduce((total, count) => total + count, 0)
        );
    }

    private createUnprintedPaymentPackagesMenuButton() {
        return this.createMenuButton(
            'PAYMENT_PACKAGE_NOT_FULLY_PRINTED',
            () => {
                this.router.navigate([AccessData.path.offer(), {
                    statusFilter: [OfferStatus.PAID],
                    unprintedPaymentPackages: true,
                    component: 'payment-package',
                    ignoreStoredFilter: true,
                    forCurrentUser: true
                }]);
            },
            () => this.counts.unprintedPaymentPackages,
            () => this.counts.unprintedPaymentPackages != undefined
        );
    }

    private createMenuButton(label: string, action: () => void, count?: () => number,
                             display: () => boolean = () => true): QuickMenuButton {
        return {
            id: label,
            label: 'HOME.QUICK_MENU.' + label,
            info: 'HOME.QUICK_MENU_INFO.' + label,
            display: display,
            action: action,
            count: count
        };
    }

    private loadMenuItemCounts(): void {
        this.dataService.getDashboardCounts(this.countsRequest).subscribe(counts => {
            this.counts = counts;
        });
    }

    private prepareLangMenu(): void {
        this.langMenuElements = [];
        this.langMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.LANGUAGE_SELECTOR)
            .build());
    }

    public showQuickMenu(selectedItems) {
        this.defaultQuickMenuConfig = false;
        this.savedQuickMenuItems = selectedItems;
        this.userUiConfigService.saveConfigForTheView(HomeComponent.VIEW_NAME, HomeComponent.SAVED_CONFIG.QUICK_MENU_ITEMS, selectedItems);
        this.setupQuickMenu();
    }
}
