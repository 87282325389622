<app-wizard header="{{ getHeader() | translate }}" (onCancel)="exitWizard()" (onComplete)="save()">
    <app-wizard-step *ngIf="mode !== PaletteWizardMode.EDIT_CONTENT"
                     label="{{ 'PALETTE.FORM.STEPS.DETAILS' | translate }}" [id]="STEPS.DETAILS.id"
                     [validate]="STEPS.DETAILS.validator">
        <app-palette-form [palette]="palette" [validationErrors]="validationErrors"></app-palette-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="mode !== PaletteWizardMode.EDIT_DATA"
                     label="{{ 'PALETTE.FORM.STEPS.PRODUCTION_ORDERS' | translate }}" [id]="STEPS.PRODUCTION_ORDERS.id"
                     [validate]="STEPS.PRODUCTION_ORDERS.validator">
        <app-palette-production-orders #orderSelection [deliveryDataAll]="deliveryData" [paletteId]="palette.id" [deliveryListId]="deliveryListId"
                                       [validationErrors]="validationErrors"></app-palette-production-orders>
    </app-wizard-step>
</app-wizard>
