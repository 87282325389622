import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Listing} from '../../../common/crud-common/crudItemList';
import {OfferCounterReportModel} from '../offer-counter-report.model';

@Injectable()
export class OfferSupplierCounterService {

    constructor(private http: HttpClient) {
    }

    countOrdersBySupplierAndYear(): Observable<Listing<OfferCounterReportModel>> {
        return this.http.get<Listing<OfferCounterReportModel>>('offerReportApi/ordersBySupplierByYear');
    }
}
