import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowParams} from "../painters/WindowParams";
import {ErrorNames} from "./ErrorNames";
import {WindowEditorField} from "../../app/features/offer/window-editor/window-editor-field";

export class SidebarIdGenerator {

    public static readonly tabNames = {
        mullions: 'mullions',
        filling: 'filling',
        muntins: 'muntins'
    };

    public static tabName(areaIndex: number, subTabName: string): string {
        let name = {area: areaIndex, subname: subTabName};
        return JSON.stringify(name);
    }

    public static mullionId(subwindow: SubWindowData, mullionNumber: number): string {
        let points = subwindow.points;
        return "mullion_" + points[0] + "_" + points[1] + "_" + mullionNumber;
    }

    public static muntinId(windowNum: number, areaNum: number, grillNum: number) {
        return windowNum + '_area_' + areaNum + '_grill_' + grillNum;
    }

    public static ventilationId(subwindow: SubWindowData) {
        let points = subwindow.points;
        return WindowParams.VENTILATION_ELEM + "_" + points[0] + "_" + points[1];
    }

    public static getGeneralTabNameForSelectedElementsType(type: WindowParams, isTerrace = false) {
        switch (type) {
            case WindowParams.FRAME_ELEM:
            case WindowParams.WING_ELEM:
                return 'colorSectionAccordion';
            case WindowParams.GLAZING_BEAD_ELEM:
            case WindowParams.GLASS_ELEM:
                return isTerrace ? 'mainSectionAccordion' : 'fillingAccordion';
            case WindowParams.HANDLE_ELEM:
            case WindowParams.VENTILATION_ELEM:
                return 'mainSectionAccordion';
            case WindowParams.MULLION_ELEM:
                return 'mullionAccordion';
            case WindowParams.MUNTIN_ELEM:
                return 'muntinAccordion';
            case WindowParams.FITTINGS_ELEM:
                return 'fittingsAccordion';
            default:
                let err = new Error("Wrong object reference type");
                err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
                throw err;
        }
    }

    public static getTabForInputElement(inputField: string): WindowParams {
        switch (inputField) {
            case WindowEditorField.BUSINESS_TYPE:
            case WindowEditorField.QUANTITY:
            case WindowEditorField.WEBSHOP_CHARGE_NAME:
            case WindowEditorField.WINDOW_SYSTEM:
            case WindowEditorField.WIDTH:
            case WindowEditorField.HEIGHT:
            case WindowEditorField.PROFILE:
            case WindowEditorField.WELD_TYPE:
            case WindowEditorField.UNDER_WINDOW_BEAD:
            case WindowEditorField.FRAME_ENHANCEMENT:
            case WindowEditorField.VIEW:
            case WindowEditorField.WINDOW_FUNCTION:
            case WindowEditorField.COVERS:
            case WindowEditorField.MILLINGS:
            case WindowEditorField.MILLINGS_NORWEGIAN:
            case WindowEditorField.HANDLES:
            case WindowEditorField.TERRACE_HANDLE:
            case WindowEditorField.TERRACE_HANDLE_LAYOUT:
            case WindowEditorField.DOORSTEP:
            case WindowEditorField.CHANNEL_SECTION:
            case WindowEditorField.CONSTRUCTIONAL_MULLION:
            case WindowEditorField.MOVABLE_POST:
            case WindowEditorField.UNDERWINDOW_PROFILE:
            case WindowEditorField.OPENING:
            case WindowEditorField.SEALS_EXTERNAL:
            case WindowEditorField.SEALS_INTERNAL:
            case WindowEditorField.TERRACE_GLAZING_PACKAGE:
            case WindowEditorField.TERRACE_COLOR:
            case WindowEditorField.FITTING_LOCK_TERRACE:
            case WindowEditorField.FITTING_LOCK_TERRACE_LOCATION:
                return WindowParams.HANDLE_ELEM;
            case WindowEditorField.CORE_COLOR:
            case WindowEditorField.EXTERNAL_COLOR:
            case WindowEditorField.EXTERNAL_COLOR_OTHER_INFO:
            case WindowEditorField.INTERNAL_COLOR:
            case WindowEditorField.INTERNAL_COLOR_OTHER_INFO:
                return WindowParams.FRAME_ELEM;
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
            case WindowEditorField.FILLING_WIDTH:
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
            case WindowEditorField.DECORATIVE_FILLING:
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
            case WindowEditorField.FILLING_INTERNAL_COLOR:
            case WindowEditorField.WEBSHOP_GLAZING_PACKAGE:
            case WindowEditorField.GLASS_SELECTOR:
            case WindowEditorField.GLAZING_BEAD:
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE:
                return WindowParams.GLASS_ELEM;
            case WindowEditorField.GRILL:
            case WindowEditorField.GRILL_COLOR:
                return WindowParams.MUNTIN_ELEM;
            case WindowEditorField.MULLION:
                return WindowParams.MULLION_ELEM;
            case WindowEditorField.FITTING_BRAKE:
            case WindowEditorField.FITTING_SLIDING:
            case WindowEditorField.FITTING_TYPE:
            case WindowEditorField.FITTING_ESPAGNOLETTE_TYPE:
            case WindowEditorField.FITTING_VERANDA:
            case WindowEditorField.FITTING_INSERTION:
            case WindowEditorField.FITTING_MAIN_INSERTION:
            case WindowEditorField.FITTING_ADDITIONAL_INSERTION:
            case WindowEditorField.FITTING_LOCK:
            case WindowEditorField.FITTING_AUTOMATIC_DRIVE:
                return WindowParams.FITTINGS_ELEM;
        }
        return null;
    }

    public static fieldExistsInSubwindow(field: string): boolean {
        switch (field) {
            case WindowEditorField.BUSINESS_TYPE:
            case WindowEditorField.DIMENSIONS:
            case WindowEditorField.VENTILATOR:
            case WindowEditorField.DRIP:
            case WindowEditorField.COUPLER:
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
            case WindowEditorField.FILLING_WIDTH:
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
            case WindowEditorField.DECORATIVE_FILLING:
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
            case WindowEditorField.FILLING_INTERNAL_COLOR:
            case WindowEditorField.GLASS_SELECTOR:
            case WindowEditorField.GLASS:
            case WindowEditorField.DISTANCE_FRAME:
            case WindowEditorField.GLAZING_BEAD:
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE:
            case WindowEditorField.MULLION:
            case WindowEditorField.GRILL:
                return true;
            default:
                return false;
        }
    }
}
