import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {PriceTableItem} from "./priceTableItem";

@JsonDeserializable
export class PriceTable {
    id: number;
    items: PriceTableItem[];
    calculatePriceByArea: boolean;
    useInterpolation: boolean;
    markOutdatedPricesWhenActivated: boolean;
    selectionAdditionalParam2: number;
    externalPricetableSymbol: string;
    externalPriceTableSizeAdjustment: number;
    lastModifiedDate: Date;
    lastNotificationDate: Date;

    static fromJSON(jsonObject: any): PriceTable {
        const priceTable = new PriceTable();
        priceTable.id = jsonObject['id'];
        if (jsonObject['items'] != undefined) {
            priceTable.items = jsonObject['items'].map(PriceTableItem.fromJSON);
        }
        priceTable.calculatePriceByArea = jsonObject['calculatePriceByArea'];
        priceTable.useInterpolation = jsonObject['useInterpolation'];
        priceTable.markOutdatedPricesWhenActivated = jsonObject['markOutdatedPricesWhenActivated'];
        priceTable.selectionAdditionalParam2 = jsonObject['selectionAdditionalParam2'];
        priceTable.externalPricetableSymbol = jsonObject['externalPricetableSymbol'];
        priceTable.externalPriceTableSizeAdjustment = jsonObject['externalPriceTableSizeAdjustment'];
        if (jsonObject['lastModifiedDate'] != undefined) {
            priceTable.lastModifiedDate = new Date(jsonObject['lastModifiedDate']);
        }
        if (jsonObject['lastNotificationDate'] != undefined) {
            priceTable.lastNotificationDate = new Date(jsonObject['lastNotificationDate']);
        }
        return priceTable;
    }
}
