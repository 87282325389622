import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {GraspGlazingPackage} from '../../../grasp-glazing-package/grasp-glazing-package';
import {WebshopGlazingPackage} from '../webshop-glazing-package';

@Component({
    selector: 'app-webshop-glazing-package-glazing-form',
    templateUrl: './webshop-glazing-package-glazing-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopGlazingPackageGlazingFormComponent implements OnChanges {

    @Input()
    item: WebshopGlazingPackage;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    readOnly = false;

    @Input()
    graspGlazingPackages: GraspGlazingPackage[];

    graspGlazingPackages1: SelectItem[];
    graspGlazingPackages2: SelectItem[];
    graspGlazingPackages3: SelectItem[];
    graspGlazingPackages4: SelectItem[];

    constructor(private readonly translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const graspGlazingPackagesChange = changes['graspGlazingPackages'];
        if (graspGlazingPackagesChange != undefined) {
            this.splitPackagesByGlassCount(graspGlazingPackagesChange.currentValue);
        }
    }

    private splitPackagesByGlassCount(packages: GraspGlazingPackage[]): void {
        if (packages == undefined) {
            this.graspGlazingPackages1 = [];
            this.graspGlazingPackages2 = [];
            this.graspGlazingPackages3 = [];
            this.graspGlazingPackages4 = [];
            return;
        }
        const createSelectItem = (ggp: GraspGlazingPackage): SelectItem => ({
            label: ggp.name[this.translate.currentLang],
            value: ggp.id
        });
        this.graspGlazingPackages1 = packages.filter(ggp => ggp.glazing.glazingGlassQuantity === 1).map(createSelectItem);
        this.graspGlazingPackages2 = packages.filter(ggp => ggp.glazing.glazingGlassQuantity === 2).map(createSelectItem);
        this.graspGlazingPackages3 = packages.filter(ggp => ggp.glazing.glazingGlassQuantity === 3).map(createSelectItem);
        this.graspGlazingPackages4 = packages.filter(ggp => ggp.glazing.glazingGlassQuantity === 4).map(createSelectItem);
    }
}
