import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {WindowDesignerCatalogDependentOption} from './data-form/window-designer-catalog-dependent-option';

@JsonDeserializable
export class WindowDesignerCatalogDependentOptionsSet implements CrudItem {
    id: number;
    windowSystemId: number;
    windowSystemName: MultilanguageFieldInterface;
    sortIndex = 1;
    name: string;
    active = true;
    options: WindowDesignerCatalogDependentOption[];

    static fromJSON(jsonObject: object): WindowDesignerCatalogDependentOptionsSet {
        const optionsSet = new WindowDesignerCatalogDependentOptionsSet();
        optionsSet.id = jsonObject['id'];
        optionsSet.windowSystemId = jsonObject['windowSystemId'];
        if (jsonObject['windowSystemName'] != undefined) {
            optionsSet.windowSystemName = MultilanguageField.fromJSON(jsonObject['windowSystemName']);
        }
        optionsSet.sortIndex = jsonObject['sortIndex'];
        optionsSet.name = jsonObject['name'];
        optionsSet.active = jsonObject['active'];
        optionsSet.options = [];
        return optionsSet;
    }
}
