import {WebshopAreaBarsCode} from '../../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {CrudItem, JsonDeserializable} from '../../../../common/crud-common/crudItem';

@JsonDeserializable
export class WebshopAreaBars implements CrudItem {
    id: number;
    webshopAreaBarsCode: WebshopAreaBarsCode;
    sortIndex: number;

    static fromJSON(jsonObject: object): WebshopAreaBars {
        const webshopAreaBars = new WebshopAreaBars();
        webshopAreaBars.id = jsonObject['id'];
        webshopAreaBars.webshopAreaBarsCode = WebshopAreaBarsCode[jsonObject['webshopAreaBarsCode'] as string];
        webshopAreaBars.sortIndex = jsonObject['sortIndex'];
        return webshopAreaBars;
    }
}
