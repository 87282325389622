<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.CLIENTS.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="permissions.isPermitted({roles:['ROLE_CREATE_CLIENT']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <app-simple-button *ngIf="!filterMine && !filterClientGroup" buttonId="toggle-mine-filter-button"
                                   label="{{ 'GENERAL.FILTER_SHOW_MINE' | translate }}"
                                   icon="filter_list" type="action" (onClick)="toggleFilterMine()"></app-simple-button>

                <app-simple-button *ngIf="!permissions.isPermitted({roles:['ROLE_SPRZEDAWCA']}) && (filterMine || filterClientGroup)"
                                   buttonId="show-all-filter-button"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action" (onClick)="showAll()"></app-simple-button>

                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.CLIENTS.LIST')"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" [displaySelectedLabel]="false"
                               id="clientsShownColumns" [options]="columnOptions | selectItemTranslate"
                               [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>
                <div class="side-actions">
                    <app-search [component]="'client-search'" (searched)="handleClientSearch($event)"></app-search>
                    <app-simple-button buttonId="addClient" label="{{ 'NAVIGATION.ITEMS.CLIENTS.ADD-CLIENT' | translate }}"
                                       icon="add" type="main-action" [size]="40" [hotkey]="newElementHotkey"
                                       (onClick)="redirectToCreateClientComponent()"></app-simple-button>
                </div>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="clients" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 [(selection)]="selectedRow" (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 id="clientGroupsTable">
        <!-- ACTIONS -->
        <p-column header="{{ 'CLIENT-DETAILS.FORM.ACTIONS' | translate }}" [sortable]="false">
            <ng-template let-client="rowData" pTemplate="body">
                <div class="table-row-action-buttons">
                    <app-simple-button *ngIf="client.active" buttonId="show" icon="add" type="main-action" [size]="36"
                                       (onClick)="actionOnClick('ADD_OFFER', client)"></app-simple-button>
                    <app-simple-button *ngIf="!client.active" buttonId="show" icon="search" type="main-action" [size]="36"
                                       (onClick)="actionOnClick('GO_OFFER', client)"></app-simple-button>
                    <button-with-menu [buttonId]="'more_' + client.id" rightButtonIcon="more_horiz" buttonType="filter"
                                      [buttonSize]="36" [menuType]="menuType.BOTTOM_RIGHT"
                                      [menuElements]="buildRowActions(client)"
                                      (menuElementSelected)="handleRowAction($event)"></button-with-menu>
                </div>
            </ng-template>
        </p-column>

        <ng-container *ngFor="let column of columns">
            <p-column *ngIf="column.field == 'active'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [filterValues]="column.filterValues | selectItemTranslate"
                      [defaultFilterValue]="defaultActiveFilter">
                <ng-template let-client="rowData" pTemplate="body">
                    {{(client.active ? 'GENERAL.ONLY_ACTIVE' : 'GENERAL.ONLY_INACTIVE') | translate}}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'address.country'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      [filterValues]="filterCountry | async" [defaultFilterValue]="column.defaultFilterValue">
                <ng-template pTemplate="body" let-client="rowData">
                    {{ (client.address.country != undefined ? 'COUNTRIES.' + client.address.country : '') | translate }}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field == 'groupId'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="true" [filter]="column.filter"
                      [filterValues]="column.filterValues" [defaultFilterValue]="column.defaultFilterValue">

                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="existingGroupSelectItems" (onChange)="loadItemsLazy(lastLoadEvent)"
                                       defaultLabel="{{ 'CLIENT-DETAILS.FORM.CLIENT-GROUP' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false"
                                       [(ngModel)]="selectedGroups"></p-multiSelect>
                    </div>
                </ng-template>

                <ng-template let-client="rowData" pTemplate="body">
                    {{ client.groupName }}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field == 'createdDate'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="true" [filter]="column.filter"
                      filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">
                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
                </ng-template>
                <ng-template let-client="rowData" pTemplate="body">
                    {{ client.createdDate | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>

            <p-column
                    *ngIf="column.field != 'active' && column.field != 'actions' && column.field != 'address.country' && column.field != 'groupId' && column.field != 'createdDate'"
                    [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                    [filter]="column.filter" filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue"></p-column>
        </ng-container>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-edit-client *ngIf="editedClient" [client]="editedClient" [existingGroups]="existingGroups"
                 (onSubmit)="editClientComponentClose($event)"></app-edit-client>
