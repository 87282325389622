import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {AddonCategory} from "./addon-category";

@Injectable()
export class AddonCategoryService implements CrudService<AddonCategory> {

    static readonly API_URL = 'addonCategory';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<AddonCategory>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<AddonCategory>>(AddonCategoryService.API_URL, {params: params});
    }

    getItem(itemId: number): Observable<AddonCategory> {
        return this.http.get<AddonCategory>(`${AddonCategoryService.API_URL}/${itemId}`);
    }

    addItem(item: AddonCategory): Observable<number> {
        return this.save(item);
    }

    editItem(itemId: number, item: AddonCategory): Observable<number> {
        return this.save(item);
    }

    delete(item: AddonCategory): Observable<void> {
        return this.http.delete<void>(`${AddonCategoryService.API_URL}/${item.id}`);
    }

    save(item: AddonCategory): Observable<number> {
        return this.http.post(AddonCategoryService.API_URL, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(AddonCategoryService.API_URL, response)));
    }

    validate(option: AddonCategory): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${AddonCategoryService.API_URL}/validate`, option));
    }
}
