import {ArcCutData} from "./ArcCutData";
import {CutDataType} from "./CutDataType";
import {LineCutData} from "./LineCutData";
import {RandomIdGenerator} from "../utils/RandomIdGenerator";

export abstract class CutData {
    generatedId: string;
    type: CutDataType;

    constructor(type: CutDataType) {
        this.generatedId = RandomIdGenerator.generate();
        this.type = type;
    }

    static isLine(cutData: CutData): cutData is LineCutData {
        return cutData.type === CutDataType.LINE;
    }

    static isArc(cutData: CutData): cutData is ArcCutData {
        return cutData.type === CutDataType.ARC;
    }
}
