import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../../common/crud-common/crud.service';
import {Listing} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {DealerDiscountPerConfigurableAddon} from './dealer-discount-per-configurable-addon';

@Injectable()
export class DealerDiscountPerConfigurableAddonService implements CrudService<DealerDiscountPerConfigurableAddon> {

    private static readonly API_URL = 'dealerDiscountPerConfigurableAddon';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: any,
             sortColumn: string, sortOrder: number): Observable<Listing<DealerDiscountPerConfigurableAddon>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(DealerDiscountPerConfigurableAddonService.API_URL, {params: params}).pipe(
            map(response => Listing.fromJSON(DealerDiscountPerConfigurableAddon, response)));
    }

    getItem(itemId: number): Observable<DealerDiscountPerConfigurableAddon> {
        return undefined;
    }

    addItem(item: DealerDiscountPerConfigurableAddon): Observable<number> {
        return this.http.post<void>(DealerDiscountPerConfigurableAddonService.API_URL, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(DealerDiscountPerConfigurableAddonService.API_URL, response)));
    }

    editItem(itemId: number, item: DealerDiscountPerConfigurableAddon): Observable<number> {
        return this.http.put<void>(`${DealerDiscountPerConfigurableAddonService.API_URL}/${itemId}`, item).pipe(
            map(() => itemId));
    }
}
