import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {SelectItemImpl} from '../../../common/service/select.item.impl';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {WindowSystemDefinition} from '../window-system-definition/window-system-definition';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {WebshopSale} from './webshop-sale';
import {WebshopSaleService} from './webshop-sale.service';

@Component({
    selector: 'app-webshop-sale',
    templateUrl: './webshop-sale.component.html',
    providers: [WebshopSaleService, DataServiceHelper, WindowSystemDefinitionService]
})
export class WebshopSaleComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    readonly STEPS = {
        DATA: 'DATA'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateForm()
    };

    itemList: WebshopSale[];
    item: WebshopSale;
    selectedItem: WebshopSale;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayDialog = false;
    newItem: boolean;
    userLang: string;

    availableSystems: SelectItem[] = [];
    windowSystems: WindowSystemDefinition[] = [];
    maxValidFrom: Date;
    minValidTo: Date;

    @ViewChild('dt') datatable;

    constructor(private systemService: WindowSystemDefinitionService,
                private webshopSaleService: WebshopSaleService,
                private errorHandler: CommonErrorHandler,
                public translate: TranslateService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopSaleComponent', false);
        this.initDefaultSortOrder();
        this.userLang = this.translate.currentLang;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "windowSystemName";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initShownColumns();
        this.loadActiveWindowSystems();
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.webshopSaleService.getItems(event.first, event.rows, event.filters, event.sortField,
            event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WebshopSaleComponent `getPage` success:', data);
                    this.itemList = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.itemList, event);
                },
                error: error => {
                    console.error('WebshopSaleComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WebshopSaleComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }

        if (!this.validationErrorsPresent()) {
            this.setSaveInProgress(true);
            this.webshopSaleService.saveItem(this.item).pipe(finalize(() => this.setSaveInProgress(false)))
                .subscribe({
                    next: () => {
                        this.reloadDatatable();
                        this.showSuccessMessage();
                        this.cancel();
                    },
                    error: error => {
                        this.validationErrors = this.errorHandler.handle(error);
                        this.changeDetector.markForCheck();
                        console.error('WebshopSaleComponent `submit` error:', error);
                    }
                });
        }
    }

    validateForm(): Observable<boolean> {
        this.validationErrors = {};

        if (this.item.windowSystemId == undefined) {
            this.validationErrors['windowSystemId'] = 'error.webshopSaleDto.windowSystemId.not_null';
        }

        this.validationErrors['rabate'] = MultiValidator.of('error.webshopSaleDto.rabate')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(1, 100, true, false).validate(this.item.rabate);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.changeDetector.markForCheck();
            return of(false);
        }

        return of(true);
    }

    showSuccessMessage() {
        if (this.newItem) {
            this.growlMessageController.info('WEBSHOP_SALE.CREATED');
        } else {
            this.growlMessageController.info('WEBSHOP_SALE.UPDATED');
        }
    }

    cancel() {
        this.newItem = false;
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedItem);
    }

    setDisplayDialog(display: boolean): void {
        this.validationErrors = {};
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    onRowSelect(event) {
        this.webshopSaleService.getItem(event.data.id).subscribe({
            next: data => {
                this.item = data;
                this.maxValidFrom = this.item.validTo;
                this.minValidTo = this.item.validFrom;

                this.onModelChange(this.item.windowSystemId);
                this.setDisplayDialog(true);
                this.keepSelectedItemIndex(event);
                this.changeDetector.markForCheck();
            },
            error: error => this.errorHandler.handle(error)
        });
    }

    showDialogToAdd(): void {
        this.validationErrors = {};
        this.item = new WebshopSale();
        this.maxValidFrom = null;
        this.minValidTo = null;
        this.newItem = true;

        if (this.item.windowSystemId == undefined) {
            this.item.windowSystemId = this.availableSystems[0].value;
        }
    }

    onModelChange(modelId: number) {
        this.item.windowSystemId = modelId;
    }

    private loadActiveWindowSystems() {
        const filters = {active: {value: 'true'}, includeRoofSystems: {value: 'false'}};
        return this.systemService.getSystems(0, 0, filters, null, null).subscribe({
            next: data => {
                this.windowSystems = data.data;
                this.availableSystems = this.windowSystems.map(system => new SelectItemImpl(system.names[this.userLang], system.id));
            },
            error: error => {
                this.errorHandler.handle(error);
            },
            complete: () => {
                console.info('WebshopSaleComponent `getWindowSystems` completed!');
            }
        });
    }

    setValidFrom(date: Date) {
        this.item.validFrom = date;
        this.minValidTo = this.item.validFrom;

    }

    setValidTo(date: Date) {
        this.item.validTo = date;
        this.maxValidFrom = this.item.validTo;
    }
}
