<ng-container *ngIf="tempVeneer.active || isMullionEligible(mullion)">
    <app-checkbox [inputId]="'veneer-active_' + mullion.generatedId" label="{{ 'OFFER.DRAWING.VENEER.ADD_VENEER' | translate }}"
                  [(ngModel)]="tempVeneer.active" [disabled]="readOnlyMode || !isMullionEligible(mullion)"
                  (onFocus)="onFocus.emit($event)" (ngModelChange)="afterChange()"></app-checkbox>
    <ng-container *ngIf="tempVeneer.active">
        <div class="new-form-row">
            <app-select inputId="veneer-system" label="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}"
                        [options]="windowSystemsOptions" [ngModel]="tempVeneer.systemId" [disabled]="readOnlyMode"
                        (ngModelChange)="handleWindowSystemChange($event)" (onFocus)="onFocus.emit($event)"></app-select>
        </div>
        <div class="new-form-row">
            <app-select inputId="veneer-frame" label="{{ 'OFFER.TABS.SECTION.MAIN.PROFILE' | translate }}"
                        [options]="framesOptions" [(ngModel)]="tempVeneer.frameId" (ngModelChange)="afterChange()"
                        (onFocus)="onFocus.emit($event)" [disabled]="readOnlyMode"></app-select>
        </div>
        <div class="new-form-row">
            <app-select inputId="veneer-type" label="{{ 'OFFER.DRAWING.VENEER.WINDOW_TYPE.LABEL' | translate }}"
                        [options]="windowTypeOptions" [(ngModel)]="tempVeneer.isWinged" (ngModelChange)="afterChange()"
                        [translateLabels]="true" (onFocus)="onFocus.emit($event)" [disabled]="readOnlyMode"></app-select>
        </div>
        <div class="new-form-row">
            <app-input-number [inputId]="'veneer-height-' + mullion.generatedId" [disabled]="readOnlyMode"
                              label="{{ 'OFFER.DRAWING.VENEER.HEIGHT' | translate }}" [min]="0"
                              [(ngModel)]="tempVeneer.distance" (onFocus)="onFocus.emit($event)"
                              (onBlur)="afterChange()" [required]="true"></app-input-number>
        </div>
        <div *ngIf="distanceOutOfBounds" class="new-form-row new-form-field-error-message">
            {{'OFFER.DRAWING.VENEER.ERROR.OUT_OF_BOUNDS' | translate}}
        </div>
    </ng-container>
</ng-container>
