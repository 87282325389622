<ng-container *ngIf="editedAddress == undefined">
    <div class="new-form-600">
        <div class="new-form-row">
            <app-subsystem-address-list [addresses]="addresses" (editAddress)="handleEdit($event)"
                                        (deleteAddress)="handleDelete($event)" [selectedAddress]="selectedAddress"
                                        (selectedAddressChange)="handleSelect($event)" [canEdit]="canEdit"
                                        [selectable]="selectable" [usedAddresses]="usedAddresses"
                                        [validationErrors]="validationErrors"></app-subsystem-address-list>
        </div>
        <div *ngIf="canEdit" class="new-form-row">
            <app-simple-button buttonId="subsystem-address-list-add"
                               label="{{ 'SUBSYSTEM-DETAILS.FORM.ADD_NEW_ADDRESS' | translate }}" type="action"
                               [size]="32" (onClick)="createNewAddress()"></app-simple-button>
            <div class="new-form-field-error-message" *ngIf="addresses && addresses.length == 0 && validationErrors['addresses'] != undefined">
                {{ validationErrors['addresses'] | translate }}
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="editedAddress != undefined">
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-text inputId="editedAddress.street" label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}"
                            [maxlength]="100" [required]="true"
                            [(validationMessageKey)]="editedAddressValidationErrors['street']"
                            [(ngModel)]="editedAddress.address.street"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="editedAddress.city" label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}"
                            [maxlength]="50" [required]="true"
                            [(validationMessageKey)]="editedAddressValidationErrors['city']"
                            [(ngModel)]="editedAddress.address.city"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-select inputId="editedAddress.country" label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}"
                        [required]="true" [options]="countries | async"
                        [(validationMessageKey)]="editedAddressValidationErrors['country']"
                        [(ngModel)]="editedAddress.address.country"></app-select>
        </div>
        <div class="new-form-row">
            <app-input-zip inputId="editedAddress.zip" label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}"
                           [disabled]="editedAddress.address.country == undefined" [required]="true"
                           [(validationMessageKey)]="editedAddressValidationErrors['zip']"
                           [country]="editedAddress.address.country"
                           [(ngModel)]="editedAddress.address.zip"></app-input-zip>
        </div>
        <div class="new-form-row">
            <div class="subsystem-address-list-edit-controls">
                <app-simple-button buttonId="subsystem-address-list-cancel" label="{{ 'GENERAL.CANCEL' | translate }}"
                                   type="cancel" [size]="32" (onClick)="editedAddress = undefined"></app-simple-button>
                <app-simple-button buttonId="subsystem-address-list-save" label="{{ 'GENERAL.ADD' | translate }}"
                                   type="main-action" [size]="32" (onClick)="handleSaveAddress()"></app-simple-button>
            </div>
        </div>
    </div>
</ng-container>
<!-- this exists to show validation failed marker in dialog tabs -->
<div style="display: none">
    <app-input-text #dialogTabValidationMarker [(validationMessageKey)]="dialogTabValidationError"></app-input-text>
</div>
