import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Permissions} from '../../../../auth/permission.service';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {ValidationErrors} from "../../../../common/validation-errors";
import {ProfileField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {ProfileFieldUsage} from "../../catalog-field-usage";
import {Profile} from '../profile';

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileFormComponent {

    @Input() item: Profile;
    @Input() validationErrors: ValidationErrors;
    @Input() types: SelectItem[];
    @Input() file: File;
    @Input() editPermits: FieldLimitation[] = [];
    @Output() readonly fileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: ProfileFieldUsage;
    ProfileField = ProfileField;

    constructor(public permissions: Permissions) {
        this.fieldUsage = new ProfileFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
