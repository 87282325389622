import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {WindowSystemMaterialLinks} from '../single-system-checkbox-crud/window-system-material-links';
import {WindowSystemsGlazingBeadsList} from '../window-system-definition/window-system-definition';
import {GlazingBead} from './glazing-bead';

@Injectable()
export class GlazingBeadService extends SingleSystemCheckboxCrudService<GlazingBead> {

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<Listing<GlazingBead & WindowSystemMaterialLinks>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GlazingBead & WindowSystemMaterialLinks>>('glazing-beads', {params: params});
    }

    getAllActive(): Observable<WindowSystemsGlazingBeadsList> {
        return this.http.get<WindowSystemsGlazingBeadsList>('glazing-beads/allActive');
    }

    saveItem(item: GlazingBead, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('glazingBeadDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post('glazing-bead', formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('glazing-bead', response)));
    }

    getItem(itemId: number): Observable<GlazingBead> {
        return this.http.get<GlazingBead>('glazing-bead/' + itemId);
    }

    addItem(item: GlazingBead): Observable<number> {
        return this.saveItem(item, undefined);
    }

    editItem(itemId: number, item: GlazingBead): Observable<number> {
        return this.saveItem(item, undefined);
    }

    getImage(itemId: number): Observable<string> {
        return this.http.get(`glazing-bead/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`glazing-bead/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    copy(id: number, item: GlazingBead, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('glazingBeadDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post('glazing-bead/' + id + '/copy', formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('glazing-bead', response)));
    }

    validate(frame: GlazingBead): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('glazing-bead/validate', frame));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('glazing-beads/names', {params: params});
    }
}
