import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {ColorInterface, getColorFormattedNameWithGroup} from '../../../../window-designer/catalog-data/color-interface';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, OtherFillingField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {OtherFillingFieldUsage} from "../catalog-field-usage";
import {Color} from '../color/color';
import {ColorService} from '../color/color.service';
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {OtherFillingService} from './other-filling.service';
import {OtherFilling} from './otherFilling';
import {OtherFillingWithMaterials} from './otherFillingWithMaterials';

@Component({
    selector: 'app-other-filling',
    templateUrl: './other-filling.component.html',
    styleUrls: ['./other-filling.component.css', '../../shared-styles.css'],
    providers: [OtherFillingService, DataServiceHelper, WindowSystemDefinitionService, ColorService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherFillingComponent extends SingleSystemCheckboxCrudComponent<OtherFilling, OtherFillingService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    item: OtherFilling;
    availableInsideColors: SelectItem[];
    availableOutsideColors: SelectItem[];
    availableCoreColors: SelectItem[];
    private allActiveColors: Observable<ColorInterface[]>;

    @ViewChild('dt') datatable;

    validateDataStep: WizardStepValidator;

    editPermits: FieldLimitation[] = [];
    fieldUsage: OtherFillingFieldUsage;
    CatalogTab = CatalogTab;
    OtherFillingField = OtherFillingField;

    constructor(injector: Injector,
                public colorService: ColorService,
                private editCatalogPermitsService: EditCatalogPermitsService,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, OtherFillingService, 'OTHER_FILLING', 'OtherFilling');
        this.item = new OtherFillingWithMaterials();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new OtherFillingFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.allActiveColors = this.colorService.getAllActiveColors().pipe(shareReplay(1));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.OTHER_FILLINGS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'otherFillings';
    }

    onRowSelect(event) {
        this.newItem = false;
        this.selectedWindowSystems = [];
        this.keepSelectedItemIndex(event);
        this.loadItem(event.data.id);
    }

    loadItem(otherFillingId: number) {
        forkJoin({
            item: this.itemService.getItem(otherFillingId),
            linkedWindowSystems: this.getLinkedWindowSystems(otherFillingId),
            allActiveColors: this.allActiveColors
        }).subscribe({
            next: data => {
                this.item = data.item;

                if (this.copyMode) {
                    this.item.id = undefined;
                }

                this.selectedWindowSystems = data.linkedWindowSystems;
                this.fillColorsList(data.allActiveColors);
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getGlass completed!');
            }
        });
    }

    showDialogToAdd() {
        this.selectedWindowSystems = [];
        this.loadAvailableColors(true);

    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.newItem = false;
            this.selectedWindowSystems = [];
            this.loadItem(this.selectedItem.id);
        }
    }

    submit() {
        this.validationErrors = {};
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
            } else if (this.newItem) {
                observable = this.itemService.addItem(this.item).pipe(mergeMap(this.editLinksAfterSave()));
            } else {
                observable = this.itemService.editItem(this.item.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    onLanguageChange(newTranslations: any): void {
        this.loadAvailableColors();
    }

    loadAvailableColors(showDialog = false) {
        this.availableInsideColors = [];
        this.availableOutsideColors = [];
        this.availableCoreColors = [];
        this.allActiveColors.subscribe({
            next: data => {
                this.fillColorsList(data);
                if (showDialog) {
                    super.showDialogToAdd();
                }
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    private fillColorsList(data: ColorInterface[]): void {
        this.fillAvailableInsideColors(data);
        this.fillAvailableOutsideColors(data);
        this.fillAvailableCoreColors(data);
    }

    private fillAvailableInsideColors(data: ColorInterface[]): void {
        this.availableInsideColors = this.getAvailableColors(data, c => c.inside,
            this.item != undefined ? this.item.insideColors : undefined);
    }

    private fillAvailableOutsideColors(data: ColorInterface[]): void {
        this.availableOutsideColors = this.getAvailableColors(data, c => c.outside,
            this.item != undefined ? this.item.outsideColors : undefined);
    }

    private fillAvailableCoreColors(data: ColorInterface[]): void {
        this.availableCoreColors = this.getAvailableColors(data, c => c.core,
            this.item != undefined && this.item.coreColor != undefined ? [this.item.coreColor] : undefined);
    }

    private getAvailableColors(data: ColorInterface[], colorFilter: (c: Color) => boolean, addonColors: ColorInterface[]): SelectItem[] {
        return data.filter(colorFilter).map(c => {
            let existingColor: ColorInterface = undefined;
            if (addonColors != undefined) {
                existingColor = addonColors.find(ac => ac.id === c.id);
            }
            if (existingColor != undefined) {
                return {label: getColorFormattedNameWithGroup(existingColor, this.userLang), value: existingColor};
            } else {
                return {label: getColorFormattedNameWithGroup(c, this.userLang), value: c};
            }
        });
    }

    validateForm(): Observable<boolean> {
        let errors = {};
        if (!this.item.name[this.userLang]) {
            errors[`name[${this.userLang}]`] = `error.otherFillingDto.name[${this.userLang}].not_empty`;
        }
        if (!this.item.symbol) {
            errors['symbol'] = 'error.otherFillingDto.symbol.not_empty';
        }

        errors['sortIndex'] = MultiValidator.of('error.otherFillingDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        errors['thickness'] = MultiValidator.of('error.otherFillingDto.thickness')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, this.INPUT_NUMBER_DEFAULT_MAX_VALUE, false, true)
            .validate(this.item.thickness);

        if (!this.item.coreColor) {
            errors['coreColor'] = 'error.otherFillingDto.coreColor.not_empty';
        }
        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    getNewItem(): OtherFillingWithMaterials {
        return new OtherFillingWithMaterials();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
