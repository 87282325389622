<app-wizard #wizard header="{{ getWizardTitle() | translate }}" (onCancel)="exitWizard()"
            (currentStepChange)="onStepChange($event)" (onComplete)="saveOffer()">

    <!-- SELECT USER STEP: -->
    <app-wizard-step label="{{ 'OFFER.CREATE_OFFER.STEP_SELECT_USER' | translate }}" [id]="STEPS.SELECT_USER"
                     [validate]="validateUserSelectionStep" *ngIf="isUserSelectionStepEnabled()" [displayHeader]="false">

        <div class="create-offer create-offer-second-header">
            <span>{{ 'OFFER.CREATE_OFFER.USERS_HEADER' | translate }}</span>
        </div>

        <div class="create-offer offer-details-header-spacer"></div>

        <div class="create-offer new-form-600 subsystem-selection">
            <div class="new-form-row">
                <app-select label="{{ 'OFFER.CREATE_OFFER.FORM.SUBSYSTEM' | translate }}"
                            [(validationMessageKey)]="validationErrors['subsystem']"
                            [options]="availableSubsystems" [ngModel]="selectedSubsystemId"
                            (ngModelChange)="subsystemSelectionChange($event)" [disabled]="!canEdit"></app-select>
            </div>
        </div>

        <div class="create-offer subsystem-selection-list-spacer"></div>

        <div class="create-offer user-selection-form">
            <app-listbox-selection (onDoubleClick)="wizard.nextStep()" (onItemChange)="handleUserSelection($event)"
                                   [columnsCount]="3"
                                   [item]="selectedUserItem" [options]="availableUsers"
                                   [validationErrors]="validationErrors" [disabled]="!canEdit"></app-listbox-selection>
        </div>
    </app-wizard-step>

    <!-- SELECT CLIENT STEP: -->
    <app-wizard-step label="{{ 'OFFER.CREATE_OFFER.STEP_SELECT_CLIENT' | translate }}" [id]="STEPS.SELECT_CLIENT"
                     [validate]="validateClientSelectionStep" *ngIf="forceClientId == undefined" [displayHeader]="false">

        <ng-container *ngIf="permissions.isPermitted({roles: AccessData.roles.client.roles})">
            <div class="create-offer create-offer-second-header">
                <span>{{ 'OFFER.CREATE_OFFER.SELECT_CLIENT_HEADER_OR' | translate }}</span>
                <span style="width: 0.5em"></span>
                <app-simple-button *ngIf="canEdit" buttonId="client-selection-create-new-button"
                                   label="{{ 'OFFER.CREATE_OFFER.CREATE_CLIENT_BUTTON' | translate }}" icon="add"
                                   type="main-action" (onClick)="prepareDataForNewClient()"></app-simple-button>
            </div>
        </ng-container>
        <ng-container *ngIf="!permissions.isPermitted({roles: AccessData.roles.client.roles})">
            <div class="create-offer create-offer-second-header">
                <span>{{ 'OFFER.CREATE_OFFER.SELECT_CLIENT_HEADER' | translate }}</span>
            </div>
        </ng-container>
        <div class="create-offer client-selection-list-spacer"></div>
        <div class="create-offer client-selection-form">
            <app-listbox-selection (onDoubleClick)="wizard.nextStep()" (onItemChange)="handleClientSelection($event)"
                                   [columnsCount]="3"
                                   [item]="selectedClientItem" [options]="clients" [validationErrors]="validationErrors"
                                   [disabled]="!canEdit"></app-listbox-selection>
        </div>
        <app-edit-client *ngIf="createdClient" [client]="createdClient" [existingGroups]="existingGroups"
                         [hideProfitMargins]="true" (onSubmit)="onClientAdded($event)"></app-edit-client>
    </app-wizard-step>

    <!-- FILL DETAILS STEP: -->
    <app-wizard-step label="{{ 'OFFER.CREATE_OFFER.STEP_DETAILS' | translate }}" [id]="STEPS.DETAILS"
                     [validate]="validateDetailsStep" [displayHeader]="false">
        <div class="create-offer create-offer-second-header">
            <span>{{ 'OFFER.CREATE_OFFER.DETAILS_HEADER' | translate }}</span>
        </div>
        <div class="create-offer offer-details-header-spacer"></div>
        <div class="create-offer new-form-600 offer-details-form">
            <div *ngIf="canEdit" class="offer-details-client-header">
                <span>{{ 'OFFER.CREATE_OFFER.FORM.SELECTED_CLIENT' | translate }}</span>
                <button *ngIf="forceClientId == undefined" type="button" class="offer-details-change-client"
                        (click)="wizard.setStepById(STEPS.SELECT_CLIENT)">
                    <span>{{ 'OFFER.CREATE_OFFER.FORM.CHANGE_CLIENT' | translate }}</span>
                </button>
            </div>
            <div class="offer-details-client" title="{{selectedClient?.name}}">
                {{selectedClient?.name}}
            </div>
            <ng-container *ngIf="selectedClient?.email" >
                <div class="offer-details-client-header">
                    <span>{{ 'OFFER.CREATE_OFFER.FORM.SELECTED_CLIENT_EMAIL' | translate }}</span>
                </div>
                <div class="offer-details-client" title="{{selectedClient?.email}}">
                    {{selectedClient?.email}}
                </div>
            </ng-container>
            <ng-container *ngIf="selectedClient?.phone" >
                <div class="offer-details-client-header">
                    <span>{{ 'OFFER.CREATE_OFFER.FORM.SELECTED_CLIENT_PHONE' | translate }}</span>
                </div>
                <div class="offer-details-client" title="{{selectedClient?.phone}}">
                    {{selectedClient?.phone}}
                </div>
            </ng-container>

            <div class="offer-details-form-spacer-32"></div>
            <div class="offer-details-client-header">
                <span>{{ 'OFFER.CREATE_OFFER.FORM.DELIVERY_ADDRESS' | translate }}</span>
            </div>
            <div class="offer-details-client-header offer-details-address">
                <div *ngIf="deliveryAddress && deliveryAddress.address">
                    <div class="new-form-row">{{ deliveryAddress.address.street }}</div>
                    <div class="new-form-row">{{ deliveryAddress.address.zip }} {{ deliveryAddress.address.city }} {{ 'COUNTRIES.' + deliveryAddress.address.country | translate }}</div>
                </div>
                <div *ngIf="canEdit" class="blue-text-button">
                    <app-simple-button buttonId="subsystem-address-select-delivery"
                                       label="{{ 'OFFER.CREATE_OFFER.FORM.CHANGE_DELIVERY_ADDRESS' | translate }}"
                                       (onClick)="showDeliveryAddressSelectionDialog()"></app-simple-button>
                </div>
            </div>
            <div class="offer-details-form-spacer-32"></div>

            <div class="new-form-row">
                <app-calendar inputId="valid-from" label="{{ 'OFFER.CREATE_OFFER.FORM.VALID_FROM' | translate }}"
                              (onSelect)="setValidToDateBasedOnValidFromDate()" [utc]="true"
                              [(validationMessageKey)]="validationErrors['validFrom']"
                              [(ngModel)]="validFromDate" [disabled]="!canEdit"></app-calendar>
                <app-calendar inputId="valid-to" label="{{ 'OFFER.CREATE_OFFER.FORM.VALID_TO' | translate }}"
                              [minDate]="validFromDate" [maxDate]="maxValidTo" [utc]="true"
                              [(validationMessageKey)]="validationErrors['validFrom']"
                              [(ngModel)]="validToDate" [disabled]="!canEdit"></app-calendar>
            </div>
            <div class="new-form-row">
                <app-input-text inputId="alternateOfferNumber"
                                label="{{ 'OFFER.CREATE_OFFER.FORM.ALTERNATE_OFFER_NUMBER' | translate }}"
                                [(validationMessageKey)]="validationErrors['alternateOfferNumber']" [maxlength]="50"
                                [(ngModel)]="offer.alternateOfferNumber" [disabled]="!canEdit"></app-input-text>
            </div>
            <div *ngIf="offer.webshopPreferredContactMethod != undefined" class="new-form-row">
                <div class="new-form-field">
                    <div class="new-form-field-label-container">
                        <label>{{ 'OFFER.CREATE_OFFER.FORM.WEBSHOP_PREFERRED_CONTACT_METHOD' | translate }}</label>
                    </div>
                    <div class="new-form-field-input-container contact-methods">
                        {{ getContactMethodString(offer) | async }}
                    </div>
                </div>
            </div>
            <div class="new-form-row offer-details-other-info">
                <app-text-area inputId="otherInfo" label="{{ 'OFFER.CREATE_OFFER.FORM.OTHER_INFO' | translate }}"
                               [(validationMessageKey)]="validationErrors['otherInfo']" [maxlength]="500"
                               [(ngModel)]="offer.otherInfo" [disabled]="!canEdit"></app-text-area>
            </div>
            <div class="new-form-row" *ngIf="isUpdateExchangeRateFieldVisible()">
                <app-checkbox inputId="calculateValueByCurrency"
                              label="{{ 'OFFER.CREATE_OFFER.FORM.RECALCULATE' | translate }}"
                              [(ngModel)]="updateExchangeRate" [disabled]="!canEdit"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="historyViewAllowed">
                <app-checkbox inputId="showHistoryCheckbox"
                              label="{{ 'OFFER.ACTIONS.TOOLTIPS.SHOW_HISTORY' | translate }}"
                              [(ngModel)]="showHistory" [disabled]="!canEdit"></app-checkbox>
            </div>
        </div>
    </app-wizard-step>

    <app-wizard-step *ngIf="showHistory" label="{{ 'OFFER.CREATE_OFFER.STEP_HISTORY' | translate }}"
                     [id]="STEPS.HISTORY" [displayHeader]="false">
        <div class="history new-form-60vw">
            <app-offer-history [offerId]="offer.id"></app-offer-history>
        </div>
    </app-wizard-step>
</app-wizard>

<app-confirm-action-dialog *ngIf="showConfirmationDialog"
                           dialogId="deleteAddonsAndAssemblyConfirmation"
                           (onCancel)="closeTheDialog()"
                           (onConfirmed)="save(); closeTheDialog()"
                           [questions]="getConfirmationDialogContent()"
                           [header]="'GENERAL.DIALOG.DELETING' | translate"
                           [confirmButtonLabel]="'GENERAL.YES' | translate"
                           [discardButtonLabel]="'GENERAL.NO' | translate"></app-confirm-action-dialog>

<app-subsystem-address-selection-dialog *ngIf="deliveryAddressSelectionDialogVisible"
                                        [addresses]="addresses"
                                        [visible]="deliveryAddressSelectionDialogVisible"
                                        (visibleChange)="handleAddressSelectionVisibilityChange($event)"
                                        [selectedAddress]="deliveryAddress"
                                        (selectedAddressChange)="handleAddressSelected($event)" [canEdit]="false"
                                        [selectable]="true"></app-subsystem-address-selection-dialog>
