import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-nav-more',
    templateUrl: './nav-more.component.html',
    styleUrls: ['./nav-more.component.css']
})
export class NavMoreComponent {

    @Input() labelKey: string;
    @Output() goTo: EventEmitter<void> = new EventEmitter<void>();

}
