<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" [closable]="true" [closeOnEscape]="true"
              (onHide)="hide()" [modal]="true" [focusOnShow]="false">

        <p-header>
            <div class="p-dialog-title motlawa-title-bar">
                {{ "MOTLAWA_INTEGRATION_DIALOG.DATA_HEADER" | translate }}
                <div class="motlawa-price-total">
                    {{ "MOTLAWA_INTEGRATION_DIALOG.PRICE_TOTAL_LABEL" | translate }} {{ dialogData.results.priceTotal }}
                </div>
            </div>
        </p-header>

        <div class="price-table-padding">
            <div class="data-container">
                <table class="data-table">
                    <tr *ngFor="let row of (showDiff ? diffRecords : records); index as i">
                        <td *ngFor="let cell of row; index as j"
                            [ngClass]="showDiff | renderDiffs:rowDiffs:cellDiffs:i:j">{{ cell }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <p-footer>
            <div class="new-form-field-error-message" *ngIf="dialogData.results.successfulSendingDate != undefined">
                {{ 'MOTLAWA_INTEGRATION_DIALOG.ALREADY_SENT' | translate : {date: dialogData.results.successfulSendingDate | momentCurrentLang | amDateFormat: 'L LTS' } }}
            </div>
            <app-checkbox *ngIf="hasDiffs"
                          inputId="showDiff"
                          label="{{ 'MOTLAWA_INTEGRATION_DIALOG.SHOW_DIFF' | translate }}"
                          [(ngModel)]="showDiff"></app-checkbox>
            <app-checkbox inputId="showIds"
                          label="{{ 'MOTLAWA_INTEGRATION_DIALOG.SHOW_IDS' | translate }}"
                          [(ngModel)]="showIds"></app-checkbox>
            <ng-container *ngIf="showIds">
                <div>{{ dialogData.paymentPackageId }}</div>
                <div *ngIf="dialogData.results.packageUuid">{{ dialogData.results.packageUuid }}</div>
            </ng-container>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="sendToMotlawa"
                                   label="{{ ('MOTLAWA_INTEGRATION_DIALOG.' + (dialogData.results.successfulSendingDate ? 'FORCE_' : '') + (dialogData.results.packagePermanentInMotlawa ? 'UPDATE' : 'SEND')) | translate }}"
                                   [type]="dialogData.results.successfulSendingDate ? 'delete' : 'main-action'"
                                   [size]="40" (onClick)="send()"></app-simple-button>
                <app-simple-button buttonId="downloadAsCsv"
                                   label="{{ 'MOTLAWA_INTEGRATION_DIALOG.DOWNLOAD_CSV' | translate }}"
                                   [size]="40" (onClick)="download()"></app-simple-button>
                <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
