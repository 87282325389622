<div class="new-form-60vw">

    <app-link-selection [disabled]="disabled"
                        [linkableItems]="entranceModels"
                        [ngModel]="linkedModelIds"
                        [linkableEntity]="LinkableEntity.ENTRANCE_MODELS"
                        (ngModelChange)="onSelectedModelsChange($event)"
                        [showToggleAllButton]="true"
                        toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.MODELS"></app-link-selection>
</div>
