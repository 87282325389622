import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemPartnerWebshopInfo} from './subsystem-partner-webshop-info';

@Injectable()
export class SubsystemPartnerWebshopInfoService {

    private static readonly API_URL = 'subsystemPartnerWebshopInfo';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItem(): Observable<SubsystemPartnerWebshopInfo> {
        type SubsystemWebshopInfoInput = Omit<SubsystemPartnerWebshopInfo, 'logo'> & {
            logoFileName: string,
            logo: string,
            logoFileType: string
        };
        return this.http.get<SubsystemWebshopInfoInput>(SubsystemPartnerWebshopInfoService.API_URL)
            .pipe(mergeMap(response => {
                const subsystemWebshopInfo = SubsystemPartnerWebshopInfo.fromJSON(response);
                return (response.logo != undefined
                    ? this.dataServiceHelper.getArrayBufferFromDataUri(response.logo)
                    : of<ArrayBuffer>(undefined))
                    .pipe(map(logo => {
                        if (logo != undefined) {
                            subsystemWebshopInfo.logo = new File([logo], response.logoFileName, {type: response.logoFileType});
                        }
                        return subsystemWebshopInfo;
                    }));
            }));
    }

    saveItem(item: SubsystemPartnerWebshopInfo, logo: File | undefined): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('subsystemPartnerWebshopInfoDto', new Blob([body], {
            type: 'application/json'
        }));
        if (logo != undefined) {
            formData.append('logo', logo);
        }
        return this.http.post(SubsystemPartnerWebshopInfoService.API_URL, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(SubsystemPartnerWebshopInfoService.API_URL, response)));
    }

    getLanguage(): Observable<keyof MultilanguageFieldInterface> {
        return this.http.get(`${SubsystemPartnerWebshopInfoService.API_URL}/language`, {responseType: 'text'})
            .pipe(map(language => language as keyof MultilanguageFieldInterface));
    }
}
