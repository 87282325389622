import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {ColorService} from '../../../window-system/color/color.service';
import {GatePanelTypeService} from '../../../window-system/gate-panel-type/gate-panel-type.service';
import {RailSystemService} from '../../../window-system/rail-system/rail-system.service';
import {AddonsService} from "../../../window-system/addons/addons.service";
import {GateWallService} from "../../../window-system/gate-wall/gate-wall.service";

@Injectable()
export class GateSidebarFieldImageService {

    private readonly gatePanelTypeDialogImageCache = new Map<number, Observable<string>>();
    private readonly gateWallDialogImageCache = new Map<number, Observable<string>>();

    constructor(private readonly railSystemService: RailSystemService,
                private readonly colorService: ColorService,
                private readonly gatePanelTypeService: GatePanelTypeService,
                private readonly gateWallService: GateWallService,
                private readonly addonService: AddonsService) {
    }

    getRailSystemImage(railSystemId: number): Observable<string> {
        return this.railSystemService.getImageAsBase64(railSystemId);

    }

    getColorImage(colorId: number): Observable<string> {
        return this.colorService.getImage(colorId);
    }

    getGatePanelTypeImage(gatePanelTypeId: number): Observable<string> {
        return this.gatePanelTypeService.getHighResolutionPreviewImage(gatePanelTypeId);
    }

    getGateWallImage(gateWallId: number): Observable<string> {
        return this.gateWallService.getHighResolutionPreviewImage(gateWallId);
    }

    getGatePanelTypeDialogImage(gatePanelTypeId: number): Observable<string> {
        let observable = this.gatePanelTypeDialogImageCache.get(gatePanelTypeId);
        if (observable == undefined) {
            observable = this.gatePanelTypeService.getLowResolutionPreviewImage(gatePanelTypeId).pipe(shareReplay(1));
            this.gatePanelTypeDialogImageCache.set(gatePanelTypeId, observable);
        }
        return observable;
    }

    getGateWallDialogImage(gateWallId: number): Observable<string> {
        let observable = this.gateWallDialogImageCache.get(gateWallId);
        if (observable == undefined) {
            observable = this.gateWallService.getLowResolutionPreviewImage(gateWallId).pipe(shareReplay(1));
            this.gateWallDialogImageCache.set(gateWallId, observable);
        }
        return observable;
    }

    getAddonImage(addonId: number): Observable<string> {
        return this.addonService.getImageForItem(addonId);
    }
}
