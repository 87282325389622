import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemSalesTargetChangeHistoryEntry {
    modificationDate: Date;
    modifiedBy: string;
    modifiedByPrincipal: string;
    oldValue: number;
    newValue: number;

    static fromJSON(jsonObject: object): SubsystemSalesTargetChangeHistoryEntry {
        const historyEntry = new SubsystemSalesTargetChangeHistoryEntry();
        historyEntry.modificationDate = new Date(jsonObject['modificationDate']);
        historyEntry.modifiedBy = jsonObject['modifiedBy'];
        historyEntry.modifiedByPrincipal = jsonObject['modifiedByPrincipal'];
        historyEntry.oldValue = jsonObject['oldValue'];
        historyEntry.newValue = jsonObject['newValue'];
        return historyEntry;
    }
}
