import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UserGroup} from '../user-group';
import {User} from '../../user/user';

@Component({
    selector: 'app-user-group-form',
    templateUrl: './user-group-form.component.html',
    styleUrls: ['./user-group-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupFormComponent {

    @Input()
    validationErrors;

    @Input()
    userGroup: UserGroup;

    @Input()
    users: (User & { selected?: boolean })[];
}
