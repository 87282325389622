<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">
            {{ 'SETTINGS.SECTION.PROMOTION_CODE.GENERAL' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.PROMOTION_CODE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code" [ngClass]="columnHeaderClasses('code')">
                        <span>{{ 'PROMOTION_CODE.FORM.CODE' | translate }}</span>
                        <p-sortIcon field="code"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="code"
                               (input)="table.filter(getInputEventValue($event), 'code', 'contains')">
                    </th>
                    <th pSortableColumn="value" [ngClass]="columnHeaderClasses('value')">
                        <span>{{ 'PROMOTION_CODE.FORM.VALUE' | translate }}</span>
                        <p-sortIcon field="value"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="value"
                               (input)="table.filter(getInputEventValue($event), 'value', 'equals')">
                    </th>
                    <th pSortableColumn="validFrom" [ngClass]="columnHeaderClasses('validFrom')">
                        <span>{{ 'PROMOTION_CODE.FORM.VALID_FROM' | translate }}</span>
                        <p-sortIcon field="validFrom"></p-sortIcon>
                        <div app-table-date-range-filter="validFrom"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th pSortableColumn="validTo" [ngClass]="columnHeaderClasses('validTo')">
                        <span>{{ 'PROMOTION_CODE.FORM.VALID_TO' | translate }}</span>
                        <p-sortIcon field="validTo"></p-sortIcon>
                        <div app-table-date-range-filter="validTo"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-promotionCode let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex"
                    (dblclick)="doOnRowSelect({data: promotionCode, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    [pSelectableRow]="promotionCode" [pSelectableRowIndex]="rowIndex"
                    [app-table-keyboard-navigation]="promotionCode"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({data: promotionCode, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    class="datatable-data">
                    <td>{{ promotionCode.code }}</td>
                    <td>{{ promotionCode.value }}</td>
                    <td>{{ promotionCode.validFrom | momentCurrentLang | amDateFormat: 'L LTS' }}</td>
                    <td>{{ promotionCode.validTo | momentCurrentLang | amDateFormat: 'L LTS' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
<app-wizard *ngIf="newItem" header="{{ 'SETTINGS.SECTION.PROMOTION_CODE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'PROMOTION_CODE.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-promotion-code-form [promotionCode]="item" [validationErrors]="validationErrors"></app-promotion-code-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'SETTINGS.SECTION.PROMOTION_CODE.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'PROMOTION_CODE.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-promotion-code-form [promotionCode]="item" [validationErrors]="validationErrors"></app-promotion-code-form>
    </app-wizard-step>
</app-wizard-dialog>
