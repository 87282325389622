import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WindowDimensions} from '../../../../../window-designer/entities/window-dimensions';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {DimensionsField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {WindowDimensionsFieldUsage} from '../../catalog-field-usage';

@Component({
     selector: 'app-window-dimensions-form',
     templateUrl: './window-dimensions-form.component.html',
     changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowDimensionsFormComponent {

    @Input() item: WindowDimensions;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[] = [];

    @Output() onSizeChange = new EventEmitter();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: WindowDimensionsFieldUsage;
    DimensionsField = DimensionsField;
    showSystemLinks = false;

    constructor(public translateService: TranslateService) {
        this.fieldUsage = new WindowDimensionsFieldUsage(this);
    }

    onWidthChange(width: number) {
        this.item.width = width;
        this.onSizeChanged();
    }

    onHeightChange(height: number) {
        this.item.height = height;
        this.onSizeChanged();
    }

    private recalculateSurface(): void {
        if (this.item.width != undefined && this.item.height != undefined) {
            this.item.surface = this.item.width * this.item.height / 1000000;
        } else {
            this.item.surface = undefined;
        }
    }

    onSizeChanged(): void {
        this.recalculateSurface();
        this.onSizeChange.emit();
    }
}
