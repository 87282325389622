import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'app-exit-without-saving-confirmation-dialog',
    templateUrl: './exit-without-saving-confirmation-dialog.component.html'
})
export class ExitWithoutSavingConfirmationDialogComponent {

    @Input()
    visible: boolean;

    @Output()
    exitWithoutSaving = new EventEmitter();

    @Output()
    hideDialog = new EventEmitter();
}
