import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

export type SimpleButtonType = 'main-action' | 'action' | 'secondary-action' | 'cancel' | 'filter' | 'delete' | 'confirm' | 'invisible';
export type SimpleButtonSize = 24 | 28 | 32 | 36 | 40 | 48;

@Component({
    selector: 'app-simple-button',
    templateUrl: './simple-button.component.html',
    styleUrls: ['./simple-button.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SimpleButtonComponent implements OnChanges, AfterViewInit {

    @Input()
    buttonId: string;

    @Input()
    label: string;

    @Input()
    inlineLabel = true;

    @Input()
    icon: string;

    @Input()
    iconAwesome: string;

    @Input()
    type: SimpleButtonType;

    @Input()
    size: SimpleButtonSize;

    @Input()
    disabled: boolean;

    @Input()
    fixedWidth: string;

    @Input()
    align: string;

    @Input()
    hotkey: any;

    @Input()
    tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'right';

    @Input()
    inverted: boolean;

    @Output()
    readonly onClick = new EventEmitter<MouseEvent>();

    @Output()
    readonly onFocus = new EventEmitter<FocusEvent>();

    @Output()
    readonly onIconClick = new EventEmitter<MouseEvent>();

    @ViewChild('buttonRef', {static: true})
    buttonRef: ElementRef;

    // <app-simple-button> styles
    @HostBinding('class.simple-button-center-align')
    centerAlign = false;

    @HostBinding('class.simple-button-right-align')
    rightAlign = false;

    formattedTooltip: string;

    constructor(private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('type' in changes) {
            const change = changes['type'];
            this.renderer.removeClass(this.buttonRef.nativeElement, this.getStyleClassForType(change.previousValue));
            if (this.inverted) {
                this.renderer.addClass(this.buttonRef.nativeElement, this.getStyleClassForTypeInverted(change.currentValue));
            } else {
                this.renderer.addClass(this.buttonRef.nativeElement, this.getStyleClassForType(change.currentValue));
            }
        }
        if ('size' in changes) {
            const change = changes['size'];
            this.renderer.removeClass(this.buttonRef.nativeElement, this.getStyleClassForSize(change.previousValue));
            this.renderer.addClass(this.buttonRef.nativeElement, this.getStyleClassForSize(change.currentValue));
        }
        if ('fixedWidth' in changes) {
            const change = changes['fixedWidth'];
            if (change.currentValue != undefined) {
                this.renderer.setStyle(this.buttonRef.nativeElement, 'width', change.currentValue);
            } else {
                this.renderer.removeStyle(this.buttonRef.nativeElement, 'width');
            }
        }
        if ('align' in changes) {
            const change = changes['align'];
            this.centerAlign = change.currentValue === 'center';
            this.rightAlign = change.currentValue === 'right';
        }
        if ('inlineLabel' in changes || 'hotkey' in changes || 'label' in changes) {
            this.formattedTooltip = this.formatTooltip();
        }
    }

    ngAfterViewInit(): void {
        const changes: SimpleChanges = {};
        if (this.type == undefined) {
            changes['type'] = new SimpleChange(undefined, 'action', false);
        }
        if (this.size == undefined) {
            changes['size'] = new SimpleChange(undefined, 32, false);
        }
        this.ngOnChanges(changes);
    }

    handleClick(event: MouseEvent): void {
        this.onClick.emit(event);
    }

    handleIconClick(event: MouseEvent): void {
        if (this.onIconClick) {
            this.onIconClick.emit(event);
        }
    }

    handleFocus(event: FocusEvent): void {
        this.onFocus.emit(event);
    }

    private formatTooltip(): string {
        if (this.inlineLabel) {
            return this.hotkey != undefined ? this.formatHotkey() : undefined;
        }
        if (this.hotkey != undefined) {
            return `${this.label} (${this.formatHotkey()})`;
        }
        return this.label;
    }

    private formatHotkey(): string {
        // format hotkey combos with nonbreaking space
        if ('formatted' in this.hotkey) {
            return `${this.hotkey.formatted
                .map(comboText => comboText
                    .split(' + ')
                    .join(`${String.fromCharCode(160)}+${String.fromCharCode(160)}`))
                .join(', ')}`;
        }
        return this.hotkey.toString();
    }

    private getStyleClassForType(type: SimpleButtonType): string {
        if (type == undefined) {
            return 'simple-button-action';
        }
        switch (type) {
            case 'main-action':
            case 'secondary-action':
            case 'action':
            case 'cancel':
            case 'filter':
            case 'delete':
            case 'confirm':
            case 'invisible':
                return 'simple-button-' + type;
            default:
                break;
        }
        throw new Error(`Unsupported type ${type} used in <app-simple-button>`);
    }

    private getStyleClassForSize(size: SimpleButtonSize): string {
        if (size == undefined) {
            return 'simple-button-32';
        }
        switch (size) {
            case 24:
            case 28:
            case 32:
            case 36:
            case 40:
            case 48:
                return 'simple-button-' + size;
            default:
                break;
        }
        throw new Error(`Unsupported size ${size} used in <app-simple-button>`);
    }

    private getStyleClassForTypeInverted(type: SimpleButtonType): string {
        return this.getStyleClassForType(type) + '-inverted';
    }
}
