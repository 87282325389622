import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class PromotionCode implements CrudItem {

    id: number;
    validFrom: Date;
    validTo: Date;
    code: string;
    value: number;

    static fromJSON(jsonObject: any): PromotionCode {
        const promotionCode = new PromotionCode();
        promotionCode.id = jsonObject.id;
        if (jsonObject.validFrom != undefined) {
            promotionCode.validFrom = new Date(jsonObject.validFrom);
        }
        if (jsonObject.validTo != undefined) {
            promotionCode.validTo = new Date(jsonObject.validTo);
        }
        promotionCode.code = jsonObject.code;
        promotionCode.value = jsonObject.value;
        return promotionCode;
    }
}
