import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from '../../../supportedLanguages';
import {WindowSystemMaterialLinks} from '../single-system-checkbox-crud/window-system-material-links';

export class Color implements CrudItem, WindowSystemMaterialLinks, ColorInterface {
    id: number;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    type: string;
    group: string;
    material: string;
    ralHex: string;

    core: boolean;
    inside: boolean;
    outside: boolean;
    grill: boolean;
    mustBeCovered: boolean;
    cannotBeCovered: boolean;

    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;

    active: boolean;
    sortIndex: number;

    matchingColorId: number;

    webshop: boolean;
    webshopHex: string;
    selectedWebshopColorOption: string;

    constructor() {
        this.id = undefined;
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.active = true;
        this.sortIndex = 1;
    }
}
