export class SavedFilter {

    id: number;
    name: string;
    description: string;
    viewId: string;
    filter: string;
    selected: boolean;
    visibleColumns: string;

    constructor(id: number, name: string, description: string, viewId: string, filter: string, visibleColumns: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.viewId = viewId;
        this.filter = filter;
        this.visibleColumns = visibleColumns;
    }
}