import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, SealField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {SealFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {Seal} from './Seal';
import {SealService} from './seal.service';
import {SealWithMaterials} from './SealWithMaterials';

@Component({
    selector: 'app-seal',
    templateUrl: './seal.component.html',
    styleUrls: ['./seal.component.css', '../../shared-styles.css'],
    providers: [SealService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SealComponent extends SingleSystemCheckboxCrudComponent<Seal, SealService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    item: SealWithMaterials;

    validateStepData: WizardStepValidator;

    @ViewChild('dt') datatable;

    editPermits: FieldLimitation[] = [];
    fieldUsage: SealFieldUsage;
    CatalogTab = CatalogTab;
    SealField = SealField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, SealService, 'SEAL', 'Seal');
        this.item = new SealWithMaterials();
        this.validateStepData = () => this.validateSealData();
        this.initDefaultSortOrder();
        this.fieldUsage = new SealFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.SEALS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'seals';
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    editEventSeal(seal, changingField) {
        const newValue: boolean = seal[changingField];
        this.itemService.editSealPositionAvailability(seal.id, changingField, newValue).subscribe({
            next: () => {
                seal[changingField] = newValue;
                this.showSuccessMessage();
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.genericErrorHandler(error);
            }
        });
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.item = this.cloneSeal(<SealWithMaterials>this.selectedItem);
            this.item.id = undefined;
            this.getLinkedWindowSystemsAndShowDialog(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        this.item = this.cloneSeal(event.data);
        this.getLinkedWindowSystemsAndShowDialog(this.item.id);
        this.keepSelectedItemIndex(event);
    }

    public getLinkedWindowSystemsAndShowDialog(entityId): void {
        this.getLinkedWindowSystems(entityId)
            .subscribe({
                next: data => {
                    this.selectedWindowSystems = data;
                    this.setDisplayDialog(true);
                },
                error: error => {
                    console.error('SingleSystemCheckboxComponent `getLinkedWindowSystems` error:', error);
                    this.setErrors(error);
                },
                complete: () => {
                    console.info('SingleSystemCheckboxComponent `getLinkedWindowSystems` completed!');
                }
            });
    }

    cloneSeal(seal: SealWithMaterials): SealWithMaterials {
        let newSeal = <SealWithMaterials>_.clone(seal);
        newSeal.names = <MultilanguageField>_.clone(seal.names);
        newSeal.shortcut = <MultilanguageField>_.clone(seal.shortcut);
        return newSeal;
    }

    getNewItem(): SealWithMaterials {
        return new SealWithMaterials();
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        let observable: Observable<number>;
        if (this.copyMode) {
            observable = this.itemService.copy(this.selectedItem.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
        } else if (this.newItem) {
            observable = this.itemService.addItem(this.item).pipe(mergeMap(this.editLinksAfterSave()));
        } else {
            observable = this.itemService.editItem(this.item.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
        }
        observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    validateSealData(): Observable<boolean> {
        let errors = {};

        if (!this.item.names[this.userLang]) {
            errors[`names[${this.userLang}]`] = `error.sealDto.names[${this.userLang}].not_empty`;
        }

        if (!this.item.symbol) {
            errors['symbol'] = 'error.sealDto.symbol.not_empty';
        }

        errors['sortIndex'] = MultiValidator.of('error.sealDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            this.changeDetector.markForCheck();
            return of(false);
        }

        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
