import {MessageSeverity, PositionMessage} from '../../message';

export class BulkChangeFrontendWarning {
    positionId: number;
    message: PositionMessage;

    constructor(positionId: number,
                translationCode: string) {
        this.positionId = positionId;
        this.message = new PositionMessage();
        this.message.severity = MessageSeverity.WARNING;
        this.message.messageCode = translationCode;
    }
}
