<p-dialog *ngIf="componentInitialized" [resizable]="false" [visible]="true" (onHide)="closeDialog()" [modal]="true"
          [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.ACTIONS.DIALOGS.COMMENTS' | translate }}
        </div>
    </p-header>

    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <p-tabView *ngIf="mode == CommentDialogMode.OFFER">
            <p-tabPanel header="{{'OFFER.ACTIONS.DIALOGS.OFFER' | translate}}
            {{' (' + filteredComments.length + ')'}}
            {{hasUnreadComment(filteredComments) ? ' - !' : ''}}">

                <div class="new-form-row">
                    <app-text-area inputId="comment" label="{{ 'OFFER.ACTIONS.DIALOGS.COMMENT.COMMENT' | translate }}"
                                   [(validationMessageKey)]="validationErrors['comment']" [maxlength]="500"
                                   [(ngModel)]="newComment.comment"></app-text-area>
                </div>

                <!-- ADD TEMPLATE -->
                <div class="new-form-row" *ngIf="otherInfoTemplates.length > 0">
                    <app-select inputId="type" [options]="otherInfoTemplates" [(ngModel)]="selectedTemplate">
                        <ng-template pTemplate="label">
                            <app-simple-button icon="add" type="action" [size]="40"
                                               [disabled]="newComment.comment && newComment.comment.length >= 500"
                                               align="right" (onClick)="addTemplate()"></app-simple-button>
                        </ng-template>
                    </app-select>
                </div>

                <div class="add-button-container">
                    <!-- ADD COMMENT BUTTON -->
                    <app-simple-button buttonId="addCommentButton" label="{{ 'GENERAL.ADD' | translate }}" type="main-action"
                                       [size]="40" [disabled]="!newComment.comment"
                                       (onClick)="addComment()"></app-simple-button>
                </div>

                <div class="wizard-step-header-spacer"></div>

                <p-accordion [multiple]="true">
                    <!-- SAVED COMMENTS -->
                    <ng-container *ngFor="let comment of filteredComments">
                        <p-accordionTab [selected]="openTabCommentId != undefined && comment.id == openTabCommentId || comment == filteredComments[0]"
                                       [header]="comment | commentTabHeader:mode:isCurrentUserVenskaUser">
                            <div class="preserveWhitespace" [innerHTML]="comment.comment"></div>
                            <div *ngIf="comment.commentType == CommentType.USER" class="change-read-flag-button-wrapper">
                                <app-simple-button *ngIf="comment.commentType == CommentType.USER" buttonId="changeCommentReadFlag"
                                                   [label]="getCommentChangeReadFlagLabel(comment)" type="action" [size]="40"
                                                   (onClick)="changeReadFlag(comment)">
                                </app-simple-button>
                            </div>
                        </p-accordionTab>
                    </ng-container>
                </p-accordion>
            </p-tabPanel>
            <p-tabPanel header="{{'OFFER.ACTIONS.DIALOGS.OFFER_POSITIONS' | translate}}
            {{' (' + positionsCommentsNumber + ')'}}
            {{hasUnreadPositionComment(positionComments) ? ' - ! ' : ''}}">
                <p-accordion [multiple]="true">
                    <!-- SAVED COMMENTS -->
                    <ng-container *ngFor="let positionComment of positionComments">
                        <p-accordionTab header="{{'#' + positionComment.printOrder + ' ' + (positionComment.name | multilanguageTranslate) | translate}}
                        {{ ' (' + positionComment.comments.length + ')'}}
                        {{hasUnreadComment(positionComment.comments) ? ' - ! ' : ''}}">
                            <div class="position-comments">
                                <p-accordion [multiple]="true" *ngIf="positionComment.comments && positionComment.comments.length > 0">
                                    <ng-container *ngFor="let posCom of positionComment.comments">
                                        <p-accordionTab [selected]="openTabPositionCommentId != undefined && posCom.id == openTabPositionCommentId || posCom == positionComment.comments[0]"
                                        [header]="posCom | commentTabHeader:mode:isCurrentUserVenskaUser">
                                            <div class="preserveWhitespace">{{posCom.comment}}</div>
                                            <div class="change-read-flag-button-wrapper">
                                                <app-simple-button buttonId="changePosCommentReadFlag"
                                                                   [label]="getCommentChangeReadFlagLabel(posCom)" type="action" [size]="40"
                                                                   (onClick)="changeReadFlag(posCom)">
                                                </app-simple-button>
                                            </div>
                                        </p-accordionTab>
                                    </ng-container>
                                </p-accordion>
                                <div *ngIf="!positionComment.comments || positionComment.comments.length === 0">
                                    {{'OFFER.POSITIONS.DIALOGS.COMMENTS.NO_COMMENTS' | translate}}
                                </div>
                            </div>
                        </p-accordionTab>
                    </ng-container>
                </p-accordion>
            </p-tabPanel>
        </p-tabView>
        <p-accordion [multiple]="true" *ngIf="mode != CommentDialogMode.OFFER">
            <div class="new-form-row">
                <app-text-area inputId="comment" label="{{ 'OFFER.ACTIONS.DIALOGS.COMMENT.COMMENT' | translate }}"
                               [(validationMessageKey)]="validationErrors['comment']" [maxlength]="500"
                               [(ngModel)]="newComment.comment"></app-text-area>
            </div>

            <!-- ADD TEMPLATE -->
            <div class="new-form-row" *ngIf="otherInfoTemplates.length > 0">
                <app-select inputId="type" [options]="otherInfoTemplates" [(ngModel)]="selectedTemplate">
                    <ng-template pTemplate="label">
                        <app-simple-button icon="add" type="action" [size]="40"
                                           [disabled]="newComment.comment && newComment.comment.length >= 500" align="right"
                                           (onClick)="addTemplate()"></app-simple-button>
                    </ng-template>
                </app-select>
            </div>

            <div class="add-button-container">
                <!-- ADD COMMENT BUTTON -->
                <app-simple-button buttonId="addCommentButton" label="{{ 'GENERAL.ADD' | translate }}" type="main-action"
                                   [size]="40" [disabled]="!newComment.comment"
                                   (onClick)="addComment()"></app-simple-button>
            </div>

            <div class="wizard-step-header-spacer"></div>

            <!-- SAVED COMMENTS -->
            <ng-container *ngFor="let comment of filteredComments">
                <p-accordionTab [selected]="openTabCommentId != undefined && comment.id == openTabCommentId || comment == filteredComments[0]" class="comment-accordion-header"
                [header]="comment | commentTabHeader:mode:isCurrentUserVenskaUser">
                    <div class="preserveWhitespace" [innerHTML]="comment.comment"></div>
                    <div *ngIf="mode == CommentDialogMode.OFFER_POSITION" class="change-read-flag-button-wrapper">
                        <app-simple-button buttonId="changePosCommentReadFlag"
                                           [label]="getCommentChangeReadFlagLabel(comment)" type="action" [size]="40"
                                           (onClick)="changeReadFlag(comment)">
                        </app-simple-button>
                    </div>
                </p-accordionTab>
            </ng-container>
        </p-accordion>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <!-- CLOSE DIALOG BUTTON -->
            <app-simple-button label="{{ 'GENERAL.EXIT' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
