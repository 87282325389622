import { Pipe, PipeTransform } from '@angular/core';
import {DetailedPricingColumn} from "../detailed-pricing.component";

@Pipe({
    name: 'stickyColumnClass'
})
export class StickyColumnClassPipe implements PipeTransform {

    transform(visibleColumns: DetailedPricingColumn[], column: DetailedPricingColumn): 'first' | 'second' | 'third' | 'second2' {
        switch (column) {
            case "PRINT_ORDER":
                return 'first';
            case "SYSTEM":
                return this.columnVisible('PRINT_ORDER', visibleColumns) ? 'second' : 'first';
            case "POSITION_NAME":
                return this.columnVisible('PRINT_ORDER', visibleColumns) && this.columnVisible('SYSTEM', visibleColumns) ?
                    'third' : !this.columnVisible('PRINT_ORDER', visibleColumns) && !this.columnVisible('SYSTEM', visibleColumns) ?
                        'first' : this.columnVisible('PRINT_ORDER', visibleColumns) && !this.columnVisible('SYSTEM', visibleColumns) ?
                            'second' : 'second2';
        }
    }

    public columnVisible(column: DetailedPricingColumn, selectedColumns: DetailedPricingColumn[]): boolean {
        return selectedColumns.some(col => col === column);
    }

}
