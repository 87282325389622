import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, Subscription} from 'rxjs';
import {finalize} from "rxjs/operators";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../common/DatatableHelper";
import {UserRoleTypes} from "../../../common/enums/UserRoleTypes";
import {FiltersHelper} from "../../../common/FiltersHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TermsOfUseService} from '../../settings/terms-of-use/terms-of-use.service';
import {SubsystemService} from "../../subsystem/subsystem.service";
import {UserConsent, UserWithConsentData} from "./userConsentsData";

@Component({
    selector: 'app-user-consents',
    templateUrl: './user-consents.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [TermsOfUseService, DataServiceHelper, TranslatedSelectItemService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserConsentsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    users: UserWithConsentData[];
    selectedUser: UserWithConsentData;
    filterRoles: Observable<SelectItem[]>;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    userLang: string;
    langTranslateSubscription: Subscription;
    userConsents: UserConsent[] = [];
    showDialog = false;
    lastLoadEvent: LazyLoadEvent;
    selectedSubsystemIds: number[] = [];
    subsystems: SelectItem[] = [];

    constructor(public permissions: Permissions,
                public termsOfUseService: TermsOfUseService,
                public translate: TranslateService,
                private subsystemService: SubsystemService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'UserConsentsComponent', false);
        this.userLang = translate.currentLang;
        this.langTranslateSubscription = translate.onLangChange.subscribe(event => {
            this.userLang = event.lang;
            this.changeDetector.markForCheck();
        });

        this.initDefaultSortOrder();
    }

    ngOnInit(): void {
        this.initShownColumns();
        this.initSubsystems();
        this.filterRoles = this.translatedSelectItemService.buildSortedDropdown(UserRoleTypes, 'ROLE.', '');
    }

    ngOnDestroy() {
        this.langTranslateSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    private initSubsystems(): void {
        this.subsystemService.getSelectionItems().subscribe({
            next: subsystems => this.subsystems = subsystems,
            error: error => this.errors.handle(error)
        });
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    loadUserConsentsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event); // save chosenRowsPerPage
        FiltersHelper.filterBySubsystemIds(event.filters, this.selectedSubsystemIds);
        this.lastLoadEvent = event;
        return this.termsOfUseService.getUsers(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.users = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedUser = this.users[0];
                    DatatableHelper.focusOnRowIfNotEditingFilters(event);
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
    }

    onRowSelect(event) {
        this.keepSelectedItemIndex(event);
        this.getUserConsents(event.data.id);
    }

    getUserConsents(userId: number) {
        this.termsOfUseService.getUserConsents(userId).subscribe({
            next: data => {
                this.userConsents = data;
                this.showDialog = true;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    getRoleTranslationKey(name: string): string {
        if (name) {
            return 'ROLE.' + name;
        } else {
            return '';
        }
    }

    getDatatable(): DataTable {
        return undefined; // not return the datatable by purpose (we don't allow row selection)
    }

    showDialogToAdd() {
    }

    submit(): void {
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "login";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_DESCENDING;
    }

    getSubsystemsName(id: number): string {
        let subsystem = (this.subsystems || []).find(s => s.value === `${id}`);
        return subsystem == null ? '' : subsystem.label;
    }
}
