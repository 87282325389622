import {SubWindowTypeCode} from "./subwindow-type-code";

export class SubWindowDataTypeUtil {
    public static getSlidingTypes(): SubWindowTypeCode[] {
        return [
            SubWindowTypeCode.HSL,
            SubWindowTypeCode.HSP,
            SubWindowTypeCode.CPL,
            SubWindowTypeCode.CPP,
            SubWindowTypeCode.HL,
            SubWindowTypeCode.HP,
        ];
    }

    public static getCopalSlidingTypes(): SubWindowTypeCode[] {
        return [
            SubWindowTypeCode.CPL,
            SubWindowTypeCode.CPP
        ];
    }

    public static getAccordionTypes(): SubWindowTypeCode[] {
        return [
            SubWindowTypeCode.HL,
            SubWindowTypeCode.HP
        ];
    }
}
