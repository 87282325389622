import {ProfileInterface, ProfileType} from '../catalog-data/profile-interface';
import {AreaSpecification} from '../drawing-data/AreaSpecification';
import {CutData} from "../drawing-data/CutData";
import {DefiningMullion} from "../drawing-data/DefiningMullion";
import {DrawingData} from "../drawing-data/drawing-data";
import {FieldWithPosition} from "../drawing-data/FieldWithPosition";
import {GrillSegment} from "../drawing-data/GrillSegment";
import {LineCutData} from "../drawing-data/LineCutData";
import {Mullion} from "../drawing-data/Mullion";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {RelativePosition} from "../drawing-data/RelativePosition";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowShape} from "../drawing-data/WindowShape";
import {ConvexHull, DataKeys, DrawingUtil, IntersectionResult, MinMaxXY} from "../drawing-util";
import {WindowParams} from "../painters/WindowParams";
import {ProfilesCompositionDistances, ProfilesCompositionType} from "../profiles-composition-distances";
import {WindowCalculator} from "../window-calculator";
import {AreaUtils} from './AreaUtils';
import {CutsUtil} from "./cutUtils";
import {ErrorNames} from "./ErrorNames";
import {FloatOps} from './float-ops';
import {GrillHelper} from "./grill-helper";
import {GrillSegmentGenerator} from './GrillSegmentGenerator';
import {MullionHelper} from "./MullionHelper";
import {OperationResult} from "./OperationResult";
import {PolygonPoint, PolygonPointUtil} from "./PolygonPoint";
import {PositionsHelper} from "./positions-helper";
import {FillingType} from "../drawing-data/FillingType";
import {Grill} from "../drawing-data/Grill";
import {StaticDataHelper} from "../static-data-helper";
import {GrillInterface} from "../catalog-data/grill-interface";
import {GrillType} from "../drawing-data/GrillType";

export class MullionUtils {
    static readonly MINIMAL_MULLION_DISTANCE = 78;
    static readonly ESG_MINIMAL_MULLION_DISTANCE = 150;

    public static getMullionPoints(mullion: Mullion, totalInnerFrame: number[], subWindow: SubWindowData,
                                   profileCompositionDistances: ProfilesCompositionDistances,
                                   includeGlazingBeads = false, round = true): number[] {
        let mullionPoints = totalInnerFrame;
        let offset = -(mullion.width / 2) - (includeGlazingBeads ? this.getGlazingBeadWidth(profileCompositionDistances) : 0);
        let segment = GrillHelper.getExpectedLineSegment(mullion);
        let tCut = new LineCutData(segment.points, 'top');
        let bCut = new LineCutData(segment.points, 'bottom');
        mullionPoints = CutsUtil.applyCut(mullionPoints, tCut, offset, round);
        mullionPoints = CutsUtil.applyCut(mullionPoints, bCut, offset, round);
        if (subWindow) {
            for (let posIndex in mullion.positions) {
                let pos = mullion.positions[posIndex];
                if (pos.type === PositionReferenceType.MULLION) {
                    let referencedMullion = MullionHelper.findMullionById(pos.id, subWindow);
                    let referencedSegment = referencedMullion.drawingSegments[0];
                    let mullionPointsOption;
                    let otherPositionReference = mullion.positions[(+posIndex + 1) % 2];
                    let otherPoint = PositionsHelper.singleRelToAbs(otherPositionReference, totalInnerFrame, null,
                        subWindow);
                    if (GrillSegment.isLine(referencedSegment)) {
                        let topCut = new LineCutData(referencedSegment.points, 'top');
                        mullionPointsOption = CutsUtil.applyCut(mullionPoints, topCut,
                            (includeGlazingBeads
                                ? MullionUtils.getExternalWidth(referencedMullion.width, profileCompositionDistances) / 4
                                : 0), round);
                        let centroid = DrawingUtil.getPolygonCentroid(mullionPointsOption);
                        if (!DrawingUtil.arePointsOnSameSideOfALine([otherPoint, centroid], referencedSegment.points)) {
                            let bottomCut = new LineCutData(referencedSegment.points, 'bottom');
                            mullionPointsOption = CutsUtil.applyCut(mullionPoints, bottomCut,
                                (includeGlazingBeads
                                    ? MullionUtils.getExternalWidth(referencedMullion.width, profileCompositionDistances) / 4
                                    : 0), round);
                        }
                    }
                    mullionPoints = mullionPointsOption;
                }
            }
        }
        return mullionPoints;
    }

    private static getAllDefiningMullions(subwindow: SubWindowData): DefiningMullion[] {
        return ([].concat.apply([], subwindow.areasSpecification.map(area => area.definingMullions)) as DefiningMullion[])
            .filter((v, i, a) => a.indexOf(v) === i);
    }

    public static findDefiningMullions(mullion: Mullion, subwindow: SubWindowData): DefiningMullion[] {
        let segmentId = GrillHelper.getExpectedLineSegment(mullion).id;
        return MullionUtils.getAllDefiningMullions(subwindow).filter(defMullion => defMullion.id === segmentId);
    }

    public static filterUsableMullions(mullions: ProfileInterface[], mullion: Mullion, subwindow: SubWindowData): ProfileInterface[] {
        if (!mullions) {
            return [];
        }
        let canBeConstructional = MullionHelper.getParentDependencies(mullion, subwindow)
            .every(m => m.isConstructional);
        let canBeNonconstructional = MullionHelper.getDependees(mullion, subwindow).every(m => !m.isConstructional);
        let filtered = mullions.filter(m => (m.type === ProfileType.CONSTRUCTIONAL_MULLION && canBeConstructional) ||
            (m.type === ProfileType.DECORATIVE_MULLION && canBeNonconstructional));
        if (GrillHelper.isDrawnAtAngle(mullion)) {
            filtered = filtered.filter(m => MullionHelper.canBeAngled(m));
        }
        return filtered;
    }

    static isMullionEligibleForVeneering(mullion: Mullion): boolean {
        if (mullion) {
            let segment = mullion.drawingSegments[0];
            if (GrillSegment.isLine(segment)) {
                if (Math.abs(segment.points[1] - segment.points[3]) < 1) { // treshhold 1
                    for (let pos of mullion.positions) {
                        if (pos.type !== PositionReferenceType.INNER_FRAME &&
                            pos.type !== PositionReferenceType.VENEER) {
                            return false;
                        }
                    }
                    return true;
                }
            } else {
                let err = new Error("Unsupported segment type");
                err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
                throw err;
            }
        }
        return false;
    }

    static convertPositionToVeneer(mullion: Mullion, veneerDistance: number, subwindow: SubWindowData, cuts: CutData[],
                                   totalBoundingBox: MinMaxXY, profileCompositionDistances: ProfilesCompositionDistances,
                                   shape: WindowShape): OperationResult {
        let totalInnerFrame = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, cuts, totalBoundingBox,
            profileCompositionDistances, false);
        let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, cuts, totalBoundingBox,
            profileCompositionDistances, false);
        let totalRealPackagePoints = WindowCalculator.getTotalRealGlazingPackagePoints(subwindow, cuts,
            totalBoundingBox, profileCompositionDistances);
        let arePointsIncrementing = (points: number[]) => {
            return points[0] === points[2] ? points[3] > points[1] : points[2] > points[0];
        };
        let directionBeforeChanges;
        let segment = mullion.drawingSegments[0];
        if (GrillSegment.isLine(segment)) {
            directionBeforeChanges = arePointsIncrementing(segment.points);
        } else {
            let err = new Error("Not supported segment type: " + segment.type);
            err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
            throw err;
        }
        let ref = new RelativePosition(PositionReferenceType.VENEER, 0, veneerDistance);
        mullion.positions = [ref];
        if (directionBeforeChanges !== arePointsIncrementing(PositionsHelper.veneerToAbsolute(ref, totalInnerFrame, subwindow.points))) {
            ref.id = 1;
        }
        let operationResult = MullionUtils.updateAbsoluteMullionPositions(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
            shape, true);
        if (AreaUtils.checkIfAreasRedefinitionIsRequired(subwindow, totalGlazingBeads.regular, profileCompositionDistances)) {
            operationResult.merge(
                AreaUtils.createFreshAreas(subwindow, totalGlazingBeads.regular, totalInnerFrame, totalRealPackagePoints,
                    profileCompositionDistances));
        }
        PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, profileCompositionDistances);
        return operationResult;
    }

    private static checkIntersectionsCount(expected: number, intersections: IntersectionResult[]): void {
        if (intersections.length !== expected) {
            let err = new Error("Invalid intersections count: " + intersections.length);
            err.name = ErrorNames.INTERSECTIONS_CALCULATION_FAILED;
            throw err;
        }
    }

    static convertPositionFromVeneer(mullion: Mullion, subwindow: SubWindowData, cuts: CutData[],
                                     totalBoundingBox: MinMaxXY, profileCompositionDistances: ProfilesCompositionDistances,
                                     shape: WindowShape): OperationResult {
        let innerFrame = WindowCalculator.getTotalFrameInnerEdgePointsFull(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
            false);
        let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, cuts, totalBoundingBox,
            profileCompositionDistances, false);
        let relPosition = mullion.positions[0];
        if (relPosition.type === PositionReferenceType.VENEER) {
            let segment = mullion.drawingSegments[0];
            if (GrillSegment.isLine(segment)) {
                let innerFrameRaw = PolygonPointUtil.toNumbersArray(innerFrame);
                let intersections = DrawingUtil.polygonLineIntersections(innerFrameRaw, segment.points, true);
                MullionUtils.checkIntersectionsCount(2, intersections);
                let snap = Snap(0, 0).polygon(innerFrameRaw);
                snap.type = WindowParams.INNER_FRAME_ELEM;
                snap.data(DataKeys.INNER_FRAME, innerFrame);
                snap.addClass(WindowParams.INNER_FRAME_ELEM);
                mullion.positions = [];
                PositionsHelper.addRelativePosition(mullion, snap,
                    PositionsHelper.getVeneerIntersection(0, relPosition, intersections), true);
                PositionsHelper.addRelativePosition(mullion, snap,
                    PositionsHelper.getVeneerIntersection(1, relPosition, intersections), true);
            }
            let result = MullionUtils.updateAbsoluteMullionPositions(subwindow, cuts, totalBoundingBox, profileCompositionDistances, shape,
                true);
            PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, profileCompositionDistances);
            return result;
        }
        return new OperationResult();
    }

    public static getWidth(mullionDto: ProfileInterface): number {
        // mullion should never have distance overrides
        return mullionDto.compositionDistances.defaultWidth;
    }

    public static getGlazingBeadWidth(profileCompositionDistances: ProfilesCompositionDistances): number {
        // glazing bead next to a mullion should never need overrides as it cannot occupy any side of a window
        return profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD);
    }

    public static getInternalWidth(mullionDto: ProfileInterface, profileCompositionDistances: ProfilesCompositionDistances): number {
        return this.getWidth(mullionDto) - (2 * this.getGlazingBeadWidth(profileCompositionDistances));
    }

    public static getExternalWidth(internalWidth: number, profileCompositionDistances: ProfilesCompositionDistances): number {
        return internalWidth + (2 * this.getGlazingBeadWidth(profileCompositionDistances));
    }

    public static getSegmentPoints(mullion: Mullion): number[] {
        return GrillHelper.getExpectedLineSegment(mullion).points;
    }

    public static getSegmentExtendedToPolygon(mullion: Mullion, polygon: number[]): number[] {
        let segment = MullionUtils.getSegmentPoints(mullion);
        if (MullionHelper.isVeneer(mullion)) {
            let intersections = DrawingUtil.polygonLineIntersections(polygon, segment, true);
            MullionUtils.checkIntersectionsCount(2, intersections.filter(i => i.intersects));
            return [intersections[0].x, intersections[0].y, intersections[1].x, intersections[1].y];
        }
        let extendedSegment = [];
        for (let i = 0; i < mullion.positions.length; i++) {
            let segmentPoint = [segment[i * 2], segment[(i * 2) + 1]];
            if (mullion.positions[i].type === PositionReferenceType.INNER_FRAME) {
                let intersections = DrawingUtil.polygonLineIntersections(polygon, segment, true);
                let intersection = DrawingUtil.getIntersectionCloserToOriginalPoint(segmentPoint, intersections);
                extendedSegment.push(...intersection);
            } else {
                extendedSegment.push(...segmentPoint);
            }
        }
        return extendedSegment;
    }

    static validatePointFarEnoughFromPoints(point: number[], corners: PolygonPoint[], minDistance: number): boolean {
        for (let corner of corners) {
            if (DrawingUtil.distance(point, [corner.x, corner.y]) < minDistance) {
                return false;
            }
        }
        return true;
    }

    static getAllCorners(polygonFull: PolygonPoint[], shape: WindowShape): PolygonPoint[] {
        if (WindowShape.isArchSegmental(shape)) {
            let corners = [];
            for (let i = 0; i < polygonFull.length; i++) {
                if (!DrawingUtil.getPoint(polygonFull, i - 1).isArc ||
                    !DrawingUtil.getPoint(polygonFull, i + 1).isArc) {
                    corners.push(polygonFull[i]);
                }
            }
            return corners;
        }
        return polygonFull.filter(p => !p.isArc);
    }

    static updateAllMullionPositions(data: DrawingData, profileCompositionDistances: ProfilesCompositionDistances,
                                     bbox?: MinMaxXY, skipValidation = false): OperationResult {
        if (bbox == null) {
            bbox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        }
        let operationResult = new OperationResult();
        data.windows.forEach(w => w.subWindows.forEach(sw => {
            operationResult.merge(MullionUtils.updateAbsoluteMullionPositions(sw, data.cuts, bbox, profileCompositionDistances,
                data.shape, undefined, skipValidation));
        }));
        return operationResult;
    }

    static validateMullionPositions(subwindow: SubWindowData, totalInnerEdgePoints: PolygonPoint[], totalGlazingBead: number[],
                                    profileCompositionDistances: ProfilesCompositionDistances, shape: WindowShape) {
        let corners = MullionUtils.getAllCorners(totalInnerEdgePoints, shape);
        let mullionPolygonsMap = new Map<Mullion, number[]>();
        let onCornerError = new Error(
            "MullionUtils.validateMullionPositions: Przewiązka nie może opierać się na rogu ramy");
        onCornerError.name = ErrorNames.CANNOT_ADD_MULLION_ON_CORNER;
        subwindow.mullions.forEach(m => {
            mullionPolygonsMap.set(m, MullionUtils.getMullionPoints(m, totalGlazingBead, subwindow, profileCompositionDistances,
                true, false));
        });
        for (let i in subwindow.mullions) {
            let mullion = subwindow.mullions[i];
            let overlapingPolygons = [];
            let extendedSegment = MullionUtils.getSegmentExtendedToPolygon(mullion, subwindow.points);
            corners.forEach(c => {
                if (DrawingUtil.distanceFromLineSegment(extendedSegment, [c.x, c.y]) <
                    (MullionUtils.getExternalWidth(mullion.width, profileCompositionDistances) / 2)) {
                    throw onCornerError;
                }
            });
            for (let j = +i + 1; j < subwindow.mullions.length; j++) {
                let otherMullion = subwindow.mullions[j];
                let overlapingPolygon = DrawingUtil.getPolygonsOverlapPoints(mullionPolygonsMap.get(mullion),
                    mullionPolygonsMap.get(otherMullion));
                if (overlapingPolygon.length === 0) {
                    continue;
                }
                if ((overlapingPolygon.length / 2) % 2 === 1) {
                    throw onCornerError;
                }
                let convex = ConvexHull.performGrahamScan(overlapingPolygon);
                overlapingPolygons.forEach(op => {
                    if (DrawingUtil.doPolygonsOverlap(op, convex)) {
                        throw onCornerError;
                    }
                });
                if (convex.length !== 8) {
                    console.debug("wyznaczony obszar nie jest czworokątem!");
                    throw onCornerError;
                }
                // W tym miejscu powinniśmy mieć czworokąt
                // Czworokąt jest równoległobokiem wtedy i tylko wtedy, gdy jego przekątne dzielą się na połowy.
                let A = [convex[0], convex[1]];
                let B = [convex[2], convex[3]];
                let C = [convex[4], convex[5]];
                let D = [convex[6], convex[7]];

                let P = [(A[0] + C[0]) / 2, (A[1] + C[1]) / 2];
                let Q = [(B[0] + D[0]) / 2, (B[1] + D[1]) / 2];

                if (!FloatOps.eq(P[0] - Q[0], 0)) {
                    throw onCornerError;
                }
                if (!FloatOps.eq(P[1] - Q[1], 0)) {
                    throw onCornerError;
                }

                overlapingPolygons.push(convex);
            }
        }
        MullionUtils.validateMullionsCrossing(subwindow.mullions, profileCompositionDistances);
        MullionUtils.validateSpacingBetweenMullions(subwindow, totalGlazingBead, profileCompositionDistances);
    }

    private static validateMullionsCrossing(mullions: Mullion[], profileCompositionDistances: ProfilesCompositionDistances) {
        if (profileCompositionDistances.additional.allowMullionCrossing) {
            return;
        }
        for (let i = 1; i < mullions.length; i++) {
            if (MullionHelper.areCoDependent(mullions[i - 1], mullions[i])) {
                continue;
            }
            let intersection = DrawingUtil.lineIntersection(MullionUtils.getSegmentPoints(mullions[i - 1]),
                MullionUtils.getSegmentPoints(mullions[i]));
            if (intersection.onLine1 && intersection.onLine2) {
                let mullionsCrossingNotAllowed = new Error(
                    "MullionUtils.validateMullionPositions: W danym systemie przewiązki nie mogą się przecinać");
                mullionsCrossingNotAllowed.name = ErrorNames.MULLIONS_CROSSING_NOT_ALLOWED;
                throw mullionsCrossingNotAllowed;
            }
        }
    }

    public static validateSpacingBetweenMullions(subwindow: SubWindowData, totalGlazingBead: number[],
                                                 profileCompositionDistances: ProfilesCompositionDistances): void {
        if (!totalGlazingBead.length) {
            return;
        }
        let bbox = DrawingUtil.calculateMinMaxFromPolygon(totalGlazingBead, false);
        type MullionInfo = { pos: number, width: number, min: number, max: number };
        let posWithWidth = (pos: number, width: number, points: number[]): MullionInfo => {
            return {
                pos: points[pos],
                width: width,
                min: Math.min(DrawingUtil.getPoint(points, pos + 1), DrawingUtil.getPoint(points, pos + 3)),
                max: Math.max(DrawingUtil.getPoint(points, pos + 1), DrawingUtil.getPoint(points, pos + 3))
            };
        };
        let getSortedMullionInfo = (filter: (m: Mullion) => boolean, pos: number) => {
            return subwindow.mullions.filter(m => filter(m))
                .map(m =>
                    posWithWidth(pos, MullionUtils.getExternalWidth(m.width / 2, profileCompositionDistances),
                        GrillHelper.getExpectedLineSegment(m).points))
                .sort((a, b) => a.pos - b.pos);
        };
        let horizontalMullionsInfo = getSortedMullionInfo(GrillHelper.isHorizontal, 1);
        let verticalMullionsInfo = getSortedMullionInfo(GrillHelper.isVertical, 0);

        let distanceError = (badFramesCount: number) => {
            let errorPrefix = "MullionUtils.validateSpacingBetweenMullions: ";
            let err = new Error();
            switch (badFramesCount) {
                case 0:
                    err.message = errorPrefix + "Przewiązka znajduje się za blisko innej przewiązki";
                    err.name = ErrorNames.MULLION_TO_CLOSE_TO_ANOTHER_MULLION;
                    break;
                case 1:
                    err.message = errorPrefix + "Przewiązka znajduje się za blisko ramy";
                    err.name = ErrorNames.MULLION_TO_CLOSE_TO_FRAME;
                    break;
                case 2:
                    err.message = errorPrefix + "Swiatlo szyby jest zbyt male";
                    err.name = ErrorNames.FRAMES_TO_CLOSE;
                    break;
                default:
                    err.message = errorPrefix + "Unsupported badFramesCount: " + badFramesCount;
                    err.name = ErrorNames.GENERAL_ERROR;
                    break;
            }
            throw err;
        };
        let areas = subwindow.areasSpecification.map(area => {
            return {
                pos: WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, totalGlazingBead, profileCompositionDistances),
                esg: area.glazing.isESG
            };
        });

        let distance = (min: number, max: number, horizontal: boolean) => {
            let isESG = areas.filter(
                a => a.pos.some((pos, index) => (index % 2 === (horizontal ? 1 : 0)) && (min <= pos && pos < max)))
                .some(a => a.esg);
            return (isESG ? MullionUtils.ESG_MINIMAL_MULLION_DISTANCE : MullionUtils.MINIMAL_MULLION_DISTANCE);
        };

        let validate = (mullions: MullionInfo[], minFrame: { pos: number, width: number },
                        maxFrame: { pos: number, width: number }, horizontal: boolean) => {
            let framesTooClose = (maxFrame.pos - minFrame.pos) < distance(minFrame.pos, maxFrame.pos, horizontal);
            if (framesTooClose) {
                distanceError(2);
            }
            for (let i = 0; i < mullions.length; i++) {
                let currentMullion = mullions[i];
                let significantMullions = mullions.filter(
                    m => !((currentMullion.max <= m.min) || (currentMullion.min >= m.max)));
                let sorted = [minFrame, ...significantMullions, maxFrame].sort((a, b) => a.pos - b.pos);
                let index = sorted.indexOf(currentMullion);
                let prevTooClose = (sorted[index].pos - sorted[index - 1].pos) <
                    distance(sorted[index - 1].pos, sorted[index].pos, horizontal) + sorted[index].width +
                    sorted[index - 1].width;
                let nextTooClose = (sorted[index + 1].pos - sorted[index].pos) <
                    distance(sorted[index].pos, sorted[index + 1].pos, horizontal) + sorted[index].width +
                    sorted[index + 1].width;
                if (prevTooClose || nextTooClose) {
                    let badFrameCount = (prevTooClose && index === 1) || (nextTooClose && index === sorted.length - 2) ? 1 : 0;
                    distanceError(badFrameCount);
                }
            }
        };

        validate(horizontalMullionsInfo, {pos: bbox.minY, width: 0}, {pos: bbox.maxY, width: 0}, true);
        validate(verticalMullionsInfo, {pos: bbox.minX, width: 0}, {pos: bbox.maxX, width: 0}, false);
    }

    public static repositionMullionsAndGrills(data: DrawingData,
                                              profileCompositionDistances: ProfilesCompositionDistances,
                                              oldFramePoints: Map<AreaSpecification, number[]>,
                                              oldGlazingBeads: Map<AreaSpecification, number[]>,
                                              skipValidation: boolean, forceY = false): OperationResult {
        let boundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        let operationResult = new OperationResult();
        for (let window of data.windows) {
            for (let subWindow of window.subWindows) {
                let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subWindow, data.cuts, boundingBox,
                    profileCompositionDistances, skipValidation);
                operationResult.merge(MullionUtils.updateAbsoluteMullionPositions(subWindow, data.cuts, boundingBox,
                    profileCompositionDistances, data.shape, false, skipValidation, false, forceY));
                if (!skipValidation) {
                    for (let area of subWindow.areasSpecification) {
                        if (GrillHelper.areaHasGrills(area)) {
                            let glazingPoints = WindowCalculator.getGlazingBeadPoints(subWindow, area.definingMullions,
                                totalGlazingBeads.regular, profileCompositionDistances);
                            let framePoints = CutsUtil.applyCuts(subWindow.points, data.cuts, 0);
                            PositionsHelper.checkForGlazingBeadChanges(framePoints, glazingPoints, oldFramePoints.get(area),
                                oldGlazingBeads.get(area));
                        }
                    }
                }
                PositionsHelper.updateAbsolutePositions(subWindow, totalGlazingBeads, profileCompositionDistances,
                    false, skipValidation);
                if (subWindow.wing != undefined) {
                    AreaUtils.recalculateWingSize(subWindow, data.cuts, boundingBox, profileCompositionDistances, skipValidation);
                }
            }
        }
        return operationResult;
    }

    public static updateAbsoluteMullionPositions(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                                 profileCompositionDistances: ProfilesCompositionDistances,
                                                 shape: WindowShape, completeCheck?: boolean, skipValidation = false,
                                                 addNewMullionMode = false, forceY = false): OperationResult {
        let operationResult: OperationResult = new OperationResult();
        cuts = cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subWindow.points, cut));
        let totalInnerEdgePointsFull = WindowCalculator.getTotalFrameInnerEdgePointsFull(subWindow, cuts, totalBoundingBox,
            profileCompositionDistances, skipValidation);
        let totalInnerEdgePoints = PolygonPointUtil.toNumbersArray(totalInnerEdgePointsFull);
        let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances,
            skipValidation);
        let totalRealPackagePoints = WindowCalculator.getTotalRealGlazingPackagePoints(subWindow, cuts,
            totalBoundingBox, profileCompositionDistances);
        let map: Map<number, GrillSegment> = new Map();
        for (let mullion of subWindow.mullions) {
            GrillHelper.initSegmentsIfNeeded(mullion);
            mullion.positionsPoints = PositionsHelper.relativeToAbsolute(mullion.positions, totalInnerEdgePoints, map,
                subWindow.points, forceY);
            GrillSegmentGenerator.updateMullionSegments(<Mullion> mullion);
            map.set(mullion.drawingSegments[0].id, mullion.drawingSegments[0]);
        }
        if (!skipValidation) {
            MullionUtils.validateMullionPositions(subWindow, totalInnerEdgePointsFull, totalGlazingBead, profileCompositionDistances,
                shape);
        }
        AreaUtils.setNewAreaSizes(subWindow, totalGlazingBead, totalInnerEdgePoints, totalRealPackagePoints, profileCompositionDistances);
        if (completeCheck && (AreaUtils.checkIfAnyAreasAreOverlapping(subWindow, totalGlazingBead,
            profileCompositionDistances) ||
            AreaUtils.checkIfAreasRedefinitionIsRequired(subWindow, totalGlazingBead, profileCompositionDistances))) {
            operationResult.merge(AreaUtils.createFreshAreas(subWindow, totalGlazingBead, totalInnerEdgePoints,
                totalRealPackagePoints, profileCompositionDistances));
        }
        AreaUtils.removeUnusedDefiningMullionReferences(subWindow, totalGlazingBead, profileCompositionDistances);
        GrillSegmentGenerator.createPricingSegments(subWindow.mullions, totalInnerEdgePointsFull, addNewMullionMode,
            skipValidation, true);
        MullionUtils.setObliqueFields(subWindow, totalInnerEdgePoints);

        return operationResult;
    }

    private static setObliqueFields(subwindow: SubWindowData, totalInnerEdgePoints: number[]): void {
        let areasMappedToFields = subwindow.areasSpecification.map(area => new FieldWithPosition(
            WindowCalculator.getFrameInnerEdgePoints(subwindow, area.definingMullions, totalInnerEdgePoints),
            area.definingMullions.map(dm => dm.id)));
        PositionsHelper.usePricingSegmentsInFields(subwindow.mullions, areasMappedToFields);
        for (let i = 0; i < areasMappedToFields.length; i++) {
            subwindow.areasSpecification[i].mullionObliqueField = areasMappedToFields[i].grillSegmentIds.some(
                id => GrillHelper.expectPricingSegment(GrillHelper.findGrillSegmentById(subwindow.mullions, id)).angled);
        }
    }

    public static onMullionWidthChange(mullion: Mullion, oldWidth: number): void {
        if (MullionHelper.isVeneer(mullion)) {
            let newVeneerDistance = mullion.positions[0].percent - ((oldWidth - mullion.width) / 2);
            mullion.positions = [new RelativePosition(PositionReferenceType.VENEER, mullion.positions[0].id, newVeneerDistance)];
        }
    }

    public static canHaveMullions(subwindow: SubWindowData): boolean {
        return !GrillHelper.subwindowContainsGrills(subwindow) && !this.subwindowContainsDecorativeFillings(subwindow);
    }

    public static isSubwindowValidForNewMullion(pendingOperationLineHelper: Snap.Element, subwindow: SubWindowData): boolean {
        let targetSubwindow = pendingOperationLineHelper == undefined ? undefined : pendingOperationLineHelper.data(DataKeys.SUBWINDOW);
        return targetSubwindow == undefined || targetSubwindow === subwindow;
    }

    private static subwindowContainsDecorativeFillings(subwindow: SubWindowData): boolean {
        return subwindow.areasSpecification.some(area => area.filling.type === FillingType.DECORATIVE_FILLING);
    }

    public static setGrillOrMullionData(grill: Grill, staticData: StaticDataHelper,
                                        profileCompositionDistances: ProfilesCompositionDistances): void {
        let dto: GrillInterface | ProfileInterface;
        switch (grill.type) {
            case GrillType.LINE_GRILL:
            case GrillType.GRID_STANDARD:
            case GrillType.GRID_CROSS_SIMPLE:
            case GrillType.GRID_CROSS_ANGLED:
            case GrillType.GRID_RHOMBUS:
                dto = staticData.grills.find(g => g.id === grill.id) as GrillInterface;
                if (dto != null) {
                    grill.width = dto.width;
                    grill.canBeAngled = dto.angled;
                    grill.constructionType = dto.type;
                }
                break;
            case GrillType.MULLION:
                dto = staticData.mullions.find(m => m.id === grill.id) as ProfileInterface;
                if (dto != null) {
                    grill.width = MullionUtils.getInternalWidth(dto, profileCompositionDistances);
                    (grill as Mullion).isConstructional = (dto.type === ProfileType.CONSTRUCTIONAL_MULLION);
                    grill.canBeAngled = MullionHelper.canBeDrawnAtAnAngle(grill as Mullion);
                }
                break;
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
        if (dto == null) {
            grill.width = undefined;
            grill.canBeAngled = undefined;
        }
    }
}
