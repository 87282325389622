<div class="list-content">
    <div class="table-header">
        <div class="title">
            {{ 'NAVIGATION.ITEMS.COMPLAINT_LIST' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">

                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <!-- SHOW ALL -->
                <app-simple-button id="clearFilters" *ngIf="isShowAllButtonVisible()"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="showAll()"></app-simple-button>

                <!-- SHOW OWN -->
                <app-simple-button *ngIf="isOwnOffersFilterVisible()"
                                   label="{{ 'OFFER.COMPLAINT.ACTIONS.TOOLTIPS.SHOW_OWN_COMPLAINTS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByOwnedComplaints()"></app-simple-button>

                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.COMPLAINT_LIST')"></app-simple-button>

                <p-multiSelect id="chooseColumnsMenu" defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                               [displaySelectedLabel]="false" [options]="columnOptions | selectItemTranslate"
                               [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>

                <app-simple-button *ngIf="showPrintButton" icon="print" type="action"
                                   label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}"
                                   (onClick)="openPrintDialog()"></app-simple-button>

                <app-simple-button icon="info" type="action" align="right"
                                   label="{{ 'OFFER.COMPLAINT.MANUAL.HEADER' | translate }}"
                                   (onClick)="manualDialogVisible = true"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="datatable-container">
        <p-dataTable #dt [value]="complaints" [rows]="chosenRowsPerPage" [id]="TABLE_ID"
                     [rowsPerPageOptions]="limitedRowsPerPageOptions"
                     [paginator]="true" [seqNum]="1" [(first)]="chosenPageNumber"
                     [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                     (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                     [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                     [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate"
                     [(selection)]="selectedItem" (onRowDblclick)="rowDoubleClick($event)">

            <p-column [style]="{ 'vertical-align': 'middle' }">
                <ng-template pTemplate="header">
                    <app-tristate-checkbox [(ngModel)]="allSelectedState" (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
                </ng-template>

                <ng-template let-position="rowData" pTemplate="body">
                    <span class="flex-row">
                        <app-checkbox [ngModel]="isSelectedItem(position)" (ngModelChange)="selectItem(position)"></app-checkbox>
                    </span>
                </ng-template>
            </p-column>

            <!-- ACTIONS -->
            <p-column header="{{ 'OFFER.COMPLAINT.LIST.ACTIONS' | translate }}" [sortable]="false">
                <ng-template let-complaint="rowData" pTemplate="body">
                    <div class="table-row-action-buttons">
                        <app-simple-button buttonId="add" icon="search" type="main-action" [size]="36"
                                           label="{{'OFFER.COMPLAINT.ACTIONS.TOOLTIPS.SHOW_COMPLAINT_DETAILS' | translate}}" [inlineLabel]="false"
                                           (onClick)="showDetails(complaint.id)"></app-simple-button>

                        <button-with-menu [buttonId]="'more_' + complaint.id" rightButtonIcon="more_horiz"
                                          buttonType="filter" [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                          buttonLabel="{{'OFFER.COMPLAINT.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate}}" [buttonInlineLabel]="false"
                                          [menuElements]="buildRowActions(complaint)"
                                          (menuElementSelected)="handleRowAction($event)"></button-with-menu>
                    </div>
                </ng-template>
            </p-column>

            <ng-container *ngFor="let column of columns">
                <p-column *ngIf="column.field == 'complaintNumber'"
                          [field]="column.field" [header]="column.header | translate"
                          [sortable]="column.sortable" [filter]="column.filter"
                          [defaultFilterValue]="column.defaultFilterValue">
                </p-column>

                <!-- STATUS -->
                <p-column *ngIf="column.field == 'status'"
                          [field]="column.field" [header]="column.header | translate"
                          [sortable]="false" [filter]="false"
                          [defaultFilterValue]="column.defaultFilterValue">

                    <ng-template pTemplate="filter">
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                            <p-multiSelect [options]="availableStatuses | selectItemTranslate"
                                           defaultLabel="{{ 'OFFER.COMPLAINT.LIST.STATUS' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedStatuses"
                                           (ngModelChange)="handleStatusFilterChange($event)"></p-multiSelect>
                        </div>
                    </ng-template>

                    <ng-template let-item="rowData" pTemplate="body">
                        <div [ngClass]="'status-color ' + item.status">
                            {{ 'OFFER.COMPLAINT.FORM.STATUS.' + item.status | translate }}
                        </div>
                    </ng-template>
                </p-column>

                <p-column *ngIf="column.field == 'createdDate' || column.field == 'sendToVerifyDate' || column.field == 'expectedShippingDate'"
                          [field]="column.field" [sortable]="column.sortable" styleClass="datatable-date-range-column"
                          [filter]="column.filter" [defaultFilterValue]="column.defaultFilterValue"
                          header="{{ column.header | translate }}">

                    <ng-template pTemplate="filter">
                        <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
                    </ng-template>

                    <ng-template let-item="rowData" pTemplate="body">
                        {{ item[column.field] | momentCurrentLang | amDateFormat: 'L LT' }}
                    </ng-template>
                </p-column>

                <p-column *ngIf="column.field === 'offerSubsystemName'" [field]="column.field"
                          [header]="column.header | translate" [sortable]="true"
                          [defaultFilterValue]="column.defaultFilterValue">
                    <ng-template pTemplate="filter">
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                            <p-multiSelect [options]="availableSubsystems"
                                           defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                           (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                        </div>
                    </ng-template>
                </p-column>

                <p-column *ngIf="column.field === 'clientManagerName'" [field]="column.field"
                          [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                          [defaultFilterValue]="column.defaultFilterValue">
                    <ng-template let-item="rowData" pTemplate="body">
                        {{ item.clientManagerFirstName + ' ' + item.clientManagerLastName}}
                    </ng-template>
                </p-column>

                <p-column *ngIf="column.field === 'subClientManagerName'" [defaultFilterValue]="column.defaultFilterValue"
                          [field]="column.field" [filter]="column.filter" [header]="column.header | translate"
                          [sortable]="column.sortable">
                    <ng-template let-item="rowData" pTemplate="body">
                        <span *ngIf="item.subClientManagerFirstName && item.subClientManagerLastName">
                            {{ item.subClientManagerFirstName + ' ' + item.subClientManagerLastName}}
                        </span>
                    </ng-template>
                </p-column>

            </ng-container>
            <ng-template pTemplate="paginatorLeft">
                <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
            </ng-template>
        </p-dataTable>
    </div>

    <app-confirm-action-dialog *ngIf="displayConfirmDeleteDialog" (onCancel)="hideConfirmDeleteDialog()"
                               (onConfirmed)="deleteComplaint()"
                               [question]="'GENERAL.DELETE_CONFIRMATION' | translate"
                               [header]="'GENERAL.DIALOG.DELETING' | translate"></app-confirm-action-dialog>

    <!-- COMMENTS DIALOG -->
    <div class="dialog-window" *ngIf="displayCommentsDialog">
        <app-comment-dialog [complaintId]="this.currentActionComplaint.id"
                            [mode]="commentDialogMode"
                            (onCloseDialog)="resetCommentDialog($event)"
                            (onSaveCommentSuccess)="showActionSuccessMessage('ADD_COMMENT')"></app-comment-dialog>
    </div>

    <app-offer-status-transition-dialog *ngIf="transitionsDialogVisible"
                                        [possibleTransitions]="possibleTransitions"
                                        infoLabelPrefix="OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS_TRANSITION_INFO.CONTENT."
                                        (onHide)="hideTransitionsDialog()"
                                        (onChangeStatus)="changeStatus($event)">
    </app-offer-status-transition-dialog>

    <app-print-dialog *ngIf="printDialogVisible" [printableSection]="PrintableSection.COMPLAINTS" [selectedItems]="printableItems"
                      (onCloseDialog)="printDialogVisible = false"></app-print-dialog>

    <app-info-dialog *ngIf="manualDialogVisible"
                     header="{{ 'OFFER.COMPLAINT.MANUAL.HEADER' | translate }}"
                     text="{{ 'OFFER.COMPLAINT.MANUAL.TEXT' | translate }}"
                     (onHide)="manualDialogVisible = false"></app-info-dialog>
</div>
