import * as _ from "underscore";

export class OperationResult {
    deletedConfigurableAddonIds: number[];
    areasChanged: boolean;
    validationGrowls: string[];

    constructor(deletedConfigurableAddonIds: number[] = [],
                areasChanged: boolean = false,
                validationGrowls: string[] = []) {
        this.deletedConfigurableAddonIds = deletedConfigurableAddonIds;
        this.areasChanged = areasChanged;
        this.validationGrowls = validationGrowls;
    }

    addIds(deletedConfigurableAddonIds: number[]): void {
        this.deletedConfigurableAddonIds =
            _.unique(this.deletedConfigurableAddonIds.concat(deletedConfigurableAddonIds));
    }

    addGrowls(validationGrowls: string[]): void {
        this.validationGrowls = _.unique(this.validationGrowls.concat(validationGrowls));
    }

    merge(otherResult: OperationResult): void {
        this.addIds(otherResult.deletedConfigurableAddonIds);
        this.areasChanged = this.areasChanged || otherResult.areasChanged;
        this.addGrowls(otherResult.validationGrowls);
    }
}
