import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {EntranceGlazingPackage} from "../entrance-glazing-package";
import {LinkableEntities} from "../../link-selection/link-selection.component";
import {ItemForCatalogLinking} from "../../single-system-checkbox-crud/item-for-catalog-linking";

@Component({
    selector: 'app-entrance-glazing-package-models-form',
    templateUrl: './entrance-glazing-package-models-form.component.html',
    styleUrls: ['../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceGlazingPackageModelsComponent {

    @Input()
    item: EntranceGlazingPackage;

    @Input()
    entranceModels: ItemForCatalogLinking[];

    @Input()
    linkedModelIds: number[];

    @Input()
    disabled: boolean;

    @Output()
    linkedModelIdsChange = new EventEmitter<number[]>();

    LinkableEntity = LinkableEntities;

    constructor(public changeDetector: ChangeDetectorRef) {
    }

    onSelectedModelsChange(linkedSystemIds: number[]) {
        this.linkedModelIdsChange.emit(linkedSystemIds);
        this.linkedModelIds = linkedSystemIds;
        this.changeDetector.markForCheck();
    }
}
