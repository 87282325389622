import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {AbstractGlazingPackageService} from './abstract-glazing-package.service';
import {GlazingPackage, GlazingPackageTarget} from './glazing-package';

@Injectable()
export class GlazingPackageService extends AbstractGlazingPackageService<GlazingPackage> {

    constructor(http: HttpClient, dataServiceHelper: DataServiceHelper) {
        super(http, dataServiceHelper);
    }

    getApiUrl() {
        return AbstractGlazingPackageService.COMMON_API_URL;
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        params['targets'] = [GlazingPackageTarget.UPSELLING_GLAZING, GlazingPackageTarget.DECORATIVE_GLAZING,
            GlazingPackageTarget.SYSTEM_DEFINITION, GlazingPackageTarget.TERRACE_SYSTEM]
            .map(target => `${GlazingPackageTarget[target]}`);
        return this.http.get<CatalogItemName[]>(`${AbstractGlazingPackageService.COMMON_API_URL}/names`, {params: params});
    }
}
