<app-wizard #wizard header="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.ADD' | translate }}" (onCancel)="exitWizard()"
            (onComplete)="submit()" (currentStepChange)="onStepChange()">
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.DETAILS_STEP' | translate }}" [id]="STEPS.FILL_DETAILS"
                     [validate]="validateDetailsStep">
        <app-subsystem-details-form [validationErrors]="validationErrors" [subsystem]="subsystem"
                                    [availableManagers]="availableManagers" [subsystemGroups]="subsystemGroups"
                                    [disabledFields]="false"></app-subsystem-details-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.ADDRESS_LIST_STEP' | translate }}" [id]="STEPS.FILL_ADDRESS_LIST"
                     [validate]="validateAddressListStep">
        <app-subsystem-address-list-form [(addresses)]="addresses" [canEdit]="true"
                                         [usedAddresses]="[selectedCorrespondenceAddress, selectedDeliveryAddress]"
                                         [validationErrors]="validationErrors"></app-subsystem-address-list-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.ADDRESS_STEP' | translate }}" [id]="STEPS.FILL_ADDRESS"
                     [validate]="validateAddressStep">
        <app-subsystem-address-form [validationErrors]="validationErrors" [subsystem]="subsystem"
                                    [addresses]="addresses" [(correspondenceAddress)]="selectedCorrespondenceAddress"
                                    [(deliveryAddress)]="selectedDeliveryAddress"
                                    [disabledFields]="false"></app-subsystem-address-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.SUPPLIERS' | translate }}" [id]="STEPS.FILL_SUPPLIERS">
        <app-subsystem-inactive-suppliers-form [subsystem]="subsystem"></app-subsystem-inactive-suppliers-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_SYSTEMS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                               entityType="subsystem" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS_ROOF' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ROOF_SYSTEMS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                               entityType="subsystem" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS_ENTRANCE' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                               entityType="subsystem" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ADDONS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-subsystem-addon-profit-margin #childTable entityType="subsystem"
                                               [subsystemGroupId]="subsystem.subsystemGroupId"
                                               [subsystemId]="subsystem.id"
                                               class="app-subsystem-addon-profit-margin-bulk-addons"
                                               [userCanEdit]="true"
                                               [globalMarginUpdateButtonId]="addonGlobalMarginButtonId"
                                               [globalMarginUpdateInputId]="addonGlobalMarginInputId"></app-subsystem-addon-profit-margin>
        </div>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_CONFIG_ADDONS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                               entityType="subsystem" [userCanEdit]="true"
                               class="app-subsystem-addon-profit-margin-config-addons"
                               [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="confAddGlobalMarginInputId"
                               [target]="possibleTargets.CONFIG"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.BULK_CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.BULK_CONFIG_ADDON_MARGINS">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                               entityType="subsystem" [userCanEdit]="true"
                               class="app-subsystem-addon-profit-margin-bulk-config-addons"
                               [globalMarginUpdateButtonId]="bulkConfAddGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="bulkConfAddGlobalMarginInputId"
                               [target]="possibleTargets.CONFIG_BULK"></app-profit-margin>
        </div>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CATALOG_CHARGE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_CATALOG">

        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-catalog-charge-profit-margin #childTable entityType="subsystem"
                                              [subsystemGroupId]="subsystem.subsystemGroupId"
                                              [subsystemId]="subsystem.id"
                                              [globalMarginUpdateButtonId]="catalogChargeGlobalMarginButtonId"
                                              [globalMarginUpdateInputId]="catalogChargeGlobalMarginInputId"
                                              [canEdit]="true"></app-catalog-charge-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.GATES_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_GATES">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId"
                               [subsystemId]="subsystem.id" entityType="subsystem" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="gatesGlobalMarginInputId" [target]="possibleTargets.GATE"></app-profit-margin>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.WINDOW_SYSTEM_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.FILL_SELL_DEALER_DISCOUNT_SYSTEM">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-subsystem-window-system-sell-dealer-discount #childTable [id]="subsystem.id"
                                                              [groupId]="subsystem.subsystemGroupId" [canEdit]="true"
                                                              [globalMarginUpdateInputId]="systemGlobalDiscountInputId"
                                                              [globalMarginUpdateButtonId]="systemGlobalDiscountButtonId"
                                                              [validationErrors]="validationErrors"></app-subsystem-window-system-sell-dealer-discount>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CONFIG_ADDON_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.FILL_SELL_DEALER_DISCOUNT_CONFIG_ADDON">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-subsystem-configurable-addon-sell-dealer-discount #childTable [id]="subsystem.id"
                                                                   [groupId]="subsystem.subsystemGroupId"
                                                                   [canEdit]="true"
                                                                   [globalMarginUpdateInputId]="configAddonGlobalDiscountInputId"
                                                                   [globalMarginUpdateButtonId]="configAddonGlobalDiscountButtonId"
                                                                   [validationErrors]="validationErrors"></app-subsystem-configurable-addon-sell-dealer-discount>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.SUPPLIER_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.FILL_SELL_DEALER_DISCOUNT_SUPPLIER">
        <div class="new-form-60vw" *ngIf="subsystem.subsystemGroupId != undefined">
            <app-subsystem-supplier-sell-dealer-discount #childTable [id]="subsystem.id"
                                                         [groupId]="subsystem.subsystemGroupId" [canEdit]="true"
                                                         [globalMarginUpdateInputId]="supplierGlobalDiscountInputId"
                                                         [globalMarginUpdateButtonId]="supplierGlobalDiscountButtonId"
                                                         [validationErrors]="validationErrors"></app-subsystem-supplier-sell-dealer-discount>
        </div>
    </app-wizard-step>
</app-wizard>
