import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from "primeng/api/selectitem";
import {TabView} from "primeng/tabview";
import {EMPTY, forkJoin, from, Observable, of} from "rxjs";
import {catchError, finalize, map, mergeMap, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {AddonInterface} from '../../../../../window-designer/catalog-data/addon-interface';
import {PositionListAddon} from '../../../../../window-designer/catalog-data/position-list-addon';
import {DoorSide, OpeningOption} from "../../../../../window-designer/catalog-data/window-system-interface";
import {WindowAddon} from "../../../../../window-designer/drawing-data/WindowAddon";
import {WindowDimensions, WindowDimensionsUtils} from "../../../../../window-designer/entities/window-dimensions";
import {AddonCategoryEnum} from "../../../../../window-designer/enums/AddonCategoryEnum";
import {AddonFor} from "../../../../../window-designer/enums/AddonFor";
import {WindowAddonCalculationMode} from "../../../../../window-designer/enums/WindowAddonCalculationMode";
import {
    AddonDefaultQuantityCalculator
} from "../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {WindowAddonMapper} from "../../../../../window-designer/utils/WindowAddonMapper";
import {WindowAddonUtils} from '../../../../../window-designer/utils/WindowAddonUtils';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {Permissions} from "../../../../auth/permission.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {ColorType} from "../../../../ColorType";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {Listing} from "../../../../common/crud-common/crudItemList";
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {MissingProfitMarginHandlerService} from '../../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service';
import {ValidationErrors} from "../../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../../common/ValidationErrorsHelper";
import {SidenavController} from "../../../../sidenav-controller";
import {MultilanguageField, SupportedLanguages} from "../../../../supportedLanguages";
import {CatalogConfiguration} from "../../../catalog-creator/catalog-configuration";
import {CatalogConfigurationService} from "../../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../../catalog-creator/CatalogPropertyTarget";
import {Addon} from "../../../window-system/addons/addon";
import {AddonsService} from "../../../window-system/addons/addons.service";
import {Color} from "../../../window-system/color/color";
import {ColorService} from "../../../window-system/color/color.service";
import {DecorativeFillingsService} from "../../../window-system/decorative-filling/decorative-filling.service";
import {EntranceGlazingPackageService} from '../../../window-system/entrance-glazing-package/entrance-glazing-package.service';
import {EntranceModel} from '../../../window-system/entrance-model/entrance-model';
import {CatalogItemExtended} from "../../../window-system/entrance-model/entrance-model-basic";
import {EntranceModelService} from '../../../window-system/entrance-model/entrance-model.service';
import {GlassService} from "../../../window-system/glass/glass.service";
import {GlazingBeadService} from "../../../window-system/glazing-bead/glazing-bead.service";
import {GrillService} from "../../../window-system/grill/grill.service";
import {Profile} from "../../../window-system/profile/profile";
import {ProfileService} from "../../../window-system/profile/profile.service";
import {RoofGlazingPackageService} from "../../../window-system/roof-glazing-package/roof-glazing-package.service";
import {WindowDimensionsService} from "../../../window-system/window-dimensions/window-dimensions.service";
import {WindowSystemDefinition} from "../../../window-system/window-system-definition/window-system-definition";
import {WindowSystemDefinitionService} from "../../../window-system/window-system-definition/window-system-definition.service";
import {ProductTypeGroup} from '../../../window-system/window-system-definition/product-type-group';
import {OffersService} from "../../offer-service";
import {MessageSeverity, PositionMessage} from "../../offers/message";
import {
    WindowAddonActionType,
    WindowAddonSaveData
} from '../../offers/position/position-list/add-bulk-addon-position/addon-position/addon-position.component';
import {Position, PositionFactory} from "../../offers/position/position-list/position";
import {PositionService} from "../../offers/position/position.service";
import {ProductionOrderService} from "../../production-orders/production-order-service";
import {ProductionOrdersPositionService} from "../../production-orders/production-orders-position/production-orders-position.service";
import {DrawingToolControlsMode} from "../drawing-tool-controls/drawing-tool-controls.component";
import {ProfitMarginExistance} from "../drawing-tool/ProfitMarginExistance";
import {RoofWindowFieldUsage, SidebarSection} from "../fieldUsage";
import {SidebarHelper} from "../sidebar-helper";
import {Pricing} from "../sidebar/pricing/Pricing";
import {PricingComponent} from "../sidebar/pricing/pricing.component";
import {PricingService} from "../sidebar/pricing/pricing.service";
import {Product} from "../sidebar/pricing/Product";
import {ResponseStatusFlags, ResponseStatusHelper} from "../sidebar/ResponseStatusFlags";
import {SelectItemFormatters} from "../sidebar/select-item-formatters";
import {SidebarFieldImageService} from "../sidebar/sidebar-field-image.service";
import {ValidationComponent} from "../sidebar/validation/validation.component";
import {WindowComponentPreviewData} from "../window-component-preview-dialog/window-component-preview-dialog.component";
import {WindowEditorField} from '../window-editor-field';
import {WindowEditorOfferData, WindowEditorPositionData, WindowEditorProductionOrderData} from '../window-editor-offer-interfaces';
import {EntranceDoorData} from './entrance-door-data';
import {GlazingPackageData} from './glazing-package-data';
import {RoofWindowData} from "./RoofWindowData";

@Component({
    selector: 'app-roof-window-editor',
    templateUrl: './roof-window-editor.component.html',
    styleUrls: ['../common/designers.css', './roof-window-editor.component.css'],
    providers: [
        AddonsService,
        CatalogConfigurationService,
        ColorService,
        DecorativeFillingsService,
        GlassService,
        GlazingBeadService,
        RoofGlazingPackageService,
        EntranceGlazingPackageService,
        GrillService,
        OffersService,
        PositionService,
        PricingService,
        ProfileService,
        ProductionOrdersPositionService,
        ProductionOrderService,
        WindowDimensionsService,
        EntranceModelService,
        SidebarFieldImageService,
        WindowSystemDefinitionService,
        MissingProfitMarginHandlerService
    ]
})
export class RoofWindowEditorComponent implements OnInit, OnDestroy {

    private static readonly BLOCK_SOURCE_ID = 'RoofWindowEditor';

    private addonsService: AddonsService;
    private blockUiController: BlockUiController;
    private errors: CommonErrorHandler;
    private roofGlazingPackageService: RoofGlazingPackageService;
    private entranceGlazingPackageService: EntranceGlazingPackageService;
    private offerService: OffersService;
    private positionService: PositionService;
    private pricingService: PricingService;
    private productionOrderPositionService: ProductionOrdersPositionService;
    private productionOrderService: ProductionOrderService;
    private windowDimensionsService: WindowDimensionsService;
    private route: ActivatedRoute;
    private router: Router;
    private sanitizer: DomSanitizer;
    private sidenavController: SidenavController;
    private currentUserService: CurrentUserService;
    private windowSystemDefinitionService: WindowSystemDefinitionService;
    private entranceModelService: EntranceModelService;
    private catalogConfigurationService: CatalogConfigurationService;
    private profileService: ProfileService;
    private colorService: ColorService;
    private readonly missingProfitMarginHandlerService: MissingProfitMarginHandlerService;
    private readonly permissions: Permissions;

    windowSystemTypeGroup: ProductTypeGroup;

    imageService: SidebarFieldImageService;
    translate: TranslateService;

    offer: WindowEditorOfferData;
    offerPosition: WindowEditorPositionData;
    productionOrder: WindowEditorProductionOrderData;
    productionOrderPosition: WindowEditorPositionData;
    headerSuffix: string;

    readOnlyMode = true;
    saveInProgress = false;
    showExitWithoutSavingConfirmationDialog: boolean;
    showAddDialog = false;
    showSidebar = true;

    generalStatus: ResponseStatusFlags = new ResponseStatusFlags();
    pricingStatus: ResponseStatusFlags = new ResponseStatusFlags();
    validationStatus: ResponseStatusFlags = new ResponseStatusFlags();

    systemPreviewImage: SafeResourceUrl;
    validationErrors: ValidationErrors = {};

    selectedQuantity: number;
    openings: OpeningOption[];
    selectedModel: EntranceModel;
    selectedGlazingPackage: GlazingPackageData;
    selectedDimension: WindowDimensions;

    windowSystems: WindowSystemDefinition[];
    selectedSystem: WindowSystemDefinition;
    catalogConfiguration: CatalogConfiguration;
    data: RoofWindowData | EntranceDoorData;

    originalData = "";
    glazingPackages: GlazingPackageData[];
    profiles: Profile[];
    externalColors: SelectItem[];
    internalColors: SelectItem[];
    dimensions: WindowDimensions[];
    entranceModels: CatalogItemExtended[];

    entranceLocks: Addon[] = [];
    availableFlashings: Addon[] = [];
    availableAKPFlashings: Addon[] = [];

    selectItemFormatters: SelectItemFormatters;
    Field = WindowEditorField;
    SidebarSection = SidebarSection;
    DrawingToolControlsMode = DrawingToolControlsMode;
    fieldUsage: RoofWindowFieldUsage;

    windowComponentPreviewData: WindowComponentPreviewData;

    isAddonsTabViewVisible = false;
    showDescriptionDialog = false;
    addedWindowAddons: PositionListAddon[] = [];

    selectingRalColorHeader: string;
    selectingRalColorField: WindowEditorField;
    selectingRalColors: Color[];
    allColors: Color[];
    systemRalExternalColors: Color[];
    systemRalInternalColors: Color[];
    systemNcsExternalColors: Color[];
    systemNcsInternalColors: Color[];
    private colorBeforeRalSelection: Color;
    public internalColorNoneSelectable = false;
    public externalColorNoneSelectable = false;
    firstChoiceMade = false;

    unavailableItemsSelected: WindowEditorField[] = [];

    DoorSide = DoorSide;

    visibleFields: WindowEditorField[] = [];

    showExitWithMessagesDialog: boolean;
    recentPricingProducts: Product[];
    recentValidationMessages: PositionMessage[] = [];

    private fullCatalogLoaded = false;
    @ViewChild('tabView') tabView: TabView;
    @ViewChild('pricing') pricingComponent: PricingComponent;
    @ViewChild('validation') validationComponent: ValidationComponent;

    constructor(injector: Injector,
                private changeDetector: ChangeDetectorRef,
                private growls: GrowlMessageController) {
        this.addonsService = injector.get(AddonsService);
        this.blockUiController = injector.get(BlockUiController);
        this.errors = injector.get(CommonErrorHandler);
        this.roofGlazingPackageService = injector.get(RoofGlazingPackageService);
        this.entranceGlazingPackageService = injector.get(EntranceGlazingPackageService);
        this.imageService = injector.get(SidebarFieldImageService);
        this.offerService = injector.get(OffersService);
        this.positionService = injector.get(PositionService);
        this.pricingService = injector.get(PricingService);
        this.productionOrderPositionService = injector.get(ProductionOrdersPositionService);
        this.productionOrderService = injector.get(ProductionOrderService);
        this.windowDimensionsService = injector.get(WindowDimensionsService);
        this.entranceModelService = injector.get(EntranceModelService);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.sanitizer = injector.get(DomSanitizer);
        this.sidenavController = injector.get(SidenavController);
        this.currentUserService = injector.get(CurrentUserService);
        this.translate = injector.get(TranslateService);
        this.windowSystemDefinitionService = injector.get(WindowSystemDefinitionService);
        this.catalogConfigurationService = injector.get(CatalogConfigurationService);
        this.profileService = injector.get(ProfileService);
        this.colorService = injector.get(ColorService);
        this.missingProfitMarginHandlerService = injector.get(MissingProfitMarginHandlerService);
        this.permissions = injector.get(Permissions);

        this.selectItemFormatters = new SelectItemFormatters(this.translate);
        this.fieldUsage = new RoofWindowFieldUsage(this);
    }

    get isRoof() {
        return this.windowSystemTypeGroup === ProductTypeGroup.ROOF;
    }

    get isEntrance() {
        return this.windowSystemTypeGroup === ProductTypeGroup.ENTRANCE;
    }

    get catalogPropertyTarget() {
        switch (this.windowSystemTypeGroup) {
            case ProductTypeGroup.ROOF:
                return CatalogPropertyTarget.ROOF_SYSTEMS;
            case ProductTypeGroup.ENTRANCE:
                return CatalogPropertyTarget.ENTRANCE_SYSTEMS;
            default:
                break;
        }
        return undefined;
    }

    get isDimensionOverride() {
        return this.isEntrance && WindowDimensionsUtils.isOverriden(this.data.dimensionsId);
    }

    ngOnInit(): void {
        this.sidenavController.hide();
        this.blockUiController.block(RoofWindowEditorComponent.BLOCK_SOURCE_ID);

        this.windowSystemTypeGroup = this.route.snapshot.queryParams['windowSystemTypeGroup'];
        let offerId = this.route.snapshot.params['offerId'];
        let productionOrderId = this.route.snapshot.params['productionOrderId'];
        let positionId = this.route.snapshot.params['positionId'];
        let newPosition = positionId === 'new';
        let offerObservable: Observable<WindowEditorOfferData>;
        let offerPositionObservable: Observable<WindowEditorPositionData>;
        let productionOrderObservable: Observable<WindowEditorProductionOrderData>;
        let productionOrderPositionObservable: Observable<WindowEditorPositionData>;
        if (offerId != null) {
            offerObservable = this.offerService.getOfferForWindowEditor(offerId);
            offerPositionObservable = newPosition
                ? of(this.windowSystemTypeGroup === ProductTypeGroup.ROOF
                    ? PositionFactory.roofWindow(offerId)
                    : PositionFactory.entranceDoor(offerId))
                : this.positionService.getOfferPositionForWindowEditor(positionId);
            productionOrderObservable = of(null);
            productionOrderPositionObservable = of(null);
        } else {
            offerObservable = of(null);
            offerPositionObservable = of(null);
            productionOrderObservable = this.productionOrderService.getProductionOrderForWindowEditor(productionOrderId);
            productionOrderPositionObservable = this.productionOrderPositionService.getProductionOrderPositionForWindowEditor(positionId);
        }

        forkJoin({
            offer: offerObservable,
            offerPosition: offerPositionObservable,
            productionOrder: productionOrderObservable,
            productionOrderPosition: productionOrderPositionObservable
        }).pipe(catchError(
            (error) => {
                this.hideUiBlock();
                this.errors.handle(error);
                this.router.navigate(['features/offer']);
                return EMPTY;
            }), mergeMap(data => {
            this.offer = data.offer;
            this.offerPosition = data.offerPosition;
            this.productionOrder = data.productionOrder;
            this.productionOrderPosition = data.productionOrderPosition;
            this.selectedQuantity = this.offerPosition ? this.offerPosition.quantity : this.productionOrderPosition.quantity;
            let position = this.offerPosition || this.productionOrderPosition;
            this.readOnlyMode = this.offer == undefined
                || !this.permissions.userCanEditOffer(this.offer.status)
                || (position.validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun())
                || (this.offer.offerLockUserLogin !== this.currentUserService.currentUserName
                    && !this.currentUserService.restrictedToOfferNumber);
            this.data = JSON.parse(position.data);
            this.originalData = position.data;
            return forkJoin({
                windowSystems: this.loadWindowSystems(newPosition, this.data.systemId),
                glazingPackages: this.loadGlazingPackages(),
                profiles: this.loadProfiles(),
                colors: this.loadColors(),
                catalogConfiguration: this.loadCatalogConfiguration(newPosition, this.catalogPropertyTarget),
                dimensions: this.loadDimensions(),
                addons: this.loadAddons(),
                windowSystemImage: this.loadSystemImage(),
                bulkAddons: this.loadAddedBulkAddons(),
                models: this.loadModels(),
                selectedModel: this.data['entranceModelId'] == null ? of(null) : this.entranceModelService.getItem(this.data['entranceModelId'], true),
            });
        }), finalize(() => this.hideUiBlock())).subscribe({
            next: catalogData => {
                this.entranceModels = catalogData.models;
                this.assignWindowSystems(catalogData.windowSystems);
                this.glazingPackages = catalogData.glazingPackages;
                this.profiles = catalogData.profiles;
                if (this.isEntrance) {
                    this.checkUnavailableItemsSelected(this.profiles, this.getEntranceData().frameProfileId, WindowEditorField.PROFILE);
                    this.checkUnavailableItemsSelected(this.glazingPackages, this.getEntranceData().glazingPackageId, WindowEditorField.GLASS);
                }
                this.prepareColors(catalogData.colors);
                this.dimensions = catalogData.dimensions;
                this.catalogConfiguration = catalogData.catalogConfiguration;
                this.setPreviewImage(catalogData.windowSystemImage);
                this.prepareSelectItems(catalogData.addons);
                this.addedWindowAddons = WindowAddonUtils.wrapAddedAddons(catalogData.bulkAddons, this.data,
                    this.translate.currentLang, this.growls);
                if (!this.readOnlyMode && !newPosition) {
                    this.rebindSelections();
                }
                if (newPosition) {
                    this.fullCatalogLoaded = true;
                    this.showAddDialog = true;
                } else {
                    this.setSystem(this.data.systemId, false);
                    if (this.isEntrance) {
                        this.selectedModel = catalogData.selectedModel;
                        this.setPreviewImage(this.getModelImage());
                        this.changeDetector.markForCheck();
                    }
                    this.setGlazingPackage(this.data.glazingPackageId);
                    this.setDimensions(this.data.dimensionsId);
                }
            },
            error: (error) => this.errors.handle(error)
        });
    }

    isRoofData(data: RoofWindowData | EntranceDoorData): data is RoofWindowData {
        return this.windowSystemTypeGroup === ProductTypeGroup.ROOF;
    }

    private getModelImage() {
        if (this.selectedModel != null) {
            return this.getEntranceData().opening === OpeningOption.OUTSIDE ? this.selectedModel.image : this.selectedModel.image2;
        }
    }

    private prepareSelectItems(addons: Addon[]): void {
        let addonsData = _.groupBy(addons.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]),
            addon => addon.category);
        this.availableFlashings = addonsData[AddonCategoryEnum.FLASHING] || [];
        this.availableAKPFlashings = addonsData[AddonCategoryEnum.FLASHING_AKP] || [];
        this.entranceLocks = addonsData[AddonCategoryEnum.ENTRANCE_DOOR_FITTING] || [];
        if (this.isEntrance) {
            this.checkUnavailableItemsSelected(this.entranceLocks, this.getEntranceData().entranceLock.addonId, WindowEditorField.ENTRANCE_DOOR_FITTING);
        } else if (this.isRoof) {
            this.checkUnavailableItemsSelected(this.availableFlashings, this.getRoofData().flashing.addonId, WindowEditorField.FLASHING);
            this.checkUnavailableItemsSelected(this.availableAKPFlashings, this.getRoofData().flashingAkp.addonId, WindowEditorField.FLASHING_AKP);
        }
    }

    public getEntranceData(): EntranceDoorData {
        return this.data as EntranceDoorData;
    }

    public getRoofData(): RoofWindowData {
        return this.data as RoofWindowData;
    }

    systemSelected(systemId: number): void {
        if (systemId == undefined) {
            this.onDialogClosed();
            return;
        }
        this.firstChoiceMade = true;
        if (systemId == null || this.data.systemId === systemId) {
            this.onDialogClosed();
            return;
        }
        this.blockUiController.block(RoofWindowEditorComponent.BLOCK_SOURCE_ID);
        this.loadProfitMargins(systemId).pipe(
            catchError(
                (error) => {
                    this.errors.handle(error);
                    return EMPTY;
                }),
            this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                ...this.offer,
                identifier: {target: 'WINDOW_SYSTEM', windowSystemId: systemId}
            }),
            mergeMap(profitMarginValid => {
                if (profitMarginValid) {
                    this.setSystem(systemId);
                    return forkJoin({
                        dimensions: this.loadDimensions(),
                        addons: this.loadAddons(),
                        systemImage: this.loadSystemImage()
                    });
                } else {
                    return EMPTY;
                }
            }), finalize(() => this.hideUiBlock())).subscribe(
            (catalogData) => {
                this.dimensions = catalogData.dimensions;
                this.resetDimensionsIfNeeded();
                this.prepareSelectItems(catalogData.addons);
                this.setPreviewImage(catalogData.systemImage);
                this.generalStatus.errors = false;
                this.showAddDialog = false;
                this.addedWindowAddons = [];
                this.data.addons = [];
            });
    }

    entranceSystemSelected(event: {systemId: number, modelId: number}): void {
        if (event == null || event.systemId == undefined) {
            this.onDialogClosed();
            return;
        }
        this.showAddDialog = false;
        if (!this.isEntranceData(this.data)) {
            return;
        }
        this.unavailableItemsSelected = [];
        this.data.systemId = event.systemId;
        this.data.entranceModelId = event.modelId;
        this.firstChoiceMade = true;
        this.selectedSystem = this.findInList(this.windowSystems, this.data.systemId);
        this.setOpenings();
        this.data.width = null;
        this.data.height = null;

        this.loadProfitMargins(this.data.systemId).pipe(
            catchError((error) => {
                this.errors.handle(error);
                return EMPTY;
            }),
            this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                ...this.offer, identifier: {target: 'WINDOW_SYSTEM', windowSystemId: this.data.systemId}
            }),
            mergeMap(profitMarginValid => {
                if (profitMarginValid) {
                    return forkJoin({
                        glazingPackages: this.loadEntranceGlazingPackages(this.data['entranceModelId']),
                        dimensions: this.loadDimensions(),
                        profiles: this.loadProfiles(),
                        addons: this.loadAddons(),
                        colors: this.loadColors(),
                        model: this.entranceModelService.getItem(this.data['entranceModelId'], true),
                    });
                } else {
                    return EMPTY;
                }
            })
        ).subscribe(catalogData => {
                this.selectedModel = catalogData.model;
                if (this.selectedModel != undefined) {
                    this.setPreviewImage(this.getModelImage());
                }
                this.dimensions = catalogData.dimensions;
                this.profiles = catalogData.profiles;
                this.glazingPackages = catalogData.glazingPackages;
                if (this.selectedModel != undefined) {
                    this.setGlazingPackage(this.selectedModel.glazingPackageId);
                    this.setPreviewImage(this.getModelImage());
                }
                this.prepareSelectItems(catalogData.addons);
                this.prepareColors(catalogData.colors);
                this.setDefaultValues();
                this.checkUnavailableItemsSelected(this.profiles, this.getEntranceData().frameProfileId, WindowEditorField.PROFILE);
                this.checkUnavailableItemsSelected(this.glazingPackages, this.getEntranceData().glazingPackageId, WindowEditorField.GLASS);
                if (this.selectedModel != undefined) {
                    this.setPreviewImage(this.getModelImage());
                }
            }
        );
    }

    private setSystem(id: number, propagate = true): void {
        this.data.systemId = id;
        let system = this.findInList(this.windowSystems, id);
        this.selectedSystem = system;
        if (system == null) {
            this.data.systemId = null;
            if (propagate) {
                this.setGlazingPackage(null);
                this.setDimensions(null);
            }
        } else {
            this.setOpenings();
            this.data.systemId = system.id;
            if (propagate) {
                this.setGlazingPackage(system.roofGlazingPackageId);
            }
        }
        this.validate('systemId');
    }

    private setOpenings() {
        this.openings = this.selectedSystem.possibleOpenings;
        this.openings.sort((a, b) => a === OpeningOption.OUTSIDE ? -1 : 1);
    }

    private setGlazingPackage(id: number): void {
        this.data.glazingPackageId = id;
        this.selectedGlazingPackage = this.findInList(this.glazingPackages, id);
        this.validate('glazingPackageId');
    }

    setDimensions(id: number): void {
        let dimensions = this.findInList(this.dimensions, id);
        this.data.dimensionsId = dimensions == null ? null : id;
        this.selectedDimension = dimensions;
        if (dimensions && this.isEntranceData(this.data)) {
            this.data.width = dimensions.width;
            this.data.height = dimensions.height;
            this.validate('width');
            this.validate('height');
        }
        this.validate('dimensionsId');
    }

    setWidth(event: number): void {
        if (this.isEntranceData(this.data)) {
            this.selectedDimension.width = event;
            this.data.width = event;
            this.validate('width');
        }
    }

    setHeight(event: number): void {
        if (this.isEntranceData(this.data)) {
            this.selectedDimension.height = event;
            this.data.height = event;
            this.validate('height');
        }
    }

    private resetDimensionsIfNeeded(): void {
        let dimensionsId = this.rebindSelection(this.dimensions, this.data.dimensionsId);
        if (dimensionsId == null && this.dimensions.length > 0) {
            dimensionsId = this.dimensions[0].id;
        }
        this.setDimensions(dimensionsId);
    }

    private setPreviewImage(data: string): void {
        this.systemPreviewImage = data == null ? null : this.sanitizer.bypassSecurityTrustUrl(data);
    }

    setOpening(event: OpeningOption): void {
        this.getEntranceData().opening = event;
        this.setPreviewImage(this.getModelImage());
    }

    private findInList<T extends { id: number }>(list: T[], id: number): T {
        return list.find(v => v.id === id);
    }

    private findIdInList<T extends { id: number }>(list: T[], id: number): number {
        return (this.findInList(list, id) || {id: undefined}).id;
    }

    private rebindSelection<T extends { id: number, active: boolean }>(list: T[], id: number): number {
        let selected = this.findInList(list, id);
        return selected == null ? null : id;
    }

    private rebindSelections(): void {
        this.data.dimensionsId = this.rebindSelection(this.dimensions, this.data.dimensionsId);
        this.data.glazingPackageId = this.rebindSelection(this.glazingPackages, this.data.glazingPackageId);
        this.data.systemId = this.rebindSelection(this.windowSystems, this.data.systemId);

        let rebindAddon = (property: string, availables: Addon[]) => {
            if (this.findIdInList(availables, this.data[property] && this.data[property].addonId) == null) {
                this.data[property] = new WindowAddon();
            }
        };
        if (this.isRoofData(this.data)) {
            rebindAddon('flashing', this.availableFlashings);
            rebindAddon('flashingAkp', this.availableAKPFlashings);
        }
        if (this.isEntranceData(this.data)) {
            rebindAddon('entranceLock', this.entranceLocks);
        }
    }

    private loadProfitMargins(systemId: number): Observable<ProfitMarginExistance> {
        return this.readOnlyMode ?
            of(new ProfitMarginExistance(true)) :
            this.windowSystemDefinitionService.validateMarginExistance(systemId, this.offerPosition.offerId);
    }

    isEntranceData(data: RoofWindowData | EntranceDoorData): data is EntranceDoorData {
        return this.windowSystemTypeGroup === ProductTypeGroup.ENTRANCE;
    }

    private loadGlazingPackages(): Observable<GlazingPackageData[]> {
        if (!this.readOnlyMode) {
            return this.isEntranceData(this.data) ? (this.data.entranceModelId == null ? of([]) :
                    this.loadEntranceGlazingPackages(this.data.entranceModelId)) :
                this.roofGlazingPackageService.getItems(null, null, {}, null, null)
                    .pipe(map(this.extractData));
        }
        return this.isEntrance ?
            this.entranceGlazingPackageService.getItem(this.data.glazingPackageId).pipe(map(response => [response])) :
            this.roofGlazingPackageService.getItem(this.data.glazingPackageId).pipe(map(response => [response]));
    }

    private loadDimensions(): Observable<WindowDimensions[]> {
        if (this.readOnlyMode) {
            return WindowDimensionsUtils.isOverriden(this.data.dimensionsId) ? of([this.overrideDimensions()]) :
                this.windowDimensionsService.getItem(this.data.dimensionsId).pipe(
                    map(response => [response]));
        }
        if (this.data.systemId == null) {
            return of([]);
        }
        return this.windowDimensionsService.forSystem(this.windowSystemTypeGroup, this.data.systemId).pipe(
            map(this.extractData)).pipe(tap(dimensions => {
            if (this.isEntranceData(this.data)) {
                dimensions.push(this.overrideDimensions());
            }
        }));
    }

    private loadModels(): Observable<CatalogItemExtended[]> {
        if (this.data.systemId != null && this.isEntranceData(this.data)) {
            return this.entranceModelService.getModelsForAddDialog(this.data.entranceModelId);
        }
        if (this.data.systemId == null && this.isEntrance) {
            return this.entranceModelService.getModelsForAddDialog();
        }
        return of([]);
    }

    private overrideDimensions() {
        const override = new WindowDimensions();
        override.id = WindowDimensionsUtils.OVERRIDE_HARDCODED_ID;
        if (this.isEntranceData(this.data)) {
            override.width = this.data.width;
            override.height = this.data.height;
        }
        return override;
    }

    private extractData<T>(listingDto: Listing<T>): T[] {
        return listingDto.data;
    }

    private loadColors(): Observable<Color[]> {
        if (this.isRoofData(this.data)) {
            return of([]);
        }
        if (this.readOnlyMode && this.data instanceof EntranceDoorData) {
            return forkJoin({
                externalColor: this.colorService.getItem((this.data as EntranceDoorData).externalColorId),
                internalColor: this.colorService.getItem((this.data as EntranceDoorData).internalColorId)
            }).pipe(map(data => [data.externalColor, data.internalColor]));
        }

        if (this.data.systemId == null) {
            return of([]);
        }
        const selectedColors = [this.data.internalColorId, this.data.externalColorId];
        return this.windowSystemDefinitionService.getSystemsColors(this.data.systemId, selectedColors)
            .pipe(map(this.extractData));
    }

    private loadProfiles(): Observable<Profile[]> {
        if (this.isRoofData(this.data) || this.data.systemId == null) {
            return of([]);
        }
        if (this.readOnlyMode && this.data instanceof EntranceDoorData) {
            return this.profileService.getItem((this.data as EntranceDoorData).frameProfileId)
                .pipe(map(profile => [profile]));
        }
        return this.windowSystemDefinitionService.getSystemsProfiles(this.data.systemId, [this.getEntranceData().frameProfileId])
            .pipe(map(this.extractData));
    }

    private loadSystemImage(): Observable<string> {
        if (this.isEntrance || this.data.systemId == null) {
            return of(null);
        }
        return this.windowSystemDefinitionService.getWindowEditorImage(this.data.systemId);
    }

    private loadAddedBulkAddons(): Observable<AddonInterface[]> {
        let ids = this.data.addons.map(addon => addon.addonId);
        if (ids != null && ids.length > 0) {
            return this.addonsService.getItemsByIds(ids, true);
        }
        return of<AddonInterface[]>([]);
    }

    private loadAddons(): Observable<Addon[]> {
        let addonIds = this.getAddonIds();
        if (this.readOnlyMode) {
            return addonIds.length === 0 ? of([]) :
                this.addonsService.getItemsByIds(addonIds, false);
        }
        if (this.data.systemId == null) {
            return of([]);
        }
        const selectedAddons: number[] = this.isRoof ? [this.getRoofData().flashing.addonId, this.getRoofData().flashingAkp.addonId]
            : [this.getEntranceData().entranceLock.addonId];
        return this.windowSystemDefinitionService.getSystemsAddOns(this.data.systemId, selectedAddons.filter(id => id != null)).pipe(
            map(this.extractData));
    }

    private loadCatalogConfiguration(loadAll: boolean, target: CatalogPropertyTarget): Observable<CatalogConfiguration> {
        return loadAll ? this.catalogConfigurationService.getForTarget(target) : of(null);
    }

    private getAddonIds(): number[] {
        let addons: WindowAddon[];
        if (this.isRoofData(this.data)) {
            addons = [
                this.data.flashing,
                this.data.flashingAkp
            ];
        } else {
            addons = [this.getEntranceData().entranceLock];
        }
        return addons.filter(addon => addon != null && addon.addonId != null).map(addon => addon.addonId);
    }

    ngOnDestroy(): void {
        this.sidenavController.show();
    }

    onDialogClosed(): void {
        if (this.data.systemId == null) {
            this.exit();
        }
        this.showAddDialog = false;
    }

    exit(): void {
        if (this.firstChoiceMade && this.dataChanged()) {
            this.showExitWithoutSavingConfirmationDialog = true;
        } else {
            this.showExitWithoutSavingConfirmationDialog = false;
            this.navigateToPositionList();
        }
    }

    private dataChanged(): boolean {
        return this.originalData !== JSON.stringify(this.data);
    }

    exitWithoutSaving(): void {
        this.navigateToPositionList();
    }

    navigateToPositionList(): void {
        const matrixParams = {
            ..._.omit(this.route.parent.snapshot.params, ['offerId']),
            lastSelection: this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id
        };
        this.router.navigate(['../..', matrixParams], {relativeTo: this.route});
    }

    saveAndExit(): void {
        if (this.saveInProgress || this.showExitWithMessagesDialog || this.readOnlyMode || !this.validateAll()) {
            return;
        }
        if (!this.selectedSystem.active) {
            this.growls.error("OFFER.DRAWING.WINDOW_SYSTEM_NOT_AVAILABLE");
            return;
        }
        if (this.selectedModel != null && !this.selectedModel.active) {
            this.growls.error("OFFER.DRAWING.MODEL_NOT_AVAILABLE");
            return;
        }
        this.saveInProgress = true;
        this.prepareOfferPosition();
        const pricingObservable = this.isRoofData(this.data) ?
            this.pricingService.evaluateRoofWindow(false, this.data, this.offer.id, this.offerPosition.id, false,
                this.offerPosition.validationDisabled)
            : this.pricingService.evaluateEntranceDoor(false, this.data, this.offer.id, this.offerPosition.id, false,
                this.offerPosition.validationDisabled);
        pricingObservable.subscribe({
            next: (data: Pricing) => {
                const hasMessages = data.products.some(product => product.messages.length > 0) ||
                    data.validationMessages.length > 0;
                const hasErrors = Pricing.containsForValidation(data, MessageSeverity.ERROR) ||
                    Pricing.containsForValidation(data, MessageSeverity.BLOCKER);
                if (hasErrors || !hasMessages) {
                    this.saveItem();
                } else {
                    this.saveInProgress = false;
                    this.showExitWithMessagesDialog = true;
                    this.recentValidationMessages = data.validationMessages;
                    this.recentPricingProducts = data.products;
                }
            },
            error: error => {
                this.saveInProgress = false;
                this.errors.handle(error);
            }
        });
    }

    saveItem() {
        this.positionService.saveItem(this.offerPosition as Position).subscribe({
            next: newOfferPositionId => {
                console.log("this.positionService.saveItem success!");
                let navigateObservable = of(true);
                if (this.offerPosition.id === undefined) {
                    this.offerPosition.id = newOfferPositionId;
                    navigateObservable = navigateObservable.pipe(mergeMap(() =>
                        from(this.router.navigate([`../../${this.offerPosition.id}/roofWindowDesigner`],
                            {relativeTo: this.route, replaceUrl: true}))));
                }
                navigateObservable.subscribe({
                    complete: () => {
                        this.saveInProgress = false;
                        this.router.navigate(['../..', {lastSelection: this.offerPosition.id}], {relativeTo: this.route});
                    }
                });
            },
            error: error => {
                this.errors.handle(error);
                this.saveInProgress = false;
            }
        });
    }

    private prepareOfferPosition(): void {
        this.mapAddons();
        if (this.openings.length === 0) {
            this.setOpening(OpeningOption.NONE);
        }
        this.offerPosition.dimensions = this.selectedDimension.width + "x" + this.selectedDimension.height;
        this.offerPosition.data = JSON.stringify(this.data);
        this.offerPosition.windowSystemId = this.data.systemId;
        this.offerPosition.configurableAddons = [];
    }

    tabChanged(event): void {
        if (event.index === TabPanels.PRICING || event.index === TabPanels.VALIDATION) {
            if (this.validateAll()) {
                this.mapAddons();
                this.pricingComponent.getPricing(this.data, this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id,
                    [], this.offerPosition != undefined && this.offerPosition.validationDisabled);
                this.selectTab(event.index);
                return;
            }
        }
        this.selectTab(TabPanels.GENERAL);
    }

    private selectTab(panel: TabPanels) {
        this.tabView.tabs.forEach(tab => tab.selected = false);
        this.tabView.tabs[panel].selected = true;
    }

    public updateValidationPricingStatuses(pricing: Pricing, omitPricing: boolean) {
        ResponseStatusHelper.updateValidationStatuses(this.validationStatus, pricing);
        let pricingTabSelected = this.tabView.tabs[TabPanels.PRICING].selected;
        if (!omitPricing || pricingTabSelected) {
            ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricing);
        }
    }

    getTabpanelHeaderStyle(status: ResponseStatusFlags): string {
        return ResponseStatusHelper.tabpanelHeaderStyle(status);
    }

    openAddDialog(): void {
        if (this.fullCatalogLoaded) {
            this.showAddDialog = true;
        } else {
            this.blockUiController.block(RoofWindowEditorComponent.BLOCK_SOURCE_ID);
            forkJoin({
                windowSystems: this.loadWindowSystems(true, this.data.systemId),
                glazingPackages: this.loadGlazingPackages(),
                catalogConfiguration: this.loadCatalogConfiguration(true, this.catalogPropertyTarget)
            }).pipe(finalize(() => this.hideUiBlock())).subscribe({
                next: (catalogData) => {
                    this.assignWindowSystems(catalogData.windowSystems);
                    this.glazingPackages = catalogData.glazingPackages;
                    this.catalogConfiguration = catalogData.catalogConfiguration;
                    this.fullCatalogLoaded = true;
                    this.showAddDialog = true;
                },
                error: (error) => this.errors.handle(error)
            });
        }
    }

    private hideUiBlock(): void {
        this.blockUiController.unblock(RoofWindowEditorComponent.BLOCK_SOURCE_ID);
    }

    private validate(field: string): void {
        delete this.validationErrors[field];
        if (this.data[field] == null) {
            this.validationErrors[field] = `error.validation.roof_systems.${field}_not_set`;
        }
        if (field === 'glazingPackageId' && this.selectedGlazingPackage && !this.selectedGlazingPackage.active) {
            this.validationErrors[field] = 'ERROR.DESIGNER.NO_LONGER_AVAILABLE';
        }
    }

    private validateAll(): boolean {
        this.validationErrors = {};
        this.validate('systemId');
        if (this.isRoof) {
            this.validate('glazingPackageId');
        }
        const unavailableDetected = this.unavailableItemsSelected.length > 0;
        if (unavailableDetected) {
            this.growls.error('OFFER.TABS.ERROR.UNAVAILABLE_ITEMS_PRESENT');
        }
        let errorsPresent = ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
        this.generalStatus.errors = errorsPresent;
        return !errorsPresent && this.allRequiredFilled() && !unavailableDetected;
    }

    private allRequiredFilled(): boolean {
        let requiredFields = [WindowEditorField.DIMENSIONS,
            WindowEditorField.PROFILE,
            WindowEditorField.WIDTH,
            WindowEditorField.HEIGHT,
            WindowEditorField.EXTERNAL_COLOR,
            WindowEditorField.INTERNAL_COLOR];
        if (this.fieldUsage.show(WindowEditorField.ENTRANCE_DOOR_FITTING)) {
            requiredFields.push(WindowEditorField.ENTRANCE_DOOR_FITTING);
        }
        if (this.fieldUsage.show(WindowEditorField.OPENING)) {
            requiredFields.push(WindowEditorField.OPENING);
        }
        for (const field of requiredFields) {
            if (this.fieldUsage.required(field)) {
                return false;
            }
        }
        return true;
    }

    updateOfferPositionQuantity(): void {
        let qn = this.offerPosition.quantity;
        if (!qn || isNaN(Number(qn)) || !Number.isInteger(qn) || qn < 1) {
            this.offerPosition.quantity = 1;
        }
    }

    handleShowImage(imageSource: Observable<string>, header: string): void {
        this.windowComponentPreviewData = new WindowComponentPreviewData(imageSource, header);
    }

    setField(value: any, field: WindowEditorField) {
        value = SidebarHelper.unwrapUndefinedNoneSelectionWorkaround(value);
        switch (field) {
            case WindowEditorField.FLASHING:
                if (this.isRoofData(this.data)) {
                    this.data.flashing.addonId = value;
                }
                break;
            case WindowEditorField.FLASHING_AKP:
                if (this.isRoofData(this.data)) {
                    this.data.flashingAkp.addonId = value;
                }
                break;
            case WindowEditorField.ENTRANCE_DOOR_FITTING:
                if (this.isEntranceData(this.data)) {
                    this.data.entranceLock.addonId = value;
                }
                break;
        }
        this.removeFieldFromUnavailable(field);
    }

    public mapAddons() {
        let dataSource = AddonDefaultQuantityCalculator.prepareRoofDataSource(this.selectedDimension);
        let mapAddon = (property: string, availables: Addon[]) => {
            WindowAddonMapper.mapToWindowAddon(this.data[property],
                this.findInList(availables, this.data[property] && this.data[property].addonId),
                WindowAddonCalculationMode.GLOBAL, dataSource);
        };
        mapAddon('flashing', this.availableFlashings);
        mapAddon('flashingAkp', this.availableAKPFlashings);
        mapAddon('entranceLock', this.entranceLocks);
    }

    showAddonsTabView() {
        this.isAddonsTabViewVisible = true;
        this.changeDetector.markForCheck();
    }

    openDescriptionDialog(): void {
        this.showDescriptionDialog = true;
        this.changeDetector.markForCheck();
    }

    closeAddonsTabView(): void {
        this.isAddonsTabViewVisible = false;
        this.changeDetector.markForCheck();
    }

    windowAddonsSave(windowAddonSaveData: WindowAddonSaveData): void {
        if (windowAddonSaveData.action === WindowAddonActionType.ADD) {
            WindowAddonUtils.addWindowAddonToData(windowAddonSaveData.addonToSave, windowAddonSaveData.positionlistAddon, this.data,
                this.addedWindowAddons);
            this.growls.info('OFFER.DRAWING.ADDONS.ADDED');
        } else {
            if (WindowAddonUtils.editWindowAddonInData(windowAddonSaveData.addonToSave, this.data)) {
                this.growls.info('OFFER.DRAWING.ADDONS.EDITED');
            }
        }
    }

    windowAddonsRemove(addonId): void {
        WindowAddonUtils.removeWindowAddonToData(addonId, this.data, this.addedWindowAddons);
        this.growls.info('OFFER.DRAWING.ADDONS.REMOVED');
    }

    private assignWindowSystems(systems: WindowSystemDefinition[]): void {
        if (this.windowSystemTypeGroup === ProductTypeGroup.ENTRANCE) {
            let usedSystems: any = _.chain(this.entranceModels)
                .map(model => model.links)
                .flatten()
                .uniq()
                .value();
            this.windowSystems = systems.filter(system => usedSystems.includes(system.id));
        } else {
            this.windowSystems = systems;
        }
    }

    private loadWindowSystems(loadAll: boolean, systemId?: number): Observable<WindowSystemDefinition[]> {
        if (loadAll) {
            switch (this.windowSystemTypeGroup) {
                case ProductTypeGroup.ROOF:
                    return this.windowSystemDefinitionService.getRoofSystems(null, null, {active: {value: 'true'}, withSmallImages: {value: 'true'}, selectedId: {value: systemId}}, null, null)
                        .pipe(map(this.extractData));
                case ProductTypeGroup.ENTRANCE:
                    return this.windowSystemDefinitionService.getEntranceSystems(null, null, {active: {value: 'true'}, selectedId: {value: systemId}}, null, null)
                        .pipe(map(this.extractData));
            }
        }
        return this.windowSystemDefinitionService.getSystem(this.data.systemId).pipe(map(response => [response]));
    }

    loadEntranceGlazingPackages(modelId: number): Observable<GlazingPackageData[]> {
        const selectedPackage = this.isEntrance ? this.getEntranceData().glazingPackageId : null;
        return this.entranceGlazingPackageService.getPackagesForModel(modelId, selectedPackage);
    }

    filterColorSelectItems = (colorSelectItem: SelectItem, selectedColorId: number) => {
        const color = this.allColors.find(c => c.id === colorSelectItem.value);
        return color != undefined
            && ((color.type !== ColorType.RAL_PALETTE_CUSTOM && color.type !== ColorType.NCS_PALETTE_CUSTOM)
                || color.id === selectedColorId
                || `${color.id}`.startsWith('PLACEHOLDER'));
    }

    private prepareColors(colors: Color[]): void {
        const externalColors: Color[] = [];
        const internalColors: Color[] = [];
        const ralExternalColors: Color[] = [];
        const ralInternalColors: Color[] = [];
        const ncsExternalColors: Color[] = [];
        const ncsInternalColors: Color[] = [];
        for (let color of colors) {
            if (color.outside) {
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ralExternalColors.push(color);
                        }
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ncsExternalColors.push(color);
                        }
                        break;
                    default:
                        externalColors.push(color);
                        break;
                }
            }
            if (color.inside) {
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ralInternalColors.push(color);
                        }
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ncsInternalColors.push(color);
                        }
                        break;
                    default:
                        internalColors.push(color);
                        break;
                }
            }
        }
        this.allColors = colors;
        this.allColors.push(this.getEmptyPaletteColor(ColorType.RAL_PALETTE_CUSTOM));
        this.allColors.push(this.getEmptyPaletteColor(ColorType.NCS_PALETTE_CUSTOM));

        let data = this.getEntranceData();
        this.externalColors = this.selectItemFormatters
            .formatColorIdOptions(externalColors.concat(ralExternalColors, ncsExternalColors), false)
            .filter(colorSelectItem => this.filterColorSelectItems(colorSelectItem, data.externalColorId));
        this.internalColors = this.selectItemFormatters
            .formatColorIdOptions(internalColors.concat(ralInternalColors, ncsInternalColors), false)
            .filter(colorSelectItem => this.filterColorSelectItems(colorSelectItem, data.internalColorId));
        if (this.isEntrance) {
            this.checkUnavailableItemsSelected(colors, this.getEntranceData().externalColorId, WindowEditorField.EXTERNAL_COLOR);
            this.checkUnavailableItemsSelected(colors, this.getEntranceData().internalColorId, WindowEditorField.INTERNAL_COLOR);
        }

        this.systemRalExternalColors = ralExternalColors;
        this.systemRalInternalColors = ralInternalColors;
        this.systemNcsExternalColors = ncsExternalColors;
        this.systemNcsInternalColors = ncsInternalColors;
        if (ralExternalColors.length > 0) {
            this.externalColors.push(this.getEmptyPaletteColorSelectItem(ColorType.RAL_PALETTE_CUSTOM));
        }
        if (ralInternalColors.length > 0) {
            this.internalColors.push(this.getEmptyPaletteColorSelectItem(ColorType.RAL_PALETTE_CUSTOM));
        }
        if (ncsExternalColors.length > 0) {
            this.externalColors.push(this.getEmptyPaletteColorSelectItem(ColorType.NCS_PALETTE_CUSTOM));
        }
        if (ncsInternalColors.length > 0) {
            this.internalColors.push(this.getEmptyPaletteColorSelectItem(ColorType.NCS_PALETTE_CUSTOM));
        }

    }

    private getEmptyPaletteColorSelectItem(type: ColorType.RAL_PALETTE_CUSTOM | ColorType.NCS_PALETTE_CUSTOM): SelectItem {
        let prefix = type === ColorType.RAL_PALETTE_CUSTOM ? 'RAL' : 'NCS';
        return {
            label: `OFFER.TABS.SECTION.COLOR.${prefix}_PALETTE_CUSTOM`,
            value: `PLACEHOLDER_${prefix}`,
            available: true
        };
    }

    private getEmptyPaletteColor(type: ColorType.RAL_PALETTE_CUSTOM | ColorType.NCS_PALETTE_CUSTOM): Color {
        let prefix = type === ColorType.RAL_PALETTE_CUSTOM ? 'RAL' : 'NCS';
        let paletteColor = new Color();
        paletteColor.id = (`PLACEHOLDER_${prefix}` as any);
        paletteColor.names = new MultilanguageField();
        for (let supportedLanguage of SupportedLanguages.languages) {
            paletteColor.names[supportedLanguage.code] = `OFFER.TABS.SECTION.COLOR.${prefix}_PALETTE_CUSTOM`;
        }
        paletteColor.core = true;
        paletteColor.inside = true;
        paletteColor.outside = true;
        paletteColor.type = type;
        return paletteColor;
    }

    handleColorSelected(colorId: number | 'PLACEHOLDER_RAL' | 'PLACEHOLDER_NCS', field: WindowEditorField) {
        let data = this.getEntranceData();
        const color = this.allColors.find(c => c.id === colorId);
        if (color != undefined && typeof color.id !== 'number'
            && (color.type === ColorType.RAL_PALETTE_CUSTOM || color.type === ColorType.NCS_PALETTE_CUSTOM)) {
            switch (field) {
                case WindowEditorField.EXTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.allColors
                        .find(c => c.id === data.externalColorId);
                    this.selectingRalColors = color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.systemRalExternalColors
                        : this.systemNcsExternalColors;
                    this.externalColorNoneSelectable = true;
                    break;
                case WindowEditorField.INTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.allColors
                        .find(c => c.id === data.internalColorId);
                    this.selectingRalColors = color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.systemRalInternalColors
                        : this.systemNcsInternalColors;
                    this.internalColorNoneSelectable = true;
                    break;
            }
            this.selectingRalColorHeader = 'OFFER.TABS.SECTION.COLOR.' + color.type;
            this.selectingRalColorField = field;
            this.changeColorValue(undefined, field);
        } else {
            this.changeColorValue(color != undefined ? color.id : undefined, field);
            if (this.selectingRalColorField === field) {
                this.clearRalVariables();
            }
        }
        if (typeof colorId === 'number') {
            this.refilterColorOptions(colorId, field);
        }
        this.removeFieldFromUnavailable(field);
        this.changeDetector.markForCheck();
    }

    private changeColorValue(value, field: WindowEditorField) {
        let data = this.getEntranceData();
        switch (field) {
            case WindowEditorField.EXTERNAL_COLOR:
                data.externalColorId = value;
                break;
            case WindowEditorField.INTERNAL_COLOR:
                data.internalColorId = value;
                break;
        }
    }

    handleRalColorSelected(color: Color): void {
        if (color != undefined) {
            let colorSelectItem = this.selectItemFormatters.colorOptionFormatter(color);
            if (this.selectingRalColorField === WindowEditorField.EXTERNAL_COLOR) {
                this.externalColors.push(colorSelectItem);
            } else if (this.selectingRalColorField === WindowEditorField.INTERNAL_COLOR) {
                this.internalColors.push(colorSelectItem);
            }
            this.refilterColorOptions(color.id, this.selectingRalColorField);
        }
        this.changeColorValue(color != undefined ? color.id : undefined, this.selectingRalColorField);
        this.clearRalVariables();
    }

    private refilterColorOptions(selectedColorId: number, field: WindowEditorField) {
        if (field === WindowEditorField.EXTERNAL_COLOR) {
            this.externalColors = this.externalColors
                .filter(colorSelectItem => this.filterColorSelectItems(colorSelectItem, selectedColorId));
        } else if (field === WindowEditorField.INTERNAL_COLOR) {
            this.internalColors = this.internalColors
                .filter(colorSelectItem => this.filterColorSelectItems(colorSelectItem, selectedColorId));
        }
    }

    handleRalColorSelectionCancel(): void {
        this.changeColorValue(this.colorBeforeRalSelection != undefined ? this.colorBeforeRalSelection.id : 0,
            this.selectingRalColorField);
        this.clearRalVariables();
    }

    private clearRalVariables() {
        this.selectingRalColorHeader = undefined;
        this.selectingRalColorField = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
        this.internalColorNoneSelectable = false;
        this.externalColorNoneSelectable = false;
    }

    private setDefaultValues() {
        if (this.isEntranceData(this.data)) {
            if (this.dimensions.length > 0) {
                this.setDimensions(this.dimensions[0].id);
            }
            this.data.frameProfileId = this.profiles.length > 0 ? this.profiles[0].id : undefined;
            this.data.opening = this.selectedSystem.possibleOpenings.length > 0 ? this.selectedSystem.possibleOpenings[0] : undefined;
            this.data.externalColorId = this.setDefaultColor(this.externalColors, WindowEditorField.EXTERNAL_COLOR);
            this.data.internalColorId = this.setDefaultColor(this.internalColors, WindowEditorField.INTERNAL_COLOR);
            this.data.entranceLock.addonId = this.entranceLocks.length > 0 ? this.entranceLocks[0].id : undefined;
            this.data.side = DoorSide.RIGHT;
        }
    }

    applyExternalToInternalColor(): void {
        const data = this.getEntranceData();
        if (data.externalColorId != null) {
            let selectedColor = this.allColors.find(color => color.id === data.externalColorId);
            if (selectedColor != undefined) {
                this.handleColorSelected(selectedColor.id, WindowEditorField.INTERNAL_COLOR);
                if (selectedColor.type === ColorType.RAL_PALETTE_CUSTOM || selectedColor.type === ColorType.NCS_PALETTE_CUSTOM
                    || selectedColor.type === ColorType.RAL_PALETTE_STANDARD) {
                    let colorSelectItem = this.selectItemFormatters.colorOptionFormatter(selectedColor);
                    this.internalColors.push(colorSelectItem);
                    data.internalColorId = selectedColor.id;
                }
            }
        }
    }

    setDefaultColor(colors: SelectItem[], field: WindowEditorField): number {
        if (colors.length > 0) {
            const noPlaceholders = colors.filter(color => typeof color.value === 'number');
            if (noPlaceholders.length > 0) {
                return noPlaceholders[0].value;
            } else {
                let defaultColour: Color;
                switch (colors[0].value) {
                    case 'PLACEHOLDER_RAL':
                        this.selectingRalColorField = field;
                        defaultColour = field === WindowEditorField.EXTERNAL_COLOR ? this.systemRalExternalColors[0]
                            : this.systemRalInternalColors[0];
                        break;
                    case 'PLACEHOLDER_NCS':
                        this.selectingRalColorField = field;
                        defaultColour = field === WindowEditorField.EXTERNAL_COLOR ? this.systemNcsExternalColors[0]
                            : this.systemNcsInternalColors[0];
                        break;
                }
                this.handleRalColorSelected(defaultColour);
                return defaultColour.id;
            }
        } else {
            return undefined;
        }
    }

    flip(entranceData: EntranceDoorData) {
        entranceData.side = entranceData.side === DoorSide.RIGHT ? DoorSide.LEFT : DoorSide.RIGHT;
    }

    checkUnavailableItemsSelected(items: any[], selectedItemId: number, field) {
        if (items.some(item => !item.active && item.id === selectedItemId)) {
            this.unavailableItemsSelected.push(field);
        }
    }

    removeFieldFromUnavailable(field: WindowEditorField) {
        const index = this.unavailableItemsSelected.indexOf(field, 0);
        if (index > -1) {
            this.unavailableItemsSelected.splice(index, 1);
        }
    }

    closeExitWithMessagesDialog() {
        this.saveInProgress = false;
        this.showExitWithMessagesDialog = false;
    }

    confirmExitWithMessages(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.saveItem();
    }

    largeImageGetter(): (itemId: number) => Observable<string> {
        return (itemId: number) => {
            return this.windowSystemDefinitionService.getWindowEditorImage(itemId);
        };
    }
}

enum TabPanels {
    GENERAL = 0,
    PRICING = 1,
    VALIDATION = 2
}
