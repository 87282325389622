import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MailBoxConfiguration} from './mail-box-configuration';

@JsonDeserializable
export class SubsystemWebshopMailConfiguration {
    mailTitle: string;
    mailBody: string;
    mailPreheader: string;
    sender: string;
    bcc: string;
    partnerOffersBcc: string;

    mailBoxConfiguration: MailBoxConfiguration;

    attachFile: boolean;

    constructor() {
        this.mailTitle = undefined;
        this.mailBody = undefined;
        this.mailPreheader = undefined;
        this.sender = undefined;
        this.bcc = undefined;
        this.partnerOffersBcc = undefined;
        this.mailBoxConfiguration = new MailBoxConfiguration();
        this.attachFile = undefined;
    }

    static fromJSON(jsonObject: any): SubsystemWebshopMailConfiguration {
        const mailConfig = new SubsystemWebshopMailConfiguration();
        mailConfig.mailTitle = jsonObject.mailTitle;
        mailConfig.mailBody = jsonObject.mailBody;
        mailConfig.mailPreheader = jsonObject.mailPreheader;
        mailConfig.sender = jsonObject.sender;
        mailConfig.bcc = jsonObject.bcc;
        mailConfig.partnerOffersBcc = jsonObject.partnerOffersBcc;
        mailConfig.mailBoxConfiguration = jsonObject.mailBoxConfiguration;
        mailConfig.attachFile = jsonObject.attachFile;
        return mailConfig;
    }
}
