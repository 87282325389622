export abstract class FileValidator {

    public static validationErrors(file: File, expectedType: string, maxSize: number): string {
        if (file) {
            if (expectedType != null && file.type !== expectedType) {
                return 'error.fileUpload.invalid_format';
            }
            if (file.size > maxSize) {
                return 'error.fileUpload.not_in_range';
            }
        }
        return undefined;
    }
}
