import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {UserConsent, UserWithConsentDataList} from '../../admin-panel/user-consents/userConsentsData';
import {TermsOfUse} from './termsOfUse';

@Injectable()
export class TermsOfUseService {

    private static readonly SERVICE_PATH = 'termsOfUse';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    saveTermsOfUse(termsOfUse: TermsOfUse, notifyUsers = false) {
        return this.http.post<void>(TermsOfUseService.SERVICE_PATH, termsOfUse, {params: {notifyUsers: '' + notifyUsers}});
    }

    getTermsOfUse(token?: string): Observable<TermsOfUse> {
        if (token) {
            let headers = {
                'Content-Type': 'text/plain',
                'Authorization': token
            };
            return this.http.get<TermsOfUse>(TermsOfUseService.SERVICE_PATH, {headers: headers});
        }
        return this.http.get<TermsOfUse>(TermsOfUseService.SERVICE_PATH);
    }

    confirmTermsOfUse(token: string): Observable<void> {
        let headers = {};
        headers['Content-Type'] = 'application/json';
        headers['Authorization'] = token;
        let options = {headers: headers};

        return this.http.post<void>(`${TermsOfUseService.SERVICE_PATH}/confirm`, undefined, options);
    }

    declineTermsOfUse(token: string): Observable<void> {
        let headers = {};
        headers['Content-Type'] = 'application/json';
        headers['Authorization'] = token;
        let options = {headers: headers};

        return this.http.post<void>(`${TermsOfUseService.SERVICE_PATH}/decline`, undefined, options);
    }

    getUsers(offset: number, pageSize: number, filters: any, sortColumn: string, sortOrder: number): Observable<UserWithConsentDataList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<UserWithConsentDataList>(`${TermsOfUseService.SERVICE_PATH}/users`, {params: params});
    }

    getUserConsents(userId: number): Observable<UserConsent[]> {
        return this.http.get<UserConsent[]>(`${TermsOfUseService.SERVICE_PATH}/users/${userId}`);
    }
}
