<div class="news-preview-container">
    <div class="news-header">
        <div class="news-title">{{ 'SETTINGS.SECTION.NEWS.GENERAL' | translate }}</div>
        <app-nav-more (goTo)="goToFullNews()" [labelKey]="'GENERAL.MORE'"></app-nav-more>
    </div>

    <div class="news-cards-container">
        <div *ngFor="let news of previewNews"
             [ngClass]="{ 'news-card': true, 'active-news-card': isCurrentNews(news), 'news-card-read': news.markedAsRead }"
             (click)="openNews(news.id)">
        <div class="card-date text-box">
                <i class="fa font-awesome fas fa-calendar"></i>
                <span>{{ news.validFrom | momentCurrentLang | amDateFormat: 'L | LT' }}</span>
            </div>
            <div class="preview-content">
                <div class="content-title text-box">{{ news.title | multilanguageTranslate }}</div>
                <div *ngIf="!news.image" class="content-text">{{ news.content | multilanguageTranslate | stripHtml }}</div>
                <div *ngIf="news.image" class="content-image"><img [src]="news.image | appSafeValue: 'url'"/></div>
            </div>
            <div class="card-footer">
                <app-nav-more (goTo)="openNews(news.id)"
                              [labelKey]="news.markedAsRead ? 'NEWS.READ_AGAIN' : 'NEWS.READ_MORE'"></app-nav-more>
            </div>
        </div>
    </div>
</div>
