import {OfferStatus} from "./enums/OfferStatus";

export class OfferStatusProvider {

    public static getOfferStatusesBeforeOrder(): OfferStatus[] {
        return [OfferStatus.NEW_OFFER,
            OfferStatus.PARTNER_NEW_OFFER,
            OfferStatus.SENT_TO_PARTNER,
            OfferStatus.TO_REVISE,
            OfferStatus.TO_SEND,
            OfferStatus.TO_VERIFY,
            OfferStatus.TO_REVIEW,
            OfferStatus.REVIEWED,
            OfferStatus.CANCEL,
            OfferStatus.VERIFICATION_APPROVED,
            OfferStatus.VERIFICATION_REJECTED,
            OfferStatus.VERIFICATION_FOR_APPROVAL,
            OfferStatus.COPY_DRAFT];
    }

    public static getOfferStatusFromOrder(): OfferStatus[] {
        return [OfferStatus.VERIFIED,
            OfferStatus.WAITING_FOR_PAYMENT,
            OfferStatus.SUSPENDED,
            OfferStatus.PAID,
            OfferStatus.CORRECTED,
            OfferStatus.PARTIALLY_DISPATCHED,
            OfferStatus.DISPATCHED,
            OfferStatus.CANCEL_ORDER,
            OfferStatus.CORRECTION];
    }

    public static isOfferStatus(offerStatus: OfferStatus): boolean {
        return this.getOfferStatusesBeforeOrder().indexOf(offerStatus) !== -1 || offerStatus === OfferStatus.DELETED;
    }

    public static isOrderStatus(offerStatus: OfferStatus): boolean {
        return this.getOfferStatusFromOrder().indexOf(offerStatus) !== -1 || offerStatus === OfferStatus.DELETED_ORDER;
    }

    public static isCorrectionStatus(offerStatus: OfferStatus): boolean {
        return offerStatus === OfferStatus.CORRECTION;
    }
}
