<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <ng-container [ngSwitch]="target">
            <div *ngSwitchCase="glazingPackageTargets.WEBSHOP_GLAZING" class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGES.LIST' | translate}}</div>
            <div *ngSwitchCase="glazingPackageTargets.UPSELLING_GLAZING" class="title">{{'ADMIN_PANEL.GLAZING_UPSELLING_SETTINGS.LIST' | translate}}</div>
            <div *ngSwitchCase="glazingPackageTargets.DECORATIVE_GLAZING" class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.SYSTEM_DECORATIVE_GLAZING_PACKAGES.LIST' | translate}}</div>
            <div *ngSwitchDefault class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.SYSTEM_GLAZING_PACKAGES.LIST' | translate}}</div>
        </ng-container>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" *ngIf="target !== glazingPackageTargets.UPSELLING_GLAZING"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords"
                 selectionMode="single" (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="glazingPackagesTable">
        <p-column *ngIf="fieldUsage.show(SystemGlazingPackageField.NAME)"
                  header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.NAME'|translate}}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains" field="name">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.SORT_INDEX'|translate}}" [sortable]="true" field="sortIndex"></p-column>
        <p-column *ngIf="fieldUsage.show(SystemGlazingPackageField.NAME)" [sortable]="false" field="hasGlazing1" header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.ONE_GLASS_GLAZING'|translate}}"
                  [filter]="showFilters" [filterValues]="filterHasGlazing | async">
            <ng-template let-item="rowData" pTemplate="body">
                <app-checkbox [ngModel]="item.hasGlazing1" [disabled]="true"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.TWO_GLASS_GLAZING'|translate}}" [sortable]="false" field="hasGlazing2"
                  [filter]="showFilters" [filterValues]="filterHasGlazing | async">
            <ng-template let-item="rowData" pTemplate="body">
                <app-checkbox [ngModel]="item.hasGlazing2" [disabled]="true"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.THREE_GLASS_GLAZING'|translate}}" [sortable]="false" field="hasGlazing3"
                  [filter]="showFilters"
                  [filterValues]="filterHasGlazing | async">
            <ng-template let-item="rowData" pTemplate="body">
                <app-checkbox [ngModel]="item.hasGlazing3" [disabled]="true"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.FOUR_GLASS_GLAZING'|translate}}" [sortable]="false" field="hasGlazing4"
                  [filter]="showFilters" [filterValues]="filterHasGlazing | async">
            <ng-template let-item="rowData" pTemplate="body">
                <app-checkbox [ngModel]="item.hasGlazing4" [disabled]="true"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column header="{{'WEBSHOP_GLAZING_PACKAGE.FORM.STATUS'|translate}}" field="active" [sortable]="false"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{(item.active ? 'WEBSHOP_GLAZING_PACKAGE.FORM.ACTIVE' : 'WEBSHOP_GLAZING_PACKAGE.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.DATA' | translate }}" [id]="STEPS.DATA" [validate]="VALIDATORS.DATA">
        <app-glazing-package-general-data-form [editPermits]="editPermits"
                                               [item]="item"
                                               [validationErrors]="validationErrors"
                                               [file]="file"
                                               [target]="target"
                                               (fileChange)="onFileChange($event)"
                                               [tags]="tags"></app-glazing-package-general-data-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)"
                     label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.SYSTEMS' | translate }}"
                     [id]="STEPS.SYSTEMS" [validate]="VALIDATORS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS) || hasWebshopAdditionalWindowSystemSelected()"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="[]"></app-link-selection>
            <app-link-selection *ngIf="isWebshopGlazingPackage(item)"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS) || hasWindowSystemSelected()"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="[ProductTypeGroup.DEFAULT]"
                                [windowSystemFilter]="WindowSystemType.isPredefinedGlazing"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="[]"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.GLAZING' | translate }}"
                     *ngIf="fieldUsage.show(CatalogTab.GLAZING)" [id]="STEPS.GLAZING" [validate]="VALIDATORS.GLAZING">
        <app-glazing-packages-form *ngIf="!hasWebshopAdditionalWindowSystemSelected()"
                                   [readOnly]="fieldUsage.disabled(CatalogTab.GLAZING)"
                                   [item]="item"
                                   [supportPackagesForAreaRanges]="target !== glazingPackageTargets.DECORATIVE_GLAZING"
                                   [validationErrors]="glazingValidationErrors"
                                   [glasses]="glasses"
                                   [target]="target"
                                   [frames]="frames"
                                   [addMode]="newItem"></app-glazing-packages-form>
        <app-webshop-glazing-package-glazing-form *ngIf="hasWebshopAdditionalWindowSystemSelected()"
                                                  [readOnly]="fieldUsage.disabled(CatalogTab.GLAZING)"
                                                  [item]="asWebshopGlazingPackage(item)"
                                                  [validationErrors]="validationErrors"
                                                  [graspGlazingPackages]="graspGlazingPackages"></app-webshop-glazing-package-glazing-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()"
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.DATA' | translate }}" [id]="STEPS.DATA" [validate]="VALIDATORS.DATA">
        <app-glazing-package-general-data-form [editPermits]="editPermits"
                                               [item]="item"
                                               [validationErrors]="validationErrors"
                                               [file]="file"
                                               [target]="target"
                                               (fileChange)="onFileChange($event)"
                                               [tags]="tags"></app-glazing-package-general-data-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)"
                     label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.SYSTEMS' | translate }}"
                     [id]="STEPS.SYSTEMS" [validate]="VALIDATORS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS) || hasWebshopAdditionalWindowSystemSelected()"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="[]"></app-link-selection>
            <app-link-selection *ngIf="isWebshopGlazingPackage(item)"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS) || hasWindowSystemSelected()"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="[ProductTypeGroup.DEFAULT]"
                                [windowSystemFilter]="WindowSystemType.isPredefinedGlazing"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="[]"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.GLAZING' | translate }}"
                     *ngIf="fieldUsage.show(CatalogTab.GLAZING)" [id]="STEPS.GLAZING" [validate]="VALIDATORS.GLAZING">
        <app-glazing-packages-form *ngIf="!hasWebshopAdditionalWindowSystemSelected()"
                                   [readOnly]="fieldUsage.disabled(CatalogTab.GLAZING)"
                                   [item]="item"
                                   [supportPackagesForAreaRanges]="target !== glazingPackageTargets.DECORATIVE_GLAZING"
                                   [validationErrors]="glazingValidationErrors"
                                   [glasses]="glasses"
                                   [target]="target"
                                   [frames]="frames"></app-glazing-packages-form>
        <app-webshop-glazing-package-glazing-form *ngIf="hasWebshopAdditionalWindowSystemSelected()"
                                                  [readOnly]="fieldUsage.disabled(CatalogTab.GLAZING)"
                                                  [item]="asWebshopGlazingPackage(item)"
                                                  [validationErrors]="validationErrors"
                                                  [graspGlazingPackages]="graspGlazingPackages"></app-webshop-glazing-package-glazing-form>
    </app-wizard-step>
</app-wizard-dialog>
