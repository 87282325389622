import {Pipe, PipeTransform} from '@angular/core';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';

@Pipe({
    name: 'glazingPackageCategoryName'
})
export class GlazingPackageCategoryNamePipe implements PipeTransform {

    transform(categoryId: number, categoriesList: {
        id: number;
        name: MultilanguageFieldInterface;
    }[] | undefined): MultilanguageFieldInterface | undefined {
        if (categoriesList == undefined) {
            return undefined;
        }
        const category = categoriesList.find(c => c.id === categoryId);
        return category != undefined ? category.name : undefined;
    }
}
