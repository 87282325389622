<div class="new-form-400">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'ASSEMBLY.FORM.NAME' | translate }}" [maxlength]="100"
                        [(validationMessageKey)]="validationErrors['name']"
                        [(ngModel)]="item.name" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="unit" label="{{ 'ASSEMBLY.FORM.UNIT' | translate }}"
                    [(validationMessageKey)]="validationErrors['assemblyUnit']" [options]="assemblyUnits"
                    [(ngModel)]="item.assemblyUnit" [required]="true"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="value" label="{{ 'ASSEMBLY.FORM.VALUE' | translate }}" [min]="0"
                          [(validationMessageKey)]="validationErrors['value']"
                          [(ngModel)]="item.value" [required]="true"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'ASSEMBLY.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'ASSEMBLY.FORM.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>
</div>
