import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of} from 'rxjs';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopColorWithPrice} from '../../webshop-pricing-interfaces';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';
import {TranslatedSelectItemService} from "../../../../../common/service/translated-select-item.service";

enum ColorSideOption {
    NONE,
    EXTERNAL,
    BOTH
}

@Component({
    selector: 'app-webshop-recommended-configuration-color-form',
    templateUrl: './webshop-recommended-configuration-color-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TranslatedSelectItemService]
})
export class WebshopRecommendedConfigurationColorFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    readonly colorSideOptions: SelectItem[] = [
        {label: 'WEBSHOP_RECOMMENDED_CONFIGURATION.COLOR_SIDES.NONE', value: ColorSideOption.NONE},
        {label: 'WEBSHOP_RECOMMENDED_CONFIGURATION.COLOR_SIDES.EXTERNAL', value: ColorSideOption.EXTERNAL},
        {label: 'WEBSHOP_RECOMMENDED_CONFIGURATION.COLOR_SIDES.BOTH', value: ColorSideOption.BOTH}
    ];

    translatedColorSideOptions: Observable<SelectItem[]>;

    selectedColorSide: ColorSideOption;

    allColorOptions: {
        [ColorSideOption.NONE]: SelectItem[],
        [ColorSideOption.EXTERNAL]: SelectItem[],
        [ColorSideOption.BOTH]: SelectItem[]
    };

    colorOptions: SelectItem[];

    constructor(private service: WebshopRecommendedConfigurationService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private translatedSelectItemService: TranslatedSelectItemService) {
        this.translatedColorSideOptions = this.translatedSelectItemService.buildUnsortedDropdown(this.colorSideOptions, option => option.label, undefined);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const itemChange = changes['item'];
        if (itemChange != undefined) {
            this.selectedColorSide = this.determineSelectedColorSide(itemChange.currentValue as WebshopRecommendedConfiguration);
        }
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            forkJoin({
                [ColorSideOption.NONE]: of<WebshopColorWithPrice[]>([]),
                [ColorSideOption.EXTERNAL]: this.service.getColors(Object.assign({}, this.item.productConfiguration, {
                    externalColorId: undefined,
                    internalColorId: undefined
                }), false),
                [ColorSideOption.BOTH]: this.service.getColors(Object.assign({}, this.item.productConfiguration, {
                    externalColorId: undefined,
                    internalColorId: undefined
                }), true)
            }).subscribe(data => {
                this.allColorOptions = {
                    [ColorSideOption.NONE]: data[ColorSideOption.NONE].map(color => ({
                        label: color.color.names[this.translate.currentLang],
                        value: color.color.id
                    })),
                    [ColorSideOption.EXTERNAL]: data[ColorSideOption.EXTERNAL].map(color => ({
                        label: color.color.names[this.translate.currentLang],
                        value: color.color.id
                    })),
                    [ColorSideOption.BOTH]: data[ColorSideOption.BOTH].map(color => ({
                        label: color.color.names[this.translate.currentLang],
                        value: color.color.id
                    }))
                };
                this.selectedColorSide = this.determineSelectedColorSide(this.item);
                this.colorOptions = this.allColorOptions[this.selectedColorSide];
                this.changeDetector.markForCheck();
            });
        }
    }

    private determineSelectedColorSide(webshopRecommendedConfiguration: WebshopRecommendedConfiguration): ColorSideOption {
        if (webshopRecommendedConfiguration != undefined) {
            const productConfiguration = webshopRecommendedConfiguration.productConfiguration;
            if (productConfiguration.externalColorId == undefined) {
                return ColorSideOption.NONE;
            } else if (productConfiguration.internalColorId == undefined) {
                return ColorSideOption.EXTERNAL;
            } else {
                return ColorSideOption.BOTH;
            }
        } else {
            return ColorSideOption.NONE;
        }
    }

    handleColorSideChange(colorSide: SelectItem): void {
        this.selectedColorSide = colorSide.value;
        if (colorSide != undefined) {
            this.colorOptions = this.allColorOptions[colorSide.value];
            // clear unwanted fields for side selection
            switch (colorSide.value) {
                case ColorSideOption.NONE:
                    this.item.productConfiguration.externalColorId = undefined;
                    this.item.productConfiguration.internalColorId = undefined;
                    break;
                case ColorSideOption.EXTERNAL:
                    this.item.productConfiguration.internalColorId = undefined;
                    break;
                default:
                    break;
            }
        }
    }

    handleColorChange(colorId: number): void {
        switch (this.selectedColorSide) {
            case ColorSideOption.NONE:
                break;
            case ColorSideOption.EXTERNAL:
                this.item.productConfiguration.externalColorId = colorId;
                break;
            case ColorSideOption.BOTH:
                this.item.productConfiguration.externalColorId = colorId;
                this.item.productConfiguration.internalColorId = colorId;
                break;
        }
    }
}
