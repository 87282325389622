import {ConfigurableAddon} from '../../../../../../../window-designer/entities/ConfigurableAddon';
import {PositionType} from "../../../../AbstractPosition";
import {WindowEditorPositionData} from '../../../window-editor-offer-interfaces';

export class ConfigurableAddonPositionModel {

    position: WindowEditorPositionData;
    configurableAddon: ConfigurableAddon;

    constructor(position: WindowEditorPositionData, configurableAddon: ConfigurableAddon) {
        this.position = position;
        this.configurableAddon = configurableAddon;
    }

    get deprecated(): boolean {
        return this.position.type === PositionType.CONFIGURABLE_ADDON;
    }
}
