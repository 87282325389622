<p-dialog *ngIf="dataReady" [modal]="true" (onHide)="resetDialog()" [visible]="true" [resizable]="false" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_GLASS_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog new-form-600">
        <ng-container *ngIf="!isTerrace else terraceGlazing">
            <app-glass-selection [commonData]="glazing" [glasses]="glasses" [frames]="frames"
                                 [validateInstantly]="false"
                                 [validationErrors]="validationErrors" [boldGlasses]="boldGlasses"
                                 [boldFrames]="boldFrames" (glassQuantityEmitter)="onGlassQnChange($event)"
                                 (glassChangeEmitter)="onGlassChange($event)"
                                 (frameChangeEmitter)="onFrameChange()"></app-glass-selection>
            <div class="new-form-row">
                <app-select inputId="glazingBead" label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD' | translate }}"
                            [(validationMessageKey)]="validationErrors['selectedGlazingBeads']"
                            [options]="filteredGlazingBeadList" [(ngModel)]="selectedGlazingBead"></app-select>
            </div>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<ng-template #terraceGlazing>
    <app-select inputId="terraceGlazing" label="{{ 'OFFER.TABS.SECTION.FILLING.TERRACE_GLAZING_PACKAGE' | translate }}"
                [(validationMessageKey)]="validationErrors['selectedTerraceGlazing']"
                [optionFormatter]="formatGlazingOption"
                [options]="terraceGlazings" [(ngModel)]="selectedTerraceGlazing"></app-select>
</ng-template>
