import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'underscore';
import {WindowSystemType, WindowSystemTypeData} from "../../../../window-designer/catalog-data/window-system-interface";
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {MultilanguageField} from '../../../supportedLanguages';
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';

export const LINKS_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LinkSelectionComponent),
    multi: true
};

class LinkableItem {
    id: number;
    symbol: string;
    names: MultilanguageField;
    selected: boolean;
}

class LinkableItemByMaterial {
    materialName: string;
    linkableItems: LinkableItem[];
    allSystemSelectionState: TristateCheckboxState;
}

export enum LinkableEntities {
    WINDOW_SYSTEMS = 'WINDOW_SYSTEMS',
    ENTRANCE_MODELS = 'ENTRANCE_MODELS',
    GATE_SYSTEMS = 'GATE_SYSTEMS',
    CONFIG_ADDONS = 'CONFIG_ADDONS'
}

@Component({
    selector: 'app-link-selection',
    templateUrl: './link-selection.component.html',
    styleUrls: ['./link-selection.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [WindowSystemDefinitionService, DataServiceHelper, LINKS_VALUE_ACCESSOR]
})
export class LinkSelectionComponent implements ControlValueAccessor, OnChanges {

    @Input()
    linkableItems: ItemForCatalogLinking[] = [];

    @Input()
    lockedItemsIds: number[] = [];

    @Input()
    linkableEntity: LinkableEntities = LinkableEntities.WINDOW_SYSTEMS;

    @Input()
    showToggleAllButton = false;

    @Input()
    toggleAllLabel = 'LINK-SELECTIONS.SELECT-ALL.WINDOW-SYSTEMS';

    @Input()
    windowSystemTypeGroups: ProductTypeGroup[];

    @Input()
    windowSystemFilter: (ws: WindowSystemTypeData) => boolean = this.alwaysTrue;

    globallyDisabled = false;
    itemsByMaterial: LinkableItemByMaterial[];
    LinkableEntity = LinkableEntities;
    private selectedIds: number[] = [];
    private onChange: (value) => void;


    constructor(public translate: TranslateService, private changeDetector: ChangeDetectorRef) {
    }

    alwaysTrue(ws: WindowSystemTypeData): boolean {
        return true;
    }

    writeValue(obj: any): void {
        this.selectedIds = obj || [];
        if (this.itemsByMaterial != undefined) {
            this.itemsByMaterial.forEach(material => {
                material.linkableItems.forEach(item => {
                    item.selected = this.selectedIds.indexOf(item.id) !== -1;
                });

                material.allSystemSelectionState = this.calculateAllMaterialSystemSelectedState(material);
            });
        }
        this.changeDetector.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.globallyDisabled = isDisabled;
        this.changeDetector.markForCheck();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('linkableItems' in changes) {
            let newItems: ItemForCatalogLinking[] = [];
            if (changes['linkableItems'].currentValue) {
                newItems = changes['linkableItems'].currentValue;
            }
            this.itemsByMaterial = _.chain(newItems)
                .filter(item => this.windowSystemTypeGroups == undefined
                    || this.windowSystemTypeGroups.includes(WindowSystemType.getByName(item.systemType).group)
                    && this.windowSystemFilter(WindowSystemType.getByName(item.systemType)))
                .groupBy(item => item.material)
                .pairs()
                .map(linkMaterialPair => {
                    return {
                        materialName: 'MATERIAL.' + linkMaterialPair[0],
                        linkableItems: linkMaterialPair[1].map(linkItem => {
                            return {
                                id: linkItem.id,
                                symbol: linkItem.symbol,
                                names: linkItem.names,
                                selected: this.selectedIds.indexOf(linkItem.id) !== -1
                            };
                        }),
                        allSystemSelectionState: TristateCheckboxState.UNCHECKED
                    };
                })
                .value();

            this.itemsByMaterial.forEach(material => material.allSystemSelectionState =
                this.calculateAllMaterialSystemSelectedState(material));
        }
    }

    isItemLocked(id: number): boolean {
        return this.lockedItemsIds.indexOf(id) !== -1;
    }

    onItemSelectionChange(linkableItem: LinkableItem, selected: boolean): void {
        linkableItem.selected = selected;
        if (selected) {
            this.selectedIds.push(linkableItem.id);
        } else {
            this.selectedIds = this.selectedIds.filter(id => id !== linkableItem.id);
        }

        for (let material of this.itemsByMaterial) {
            material.allSystemSelectionState = this.calculateAllMaterialSystemSelectedState(material);
        }
        this.onChange(this.selectedIds);
    }

    onSelectAllMaterialSystemsChange(material: LinkableItemByMaterial): void {
        if (!this.globallyDisabled) {
            let newState: boolean = material.allSystemSelectionState === TristateCheckboxState.CHECKED_PARTIALLY ||
                material.allSystemSelectionState === TristateCheckboxState.UNCHECKED;

            material.linkableItems.forEach(windowSystem => {
                if (!this.isItemLocked(windowSystem.id)) {
                    this.onItemSelectionChange(windowSystem, newState);
                }
            });
        }
    }

    buildCheckboxId(linkableItem: LinkableItem): string {
        return 'linkableItem_' + linkableItem.symbol.replace(' ', '');
    }

    toggleAll(): void {
        let allPreviouslySelected = this.linkableItems.length === this.selectedIds.length;
        for (let material of this.itemsByMaterial) {
            for (let windowSystem of material.linkableItems) {
                if (!this.isItemLocked(windowSystem.id)) {
                    if (windowSystem.selected === allPreviouslySelected) {
                        windowSystem.selected = !allPreviouslySelected;
                        if (allPreviouslySelected) {
                            this.selectedIds = this.selectedIds.filter(id => id !== windowSystem.id);
                        } else {
                            this.selectedIds.push(windowSystem.id);
                        }
                    }
                }
            }

            material.allSystemSelectionState = this.calculateAllMaterialSystemSelectedState(material);
        }
        this.onChange(this.selectedIds);
    }

    private calculateAllMaterialSystemSelectedState(material: LinkableItemByMaterial): TristateCheckboxState {
        let selectedSystems = material.linkableItems.filter(system => this.selectedIds.find(id => system.id === id) != undefined);

        if (selectedSystems.length === 0) {
            return TristateCheckboxState.UNCHECKED;
        } else if (selectedSystems.length === material.linkableItems.filter(item => !this.lockedItemsIds.includes(item.id)).length) {
            return TristateCheckboxState.CHECKED;
        } else {
            return TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }
}
