import {OtherFilling} from "./otherFilling";

export class OtherFillingWithMaterials extends OtherFilling {

    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;

    constructor() {
        super();
    }
}
