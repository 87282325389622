import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {ValidationErrors} from '../../../common/validation-errors';
import {MultiValidator} from "../../../shared/validator/input-validator";
import {WindowSystemDefinition} from "./window-system-definition";

export class WindowSystemDefinitionFormValidator {

    public validateGeneralData(toValidate: WindowSystemDefinition, userLang: string): ValidationErrors {
        let validationErrors = {};

        if (!toValidate.names[userLang]) {
            validationErrors[`names[${userLang}]`] = `error.windowSystemDto.names[${userLang}].not_empty`;
        }

        if (!toValidate.symbol) {
            validationErrors['symbol'] = 'error.windowSystemDto.symbol.not_empty';
        }

        if (!toValidate.material) {
            validationErrors['material'] = 'error.windowSystemDto.material.not_empty';
        }
        if (!toValidate.supplier) {
            validationErrors['supplier'] = 'error.windowSystemDto.supplier.not_empty';
        }
        if (toValidate.pcn) {
            let pattern = /PCN [0-9][0-9] [0-9][0-9] [0-9][0-9] [0-9][0-9]/g;
            if (!pattern.test(toValidate.pcn)) {
                validationErrors['pcn'] = 'error.windowSystemDto.pcn.pattern_not_matched';
            }
        }

        if (!toValidate.systemType) {
            validationErrors['systemType'] = 'error.windowSystemDto.systemType.not_empty';
        } else if (!WindowSystemType.getByName(toValidate.systemType)) {
            validationErrors['systemType'] = 'error.windowSystemDto.systemType.pattern_not_matched';
        }

        validationErrors['pricetableSelectionMode'] = MultiValidator.of('error.windowSystemDto.pricetableSelectionMode')
            .withNotNullValidator()
            .validate(toValidate.pricetableSelectionMode);

        if (toValidate.systemType === WindowSystemType.ROOF.type) {
            validationErrors['sortIndex'] = MultiValidator.of('error.windowSystemDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(toValidate.roofSystemSortIndex);

            validationErrors['uw'] = MultiValidator.of('error.windowSystemDto.uw')
                .withNotNullValidator()
                .withDecimalValidator()
                .withRangeValidator(0, 99)
                .validate(toValidate.uw);

            validationErrors['unitWeight'] = MultiValidator.of('error.windowSystemDto.unitWeight')
                .withNotNullValidator()
                .withDecimalValidator()
                .withRangeValidator(0, 9999)
                .validate(toValidate.unitWeight);
        } else if (toValidate.systemType === WindowSystemType.ENTRANCE.type) {
            validationErrors['sortIndex'] = MultiValidator.of('error.windowSystemDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(toValidate.entranceSystemSortIndex);
            validationErrors['unitWeight'] = MultiValidator.of('error.windowSystemDto.unitWeight')
                .withNotNullValidator()
                .withDecimalValidator()
                .withRangeValidator(0, 9999)
                .validate(toValidate.unitWeight);
        } else {
            validationErrors['sortIndex'] = MultiValidator.of('error.windowSystemDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(toValidate.sortIndex);
        }

        return validationErrors;
    }

    public validateAngles(toValidate: WindowSystemDefinition): ValidationErrors {
        let validationErrors = {};

        validationErrors['maxFrameSize'] = MultiValidator.of('error.windowSystemDto.maxFrameSize')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, null, false, false)
            .validate(toValidate.maxFrameSize);

        validationErrors['minAngleF'] = MultiValidator.of('error.windowSystemDto.minAngleF')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 90)
            .validate(toValidate.minAngleF);

        validationErrors['minAngleFW'] = MultiValidator.of('error.windowSystemDto.minAngleFW')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 90)
            .validate(toValidate.minAngleFW);

        validationErrors['minAngleR'] = MultiValidator.of('error.windowSystemDto.minAngleR')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 90)
            .validate(toValidate.minAngleR);

        validationErrors['minAngleU'] = MultiValidator.of('error.windowSystemDto.minAngleU')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 90)
            .validate(toValidate.minAngleU);

        validationErrors['glazingWidths'] = MultiValidator.of('error.windowSystemDto.glazingWidths')
            .withNotNullValidator()
            .withWidthsValidator(0, 1000, true, false)
            .validate(toValidate.glazingWidths);

        validationErrors['defaultWidth'] = MultiValidator.of('error.windowSystemDto.defaultWidth')
            .withNotNullValidator()
            .withRangeValidator(1, 15000, true, false)
            .validate(toValidate.defaultWidth);

        validationErrors['defaultHeight'] = MultiValidator.of('error.windowSystemDto.defaultHeight')
            .withNotNullValidator()
            .withRangeValidator(1, 10000, true, false)
            .validate(toValidate.defaultHeight);

        return validationErrors;
    }

    public validateAssembly(toValidate: WindowSystemDefinition, validationErrors: ValidationErrors): void {
        validationErrors['wingWidth.defaultWidth'] = MultiValidator.of('error.windowSystemDto.wingWidth.defaultWidth')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999)
            .validate(toValidate.wingWidth.defaultWidth);

        validationErrors['glazingBeadWidth.defaultWidth'] =
            MultiValidator.of('error.windowSystemDto.glazingBeadWidth.defaultWidth')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999)
                .validate(toValidate.glazingBeadWidth.defaultWidth);

        validationErrors['wingImpositionWidth.defaultWidth'] =
            MultiValidator.of('error.windowSystemDto.wingImpositionWidth.defaultWidth')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(0, 99999)
                .validate(toValidate.wingImpositionWidth.defaultWidth);

        validationErrors['glazingPackageSeatingDepth.defaultWidth'] =
            MultiValidator.of('error.windowSystemDto.glazingPackageSeatingDepth.defaultWidth')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(0, 99999)
                .validate(toValidate.glazingPackageSeatingDepth.defaultWidth);

        if (toValidate.hsSystem) {
            validationErrors['wingsImpositionWidth'] = MultiValidator.of('error.windowSystemDto.wingsImpositionWidth')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999)
                .validate(toValidate.wingsImpositionWidth);

            validationErrors['wingsContactWidth'] = MultiValidator.of('error.windowSystemDto.wingsContactWidth')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999)
                .validate(toValidate.wingsContactWidth);
        }

        validationErrors['frameThickness'] = MultiValidator.of('error.windowSystemDto.frameThickness')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 999)
            .validate(toValidate.frameThickness);

        validationErrors['frameWingCompositionThickness'] = MultiValidator.of('error.windowSystemDto.frameWingCompositionThickness')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 999)
            .validate(toValidate.frameWingCompositionThickness);

        validationErrors['systemThickness'] = MultiValidator.of('error.windowSystemDto.systemThickness')
            .withIntegerValidator()
            .withRangeValidator(1, 999)
            .validate(toValidate.systemThickness);
    }

    public validateShapesData(toValidate: WindowSystemDefinition): ValidationErrors {
        let validationErrors = {};
        let limitations = toValidate.subwindowTypesLimitations;
        for (let type of Object.keys(limitations)) {
            if (limitations[type].minSideLength == undefined) {
                validationErrors['subwindowTypesLimitations[' + type + '].minSideLength'] =
                    'error.windowSystemDto.minSideLength.is_empty';
            } else {
                validationErrors['subwindowTypesLimitations[' + type + '].minSideLength'] =
                    MultiValidator.of('error.windowSystemDto.minSideLength')
                        .withIntegerValidator().withRangeValidator(0, null)
                        .validate(limitations[type].minSideLength);
            }
        }
        return validationErrors;
    }

    public validateGlamourPrintData(toValidate: WindowSystemDefinition): ValidationErrors {
        const validationErrors = {};
        if (toValidate.systemType !== WindowSystemType.ROOF.type) {
            if (toValidate.glamourDisplayUw != null && toValidate.glamourDisplayUw <= 0) {
                validationErrors['glamourDisplayUw'] = 'error.windowSystemDto.glamourDisplayUw.below_min';
            }
        }
        return validationErrors;
    }
}
