import {ArcCutData} from "../drawing-data/ArcCutData";
import {CutData} from "../drawing-data/CutData";
import {LineCutData} from "../drawing-data/LineCutData";
import {DrawingUtil} from "../drawing-util";
import {FloatOps} from "./float-ops";
import {PolygonPoint, PolygonPointUtil} from "./PolygonPoint";

export class CutsUtil {

    private static readonly ARC_TO_LINES_ACCURACY = 32;

    public static cutDataIntersectsPolygon(polygonPoints: number[], cut: CutData): boolean {
        if (CutData.isLine(cut)) {
            return DrawingUtil.polygonLineIntersectionCount(polygonPoints, cut.points, true) === 2;
        } else if (CutData.isArc(cut)) {
            // TODO: Optimize performance
            return CutsUtil.convertArcToLines(cut).some(l => DrawingUtil.polygonLineIntersectionCount(polygonPoints, l.points, true) === 2);
        }
    }

    public static applyCut(framePoints: number[], cut: LineCutData, distanceOffset: number, round = true): number[] {
        let result = PolygonPointUtil.toNumbersArray(CutsUtil.applyLineCutInternal(
            PolygonPointUtil.toPolygonPoints(framePoints), cut, distanceOffset, false));
        return round ? result.map(p => this.geometricalFriendlyRound(p)) : result;
    }

    public static applyCutFull(framePoints: PolygonPoint[], cut: LineCutData, distanceOffset: number): PolygonPoint[] {
        return CutsUtil.applyLineCutInternal(framePoints, cut, distanceOffset, false)
            .map(p => new PolygonPoint(FloatOps.round(p.x), FloatOps.round(p.y), p.isArc));
    }

    public static applyCuts(framePoints: number[], cuts: CutData[], distanceOffset: number, noRounding = false): number[] {
        return PolygonPointUtil.toNumbersArray(this.applyCutsFull(framePoints, cuts, distanceOffset, noRounding));
    }

    public static applyCutsFull(framePoints: number[], cuts: CutData[], distanceOffset: number,
                                noRounding = false): PolygonPoint[] {
        let onlyUnique = (value: LineCutData, index: number, self: LineCutData[]) => self.findIndex(
            v => this.lineCutEquals(v, value)) === index;
        let arcs: LineCutData[] = [].concat.apply([], cuts.filter(CutData.isArc).map(this.convertArcToLines));
        let lines = cuts.filter(CutData.isLine).filter(onlyUnique) as LineCutData[];

        let points = PolygonPointUtil.toPolygonPoints(framePoints);
        points = lines.reduce((path, cut) => CutsUtil.applyLineCutInternal(path, cut, distanceOffset, false), points);
        points = arcs.reduce((path, cut) => CutsUtil.applyLineCutInternal(path, cut, distanceOffset, true), points);
        return points.map(p => new PolygonPoint(noRounding ? p.x : FloatOps.round(p.x), noRounding ? p.y : FloatOps.round(p.y), p.isArc))
            .filter((v, i, a) => {
                let previous = DrawingUtil.getPoint(a, i - 1);
                if (v.x === previous.x && v.y === previous.y) {
                    previous.isArc = v.isArc || previous.isArc;
                    return false;
                }
                return true;
            });
    }

    public static splitPolygonWithLine(polygonPoints: number[], cutLine: number[]): number[][] {
        let cx1 = cutLine[0];
        let cy1 = cutLine[1];
        let cx2 = cutLine[2];
        let cy2 = cutLine[3];
        let newPoints1: number[] = [];
        let newPoints2: number[] = [];
        for (let j = 0; j < polygonPoints.length; j += 2) {
            let x1 = DrawingUtil.getPoint(polygonPoints, j);
            let y1 = DrawingUtil.getPoint(polygonPoints, j + 1);
            let x2 = DrawingUtil.getPoint(polygonPoints, j + 2);
            let y2 = DrawingUtil.getPoint(polygonPoints, j + 3);

            let intersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, cx1, cy1, cx2, cy2);

            let p1IsAbove = FloatOps.gt((cx2 - cx1) * (y1 - cy1) - (cy2 - cy1) * (x1 - cx1), 0);
            // cut with an arc will additionally check if the point is inside ellipse
            if (p1IsAbove) {
                this.pushPointIfUnique(newPoints1, x1, y1);
            } else {
                this.pushPointIfUnique(newPoints2, x1, y1);
            }
            if (intersection.onLine1) {
                this.pushPointIfUnique(newPoints1, intersection.x, intersection.y);
                this.pushPointIfUnique(newPoints2, intersection.x, intersection.y);
            }
        }
        return [newPoints1, newPoints2];
    }

    /**
     * Returns new polygon that is the cartesian product of given polygons
     * @param destPolygon
     * @param trimmingPolygon
     * @param pointInTrimmingPolygon some point that lay inside the trimmingPolygon, will be calculated if not provided
     * @returns number[] | null new polygon that is the cartesian product of given polygons if exists, in other case null
     */
    public static trimPolygonWithPolygon(destPolygon: number[], trimmingPolygon: number[],
                                         pointInTrimmingPolygon?: number[]): number[] | null {
        if (!pointInTrimmingPolygon) {
            pointInTrimmingPolygon = this.getAnyPointInPolygon(trimmingPolygon);
        }
        let destPoints = PolygonPointUtil.toPolygonPoints(destPolygon);
        let trimmingX = pointInTrimmingPolygon[0];
        let trimmingY = pointInTrimmingPolygon[1];
        for (let i = 0; i < trimmingPolygon.length; i += 2) {
            let cx1 = trimmingPolygon[i];
            let cy1 = trimmingPolygon[i + 1];
            let cx2 = DrawingUtil.getPoint(trimmingPolygon, i + 2);
            let cy2 = DrawingUtil.getPoint(trimmingPolygon, i + 3);
            let cutPlaneAbove = FloatOps.gt((cx2 - cx1) * (trimmingY - cy1) - (cy2 - cy1) * (trimmingX - cx1), 0);
            destPoints = this.trimPolygonWithLineInternal(destPoints, cx1, cy1, cx2, cy2, cutPlaneAbove, false);
            if (destPoints.length === 0) {
                return null;
            }
        }
        return PolygonPointUtil.toNumbersArray(destPoints);
    }

    public static getAnyPointInPolygon(polygon: number[]): number[] {
        let bounds = DrawingUtil.calculatePolygonTotalBoundingBox(polygon);
        let midY = (bounds.maxY + bounds.minY) / 2;
        let xMinAndMax = DrawingUtil.getMinAndMax(polygon, midY, false);
        let midX = (xMinAndMax[1] + xMinAndMax[0]) / 2;
        return [midX, midY];
    }

    private static applyLineCutInternal(framePoints: PolygonPoint[], cut: LineCutData, distanceOffset: number,
                                        isArcCut: boolean): PolygonPoint[] {
        if (cut.side == undefined) {
            return framePoints;
        }
        let isAbove = cut.side === 'top';
        let distance = isAbove ? distanceOffset : -distanceOffset;
        let cutLine = DrawingUtil.getParallelLine(cut.points, distance);
        let cx1 = cutLine[0];
        let cy1 = cutLine[1];
        let cx2 = cutLine[2];
        let cy2 = cutLine[3];
        return this.trimPolygonWithLineInternal(framePoints, cx1, cy1, cx2, cy2, isAbove, isArcCut);
    }

    private static trimPolygonWithLineInternal(polygon: PolygonPoint[], cx1: number, cy1: number, cx2: number, cy2: number,
                                               cutPlaneAbove: boolean, isArcCut: boolean): PolygonPoint[] {
        let newPoints: PolygonPoint[] = [];
        for (let j = 0; j < polygon.length; j++) {
            let p1 = DrawingUtil.getPoint(polygon, j);
            let p2 = DrawingUtil.getPoint(polygon, j + 1);

            let intersection = DrawingUtil.lineIntersection(p1.x, p1.y, p2.x, p2.y, cx1, cy1, cx2, cy2);

            let p1IsAbove = FloatOps.gt((cx2 - cx1) * (p1.y - cy1) - (cy2 - cy1) * (p1.x - cx1), 0);
            // cut with an arc will additionally check if the point is inside ellipse
            if (p1IsAbove === cutPlaneAbove) {
                this.pushPolygonPointIfUnique(newPoints, p1.x, p1.y, p1.isArc);
            }
            if (intersection.onLine1) {
                this.pushPolygonPointIfUnique(newPoints, intersection.x, intersection.y, isArcCut);
            }
        }
        return newPoints.length < 3 ? [] : newPoints;
    }

    private static pushPolygonPointIfUnique(arr: PolygonPoint[], x: number, y: number, isArcCut: boolean) {
        if (!arr.some(p => p.x === x && p.y === y)) {
            arr.push(new PolygonPoint(x, y, isArcCut));
        }
    }

    private static pushPointIfUnique(arr: number[], x: number, y: number) {
        for (let i = 0; i < arr.length; i += 2) {
            if (arr[i] === x && arr[i + 1] === y) {
                return;
            }
        }
        arr.push(x, y);
    }

    private static lineCutEquals(cut1: LineCutData, cut2: LineCutData) {
        return cut1.type === cut2.type && (
            (cut1.side === cut2.side &&
            cut1.points[0] === cut2.points[0] && cut1.points[1] === cut2.points[1]
            && cut1.points[2] === cut2.points[2] && cut1.points[3] === cut2.points[3]) ||
            (cut1.side !== cut2.side &&
            cut1.points[0] === cut2.points[2] && cut1.points[1] === cut2.points[3]
            && cut1.points[2] === cut2.points[0] && cut1.points[3] === cut2.points[1]));
    }

    private static convertArcToLines(arc: ArcCutData): LineCutData[] {
        const pointsArray = [];
        const angleStep = (arc.angle2 - arc.angle1) / CutsUtil.ARC_TO_LINES_ACCURACY;
        for (let i = 0; i <= CutsUtil.ARC_TO_LINES_ACCURACY; ++i) {
            let fi = arc.angle1 + angleStep * i;
            let x = arc.cx + arc.rx * Math.cos(fi);
            let y = arc.cy + arc.ry * Math.sin(fi);
            pointsArray.push([x, y]);
        }
        let linesArray = [];
        for (let i = 1; i < pointsArray.length; ++i) {
            let c1 = pointsArray[i - 1];
            let c2 = pointsArray[i];
            linesArray.push([...c1, ...c2]);
        }
        return linesArray.map(p => new LineCutData(p, "top"));
    }

    // When calculating line intersections with 0.5mm offset we sometimes get results like:
    // - x.49999999
    // - x.50000001
    // that should represent the same point.
    private static geometricalFriendlyRound(value: number): number {
        if (value < 0) {
            return -Math.round(Math.round(-value * 10) / 10);
        }
        return Math.round(Math.round(value * 10) / 10);
    }

    public static getCutLength(cut: CutData): number {
        let cutLength;
        if (CutData.isLine(cut)) {
            cutLength = DrawingUtil.segmentsLength(cut.points);
        } else if (CutData.isArc(cut)) {
            console.log(cut);
            cutLength =
                CutsUtil.convertArcToLines(cut).map(lineCut => DrawingUtil.segmentsLength(lineCut.points))
                    .reduce((a, b) => a + b, 0);
        }
        return cutLength;
    }
}
