import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {PromotionInformation} from './promotion-information';
import {PromotionInformationService} from './promotion-information.service';

@Component({
    selector: 'app-promotion-information',
    templateUrl: './promotion-information.component.html',
    providers: [PromotionInformationService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionInformationComponent extends CrudCommonComponent<PromotionInformation, PromotionInformationService> {

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState: boolean;

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => true
        }
    };

    @ViewChild('table')
    table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, PromotionInformationService, 'PROMOTION_INFORMATION', 'PromotionInformation');
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
    }

    getNewItem(): PromotionInformation {
        return new PromotionInformation();
    }

    loadEditedItem(event: { data: PromotionInformation }): Observable<PromotionInformation> {
        return this.itemService.getItem(event.data.id);
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }
}
