<div class="new-form-60vw">

    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaWindowsPcvInfo"
                                   [validationErrorsPrefix]="'motlawaWindowsPcvInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.WINDOWS_PCV_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaWindowsAluInfo"
                                   [validationErrorsPrefix]="'motlawaWindowsAluInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.WINDOWS_ALU_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaWindowsWoodInfo"
                                   [validationErrorsPrefix]="'motlawaWindowsWoodInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.WINDOWS_WOOD_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaWindowsMixInfo"
                                   [validationErrorsPrefix]="'motlawaWindowsMixInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.WINDOWS_MIX_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaRoofWindowsInfo"
                                   [validationErrorsPrefix]="'motlawaRoofWindowsInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.ROOF_WINDOWS_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaEntranceDoorsInfo"
                                   [validationErrorsPrefix]="'motlawaEntranceDoorsInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.ENTRANCE_DOORS_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaAddonsInfo"
                                   [validationErrorsPrefix]="'motlawaAddonsInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.ADDONS_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaGatesInfo"
                                   [validationErrorsPrefix]="'motlawaGatesInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.GATES_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
    <app-motlawa-integration-input [value]="motlawaIntegrationInfo.motlawaOfferChargesInfo"
                                   [validationErrorsPrefix]="'motlawaOfferChargesInfo'"
                                   [header]="'MOTLAWA_INTEGRATION_FORM.OFFER_CHARGES_HEADER' | translate"
                                   [validationErrors]="validationErrors"></app-motlawa-integration-input>
</div>
