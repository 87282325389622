import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopGlazingWithPrice} from '../../webshop-pricing-interfaces';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration-glazing-form',
    templateUrl: './webshop-recommended-configuration-glazing-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationGlazingFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    glazingGlassQuantityOptions: SelectItem[];
    glazingPackageOptions: SelectItem[];
    readonly glazingPackageOptionsPerGlassCount: { standardFrames: SelectItem[], warmFrames: SelectItem[] }[];
    glazings: WebshopGlazingWithPrice[][];

    constructor(private service: WebshopRecommendedConfigurationService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
        this.glazingPackageOptionsPerGlassCount = [
            {standardFrames: [], warmFrames: []},
            {standardFrames: [], warmFrames: []},
            {standardFrames: [], warmFrames: []},
            {standardFrames: [], warmFrames: []},
            {standardFrames: [], warmFrames: []}
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            this.service.getGlazings(Object.assign({}, this.item.productConfiguration, {
                glazingPackageId: undefined,
                glazingGlassQuantity: undefined,
                useWarmDistanceFrames: undefined
            })).subscribe(glazings => {
                const glazingsByGlassCount: WebshopGlazingWithPrice[][] = [[], [], [], [], []];
                for (let glazing of glazings) {
                    glazingsByGlassCount[glazing.glazingGlassQuantity].push(glazing);
                }
                for (let glassCount in glazingsByGlassCount) {
                    this.glazingPackageOptionsPerGlassCount[glassCount] = {
                        standardFrames: glazingsByGlassCount[glassCount].filter(glazing => !glazing.useWarmDistanceFrames)
                            .sort((a, b) => a.sortIndex - b.sortIndex)
                            .map(glazing => ({value: glazing.id, label: glazing.name[this.translate.currentLang]})),
                        warmFrames: glazingsByGlassCount[glassCount].filter(glazing => glazing.useWarmDistanceFrames)
                            .sort((a, b) => a.sortIndex - b.sortIndex)
                            .map(glazing => ({value: glazing.id, label: glazing.name[this.translate.currentLang]}))
                    };
                }
                this.glazings = glazingsByGlassCount;
                this.glazingGlassQuantityOptions = glazingsByGlassCount
                    .filter(glazingsGroup => glazingsGroup.length > 0)
                    .map(glazingsGroup => ({
                            label: `${glazingsGroup[0].glazingGlassQuantity}`,
                            value: glazingsGroup[0].glazingGlassQuantity
                        })
                    );
                if (this.item.productConfiguration.glazingGlassQuantity != undefined) {
                    this.glazingPackageOptions = !!this.item.productConfiguration.useWarmDistanceFrames
                        ? this.glazingPackageOptionsPerGlassCount[this.item.productConfiguration.glazingGlassQuantity].warmFrames
                        : this.glazingPackageOptionsPerGlassCount[this.item.productConfiguration.glazingGlassQuantity].standardFrames;
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    handleSelectedGlazingGlassQuantityChange(glazingGlassQuantity: number): void {
        this.item.productConfiguration.glazingGlassQuantity = glazingGlassQuantity;
        this.glazingPackageOptions = !!this.item.productConfiguration.useWarmDistanceFrames
            ? this.glazingPackageOptionsPerGlassCount[glazingGlassQuantity].warmFrames
            : this.glazingPackageOptionsPerGlassCount[glazingGlassQuantity].standardFrames;
    }

    handleUseWarmDistanceFramesChange(useWarmDistanceFrames: boolean): void {
        this.item.productConfiguration.useWarmDistanceFrames = useWarmDistanceFrames;
        this.glazingPackageOptions = !!this.item.productConfiguration.useWarmDistanceFrames
            ? this.glazingPackageOptionsPerGlassCount[this.item.productConfiguration.glazingGlassQuantity].warmFrames
            : this.glazingPackageOptionsPerGlassCount[this.item.productConfiguration.glazingGlassQuantity].standardFrames;
    }
}
