<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>
        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <span class="new-form-field-error-icon material-icons">error</span>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<ng-template #defaultIcon>
    <a href="#" class="textarea-icon material-icons" (click)="iconClicked($event)">{{icon}}</a>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container" [ngClass]="{'icon-padding':icon}">
        <textarea [attr.id]="inputId" [disabled]="disabled" [attr.placeholder]="placeholder"
                  [attr.maxlength]="maxlength" [(ngModel)]="value" [ngModelOptions]="modelOptions"
                  (focus)="handleFocus($event)" (blur)="handleBlur($event)"></textarea>
        <ng-container *ngIf="icon">
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="validationMessageKey != undefined">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
