import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule, NgZone} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HotkeyModule, HotkeyOptions, HotkeysService, IHotkeyOptions} from "angular2-hotkeys";
import {MomentModule} from "ngx-moment";
import {AccordionModule} from 'primeng/accordion';
import {BlockUIModule} from 'primeng/blockui';
import {CalendarModule} from 'primeng/calendar';
import {ChartModule} from 'primeng/chart';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DataTableModule} from 'primeng/datatable';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {EditorModule} from 'primeng/editor';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PickListModule} from 'primeng/picklist';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {AppComponent} from "./app.component";
import {routes} from "./app.routes";
import {ApplicationInfoComponent} from "./application-info/application-info.component";
import {ApplicationInfoService} from './application-info/application-info.service';
import {AuthGuard} from "./auth/auth.guard";
import {CurrentUserService} from "./auth/current-user.service";
import {JwtHelperService} from './auth/jwt-helper.service';
import {JwtInterceptor} from './auth/jwt.interceptor';
import {LogoutHelperService} from './auth/logout-helper.service';
import {OfferViewRestrictionGuard} from "./auth/offer-view-restriction.guard";
import {Permissions} from "./auth/permission.service";
import {PermissionsGuard} from "./auth/permissions.guard";
import {StorageService} from "./auth/storage.service";
import {UserUiConfigService} from "./auth/uiconfig/userUiConfig.service";
import {BlockUiController, blockUiControllerFactory} from './block-ui/block-ui-controller';
import {BlockUiComponent} from './block-ui/block-ui.component';
import {ButtonPopupMenuComponent} from "./common/button-with-menu/button-popup-menu.component";
import {ButtonWithMenuComponent} from './common/button-with-menu/button-with-menu.component';
import {ColorsSelectionComponent} from './common/colors-selection/colors-selection.component';
import {CommonErrorHandler} from './common/CommonErrorHandler';
import {RequiresExitConfirmationGuard} from "./common/component-exit-confirmation/requires-exit-confirmation-guard.service";
import {ConfirmActionDialogComponent} from './common/confirm-action/confirm-action-dialog.component';
import {ContainerWithPreviewComponent} from './common/container-with-preview/container-with-preview.component';
import {CountdownComponent} from './common/countdown/countdown.component';
import {DataServiceHelper} from "./common/dataServiceHelper";
import {DateRangeFilterComponent} from './common/date-range-filter/date-range-filter.component';
import {FormDateRangeFilterComponent} from './common/date-range-filter/form-date-range-filter.component';
import {TableDateRangeFilterComponent} from './common/date-range-filter/table-date-range-filter.component';
import {GlobalErrorHandler} from "./common/error.handler";
import {FormatGlazingWidthsPipe} from './common/glass-selection/format-glazing-widths.pipe';
import {GlassSelectionFieldLabelPipe} from './common/glass-selection/glass-selection-field-label.pipe';
import {GlassSelectionComponent} from "./common/glass-selection/glass-selection.component";
import {RangePipe} from './common/glass-selection/range.pipe';
import {
    GlazingPackagesForAreaRangeFormComponent
} from './common/glazing-packages-for-area-range/glazing-packages-for-area-range-form.component';
import {GlazingPackagesFormComponent} from './common/glazing-packages/glazing-packages-form.component';
import {GrowlMessageController} from "./common/growl-message/growl-message-controller";
import {GrowlMessageComponent} from "./common/growl-message/growl-message.component";
import {HotkeysHelpComponent} from './common/hotkeys-help/hotkeys-help.component';
import {SkipInterceptorsInterceptor} from './common/http/skip-interceptors.interceptor';
import {UrlInterceptor} from './common/http/url.interceptor';
import {InfoDialogComponent} from "./common/info-dialog/info-dialog.component";
import {InputLengthComponent} from "./common/input-length/input-length.component";
import {InputZipComponent} from './common/input-zip/input-zip.component';
import {MaintenancePageComponent} from "./common/maintenance-page/maintenance-page.component";
import {
    MissingProfitMarginHandlerComponent
} from './common/missing-profit-margin-handler/missing-profit-margin-handler.component';
import {NavigationBarComponent} from './common/navigation-bar/navigation-bar.component';
import {NewsDialogComponent} from './common/news-dialog/news-dialog.component';
import {RedrawWindowsPageComponent} from './common/redraw-windows-page/redraw-windows-page.component';
import {SavedFilterComponent} from './common/saved-filter/saved-filter.component';
import {ConvertMillimetersToInchesPipe} from './common/service/convert-millimeters-to-inches.pipe';
import {CustomDecimalPipe} from "./common/service/custom-decimal-format.pipe";
import {CustomHotkeysService} from './common/service/CustomHotkeysService';
import {DisplayUnitService} from "./common/service/display-unit.service";
import {MomentCurrentLangPipe} from "./common/service/moment-current-lang.pipe";
import {MultilanguageTranslatePipe} from "./common/service/multilanguage.translate.pipe";
import {PricePipe} from './common/service/price.pipe';
import {SafeValuePipe} from './common/service/safe-value.pipe';
import {SelectItemMultilanguageFieldTranslatePipe} from "./common/service/select-item-multilanguage-field-translate.pipe";
import {SelectItemTranslatePipe} from "./common/service/select.item.translate.pipe";
import {TableKeyboardNavigationDirective} from './common/table-keyboard-navigation/table-keyboard-navigation.directive';
import {TermsOfUseDialogComponent} from './common/terms-of-use-dialog/terms-of-use-dialog.component';
import {ToolbarButtonComponent} from './common/toolbar-button/toolbar-button.component';
import {UserConsentsDialogComponent} from './common/user-consents-dialog/user-consents-dialog.component';
import {WizardSidenavAdapter} from './common/wizard/wizard-sidenav-adapter';
import {ApplicationResourceComponent} from './features/admin-panel/application-resource/application-resource.component';
import {
    ConfigurableAddonUpsellingSettingsComponent
} from './features/admin-panel/configurable-addon-upselling-settings/configurable-addon-upselling-settings.component';
import {
    UpsellingSettingsFormComponent
} from './features/admin-panel/configurable-addon-upselling-settings/upselling-settings-form/upselling-settings-form.component';
import {MarkProductsComponent} from './features/admin-panel/mark-products/mark-products.component';
import {RoleFormComponent} from './features/admin-panel/role/form/role-form.component';
import {
    UpsellingPropositionChargesComponent
} from './features/admin-panel/upselling-proposition/upselling-proposition-charges/upselling-proposition-charges.component';
import {
    UpsellingPropositionConfigAddonsComponent
} from './features/admin-panel/upselling-proposition/upselling-proposition-config-addons/upselling-proposition-config-addons.component';
import {
    UpsellingPropositionFormComponent
} from './features/admin-panel/upselling-proposition/upselling-proposition-form/upselling-proposition-form.component';
import {
    UpsellingPropositionComponent
} from './features/admin-panel/upselling-proposition/upselling-proposition.component';
import {UserActivityReportMailSettingsComponent} from './features/admin-panel/user-activity/user-activity-report-mail-settings/user-activity-report-mail-settings.component';
import {UserActivityReportWizardComponent} from './features/admin-panel/user-activity/user-activity-report-wizard/user-activity-report-wizard.component';
import {UserActivityComponent} from './features/admin-panel/user-activity/user-activity-times/user-activity.component';
import {
    UserLoginTimesComponent
} from './features/admin-panel/user-activity/user-login-times/user-login-times.component';
import {UserConsentsComponent} from './features/admin-panel/user-consents/user-consents.component';
import {CatalogCreatorComponent} from './features/catalog-creator/catalog-creator.component';
import {
    SimpleProfitMarginSourceComponent
} from './features/client/simple-profit-margin-source/simple-profit-margin-source.component';
import {
    DashboardReportSettingsFormComponent
} from './features/home/dashboard-report/dashboard-report-settings-form/dashboard-report-settings-form.component';
import {DashboardReportComponent} from './features/home/dashboard-report/dashboard-report.component';
import {LoginFormComponent} from './features/login/login-form/login-form.component';
import {LoginComponent} from "./features/login/login.component";
import {ResetPasswordFormComponent} from './features/login/reset-password-form/reset-password-form.component';
import {SendResetPasswordMailFormComponent} from './features/login/send-reset-password-mail-form/send-reset-password-mail-form.component';
import {PaletteAdditionalElementsTableComponent} from './features/offer/delivery-list/palette-additional-elements-table.component/palette-additional-elements-table.component';
import {GateEditorComponent} from './features/offer/gate-editor/gate-editor.component';
import {GatePainterDirective} from './features/offer/gate-editor/gate-painter/gate-painter.directive';
import {GatePrintPainterDirective} from './features/offer/gate-editor/gate-painter/gate-print-painter.directive';
import {
    GatePanelTypeSelectionDialogComponent
} from './features/offer/gate-editor/gate-sidebar/gate-panel-type-selection-dialog/gate-panel-type-selection-dialog.component';
import {GateSidebarComponent} from './features/offer/gate-editor/gate-sidebar/gate-sidebar.component';
import {OfferHistoryComponent} from "./features/offer/offer-history/offer-history.component";
import {DetailedPricingComponent} from "./features/offer/offers/detailed-pricing/detailed-pricing.component";
import {DetailedPricingHelpDialogComponent} from "./features/offer/offers/detailed-pricing/help-dialog/detailed-pricing-help-dialog.component";
import {DetailedPricingPositionNamePipe} from "./features/offer/offers/detailed-pricing/pipes/detailed-pricing-position-name.pipe";
import {ModificationValuePipe} from "./features/offer/offers/detailed-pricing/pipes/modification-value.pipe";
import {ShowZeroPricesPipe} from "./features/offer/offers/detailed-pricing/pipes/show-zero-prices.pipe";
import {StickyColumnClassPipe} from "./features/offer/offers/detailed-pricing/pipes/sticky-column-class.pipe";
import {
    DetailedPricingSimulationParametersDialogComponent
} from "./features/offer/offers/detailed-pricing/simulation-parameters-dialog/detailed-pricing-simulation-parameters-dialog.component";
import {ExternalUserOfferEditCompletedComponent} from './features/offer/offers/position/external-user-offer-edit-completed/external-user-offer-edit-completed.component';
import {AddStandaloneGlazingPackageComponent} from './features/offer/offers/position/position-list/add-standalone-glazing-package/add-standalone-glazing-package.component';
import {AddStandaloneGlazingPackagesGloballyComponent} from './features/offer/offers/position/position-list/add-standalone-glazing-packages-globally/add-standalone-glazing-packages-globally.component';
import {
    AddTransportComponent
} from './features/offer/offers/position/position-list/add-transport/add-transport.component';
import {
    ComparePositionsComponent
} from './features/offer/offers/position/position-list/compare-positions-dialog/compare-positions-dialog.component';
import {
    EditTransportComponent
} from './features/offer/offers/position/position-list/edit-transport/edit-transport.component';
import {
    SetPrintOrderDialogComponent
} from './features/offer/offers/position/position-list/set-print-order-dialog/set-print-order-dialog.component';
import {
    UpsellingFloatButtonComponent
} from './features/offer/offers/position/position-list/windowUpselling/upselling-float-button/upselling-float-button.component';
import {
    UpsellingSidebarComponent
} from './features/offer/offers/position/position-list/windowUpselling/upselling-sidebar/upselling-sidebar.component';
import {
    PaymentPackageSubsystemSelectionFormComponent
} from './features/offer/payment-package/payment-package-subsystem-selection-form/payment-package-subsystem-selection-form.component';
import {OfferSearchResultsComponent} from './features/offer/search/offer-search-results/offer-search-results.component';
import {SearchComponent} from './features/offer/search/search.component';
import {
    AddEntranceWindowDialogComponent
} from './features/offer/add-product-dialog/add-product-dialog-data-provider/add-entrance-window-dialog.component';
import {DesignerLengthUnitHiderDirective} from "./features/offer/window-editor/common/designer-length-unit-hider.directive";
import {
    TerraceHandleLayoutSelectionDialogComponent
} from './features/offer/window-editor/sidebar/general-tab/terrace-handle-location-selection-dialog/terrace-handle-layout-selection-dialog.component';
import {RoleComponent} from './features/admin-panel/role/role.component';
import {WindowPricingtablesComponent} from './features/price-lists/price-table/window-pricingtable/window-pricingtables.component';
import {
    DocumentTemplateFormComponent
} from './features/settings/document-templates/document-template-form/document-template-form.component';
import {DocumentTemplateComponent} from './features/settings/document-templates/document-template.component';
import {GateSystemDefaultsComponent} from './features/settings/gate-system-defaults/gate-system-defaults.component';
import {NewsEditorComponent} from './features/settings/news/editor/news-editor.component';
import {NewsComponent} from './features/settings/news/news.component';
import {WindowPricingtableComponent} from './features/price-lists/price-table/window-pricingtable/window-pricingtable.component';
import {
    OfferStatusChangeMailSettingsComponent
} from './features/settings/offer-status-change-mail-settings/offer-status-change-mail-settings.component';
import {PrintoutDataComponent} from './features/settings/printout-data/printout-data.component';
import {
    PromotionCodeFormComponent
} from './features/settings/promotion-code/promotion-code-form/promotion-code-form.component';
import {PromotionCodeComponent} from './features/settings/promotion-code/promotion-code.component';
import {
    PromotionInformationFormComponent
} from './features/settings/promotion-information/promotion-information-form/promotion-information-form.component';
import {PromotionInformationComponent} from './features/settings/promotion-information/promotion-information.component';
import {SubsystemPartnerWebshopInfoComponent} from './features/settings/subsystem-partner-webshop-info/subsystem-partner-webshop-info.component';
import {SubsystemSalesTargetSettingsComponent} from "./features/settings/subsystem-sales-target-settings/subsystem-sales-target-settings.component";
import {SubsystemWebshopMailConfigurationComponent} from './features/settings/subsystem-webshop-mail-configuration/subsystem-webshop-mail-configuration.component';
import {VenskaPasswordResetMailSettingsComponent} from './features/settings/venska-password-reset-mail-settings/venska-password-reset-mail-settings.component';
import {WebshopHelpComponent} from './features/settings/webshop-help/webshop-help.component';
import {
    WebshopUiTranslationsComponent
} from './features/settings/webshop-ui-translations/webshop-ui-translations.component';
import {
    SubsystemGroupAddonProfitMarginComponent
} from './features/subsystem-group/subsystem-group-addon-profit-margin/subsystem-group-addon-profit-margin.component';
import {
    SubsystemAddressListFormComponent
} from './features/subsystem/subsystem-address-list-form/subsystem-address-list-form.component';
import {
    SubsystemAddressListComponent
} from './features/subsystem/subsystem-address-list/subsystem-address-list.component';
import {
    SubsystemAddressSelectionDialogComponent
} from './features/subsystem/subsystem-address-selection-dialog/subsystem-address-selection-dialog.component';
import {SubsystemUserActivityReportComponent} from './features/subsystem/subsystem-user-activity-report/subsystem-user-activity-report.component';
import {SubstituteUserDialogComponent} from './features/user/substitute-user-dialog/substitute-user-dialog.component';
import {UserListComponent} from './features/user/user-list.component';
import {UserComponent} from "./features/user/user.component";
import {SubsystemComponent} from "./features/subsystem/subsystem.component";
import {CatalogOptionComponent} from './features/catalog-creator/catalog-option/catalog-option.component';
import {CatalogOptionFormComponent} from './features/catalog-creator/catalog-option/form/catalog-option-form.component';
import {CatalogPropertyComponent} from './features/catalog-creator/catalog-property/catalog-property.component';
import {
    CatalogPropertyFormComponent
} from './features/catalog-creator/catalog-property/form/catalog-property-form.component';
import {
    DiscontinuedCatalogItemInfoComponent
} from './features/window-system/discontinued-catalog-item-info/discontinued-catalog-item-info.component';
import {
    DiscontinuedCatalogItemInfoFormComponent
} from './features/window-system/discontinued-catalog-item-info/form/discontinued-catalog-item-info-form.component';
import {
    ConfigAddonGlamourPrintInfoFormComponent
} from './features/window-system/config-addons/config-addon-glamour-print-info-form/config-addon-glamour-print-info-form.component';
import {
    EntranceGlazingPackageComponent
} from './features/window-system/entrance-glazing-package/entrance-glazing-package.component';
import {
    EntranceGlazingPackageFormComponent
} from './features/window-system/entrance-glazing-package/general-data-form/entrance-glazing-package-form.component';
import {
    GateDesignerCatalogDependentOptionFormDependentComponent
} from './features/window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option-form-dependent.component';
import {
    GateDesignerCatalogDependentOptionFormRequiredComponent
} from './features/window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option-form-required.component';
import {
    GateDesignerCatalogDependentOptionFormComponent
} from './features/window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option-form.component';
import {
    GateDesignerCatalogDependentOptionsSetComponent
} from './features/window-system/gate-designer-catalog-dependent-option/gate-designer-catalog-dependent-options-set.component';
import {GatePanelTypeFormComponent} from './features/window-system/gate-panel-type/form/gate-panel-type-form.component';
import {GatePanelTypeComponent} from './features/window-system/gate-panel-type/gate-panel-type.component';
import {
    GlazingPackageGeneralDataFormComponent
} from './features/window-system/glazing-package/glazing-package-general-data-form/glazing-package-general-data-form.component';
import {GlazingPackageComponent} from './features/window-system/glazing-package/glazing-package.component';
import {
    WebshopGlazingPackageGlazingFormComponent
} from './features/window-system/glazing-package/webshop-glazing-package/webshop-glazing-package-glazing-form/webshop-glazing-package-glazing-form.component';
import {GraspDistanceFrameCategoryFormComponent} from './features/window-system/grasp-distance-frame-category/grasp-distance-frame-category-form.component';
import {GraspDistanceFrameCategoryComponent} from './features/window-system/grasp-distance-frame-category/grasp-distance-frame-category.component';
import {TransportFormComponent} from './features/window-system/transport/transport-form/transport-form.component';
import {TransportComponent} from './features/window-system/transport/transport.component';
import {
    UpsellingPropositionGlazingsComponent
} from './features/admin-panel/upselling-proposition/upselling-proposition-glazings/upselling-proposition-glazings.component';
import {
    WebshopBusinessTypeComponent
} from './features/window-system/webshop-business-type/webshop-business-type.component';
import {
    WebshopGlazingPackagePerSubsystemComponent
} from './features/window-system/webshop-glazing-package-per-subsystem/webshop-glazing-package-per-subsystem.component';
import {
    WebshopGlazingPackageComponent
} from './features/window-system/glazing-package/webshop-glazing-package/webshop-glazing-package.component';
import {
    RoofGlazingPackageFormComponent
} from './features/window-system/roof-glazing-package/roof-glazing-package-form/roof-glazing-package-form.component';
import {
    RoofGlazingPackageComponent
} from './features/window-system/roof-glazing-package/roof-glazing-package.component';
import {RackFormComponent} from './features/window-system/rack/form/rack-form.component';
import {
    WindowDimensionsFormComponent
} from './features/window-system/window-dimensions/window-dimensions-form/window-dimensions-form.component';
import {WindowDimensionsComponent} from './features/window-system/window-dimensions/window-dimensions.component';
import {
    WebshopRecommendedConfigurationAreaBarsFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-area-bars-form/webshop-recommended-configuration-area-bars-form.component';
import {
    WebshopRecommendedConfigurationBusinessTypeFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-business-type-form/webshop-recommended-configuration-business-type-form.component';
import {
    WebshopRecommendedConfigurationColorFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-color-form/webshop-recommended-configuration-color-form.component';
import {
    WebshopRecommendedConfigurationGeneralFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-general-form/webshop-recommended-configuration-general-form.component';
import {
    WebshopRecommendedConfigurationGlazingFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-glazing-form/webshop-recommended-configuration-glazing-form.component';
import {
    WebshopRecommendedConfigurationModelFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-model-form/webshop-recommended-configuration-model-form.component';
import {
    WebshopRecommendedConfigurationSizeFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-size-form/webshop-recommended-configuration-size-form.component';
import {
    WebshopRecommendedConfigurationWebshopChargesFormComponent
} from './features/window-system/webshop-recommended-configuration/steps/webshop-recommended-configuration-webshop-charges-form/webshop-recommended-configuration-webshop-charges-form.component';
import {
    WebshopRecommendedConfigurationComponent
} from './features/window-system/webshop-recommended-configuration/webshop-recommended-configuration.component';
import {WebshopSaleComponent} from './features/window-system/webshop-sale/webshop-sale.component';
import {
    WindowDesignerCatalogDependentOptionFormDependentComponent
} from './features/window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option-form-dependent.component';
import {
    WindowDesignerCatalogDependentOptionFormRequiredComponent
} from './features/window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option-form-required.component';
import {
    WindowDesignerCatalogDependentOptionFormComponent
} from './features/window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option-form.component';
import {
    WindowDesignerCatalogDependentOptionsSetComponent
} from './features/window-system/window-designer-catalog-dependent-option/window-designer-catalog-dependent-options-set.component';
import {
    WindowSystemDefinitionDimensionPriceFormComponent
} from './features/window-system/window-system-definition/window-system-definition-dimension-price-form/window-system-definition-dimension-price-form.component';
import {
    WindowSystemDefinitionGlamourPrintInfoFormComponent
} from './features/window-system/window-system-definition/window-system-definition-glamour-print-info-form/window-system-definition-glamour-print-info-form.component';
import {
    WindowSystemDefinitionWebShopFormComponent
} from './features/window-system/window-system-definition/window-system-definition-web-shop-form/window-system-definition-web-shop-form.component';
import {
    WindowSystemDefinitionComponent
} from "./features/window-system/window-system-definition/window-system-definition.component";
import {WindowSystemModelComponent} from './features/window-system/window-system-model/window-system-model.component';
import {WindowSystemComponent} from "./features/window-system/window-system.component";
import {UserSettingsComponent} from "./features/settings/user-settings/user-settings.component";
import {SettingsComponent} from "./features/settings/settings.component";
import {DistanceFrameComponent} from "./features/window-system/distance-frame/distance-frame.component";
import {SealComponent} from "./features/window-system/seal/seal.component";
import {ListUserGroupsComponent} from "./features/user-group/list-user-groups/list-user-groups.component";
import {ClientComponent} from "./features/client/client.component";
import {ClientGroupComponent} from "./features/client-group/client-group.component";
import {SupplierComponent} from "./features/supplier/supplier.component";
import {PageNotFoundComponent} from "./features/errors/page-not-found/page-not-found.component";
import {TranslationsComponent} from "./features/translations/translations.component";
import {GlassComponent} from "./features/window-system/glass/glass.component";
import {GrillComponent} from "./features/window-system/grill/grill.component";
import {ColorComponent} from "./features/window-system/color/color.component";
import {WindowDesignerComponent} from "./features/offer/window-editor/window-designer/window-designer.component";
import {SubsystemSettingsComponent} from "./features/settings/subsystem-settings/subsystem-settings.component";
import {PriceListsComponent} from "./features/price-lists/price-lists.component";
import {BusinessTypeComponent} from "./features/window-system/business-type/business-type.component";
import {PriceTableComponent} from "./features/price-lists/price-table/price-table.component";
import {
    PriceListsDefinitionComponent
} from "./features/price-lists/price-lists-definition/price-lists-definition.component";
import {GeneralErrorComponent} from "./features/errors/general-error/general-error.component";
import {DecorativeFillingComponent} from "./features/window-system/decorative-filling/decorative-filling.component";
import {OtherFillingComponent} from "./features/window-system/other-filling/other-filling.component";
import {PricesheetsComponent} from "./features/price-lists/pricesheets/pricesheets.component";
import {OffersComponent} from "./features/offer/offers/offers.component";
import {MainOffersComponent} from "./features/offer/main-offers.component";
import {ProfitMarginComponent} from "./features/subsystem/profit-margin/profit-margin.component";
import {NoPrivilegesErrorComponent} from "./features/errors/no-privileges-error/no-privileges-error.component";
import {AddonsComponent} from "./features/window-system/addons/addons.component";
import {OrdersComponent} from "./features/offer/orders/orders.component";
import {PositionComponent} from "./features/offer/offers/position/position.component";
import {PositionListComponent} from "./features/offer/offers/position/position-list/position-list.component";
import {
    AddBulkAddonPositionComponent
} from "./features/offer/offers/position/position-list/add-bulk-addon-position/add-bulk-addon-position.component";
import {IsLoggedService, isLoggedServiceSingletonFactory} from "./features/login/islogged.service";
import {
    IsAnotherUserLoggedService,
    isAnotherUserLoggedServiceSingletonFactory
} from "./features/login/isAnotherUserLogged";
import {PictureComponent} from "./features/offer/offers/position/picture/picture.component";
import {WindowEditorComponent} from "./features/offer/window-editor/window-editor.component";
import {PricingComponent} from "./features/offer/window-editor/sidebar/pricing/pricing.component";
import {ProfileComponent} from "./features/window-system/profile/profile.component";
import {PricingMessageComponent} from "./features/errors/pricing-message/pricing-message.component";
import {ProductionOrdersComponent} from "./features/offer/production-orders/production-orders.component";
import {MaterialComponent} from "./features/window-system/material/material.component";
import {ValidationComponent} from "./features/offer/window-editor/sidebar/validation/validation.component";
import {SubwindowComponent} from "./features/offer/window-editor/sidebar/subwindow/subwindow.component";
import {GeneralTabComponent} from "./features/offer/window-editor/sidebar/general-tab/general-tab.component";
import {
    ShowDescriptionComponent
} from "./features/offer/offers/position/position-list/show-description/show-description.component";
import {
    WindowPropertiesComponent
} from "./features/offer/offers/position/window-properties/window-properties.component";
import {
    BulkWindowSystemChangeComponent
} from "./features/offer/offers/position/position-list/bulk-window-system-change/bulk-window-system-change.component";
import {
    BulkGlassChangeComponent
} from "./features/offer/offers/position/position-list/bulk-glass-change/bulk-glass-change.component";
import {
    BulkChangeConfirmationComponent
} from "./features/offer/offers/position/position-list/bulk-change-confirmation/bulk-change-confirmation.component";
import {
    BulkColorChangeComponent
} from "./features/offer/offers/position/position-list/bulk-color-change/bulk-color-change.component";
import {VeneerComponent} from "./features/offer/veneer/veneer.component";
import {
    BulkGeneralChangeComponent
} from "./features/offer/offers/position/position-list/bulk-general-change/bulk-general-change.component";
import {
    ConfigurableAddonComponent
} from "./features/offer/offers/position/position-list/configurable-addon/configurableAddon.component";
import {
    ConfigurableAddonFieldComponent
} from "./features/offer/offers/position/position-list/configurable-addon/configurable-addon-field/configurableAddon-field.component";
import {
    SubsystemAddonProfitMarginComponent
} from "./features/subsystem/subsystem-addon-profit-margin/subsystem-addon-profit-margin.component";
import {
    PositionListTableComponent
} from "./features/offer/offers/position/position-list/position-list-table/position-list-table.component";
import {RabateComponent} from "./features/offer/offers/position/position-list/rabate/rabate.component";
import {
    AddonsTabViewComponent
} from "./features/offer/window-editor/sidebar/general-tab/addons-tab-view/addons-tab-view.component";
import {
    AddonPositionComponent
} from "./features/offer/offers/position/position-list/add-bulk-addon-position/addon-position/addon-position.component";
import {
    DealerDiscountPerSystemComponent
} from "./features/price-lists/dealer-discount/per-system/dealer-discount-per-system.component";
import {
    DealerDiscountPerSupplierComponent
} from "./features/price-lists/dealer-discount/per-supplier/dealer-discount-per-supplier.component";
import {
    DealerDiscountPerConfigurableAddonComponent
} from "./features/price-lists/dealer-discount/per-configurable-addon/dealer-discount-per-configurable-addon.component";
import {ConfigAddonsComponent} from "./features/window-system/config-addons/config-addons.component";
import {GlazingBeadComponent} from "./features/window-system/glazing-bead/glazing-bead.component";
import {
    UpdateExchangeRateComponent
} from "./features/offer/offers/position/position-list/update-exchange-rate/update-exchange-rate.component";
import {ApplicationFeaturesResolver, UserUiConfigResolver} from "./features/features.resolver";
import {VenskaSettingsComponent} from "./features/settings/venska-settings/venska-settings.component";
import {
    ChangeDealerDiscountComponent
} from "./features/offer/production-orders/change-dealer-discount/change-dealer-discount.component";
import {
    ChangeAnnotationsComponent
} from "./features/offer/production-orders/change-annotations/change-annotations.component";
import {AdminPanelComponent} from "./features/admin-panel/admin-panel.component";
import {ErrorBrowserComponent} from "./features/admin-panel/error-browser/error-browser.component";
import {
    UpdatePricingsComponent
} from "./features/offer/offers/position/position-list/update-pricings/update-pricings.component";
import {AssemblyComponent} from "./features/window-system/assembly/assembly.component";
import {AddAssemblyComponent} from "./features/offer/offers/position/position-list/add-assembly/add-assembly.component";
import {
    EditAssemblyComponent
} from "./features/offer/offers/position/position-list/edit-assembly/edit-assembly.component";
import {UpdateVatComponent} from "./features/offer/offers/position/position-list/update-vat/update-vat.component";
import {
    CatalogChargeProfitMarginComponent
} from "./features/subsystem/catalog-charge-profit-margin/catalog-charge-profit-margin.component";
import {PricesheetTabComponent} from "./features/price-lists/pricesheets/pricesheet-tab/pricesheet-tab.component";
import {
    ConfigurableAddonDialogComponent
} from "./features/offer/offers/position/position-list/configurable-addon/configurable-addon-dialog.component";
import {ConfigAddonsListComponent} from './features/offer/config-addons-list/config-addons-list.component';
import {
    EditBulkAddonPositionComponent
} from "./features/offer/offers/position/position-list/add-bulk-addon-position/edit-dialog/edit-bulk-addon-position.component";
import {CreateOfferComponent} from './features/offer/offers/create-offer/create-offer.component';
import {
    ConfigAddonPricingComponent
} from './features/offer/window-editor/sidebar/pricing/config-addon-pricing/config-addon-pricing.component';
import {
    CommentDialogComponent
} from "./features/offer/offers/position/position-list/comment-dialog/comment-dialog.component";
import {UserGroupFormComponent} from './features/user-group/user-group-form/user-group-form.component';
import {AddonFormComponent} from './features/window-system/addons/addon-form/addon-form.component';
import {LinkSelectionComponent} from './features/window-system/link-selection/link-selection.component';
import {AssemblyFormComponent} from './features/window-system/assembly/assembly-form/assembly-form.component';
import {CreateClientComponent} from './features/client/create-client/create-client.component';
import {ClientDetailsFormComponent} from './features/client/client-details-form/client-details-form.component';
import {EditClientComponent} from './features/client/edit-client/edit-client.component';
import {CreateClientGroupComponent} from './features/client-group/create-client-group/create-client-group.component';
import {EditClientGroupComponent} from './features/client-group/edit-client-group/edit-client-group.component';
import {CreateSupplierComponent} from "./features/supplier/create-supplier/create-supplier.component";
import {EditSupplierDialogComponent} from "./features/supplier/edit-supplier/edit-supplier-dialog.component";
import {SupplierDetailsFormComponent} from "./features/supplier/form/supplier-details-form.component";
import {SupplierAddressFormComponent} from "./features/supplier/form/supplier-address-form.component";
import {SupplierAnnotationsFormComponent} from "./features/supplier/form/supplier-annotations-form.component";
import {
    SubstituteSupplierDialogComponent
} from "./features/supplier/substitute-supplier-dialog/substitute-supplier-dialog.component";
import {PriceChangeComponent} from './features/offer/offers/position/position-list/price-change/price-change.component';
import {CreateSubsystemComponent} from './features/subsystem/create-subsystem/create-subsystem.component';
import {
    SubsystemDetailsFormComponent
} from './features/subsystem/subsystem-details-form/subsystem-details-form.component';
import {
    SubsystemAddressFormComponent
} from './features/subsystem/subsystem-address-form/subsystem-address-form.component';
import {EditSubsystemComponent} from './features/subsystem/edit-subsystem/edit-subsystem.component';
import {ScheduledJobComponent} from "./features/admin-panel/scheduled-job/scheduled-job.component";
import {
    PricelistFormComponent
} from './features/price-lists/price-lists-definition/pricelist-form/pricelist-form.component';
import {PricesheetFormComponent} from './features/price-lists/pricesheets/pricesheet-form/pricesheet-form.component';
import {
    DealerDiscountFormComponent
} from './features/price-lists/dealer-discount/dealer-discount-form/dealer-discount-form.component';
import {CreateUserComponent} from './features/user/create-user/create-user.component';
import {UserDetailsFormComponent} from './features/user/user-details-form/user-details-form.component';
import {EditUserComponent} from './features/user/edit-user/edit-user.component';
import {
    DrawingToolControlsComponent
} from "./features/offer/window-editor/drawing-tool-controls/drawing-tool-controls.component";
import {
    DistanceFrameFormComponent
} from './features/window-system/distance-frame/distance-frame-form/distance-frame-form.component';
import {SealFormComponent} from './features/window-system/seal/seal-form/seal-form.component';
import {
    WindowSystemDefinitionGeneralFormComponent
} from './features/window-system/window-system-definition/window-system-definition-general-form/window-system-definition-general-form.component';
import {
    WindowSystemDefinitionShapeFormComponent
} from './features/window-system/window-system-definition/window-system-definition-shape-form/window-system-definition-shape-form.component';
import {ColorFormComponent} from './features/window-system/color/color-form/color-form.component';
import {MaterialFormComponent} from './features/window-system/material/material-form/material-form.component';
import {
    GlazingBeadFormComponent
} from './features/window-system/glazing-bead/glazing-bead-form/glazing-bead-form.component';
import {AddonSystemsFormComponent} from "./features/window-system/addons/addon-form/addon-systems-form.component";
import {
    OtherFillingFormComponent
} from './features/window-system/other-filling/other-filling-form/other-filling-form.component';
import {GrillFormComponent} from './features/window-system/grill/grill-form/grill-form.component';
import {ProfileFormComponent} from './features/window-system/profile/profile-form/profile-form.component';
import {
    DecorativeFillingFormComponent
} from './features/window-system/decorative-filling/decorative-filling-form/decorative-filling-form.component';
import {
    DeletePositionDialogComponent
} from "./features/offer/offers/position/position-list/delete-position-dialog/delete-position-dialog.component";
import {
    SummaryDialogComponent
} from "./features/offer/offers/position/position-list/summary-dialog/summary-dialog.component";
import {GlassFormComponent} from "./features/window-system/glass/glass-form/glass-form.component";
import {
    ProductionOrderSummaryDialogComponent
} from './features/offer/production-orders/production-orders-position-list/production-order-summary-dialog/production-order-summary-dialog.component';
import {
    WindowSystemDefinitionAnglesFormComponent
} from './features/window-system/window-system-definition/window-system-definition-angles-form/window-system-definition-angles-form.component';
import {
    WindowSystemDefinitionImpositionFormComponent
} from './features/window-system/window-system-definition/window-system-definition-imposition-form/window-system-definition-imposition-form.component';
import {TemplatesSettingsComponent} from './features/settings/templates-settings/templates-settings.component';
import {TemplateFormComponent} from './features/settings/templates-settings/template-form/template-form.component';
import {PaymentPackageComponent} from './features/offer/payment-package/payment-package.component';
import {
    PaymentPackageFormComponent
} from './features/offer/payment-package/payment-package-form/payment-package-form.component';
import {
    GlobalConfigAddonsAddConfirmationComponent
} from "./features/offer/offers/position/position-list/bulk-change-confirmation/global-config-addons-add-confirmation.component";
import {ShippingPricingsComponent} from './features/price-lists/shipping-pricings/shipping-pricings.component';
import {
    ShippingPricingtableComponent
} from './features/price-lists/shipping-pricings/shipping-pricingtable/shipping-pricingtable.component';
import {BusinessTypeFormComponent} from "./features/window-system/business-type/form/business-type-form.component";
import {
    StatusTransitionDialogComponent
} from "./features/offer/status-transition-dialog/status-transition-dialog.component";
import {
    ShippingResultDrawerComponent
} from './features/offer/shipping/shipping-result-drawer/shipping-result-drawer.component';
import {
    ShippingItemDrawerComponent
} from './features/offer/shipping/shipping-item-drawer/shipping-item-drawer.component';
import {
    ShippingContainerDrawerComponent
} from './features/offer/shipping/shipping-container-drawer/shipping-container-drawer.component';
import {
    WindowComponentPreviewDialogComponent
} from './features/offer/window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {
    SidebarFieldLabelComponent
} from './features/offer/window-editor/sidebar/sidebar-field-label/sidebar-field-label.component';
import {TermsOfUseComponent} from "./features/settings/terms-of-use/terms-of-use.component";
import {
    GlobalChangeConfirmationComponent
} from './features/offer/offers/position/position-list/bulk-change-confirmation/global-change-confirmation/global-change-confirmation.component';
import {OfferNumberComponent} from "./features/offer/offers/offer-number.component/offer-number.component";
import {
    OfferSupplierCounterComponent
} from "./features/admin-panel/offer-supplier-counter/offer-supplier-counter.component";
import {
    OfferSubsystemCounterComponent
} from "./features/admin-panel/offer-subsystem-counter/offer-subsystem-counter.component";
import {
    ChangeSystemDealerDiscountComponent
} from './features/offer/production-orders/change-system-dealer-discount/change-system-dealer-discount.component';
import {ShippingSimulationService} from "./features/offer/shipping/shipping-simulation/shipping-simulation.service";
import {
    ShippingSimulationSidebarComponent
} from "./features/offer/shipping/shipping-simulation/shipping-simulation-sidebar/shipping-simulation-sidebar.component";
import {
    ShippingSimulationFloatButton
} from "./features/offer/shipping/shipping-simulation/shipping-simulation-float-button/shipping-simulation-float-button";
import {
    ShippingSimulationDetailsComponent
} from "./features/offer/shipping/shipping-simulation/shipping-simulation-details/shipping-simulation-details.component";
import {
    ShippingSimulationListComponent
} from "./features/offer/shipping/shipping-simulation/shipping-simulation-list/shipping-simulation-list.component";
import {SubwindowTypeComponent} from "./features/window-system/subwindow-type/subwindow-type.component";
import {SubwindowTypeFormComponent} from "./features/window-system/subwindow-type/form/subwindow-type-form.component";
import {ApplicationStatusComponent} from './features/admin-panel/application-status/application-status.component';
import {CreateComplaintComponent} from "./features/offer/complaint/create-complaint/create-complaint.component";
import {ComplaintListComponent} from "./features/offer/complaint/complaint-list/complaint-list.component";
import {ComplaintPositionComponent} from "./features/offer/complaint/position/complaint-position.component";
import {ComplaintPositionFormComponent} from "./features/offer/complaint/form/complaint-position-form.component";
import {
    DeleteComplaintPositionDialogComponent
} from "./features/offer/offers/position/position-list/delete-complaint-position-dialog/delete-complaint-position-dialog.component";
import {
    CreateComplaintConfirmationDialogComponent
} from './features/offer/offers/position/position-list/create-complaint-confirmation-dialog/create-complaint-confirmation-dialog.component';
import {TranslationFieldsComponent} from './features/translations/translation-fields/translation-fields.component';
import {
    RalColorSelectionDialogComponent
} from './features/offer/window-editor/sidebar/ral-color-selection-dialog/ral-color-selection-dialog.component';
import {
    OfferArchivedVersionsDialogComponent
} from "./features/offer/offers/position/position-list/offer-archived-versions-dialog/offer-archived-versions-dialog.component";
import {PrintDialogComponent} from './features/offer/print-dialog/print-dialog.component';
import {SubsystemGroupComponent} from './features/subsystem-group/subsystem-group.component';
import {
    SubsystemGroupFormComponent
} from './features/subsystem-group/subsystem-group-form/subsystem-group-form.component';
import {RackComponent} from './features/window-system/rack/rack.component';
import {
    CorrectionOrderGenerationDialogComponent
} from "./features/offer/offers/position/position-list/correction-order-generation-dialog/correction-order-generation-dialog.component";
import {
    ConfirmOverwritingWindowSystemDefaultsDialogComponent
} from './features/offer/window-editor/confirm-overwriting-window-system-defaults-dialog/confirm-overwriting-window-system-defaults-dialog.component';
import {
    WindowSystemWebshopComponent
} from './features/window-system/webshop/window-system-webshop/window-system-webshop.component';
import {
    SubsystemGroupWindowSystemSellDealerDiscountComponent
} from './features/subsystem-group/subsystem-group-window-system-sell-dealer-discount/subsystem-group-window-system-sell-dealer-discount.component';
import {
    SubsystemGroupConfigurableAddonSellDealerDiscountComponent
} from './features/subsystem-group/subsystem-group-configurable-addon-sell-dealer-discount/subsystem-group-configurable-addon-sell-dealer-discount.component';
import {
    SubsystemGroupSupplierSellDealerDiscountComponent
} from './features/subsystem-group/subsystem-group-supplier-sell-dealer-discount/subsystem-group-supplier-sell-dealer-discount.component';
import {
    SubsystemWindowSystemSellDealerDiscountComponent
} from './features/subsystem/subsystem-window-system-sell-dealer-discount/subsystem-window-system-sell-dealer-discount.component';
import {
    SubsystemConfigurableAddonSellDealerDiscountComponent
} from './features/subsystem/subsystem-configurable-addon-sell-dealer-discount/subsystem-configurable-addon-sell-dealer-discount.component';
import {
    SubsystemSupplierSellDealerDiscountComponent
} from './features/subsystem/subsystem-supplier-sell-dealer-discount/subsystem-supplier-sell-dealer-discount.component';
import {DeliveryListsComponent} from "./features/offer/delivery-list/delivery-lists.component";
import {
    DeliveryListFormComponent
} from "./features/offer/delivery-list/delivery-list-form/delivery-list-form.component";
import {
    DeliveryListSubsystemSelectionFormComponent
} from "./features/offer/delivery-list/delivery-list-subsystem-selection-form/delivery-list-subsystem-selection-form.component";
import {DeliveryListWizardComponent} from "./features/offer/delivery-list/delivery-list-wizard.component";
import {DeliveryListComponent} from "./features/offer/delivery-list/delivery-list.component";
import {PaletteTableComponent} from "./features/offer/delivery-list/palette-list-table/palette-table.component";
import {PaletteFormComponent} from "./features/offer/delivery-list/palette-wizard/palette-form/palette-form.component";
import {PaletteWizardComponent} from "./features/offer/delivery-list/palette-wizard/palette-wizard.component";
import {
    PaletteProductionOrdersComponent
} from "./features/offer/delivery-list/palette-wizard/palette-production-orders/palette-production-orders.component";
import {
    WindowSystemDefinitionDrawingToolsFormComponent
} from "./features/window-system/window-system-definition/window-system-definition-drawing-tools-form/window-system-definition-drawing-tools-form.component";
import {
    BulkFittingChangeComponent
} from './features/offer/offers/position/position-list/bulk-fitting-change/bulk-fitting-change.component';
import {
    SubsystemWebshopInfoComponent
} from './features/settings/subsystem-webshop-info/subsystem-webshop-info.component';
import {
    RoofWindowEditorComponent
} from "./features/offer/window-editor/roof-window-editor/roof-window-editor.component";
import {
    ExitWithoutSavingConfirmationDialogComponent
} from "./features/offer/window-editor/common/exit-without-saving-confirmation-dialog/exit-without-saving-confirmation-dialog.component";
import {SidebarHiderComponent} from "./features/offer/window-editor/sidebar/sidebar-hider.component";
import {
    AddRoofWindowDialogComponent
} from "./features/offer/add-product-dialog/add-product-dialog-data-provider/add-roof-window-dialog.component";
import {WebshopChargeComponent} from './features/window-system/webshop-charge/webshop-charge.component';
import {
    SubsystemInactiveSuppliersFormComponent
} from './features/subsystem/subsystem-inactive-suppliers-form/subsystem-inactive-suppliers-form.component';
import {
    CatalogConfigurationWizardStepComponent
} from "./features/catalog-creator/catalog-configuration-wizard-step/catalog-configuration-wizard-step.component";
import {
    CatalogConfigurationDisplayComponent
} from "./features/catalog-creator/catalog-configuration-display/catalog-configuration-display.component";
import {
    PaymentPackagePicklistComponent
} from './features/offer/payment-package/payment-package-picklist/payment-package-picklist.component';
import {CatalogItemTagsComponent} from './features/window-system/catalog-item-tags/catalog-item-tags.component';
import {
    CatalogItemTagsFormComponent
} from './features/window-system/catalog-item-tags/catalog-item-tags-form/catalog-item-tags-form.component';
import {SellerSettingsComponent} from './features/settings/seller-settings/seller-settings.component';
import {
    WindowSystemWebshopAreaBarsFormComponent
} from './features/window-system/webshop/window-system-webshop/window-system-webshop-area-bars-form/window-system-webshop-area-bars-form.component';
import {WebshopCustomPageComponent} from './features/settings/webshop-custom-page/webshop-custom-page.component';
import {
    WebshopChargePerSubsystemComponent
} from './features/window-system/webshop-charge-per-subsystem/webshop-charge-per-subsystem.component';
import {WebshopAreaBarsComponent} from './features/window-system/webshop/webshop-area-bars/webshop-area-bars.component';
import {WebshopGlazingHelpComponent} from './features/settings/webshop-glazing-help/webshop-glazing-help.component';
import {WebshopSizingHelpComponent} from './features/settings/webshop-sizing-help/webshop-sizing-help.component';
import {
    BulkStatusChangeDialogComponent
} from './features/offer/offers/bulk-status-change-dialog/bulk-status-change-dialog.component';
import {UiTranslationsComponent} from './features/translations/ui-translations/ui-translations.component';
import {
    SupplierMotlawaIntegrationFormComponent
} from './features/supplier/form/supplier-motlawa-integration-form.component';
import {
    MotlawaBulkAddonsDistributionDialogComponent
} from './features/offer/payment-package/motlawa-bulk-addons-distribution-dialog/motlawa-bulk-addons-distribution-dialog.component';
import {
    MotlawaDataDialogComponent
} from './features/offer/payment-package/motlawa-data-dialog/motlawa-data-dialog.component';
import {
    UpdateValidityDatesComponent
} from "./features/offer/offers/position/position-list/update-validity-dates/update-validity-dates.component";
import {
    CommentTabHeaderPipe
} from './features/offer/offers/position/position-list/comment-dialog/comment-tab-header.pipe';
import {FormInputsModule} from './form-inputs/form-inputs.module';
import {
    MotlawaIntegrationInputComponent
} from './form-inputs/motlawa-integration-input/motlawa-integration-input.component';
import {FULLSCREEN_CONTROLLER} from './form-inputs/wizard/wizard-fullscreen-controller';
import {SidenavController, sidenavControllerFactory} from './sidenav-controller';
import {SubsystemNotesComponent} from './features/subsystem/subsystem-notes/subsystem-notes.component';
import {
    SubsystemNotesCategoriesComponent
} from './features/window-system/subsystem-notes-categories/subsystem-notes-categories.component';
import {
    SubsystemNotesCategoriesFormComponent
} from './features/window-system/subsystem-notes-categories/subsystem-notes-categories-form/subsystem-notes-categories-form.component';
import {
    SubsystemNotesFormComponent
} from './features/subsystem/subsystem-notes/subsystem-notes-form/subsystem-notes-form.component';
import {
    SubsystemNoteCategoryNamePipe
} from './features/subsystem/subsystem-notes/pipes/subsystem-note-category-name.pipe';
import {StripHtmlPipe} from './features/subsystem/subsystem-notes/pipes/strip-html.pipe';
import {CustomMissingTranslationHandler} from "./translations/custom-missing-translation-handler";
import {MessagesDialogComponent} from "./features/offer/offers/offer-messages-dialog/messages-dialog.component";
import {LetVariableModule} from "./shared/let/let-variable.module";
import {RotSettingsComponent} from "./features/price-lists/rot-settings/rot-settings.component";
import {
    RotDataDialogComponent
} from "./features/offer/offers/position/position-list/rot-data-dialog/rot-data-dialog.component";
import {LanguagesListComponent} from './common/languages-list/languages-list.component';
import {EntranceModelComponent} from './features/window-system/entrance-model/entrance-model.component';
import {
    EntranceModelFormComponent
} from './features/window-system/entrance-model/entrance-model-form/entrance-model-form.component';
import {
    EntranceModelSystemsFormComponent
} from './features/window-system/entrance-model/entrance-model-systems-form/entrance-model-systems-form.component';
import {
    GraspGlazingCategoriesComponent
} from './features/window-system/grasp-glazing-categories/grasp-glazing-categories.component';
import {
    GraspGlazingPackageComponent
} from './features/window-system/grasp-glazing-package/grasp-glazing-package.component';
import {
    GraspGlazingCategoriesFormComponent
} from './features/window-system/grasp-glazing-categories/grasp-glazing-categories-form/grasp-glazing-categories-form.component';
import {
    GraspGlazingPackageFormComponent
} from './features/window-system/grasp-glazing-package/grasp-glazing-package-form/grasp-glazing-package-form.component';
import {
    GlazingPackageCategoryNamePipe
} from "./features/window-system/grasp-glazing-package/glazing-package-category-name.pipe";
import {
    GraspGlazingFormComponent
} from './features/window-system/grasp-glazing-package/grasp-glazing-form/grasp-glazing-form.component';
import {
    GraspGlazingPackageSystemsFormComponent
} from "./features/window-system/grasp-glazing-package/grasp-glazing-package-systems-form/grasp-glazing-package-systems-form.component";
import {
    EntranceGlazingPackageModelsComponent
} from "./features/window-system/entrance-glazing-package/entrance-glazing-package-models-form/entrance-glazing-package-models-form.component";
import {ProductTypePipe} from './features/offer/window-editor/sidebar/pricing/product-type.pipe';
import {
    SubsystemNotesCounterComponent
} from "./features/admin-panel/subsystem-notes-counter/subsystem-notes-counter.component";
import {CompositionDistancesFormComponent} from "./common/composition-distances/composition-distances-form.component";
import {
    OpenOfferChargeComponent
} from "./features/offer/offers/position/position-list/open-offer-charge/open-offer-charge.component";
import {GateSystemComponent} from "./features/window-system/gate-system/gate-system.component";
import {
    GateSystemFormComponent
} from "./features/window-system/gate-system/gate-system-form/gate-system-form.component";
import {RailSystemComponent} from './features/window-system/rail-system/rail-system.component';
import {
    RailSystemFormComponent
} from './features/window-system/rail-system/rail-system-form/rail-system-form.component';
import {IsNotAvailablePipe} from "./features/offer/window-editor/sidebar/is-not-available.pipe";
import {MapToSelectItemsPipe} from './features/offer/window-editor/sidebar/map-to-select-items.pipe';
import {AddGateDialogComponent} from './features/offer/add-product-dialog/add-product-dialog-data-provider/add-gate-dialog.component';
import {AddonCategoryFormComponent} from "./features/window-system/addon-category/form/addon-category-form.component";
import {AddonCategoryComponent} from "./features/window-system/addon-category/addon-category.component";
import {
    AddonCategoryGroupFormComponent
} from "./features/window-system/addon-category-group/form/addon-category-group-form.component";
import {
    AddonCategoryGroupComponent
} from "./features/window-system/addon-category-group/addon-category-group.component";
import {
    AddonCategoryAddonsFormComponent
} from "./features/window-system/addon-category/form/addon-category-addons-form.component";
import {
    ConjunctionsComponent
} from "./features/offer/offers/position/position-list/conjunctions/conjunctions.component";
import {
    ConjunctionEditorComponent
} from "./features/offer/window-editor/conjunction-editor/conjunction-editor.component";
import {GateWallFormComponent} from "./features/window-system/gate-wall/gate-wall-form/gate-wall-form.component";
import {GateWallComponent} from "./features/window-system/gate-wall/gate-wall.component";
import {
    GateWallSelectionDialogComponent
} from "./features/offer/gate-editor/gate-sidebar/gate-wall-selection-dialog/gate-wall-selection-dialog.component";
import {NewsColourClassPipe} from './features/settings/news/news-colour-class.pipe';
import {SheetTableComponent} from './features/price-lists/sheet-table/sheet-table.component';
import {RenderMotlawaDiffsPipe} from './features/offer/payment-package/motlawa-data-dialog/renderMotlawaDiffs.pipe';
import {CellSelectedPipe} from './features/price-lists/sheet-table/cell-selected.pipe';
import {SelectOrCheckboxComponent} from './features/offer/select-or-checkbox/select-or-checkbox.component';
import {EditCatalogPermitsComponent} from './features/admin-panel/edit-catalog-permits/edit-catalog-permits.component';
import {AsPipe} from './common/pipes/as.pipe';
import {ListboxSelectionComponent} from './common/listbox-selection/listbox-selection.component';
import {AddProductDialogComponent} from "./features/offer/add-product-dialog/add-product-dialog.component";
import {ConfigTypeDescriptorComponent} from './features/window-system/config-type-descriptor/config-type-descriptor.component';
import {ConfigTypeDescriptorFormComponent} from './features/window-system/config-type-descriptor/config-type-descriptor-form/config-type-descriptor-form.component';
import {ConfigSystemComponent} from "./features/window-system/config-system/config-system.component";
import {
    ConfigSystemFormComponent
} from "./features/window-system/config-system/config-system-form/config-system-form.component";
import { ConfigSystemDimensionsFormComponent } from './features/window-system/config-system/config-system-dimensions-form/config-system-dimensions-form.component';
import { ConfigSystemDimensionsSettingsComponent } from './features/window-system/config-system/config-system-dimensions-form/config-system-dimensions-settings/config-system-dimensions-settings.component';
import {NewConfigurableAddonDefaultsComponent} from "./features/settings/configurable-addon-global-settings/new-configurable-addon-defaults.component";
import {ConfigEditorComponent} from "./features/offer/config-editor/config-editor.component";
import {ConfigSidebarComponent} from "./features/offer/config-editor/config-sidebar/config-sidebar.component";
import { AddConfigDialogComponent } from "./features/offer/add-product-dialog/add-product-dialog-data-provider/add-config-dialog.component";
import {MaterialColorImagesFormComponent} from "./features/window-system/material/material-color-images/material-color-images-form.component";
import {TileSelectDialogComponent} from "./features/offer/config-editor/config-sidebar/tile-select-dialog/tile-select-dialog.component";
import {OpenImageInNewBrowserTabController} from "./common/open-image-in-new-browser-tab-controller.service";
import {PriceTableConfigsComponent} from "./features/price-lists/price-table/price-table-configs.component";
import {AddWindowDialogComponent} from "./features/offer/add-product-dialog/add-product-dialog-data-provider/add-window-dialog.component";
import {AddSubwindowDialogComponent} from "./features/offer/window-editor/add-subwindow-dialog/add-subwindow-dialog.component";
import {HomeComponent} from './features/home/home.component';
import {NewsPreviewComponent} from './features/home/news-preview/news-preview.component';
import {CardFoldComponent} from './common/card-fold/card-fold.component';
import {NavMoreComponent} from './features/home/news-preview/nav-more/nav-more.component';
import {GaugeChartComponent} from "./features/home/dashboard-report/gauge-chart/gauge-chart.component";
import {ConfigDesignerCatalogDependentOptionFormComponent} from "./features/window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option-form.component";
import {ConfigDesignerCatalogDependentOptionsSetComponent} from "./features/window-system/config-designer-catalog-dependent-option/config-designer-catalog-dependent-options-set.component";
import { ConfigDesignerCatalogDependentOptionFormRequiredComponent } from "./features/window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option-form-required.component";
import {ConfigDesignerCatalogDependentOptionFormDependentComponent} from "./features/window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option-form-dependent.component";
import {SliderModule} from "./shared/slider/slider.module";
import {ConfigSliderElementComponent} from "./features/window-system/config-slider-element/config-slider-element.component";
import {ConfigSliderElementFormComponent} from "./features/window-system/config-slider-element/config-slider-element-form/config-slider-element-form.component";
import {AddProductSliderComponent} from "./features/offer/add-product-dialog/add-product-slider/add-product-slider.component";
import {IsTileNotAvailablePipe} from "./features/offer/window-editor/sidebar/is-tile-not-available.pipe";
import {
    BulkPredefGlazingPackageChangeComponent
} from "./features/offer/offers/position/position-list/bulk-predef-glazing-package-change/bulk-predef-glazing-package-change.component";
import { PredefinedGlazingSelectionComponent } from './common/predefined-glazing-selection/predefined-glazing-selection.component';
import {PrintHistoryComponent} from "./features/offer/print-history/print-history.component";

export function translateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'translations/ui/', '');
}

export function hotkeysServiceFactory(options: IHotkeyOptions, zone: NgZone) {
    return new CustomHotkeysService(options, zone);
}

const pipes = [
    SelectItemTranslatePipe,
    SelectItemMultilanguageFieldTranslatePipe,
    SafeValuePipe,
    MultilanguageTranslatePipe,
    PricePipe,
    CommentTabHeaderPipe,
    FormatGlazingWidthsPipe,
    RangePipe,
    GlassSelectionFieldLabelPipe,
    SubsystemNoteCategoryNamePipe,
    StripHtmlPipe,
    SubsystemNoteCategoryNamePipe,
    StripHtmlPipe,
    GlazingPackageCategoryNamePipe,
    ProductTypePipe,
    IsNotAvailablePipe,
    IsTileNotAvailablePipe,
    MapToSelectItemsPipe,
    NewsColourClassPipe,
    RenderMotlawaDiffsPipe,
    AsPipe,
    MomentCurrentLangPipe,
    ConvertMillimetersToInchesPipe,
    CustomDecimalPipe,
    DetailedPricingPositionNamePipe,
    ModificationValuePipe,
    ShowZeroPricesPipe,
    StickyColumnClassPipe,
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MomentModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler
            },
            useDefaultLang: false
        }),
        HotkeyModule.forRoot(),
        FormInputsModule,
        AccordionModule,
        BlockUIModule,
        CalendarModule,
        ChartModule,
        ConfirmDialogModule,
        DataTableModule,
        DataViewModule,
        DialogModule,
        EditorModule,
        InputMaskModule,
        InputTextModule,
        ListboxModule,
        MultiSelectModule,
        PaginatorModule,
        PanelModule,
        PickListModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        ToastModule,
        TooltipModule,
        TreeTableModule,
        TreeModule,
        CommonModule,
        LetVariableModule,
        SliderModule,
        InputSwitchModule
    ],
    declarations: [
        pipes,
        AppComponent,
        LoginComponent,
        LoginFormComponent,
        SendResetPasswordMailFormComponent,
        ResetPasswordFormComponent,
        UserComponent,
        UserListComponent,
        SubsystemComponent,
        WindowSystemComponent,
        DistanceFrameComponent,
        UserSettingsComponent,
        SubsystemSettingsComponent,
        SettingsComponent,
        AdminPanelComponent,
        UserActivityComponent,
        SealComponent,
        ListUserGroupsComponent,
        ClientComponent,
        ClientGroupComponent,
        SupplierComponent,
        CreateSupplierComponent,
        EditSupplierDialogComponent,
        SupplierDetailsFormComponent,
        SupplierAddressFormComponent,
        SupplierAnnotationsFormComponent,
        SupplierMotlawaIntegrationFormComponent,
        SubstituteSupplierDialogComponent,
        SubstituteUserDialogComponent,
        PageNotFoundComponent,
        NoPrivilegesErrorComponent,
        PriceListsComponent,
        TranslationsComponent,
        WindowSystemDefinitionComponent,
        GlassComponent,
        GlassFormComponent,
        GrillComponent,
        RoofGlazingPackageComponent,
        OtherFillingComponent,
        ColorComponent,
        WindowDesignerComponent,
        BusinessTypeComponent,
        PriceTableComponent,
        PriceTableConfigsComponent,
        PriceListsDefinitionComponent,
        GeneralErrorComponent,
        DecorativeFillingComponent,
        PricesheetsComponent,
        OffersComponent,
        MainOffersComponent,
        PricingComponent,
        ValidationComponent,
        SubwindowComponent,
        GeneralTabComponent,
        ProfitMarginComponent,
        DealerDiscountPerSystemComponent,
        DealerDiscountPerSupplierComponent,
        DealerDiscountPerConfigurableAddonComponent,
        AddonsComponent,
        OrdersComponent,
        PositionComponent,
        PositionListComponent,
        AddBulkAddonPositionComponent,
        PictureComponent,
        PricingMessageComponent,
        WindowEditorComponent,
        RoofWindowEditorComponent,
        ConjunctionEditorComponent,
        ProfileComponent,
        ProductionOrdersComponent,
        MaterialComponent,
        MaterialColorImagesFormComponent,
        ShowDescriptionComponent,
        WindowPropertiesComponent,
        BulkWindowSystemChangeComponent,
        BulkGlassChangeComponent,
        BulkChangeConfirmationComponent,
        GlobalConfigAddonsAddConfirmationComponent,
        ApplicationInfoComponent,
        BulkColorChangeComponent,
        AddWindowDialogComponent,
        VeneerComponent,
        GlassSelectionComponent,
        BulkGeneralChangeComponent,
        ConfigurableAddonComponent,
        ConfigurableAddonFieldComponent,
        SubsystemAddonProfitMarginComponent,
        PositionListTableComponent,
        RabateComponent,
        ConfigAddonsComponent,
        ConfigAddonGlamourPrintInfoFormComponent,
        AddonsTabViewComponent,
        AddonPositionComponent,
        GlazingBeadComponent,
        UpdateExchangeRateComponent,
        VenskaSettingsComponent,
        VenskaPasswordResetMailSettingsComponent,
        ChangeDealerDiscountComponent,
        ChangeAnnotationsComponent,
        ErrorBrowserComponent,
        ScheduledJobComponent,
        UpdatePricingsComponent,
        AssemblyComponent,
        AssemblyFormComponent,
        AddAssemblyComponent,
        EditAssemblyComponent,
        TransportComponent,
        TransportFormComponent,
        AddTransportComponent,
        EditTransportComponent,
        SetPrintOrderDialogComponent,
        UpdateVatComponent,
        CatalogChargeProfitMarginComponent,
        MaintenancePageComponent,
        PricesheetTabComponent,
        ConfigurableAddonDialogComponent,
        ConfigAddonsListComponent,
        ButtonWithMenuComponent,
        ButtonPopupMenuComponent,
        EditBulkAddonPositionComponent,
        CreateOfferComponent,
        ConfigAddonPricingComponent,
        CommentDialogComponent,
        ConfirmActionDialogComponent,
        UserGroupFormComponent,
        AddonFormComponent,
        AddonSystemsFormComponent,
        LinkSelectionComponent,
        CreateClientComponent,
        ClientDetailsFormComponent,
        EditClientComponent,
        CreateClientGroupComponent,
        EditClientGroupComponent,
        PriceChangeComponent,
        TileSelectDialogComponent,
        BlockUiComponent,
        CreateSubsystemComponent,
        SubsystemDetailsFormComponent,
        SubsystemAddressFormComponent,
        EditSubsystemComponent,
        PricelistFormComponent,
        PricesheetFormComponent,
        DealerDiscountFormComponent,
        CreateUserComponent,
        UserDetailsFormComponent,
        EditUserComponent,
        ColorsSelectionComponent,
        DrawingToolControlsComponent,
        DistanceFrameFormComponent,
        SealFormComponent,
        WindowSystemDefinitionGeneralFormComponent,
        GlazingPackagesFormComponent,
        GlazingPackagesForAreaRangeFormComponent,
        WindowSystemDefinitionShapeFormComponent,
        WindowSystemDefinitionDrawingToolsFormComponent,
        WindowSystemDefinitionWebShopFormComponent,
        ColorFormComponent,
        MaterialFormComponent,
        GlazingBeadFormComponent,
        OtherFillingFormComponent,
        GrillFormComponent,
        RoofGlazingPackageFormComponent,
        ProfileFormComponent,
        GrowlMessageComponent,
        DecorativeFillingFormComponent,
        DeletePositionDialogComponent,
        SummaryDialogComponent,
        StatusTransitionDialogComponent,
        MessagesDialogComponent,
        ProductionOrderSummaryDialogComponent,
        WindowSystemDefinitionAnglesFormComponent,
        WindowSystemDefinitionGlamourPrintInfoFormComponent,
        WindowSystemDefinitionImpositionFormComponent,
        TemplatesSettingsComponent,
        TemplateFormComponent,
        DocumentTemplateComponent,
        DocumentTemplateFormComponent,
        PaymentPackageComponent,
        PaymentPackageFormComponent,
        InputLengthComponent,
        InputZipComponent,
        TemplateFormComponent,
        ShippingPricingsComponent,
        ShippingPricingtableComponent,
        BusinessTypeFormComponent,
        ShippingResultDrawerComponent,
        ShippingItemDrawerComponent,
        ShippingContainerDrawerComponent,
        WindowComponentPreviewDialogComponent,
        SidebarFieldLabelComponent,
        TermsOfUseComponent,
        TermsOfUseDialogComponent,
        NewsComponent,
        NewsEditorComponent,
        PrintoutDataComponent,
        GateSystemComponent,
        GateSystemFormComponent,
        PromotionCodeFormComponent,
        PromotionCodeComponent,
        PromotionInformationFormComponent,
        PromotionInformationComponent,
        NewsDialogComponent,
        GlobalChangeConfirmationComponent,
        SavedFilterComponent,
        DateRangeFilterComponent,
        FormDateRangeFilterComponent,
        TableDateRangeFilterComponent,
        OfferNumberComponent,
        OfferSupplierCounterComponent,
        OfferSubsystemCounterComponent,
        SubsystemNotesCounterComponent,
        ShippingSimulationSidebarComponent,
        ShippingSimulationFloatButton,
        UpsellingSidebarComponent,
        UpsellingFloatButtonComponent,
        ShippingSimulationDetailsComponent,
        ShippingSimulationListComponent,
        ChangeSystemDealerDiscountComponent,
        SubwindowTypeComponent,
        SubwindowTypeFormComponent,
        ApplicationStatusComponent,
        RedrawWindowsPageComponent,
        CreateComplaintComponent,
        InfoDialogComponent,
        ComparePositionsComponent,
        ComplaintListComponent,
        ComplaintPositionComponent,
        ComplaintPositionFormComponent,
        DeleteComplaintPositionDialogComponent,
        HotkeysHelpComponent,
        CreateComplaintConfirmationDialogComponent,
        TranslationFieldsComponent,
        RalColorSelectionDialogComponent,
        OfferArchivedVersionsDialogComponent,
        ToolbarButtonComponent,
        ContainerWithPreviewComponent,
        PrintDialogComponent,
        RackComponent,
        RackFormComponent,
        CatalogOptionComponent,
        CatalogOptionFormComponent,
        CatalogPropertyComponent,
        CatalogPropertyFormComponent,
        CatalogCreatorComponent,
        CatalogConfigurationWizardStepComponent,
        CatalogConfigurationDisplayComponent,
        SubsystemGroupComponent,
        SubsystemGroupFormComponent,
        SubsystemGroupAddonProfitMarginComponent,
        UserConsentsComponent,
        UserConsentsDialogComponent,
        CorrectionOrderGenerationDialogComponent,
        PaymentPackageSubsystemSelectionFormComponent,
        ConfirmOverwritingWindowSystemDefaultsDialogComponent,
        NewConfigurableAddonDefaultsComponent,
        NavigationBarComponent,
        WindowSystemWebshopComponent,
        SubsystemGroupWindowSystemSellDealerDiscountComponent,
        SubsystemGroupConfigurableAddonSellDealerDiscountComponent,
        SubsystemGroupSupplierSellDealerDiscountComponent,
        SubsystemWindowSystemSellDealerDiscountComponent,
        SubsystemConfigurableAddonSellDealerDiscountComponent,
        SubsystemSupplierSellDealerDiscountComponent,
        DeliveryListsComponent,
        DeliveryListFormComponent,
        DeliveryListSubsystemSelectionFormComponent,
        DeliveryListWizardComponent,
        DeliveryListComponent,
        PaletteTableComponent,
        PaletteWizardComponent,
        PaletteFormComponent,
        PaletteProductionOrdersComponent,
        WindowPricingtablesComponent,
        WindowPricingtableComponent,
        PaletteAdditionalElementsTableComponent,
        BulkFittingChangeComponent,
        WindowDimensionsComponent,
        WindowDimensionsFormComponent,
        WindowSystemDefinitionDimensionPriceFormComponent,
        WebshopGlazingPackageComponent,
        WebshopGlazingPackageGlazingFormComponent,
        GlazingPackageComponent,
        GlazingPackageGeneralDataFormComponent,
        TableKeyboardNavigationDirective,
        WebshopBusinessTypeComponent,
        SubsystemWebshopInfoComponent,
        TableKeyboardNavigationDirective,
        WindowSystemDefinitionDimensionPriceFormComponent,
        ExitWithoutSavingConfirmationDialogComponent,
        SidebarHiderComponent,
        AddProductDialogComponent,
        AddRoofWindowDialogComponent,
        WindowSystemModelComponent,
        WebshopGlazingPackagePerSubsystemComponent,
        WebshopChargeComponent,
        SubsystemInactiveSuppliersFormComponent,
        WebshopRecommendedConfigurationComponent,
        WebshopRecommendedConfigurationGeneralFormComponent,
        WebshopRecommendedConfigurationBusinessTypeFormComponent,
        WebshopRecommendedConfigurationSizeFormComponent,
        WebshopRecommendedConfigurationModelFormComponent,
        WebshopRecommendedConfigurationGlazingFormComponent,
        WebshopRecommendedConfigurationColorFormComponent,
        WebshopRecommendedConfigurationAreaBarsFormComponent,
        WebshopRecommendedConfigurationWebshopChargesFormComponent,
        PaymentPackagePicklistComponent,
        SubsystemAddressListComponent,
        SubsystemAddressListFormComponent,
        SubsystemAddressSelectionDialogComponent,
        CatalogItemTagsComponent,
        CatalogItemTagsFormComponent,
        ConfigSidebarComponent,
        SubsystemAddressSelectionDialogComponent,
        SellerSettingsComponent,
        ConfigurableAddonUpsellingSettingsComponent,
        UpsellingSettingsFormComponent,
        SubsystemWebshopMailConfigurationComponent,
        WebshopSaleComponent,
        SearchComponent,
        OfferSearchResultsComponent,
        WindowSystemWebshopAreaBarsFormComponent,
        WebshopCustomPageComponent,
        WebshopChargePerSubsystemComponent,
        WebshopAreaBarsComponent,
        WebshopGlazingHelpComponent,
        WebshopSizingHelpComponent,
        UserLoginTimesComponent,
        BulkStatusChangeDialogComponent,
        UiTranslationsComponent,
        UpsellingPropositionComponent,
        UpsellingPropositionFormComponent,
        UpsellingPropositionChargesComponent,
        UpsellingPropositionGlazingsComponent,
        UpsellingPropositionConfigAddonsComponent,
        ApplicationResourceComponent,
        MotlawaIntegrationInputComponent,
        MotlawaBulkAddonsDistributionDialogComponent,
        MotlawaDataDialogComponent,
        WebshopUiTranslationsComponent,
        WindowDesignerCatalogDependentOptionsSetComponent,
        WindowDesignerCatalogDependentOptionFormComponent,
        WindowDesignerCatalogDependentOptionFormRequiredComponent,
        WindowDesignerCatalogDependentOptionFormDependentComponent,
        WebshopHelpComponent,
        TerraceHandleLayoutSelectionDialogComponent,
        UpdateValidityDatesComponent,
        SubsystemNotesComponent,
        SubsystemNotesCategoriesComponent,
        SubsystemNotesCategoriesFormComponent,
        SubsystemNotesFormComponent,
        CountdownComponent,
        RotSettingsComponent,
        RotDataDialogComponent,
        LanguagesListComponent,
        EntranceGlazingPackageComponent,
        EntranceGlazingPackageFormComponent,
        EntranceModelComponent,
        EntranceModelFormComponent,
        EntranceModelSystemsFormComponent,
        AddEntranceWindowDialogComponent,
        AddSubwindowDialogComponent,
        AddonCategoryAddonsFormComponent,
        AddonCategoryFormComponent,
        AddonCategoryComponent,
        AddonCategoryGroupFormComponent,
        AddonCategoryGroupComponent,
        GraspGlazingCategoriesComponent,
        GraspGlazingPackageComponent,
        GraspGlazingCategoriesFormComponent,
        GraspGlazingPackageFormComponent,
        GraspGlazingFormComponent,
        GraspGlazingPackageSystemsFormComponent,
        SimpleProfitMarginSourceComponent,
        EntranceGlazingPackageModelsComponent,
        SubsystemPartnerWebshopInfoComponent,
        CompositionDistancesFormComponent,
        DiscontinuedCatalogItemInfoComponent,
        OpenOfferChargeComponent,
        DiscontinuedCatalogItemInfoFormComponent,
        RoleComponent,
        RoleFormComponent,
        RailSystemComponent,
        RailSystemFormComponent,
        GatePanelTypeComponent,
        GatePanelTypeFormComponent,
        AddGateDialogComponent,
        GateEditorComponent,
        ConfigEditorComponent,
        AddConfigDialogComponent,
        GateSidebarComponent,
        GatePanelTypeSelectionDialogComponent,
        GateWallSelectionDialogComponent,
        GatePainterDirective,
        GatePrintPainterDirective,
        GateDesignerCatalogDependentOptionsSetComponent,
        ConfigDesignerCatalogDependentOptionFormComponent,
        ConfigDesignerCatalogDependentOptionsSetComponent,
        GateDesignerCatalogDependentOptionFormComponent,
        GateDesignerCatalogDependentOptionFormRequiredComponent,
        GateDesignerCatalogDependentOptionFormDependentComponent,
        ConfigDesignerCatalogDependentOptionFormRequiredComponent,
        ConfigDesignerCatalogDependentOptionFormDependentComponent,
        GateSystemDefaultsComponent,
        MarkProductsComponent,
        GateWallFormComponent,
        GateWallComponent,
        ConjunctionsComponent,
        MissingProfitMarginHandlerComponent,
        SheetTableComponent,
        CellSelectedPipe,
        SelectOrCheckboxComponent,
        OfferStatusChangeMailSettingsComponent,
        EditCatalogPermitsComponent,
        ListboxSelectionComponent,
        ConfigTypeDescriptorComponent,
        ConfigTypeDescriptorFormComponent,
        ConfigSystemComponent,
        ConfigSystemFormComponent,
        ConfigSliderElementComponent,
        ConfigSliderElementFormComponent,
        AddProductSliderComponent,
        ConfigSystemDimensionsFormComponent,
        ConfigSystemDimensionsSettingsComponent,
        DashboardReportComponent,
        DashboardReportSettingsFormComponent,
        HomeComponent,
        NewsPreviewComponent,
        CardFoldComponent,
        NavMoreComponent,
        OfferHistoryComponent,
        PrintHistoryComponent,
        GaugeChartComponent,
        AddStandaloneGlazingPackageComponent,
        AddStandaloneGlazingPackagesGloballyComponent,
        DesignerLengthUnitHiderDirective,
        ExternalUserOfferEditCompletedComponent,
        GraspDistanceFrameCategoryComponent,
        GraspDistanceFrameCategoryFormComponent,
        DetailedPricingComponent,
        DetailedPricingHelpDialogComponent,
        DetailedPricingSimulationParametersDialogComponent,
        BulkPredefGlazingPackageChangeComponent,
        PredefinedGlazingSelectionComponent,
        SubsystemSalesTargetSettingsComponent,
        SubsystemUserActivityReportComponent,
        UserActivityReportWizardComponent,
        UserActivityReportMailSettingsComponent,
    ],
    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        LogoutHelperService,
        AuthGuard,
        {provide: IsLoggedService, useFactory: isLoggedServiceSingletonFactory},
        {provide: IsAnotherUserLoggedService, useFactory: isAnotherUserLoggedServiceSingletonFactory},
        CurrentUserService,
        Permissions,
        PermissionsGuard,
        StorageService,
        JwtHelperService,
        UserUiConfigService,
        UserUiConfigResolver,
        ApplicationInfoService,
        ApplicationFeaturesResolver,
        ShippingSimulationService,
        DataServiceHelper,
        DisplayUnitService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SkipInterceptorsInterceptor, multi: true}, // must be the last interceptor
        {provide: HotkeyOptions, useValue: {disableCheatSheet: true}},
        {provide: HotkeysService, useFactory: hotkeysServiceFactory, deps: [HotkeyOptions, NgZone]},
        {provide: SidenavController, useFactory: sidenavControllerFactory},
        GrowlMessageController,
        OpenImageInNewBrowserTabController,
        CommonErrorHandler,
        {provide: BlockUiController, useFactory: blockUiControllerFactory},
        {provide: FULLSCREEN_CONTROLLER, useClass: WizardSidenavAdapter, multi: true},
        RequiresExitConfirmationGuard,
        OfferViewRestrictionGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
