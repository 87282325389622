<app-global-change-confirmation (onSubmit)="submitDialog($event)"
                                [visible]="dialogData != null"
                                (visibleChange)="submitDialog(false)"
                                [allPositionsContainErrors]="allPositionsContainErrors()"
                                [somePositionsContainErrors]="somePositionsContainErrors()">
    <div class="position" *ngFor="let position of getOnlyWindowSystems(dialogData.modifiedPositions)">
        <div class="name-status">
            <div class="name">
                {{ position.printOrder + ' - '}}
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.POSITION' | translate }}
                {{ position.name[translate.currentLang] }}
            </div>
            <div *ngIf="positionContainErrors(position)" class="status failure">
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.NOT_VALID' | translate }}
            </div>
            <div *ngIf="!positionContainErrors(position)" class="status success">
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.VALID' | translate }}
            </div>
        </div>
        <ng-container *ngIf="positionContainErrors(position)">
            <div class="new-form-field-error-message" *ngFor="let error of dialogData.changeResult.validationResults[position.id]"
                 [innerHTML]="error | translate"></div>
        </ng-container>

        <ng-container *ngFor="let msg of dialogData.changeResult.messagesByPosition[position.id]">
            <app-pricing-message [message]="msg"></app-pricing-message>
        </ng-container>
    </div>
    <div>
        {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.CLOSE_WARN' | translate }}
    </div>
</app-global-change-confirmation>
