export enum DecorativeFillingType {
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    T6 = 'T6',
    T7 = 'T7',
    T8 = 'T8',
    T9 = 'T9',
    T10 = 'T10',
    T11 = 'T11',
    T12 = 'T12',
    T13 = 'T13',
    T14 = 'T14',
    TTEMP = 'TTEMP'
}
