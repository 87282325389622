import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {OnceFlag} from '../../../shared/once-flag';
import {StatusTransition} from "./StatusTransition";

@Component({
    selector: 'app-offer-status-transition-dialog',
    templateUrl: './status-transition-dialog.component.html',
    styleUrls: ['../../shared-styles.css', '../../../common/offer-status-colors.css', './status-transition-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusTransitionDialogComponent {

    @Input()
    possibleTransitions: StatusTransition[] = [];

    @Input()
    infoLabelPrefix: string;

    @Output()
    onHide = new EventEmitter<void>();

    @Output()
    onChangeStatus = new EventEmitter<StatusTransition>();

    selectedTransition: StatusTransition;
    selectedTransitionForInfo: StatusTransition;

    statusTransitionDialogVisible = true;
    statusTransitionInfoDialogVisible = false;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private translate: TranslateService) {
    }

    hide(): void {
        if (!this.statusTransitionInfoDialogVisible) {
            this.dialogHideHelper.call(() => this.onHide.emit());
        }
    }

    changeStatus() {
        if (this.selectedTransition) {
            this.dialogHideHelper.call(() => this.onChangeStatus.emit(this.selectedTransition));
        }
    }

    showTransitionInfoDialog(transition: StatusTransition, event): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        this.selectedTransitionForInfo = transition;
        this.statusTransitionDialogVisible = false;
        this.statusTransitionInfoDialogVisible = true;
    }

    hideStatusTransitionDialog(): void {
        this.statusTransitionDialogVisible = true;
        this.statusTransitionInfoDialogVisible = false;
    }

    getStatusTransitionInfoLabel(): string {
        return this.translate.instant(this.infoLabelPrefix + this.selectedTransitionForInfo.item.id);
    }
}
