import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Subscription} from "rxjs";
import {WindowTypeCodeParser} from "../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {Country} from '../../../common/enums/country';
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {ShippingPriceTableService} from './shipping-pricings.service';
import {ShippingPriceTable} from './shippingPriceTable';
import {ShippingPriceTableInfo} from './shippingPriceTableInfo';

@Component({
    selector: 'app-shipping-pricings',
    templateUrl: './shipping-pricings.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [DataServiceHelper, ShippingPriceTableService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingPricingsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    errors: CommonErrorHandler;
    shippingPriceTableInfoList: ShippingPriceTableInfo[] = [];
    selectedTableInfo: ShippingPriceTableInfo;
    totalRecords = 0;
    displayDialog = false;
    lastLoadEvent: LazyLoadEvent;
    langTranslateSubscription: Subscription;
    userLang: string;
    availableTypes: SelectItem[] = [];
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    typeNameParser: (string) => string;

    priceTable: ShippingPriceTable;

    @ViewChild("dt", {static: true}) datatable: DataTable;

    constructor(public priceTableService: ShippingPriceTableService,
                public translate: TranslateService,
                public permissions: Permissions,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'ShippingPricingsComponent', false);
        this.errors = injector.get(CommonErrorHandler);
        this.userLang = translate.currentLang;
        this.langTranslateSubscription = translate.onLangChange.subscribe((event) => {
            this.userLang = event.lang;
            this.changeDetector.markForCheck();
        });
        this.typeNameParser = (name: string) => WindowTypeCodeParser.getTypeName(WindowTypeCode[name]);
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.priceTableService.getShippingPriceTableInfoList().subscribe({
            next: data => {
                this.shippingPriceTableInfoList = data.data;
                this.totalRecords = data.totalRecords;
            },
            error: error => {
                this.onError(error);
            },
            complete: () => {
                console.info('loadshippingPriceTableInfoList `getPage` completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.langTranslateSubscription.unsubscribe();
    }

    editPricetable(table: ShippingPriceTableInfo) {
        this.selectedTableInfo = table;
        if (table.hasPricetable) {
            this.loadCountryShippingPricing(table.country);
        } else {
            this.priceTable = new ShippingPriceTable();
            this.validationErrors = {};
            this.displayDialog = true;
        }
    }

    loadCountryShippingPricing(country: Country) {
        this.showDataLoadingIndicator();
        this.priceTableService.getPriceTable(country).subscribe({
            next: data => {
                this.priceTable = data;
                this.validationErrors = {};
                this.displayDialog = true;
            },
            error: error => {
                this.onError(error);
            },
            complete: () => {
                console.info('ShippingPricingsComponent loadCountryShippingPricing  completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        });
    }

    submitShippingTable(newPriceTable: ShippingPriceTable) {
        this.showDataLoadingIndicator();
        this.sanitizeShippingPriceTable(newPriceTable);
        this.priceTableService.savePriceTable(newPriceTable).subscribe({
            next: () => {
                this.loadData();
                this.closeDialog();
                this.showSuccessMessage();
            },
            error: error => {
                this.onError(error);
            },
            complete: () => {
                console.info(`saveShippingPriceTable for ${newPriceTable.country} completed!`);
            }
        });
    }

    private sanitizeShippingPriceTable(newPriceTable: ShippingPriceTable) {
        newPriceTable.dataByCodes = newPriceTable.dataByCodes.map(shippingPriceTableDataRow => {
            let toRemove = Object.keys(shippingPriceTableDataRow)
                .filter(key => shippingPriceTableDataRow[key] == null || shippingPriceTableDataRow[key].trim() === '');
            toRemove.forEach(key => {
                delete shippingPriceTableDataRow[key];
            });
            return shippingPriceTableDataRow;
        });
    }

    showSuccessMessage() {
        if (this.selectedTableInfo.hasPricetable) {
            this.growlMessageController.info('SHIPPING_PRICE_TABLES.UPDATED');
        } else {
            this.growlMessageController.info('SHIPPING_PRICE_TABLES.CREATED');
        }
    }

    onError(error): void {
        this.hideDataLoadingIndicator();
        this.validationErrors = this.errors.handle(error);
        this.changeDetector.markForCheck();
    }

    closeDialog() {
        this.displayDialog = false;
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    submit() {
    }

    onRowSelect(event: any) {
    }

    showDialogToAdd(): void {
    }
}
