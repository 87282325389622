import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class ScheduledJob {
    id: number;
    jobType: string;
    startDate: Date;
    endDate: Date;
    lastSuccessDate: Date;
    lastFailureDate: Date;

    static fromJSON(jsonObject: any): ScheduledJob {
        const scheduledJob = new ScheduledJob();
        scheduledJob.id = jsonObject.id;
        scheduledJob.jobType = jsonObject.jobType;
        scheduledJob.startDate = jsonObject.startDate ? new Date(jsonObject.startDate) : undefined;
        scheduledJob.endDate = jsonObject.endDate ? new Date(jsonObject.endDate) : undefined;
        scheduledJob.lastSuccessDate = jsonObject.lastSuccessDate ? new Date(jsonObject.lastSuccessDate) : undefined;
        scheduledJob.lastFailureDate = jsonObject.lastFailureDate ? new Date(jsonObject.lastFailureDate) : undefined;
        return scheduledJob;
    }
}
