import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {News} from './news';
import {NewsViewMode} from "./newsViewMode";

@Injectable()
export class NewsService implements CrudService<News> {

    private static readonly API_URL = 'news';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<News>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(NewsService.API_URL, {params: params}).pipe(mapListingToJson(News));
    }

    getPreviewItems(limit: number): Observable<Listing<News>> {
        let params = this.dataServiceHelper.prepareSearchParams(null, limit, null, null, null);
        params['viewMode'] = NewsViewMode.PREVIEW;
        return this.http.get<Listing<News>>(NewsService.API_URL, {params: params}).pipe(mapListingToJson(News));
    }

    getItem(newsId: number, viewMode?: NewsViewMode): Observable<News> {
        let params: { [param: string]: string | string[] } = {};
        if (viewMode) {
            params['viewMode'] = viewMode;
        }
        return this.http.get<object>(`${NewsService.API_URL}/${newsId}`, {params: params}).pipe(mapItemToJson(News));
    }

    addItem(news: News, file: File, copyId?: number): Observable<number> {
        let body = JSON.stringify(news);
        let formData = new FormData();
        formData.append('newsDto', new Blob([body], {type: 'application/json'}));
        if (copyId) {
            formData.append('copyId', '' + copyId);
        }
        if (file) {
            formData.append('image', file);
        }
        return this.http.post<void>(NewsService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(NewsService.API_URL));
    }

    editItem(newsId: number, news: News, file: File, clearReadFlag?: boolean): Observable<number> {
        let body = JSON.stringify(news);
        let formData = new FormData();
        formData.append('newsDto', new Blob([body], {type: 'application/json'}));
        if (file) {
            formData.append('image', file);
        }
        return this.http.put<void>(NewsService.API_URL, formData, {params: {clearReadFlag: `${clearReadFlag}`}})
            .pipe(this.dataServiceHelper.mapToExistingItemId(news.id));
    }

    saveNews(news: News, clearReadFlag: boolean, file: File, copyId: number): Observable<number> {
        if (news.id != undefined) {
            return this.editItem(news.id, news, file, clearReadFlag);
        }
        return this.addItem(news, file, copyId);
    }

    deleteNews(newsId: number): Observable<void> {
        return this.http.delete<void>(`${NewsService.API_URL}/${newsId}`);
    }

    finishNews(newsId: number): Observable<void> {
        return this.http.patch<void>(`${NewsService.API_URL}/finish/${newsId}`, null);
    }

    getCurrentNews(): Observable<News> {
        return this.http.get<object>(`${NewsService.API_URL}/current`)
            .pipe(mapItemToJson(News));
    }

    getImage(newsId: number): Observable<File> {
        return this.http.get(`${NewsService.API_URL}/${newsId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }
}
