import {JsonDeserializable} from './crud-common/crudItem';
import {Country} from './enums/country';

@JsonDeserializable
export class Address {
    street: string;
    city: string;
    zip: string;
    country: Country;

    static fromJSON(jsonObject: any): Address {
        const address = new Address();
        address.street = jsonObject.street;
        address.city = jsonObject.city;
        address.zip = jsonObject.zip;
        address.country = Country[jsonObject.country as string];
        return address;
    }
}
