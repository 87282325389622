import {GrillType} from "./GrillType";
import {GenericGrillGrid} from "./GenericGrillGrid";

export class CrossGrillSimple extends GenericGrillGrid {
    type: GrillType.GRID_CROSS_SIMPLE;

    constructor() {
        super(GrillType.GRID_CROSS_SIMPLE);
    }
}
