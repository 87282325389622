import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {AbstractSellDealerDiscount} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount';

@JsonDeserializable
export class SubsystemWindowSystemSellDealerDiscount extends AbstractSellDealerDiscount {
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    windowSystemMaterial: string;

    static fromJSON(jsonObject: any): SubsystemWindowSystemSellDealerDiscount {
        const sellDealerDiscount = Object.assign(new SubsystemWindowSystemSellDealerDiscount(), jsonObject);
        sellDealerDiscount.windowSystemName = Object.assign(new MultilanguageField(), jsonObject.windowSystemName);
        return sellDealerDiscount;
    }
}
