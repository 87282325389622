export class ProfitMarginExistance {
    dealerMarkup: boolean;
    subsystemMarkup: boolean;
    clientMarkup: boolean;
    clientMarkupIsZeroWarning: boolean;
    sellerClientMarkup: boolean;

    constructor(values: boolean) {
        this.dealerMarkup = values;
        this.subsystemMarkup = values;
        this.clientMarkup = values;
        this.clientMarkupIsZeroWarning = !values;
        this.sellerClientMarkup = values;
    }
}
