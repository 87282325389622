import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslationsService} from '../../translations/translations.service';
import {MailSettings} from '../mail-settings/mail-settings';
import {AbstractMailSettingsComponent} from '../mail-settings/mail-settings.component';
import {MailSettingsService} from '../mail-settings/mail-settings.service';

@Component({
    selector: 'app-offer-status-change-mail-settings',
    templateUrl: '../mail-settings/mail-settings.component.html',
    styleUrls: ['../settings.component.css'],
    providers: [MailSettingsService, TranslationsService, TranslatedSelectItemService]
})
export class OfferStatusChangeMailSettingsComponent extends AbstractMailSettingsComponent {

    constructor(private readonly mailSettingsService: MailSettingsService,
                translationsService: TranslationsService,
                translateService: TranslateService,
                translatedSelectItemService: TranslatedSelectItemService,
                blockUiController: BlockUiController,
                growlMessageController: GrowlMessageController,
                errors: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(translationsService, translateService, translatedSelectItemService, blockUiController,
            growlMessageController, errors, 'SETTINGS.SECTION.OFFER_STATUS_CHANGE_MAIL_SETTINGS.HEADER',
            'OFFER.MAIL.STATUS_CHANGE.SUBJECT', 'OFFER.MAIL.STATUS_CHANGE.BODY', false, changeDetector);
        this.componentType = 'offerStatusChange';
    }

    protected loadMailSettings(): Observable<MailSettings> {
        return this.mailSettingsService.getOfferStatusChangeMailSettings();
    }

    protected saveMailSettings(mailSettings: MailSettings): Observable<void> {
        return this.mailSettingsService.updateOfferStatusChangeMailSettings(mailSettings);
    }
}
