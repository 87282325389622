import {Component, Input} from '@angular/core';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {SealField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {SealFieldUsage} from "../../catalog-field-usage";
import {SealWithMaterials} from "../SealWithMaterials";

@Component({
    selector: 'app-seal-form',
    templateUrl: './seal-form.component.html'
})
export class SealFormComponent {

    @Input() item: SealWithMaterials;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[] = [];

    fieldUsage: SealFieldUsage;
    SealField = SealField;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor() {
        this.fieldUsage = new SealFieldUsage(this);
    }
}
