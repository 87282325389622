import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper, FileState} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {GatePanelType} from './gate-panel-type';

@Injectable()
export class GatePanelTypeService implements CrudService<GatePanelType> {

    private static readonly API_URL = 'gatePanelTypes';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GatePanelType>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(GatePanelTypeService.API_URL, {params: params})
            .pipe(mapListingToJson(GatePanelType));
    }

    getItem(itemId: number): Observable<GatePanelType> {
        return this.http.get<object>(`${GatePanelTypeService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(GatePanelType));
    }

    getLowResolutionPreviewImage(gatePanelTypeId: number): Observable<string> {
        return this.http.get(`${GatePanelTypeService.API_URL}/${gatePanelTypeId}/lowResolutionPreviewImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getLowResolutionPreviewImageAsFile(gatePanelTypeId: number): Observable<File> {
        return this.http.get(`${GatePanelTypeService.API_URL}/${gatePanelTypeId}/lowResolutionPreviewImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getHighResolutionPreviewImage(gatePanelTypeId: number): Observable<string> {
        return this.http.get(`${GatePanelTypeService.API_URL}/${gatePanelTypeId}/highResolutionPreviewImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getHighResolutionPreviewImageAsFile(gatePanelTypeId: number): Observable<File> {
        return this.http.get(`${GatePanelTypeService.API_URL}/${gatePanelTypeId}/highResolutionPreviewImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getAdditionalIconAsFile(gatePanelTypeId: number): Observable<File> {
        return this.http.get(`${GatePanelTypeService.API_URL}/${gatePanelTypeId}/additionalIcon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(item: GatePanelType, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState,
            additionalIcon?: FileState): Observable<number> {
        const formData = this.prepareFormData(item, lowResolutionPreviewImage, highResolutionPreviewImage, additionalIcon);
        return this.http.post<void>(GatePanelTypeService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(GatePanelTypeService.API_URL));
    }

    editItem(itemId: number, item: GatePanelType, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState,
             additionalIcon?: FileState): Observable<number> {
        const formData = this.prepareFormData(item, lowResolutionPreviewImage, highResolutionPreviewImage, additionalIcon);
        return this.http.put<void>(GatePanelTypeService.API_URL, formData)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    copyItem(itemId: number, item: GatePanelType, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState,
            additionalIcon?: FileState): Observable<number> {
        const formData = this.prepareFormData(item, lowResolutionPreviewImage, highResolutionPreviewImage, additionalIcon);
        return this.http.post<void>(`${GatePanelTypeService.API_URL}/${itemId}/copy`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(GatePanelTypeService.API_URL));
    }

    private prepareFormData(item: GatePanelType, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState,
                            additionalIcon?: FileState): FormData {
        const formData = new FormData();
        formData.append('gatePanelTypeDto', new Blob([JSON.stringify(item)], {type: 'application/json'}));
        if (this.dataServiceHelper.isFileSaveNeeded(lowResolutionPreviewImage)) {
            formData.append('lowResolutionPreviewImage', lowResolutionPreviewImage.file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(highResolutionPreviewImage)) {
            formData.append('highResolutionPreviewImage', highResolutionPreviewImage.file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(additionalIcon)) {
            formData.append('additionalIcon', additionalIcon.file);
        }
        return formData;
    }

    validate(item: GatePanelType): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GatePanelTypeService.API_URL}/validate`, item));
    }

    editLinks(gatePanelTypeId: number, gateSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`${GatePanelTypeService.API_URL}/link/${gatePanelTypeId}`, gateSystemIds);
    }

    getLinkedSystems(gatePanelTypeId: number): Observable<number[]> {
        return this.http.get<number[]>(`${GatePanelTypeService.API_URL}/linked/${gatePanelTypeId}`);
    }

    getPanelTypesForGateSystem(gateSystemId: number, selectedIds: number[] = null): Observable<GatePanelType[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        return this.http.get<object[]>(`${GatePanelTypeService.API_URL}/forGateSystem/${gateSystemId}`, {params: params})
            .pipe(map(result => result.map(GatePanelType.fromJSON)));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${GatePanelTypeService.API_URL}/names`, {params: params});
    }
}
