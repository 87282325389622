import {ProfileInterface, ProfileType} from '../../../../window-designer/catalog-data/profile-interface';
import {CrudItem} from "../../../common/crud-common/crudItem";
import {CrudItemList} from '../../../common/crud-common/crudItemList';
import {MultilanguageField} from "../../../supportedLanguages";
import {CompositionDistances} from "../../../../window-designer/composition-distances";

export class Profile implements CrudItem, ProfileInterface {
    id: number;
    active: boolean;
    symbol: string;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    type: ProfileType;
    compositionDistances: CompositionDistances;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    systemIds: number[];
    sortIndex: number;
    unitWeight: number;

    constructor() {
        this.id = undefined;
        this.active = true;
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.type = ProfileType.FRAME;
        this.pcv = "UNCHECKED";
        this.aluminium = "UNCHECKED";
        this.wood = "UNCHECKED";
        this.aluminiumWoodMix = "UNCHECKED";
        this.sortIndex = 1;
        this.unitWeight = undefined;
        this.compositionDistances = new CompositionDistances();
    }
}

export class ProfileList implements CrudItemList {
    data: Profile[];
    totalRecords: number;
}
