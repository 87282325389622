<p-dialog [resizable]="false" [modal]="true" [visible]="visible" (visibleChange)="visibleChange.emit($event)"
          header="{{ 'SUBSYSTEM-DETAILS.FORM.ADDRESS_SELECTION_HEADER' | translate }}">
    <app-subsystem-address-list-form #addressForm [addresses]="addresses"
                                     (addressesChange)="handleModify($event)"
                                     [selectedAddress]="selectedAddress" [usedAddresses]="[selectedAddress]"
                                     (selectedAddressChange)="selectedAddress = $event" [canEdit]="canEdit"
                                     [selectable]="selectable"></app-subsystem-address-list-form>
    <p-footer *ngIf="addressForm.editedAddress == null">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="wizard-dialog-save" label="{{'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" [disabled]="selectable && selectedAddress == undefined"
                               (onClick)="handleSelect()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel"
                               label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="visibleChange.emit(false)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
