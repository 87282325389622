import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {CatalogOption} from './catalog-option';

@Injectable()
export class CatalogOptionService implements CrudService<CatalogOption> {

    private static readonly API_URL = 'catalogOption';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<CatalogOption>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<CatalogOption>>(CatalogOptionService.API_URL, {params: params});
    }

    getItem(itemId: number): Observable<CatalogOption> {
        return this.http.get<CatalogOption>(`${CatalogOptionService.API_URL}/${itemId}`);
    }

    getImage(optionId: number): Observable<File> {
        return this.http.get(`${CatalogOptionService.API_URL}/${optionId}/icon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(item: CatalogOption): Observable<number> {
        // handled by saveCatalogOption
        return this.saveCatalogOption(item, undefined, undefined);
    }

    editItem(itemId: number, item: CatalogOption): Observable<number> {
        // handled by saveCatalogOption
        return this.saveCatalogOption(item, undefined, undefined);
    }

    saveCatalogOption(item: CatalogOption, file, amazonS3File: File | string | undefined): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('catalogOptionDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (amazonS3File != undefined) {
            formData.append('amazonS3File', amazonS3File);
        }
        return this.http.post('catalogOption', formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(CatalogOptionService.API_URL, response)));
    }

    copy(id: number, item: CatalogOption, file, amazonS3File: File | string | undefined) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('catalogOptionDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (amazonS3File != undefined) {
            formData.append('amazonS3File', amazonS3File);
        }
        return this.http.post(`${CatalogOptionService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(CatalogOptionService.API_URL, response)));
    }

    validate(option: CatalogOption): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${CatalogOptionService.API_URL}/validate`, option));
    }

    canUploadToAmazonS3(): Observable<boolean> {
        return this.http.get<boolean>(`${CatalogOptionService.API_URL}/canUploadToAmazonS3`);
    }

    getAmazonS3Files(): Observable<string[]> {
        return this.http.get<string[]>(`${CatalogOptionService.API_URL}/amazonS3Files`);
    }

    getCatalogOptionFileUrl(optionId: number): Observable<string> {
        return this.http.get(`${CatalogOptionService.API_URL}/${optionId}/fileUrl`, {responseType: 'text'});
    }
}
