import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {ColorInterface, getColorFormattedNameWithGroup} from '../../../../window-designer/catalog-data/color-interface';
import {DecorativeFillingType} from '../../../../window-designer/catalog-data/decorative-filling-type';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, DecorativeFillingField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {DecorativeFillingFieldUsage} from "../catalog-field-usage";
import {Color} from '../color/color';
import {ColorService} from '../color/color.service';
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {DecorativeFillingsService} from './decorative-filling.service';
import {DecorativeFilling} from './decorativeFilling';

@Component({
    selector: 'app-decorative-filling',
    templateUrl: './decorative-filling.component.html',
    styleUrls: ['./decorative-filling.component.css', '../../shared-styles.css'],
    providers: [DecorativeFillingsService, DataServiceHelper, WindowSystemDefinitionService, ColorService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecorativeFillingComponent extends SingleSystemCheckboxCrudComponent<DecorativeFilling, DecorativeFillingsService>
    implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    item: DecorativeFilling;
    availableInsideColors: SelectItem[];
    availableOutsideColors: SelectItem[];
    availableCoreColors: SelectItem[];
    availableTypes: string[] = [];
    private allActiveColors: Observable<ColorInterface[]>;

    @ViewChild('dt') datatable;
    validateDataStep: WizardStepValidator;

    editPermits: FieldLimitation[] = [];
    fieldUsage: DecorativeFillingFieldUsage;
    CatalogTab = CatalogTab;
    DecorativeFillingField = DecorativeFillingField;

    constructor(injector: Injector,
                public colorService: ColorService,
                private editCatalogPermitsService: EditCatalogPermitsService,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, DecorativeFillingsService, 'DECORATIVE_FILLING', 'DecorativeFilling');
        this.item = new DecorativeFilling();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new DecorativeFillingFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        for (let type in DecorativeFillingType) {
            this.availableTypes.push(type);
        }
        this.allActiveColors = this.colorService.getAllActiveColors().pipe(shareReplay(1));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.DECORATIVE_FILLINGS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'decorative-filling';
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.newItem = false;
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        this.newItem = false;
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    showDialogToAdd() {
        this.item = this.getNewItem();
        this.selectedWindowSystems = [];
        this.loadAvailableColors(true);
    }

    onLanguageChange() {
        this.loadAvailableColors();
    }

    getItemWithImage(decorativeFillingId: number) {
        forkJoin({
            item: this.itemService.getItem(decorativeFillingId),
            image: this.itemService.getImageAsFile(decorativeFillingId),
            linkedSystems: this.getLinkedWindowSystems(decorativeFillingId),
            colors: this.allActiveColors
        }).subscribe({
            next: data => {
                this.item = data.item;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.file = data.image;
                this.selectedWindowSystems = data.linkedSystems;
                this.fillColorsList(data.colors);
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                this.focusOnElementWithId(this.getFirstInputId());
                console.debug('getGlass completed!');
            }
        });
    }

    loadAvailableColors(showDialog = false) {
        this.availableInsideColors = [];
        this.availableOutsideColors = [];
        this.availableCoreColors = [];
        this.allActiveColors.subscribe({
            next: data => {
                this.fillColorsList(data);
                if (showDialog) {
                    super.showDialogToAdd();
                }
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    private fillColorsList(data: ColorInterface[]): void {
        this.fillAvailableInsideColors(data);
        this.fillAvailableOutsideColors(data);
        this.fillAvailableCoreColors(data);
    }

    private fillAvailableInsideColors(data: ColorInterface[]): void {
        this.availableInsideColors = this.getAvailableColors(data, c => c.inside,
            this.item != undefined ? this.item.insideColors : undefined);
    }

    private fillAvailableOutsideColors(data: ColorInterface[]): void {
        this.availableOutsideColors = this.getAvailableColors(data, c => c.outside,
            this.item != undefined ? this.item.outsideColors : undefined);
    }

    private fillAvailableCoreColors(data: ColorInterface[]): void {
        this.availableCoreColors = this.getAvailableColors(data, c => c.core,
            this.item != undefined && this.item.coreColor != undefined ? [this.item.coreColor] : undefined);
    }

    private getAvailableColors(data: ColorInterface[], colorFilter: (c: Color) => boolean, addonColors: ColorInterface[]): SelectItem[] {
        return data.filter(colorFilter).map(c => {
            let existingColor: ColorInterface = undefined;
            if (addonColors != undefined) {
                existingColor = addonColors.find(ac => ac.id === c.id);
            }
            if (existingColor != undefined) {
                return {label: getColorFormattedNameWithGroup(existingColor, this.userLang), value: existingColor};
            } else {
                return {label: getColorFormattedNameWithGroup(c, this.userLang), value: c};
            }
        });
    }

    getNewItem(): DecorativeFilling {
        return new DecorativeFilling();
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        if (!this.item.names[this.userLang] || this.item.names[this.userLang].trim() === '') {
            this.validationErrors[`names[${this.userLang}]`] = `error.decorativeFillingDto.names[${this.userLang}].not_empty`;
        }

        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.decorativeFillingDto.symbol.not_empty';
        }

        this.validationErrors['thickness'] = MultiValidator.of('error.decorativeFillingDto.thickness')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 99999, false, true)
            .validate(this.item.thickness);
        if (!this.item.glazingWidths) {
            this.validationErrors['glazingWidths'] = 'error.decorativeFillingDto.glazingWidths.not_empty';
        }
        if (this.item.glazingSurface && !isFinite(this.item.glazingSurface)) {
            this.validationErrors['glazingSurface'] = 'error.decorativeFillingDto.glazingSurface.not_a_number';
        }

        if (this.item.minimalWidth == undefined) {
            this.validationErrors['minimalWidth'] = 'error.decorativeFillingDto.minimalWidth.not_null';
        } else if (this.item.minimalWidth < 0) {
            this.validationErrors['minimalWidth'] = 'error.decorativeFillingDto.minimalWidth.forbidden_value';
        }

        if (this.item.maximumWidth == undefined) {
            this.validationErrors['maximumWidth'] = 'error.decorativeFillingDto.maximumWidth.not_null';
        } else if (this.item.maximumWidth <= 0 || this.item.maximumWidth < this.item.minimalWidth) {
            this.validationErrors['maximumWidth'] = 'error.decorativeFillingDto.maximumWidth.forbidden_value';
        }

        if (this.item.minimalHeight == undefined) {
            this.validationErrors['minimalHeight'] = 'error.decorativeFillingDto.minimalHeight.not_null';
        } else if (this.item.minimalHeight < 0) {
            this.validationErrors['minimalHeight'] = 'error.decorativeFillingDto.minimalHeight.forbidden_value';
        }

        if (this.item.maximumHeight == undefined) {
            this.validationErrors['maximumHeight'] = 'error.decorativeFillingDto.maximumHeight.not_null';
        } else if (this.item.maximumHeight <= 0 || this.item.maximumHeight < this.item.minimalHeight) {
            this.validationErrors['maximumHeight'] = 'error.decorativeFillingDto.maximumHeight.forbidden_value';
        }

        if (!this.item.coreColor) {
            this.validationErrors['coreColor'] = 'error.decorativeFillingDto.coreColor.not_null';
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.colorDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            } else {
                observable = this.itemService.saveItem(this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    prepareItemForRequest(): DecorativeFilling {
        return JSON.parse(JSON.stringify(this.item));
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
