export function NumberInputConverter(fieldName: string) {
    return (target: object, key: string) => {
        Object.defineProperty(target, key, {
            get: function () {
                if (!(this[fieldName] === null || this[fieldName] === undefined)) {
                    if (isNaN(this[fieldName])) {
                        return this[fieldName];
                    } else {
                        return this[fieldName].toString().replace(".", ",");
                    }
                } else {
                    return "";
                }
            },
            set: function (newValue: any) {
                if (!(newValue === null || newValue === undefined || typeof newValue === "number")) {
                    if (newValue.trim().length > 0) {
                        let value = Number(newValue.replace(",", "."));
                        if (isNaN(value)) {
                            this[fieldName] = newValue;
                        } else {
                            this[fieldName] = Number(newValue.replace(",", "."));
                        }
                    } else {
                        this[fieldName] = null;
                    }
                }
            }
        });
    };
}
