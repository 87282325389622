import {Injectable} from '@angular/core';

export interface DocumentFragmentImpl {
    get(): DocumentFragment;

    toString(): string;
}

class TemplateBasedDocumentFragment implements DocumentFragmentImpl {

    constructor(private template: HTMLTemplateElement, sourceContent: string) {
        this.template.innerHTML = sourceContent;
    }

    get(): DocumentFragment {
        return this.template.content;
    }

    toString(): string {
        return this.template.innerHTML;
    }
}

class DivBasedDocumentFragment implements DocumentFragmentImpl {

    private readonly fragment: DocumentFragment;

    constructor(sourceContent: string) {
        this.fragment = document.createDocumentFragment();
        const div = document.createElement('div');
        div.innerHTML = sourceContent;
        while (div.firstChild != undefined) {
            this.fragment.appendChild(div.firstChild);
        }
    }

    get(): DocumentFragment {
        return this.fragment;
    }

    toString(): string {
        return new XMLSerializer().serializeToString(this.fragment);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentFragmentService {

    createDocumentFragment(content: string): DocumentFragmentImpl {
        const template = document.createElement('template');
        if ('content' in template) {
            return new TemplateBasedDocumentFragment(template, content);
        }
        return new DivBasedDocumentFragment(content);
    }
}
