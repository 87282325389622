<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.USERS.LIST' | translate}}
        </div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <!-- CLEAR HIDDEN FILTERS -->
                <app-simple-button *ngIf="filterOwnUsersEnabled" label="{{ 'USER-DETAILS.REMOVE_FILTERS' | translate }}"
                                   icon="filter_list" type="action" (onClick)="clearFilterOwnUsers()"></app-simple-button>

                <!-- SHOW OWN USERS -->
                <app-simple-button *ngIf="isShowOwnUsersFilterEnabled() && !filterOwnUsersEnabled"
                                   label="{{ 'USER-DETAILS.SHOW_OWN_USERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByOwnUsers()"></app-simple-button>

                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.USERS.LIST')"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" [displaySelectedLabel]="false"
                               [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"
                               id="usersShownColumns"></p-multiSelect>
                <app-simple-button *ngIf="permissions.canCreateUser()" buttonId="addUser"
                                   label="{{ 'NAVIGATION.ITEMS.USERS.ADD-USER' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="users" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [seqNum]="1" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 [responsive]="true" [(selection)]="selectedUser"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" [attr.id]="USERS_TABLE_ID">
        <!--Impersonate column-->
        <p-column *ngIf="isPermitted({roles:['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})"
                  header="{{ 'USER-DETAILS.FORM.ACTIONS_HEADER' | translate}}">
            <ng-template let-user="rowData" pTemplate="body">
                <div class="table-row-action-buttons min-width80">
                    <app-simple-button *ngIf="isImpersonationAllowed(user)"
                                       label="{{ 'USER-DETAILS.FORM.IMPERSONATE_BUTTON' | translate }}"
                                       [inlineLabel]="false" icon="fingerprint" type="main-action"
                                       (onClick)="impersonate(user.login)"></app-simple-button>
                </div>
            </ng-template>
        </p-column>
        <ng-container *ngFor="let column of columns">
            <p-column *ngIf="column.field == 'active'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="false" [filter]="column.filter"
                      [filterValues]="column.filterValues | selectItemTranslate"
                      [defaultFilterValue]="defaultActiveFilter">
              <ng-template let-user="rowData" pTemplate="body">
                  {{(user.active ? 'USER-DETAILS.ACTIVE' : 'USER-DETAILS.INACTIVE') | translate}}
              </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'id'" [style]="{'width':'5%'}" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue"></p-column>
            <p-column *ngIf="column.field == 'roleName'" [field]="column.field"
                      [header]="column.header | translate"
                      [sortable]="false" [filter]="column.filter"
                      [filterValues]="roleSelectItems | async"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-item="rowData" pTemplate="body">
                  {{ getRoleTranslationKey(item.roleName) | translate }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'interfaceLanguage'"
                      [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                      [filter]="column.filter" [defaultFilterValue]="column.defaultFilterValue"
                      filterMatchMode="contains">
                <ng-template let-col let-user="rowData" pTemplate="body">
                    {{ user[col.field] | translate }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'subsystemIds'" [field]="column.field" sortField="subsystem.name"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">

                <ng-template let-user="rowData" pTemplate="body">
                    {{ getSubsystemsName(user.subsystemId) }}
                </ng-template>
                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="subsystemsSelect"
                                       defaultLabel="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}" appendTo="body"
                                       [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                       (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                    </div>
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'additionalRoles'" [field]="column.field" sortField="additionalRoles"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">

                <ng-template let-user="rowData" pTemplate="body">
                    <div *ngFor="let roleName of getAdditionalRoleNames(user) | async">
                        {{ roleName }}
                    </div>
                </ng-template>
                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="additionalRoleSelectItems | async"
                                       defaultLabel="{{ 'USER-DETAILS.FORM.ADDITIONAL_ROLES' | translate }}" appendTo="body"
                                       [displaySelectedLabel]="false" [ngModel]="selectedAdditionalRoles"
                                       (ngModelChange)="handleAdditionalRolesFilterChange($event)"></p-multiSelect>
                    </div>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field == 'accountExpirationDate'" field="accountExpirationDate"
                      [header]="column.header | translate" [sortable]="true"
                      [defaultFilterValue]="column.defaultFilterValue" styleClass="datatable-date-range-column">

                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
                </ng-template>

                <ng-template let-item="rowData" pTemplate="body">
                    {{ item.accountExpirationDate | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>

            <p-column
                    *ngIf="column.field != 'active' && column.field != 'roleName' && column.field != 'interfaceLanguage' && column.field != 'id' && column.field != 'subsystemIds' && column.field != 'accountExpirationDate' && column.field != 'additionalRoles'"
                    [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                    [filter]="column.filter" filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">
            </p-column>
        </ng-container>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-edit-user *ngIf="displayDialog && editedUser" [(file)]="file" [user]="editedUser" [existingSubsystems]="subsystemsSelect"
               (onNoSuitableUsers)="showNoSuitableSupplierError()"
               (onShowSubstituteUsersDialog)="showSelectNewUserDialog($event)"
               [existingRoles]="roleSelectItems | async" (onSave)="handleUserUpdated()" (onCancel)="handleCancel()"></app-edit-user>

<!-- ADD SUBSTITUTE MERCHANT DIALOG -->
<app-substitute-user-dialog  *ngIf="displaySubstitueUserDialog"
                                [user]="editedUser"
                                [substituteUsers]="substituteUsers"
                                (onHide)="handleCancel()"
                                (onSuccess)="onUpdateUserSuccess()"
                                (onSaveUserError)="showFailedToSaveUserMsg()"
                                (onMoveClientsError)="showFailedToMoveClientsMsg()">
</app-substitute-user-dialog>
