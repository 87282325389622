import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {ComponentWithUserConfigAndPaginator} from '../../../../../common/crud-common/paginable.component';
import {DatatableHelper} from '../../../../../common/DatatableHelper';
import {DateRangeKind} from '../../../../../common/date-range-filter';
import {ValidationErrors} from "../../../../../common/validation-errors";
import {TristateCheckboxState} from '../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {OrderDeliveryData} from "./order-delivery-data";

@Component({
    selector: 'app-palette-production-orders',
    templateUrl: './palette-production-orders.component.html',
    styleUrls: ['./palette-production-orders.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteProductionOrdersComponent extends ComponentWithUserConfigAndPaginator implements OnChanges {

    @Input()
    paletteId: number;

    @Input()
    deliveryListId: number;

    @Input()
    deliveryDataAll: OrderDeliveryData[];

    @Input()
    validationErrors: ValidationErrors = {};

    deliveryData: OrderDeliveryData[];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    @ViewChild(DataTable, {static: true}) dataTable: DataTable;
    private lastLoadEvent: LazyLoadEvent;
    selectedItems: OrderDeliveryData[] = [];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'PaletteProductionOrdersComponent', false);
        this.deliveryData = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        let paletteChanges = changes['paletteId'];
        let deliveryDataChanges = changes['deliveryDataAll'];
        if (paletteChanges != null && paletteChanges.currentValue != null) {
            this.loadItemsLazy(this.lastLoadEvent);
            this.selectedItems = this.deliveryDataAll.filter(item => item.paletteId === this.paletteId);
            this.refreshAllSelectedFlag();
        } else if (deliveryDataChanges != null && deliveryDataChanges.currentValue != null) {
            this.loadItemsLazy(this.lastLoadEvent);
            this.selectedItems = this.deliveryDataAll.filter(item => item.paletteId === this.paletteId);
            this.refreshAllSelectedFlag();
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.lastLoadEvent = event;
        this.deliveryData = (this.deliveryDataAll || []).filter(item => item.paletteId === this.paletteId || item.paletteId == null);
        this.filterDeliveryData(event.filters);
        this.sortDeliveryData(event.sortField, event.sortOrder);
        this.refreshAllSelectedFlag();
        this.hideDataLoadingIndicator();
        this.changeDetector.markForCheck();
    }

    private filterDeliveryData(filters: { [p: string]: FilterMetadata }) {
        for (let filterKey in filters) {
            if (filterKey === 'lastStatusChange') {
                let filterValue = filters[filterKey].value;
                let filterFn = (item: OrderDeliveryData) => {};
                if (filterValue.kind === DateRangeKind.INPUT_SINGLE) {
                    if (filterValue.from == undefined) {
                        return true;
                    }
                    let from = new Date(filterValue.from);
                    filterFn = (item: OrderDeliveryData) => {
                        let lastStatusChange = new Date(item.lastStatusChange);
                        return lastStatusChange.getFullYear() === from.getFullYear() &&
                            lastStatusChange.getMonth() === from.getMonth() &&
                            lastStatusChange.getDate() === from.getDate();
                    };
                } else {
                    let now = new Date();
                    let {from, to} = DatatableHelper.getRangeForFilter(now, filterValue);
                    filterFn = (item: OrderDeliveryData) => {
                        let lastStatusChange = new Date(item.lastStatusChange);
                        let toValidate = moment(lastStatusChange);
                        return (!from || toValidate.isSameOrAfter(from)) &&
                        (!to || toValidate.isSameOrBefore(to));
                    };
                }
                this.deliveryData = this.deliveryData.filter(filterFn);
            } else {
                let filterValue = filters[filterKey].value as string;
                this.deliveryData = this.deliveryData.filter(item => ((item[filterKey] as string) || '').toUpperCase().includes(filterValue.toUpperCase()));
            }
        }
    }

    private sortDeliveryData(sortField: string, sortOrder: number) {
        this.deliveryData.sort((a, b) => {
            let aElement = a[sortField];
            let bElement = b[sortField];
            if (typeof (aElement || bElement) === 'string') {
                return (aElement || '').localeCompare((bElement || ''), undefined /* Ignore language */, {sensitivity: 'accent'});
            }
            return aElement - bElement;
        });
        if (sortOrder === -1) {
            this.deliveryData.reverse();
        }
    }

    getSelectedProductionOrderIds(): number[] {
        return this.selectedItems.map(item => item.productionOrderId);
    }

    isSelectedItem(item: OrderDeliveryData): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: OrderDeliveryData): void {
        let index = this.selectedItems.indexOf(item);

        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }
        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0 || this.deliveryData.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.deliveryData.every(item => this.selectedItems.includes(item))) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.deliveryData.some(item => this.selectedItems.includes(item))) {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        } else {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        }
    }

    selectAllChange(state: TristateCheckboxState) {
        this.allSelectedState = state;
        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.deliveryData.filter(item => !this.selectedItems.includes(item)));
        } else if (this.allSelectedState === TristateCheckboxState.UNCHECKED) {
            this.selectedItems = this.selectedItems.filter(item => !this.deliveryData.includes(item));
        }
        this.changeDetector.markForCheck();
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    doOnRowSelect(event) {
        // do nothing
    }

    onRowSelect() {
        // do nothing
    }

    showDialogToAdd() {
        // do nothing
    }

    submit() {
        // do nothing
    }
}
