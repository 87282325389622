import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from "../../../supportedLanguages";
import {ComplaintType} from "./complaint-type";

@JsonDeserializable
export class ComplaintPosition implements CrudItem {
    id: number;
    quantity: number;
    description: string;
    translatedDescription: string;
    movieUrl: string;
    photoUrl: string;
    movieFile: string; // URL to file hosted on amazon
    photoFile: string; // URL to file hosted on amazon
    offerPositionId: number;
    name: MultilanguageField;
    printOrder: number;
    complaintTypes: ComplaintType[];
    complaintId: number;

    static fromJSON(jsonObject: object): ComplaintPosition {
        const complaintPosition = new ComplaintPosition();
        complaintPosition.id = jsonObject['id'];
        complaintPosition.quantity = jsonObject['quantity'];
        complaintPosition.description = jsonObject['description'];
        complaintPosition.translatedDescription = jsonObject['translatedDescription'];
        complaintPosition.movieUrl = jsonObject['movieUrl'];
        complaintPosition.photoUrl = jsonObject['photoUrl'];
        complaintPosition.movieFile = jsonObject['movieFile'];
        complaintPosition.photoFile = jsonObject['photoFile'];
        complaintPosition.offerPositionId = jsonObject['offerPositionId'];
        if (jsonObject['name'] != undefined) {
            complaintPosition.name = MultilanguageField.fromJSON(jsonObject['name']);
        }
        complaintPosition.printOrder = jsonObject['printOrder'];
        if (jsonObject['complaintTypes'] != undefined) {
            complaintPosition.complaintTypes = (jsonObject['complaintTypes'] as string[]).map(ct => ComplaintType[ct]);
        }
        complaintPosition.complaintId = jsonObject['complaintId'];
        return complaintPosition;
    }
}
