import {CompositionType} from "./CompositionType";

export class ProfilesComposition {
    left: CompositionType;
    right: CompositionType;
    top: CompositionType;
    bottom: CompositionType;

    constructor() {
        this.left = CompositionType.REGULAR;
        this.right = CompositionType.REGULAR;
        this.top = CompositionType.REGULAR;
        this.bottom = CompositionType.REGULAR;
    }
}
