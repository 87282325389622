import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {ColorType} from "../../../ColorType";
import {GateSystemDefaults} from '../../settings/gate-system-defaults/gate-system-defaults';
import {AddonCategoryGroup} from '../../window-system/addon-category-group/addon-category-group';
import {AddonCategoryGroupService} from '../../window-system/addon-category-group/addon-category-group.service';
import {Color} from '../../window-system/color/color';
import {ColorService} from '../../window-system/color/color.service';
import {
    GateDesignerCatalogDependentOption
} from '../../window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option';
import {
    GateDesignerCatalogDependentOptionService
} from '../../window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option.service';
import {GatePanelType} from '../../window-system/gate-panel-type/gate-panel-type';
import {GatePanelTypeService} from '../../window-system/gate-panel-type/gate-panel-type.service';
import {GateSystem} from '../../window-system/gate-system/gate-system';
import {GateSystemService} from '../../window-system/gate-system/gate-system.service';
import {GateWall} from "../../window-system/gate-wall/gate-wall";
import {GateWallService} from "../../window-system/gate-wall/gate-wall.service";
import {RailSystem} from '../../window-system/rail-system/rail-system';
import {RailSystemService} from '../../window-system/rail-system/rail-system.service';
import {PositionEditorFieldContentProvider} from '../window-editor/position-editor-field-content-provider';
import {GateData} from './gate-data';
import {GateEditorField} from './gate-editor-field';
import {SizeUnitsConverter} from "../../../../window-designer/utils/SizeUnitsConverter";

export interface CatalogLoadResult {
    walls: GateWall[];
    railSystems: RailSystem[];
    colors: Color[];
    panelTypes: GatePanelType[];
    addonCategoryGroups: AddonCategoryGroup[];
    fieldDependencies: GateDesignerCatalogDependentOption[];
}

export type CatalogLoadObservables = { [K in keyof CatalogLoadResult]: Observable<CatalogLoadResult[K]>; };

type GateDataKey = 'railSystemId' | 'coreColorId' | 'externalColorId' | 'internalColorId' | 'gatePanelTypeId' | 'wall1' | 'wall2';

@Injectable()
export class GateEditorFieldContentProvider extends PositionEditorFieldContentProvider {

    constructor(private readonly gateSystemService: GateSystemService,
                private readonly gateWallService: GateWallService,
                private readonly railSystemService: RailSystemService,
                private readonly colorService: ColorService,
                private readonly gatePanelTypeService: GatePanelTypeService,
                private readonly addonCategoryGroupService: AddonCategoryGroupService,
                private readonly dependentOptionService: GateDesignerCatalogDependentOptionService,
                private readonly translate: TranslateService) {
        super(Object.keys(GateEditorField));
    }

    loadGatesForEditor(selectedGateId?: number): Observable<GateSystem[]> {
        return this.gateSystemService.getActiveGatesWithImages(selectedGateId);
    }

    getCatalogDataSource(gateSystemId: number, data: GateSystemDefaults): CatalogLoadObservables {
        if (gateSystemId == undefined) {
            return {
                walls: of<GateWall[]>([]),
                railSystems: of<RailSystem[]>([]),
                colors: of<Color[]>([]),
                panelTypes: of<GatePanelType[]>([]),
                addonCategoryGroups: of<AddonCategoryGroup[]>([]),
                fieldDependencies: of<GateDesignerCatalogDependentOption[]>([]),
            };
        }
        return {
            walls: this.gateWallService.getGateWallsForGateSystem(gateSystemId, this.extractSelectedId(data, 'wall1', 'wall2')),
            railSystems: this.railSystemService.getRailSystemsForGateSystem(gateSystemId, this.extractSelectedId(data, 'railSystemId')),
            colors: this.colorService.getColorsForGateSystem(gateSystemId,
                this.extractSelectedId(data, 'coreColorId', 'externalColorId', 'internalColorId')),
            panelTypes: this.gatePanelTypeService.getPanelTypesForGateSystem(gateSystemId, this.extractSelectedId(data, 'gatePanelTypeId')),
            addonCategoryGroups: this.addonCategoryGroupService.getGroupsForGateDeep(gateSystemId, this.extractSelectedAddons(data)),
            fieldDependencies: this.dependentOptionService.getItems(undefined, undefined, {
                    gateSystemId: {value: gateSystemId},
                    activeSet: {value: 'true'}
                },
                undefined, undefined).pipe(map(listing => listing.data)),
        };
    }

    storeFieldDependencies(dependencies: GateDesignerCatalogDependentOption[]) {
        super.storeFieldDependencies(dependencies);

        const addDependentField = (requiredInputId: GateEditorField, dependentInputId: GateEditorField) => {
            let dependentFields = this.fieldsThatDependOnField.get(requiredInputId);
            if (dependentFields == undefined) {
                dependentFields = new Set<GateEditorField>();
                this.fieldsThatDependOnField.set(requiredInputId, dependentFields);
            }
            dependentFields.add(dependentInputId);
        };

        // link color fields to self for RAL/NCS manipulations
        addDependentField(GateEditorField.EXTERNAL_COLOR, GateEditorField.EXTERNAL_COLOR);
        addDependentField(GateEditorField.INTERNAL_COLOR, GateEditorField.INTERNAL_COLOR);
    }

    initCatalog(catalogData: CatalogLoadResult): void {
        this.storeGateWalls(catalogData.walls);
        this.storeRailSystems(catalogData.railSystems);
        this.storeColors(catalogData.colors);
        this.storeGatePanelTypes(catalogData.panelTypes);
        this.storeAddons(catalogData.addonCategoryGroups);
        this.storeFieldDependencies(catalogData.fieldDependencies);
    }

    storeRailSystems(railSystems: RailSystem[]): void {
        this.setItems(GateEditorField.RAIL_SYSTEM, railSystems.map(railSystem => this.mapSelectItemWithName(railSystem)), []);
    }

    storeGateWalls(walls: GateWall[]): void {
        this.setItems(GateEditorField.GATE_WALL, walls.map(wall => this.mapSelectItemWithName(wall)), []);
    }

    storeColors(colors: Color[]): void {
        const coreColors: Color[] = colors.filter(color => color.type === ColorType.GATE_CORE);
        const notCoreColors: Color[] = colors.filter(color => color.type !== ColorType.GATE_CORE);
        this.setItems(GateEditorField.CORE_COLOR, coreColors.map(color => this.mapSelectItemWithNames(color)), []);
        this.setItems(GateEditorField.EXTERNAL_COLOR, notCoreColors.map(color => this.mapSelectItemWithNames(color)), []);
        this.setItems(GateEditorField.INTERNAL_COLOR, notCoreColors.map(color => this.mapSelectItemWithNames(color)), []);
    }

    storeGatePanelTypes(panelTypes: GatePanelType[]): void {
        this.setItems(GateEditorField.GATE_PANEL_TYPE, panelTypes.map(gatePanelType => this.mapSelectItemWithName(gatePanelType)), []);
    }

    storeAddons(addonCategoryGroups: AddonCategoryGroup[]): void {
        // clear addon items
        for (let key of this.items.keys()) {
            const isPredefinedField = Object.keys(GateEditorField).includes(key);
            if (isPredefinedField) {
                continue;
            }
            this.setItems(key, [], []);
        }

        const addonCategorySymbols = _.chain(addonCategoryGroups)
            .map(group => group.categories)
            .flatten()
            .map(category => category.symbol)
            .value();

        this.initializeProvider(addonCategorySymbols);

        for (let addonCategoryGroup of addonCategoryGroups) {
            for (let category of addonCategoryGroup.categories) {
                this.setItems(category.symbol, category.addons.map(addon => this.mapSelectItemWithName(addon)), []);
            }
        }
    }

    storeSelectedValues(data: Partial<GateData>): void {
        this.notifyFieldChanged(GateEditorField.GATE_SYSTEM, data.gateSystemId);
        this.notifyFieldChanged(GateEditorField.WIDTH, data.width);
        this.notifyFieldChanged(GateEditorField.HEIGHT, data.height);
        this.notifyFieldChanged(GateEditorField.LINTEL_HEIGHT, data.lintelHeight);
        this.notifyFieldChanged(GateEditorField.RAIL_SYSTEM, data.railSystemId);
        this.notifyFieldChanged(GateEditorField.EXTERNAL_COLOR, data.externalColorId);
        this.notifyFieldChanged(GateEditorField.INTERNAL_COLOR, data.internalColorId);
        this.notifyFieldChanged(GateEditorField.GATE_PANEL_TYPE, data.gatePanelTypeId);
        this.notifyFieldChanged(GateEditorField.CONTROL, data.control);
        this.notifyFieldChanged(GateEditorField.WIDTH_GREATER_THAN, data.width);
        this.notifyFieldChanged(GateEditorField.WIDTH_LESS_THAN, data.width);
        this.notifyFieldChanged(GateEditorField.HEIGHT_GREATER_THAN, data.height);
        this.notifyFieldChanged(GateEditorField.HEIGHT_LESS_THAN, data.height);
        this.notifyFieldChanged(GateEditorField.AREA_GREATER_THAN, SizeUnitsConverter.mmSidesToMeterSquared(data.width, data.height));
        this.notifyFieldChanged(GateEditorField.AREA_LESS_THAN, SizeUnitsConverter.mmSidesToMeterSquared(data.width, data.height));
        this.notifyFieldChanged(GateEditorField.LINTEL_HEIGHT_GREATER_THAN, data.lintelHeight);
        this.notifyFieldChanged(GateEditorField.LINTEL_HEIGHT_LESS_THAN, data.lintelHeight);
        Object.entries(data.sidebarAddons || {}).forEach(entry => {
            this.notifyFieldChanged(entry[0], entry[1]);
        });
    }

    private mapSelectItemWithName(item: { id: number, name: MultilanguageFieldInterface, active: boolean, singlePositionAddon?: boolean }) {
        return ({
            label: item.name[this.translate.currentLang],
            value: item.id,
            available: item.active,
            singlePositionItem: item.singlePositionAddon
        });
    }

    private mapSelectItemWithNames(item: { id: number, names: MultilanguageFieldInterface, active: boolean }) {
        return {
            label: item.names[this.translate.currentLang],
            value: item.id,
            available: item.active
        };
    }

    private extractSelectedId(data: GateSystemDefaults, ...key: GateDataKey[]): number[] {
        if (data == undefined) {
            return [];
        }
        return key.map(k => data[k]).filter(v => v != undefined);
    }

    private extractSelectedAddons(data: GateSystemDefaults): number[] {
        if (data == undefined) {
            return [];
        }
        return _.values(data.sidebarAddons);
    }

    protected requiredValueIsCorrect(currentValue: string, requiredField: GateEditorField, requiredValue: string): boolean {
        switch (requiredField) {
            case GateEditorField.WIDTH_GREATER_THAN:
            case GateEditorField.HEIGHT_GREATER_THAN:
            case GateEditorField.AREA_GREATER_THAN:
            case GateEditorField.LINTEL_HEIGHT_GREATER_THAN:
                return +currentValue > +requiredValue;
            case GateEditorField.WIDTH_LESS_THAN:
            case GateEditorField.HEIGHT_LESS_THAN:
            case GateEditorField.AREA_LESS_THAN:
            case GateEditorField.LINTEL_HEIGHT_LESS_THAN:
                return +currentValue < +requiredValue;
            default:
                break;
        }
        return super.requiredValueIsCorrect(currentValue, requiredField, requiredValue);
    }

}
