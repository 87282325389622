import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {ValidationErrors} from '../../../../common/validation-errors';
import {MultilanguageField, SupportedLanguages} from '../../../../supportedLanguages';
import {WindowSystemDefinition} from '../window-system-definition';

@Component({
    selector: 'app-window-system-definition-glamour-print-info-form',
    templateUrl: './window-system-definition-glamour-print-info-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionGlamourPrintInfoFormComponent {

    readonly supportedLanguages = SupportedLanguages.languages;

    @Input()
    roofSystem = false;

    @Input()
    entranceSystem = false;

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    glamourPrintImageFile: File;

    @Output()
    readonly glamourPrintImageFileChange = new EventEmitter<File>();

    @Input()
    glamourPrintIconFile: Partial<Record<keyof MultilanguageFieldInterface, { file: File }>>;

    @Input()
    disabled: boolean;

    @Output()
    readonly glamourPrintIconFileChange = new EventEmitter<{ language: keyof MultilanguageFieldInterface, file: File }>();

    glamourPrintImageFileChanged(file: File): void {
        this.glamourPrintImageFileChange.emit(file);
    }

    glamourPrintIconFileChanged(language: keyof MultilanguageFieldInterface, file: File): void {
        this.glamourPrintIconFileChange.emit({language, file});
    }

    hasGlamourPrintIconValidationErrors() {
        return Object.keys(this.validationErrors)
            .filter(key => key.startsWith('glamourPrintIconFile.'))
            .some(key => this.validationErrors[key] != undefined);
    }

    handleGlamourDescription1Changed(description: string, lang: keyof MultilanguageFieldInterface): void {
        if (this.windowSystem.glamourRoofDescription1 == null) {
            this.windowSystem.glamourRoofDescription1 = new MultilanguageField();
        }
        this.windowSystem.glamourRoofDescription1[lang] = description;
    }

    handleGlamourDescription2Changed(description: string, lang: keyof MultilanguageFieldInterface): void {
        if (this.windowSystem.glamourRoofDescription2 == null) {
            this.windowSystem.glamourRoofDescription2 = new MultilanguageField();
        }
        this.windowSystem.glamourRoofDescription2[lang] = description;
    }

    handleGlamouDisplayUwChanged(description: string, lang: keyof MultilanguageFieldInterface): void {
        if (this.windowSystem.glamourDisplayUwDescription == null) {
            this.windowSystem.glamourDisplayUwDescription = new MultilanguageField();
        }
        this.windowSystem.glamourDisplayUwDescription[lang] = description;
    }
}
