import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from 'rxjs';
import {Permissions} from "../../auth/permission.service";
import {SecondLevelMenu} from "../../common/second-level-menu/SecondLevelMenu";
import {SidenavController} from '../../sidenav-controller';
import {SubsystemService} from '../subsystem/subsystem.service';
import {CatalogPropertyTarget} from "./CatalogPropertyTarget";

type CatalogCreatorMenuOption = 'optionList' | CatalogPropertyTarget;

@Component({
    selector: 'app-catalog-creator',
    templateUrl: './catalog-creator.component.html',
    styleUrls: ['../../second-level-menu.css'],
    providers: [SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCreatorComponent implements OnInit, OnDestroy, SecondLevelMenu<CatalogCreatorMenuOption> {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItemsTranslateSubscription: Subscription;
    targetCategory: CatalogCreatorMenuOption;
    categoryTargets: CatalogPropertyTarget[];

    constructor(public permissions: Permissions,
                private sidenavController: SidenavController,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private changeDetector: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.initialize();

        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.changeDetector.markForCheck();
        });
    }

    private initialize(): void {
        this.prepareSideNavItems();
        this.activatedRoute.paramMap.subscribe(paramMap => {
            this.switchToSubComponent((paramMap.get('view') as CatalogCreatorMenuOption) || 'optionList');
        });
        this.initTranslationSubscription();
        this.changeDetector.markForCheck();
    }

    ngOnDestroy(): void {
        if (this.showMenuSubscription != undefined) {
            this.showMenuSubscription.unsubscribe();
        }
        if (this.sideNavItemsTranslateSubscription != undefined) {
            this.sideNavItemsTranslateSubscription.unsubscribe();
        }
    }

    private prepareSideNavItems(): void {
        this.categoryTargets = Object.values(CatalogPropertyTarget);
        this.translate.get(this.categoryTargets.map(category => 'NAVIGATION.ITEMS.CATALOG_CREATOR.VIEWS.' + category))
            .subscribe(translations => {
                this.categoryTargets.sort((a, b) => {
                    const aName = translations[this.getTargetName(a)];
                    const bName = translations[this.getTargetName(b)];
                    return aName.localeCompare(bName);
                });
            });
    }

    initTranslationSubscription() {
        this.sideNavItemsTranslateSubscription = this.translate.onLangChange.subscribe(event => {
                this.categoryTargets.sort((a, b) => {
                    const aName = this.translate.getParsedResult(event.translations, this.getTargetName(a));
                    const bName = this.translate.getParsedResult(event.translations, this.getTargetName(b));
                    return aName.localeCompare(bName);
                });
            }
        );
    }

    getTargetName(target: CatalogPropertyTarget): string {
        return 'NAVIGATION.ITEMS.CATALOG_CREATOR.VIEWS.' + target;
    }

    switchToSubComponent(target: CatalogCreatorMenuOption): void {
        this.targetCategory = target;
    }

    navigateToSubComponent(item: CatalogCreatorMenuOption): void {
        this.router.navigate(['.', {view: item}], {relativeTo: this.activatedRoute});
    }

    isActiveSubComponent(item: CatalogCreatorMenuOption): boolean {
        return this.targetCategory === item;
    }
}
