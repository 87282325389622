import {ApplicationPrivilege} from '../auth/application-privilege';
import {PositionTarget} from './offer/AbstractPosition';

export class AccessData {
    public static windowDesignerURLSuffix = "windowdesigner";
    public static roofWindowDesignerURLSuffix = "roofWindowDesigner";
    public static gateDesignerURLSuffix = "gateDesigner";
    public static configDesignerURLSuffix = "configDesigner";
    public static conjunctionDesignerURLSuffix = "conjunctionDesigner";
    public static offerPositionURLSuffix = "position";
    public static complaintPositionURLSuffix = "complaintPosition";
    public static productionOrderPositionURLSuffix = "productionOrderPosition";
    public static deliveryListURLSuffix = "deliveryList";
    public static notesURLSuffix = "notes";

    public static readonly pathSuffix = {
        login: 'login',
        features: 'features',
        userGroups: 'user-groups',
        user: 'user',
        createUser: 'create-user',
        client: 'client',
        createClient: 'create-client',
        clientGroups: 'client-groups',
        createClientGroup: 'create-client-group',
        subsystemGroup: 'subsystem-group',
        subsystem: 'subsystem',
        subsystemNotes: (':subsystemId/' + AccessData.notesURLSuffix),
        createSubsystem: 'create-subsystem',
        supplier: 'supplier',
        createSupplier: 'create-supplier',
        windowSystem: 'window-system',
        catalogCreator: 'catalog-creator',
        settings: 'settings',
        translations: 'translations',
        offer: 'offer',
        offerPosition: (':offerId/' + AccessData.offerPositionURLSuffix),
        productionOrderPosition: (':productionOrderId/' + AccessData.productionOrderPositionURLSuffix),
        windowDesigner: (':positionId/' + AccessData.windowDesignerURLSuffix),
        roofWindowDesigner: (':positionId/' + AccessData.roofWindowDesignerURLSuffix),
        gateDesigner: (':positionId/' + AccessData.gateDesignerURLSuffix),
        configDesigner: (':positionId/' + AccessData.configDesignerURLSuffix),
        conjunctionDesigner: (':conjunctionId/' + AccessData.conjunctionDesignerURLSuffix),
        externalOfferEditDone: 'external-edit-done',
        createOffer: 'create-offer',
        priceLists: 'price-lists',
        adminPanel: 'admin-panel',
        shippingSimulationDetails: 'shipping-simulation-details',
        createComplaint: "create-complaint",
        complaint: "complaint",
        complaintPosition: (':complaintId/' + AccessData.complaintPositionURLSuffix),
        deliveryListWizard: 'delivery-list-wizard',
        deliveryList: (':id/' + AccessData.deliveryListURLSuffix),
        paletteWizard: 'palette-wizard'
    };

    public static readonly path = {
        login: () => {
            return AccessData.joinPath([AccessData.pathSuffix.login]);
        },
        features: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features]);
        },
        userGroups: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.userGroups]);
        },
        user: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.user]);
        },
        client: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.client]);
        },
        createClient: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.createClient]);
        },
        clientGroups: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.clientGroups]);
        },
        subsystemGroup: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.subsystemGroup]);
        },
        subsystem: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.subsystem]);
        },
        subsystemNotes: (id: string) => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.subsystem, id, AccessData.notesURLSuffix]);
        },
        supplier: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.supplier]);
        },
        createSupplier: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.createSupplier]);
        },
        windowSystem: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.windowSystem]);
        },
        catalogCreator: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.catalogCreator]);
        },
        settings: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.settings]);
        },
        translations: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.translations]);
        },
        offer: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.offer]);
        },
        offerPosition: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.offerPosition]);
        },
        createOffer: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.createOffer]);
        },
        priceLists: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.priceLists]);
        },
        adminPanel: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.adminPanel]);
        },
        shippingSimulationDetails: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.shippingSimulationDetails]);
        },
        createComplaint: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.createComplaint]);
        },
        complaint: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.complaint]);
        },
        complaintPosition: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.complaintPosition]);
        },
        deliveryListWizard: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.deliveryListWizard]);
        },
        deliveryList: (id: string) => {
            return AccessData.joinPath([AccessData.pathSuffix.features, id, AccessData.deliveryListURLSuffix]);
        },
        paletteWizard: () => {
            return AccessData.joinPath([AccessData.pathSuffix.features, AccessData.pathSuffix.paletteWizard]);
        },
        productionOrderPosition: (id: string) => {
            return AccessData.joinPath([AccessData.pathSuffix.features, id, AccessData.productionOrderPositionURLSuffix]);
        }
    };

    public static readonly roles: { [group: string]: { roles: ApplicationPrivilege[], target?: PositionTarget } } = {
        features: {roles: []},
        userGroups: {
            roles: ['ROLE_CREATE_USERGROUP', 'ROLE_EDIT_USERGROUP', 'ROLE_DELETE_USERGROUP',
                'ROLE_READ_USERGROUP']
        },
        user: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_CREATE_USER', 'ROLE_EDIT_USER', 'ROLE_DELETE_USER',
                'ROLE_READ_USER']
        },
        client: {
            roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA', 'ROLE_CREATE_CLIENT', 'ROLE_EDIT_CLIENT', 'ROLE_DELETE_CLIENT',
                'ROLE_READ_CLIENT']
        },
        clientGroups: {
            roles: ['ROLE_CREATE_CLIENTGROUP', 'ROLE_EDIT_CLIENTGROUP', 'ROLE_DELETE_CLIENTGROUP',
                'ROLE_READ_CLIENTGROUP']
        },
        subsystemGroup: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        },
        subsystem: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        },
        subsystemNotes: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        },
        supplier: {
            roles: ['ROLE_EDIT_SUPPLIER']
        },
        windowSystem: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPERATOR', 'ROLE_CREATE_CATALOG_ITEM', 'ROLE_DELETE_CATALOG_ITEM']
        },
        catalogCreator: {
            roles: ['ROLE_KOORDYNATOR']
        },
        settings: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_EDIT_NEWS', 'ROLE_EDIT_USER_SELF']
        },
        translations: {
            roles: ['ROLE_VIEW_TRANSLATION_EDIT_UI']
        },
        createOffer: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']
        },
        offer: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']
        },
        offerPosition: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA'],
            target: PositionTarget.OFFER
        },
        productionOrderPosition: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_READ_ROLE', 'ROLE_EDIT_ROLE'],
            target: PositionTarget.PRODUCTION_ORDER
        },
        windowDesigner: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA']
        },
        roofWindowDesigner: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA']
        },
        gateDesigner: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA']
        },
        configDesigner: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA']
        },
        conjunctionDesigner: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_SPRZEDAWCA']
        },
        priceLists: {
            roles: ['ROLE_READ_PRICELIST', 'ROLE_CREATE_PRICELIST', 'ROLE_EDIT_PRICELIST', 'ROLE_DELETE_PRICELIST',
                'ROLE_READ_DEALER_DISCOUNT', 'ROLE_EDIT_DEALER_DISCOUNT', 'ROLE_EDIT_ROT_SETTINGS']
        },
        adminPanel: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        },
        complaint: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']
        },
        complaintPosition: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']
        },
        deliveryListWizard: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        },
        paletteWizard: {
            roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']
        }
    };

    private static joinPath(parts: string[]) {
        let joined = '';
        parts.forEach(p => joined += ('/' + p));
        return joined;
    }
}
