<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(EntranceModelField.NAME)" [disabled]="fieldUsage.disabled(EntranceModelField.NAME)"
                    label="{{ 'ENTRANCE_MODELS.FORM.NAME' | translate }}"
                    validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="100"
                    inputId="name"
                    [required]="false" [field]="item.name"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(EntranceModelField.SHORTCUT)" [disabled]="fieldUsage.disabled(EntranceModelField.SHORTCUT)"
                    label="{{ 'ENTRANCE_MODELS.FORM.SHORTCUT' | translate }}"
                    validationKeyPrefix="shortcut" [validationErrors]="validationErrors" [maxlength]="100"
                    inputId="shortcut"
                    [required]="false" [field]="item.shortcut"></app-translation-fields>

    <div class="new-form-row">
        <app-input-text *ngIf="fieldUsage.show(EntranceModelField.SYMBOL)"
                        inputId="symbol" label="{{ 'ENTRANCE_MODELS.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(EntranceModelField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(EntranceModelField.GLAZING_PACKAGE)">
        <app-select inputId="entranceSystemGlazingPackage" label="{{ 'ENTRANCE_MODELS.FORM.GLAZING_PACKAGE' | translate }}"
                    [disabled]="fieldUsage.disabled(EntranceModelField.GLAZING_PACKAGE)"
                    [(validationMessageKey)]="validationErrors['glazingPackageId']"
                    [options]="glazingPackages" [allowSelectingNone]="true"
                    [(ngModel)]="item.glazingPackageId"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(EntranceModelField.UW)">
        <app-input-number inputId="uw" label="{{ 'WINDOW-SYSTEM-ENTRANCE_MODELS.FORM.UW' | translate }}"
                          [disabled]="fieldUsage.disabled(EntranceModelField.UW)"
                          [(validationMessageKey)]="validationErrors['uw']" [required]="true"
                          [(ngModel)]="item.uw" [min]="0"
                          [max]="99"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(EntranceModelField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'ENTRANCE_MODELS.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(EntranceModelField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(EntranceModelField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'ENTRANCE_MODELS.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(EntranceModelField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab *ngIf="fieldUsage.show(EntranceModelField.IMAGE_SECTION)"
                            header="{{ 'ENTRANCE_MODELS.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 (ngModelChange)="onFileChange($event)"
                                 [disabled]="fieldUsage.disabled(EntranceModelField.IMAGE_SECTION)"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 [addButtonLabel]="'ENTRANCE_MODELS.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ENTRANCE_MODELS.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ENTRANCE_MODELS.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
            <p-accordionTab *ngIf="fieldUsage.show(EntranceModelField.IMAGE2_SECTION)"
                            header="{{ 'ENTRANCE_MODELS.FORM.IMAGE2_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image2']}">
                <app-file-upload inputId="image2" [ngModel]="file2"
                                 (ngModelChange)="onFile2Change($event)"
                                 [disabled]="fieldUsage.disabled(EntranceModelField.IMAGE2_SECTION)"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 [addButtonLabel]="'ENTRANCE_MODELS.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ENTRANCE_MODELS.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ENTRANCE_MODELS.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image2']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
