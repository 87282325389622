import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from "rxjs";
import {Permissions} from "../../../auth/permission.service";
import {ResponseError} from "../../../common/error.handler";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ErrorResponse} from "../../errors/errorResponse";
import {RotSettingsService} from "./rot-settings.service";
import {RotSettings} from "./RotSettings";
import {Currencies} from "../../../currencies";
import {BlockUiController} from "../../../block-ui/block-ui-controller";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-rot-settings',
    templateUrl: './rot-settings.component.html',
    styleUrls: ['../../shared-styles.css', '../../settings/settings.component.css'],
    providers: [RotSettingsService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RotSettingsComponent implements OnInit {

    private readonly ROT_SETTINGS_BLOCKER = "ROT_SETTINGS_BLOCKER";

    rotSettings: RotSettings = new RotSettings();
    validationErrors = {};
    currencyOptions: Observable<SelectItem[]>;

    constructor(public permissions: Permissions,
                public changeDetector: ChangeDetectorRef,
                public rotSettingsService: RotSettingsService,
                public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private growlMessageController: GrowlMessageController,
                private blockUiController: BlockUiController) {
    }

    ngOnInit() {
        this.currencyOptions = this.translatedSelectItemService.buildUnsortedDropdown(Currencies, '', undefined);
        this.getRotSettings();
    }

    private getRotSettings() {
        this.blockUiController.block(this.ROT_SETTINGS_BLOCKER);
        this.rotSettingsService.getRotSettings().pipe(
            finalize(() => this.blockUiController.unblock(this.ROT_SETTINGS_BLOCKER))
        ).subscribe({
            next: data => {
                this.rotSettings = data;
                this.changeDetector.markForCheck();
            },
            error: error => {
                throw new ResponseError(error);
            }
        });
    }

    public saveChanges() {
        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.rotSettingsService.updateRotSettings(this.rotSettings).subscribe({
                next: () => {
                    this.getRotSettings();
                    this.showSuccessMessage();
                },
                error: (errorMessage: HttpErrorResponse) => {
                    let errorResponse = new ErrorResponse(errorMessage.error);
                    if (errorResponse.is400()) {
                        this.validationErrors = {};
                        for (let property in errorResponse.invalidFields) {
                            this.validationErrors[property] = errorResponse.invalidFields[property];
                        }
                        this.changeDetector.markForCheck();
                    } else {
                        throw new ResponseError(errorMessage);
                    }
                }
            });
        }
    }

    private showSuccessMessage() {
        this.growlMessageController.info('ROT_SETTINGS.DATA_UPDATED');
    }
}
