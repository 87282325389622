import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowDesignerInterface} from "../window-designer-interface";
import {SubwindowSortHelper} from "./subwindow-sort-helper";
import {WindowData} from "../drawing-data/WindowData";
import {CutData} from "../drawing-data/CutData";
import {WindowCalculator} from "../window-calculator";
import {CutsUtil} from "./cutUtils";

export class SubwindowDependencies {

    dependencies: Map<SubWindowData, SubWindowData[]>;
    overlappingSubwindowPoints: Map<SubWindowData, number[][]>;

    constructor(window: WindowData, designer?: WindowDesignerInterface, cuts?: CutData[]) {
        this.dependencies = SubwindowSortHelper.getDependencies([...window.subWindows]);
        this.overlappingSubwindowPoints = new Map<SubWindowData, number[][]>();
        if (designer && cuts) {
            for (let subwindow of this.dependencies.keys()) {
                let overlappingPoints = [];
                this.getOverlappingSubwindows(subwindow).forEach(overlappingSubwindow =>
                    overlappingPoints.push(WindowCalculator.getFFWingOuterEdgePoints(overlappingSubwindow,
                        cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(overlappingSubwindow.points, cut)),
                        designer.totalBoundingBox, designer.profileCompositionDistances,
                        designer.isValidationDisabled()))
                );
                this.overlappingSubwindowPoints.set(subwindow, overlappingPoints);
            }
        }
    }

    public get(subwindow: SubWindowData): SubWindowData[] {
        return this.dependencies.get(subwindow);
    }

    private getOverlappingSubwindows(subwindow: SubWindowData): SubWindowData[] {
        let result = [];
        for (let [key, value] of this.dependencies) {
            if (value.includes(subwindow)) {
                result.push(key);
            }
        }
        return result;
    }

    public getOverlappingSubwindowsPoints(subwindow: SubWindowData): number[][] {
        return this.overlappingSubwindowPoints.get(subwindow);
    }
}
