<div class="flex-row-container">
    <div class="second-level-menu">
        <div class="second-level-menu">
            <div class="title"></div>
            <app-saved-filter  [viewId]="getSavedFilterViewId()"
                              [currentTableFilters]="currentTableFilters"
                              [currentTableVisibleColumns]="currentTableVisibleColumns"
                              (onFilterSelected)="tableFilterSelected($event)"
                              (onFilterUnselected)="tableFilterUnselected()"
                              (onCreateSuccess)="tableFilterCreateSuccess()"
                              (onCreateError)="tableFilterCreateError()"
                              (onDeleteSuccess)="tableFilterDeleteSuccess()"
                              (onDeleteError)="tableFilterDeleteError()"></app-saved-filter>

        </div>
    </div>
    <div class="content">
        <app-user (totalRecordsChange)="updateCounter($event)"
                  (onDataLazyLoad)="updateCurrentTableFilters($event)"
                  (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                  (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-user>
    </div>
</div>
