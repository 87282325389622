export class OnceFlag {

    private called = false;

    call(func: () => void): void {
        if (this.called) {
            return;
        }

        func();
        this.called = true;
    }

    reset(): void {
        this.called = false;
    }
}
