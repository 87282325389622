<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>

        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container">
        <p-inputMask [inputId]="inputId" [disabled]="disabled" [placeholder]="mask" [maxlength]="maxlength"
                     [ngModel]="value" (ngModelChange)="handleValueChange($event)" (onFocus)="handleFocus($event)"
                     (onBlur)="handleBlur($event)" [mask]="mask"></p-inputMask>
        <ng-container *ngIf="validationMessageKey != undefined">
            <span class="new-form-field-error-icon material-icons">error</span>
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
