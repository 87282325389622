import {CrudItem} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {Addon} from "../addons/addon";
import {PrintVisibility} from "./print-visibility.enum";
import {CatalogSection} from "../addons/CatalogSection";

export class AddonCategory implements CrudItem {
    id: number;
    name: MultilanguageField;
    sortIndex: number;
    groupId: number;
    addons: Addon[];
    symbol: string;
    required: boolean;
    info: MultilanguageField;
    hideValueOnPrint: boolean;
    printVisibility: PrintVisibility;
    target: CatalogSection;
    hasAutoOption: boolean;
    tileDialog: boolean;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.sortIndex = 1;
        this.groupId = undefined;
        this.addons = [];
        this.symbol = undefined;
        this.required = false;
        this.info = new MultilanguageField();
        this.hideValueOnPrint = false;
        this.printVisibility = PrintVisibility.ALWAYS;
        this.target = undefined;
        this.hasAutoOption = false;
        this.tileDialog = false;
    }
}
