<ng-container *vLet="!isPermitted({roles:['ROLE_OPERATOR']}) as isNotOperator">
    <div class="list-content settings">
        <div class="table-header">
            <div class="title">{{ 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO' | translate }}</div>
        </div>
        <div class="settings-form-wrapper">
            <div class="new-form-600">
                <div class="new-form-row">
                    <h3>{{ "SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO" | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="primaryColor"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.LEADING_COLOR' | translate }}"
                                    [(validationMessageKey)]="validationErrors['theme.primaryColor']"
                                    [maxlength]="7" [required]="true"
                                    [disabled]="isNotOperator"
                                    [(ngModel)]="subsystemWebshopInfo.theme.primaryColor"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="secondaryColor"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SECONDARY_COLOR' | translate }}"
                                    [(validationMessageKey)]="validationErrors['theme.secondaryColor']"
                                    [maxlength]="7" [required]="true"
                                    [disabled]="isNotOperator"
                                    [(ngModel)]="subsystemWebshopInfo.theme.secondaryColor"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="phoneNumber"
                                    [(ngModel)]="subsystemWebshopInfo.phoneNumber"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.PHONE_NUMBER' | translate }}"
                                    [maxlength]="50"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['phoneNumber']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="phoneNumber2"
                                    [(ngModel)]="subsystemWebshopInfo.phoneNumber2"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.PHONE_NUMBER_2' | translate }}"
                                    [maxlength]="50"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['phoneNumber2']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="email"
                                    [(ngModel)]="subsystemWebshopInfo.email"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.EMAIL' | translate }}"
                                    [maxlength]="255"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['email']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="cardTitle"
                                    [(ngModel)]="subsystemWebshopInfo.cardTitle"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CARD_TITLE' | translate }}"
                                    [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['cardTitle']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="callpageId"
                                    [(ngModel)]="subsystemWebshopInfo.callpageId"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CALLPAGE_ID_TITLE' | translate }}"
                                    [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['callpageId']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <div class="new-form-field new-form-field-nohover">
                        <div class="new-form-field-label-container">
                            <div>
                                <label>{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CALLPAGE_VISIBLE_VIEWS' | translate }}</label>
                            </div>
                        </div>
                        <div class="new-form-field-input-container">
                            <div class="new-form-row webshop-visible-views" *ngFor="let viewOption of callpageVisibleViewsOptions | async">
                                <app-checkbox [inputId]="'callpageVisibleView_' + viewOption.value"
                                              [label]="viewOption.label"
                                              [disabled]="isNotOperator"
                                              [ngModel]="isCallpageVisibleViewSelected(viewOption.value)"
                                              (ngModelChange)="handleCallpageVisibleViewSelectionChanged(viewOption.value, $event)"></app-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="smartsuppKey"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SMARTSUPP_KEY' | translate }}"
                                    [(validationMessageKey)]="validationErrors['smartsuppConfig.key']" [maxlength]="200"
                                    [(ngModel)]="subsystemWebshopInfo.smartsuppConfig.key"
                                    [disabled]="isNotOperator"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="smartsuppOffsetX"
                                      label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SMARTSUPP_OFFSET_X' | translate }}"
                                      [(validationMessageKey)]="validationErrors['smartsuppConfig.offsetX']"
                                      [allowOnlyIntegers]="true" [(ngModel)]="subsystemWebshopInfo.smartsuppConfig.offsetX"
                                      [disabled]="isNotOperator"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="smartsuppKey"
                                      label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SMARTSUPP_OFFSET_Y' | translate }}"
                                      [(validationMessageKey)]="validationErrors['smartsuppConfig.offsetY']"
                                      [allowOnlyIntegers]="true" [(ngModel)]="subsystemWebshopInfo.smartsuppConfig.offsetY"
                                      [disabled]="isNotOperator"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="gtmUserId"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.GTM_USER_ID' | translate }}"
                                    [(validationMessageKey)]="validationErrors['gtmUserId']" [disabled]="isNotOperator"
                                    [(ngModel)]="subsystemWebshopInfo.gtmUserId"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="webshopCompanyName"
                                    [(ngModel)]="subsystemWebshopInfo.webshopCompanyName"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.WEBSHOP_COMPANY_NAME' | translate }}"
                                    [maxlength]="512"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['webshopCompanyName']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="companyWebsiteUrl"
                                    [(ngModel)]="subsystemWebshopInfo.companyWebsiteUrl"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.COMPANY_WEBSITE_URL' | translate }}"
                                    [maxlength]="1024"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['companyWebsiteUrl']"></app-input-text>
                </div>
            </div>

            <div class="new-form-600">
                <div class="new-form-row">
                    <app-file-upload inputId="footer" [ngModel]="files['footer']" label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.FOOTER' | translate }}"
                                     (ngModelChange)="onFileChange($event, 'footer')"
                                     [maxSize]="300000" [image]="false" accept=".html"
                                     [addButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SELECT_FOOTER_UPLOAD'"
                                     [changeButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CHANGE_FOOTER_UPLOAD'"
                                     [deleteButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.DELETE_FOOTER_UPLOAD'"
                                     [disabled]="isNotOperator"
                                     [(validationMessageKey)]="validationErrors['footer']"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <app-file-upload inputId="icon" [ngModel]="files['icon']" label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.FAVICON' | translate }}"
                                     (ngModelChange)="onFileChange($event, 'icon')"
                                     [maxSize]="15000" [maxWidth]="128" [maxHeight]="128" [widthToHeightRatio]="1"
                                     [addButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SELECT_FAVICON_UPLOAD'"
                                     [changeButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CHANGE_FAVICON_UPLOAD'"
                                     [deleteButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.DELETE_FAVICON_UPLOAD'"
                                     [disabled]="isNotOperator"
                                     [(validationMessageKey)]="validationErrors['icon']"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <app-file-upload inputId="logo" [ngModel]="files['logo']" label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.LOGO' | translate }}"
                                     (ngModelChange)="onFileChange($event, 'logo')"
                                     [maxHeight]="75 * LOGO_SIZE_MULTIPLIER" [maxWidth]="600 * LOGO_SIZE_MULTIPLIER"
                                     [addButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SELECT_LOGO_UPLOAD'"
                                     [changeButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CHANGE_LOGO_UPLOAD'"
                                     [deleteButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.DELETE_LOGO_UPLOAD'"
                                     [disabled]="isNotOperator"
                                     [(validationMessageKey)]="validationErrors['logo']"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <app-file-upload (ngModelChange)="onFileChange($event, 'logoMobile')" [(validationMessageKey)]="validationErrors['logoMobile']" [addButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SELECT_LOGO_UPLOAD'"
                                     [changeButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CHANGE_LOGO_UPLOAD'"
                                     [deleteButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.DELETE_LOGO_UPLOAD'" [disabled]="isNotOperator"
                                     [maxHeight]="75 * LOGO_SIZE_MULTIPLIER" [maxWidth]="600 * LOGO_SIZE_MULTIPLIER"
                                     [ngModel]="files['logoMobile']"
                                     inputId="logoMobile"
                                     label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.LOGO_MOBILE' | translate }}"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <h3>{{ "SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ADDRESS" | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.street"
                                    [(ngModel)]="subsystemWebshopInfo.street"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.STREET' | translate }}"
                                    [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['street']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.city"
                                    [(ngModel)]="subsystemWebshopInfo.city"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CITY' | translate }}"
                                    [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['city']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.country"
                                [(ngModel)]="subsystemWebshopInfo.country"
                                label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.COUNTRY' | translate }}"
                                    [maxlength]="100"
                                [disabled]="isNotOperator"
                                [(validationMessageKey)]="validationErrors['country']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.zip"
                                    [(ngModel)]="subsystemWebshopInfo.zip"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ZIP' | translate }}"
                                    [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['zip']"></app-input-text>
                </div>
            </div>

        </div>

        <div class="settings-footer">
            <div *ngIf="isPermitted({roles:['ROLE_OPERATOR']})" class="settings-footer-right">
                <app-simple-button buttonId="saveSubsystemButton"
                                   label="{{ 'GENERAL.SAVE' | translate }}"
                                   icon="check"
                                   type="main-action"
                                   [size]="40"
                                   (onClick)="save()"></app-simple-button>
            </div>
        </div>
    </div>
</ng-container>
