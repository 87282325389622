import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

export enum Severity {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    LOGIN = 'LOGIN'
}

export enum ErrorCategory {
    VENSKA_PRICE_NOT_SET = 'VENSKA_PRICE_NOT_SET',
    DEALER_MARKUP_PERCENTAGE_NOT_FOUND = 'DEALER_MARKUP_PERCENTAGE_NOT_FOUND',
    FRONTEND_ERROR = 'FRONTEND_ERROR',
    SUBSYSTEM_MARKUP_PERCENTAGE_NOT_FOUND = 'SUBSYSTEM_MARKUP_PERCENTAGE_NOT_FOUND',
    PRICE_GROUP_MARKUP_PERCENTAGE_NOT_FOUND = 'PRICE_GROUP_MARKUP_PERCENTAGE_NOT_FOUND',
    SELLER_CLIENT_MARKUP_PERCENTAGE_NOT_FOUND = 'SELLER_CLIENT_MARKUP_PERCENTAGE_NOT_FOUND',
    SUBSYSTEM_ADDON_MARKUP_PERCENTAGE_NOT_FOUND = 'SUBSYSTEM_ADDON_MARKUP_PERCENTAGE_NOT_FOUND',
    BACKEND_ERROR = 'BACKEND_ERROR',
    PRICELIST_NOT_FOUND = 'PRICELIST_NOT_FOUND',
    PRICE_NOT_FOUND = 'PRICE_NOT_FOUND',
    CONFIGURABLE_ADDON_PRICE_NOT_FOUND = 'CONFIGURABLE_ADDON_PRICE_NOT_FOUND',
    PRICETABLE_NOT_FOUND = 'PRICETABLE_NOT_FOUND',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    ADDON_MARGIN_NOT_FOUND = 'ADDON_MARGIN_NOT_FOUND',
    INACTIVE_USER_LOGIN = 'INACTIVE_USER_LOGIN',
    DEPRECATED_FRONTEND_VERSION = 'DEPRECATED_FRONTEND_VERSION',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_LOGIN = 'INVALID_LOGIN',
    FAILED_TO_ACCEPT_TERMS = 'FAILED_TO_ACCEPT_TERMS',
    PRICING_FAILED = 'PRICING_FAILED',
    SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED',
    SEND_EMAIL_WITHOUT_WINDOW_IMAGES = 'SEND_EMAIL_WITHOUT_WINDOW_IMAGES',
    NO_WEBSHOP_PARTNER_SET_IN_SUBSYSTEM = 'NO_WEBSHOP_PARTNER_SET_IN_SUBSYSTEM',
    WEBSHOP_PARTNER_SUBSYSTEM_SETTINGS_NOT_SET = 'WEBSHOP_PARTNER_SUBSYSTEM_SETTINGS_NOT_SET',
    CONFIGURABLE_ADDON_INVALID_FORMULA = 'CONFIGURABLE_ADDON_INVALID_FORMULA',
    BUSINESSTYPE_NAME_NOT_SET = 'BUSINESSTYPE_NAME_NOT_SET',
    INVALID_BUSINESSTYPE_CODE = 'INVALID_BUSINESSTYPE_CODE',
    INVALID_BUSINESSTYPE_FOR_SYSTEM = 'INVALID_BUSINESSTYPE_FOR_SYSTEM',
    SHIPPING_CALCULATION = 'SHIPPING_CALCULATION',
    PRINT_ERROR = 'PRINT_ERROR',
    MOTLAWA_SEND_FAILED = 'MOTLAWA_SEND_FAILED',
    REDRAW_WINDOWS_RESULT = 'REDRAW_WINDOWS_RESULT',
    RESET_PASSWORD = 'RESET_PASSWORD'
}

@JsonDeserializable
export class AffectedElement {
    translationKey: string;
    translatedKeys: MultilanguageField;
    showAllLanguages = false;

    static fromJSON(jsonObject: any): AffectedElement {
        const affectedElement = new AffectedElement();
        affectedElement.translationKey = jsonObject['translationKey'];
        affectedElement.translatedKeys = jsonObject['translatedKeys'] != undefined
            ? MultilanguageField.fromJSON(jsonObject['translatedKeys'])
            : new MultilanguageField();
        affectedElement.showAllLanguages = jsonObject['showAllLanguages'];
        return affectedElement;
    }
}

@JsonDeserializable
export class ErrorReport {
    id: number;
    userLogin: string;
    eventOccurred: Date;
    subsystem: string;
    errorId: string;
    errorContent: string;
    errorCategory: ErrorCategory;
    severity: Severity;
    summary: string;
    details: string;
    stacktrace: string;
    active: boolean;
    affectedElements: AffectedElement[];
    offerNumber: string;
    backendVersion: string;
    backendCommit: string;
    frontendVersion: string;
    frontendCommit: string;
    requestUrl: string;

    static fromJSON(jsonObject: any): ErrorReport {
        let errorReport = new ErrorReport();
        errorReport.id = jsonObject['id'];
        errorReport.userLogin = jsonObject['userLogin'];
        errorReport.eventOccurred = new Date(jsonObject['eventOccurred']);
        errorReport.subsystem = jsonObject['subsystem'];
        errorReport.errorId = jsonObject['errorId'];
        errorReport.errorContent = jsonObject['errorContent'];
        errorReport.errorCategory = jsonObject['errorCategory'];
        errorReport.severity = jsonObject['severity'];
        errorReport.summary = jsonObject['summary'];
        errorReport.details = jsonObject['details'];
        errorReport.stacktrace = jsonObject['stacktrace'];
        errorReport.active = jsonObject['active'];
        if (jsonObject['affectedElements'] != undefined) {
            errorReport.affectedElements = jsonObject['affectedElements'].map(AffectedElement.fromJSON);
        }
        errorReport.offerNumber = jsonObject['offerNumber'];
        errorReport.backendVersion = jsonObject['backendVersion'];
        errorReport.backendCommit = jsonObject['backendCommit'];
        errorReport.frontendVersion = jsonObject['frontendVersion'];
        errorReport.frontendCommit = jsonObject['frontendCommit'];
        errorReport.requestUrl = jsonObject['requestUrl'];
        return errorReport;
    }
}

export function getErrorCategoryTranslationKey(errorCategory: string): string {
    switch (ErrorCategory[errorCategory]) {
        case ErrorCategory.FRONTEND_ERROR:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.FRONTEND_ERROR';
        case ErrorCategory.DEALER_MARKUP_PERCENTAGE_NOT_FOUND:
            return 'error.pricing.dealerMarkupPercentageNotFound';
        case ErrorCategory.SEND_EMAIL_WITHOUT_WINDOW_IMAGES:
            return 'error.pricing.sendEmailWithoutImages';
        case ErrorCategory.SEND_EMAIL_FAILED:
            return 'error.pricing.sendEmailFailed';
        case ErrorCategory.NO_WEBSHOP_PARTNER_SET_IN_SUBSYSTEM:
            return 'error.pricing.noWebshopPartnerSetInSubsystem';
        case ErrorCategory.WEBSHOP_PARTNER_SUBSYSTEM_SETTINGS_NOT_SET:
            return 'error.pricing.webshopPartnerSubsystemSettingsNotSet';
        case ErrorCategory.SUBSYSTEM_MARKUP_PERCENTAGE_NOT_FOUND:
            return 'error.pricing.subsystemMarkupPercentageNotFound';
        case ErrorCategory.VENSKA_PRICE_NOT_SET:
            return 'error.pricing.venskaPriceNotSet';
        case ErrorCategory.PRICE_GROUP_MARKUP_PERCENTAGE_NOT_FOUND:
            return 'error.pricing.clientMarkupPercentageNotFound';
        case ErrorCategory.SELLER_CLIENT_MARKUP_PERCENTAGE_NOT_FOUND:
            return 'error.pricing.sellerClientMarkupPercentageNotFound';
        case ErrorCategory.BACKEND_ERROR:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.BACKEND_ERROR';
        case ErrorCategory.SUBSYSTEM_ADDON_MARKUP_PERCENTAGE_NOT_FOUND:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.SUBSYSTEM_ADDON_MARKUP_PERCENTAGE_NOT_FOUND';
        case ErrorCategory.PRINT_ERROR:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.PRINT_ERROR';
        case ErrorCategory.PRICELIST_NOT_FOUND:
            return 'error.pricing.pricelsitNotFound';
        case ErrorCategory.CONFIGURABLE_ADDON_PRICE_NOT_FOUND:
            return 'error.pricing.configAddonBasePriceNotFound';
        case ErrorCategory.PRICE_NOT_FOUND:
            return 'error.pricing.priceNotFound';
        case ErrorCategory.PRICETABLE_NOT_FOUND:
            return 'error.pricing.pricetableNotFound';
        case ErrorCategory.ADDON_MARGIN_NOT_FOUND:
            return 'error.pricing.adminAddonProfitMarginNotFound';
        case ErrorCategory.INACTIVE_USER_LOGIN:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.INACTIVE_USER_LOGIN';
        case ErrorCategory.DEPRECATED_FRONTEND_VERSION:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.DEPRECATED_FRONTEND_VERSION';
        case ErrorCategory.INVALID_PASSWORD:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.INVALID_PASSWORD';
        case ErrorCategory.INVALID_LOGIN:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.INVALID_LOGIN';
        case ErrorCategory.FAILED_TO_ACCEPT_TERMS:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.FAILED_TO_ACCEPT_TERMS';
        case ErrorCategory.PRICING_FAILED:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.PRICING_FAILED';
        case ErrorCategory.CONFIGURABLE_ADDON_INVALID_FORMULA:
            return 'error.pricing.invalidAttributeFormula_report';
        case ErrorCategory.BUSINESSTYPE_NAME_NOT_SET:
            return 'error.pricing.businessType_name_not_set_report';
        case ErrorCategory.INVALID_BUSINESSTYPE_CODE:
            return 'error.pricing.invalidBusinessTypeCodeException_report';
        case ErrorCategory.INVALID_BUSINESSTYPE_FOR_SYSTEM:
            return 'error.pricing.invalidBusinessTypeForSystemException_report';
        case ErrorCategory.SHIPPING_CALCULATION:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.SHIPPING_CALCULATION';
        case ErrorCategory.MOTLAWA_SEND_FAILED:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.MOTLAWA_SEND_FAILED';
        case ErrorCategory.RESET_PASSWORD:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.RESET_PASSWORD';
        case ErrorCategory.UNKNOWN_ERROR:
        default:
            return 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.UNKNOWN_ERROR';
    }
}
