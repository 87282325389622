import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {TabularAddress} from '../../subsystem/tabularAddress';
import {SellerSettings} from './seller-settings';

@Injectable()
export class SellerSettingsService {

    public static readonly API_URL = 'sellerSettings';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItem(itemId: number): Observable<SellerSettings> {
        return this.http.get<SellerSettings>(`${SellerSettingsService.API_URL}/${itemId}`).pipe(
            map(response => SellerSettings.fromJSON(response)));
    }

    getAddresses(sellerUserId: number): Observable<TabularAddress[]> {
        return this.http.get<object[]>(`${SellerSettingsService.API_URL}/${sellerUserId}/addresses`)
            .pipe(map(response => response.map(TabularAddress.fromJSON)));
    }

    saveSettings(settings: SellerSettings, addresses: TabularAddress[],
                 correspondenceAddress: TabularAddress, deliveryAddress: TabularAddress): Observable<number> {
        let body = JSON.stringify(settings);
        let formData = new FormData();
        formData.append('sellerSettingsDto', new Blob([body], {
            type: 'application/json'
        }));

        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }

        return this.http.post(SellerSettingsService.API_URL, formData, {observe: 'response'})
            .pipe(map(response => {
                if (response.status === 201) {
                    return this.dataServiceHelper.getNewItemId(SellerSettingsService.API_URL, response);
                }
                return undefined;
            }));
    }

    saveFiles(userId: number, logoGlamour: File, glamourTitlePageImage: File, template: File, confirmationTemplate: File, glamourAnnotations: File): Observable<void> {
        let formData = new FormData();
        this.appendImageFile(formData, logoGlamour, 'logoGlamour');
        this.appendImageFile(formData, glamourTitlePageImage, 'glamourTitlePageImage');
        this.appendImageFile(formData, template, 'template');
        this.appendImageFile(formData, confirmationTemplate, 'confirmationTemplate');
        this.appendImageFile(formData, glamourAnnotations, 'glamourAnnotations');

        return this.http.post<void>(`${SellerSettingsService.API_URL}/${userId}/files`, formData);
    }

    saveAddresses(addresses: TabularAddress[], correspondenceAddress: TabularAddress, deliveryAddress: TabularAddress): Observable<any> {
        let formData = new FormData();

        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }
        return this.http.post(`${SellerSettingsService.API_URL}/saveAddresses`, formData, {observe: 'response'});
    }

    private appendImageFile(formData: FormData, file: File, filename: string) {
        if (file) {
            formData.append(filename, file);
        }
    }

    validateDetails(settings: SellerSettings): Observable<ValidationErrors> {
        let body = JSON.stringify(settings);
        let formData = new FormData();
        formData.append('sellerSettingsDto', new Blob([body], {
            type: 'application/json'
        }));

        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${SellerSettingsService.API_URL}/validateDetails`, formData));
    }

    validateAddress(settings: SellerSettings, addresses: TabularAddress[], correspondenceAddress: TabularAddress,
                    deliveryAddress: TabularAddress): Observable<ValidationErrors> {
        const formData = new FormData();
        formData.append('sellerSettingsDto', new Blob([JSON.stringify(settings)], {type: 'application/json'}));
        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${SellerSettingsService.API_URL}/validateAddress`, formData));
    }
}
