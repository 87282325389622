import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JwtInterceptor} from '../../auth/jwt.interceptor';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {SkipInterceptorsInterceptor} from '../../common/http/skip-interceptors.interceptor';
import {User} from './user';
import {UserList} from './user-list';
import {HolidayConfirmation} from "../settings/user-settings/HolidayConfirmation";

@Injectable()
export class UserService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getUsers(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<UserList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<UserList>('users', {params: params}).pipe(
            map(users => {
                users.data.forEach(user => {
                    user.accountExpirationDate = user.accountExpirationDate != undefined ? new Date(user.accountExpirationDate) : undefined;
                });
                return users;
            }));
    }

    getUsersBySubsystem(subsystemId?: number): Observable<UserList> {
        let params = undefined;
        if (subsystemId != undefined) {
            params = {subsystemId: '' + subsystemId};
        }
        return this.http.get<UserList>('users/getBySubsystem', {params: params});
    }

    saveUser(user: User, file: File): Observable<number> {
        let body = JSON.stringify(user);
        let formData = new FormData();
        formData.append('userDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('image', file);
        }
        return this.http.post('user', formData, {observe: 'response'}).pipe(
            map(response => user.id != undefined ? user.id : this.dataServiceHelper.getNewItemId('user', response)));
    }

    hasAnyClient(userId: number): Observable<boolean> {
        return this.http.get<boolean>(`user/${userId}/hasClients`);
    }

    moveClientsBetweenUsers(fromUserId: number, toUserId: number): Observable<boolean> {
        return this.http.post<boolean>(`user/${fromUserId}/moveTo/${toUserId}`, undefined);
    }

    getUser(userId: number): Observable<User> {
        return this.http.get<User>(`user/${userId}`);
    }

    getUserSignature(userId: number): Observable<File> {
        return this.http.get(`user/${userId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getUserProfile(): Observable<User> {
        return this.http.get<User>('user/profile');
    }

    getUserProfileSignature(): Observable<File> {
        return this.http.get('user/profile/image', {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    isWebshopPartner(): Observable<boolean> {
        return this.http.get<boolean>('user/isWebshopPartner');
    }

    markNewsAsRead(newsId: number): Observable<void> {
        return this.http.post<void>('user/confirmNewsAsRead', newsId);
    }

    generateWebshopToken(userId: number): Observable<string> {
        return this.http.get(`user/${userId}/generateWebshopToken`, {responseType: 'text'});
    }

    resetPassword(token: string, newPassword: string): Observable<void> {
        return this.http.put<void>(`user/resetPassword`, {password: newPassword}, {
            headers: {
                PasswordResetAuthorization: token,
                [SkipInterceptorsInterceptor.HEADER]: JwtInterceptor.NAME,
                [JwtInterceptor.SKIP_ERROR_HANDLING_HEADER]: '1'
            }
        });
    }

    goOnHoliday(): Observable<HolidayConfirmation> {
        return this.http.patch<HolidayConfirmation>('user/requestHoliday', {});
    }

    isOfferExportEnabled(): Observable<boolean> {
        return this.http.get<boolean>('user/isOfferExportEnabled');
    }
}
