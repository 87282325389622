import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CurrentUserService} from "../../../auth/current-user.service";
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {SidenavController} from "../../../sidenav-controller";

@Component({
    selector: 'app-general-error',
    templateUrl: './general-error.component.html',
    styleUrls: ['./general-error.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralErrorComponent implements OnInit, OnDestroy {

    errorId: string;

    constructor(private route: ActivatedRoute,
                private blockUiController: BlockUiController,
                private readonly sidenavController: SidenavController,
                private readonly currentUser: CurrentUserService) {
    }

    ngOnInit() {
        this.blockUiController.unblockAll();
        this.route.queryParams.subscribe(params => {
            this.errorId = params['errorId'];
        });
        if (this.currentUser.restrictedToOfferNumber) {
            this.sidenavController.hide();
        }
    }

    ngOnDestroy() {
        if (this.currentUser.restrictedToOfferNumber) {
            this.sidenavController.show();
        }
    }

    getErrorImageUrl() {
        return 'assets/images/error-page-broken-window.jpg';
    }
}
