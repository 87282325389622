import * as _ from 'underscore';
import {AddonInterface} from './catalog-data/addon-interface';
import {AddonCategoryEnum} from './enums/AddonCategoryEnum';
import {AddonFor} from './enums/AddonFor';

export class AllAddons {
    ungrouped: AddonInterface[];

    availableWeldTypes: AddonInterface[];
    availableHandles: AddonInterface[];
    availableCovers: AddonInterface[];
    availableMillings: AddonInterface[];
    availableMillingsNorwegian: AddonInterface[];
    availableUnderWindowBeads: AddonInterface[];
    availableEnhancements: AddonInterface[];
    availableUnderwindowProfiles: AddonInterface[];
    availableVentilators: AddonInterface[];
    availableDrips: AddonInterface[];
    availableCouplers: AddonInterface[];
    availableFittingBrakes: AddonInterface[];
    availableFittingSlidings: AddonInterface[];
    availableFittingTypes: AddonInterface[];
    availableFittingEspagnoletteTypes: AddonInterface[];
    availableFittingVerandas: AddonInterface[];
    availableFittingInsertions: AddonInterface[];
    availableFittingMainInsertions: AddonInterface[];
    availableFittingAdditionalInsertions: AddonInterface[];
    availableFittingLocks: AddonInterface[];
    availableFittingLocksTerrace: AddonInterface[];
    availableFittingAutomaticDrives: AddonInterface[];
    availableTerraceHandles: AddonInterface[];

    constructor() {
        this.fill([]);
    }

    fill(addons: AddonInterface[]): void {
        let data = _.groupBy(addons.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]),
            addon => addon.category);

        this.ungrouped = addons;
        this.availableUnderwindowProfiles = data[AddonCategoryEnum.UNDERWINDOW_PROFILE] || [];
        this.availableWeldTypes = data[AddonCategoryEnum.WELD_TYPE] || [];
        this.availableHandles = data[AddonCategoryEnum.HANDLE] || [];
        this.availableCovers = data[AddonCategoryEnum.COVER] || [];
        this.availableMillings = data[AddonCategoryEnum.MILLING] || [];
        this.availableMillingsNorwegian = data[AddonCategoryEnum.MILLING_NORWEGIAN] || [];
        this.availableUnderWindowBeads = data[AddonCategoryEnum.UNDER_WINDOW_BEAD] || [];
        this.availableEnhancements = data[AddonCategoryEnum.ENHANCEMENTS] || [];
        this.availableVentilators = data[AddonCategoryEnum.VENTILATORS] || [];
        this.availableDrips = data[AddonCategoryEnum.DRIP] || [];
        this.availableCouplers = data[AddonCategoryEnum.COUPLER] || [];
        this.availableFittingBrakes = data[AddonCategoryEnum.FITTING_BRAKE] || [];
        this.availableFittingSlidings = data[AddonCategoryEnum.FITTING_SLIDING] || [];
        this.availableFittingTypes = data[AddonCategoryEnum.FITTING_TYPE] || [];
        this.availableFittingEspagnoletteTypes = data[AddonCategoryEnum.FITTING_ESPAGNOLETTE_TYPE] || [];
        this.availableFittingVerandas = data[AddonCategoryEnum.FITTING_VERANDA] || [];
        this.availableFittingInsertions = data[AddonCategoryEnum.FITTING_INSERTION] || [];
        this.availableFittingMainInsertions = data[AddonCategoryEnum.FITTING_MAIN_INSERTION] || [];
        this.availableFittingAdditionalInsertions = data[AddonCategoryEnum.FITTING_ADDITIONAL_INSERTION] || [];
        this.availableFittingLocks = data[AddonCategoryEnum.FITTING_LOCK] || [];
        this.availableFittingLocksTerrace = data[AddonCategoryEnum.FITTING_LOCK_TERRACE] || [];
        this.availableFittingAutomaticDrives = data[AddonCategoryEnum.FITTING_AUTOMATIC_DRIVE] || [];
        this.availableTerraceHandles = data[AddonCategoryEnum.TERRACE_HANDLE] || [];
    }
}
