<div class="cover-behind-tabview-nav"></div>
<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.PRICESHEETS' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="permissions.canCreatePricelist()"
                                   label="{{ 'PRICESHEET.LIST.ADD' | translate }}" icon="add" type="main-action"
                                   [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-tabView class="details-tabs" id="details-tabs" [activeIndex]="selectedCategoryIndex"
               (onChange)="onTabChange($event.index)">
        <p-tabPanel *ngFor="let availableCategory of availableCategories; let i = index"
                    header="{{ ('PRICESHEET.CATEGORY.'+availableCategory) | translate }}">
            <app-pricesheet-tab [active]="i === selectedCategoryIndex" [category]="availableCategory" [seqNum]="i"
                                [showFilters]="showFilters"
                                [selectedItem]="selectedItemByCategory.get(availableCategory)"
                                (selectedItemChange)="handleSelectedItemChange(availableCategory, $event)"
                                (addNewItem)="doShowDialogToAdd()"
                                (onRowSelectEmitter)="doOnRowSelect($event)"></app-pricesheet-tab>
        </p-tabPanel>
    </p-tabView>
</div>
<app-wizard *ngIf="newItem" header="{{ 'PRICESHEET.FORM.ADD_TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'PRICESHEET.FORM.DETAILS_STEP' | translate }}">
        <app-pricesheet-form [pricesheet]="item" [category]="selectedCategory" [types]="getAvailableTypes()"
                             [validationErrors]="validationErrors" [(file)]="file"
                             [canEdit]="true"></app-pricesheet-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog #editDialog *ngIf="!newItem && displayDialog" header="{{ 'PRICESHEET.FORM.EDIT_TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <ng-template pTemplate="afterHeader">
        <div class="dialog-update-dates-container">
            <span>{{ 'PRICESHEET.LAST_MODIFICATION_DATE' | translate }}:</span>
            <span>{{item.lastModifiedDate | momentCurrentLang | amDateFormat: 'L LT'}}</span>
            <ng-container *ngIf="item.lastNotificationDate">
                <span>{{ 'PRICESHEET.LAST_NOTIFICATION_DATE' | translate }}:</span>
                <span>{{item.lastNotificationDate | momentCurrentLang | amDateFormat: 'L LT'}}</span>
            </ng-container>
        </div>
    </ng-template>
    <app-wizard-step label="{{ 'PRICESHEET.FORM.DETAILS_STEP' | translate }}">
        <app-pricesheet-form [pricesheet]="item" [category]="selectedCategory" [types]="getAvailableTypes()"
                             [validationErrors]="validationErrors" [(file)]="file"
                             [canEdit]="permissions.canEditPricelist()"></app-pricesheet-form>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="permissions.canEditPricelist()" buttonId="wizard-dialog-save"
                               label="{{'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="editDialog.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="editDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
