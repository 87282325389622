<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(OtherFillingField.NAME)"
                            inputId="name" label="{{ 'OTHER_FILLING.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(OtherFillingField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.name"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(OtherFillingField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'OTHER_FILLING.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(OtherFillingField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div class="new-form-row">
        <app-input-text *ngIf="fieldUsage.show(OtherFillingField.SYMBOL)"
                        inputId="symbol" label="{{ 'OTHER_FILLING.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(OtherFillingField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true">
        </app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.THICKNESS)">
        <app-input-number inputId="thickness" label="{{ 'OTHER_FILLING.FORM.THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(OtherFillingField.THICKNESS)"
                          [(validationMessageKey)]="validationErrors['thickness']" [maxlength]="50"
                          [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                          [(ngModel)]="item.thickness" [required]="true">
        </app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.UNIT_WEIGHT)">
        <app-input-number inputId="unitWeight" label="{{ 'OTHER_FILLING.FORM.UNIT_WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(OtherFillingField.UNIT_WEIGHT)"
                          [(validationMessageKey)]="validationErrors['unitWeight']" [min]="0" [max]="9999"
                          [required]="true" [(ngModel)]="item.unitWeight"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.FOR_INDOOR_USE)">
        <app-checkbox inputId="forIndoorUse" label="{{ 'OTHER_FILLING.FORM.FOR_INDOOR_USE' | translate }}"
                      [disabled]="fieldUsage.disabled(OtherFillingField.FOR_INDOOR_USE)"
                      [(ngModel)]="item.forIndoorUse"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.FOR_OUTDOOR_USE)">
        <app-checkbox inputId="forOutdoorUse" label="{{ 'OTHER_FILLING.FORM.FOR_OUTDOOR_USE' | translate }}"
                      [disabled]="fieldUsage.disabled(OtherFillingField.FOR_OUTDOOR_USE)"
                      [(ngModel)]="item.forOutdoorUse"></app-checkbox>
    </div>
    <div *ngIf="fieldUsage.show(OtherFillingField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'OTHER_FILLING.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(OtherFillingField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <!-- KOLOR RDZENIA -->
    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.CORE_COLORS)">
        <app-select inputId="coreColor" label="{{ 'OTHER_FILLING.FORM.CORE_COLORS' | translate }}"
                    [disabled]="fieldUsage.disabled(OtherFillingField.CORE_COLORS)"
                    [options]="availableCoreColors" [(ngModel)]="item.coreColor"
                    [(validationMessageKey)]="validationErrors['coreColor']" [required]="true"></app-select>
    </div>

    <!-- SORT INDEX -->
    <div *ngIf="fieldUsage.show(OtherFillingField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'OTHER_FILLING.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(OtherFillingField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(OtherFillingField.COLOR_SELECTION)">
        <p-accordion>
            <app-colors-selection [showCoreColors]="false"
                                  [coreColorsDisabled]="fieldUsage.disabled(OtherFillingField.COLOR_SELECTION)"
                                  [insideColorsDisabled]="fieldUsage.disabled(OtherFillingField.COLOR_SELECTION)"
                                  [outsideColorsDisabled]="fieldUsage.disabled(OtherFillingField.COLOR_SELECTION)"
                                  [insideColorsHeader]="'OTHER_FILLING.FORM.INSIDE_COLORS'"
                                  [insideColorsModel]="item.insideColors"
                                  (onInsideColorChange)="onInsideColorChange($event)"
                                  [insideColorsOptions]="availableInsideColors"
                                  [outsideColorsHeader]="'OTHER_FILLING.FORM.OUTSIDE_COLORS'"
                                  [outsideColorsModel]="item.outsideColors"
                                  (onOutsideColorChange)="onOutsideColorChange($event)"
                                  [outsideColorsOptions]="availableOutsideColors"></app-colors-selection>
        </p-accordion>
    </div>

</div>
