import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {MailSettings} from 'src/app/features/settings/mail-settings/mail-settings';
import {BlockUiController} from '../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {AbstractMailSettingsComponent} from '../../../settings/mail-settings/mail-settings.component';
import {MailSettingsService} from '../../../settings/mail-settings/mail-settings.service';
import {TranslationsService} from '../../../translations/translations.service';

@Component({
    selector: 'app-user-activity-report-mail-settings',
    templateUrl: '../../../settings/mail-settings/mail-settings.component.html',
    styleUrls: ['../../../settings/settings.component.css'],
    providers: [MailSettingsService, TranslationsService, TranslatedSelectItemService]
})
export class UserActivityReportMailSettingsComponent extends AbstractMailSettingsComponent {

    constructor(private readonly mailSettingsService: MailSettingsService,
                translationsService: TranslationsService,
                translateService: TranslateService,
                translatedSelectItemService: TranslatedSelectItemService,
                blockUiController: BlockUiController,
                growlMessageController: GrowlMessageController,
                errors: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(translationsService, translateService, translatedSelectItemService, blockUiController, growlMessageController, errors,
            'USER_ACTIVITY_REPORT.MAIL_SETTINGS', 'USER_ACTIVITY_REPORT.FORM.MAIL_SUBJECT',
            'USER_ACTIVITY_REPORT.FORM.MAIL_BODY', false, changeDetector);
        this.componentType = 'userActivityReport';
    }

    protected loadMailSettings(): Observable<MailSettings> {
        return this.mailSettingsService.getUserActivityReportMailSettings();
    }

    protected saveMailSettings(mailSettings: MailSettings): Observable<void> {
        return this.mailSettingsService.updateUserActivityReportMailSettings(mailSettings);
    }
}
