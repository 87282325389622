import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomTranslations} from './custom-translations';

@Injectable()
export class CustomTranslationsService {

    constructor(private http: HttpClient) {
    }

    public get(): Observable<CustomTranslations> {
        return this.http.get<CustomTranslations>('translations/custom');
    }
}
