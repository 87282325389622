import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WindowSystemModel} from '../../../window-system-model/WindowSystemModel';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration-model-form',
    templateUrl: './webshop-recommended-configuration-model-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationModelFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    windowSystemModels: SelectItem[];

    private readonly windowSystemModelsById = new Map<number, WindowSystemModel>();

    constructor(private service: WebshopRecommendedConfigurationService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            this.service.getModelsWithPrices(this.item.productConfiguration, 100, 0).subscribe(models => {
                this.windowSystemModels = [];
                for (let model of models.data) {
                    this.windowSystemModels.push({
                        label: model.windowSystemModel.windowSystemName[this.translate.currentLang],
                        value: model.windowSystemModel.id
                    });
                    this.windowSystemModelsById.set(model.windowSystemModel.id, model.windowSystemModel);
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    handleWindowSystemModelChange(windowSystemModelId: number): void {
        this.item.windowSystemModelId = windowSystemModelId;
        this.item.productConfiguration.windowSystemModelId = windowSystemModelId;

        if (windowSystemModelId != undefined) {
            const windowSystemModel = this.windowSystemModelsById.get(windowSystemModelId);
            if (windowSystemModel.value.glazingGlassQn == undefined) {
                this.item.productConfiguration.glazingGlassQuantity = windowSystemModel.value.glazingGlassQn;
            }
            if (windowSystemModel.value.webshopGlazingPackageId == undefined) {
                this.item.productConfiguration.glazingPackageId = windowSystemModel.value.webshopGlazingPackageId;
            }
            if (windowSystemModel.value.colorIdInternal == undefined) {
                this.item.productConfiguration.internalColorId = windowSystemModel.value.colorIdInternal;
            }
            if (windowSystemModel.value.colorIdExternal == undefined) {
                this.item.productConfiguration.externalColorId = windowSystemModel.value.colorIdExternal;
            }
        }
    }
}
