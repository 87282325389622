<div class="list-content">
    <div class="table-header">
        <div class="title">{{'SUBWINDOWTYPE.TITLE' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column *ngIf="fieldUsage.show(SubwindowTypeField.TYPE)" field="type" header="{{ 'SUBWINDOWTYPE.FORM.CODE' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="equals" [filterValues]="filterTypes | async">
            <ng-template pTemplate="body" let-subwindowType="rowData">
                {{ subwindowType.type }}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(SubwindowTypeField.NAME)"
                  field="name" header="{{ 'SUBWINDOWTYPE.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-subwindowType="rowData">
                {{subwindowType.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(SubwindowTypeField.FUNCTIONS_QUANTITY)"
                  field="functionsQuantity" header="{{ 'SUBWINDOWTYPE.FORM.FUNCTIONS_QUANTITY' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-subwindowType="rowData">
                {{subwindowType.functionsQuantity}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard-dialog *ngIf="displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ 'SUBWINDOWTYPE.FORM.EDIT-TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'SUBWINDOWTYPE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-subwindow-type-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-subwindow-type-form>
    </app-wizard-step>

</app-wizard-dialog>
