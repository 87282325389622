import {Pipe, PipeTransform} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";

@Pipe({
    name: 'mapToSelectItems'
})
export class MapToSelectItemsPipe implements PipeTransform {

    transform(options: any[], optionFormatter: (option: any) => SelectItem): SelectItem[] {
        if (!options) {
            return;
        }
        return options.map(option => optionFormatter(option));
    }

}
