import {Injectable} from '@angular/core';
import {JwtDto} from "./jwt-dto";
import {JwtHelperService} from './jwt-helper.service';
import {StorageKey, StorageService} from './storage.service';

@Injectable()
export class CurrentUserService {

    constructor(private readonly storage: StorageService,
                private readonly jwtHelper: JwtHelperService) {
    }

    get currentUserId(): number {
        return JwtHelperService.decodeToken(this.jwtHelper.tokenGetter())?.userid;
    }

    get currentUserName(): string {
        return JwtHelperService.decodeToken(this.jwtHelper.tokenGetter())?.sub;
    }

    get currentUser(): JwtDto {
        const jwt = this.storage.get(StorageKey.CURRENT_USER_AUTH_DATA);
        if (jwt != undefined) {
            return JSON.parse(jwt);
        }
        return undefined;
    }

    get mainUserId(): number {
        const mainJwt = this.storage.get(StorageKey.MAIN_JWT);
        if (!mainJwt) {
            return undefined;
        }
        return JwtHelperService.decodeToken(mainJwt)?.userid;
    }

    get mainUserName(): string {
        const mainJwt = this.storage.get(StorageKey.MAIN_JWT);
        if (!mainJwt) {
            return undefined;
        }
        return JwtHelperService.decodeToken(mainJwt)?.sub;
    }

    get mainUser(): JwtDto {
        const jwt = this.storage.get(StorageKey.MAIN_USER_AUTH_DATA);
        if (jwt != undefined) {
            return JSON.parse(jwt);
        }
        return undefined;
    }

    get restrictedToOfferNumber(): string {
        return JwtHelperService.decodeToken(this.jwtHelper.tokenGetter())?.restriction_offernum;
    }
}
