<p-accordion [multiple]="false" #mainAcc class="window-designer-tab" id="general-tab">
    <!--Main-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.MAIN.SECTION_TITLE' | translate }}"
                    [selected]="true" id="mainSectionAccordion" name="mainSectionAccordion"
                    [ngClass]="getSectionErrorClass(SidebarSection.MAIN)">

        <!--Quantity-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.QUANTITY)">
                <app-input-number [inputId]="Field.QUANTITY +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.QUANTITY' | translate }}"
                              (input)="filterNumbersOnInputField($event)" (onFocus)="onSelectFocus($event)"
                              (onBlur)="updateOfferPositionQuantity()" [min]="1" [max]="1000000"
                              [hasValidationError]="fieldUsage.required(Field.QUANTITY)"
                              [disabled]="readOnlyMode"
                              [(ngModel)]="offerPosition.quantity"></app-input-number>
        </div>

        <!--Webshop charge name-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WEBSHOP_CHARGE_NAME)">
            <app-translation-fields [inputId]="Field.WEBSHOP_CHARGE_NAME +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.WEBSHOP_CHARGE_NAME' | translate }}" validationKeyPrefix="name"
                                    [validationErrors]="webshopChargeValidationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                                    [field]="webshopCharge.name"></app-translation-fields>
        </div>

        <!--Window system-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WINDOW_SYSTEM)">
            <app-select [inputId]="Field.WINDOW_SYSTEM +'_id'" label="{{ 'OFFER.TABS.SYSTEM' | translate }}"
                        [options]="filteredWindowSystems" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.WINDOW_SYSTEM)"
                        [ngModel]="drawingData.windowSystemId" [optionFormatter]="selectItemFormatters.windowSystemsOptionFormatter"
                        [hasValidationError]="fieldUsage.required(Field.WINDOW_SYSTEM)"
                        [disabled]="readOnlyMode || editModelMode || isDataModificationMode() && webshopCharge.id != undefined"
                        [required]="false"></app-select>
        </div>

        <!--Width-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WIDTH)">
            <app-input-length [inputId]="Field.WIDTH +'_id'"
                              label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.WIDTH' | translate }}"
                              (onFocus)="onSelectFocus($event)" (onBlur)="totalSizeChanged(sizeProp.TOTAL_WIDTH)"
                              [min]="selectedWindowSystem?.dimensionStep" [max]="maxWindowDimensions.MAX_WIDTH"
                              [step]="selectedWindowSystem?.dimensionStep" [shouldRoundToStep]="true"
                              [hasValidationError]="fieldUsage.required(Field.WIDTH)" [disabled]="readOnlyMode"
                              [(ngModel)]="totalWidth" [required]="false"></app-input-length>
        </div>

        <!--Height-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.HEIGHT)">
            <app-input-length [inputId]="Field.HEIGHT +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.HEIGHT' | translate }}"
                              (onFocus)="onSelectFocus($event)" (onBlur)="totalSizeChanged(sizeProp.TOTAL_HEIGHT)"
                              [min]="selectedWindowSystem?.dimensionStep" [max]="maxWindowDimensions.MAX_HEIGHT"
                              [step]="selectedWindowSystem?.dimensionStep" [shouldRoundToStep]="true"
                              [hasValidationError]="fieldUsage.required(Field.HEIGHT)" [disabled]="readOnlyMode"
                              [(ngModel)]="totalHeight" [required]="false"></app-input-length>
        </div>

        <!--Profile-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.PROFILE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.PROFILE) | async as items">
                <app-select [inputId]="Field.PROFILE + '_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.PROFILE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" (ngModelChange)="emitOnChangeFrameProfile($event)"
                            [disabled]="readOnlyMode" [ngModel]="drawingData.specification.frameProfileId"
                            [hasValidationError]="fieldUsage.required(Field.PROFILE, componentInitialized) ||
                                (drawingData.specification.frameProfileId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.frameProfileId | isNotAvailable:items:isWebshopCharge"
                            [required]="false" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.PROFILE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.PROFILE)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.frameProfileId == undefined || drawingData.specification.frameProfileId === 0"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(drawingData.specification.frameProfileId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--Weld type-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WELD_TYPE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.WELD_TYPE) | async as items">
                <app-select-or-checkbox [inputId]="Field.WELD_TYPE +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.WELD_TYPE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.WELD_TYPE)"
                            [value]="drawingData.specification.weldType.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.WELD_TYPE) ||
                                (drawingData.specification.weldType.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.weldType.addonId | isNotAvailable:items:isWebshopCharge"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.WELD_TYPE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.WELD_TYPE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--Under window bead-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.UNDER_WINDOW_BEAD)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.UNDER_WINDOW_BEAD) | async as items">
                <app-select-or-checkbox [inputId]="Field.UNDER_WINDOW_BEAD +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.UNDER_WINDOW_BEAD' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.UNDER_WINDOW_BEAD)"
                            [value]="drawingData.specification.underWindowBead.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.UNDER_WINDOW_BEAD) ||
                                (drawingData.specification.underWindowBead.addonId |isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.underWindowBead.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.UNDER_WINDOW_BEAD)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.UNDER_WINDOW_BEAD)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- Frame enhancement -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FRAME_ENHANCEMENT)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FRAME_ENHANCEMENT) | async as items">
                <app-select-or-checkbox [inputId]="Field.FRAME_ENHANCEMENT +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.ENHANCEMENT' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [disabled]="readOnlyMode"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FRAME_ENHANCEMENT)"
                            [value]="drawingData.specification.frameEnhancement.addonId"
                            [hasValidationError]="fieldUsage.required(Field.FRAME_ENHANCEMENT) ||
                                (drawingData.specification.frameEnhancement.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.frameEnhancement.addonId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FRAME_ENHANCEMENT)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FRAME_ENHANCEMENT)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- View -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.VIEW)">
            <app-select [inputId]="Field.VIEW +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.VIEW' | translate }}"
                        [options]="windowEditorFieldContentProvider.getItemsStream(Field.VIEW) | async"
                        (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                        (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                        (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.VIEW)" [ngModel]="drawingData.view"
                        [required]="false" [disabled]="readOnlyMode"
                        [hasValidationError]="fieldUsage.required(Field.VIEW)" [translateLabels]="true"
                        [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.VIEW)}"
                        (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.VIEW)"></app-select>
        </div>

        <!--Window function-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WINDOW_FUNCTION)">
            <app-select [inputId]="Field.WINDOW_FUNCTION +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.FUNCTION' | translate }}"
                        [options]="windowEditorFieldContentProvider.getItemsStream(Field.WINDOW_FUNCTION) | async"
                        (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                        (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                        (onFocus)="onSelectFocus($event)" [disabled]="readOnlyMode"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.WINDOW_FUNCTION)"
                        [ngModel]="drawingData.specification.windowFunction" [required]="false" [translateLabels]="true"
                        [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.WINDOW_FUNCTION)}"
                        (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.WINDOW_FUNCTION)"></app-select>
        </div>

        <!--Covers-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.COVERS)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.COVERS) | async as items">
                <app-select-or-checkbox [inputId]="Field.COVERS +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.COVERS' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [disabled]="readOnlyMode"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.COVERS)"
                            [value]="drawingData.specification.cover.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.COVERS) ||
                                (drawingData.specification.cover.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.cover.addonId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.COVERS)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.COVERS)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--Millings-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.MILLINGS)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.MILLINGS) | async as items">
                <app-select-or-checkbox [inputId]="Field.MILLINGS +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.MILLING' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.MILLINGS)"
                            [value]="drawingData.specification.milling.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.MILLINGS) ||
                                (drawingData.specification.milling.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.milling.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.MILLINGS)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.MILLINGS)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.MILLINGS_NORWEGIAN)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.MILLINGS_NORWEGIAN) | async as items">
                <app-select-or-checkbox [inputId]="Field.MILLINGS_NORWEGIAN +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.MILLING_NORWEGIAN' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.MILLINGS_NORWEGIAN)"
                            [value]="drawingData.specification.millingNorwegian.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.MILLINGS_NORWEGIAN) ||
                                (drawingData.specification.millingNorwegian.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.millingNorwegian.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.MILLINGS_NORWEGIAN)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.MILLINGS_NORWEGIAN)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--Handles type-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.HANDLES)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.HANDLES) | async as items">
                <app-select-or-checkbox [inputId]="Field.HANDLES +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.HANDLES' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [required]="false"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.HANDLES)"
                            [value]="drawingData.specification.handleType.addonId ||
                                (drawingData.specification.handleType.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.handleType.addonId | isNotAvailable:items:isWebshopCharge"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [hasValidationError]="fieldUsage.required(Field.HANDLES)"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.HANDLES)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.HANDLES)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- Terrace handle -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.TERRACE_HANDLE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.TERRACE_HANDLE) | async as items">
                <app-select-or-checkbox [inputId]="Field.TERRACE_HANDLE +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLES' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [required]="false"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.TERRACE_HANDLE)"
                            [value]="drawingData.specification.terraceHandle.addonId ||
                                (drawingData.specification.terraceHandle.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.terraceHandle.addonId | isNotAvailable:items:isWebshopCharge"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [hasValidationError]="fieldUsage.required(Field.TERRACE_HANDLE)"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.TERRACE_HANDLE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.TERRACE_HANDLE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- Terrace handle layout -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.TERRACE_HANDLE_LAYOUT)">
            <app-select [inputId]="Field.TERRACE_HANDLE_LAYOUT +'_id'" [fakeSelect]="true"
                        label="{{ 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLE_LAYOUT' | translate }}"
                        [options]="windowEditorFieldContentProvider.getItemsStream(Field.TERRACE_HANDLE_LAYOUT) | async"
                        (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                        (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                        (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.TERRACE_HANDLE_LAYOUT)"
                        [ngModel]="drawingData.specification.terraceHandleLayout" [required]="false"
                        [hasValidationError]="fieldUsage.required(Field.TERRACE_HANDLE_LAYOUT)" [disabled]="readOnlyMode"
                        [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.TERRACE_HANDLE_LAYOUT)}"
                        [translateLabels]="true"
                        (onClick)="handleTerraceHandleLayoutSelectClick($event); windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.TERRACE_HANDLE_LAYOUT)"></app-select>
        </div>

        <!--Doors - Doorstep-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.DOORSTEP)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.DOORSTEP) | async as items">
                <app-select [inputId]="Field.DOORSTEP +'_id'" label="{{(fieldUsage.isTerraceSystem() ? 'OFFER.TABS.SECTION.MAIN.DOORSTEPS_TERRACE' : 'OFFER.TABS.SECTION.MAIN.DOORSTEPS') | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" (ngModelChange)="emitOnChangeThresholdProfile($event)"
                            [required]="!standardDoorstepAvailable()" [ngModel]="drawingData.specification.doorstepId"
                            [disabled]="readOnlyMode" [allowSelectingNone]="standardDoorstepAvailable()"
                            placeholder="{{ (standardDoorstepAvailable() ? 'GENERAL.OPTION_STANDARD_TRESHOLD' : '') | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.DOORSTEP) ||
                                (drawingData.specification.doorstepId | isNotAvailable:items:standardDoorstepAvailable())"
                            [noLongerAvailable]="drawingData.specification.doorstepId | isNotAvailable:items:standardDoorstepAvailable()"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.DOORSTEP)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.DOORSTEP)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.doorstepId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(drawingData.specification.doorstepId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--Channel section-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.CHANNEL_SECTION)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.CHANNEL_SECTION) | async as items">
                <app-select [inputId]="Field.CHANNEL_SECTION +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.CHANNEL_SECTIONS' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" (ngModelChange)="emitOnChangeChannelSectionProfile($event)"
                            [required]="true" [ngModel]="drawingData.specification.channelSectionId"
                            [disabled]="readOnlyMode" [allowSelectingNone]="false"
                            [hasValidationError]="fieldUsage.required(Field.CHANNEL_SECTION) ||
                                (drawingData.specification.channelSectionId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.channelSectionId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.CHANNEL_SECTION)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.CHANNEL_SECTION)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.channelSectionId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(drawingData.specification.channelSectionId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--Construction mullion-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.CONSTRUCTIONAL_MULLION)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.CONSTRUCTIONAL_MULLION) | async as items">
                <app-select [inputId]="Field.CONSTRUCTIONAL_MULLION +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.CONSTRUCTIONAL_MULLION' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" (ngModelChange)="emitOnChangeConstructionalMullionProfile($event)"
                            [required]="true" [ngModel]="drawingData.specification.constructionalMullionId"
                            [disabled]="readOnlyMode" [allowSelectingNone]="false"
                            [hasValidationError]="fieldUsage.required(Field.CONSTRUCTIONAL_MULLION) ||
                                (drawingData.specification.constructionalMullionId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.constructionalMullionId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.CONSTRUCTIONAL_MULLION)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.CONSTRUCTIONAL_MULLION)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.constructionalMullionId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(drawingData.specification.constructionalMullionId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--Movable post-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.MOVABLE_POST)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.MOVABLE_POST) | async as items">
                <app-select [inputId]="Field.MOVABLE_POST +'_id'" label="{{ 'OFFER.TABS.SECTION.MAIN.MOVABLE_POST' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" (ngModelChange)="emitOnChangeMovablePostProfile($event)"
                            [required]="true" [ngModel]="drawingData.specification.movablePostId"
                            [disabled]="readOnlyMode" [allowSelectingNone]="false"
                            [hasValidationError]="fieldUsage.required(Field.MOVABLE_POST) ||
                                (drawingData.specification.movablePostId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.movablePostId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.MOVABLE_POST)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.MOVABLE_POST)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.movablePostId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(drawingData.specification.movablePostId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!-- Underwindow profile -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.UNDERWINDOW_PROFILE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.UNDERWINDOW_PROFILE) | async as items">
                <app-select-or-checkbox [inputId]="Field.UNDERWINDOW_PROFILE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.UNDERWINDOW_PROFILE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [required]="false"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.UNDERWINDOW_PROFILE)"
                            [value]="drawingData.specification.underwindowProfile.addonId" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.UNDERWINDOW_PROFILE) ||
                                (drawingData.specification.underwindowProfile.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.underwindowProfile.addonId | isNotAvailable:items:true"
                            [checkAvailability]="true"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.UNDERWINDOW_PROFILE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.UNDERWINDOW_PROFILE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--Doors - Opening-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.OPENING)">
            <app-select [inputId]="Field.OPENING +'_id'" label="{{ 'OFFER.OPENING.SECTION_TITLE' | translate }}"
                        [options]="windowEditorFieldContentProvider.getItemsStream(Field.OPENING) | async"
                        (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                        (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                        (onFocus)="onSelectFocus($event)" [translateLabels]="true"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.OPENING)"
                        [ngModel]="drawingData.specification.opening" [required]="false"
                        [hasValidationError]="fieldUsage.required(Field.OPENING)" [disabled]="readOnlyMode"
                        [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.OPENING)}"
                        (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.OPENING)"></app-select>
        </div>

        <!--Seals - external color-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.SEALS_EXTERNAL)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.SEALS_EXTERNAL) | async as items">
                <app-select [inputId]="Field.SEALS_EXTERNAL +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.SEAL_EXTERNAL_COLOR' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [disabled]="readOnlyMode"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.SEALS_EXTERNAL)"
                            [ngModel]="drawingData.specification.sealExternalId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.SEALS_EXTERNAL) ||
                                (drawingData.specification.sealExternalId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.sealExternalId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.SEALS_EXTERNAL)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.SEALS_EXTERNAL)"></app-select>
            </ng-container>
        </div>

        <!--Seals - internal color-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.SEALS_INTERNAL)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.SEALS_INTERNAL) | async as items">
                <app-select [inputId]="Field.SEALS_INTERNAL +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.SEAL_INTERNAL_COLOR' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [required]="false"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.SEALS_INTERNAL)"
                            [ngModel]="drawingData.specification.sealInternalId" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.SEALS_INTERNAL) ||
                                (drawingData.specification.sealInternalId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.sealInternalId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.SEALS_INTERNAL)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.SEALS_INTERNAL)"></app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.TERRACE_GLAZING_PACKAGE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.TERRACE_GLAZING_PACKAGE) | async as items">
                <app-select [inputId]="Field.TERRACE_GLAZING_PACKAGE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FILLING.TERRACE_GLAZING_PACKAGE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)" [required]="true"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.TERRACE_GLAZING_PACKAGE)"
                            [ngModel]="drawingData.specification.terraceGlazingPackageId" [disabled]="readOnlyMode || modelMode"
                            [hasValidationError]="fieldUsage.required(Field.TERRACE_GLAZING_PACKAGE) ||
                                (drawingData.specification.terraceGlazingPackageId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.terraceGlazingPackageId | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.TERRACE_GLAZING_PACKAGE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.TERRACE_GLAZING_PACKAGE)"></app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.TERRACE_COLOR)">
            <ng-container *vLet="terraceColors | async as items">
                <app-select *ngIf="colors" [inputId]="Field.TERRACE_COLOR +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.COLOR.TERRACE_COLOR' | translate }}"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (ngModelChange)="handleColorSelected($event, Field.TERRACE_COLOR)"
                            [ngModel]="drawingData.specification.colorIdExternal" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.TERRACE_COLOR) ||
                                (drawingData.specification.colorIdExternal | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.colorIdExternal | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [disabled]="readOnlyMode" [translateLabels]="true" [allowSelectingNone]="false"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.TERRACE_COLOR)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.TERRACE_COLOR)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.colorIdInternal == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getColorImage(drawingData.specification.colorIdInternal), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--FITTING_LOCK_TERRACE-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_LOCK_TERRACE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_LOCK_TERRACE) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_LOCK_TERRACE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_LOCK_TERRACE)"
                            [value]="drawingData.specification.fittingLockTerrace.addonId"
                            [required]="false" [disabled]="readOnlyMode"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_LOCK_TERRACE) ||
                                (drawingData.specification.fittingLockTerrace.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingLockTerrace.addonId | isNotAvailable:items:true"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_LOCK_TERRACE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_LOCK_TERRACE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_LOCK_TERRACE_LOCATION)">
            <app-select [inputId]="Field.FITTING_LOCK_TERRACE_LOCATION +'_id'" label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE_LOCATION' | translate }}"
                        [options]="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_LOCK_TERRACE_LOCATION) | async"
                        (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                        (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                        (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FITTING_LOCK_TERRACE_LOCATION)"
                        [ngModel]="drawingData.specification.fittingLockTerraceLocation" [required]="false"
                        [hasValidationError]="fieldUsage.required(Field.FITTING_LOCK_TERRACE_LOCATION)" [disabled]="readOnlyMode"
                        [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_LOCK_TERRACE_LOCATION)}"
                        [translateLabels]="true"
                        (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_LOCK_TERRACE_LOCATION)"></app-select>
        </div>

    </p-accordionTab>
    <!--Color-->
    <p-accordionTab
        header="{{ 'OFFER.TABS.SECTION.COLOR.SECTION_TITLE' | translate }}"
        *ngIf="fieldUsage.showSection(SidebarSection.COLOR)" tabindex="-1"
        id="colorSectionAccordion" name='colorSectionAccordion'
        [ngClass]="getSectionErrorClass(SidebarSection.COLOR)">

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.CORE_COLOR)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.CORE_COLOR) | async as items">
                <app-select *ngIf="colors" [inputId]="Field.CORE_COLOR +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.COLOR.CORE_COLOR' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="handleColorSelected($event, Field.CORE_COLOR)"
                            [ngModel]="drawingData.specification.colorIdCore" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.CORE_COLOR) || (drawingData.specification.colorIdCore | isNotAvailable:items:isWebshopCharge)"
                            [disabled]="readOnlyMode" [noLongerAvailable]="drawingData.specification.colorIdCore | isNotAvailable:items:isWebshopCharge"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.CORE_COLOR)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.CORE_COLOR)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.colorIdCore == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getColorImage(drawingData.specification.colorIdCore), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.EXTERNAL_COLOR)">
            <ng-container *vLet="externalColors | async as items">
                <div class="input-with-action-container">
                    <app-select *ngIf="colors" [inputId]="Field.EXTERNAL_COLOR +'_id'"
                                label="{{ 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR' | translate }}"
                                [options]="items"
                                (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                                (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                                (onFocus)="onSelectFocus($event)" [translateLabels]="true"
                                (ngModelChange)="handleColorSelected($event, Field.EXTERNAL_COLOR)"
                                [ngModel]="drawingData.specification.colorIdExternal" [required]="false"
                                [hasValidationError]="fieldUsage.required(Field.EXTERNAL_COLOR) ||
                                    (drawingData.specification.colorIdExternal | isNotAvailable:items:true)" [allowSelectingNone]="true"
                                [disabled]="readOnlyMode" [noLongerAvailable]="drawingData.specification.colorIdExternal | isNotAvailable:items:true"
                                [checkAvailability]="true"
                                [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.EXTERNAL_COLOR)}"
                                (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.EXTERNAL_COLOR)">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [disabled]="drawingData.specification.colorIdExternal == undefined"
                                                     (onShowImage)="handleShowImage(imageService.getColorImage(drawingData.specification.colorIdExternal), label)"></app-sidebar-field-label>
                        </ng-template>
                    </app-select>
                </div>
            </ng-container>

            <div *ngIf="colors" class="new-form-field flex-row input-action-button-container">
                <app-simple-button buttonId="applyExternalToInternalColorButton"
                                   label="{{ 'OFFER.TABS.SECTION.MAIN.APPPLY_EXTERNAL_TO_INTERNAL_COLOR' | translate }}"
                                   [inlineLabel]="false" icon="arrow_downward" type="filter" align="right" [disabled]="readOnlyMode"
                                   (onClick)="applyExternalToInternalColor()"></app-simple-button>
            </div>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.EXTERNAL_COLOR_OTHER_INFO)">
            <app-input-text [inputId]="Field.EXTERNAL_COLOR_OTHER_INFO +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR_OTHER_INFO' | translate }}"
                            [maxlength]="35" [required]="true" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.EXTERNAL_COLOR_OTHER_INFO)"
                            [(ngModel)]="drawingData.specification.colorOtherInfoExternal"></app-input-text>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.INTERNAL_COLOR)">
            <ng-container *vLet="internalColors | async as items">
                <app-select *ngIf="colors" [inputId]="Field.INTERNAL_COLOR +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR' | translate }}"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (ngModelChange)="handleColorSelected($event, Field.INTERNAL_COLOR)"
                            [ngModel]="drawingData.specification.colorIdInternal" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.INTERNAL_COLOR) ||
                                (drawingData.specification.colorIdInternal | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.colorIdInternal | isNotAvailable:items:true"
                            [checkAvailability]="true"
                            [allowSelectingNone]="true" [disabled]="readOnlyMode" [translateLabels]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.INTERNAL_COLOR)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.INTERNAL_COLOR)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="drawingData.specification.colorIdInternal == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getColorImage(drawingData.specification.colorIdInternal), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.INTERNAL_COLOR_OTHER_INFO)">
            <app-input-text [inputId]="Field.INTERNAL_COLOR_OTHER_INFO +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR_OTHER_INFO' | translate }}"
                            [maxlength]="35" [required]="true" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.INTERNAL_COLOR_OTHER_INFO)"
                            [(ngModel)]="drawingData.specification.colorOtherInfoInternal"></app-input-text>
        </div>

    </p-accordionTab>
    <!--Filling-->
    <p-accordionTab
        header="{{ 'OFFER.TABS.SECTION.FILLING.SECTION_TITLE' | translate }}"
        *ngIf="fieldUsage.showSection(SidebarSection.FILLING) && !fieldUsage.show(Field.TERRACE_GLAZING_PACKAGE)"
        id="fillingAccordion" name='fillingAccordion'
        [ngClass]="getSectionErrorClass(SidebarSection.FILLING)">

        <!--Filling type-->
        <div class="new-form-row">
            <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_W_MUNTINS)" [inputId]="Field.FILLING_TYPE_W_MUNTINS +'_id'"
                        label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                        [options]="fillingTypesGlassOnly" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_TYPE_W_MUNTINS)"
                        [ngModel]="commonData.fillingType" [required]="false" [disabled]="readOnlyMode || modelMode"
                        [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE)" [translateLabels]="true"
                        [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>

            <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_WO_MUNTINS)" [inputId]="Field.FILLING_TYPE_WO_MUNTINS +'_id'"
                        label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                        [options]="fillingTypes" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_TYPE_WO_MUNTINS)"
                        [ngModel]="commonData.fillingType" [required]="false" [disabled]="readOnlyMode || modelMode"
                        [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE)" [translateLabels]="true"
                        [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>

            <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS)" [inputId]="Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS +'_id'"
                        label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                        [options]="fillingTypesWithoutDecorativeFilling" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS)"
                        [ngModel]="commonData.fillingType" [required]="false" [disabled]="readOnlyMode || modelMode"
                        [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE)" [translateLabels]="true"
                        [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>
        </div>

        <!--Filling width-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_WIDTH)">
            <app-select [inputId]="Field.FILLING_WIDTH +'_id'"
                        label="{{ 'OFFER.TABS.SECTION.FILLING.WIDTH' | translate }}"
                        [options]="systemGlazingWidthItems" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_WIDTH)"
                        [hasValidationError]="fieldUsage.required(Field.FILLING_WIDTH)" [disabled]="readOnlyMode"
                        [ngModel]="commonData.fillingWidth" [required]="false"></app-select>
        </div>

        <!--Filling name-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_NAME)">

            <!--Filling name - external-->
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FILLING_NAME_EXTERNAL) | async as items">
                <app-select *ngIf="fieldUsage.show(Field.FILLING_NAME_EXTERNAL)" [inputId]="Field.FILLING_NAME_EXTERNAL +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_NAME_EXTERNAL)"
                            [ngModel]="commonData.fillingId" [required]="false" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.FILLING_NAME_EXTERNAL) ||
                                (commonData.fillingId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="commonData.fillingId | isNotAvailable:items:isWebshopCharge" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FILLING_NAME)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FILLING_NAME)"></app-select>
            </ng-container>

            <!--Filling name - internal-->

            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FILLING_NAME_INTERNAL) | async as items">
                <app-select *ngIf="fieldUsage.show(Field.FILLING_NAME_INTERNAL)" [inputId]="Field.FILLING_NAME_INTERNAL +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.FILLING_NAME_INTERNAL)"
                            [ngModel]="commonData.fillingId" [required]="false" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.FILLING_NAME_INTERNAL) ||
                                (commonData.fillingId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="commonData.fillingId | isNotAvailable:items:isWebshopCharge" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FILLING_NAME_INTERNAL)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FILLING_NAME_INTERNAL)"></app-select>
            </ng-container>

            <!--Filling name - decorative filling-->
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.DECORATIVE_FILLING) | async as items">
                <app-select *ngIf="fieldUsage.show(Field.DECORATIVE_FILLING)" [inputId]="Field.DECORATIVE_FILLING +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.DECORATIVE_FILLING)"
                            [ngModel]="commonData.decorativeFillingId" [required]="false" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.DECORATIVE_FILLING) ||
                                (commonData.decorativeFillingId | isNotAvailable:items:true)"
                            [noLongerAvailable]="commonData.decorativeFillingId | isNotAvailable:items:true"
                            [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.DECORATIVE_FILLING)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.DECORATIVE_FILLING)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="commonData.decorativeFillingId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getDecorativeFillingImage(commonData.decorativeFillingId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!--Fillings external color-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_EXTERNAL_COLOR)">
            <div class="input-with-action-container">
                <app-select label="{{ 'OFFER.TABS.SECTION.FILLING.EXTERNAL_COLOR' | translate }}" [inputId]="Field.FILLING_EXTERNAL_COLOR +'_id'"
                            [options]="getColorsForFilling(FillingColorType.EXTERNAL)" (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="handleColorSelected($event != undefined ? $event.id : undefined, Field.FILLING_EXTERNAL_COLOR)"
                            [ngModel]="commonData.externalColorId" [required]="false" [allowSelectingNone]="true"
                            [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                            [optionKey]="selectItemFormatters.colorOptionKey" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.FILLING_EXTERNAL_COLOR)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="commonData.externalColorId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getColorImage(commonData.externalColorId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </div>

            <div *ngIf="fieldUsage.show(Field.FILLING_INTERNAL_COLOR)" class="new-form-field flex-row input-action-button-container">
                <app-simple-button buttonId="applyFillingExternalToInternalColorButton" [disabled]="readOnlyMode"
                                   label="{{ 'OFFER.TABS.SECTION.MAIN.APPPLY_EXTERNAL_TO_INTERNAL_COLOR' | translate }}"
                                   [inlineLabel]="false" icon="arrow_downward" type="filter" [size]="40" align="right"
                                   (onClick)="applyFillingExternalToInternalColor()"></app-simple-button>
            </div>
        </div>

        <!--Fillings internal color-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_INTERNAL_COLOR)">
            <app-select label="{{ 'OFFER.TABS.SECTION.FILLING.INTERNAL_COLOR' | translate }}" [inputId]="Field.FILLING_INTERNAL_COLOR +'_id'"
                        [options]="getColorsForFilling(FillingColorType.INTERNAL)" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="handleColorSelected($event != undefined ? $event.id : undefined, Field.FILLING_INTERNAL_COLOR)"
                        [ngModel]="commonData.internalColorId" [required]="false" [allowSelectingNone]="true"
                        [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                        [optionKey]="selectItemFormatters.colorOptionKey" [disabled]="readOnlyMode"
                        [hasValidationError]="fieldUsage.required(Field.FILLING_INTERNAL_COLOR)">
                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                             [disabled]="commonData.internalColorId == undefined"
                                             (onShowImage)="handleShowImage(imageService.getColorImage(commonData.internalColorId), label)"></app-sidebar-field-label>
                </ng-template>
            </app-select>
        </div>

        <!--Model glazing package-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.WEBSHOP_GLAZING_PACKAGE)">
            <app-select label="{{ 'OFFER.TABS.SECTION.FILLING.WEBSHOP_GLAZING_PACKAGE' | translate }}"
                        [inputId]="Field.WEBSHOP_GLAZING_PACKAGE +'_id'"
                        [options]="availableWebshopGlazingPackages" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnSetWebshopGlazingPackage($event);" [disabled]="readOnlyMode"
                        [ngModel]="selectedWebshopGlazingPackageId" [required]="false"
                        [hasValidationError]="fieldUsage.required(Field.WEBSHOP_GLAZING_PACKAGE)"
                        [optionFormatter]="selectItemFormatters.glazingPackageOptionFormatter">
            </app-select>
        </div>

        <!--Glass settings-->
        <app-glass-selection #glassSelection [id]="Field.GLASS_SELECTOR + '_id'"
                             *ngIf="fieldUsage.show(Field.GLASS_SELECTOR)" [commonData]="commonData" [frames]="frames"
                             [glasses]="glasses" [decorative]="isDecorativeFillingType(commonData.fillingType)"
                             [glazingGlassNumber]="isDecorativeFillingType(commonData.fillingType) ? availableGlassCountsForDecor : availableGlassCounts"
                             (focus)="onSelectFocus($event)" [readOnlyMode]="readOnlyMode" [modelMode]="modelMode" [sidebarOnlyMode]="sidebarOnlyMode"
                             [validateInstantly]="false" [glazingWidths]="getGlazingWidths()"
                             [showImagePreviewButtons]="true" [checkAvailability]="true"
                             (glassQuantityEmitter)="emitOnSetAllGlazingGlassQuantity($event)"
                             (frameChangeEmitter)="emitOnSetAllFrames($event)"
                             (glassChangeEmitter)="emitOnSetAllGlassType($event)"
                             (beforeGeneralGlassUpdate)="emitOnGeneralTabValueChange($event, Field.GLASS_SELECTOR)"
                             (onShowGlassImage)="handleShowImage(imageService.getGlassImage($event.id), $event.header)">
        </app-glass-selection>

        <!--Glazing bead-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.GLAZING_BEAD)">
            <app-select [inputId]="Field.GLAZING_BEAD + '_id'" label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD' | translate }}"
                        [options]="filteredGlazingBeads" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="emitOnGeneralTabValueChange($event, Field.GLAZING_BEAD)"
                        [ngModel]="commonData.glazingBeadId" [required]="false" [disabled]="readOnlyMode"
                        [hasValidationError]="fieldUsage.required(Field.GLAZING_BEAD) ||
                            (commonData.glazingBeadId | isNotAvailable:glazingBeadsSelectItems:true)"
                        [noLongerAvailable]="commonData.glazingBeadId | isNotAvailable:glazingBeadsSelectItems:true"
                        [checkAvailability]="true"
                        [optionFormatter]="selectItemFormatters.glazingBeadOptionFormatter">
                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                             [disabled]="commonData.glazingBeadId == undefined"
                                             (onShowImage)="handleShowImage(imageService.getGlazingBeadImage(commonData.glazingBeadId), label)"></app-sidebar-field-label>
                </ng-template>
            </app-select>
        </div>

        <app-predefined-glazing-selection
            *ngIf="fieldUsage.show(Field.PREDEFINED_GLAZING_PACKAGE)"
            [readOnlyMode]="readOnlyMode"
            (selectFocus)="onSelectFocus($event)"
            [quantityOptions]="windowEditorFieldContentProvider.getItemsStream(Field.GLAZING_PACKAGE_QUANTITY) | async"
            [selectedQuantity]="commonData.glazingGlassQuantity"
            [quantityNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_QUANTITY)"
            [quantityHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_QUANTITY)"
            (quantityChanged)="emitOnGeneralTabValueChange($event, Field.GLAZING_PACKAGE_QUANTITY)"
            (quantityClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_QUANTITY)"
            [categoryOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackageCategoryFieldId(commonData.glazingGlassQuantity)) | async"
            [glazingCategoryId]="commonData.glazingCategoryId"
            [categoryNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_CATEGORY)"
            [categoryHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_CATEGORY)"
            (categoryChanged)="emitOnGeneralTabValueChange($event, Field.GLAZING_PACKAGE_CATEGORY)"
            (categoryClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_CATEGORY)"
            [frameCategoryOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackageFrameCategoryFieldId(commonData.glazingGlassQuantity)) | async"
            [glazingFrameCategoryId]="commonData.glazingFrameCategoryId"
            [frameCategoryNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
            [frameCategoryHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
            (frameCategoryChanged)="emitOnGeneralTabValueChange($event, Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
            (frameCategoryClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
            [glazingPackageOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackagesFieldId(commonData.glazingGlassQuantity, commonData.glazingCategoryId, commonData.glazingFrameCategoryId)) | async"
            [glazingPackageId]="commonData.glazingPackageId"
            [glazingPackageNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE)"
            [glazingPackageHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE)"
            (glazingPackageChanged)="emitOnGeneralTabValueChange($event, Field.GLAZING_PACKAGE)"
            (glazingPackageClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE)"
            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()">
        </app-predefined-glazing-selection>
    </p-accordionTab>

    <!--Grills-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.GRILL.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.MUNTINS)" id="muntinAccordion" name='muntinAccordion'
                    [ngClass]="getSectionErrorClass(SidebarSection.MUNTINS)">

        <!-- GRILL NAME -->
        <div class="new-form-row">
            <ng-container *vLet="filteredGrills | async as items">
                <app-select [inputId]="Field.GRILL +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.GRILL.GRILL_NAME' | translate }}"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="emitOnGlobalGrillChange($event, false, false)" [disabled]="readOnlyMode"
                            [ngModel]="commonData['grillId']" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.GRILL) ||
                                (commonData['grillId'] | isNotAvailable:items:true)"
                            [noLongerAvailable]="commonData['grillId'] | isNotAvailable:items:true" [checkAvailability]="true">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="commonData.grillId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getGrillImage(commonData.grillId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!-- GRILL COLOR -->
        <div class="new-form-row">
            <app-select label="{{ 'OFFER.TABS.SECTION.GRILL.GRILL_COLOR' | translate }}"
                        [inputId]="Field.GRILL_COLOR +'_id'"
                        [options]="intersectingGrillColors" (onFocus)="onSelectFocus($event)"
                        (ngModelChange)="handleColorSelected($event != undefined ? $event.id : undefined, Field.GRILL_COLOR)"
                        [ngModel]="commonData['grillColorId']" [required]="false" [disabled]="readOnlyMode"
                        [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                        [translateLabels]="true"
                        [hasValidationError]="fieldUsage.required(Field.GRILL_COLOR)"
                        [optionKey]="selectItemFormatters.colorOptionKey">
                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                             [disabled]="commonData.grillColorId == undefined"
                                             (onShowImage)="handleShowImage(imageService.getColorImage(commonData.grillColorId), label)"></app-sidebar-field-label>
                </ng-template>
            </app-select>
        </div>
    </p-accordionTab>

    <!--Mullions-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.MULLION.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.MULLIONS)" id="mullionAccordion"
                    [ngClass]="getSectionErrorClass(SidebarSection.MULLIONS)"
                    name='mullionAccordion'>

        <div class="new-form-row" *ngIf="fieldUsage.show(Field.MULLION)">
            <ng-container *vLet="filteredMullions | async as items">
                <app-select label="{{ 'OFFER.TABS.SECTION.MULLION.MULLION' | translate }}"
                            [inputId]="Field.MULLION +'_id'"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (ngModelChange)="emitOnGeneralTabValueChange($event, Field.MULLION)"
                            [ngModel]="commonData.mullionId" [required]="false" [disabled]="readOnlyMode"
                            [hasValidationError]="fieldUsage.required(Field.MULLION) ||
                                (commonData.mullionId | isNotAvailable:items:true)"
                            [noLongerAvailable]="commonData.mullionId | isNotAvailable:items:true" [checkAvailability]="true">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 [disabled]="commonData.mullionId == undefined"
                                                 (onShowImage)="handleShowImage(imageService.getProfileImage(commonData.mullionId), label)"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>
    </p-accordionTab>

    <!--Fittings-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.FITTINGS.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.FITTINGS)"
                    [ngClass]="getSectionErrorClass(SidebarSection.FITTINGS)"
                    id="fittingsAccordion" name='fittingsAccordion'>

        <!--FITTING_BRAKE-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_BRAKE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_BRAKE) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_BRAKE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_BRAKE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_BRAKE)"
                            [value]="drawingData.specification.fittingBrake.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_BRAKE) ||
                                (drawingData.specification.fittingBrake.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingBrake.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_BRAKE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_BRAKE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_SLIDING-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_SLIDING)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_SLIDING) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_SLIDING +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_SLIDING' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_SLIDING)"
                            [value]="drawingData.specification.fittingSliding.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_STANDARD' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_SLIDING) ||
                                (drawingData.specification.fittingSliding.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingSliding.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_SLIDING)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_SLIDING)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_TYPE-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_TYPE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_TYPE) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_TYPE + '_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_TYPE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_TYPE)"
                            [value]="drawingData.specification.fittingType.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_STANDARD' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_TYPE) ||
                                (drawingData.specification.fittingType.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingType.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_TYPE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_TYPE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_ESPAGNOLETTE_TYPE-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_ESPAGNOLETTE_TYPE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_ESPAGNOLETTE_TYPE) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_ESPAGNOLETTE_TYPE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_ESPAGNOLETTE_TYPE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_ESPAGNOLETTE_TYPE)"
                            [value]="drawingData.specification.fittingEspagnoletteType.addonId" [required]="true"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_ESPAGNOLETTE_TYPE) ||
                                (drawingData.specification.fittingEspagnoletteType.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.fittingEspagnoletteType.addonId | isNotAvailable:items:isWebshopCharge"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_ESPAGNOLETTE_TYPE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_ESPAGNOLETTE_TYPE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_VERANDA-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_VERANDA)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_VERANDA) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_VERANDA +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_VERANDA' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_VERANDA)"
                            [value]="drawingData.specification.fittingVeranda.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_VERANDA) ||
                                (drawingData.specification.fittingVeranda.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingVeranda.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_VERANDA)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_VERANDA)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_INSERTION-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_INSERTION)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_INSERTION) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_INSERTION +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_INSERTION' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_INSERTION)"
                            [value]="drawingData.specification.fittingInsertion.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_INSERTION) ||
                                (drawingData.specification.fittingInsertion.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingInsertion.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_INSERTION)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_INSERTION)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_MAIN_INSERTION-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_MAIN_INSERTION)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_MAIN_INSERTION) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_MAIN_INSERTION +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_MAIN_INSERTION' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_MAIN_INSERTION)"
                            [value]="drawingData.specification.fittingMainInsertion.addonId" [required]="true"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_MAIN_INSERTION) ||
                                (drawingData.specification.fittingMainInsertion.addonId | isNotAvailable:items:isWebshopCharge)"
                            [noLongerAvailable]="drawingData.specification.fittingMainInsertion.addonId | isNotAvailable:items:isWebshopCharge"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_MAIN_INSERTION)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_MAIN_INSERTION)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_ADDITIONAL_INSERTION-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_ADDITIONAL_INSERTION)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_ADDITIONAL_INSERTION) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_ADDITIONAL_INSERTION +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_ADDITIONAL_INSERTION' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_ADDITIONAL_INSERTION)"
                            [value]="drawingData.specification.fittingAdditionalInsertion.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_ADDITIONAL_INSERTION) ||
                                (drawingData.specification.fittingAdditionalInsertion.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingAdditionalInsertion.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_ADDITIONAL_INSERTION)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_ADDITIONAL_INSERTION)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_LOCK-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_LOCK)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_LOCK) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_LOCK +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_LOCK)"
                            [value]="drawingData.specification.fittingLock.addonId" [required]="false"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_LOCK) ||
                                (drawingData.specification.fittingLock.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingLock.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_LOCK)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_LOCK)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!--FITTING_AUTOMATIC_DRIVE-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.FITTING_AUTOMATIC_DRIVE)">
            <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FITTING_AUTOMATIC_DRIVE) | async as items">
                <app-select-or-checkbox [inputId]="Field.FITTING_AUTOMATIC_DRIVE +'_id'"
                            label="{{ 'OFFER.TABS.SECTION.FITTINGS.FITTING_AUTOMATIC_DRIVE' | translate }}"
                            [options]="items"
                            (beginInvalidValueCorrection)="handleBeginSelectInvalidValueCorrection($event)"
                            (endInvalidValueCorrection)="handleEndSelectInvalidValueCorrection()"
                            (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnGeneralTabValueChange($event, Field.FITTING_AUTOMATIC_DRIVE)"
                            [value]="drawingData.specification.fittingAutomaticDrive && drawingData.specification.fittingAutomaticDrive.addonId"
                            [required]="false" [allowSelectingNone]="true"
                            placeholder="{{ 'GENERAL.OPTION_STANDARD' | translate }}"
                            [hasValidationError]="fieldUsage.required(Field.FITTING_AUTOMATIC_DRIVE) ||
                                (drawingData.specification.fittingAutomaticDrive?.addonId | isNotAvailable:items:true)"
                            [noLongerAvailable]="drawingData.specification.fittingAutomaticDrive?.addonId | isNotAvailable:items:true"
                            [disabled]="readOnlyMode" [checkAvailability]="true"
                            [ngClass]="{'has-new-options': windowEditorFieldContentProvider.hasNewItems(Field.FITTING_AUTOMATIC_DRIVE)}"
                            (onClick)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.FITTING_AUTOMATIC_DRIVE)">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

    </p-accordionTab>

    <!--Addons-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.ADDONS.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.ADDONS)"
                    id="addonsAccordion" name='addonsAccordion' class="p-accordion-button"
                    [disabled]="isAddonsTabViewButtonDisabled()" (click)="showAddonsTabView()">
    </p-accordionTab>

    <!--Configurable addons-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.CONFIGURABLE_ADDONS.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.CONFIGURABLE_ADDONS)"
                    id="configurableAddonsAccordion" name='configurableAddonsAccordion'
                    class="p-accordion-button" (click)="showConfigAddonsList()">
    </p-accordionTab>

    <!--Description-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.DESCRIPTION.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.DESCRIPTION)"
                    id="descriptionAccordion" name='descriptionAccordion'
                    class="p-accordion-button" (click)="showDescriptionDialog()">
    </p-accordionTab>

    <!--Global settings-->
    <p-accordionTab *ngIf="showGlobalSettingsTab()" header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}"
                    id="globalSettingsAccordion" name='globalSettingsAccordion'>

        <div class="new-form-row">
            <app-select inputId="globalSettingsSelect"
                        [(validationMessageKey)]="windowSystemDefaultsValidation['level']"
                        [options]="windowSystemDefaultsLevels" [required]="false" (onFocus)="onSelectFocus($event)"
                        [ngModel]="windowSystemDefaultsLevel" [translateLabels]="true"
                        (ngModelChange)="emitOnWindowSystemDefaultsLevelChange($event)">
                <ng-template pTemplate="label"></ng-template>
            </app-select>
        </div>

        <div class="new-form-row" *ngIf="!sidebarOnlyMode">
            <app-checkbox inputId="globalSettingsOverrideLowerLevels"
                          label="{{ 'OFFER.TABS.SECTION.DEFAULTS.OVERRIDE' | translate }}"
                          [ngModel]="windowSystemDefaultsOverrideLowerLevel"
                          (ngModelChange)="emitWindowSystemDefaultsOverrideLowerLevelChange($event)"></app-checkbox>
        </div>

        <div class="new-form-row">
            <div class="new-form-field">
                <app-simple-button id="globalSettingsSaveButtonContainer" buttonId="globalSettingsSaveButton"
                                   label="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" type="main-action"
                                   [size]="40" fixedWidth="100%" (onClick)="emitOnSetDefaultValuesButtonClick()"
                                   (onFocus)="onSelectFocus($event)"></app-simple-button>
            </div>
        </div>

        <div *ngIf="drawingData.usedGlobalSettingsLevel && !drawingData.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }} - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + drawingData.usedGlobalSettingsLevel | translate }}
            </div>
        </div>

        <div *ngIf="drawingData.usedGlobalSettingsLevel && drawingData.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }} - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + drawingData.usedGlobalSettingsLevel | translate }} - {{ 'OFFER.TABS.SECTION.DEFAULTS.MODIFIED' | translate }}
            </div>
        </div>
    </p-accordionTab>
</p-accordion>


<div *ngIf="modelMode" class="modelsButtonsContainer">
    <app-simple-button id="modelCancelButtonContainer" buttonId="modelCancelButton" fixedWidth="45%"
                       label="{{ 'GENERAL.CANCEL' | translate }}" type="action" [size]="40"
                       align="right" (onClick)="emitOnCancelModel()"></app-simple-button>

    <app-simple-button id="modelSaveButtonContainer" buttonId="modelSaveButton" fixedWidth="45%"
                       label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                       align="right" (onClick)="emitOnSetDefaultValuesButtonClick()"></app-simple-button>

</div>

<div *ngIf="isDataModificationMode()" class="chargesButtonsContainer">
    <app-simple-button id="chargeCancelButtonContainer" buttonId="chargeCancelButton" fixedWidth="45%"
                       label="{{ 'GENERAL.CANCEL' | translate }}" type="action" [size]="40"
                       align="right" (onClick)="emitOnCancelWebshopCharge()"></app-simple-button>

    <app-simple-button id="chargeSaveButtonContainer" buttonId="chargeSaveButton" fixedWidth="45%"
                       label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                       align="right" (onClick)="emitOnSetDefaultValuesButtonClick()"></app-simple-button>

</div>

<app-ral-color-selection-dialog *ngIf="selectingRalColors != undefined" [header]="selectingRalColorHeader | translate"
                                [colors]="selectingRalColors" (onColorSelect)="handleRalColorSelected($event)"
                                (onCancel)="handleRalColorSelectionCancel()"></app-ral-color-selection-dialog>

<app-terrace-handle-layout-selection-dialog *ngIf="displayTerraceHandleLocationDialog"
                                            [terraceWindow]="drawingData?.windows[0]"
                                            [terraceHandleLayout]="drawingData.specification.terraceHandleLayout"
                                            (terraceHandleLayoutChange)="emitOnGeneralTabValueChange($event, Field.TERRACE_HANDLE_LAYOUT)"
                                            (onClose)="displayTerraceHandleLocationDialog = false"></app-terrace-handle-layout-selection-dialog>
