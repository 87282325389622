import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription} from 'rxjs';
import {ApplicationFeatures} from "../../application-info/application-features";
import {Permissions} from '../../auth/permission.service';
import {NavigationLink} from '../../NavigationLink';
import {SidenavController} from '../../sidenav-controller';
import {SubsystemService} from '../subsystem/subsystem.service';

type SettingsOption = 'user' | 'subsystem' | 'sellerSettingsItem' | 'subsystemWebshopInfo' | 'subsystemWebshopMailConfiguration'
    | 'webshopCustomPages' | 'windowSystemDefaults' | 'configurableAddonGlobalSettingsItem' | 'venskaData' | 'templates'
    | 'documentTemplates' | 'termsOfUse' | 'news' | 'webshopHelp' | 'webshopGlazingHelp'
    | 'webshopSizingHelp' | 'windowUpsellingSettingsPerSubsystem' | 'printoutData' | 'webshopUiTranslations' | 'promotionInformation'
    | 'promotionCode' | 'partnerWebshopInfo' | 'gateSystemDefaults' | 'venskaPasswordResetMailSettings' | 'offerStatusChangeMailSettings'
    | 'subsystemSalesTarget';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css', '../../second-level-menu.css'],
    providers: [SubsystemService]
})
export class SettingsComponent implements OnInit, OnDestroy {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItemsByGroup: { groupName: string, items: NavigationLink<SettingsOption>[] }[] = [];
    sideNavItems: NavigationLink<SettingsOption>[] = [];

    sideNavItemsTranslateSubscription: Subscription;

    webshopEnabledForSubsystem = false;
    webshopPartnerEnabledForSubsystem = false;
    appFeatures: ApplicationFeatures;

    constructor(public permissions: Permissions,
                private route: ActivatedRoute,
                private router: Router,
                private sidenavController: SidenavController,
                private changeDetector: ChangeDetectorRef,
                private translate: TranslateService,
                private subsystemService: SubsystemService) {
    }

    ngOnInit(): void {
        this.appFeatures = this.route.snapshot.data['features'] as ApplicationFeatures;
        forkJoin({
            webshopEnabled: this.subsystemService.isWebshopEnabledForCurrentUserSubsystem(),
            webshopPartnerEnabled: this.subsystemService.currentUserSubsystemHasPartners()
        }).subscribe({
            next: result => {
                this.webshopEnabledForSubsystem = result.webshopEnabled;
                this.webshopPartnerEnabledForSubsystem = result.webshopPartnerEnabled;
                this.initialize();
            }
        });

        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.changeDetector.markForCheck();
        });
    }

    private initialize(): void {
        this.prepareSideNavItems();
        this.initTranslationSubscription();
        this.route.url.subscribe(url => {
            if (!this.route.firstChild) {
                this.router.navigate(['.', this.sideNavItems[0].cssClass], {relativeTo: this.route, replaceUrl: true});
            }
        });
        this.changeDetector.markForCheck();
    }

    ngOnDestroy(): void {
        if (this.showMenuSubscription != undefined) {
            this.showMenuSubscription.unsubscribe();
        }
        if (this.sideNavItemsTranslateSubscription != undefined) {
            this.sideNavItemsTranslateSubscription.unsubscribe();
        }
    }

    prepareSideNavItems() {
        let itemsGroups: { groupName: string, items: NavigationLink<SettingsOption>[] }[] = [];

        let informationItems: NavigationLink<SettingsOption>[] = [
            new NavigationLink('', 'news', '', {roles: []}, 'SETTINGS.SECTION.NEWS.GENERAL'),
            new NavigationLink('', 'termsOfUse', '', {roles: []}, 'SETTINGS.SECTION.TERMS_OF_USE.GENERAL'),
            new NavigationLink('', 'promotionInformation', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.PROMOTION_INFORMATION.GENERAL'),
            new NavigationLink('', 'promotionCode', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.PROMOTION_CODE.GENERAL'),
        ];
        itemsGroups.push({groupName: "INFO", items: this.prepareItems(informationItems)});

        let settingsItems: NavigationLink<SettingsOption>[] = [
            new NavigationLink('', 'subsystem', '', {roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}, 'SETTINGS.SECTION.SUBSYSTEM_INFO'),
            new NavigationLink('', 'sellerSettingsItem', '', {roles: ['ROLE_SPRZEDAWCA']}, 'SETTINGS.SECTION.SELLER_SETTINGS'),
            new NavigationLink('', 'user', '', {roles: []}, 'SETTINGS.SECTION.USER_SETTINGS'),
            new NavigationLink('', 'venskaData', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.VENSKA_DATA.GENERAL'),
            new NavigationLink('', 'venskaPasswordResetMailSettings', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.VENSKA_DATA_PASSWORD_RESET_MAIL_SETTINGS.MENU_LINK'),
            new NavigationLink('', 'offerStatusChangeMailSettings', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.OFFER_STATUS_CHANGE_MAIL_SETTINGS.MENU_LINK'),
            new NavigationLink('', 'templates', '', {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']}, 'SETTINGS.SECTION.TEMPLATES.GENERAL'),
            new NavigationLink('', 'documentTemplates', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.GENERAL'),
            new NavigationLink('', "windowUpsellingSettingsPerSubsystem", "", {roles: ['ROLE_OPERATOR']}, 'SETTINGS.SECTION.WINDOW_UPSELLING_SETTINGS_PER_PODSYSTEM.LIST'),
            new NavigationLink('', "printoutData", "", {roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}, 'SETTINGS.SECTION.PRINTOUT_DATA.GENERAL')
        ];
        itemsGroups.push({groupName: "SETTINGS", items: this.prepareItems(settingsItems)});

        let globalDefaultsItems: NavigationLink<SettingsOption>[] = [
            new NavigationLink('', 'windowSystemDefaults', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.WINDOW_DEFAULTS'),
            new NavigationLink('', 'configurableAddonGlobalSettingsItem', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.CONFIGURABLE_ADDON_DEFAULTS'),
            new NavigationLink('', 'gateSystemDefaults', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.GATE_SYSTEM_DEFAULTS'),
        ];
        itemsGroups.push({groupName: "DEFAULTS", items: this.prepareItems(globalDefaultsItems)});

        // webshop - global for all, ROLE_KOORDYNATOR
        if (this.permissions.isKoordynator()) {
            let webshopItems: NavigationLink<SettingsOption>[] = [
                new NavigationLink('', 'webshopHelp', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.WEBSHOP_HELP.GENERAL'),
                new NavigationLink('', 'webshopGlazingHelp', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.GENERAL'),
                new NavigationLink('', 'webshopSizingHelp', '', {roles: ['ROLE_KOORDYNATOR']}, 'SETTINGS.SECTION.WEBSHOP_SIZING_HELP.GENERAL')
            ];
            itemsGroups.push({groupName: "WEBSHOP", items: this.prepareItems(webshopItems)});
        } else if (this.webshopEnabledForSubsystem) { // webshop - subsystem
            let webshopItems: NavigationLink<SettingsOption>[] = [
                new NavigationLink('', 'subsystemWebshopInfo', '', {roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']}, 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO'),
                new NavigationLink('', 'subsystemWebshopMailConfiguration', '', {roles: ['ROLE_OPERATOR']}, 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION'),
                new NavigationLink('', 'webshopCustomPages', '', {roles: ['ROLE_OPERATOR']}, 'SETTINGS.SECTION.WEBSHOP_CUSTOM_PAGE.GENERAL'),
                new NavigationLink('', 'webshopUiTranslations', '', {roles: ['ROLE_OPERATOR']}, 'TRANSLATIONS.UI_TRANSLATIONS.UI_TRANSLATIONS')
            ];
            itemsGroups.push({groupName: "WEBSHOP", items: this.prepareItems(webshopItems)});
        }

        if (this.webshopPartnerEnabledForSubsystem) {
            const webshopPartnerItems: NavigationLink<SettingsOption>[] = [
                new NavigationLink('', 'partnerWebshopInfo', '', {roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC']}, 'SETTINGS.SECTION.PARTNER_SUBSYSTEM_WEBSHOP_INFO')
            ];
            itemsGroups.push({groupName: "WEBSHOP_PARTNER", items: this.prepareItems(webshopPartnerItems)});
        }

        const reportsItems: NavigationLink<SettingsOption>[] = [];
        if (this.appFeatures.enableSalesTargets) {
            reportsItems.push(
                new NavigationLink('', 'subsystemSalesTarget', '', {roles: ['ROLE_EDIT_SUBSYSTEM_SALES_TARGET']}, 'SETTINGS.SECTION.SUBSYSTEM_SALES_TARGET')
            );
        }
        itemsGroups.push({groupName: "REPORTS", items: this.prepareItems(reportsItems)});

        this.sideNavItems = [];
        this.sideNavItemsByGroup = itemsGroups.filter(group => group.items && group.items.length > 0);
        this.sideNavItems = [].concat.apply([], this.sideNavItemsByGroup.map(group => group.items));
    }

    private prepareItems(navItems: NavigationLink<SettingsOption>[]): NavigationLink<SettingsOption>[] {
        navItems = navItems.filter((item) => {
            return this.permissions.isPermitted(item.roles);
        });
        if (navItems.length < 1) {
            return [];
        }

        this.translate.get(navItems.map(item => item.name)).subscribe(translations => {
            navItems.sort((a, b) => {
                const aName = translations[a.name];
                const bName = translations[b.name];
                return aName.localeCompare(bName);
            });
        });
        return navItems;
    }

    initTranslationSubscription() {
        this.sideNavItemsTranslateSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.sideNavItemsByGroup.forEach(group => {
                group.items.sort((a, b) => {
                    const aName = this.translate.getParsedResult(event.translations, a.name);
                    const bName = this.translate.getParsedResult(event.translations, b.name);
                    return aName.localeCompare(bName);
                });
            });
        });
    }
}
