import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ScopeValidator} from '../../../shared/validator/input-validator';
import {WebshopSale} from './webshop-sale';

@Injectable()
export class WebshopSaleService {

    private static readonly API_URL = 'webshopSale';

    rangeFilterValidator: ScopeValidator;
    private readonly filterValidator: { [filterProperty: string]: (value: any) => boolean };

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        this.rangeFilterValidator = new ScopeValidator();
        this.filterValidator = {
            width: width => this.rangeFilterValidator.isValid(width),
            height: height => this.rangeFilterValidator.isValid(height)
        };
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopSale>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, this.filterValidator);
        return this.http.get<Listing<object>>(WebshopSaleService.API_URL, {params: params}).pipe(
            map(result => Listing.fromJSON(WebshopSale, result))
        );
    }

    saveItem(item: WebshopSale): Observable<number> {
        let url = item.id == null ? WebshopSaleService.API_URL : `${WebshopSaleService.API_URL}/${item.id}`;
        return this.http.post<void>(url, item).pipe(map(() => item.id));
    }

    getItem(itemId: number): Observable<WebshopSale> {
        return this.http.get<object>(`${WebshopSaleService.API_URL}/${itemId}`).pipe(
            map(result => WebshopSale.fromJSON(result))
        );
    }
}
