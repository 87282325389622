<div *ngIf="!isMaintenanceModeOn()" class="flex-row-container">
    <app-growl-message></app-growl-message>
    <app-block-ui *ngIf="isLoggedIn"></app-block-ui>
    <app-hotkeys-help *ngIf="isLoggedIn"></app-hotkeys-help>

    <ng-template #sideNavTemplate>

        <!--Logo-->
        <div id="navigation-venska-logo" routerLink="/features" class="small-logo"></div>

        <div id="mySidenav">
            <div *ngFor="let item of sideNavItems">
                <div class="{{item.cssClass}} navigation-tile" routerLink="{{item.routerLink}}"
                     *ngIf="isPermitted(item.roles) && (item.roles2 == undefined || isPermitted(item.roles2))"
                     [class.active]="menuElementActive(item.routerLink)">
                    <i class="material-icons">{{item.icon}}</i>
                    <div *ngIf="isDisplaySideMenuLabels()" class="name">{{item.name | translate}}</div>
                </div>
            </div>
        </div>

        <div class="spacer"></div>

        <button-with-menu *ngIf="isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                          [menuType]="menuType.SIDEBAR_ICON_BUTTON"
                          [menuElements]="settingsMenuElements">
            <div id="settingsButton" class="navigation-tile">
                <i class="material-icons">settings</i>
            </div>
        </button-with-menu>

        <!--User menu-->
        <button-with-menu [menuType]="menuType.SIDEBAR_ICON_BUTTON"
                          [menuElements]="userMenuElements"
                          (menuElementSelected)="menuElementSelected($event)">
            <div class="navigation-tile" id="userMainMenu">
                <div class="circled" [class.impersonate]="isAnotherLogged">
                    <img src="../assets/images/avatar-blank.jpg" id="user-avatar"/>
                </div>
                <div class="name">{{currentUser}}</div>
            </div>
        </button-with-menu>
    </ng-template>

    <app-navigation-bar *ngIf="isLoggedIn" [defaultTemplate]="sideNavTemplate"></app-navigation-bar>

    <main [ngStyle]="calculateStyleWidth()">
        <router-outlet></router-outlet>
    </main>
</div>

<div *ngIf="isMaintenanceModeOn()">
    <app-maintenance-page></app-maintenance-page>
</div>
