import {PricingFailedField} from "../../../../../errors/errorResponse";

export class ConfigAddonErrorMessages {
    errorCodes: string[];
    failedFields: PricingFailedField[];

    constructor(errorCodes: string[],
                failedFields: PricingFailedField[]) {
        this.errorCodes = errorCodes;
        this.failedFields = failedFields;
    }
}
