<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" (onHide)="cancel()" [modal]="true" [focusOnShow]="false">
        <p-header>
            <div *ngIf="isNew" class="p-dialog-title">
                {{ "SHIPPING_PRICE_TABLES.FORM.ADD-TITLE" | translate }}
            </div>
            <div *ngIf="!isNew" class="p-dialog-title">
                {{ "SHIPPING_PRICE_TABLES.FORM.EDIT-TITLE" | translate }}
            </div>
        </p-header>

        <div class="scrolled-dialog scrolled-dialog-900">
            <div class="price-table-padding">
                <div class="shipping-pricingtable-header-info">
                    <label>{{ 'SHIPPING_PRICE_TABLES.FORM.COUNTRY' | translate }}</label>
                    <label>{{ 'COUNTRIES.' + tableInfo.country | translate }}</label>
                    <label>{{ 'SHIPPING_PRICE_TABLES.FORM.CURRENCY' | translate }}</label>
                    <label>{{ "EUR" }}</label>
                </div>
                <div style="display: flex;">
                    <app-sheet-table
                        [data]="data"
                        [isNew]="isNew"
                        [columns]="columns"
                        [firstColumnHeaderLabel]="true"
                        [columnHeaderLabel]="'SHIPPING_PRICE_TABLES.FORM.CODES' | translate"
                        [readOnly]="!permissions.canEditPricelist()"
                        [validator]="validateCode"
                        [setErrorCode]="setErrorCode"
                        (errorDetected)="setError($event)"></app-sheet-table>
                </div>
            </div>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <div class="new-form-field-error-message profit-margin-error" *ngFor="let key of getValidationErrors()">
                    {{ validationErrors[key] | translate }}
                </div>
                <app-pricing-message *ngIf="error" [message]="error"></app-pricing-message>
                <div *ngIf="permissions.canEditPricelist()">
                    <app-simple-button label="{{ 'PRICE_TABLES.FORM.NEW_COLUMN' | translate }}" icon="create"
                                       type="action" (onClick)="sheetTable.addColumn()"></app-simple-button>
                    <app-simple-button label="{{ 'PRICE_TABLES.FORM.NEW_ROW' | translate }}" icon="create" type="action"
                                       (onClick)="sheetTable.addRow()"></app-simple-button>
                    <app-simple-button label="{{ 'PRICE_TABLES.FORM.DELETE_LAST_COLUMN' | translate }}" icon="create"
                                       type="action" [disabled]="!sheetTable.canDeleteLastColumn() || !activeCellValid"
                                       (onClick)="sheetTable.removeLastColumn()"></app-simple-button>
                    <app-simple-button label="{{ 'PRICE_TABLES.FORM.DELETE_ROW' | translate }}" icon="create"
                                       type="action" [disabled]="!sheetTable.canDeleteSelectedRows() || !activeCellValid"
                                       (onClick)="sheetTable.removeRows()"></app-simple-button>
                </div>
                <div>
                    <app-simple-button *ngIf="permissions.canEditPricelist()" label="{{ 'GENERAL.SAVE' | translate }}"
                                       type="main-action" [size]="40" (onClick)="onSubmit()"></app-simple-button>
                    <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                       (onClick)="cancel()"></app-simple-button>
                </div>
            </div>
        </p-footer>
    </p-dialog>
</div>
