import {Directive, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowEditorField} from '../../../offer/window-editor/window-editor-field';
import {WindowEditorFieldContentProvider} from '../../../offer/window-editor/window-editor-field-content-provider';
import {WindowSystemDefinition} from '../../window-system-definition/window-system-definition';
import {WindowDesignerCatalogDependentOptionsSet} from '../window-designer-catalog-dependent-options-set';
import {WindowDesignerCatalogDependentOption, WindowDesignerCatalogDependentOptionAction} from './window-designer-catalog-dependent-option';

enum WindowEditorFieldInputType {
    NUMBER = 'NUMBER',
    SELECT = 'SELECT'
}

@Directive()
export abstract class WindowDesignerCatalogDependentOptionFormDependencyComponent implements OnInit {

    @Input()
    inputSelectedMap: Map<string, string[]>;

    @Input()
    item: WindowDesignerCatalogDependentOptionsSet;

    @Input()
    options: WindowDesignerCatalogDependentOption[];

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    windowSystems: Observable<WindowSystemDefinition[]>;

    @Input()
    fieldType: Observable<SelectItem[]>;

    @Input()
    dependentFieldType: Observable<SelectItem[]>;

    WindowEditorFieldInputType = WindowEditorFieldInputType;
    inputOptionsMap: Map<WindowEditorField, Observable<SelectItem[]>>;
    selectedNewField: WindowEditorField;
    selectedWhenMatched: WindowDesignerCatalogDependentOptionAction;

    readonly whenMatchedValues: Observable<SelectItem[]>;

    protected constructor(public readonly translate: TranslateService,
                protected readonly translatedSelectItemService: TranslatedSelectItemService,
                protected readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider,
                public readonly isRequiredFieldsForm: boolean) {
        this.whenMatchedValues = this.translatedSelectItemService.buildSortedDropdown(WindowDesignerCatalogDependentOptionAction,
            'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION_ACTION.', undefined);
    }

    ngOnInit(): void {
        this.inputOptionsMap = new Map<WindowEditorField, Observable<SelectItem[]>>();
        this.options.forEach(option => {
            let {inputId, inputValue, whenMatched} = this.getInput(option);
            if (this.inputSelectedMap.has(this.getSelectedMapKey(inputId, whenMatched))) {
                let values = this.inputSelectedMap.get(this.getSelectedMapKey(inputId, whenMatched));
                if (!values.includes(inputValue)) { 
                    values.push(inputValue);
                }
            } else {
                this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), [inputValue]);
                this.inputOptionsMap.set(inputId, this.windowEditorFieldContentProvider.getItemsStream(inputId)
                    .pipe(map(items => items.map(item => (<SelectItem>{label: item.label, value: `${item.value}`})))) || of<SelectItem[]>([]));
            }
        });
    }

    formatMultiselectValues(options: SelectItem[], selected: string) {
        let find = (options || []).find(option => `${option.value}` === `${selected}`);
        return find && find.label;
    }

    abstract getInput(option: WindowDesignerCatalogDependentOption): {inputId: WindowEditorField, inputValue: string, whenMatched: WindowDesignerCatalogDependentOptionAction | null};

    setMultiValue(inputId: WindowEditorField, whenMatched: WindowDesignerCatalogDependentOptionAction, values: string[]) {
        this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), values);
        this.clearErrors();
    }

    remove(inputId: WindowEditorField, whenMatched: WindowDesignerCatalogDependentOptionAction) {
        this.inputSelectedMap.delete(this.getSelectedMapKey(inputId, whenMatched));
        this.inputOptionsMap.delete(inputId);
        this.clearErrors();
    }

    add() {
        let inputId = this.selectedNewField;
        let whenMatched = this.selectedWhenMatched;
        this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), []);
        this.inputOptionsMap.set(inputId, this.windowEditorFieldContentProvider.getItemsStream(inputId) || of<SelectItem[]>([]));
        this.selectedNewField = null;
        this.clearErrors();
    }

    clearErrors() {
        if (this.isRequiredFieldsForm) {
            this.validationErrors['required'] = undefined;
        } else {
            this.validationErrors['dependent'] = undefined;
            this.validationErrors['options'] = undefined;
        }
    }


    handleFieldInputIdChange(field: WindowEditorField): void {
        this.selectedNewField = field;
    }

    handleFieldInputWhenMatchedChange(whenMatched: WindowDesignerCatalogDependentOptionAction): void {
        this.selectedWhenMatched = whenMatched;
    }

    getValueInputType(field: WindowEditorField): WindowEditorFieldInputType {
        switch (field) {
            case WindowEditorField.WIDTH_GREATER_THAN:
            case WindowEditorField.WIDTH_LESS_THAN:
            case WindowEditorField.HEIGHT_GREATER_THAN:
            case WindowEditorField.HEIGHT_LESS_THAN:
                return WindowEditorFieldInputType.NUMBER;
            default:
                break;
        }
        return WindowEditorFieldInputType.SELECT;
    }

    getInputIdFromKey(key: string): WindowEditorField {
        let [inputId, ] = key.split(';');
        return WindowEditorField[inputId];
    }

    getWhenMatchedFromKey(key: string): WindowDesignerCatalogDependentOptionAction {
        let [, whenMatched] = key.split(';');
        return whenMatched && WindowDesignerCatalogDependentOptionAction[whenMatched];
    }

    static decodeMapKey(key: string) {
        let [inputId, whenMatched] = key.split(';');
        return {
            inputId: WindowEditorField[inputId],
            whenMatched: whenMatched && WindowDesignerCatalogDependentOptionAction[whenMatched]
        }
    }

    getSelectedMapKey(inputId: WindowEditorField, whenMatched: WindowDesignerCatalogDependentOptionAction): string {
        return `${inputId};${whenMatched}`;
    }
}
