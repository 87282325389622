import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationServerStatus} from './application-server-status';

@Injectable()
export class ApplicationServerStatusService {

    private static readonly API_URL = 'applicationServerStatus';

    constructor(private readonly http: HttpClient) {
    }

    findAll(): Observable<ApplicationServerStatus[]> {
        return this.http.get<object[]>(ApplicationServerStatusService.API_URL)
            .pipe(map(result => result.map(ApplicationServerStatus.fromJSON)));
    }

    deleteStatus(id: number): Observable<void> {
        return this.http.delete<void>(`${ApplicationServerStatusService.API_URL}/${id}`);
    }
}
