import {Routes} from "@angular/router";
import {ApplicationInfoComponent} from "../application-info/application-info.component";
import {AuthGuard} from "../auth/auth.guard";
import {OfferViewRestrictionGuard} from "../auth/offer-view-restriction.guard";
import {PermissionsGuard} from "../auth/permissions.guard";
import {RequiresExitConfirmationGuard} from "../common/component-exit-confirmation/requires-exit-confirmation-guard.service";
import {RedrawWindowsPageComponent} from "../common/redraw-windows-page/redraw-windows-page.component";
import {AccessData} from "./AccessData";
import {AdminPanelComponent} from "./admin-panel/admin-panel.component";
import {ApplicationResourceComponent} from "./admin-panel/application-resource/application-resource.component";
import {ApplicationStatusComponent} from "./admin-panel/application-status/application-status.component";
import {
    ConfigurableAddonUpsellingSettingsComponent
} from "./admin-panel/configurable-addon-upselling-settings/configurable-addon-upselling-settings.component";
import {EditCatalogPermitsComponent} from "./admin-panel/edit-catalog-permits/edit-catalog-permits.component";
import {ErrorBrowserComponent} from "./admin-panel/error-browser/error-browser.component";
import {MarkProductsComponent} from "./admin-panel/mark-products/mark-products.component";
import {OfferSubsystemCounterComponent} from "./admin-panel/offer-subsystem-counter/offer-subsystem-counter.component";
import {OfferSupplierCounterComponent} from "./admin-panel/offer-supplier-counter/offer-supplier-counter.component";
import {RoleComponent} from "./admin-panel/role/role.component";
import {ScheduledJobComponent} from "./admin-panel/scheduled-job/scheduled-job.component";
import {SubsystemNotesCounterComponent} from "./admin-panel/subsystem-notes-counter/subsystem-notes-counter.component";
import {UpsellingPropositionComponent} from "./admin-panel/upselling-proposition/upselling-proposition.component";
import {UserActivityComponent} from "./admin-panel/user-activity/user-activity-times/user-activity.component";
import {UserLoginTimesComponent} from "./admin-panel/user-activity/user-login-times/user-login-times.component";
import {UserConsentsComponent} from "./admin-panel/user-consents/user-consents.component";
import {CatalogCreatorComponent} from './catalog-creator/catalog-creator.component';
import {ClientGroupComponent} from "./client-group/client-group.component";
import {CreateClientGroupComponent} from "./client-group/create-client-group/create-client-group.component";
import {ClientComponent} from "./client/client.component";
import {CreateClientComponent} from "./client/create-client/create-client.component";
import {ApplicationFeaturesResolver, UserUiConfigResolver} from "./features.resolver";
import {CreateComplaintComponent} from "./offer/complaint/create-complaint/create-complaint.component";
import {ComplaintPositionComponent} from "./offer/complaint/position/complaint-position.component";
import {DeliveryListWizardComponent} from "./offer/delivery-list/delivery-list-wizard.component";
import {DeliveryListComponent} from "./offer/delivery-list/delivery-list.component";
import {PaletteWizardComponent} from "./offer/delivery-list/palette-wizard/palette-wizard.component";
import {GateEditorComponent} from './offer/gate-editor/gate-editor.component';
import {MainOffersComponent} from "./offer/main-offers.component";
import {CreateOfferComponent} from "./offer/offers/create-offer/create-offer.component";
import {
    ExternalUserOfferEditCompletedComponent
} from "./offer/offers/position/external-user-offer-edit-completed/external-user-offer-edit-completed.component";
import {PositionComponent} from "./offer/offers/position/position.component";
import {ShippingSimulationDetailsComponent} from "./offer/shipping/shipping-simulation/shipping-simulation-details/shipping-simulation-details.component";
import {ConjunctionEditorComponent} from "./offer/window-editor/conjunction-editor/conjunction-editor.component";
import {RoofWindowEditorComponent} from "./offer/window-editor/roof-window-editor/roof-window-editor.component";
import {WindowEditorComponent} from "./offer/window-editor/window-editor.component";
import {PriceListsComponent} from "./price-lists/price-lists.component";
import {NewConfigurableAddonDefaultsComponent} from "./settings/configurable-addon-global-settings/new-configurable-addon-defaults.component";
import {DocumentTemplateComponent} from "./settings/document-templates/document-template.component";
import {GateSystemDefaultsComponent} from "./settings/gate-system-defaults/gate-system-defaults.component";
import {NewsComponent} from "./settings/news/news.component";
import {OfferStatusChangeMailSettingsComponent} from "./settings/offer-status-change-mail-settings/offer-status-change-mail-settings.component";
import {PrintoutDataComponent} from "./settings/printout-data/printout-data.component";
import {PromotionCodeComponent} from "./settings/promotion-code/promotion-code.component";
import {PromotionInformationComponent} from "./settings/promotion-information/promotion-information.component";
import {SellerSettingsComponent} from "./settings/seller-settings/seller-settings.component";
import {SettingsComponent} from "./settings/settings.component";
import {SubsystemPartnerWebshopInfoComponent} from "./settings/subsystem-partner-webshop-info/subsystem-partner-webshop-info.component";
import {SubsystemSalesTargetSettingsComponent} from "./settings/subsystem-sales-target-settings/subsystem-sales-target-settings.component";
import {SubsystemSettingsComponent} from "./settings/subsystem-settings/subsystem-settings.component";
import {SubsystemWebshopInfoComponent} from "./settings/subsystem-webshop-info/subsystem-webshop-info.component";
import {SubsystemWebshopMailConfigurationComponent} from "./settings/subsystem-webshop-mail-configuration/subsystem-webshop-mail-configuration.component";
import {TemplatesSettingsComponent} from "./settings/templates-settings/templates-settings.component";
import {TermsOfUseComponent} from "./settings/terms-of-use/terms-of-use.component";
import {UserSettingsComponent} from "./settings/user-settings/user-settings.component";
import {VenskaPasswordResetMailSettingsComponent} from "./settings/venska-password-reset-mail-settings/venska-password-reset-mail-settings.component";
import {VenskaSettingsComponent} from "./settings/venska-settings/venska-settings.component";
import {WebshopCustomPageComponent} from "./settings/webshop-custom-page/webshop-custom-page.component";
import {WebshopGlazingHelpComponent} from "./settings/webshop-glazing-help/webshop-glazing-help.component";
import {WebshopHelpComponent} from "./settings/webshop-help/webshop-help.component";
import {WebshopSizingHelpComponent} from "./settings/webshop-sizing-help/webshop-sizing-help.component";
import {WebshopUiTranslationsComponent} from "./settings/webshop-ui-translations/webshop-ui-translations.component";
import {SubsystemGroupComponent} from './subsystem-group/subsystem-group.component';
import {CreateSubsystemComponent} from "./subsystem/create-subsystem/create-subsystem.component";
import {SubsystemNotesComponent} from "./subsystem/subsystem-notes/subsystem-notes.component";
import {SubsystemComponent} from "./subsystem/subsystem.component";
import {CreateSupplierComponent} from "./supplier/create-supplier/create-supplier.component";
import {SupplierComponent} from "./supplier/supplier.component";
import {TranslationsComponent} from "./translations/translations.component";
import {ListUserGroupsComponent} from "./user-group/list-user-groups/list-user-groups.component";
import {CreateUserComponent} from "./user/create-user/create-user.component";
import {UserListComponent} from './user/user-list.component';
import {GlazingPackageTarget} from "./window-system/glazing-package/glazing-package";
import {WebshopGlazingPackageComponent} from "./window-system/glazing-package/webshop-glazing-package/webshop-glazing-package.component";
import {WebshopChargePerSubsystemComponent} from "./window-system/webshop-charge-per-subsystem/webshop-charge-per-subsystem.component";
import {WebshopChargeComponent} from "./window-system/webshop-charge/webshop-charge.component";
import {DataModificationTarget} from "./window-system/webshop-charge/WebshopCharge";
import {WindowSystemComponent} from "./window-system/window-system.component";
import {HomeComponent} from "./home/home.component";
import {ConfigEditorComponent} from "./offer/config-editor/config-editor.component";

export const featuresRoutes: Routes = [
    {
        path: '',
        redirectTo: AccessData.pathSuffix.login,
        pathMatch: 'full'
    },
    {
        path: AccessData.pathSuffix.features,
        canActivate: [AuthGuard, PermissionsGuard],
        resolve: {uiConfig: UserUiConfigResolver, features: ApplicationFeaturesResolver},
        data: AccessData.roles.features,
        canActivateChild: [OfferViewRestrictionGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
                canActivate: [AuthGuard]
            },
            {
                path: AccessData.pathSuffix.userGroups,
                component: ListUserGroupsComponent,
                canActivate: [AuthGuard, PermissionsGuard],
                data: AccessData.roles.userGroups
            },
            {
                path: AccessData.pathSuffix.user,
                component: UserListComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.user,
                runGuardsAndResolvers: 'always'
            },
            {
                path: AccessData.pathSuffix.createUser,
                component: CreateUserComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.user
            },
            {
                path: AccessData.pathSuffix.client,
                component: ClientComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.client
            },
            {
                path: AccessData.pathSuffix.createClient,
                component: CreateClientComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.client
            },
            {
                path: AccessData.pathSuffix.clientGroups,
                component: ClientGroupComponent,
                canActivate: [AuthGuard, PermissionsGuard],
                data: AccessData.roles.clientGroups
            },
            {
                path: AccessData.pathSuffix.createClientGroup,
                component: CreateClientGroupComponent,
                canActivate: [AuthGuard, PermissionsGuard],
                data: AccessData.roles.clientGroups
            },
            {
                path: AccessData.pathSuffix.subsystemGroup,
                component: SubsystemGroupComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.subsystemGroup
            },
            {
                path: AccessData.pathSuffix.subsystem,
                children: [
                    {
                        path: '',
                        component: SubsystemComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.subsystem,
                    },
                    {
                        path: AccessData.pathSuffix.subsystemNotes,
                        component: SubsystemNotesComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.subsystem
                    },
                ]
            },
            {
                path: AccessData.pathSuffix.createSubsystem,
                component: CreateSubsystemComponent,
                canActivate: [AuthGuard, PermissionsGuard],
                data: AccessData.roles.subsystem
            },
            {
                path: AccessData.pathSuffix.supplier,
                component: SupplierComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.supplier
            },
            {
                path: AccessData.pathSuffix.createSupplier,
                component: CreateSupplierComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.supplier
            },
            {
                path: AccessData.pathSuffix.windowSystem,
                component: WindowSystemComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.windowSystem
            },
            {
                path: AccessData.pathSuffix.catalogCreator,
                component: CatalogCreatorComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.catalogCreator
            },
            {
                path: AccessData.pathSuffix.settings,
                component: SettingsComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.settings,
                children: [
                    {
                        path: 'printoutData',
                        component: PrintoutDataComponent
                    }, {
                        path: 'user',
                        component: UserSettingsComponent
                    }, {
                        path: 'subsystem',
                        component: SubsystemSettingsComponent
                    }, {
                        path: 'sellerSettingsItem',
                        component: SellerSettingsComponent
                    }, {
                        path: 'subsystemWebshopInfo',
                        component: SubsystemWebshopInfoComponent
                    }, {
                        path: 'subsystemWebshopMailConfiguration',
                        component: SubsystemWebshopMailConfigurationComponent
                    }, {
                        path: 'webshopCustomPages',
                        component: WebshopCustomPageComponent
                    }, {
                        path: 'windowSystemDefaults',
                        component: WindowEditorComponent,
                        data: {
                            sidebarOnlyMode: true
                        }
                    }, {
                        path: 'configurableAddonGlobalSettingsItem',
                        component: NewConfigurableAddonDefaultsComponent
                    }, {
                        path: 'venskaData',
                        component: VenskaSettingsComponent
                    }, {
                        path: 'venskaPasswordResetMailSettings',
                        component: VenskaPasswordResetMailSettingsComponent
                    }, {
                        path: 'offerStatusChangeMailSettings',
                        component: OfferStatusChangeMailSettingsComponent
                    }, {
                        path: 'templates',
                        component: TemplatesSettingsComponent
                    }, {
                        path: 'documentTemplates',
                        component: DocumentTemplateComponent
                    }, {
                        path: 'termsOfUse',
                        component: TermsOfUseComponent
                    }, {
                        path: 'news',
                        component: NewsComponent
                    }, {
                        path: 'webshopHelp',
                        component: WebshopHelpComponent
                    }, {
                        path: 'webshopGlazingHelp',
                        component: WebshopGlazingHelpComponent
                    }, {
                        path: 'webshopSizingHelp',
                        component: WebshopSizingHelpComponent
                    }, {
                        path: 'windowUpsellingSettingsPerSubsystem',
                        component: WebshopChargePerSubsystemComponent,
                        data: {
                            target: DataModificationTarget.WINDOW_UPSELLING
                        }
                    }, {
                        path: 'webshopUiTranslations',
                        component: WebshopUiTranslationsComponent
                    }, {
                        path: 'promotionInformation',
                        component: PromotionInformationComponent
                    }, {
                        path: 'promotionCode',
                        component: PromotionCodeComponent
                    }, {
                        path: 'partnerWebshopInfo',
                        component: SubsystemPartnerWebshopInfoComponent
                    }, {
                        path: 'gateSystemDefaults',
                        component: GateSystemDefaultsComponent
                    }, {
                        path: 'subsystemSalesTarget',
                        component: SubsystemSalesTargetSettingsComponent
                    }
                ]
            },
            {
                path: AccessData.pathSuffix.translations,
                component: TranslationsComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.translations
            },
            {
                path: AccessData.pathSuffix.offer,
                children: [
                    {
                        path: '',
                        component: MainOffersComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.offer
                    },
                    {
                        path: AccessData.pathSuffix.offerPosition,
                        children: [
                            {
                                path: '',
                                component: PositionComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.offerPosition
                            },
                            {
                                path: AccessData.pathSuffix.windowDesigner,
                                component: WindowEditorComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.windowDesigner
                            },
                            {
                                path: AccessData.pathSuffix.roofWindowDesigner,
                                component: RoofWindowEditorComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.roofWindowDesigner
                            },
                            {
                                path: AccessData.pathSuffix.gateDesigner,
                                component: GateEditorComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.gateDesigner
                            },
                            {
                                path: AccessData.pathSuffix.configDesigner,
                                component: ConfigEditorComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.configDesigner
                            },
                            {
                                path: AccessData.pathSuffix.conjunctionDesigner,
                                component: ConjunctionEditorComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.conjunctionDesigner
                            },
                            {
                                path: AccessData.pathSuffix.externalOfferEditDone,
                                component: ExternalUserOfferEditCompletedComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.offerPosition
                            },
                            {
                                path: AccessData.pathSuffix.settings,
                                component: CreateOfferComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.createOffer
                            },
                        ]
                    }
                ]
            },
            {
                path: AccessData.pathSuffix.productionOrderPosition,
                children: [
                    {
                        path: '',
                        component: PositionComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.productionOrderPosition
                    },
                    {
                        path: AccessData.pathSuffix.windowDesigner,
                        component: WindowEditorComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.windowDesigner
                    },
                    {
                        path: AccessData.pathSuffix.roofWindowDesigner,
                        component: RoofWindowEditorComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.roofWindowDesigner
                    },
                    {
                        path: AccessData.pathSuffix.gateDesigner,
                        component: GateEditorComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.gateDesigner
                    },
                    {
                        path: AccessData.pathSuffix.configDesigner,
                        component: ConfigEditorComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.configDesigner
                    },
                    {
                        path: AccessData.pathSuffix.conjunctionDesigner,
                        component: ConjunctionEditorComponent,
                        canActivate: [PermissionsGuard],
                        data: AccessData.roles.conjunctionDesigner
                    }
                ]
            },
            {
                path: AccessData.pathSuffix.createOffer,
                component: CreateOfferComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.createOffer
            },
            {
                path: AccessData.pathSuffix.priceLists,
                component: PriceListsComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.priceLists
            },
            {
                path: AccessData.pathSuffix.adminPanel,
                component: AdminPanelComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.adminPanel,
                children: [
                    {
                        path: 'role',
                        component: RoleComponent
                    }, {
                        path: 'lastActivityTime',
                        component: UserActivityComponent
                    }, {
                        path: 'lastLoginTime',
                        component: UserLoginTimesComponent
                    }, {
                        path: 'userConsents',
                        component: UserConsentsComponent
                    }, {
                        path: 'errorBrowser',
                        component: ErrorBrowserComponent
                    }, {
                        path: 'scheduledJobs',
                        component: ScheduledJobComponent
                    }, {
                        path: 'offerSupplierCounter',
                        component: OfferSupplierCounterComponent
                    }, {
                        path: 'offerSubsystemCounter',
                        component: OfferSubsystemCounterComponent
                    }, {
                        path: 'subsystemNotesCounter',
                        component: SubsystemNotesCounterComponent
                    }, {
                        path: 'configurableAddonUpsellingSettings',
                        component: ConfigurableAddonUpsellingSettingsComponent
                    }, {
                        path: 'windowUpsellingSettings',
                        component: WebshopChargeComponent,
                        data: {
                            target: DataModificationTarget.WINDOW_UPSELLING
                        }
                    }, {
                        path: 'upsellingPropositions',
                        component: UpsellingPropositionComponent
                    }, {
                        path: 'glazingUpsellingSettings',
                        component: WebshopGlazingPackageComponent,
                        data: {
                            target: GlazingPackageTarget.UPSELLING_GLAZING
                        }
                    }, {
                        path: 'applicationResources',
                        component: ApplicationResourceComponent
                    }, {
                        path: 'applicationStatus',
                        component: ApplicationStatusComponent
                    }, {
                        path: 'redrawWindows',
                        component: RedrawWindowsPageComponent
                    }, {
                        path: 'markProducts',
                        component: MarkProductsComponent
                    }, {
                        path: 'editPermissions',
                        component: EditCatalogPermitsComponent,
                        canDeactivate: [RequiresExitConfirmationGuard]
                    }, {
                        path: 'applicationInfo',
                        component: ApplicationInfoComponent
                    }
                ]
            },
            {
                path: AccessData.pathSuffix.shippingSimulationDetails,
                component: ShippingSimulationDetailsComponent
            },
            {
                path: AccessData.pathSuffix.createComplaint,
                component: CreateComplaintComponent
            },
            {
                path: AccessData.pathSuffix.complaint,
                children: [
                    {
                        path: AccessData.pathSuffix.complaintPosition,
                        children: [
                            {
                                path: '',
                                component: ComplaintPositionComponent,
                                canActivate: [PermissionsGuard],
                                data: AccessData.roles.complaintPosition
                            }
                        ]
                    }
                ]
            },
            {
                path: AccessData.pathSuffix.deliveryListWizard,
                component: DeliveryListWizardComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.deliveryListWizard
            },
            {
                path: AccessData.pathSuffix.deliveryList,
                component: DeliveryListComponent
            },
            {
                path: AccessData.pathSuffix.paletteWizard,
                component: PaletteWizardComponent,
                canActivate: [PermissionsGuard],
                data: AccessData.roles.paletteWizard
            }
        ]
    }
];
