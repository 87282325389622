import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'underscore';
import {Permissions} from '../../../../auth/permission.service';
import {ZipCodeValidator} from '../../../../common/zip-code-validator';
import {PriceTableService} from '../../price-table/price-table.service';
import {PricingTableComponent, PricingTableDataRow} from '../../pricing-table/pricing-table.component';
import {ShippingPriceTable} from '../shippingPriceTable';
import {ShippingPriceTableInfo} from '../shippingPriceTableInfo';
import {SheetTableComponent} from "../../sheet-table/sheet-table.component";
import {PositionMessage} from "../../../offer/offers/message";

@Component({
    selector: 'app-shipping-pricingtable',
    templateUrl: './shipping-pricingtable.component.html',
    styleUrls: ['./shipping-pricingtable.component.css', '../../../shared-styles.css'],
    providers: [PriceTableService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingPricingtableComponent extends PricingTableComponent implements OnInit {

    @Input() tableInfo: ShippingPriceTableInfo;
    @Input() priceTable: ShippingPriceTable;
    @Input() validationErrors: { [field: string]: string };
    @Output() submit = new EventEmitter<ShippingPriceTable>();

    @ViewChild(SheetTableComponent, {static: true}) sheetTable: SheetTableComponent;

    zipValidator = new ZipCodeValidator();

    constructor(permissions: Permissions,
                translate: TranslateService,
                changeDetector: ChangeDetectorRef) {
        super(permissions, translate, changeDetector);
    }

    ngOnInit() {
        this.isNew = !this.tableInfo.hasPricetable;
        super.ngOnInit();
    }

    protected prepareData(): PricingTableDataRow[] {
        return this.priceTable.dataByCodes.map(dataRow => _.clone(dataRow));
    }

    onSubmit() {
        if (this.error) {
            return;
        }
        const newShippingPriceTable = new ShippingPriceTable();
        newShippingPriceTable.country = this.tableInfo.country;
        this.data.forEach(row => {
            Object.keys(row).forEach(key => {
                if (row[key] == undefined || row[key] === '') {
                    delete row[key];
                }
            });

        });

        this.trimValues();
        newShippingPriceTable.dataByCodes = this.data.filter(row => !_.isEmpty(row));

        this.submit.emit(newShippingPriceTable);
    }

    private trimValues() {
        this.data = this.data.map(rowObj => {
            Object.keys(rowObj).forEach(key => {
                rowObj[key] = rowObj[key].toString().trim();
            });
            return rowObj;
        });
    }

    getValidationErrors() {
        return Object.keys(this.validationErrors || {});
    }

    validateCode = (value: any, column: number) => {
        if (column !== 0 || value === "") {
            return true;
        }
        const stringValue = value.toString();
        if (!stringValue.includes(":")) {
            return this.validateZipCode(stringValue);
        }
        const limits = stringValue.split(":");
        if (limits.length !== 2) {
            return false;
        }
        return this.validateZipCode(limits[0]) && this.validateZipCode(limits[1]);
    }

    private validateZipCode(value: string): boolean {
        return this.zipValidator.validate(this.tableInfo.country, value.trim());
    }

    setErrorCode = (row: number, column: number) => {
        return "error.shippingPricetableDto." + (column === 0 ? "code" : "value") + ".forbidden_value";
    }

    setError (error: PositionMessage) {
        if (error === undefined) {
            this.validationErrors = {};
        }
        this.error = error;
    }
}
