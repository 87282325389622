import {ColorInterface} from './catalog-data/color-interface';
import {DecorativeFillingInterface} from './catalog-data/decorative-filling-interface';
import {DistanceFrameInterface} from './catalog-data/distance-frame-interface';
import {GlassInterface} from './catalog-data/glass-interface';
import {GrillInterface} from './catalog-data/grill-interface';
import {ProfileInterface} from './catalog-data/profile-interface';

export class StaticDataHelper {
    grills: GrillInterface[];       // grills filtered by window system
    angledGrills: GrillInterface[]; // grills filtered by window system and angled
    mullions: ProfileInterface[];
    glasses: GlassInterface[];
    frames: DistanceFrameInterface[];
    decorativeFillings: DecorativeFillingInterface[] = [];
    colors: ColorInterface[];
}
