import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ScopeValidator} from '../../../shared/validator/input-validator';
import {Assembly} from './assembly';

@Injectable()
export class AssemblyService implements CrudService<Assembly> {

    private static readonly API_URL = 'assembly';

    rangeFilterValidator: ScopeValidator;
    private readonly filterValidator: { [filterProperty: string]: (value: any) => boolean };

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        this.rangeFilterValidator = new ScopeValidator();
        this.filterValidator = {
            value: value => this.rangeFilterValidator.isValid(value)
        };
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Assembly>> {
        this.transformRangeFilterValues(filters);
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, this.filterValidator);
        return this.http.get<Listing<object>>(AssemblyService.API_URL, {params: params}).pipe(
            map(response => Listing.fromJSON(Assembly, response)));
    }

    getItem(assemblyId: number): Observable<Assembly> {
        return this.http.get<Assembly>(`${AssemblyService.API_URL}/${assemblyId}`);
    }

    addItem(assembly: Assembly): Observable<number> {
        return this.http.post<any>(AssemblyService.API_URL, assembly, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(AssemblyService.API_URL, response)));
    }

    editItem(assemblyId: number, assembly: Assembly): Observable<number> {
        return this.http.put(`${AssemblyService.API_URL}/${assemblyId}`, assembly).pipe(
            map(() => assemblyId));
    }

    calculateQuantityForOffer(assemblyId: number, offerId: number): Observable<number> {
        return this.http.get(`${AssemblyService.API_URL}/${assemblyId}/calculateQuantityForOffer/${offerId}`, {responseType: 'text'}).pipe(
            map(response => +response));
    }

    private transformRangeFilterValues(filters: { [filterProperty: string]: FilterMetadata }): void {
        const valueFilter = filters['value'];
        if (valueFilter != undefined && this.rangeFilterValidator.isValid(valueFilter.value)) {
            const {from, to} = this.rangeFilterValidator.getMatchedLimits(valueFilter.value);
            valueFilter.value = `(${from.toFixed(2)})-(${to.toFixed(2)})`;
        }
    }
}
