import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubwindowType} from './SubwindowType';
import {SubwindowTypeList} from './SubwindowTypeList';

@Injectable()
export class SubwindowTypeService implements CrudService<SubwindowType> {

    constructor(private http: HttpClient) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<SubwindowTypeList> {

        let params = new DataServiceHelper().prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<SubwindowTypeList>('subwindowTypes', {params: params});
    }

    getItem(id: number): Observable<SubwindowType> {
        return undefined;
    }

    addItem(item: SubwindowType): Observable<number> {
        return undefined;
    }

    editItem(id: number, item: SubwindowType): Observable<number> {
        return this.http.put(`subwindowTypes/${id}`, item).pipe(map(() => id));
    }

    getAll(): Observable<SubwindowType[]> {
        return this.getItems(0, null, null, 'id', 1).pipe(map(listingDto => listingDto.data));
    }
}
