import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {ConfigSystemUpsellingSettings} from '../config-system-upselling-settings';
import {ItemForCatalogLinking} from "../../../window-system/single-system-checkbox-crud/item-for-catalog-linking";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-upselling-settings-form',
    templateUrl: './upselling-settings-form.component.html',
    styleUrls: ['../configurable-addon-upselling-settings.component.css']
})
export class UpsellingSettingsFormComponent {

    @Input()
    upsellingSettings: ConfigSystemUpsellingSettings;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    selectedConfigSystem: ItemForCatalogLinking;

    @Input()
    configSystems: ItemForCatalogLinking[];

    @Output()
    readonly selectedConfigSystemChange = new EventEmitter<ItemForCatalogLinking>();

    private readonly translate: TranslateService;

    configSystemFormatter: (v: ItemForCatalogLinking) => SelectItem;

    constructor(injector: Injector) {
        this.translate = injector.get(TranslateService);
        this.configSystemFormatter = (v: ItemForCatalogLinking) => {
            return {
                label: v.names[this.translate.currentLang],
                value: v.id
            };
        };
    }

    handleConfigurableAddonDefinitionChange(configurableAddonDefinitionId: number): void {
        ValidationErrorsHelper.clearErrors(this.validationErrors, 'configAddonDefaluts');
        this.upsellingSettings.configSystemId = configurableAddonDefinitionId;
    }
}
