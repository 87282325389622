import {WindowSystemType} from "../../../../../../window-designer/catalog-data/window-system-interface";
import {FittingTerraceLockLocation} from '../../../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {MultilanguageField} from "../../../../../supportedLanguages";
import {CatalogDescriptionEntry} from "../../../../catalog-creator/CatalogDescriptionEntry";
import {PreviewWindowAddon} from "./PreviewWindowAddon";

export class WindowProperties {
    public positionName: MultilanguageField;
    public windowSystemNames: MultilanguageField;
    public configSystemNames: MultilanguageField;
    public configSystemId: number;
    public windowSystemType: WindowSystemType;
    public quantity: number;
    public dimensions: string;
    public profileNames: MultilanguageField;
    public coreColorNames: MultilanguageField;
    public externalColorNames: MultilanguageField;
    public internalColorNames: MultilanguageField;
    public sealExternalColorNames: MultilanguageField;
    public sealInternalColorNames: MultilanguageField;
    public fittingTypeNames: MultilanguageField;
    public windowFunction: string;
    public handleTypeNames: MultilanguageField;
    public glazingBeadNames: MultilanguageField;
    public doorCoverNames: MultilanguageField;
    public doorStepNames: MultilanguageField;
    public movablePostNames: MultilanguageField;
    public doorOpening: string;
    public glassNames: MultilanguageField[];
    public glassQuantity: number;
    public frameNames: MultilanguageField[];
    public grillNames: MultilanguageField;
    public fillingType: string;
    public fillingWidth: number;
    public fillingNames: MultilanguageField;
    public fittingSlidingNames: MultilanguageField;
    public fittingBrakeNames: MultilanguageField;
    public fittingLockNames: MultilanguageField;
    public fittingLockTerraceNames: MultilanguageField;
    public fittingTerraceLockLocation: FittingTerraceLockLocation;
    public fittingInsertionNames: MultilanguageField;
    public fittingAutomaticDriveNames: MultilanguageField;
    public fittingEspagnoletteTypeNames: MultilanguageField;
    public fittingVerandaNames: MultilanguageField;
    public fittingMainInsertionNames: MultilanguageField;
    public fittingAdditionalInsertionNames: MultilanguageField;
    public entranceDoorFitting: MultilanguageField;
    public view: string;
    public decorativeFillingNames: MultilanguageField;
    public fillingInternalColorNames: MultilanguageField;
    public fillingExternalColorNames: MultilanguageField;
    public frameEnhancementNames: MultilanguageField;
    public underwindowProfileNames: MultilanguageField;
    public mullionNames: MultilanguageField;
    public dripNames: MultilanguageField;
    public ventilatorNames: MultilanguageField;
    public couplerNames: MultilanguageField;
    public millingNames: MultilanguageField;
    public millingNorwegianNames: MultilanguageField;
    public underWindowBeadNames: MultilanguageField;
    public weldType: MultilanguageField;
    public previewWindowAddons: PreviewWindowAddon[];
    public uw: number;
    public glazingPackageNames: MultilanguageField;
    public glazingCategoryNames: MultilanguageField;
    public roofWindowFunction: string;
    public description: CatalogDescriptionEntry[];
    public terraceHandleNames: MultilanguageField;
    public terraceHandleLayout: string;

    constructor() {
        this.previewWindowAddons = [];
        this.glassNames = [];
        this.frameNames = [];
    }
}
