import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, Observer, of, Subscription} from "rxjs";
import {mergeMap} from 'rxjs/operators';
import * as _ from "underscore";
import {WindowTypeCodeParser} from "../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../common/DatatableHelper";
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {OnceFlag} from '../../../shared/once-flag';
import {WindowSystemDefinitionService} from '../../window-system/window-system-definition/window-system-definition.service';
import {PriceListService} from "../price-list.service";
import {Pricelist, PricelistStatus, PricelistTarget} from "../pricelist";
import {PriceTableService} from "./price-table.service";
import {PriceTable} from './priceTable';
import {Type2WindowSystem} from "./type2windowSystem";
import {Type2WindowSystemList} from "./type2windowSystemList";

declare const jQuery: any;

interface TableValidationError {
    errorMessage: string;
    errorRowNum: number;
    errorColumnNum: number;
}

@Component({
    selector: 'app-price-table',
    templateUrl: './price-table.component.html',
    styleUrls: ['../../shared-styles.css', './price-table.component.css'],
    providers: [PriceListService, DataServiceHelper, PriceTableService, WindowSystemDefinitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceTableComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, AfterViewChecked {

    availablePricelists: Pricelist[] = [];
    selectedPricelist: Pricelist;
    currentPricelist: Pricelist;
    type2windowSystems: Type2WindowSystem[] = [];
    selectedType2windowSystem: Type2WindowSystem;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    displayDialog = false;
    langTranslateSubscription: Subscription;
    availableTypes: SelectItem[] = [];
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    selectedRow: Type2WindowSystem;
    typeNameParser: (string) => string;
    tableValidationErrors: TableValidationError[] = [];
    scrollToFirstError = false;

    priceTables: PriceTable[];
    selectedTableInfo: Type2WindowSystem;
    downloadFromExternalApiUrl: string;
    targets = PricelistTarget;

    @ViewChild("dt", {static: true}) datatable: DataTable;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private readonly pricelistService: PriceListService,
                private readonly priceTableService: PriceTableService,
                private readonly translate: TranslateService,
                private readonly permissions: Permissions,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private readonly errors: CommonErrorHandler,
                private readonly windowSystemService: WindowSystemDefinitionService) {
        super(injector, changeDetector, 'PriceTableComponent', false);
        this.langTranslateSubscription = translate.onLangChange.subscribe(() => {
            this.changeDetector.markForCheck();
        });
        this.typeNameParser = (name: string) => WindowTypeCodeParser.getTypeName(WindowTypeCode[name]);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fillPricelilstsDropdown();
        this.availableTypes = Object.keys(WindowTypeCode).map(type => {
            return {label: this.typeNameParser(type), value: type};
        });
        this.availableTypes = _.sortBy(this.availableTypes, "label");
        this.availableTypes.unshift({label: '', value: ''});
        this.hotkeysService.remove(this.newElementHotkey);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.langTranslateSubscription.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (this.scrollToFirstError) {
            const error = jQuery('.price-table-error:first');
            const scrollContainer = error.scrollParent();
            scrollContainer.animate({
                scrollTop: scrollContainer.scrollTop() - scrollContainer.offset().top + error.offset().top
            }, 250);
            this.scrollToFirstError = false;
        }
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    showDialogToAdd(): void {
        if (this.selectedRow) {
            this.type2WindowSystemButtonClick(this.selectedRow);
        }
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    pricelistChanged(pricelist: Pricelist): void {
        this.selectedPricelist = pricelist;
        this.loadPricetablesLazy(this.getDatatable().createLazyLoadMetadata());
    }

    private loadPricetablesLazySubscriber(first: number, rows: number): Observer<Type2WindowSystemList> {
        return {
            next: type2windowSystemData => {
                this.totalRecords = type2windowSystemData.totalRecords;
                this.type2windowSystems = type2windowSystemData.data;
                this.fromRecord = Math.min(first + 1, this.totalRecords);
                this.toRecord = Math.min(first + rows, this.totalRecords);
                let selectedItemIndex = -1;
                if (this.selectedRow != undefined) {
                    selectedItemIndex = this.type2windowSystems.findIndex(item => item.windowSystemId === this.selectedRow.windowSystemId
                        && item.typeId === this.selectedRow.typeId);
                }
                if (selectedItemIndex === -1) {
                    selectedItemIndex = 0;
                }
                this.selectedRow = this.type2windowSystems[selectedItemIndex];
                if (!DatatableHelper.isColumnFilterFocused(this.datatable)) {
                    DatatableHelper.focusOnTableRow(this.datatable, selectedItemIndex);
                }
            },
            error: error => {
                console.error('loadPricetablesLazy `getPage` error:', error);
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            },
            complete: () => {
                console.info('loadPricetablesLazy `getPage` completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        };
    }

    loadPricetablesLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        if (this.selectedPricelist) {
            return this.priceTableService.getType2WindowSystems(this.selectedPricelist.id, event.first, event.rows, event.filters,
                event.sortField, event.sortOrder).subscribe(this.loadPricetablesLazySubscriber(event.first, event.rows));
        }
    }

    onRowSelect(event: any) {
        this.type2WindowSystemButtonClick(this.selectedRow);
        this.keepSelectedItemIndex(event);
    }

    cancel() {
        this.dialogHideHelper.call(() => {
            this.setDisplayDialog(false);
            this.clearError('table');
            this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedRow);
        });
    }

    fillPricelilstsDropdown() {
        forkJoin({
            allPricelists: this.pricelistService.getAllPricelists(PricelistTarget.WINDOWS),
            currentPricelist: this.pricelistService.getCurrentPricelist(PricelistTarget.WINDOWS)
        }).pipe(mergeMap(data => {
            this.availablePricelists = data.allPricelists.data;
            this.currentPricelist = data.currentPricelist;
            if (this.availablePricelists.length > 0) {
                this.setDefaultSelectedPricelist();
                const event = this.getDatatable().createLazyLoadMetadata();
                return this.priceTableService.getType2WindowSystems(this.selectedPricelist.id, 0, this.chosenRowsPerPage,
                    event.filters, event.sortField, event.sortOrder);
            }
            return of<Type2WindowSystemList>({data: [], totalRecords: 0});
        })).subscribe(this.loadPricetablesLazySubscriber(0, this.chosenRowsPerPage));
    }

    private setDefaultSelectedPricelist() {
        this.selectedPricelist = this.availablePricelists
                .filter(pricelist => pricelist.status === PricelistStatus.ACTIVE)
                .filter(pricelist => pricelist.validFrom.valueOf() < Date.now())
                .sort((list1, list2) => list2.validFrom.valueOf() - list1.validFrom.valueOf())[0]
            || this.availablePricelists[0];
    }

    editPricetable(passedInType2windowSystem: Type2WindowSystem) {
        this.selectedRow = passedInType2windowSystem;
        let event = {};
        event['data'] = passedInType2windowSystem;
        this.onRowSelect(event);
    }

    type2WindowSystemButtonClick(passedInType2windowSystem: Type2WindowSystem) {
        this.tableValidationErrors = [];
        this.selectedType2windowSystem = passedInType2windowSystem;

        this.selectedTableInfo = passedInType2windowSystem;
        this.loadPriceTable(passedInType2windowSystem);
    }

    loadPriceTable(passedInType2windowSystem: Type2WindowSystem) {
        this.showDataLoadingIndicator();
        forkJoin({
            priceTable: passedInType2windowSystem.priceTableId != undefined
                ? this.priceTableService.getPriceTable(passedInType2windowSystem.priceTableId, true)
                : of<PriceTable[]>([this.createEmptyInitialPricetable()]),
            downloadFromExternalApiUrl: this.windowSystemService
                .getDownloadPriceTableFromExternalApiUrl(passedInType2windowSystem.windowSystemId)
        }).subscribe({
            next: data => {
                this.priceTables = data.priceTable;
                this.downloadFromExternalApiUrl = data.downloadFromExternalApiUrl;
                this.setDisplayDialog(true);
            },
            error: error => {
                console.error('PriceTableComponent loadPriceTable error:', error);
                this.hideDataLoadingIndicator();
            },
            complete: () => {
                console.info('loadPriceTable `getPage` completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        });
    }

    showSuccessMessage() {
        if (this.isNewPriceTable()) {
            this.growlMessageController.info('PRICE_TABLES.CREATED');
        } else {
            this.growlMessageController.info('PRICE_TABLES.UPDATED');
        }
    }

    isNewPriceTable() {
        return !(this.selectedPricelist && this.selectedPricelist.id);
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    closeDialog() {
        this.setDisplayDialog(false);
    }

    submit() {
    }

    submitPricingTable(priceTables: PriceTable[]) {
        const priceTableSaveObservables: Observable<void>[] = priceTables
            .filter(priceTable => priceTable.id != undefined || priceTable.items.length > 0)
            .map(priceTable => this.priceTableService.savePricetable(this.selectedPricelist, this.selectedType2windowSystem, priceTable));
        if (priceTableSaveObservables.length === 0) {
            this.closeDialog();
            return;
        }
        this.showDataLoadingIndicator();
        forkJoin(priceTableSaveObservables).subscribe({
            next: () => {
                this.showSuccessMessage();
                this.loadPricetablesLazy(this.getDatatable().createLazyLoadMetadata());
                this.closeDialog();
            },
            error: (error: HttpErrorResponse) => {
                this.hideDataLoadingIndicator();
                this.errors.handle(error, true);
                console.error('pricetable save failed error:', error);
                this.changeDetector.markForCheck();
            }
        });
    }

    get selectedPricelistIsFuture() {
        return this.selectedPricelist != undefined
            && this.currentPricelist != undefined
            && this.selectedPricelist.validFrom > this.currentPricelist.validFrom;
    }

    downloadExternalPriceTable(code: string, futurePricetable: boolean) {
        this.priceTableService.downloadExternalPriceTable(this.downloadFromExternalApiUrl, code, futurePricetable).subscribe({
            next: data => {
                this.priceTables = [{...this.priceTables[0], items: data}];
                this.growlMessageController.info('PRICE_TABLES.ACTIONS.DOWNLOAD_EXTERNAL_PRICE_TABLE.SUCCESS');
                this.changeDetector.markForCheck();
            },
            error: () => {
                this.growlMessageController.error('PRICE_TABLES.ACTIONS.DOWNLOAD_EXTERNAL_PRICE_TABLE.ERROR');
            }
        });
    }

    createEmptyInitialPricetable(): PriceTable {
        const newPriceTable = new PriceTable();
        newPriceTable.items = [];
        newPriceTable.selectionAdditionalParam2 = 0;
        return newPriceTable;
    }

    addChildPricetable(priceTable: PriceTable): void {
        this.priceTables = [...this.priceTables, priceTable]
            .sort((a, b) => a.selectionAdditionalParam2 - b.selectionAdditionalParam2);
    }
}
