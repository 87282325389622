import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import * as _ from 'underscore';
import {UserUiConfigService} from '../../auth/uiconfig/userUiConfig.service';
import {AbstractPosition, PositionTarget} from '../../features/offer/AbstractPosition';
import {PictureComponent} from '../../features/offer/offers/position/picture/picture.component';
import {WindowPropertiesComponent} from '../../features/offer/offers/position/window-properties/window-properties.component';

declare const jQuery: any;

@Component({
    selector: 'app-container-with-preview',
    templateUrl: './container-with-preview.component.html',
    styleUrls: ['./container-with-preview.component.css']
})
export class ContainerWithPreviewComponent implements AfterViewInit, OnDestroy {

    static readonly VIEW_NAME = 'PositionComponent';
    static readonly PICTURE_SIZE = 'pictureSize';

    @ViewChild("picture", {static: true}) picture: PictureComponent;
    @ViewChild("windowProperties", {static: true}) windowProperties: WindowPropertiesComponent;

    @Output() onPictureClicked = new EventEmitter<AbstractPosition>();

    showDetails = true;
    otherInfo?: string;
    resizableTopElementHeight = 0.5;
    private readonly saveTopElementHeight: () => void;
    private cleanup: (() => void)[] = [];

    constructor(private changeDetector: ChangeDetectorRef,
                private userUiConfigService: UserUiConfigService) {
        this.saveTopElementHeight = _.debounce(() => {
            this.userUiConfigService.saveConfigForTheView(ContainerWithPreviewComponent.VIEW_NAME,
                ContainerWithPreviewComponent.PICTURE_SIZE, this.resizableTopElementHeight);
            this.changeDetector.markForCheck();
        }, 200);
    }

    ngAfterViewInit(): void {
        this.initResizableTopElementHeight();
        this.makeDivsResizable();
        this.resize();
    }

    ngOnDestroy(): void {
        this.cleanup.forEach(cleanup => cleanup());
        this.cleanup.length = 0;
    }

    private initResizableTopElementHeight(): void {
        this.resizableTopElementHeight = this.userUiConfigService.getConfigForTheView(ContainerWithPreviewComponent.VIEW_NAME,
            ContainerWithPreviewComponent.PICTURE_SIZE);

        if (this.resizableTopElementHeight == null) {
            this.resizableTopElementHeight = 0.5;
        }
    }

    private makeDivsResizable(): void {
        let twoTopElement = jQuery('#two-top');
        twoTopElement.resizable({
            handles: 's'
        });
        const topTwoElementResizeHandler = () => {
            const containerHeight = jQuery("#two").innerHeight();
            const topHeight = jQuery("#two-top").outerHeight();
            this.resizableTopElementHeight = topHeight / containerHeight;
            this.saveTopElementHeight();
        };
        twoTopElement.on('resize', topTwoElementResizeHandler);
        this.cleanup.push(() => jQuery('#two-top').off('resize', topTwoElementResizeHandler));

        // adjust DIVs dimensions on browser resize
        const windowResizeHandler = (event, ui) => this.resize(ui);
        jQuery(window).on('resize', windowResizeHandler);
        this.cleanup.push(() => jQuery(window).off('resize', windowResizeHandler));
    }

    private resize(ui?) {
        // if ui == null -> event is sent by browser window resize, otherwise ignore
        if (ui != undefined) {
            return;
        }
        const newHeight = jQuery("#container").innerHeight();
        jQuery('#two-top')
            .resizable("option", 'minHeight', Math.ceil(0.2 * newHeight))
            .resizable("option", 'maxHeight', Math.floor(0.8 * newHeight))
            .height(newHeight * this.resizableTopElementHeight);
        this.changeDetector.markForCheck();
    }

    handlePositionChange(position: AbstractPosition, target: PositionTarget): void {
        this.picture.updatePositionPicture(position, target);
        this.windowProperties.updatePositionWindowProperties(position, target);
        this.otherInfo = undefined;
        if (target === PositionTarget.OFFER) {
            if (position != undefined && !!position.otherInfo) {
                this.otherInfo = position.otherInfo;
            }
        }
    }

    handlePictureClick(event: AbstractPosition): void {
        this.onPictureClicked.emit(event);
    }

    get otherInfoNeedsTooltip() {
        return this.otherInfo.length > 40 || this.otherInfo.includes('\n');
    }

    get otherInfoPreview() {
        const preview = this.otherInfo.substring(0, 40);
        const newline = preview.indexOf('\n');
        if (newline >= 0) {
            return preview.substring(0, newline);
        }
        return preview;
    }
}
