<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(GraspGlazingCategoryField.NAME)"
                            inputId="name" label="{{ 'GRASP_GLAZING_CATEGORIES.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="50"
                            [disabled]="fieldUsage.disabled(GraspGlazingCategoryField.NAME)"
                            [required]="true" [field]="item?.name"></app-translation-fields>

    <div *ngIf="fieldUsage.show(GraspGlazingCategoryField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GRASP_GLAZING_CATEGORIES.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GraspGlazingCategoryField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GraspGlazingCategoryField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'GRASP_GLAZING_CATEGORIES.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GraspGlazingCategoryField.SORT_INDEX)"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
