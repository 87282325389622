import {ConfigAddonOpeningType} from "../enums/ConfigAddonOpeningType";

export class ConfigAddonOpening {

    type: ConfigAddonOpeningType;
    width: number;
    height: number;
    rectangular: boolean;

    constructor(type: ConfigAddonOpeningType,
                width: number,
                height: number,
                rectangular: boolean) {
        this.type = type;
        this.width = width;
        this.height = height;
        this.rectangular = rectangular;
    }
}
