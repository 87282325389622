import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {configurableAddonDefinitionTypeToTranslationKey} from '../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowSystemName} from '../../../window-system/window-system-definition/window-system-definition';
import {ConfigSystemUpsellingSettings} from '../../configurable-addon-upselling-settings/config-system-upselling-settings';
import {UpsellingProposition} from '../upselling-proposition';

interface ConfigAddonPickListItem extends Readonly<ConfigSystemUpsellingSettings> {
    warnInSource: boolean;
    warnInTarget: boolean;
    nameForFilter: string;
}

@Component({
    selector: 'app-upselling-proposition-config-addons',
    templateUrl: './upselling-proposition-config-addons.component.html',
    styleUrls: ['./upselling-proposition-config-addons.component.css']
})
export class UpsellingPropositionConfigAddonsComponent implements OnInit {

    @Input()
    item: UpsellingProposition;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    allActiveConfigurableAddonUpsellingSettings: ConfigSystemUpsellingSettings[];

    @Input()
    linkedWindowSystems: Map<number, number[]>;

    @Input()
    windowSystemNames: WindowSystemName[];

    allMappedSettings: ConfigAddonPickListItem[];
    unselectedSettings: ConfigAddonPickListItem[];
    selectedSettings: ConfigAddonPickListItem[];

    overlappingSystemNames: string;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {
        this.allMappedSettings = [];
        this.unselectedSettings = [];
        this.selectedSettings = [];
        for (let settings of this.allActiveConfigurableAddonUpsellingSettings) {
            const pickListItem: ConfigAddonPickListItem = {...settings, warnInSource: false, warnInTarget: false, nameForFilter: ''};
            this.allMappedSettings.push(pickListItem);
            if (this.item.configurableAddonUpsellingSettingsIds.includes(settings.id)) {
                this.selectedSettings.push(pickListItem);
            } else {
                this.unselectedSettings.push(pickListItem);
            }
        }
        this.countSettingsPerSystem();
    }

    handleMoveToSource(moved: ConfigSystemUpsellingSettings[]): void {
        for (let movedOption of moved) {
            let selectedIndex = this.item.configurableAddonUpsellingSettingsIds.findIndex(orderId => orderId === movedOption.id);
            if (selectedIndex !== -1) {
                this.item.configurableAddonUpsellingSettingsIds.splice(selectedIndex, 1);
            }
        }
        this.sortSettings(this.unselectedSettings);
        this.countSettingsPerSystem();
    }

    handleMoveToTarget(moved: ConfigSystemUpsellingSettings[]): void {
        for (let movedOption of moved) {
            this.item.configurableAddonUpsellingSettingsIds.push(movedOption.id);
        }
        this.sortSettings(this.selectedSettings);
        this.countSettingsPerSystem();
    }

    sortSettings(settings: ConfigAddonPickListItem[]): void {
        settings.sort((a, b) => a.sortIndex - b.sortIndex);
    }

    countSettingsPerSystem(): void {
        let selectedPerSystem = new Map<number, number>();
        this.selectedSettings.forEach(settings => {
            const windowSystemIds = this.linkedWindowSystems.get(settings.id);
            if (windowSystemIds != undefined) {
                windowSystemIds.forEach(windowSystemId => {
                    if (selectedPerSystem.has(windowSystemId)) {
                        selectedPerSystem.set(windowSystemId, selectedPerSystem.get(windowSystemId) + 1);
                    } else {
                        selectedPerSystem.set(windowSystemId, 1);
                    }
                });
            }
        });

        let systemIdsSelectedOnce = [];
        let systemIdsSelectedMoreThanOnce = [];
        for (let mapEntry of selectedPerSystem) {
            let systemId = mapEntry[0];
            let count = mapEntry[1];
            if (count === 1) {
                systemIdsSelectedOnce.push(systemId);
            } else if (count > 1) {
                systemIdsSelectedMoreThanOnce.push(systemId);
            }
        }

        this.allMappedSettings.forEach(settings => {
            const windowSystemIds = this.linkedWindowSystems.get(settings.id);
            settings.warnInSource = windowSystemIds.some(systemId => systemIdsSelectedOnce.includes(systemId) || systemIdsSelectedMoreThanOnce.includes(systemId));
            settings.warnInTarget = windowSystemIds.some(systemId => systemIdsSelectedMoreThanOnce.includes(systemId));
        });
        this.overlappingSystemNames = undefined;
        if (systemIdsSelectedMoreThanOnce.length > 0) {
            this.validationErrors['configurableAddonUpsellingSettingsIds'] = 'error.upsellingProposition.configurableAddonUpsellingSettingsIds.not_unique';
            this.overlappingSystemNames = systemIdsSelectedMoreThanOnce
                .map(systemId => this.windowSystemNames.find(systemData => systemData.id === systemId))
                .filter(systemData => systemData != null)
                .map(systemData => systemData.names[this.translateService.currentLang])
                .join(", ");
        } else {
            this.validationErrors['configurableAddonUpsellingSettingsIds'] = undefined;
        }
    }
}
