export enum ColorType {
    PCV = 'PCV',
    VENEER = 'VENEER',
    LAZUR = 'LAZUR',
    RAL_PALETTE_CUSTOM = 'RAL_PALETTE_CUSTOM',
    RAL_PALETTE_STANDARD = 'RAL_PALETTE_STANDARD',
    PLAIN = 'PLAIN',
    COVERING_STANDARD = 'COVERING_STANDARD',
    WOOD_LIKE_ADEC = 'WOOD_LIKE_ADEC',
    ADDON_MATERIAL = 'ADDON_MATERIAL',
    NCS_PALETTE_CUSTOM = 'NCS_PALETTE_CUSTOM',
    OTHER = 'OTHER',
    TERRACE = 'TERRACE',
    GATE_CORE = 'GATE_CORE'
}

export class ColorTargetGroup {

    static readonly Config: ColorTarget = {
        target: 'Config',
        colorTypes: [ColorType.ADDON_MATERIAL],
    };

    static readonly General: ColorTarget = {
        target: 'General',
        colorTypes: Object.keys(ColorType)
            .filter(ct => ColorTargetGroup.Config.colorTypes.indexOf(ColorType[ct]) < 0)
            .map(ct => ct as ColorType),
    };
}

export type ColorTargetType = 'General' | 'Config';

export interface ColorTarget {
    target: ColorTargetType;
    colorTypes: ColorType[];
}
