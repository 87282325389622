import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterContentChecked,
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {forkJoin, interval, Observable, of} from "rxjs";
import {finalize, mergeMap, take, takeWhile} from "rxjs/operators";
import * as _ from 'underscore';
import {ConfigAddonParentInfo} from "../../../../../../../window-designer/entities/ConfigAddonParentInfo";
import {ConfigurableAddon} from "../../../../../../../window-designer/entities/ConfigurableAddon";
import {CechaType} from "../../../../../../../window-designer/enums/CechaType";
import {ConfigAddonApplication} from "../../../../../../../window-designer/enums/ConfigAddonApplication";
import {configurableAddonDefinitionTypeToTranslationKey} from '../../../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {SubwindowTypes} from "../../../../../../../window-designer/subwindow-types";
import {Permissions} from "../../../../../../auth/permission.service";
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {ExchangeService} from '../../../../../../common/exchange.service';
import {GrowlMessageController} from "../../../../../../common/growl-message/growl-message-controller";
import {IdGeneratorService} from "../../../../../../common/service/id-generator.service";
import {TranslatedSelectItemService} from '../../../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../../../common/validation-errors';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {MultiValidator} from "../../../../../../shared/validator/input-validator";
import {ErrorResponse} from "../../../../../errors/errorResponse";
import {SubwindowTypeService} from "../../../../../window-system/subwindow-type/subwindow-type.service";
import {PositionType} from '../../../../AbstractPosition';
import {OfferComponentsCounter} from "../../../../window-editor/drawing-tool/OfferComponentsCounter";
import {Pricing} from "../../../../window-editor/sidebar/pricing/Pricing";
import {PricingService} from "../../../../window-editor/sidebar/pricing/pricing.service";
import {WindowEditorOfferData, WindowEditorPositionData} from '../../../../window-editor/window-editor-offer-interfaces';
import {MessageSeverity, PositionMessage} from '../../../message';
import {ConfigurableAddonsService} from "../../config-addons.service";
import {PositionService} from "../../position.service";
import {ConfigurableAddonFormHandler} from "../ConfigurableAddonFormHandler";
import {Cecha} from "../ConfigurableAddonModel/Cecha";
import {ConfigurableAddonDefinition} from "../ConfigurableAddonModel/ConfigurableAddonDefinition";
import {ConfigurableAddonDefinitionRoDto} from "../ConfigurableAddonModel/ConfigurableAddonDefinitionRoDto";
import {ConfigurableAddonDialogEventModel} from "../ConfigurableAddonModel/ConfigurableAddonDialogEventModel";
import {ConfigurableAddonType} from "../ConfigurableAddonModel/ConfigurableAddonType";
import {Position} from "../position";
import {AddConfigurableAddonDialogData, DialogType, GlobalAddConfigAddonsDialogData} from "../position-list-dialogs";
import {ConfigAddonDefaultAttributeValue} from './config-addon-default-attribute-value';
import {ConfigurableAddonComponent} from "./configurableAddon.component";

@Component({
    selector: 'app-configurable-addon-dialog',
    templateUrl: './configurable-addon-dialog.component.html',
    styleUrls: ['./configurable-addon-dialog.component.css', '../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConfigurableAddonsService, PricingService, Permissions, PositionService, IdGeneratorService,
        SubwindowTypeService, ExchangeService, TranslatedSelectItemService]
})
export class ConfigurableAddonDialogComponent implements OnInit, AfterContentChecked, AfterViewChecked {

    public static readonly SAVE_CONFIG_ADDON_ID = 'saveConfigAddon';
    private static readonly LOAD_DATA_ID = 'ConfigurableAddonDialogComponent loadData';
    private static readonly REBUILD_FORM_ID = 'ConfigurableAddonDialogComponent rebuildConfigAddonFormWhenReady';

    @Input() dialogType: DialogType;
    @Input() offer: WindowEditorOfferData;
    @Input() selectedExchangeRate: number;
    @Input() selectedCurrency: string;
    @Input() currentActionPosition: WindowEditorPositionData | Position;
    @Input() showPriceSection = true;
    @Input() showDimensionSection = true;
    @Input() showQuantitySection = true;
    @Input() displayedDialogData: AddConfigurableAddonDialogData | GlobalAddConfigAddonsDialogData;
    @Input() allDefinitions: ConfigurableAddonDefinitionRoDto[];
    @Input() subwindowTypes: SubwindowTypes;
    @Input() readOnlyMode: boolean;

    /** Indicates that addon should be saved to database after form submit. */
    @Input() saveAddonOnFormSubmit = true;

    @Output() onSuccess = new EventEmitter<ConfigurableAddonDialogEventModel[]>();
    @Output() onClose = new EventEmitter<void>();

    DialogType = DialogType;

    configAddonApplications: Observable<SelectItem[]>;
    configAddonTypeItems: Observable<SelectItem[]>;
    configOtherSystemAddonTypeItems: Observable<SelectItem[]>;
    selectedConfigAddonType: ConfigurableAddonType;

    addonsOfType: ConfigurableAddonDefinitionRoDto[] = [];
    addonsOfTypeSelectItems: Observable<SelectItem[]>;
    configAddon: ConfigurableAddonDefinitionRoDto;

    configAddonInitialValues;
    initialAttributeValues: ConfigAddonDefaultAttributeValue[];

    newConfigAddonDefPricingOk: boolean;
    configAddonErrors: ValidationErrors = {};
    newConfigAddonDef: ConfigurableAddonDefinition;
    newConfigAddonDescriptionImage: string;
    configAddonError: ErrorResponse;
    private groupedConfigAddons: { [key: string]: ConfigurableAddonDefinitionRoDto[] } = {};
    configAddonCalculatedPrice = {price: ''};
    configAddonMessages: PositionMessage[] = [];
    parentElementDescription: ConfigAddonParentInfo[];
    userLang: string;
    quantityCecha: Cecha = new Cecha();
    quantity = 1;
    formBuilding = false;
    focusConfigAddonError = false;
    focusLastConfigAddonMessage = false;
    showValidationMessagesDialog = false;
    configuredAddon: ConfigurableAddon;

    @ViewChildren('configurableAddonComponent', {emitDistinctChangesOnly: false}) configurableAddonComponent: QueryList<ConfigurableAddonComponent>;
    @ViewChild('addonDialog', {static: true}) addonDialog: Dialog;
    @ViewChildren('messages') messages: QueryList<ElementRef>;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public translate: TranslateService,
                private configurableAddonService: ConfigurableAddonsService,
                private permissions: Permissions,
                private positionService: PositionService,
                private changeDetector: ChangeDetectorRef,
                private pricingService: PricingService,
                private idGeneratorService: IdGeneratorService,
                protected subwindowTypeService: SubwindowTypeService,
                private growls: GrowlMessageController,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController,
                private translatedSelectItemService: TranslatedSelectItemService) {
        this.userLang = translate.currentLang;
        this.resetQuantityField();
        this.initConfigApplicationSelect();
    }

    private initConfigApplicationSelect() {
        const applications = Object.keys(ConfigAddonApplication).filter(application => {
            return application !== ConfigAddonApplication[ConfigAddonApplication.INDEPENDENT]
                && application !== ConfigAddonApplication[ConfigAddonApplication.WINDOW];
        });
        this.configAddonApplications = this.translatedSelectItemService
            .buildUnsortedDropdown(applications, 'OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.GLOBAL_CHANGE.', undefined);
    }

    private setAddonsOfType(addonsOfType: ConfigurableAddonDefinitionRoDto[]): void {
        this.addonsOfType = addonsOfType;
        this.addonsOfTypeSelectItems = this.translatedSelectItemService.buildUnsortedDropdown(addonsOfType,
            definition => configurableAddonDefinitionTypeToTranslationKey(definition.type), undefined);
    }

    ngOnInit(): void {
        this.initConfigurableAddon();
    }

    ngAfterContentChecked() {
        if (this.focusLastConfigAddonMessage) {
            this.messages.changes.subscribe(() => {
                if (this.messages && this.messages.last) {
                    this.messages.last.nativeElement.focus();
                }
            });
            this.focusLastConfigAddonMessage = false;
        }
    }

    ngAfterViewChecked() {
        if (this.focusConfigAddonError) {
            document.getElementById('configAddonError').focus();
            this.focusConfigAddonError = false;
        }
    }

    initConfigurableAddon() {
        this.setAddonsOfType([]);
        this.newConfigAddonDefPricingOk = false;
        this.selectedConfigAddonType = undefined;
        this.blockUiController.block(ConfigurableAddonDialogComponent.LOAD_DATA_ID);
        if (this.allDefinitions) {
            let availableDefinitions = this.allDefinitions;
            if (this.currentActionPosition == null) {
                availableDefinitions = availableDefinitions.filter(config => config.active)
                    .filter(config => this.configIsApplicable(config));
            }
            this.processDefinitions(availableDefinitions);
        } else if (!this.readOnlyMode || this.currentActionPosition == undefined) {
            let onlyActiveFilter = this.currentActionPosition == null ? true : undefined;
            forkJoin({
                definitions: this.configurableAddonService.getDefinitions(onlyActiveFilter),
                subwindowTypes: this.subwindowTypes == undefined ? this.subwindowTypeService.getAll() : of(undefined)
            }).subscribe({
                next: data => {
                    this.processDefinitions(data.definitions.data);
                    if (data.subwindowTypes != undefined) {
                        this.subwindowTypes = new SubwindowTypes(data.subwindowTypes);
                    }
                    this.changeDetector.markForCheck();
                },
                error: (error) => this.errors.handle(error)
            });
        } else {
            const configData: ConfigurableAddon = JSON.parse(this.currentActionPosition.data);
            forkJoin({
                definitions: this.configurableAddonService.getItem(configData.definitionId),
                subwindowTypes: this.subwindowTypes == undefined ? this.subwindowTypeService.getAll() : of(undefined)
            }).subscribe({
                next: data => {
                    this.processDefinitions([data.definitions]);
                    if (data.subwindowTypes != undefined) {
                        this.subwindowTypes = new SubwindowTypes(data.subwindowTypes);
                    }
                    this.changeDetector.markForCheck();
                },
                error: (error) => this.errors.handle(error)
            });
        }
    }

    private configIsApplicable(definition: ConfigurableAddonDefinitionRoDto | ConfigurableAddonDefinition): boolean {
        return definition.addonType === ConfigurableAddonType.GATE || definition.applicableTo.find(element => element === this.displayedDialogData.application) != undefined;
    }

    private processDefinitions(definitions: ConfigurableAddonDefinitionRoDto[]): void {
        let availableDefinitions = definitions;
        if (this.currentActionPosition == null) {
            availableDefinitions = definitions.filter(config => this.configIsApplicable(config));
        }
        this.setAvailableTypes(availableDefinitions);

        if (this.currentActionPosition) {
            let configuredAddon: ConfigurableAddon = JSON.parse(this.currentActionPosition.data);
            this.newConfigAddonDefPricingOk = true;

            if (this.currentActionPosition['sellPrice']) {
                const position = this.currentActionPosition as Position;
                let buyPrice;
                let sellPrice;
                if (this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
                    buyPrice = position.sellPrice.netValue;
                    sellPrice = position.retailSellPrice.netValue;
                } else {
                    buyPrice = position.buyPrice.netValue;
                    sellPrice = position.sellPrice.netValue;
                }
                this.setCalculatedPrice(buyPrice, sellPrice);
            } else {
                this.configAddonCalculatedPrice.price = undefined;
            }

            this.configurableAddonService.getDefinition(configuredAddon.definitionId).subscribe({
                next: def => {
                    this.newConfigAddonDef = def;
                    this.selectedConfigAddonType = def.addonType;
                    this.setAddonsOfType(this.groupedConfigAddons[def.addonType]);
                    let addonOfType = this.addonsOfType ? this.addonsOfType.find(addonOfType => addonOfType.id === configuredAddon.definitionId) : undefined;

                    let loadEditedAddonOfUnavailableSupplierObservable = addonOfType ? of(null) : this.configurableAddonService.getItem(configuredAddon.definitionId);
                    loadEditedAddonOfUnavailableSupplierObservable.subscribe({
                        next: editedAddonOfUnavailableSupplier => {
                            if (def.active && this.configIsApplicable(def)) {
                                if (!this.addonsOfType || !this.addonsOfType.find(addonOfType => addonOfType.id === configuredAddon.definitionId)) {
                                    availableDefinitions.push(editedAddonOfUnavailableSupplier);
                                    this.setAvailableTypes(availableDefinitions);
                                    this.setAddonsOfType(this.groupedConfigAddons[def.addonType]);
                                }
                                this.setAddonsOfType(this.addonsOfType.filter(config => config.active && this.configIsApplicable(config)));
                            } else {
                                this.readOnlyMode = true;
                            }
                            this.configAddon = this.addonsOfType.find(addon => addon.type === def.type);
                            this.parentElementDescription = configuredAddon.parentElementDescription;
                            this.quantity = this.currentActionPosition.quantity;
                            this.configAddonInitialValues = configuredAddon;
                            this.resetQuantityField();
                            this.updateAvailableDefinitionsSetSelectedAndBuildForm(def);
                        },
                        error: error => {
                            this.errors.handle(error);
                            this.blockUiController.unblock(ConfigurableAddonDialogComponent.LOAD_DATA_ID);
                        }
                    });
                },
                error: (error) => {
                    this.errors.handle(error);
                    this.blockUiController.unblock(ConfigurableAddonDialogComponent.LOAD_DATA_ID);
                }
            });
        } else {
            this.blockUiController.unblock(ConfigurableAddonDialogComponent.LOAD_DATA_ID);
        }
    }

    private setAvailableTypes(availableDefinitions: ConfigurableAddonDefinitionRoDto[]): void {
        this.configAddonTypeItems = this.translatedSelectItemService.buildUnsortedDropdown(_.unique(
            availableDefinitions.sort((a, b) => a.sortIndex - b.sortIndex)
                .filter(config => !config.isOtherSystem && config.applicableTo)
                .map(config => config.addonType)
        ), 'OFFER.POSITIONS.ADDON_CONFIG.TYPES.', undefined);

        this.configOtherSystemAddonTypeItems = this.translatedSelectItemService.buildUnsortedDropdown(_.unique(
            availableDefinitions.filter(config => config.isOtherSystem).map(config => config.addonType)
        ), 'OFFER.POSITIONS.ADDON_CONFIG.TYPES.', undefined);

        this.groupedConfigAddons = _.groupBy(availableDefinitions, "addonType");
    }

    private resetQuantityField() {
        this.quantityCecha = new Cecha();
        this.quantityCecha.minValue = 1;
        this.quantityCecha.step = 1;
        this.quantityCecha.obowiazkowa = true;
        this.quantityCecha.symbol = 'quantity';
        this.quantityCecha.type = CechaType.NUMERIC_VALUE;
        this.quantityCecha.nameCode = 'OFFER.POSITIONS.ADDON_CONFIG.FORM.QUANTITY';
    }

    private updateAvailableDefinitionsSetSelectedAndBuildForm(selected: ConfigurableAddonDefinition) {
        this.selectedConfigAddonType = selected.addonType;
        this.configAddon =
            this.addonsOfType.find(addon => addon.id === selected.id);
        this.changeDetector.markForCheck();
        let observables = forkJoin({
            definition: (this.newConfigAddonDef != null && this.newConfigAddonDef.id === selected.id) ? of(this.newConfigAddonDef) : this.configurableAddonService.getDefinition(selected.id),
            image: this.showDimensionSection ? this.configurableAddonService.getDescriptionImageForItem(this.configAddon.id) : of<string>(null)
        });
        observables.pipe(
            finalize(() => this.blockUiController.unblock(ConfigurableAddonDialogComponent.LOAD_DATA_ID))
        ).subscribe({
            next: data => {
                this.newConfigAddonDef = data.definition;
                this.newConfigAddonDescriptionImage = this.showDimensionSection && data.image;
                this.changeDetector.markForCheck();
                this.rebuildConfigAddonFormWhenReady();
            },
            error: (error) => this.errors.handle(error)
        });
    }

    changeQuantity(event) {
        this.quantity = event;
    }

    updateAvailableDefinitions(configAddonType: ConfigurableAddonType, configAddon?: ConfigurableAddonDefinitionRoDto): void {
        this.selectedConfigAddonType = configAddonType;
        this.configAddonError = undefined;
        this.newConfigAddonDef = undefined;
        this.configAddonMessages = undefined;
        this.configAddonErrors = {};
        if (this.selectedConfigAddonType == undefined) {
            return;
        }
        this.setAddonsOfType(this.groupedConfigAddons[this.selectedConfigAddonType]);
        if (this.dialogType !== DialogType.ADD_OTHER_SYSTEM_ADDON) {
            this.setAddonsOfType(this.addonsOfType.filter(addon =>
                addon.applicableTo.find(element => element === this.displayedDialogData.application) != undefined));
        }
        this.buildConfigAddonForm(configAddon == null ? this.addonsOfType[0] : configAddon);
    }

    buildConfigAddonForm(configAddon: ConfigurableAddonDefinitionRoDto | undefined): void {
        this.configAddon = configAddon;
        if (configAddon == undefined) {
            return;
        }
        this.newConfigAddonDefPricingOk = false;
        this.newConfigAddonDef = null;
        this.formBuilding = true;
        this.blockUiController.block(ConfigurableAddonDialogComponent.REBUILD_FORM_ID);
        forkJoin({
            definition: this.configurableAddonService.getDefinition(this.configAddon.id),
            descriptionImage: this.showDimensionSection
                ? this.configurableAddonService.getDescriptionImageForItem(this.configAddon.id)
                : of<string>(null)
        }).subscribe({
            next: response => {
                this.newConfigAddonDef = response.definition;
                this.newConfigAddonDescriptionImage = response.descriptionImage;
                this.newConfigAddonDefPricingOk = true;
                this.configAddonError = undefined;
                this.configAddonMessages = undefined;
                this.configAddonCalculatedPrice = {price: ''};
                this.configAddonErrors = {};
                this.configAddonInitialValues = null;
                this.initialAttributeValues = null;
                this.rebuildConfigAddonFormWhenReady();
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
                this.blockUiController.unblock(ConfigurableAddonDialogComponent.REBUILD_FORM_ID);
            }
        });
    }

    onRebuildFormComplete(): void {
        this.addonDialog.center();
        this.formBuilding = false;
    }

    getDescription(description: ConfigAddonParentInfo) {
        let name = description.name[this.userLang];
        if (description.application === ConfigAddonApplication.SUBWINDOW) {
            name = this.subwindowTypes.getFromMultilangName(description.name).names[this.translate.currentLang];
        }
        return name + " " + description.dimensions;
    }

    private resetDialog(): void {
        this.dialogType = undefined;
        this.newConfigAddonDef = undefined;
        this.configAddon = undefined;
        this.parentElementDescription = undefined;
        this.newConfigAddonDefPricingOk = false;
        this.selectedConfigAddonType = undefined;
        this.currentActionPosition = undefined;
        this.configAddonCalculatedPrice.price = '';
        this.configAddonMessages = [];
        this.setAddonsOfType([]);
        this.configAddonError = undefined;
        this.configAddonErrors = {};
        this.configAddonInitialValues = null;
        this.resetQuantityField();
    }

    recalculateConfigAddonPrice(): void {
        this.configAddonError = undefined;
        this.configAddonMessages = [];
        this.configuredAddon = this.configurableAddonComponent.first.item;
        this.setAddonProperties();
        let errors = this.validateConfigAddon();

        if (errors && Object.keys(errors).length !== 0) {
            this.configAddonCalculatedPrice.price = undefined;
            this.configAddonErrors = Object.assign({}, errors);
            this.changeDetector.markForCheck();
        } else {
            return this.recalculateConfigAddon(false);
        }
    }

    private setAddonProperties() {
        this.configuredAddon.offerId = this.offer.id;
        this.configuredAddon.parentElementDescription = this.parentElementDescription;
        this.configuredAddon.application = this.displayedDialogData.application;
        this.configuredAddon.openings = this.displayedDialogData.openings;
        this.configuredAddon.windowSystemId = this.displayedDialogData.windowSystemId;
    }

    submitDialog(): void {
        if (this.readOnlyMode) {
            return;
        }

        this.blockUiController.block(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
        this.configuredAddon = this.configurableAddonComponent.first.item;
        this.setAddonProperties();
        let errors = this.validateConfigAddon(this.quantity);

        if (Object.keys(errors).length > 0) {
            this.configAddonErrors = Object.assign({}, errors);
            this.changeDetector.detectChanges();
            this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
        } else {
            if (this.saveAddonOnFormSubmit) {
                this.recalculateConfigAddon(true);
            } else {
                this.save();
            }

        }
    }

    private save(): void {
        this.configuredAddon.attributes.forEach(attr => {
            if (attr.visible === false) {
                if (attr.type === CechaType.OPTIONS && attr.value !== 'NONE') {
                    attr.value = '0';
                } else if (attr.type !== CechaType.OPTIONS) {
                    attr.value = '';
                }
            }
        });
        let position;
        if (this.currentActionPosition) {
            position = Object.assign({}, this.currentActionPosition);
            position.data = JSON.stringify(this.configuredAddon);
            position.quantity = this.quantity;
            position.configurableAddonDefinitionType = this.configuredAddon.configurableAddonDefinitionType;
            position.dimensions = this.getDimensions();
        } else {
            position = new Position(undefined, PositionType.CONFIGURABLE_ADDON,
                this.configuredAddon.configurableAddonDefinitionType, this.getDimensions(), this.quantity, null, null,
                null, null, null, null, null, null, null, null,
                JSON.stringify(this.configuredAddon), this.offer.id, null);
        }

        position.supplierId = this.newConfigAddonDef.supplier.id;

        let positions: Position[] = [position];
        if (this.saveAddonOnFormSubmit) {
            this.positionService.saveItem(position).subscribe({
                next: () => {
                },
                error: (error: HttpErrorResponse): void => {
                    this.errors.handle(error);
                    this.configAddonError = new ErrorResponse(error.error);
                    this.showValidationMessagesDialog = false;
                    this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                    this.changeDetector.markForCheck();
                },
                complete: (): void => {
                    this.finishAndEmitSuccess("Saved to database.", positions);
                    this.changeDetector.markForCheck();
                }
            });
        } else {
            if (position.id || position.assignedId) {
                this.finishAndEmitSuccess("Edited existing addon.", positions);
            } else {
                let addonsCount;
                if (this.dialogType === DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON) {
                    let globalAddData = this.displayedDialogData as GlobalAddConfigAddonsDialogData;
                    addonsCount = OfferComponentsCounter.countApplicable(globalAddData.selectedPositions, this.configuredAddon.application);
                } else {
                    addonsCount = this.displayedDialogData.addonsCount;
                }
                this.idGeneratorService.getGeneratedOfflineIds(addonsCount).subscribe({
                    next: data => {
                        for (let i = 0; i < data.ids.length; i++) {
                            if (i > 0) {
                                position = JSON.parse(JSON.stringify(position));
                                positions.push(position);
                            }
                            position.assignedId = data.ids[i];
                        }
                        this.finishAndEmitSuccess("Created new addon with generated id", positions);
                    },
                    error: (error) => {
                        this.errors.handle(error);
                        this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                    }
                });
            }
        }
    }

    private finishAndEmitSuccess(message: string, positions: Position[]) {
        this.dialogHideHelper.call(() => {
            this.emitOnSuccess(positions, this.dialogType);
            this.resetDialog();
            this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
            console.info('ConfigurableAddonDialog action `saveItem` completed! ' + message);
        });
    }

    private getDimensions(): string {
        let szer = this.getAttrValue('szer');
        if (szer == null || szer === "") {
            return this.getAttrValue('wym1') + 'x' + this.getAttrValue('wym2');
        }
        return szer + 'x' + this.getAttrValue('wys');
    }

    private getAttrValue(attrSymbol: string): string {
        let addonAttribute = this.configuredAddon.attributes.find(attr => attr.symbol === attrSymbol);
        return addonAttribute && addonAttribute.value;
    }

    onDialogClose(): void {
        this.dialogHideHelper.call(() => {
            this.resetDialog();
            this.emitOnClose();
        });
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    private rebuildConfigAddonFormWhenReady() {
        if (this.configurableAddonComponent.length === 0) {
            this.configurableAddonComponent.changes.pipe(take(1), mergeMap(data => {
                return interval(50).pipe(takeWhile(() => !data.first.componentReady.getValue()));
            })).pipe(
                finalize(() => this.blockUiController.unblock(ConfigurableAddonDialogComponent.REBUILD_FORM_ID))
            ).subscribe({
                complete: () => {
                    this.configurableAddonComponent.first.rebuildForm();
                }
            });
        } else {
            interval(50).pipe(takeWhile(() => !this.configurableAddonComponent.first.componentReady.getValue())
            ).pipe(
                finalize(() => this.blockUiController.unblock(ConfigurableAddonDialogComponent.REBUILD_FORM_ID))
            ).subscribe({
                complete: () => {
                    this.configurableAddonComponent.first.rebuildForm();
                }
            });
        }
    }

    private validateConfigAddon(quantity = 1) {
        let handler = new ConfigurableAddonFormHandler();
        let errors = handler.validateAddon(this.configuredAddon.attributes, this.newConfigAddonDef, !this.showDimensionSection);

        let validationError = MultiValidator.of('error.configAddon.quantity')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 1000000, true, false)
            .validate('' + quantity);

        if (validationError != undefined) {
            errors['quantity'] = validationError;
        }

        return errors;
    }

    private recalculateConfigAddon(savingMode: boolean) {
        const validationDisabled = this.currentActionPosition ? this.currentActionPosition.validationDisabled : false;
        this.pricingService.evaluateAddons(this.offer.id, [this.configuredAddon], [validationDisabled]).subscribe({
            next: pricingResultList => {
                if (pricingResultList[0].pricing == null) {
                    this.configAddonError = new ErrorResponse({
                        message: "error.pricing.pricingFailed",
                        pricingFailedFields: pricingResultList[0].failedFields
                    });
                    this.growls.error(this.configAddonError.message);
                    this.changeDetector.markForCheck();
                    this.focusConfigAddonError = true;
                    if (savingMode) {
                        this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                    }
                } else {
                    let pricing = pricingResultList[0].pricing;
                    const seenMessages = new Set<string>();
                    const configAddonMessages = Pricing.getAllMessages(pricing)
                        .filter(message => message.severity !== MessageSeverity.CODE);
                    const extractErrorMessageUniqueKey = (message: PositionMessage): string => message.messageCode;
                    this.configAddonMessages = [];
                    configAddonMessages.forEach(configAddonMessage => {
                        const seenMessage = extractErrorMessageUniqueKey(configAddonMessage);
                        if (!seenMessages.has(seenMessage)) {
                            this.configAddonMessages.push(configAddonMessage);
                            seenMessages.add(seenMessage);
                        }
                    });
                    if (savingMode) {
                        if (this.configAddonMessages.length > 0) {
                            this.showValidationMessagesDialog = true;
                            this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                        } else {
                            this.save();
                        }
                    } else {
                        if (!Pricing.containsErrors(pricing) && !Pricing.containsBlockers(pricing)) {
                            let buyPrice;
                            let sellPrice;
                            if (this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
                                buyPrice = pricing.sellPrice.netValue;
                                sellPrice = pricing.retailSellPrice.netValue;
                            } else {
                                buyPrice = pricing.buyPrice.netValue;
                                sellPrice = pricing.sellPrice.netValue;
                            }
                            this.setCalculatedPrice(buyPrice, sellPrice);
                        }
                        if (this.configAddonMessages.length > 0) {
                            this.focusLastConfigAddonMessage = true;
                        }
                    }
                    this.changeDetector.markForCheck();
                }
            },
            error: (error: HttpErrorResponse) => {
                this.errors.handle(error);
                this.configAddonError = new ErrorResponse(error.error);
                this.changeDetector.markForCheck();
                this.focusConfigAddonError = true;
                if (savingMode) {
                    this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                }
            }
        });
    }

    private setCalculatedPrice(buyPrice: number, sellPrice: number): void {
        let buyPriceOfferCurrency = this.formatPriceInOfferCurrency(buyPrice);
        let sellPriceOfferCurrency = this.formatPriceInOfferCurrency(sellPrice);
        if (this.offer.currency !== this.selectedCurrency) {
            let buySelectedCurrency = this.formatPriceInSelectedCurrency(buyPrice);
            let sellSelectedCurrency = this.formatPriceInSelectedCurrency(sellPrice);
            this.configAddonCalculatedPrice.price = `${buyPriceOfferCurrency}/${sellPriceOfferCurrency}\n${buySelectedCurrency}/${sellSelectedCurrency}`;
        } else {
            this.configAddonCalculatedPrice.price = `${buyPriceOfferCurrency}/${sellPriceOfferCurrency}`;
        }
    }

    private formatPriceInOfferCurrency(price: number): string {
        return this.formatPriceInCurrency(price, this.offer.exchangeRate, this.offer.currency);
    }

    private formatPriceInSelectedCurrency(price: number): string {
        return this.formatPriceInCurrency(price, this.selectedExchangeRate, this.selectedCurrency);
    }

    private formatPriceInCurrency(price: number, exchangeRate: number, currency: string): string {
        return ExchangeService.formatPriceInCurrency(
            ExchangeService.getPriceInDefaultCurrency(price, exchangeRate), currency);
    }

    private emitOnSuccess(positions: Position[], mode: DialogType): void {
        this.onSuccess.emit(
            positions.map(position => new ConfigurableAddonDialogEventModel(mode, position, this.configuredAddon, this.newConfigAddonDef.name)));
    }

    private emitOnClose(): void {
        this.onClose.emit();
    }

    showParentInfoIndex(application: ConfigAddonApplication): boolean {
        return application === ConfigAddonApplication.INDEPENDENT || application === ConfigAddonApplication.AREA;
    }

    closeValidationMessagesDialog(): void {
        this.showValidationMessagesDialog = false;
    }

    confirmValidationMessages(): void {
        this.blockUiController.block(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
        this.save();
    }

    definitionKey = (definition: ConfigurableAddonDefinitionRoDto | ConfigurableAddonDefinition) => definition.id;
}
