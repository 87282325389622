import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UnitConverter} from '../unit-converter';
import {DisplayUnitService, SupportedDisplayUnitSystem} from './display-unit.service';

@Pipe({
    name: 'convertMillimetersToInches'
})
export class ConvertMillimetersToInchesPipe implements PipeTransform {

    constructor(private readonly displayUnitService: DisplayUnitService) {
    }

    transform(value: string | number, ...args: unknown[]): Observable<string> {
        return this.displayUnitService.system.pipe(map(system => {
            if (value == undefined) {
                return undefined;
            }
            if (typeof value !== 'string') {
                value = `${value}`;
            }
            if (system !== SupportedDisplayUnitSystem.IMPERIAL) {
                return value;
            }
            return value.replace(/([0-9.]+)/g, (match): string => {
                const dimension = Number.parseFloat(match);
                if (isNaN(dimension)) {
                    return match;
                }
                const inches = UnitConverter.millimitersToInches(dimension);
                let inchesText = `${inches.full}`;
                if (inches.sixteenths !== 0) {
                    inchesText += `\u2060${inches.sixteenths}\u204416`;
                }
                return inchesText;
            });
        }));
    }
}
