<div class="new-form-60vw">
    <div class="create-supplier create-supplier-section-header">
        <span>{{ 'SUPPLIER-DETAILS.FORM.ADDRESS-SECTION-TITLE' | translate }}</span>
    </div>

    <!-- ADDRESS - STREET -->
    <div class="new-form-row">
        <app-input-text inputId="address.street"
                        label="{{ 'SUPPLIER-DETAILS.FORM.ADDRESS.STREET' | translate }}"
                        [maxlength]="100"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['address.street']"
                        [(ngModel)]="supplier.address.street" [required]="true"></app-input-text>
    </div>

    <!-- ADDRESS - CITY -->
    <div class="new-form-row">
        <app-input-text inputId="address.city"
                        label="{{ 'SUPPLIER-DETAILS.FORM.ADDRESS.CITY' | translate }}"
                        [maxlength]="50"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['address.city']"
                        [(ngModel)]="supplier.address.city" [required]="true"></app-input-text>
    </div>

    <!-- ADDRESS - COUNTRY -->
    <div class="new-form-row">
        <app-select inputId="address.country" label="{{ 'SUPPLIER-DETAILS.FORM.ADDRESS.COUNTRY' | translate }}"
                    [(validationMessageKey)]="validationErrors['address.country']"
                    [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                    [options]="countries | async" [required]="true" [(ngModel)]="supplier.address.country"></app-select>
    </div>

    <!-- ADDRESS - ZIP -->
    <div class="new-form-row">
        <app-input-zip inputId="address.zip" label="{{ 'SUPPLIER-DETAILS.FORM.ADDRESS.ZIP' | translate }}"
                       [(validationMessageKey)]="validationErrors['address.zip']"
                       [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']}) || supplier.address.country == undefined"
                       [required]="true" [country]="supplier.address.country"
                       [(ngModel)]="supplier.address.zip"></app-input-zip>
    </div>

    <div class="create-supplier create-supplier-header-spacer"></div>

    <div class="create-supplier create-supplier-section-header">
        <span>{{ 'SUPPLIER-DETAILS.FORM.CONTACT-SECTION-TITLE' | translate }}</span>
    </div>

    <!-- CONTACT - NAME -->
    <div class="new-form-row">
        <app-input-text inputId="contact.name"
                        label="{{ 'SUPPLIER-DETAILS.FORM.CONTACT.NAME' | translate }}"
                        [maxlength]="100"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['contact.name']"
                        [(ngModel)]="supplier.contact.name"></app-input-text>
    </div>

    <!-- CONTACT - PHONE -->
    <div class="new-form-row">
        <app-input-text inputId="contact.phone"
                        label="{{ 'SUPPLIER-DETAILS.FORM.CONTACT.PHONE' | translate }}"
                        [maxlength]="50"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['contact.phone']"
                        [(ngModel)]="supplier.contact.phone"></app-input-text>
    </div>

    <!-- CONTACT - EMAIL -->
    <div class="new-form-row">
        <app-input-text inputId="contact.email"
                        label="{{ 'SUPPLIER-DETAILS.FORM.CONTACT.EMAIL' | translate }}"
                        [maxlength]="255"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['contact.email']"
                        [(ngModel)]="supplier.contact.email"></app-input-text>
    </div>

    <!-- CONTACT - FAX -->
    <div class="new-form-row">
        <app-input-text inputId="contact.fax"
                        label="{{ 'SUPPLIER-DETAILS.FORM.CONTACT.FAX' | translate }}"
                        [maxlength]="50"
                        [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                        [(validationMessageKey)]="validationErrors['contact.fax']"
                        [(ngModel)]="supplier.contact.fax"></app-input-text>
    </div>
</div>
