import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import * as moment from "moment";

@JsonDeserializable
export class News implements CrudItem {
    id: number;
    title = new MultilanguageField();
    content = new MultilanguageField();
    validFrom: Date = new Date();
    validTo: Date = moment().add(1, 'days').toDate();
    visibleFor: number[] = [];
    published: boolean;
    showOnDashboard = true;
    markedAsRead: boolean;
    image?: string;

    static fromJSON(jsonObject: any): News {
        const news = new News();
        news.id = jsonObject.id;
        if (jsonObject.title != undefined) {
            news.title = MultilanguageField.fromJSON(jsonObject.title);
        }
        if (jsonObject.content != undefined) {
            news.content = MultilanguageField.fromJSON(jsonObject.content);
        }
        news.validFrom = jsonObject.validFrom == undefined ? new Date() : new Date(jsonObject.validFrom);
        news.validTo = jsonObject.validTo == undefined ? moment().add(1, 'days').toDate() : new Date(jsonObject.validTo);
        if (jsonObject.visibleFor != undefined) {
            news.visibleFor = [...jsonObject.visibleFor];
        }
        news.published = jsonObject.published;
        news.showOnDashboard = jsonObject.showOnDashboard;
        news.markedAsRead = jsonObject.markedAsRead;
        news.image = jsonObject.image;
        return news;
    }
}
