import {CrudItem} from "../../../../common/crud-common/crudItem";
import {ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {CatalogConfiguration} from "../../../catalog-creator/catalog-configuration";
import {MenuItem} from "primeng/api/menuitem";
import {SelectItem} from "primeng/api/selectitem";
import {CatalogProperty} from "../../../catalog-creator/catalog-property/catalog-property";
import {CatalogOption} from "../../../catalog-creator/catalog-option/catalog-option";
import {MaterialType} from "../../../../common/enums/MaterialType";
import {Observable} from "rxjs";

@Directive()
export abstract class AddProductDialogDataProviderComponent<Item extends CrudItem> implements OnInit {

    @Input() displayDialog: boolean;

    @Input() items: Item[]; // type of item selected in the table
    @Input() catalogConfiguration: CatalogConfiguration;
    @Input() largeImageGetter: (id: number) => Observable<string>;

    @Output() dialogClosed = new EventEmitter<any>();
    @Output() openStorageFile: EventEmitter<number> = new EventEmitter<number>();
    submitButtonLabel = 'OFFER.DRAWING.SYSTEM_ADD';
    largeImageId: number;

    tabItems: MenuItem[] = [];
    activeTabItem: MenuItem;
    dropdownSelectOptions: SelectItem[];
    selectedDropdownSelectOption: any;
    showDropdown: boolean;
    dropdownSelectLabel = '';

    tableHeader = '';
    tableOptions: Item[] = [];
    tableOptionNames: string[] = [];
    tableOptionIcons: any[] = [];
    selectedTableOption: Item;
    tableOptionDescriptions: string[] = [];

    selectedValueDescription: { property: CatalogProperty, option: CatalogOption }[] = [];
    selectedValueImage: SafeUrl;
    hasSlider: boolean;

    protected constructor(public translate: TranslateService,
                          public domSanitizer: DomSanitizer,
                public changeDetector: ChangeDetectorRef) {
    }

    abstract ngOnInit();

    handleDialogClosed(cancel: boolean) {
        this.dialogClosed.emit(cancel ? null : this.prepareDataToSubmit());
    }

    prepareDataToSubmit(): any {
        return this.selectedTableOption.id;
    }

    trackBy = (index: number, item: Item) => {
        return item.id;
    }

    prepareDescription(dto: { [propertyId: number]: number; }): { property: CatalogProperty, option: CatalogOption }[] {
        let description: { property: CatalogProperty, option: CatalogOption }[] = [];
        for (let propertyId in dto) {
            let property = this.catalogConfiguration.properties.find(p => p.id === +propertyId);
            let option = this.catalogConfiguration.options.find(o => o.id === dto[propertyId]);
            if (property && option && this.canShowDescriptionOptionProperty(option, property)) {
                description.push({property: property, option: option});
            }
        }
        description.sort((a, b) => a.property.sortIndex - b.property.sortIndex);
        return description;
    }

    canShowDescriptionOptionProperty(option: CatalogOption, property: CatalogProperty): boolean {
        return true;
    }

    initMaterialTabs(): void {
        this.tabItems = [
            this.prepareMaterialTab(MaterialType.PCV),
            this.prepareMaterialTab(MaterialType.WOOD),
            this.prepareMaterialTab(MaterialType.ALUMINIUM),
            this.prepareMaterialTab(MaterialType.ALUMINIUM_WOOD_MIX)
        ];
    }

    prepareMaterialTab(materialType: MaterialType): MenuItem {
        return {
            label: this.translate.instant('MATERIAL.' + materialType),
            icon: 'fa fa-windows',
            command: () => this.handleTabChange(materialType),
            disabled: !this.hasWindowsOfMaterialType(materialType),
            tabindex: materialType
        };
    }

    abstract handleTabChange(tabItem: any): void;

    private hasWindowsOfMaterialType(material: MaterialType): boolean {
        return this.getWindowSystems().some(system => system['material'] === material);
    }

    protected getWindowSystems(): any[] {
        return [];
    }
}
