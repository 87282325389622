import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Optional,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const CHECKBOX_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
};

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
    providers: [CHECKBOX_VALUE_ACCESSOR],
    encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends AbstractInputComponent {

    @Output()
    readonly onClick = new EventEmitter<MouseEvent>();

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    getContainer(): ElementRef {
        return this.containerElement;
    }

    handleClick(event: MouseEvent): void {
        this.onClick.emit(event);
    }
}
