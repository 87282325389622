import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {getLanguageInfoByCode} from '../../supportedLanguages';

const NUMBER_FORMAT_REGEXP = /^(\d+)?\.((\d+)(-(\d+))?)?(g)?$/;

function getFormatter(appLanguage: string, options: Intl.NumberFormatOptions): Intl.NumberFormat {
    const supportedLanguage = getLanguageInfoByCode(appLanguage);
    if (supportedLanguage != undefined) {
        return new Intl.NumberFormat(supportedLanguage.locale, options);
    }
    return new Intl.NumberFormat('en-GB', options);
}

export function formatNumber(value: number | undefined, appLanguage: string, digitsInfo?: string): string | undefined {
    if (value == undefined) {
        return undefined;
    }
    const options: Intl.NumberFormatOptions = {};
    if (digitsInfo) {
        const parts = digitsInfo.match(NUMBER_FORMAT_REGEXP);
        if (parts === null) {
            throw new Error(`${digitsInfo} is not a valid digit info`);
        }
        const minIntPart = parts[1];
        const minFractionPart = parts[3];
        const maxFractionPart = parts[5];
        if (minIntPart != undefined) {
            options.minimumIntegerDigits = parseInt(minIntPart, 10);
        }
        if (minFractionPart != undefined) {
            options.minimumFractionDigits = parseInt(minFractionPart, 10);
        }
        if (maxFractionPart != undefined) {
            options.maximumFractionDigits = parseInt(maxFractionPart, 10);
        }
        if (options.minimumFractionDigits != undefined
            && options.maximumFractionDigits != undefined
            && options.minimumFractionDigits > options.maximumFractionDigits) {
            options.maximumFractionDigits = options.minimumFractionDigits;
        }
        const alwaysGroupPart = parts[6];
        if (alwaysGroupPart != undefined) {
            options.useGrouping = true;
        }
    }
    return getFormatter(appLanguage, options).format(value);
}

@Pipe({
    name: 'number',
    pure: false
})
export class CustomDecimalPipe implements OnDestroy, PipeTransform {

    private subscription: Subscription;

    constructor(private readonly translate: TranslateService,
                changeDetector: ChangeDetectorRef) {
        this.subscription = translate.onLangChange.subscribe(() => {
            changeDetector.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    transform(value: number | undefined, digitsInfo?: string): string | undefined {
        return formatNumber(value, this.translate.currentLang, digitsInfo);
    }
}
