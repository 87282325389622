import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {WindowEditorFieldContentProvider} from '../../../offer/window-editor/window-editor-field-content-provider';
import {WindowDesignerCatalogDependentOption} from './window-designer-catalog-dependent-option';
import {WindowDesignerCatalogDependentOptionFormDependencyComponent} from './window-designer-catalog-dependent-option-form-dependency.component';

@Component({
    selector: 'app-window-designer-catalog-dependent-option-form-dependent',
    templateUrl: './window-designer-catalog-dependent-option-form-dependency.component.html',
    styleUrls: ['./window-designer-catalog-dependent-option-form-dependency.component.css']
})
export class WindowDesignerCatalogDependentOptionFormDependentComponent extends WindowDesignerCatalogDependentOptionFormDependencyComponent implements OnInit {

    constructor(public readonly translate: TranslateService,
                protected readonly translatedSelectItemService: TranslatedSelectItemService,
                protected readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider) {
        super(translate, translatedSelectItemService, windowEditorFieldContentProvider, false);
    }

    getInput(option: WindowDesignerCatalogDependentOption) {
        return {
            inputId: option.dependentInputId,
            inputValue: option.dependentInputValue,
            whenMatched: option.whenMatched
        }
    }

}
