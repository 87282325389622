<div class="table-header">
    <div class="title">{{ 'SETTINGS.SECTION.NEWS.GENERAL' | translate }}</div>
    <div class="table-header-buttons">
        <div class="table-header-buttons-container">
            <app-simple-button *ngIf="permissions.canEditNews()" label="{{ 'SETTINGS.SECTION.NEWS.ADD' | translate }}"
                               icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                               (onClick)="doShowDialogToAdd()"></app-simple-button>
        </div>
    </div>
</div>

<div class="news-container">
    <app-news-editor [news]="item" [(saveAsNew)]="saveAsNew" [futureNews]="futureNews" [currentNews]="currentNews"
                     [saveCopy]="copyMode" (newsSaved)="handleNewsUpdate($event)" (copySaved)="handleNewsCopied($event)"
                     (newsFinished)="handleNewsUpdate(null)"></app-news-editor>
    <div class="news-list">
        <p-table #dt [value]="itemList" [rows]="MAX_ROWS_WITHOUT_PAGINATION" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [lazyLoadOnInit]="false" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" dataKey="id"
                 [responsive]="true" sortField="validFrom" [sortOrder]="0" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="permissions.canEditNews()"></th>
                    <th [ngClass]="columnHeaderClasses('validFrom')">
                        <span>{{ 'NEWS.VALID_FROM' | translate }}</span>
                    </th>
                    <th [ngClass]="columnHeaderClasses('validTo')">
                        <span>{{ 'NEWS.VALID_TO' | translate }}</span>
                    </th>
                    <th [ngClass]="columnHeaderClasses('title')">
                        <span>{{ 'NEWS.TITLE' | translate }}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-news let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" [pSelectableRow]="news" [pSelectableRowIndex]="rowIndex"
                    (click)="doOnRowSelect({ data: news })" (dblclick)="doOnRowSelect({ data: news })"
                    [app-table-keyboard-navigation]="news"  [ngClass]="permissions.canEditNews() ? (news | newsColourClass) : ''"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({ data: news })">
                    <td *ngIf="permissions.canEditNews()">
                        <div class="table-row-action-buttons">
                            <app-simple-button [buttonId]="'copy_' + news.id" icon="content_copy" type="filter" [size]="36"
                                               (onClick)="actionOnClick('COPY_NEWS', news)"></app-simple-button>
                            <app-simple-button [buttonId]="'delete_' + news.id" icon="delete" type="filter" [size]="36"
                                               (onClick)="actionOnClick('DELETE_NEWS', news)"></app-simple-button>
                        </div>
                    </td>
                    <td>
                        {{ news.validFrom | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td>
                        {{ news.validTo | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td>
                        {{ news.title | multilanguageTranslate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template #paginatorPageInfoTemplate>
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
        <p-paginator [pageLinkSize]="3" (onPageChange)="dt.onPageChange($event)"
                     styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                     [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                     [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
    </div>
</div>

<app-confirm-action-dialog *ngIf="displayConfirmDeleteDialog" (onCancel)="hideConfirmDeleteDialog()"
                           (onConfirmed)="deleteNews(selectedItem)"
                           [question]="'GENERAL.DELETE_CONFIRMATION' | translate"
                           [header]="'GENERAL.DIALOG.DELETING' | translate"></app-confirm-action-dialog>
