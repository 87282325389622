import {Country} from './enums/country';

interface ZipCodeCountryData {
    regex: RegExp;
    inputMask: string;
    maxlength: number;
}

const ZipCodeCountryFormats: { [country: string]: ZipCodeCountryData } = {
    NORWAY: {
        regex: /^\d{4}$/,
        inputMask: '9999',
        maxlength: 4
    },
    SWEDEN: {
        regex: /^\d{3} \d{2}$/,
        inputMask: '999 99',
        maxlength: 6
    },
    POLAND: {
        regex: /^\d{2}-\d{3}$/,
        inputMask: '99-999',
        maxlength: 6
    },
    FINLAND: {
        regex: /^\d{5}$/,
        inputMask: '99999',
        maxlength: 5
    },
    DENMARK: {
        regex: /^\d{4}$/,
        inputMask: '9999',
        maxlength: 4
    },
    ICELAND: {
        regex: /^\d{3}$/,
        inputMask: '999',
        maxlength: 3
    },
    // SCOTLAND: {
    //     regex: /GIR ?0AA|(?:(?:AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(?:\d[\dA-Z]? ?\d[ABD-HJLN-UW-Z]{2}))|BFPO ?\d{1,4}/,
    //     inputMask: 'aa9?9aa',
    //     maxlength: 9
    // },
    SCOTLAND: {
        // temporary
        regex: /.*/,
        inputMask: '*?********',
        maxlength: 9
    },
    GERMANY: {
        regex: /^\d{5}$/,
        inputMask: '99999',
        maxlength: 5
    },
    HUNGARY: {
        regex: /^\d{4}$/,
        inputMask: '9999',
        maxlength: 4
    },
    CANADA: {
        regex: /^[A-Z]\d[A-Z] \d[A-Z]\d$/i,
        inputMask: 'a9a 9a9',
        maxlength: 7
    },
    IRELAND: {
        regex: /(?:^[ACDEFHKNPRTVWXY][0-9]{2}|D6W)[ -]?[0-9ACDEFHKNPRTVWXY]{4}$/i,
        inputMask: 'a9* ?****',
        maxlength: 8
    },
    NORTHERN_IRELAND: {
        // temporary
        regex: /.*/,
        inputMask: '*?********',
        maxlength: 9
    },
    GREAT_BRITAIN: {
        // temporary
        regex: /.*/,
        inputMask: '*?********',
        maxlength: 9
    },
};

export class ZipCodeValidator {

    validate(country: Country, zip: string): boolean {
        const format = this.getFormat(country);
        if (format == undefined) {
            return false;
        }
        if (!format.regex.test(zip)) {
            return false;
        }
        return true;
    }

    getFormat(country: Country): ZipCodeCountryData {
        return ZipCodeCountryFormats[country];
    }
}
