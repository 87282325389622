<div [class]="materialPopupClasses"
     [style.top.px]="getTopStyle"
     [style.left.px]="getMenuLeftPosition()"
     [id]="menuId" [ngClass]="{'hidden':!menuVisible}">

    <ul class="popup-menu" *ngIf="menuVisible">
        <ng-container *ngIf="menuType != MenuTypeChoices.ICON_LINE">
            <li *ngFor="let position of menuElements" [ngClass]="{'disabled': position.disabled}">
                <!--Regular-->
                <div *ngIf="position.type == menuElementType.REGULAR" [id]="position.identifier"
                     pTooltip="{{position.tooltip | translate}}"
                     class="menu-position" (mousedown)="!position.disabled && onPositionSelected(position.identifier)">
                    <span *ngIf="position.title">{{ position.title }}</span>
                    <span *ngIf="position.translationKey">{{ position.translationKey | translate }}</span>
                    <span *ngIf="position.productMark" [innerHTML]="position.productMark | appSafeValue: 'html'"></span>
                </div>

                <!--Navigation element-->
                <div *ngIf="position.type == menuElementType.NAVIGATION_LINK && isPermitted(position.navigationLink.roles)"
                     [id]="position.identifier" class="menu-position" (mousedown)="!position.disabled && onNavigateTo(position.navigationLink.routerLink)">

                    <span class="name" *ngIf="position.translateElementTitle">{{position.navigationLink.name | translate}}</span>
                    <span class="name" *ngIf="!position.translateElementTitle">{{position.navigationLink.name}}</span>
                </div>

                <!--Language bar-->
                <div *ngIf="position.type == menuElementType.LANGUAGE_SELECTOR" class="menu-position languages">
                    <app-languages-list></app-languages-list>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="menuType == MenuTypeChoices.ICON_LINE">
            <li>
                <div class="menu-position-icon-line">
                    <div *ngFor="let position of menuElements" class="icon-container" [ngClass]="{'disabled': position.disabled}">
                        <div (mousedown)="!position.disabled && onPositionSelected(position.identifier, $event)" [ngClass]="position.identifier" [id]="position.identifier" pTooltip="{{ position.translationKey | translate}}" tooltipPosition="bottom"></div>
                    </div>
                </div>
            </li>
        </ng-container>
    </ul>
</div>
