<p-dialog header="{{ 'MATERIAL_COLOR_LINKS.HEADER' | translate }} - {{ material.name[translate.currentLang] }}"
          [(visible)]="dataInitialized" (onHide)="closeDialog()" [resizable]="false" [modal]="true" [focusOnShow]="false">
    <div class="scrolled-dialog scrolled-dialog-600">
        <div class="new-form-600">

            <div class="rule-block">
                <ng-container *ngTemplateOutlet="ruleBlockContentTemplate; context: { colorId: selectedNewColor, added: false }"></ng-container>
            </div>

            <div class="rule-block" *ngFor="let colorId of getAddedColorsKeys(); trackBy: trackByColorId">
                <div class="block-separator">&nbsp;</div>
                <ng-container *ngTemplateOutlet="ruleBlockContentTemplate; context: { colorId: colorId, added: true }"></ng-container>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="close"
                               [label]="'GENERAL.EXIT' | translate"
                               type="cancel" [size]="40" (onClick)="closeDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>


<ng-template #ruleBlockContentTemplate let-colorId="colorId" let-added="added">
    <div style="display: flex">
        <div class="rule-selects">
            <div *ngIf="!added" class="new-form-row">
                <app-listbox-selection (onItemChange)="handleColorChange($event)" [item]="colorId"
                                       [columnsCount]="1"
                                       [disabled]="added" [options]="availableColorsOptions"
                                       [validationErrors]="{}"></app-listbox-selection>
            </div>
            <div *ngIf="added" class="new-form-row">
                <app-select [inputId]="'color_' + colorId"
                            label="{{ 'MATERIAL_COLOR_LINKS.COLOR' | translate }}"
                            [disabled]="true"
                            [noLongerAvailable]="colorId | isNotAvailable:allColorsOptions:true"
                            [options]="allColorsOptions"
                            [ngModel]="colorId"></app-select>
            </div>
            <div class="new-form-row"><div class="new-form-field"><div></div>
                <div *ngIf="!added && validationErrors['color']" class="new-form-field-error-message">
                    {{ validationErrors['color'] | translate }}
                </div>
            </div></div>

            <div class="new-form-row" style="position: relative">
                <app-input-text *ngIf="added"
                                inputId="hex" label="{{ 'MATERIAL_COLOR_LINKS.HEX' | translate }}"
                                [disabled]="true" [ngModel]="inputSelectedMapHexes.get(colorId)"></app-input-text>
                <app-input-text *ngIf="!added"
                                inputId="hex" label="{{ 'MATERIAL_COLOR_LINKS.HEX' | translate }}"
                                [(validationMessageKey)]="validationErrors['hex']" [maxlength]="7" [required]="false"
                                [(ngModel)]="hex"></app-input-text>
                <span *ngIf="!added" class="hex-preview" [ngStyle]="{'backgroundColor': hex}"></span>
                <span *ngIf="added" class="hex-preview" [ngStyle]="{'backgroundColor': inputSelectedMapHexes.get(colorId)}"></span>
            </div>

            <div class="new-form-row" *ngIf="!added">
                <app-file-upload (ngModelChange)="onImageChange($event)"
                                 [ngModel]="selectedFile" [widthToHeightRatio]="1"
                                 [(validationMessageKey)]="validationErrors['file']"
                                 [addButtonLabel]="'CONFIG_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CONFIG_SYSTEM.FORM.CHANGE_FILE_UPLOAD'"
                                 [maxHeight]="2000" [maxSize]="2000000" [maxWidth]="2000"
                                 inputId="image"></app-file-upload>
            </div>

            <div class="new-form-row" *ngIf="added">
                <div class="new-form-field">
                    <div></div>
                    <div>
                        <img *ngIf="inputSelectedMap.get(colorId) as image"
                             [ngStyle]="{'max-width': '100%', 'max-height.px': 200}"
                             [src]="image | appSafeValue: 'url'">
                    </div>
                </div>
            </div>
        </div>
        <div class="rule-actions">
            <ng-container *ngIf="added; else createNew">
                <app-simple-button [buttonId]="'remove_' + colorId" icon="remove" type="delete"
                                   [pTooltip]="'MATERIAL_COLOR_LINKS.ACTIONS.TOOLTIPS.DELETE' | translate"
                                   (onClick)="remove(colorId)"></app-simple-button>
            </ng-container>
            <ng-template #createNew>
                <app-simple-button buttonId="add" icon="add" type="main-action"
                                   [pTooltip]="'MATERIAL_COLOR_LINKS.ACTIONS.TOOLTIPS.ADD' | translate"
                                   [disabled]="containsErrors()" (onClick)="add()"></app-simple-button>
            </ng-template>

        </div>
    </div>
</ng-template>
