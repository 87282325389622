import {ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {SubsystemNoteCategory} from "./subsystem-note-category";
import {SubsystemNotesCategoriesService} from "./subsystem-notes-categories.service";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {callWizardStepValidators, WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {map} from "rxjs/operators";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {Observable, of} from "rxjs";
import {ValidationErrors} from "../../../common/validation-errors";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";

@Component({
    selector: 'app-subsystem-notes-categories',
    templateUrl: './subsystem-notes-categories.component.html',
    providers: [SubsystemNotesCategoriesService, TranslatedSelectItemService]
})
export class SubsystemNotesCategoriesComponent extends CrudCommonComponent<SubsystemNoteCategory, SubsystemNotesCategoriesService> {

    readonly STEPS = {
        DATA: 'DATA'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef
    ) {
        super(injector, changeDetector, false, SubsystemNotesCategoriesService, 'SUBSYSTEM_NOTES_CATEGORY', 'SubsystemNotesCategory');
        this.validateDataStep = () => this.validateData().pipe(map(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
        }));
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): SubsystemNoteCategory {
        const category = new SubsystemNoteCategory();
        category.name = new MultilanguageField();
        category.active = true;
        category.sortIndex = 1;
        return category;
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.itemId = event.data.id;
        this.item = new SubsystemNoteCategory();
        this.newItem = false;
        this.selectedItem = event.data;
        this.keepSelectedItemIndex(event);
        this.itemService.getItem(this.itemId).subscribe(data => {
            this.item = data;
            this.setDisplayDialog(true);
        })
    }

    protected validateForm(): Observable<boolean> {
        return callWizardStepValidators([this.validateDataStep])
            .pipe(map(results => results.every(result => result)));
    }

    private validateData(): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {};
        if (!this.item.name[this.translate.currentLang] || !this.item.name[this.translate.currentLang].trim()) {
            validationErrors[`name[${this.translate.currentLang}]`] =
                `error.subsystemNoteCategoryDto.name[${this.translate.currentLang}].not_empty`;
        }
        if (this.item.active == undefined) {
            validationErrors['active'] = 'error.subsystemNoteCategoryDto.active.not_null';
        }
        validationErrors['sortIndex'] = MultiValidator.of('error.subsystemNoteCategoryDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);
        return of<ValidationErrors>(validationErrors);
    }

}
