<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.MARK_PRODUCTS.GENERAL' | translate }}</div>
    </div>

    <div class="settings-form-wrapper">
        <p-tabView class="full-width-container" (onChange)="activeTab = $event.index">
            <p-tabPanel *ngFor="let product of products"
                        header="{{('MARK_PRODUCTS.' + product) | translate}}">
                <div class="new-form-60vw">
                    <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                        <app-input-text [inputId]="'title_' + lang.code"
                                        [label]="lang.name | translate"
                                        [validationMessageKey]="formatValidationMessage(validationErrorsByProduct.get(product)['markLabels['+ lang.code +']'], lang.code)"
                                        (validationMessageKeyChange)="handleValidationMessageChange($event, 'markLabels['+ lang.code +']')"
                                        [(ngModel)]="productsToMark.get(product)[lang.code]"></app-input-text>
                        <div class="preview">
                            <span [innerHTML]="productsToMark.get(product)[lang.code] | appSafeValue: 'html'"></span>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div>
            {{ 'MARK_PRODUCTS.SAMPLE' | translate }} {{sampleLabel}} - <span [innerHTML]="sampleLabel | appSafeValue: 'html'"></span>
        </div>
    </div>
    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button label="{{'MARK_PRODUCTS.CANCEL' | translate }}" icon="check" type="action"
                               [size]="40" (onClick)="cancel()"></app-simple-button>
            <app-simple-button label="{{'GENERAL.SAVE' | translate }}" icon="check" type="main-action"
                               [size]="40" (onClick)="save()"></app-simple-button>
        </div>
    </div>
</div>
