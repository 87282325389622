import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from 'rxjs';
import {AddonCategoryEnumGroup} from '../../../window-designer/enums/AddonCategoryEnum';
import {Permissions} from "../../auth/permission.service";
import {SecondLevelMenu} from "../../common/second-level-menu/SecondLevelMenu";
import {NavigationLink} from "../../NavigationLink";
import {SidenavController} from '../../sidenav-controller';
import {SubsystemService} from '../subsystem/subsystem.service';
import {GlazingPackageTarget} from './glazing-package/glazing-package';
import {DataModificationTarget} from './webshop-charge/WebshopCharge';
import {WindowDimensionsType} from "./window-dimensions/window-dimensions-type";
import {ApplicationFeatures} from "../../application-info/application-features";
import {CatalogSection} from "./addons/CatalogSection";
import {ColorTargetGroup} from "../../ColorType";

export type CatalogTab = 'windowSystems' | 'colors' | 'configColors' | 'glasses' | 'frames' | 'seals' | 'subwindowTypes' | 'businessTypes' | 'grills'
    | 'glazingPackages' | 'otherFillings' | 'decorativeFillings' | 'addons' | 'profiles' | 'materials' | 'configAddons' | 'glazingBeads'
    | 'assemblies' | 'racks' | 'roofWindowsDimensions' | 'roofSystems' | 'webshopGlazingPackages' | 'systemGlazingPackages'
    | 'systemDecorativeGlazingPackages' | 'terraceGlazingPackages' | 'webshopCharges' | 'catalogItemTags' | 'webshopAreaBars' | 'webshopBusinessTypes' | 'models'
    | 'windowSystemsWebshop' | 'webshopGlazingPackagesPerSubsystem' | 'webshopRecommendedConfiguration' | 'webshopSale'
    | 'webshopChargePerSubsystem' | 'transports' | 'windowDesignerCatalogDependentOption' | 'subsystemNotesCategories' | 'entranceSystems'
    | 'entranceGlazingPackages' | 'entranceModels' | 'entranceDimensions' | 'graspGlazingCategories' | 'graspGlazingPackages'
    | 'discontinuedCatalogItemInfo' | 'gateSystem' | 'railSystem' | 'gatePanelType' | 'addonCategoryGate' | 'addonCategoryConfig' | 'addonCategoryGroupGate' | 'addonCategoryGroupConfig'
    | 'configDesignerCatalogDependentOption' | 'gateDesignerCatalogDependentOption' | 'gateWall' | 'gateAddons' | 'configSidebarAddons' | 'configTypeDescriptor' | 'configSystem' | 'configSliderElement' | 'graspDistanceFrameCategory';

@Component({
    selector: 'app-window-system',
    templateUrl: './window-system.component.html',
    styleUrls: ['../../second-level-menu.css'],
    providers: [SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemComponent implements OnInit, OnDestroy, SecondLevelMenu<CatalogTab> {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItemsByGroup: {groupName: string, items: NavigationLink<CatalogTab>[]}[] = [];
    sideNavItems: NavigationLink<CatalogTab>[] = [];
    sideNavItemsTranslateSubscription: Subscription;
    activeSubComponent: string;
    webshopEnabledForSubsystem = false;
    glazingPackageTargets = GlazingPackageTarget;
    dataModificationTargets = DataModificationTarget;
    windowDimensionsTypes = WindowDimensionsType;
    addonCategoryEnumGroups = AddonCategoryEnumGroup;
    colorTarget = ColorTargetGroup;
    appFeatures: ApplicationFeatures;
    catalogSection = CatalogSection;

    constructor(public permissions: Permissions,
                private route: ActivatedRoute,
                private router: Router,
                private sidenavController: SidenavController,
                private changeDetector: ChangeDetectorRef,
                private translate: TranslateService,
                private subsystemService: SubsystemService) {
    }

    ngOnInit(): void {
        this.appFeatures = this.route.snapshot.data['features'] as ApplicationFeatures;
        if (this.isPermitted({roles: ["ROLE_OPERATOR"]})) {
            this.subsystemService.isWebshopEnabledForCurrentUserSubsystem()
                .subscribe(
                    response => {
                        this.webshopEnabledForSubsystem = response;
                        this.initialize();
                    });
        } else {
            this.initialize();
        }

        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.scrollToActiveTab();
            this.activeSubComponent;
            this.changeDetector.markForCheck();
        });
    }

    private scrollToActiveTab() {
        if (this.showMenu) {
            setTimeout(() => {
                const htmlElement = document.getElementById(this.activeSubComponent);
                const menuList = (document.getElementsByClassName('second-level-menu') || []);
                if (htmlElement != null && menuList.length > 0) {
                    const menu = menuList[0];
                    const count = htmlElement.offsetTop - menu.scrollTop - 10;
                    menu.scrollBy({top: count, left: 0, behavior: 'instant'});
                    this.changeDetector.markForCheck();
                }
            }, 0);
        }
    }

    private initialize(): void {
        this.prepareSideNavItems();
        this.initTranslationSubscription();
        this.route.paramMap.subscribe(paramMap => {
            this.switchToSubComponent((paramMap.get('tabName') as CatalogTab) || this.sideNavItems[0].cssClass);
        });
        this.scrollToActiveTab();
        this.changeDetector.markForCheck();
    }

    ngOnDestroy(): void {
        if (this.showMenuSubscription != undefined) {
            this.showMenuSubscription.unsubscribe();
        }
        if (this.sideNavItemsTranslateSubscription != undefined) {
            this.sideNavItemsTranslateSubscription.unsubscribe();
        }
    }

    private prepareSideNavItems(): void {
        let itemsGroups: {groupName: string, items: NavigationLink<CatalogTab>[]}[] = [];

        let catalogItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "addons", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDONS.LIST'),
            new NavigationLink('', "addons", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDONS.LIST'),
            new NavigationLink('', "windowSystems", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_SYSTEMS.LIST'),
            new NavigationLink('', "colors", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.COLORS.LIST'),
            new NavigationLink('', "glasses", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASSES.LIST'),
            new NavigationLink('', "frames", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISTANCE_FRAMES.LIST'),
            new NavigationLink('', "seals", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.SEALS.LIST'),
            new NavigationLink('', "subwindowTypes", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.SUBWINDOW_TYPES.LIST'),
            new NavigationLink('', "businessTypes", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.BUSINESS_TYPES.LIST'),
            new NavigationLink('', "grills", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILLS.LIST'),
            new NavigationLink('', "otherFillings", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.OTHER_FILLINGS.LIST'),
            new NavigationLink('', "decorativeFillings", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DECORATIVE_FILLINGS.LIST'),
            new NavigationLink('', "profiles", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.PROFILES.LIST'),
            new NavigationLink('', "configAddons", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_ADDONS.LIST'),
            new NavigationLink('', "glazingBeads", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLAZING_BEADS.LIST'),
            new NavigationLink('', "assemblies", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.LIST'),
            new NavigationLink('', "transports", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.TRANSPORT.LIST'),
            new NavigationLink('', "systemGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.SYSTEM_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "systemDecorativeGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.SYSTEM_DECORATIVE_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "terraceGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.TERRACE_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "windowDesignerCatalogDependentOption", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.LIST'),
        ];
        itemsGroups.push({groupName: "WINDOW-SYSTEM", items: this.prepareItems(catalogItems)});

        let roofItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "glazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "roofSystems", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_SYSTEMS.LIST'),
            new NavigationLink('', "roofWindowsDimensions", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_DIMENSIONS.LIST'),
        ];
        itemsGroups.push({groupName: "ROOF-SYSTEM", items: this.prepareItems(roofItems)});

        let entranceItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "entranceSystems", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_SYSTEMS.LIST'),
            new NavigationLink('', "entranceGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "entranceModels", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_MODELS.LIST'),
            new NavigationLink('', "entranceDimensions", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_DIMENSIONS.LIST'),
            new NavigationLink('', "graspGlazingCategories", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRASP_GLAZING_CATEGORIES.LIST'),
            new NavigationLink('', "graspDistanceFrameCategory", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRASP_DISTANCE_FRAME_CATEGORIES.LIST'),
            new NavigationLink('', "graspGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRASP_GLAZING_PACKAGES.LIST'),
        ];
        itemsGroups.push({groupName: "ENTRANCE-SYSTEM", items: this.prepareItems(entranceItems)});

        let webshopItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "webshopGlazingPackages", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGES.LIST'),
            new NavigationLink('', "webshopCharges", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_CHARGES.LIST'),
            new NavigationLink('', "webshopAreaBars", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_AREA_BARS.LIST'),
        ];
        if (this.webshopEnabledForSubsystem) {
            webshopItems.push(new NavigationLink('', "webshopBusinessTypes", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_BUSINESS_TYPE.LIST'));
            webshopItems.push(new NavigationLink('', "models", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.MODEL.LIST'));
            webshopItems.push(new NavigationLink('', "windowSystemsWebshop", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_SYSTEM_WEB_SHOP.WINDOW-SYSTEM.LIST'));
            webshopItems.push(new NavigationLink('', "webshopGlazingPackagesPerSubsystem", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.LIST'));
            webshopItems.push(new NavigationLink('', "webshopRecommendedConfiguration", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_RECOMMENDED_CONFIGURATION.LIST'));
            webshopItems.push(new NavigationLink('', "webshopSale", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_SALE.LIST'));
            webshopItems.push(new NavigationLink('', "webshopChargePerSubsystem", "", {roles: ['ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_CHARGES.LIST'));
        }
        itemsGroups.push({groupName: "WEBSHOP", items: this.prepareItems(webshopItems)});

        let gateSystemsItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "gateSystem", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_SYSTEMS.LIST'),
            new NavigationLink('', "railSystem", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RAIL_SYSTEMS.LIST'),
            new NavigationLink('', "gatePanelType", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPES.LIST'),
            new NavigationLink('', "gateWall", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_WALLS.LIST'),
            new NavigationLink('', "gateDesignerCatalogDependentOption", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.LIST'),
            new NavigationLink('', "addonCategoryGate", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORIES.LIST'),
            new NavigationLink('', "addonCategoryGroupGate", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUPS.LIST'),
            new NavigationLink('', "gateAddons", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_ADDONS.LIST'),
        ];
        itemsGroups.push({groupName: "GATE_SYSTEM", items: this.prepareItems(gateSystemsItems)});

        let configItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "configDesignerCatalogDependentOption", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTION.LIST'),
            new NavigationLink('', "configColors", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.COLORS.LIST'),
            new NavigationLink('', "materials", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.MATERIALS.LIST'),
            new NavigationLink('', "configTypeDescriptor", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_TYPE_DESCRIPTORS.LIST'),
            new NavigationLink('', "configSliderElement", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_SLIDER_ELEMENT.LIST'),
            new NavigationLink('', "configSystem", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_SYSTEMS.LIST'),
            new NavigationLink('', "configSidebarAddons", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_ADDON_SIDEBAR.LIST'),
            new NavigationLink('', "addonCategoryConfig", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORIES.LIST'),
            new NavigationLink('', "addonCategoryGroupConfig", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUPS.LIST'),
        ];
        itemsGroups.push({groupName: "CONFIG_ADDON", items: this.prepareItems(configItems)});

        let otherItems: NavigationLink<CatalogTab>[] = [
            new NavigationLink('', "racks", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACKS.LIST'),
            new NavigationLink('', "catalogItemTags", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CATALOG_ITEM_TAG.LIST'),
            new NavigationLink('', "subsystemNotesCategories", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.SUBSYSTEM_NOTES_CATEGORIES.LIST'),
            new NavigationLink('', "discontinuedCatalogItemInfo", "", {roles: ['ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISCONTINUED_CATALOG_ITEM_INFO.LIST'),
        ];
        itemsGroups.push({groupName: "OTHER", items: this.prepareItems(otherItems)});

        this.sideNavItems = [];
        this.sideNavItemsByGroup = itemsGroups.filter(group => group.items && group.items.length > 0);
        this.sideNavItems = [].concat.apply([], this.sideNavItemsByGroup.map(group => group.items));
        if (this.activeSubComponent == undefined) {
            this.activeSubComponent = this.sideNavItems[0].cssClass;
        }
    }

    private prepareItems(navItems: NavigationLink<CatalogTab>[]): NavigationLink<CatalogTab>[] {
        navItems = navItems.filter((item) => {
            return this.isPermitted(item.roles);
        });
        if (navItems.length < 1) {
            return [];
        }

        this.translate.get(navItems.map(item => item.name)).subscribe(translations => {
            navItems.sort((a, b) => {
                const aName = translations[a.name];
                const bName = translations[b.name];
                return aName.localeCompare(bName);
            });
        });
        return navItems;
    }

    initTranslationSubscription() {
        this.sideNavItemsTranslateSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.sideNavItemsByGroup.forEach(group => {
                    group.items.sort((a, b) => {
                        const aName = this.translate.getParsedResult(event.translations, a.name);
                        const bName = this.translate.getParsedResult(event.translations, b.name);
                        return aName.localeCompare(bName);
                    });
                });
            }
        );
    }

    switchToSubComponent(item: CatalogTab): void {
        if (this.sideNavItems.some(i => i.cssClass === item)) {
            this.activeSubComponent = item;
        }
    }

    navigateToSubComponent(item: CatalogTab): void {
        this.router.navigate(['.', {tabName: item}], {relativeTo: this.route});
    }

    isActiveSubComponent(cssClass: CatalogTab): boolean {
        return this.activeSubComponent === cssClass;
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }
}
