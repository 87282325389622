import {Pipe, PipeTransform} from '@angular/core';
import * as _ from "underscore";

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(number: number): number[] {
      return _.range(number);
  }

}
