<p-dialog [resizable]="false" [visible]="true" [modal]="true" (onHide)="close()"
          [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div>
            <p><b>{{'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.MESSAGE' | translate}}</b></p>
            <ng-container *ngIf="dialogData.subsystemCurrencyData?.manualExchangeRate != null">
                <p>{{'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.MANUAL_EXCHANGE_RATE_WARNING' | translate: {manualExchangeRate: dialogData.subsystemCurrencyData.manualExchangeRate, subsystemCurrency: dialogData.subsystemCurrencyData.defaultCurrency} }}</p>
            </ng-container>
        </div>
        <p></p>
        <div *ngIf="dialogData.offerCurrency != currencies.PLN && dialogData.exchangeRateWarningStatus != null">
            <p *ngIf="dialogData.exchangeRateWarningStatus == 'HIGH'" [ngStyle]="{color: '#4DD964'}">
                {{'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.RATE_TOO_HIGH' | translate: {diff: dialogData.exchangeRatePercentDiff} }}
            </p>
            <p *ngIf="dialogData.exchangeRateWarningStatus == 'LOW'" [ngStyle]="{color: '#ED1A3B'}">
                {{'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.RATE_TOO_LOW' | translate: {diff: dialogData.exchangeRatePercentDiff} }}
            </p>

            <span>{{'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.EXCHANGE_RATE_SAFE_CHANGE_THRESHOLD' | translate: {threshold: dialogData.subsystemCurrencyData.exchangeRateSafeChangeThreshold} }}</span>
            <div [ngStyle]="{'padding-top.px': '5', 'margin-left.px': '-6'}">
                <app-simple-button label="{{ 'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.NAVIGATE_TO_SUBSYSTEM_SETTINGS' | translate }}" type="filter" [size]="40"
                                    (onClick)="navigateToSubsystemSettings.emit()"></app-simple-button>
            </div>
        </div>

    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
