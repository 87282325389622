import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {SafeUrl} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {addonQuantityTypeIntegerOnly} from '../../../../../../../../window-designer/catalog-data/addon-interface';
import {PositionListAddon} from "../../../../../../../../window-designer/catalog-data/position-list-addon";
import {Permissions} from "../../../../../../../auth/permission.service";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../../../../common/crud-common/common-input-length-limits';
import {ExchangeService} from "../../../../../../../common/exchange.service";
import {Currencies} from '../../../../../../../currencies';
import {OnceFlag} from '../../../../../../../shared/once-flag';
import {Offer} from '../../../../../offer';
import {PositionService} from "../../../position.service";
import {Position} from '../../position';
import {EditBulkAddonDialogData} from "../../position-list-dialogs";
import {BulkAddonData} from "../bulk-addon-data";
import {BulkAddonPositionValidator} from "../bulk-addon-position.validator";

@Component({
    selector: 'edit-bulk-addon-position',
    templateUrl: './edit-bulk-addon-position.component.html',
    styleUrls: ['../../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBulkAddonPositionComponent {

    readonly INPUT_NUMBER_DEFAULT_MAX_VALUE = 999999;
    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @Input() offer: Offer;
    @Input() addon: PositionListAddon;
    @Input() dialogData: EditBulkAddonDialogData;
    @Input() imagePreview: SafeUrl | string;
    @Input() canEditOffer: boolean;
    @Input() subsystemDefaultCurrency: Currencies;

    @Output() onHide: EventEmitter<any> = new EventEmitter();
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();
    @Output() onError: EventEmitter<any> = new EventEmitter();

    validationErrors = {};

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private permissions: Permissions,
                public positionService: PositionService,
                public translate: TranslateService) {
    }

    get readOnlyMode(): boolean {
        if (!this.canEditOffer) {
            return true;
        }
        const validationDisabled = this.dialogData.offerPosition['validationDisabled'];
        return validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun();
    }

    hideDialog(): void {
        this.validationErrors = {};
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    submit(): void {
        if (this.readOnlyMode) {
            return;
        }
        this.validationErrors = {};
        this.addon.quantity = this.addon.defaultQuantity;
        if (new BulkAddonPositionValidator().validate([this.addon], true, this.validationErrors)) {
            let quantity: number;
            quantity = +this.addon.defaultQuantity.toString().replace(",", ".") || 1;

            let newData = new BulkAddonData(this.addon.id, this.addon.category, this.addon.pcn,
                this.addon.supplier.id, this.addon.price.value, this.addon.selectedInsideColor,
                this.addon.selectedOutsideColor, this.addon.selectedCoreColor, this.addon.quantityType);
            this.dialogData.offerPosition.data = JSON.stringify(newData);
            this.dialogData.offerPosition.quantity = quantity;
            this.positionService.saveItem(this.dialogData.offerPosition as Position).subscribe({
                next: () => {
                    this.dialogHideHelper.call(() => this.onSubmit.emit());
                },
                error: (error): void => {
                    this.onError.emit(error);
                }
            });
        }
    }

    formatSupplierName(addon: PositionListAddon): string {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return addon.supplier.companyName;
        }
        return addon.supplier.name[this.translate.currentLang];
    }

    formatPrice(addon: PositionListAddon, quantity: number): string {
        if (addon.price.value == undefined) {
            return 'OFFER.POSITIONS.DIALOGS.EDIT_BULK_ADDON.PRICE_DEPEND_ON_GROUP';
        }
        let price: string;
        if (addon.subsystemId == null) {
            price = ExchangeService.getPriceInDefaultCurrency(addon.price.value * quantity, this.offer.exchangeRate,
                this.offer.subsystemManualExchangeRate);
        } else {
            price = (addon.price.value * quantity).toFixed(2);
        }
        return ExchangeService.formatPriceInCurrency(price, this.subsystemDefaultCurrency);
    }

    isAllowOnlyIntegers(): boolean {
        return addonQuantityTypeIntegerOnly(this.addon.quantityType);
    }
}
