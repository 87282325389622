import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {LineCutData} from "../../../../../window-designer/drawing-data/LineCutData";
import {SubWindowData} from "../../../../../window-designer/drawing-data/SubWindowData";
import {DrawingUtil, Point} from "../../../../../window-designer/drawing-util";
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {FloatOps} from '../../../../../window-designer/utils/float-ops';
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";
import {WindowSystemDefinition} from "../../../window-system/window-system-definition/window-system-definition";

export class TrimTool {

    public static canUse(data: DrawingData, windowSystem: WindowSystemDefinition): boolean {
        for (let window of data.windows) {
            for (let subwindow of window.subWindows) {
                let limitations = windowSystem.subwindowTypesLimitations[subwindow.typeCode];
                if (limitations != undefined) {
                    let framePoints = WindowCalculator.getOuterSubwindowPoints(subwindow, data.cuts);
                    for (let i = 0; i < (framePoints.length / 2); i++) {
                        let triangle = this.prepareTriangle(framePoints, i);
                        if (this.hasPerpendicularSide(triangle) &&
                            !this.validateAngle(triangle, subwindow, windowSystem)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    public static use(data: DrawingData, windowSystem: WindowSystemDefinition): LineCutData[] {
        let neededCuts = [];
        for (let window of data.windows) {
            for (let subwindow of window.subWindows) {
                let limitations = windowSystem.subwindowTypesLimitations[subwindow.typeCode];
                if (limitations != undefined) {
                    let minSideLenght = limitations.minSideLength;
                    let subwindowHeight = subwindow.points[5] - subwindow.points[1];
                    let subwindowWidth = subwindow.points[2] - subwindow.points[0];
                    let framePoints = WindowCalculator.getOuterSubwindowPoints(subwindow, data.cuts);
                    for (let i = 0; i < (framePoints.length / 2); i++) {
                        let triangle = this.prepareTriangle(framePoints, i);
                        let maxDistance = triangle.isHorizontal ? subwindowWidth : subwindowHeight;
                        if (this.hasPerpendicularSide(triangle) &&
                            !this.validateAngle(triangle, subwindow, windowSystem)) {
                            let rightTriangle = this.convertToRightTriangle(triangle);
                            let result = this.calculateThalesTheorem(rightTriangle, minSideLenght);
                            this.validateDistance(result.distance, maxDistance);
                            while (!result.success) {
                                let newPointB = triangle.abSidePerpendicular ? triangle.c : triangle.a;
                                let nextPointIndex = triangle.abSidePerpendicular ? (i * 2) + 4 : (i * 2) - 4;
                                let newPointC = new Point(DrawingUtil.getPoint(framePoints, nextPointIndex),
                                    DrawingUtil.getPoint(framePoints, nextPointIndex + 1));
                                let newPointA = new Point(0, 0);
                                newPointA[triangle.shiftingCoordinate] = newPointC[triangle.shiftingCoordinate];
                                newPointA[triangle.staticCoordinate] = newPointB[triangle.staticCoordinate];
                                let newTriangle = new Triangle(newPointA, newPointB, newPointC);
                                let newResult = this.calculateThalesTheorem(newTriangle,
                                    (minSideLenght - result.totalHeight));
                                this.combineResults(result, newResult);
                                this.validateDistance(result.distance, maxDistance);
                            }
                            neededCuts.push(this.prepareCutLine(triangle, result.distance, framePoints));
                        }
                    }
                }
            }
        }
        return neededCuts;
    }

    private static validateDistance(distance: number, maxDistance: number) {
        if (distance > maxDistance) {
            this.throwTrimError();
        }
    }

    public static throwTrimError() {
        this.throwError(ErrorNames.TRIM_WOULD_REMOVE_SUBWINDOW, "Trim would go over total subwindow size");
    }

    private static throwError(errorName: string, debug: string) {
        let error = new Error("TrimTool: " + debug);
        error.name = errorName;
        throw error;
    }

    private static validateAngle(triangle: Triangle, subwindow: SubWindowData,
                                 windowSystem: WindowSystemDefinition): boolean {
        let minAngle;
        switch (subwindow.typeCode) {
            case SubWindowTypeCode.F:
                minAngle = windowSystem.minAngleF;
                break;
            case SubWindowTypeCode.FF:
            case SubWindowTypeCode.UPL:
            case SubWindowTypeCode.UPP:
            case SubWindowTypeCode.HSL:
            case SubWindowTypeCode.HSP:
            case SubWindowTypeCode.CPL:
            case SubWindowTypeCode.CPP:
            case SubWindowTypeCode.OG:
            case SubWindowTypeCode.OOG:
            case SubWindowTypeCode.OL:
            case SubWindowTypeCode.OP:
            case SubWindowTypeCode.PD:
            case SubWindowTypeCode.PL:
            case SubWindowTypeCode.PP:
            case SubWindowTypeCode.WL:
            case SubWindowTypeCode.WP:
            case SubWindowTypeCode.HL:
            case SubWindowTypeCode.HP:
                minAngle = windowSystem.minAngleFW;
                break;
            case SubWindowTypeCode.RL:
            case SubWindowTypeCode.RP:
            case SubWindowTypeCode.URL:
            case SubWindowTypeCode.URP:
            case SubWindowTypeCode.SHL:
            case SubWindowTypeCode.SHP:
            case SubWindowTypeCode.ROL:
            case SubWindowTypeCode.ROP:
            case SubWindowTypeCode.ROA:
                minAngle = windowSystem.minAngleR;
                break;
            case SubWindowTypeCode.U:
                minAngle = windowSystem.minAngleU;
                break;
            default:
                throw Error("Unsupported subwindow type: " + subwindow.typeCode);
        }
        return Math.floor(Snap.deg(this.getAngle(triangle))) >= minAngle;
    }

    private static getAngle(triangle: Triangle): number {
        let angle1 = DrawingUtil.atan2normalized(triangle.b.y - triangle.a.y, triangle.b.x - triangle.a.x);
        let angle2 = DrawingUtil.atan2normalized(triangle.c.y - triangle.b.y, triangle.c.x - triangle.b.x);
        return Math.abs(Math.PI - DrawingUtil.normalizeAngle(angle2 - angle1));
    }

    private static prepareTriangle(framePoints: number[], angleMiddlePointIndex: number): Triangle {
        let index = angleMiddlePointIndex * 2;
        let pointA = [DrawingUtil.getPoint(framePoints, index - 2), DrawingUtil.getPoint(framePoints, index - 1)];
        let pointB = [DrawingUtil.getPoint(framePoints, index), DrawingUtil.getPoint(framePoints, index + 1)];
        let pointC = [DrawingUtil.getPoint(framePoints, index + 2), DrawingUtil.getPoint(framePoints, index + 3)];
        return new Triangle(new Point(pointA[0], pointA[1]), new Point(pointB[0], pointB[1]),
            new Point(pointC[0], pointC[1]));
    }

    private static hasPerpendicularSide(triangle: Triangle) {
        return triangle.isHorizontal || triangle.isABvertical || triangle.isBCvertical;
    }

    private static calculateThalesTheorem(triangle: Triangle,
                                          minSideLenght: number): ThalesResult {
        let totalHeight = Math.abs(triangle.a[triangle.staticCoordinate] - triangle.c[triangle.staticCoordinate]);
        let totalWidth = triangle.abSidePerpendicular ?
            Math.abs(triangle.a[triangle.shiftingCoordinate] - triangle.b[triangle.shiftingCoordinate]) :
            Math.abs(triangle.c[triangle.shiftingCoordinate] - triangle.b[triangle.shiftingCoordinate]);

        if (minSideLenght > totalHeight) {
            return new ThalesResult(false, totalWidth, totalHeight);
        }
        return new ThalesResult(true, (totalWidth * minSideLenght / totalHeight), totalHeight);
    }

    private static convertToRightTriangle(triangle: Triangle) {
        let newTriangle = JSON.parse(JSON.stringify(triangle));
        if (triangle.abSidePerpendicular) {
            newTriangle.a[triangle.shiftingCoordinate] = newTriangle.c[triangle.shiftingCoordinate];
        } else {
            newTriangle.c[triangle.shiftingCoordinate] = newTriangle.a[triangle.shiftingCoordinate];
        }
        return newTriangle;
    }

    private static prepareCutLine(triangle: Triangle, theoremResult: number, framePoints: number[]) {
        let trimRight = triangle.abSidePerpendicular ?
            triangle.b[triangle.shiftingCoordinate] > triangle.a[triangle.shiftingCoordinate] :
            triangle.b[triangle.shiftingCoordinate] > triangle.c[triangle.shiftingCoordinate];

        let cutPosition = FloatOps.round(trimRight ? triangle.b[triangle.shiftingCoordinate] - theoremResult :
            triangle.b[triangle.shiftingCoordinate] + theoremResult);

        let cutLine = triangle.isHorizontal ? [cutPosition, 0, cutPosition, 1] : [0, cutPosition, 1, cutPosition];

        let intersections = DrawingUtil.polygonLineIntersections(framePoints, cutLine, true);
        if (intersections.length !== 2) {
            this.throwError(ErrorNames.GENERAL_ERROR, "Invalid intersection count: " + intersections.length);
        }
        let minIntersectingPosition = FloatOps.round(triangle.isHorizontal ?
            Math.min(intersections[0].y, intersections[1].y) :
            Math.min(intersections[0].x, intersections[1].x));
        let maxIntersectingPosition = FloatOps.round(triangle.isHorizontal ?
            Math.max(intersections[0].y, intersections[1].y) :
            Math.max(intersections[0].x, intersections[1].x));

        return new LineCutData(triangle.isHorizontal ?
            [cutPosition, maxIntersectingPosition, cutPosition, minIntersectingPosition] :
            [minIntersectingPosition, cutPosition, maxIntersectingPosition, cutPosition],
            trimRight ? "bottom" : "top");
    }

    private static combineResults(result: ThalesResult, newResult: ThalesResult) {
        result.success = newResult.success;
        result.distance += newResult.distance;
        result.totalHeight += newResult.totalHeight;
    }
}

class Triangle {
    a: Point;
    b: Point;
    c: Point;

    isABhorizontal: boolean;
    isBChorizontal: boolean;
    isABvertical: boolean;
    isBCvertical: boolean;
    isHorizontal: boolean;
    shiftingCoordinate: string;
    staticCoordinate: string;
    abSidePerpendicular: boolean;

    constructor(a: Point, b: Point, c: Point) {
        this.a = a;
        this.b = b;
        this.c = c;

        this.isABhorizontal = Triangle.isSidePerpendicular(this.a, this.b, true);
        this.isBChorizontal = Triangle.isSidePerpendicular(this.b, this.c, true);
        this.isABvertical = Triangle.isSidePerpendicular(this.a, this.b, false);
        this.isBCvertical = Triangle.isSidePerpendicular(this.b, this.c, false);
        this.isHorizontal = this.isABhorizontal || this.isBChorizontal;
        this.shiftingCoordinate = this.isHorizontal ? "x" : "y";
        this.staticCoordinate = this.isHorizontal ? "y" : "x";
        this.abSidePerpendicular = this.isHorizontal ? this.isABhorizontal : this.isABvertical;
    }

    private static isSidePerpendicular(point1: Point, point2: Point, horizontal: boolean) {
        if (horizontal) {
            return DrawingUtil.isLineHorizontal([point1.x, point1.y, point2.x, point2.y]);
        } else {
            return DrawingUtil.isLineVertical([point1.x, point1.y, point2.x, point2.y]);
        }
    }
}

class ThalesResult {
    success: boolean;
    distance: number;
    totalHeight: number;

    constructor(success: boolean, distance: number, totalHeight: number) {
        this.success = success;
        this.distance = distance;
        this.totalHeight = totalHeight;
    }
}
