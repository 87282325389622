export class FocusOnElement {
    static tryToFocus(firstInputId: string, tryCount: number, maxTries: number, timeout: number) {
        if (tryCount < maxTries) {
            setTimeout(() => {
                let field = document.getElementById(firstInputId);
                if (field) {
                    field.focus();
                } else {
                    this.tryToFocus(firstInputId, ++tryCount, maxTries, timeout)
                }
            }, timeout);
        }
    }
}
