import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {BlockUiController} from "../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ValidationErrors} from '../../../common/validation-errors';
import {AccessData} from "../../AccessData";
import {Subsystem} from '../../subsystem/subsystem';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {DeliveryList} from "./delivery-list";
import {DeliveryListService} from "./delivery-list.service";

@Component({
    selector: 'app-delivery-list-wizard',
    templateUrl: './delivery-list-wizard.component.html',
    providers: [DeliveryListService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryListWizardComponent {

    private static readonly SAVING_BLOCK_UI_NAME = 'DeliveryListSaveBlock';

    readonly STEPS = {
        DETAILS: {
            id: 'DATA',
            validator: () => this.validateForm()
        },
        SUBSYSTEM: {
            id: 'SUBSYSTEM',
            validator: () => this.validateSubsystem()
        }
    };

    private activatedRoute: ActivatedRoute;
    private blockUiController: BlockUiController;
    private changeDetector: ChangeDetectorRef;
    private subsystemService: SubsystemService;
    private deliveryListService: DeliveryListService;
    private errors: CommonErrorHandler;
    private router: Router;
    public translate: TranslateService;

    originalDeliveryListId: number;

    deliveryList: DeliveryList = new DeliveryList();
    selectedSubsystems: Subsystem[] = [];
    subsystems: SelectItem[] = [];
    validationErrors: ValidationErrors = {};

    constructor(injector: Injector) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.blockUiController = injector.get(BlockUiController);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.subsystemService = injector.get(SubsystemService);
        this.deliveryListService = injector.get(DeliveryListService);
        this.errors = injector.get(CommonErrorHandler);
        this.router = injector.get(Router);
        this.translate = injector.get(TranslateService);

        this.initDeliveryList();
        this.initSubsystems();
    }

    private initDeliveryList(): void {
        this.originalDeliveryListId = this.getRouteParamIfPresent('id');
        if (this.originalDeliveryListId != null) {
            this.getDeliveryList();
        }
    }

    private initSubsystems(): void {
        this.subsystemService.getSelectionItems().subscribe(data => {
            this.subsystems = data;
        });
    }

    private getRouteParamIfPresent(paramName: string): any {
        return this.activatedRoute.snapshot.paramMap.has(paramName) ?
            +this.activatedRoute.snapshot.paramMap.get(paramName) : undefined;
    }

    private getDeliveryList(): void {
        this.deliveryListService.getItem(this.originalDeliveryListId).subscribe(data => {
            this.deliveryList = data;
            this.changeDetector.markForCheck();
        });
    }

    exitWizard(): void {
        if (this.originalDeliveryListId != null) {
            this.goToDeliveryList(this.originalDeliveryListId);
        } else {
            this.router.navigate([AccessData.path.offer(), {component: 'delivery-lists'}]);
        }
    }

    public save(): any {
        this.blockUiController.block(DeliveryListWizardComponent.SAVING_BLOCK_UI_NAME);
        this.deliveryListService.save(this.deliveryList).pipe(
            finalize(() => this.blockUiController.unblock(DeliveryListWizardComponent.SAVING_BLOCK_UI_NAME)))
            .subscribe({
                next: id => {
                    this.goToDeliveryList(id);
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
    }

    goToDeliveryList(id: number): void {
        this.router.navigate([AccessData.path.deliveryList(id.toString())]);
    }

    validateForm(): Observable<boolean> {
        const validationErrors = {};
        if (this.deliveryList.name == undefined) {
            validationErrors['name'] = 'error.deliveryListDto.name.not_null';
        } else if (this.deliveryList.name.length === 0 || this.deliveryList.name.length > 100) {
            validationErrors['name'] = 'error.deliveryListDto.name.not_in_range';
        }
        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.deliveryListService.validateGeneralData(this.deliveryList).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validateSubsystem(): Observable<boolean> {
        const validationErrors = {};
        if (this.deliveryList.subsystemId == undefined) {
            validationErrors['subsystemId'] = 'error.deliveryListDto.subsystemId.not_null';
        }
        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.deliveryListService.validateSubsystemData(this.deliveryList).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }

    getHeader(): string {
        return 'DELIVERY_LIST.FORM.' + (this.originalDeliveryListId == null ? 'NEW_DELIVERY_LIST' : 'EDIT_DELIVERY_LIST');
    }
}
