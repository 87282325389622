import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {AllAddons} from './all-addons';
import {ColorInterface} from './catalog-data/color-interface';
import {CustomTranslationsInterface} from './catalog-data/custom-translations-interface';
import {DecorativeFillingListingInterface} from './catalog-data/decorative-filling-interface';
import {DistanceFrameInterface} from "./catalog-data/distance-frame-interface";
import {GlassInterface} from './catalog-data/glass-interface';
import {GlazingBeadInterface} from './catalog-data/glazing-bead-interface';
import {GrillInterface} from './catalog-data/grill-interface';
import {ProfileListingInterface} from './catalog-data/profile-interface';
import {SubwindowTypeInterface} from './catalog-data/subwindow-type-interface';
import {WindowSystemInterface} from './catalog-data/window-system-interface';
import {BulkWindowDesignerDataForSystem, WindowDesignerDataServiceInterface} from './window-designer-data.service-interface';

export class CachingWindowDesignerDataService implements WindowDesignerDataServiceInterface {
    readonly windowSystems = new Map<number, Observable<WindowSystemInterface>>();
    readonly grills = new Map<number, Observable<GrillInterface[]>>();
    readonly glasses = new Map<number, Observable<GlassInterface[]>>();
    readonly frames = new Map<number, Observable<DistanceFrameInterface[]>>();
    readonly profiles = new Map<number, Observable<ProfileListingInterface>>();
    readonly decorativeFillings = new Map<number, Observable<DecorativeFillingListingInterface>>();
    subwindowTypes: Observable<SubwindowTypeInterface[]>;
    customTranslations: Observable<CustomTranslationsInterface>;
    readonly bulkData = new Map<number, Observable<BulkWindowDesignerDataForSystem>>();
    readonly addons = new Map<string, Observable<AllAddons>>();
    readonly colors = new Map<number, Observable<ColorInterface>>();
    readonly glazingBeads = new Map<number, Observable<GlazingBeadInterface[]>>();

    constructor(private windowDesignerDataService: WindowDesignerDataServiceInterface) {
    }

    getWindowSystem(windowSystemId: number): Observable<WindowSystemInterface> {
        let windowSystemObservable = this.windowSystems.get(windowSystemId);
        if (windowSystemObservable == undefined) {
            windowSystemObservable = this.windowDesignerDataService.getWindowSystem(windowSystemId)
                .pipe(shareReplay(1));
            this.windowSystems.set(windowSystemId, windowSystemObservable);
        }
        return windowSystemObservable;
    }

    getGrills(windowSystemId: number): Observable<GrillInterface[]> {
        let grillsObservable = this.grills.get(windowSystemId);
        if (grillsObservable == undefined) {
            grillsObservable = this.windowDesignerDataService.getGrills(windowSystemId)
                .pipe(shareReplay(1));
            this.grills.set(windowSystemId, grillsObservable);
        }
        return grillsObservable;
    }

    getGlazingBeads(windowSystemId: number): Observable<GlazingBeadInterface[]> {
        let observable = this.glazingBeads.get(windowSystemId);
        if (observable == undefined) {
            observable = this.windowDesignerDataService.getGlazingBeads(windowSystemId)
                .pipe(shareReplay(1));
            this.glazingBeads.set(windowSystemId, observable);
        }
        return observable;
    }

    getGlasses(windowSystemId: number): Observable<GlassInterface[]> {
        let glassesObservable = this.glasses.get(windowSystemId);
        if (glassesObservable == undefined) {
            glassesObservable = this.windowDesignerDataService.getGlasses(windowSystemId)
                .pipe(shareReplay(1));
            this.glasses.set(windowSystemId, glassesObservable);
        }
        return glassesObservable;
    }

    getFrames(windowSystemId: number): Observable<DistanceFrameInterface[]> {
        let framesObservable = this.frames.get(windowSystemId);
        if (framesObservable == undefined) {
            framesObservable = this.windowDesignerDataService.getFrames(windowSystemId)
                .pipe(shareReplay(1));
            this.frames.set(windowSystemId, framesObservable);
        }
        return framesObservable;
    }

    getProfiles(windowSystemId: number): Observable<ProfileListingInterface> {
        let profilesObservable = this.profiles.get(windowSystemId);
        if (profilesObservable == undefined) {
            profilesObservable = this.windowDesignerDataService.getProfiles(windowSystemId)
                .pipe(shareReplay(1));
            this.profiles.set(windowSystemId, profilesObservable);
        }
        return profilesObservable;
    }

    getDecorativeFillings(windowSystemId: number): Observable<DecorativeFillingListingInterface> {
        let decorativeFillingsObservable = this.decorativeFillings.get(windowSystemId);
        if (decorativeFillingsObservable == undefined) {
            decorativeFillingsObservable = this.windowDesignerDataService.getDecorativeFillings(windowSystemId)
                .pipe(shareReplay(1));
            this.decorativeFillings.set(windowSystemId, decorativeFillingsObservable);
        }
        return decorativeFillingsObservable;
    }

    getSubwindowTypes(): Observable<SubwindowTypeInterface[]> {
        if (this.subwindowTypes == undefined) {
            this.subwindowTypes = this.windowDesignerDataService.getSubwindowTypes()
                .pipe(shareReplay(1));
        }
        return this.subwindowTypes;
    }

    getCustomTranslations(): Observable<CustomTranslationsInterface> {
        if (this.customTranslations == undefined) {
            this.customTranslations = this.windowDesignerDataService.getCustomTranslations()
                .pipe(shareReplay(1));
        }
        return this.customTranslations;
    }

    getDataBulk(windowSystemId: number): Observable<BulkWindowDesignerDataForSystem> {
        let bulkDataObservable = this.bulkData.get(windowSystemId);
        if (bulkDataObservable == undefined) {
            bulkDataObservable = this.windowDesignerDataService.getDataBulk(windowSystemId)
                .pipe(shareReplay(1));
            this.bulkData.set(windowSystemId, bulkDataObservable);
        }
        return bulkDataObservable;
    }

    getAddonsByIds(addonsIds: number[]): Observable<AllAddons> {
        let key = addonsIds.sort().toString();
        let addonsObservable = this.addons.get(key);
        if (addonsObservable == undefined) {
            addonsObservable = this.windowDesignerDataService.getAddonsByIds(addonsIds)
                .pipe(shareReplay(1));
            this.addons.set(key, addonsObservable);
        }
        return addonsObservable;
    }

    getColorById(colorId: number): Observable<ColorInterface> {
        let colorObservable = this.colors.get(colorId);
        if (colorObservable == undefined) {
            colorObservable = this.windowDesignerDataService.getColorById(colorId)
                .pipe(shareReplay(1));
            this.colors.set(colorId, colorObservable);
        }
        return colorObservable;
    }
}
