import {WindowSystemTypeEnum} from "../../../../../../window-designer/catalog-data/window-system-interface";
import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {ConfigurableAddonDefinitionType} from "../../../../../../window-designer/enums/ConfigurableAddonDefinitionType";
import {MaterialType} from '../../../../../common/enums/MaterialType';
import {Currencies} from '../../../../../currencies';
import {MultilanguageField} from "../../../../../supportedLanguages";
import {AbstractPosition, PositionType} from '../../../AbstractPosition';
import {ConfigData} from "../../../config-editor/config-data";
import {GateData} from '../../../gate-editor/gate-data';
import {PriceWithTax} from "../../../offer";
import {EntranceDoorData} from '../../../window-editor/roof-window-editor/entrance-door-data';
import {RoofWindowData} from "../../../window-editor/roof-window-editor/RoofWindowData";
import {MessageSeverity} from "../../message";

export class Position implements AbstractPosition {
    id: number;
    assignedId: number;
    name: MultilanguageField;
    type: PositionType;
    configurableAddonDefinitionType: ConfigurableAddonDefinitionType;
    dimensions: string;
    quantity: number;
    sortGroup: PositionSortGroup;
    data: string;
    profitMargin: number;
    rabate: number;
    pricingCurrency: Currencies;
    buyPrice: PriceWithTax;
    venskaBuyPrice: PriceWithTax;
    venskaBuyValue: PriceWithTax;
    sellPrice: PriceWithTax;
    retailSellPrice: PriceWithTax;
    netIncome: number;
    grossIncome: number;
    netVenskaIncome: number;
    grossVenskaIncome: number;
    netRetailIncome: number;
    grossRetailIncome: number;
    buyValue: PriceWithTax;
    sellValue: PriceWithTax;
    retailSellValue: PriceWithTax;
    offerId: number;
    highestMessageSeverity: MessageSeverity;
    otherInfo: string;
    supplierId: number;
    printOrder: number;
    parentPrintOrder: number;
    dealerDiscount: string;
    calculatedDealerDiscount: number;
    tempDealerDiscount: string;
    calculatedTempDealerDiscount: number;
    basePrice: string;
    pricingOutdated: boolean;
    configurableAddons: Position[] = [];
    parentOfferPositionId: number;
    realDimensions: string;
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    windowSystemType: WindowSystemTypeEnum;
    windowSystemMaterial: MaterialType;
    gateSystemId: number;
    configSystemId: number;
    hasComment: boolean;
    hasCommentUnreadByVenska: boolean;
    hasCommentUnreadBySubsystem: boolean;
    validationDisabled: boolean;

    constructor(name: MultilanguageField, type: PositionType, configurableAddonDefinitionType: ConfigurableAddonDefinitionType,
                dimensions: string, quantity: number, profitMargin: number, rabate: number,
                venskaBuyPrice: PriceWithTax, venskaBuyValue: PriceWithTax, buyPrice: PriceWithTax, sellPrice: PriceWithTax, retailSellPrice: PriceWithTax,
                buyValue: PriceWithTax, sellValue: PriceWithTax, retailSellValue: PriceWithTax, data: string, offerId: number, otherInfo: string) {
        this.name = name;
        this.type = type;
        this.configurableAddonDefinitionType = configurableAddonDefinitionType;
        this.dimensions = dimensions;
        this.quantity = quantity;
        this.sortGroup = PositionSortGroup.SYSTEM;
        this.data = data;
        this.profitMargin = profitMargin;
        this.rabate = rabate;
        this.buyPrice = buyPrice;
        this.venskaBuyPrice = venskaBuyPrice;
        this.venskaBuyValue = venskaBuyValue;
        this.sellPrice = sellPrice;
        this.retailSellPrice = retailSellPrice;
        this.buyValue = buyValue;
        this.sellValue = sellValue;
        this.retailSellValue = retailSellValue;
        this.offerId = offerId;
        this.otherInfo = otherInfo;
        this.validationDisabled = false;
    }
}

export class PositionFactory {
    public static window(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.SYSTEM);
    }

    public static standaloneGlazingPackage(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.STANDALONE_GLAZING_PACKAGE);
    }

    public static roofWindow(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.ROOF_SYSTEM);
    }

    public static entranceDoor(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.ENTRANCE_DOOR_SYSTEM);
    }

    public static gate(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.GATE_SYSTEM);
    }

    public static config(offerId: number): Position {
        return PositionFactory.new(offerId, PositionType.CONFIG_SYSTEM);
    }

    private static new(offerId: number, type: PositionType) {
        let p = new PriceWithTax(0, 23);
        let data: string;
        switch (type) {
            case PositionType.SYSTEM:
            case PositionType.STANDALONE_GLAZING_PACKAGE:
                data = JSON.stringify(new DrawingData());
                break;
            case PositionType.ROOF_SYSTEM:
                data = JSON.stringify(new RoofWindowData());
                break;
            case PositionType.ENTRANCE_DOOR_SYSTEM:
                data = JSON.stringify(new EntranceDoorData());
                break;
            case PositionType.GATE_SYSTEM:
                data = JSON.stringify(new GateData());
                break;
            case PositionType.CONFIG_SYSTEM:
                data = JSON.stringify(new ConfigData());
                break;
            default:
                data = null;
                break;
        }
        return new Position(null, type, null, "", 1, 0, 0, p, p, p, p, p, p, p, p, data, offerId, null);
    }
}

export enum PositionSortGroup {
    SYSTEM = 'SYSTEM',
    OWN_ADDON = 'OWN_ADDON',
    ASSEMBLY = 'ASSEMBLY',
    OWN_TRANSPORT = 'OWN_TRANSPORT'
}
