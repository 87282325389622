import {CutData} from "./CutData";
import {CutDataType} from "./CutDataType";

export class ArcCutData extends CutData {
    cx: number;
    cy: number;
    rx: number;
    ry: number;
    angle1: number;
    angle2: number;

    constructor(cx: number, cy: number, rx: number, ry: number, angle1: number, angle2: number) {
        super(CutDataType.ARC);
        this.cx = cx;
        this.cy = cy;
        this.rx = rx;
        this.ry = ry;
        this.angle1 = angle1;
        this.angle2 = angle2;
    }
}
