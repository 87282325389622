import {ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Observable, of, Subscription} from "rxjs";
import * as _ from 'underscore';
import {ComponentWithUserConfigAndPaginator} from "../crud-common/paginable.component";
import {DataTableColumn} from "./data.table.column";
import {DataTableColumnBuilder, SavedShownColumns} from "./data.table.column.builder";
import {CSVColumn, CSVExportable, ExportComponent} from "./export.component";
import {TranslatedSelectItem} from "./translated.select.item";

@Directive()
export abstract class CrudComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, CSVExportable {

    columns: DataTableColumn[] = [];
    allColumns: DataTableColumn[] = [];
    selectedColumns: string[] = [];
    columnOptions: TranslatedSelectItem[] = [];
    columnByField: { [field: string]: DataTableColumn } = {};

    langTranslateSubscription: Subscription[] = [];

    public translate: TranslateService;
    protected exportComponent: ExportComponent;

    protected constructor(injector: Injector,
                          changeDetector: ChangeDetectorRef,
                          viewName: string,
                          copySupported: boolean) {
        super(injector, changeDetector, viewName, copySupported);
        this.translate = injector.get(TranslateService);
        this.exportComponent = injector.get(ExportComponent);
    }

    ngOnDestroy(): void {
        for (let subscription of this.langTranslateSubscription) {
            subscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    public init(columns: DataTableColumn[]): void {
        this.columns = [];
        this.allColumns = columns;
        this.columnOptions = [];
        this.columnByField = {};
        columns.forEach(column => {
            this.columnOptions.push({label: null, labelKey: column.header, value: column.field});
            if (column.defaultVisible) {
                this.columns.push(column);
                this.selectedColumns.push(column.field);
                this.columnByField[column.field] = column;
            }

            if (column.sortOrder) {
                this.defaultSortOrder = column.sortOrder;
                this.defaultSortColumn = column.field;
            }
        });
        this.changeDetector.markForCheck();
    }

    abstract getExportData(): Observable<object[]>;

    getExportColumns(): Observable<CSVColumn[]> {
        return of(this.columnOptions.map(columnOption => {
            return {
                label: columnOption.label,
                field: columnOption.value
            };
        }));
    }

    public exportCSV(filename: string): void {
        this.exportComponent.exportCSV(this, filename);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        this.removeFiltersFromHiddenColumns(event.filters);
        super.loadItemsLazy(event);
    }

    public saveShownColumns(): SavedShownColumns {
        this.updateColumnsFilterFlag();
        let newColumns = {};
        for (let i = 0; i < this.columns.length; i++) {
            newColumns[this.columns[i].field] = {
                sortOrder: this.columns[i].sortOrder
            };
        }
        this.saveUserUiConfigForThisView(ComponentWithUserConfigAndPaginator.SHOWN_COLUMNS_PROPERTY_KEY, newColumns);

        return newColumns;
    }

    private updateColumnsFilterFlag(): void {
        this.columns.forEach(column => {
            if (column.filterable) {
                column.filter = this.showFilters;
            }
        });
    }

    public updateShownColumnsSortOrder(event: LazyLoadEvent): void {
        if (this.isDefaultSortOrderChanged(event)) {
            this.defaultSortColumn = event.sortField;
            this.defaultSortOrder = event.sortOrder;

            this.columns.forEach(column => {
                if (column.field === this.defaultSortColumn) {
                    column.sortOrder = this.defaultSortOrder;
                } else {
                    column.sortOrder = DataTableColumnBuilder.ORDER_NONE;
                }
            });

            this.saveShownColumns();
        }
    }

    getColumns(): DataTableColumn[] {
        return this.columns;
    }

    getTranslatedLabelForAttrValue(item, attributeName: string) {
        const attributes = this.findColumnDefinition(attributeName).filterValues;
        // ie. access object property user.role.roleName given string "role.roleName"
        const itemAttrValue = attributeName.split('.').reduce((a, b) => a[b], item);
        return attributes.find(attr => attr.value === itemAttrValue).label;
    }

    protected updateVisibleColumns(visibleColumns: SavedShownColumns): void {
        this.columns = [];
        this.selectedColumns = [];
        this.columnByField = {};
        for (let columnOption of this.columnOptions) {
            if (!visibleColumns[columnOption.value]) {
                continue;
            }
            const column = this.findColumnDefinition(columnOption.value);
            this.columns.push(column);
            this.selectedColumns.push(column.field);
            this.columnByField[column.field] = column;
        }
        this.changeDetector.markForCheck();
    }

    private sortColumns(): void {
        this.columns.sort((a, b) => a.index - b.index);
    }

    private findColumnDefinition(field: string): DataTableColumn {
        return this.allColumns.find(column => column.field === field);
    }

    public onDisplayedColumnsChange(newColumns: string[]): SavedShownColumns {
        this.columns = newColumns.map(column => this.findColumnDefinition(column));
        this.sortColumns();
        this.selectedColumns = newColumns;
        const oldColumns = Object.keys(this.columnByField);
        this.columnByField = _.object(this.columns.map(c => c.field), this.columns);
        const removedColumns = _.without(oldColumns, ...Object.keys(this.columnByField));
        this.onDisplayedColumnsRemoved(removedColumns);
        return this.saveShownColumns();
    }

    onDisplayedColumnsRemoved(removedColumns: string[]): void {
        const dataTable = this.getDatatable();
        for (let removedColumn of removedColumns) {
            if (dataTable.filters[removedColumn] != undefined) {
                dataTable.filter(undefined, removedColumn, undefined);
            }
        }
    }

    removeFiltersFromHiddenColumns(filters?: { [s: string]: FilterMetadata; }): void {
        const dataTable = this.getDatatable();
        Object.keys(filters).forEach(key => {
            if (this.columnByField[key] == undefined) {
                const isColumnFilter = this.columnOptions.some(column => column.value === key);
                if (isColumnFilter) {
                    filters[key] = undefined;
                    dataTable.setFilterValue(undefined, key, undefined);
                }
            }
        });
    }
}
