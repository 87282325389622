<div class="new-form-60vw">
    <div class="new-form-row" *ngIf="fieldUsage.show(GateDependentOptionField.SYSTEM)">
        <app-select inputId="gateSystemSelect"
                    label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.GATE_SYSTEM' | translate }}" autofocus
                    [(validationMessageKey)]="validationErrors['gateSystemId']" [options]="gateSystems | async"
                    [disabled]="fieldUsage.disabled(GateDependentOptionField.SYSTEM)"
                    [required]="true"
                    [optionFormatter]="formatGateSystemOption" [ngModel]="item.gateSystemId"
                    (ngModelChange)="handleGateSystemChange($event)"></app-select>
    </div>

    <div *ngIf="fieldUsage.show(GateDependentOptionField.NAME)" class="new-form-row">
        <app-input-text inputId="name" label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.NAME' | translate }}"
                        [disabled]="fieldUsage.disabled(GateDependentOptionField.NAME)"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="255"
                        [(ngModel)]="item.name"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(GateDependentOptionField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="name"
                      label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GateDependentOptionField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GateDependentOptionField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GateDependentOptionField.SORT_INDEX)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                          [max]="999999" [step]="1" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
