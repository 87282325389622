import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-help-dialog',
    templateUrl: './detailed-pricing-help-dialog.component.html',
    styleUrls: ['./detailed-pricing-help-dialog.component.css', '../detailed-pricing.component.css']
})
export class DetailedPricingHelpDialogComponent {

    @Output() onHide = new EventEmitter();

    hide() {
        this.onHide.emit();
    }

}
