import {MultilanguageFieldInterface} from './catalog-data/multilanguage-field-interface';
import {SubwindowTypeInterface} from './catalog-data/subwindow-type-interface';
import {SubWindowTypeCode} from "./window-types/subwindow-type-code";

export class SubwindowTypes {

    private map = new Map<SubWindowTypeCode, SubwindowTypeInterface>();

    constructor(types: SubwindowTypeInterface[]) {
        types.forEach(type => {
            this.map.set(type.type, type);
        });
    }

    public get(typeCode: SubWindowTypeCode): SubwindowTypeInterface {
        let subwindowType = this.map.get(typeCode);
        if (subwindowType == undefined) {
            throw new Error("SubwindowType not found by typeCode: " + SubWindowTypeCode[typeCode]);
        }
        return subwindowType;
    }

    public getFromMultilangName(multilang: MultilanguageFieldInterface): SubwindowTypeInterface {
        let name = this.getFirstNotNullFieldValue(multilang);
        let code = SubWindowTypeCode[name];
        if (code == undefined) {
            throw new Error("SubwindowType not found from MultilanguageFieldInterface: " + multilang);
        }
        return this.get(code);
    }

    public getFunctions(typeCode: SubWindowTypeCode): number {
        let subwindowType = this.get(typeCode);
        if (subwindowType.functionsQuantity == undefined) {
            console.warn("SubwindowType " + SubWindowTypeCode[typeCode] + " has no functionsQuantity set. Using 0 instead");
            return 0;
        }
        return subwindowType.functionsQuantity;
    }

    private getFirstNotNullFieldValue(multilang: MultilanguageFieldInterface): string {
        for (let key of Object.keys(multilang)) {
            if (multilang[key] != null) {
                return multilang[key];
            }
        }
        return null;
    }
}
