import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {RackField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {RackFieldUsage} from "../../catalog-field-usage";
import {Rack} from '../rack';

@Component({
    selector: 'app-rack-form',
    templateUrl: './rack-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RackFormComponent {

    @Input() item: Rack;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[] = [];

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: RackFieldUsage;
    RackField = RackField;

    constructor(public translateService: TranslateService) {
        this.fieldUsage = new RackFieldUsage(this);
    }
}
