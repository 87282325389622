import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CatalogConfiguration} from './catalog-configuration';
import {CatalogPropertyTarget} from './CatalogPropertyTarget';

@Injectable()
export class CatalogConfigurationService {

    constructor(private http: HttpClient) {
    }

    getForTarget(target: CatalogPropertyTarget, showHidden = false): Observable<CatalogConfiguration> {
        return this.http.get<CatalogConfiguration>(`catalogConfiguration/${target}`, {params: {showHidden: '' + showHidden}});
    }
}
