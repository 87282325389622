<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(WindowSystemBasicField.NAME)"
                            inputId="names" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(WindowSystemBasicField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="windowSystem.names"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(WindowSystemBasicField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(WindowSystemBasicField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="windowSystem.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(WindowSystemBasicField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(WindowSystemBasicField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50" [required]="true"
                        [(ngModel)]="windowSystem.symbol"></app-input-text>
    </div>
    <ng-container>
        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.PCN)">
            <div class="new-form-field">
                <label for="pcn">{{ 'WINDOW-SYSTEM-DEFINITION.FORM.PCN' | translate }}</label>
                <div class="new-form-field-input-container">
                    <p-inputMask [ngModel]="windowSystem.pcn" id="pcn" inputId="pcn" ngDefaultControl
                                 [disabled]="fieldUsage.disabled(WindowSystemBasicField.PCN)"
                                 (ngModelChange)="windowSystem.pcn = ($event || undefined); validationErrors['pcn'] = undefined"
                                 mask="PCN 99 99 99 99" placeholder="PCN 99 99 99 99"></p-inputMask>
                    <span *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-icon material-icons">error</span>
                    <div *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-message">
                        {{ validationErrors['pcn'] | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.SUPPLIER)">
            <app-select inputId="supplier" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SUPPLIER' | translate }}"
                        [disabled]="fieldUsage.disabled(WindowSystemBasicField.SUPPLIER)"
                        [(validationMessageKey)]="validationErrors['supplier']" [options]="suppliers" [required]="true"
                        [optionKey]="getSupplierKey" [(ngModel)]="windowSystem.supplier"></app-select>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.MATERIAL)">
            <app-select inputId="material" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MATERIAL' | translate }}"
                        [disabled]="fieldUsage.disabled(WindowSystemBasicField.MATERIAL)"
                        [(validationMessageKey)]="validationErrors['material']" [options]="materialTypes | async"
                        [required]="true" [(ngModel)]="windowSystem.material"></app-select>
        </div>
        <ng-container *ngIf="!roofSystem && !entranceSystem">
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.SYSTEM_TYPE)">
                <app-select inputId="systemType" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_TYPE' | translate }}"
                            [(validationMessageKey)]="validationErrors['systemType']"
                            [disabled]="fieldUsage.disabled(WindowSystemField.SYSTEM_TYPE)"
                            [options]="windowSystemTypes | async" [required]="true"
                            [optionKey]="getWindowSystemTypeKey" [ngModel]="selectedWindowSystemType"
                            (ngModelChange)="handleSystemTypeChange($event)"></app-select>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.COMBINE_BUSINESS_TYPES)">
                <app-checkbox inputId="canCombineBusinessTypes"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.COMBINE_BUSINESS_TYPES' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.COMBINE_BUSINESS_TYPES)"
                              [(ngModel)]="windowSystem.canCombineBusinessTypes"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.VENTILATION_ALWAYS_ON_FRAME)">
                <app-checkbox inputId="ventilationAlwaysOnFrame"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.VENTILATION_ALWAYS_ON_FRAME' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.VENTILATION_ALWAYS_ON_FRAME)"
                              [(ngModel)]="windowSystem.ventilationAlwaysOnFrame"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.SPLIT_CUT_BUSINESS_TYPES)">
                <app-checkbox inputId="splitCutBusinessTypes"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SPLIT_CUT_BUSINESS_TYPES' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.SPLIT_CUT_BUSINESS_TYPES)"
                              [(validationMessageKey)]="validationErrors['splitCutBusinessTypes']"
                              [(ngModel)]="windowSystem.splitCutBusinessTypes"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.ALLOW_ONLY_EQUAL_SUBWINDOWS)">
                <app-checkbox inputId="allowOnlyEqualSubwindows"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ALLOW_ONLY_EQUAL_SUBWINDOWS' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.ALLOW_ONLY_EQUAL_SUBWINDOWS)"
                              [(validationMessageKey)]="validationErrors['allowOnlyEqualSubwindows']"
                              [(ngModel)]="windowSystem.allowOnlyEqualSubwindows"></app-checkbox>
            </div>


            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.ALLOW_MULLION_CROSSING)">
                <app-checkbox inputId="allowMullionCrossing" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ALLOW_MULLION_CROSSING' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.ALLOW_MULLION_CROSSING)"
                              [(ngModel)]="windowSystem.allowMullionCrossing"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.ALLOW_FEST_AT_HINGES)">
                <app-checkbox inputId="allowFestAtHinges" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ALLOW_FEST_AT_HINGES' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemField.ALLOW_FEST_AT_HINGES)"
                              [(ngModel)]="windowSystem.allowFestAtHinges"></app-checkbox>
            </div>
            <div *ngIf="fieldUsage.show(WindowSystemField.CAN_HAVE_STANDALONE_GLAZING_PACKAGES)" class="new-form-row">
                <app-checkbox [(ngModel)]="windowSystem.canHaveStandaloneGlazingPackages" [disabled]="fieldUsage.disabled(WindowSystemField.CAN_HAVE_STANDALONE_GLAZING_PACKAGES)"
                              inputId="canHaveStandaloneGlazingPackages"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.CAN_HAVE_STANDALONE_GLAZING_PACKAGES' | translate }}"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.AUTO_ALIGN)">
                <app-radio-button label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.AUTO_ALIGN.LABEL' | translate }}"
                                  inputId="autoAlign" [options]="autoAlignOptions | async" [(ngModel)]="windowSystem.autoAlign"
                                  [disabled]="fieldUsage.disabled(WindowSystemField.AUTO_ALIGN)"
                                  [(validationMessageKey)]="validationErrors['autoAlign']"></app-radio-button>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemField.PRICETABLE_SELECTION_MODE)">
                <app-select inputId="pricetableSelectionMode"
                            label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.PRICETABLE_SELECTION_MODE.LABEL' | translate }}"
                            [(validationMessageKey)]="validationErrors['pricetableSelectionMode']"
                            [disabled]="fieldUsage.disabled(WindowSystemField.PRICETABLE_SELECTION_MODE)"
                            [options]="pricetableSelectionModeOptions | async" [required]="true"
                            [(ngModel)]="windowSystem.pricetableSelectionMode"></app-select>
            </div>
        </ng-container>

        <div *ngIf="fieldUsage.show(WindowSystemBasicField.POSSIBLE_OPENING)" class="new-form-row">
            <p-accordion>
                <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.POSSIBLE_OPENING' | translate }}" [selected]="false">
                    <p-listbox [options]="availableOpenings | async" [(ngModel)]="windowSystem.possibleOpenings" [multiple]="true"
                               [disabled]="fieldUsage.disabled(WindowSystemBasicField.POSSIBLE_OPENING)"
                               id="systemPossibleOpenings" (ngModelChange)="onSelectedOpeningsChange()"></p-listbox>
                </p-accordionTab>
                <div *ngIf="validationErrors['possibleOpenings'] != undefined" class="new-form-field-label-error-spacer"></div>
                <div *ngIf="validationErrors['possibleOpenings'] != undefined" class="new-form-field-error-message">{{
                    validationErrors['possibleOpenings'] | translate }}
                </div>
            </p-accordion>
        </div>

        <div *ngIf="fieldUsage.show(RoofSystemField.ROOF_SYSTEM_FUNCTION)" class="new-form-row">
            <app-select inputId="roofSystemFunction" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_FUNCTION' | translate }}"
                        [disabled]="fieldUsage.disabled(RoofSystemField.ROOF_SYSTEM_FUNCTION)"
                        [(validationMessageKey)]="validationErrors['roofSystemFunction']"
                        [options]="roofSystemFunctions | async" [required]="true"
                        [(ngModel)]="windowSystem.roofSystemFunction"></app-select>
        </div>

        <div *ngIf="fieldUsage.show(RoofSystemField.ROOF_SYSTEM_GLAZING_PACKAGE)" class="new-form-row">
            <app-select inputId="roofSystemGlazingPackage" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_GLAZING_PACKAGE' | translate }}"
                        [disabled]="fieldUsage.disabled(RoofSystemField.ROOF_SYSTEM_GLAZING_PACKAGE)"
                        [(validationMessageKey)]="validationErrors['roofGlazingPackageId']" [required]="true"
                        [options]="roofGlazingPackages" [allowSelectingNone]="true"
                        [(ngModel)]="windowSystem.roofGlazingPackageId"></app-select>
        </div>

        <div *ngIf="fieldUsage.show(RoofSystemField.ROOF_SYSTEM_UW)" class="new-form-row">
            <app-input-number inputId="uw" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_UW' | translate }}"
                              [disabled]="fieldUsage.disabled(RoofSystemField.ROOF_SYSTEM_UW)"
                              [(validationMessageKey)]="validationErrors['uw']" [required]="true"
                              [(ngModel)]="windowSystem.uw" [min]="0"
                              [max]="99"></app-input-number>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.DIMENSION_STEP)">
            <app-input-number inputId="dimensionStep" label="{{ 'GATE_SYSTEM.FORM.DIMENSION_STEP' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemBasicField.DIMENSION_STEP)"
                              [(validationMessageKey)]="validationErrors['dimensionStep']" [required]="true"
                              [(ngModel)]="windowSystem.dimensionStep" [min]="1"></app-input-number>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.UNIT_WEIGHT)">
            <app-input-number inputId="unitWeight" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.UNIT_WEIGHT' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemBasicField.UNIT_WEIGHT)"
                              [(validationMessageKey)]="validationErrors['unitWeight']" [required]="true"
                              [(ngModel)]="windowSystem.unitWeight" [min]="0" [max]="9999"></app-input-number>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(WindowSystemBasicField.TAGS)">
            <div class="new-form-field">
                <div class="new-form-field-label-container">
                    <div>
                        <label for="tags">{{ 'WINDOW-SYSTEM-DEFINITION.FORM.TAGS' | translate }}</label>
                    </div>
                </div>
                <div class="new-form-field-input-container window-system-tag-container">
                    <p-multiSelect inputId="tags" [options]="tags" appendTo="body" [ngModel]="selectedTags"
                                   [disabled]="fieldUsage.disabled(WindowSystemBasicField.TAGS)"
                                   (ngModelChange)="handleTagsChange($event)">
                        <ng-template pTemplate="item" let-tag>
                            <label>{{ tag.value.tagText[translate.currentLang] }}</label>
                        </ng-template>
                        <ng-template pTemplate="selectedItems" let-tags>
                            <div *ngFor="let tag of tags; let last = last" class="selected-tag-item">
                                {{ tag.tagText[translate.currentLang] + (last ? '' : ',') }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>
            </div>
        </div>

        <div *ngIf="fieldUsage.show(WindowSystemBasicField.ACTIVE)" class="new-form-row">
            <app-checkbox inputId="active" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ACTIVE' | translate }}"
                          [disabled]="fieldUsage.disabled(WindowSystemBasicField.ACTIVE)"
                          [(ngModel)]="windowSystem.active"></app-checkbox>
        </div>

        <div *ngIf="!roofSystem && !entranceSystem && fieldUsage.show(WindowSystemBasicField.SORT_INDEX)" class="new-form-row">
            <app-input-number inputId="sortIndex" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemBasicField.SORT_INDEX)"
                              [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']"
                              [(ngModel)]="windowSystem.sortIndex" [min]="1" [max]="999" [step]="1"></app-input-number>
        </div>

        <div *ngIf="roofSystem && fieldUsage.show(WindowSystemBasicField.SORT_INDEX)" class="new-form-row">
            <app-input-number inputId="roofSystemSortIndex" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemBasicField.SORT_INDEX)"
                              [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['roofSystemSortIndex']"
                              [(ngModel)]="windowSystem.roofSystemSortIndex" [min]="1" [max]="999" [step]="1"></app-input-number>
        </div>

        <div *ngIf="entranceSystem && fieldUsage.show(WindowSystemBasicField.SORT_INDEX)" class="new-form-row">
            <app-input-number inputId="entranceSystemSortIndex" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}"
                              [disabled]="fieldUsage.disabled(WindowSystemBasicField.SORT_INDEX)"
                              [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['entranceSystemSortIndex']"
                              [(ngModel)]="windowSystem.entranceSystemSortIndex" [min]="1" [max]="999" [step]="1"></app-input-number>
        </div>

        <p-accordion>
            <p-accordionTab *ngIf="fieldUsage.show(RoofSystemField.WINDOW_EDITOR_IMAGE)"
                            header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.WINDOW_EDITOR_IMAGE' | translate }}"
                            [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <div class="new-form-row">
                    <app-file-upload inputId="image" [ngModel]="windowEditorImage" (ngModelChange)="windowEditorImageChanged($event)"
                                     [disabled]="fieldUsage.disabled(RoofSystemField.WINDOW_EDITOR_IMAGE)"
                                     [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                     [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.SELECT_FILE_UPLOAD'"
                                     [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.CHANGE_FILE_UPLOAD'"
                                     [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.DELETE_FILE_UPLOAD'"
                                     [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
                </div>
            </p-accordionTab>

            <p-accordionTab *ngIf="fieldUsage.show(RoofSystemField.PRINTOUT_IMAGE)" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.PRINTOUT_IMAGE' | translate }}"
                            [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['printoutImage']}">
                <div class="new-form-row">
                    <app-file-upload inputId="printoutImage" [ngModel]="printoutImage" (ngModelChange)="printoutImageChanged($event)"
                                     [disabled]="fieldUsage.disabled(RoofSystemField.PRINTOUT_IMAGE)"
                                     [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                     [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.SELECT_FILE_UPLOAD'"
                                     [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.CHANGE_FILE_UPLOAD'"
                                     [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.DELETE_FILE_UPLOAD'"
                                     [(validationMessageKey)]="validationErrors['printoutImage']"></app-file-upload>
                </div>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
</div>
