import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../../../common/crud-common/common-input-length-limits';
import {DataServiceHelper} from '../../../../../../common/dataServiceHelper';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {TemplateType} from '../../../../../settings/templates-settings/template';
import {TemplatesService} from '../../../../../settings/templates-settings/templates.service';
import {WindowEditorPositionData} from '../../../../window-editor/window-editor-offer-interfaces';
import {PositionService} from "../../position.service";

export interface OtherInfoDialogCloseEvent {
    position: WindowEditorPositionData;
    success: boolean;
}

@Component({
    selector: 'app-show-description',
    templateUrl: './show-description.component.html',
    styleUrls: ['../../../../../shared-styles.css', './show-description.component.css'],
    providers: [TemplatesService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowDescriptionComponent implements OnInit {

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @Input() visible = false;
    @Input() position: WindowEditorPositionData;
    @Input() persistOnSave = true;
    @Input() readOnlyMode: boolean;

    @Output() onClose = new EventEmitter<OtherInfoDialogCloseEvent>();

    otherInfo: string;
    ready = false;

    otherInfoTemplates: SelectItem[] = [];
    selectedTemplate: string;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public positionService: PositionService,
                public templateService: TemplatesService,
                public translate: TranslateService,
                public changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngOnInit(): void {
        this.otherInfo = this.position.otherInfo || "";
        if (this.readOnlyMode) {
            this.ready = true;
        } else {
            let filters = {};
            filters["templateType"] = {
                value: TemplateType[TemplateType.OTHER_INFO]
            };
            this.templateService.getItems(0, 1000, filters, null, null).subscribe({
                next: data => {
                    this.otherInfoTemplates = data.data.map(template => ({
                        label: this.trimContent(template.content[this.translate.currentLang]),
                        value: template.content[this.translate.currentLang]
                    }));
                    if (this.otherInfoTemplates.length > 0) {
                        this.selectedTemplate = this.otherInfoTemplates[0].value;
                    }
                    this.ready = true;
                    this.changeDetector.markForCheck();
                },
                error: (error) => this.errors.handle(error)
            });
        }
    }

    submitDialog(): void {
        if (this.readOnlyMode) {
            return;
        }
        if (this.persistOnSave) {
            this.positionService.setOtherInfo(this.position.id, this.otherInfo).subscribe({
                next: () => {
                    this.position.otherInfo = this.otherInfo;
                    this.onClose.emit({position: this.position, success: true});
                },
                error: (error) => {
                    this.errors.handle(error);
                    this.onClose.emit({position: this.position, success: false});
                }
            });
        } else {
            this.position.otherInfo = this.otherInfo;
            this.onClose.emit({position: this.position, success: true});
        }
    }

    resetDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit({position: this.position, success: false}));
    }

    addTemplateToDescription(): void {
        if (this.selectedTemplate != null) {
            if (this.otherInfo) {
                this.otherInfo = (this.otherInfo + " " + this.selectedTemplate).substring(0, this.lengthLimit.OTHER_INFO_MAX_LENGTH);
            } else {
                this.otherInfo = this.selectedTemplate;
            }
        }
    }

    trimContent(data: string): string {
        if (data && data.length > 50) {
            return data.substring(0, 48) + "...";
        }
        return data;
    }
}
