<div class="dialog-window">
    <p-dialog [visible]="statusTransitionDialogVisible" [resizable]="false" [closable]="true" [closeOnEscape]="true"
              (onHide)="hide()" [modal]="true" [focusOnShow]="false">

        <p-header>
            <div class="p-dialog-title">
                {{ "OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP" | translate }}
            </div>
        </p-header>

        <div class="scrolled-dialog scrolled-dialog-400">
            <div *ngFor="let transition of possibleTransitions" class="change-status-button-container">
                <div [ngClass]="{ 'selected':selectedTransition === transition }" id="{{ 'transitionTo_' + transition.item.id }}"
                        class="change-status-button" (click)="selectedTransition = transition">

                    <div class="change-status-button-label">
                        <span class="material-icons">{{transition.item.icon}}</span>
                        <span class="material-icons change-status-action-icon" (click)="showTransitionInfoDialog(transition, $event)">info</span>
                        <span>{{transition.item.label | translate}}</span>
                    </div>
                </div>
            </div>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirmTransitionChange"
                                   label="{{ 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP' | translate }}"
                                   type="main-action" [size]="40" [disabled]="!selectedTransition"
                                   (onClick)="changeStatus()"></app-simple-button>
                <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>

<app-info-dialog *ngIf="statusTransitionInfoDialogVisible"
                 header="{{ 'OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.HEADER' | translate }}"
                 text="{{ getStatusTransitionInfoLabel() }}"
                 (onHide)="hideStatusTransitionDialog()"></app-info-dialog>
