import {PlatformLocation} from "@angular/common";
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, from, Observable, of, OperatorFunction, Subscription} from "rxjs";
import {concatAll, finalize, map, mergeMap, takeLast, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {SubwindowTypes} from "../../../../window-designer/subwindow-types";
import {WindowTypeCodeParser} from "../../../../window-designer/utils/WindowTypeCodeParser";
import {SubWindowTypeCode} from "../../../../window-designer/window-types/subwindow-type-code";
import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper, FileState} from "../../../common/dataServiceHelper";
import {MaterialType} from "../../../common/enums/MaterialType";
import {GlazingPackagesValidationErrors} from '../../../common/glazing-packages/glazing-packages-form.component';
import {MaterialTypeProvider} from '../../../common/MaterialTypeProvider';
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {MultilanguageFieldSelectItem} from "../../../common/service/select-item-multilanguage-field-translate.pipe";
import {SelectItemImpl} from '../../../common/service/select.item.impl';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SupportedLanguages} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {RoofSystemField, WindowSystemTab} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {CatalogConfiguration} from "../../catalog-creator/catalog-configuration";
import {CatalogConfigurationService} from "../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../catalog-creator/CatalogPropertyTarget";
import {ErrorResponse} from '../../errors/errorResponse';
import {SupplierService} from "../../supplier/supplier.service";
import {BusinessTypeService} from "../business-type/business-type.service";
import {BusinessType} from "../business-type/BusinessType";
import {WindowSystemFieldUsage} from "../catalog-field-usage";
import {CatalogItemTagsService} from '../catalog-item-tags/catalog-item-tags.service';
import {DistanceFrameService} from "../distance-frame/distance-frame.service";
import {DistanceFrame} from "../distance-frame/distanceFrame";
import {GlassService} from "../glass/glass.service";
import {GlassWithPosition} from "../glass/glassWithPositions";
import {GlazingPackageTarget} from '../glazing-package/glazing-package';
import {GlazingPackageService} from '../glazing-package/glazing-package.service';
import {RoofGlazingPackageService} from "../roof-glazing-package/roof-glazing-package.service";
import {SubwindowTypeService} from "../subwindow-type/subwindow-type.service";
import {RoofSystemFunction, WindowSystemDefinition, WindowSystemDefinitionList} from "./window-system-definition";
import {
    WindowSystemDefinitionDimensionPriceFormComponent
} from './window-system-definition-dimension-price-form/window-system-definition-dimension-price-form.component';
import {WindowSystemDefinitionService} from "./window-system-definition.service";
import {WindowSystemDefinitionFormValidator} from "./window-system-definition.validator";
import {WindowSystemWebShopInfo} from './window-system-web-shop-info';
import {WindowSystemWebShopInfoService} from './window-system-web-shop-info.service';

@Component({
    selector: 'app-window-system-definition',
    templateUrl: './window-system-definition.component.html',
    styleUrls: ['./window-system-definition.component.css', '../../shared-styles.css'],
    providers: [WindowSystemDefinitionService, WindowSystemWebShopInfoService, DataServiceHelper, SupplierService,
        GlassService, DistanceFrameService, BusinessTypeService, TranslatedSelectItemService, SubwindowTypeService,
        RoofGlazingPackageService, GlazingPackageService, CatalogConfigurationService, CatalogItemTagsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    private static readonly SUBMIT_BLOCK_ID = 'WindowSystemDefinitionComponent submit';

    @Input()
    roofSystem = false;

    @Input()
    entranceSystem = false;

    @ViewChild(WindowSystemDefinitionDimensionPriceFormComponent)
    dimensionPriceComponent: WindowSystemDefinitionDimensionPriceFormComponent;

    readonly STEPS = {
        DATA: 'DATA',
        ANGLES: 'ANGLES',
        ASSEMBLY: 'ASSEMBLY',
        SHAPES: 'SHAPES',
        DRAWING_TOOLS: 'DRAWING_TOOLS',
        WEB_SHOP: 'WEB_SHOP',
        DESCRIPTION: 'DESCRIPTION',
        DESIGNER_DESCRIPTION: 'DESIGNER_DESCRIPTION',
        SPECIFICATION: 'SPECIFICATION',
        DIMENSION_PRICE: 'DIMENSION_PRICE',
        GLAMOUR_PRINT_INFO: 'GLAMOUR_PRINT_INFO'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateGeneralData(),
        ANGLES: () => this.validateAnglesData(),
        ASSEMBLY: () => this.validateAssemblyData(),
        SHAPES: () => this.validateShapesData(),
        WEB_SHOP: () => this.validateWebShopData(),
        GLAMOUR_PRINT_INFO: () => this.validateGlamourPrintData()
    };

    windowSystemDefinitions: WindowSystemDefinition[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    filterMaterial: Observable<SelectItem[]>;
    filterType: Observable<SelectItem[]>;

    newSystem = false;

    lastLoadEvent: LazyLoadEvent;
    displayDialog = false;
    userLang: string;
    langTranslateSubscription: Subscription;
    existingSuppliers: SelectItem[] = [];
    roofGlazingPackages: SelectItem[];
    glazingPackages: SelectItem[];
    decorativeGlazingPackages: SelectItem[];
    tags: SelectItem[];
    selectedSystem: WindowSystemDefinition;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    incomingSupplierId: number;
    @ViewChild('dt') datatable: DataTable;

    glasses: GlassWithPosition[];
    frames: DistanceFrame[];
    availableSubwindowTypes: SubWindowTypeCode[] = [];
    subwindowTypes: SubwindowTypes;

    glazingValidationErrors = new GlazingPackagesValidationErrors();

    windowSystem: WindowSystemDefinition;
    windowSystemWebShopInfo: WindowSystemWebShopInfo;
    windowSystemWebShopInfoValidationErrors: ValidationErrors = {};

    webshopImageFile: File;
    webshopLargeImageFile: File;
    webshopLargeImageMobileFile: File;
    glamourPrintIconFile: Partial<Record<keyof MultilanguageFieldInterface, FileState>>;
    webshopSliderImageFiles = new Map<string, FileState>();
    tempWebshopSliderIdGenerator = 0;
    windowEditorImage: File;
    printoutImage: File;
    glamourPrintImageFile: FileState = {file: undefined, needSave: false};

    CatalogPropertyTarget = CatalogPropertyTarget;
    catalogConfigurations: CatalogConfiguration[] = [];
    catalogConfigurationService: CatalogConfigurationService;
    glazingPackageTargets = GlazingPackageTarget;
    activeWindowSystemsForStandaloneGlazingPackages: MultilanguageFieldSelectItem[];

    editPermits: FieldLimitation[] = [];
    fieldUsage: WindowSystemFieldUsage;
    WindowSystemTab = WindowSystemTab;
    RoofSystemField = RoofSystemField;

    constructor(private permissions: Permissions,
                private windowSystemDefinitionService: WindowSystemDefinitionService,
                private windowSystemWebShopInfoService: WindowSystemWebShopInfoService,
                private router: Router,
                private route: ActivatedRoute,
                private location: PlatformLocation,
                private glassService: GlassService,
                private frameService: DistanceFrameService,
                private businessTypeService: BusinessTypeService,
                private translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private supplierService: SupplierService,
                private roofGlazingPackageService: RoofGlazingPackageService,
                private glazingPackageService: GlazingPackageService,
                private subwindowTypeService: SubwindowTypeService,
                private catalogItemTagsService: CatalogItemTagsService,
                private errors: CommonErrorHandler,
                private editCatalogPermitsService: EditCatalogPermitsService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WindowSystemDefinitionComponent', true);
        this.userLang = translate.currentLang;
        this.catalogConfigurationService = injector.get(CatalogConfigurationService);
        this.langTranslateSubscription = translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
            this.changeDetector.markForCheck();
        });
        this.fieldUsage = new WindowSystemFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initDefaultSortOrder();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterMaterial = this.translatedSelectItemService.buildUnsortedDropdown(
            this.roofSystem ? MaterialTypeProvider.getRoofSystemsMaterials() : MaterialType, 'MATERIAL.',
            'WINDOW-SYSTEM-DEFINITION.MATERIAL.ALL');
        this.filterType = this.translatedSelectItemService.buildSortedDropdown(WindowSystemType.getWindowAndTerraceSystemsTypes().map(type => type.type),
            'WINDOW-SYSTEM-DEFINITION.SYSTEM_TYPE.', '');
        this.route.params.forEach((params: Params) => {
            this.incomingSupplierId = params['supplierId'];
        });
        let filters = {active: {value: 'true'}};
        forkJoin({
            suppliers: this.supplierService.getSupplierNames(),
            roofGlazingPackages: this.roofGlazingPackageService.getItems(null, null, filters, null, null),
            glazingPackages: this.glazingPackageService.getItems(null, null,
                {...filters, target: {value: GlazingPackageTarget.SYSTEM_DEFINITION},
                 decorative: {value: false}}, null, null),
            decorativeGlazingPackages: this.glazingPackageService.getItems(null, null,
                {...filters, target: {value: GlazingPackageTarget.DECORATIVE_GLAZING},
                 decorative: {value: true}}, null, null),
            subwindowTypes: this.subwindowTypeService.getAll(),
            tags: this.catalogItemTagsService.getAllActive()
        }).subscribe({
            next: data => {
                this.existingSuppliers =
                    data.suppliers.map(supplier => new SelectItemImpl(supplier.companyName, supplier));
                this.roofGlazingPackages =
                    data.roofGlazingPackages.data.map(gp => new SelectItemImpl(gp.name[this.translate.currentLang], gp.id));
                this.glazingPackages =
                    data.glazingPackages.data.map(gp => new SelectItemImpl(gp.names[this.translate.currentLang], gp.id));
                this.decorativeGlazingPackages =
                    data.decorativeGlazingPackages.data.map(gp => new SelectItemImpl(gp.names[this.translate.currentLang], gp.id));
                this.subwindowTypes = new SubwindowTypes(data.subwindowTypes);
                this.tags = data.tags.map(tag => ({label: tag.tagText[this.translate.currentLang], value: tag}));
            },
            error: error => this.errors.handle(error)
        });
        this.getEditPermits();
    }

    ngOnDestroy() {
        this.langTranslateSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    removeFiltersNotSetOnTableColumns() {
        this.incomingSupplierId = undefined;
        this.location.replaceState(null, null, 'features/window-system');
        if (this.lastLoadEvent) {
            delete this.lastLoadEvent.filters['incomingSupplierId'];
        }
        this.reloadDatatable();
    }

    private filterSupplierId(event: LazyLoadEvent) {
        if (this.incomingSupplierId) {
            event.filters['incomingSupplierId'] = {value: this.incomingSupplierId.toString(), matchMode: undefined};
        }
    }

    private forceRawSortIndexSorting(event: LazyLoadEvent) {
        event.filters['rawSortIndexSorting'] = {value: 'true'};
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.filterSupplierId(event);
        this.forceRawSortIndexSorting(event);
        this.lastLoadEvent = event;

        let windowSystemsObservable: Observable<WindowSystemDefinitionList>;
        if (this.roofSystem) {
            windowSystemsObservable = this.windowSystemDefinitionService
                .getRoofSystems(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
        } else if (this.entranceSystem) {
            windowSystemsObservable = this.windowSystemDefinitionService
                .getEntranceSystems(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
        } else {
            windowSystemsObservable = this.windowSystemDefinitionService
                .getSystems(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
        }

        return windowSystemsObservable.pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WindowSystemDefinitionComponent `getPage` success:', data);
                    this.windowSystemDefinitions = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedSystem = this.restoreSelectionAfterLoad(this.selectedSystem, this.windowSystemDefinitions, event);
                },
                error: error => {
                    console.error('WindowSystemDefinitionComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('WindowSystemDefinitionComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    submit(): void {
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<any>;
            if (this.copyMode) {
                this.blockUiController.block('WindowSystemDefinitionCopy');
                if (this.roofSystem) {
                    observable = this.windowSystemDefinitionService
                        .copyRoofSystem(this.selectedSystem.id, this.windowSystem, this.windowEditorImage,
                            this.printoutImage, this.glamourPrintImageFile)
                        .pipe(
                            mergeMap(systemId => {
                                return this.dimensionPriceComponent.saveTable(systemId).pipe(
                                    finalize(() => this.blockUiController.unblock('WindowSystemDefinitionCopy')),
                                    tap(() => this.showCopyWarning()),
                                    map(() => systemId),
                                    this.saveWebshopSliderImages());
                            }));
                } else if (this.entranceSystem) {
                    observable = this.windowSystemDefinitionService
                        .copyEntranceSystem(this.selectedSystem.id, this.windowSystem)
                        .pipe(
                            finalize(() => this.blockUiController.unblock('WindowSystemDefinitionCopy')),
                            tap(() => this.showCopyWarning()));
                } else {
                    observable = this.windowSystemDefinitionService
                        .copy(this.selectedSystem.id, this.windowSystem, this.windowSystemWebShopInfo,
                            this.webshopImageFile, this.webshopLargeImageFile, this.webshopLargeImageMobileFile,
                            this.glamourPrintImageFile, this.windowEditorImage)
                        .pipe(
                            this.saveGlamourPrintIcons(this.copyMode),
                            mergeMap(systemId => {
                                return this.windowSystemWebShopInfoService.setWebShopInfoImage(systemId, this.selectedSystem.id,
                                    this.webshopImageFile, this.webshopLargeImageFile, this.webshopLargeImageMobileFile)
                                    .pipe(
                                        finalize(() => this.blockUiController.unblock('WindowSystemDefinitionCopy')),
                                        tap(() => this.showCopyWarning()));
                            }));
                }
            } else {
                if (this.roofSystem) {
                    observable = this.windowSystemDefinitionService
                        .saveRoofSystem(this.windowSystem, this.windowEditorImage, this.printoutImage, this.glamourPrintImageFile)
                        .pipe(mergeMap(systemId => {
                            return this.dimensionPriceComponent.saveTable(systemId).pipe(
                                tap(() => this.showSuccessMessage()));
                        }));
                } else if (this.entranceSystem) {
                    observable = this.windowSystemDefinitionService
                        .saveEntranceSystem(this.windowSystem)
                        .pipe(tap(() => this.showSuccessMessage()));
                } else {
                    observable = this.windowSystemDefinitionService.saveSystem(this.windowSystem, this.windowSystemWebShopInfo,
                        this.webshopImageFile, this.webshopLargeImageFile, this.webshopLargeImageMobileFile, this.glamourPrintImageFile, this.windowEditorImage)
                        .pipe(
                            this.saveGlamourPrintIcons(this.copyMode),
                            this.saveWebshopSliderImages(),
                            tap(() => this.showSuccessMessage())
                        );
                }
            }

            this.blockUiController.block(WindowSystemDefinitionComponent.SUBMIT_BLOCK_ID);
            observable.pipe(finalize(
                () => {
                    this.setSaveInProgress(false);
                    this.blockUiController.unblock(WindowSystemDefinitionComponent.SUBMIT_BLOCK_ID);
                })
            ).subscribe({
                next: affectedWindowSystemId => {
                    this.selectedSystem = this.createNewWindowSystemDefinition();
                    this.selectedSystem.id = affectedWindowSystemId;
                    this.hideSystemDetails();
                },
                error: (error: HttpErrorResponse) => {
                    const errorResponse = new ErrorResponse(error.error);
                    if (errorResponse.is400()) {
                        const validationErrors = {};
                        const glazingValidationErrors = {};
                        for (let property in errorResponse.invalidFields) {
                            if (property !== 'glazingWidths' && property.indexOf('glazing') > -1) {
                                glazingValidationErrors[property] = errorResponse.invalidFields[property];
                            } else if (property === 'webshopImage' || property === 'largeWebshopImage' || property === 'largeWebshopImageMobile') {
                                this.windowSystemWebShopInfoValidationErrors[property] = errorResponse.invalidFields[property];
                            } else {
                                validationErrors[property] = errorResponse.invalidFields[property];
                            }
                        }
                        this.validationErrors = validationErrors;
                        this.changeDetector.markForCheck();
                    } else {
                        this.errors.handle(error);
                    }
                }
            });
        }
    }

    private saveGlamourPrintIcons(copyMode: boolean): OperatorFunction<number, number> {
        return mergeMap((affectedWindowSystemId: number) => {
            const saveObservables: Observable<void>[] = [];
            for (let glamourPrintIcon of Object.entries(this.glamourPrintIconFile)) {
                if (glamourPrintIcon[1].needSave || (copyMode && glamourPrintIcon[1].file)) {
                    saveObservables.push(this.windowSystemDefinitionService.saveGlamourPrintIconAsFile(
                        affectedWindowSystemId, glamourPrintIcon[0] as keyof MultilanguageFieldInterface, glamourPrintIcon[1].file));
                }
            }
            return from([...saveObservables, of(affectedWindowSystemId)]).pipe(
                concatAll(),
                takeLast<number>(1)
            );
        });
    }

    private saveWebshopSliderImages(): OperatorFunction<number, number> {
        return mergeMap((affectedWindowSystemId: number) => {
            const removeObservables: Observable<void>[] = [];
            const addObservables: Observable<void>[] = [];
            for (let sliderImage of this.webshopSliderImageFiles.entries()) {
                if (sliderImage[1].needSave) {
                    if (!sliderImage[1].isNew) {
                        removeObservables.push(
                            this.windowSystemWebShopInfoService.removeSliderImageFromWindowSystemWebShopInfo(
                                affectedWindowSystemId, +sliderImage[0]));
                    }
                    if (sliderImage[1].file != undefined) {
                        addObservables.push(this.windowSystemWebShopInfoService.addSliderImageForWindowSystem(
                            affectedWindowSystemId, sliderImage[1].file));
                    }
                }
            }
            return from([...removeObservables, ...addObservables, of(affectedWindowSystemId)]).pipe(
                concatAll(),
                takeLast<number>(1)
            );
        });
    }

    private validateGeneralData(): Observable<boolean> {
        const validationErrors = new WindowSystemDefinitionFormValidator().validateGeneralData(this.windowSystem, this.userLang);
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }

        if (this.roofSystem) {
            return this.windowSystemDefinitionService
                .validateRoofSystem(this.windowSystem, this.windowEditorImage, this.printoutImage, this.glamourPrintImageFile)
                .pipe(
                    tap(backendValidationErrors => {
                        this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                        this.changeDetector.markForCheck();
                    }),
                    map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
        }
        if (this.entranceSystem) {
            return this.windowSystemDefinitionService
                .validateEntranceSystem(this.windowSystem)
                .pipe(
                    tap(backendValidationErrors => {
                        this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                        this.changeDetector.markForCheck();
                    }),
                    map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
        }

        return this.windowSystemDefinitionService.validateGeneralData(this.windowSystem).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private validateAnglesData(): Observable<boolean> {
        const validationErrors = new WindowSystemDefinitionFormValidator().validateAngles(this.windowSystem);
        this.validationErrors['glazingPackageId'] = undefined;
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.windowSystemDefinitionService.validateAnglesData(this.windowSystem).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private validateAssemblyData(): Observable<boolean> {
        let validationErrors = {};
        new WindowSystemDefinitionFormValidator().validateAssembly(this.windowSystem, validationErrors);
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.windowSystemDefinitionService.validateAssemblyData(this.windowSystem).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private validateShapesData(): Observable<boolean> {
        const validationErrors = new WindowSystemDefinitionFormValidator().validateShapesData(this.windowSystem);
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.windowSystemDefinitionService.validateShapesData(this.windowSystem).pipe(
            tap(backendValidationErrors => {
                this.removeFieldNamesFromMapErrors(backendValidationErrors);
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private validateWebShopData(): Observable<boolean> {
        if (ValidationErrorsHelper.validationErrorsPresent(this.windowSystemWebShopInfoValidationErrors)) {
            return of(false);
        }
        return this.windowSystemWebShopInfoService.validateWebShopInfo(this.windowSystemWebShopInfo).pipe(
            tap(backendValidationErrors => {
                this.windowSystemWebShopInfoValidationErrors =
                    Object.assign({}, this.windowSystemWebShopInfoValidationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private validateGlamourPrintData(): Observable<boolean> {
        const validationErrors = new WindowSystemDefinitionFormValidator().validateGlamourPrintData(this.windowSystem);
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.windowSystemDefinitionService.validateGlamourPrintData(this.windowSystem).pipe(
            tap(backendValidationErrors => {
                this.removeFieldNamesFromMapErrors(backendValidationErrors);
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private removeFieldNamesFromMapErrors(backendValidationErrors: { [field: string]: string }): void {
        for (let key of Object.keys(backendValidationErrors)) {
            backendValidationErrors[key] = backendValidationErrors[key].replace(/subwindowTypesLimitations\[.+\]\./g, "");
        }
    }

    validationErrorsPresent(): boolean {
        return super.validationErrorsPresent() || this.glazingValidationErrors.validationErrorsPresent();
    }

    private hideSystemDetails() {
        this.reloadDatatable();
        this.setDisplayDialog(false);
        this.newSystem = false;
        this.copyMode = false;
    }

    private showSuccessMessage() {
        if (this.newSystem || this.copyMode) {
            this.growlMessageController.info('WINDOW-SYSTEM-DEFINITION.SYSTEM_CREATED');
        } else {
            this.growlMessageController.info('WINDOW-SYSTEM-DEFINITION.SYSTEM_UPDATED');
        }
    }

    private showCopyWarning() {
        this.growlMessageController.warning('WINDOW-SYSTEM-DEFINITION.SYSTEM_COPIED', undefined, true);
    }

    showDialogToCopy() {
        if (this.selectedSystem) {
            this.prepareDataForSystem(this.selectedSystem.id);
        }
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.newSystem = false;
        this.prepareDataForSystem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    cancel() {
        this.validationErrors = {};
        this.newSystem = false;
        this.copyMode = false;
        this.resetFile();
        this.resetWindowEditorImage();
        this.resetPrintoutImage();
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedSystem);
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.prepareDataForSystem(undefined);
    }

    private prepareDataForSystem(systemDefinitionId: number): void {
        if (this.roofSystem) {
            this.prepareDataForRoofSystem(systemDefinitionId);
        } else if (this.entranceSystem) {
            this.prepareDataForEntranceSystem(systemDefinitionId);
        } else {
            this.prepareDataForWindowSystem(systemDefinitionId);
        }
    }

    private prepareDataForRoofSystem(windowSystemDefinitionId: number) {
        forkJoin({
            windowSystem: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getRoofSystem(windowSystemDefinitionId)
                : of(this.createNewWindowSystemDefinition()),
            windowEditorImage: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getWindowEditorImageAsFile(windowSystemDefinitionId)
                : of<File>(null),
            printoutImage: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getPrintoutImageAsFile(windowSystemDefinitionId)
                : of<File>(null),
            glamourPrintImageFile: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getGlamourPrintImageAsFile(windowSystemDefinitionId)
                : of<File>(undefined),
            catalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.ROOF_SYSTEMS)
        }).subscribe({
            next: data => {
                this.windowSystem = WindowSystemDefinition.copy(data.windowSystem);
                if (this.copyMode) {
                    this.windowSystem.id = undefined;
                }
                this.newSystem = windowSystemDefinitionId == undefined;
                this.windowEditorImage = data.windowEditorImage;
                this.printoutImage = data.printoutImage;
                this.glamourPrintImageFile = {file: data.glamourPrintImageFile, needSave: false};
                this.catalogConfigurations[CatalogPropertyTarget.ROOF_SYSTEMS] = data.catalogConfiguration;
                this.setDisplayDialog(true);
            },
            error: error => this.errors.handle(error)
        });
    }

    private prepareDataForEntranceSystem(windowSystemDefinitionId: number) {
        forkJoin({
            entranceSystem: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getEntranceSystem(windowSystemDefinitionId)
                : of(this.createNewWindowSystemDefinition()),
            catalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.ENTRANCE_SYSTEMS)
        }).subscribe({
            next: data => {
                this.windowSystem = WindowSystemDefinition.copy(data.entranceSystem);
                if (this.copyMode) {
                    this.windowSystem.id = undefined;
                }
                this.newSystem = windowSystemDefinitionId == undefined;
                this.catalogConfigurations[CatalogPropertyTarget.ENTRANCE_SYSTEMS] = data.catalogConfiguration;
                this.setDisplayDialog(true);
            },
            error: error => this.errors.handle(error)
        });
    }

    private prepareDataForWindowSystem(windowSystemDefinitionId: number) {
        forkJoin({
            windowSystem: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getSystem(windowSystemDefinitionId)
                : of(this.createNewWindowSystemDefinition()),
            glasses: windowSystemDefinitionId != undefined
                ? this.glassService.getGlassesForWindowSystem(windowSystemDefinitionId)
                : of<GlassWithPosition[]>([]),
            distanceFrames: windowSystemDefinitionId != undefined
                ? this.frameService.getDistanceFramesForWindowSystem(windowSystemDefinitionId)
                : of<DistanceFrame[]>([]),
            businessTypes: windowSystemDefinitionId != undefined
                ? this.businessTypeService.getBusinessTypesByWindowSystem(windowSystemDefinitionId)
                : of<BusinessType[]>([]),
            webshopInfo: windowSystemDefinitionId != undefined
                ? this.windowSystemWebShopInfoService.getWindowSystemWebShopInfo(windowSystemDefinitionId)
                : of(new WindowSystemWebShopInfo()),
            webshopImage: windowSystemDefinitionId != undefined
                ? this.windowSystemWebShopInfoService.getImageAsFile(windowSystemDefinitionId)
                : of<File>(undefined),
            webshopLargeImage: windowSystemDefinitionId != undefined
                ? this.windowSystemWebShopInfoService.getLargeImageAsFile(windowSystemDefinitionId)
                : of<File>(undefined),
            webshopLargeImageMobile: windowSystemDefinitionId != undefined
                ? this.windowSystemWebShopInfoService.getLargeImageMobileAsFile(windowSystemDefinitionId)
                : of<File>(undefined),
            glamourPrintImageFile: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getGlamourPrintImageAsFile(windowSystemDefinitionId)
                : of<File>(undefined),
            windowEditorImage: windowSystemDefinitionId != undefined
                ? this.windowSystemDefinitionService.getWindowEditorImageAsFile(windowSystemDefinitionId)
                : of<File>(null),
            glamourPrintIconFile: windowSystemDefinitionId != undefined
                ? forkJoin((_.object(SupportedLanguages.languages.map(lang => lang.code),
                        SupportedLanguages.languages.map(lang =>
                            this.windowSystemDefinitionService.getGlamourPrintIconAsFile(windowSystemDefinitionId, lang.code)
                                .pipe(map<File, FileState>(file => ({file: file, needSave: false})))))
                ) as Partial<Record<keyof MultilanguageFieldInterface, Observable<FileState>>>)
                : of<Partial<Record<keyof MultilanguageFieldInterface, FileState>>>({}),
            designerDescriptionCatalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.WINDOW_SYSTEMS),
            descriptionCatalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.WEBSHOP_SYSTEMS_DESCRIPTION),
            specificationCatalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.WEBSHOP_SYSTEMS_SPECIFICATION),
            activeWindowSystemsForStandaloneGlazingPackages: this.businessTypeService
                .getWindowSystemsByBusinessTypes([WindowTypeCode.F], WindowSystemType.getWindowAndTerraceSystemsTypes())
        }).pipe(
            mergeMap(data => {
                return forkJoin({
                    data: of(data),
                    webshopSliderImages: data.webshopInfo.sliderImageIds.length > 0 ? forkJoin([
                        ...data.webshopInfo.sliderImageIds.map(sliderImageId => {
                            return this.windowSystemWebShopInfoService.getSliderImageAsFile(windowSystemDefinitionId, sliderImageId)
                                .pipe(map<File, [string, FileState]>(imageFile => [`${sliderImageId}`, {
                                    file: imageFile,
                                    needSave: false,
                                    isNew: false
                                }]));
                        })
                    ]) : of<[string, FileState][]>([])
                });
            }),
            map(data => {
                return {
                    ...data.data,
                    webshopSliderImages: data.webshopSliderImages
                };
            })
        ).subscribe({
            next: data => {
                this.windowSystem = WindowSystemDefinition.copy(data.windowSystem);
                this.glasses = data.glasses.filter(g => g.active);
                this.frames = data.distanceFrames.filter(df => df.active);
                this.windowSystemWebShopInfo = data.webshopInfo;
                if (this.copyMode) {
                    this.windowSystem.id = undefined;
                    this.windowSystemWebShopInfo.id = undefined;
                }
                this.setAvailableSubwindowTypes(data.businessTypes.filter(bt => bt.active));
                this.newSystem = windowSystemDefinitionId == undefined;
                this.setDisplayDialog(true);

                this.windowEditorImage = data.windowEditorImage;
                this.webshopImageFile = data.webshopImage;
                this.webshopLargeImageFile = data.webshopLargeImage;
                this.webshopLargeImageMobileFile = data.webshopLargeImageMobile;
                this.glamourPrintImageFile = {file: data.glamourPrintImageFile, needSave: false};
                this.glamourPrintIconFile = data.glamourPrintIconFile;
                this.webshopSliderImageFiles = new Map<string, FileState>(data.webshopSliderImages);
                this.catalogConfigurations[CatalogPropertyTarget.WINDOW_SYSTEMS] = data.designerDescriptionCatalogConfiguration;
                this.catalogConfigurations[CatalogPropertyTarget.WEBSHOP_SYSTEMS_DESCRIPTION] = data.descriptionCatalogConfiguration;
                this.catalogConfigurations[CatalogPropertyTarget.WEBSHOP_SYSTEMS_SPECIFICATION] = data.specificationCatalogConfiguration;
                this.activeWindowSystemsForStandaloneGlazingPackages = data.activeWindowSystemsForStandaloneGlazingPackages.map(ws => ({
                    labelKey: ws.names,
                    value: ws.id
                }));
            },
            error: error => this.errors.handle(error)
        });
    }

    private createNewWindowSystemDefinition() {
        let windowSystemDefinition = new WindowSystemDefinition();
        windowSystemDefinition.active = true;
        windowSystemDefinition.standardDoorstepAvailable = true;
        windowSystemDefinition.material = MaterialType[MaterialType.PCV];
        windowSystemDefinition.supplier = this.getFirstSelectItemValue(this.existingSuppliers);
        windowSystemDefinition.description = {};
        if (this.roofSystem) {
            windowSystemDefinition.roofSystemFunction = RoofSystemFunction[RoofSystemFunction.CENTRE_PIVOT_WINDOWS];
            windowSystemDefinition.systemType = WindowSystemType.ROOF.type;
            windowSystemDefinition.roofGlazingPackageId = this.getFirstSelectItemValue(this.roofGlazingPackages);
        } else if (this.entranceSystem) {
            windowSystemDefinition.systemType = WindowSystemType.ENTRANCE.type;
        } else {
            windowSystemDefinition.glazingPackageId = this.getFirstSelectItemValue(this.glazingPackages);
        }

        return windowSystemDefinition;
    }

    private getFirstSelectItemValue(items: SelectItem[]): any {
        return items.length > 0 ? items[0].value : null;
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    private setAvailableSubwindowTypes(businessTypes: BusinessType[]): void {
        this.availableSubwindowTypes = [];
        let subwindowTypes = businessTypes
            .filter(bt => WindowTypeCode[bt.type] != undefined)
            .reduce((all, bt) => {
                let parsed = WindowTypeCodeParser.parseTypeCode(bt.type);
                return all.concat(parsed.subwindows.map(sw => sw.type));
            }, [] as SubWindowTypeCode[]);
        this.availableSubwindowTypes = _.uniq(subwindowTypes);
        this.removeRedundantLimitations();
    }

    private removeRedundantLimitations(): void {
        let limitations = this.windowSystem.subwindowTypesLimitations;
        this.windowSystem.subwindowTypesLimitations = Object.keys(limitations)
            .filter(key => this.availableSubwindowTypes.includes(SubWindowTypeCode[key]))
            .reduce((obj, key) => {
                obj[key] = limitations[key];
                return obj;
            }, {});
    }

    private initDefaultSortOrder(): void {
        if (this.roofSystem) {
            this.defaultSortColumn = "roofSystemSortIndex";
        } else if (this.entranceSystem) {
            this.defaultSortColumn = "entranceSystemSortIndex";
        } else {
            this.defaultSortColumn = "sortIndex";
        }
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    handleWebshopImageFileChange(newFile: File) {
        this.webshopImageFile = newFile;
        this.changeDetector.markForCheck();
    }

    handleWebshopLargeImageFileChange(newFile: File) {
        this.webshopLargeImageFile = newFile;
        this.changeDetector.markForCheck();
    }

    handleWebshopLargeImageMobileFileChange(newFile: File) {
        this.webshopLargeImageMobileFile = newFile;
        this.changeDetector.markForCheck();
    }

    handleWebshopSliderImageFileAdded(): void {
        const tempId = `TEMP_${++this.tempWebshopSliderIdGenerator}`;
        this.webshopSliderImageFiles.set(tempId, {file: undefined, needSave: false, isNew: true});
        this.changeDetector.markForCheck();
    }

    handleWebshopSliderImageFileChanged(id: string, file: File): void {
        const fileEntry = this.webshopSliderImageFiles.get(id);
        if (fileEntry != undefined) {
            fileEntry.file = file;
            fileEntry.needSave = true;
            this.changeDetector.markForCheck();
        }
    }

    handleGlamourPrintImageFileChange(newFile: File): void {
        this.glamourPrintImageFile.file = newFile;
        this.glamourPrintImageFile.needSave = true;
        if (!newFile) {
            this.glamourPrintImageFile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourPrintIconFileChange(language: keyof MultilanguageFieldInterface, newFile: File): void {
        if (this.glamourPrintIconFile[language] == null) {
            this.glamourPrintIconFile[language] = {file: undefined, needSave: false};
        }
        this.glamourPrintIconFile[language].needSave = this.glamourPrintIconFile[language] && this.glamourPrintIconFile[language].file !== newFile;
        this.glamourPrintIconFile[language].file = newFile;
        if (!newFile) {
            this.glamourPrintIconFile[language].file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetFile(): void {
        this.webshopImageFile = null;
        this.webshopLargeImageFile = null;
        this.webshopLargeImageMobileFile = null;
        this.webshopSliderImageFiles = new Map<string, FileState>();
        this.windowSystemWebShopInfoValidationErrors = {};
    }

    onWindowEditorImageChange(newFile) {
        this.windowEditorImage = newFile;
        if (!newFile) {
            this.windowEditorImage = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetWindowEditorImage(): void {
        this.windowEditorImage = null;
    }

    onPrintoutImageChange(newFile) {
        this.printoutImage = newFile;
        if (!newFile) {
            this.printoutImage = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetPrintoutImage(): void {
        this.printoutImage = null;
    }

    getCatalogConfiguration(target: CatalogPropertyTarget): Observable<CatalogConfiguration> {
        return this.catalogConfigurations[target] == undefined ?
            this.catalogConfigurationService.getForTarget(target, true) : of(this.catalogConfigurations[target]);
    }

    getEditPermits() {
        let catalogElement;
        if (this.entranceSystem) {
            catalogElement = CatalogElement.ENTRANCE_SYSTEMS;
        } else if (this.roofSystem) {
            catalogElement = CatalogElement.ROOF_SYSTEMS;
        } else {
            catalogElement = CatalogElement.WINDOW_SYSTEMS;
        }
        this.editCatalogPermitsService.getPermitsByCatalogElement(catalogElement).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }
}
