import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {SupportedLanguages} from '../../../supportedLanguages';
import {WebshopChargeService} from './webshop-charge-service';
import {DataModificationTarget, WebshopCharge} from './WebshopCharge';

@Component({
    selector: 'app-webshop-charge',
    templateUrl: './webshop-charge.component.html',
    styleUrls: ['./webshop-charge.component.css'],
    providers: [WebshopChargeService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopChargeComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    selectedWebshopCharge: WebshopCharge;
    webshopCharges: WebshopCharge[];
    editedSortIndex: number;
    editedWebshopCharge: WebshopCharge;

    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayWebshopChargeEditor = false;
    displayChangeSortIndexDialog = false;
    userLang: string;
    file: File;
    fileBig: File;
    dataModificationTargets = DataModificationTarget;
    protected sanitizer: DomSanitizer;
    supportedLanguages = SupportedLanguages.languages;
    @Input('target')
    targetInput: DataModificationTarget;

    @ViewChild('dt') datatable;

    get target(): DataModificationTarget {
        if (this.targetInput) {
            return this.targetInput;
        }
        if (this.activatedRoute.snapshot.data.target) {
            return this.activatedRoute.snapshot.data.target;
        }
        return undefined;
    }

    constructor(private webshopChargeService: WebshopChargeService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private translate: TranslateService,
                private errorHandler: CommonErrorHandler,
                private activatedRoute: ActivatedRoute,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopChargeComponent', false);
        this.sanitizer = injector.get(DomSanitizer);
        this.userLang = translate.currentLang;
        this.selectedWebshopCharge = new WebshopCharge(this.target);
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initShownColumns();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        if (!event.filters.hasOwnProperty('target')) {
            event.filters.target = {value: this.target};
        }
        return this.webshopChargeService.getWebshopCharges(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WebshopChargeComponent `getPage` success:', data);
                    this.webshopCharges = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedWebshopCharge =
                        this.restoreSelectionAfterLoad(this.selectedWebshopCharge, this.webshopCharges, event);
                },
                error: error => {
                    console.error('WebshopChargeComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WebshopChargeComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    onRowSelect(event: any): void {
        this.selectedWebshopCharge = _.clone(event.data);
        this.setDisplayWebshopChargeEditor(true);
        this.keepSelectedItemIndex(event);
    }

    setDisplayWebshopChargeEditor(display: boolean): void {
        if (this.displayWebshopChargeEditor !== display) {
            this.displayWebshopChargeEditor = display;
            this.changeDetector.markForCheck();
        }
    }

    showDialogToAdd(): void {
        this.selectedWebshopCharge = new WebshopCharge(this.target);
        this.setDisplayWebshopChargeEditor(true);
        this.changeDetector.markForCheck();
    }

    submit(): void {
    }

    changeActiveStatus(webshopCharge: WebshopCharge, active: boolean) {
        this.editedWebshopCharge = _.clone(webshopCharge);
        this.editedWebshopCharge.active = active;
        this.edit();
    }

    changeSortIndex() {
        this.editedWebshopCharge.sortIndex = this.editedSortIndex;
        this.edit();
    }

    edit(): void {
        this.validateForm();

        if (this.validationErrorsPresent()) {
            this.changeDetector.markForCheck();
            return;
        }

        if (this.isSaveInProgress()) {
            return;
        } else {
            this.setSaveInProgress(true);
        }

        this.webshopChargeService.editWebshopCharge(this.editedWebshopCharge.id, this.editedWebshopCharge, this.file, this.fileBig).pipe(
            finalize(() => this.setSaveInProgress(false))
        ).subscribe({
            next: () => {
                this.reloadDatatable();
                this.showSuccessMessage();
                this.closeSortIndexDialog();
            },
            error: error => {
                this.validationErrors = this.errorHandler.handle(error);
                this.changeDetector.markForCheck();
                console.error('WebshopChargeComponent `getPage` error:', error);
            }
        });
    }

    validateForm(): void {
        this.validationErrors = {};

        this.validationErrors['sortIndex'] = MultiValidator.of('error.webshopChargeDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.editedWebshopCharge.sortIndex);
    }

    closeSortIndexDialog(): void {
        this.validationErrors = {};
        this.displayChangeSortIndexDialog = false;
        this.changeDetector.markForCheck();

    }

    displaySortIndexDialog(webshopCharge: WebshopCharge): void {
        this.resetFiles();
        this.editedWebshopCharge = _.clone(webshopCharge);
        this.editedSortIndex = webshopCharge.sortIndex;

        forkJoin({
            file: this.webshopChargeService.getImage(webshopCharge.id),
            fileBig: this.webshopChargeService.getImageBig(webshopCharge.id)
        }).subscribe(responses => {
            this.file = responses.file;
            this.fileBig = responses.fileBig;
            this.displayChangeSortIndexDialog = true;
            this.changeDetector.markForCheck();
        });
    }

    showSuccessMessage() {
        this.growlMessageController.info('WEBSHOP_CHARGE.UPDATED');
    }

    onFileChange(newFile: File, property: 'file' | 'fileBig') {
        this[property] = newFile;
        if (!newFile) {
            this[property] = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetFiles(): void {
        this.file = undefined;
        this.fileBig = undefined;
    }
}
