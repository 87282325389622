import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, Observer, of, Subscription} from "rxjs";
import {mergeMap} from 'rxjs/operators';
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../common/DatatableHelper";
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {OnceFlag} from '../../../shared/once-flag';
import {PriceListService} from "../price-list.service";
import {Pricelist, PricelistStatus, PricelistTarget} from "../pricelist";
import {PriceTableService} from "./price-table.service";
import {PriceTable} from './priceTable';
import {Material2ConfigSystem} from "./material2ConfigSystem";
import {Material2ConfigSystemList} from "./material2ConfigSystemList";
import {MaterialService} from "../../window-system/material/material.service";
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";
import {CatalogItemName} from "../../../common/crud-common/catalog-item-name";
import {CatalogItemExtended} from "../../window-system/entrance-model/entrance-model-basic";
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";

declare const jQuery: any;

interface TableValidationError {
    errorMessage: string;
    errorRowNum: number;
    errorColumnNum: number;
}

@Component({
    selector: 'app-price-table-configs',
    templateUrl: './price-table-configs.component.html',
    styleUrls: ['../../shared-styles.css', './price-table.component.css'],
    providers: [PriceListService, DataServiceHelper, PriceTableService, MaterialService, ConfigSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceTableConfigsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, AfterViewChecked {

    availablePricelists: Pricelist[] = [];
    selectedPricelist: Pricelist;
    currentPricelist: Pricelist;
    material2ConfigSystems: Material2ConfigSystem[] = [];
    selectedMaterial2ConfigSystem: Material2ConfigSystem;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    displayDialog = false;
    lastLoadEvent: LazyLoadEvent;
    langTranslateSubscription: Subscription;
    userLang: string;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    selectedRow: Material2ConfigSystem;
    tableValidationErrors: TableValidationError[] = [];
    scrollToFirstError = false;

    priceTable: PriceTable;
    selectedTableInfo: Material2ConfigSystem;
    targets = PricelistTarget;

    allSelectedState = TristateCheckboxState.UNCHECKED;
    selectedItems: Material2ConfigSystem[] = [];

    @ViewChild("dt", {static: true}) datatable: DataTable;

    private readonly dialogHideHelper = new OnceFlag();
    private readonly materialService: MaterialService;
    private readonly configSystemService: ConfigSystemService;
    systemsWithLinks: CatalogItemExtended[];
    materials: CatalogItemName[];

    constructor(private readonly pricelistService: PriceListService,
                private readonly priceTableService: PriceTableService,
                private readonly translate: TranslateService,
                private readonly permissions: Permissions,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private readonly errors: CommonErrorHandler) {
        super(injector, changeDetector, 'PriceTableComponent', false);
        this.materialService = injector.get(MaterialService);
        this.configSystemService = injector.get(ConfigSystemService);
        this.userLang = translate.currentLang;
        this.langTranslateSubscription = translate.onLangChange.subscribe((event) => {
            this.userLang = event.lang;
            this.changeDetector.markForCheck();
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fillPricelilstsDropdown();
        this.hotkeysService.remove(this.newElementHotkey);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.langTranslateSubscription.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (this.scrollToFirstError) {
            const error = jQuery('.price-table-error:first');
            const scrollContainer = error.scrollParent();
            scrollContainer.animate({
                scrollTop: scrollContainer.scrollTop() - scrollContainer.offset().top + error.offset().top
            }, 250);
            this.scrollToFirstError = false;
        }
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    showDialogToAdd(): void {
        if (this.selectedRow) {
            this.material2ConfigSystemButtonClick(this.selectedRow);
        }
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    pricelistChanged(pricelist: Pricelist): void {
        this.selectedPricelist = pricelist;
        this.priceTableService.getMaterial2ConfigSystems(this.selectedPricelist.id, this.lastLoadEvent.first, this.lastLoadEvent.rows,
            this.lastLoadEvent.filters, this.lastLoadEvent.sortField, this.lastLoadEvent.sortOrder)
            .subscribe(this.loadPricetablesLazySubscriber(this.lastLoadEvent.first, this.lastLoadEvent.rows));
    }

    private loadPricetablesLazySubscriber(first: number, rows: number): Observer<Material2ConfigSystemList> {
        return {
            next: data => {
                this.totalRecords = data.totalRecords;
                this.material2ConfigSystems = data.data;
                this.fromRecord = Math.min(first + 1, this.totalRecords);
                this.toRecord = Math.min(first + rows, this.totalRecords);
                let selectedItemIndex = -1;
                if (this.selectedRow != undefined) {
                    selectedItemIndex = this.material2ConfigSystems.findIndex(item => item.configSystemId === this.selectedRow.configSystemId
                        && item.materialId === this.selectedRow.materialId);
                }
                if (selectedItemIndex === -1) {
                    selectedItemIndex = 0;
                }
                this.selectedRow = this.material2ConfigSystems[selectedItemIndex];
                if (!DatatableHelper.isColumnFilterFocused(this.datatable)) {
                    DatatableHelper.focusOnTableRow(this.datatable, selectedItemIndex);
                }
            },
            error: error => {
                console.error('loadPricetablesLazy `getPage` error:', error);
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            },
            complete: () => {
                console.info('loadPricetablesLazy `getPage` completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        };
    }

    loadPricetablesLazy(event: LazyLoadEvent) {
        this.resetSelections();
        super.loadItemsLazy(event);
        this.lastLoadEvent = event;
        if (this.selectedPricelist) {
            return this.priceTableService.getMaterial2ConfigSystems(this.selectedPricelist.id, event.first, event.rows, event.filters,
                event.sortField, event.sortOrder).subscribe(this.loadPricetablesLazySubscriber(event.first, event.rows));
        }
    }

    onRowSelect(event: any) {
        this.material2ConfigSystemButtonClick(this.selectedRow);
        this.keepSelectedItemIndex(event);
    }

    cancel() {
        this.dialogHideHelper.call(() => {
            this.setDisplayDialog(false);
            this.clearError('table');
            this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedRow);
        });
    }

    fillPricelilstsDropdown() {
        forkJoin({
            allPricelists: this.pricelistService.getAllPricelists(PricelistTarget.CONFIGS),
            currentPricelist: this.pricelistService.getCurrentPricelist(PricelistTarget.CONFIGS)
        }).pipe(mergeMap(data => {
            this.availablePricelists = data.allPricelists.data;
            this.currentPricelist = data.currentPricelist;
            if (this.availablePricelists.length > 0) {
                this.setDefaultSelectedPricelist();
                return this.priceTableService.getMaterial2ConfigSystems(this.selectedPricelist.id, 0, this.chosenRowsPerPage,
                    this.lastLoadEvent.filters, this.lastLoadEvent.sortField, this.lastLoadEvent.sortOrder);
            }
            return of<Material2ConfigSystemList>({data: [], totalRecords: 0});
        })).subscribe(this.loadPricetablesLazySubscriber(0, this.chosenRowsPerPage));
    }

    private setDefaultSelectedPricelist() {
        this.selectedPricelist = this.availablePricelists
                .filter(pricelist => pricelist.status === PricelistStatus.ACTIVE)
                .filter(pricelist => pricelist.validFrom.valueOf() < Date.now())
                .sort((list1, list2) => list2.validFrom.valueOf() - list1.validFrom.valueOf())[0]
            || this.availablePricelists[0];
    }

    editPricetable(passedInMaterial2ConfigSystem: Material2ConfigSystem) {
        this.resetSelections();
        this.selectedRow = passedInMaterial2ConfigSystem;
        let event = {};
        event['data'] = passedInMaterial2ConfigSystem;
        this.onRowSelect(event);
    }

    material2ConfigSystemButtonClick(passedInMaterial2ConfigSystem: Material2ConfigSystem) {
        this.tableValidationErrors = [];
        this.selectedMaterial2ConfigSystem = passedInMaterial2ConfigSystem;

        this.selectedTableInfo = passedInMaterial2ConfigSystem;
        this.loadPriceTable(passedInMaterial2ConfigSystem);
    }

    loadPriceTable(passedInMaterial2ConfigSystem: Material2ConfigSystem) {
        this.showDataLoadingIndicator();
        (passedInMaterial2ConfigSystem.priceTableItemsPresent
            ? this.priceTableService.getPriceTable(passedInMaterial2ConfigSystem.priceTableId, false)
            : of(undefined as PriceTable[])).subscribe({
            next: priceTable => {
                if (priceTable != undefined) {
                    this.priceTable = priceTable[0];
                } else {
                    this.priceTable = undefined;
                }
                this.setDisplayDialog(true);
            },
            error: error => {
                console.error('PriceTableComponent loadPriceTable error:', error);
                this.hideDataLoadingIndicator();
            },
            complete: () => {
                console.info('loadPriceTable `getPage` completed!');
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            }
        });
    }

    showSuccessMessage() {
        if (this.isNewPriceTable()) {
            this.growlMessageController.info('PRICE_TABLES.CREATED');
        } else {
            this.growlMessageController.info('PRICE_TABLES.UPDATED');
        }
    }

    isNewPriceTable() {
        return !(this.selectedPricelist && this.selectedPricelist.id);
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    closeDialog() {
        this.systemsWithLinks = null;
        this.setDisplayDialog(false);
    }

    submit() {
    }

    submitPricingTable(event: {priceTables: PriceTable[], material2system?: Material2ConfigSystem}) {
        this.showDataLoadingIndicator();
        let saveObservable: Observable<void>;
        if (this.selectedItems.length > 1) {
            let pricetableIds = this.selectedItems.map(item => item.priceTableId);
            saveObservable = this.priceTableService.savePricetableConfigsMultiple(pricetableIds, event.priceTables[0]);
        } else {
            let material2system = event.material2system ? event.material2system : this.selectedMaterial2ConfigSystem;
            saveObservable = this.priceTableService.savePricetableConfigs(this.selectedPricelist, material2system, event.priceTables[0]);

        }
        saveObservable.subscribe({
            next: () => {
                this.showSuccessMessage();
                this.loadPricetablesLazy(this.lastLoadEvent);
                this.closeDialog();
            },
            error: (error: HttpErrorResponse) => {
                this.hideDataLoadingIndicator();
                this.errors.handle(error, true);
                console.error('pricetable save failed error:', error);
                this.changeDetector.markForCheck();
            }
        });
    }

    get selectedPricelistIsFuture() {
        return this.selectedPricelist != undefined
            && this.currentPricelist != undefined
            && this.selectedPricelist.validFrom > this.currentPricelist.validFrom;
    }

    editTables() {
        if (this.selectedItems.length === 1) {
            this.doOnRowSelect({data: this.selectedItems[0]});
        } else {
            this.priceTable = new PriceTable();
            this.selectedTableInfo = new Material2ConfigSystem();
            this.setDisplayDialog(true);
        }
    }

    addTable() {
        this.priceTable = new PriceTable();
        this.selectedTableInfo = new Material2ConfigSystem();
        forkJoin({
            materials: this.materialService.getItemNames(undefined),
            systemsWithMaterialLinks: this.materialService.getConfigsWithMaterialLinks(this.selectedPricelist.id),
        }).subscribe({
            next: data => {
                this.systemsWithLinks = data.systemsWithMaterialLinks;
                if (this.systemsWithLinks == null || this.systemsWithLinks.length < 1) {
                    this.growlMessageController.info("PRICE_TABLES.NO_MORE_TABLES_TO_CREATE");
                } else {
                    this.materials = data.materials;
                    this.setDisplayDialog(true);
                }
            },
            error: error => {
                console.error('SingleSystemCheckboxComponent `addTable` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('SingleSystemCheckboxComponent `addTable` completed!');
            }
        });
    }

    setErrors(error: HttpErrorResponse | Error): void {
        this.errors.handle(error);
    }

    selectAllChange(state: TristateCheckboxState) {
        this.allSelectedState = state;
        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.material2ConfigSystems.filter(item => !this.selectedItems.includes(item)));
        } else if (this.allSelectedState === TristateCheckboxState.UNCHECKED) {
            this.selectedItems = this.selectedItems.filter(item => !this.material2ConfigSystems.includes(item));
        }
        this.changeDetector.markForCheck();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0 || this.material2ConfigSystems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.material2ConfigSystems.every(item => this.selectedItems.includes(item))) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.material2ConfigSystems.some(item => this.selectedItems.includes(item))) {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        } else {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        }
    }

    isSelectedItem(item: Material2ConfigSystem): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: Material2ConfigSystem): void {
        let index = this.selectedItems.indexOf(item);

        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }
        this.refreshAllSelectedFlag();
    }

    resetSelections() {
        this.selectedItems = [];
        this.refreshAllSelectedFlag();
    }
}
