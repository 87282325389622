import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OnceFlag} from '../../../../shared/once-flag';
import {SubsystemSelectionItem} from '../../../subsystem/SubsystemSelectionItem';

@Component({
    selector: 'app-confirm-overwriting-window-system-defaults-dialog',
    templateUrl: './confirm-overwriting-window-system-defaults-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmOverwritingWindowSystemDefaultsDialogComponent {

    @Input()
    visible = false;

    @Input()
    selections: SubsystemSelectionItem[] = [];

    @Input()
    title: string;

    @Input()
    body: string;

    @Output()
    onConfirm = new EventEmitter<void>();

    @Output()
    onHide = new EventEmitter<void>();

    private dialogHideHelper = new OnceFlag();

    canConfirm(): boolean {
        return this.selections.some(subsystem => subsystem.selected);
    }

    handleShow() {
        this.dialogHideHelper.reset();
    }

    confirm() {
        this.dialogHideHelper.call(() => this.onConfirm.emit());
    }

    hide() {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }
}
