import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {AbstractPositionList} from '../../AbstractPosition';
import {AddonProperties} from '../../offers/position/window-properties/addon-properties';
import {AssemblyProperties} from '../../offers/position/window-properties/assembly-properties';
import {GateProperties} from '../../offers/position/window-properties/gate-properties';
import {TransportProperties} from '../../offers/position/window-properties/transport-properties';
import {WindowProperties} from '../../offers/position/window-properties/window-properties';
import {WindowEditorPositionData} from '../../window-editor/window-editor-offer-interfaces';
import {ProductionOrdersPosition} from '../production-orders-position-list/productionOrdersPosition';
import {ConfigProperties} from "../../offers/position/window-properties/config-properties";

@Injectable()
export class ProductionOrdersPositionService {

    private static SERVICE_PATH = 'productionorderposition';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    private static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<AbstractPositionList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, {
            'quantity': ProductionOrdersPositionService.isNumeric,
            'profitMargin': ProductionOrdersPositionService.isNumeric,
            'rabate': ProductionOrdersPositionService.isNumeric,
            'venskaBuyPrice.vatPercent': ProductionOrdersPositionService.isNumeric,
            'buyPrice.vatPercent': ProductionOrdersPositionService.isNumeric,
            'dealerDiscount': ProductionOrdersPositionService.isNumeric,
            'tempDealerDiscount': ProductionOrdersPositionService.isNumeric
        });
        return this.http.get<AbstractPositionList>(ProductionOrdersPositionService.SERVICE_PATH, {params: params});
    }

    getPreview(id: number): Observable<string> {
        return this.http.get(`${ProductionOrdersPositionService.SERVICE_PATH}/${id}/preview`, {responseType: 'text'});
    }

    getWindowProperties(positionOfferId): Observable<WindowProperties> {
        return this.http.get<WindowProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/windowProperties`);
    }

    getGateProperties(positionOfferId): Observable<GateProperties> {
        return this.http.get<GateProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/gateProperties`);
    }

    getAddonProperties(positionOfferId): Observable<AddonProperties> {
        return this.http.get<AddonProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/addonProperties`);
    }

    getAssemblyProperties(positionOfferId): Observable<AssemblyProperties> {
        return this.http.get<AssemblyProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/assemblyProperties`);
    }

    getTransportProperties(positionOfferId): Observable<TransportProperties> {
        return this.http.get<TransportProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/transportProperties`);
    }

    getConfigProperties(positionOfferId): Observable<ConfigProperties> {
        return this.http.get<ConfigProperties>(`${ProductionOrdersPositionService.SERVICE_PATH}/${positionOfferId}/configProperties`);
    }

    getItem(itemId: number): Observable<ProductionOrdersPosition> {
        return this.http.get<ProductionOrdersPosition>(`${ProductionOrdersPositionService.SERVICE_PATH}/${itemId}`);
    }

    getProductionOrderPositionForWindowEditor(itemId: number): Observable<WindowEditorPositionData> {
        return this.http.get<WindowEditorPositionData>(`${ProductionOrdersPositionService.SERVICE_PATH}/${itemId}/forWindowEditor`);
    }
}
