import {Injectable} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {BlockUiController} from '../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {OfferStatus} from '../../../../common/enums/OfferStatus';
import {UserRoleTypes} from "../../../../common/enums/UserRoleTypes";
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ValidationErrorsHelper} from "../../../../common/ValidationErrorsHelper";
import {ErrorResponse} from "../../../errors/errorResponse";
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {UserService} from "../../../user/user.service";
import {Offer} from "../../offer";
import {OffersService} from "../../offer-service";
import {CreateOfferMode} from "./create-offer-mode";
import {UserList} from "../../../user/user-list";

@Injectable()
export class CreateOfferComponentService {

    private static readonly SAVING_BLOCK_UI_NAME = 'CreateOfferSaveBlock';

    validUserRoleTypes = [UserRoleTypes[UserRoleTypes.HANDLOWIEC], UserRoleTypes[UserRoleTypes.OPERATOR]];

    constructor(private offerService: OffersService,
                private subsystemService: SubsystemService,
                private userService: UserService,
                private blockUiController: BlockUiController,
                private errors: CommonErrorHandler,
                private growls: GrowlMessageController) {
    }

    public save(createOfferMode: CreateOfferMode, offer: Offer, errorsContainer: ValidationErrors,
                originalOfferId?: number, updateExchangeRate?: boolean): Observable<number> {
        let observable: Observable<number>;
        switch (createOfferMode) {
            case CreateOfferMode.NEW:
                observable = this.offerService.createOffer(offer);
                break;
            case CreateOfferMode.EDIT:
                observable = this.offerService.editOffer(offer, updateExchangeRate);
                break;
            case CreateOfferMode.COPY:
                observable = this.offerService.copyOffer(offer, originalOfferId, updateExchangeRate, false)
                    .pipe(catchError(error => {
                        let errorResponse = new ErrorResponse(error.error);
                        if (offer.status !== OfferStatus.COPY_DRAFT // do not create drafts from other drafts
                            && errorResponse.is400()) {
                            if (!ValidationErrorsHelper.validationErrorsPresent(errorResponse.invalidFields)) {
                                return this.offerService.createFixupDraft(originalOfferId).pipe(tap(() => {
                                    this.growls.info('OFFER.CREATE_OFFER.FORM.COPIED_AS_DRAFT');
                                    this.errors.handle(error); // show growl with broken positions info
                                }));
                            }
                        }
                        return throwError(() => error);
                    }));
                break;
            case CreateOfferMode.COPY_TO_PARTNER:
                observable = this.offerService.copyOffer(offer, originalOfferId, updateExchangeRate, true);
                break;
        }
        this.blockUiController.block(CreateOfferComponentService.SAVING_BLOCK_UI_NAME);
        return observable.pipe(finalize(() => this.blockUiController.unblock(CreateOfferComponentService.SAVING_BLOCK_UI_NAME)));
    }

    public loadAvailableSubsystems(limitToPartners: boolean): Observable<SelectItem[]> {
        return this.subsystemService.getSelectionItems(limitToPartners);
    }

    public loadUsersBySubsystem(subsystemId: number, partnersOnly = false): Observable<UserList> {
        let filters = {};

        filters['active'] = {value: 'true'};
        filters['roleName'] = {value: this.validUserRoleTypes, matchMode: undefined};
        filters['subsystemIds'] = {value: [subsystemId], matchMode: undefined};
        if (partnersOnly) {
            filters['webshopPartner'] = {value: 'true'};
        }

        return this.userService.getUsers(0, 99999, filters, "id", 0);
    }
}
