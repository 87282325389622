import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {DataTable} from 'primeng/datatable';
import {BaseAddonProfitMarginComponent} from "../../../common/addon-profit-margin/base-addon-profit-margin.component";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SubsystemAddonProfitMarginService} from "./subsystem-addon-profit-margin.service";
import {SubsystemAddonProfitMargin} from "./subsystemAddonProfitMargin";

@Component({
    selector: 'app-subsystem-addon-profit-margin',
    templateUrl: '../../../common/addon-profit-margin/base-addon-profit-margin.component.html',
    styleUrls: ['../../../common/addon-profit-margin/base-addon-profit-margin.component.css'],
    providers: [SubsystemAddonProfitMarginService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemAddonProfitMarginComponent
    extends BaseAddonProfitMarginComponent<SubsystemAddonProfitMargin, SubsystemAddonProfitMarginService> implements OnInit {

    @ViewChild('dt') dt: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, SubsystemAddonProfitMarginService);
    }

    getDatatable(): DataTable {
        return this.dt;
    }

    fillElementId(rowData): void {
        this.editedItem.subsystemId = rowData.subsystemId;
        if (!this.editedItem.subsystemId && this.subsystemId) {
            this.editedItem.subsystemId = this.subsystemId;
        }
    }

    getNewItem(): SubsystemAddonProfitMargin {
        return new SubsystemAddonProfitMargin();
    }
}
