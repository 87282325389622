<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <ng-container *ngFor="let group of sideNavItemsByGroup; let last = last">
            <div class="title">
                <div class="text">{{ ('NAVIGATION.ITEMS.SETTINGS-PANEL.' + group.groupName) | translate }}</div>
            </div>
            <div *ngFor="let item of group.items" class="navigation-element" [id]="item.cssClass"
                 [routerLink]="item.routerLink !== '' ? item.routerLink : item.cssClass" routerLinkActive="active">
                <div class="name">{{ item.name | translate }}</div>
            </div>

            <div *ngIf="!last" class="menu-separator">&nbsp;</div>
        </ng-container>
    </div>

    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <router-outlet></router-outlet>
    </div>
</div>
