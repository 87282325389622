<div class="new-form-60vw">

    <app-link-selection [disabled]="disabled"
                        [linkableItems]="windowSystems"
                        [ngModel]="linkedSystemIds"
                        [windowSystemTypeGroups]="windowSystemTypeGroups"
                        [windowSystemFilter]="predefinedGlazingFilter"
                        (ngModelChange)="onSelectedSystemsChange($event)"
                        [showToggleAllButton]="true"></app-link-selection>
</div>
