import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {GateDesignerCatalogDependentOption} from './data-form/gate-designer-catalog-dependent-option';

@JsonDeserializable
export class GateDesignerCatalogDependentOptionsSet implements CrudItem {
    id: number;
    gateSystemId: number;
    gateSystemName: MultilanguageFieldInterface;
    sortIndex = 1;
    name: string;
    active = true;
    options: GateDesignerCatalogDependentOption[];

    static fromJSON(jsonObject: object): GateDesignerCatalogDependentOptionsSet {
        const optionsSet = new GateDesignerCatalogDependentOptionsSet();
        optionsSet.id = jsonObject['id'];
        optionsSet.gateSystemId = jsonObject['gateSystemId'];
        if (jsonObject['gateSystemName'] != undefined) {
            optionsSet.gateSystemName = MultilanguageField.fromJSON(jsonObject['gateSystemName']);
        }
        optionsSet.sortIndex = jsonObject['sortIndex'];
        optionsSet.name = jsonObject['name'];
        optionsSet.active = jsonObject['active'];
        optionsSet.options = [];
        return optionsSet;
    }
}
