<div class="container-with-toolbar">
    <ng-template #toolbar>
        <app-toolbar-button buttonId="addPosition" *ngIf="canEdit" [hotkey]="addPaletteHotkey"
                            type="main-action" icon="add" label="{{ 'DELIVERY_LIST.ADD_PALETTE' | translate}}"
                            (onClick)="addPalette()"></app-toolbar-button>

        <app-toolbar-button buttonId="printButton"
                            icon="print" label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}"
                            (onClick)="openPrintDialog()"></app-toolbar-button>

        <ng-container *ngIf="isDeleteButtonEnabled()">
            <div class="separator"></div>
            <app-toolbar-button buttonId="removePositions"
                                icon="delete" label="{{ 'DELIVERY_LIST.DELETE_SELECTED' | translate}}"
                                (onClick)="deleteSelected()" [hotkey]="deleteSelectedHotkey"></app-toolbar-button>
        </ng-container>

        <div class="spacer"></div>

        <app-toolbar-button buttonId="editDeliveryList" *ngIf="canEdit"
                            icon="settings" label="{{ 'DELIVERY_LIST.EDIT_DELIVERY_LIST' | translate}}"
                            (onClick)="editDeliveryList()" [hotkey]="editDeliveryListHotkey"></app-toolbar-button>

        <app-toolbar-button buttonId="goBack" icon="exit_to_app" label="{{ 'GENERAL.GO_BACK' | translate}}"
                            (onClick)="navigateBack()"></app-toolbar-button>
    </ng-template>

    <div class="list-content" *ngIf="deliveryList != null">
        <div class="cover-behind-tabview-nav"></div>
        <div class="position-list-header" style="min-height: 88px">
            <div class="header-row">
                <div id="left">
                    <div class="primary-table-header major-element">
                        <div class="title">
                            <span class="deliveryListName">{{deliveryList.name}}</span>
                        </div>
                    </div>
                </div>

                <div id="right">
                    <div class="primary-table-header major-element">
                        <app-simple-button *ngIf="possibleTransitions.length > 0" buttonId="deliveryListStatusTransitionButton"
                                           label="{{ (possibleTransitions.length === 1 ? possibleTransitions[0].item.label : 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP') | translate }}"
                                           icon="{{ possibleTransitions.length === 1 ? 'info' : '' }}" type="confirm" [size]="40" align="left"
                                           (onClick)="statusTransitionButtonClick()"
                                           (onIconClick)="showTransitionInfo($event)"></app-simple-button>
                    </div>
                </div>
            </div>

            <div class="second-header-row">
                <div id="mid">
                    <div class="centered-table-cell">
                        <span class="table-header-buttons">
                            <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                                           id="positionListShownColumns" [displaySelectedLabel]="false"
                                           [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                                           (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>
                            <button-with-menu buttonId="sortPalettesButton" buttonLabel="{{ 'DELIVERY_LIST.PALETTE_LIST.SORT_BY.LABEL' | translate }}"
                                              rightButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                                              [menuType]="menuType.BOTTOM_RIGHT" [menuElements]="availableSorting"
                                              (menuElementSelected)="handleSortMenuElementSelected($event)"></button-with-menu>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <p-accordion [multiple]="true" class="palette-accordion">
            <ng-container *ngFor="let palette of deliveryList.palettes; let last = last;" >
                <p-accordionTab [selected]="true">
                    <p-header>
                        <div class="accordion-tab-header">
                            <span class="left-side">
                                {{ palette.name }} {{ palette.creationDate | momentCurrentLang | amDateFormat: '(L)' }} - {{ palette.quantity }}
                            </span>
                            <app-simple-button [buttonId]="'toggle-filter-button-palette-' + palette.id"
                                               label="{{ (filterEnabledForPalette(palette.id) ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                               icon="filter_list" type="filter"
                                               (onClick)="showTableFilters($event, palette.id)"></app-simple-button>
                            <app-simple-button *ngIf="canEdit" [buttonId]="'editPalette_' + palette.id" icon="add" type="main-action"
                                               (onClick)="editPalette($event, palette.id, PaletteWizardMode.EDIT_CONTENT)"
                                               label="{{ 'DELIVERY_LIST.PALETTE_LIST.ADD_PRODUCTION_ORDER' | translate }}" [inlineLabel]="false"></app-simple-button>
                            <app-simple-button *ngIf="canEdit" [buttonId]="'editPalette_' + palette.id" icon="queue" type="filter"
                                               (onClick)="addPaletteAdditionalElement($event, palette.id)"
                                               label="{{ 'DELIVERY_LIST.PALETTE_LIST.ADD_PALETTE_ADDITIONAL_ELEMENT' | translate }}" [inlineLabel]="false"></app-simple-button>
                            <app-simple-button *ngIf="canEdit" [buttonId]="'editPalette_' + palette.id" icon="create" type="filter"
                                               (onClick)="editPalette($event, palette.id, PaletteWizardMode.EDIT_DATA)"
                                               label="{{ 'DELIVERY_LIST.PALETTE_LIST.EDIT_PALETTE' | translate }}" [inlineLabel]="false"></app-simple-button>
                            <app-simple-button *ngIf="canDeletePalette(palette)"
                                               [buttonId]="'deletePalette_' + palette.id"
                                               label="{{ 'DELIVERY_LIST.PALETTE_LIST.DELETE_PALETTE' | translate }}"
                                               [inlineLabel]="false" icon="delete" type="delete"
                                               (onClick)="deletePalette(palette)"></app-simple-button>
                            <span style="width: 8px"></span>
                            <span>
                                <i class="material-icons tab-closed">keyboard_arrow_down</i>
                                <i class="material-icons tab-opened">keyboard_arrow_right</i>
                            </span>
                        </div>
                    </p-header>
                    <div class="datatable-container">
                        <app-palette-table #paletteTable
                                           [palette]="palette"
                                           [tableData]="tableDataById[palette.id]"
                                           [canEdit]="canEdit"
                                           [columns]="columns"
                                           (onLazyLoad)="loadItemsForPalette($event, palette.id, true)"
                                           (emitGoToOrder)="navigateToProductionOrder($event)">
                        </app-palette-table>
                    </div>
                </p-accordionTab>

                <p-accordionTab class="palette-additional-elements-tab" *ngIf="showPaletteAdditionalElementsTable(palette)" [selected]="true">
                    <p-header>
                        <div class="accordion-tab-header" style="margin-top: 0">
                            <span class="left-side">
                              {{ palette.name }} - {{ palette.quantity }} - {{ 'DELIVERY_LIST.PALETTE_ADDITIONAL_ELEMENT_LIST.HEADER' | translate }}
                            </span>
                            <app-simple-button *ngIf="canEdit" [buttonId]="'editPalette_' + palette.id" icon="add" type="main-action"
                                               (onClick)="addPaletteAdditionalElement($event, palette.id)"
                                               label="{{ 'DELIVERY_LIST.PALETTE_LIST.ADD_PALETTE_ADDITIONAL_ELEMENT' | translate }}" [inlineLabel]="false"></app-simple-button>
                            <app-simple-button [buttonId]="'toggle-filter-button-palette-' + palette.id"
                                               label="{{ (filterEnabledForPaletteAdditionalElements(palette.id) ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                               icon="filter_list" type="filter"
                                               (onClick)="showAdditionalElementsTableFilters($event, palette.id)"></app-simple-button>
                            <span style="width: 8px"></span>
                            <span>
                                <i class="material-icons tab-closed">keyboard_arrow_down</i>
                                <i class="material-icons tab-opened">keyboard_arrow_right</i>
                            </span>
                        </div>
                    </p-header>
                    <div class="datatable-container">
                        <app-palette-additional-elements-table #paletteAdditionalElementsTable
                                                               [palette]="palette"
                                                               [tableData]="paletteAdditionalElementsTableDataById[palette.id]"
                                                               [canEdit]="canEdit"
                                                               (onLazyLoad)="loadAdditionalElementsForPalette($event, palette.id, true)">
                        </app-palette-additional-elements-table>
                    </div>
                </p-accordionTab>
                <div *ngIf="!last" class="line-space"></div>
            </ng-container>
        </p-accordion>
    </div>
</div>

<app-info-dialog *ngIf="statusTransitionInfoDialogVisible"
                 header="{{ 'OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.HEADER' | translate }}"
                 text="{{ getStatusTransitionInfoLabel() }}"
                 (onHide)="hideStatusTransitionDialog()"></app-info-dialog>

<app-print-dialog *ngIf="printDialogVisible" [printableSection]="PrintableSection.DELIVERY_LISTS" [selectedItems]="printableItems"
                  [selectedPositionsIds]="selectedProdOrdersIds" (onCloseDialog)="printDialogVisible = false"></app-print-dialog>

<p-dialog *ngIf="showDialogToAddPaletteAdditionalElement" [resizable]="false" [visible]="true" (onHide)="closeDialog()"
          [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'DELIVERY_LIST.PALETTE_LIST.ADD_PALETTE_ADDITIONAL_ELEMENT' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <div class="new-form-row description-textarea">
            <app-text-area inputId="description"
                           [maxlength]="512"
                           [(validationMessageKey)]="validationErrors['description']"
                           [(ngModel)]="paletteAdditionalElementDescription"></app-text-area>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="addCommentButton" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" (onClick)="savePaletteAdditionalElement()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
