import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {PriceSheetType} from './price-sheet-type';
import {Pricesheet} from './pricesheet';

@Injectable()
export class PricesheetsService implements CrudService<Pricesheet> {

    private static readonly API_URL = 'pricingsheets';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Pricesheet>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(`${PricesheetsService.API_URL}`, {params: params})
            .pipe(mapListingToJson(Pricesheet));
    }

    getItem(itemId: number): Observable<Pricesheet> {
        return this.http.get<object>(`${PricesheetsService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(Pricesheet));
    }

    addItem(item: Pricesheet): Observable<number> {
        console.error('PricesheetsService: method saveItem not implemented, use savePricesheet() instead');
        return undefined;
    }

    editItem(itemId: number, item: Pricesheet): Observable<number> {
        console.error('PricesheetsService: method editItem not implemented, use savePricesheet() instead');
        return undefined;
    }

    savePricesheet(item: Pricesheet, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('pricingSheetDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(`${PricesheetsService.API_URL}`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToItemId(`${PricesheetsService.API_URL}`, item.id));
    }

    getFileForItem(itemId: number): Observable<Blob> {
        return this.http.get(`${PricesheetsService.API_URL}/${itemId}/file`, {responseType: 'blob'});
    }

    getAvailableTypes(): Observable<PriceSheetType[]> {
        return this.http.get<PriceSheetType[]>(`${PricesheetsService.API_URL}/availableTypes`);
    }
}
