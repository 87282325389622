import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {GateEditorFieldContentProvider} from '../../../offer/gate-editor/gate-editor-field-content-provider';
import {GateDesignerCatalogDependentOption} from './gate-designer-catalog-dependent-option';
import {
    GateDesignerCatalogDependentOptionFormDependencyComponent
} from './gate-designer-catalog-dependent-option-form-dependency.component';

@Component({
    selector: 'app-gate-designer-catalog-dependent-option-form-dependent',
    templateUrl: './gate-designer-catalog-dependent-option-form-dependency.component.html',
    styleUrls: ['./gate-designer-catalog-dependent-option-form-dependency.component.css']
})
export class GateDesignerCatalogDependentOptionFormDependentComponent extends GateDesignerCatalogDependentOptionFormDependencyComponent
    implements OnInit {

    constructor(translate: TranslateService,
                translatedSelectItemService: TranslatedSelectItemService,
                gateEditorFieldContentProvider: GateEditorFieldContentProvider) {
        super(translate, translatedSelectItemService, gateEditorFieldContentProvider, false);
    }

    getInput(option: GateDesignerCatalogDependentOption) {
        return {
            inputId: option.dependentInputId,
            inputValue: option.dependentInputValue,
            whenMatched: option.whenMatched
        };
    }
}
