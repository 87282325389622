<div class="new-form-600">
    <div class="new-form-row">
        <app-input-number inputId="width" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.WIDTH' | translate }}"
                          [(validationMessageKey)]="validationErrors['width']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.productConfiguration.windowWidth" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row">
        <app-input-number inputId="height" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.HEIGHT' | translate }}"
                          [(validationMessageKey)]="validationErrors['height']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.productConfiguration.windowHeight" [required]="true"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="addFilling"
                      label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.ADD_FILLING' | translate }}"
                      [(validationMessageKey)]="validationErrors['addFilling']" [ngModel]="addFilling"
                      (ngModelChange)="handleAddFillingChange($event)"></app-checkbox>
    </div>
    <div *ngIf="addFilling" class="new-form-row">
        <app-input-number inputId="fillingMullionPosition"
                          label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.FILLING_MULLION_POSITION' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['productConfiguration.fillingMullionPosition']"
                          [min]="minFillingMullionPosition" [max]="maxFillingMullionPosition" [step]="1"
                          [(ngModel)]="item.productConfiguration.fillingMullionPosition"></app-input-number>
    </div>
</div>
