export class ConfigSystemDefaults {
    wym1: number;
    wym2: number;
    wym3: number;
    wym4: number;
    wym5: number;
    wym6: number;
    materialId: number;
    colorId: number;
    sidebarAddons: { [addonCategorySymbol: string]: number } = {};

    static copy(defaults: ConfigSystemDefaults): ConfigSystemDefaults {
        const clone = new ConfigSystemDefaults();
        clone.wym1 = defaults.wym1;
        clone.wym2 = defaults.wym2;
        clone.wym3 = defaults.wym3;
        clone.wym4 = defaults.wym4;
        clone.wym5 = defaults.wym5;
        clone.wym6 = defaults.wym6;
        clone.colorId = defaults.colorId;
        clone.materialId = defaults.materialId;
        clone.sidebarAddons = Object.assign({}, defaults.sidebarAddons);
        return clone;
    }
}
