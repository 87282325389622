<div class="new-form-row" *ngIf="header != undefined"><h3>{{ header }}</h3></div>
<div class="new-form-row">
    <app-input-text [inputId]="getId('symbol')"
                    [disabled]="disabled"
                    label="{{ 'MOTLAWA_INTEGRATION_FORM.SYMBOL' | translate }}" [maxlength]="38"
                    [(validationMessageKey)]="validationErrors[getValidationKey('symbol')]"
                    [(ngModel)]="value.symbol"></app-input-text>
</div>
<div class="new-form-row">
    <app-input-text #descriptionInput
                    [inputId]="getId('description')"
                    [disabled]="disabled"
                    label="{{ 'MOTLAWA_INTEGRATION_FORM.DESCRIPTION' | translate }}" [maxlength]="150"
                    [(validationMessageKey)]="validationErrors[getValidationKey('description')]"
                    [(ngModel)]="value.description"
                    appInputTooltip="{{ 'MOTLAWA_INTEGRATION_FORM.HINT' | translate }}"
                    [appInputTooltipComponentHost]="descriptionInput"></app-input-text>
</div>
