<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPES.LIST' | translate }}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles: ['ROLE_EDIT_CATALOG_ITEM']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPE.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true" [lazyLoadOnInit]="false"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="id"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="fieldUsage.show(GatePanelTypeField.NAME)" [ngClass]="columnHeaderClasses('name')" [pSortableColumn]="'name.' + translate.currentLang">
                    <span>{{ 'GATE_PANEL_TYPE.FORM.NAME' | translate }}</span>
                    <p-sortIcon [field]="'name.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="name"
                           (input)="table.filter(getInputEventValue($event), 'name', 'contains')">
                </th>
                <th pSortableColumn="sortIndex" [ngClass]="columnHeaderClasses('sortIndex')">
                    <span>{{ 'GATE_PANEL_TYPE.FORM.SORT_INDEX' | translate }}</span>
                    <p-sortIcon field="sortIndex"></p-sortIcon>
                </th>
                <th *ngIf="fieldUsage.show(GatePanelTypeField.SHORTCUT)" [ngClass]="columnHeaderClasses('shortcut')" [pSortableColumn]="'shortcut.' + translate.currentLang">
                    <span>{{ 'GATE_PANEL_TYPE.FORM.SHORTCUT' | translate }}</span>
                    <p-sortIcon [field]="'shortcut.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="shortcut"
                           (input)="table.filter(getInputEventValue($event), 'shortcut', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(GatePanelTypeField.SYMBOL)" [ngClass]="columnHeaderClasses('symbol')" pSortableColumn="symbol">
                    <span>{{ 'GATE_PANEL_TYPE.FORM.SYMBOL' | translate }}</span>
                    <p-sortIcon field="symbol"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="symbol"
                           (input)="table.filter(getInputEventValue($event), 'symbol', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(GatePanelTypeField.ACTIVE)" [ngClass]="columnHeaderClasses('active')" pSortableColumn="active">
                    <span>{{ 'GATE_PANEL_TYPE.FORM.STATUS' | translate }}</span>
                    <p-sortIcon field="active"></p-sortIcon>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="activeFilter" [options]="filterActiveItems | async"
                                    [ngModel]="filterActiveState" (ngModelChange)="handleFilterActiveChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-gatePanelType let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: gatePanelType, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="gatePanelType" [pSelectableRowIndex]="rowIndex"
                [app-table-keyboard-navigation]="gatePanelType"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: gatePanelType, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td *ngIf="fieldUsage.show(GatePanelTypeField.NAME)">{{ gatePanelType.name[translate.currentLang] }}</td>
                <td>{{ gatePanelType.sortIndex }}</td>
                <td *ngIf="fieldUsage.show(GatePanelTypeField.SHORTCUT)">{{ gatePanelType.shortcut[translate.currentLang] }}</td>
                <td *ngIf="fieldUsage.show(GatePanelTypeField.SYMBOL)">{{ gatePanelType.symbol }}</td>
                <td *ngIf="fieldUsage.show(GatePanelTypeField.ACTIVE)">{{ (gatePanelType.active ? 'GATE_PANEL_TYPE.FORM.ACTIVE' : 'GATE_PANEL_TYPE.FORM.INACTIVE') | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPE.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'GATE_PANEL_TYPE.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id"
                     [validate]="STEPS.DATA.validate">
        <app-gate-panel-type-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                  [lowResolutionPreviewImage]="lowResolutionPreviewImage.file"
                                  (lowResolutionPreviewImageChange)="handleLowResolutionPreviewImageChange($event)"
                                  [highResolutionPreviewImage]="highResolutionPreviewImage.file"
                                  (highResolutionPreviewImageChange)="handleHighResolutionPreviewImageChange($event)"
                                  [additionalIcon]="additionalIcon.file"
                                  (additionalIconChange)="handleAdditionalIconChange($event)"
                                  [gateColors]="gateColors"></app-gate-panel-type-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GATE_PANEL_TYPE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS.id"
                     *ngIf="fieldUsage.show(CatalogField.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogField.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="false" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPE.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_PANEL_TYPE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'GATE_PANEL_TYPE.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id"
                     [validate]="STEPS.DATA.validate">
        <app-gate-panel-type-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                  [lowResolutionPreviewImage]="lowResolutionPreviewImage.file"
                                  (lowResolutionPreviewImageChange)="handleLowResolutionPreviewImageChange($event)"
                                  [highResolutionPreviewImage]="highResolutionPreviewImage.file"
                                  (highResolutionPreviewImageChange)="handleHighResolutionPreviewImageChange($event)"
                                  [additionalIcon]="additionalIcon.file"
                                  (additionalIconChange)="handleAdditionalIconChange($event)"
                                  [gateColors]="gateColors"></app-gate-panel-type-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GATE_PANEL_TYPE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS.id"
                     *ngIf="fieldUsage.show(CatalogField.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogField.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="false" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
