<div class="list-content" *ngIf="!newUserGroup">
    <div class="table-header">
        <div class="title">{{'USER-GROUP.TITLE' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="addUserGroup" label="{{ 'USER-GROUP.LIST.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="userGroups" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadUserGroupsLazy($event)" [responsive]="true"
                 [(selection)]="selectedUserGroup"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="name"
                  header="{{ 'USER-GROUP.LIST.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains"></p-column>
        <p-column field="linkedUsers" header="{{ 'USER-GROUP.FORM.USERS' | translate }}">
            <ng-template let-users="rowData.linkedUsers" pTemplate="body">
                <div class="scroll-panel">
                    <div *ngFor="let user of users">
                        <span [ngClass]="{'inactive-user': !user.active}">{{user.firstName}} {{user.lastName}}</span>
                    </div>
                </div>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="displayDialog && newUserGroup" header="{{ 'USER-GROUP.FORM.TITLE_NEW' | translate }}"
            (onCancel)="cancel()" (onComplete)="add()">
    <app-wizard-step label="{{ 'USER-GROUP.FORM.STEP_NAME' | translate }}" id="details">
        <div class="wizard-header-space"></div>
        <app-user-group-form [userGroup]="userGroup" [users]="usersList"
                             [validationErrors]="validationErrors"></app-user-group-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog #wizardDialog *ngIf="displayDialog && !newUserGroup" header="{{ 'USER-GROUP.FORM.TITLE' | translate }}"
            (onCancel)="cancel()" (onComplete)="edit()">
    <app-wizard-step label="{{ 'USER-GROUP.FORM.STEP_NAME' | translate }}" id="details">
        <div class="wizard-header-space"></div>
        <app-user-group-form [userGroup]="userGroup" [users]="usersList"
                             [validationErrors]="validationErrors"></app-user-group-form>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="wizardDialog.submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
                               type="delete" [size]="40" (onClick)="button_delete()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="wizardDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
<app-confirm-action-dialog *ngIf="displayYesNoDialog" (onCancel)="showYesNoDialog(false)" (onConfirmed)="showYesNoDialog(false);confirmed_delete()"
                           [question]="'GENERAL.DELETE_CONFIRMATION' | translate" [header]="'GENERAL.DIALOG.DELETING' | translate">
</app-confirm-action-dialog>
