import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {getTomorrowDateUTC} from '../../tomorrow-date-helper';
import {DealerDiscount} from '../dealer-discount';

@Component({
    selector: 'app-dealer-discount-form',
    templateUrl: './dealer-discount-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerDiscountFormComponent implements OnInit {

    @Input()
    dealerDiscount: DealerDiscount;

    @Input()
    targetIdField: 'windowSystemId' | 'supplierId' | 'systemId';

    @Input()
    targetLabel: string;

    @Input()
    targetOptions: SelectItem[];

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    canEdit: boolean;

    minValidFromDate: Date;

    ngOnInit(): void {
        this.initValidFromDate();
    }

    onCascadeChange(cascade: boolean): void {
        this.dealerDiscount.cascade = cascade;
        ValidationErrorsHelper.clearErrors(this.validationErrors, 'cascade', 'value');
    }

    onTargetChange(targetId: number): void {
        this.dealerDiscount[this.targetIdField] = targetId;
        ValidationErrorsHelper.clearErrors(this.validationErrors, 'validFrom');
    }

    private initValidFromDate(): void {
        if (this.dealerDiscount.id == null) {
            this.minValidFromDate = getTomorrowDateUTC();
        }
    }
}
