import {CrudItem} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {AddonCategory} from "../addon-category/addon-category";
import {CatalogSection} from "../addons/CatalogSection";

export class AddonCategoryGroup implements CrudItem {
    id: number;
    name: MultilanguageField;
    sortIndex: number;
    categories: AddonCategory[];
    target: CatalogSection;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.sortIndex = 1;
        this.categories = [];
        this.target = undefined;
    }
}
