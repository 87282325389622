import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {Supplier, SupplierMotlawaIntegrationInfo} from "../supplier";
import {ValidationErrors} from "../../../common/validation-errors";

@Component({
    selector: 'app-supplier-motlawa-integration-form',
    templateUrl: './supplier-motlawa-integration-form.component.html',
    styleUrls: ['../create-supplier/create-supplier.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierMotlawaIntegrationFormComponent {

    @Input() validationErrors: ValidationErrors;
    @Input() motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo;
}
