import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class OtherFilling implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    thickness: number;
    active: boolean;
    insideColors: ColorInterface[];
    outsideColors: ColorInterface[];
    coreColor: ColorInterface;
    forIndoorUse: boolean;
    forOutdoorUse: boolean;
    sortIndex: number;
    unitWeight: number;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.forIndoorUse = true;
        this.forOutdoorUse = true;
        this.active = true;
        this.insideColors = [];
        this.outsideColors = [];
        this.shortcut = new MultilanguageField();
        this.sortIndex = 1;
        this.unitWeight = undefined;
    }
}
