<div class="dialog-window add-product-dialog">
    <p-dialog #dialog
              [focusOnShow]="false"
              [modal]="true"
              [resizable]="false"
              [visible]="displayDialog"
              (onShow)="afterShow()"
              (onHide)="closeWindow()">
        <p-header>
            <div class="overlay header-margin"></div>
            <div class="p-dialog-title above-overlay">{{ dialogHeader | translate }}</div>
        </p-header>
        <div>
            <div class="overlay"></div>
            <div class="content above-overlay">

                <div class="left-column">
                    <div class="selections-section">
                        <p-tabMenu [model]="tabItems" [activeItem]="activeTabItem"></p-tabMenu>

                        <div class="new-form-600 dropdown-form" *ngIf="showDropdown">
                            <div class="new-form-row flex-justify-center">
                                <app-select label="{{ dropdownSelectLabel | translate }}"
                                            inputId="dropdown"
                                            [ngModel]="selectedDropdownSelectOption"
                                            [options]="dropdownSelectOptions"
                                            (ngModelChange)="onDropdownSelectionChanged.emit($event)"></app-select>
                            </div>
                        </div>
                        <div class="scrolled-systems" [ngClass]="{noImage: !selectedValueImage}">
                            <div class="new-form-600">
                                <div class="new-form-row flex-justify-center">
                                    <p-dataTable
                                                 selectionMode="single"
                                                 [responsive]="true"
                                                 [rowTrackBy]="tableTrackBy"
                                                 [value]="tableOptions"
                                                 [(selection)]="tableSelection"
                                                 (onRowDblclick)="$event.data.active && submit($event)"
                                                 (onRowSelect)="onTableSelectionChanged.emit($event.data)">
                                        <p-column field="tableField" [sortable]="false" [filter]="false">
                                            <ng-template let-item="rowData" pTemplate="body">
                                                <div class="row" [ngClass]="{disabled: !item.active}">
                                                    <img *ngIf="tableOptionIcons[item.id]" class="image" [src]="tableOptionIcons[item.id]" />
                                                    <div *ngIf="tableOptionNames[item.id]" class="unselectable name" [attr.data-value]="tableTrackBy(null, item)">
                                                        <span *ngIf="selectedValueDescription">{{ tableHeader | translate }}</span>
                                                        <span>{{ tableOptionNames[item.id] }}</span>
                                                    </div>
                                                    <div *ngIf="tableOptionDescriptions[item.id]" class="description" [attr.data-value]="tableTrackBy(null, item)">{{tableOptionDescriptions[item.id]}}</div>
                                                </div>
                                            </ng-template>
                                        </p-column>
                                    </p-dataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div></div>

                    <div class="new-form-600 image-container" *ngIf="selectedValueImage">
                        <div class="cursor-zoom-in" *ngIf="selectedValueImage" (click)="handleImageClick()">
                            <img class="image" [src]="selectedValueImage"/>
                            <span class="material-icons">search</span>
                        </div>
                    </div>
                </div>

                <div class="right-column">
                    <div>
                        <div class="catalog-description-header-left">{{ 'OFFER.DRAWING.SYSTEM_ADD_PRODUCT_DESCRIPTION_HEADER' | translate }}</div>
                        <div class="catalog-description-header-right">{{(tableSelection?.name) | multilanguageTranslate}}{{(tableSelection?.names) | multilanguageTranslate}}</div>
                    </div>
                    <div class="scrolled-desc">
                        <div class="new-form-600 no-padding">
                            <app-catalog-configuration-display (openStorageFile)="openStorageFile.emit($event)"
                                                               [description]="selectedValueDescription"
                            ></app-catalog-configuration-display>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="p-dialog-buttonpane">
                            <app-simple-button buttonId="save" label="{{ submitButtonLabel | translate }}"
                                               type="main-action" [size]="40"
                                               [disabled]="submitButtonDisabled || !tableSelection || !tableSelection.active"
                                               (onClick)="submit()"></app-simple-button>
                            <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                               (onClick)="closeWindow(true)"></app-simple-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-dialog>
</div>

<app-add-product-slider *ngIf="showSlider" [visible]="showSlider" (close)="showSlider = false"
                        [elements]="this.elements" [header]="sliderHeader"></app-add-product-slider>
