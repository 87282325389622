import {MessageSeverity} from '../../offers/message';
import {Pricing} from "./pricing/Pricing";

export class ResponseStatusFlags {
    errors: boolean;
    warnings: boolean;
    infos: boolean;

    constructor(errors = false, warnings = false, infos = false) {
        this.errors = errors;
        this.warnings = warnings;
        this.infos = infos;
    }
}

export class ResponseStatusHelper {

    public static updateValidationStatuses(validationStatus: ResponseStatusFlags, pricing: Pricing) {
        validationStatus.errors = Pricing.containsForValidation(pricing, MessageSeverity.ERROR) || Pricing.containsForValidation(pricing, MessageSeverity.BLOCKER);
        validationStatus.warnings = Pricing.containsForValidation(pricing, MessageSeverity.WARNING);
        validationStatus.infos = Pricing.containsForValidation(pricing, MessageSeverity.INFO);
    }

    public static updatePricingStatuses(validationStatus: ResponseStatusFlags, pricingStatus: ResponseStatusFlags,
                                        pricing: Pricing) {
        pricingStatus.errors = Pricing.containsForPricing(pricing, MessageSeverity.ERROR) || Pricing.containsForPricing(pricing, MessageSeverity.BLOCKER) || validationStatus.errors;
        pricingStatus.warnings = Pricing.containsForPricing(pricing, MessageSeverity.WARNING);
        pricingStatus.infos = Pricing.containsForPricing(pricing, MessageSeverity.INFO);
    }

    public static tabpanelHeaderStyle(status: ResponseStatusFlags): string {
        if (status.errors) {
            return 'errors-on-tab';
        } else if (status.warnings) {
            return 'warnings-on-tab';
        }
        return '';
    }
}
