import {DecorativeFillingType} from "../catalog-data/decorative-filling-type";
import {DecorativeFillingInterface} from "../catalog-data/decorative-filling-interface";
import {AreaSpecification} from "../drawing-data/AreaSpecification";
import {LineCutData} from "../drawing-data/LineCutData";
import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {CutsUtil} from "../utils/cutUtils";
import {FloatOps} from '../utils/float-ops';
import {ScalingPainter} from "./ScalingPainter";
import {PainterParams} from "./PainterParams";
import {ShadingCalculator} from "../ShadingCalculator";
import {WindowParams} from "./WindowParams";

class ExtensionTransformation {
    decorationNumber: number;
    positionNumber: number;
    value: number;

    constructor(decorationNumber: number,
                positionNumber: number,
                value: number) {
        this.decorationNumber = decorationNumber;
        this.positionNumber = positionNumber;
        this.value = value;
    }
}

class FillingLayers {
    decorations: number[][];
    glasses: number[][];
    extensionTransformations: ExtensionTransformation[];

    constructor(decorations: number[][],
                glasses: number[][],
                extensionTransformations?: ExtensionTransformation[]) {
        let bbox = this.findBBox(decorations, glasses);
        this.decorations = this.normalize(decorations, bbox);
        this.glasses = this.normalize(glasses, bbox);
        this.extensionTransformations = (extensionTransformations ? extensionTransformations : []);
    }

    private findBBox(decorations: number[][], glasses: number[][]): MinMaxXY {
        let bBox = {
            minX: Infinity,
            maxX: -Infinity,
            minY: Infinity,
            maxY: -Infinity
        };
        ([].concat.apply([], [...decorations, ...glasses]) as number[]).forEach((val, idx) => {
            if ((idx % 2) === 0) {
                bBox.minX = Math.min(bBox.minX, val);
                bBox.maxX = Math.max(bBox.maxX, val);
            } else {
                bBox.minY = Math.min(bBox.minY, val);
                bBox.maxY = Math.max(bBox.maxY, val);
            }
        });
        return bBox;
    }

    private normalize(elements: number[][], bbox: MinMaxXY): number[][] {
        let xDiff = (bbox.maxX + bbox.minX) / -2;
        let yDiff = (bbox.maxY + bbox.minY) / -2;
        let normalized = [];
        for (let el of elements) {
            normalized.push(el.map((val, idx) => val + (((idx % 2) === 0) ? xDiff : yDiff)));
        }
        return normalized;
    }
}

export class DecorativeFillingPainter {

    static readonly DECORATION_BORDER = 40;
    static readonly FALLBACK_DECORATIVE_FILLING: DecorativeFillingInterface = {
        id: undefined,
        type: DecorativeFillingType.TTEMP,
        minimalWidth: 500,
        minimalHeight: 500,
        glazingSurface: 0
    };

    static paint(glazingBeadPoints: number[], area: AreaSpecification, svg: Snap.Paper,
                 params: PainterParams,
                 decorativeFilling = DecorativeFillingPainter.FALLBACK_DECORATIVE_FILLING): Snap.Element[] {
        let elementsToBePainted: FillingLayers;
        let glazingBeadBbox = DrawingUtil.calculatePolygonTotalBoundingBox(glazingBeadPoints);
        switch (decorativeFilling.type) {
            case DecorativeFillingType.T1:
                elementsToBePainted = this.getT1();
                break;
            case DecorativeFillingType.T2:
                elementsToBePainted = this.getT2(glazingBeadBbox);
                break;
            case DecorativeFillingType.T3:
                elementsToBePainted = this.getT3();
                break;
            case DecorativeFillingType.T4:
                elementsToBePainted = this.getT4(glazingBeadBbox);
                break;
            case DecorativeFillingType.T5:
                elementsToBePainted = this.getT5();
                break;
            case DecorativeFillingType.T6:
                elementsToBePainted = this.getT6(glazingBeadBbox);
                break;
            case DecorativeFillingType.T7:
                elementsToBePainted = this.getT7();
                break;
            case DecorativeFillingType.T8:
                elementsToBePainted = this.getT8(glazingBeadBbox);
                break;
            case DecorativeFillingType.T9:
                elementsToBePainted = this.getT9(glazingBeadBbox);
                break;
            case DecorativeFillingType.T10:
                elementsToBePainted = this.getT10();
                break;
            case DecorativeFillingType.T11:
                elementsToBePainted = this.getT11();
                break;
            case DecorativeFillingType.T12:
                elementsToBePainted = this.getT12();
                break;
            case DecorativeFillingType.T13:
                elementsToBePainted = this.getT13();
                break;
            case DecorativeFillingType.T14:
                elementsToBePainted = this.getT14();
                break;
            default:
                elementsToBePainted = this.getDefualt();
                break;
        }
        if (area.filling.flipDecorativeFilling) {
            this.flipElements(elementsToBePainted);
        }
        let middlePoint = DrawingUtil.getPolygonCentroid(glazingBeadPoints);
        return [
            this.paintLayer(params, elementsToBePainted, glazingBeadPoints, glazingBeadBbox, svg, middlePoint,
                false, decorativeFilling.type, area.filling.flipDecorativeFilling),
            this.paintLayer(params, elementsToBePainted, glazingBeadPoints, glazingBeadBbox, svg, middlePoint, true,
                decorativeFilling.type, area.filling.flipDecorativeFilling)
        ];
    }

    private static paintLayer(params: PainterParams, elementsToBePainted: FillingLayers,
                              glazingBeadPoints: number[], glazingBeadBbox: MinMaxXY, svg: Snap.Paper,
                              middlePoint: number[], forGlasses: boolean, decorativeFillingType: DecorativeFillingType,
                              flipFilling: boolean): Snap.Element {
        let totalAreaWidth = glazingBeadBbox.maxX - glazingBeadBbox.minX;
        let elements = forGlasses ? elementsToBePainted.glasses : elementsToBePainted.decorations;
        let group = svg.g();
        let gradient;
        if (params.isShaded()) {
            gradient =
                svg.gradient(forGlasses ? ShadingCalculator.areaGlassesGradientString(glazingBeadPoints, params) :
                    ShadingCalculator.areaDecorationsGradientString(glazingBeadPoints, params));
        } else {
            group.addClass(forGlasses ? 'glass' : 'decorative-filling');
        }

        let shift;
        switch (decorativeFillingType) {
            case DecorativeFillingType.T1:
                shift = -(totalAreaWidth / 2 - 270 / 2 - (totalAreaWidth - 270) / 3.3);
                break;
            case DecorativeFillingType.T6:
            case DecorativeFillingType.T9:
                shift = -(totalAreaWidth / 2 - 130 - 300 / 2);
                break;
        }

        if (shift && flipFilling) {
            shift = -shift;
        }

        for (let i = 0; i < elements.length; i++) {
            let e = elements[i];
            e = this.alignToCenter(e, middlePoint);

            if (shift) {
                e = DrawingUtil.shiftArrayInXAxis(e, shift);
            }

            if (!forGlasses) {
                let appropriateExtensions = elementsToBePainted.extensionTransformations.filter(
                    t => t.decorationNumber === i);
                e = this.extend(e, appropriateExtensions);
            }
            if (this.validatePosition(e, glazingBeadPoints)) {
                if (params.isShaded()) {
                    if (forGlasses) {
                        group.add(ScalingPainter.path(svg, e, {fill: WindowParams.getBackgroundColor(params)}, params));
                    }
                    group.add(ScalingPainter.path(svg, e, {fill: gradient}, params));
                } else {
                    group.add(ScalingPainter.path(svg, e, {}, params));
                }
            }
        }
        return group;
    }

    private static getDefualt() {
        let glass = this.prepareRectangle(0, 0, 150, 150);
        let decoration = this.circumscribeRectangle(glass);
        return new FillingLayers([decoration], [glass]);
    }

    private static getT1(): FillingLayers {
        let w = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1600 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let glass = this.prepareRectangle(0, 0, w, h);
        let decoration = this.circumscribeRectangle(glass);
        return new FillingLayers([decoration], [glass], []);
    }

    private static getT2(glazingBeadBbox: MinMaxXY): FillingLayers {
        let w = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1400 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let decorationsDistance = 75 + DecorativeFillingPainter.DECORATION_BORDER;
        let glass = this.prepareRectangle(0, 0, w, h);
        let decoration = this.circumscribeRectangle(glass);
        let leftDecoration = this.prepareRectangle(-decorationsDistance - DecorativeFillingPainter.DECORATION_BORDER,
            0 - DecorativeFillingPainter.DECORATION_BORDER,
            DecorativeFillingPainter.DECORATION_BORDER, h);
        let rightDecoration = this.prepareRectangle(w + decorationsDistance, 0,
            DecorativeFillingPainter.DECORATION_BORDER, h + DecorativeFillingPainter.DECORATION_BORDER);
        let left1 = new ExtensionTransformation(1, 5, glazingBeadBbox.maxY);
        let left2 = new ExtensionTransformation(1, 7, glazingBeadBbox.maxY);
        let right1 = new ExtensionTransformation(2, 1, glazingBeadBbox.minY);
        let right2 = new ExtensionTransformation(2, 3, glazingBeadBbox.minY);
        let extensionsTransformations = [left1, left2, right1, right2];
        return new FillingLayers([decoration, leftDecoration, rightDecoration], [glass], extensionsTransformations);
    }

    private static getT3(): FillingLayers {
        let w = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1400 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let decorationsDistanceFromGlass = 180 + DecorativeFillingPainter.DECORATION_BORDER;
        let distanceBetweenDecorations = 100;
        let decorationH = 120;
        let decorations: number[][] = [];
        let glass = this.prepareRectangle(0, 0, w, h);
        let decoration = this.circumscribeRectangle(glass);
        let maxY = DrawingUtil.calculatePolygonTotalBoundingBox(decoration).maxY;
        decorations.push(decoration);
        let dec = (y: number) => decorations.push(
            this.prepareRectangle(w + decorationsDistanceFromGlass, y, 50, decorationH));
        for (let i = 0; i < 3; i++) {
            dec(-DecorativeFillingPainter.DECORATION_BORDER + (i * (decorationH + distanceBetweenDecorations)));
            dec(maxY - decorationH - (i * (decorationH + distanceBetweenDecorations)));
        }
        return new FillingLayers(decorations, [glass]);
    }

    private static getT4(glazingBeadBbox: MinMaxXY): FillingLayers {
        let w = 260 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1500 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let decorationsDistance = 80;
        let distanceBetweenGlasses = 255 + 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let squareH = 330 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let glasses: number[][] = [];
        let decorations: number[][] = [];
        for (let i = 0; i < 3; i++) {
            let glass = this.prepareRectangle(0, 0 + (i * (squareH + distanceBetweenGlasses)), w, squareH);
            glasses.push(glass);
            decorations.push(this.circumscribeRectangle(glass));
        }
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(decorations[0]);
        decorations.push(
            this.prepareRectangle(box.minX - decorationsDistance - DecorativeFillingPainter.DECORATION_BORDER, 0,
                DecorativeFillingPainter.DECORATION_BORDER, h));
        decorations.push(
            this.prepareRectangle(box.maxX + decorationsDistance, 0, DecorativeFillingPainter.DECORATION_BORDER, h));
        let extensionsTransformations = [...this.extendAll(3, glazingBeadBbox), ...this.extendAll(4, glazingBeadBbox)];
        return new FillingLayers(decorations, glasses, extensionsTransformations);
    }

    private static getT5(): FillingLayers {
        let w = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1400 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let glass = this.prepareRectangle(0, 0, w, h);
        let decoration = this.circumscribeRectangle(glass);
        return new FillingLayers([decoration], [glass]);
    }

    private static getT6(glazingBeadBbox: MinMaxXY): FillingLayers {
        let w = 150;
        let h = 1600;
        let decorationsDistance = 75;
        let distanceBetweenGlasses = 100;
        let squareH = 240;
        let glasses = [];
        let decorations = [];
        for (let i = 0; i < 5; i++) {
            let glass = this.prepareRectangle(0, 0 + (i * (squareH + distanceBetweenGlasses)), w, squareH);
            glasses.push(glass);
        }
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(glasses[0]);
        decorations.push(this.prepareRectangle(box.minX - decorationsDistance, 0, w + (2 * decorationsDistance), h));

        let extensionsTransformations = [...this.extendAll(0, glazingBeadBbox)];

        return new FillingLayers(decorations, glasses, extensionsTransformations);
    }

    private static getT7() {
        let width = 500;
        let height = 1550;
        let distanceBetweenGlasses = 100;
        let offset = distanceBetweenGlasses / 2;
        let topGlassDistance = 144 / Math.sqrt(2);
        let middleX = width / 2;
        let rimSize = DecorativeFillingPainter.DECORATION_BORDER;

        let totalRectangle = this.prepareRectangle(0, 0, width, height);

        let topCutLeft = new LineCutData([0, 0, 1, 1], 'bottom');
        let topCutRight = new LineCutData([width, 0, width - 1, 1], 'top');
        let topCutTop = new LineCutData([0, 0, width, 0], 'top');
        let topDecoration = CutsUtil.applyCuts(totalRectangle, [topCutLeft, topCutRight], 0);
        let topGlass = CutsUtil.applyCuts(totalRectangle, [topCutLeft, topCutRight, topCutTop], rimSize);

        let cutBottom = new LineCutData([0, height + offset, 1, height + offset], 'bottom');

        let leftCutTop = new LineCutData([0, 0, 1, 1], 'top');
        let leftCutMiddle = new LineCutData([middleX, 0, middleX, 1], 'top');
        let leftCutLeft = new LineCutData([-offset, 0, -offset, 1], 'bottom');
        let leftDecoration = CutsUtil.applyCuts(totalRectangle, [leftCutMiddle], offset);
        leftDecoration = CutsUtil.applyCuts(leftDecoration, [leftCutTop], topGlassDistance);
        let leftGlass = CutsUtil.applyCuts(totalRectangle, [leftCutMiddle, leftCutLeft, cutBottom], offset + rimSize);
        leftGlass = CutsUtil.applyCuts(leftGlass, [leftCutTop], topGlassDistance + rimSize);

        let rightCutTop = new LineCutData([width, 0, width - 1, 1], 'bottom');
        let rightCutMiddle = new LineCutData([middleX, 0, middleX, 1], 'bottom');
        let rightCutRight = new LineCutData([width + offset, 0, width + offset, 1], 'top');
        let rightDecoration = CutsUtil.applyCuts(totalRectangle, [rightCutMiddle], offset);
        rightDecoration = CutsUtil.applyCuts(rightDecoration, [rightCutTop], topGlassDistance);
        let rightGlass = CutsUtil.applyCuts(totalRectangle, [rightCutMiddle, rightCutRight, cutBottom], offset + rimSize);
        rightGlass = CutsUtil.applyCuts(rightGlass, [rightCutTop], topGlassDistance + rimSize);

        let decorations = [topDecoration, leftDecoration, rightDecoration];
        let glasses = [topGlass, leftGlass, rightGlass];
        return new FillingLayers(decorations, glasses);
    }

    private static getT8(glazingBeadBbox: MinMaxXY): FillingLayers {
        let w = 150;
        let h = 1600;
        let decorationsDistance = 75;
        let glass = this.prepareRectangle(0, 0, w, h);
        let decoration = this.prepareRectangle(-decorationsDistance, 0, w + (2 * decorationsDistance), h);
        let extensionsTransformations = [...this.extendAll(0, glazingBeadBbox)];
        return new FillingLayers([decoration], [glass], extensionsTransformations);
    }

    private static getT9(glazingBeadBbox: MinMaxXY): FillingLayers {
        let temp = this.getT8(glazingBeadBbox);
        return temp;
    }

    private static getT10(): FillingLayers {
        let w = 500 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1400;
        let step = DecorativeFillingPainter.DECORATION_BORDER;
        let heightDistanceBetweenDecorations = 50;
        let sinePoints = this.plotSine(w / 2, 0, h, h, (w / 7));
        let decorations = [this.prepareWave(sinePoints, step, step * -2), this.prepareWave(sinePoints, step, step * 6)];
        let glasses = [];
        let height = 240;
        let leftGlassSine = DrawingUtil.shiftArrayInXAxis(sinePoints, step);
        let rightGlassSine = DrawingUtil.shiftArrayInXAxis(sinePoints, 4 * step);
        let rightDecorativeSine = DrawingUtil.shiftArrayInXAxis(sinePoints, 5 * step);
        let y = 0;
        for (let i = 0; i < 5; i++) {
            decorations.push(this.prepareSineRectangle(sinePoints, rightDecorativeSine, y, height));
            glasses.push(this.prepareSineRectangle(leftGlassSine, rightGlassSine, y + step, height - (2 * step)));
            y += height + heightDistanceBetweenDecorations;
        }
        return new FillingLayers(decorations, glasses);
    }

    private static getT11(): FillingLayers {
        let offset = 150;
        let rimSize = DecorativeFillingPainter.DECORATION_BORDER;
        let width = 530 - offset;
        let leftHeight = 1350;
        let rightHeight = 1550;
        let yDifference = (rightHeight - leftHeight) / 2;
        let circularCutWidth = width / 2;
        let shift = 150;
        let leftGlassesWidth = 20;

        let decorationSinePoints = this.plotSine(offset + width, -yDifference, rightHeight - yDifference,
            rightHeight * 2, circularCutWidth / 2);
        let glassSinePoints = [];
        for (let i = 0; i < decorationSinePoints.length - 1; i += 2) {
            if (rimSize < decorationSinePoints[i + 1] && decorationSinePoints[i + 1] < leftHeight - rimSize) {
                glassSinePoints.push(decorationSinePoints[i]);
                glassSinePoints.push(decorationSinePoints[i + 1]);
            }
            if (decorationSinePoints[i + 1] <= rimSize && rimSize <= decorationSinePoints[i + 3]) {
                shift = ((decorationSinePoints[i] + decorationSinePoints[i + 2]) / 2) - (width - rimSize);
            }
        }
        glassSinePoints = DrawingUtil.shiftArrayInXAxis(glassSinePoints, -shift);

        let glass = [
            rimSize, rimSize,
            width - rimSize, rimSize,
            ...glassSinePoints,
            width - rimSize, leftHeight - rimSize,
            rimSize, leftHeight - rimSize
        ];
        let decoration = [
            0, 0,
            offset, 0,
            offset, -yDifference,
            offset + width, -yDifference,
            ...decorationSinePoints,
            offset + width, rightHeight - yDifference,
            offset, rightHeight - yDifference,
            offset, leftHeight,
            0, leftHeight
        ];

        let leftTopGlassSinePoints = this.plotSine(460, -yDifference, 700, rightHeight * 2, circularCutWidth / 2);
        let leftTopGlass = this.prepareWave(leftTopGlassSinePoints, leftGlassesWidth, 0);
        let topCutLeftTopGlass = new LineCutData([0, 0, leftGlassesWidth, 0], 'top');
        leftTopGlass = CutsUtil.applyCuts(leftTopGlass, [topCutLeftTopGlass], -20);

        let leftBottomGlassSinePoints = this.plotSine(420, -yDifference, 1370, rightHeight * 2, circularCutWidth / 2);
        let leftBottomGlass = this.prepareWave(leftBottomGlassSinePoints, leftGlassesWidth, 0);
        let topCutLeftBottomGlass = new LineCutData([0, 0, leftGlassesWidth, 0], 'top');
        leftBottomGlass = CutsUtil.applyCuts(leftBottomGlass, [topCutLeftBottomGlass], 600);

        return new FillingLayers([decoration], [glass, leftTopGlass, leftBottomGlass]);
    }

    private static getT12(): FillingLayers {
        let w = 240 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1360 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let distanceBetweenGlasses = 120;
        let squareH = 160;
        let glasses = [];
        for (let i = 0; i < 5; i++) {
            glasses.push(this.prepareRectangle(0, 0 + (i * (squareH + distanceBetweenGlasses)), w, squareH));
        }
        let decoration = this.prepareRectangle(0 - DecorativeFillingPainter.DECORATION_BORDER,
            0 - DecorativeFillingPainter.DECORATION_BORDER,
            w + (2 * DecorativeFillingPainter.DECORATION_BORDER), h + (2 * DecorativeFillingPainter.DECORATION_BORDER));
        return new FillingLayers([decoration], glasses);
    }

    private static getT13(): FillingLayers {
        let w = 260 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1400 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let glassHeight = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;

        return this.prepareSimpleColumn(3, w, h, glassHeight);
    }

    private static getT14(): FillingLayers {
        let w = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let h = 1600 - 2 * DecorativeFillingPainter.DECORATION_BORDER;
        let glassHeight = 270 - 2 * DecorativeFillingPainter.DECORATION_BORDER;

        return this.prepareSimpleColumn(5, w, h, glassHeight);
    }

    private static prepareSimpleColumn(glassCount: number, totalWidth, totalHeight, glassHeight): FillingLayers {
        let distanceBetweenGlasses = (totalHeight - (glassHeight * glassCount)) / (glassCount - 1);
        let glasses: number[][] = [];
        let decorations: number[][] = [];
        for (let i = 0; i < glassCount; i++) {
            let glass = this.prepareRectangle(0, 0 + (i * (glassHeight + distanceBetweenGlasses)), totalWidth,
                glassHeight);
            glasses.push(glass);
            decorations.push(this.circumscribeRectangle(glass));
        }
        return new FillingLayers(decorations, glasses);
    }

    private static extendAll(decNo: number, box: MinMaxXY) {
        return [
            new ExtensionTransformation(decNo, 1, box.minY),
            new ExtensionTransformation(decNo, 3, box.minY),
            new ExtensionTransformation(decNo, 5, box.maxY),
            new ExtensionTransformation(decNo, 7, box.maxY)];
    }

    private static prepareRectangle(x: number, y: number, w: number, h: number): number[] {
        x = FloatOps.round(x);
        y = FloatOps.round(y);
        w = FloatOps.round(w);
        h = FloatOps.round(h);
        return [
            x, y,
            x + w, y,
            x + w, y + h,
            x, y + h
        ];
    }

    private static circumscribeRectangle(r: number[]): number[] {
        return [
            r[0] - DecorativeFillingPainter.DECORATION_BORDER, r[1] - DecorativeFillingPainter.DECORATION_BORDER,
            r[2] + DecorativeFillingPainter.DECORATION_BORDER, r[3] - DecorativeFillingPainter.DECORATION_BORDER,
            r[4] + DecorativeFillingPainter.DECORATION_BORDER, r[5] + DecorativeFillingPainter.DECORATION_BORDER,
            r[6] - DecorativeFillingPainter.DECORATION_BORDER, r[7] + DecorativeFillingPainter.DECORATION_BORDER
        ];
    }

    private static prepareSineRectangle(leftSine: number[], rightSine: number[], topPos: number,
                                        height: number): number[] {
        let findIntesection = (sine: number[], pos: number): number[] => {
            let intersections = DrawingUtil.polygonLineIntersections(sine, [0, pos, 1, pos], true, true);
            if (intersections.length !== 1) {
                throw new Error("Cannot find sine intersection. ");
            }
            return [intersections[0].x, intersections[0].y];
        };
        let reverse = (arr: number[]) => {
            let reversed = [];
            for (let i = arr.length - 2; i > 0; i -= 2) {
                reversed.push(arr[i]);
                reversed.push(arr[i + 1]);
            }
            return reversed;
        };
        let ignoreObsoleteParts = (sine: number[], top: number, bot: number) => {
            let result = [];
            for (let i = 0; i < sine.length; i += 2) {
                if (top <= sine[i + 1] && sine[i + 1] <= bot) {
                    result.push(sine[i], sine[i + 1]);
                }
            }
            return result;
        };
        let botPos = topPos + height;
        let topLeft = findIntesection(leftSine, topPos);
        let topRight = findIntesection(rightSine, topPos);
        let botLeft = findIntesection(leftSine, botPos);
        let botRight = findIntesection(rightSine, botPos);
        let leftSinePart = ignoreObsoleteParts(leftSine, topPos, botPos);
        let rightSinePart = ignoreObsoleteParts(rightSine, topPos, botPos);
        return [...leftSinePart, ...botLeft, ...botRight, ...reverse(rightSinePart), ...topRight, ...topLeft];
    }

    private static prepareWave(sinePoints: number[], width: number, shift = 0): number[] {
        let wavePoints = DrawingUtil.shiftArrayInXAxis(sinePoints.slice(), shift);
        for (let i = sinePoints.length - 2; i >= 0; i -= 2) {
            wavePoints.push(sinePoints[i] + width + shift, sinePoints[i + 1]);
        }
        return wavePoints;
    }

    private static plotSine(xCenter: number, top: number, bottom: number, frequency: number,
                            amplitude: number): number[] {
        let x;
        let y = top;
        let steps = 32;
        let step = (bottom - top) / steps;
        let sinePoints = [];
        for (let i = 0; i <= steps; i++) {
            x = xCenter + (amplitude * -Math.sin(((y - top) / frequency) * Math.PI * 2));
            sinePoints.push(FloatOps.round(x), FloatOps.round(y));
            y += step;
        }
        return sinePoints;
    }

    private static alignToCenter(e: number[], middlePoint: number[]) {
        return e.map((val, idx) => middlePoint[idx % 2] + val);
    }

    private static extend(e: number[], extensionTransformation: ExtensionTransformation[]) {
        extensionTransformation.forEach(t => {
            e[t.positionNumber] = t.value;
        });
        return e;
    }

    private static validatePosition(e: number[], glazingBeadPoints: number[]): boolean {
        if (!DrawingUtil.isWholePolygonInsidePolygon(e, glazingBeadPoints)) {
            return false;
        }
        return true;
    }

    private static flipElements(elementsToBePainted: FillingLayers): void {
        elementsToBePainted.decorations = elementsToBePainted.decorations.map(e => DrawingUtil.flipXCoordinates(e));
        elementsToBePainted.glasses = elementsToBePainted.glasses.map(e => DrawingUtil.flipXCoordinates(e));
    }
}
