<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId" [ngClass]="{'new-form-radio-hide-label': !label}">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>

        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultOptionLabel let-option>
    {{ translateLabels ? (option.label | translate) : option.label }}
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    <div class="new-form-field-input-container">
        <div class="new-form-radio-option" *ngFor="let option of options; let i = index"
             [ngClass]="{ 'new-form-radio-option-horizontal': horizontal, 'new-form-radio-option-vertical': !horizontal }">
            <input #input type="radio" [attr.id]="inputId + i" [attr.name]="inputId" [disabled]="disabled"
                   [attr.value]="i" (change)="handleChange(i)" (focus)="handleFocus($event)"
                   (blur)="handleBlur($event)">
            <span (click)="handleChange(i)" [attr.id]="inputId + i + '_option'">
                <ng-container *ngTemplateOutlet="optionLabelTemplate; context: { $implicit: option, index: i }"></ng-container>
            </span>
        </div>
        <ng-container *ngIf="validationMessageKey != undefined">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
