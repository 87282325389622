<app-simple-button *ngIf="showToggleAllButton" buttonId="links-toggle-all-button"
                   [label]="toggleAllLabel | translate" type="action"
                   [disabled]="globallyDisabled" (onClick)="toggleAll()"></app-simple-button>

<div class="links-materials-container">
    <div *ngFor="let material of itemsByMaterial" class="links-container">

        <!-- SELECT ALL MATERIAL SYSTEMS -->
        <app-tristate-checkbox *ngIf="linkableEntity === LinkableEntity.WINDOW_SYSTEMS" [inputId]="material.materialName" [ngModel]="material.allSystemSelectionState"
                      [disabled]="globallyDisabled" (ngModelChange)="onSelectAllMaterialSystemsChange(material)">

            <ng-template pTemplate="label">
                <label [attr.for]="material.materialName" class="links-material-label"
                       (click)="onSelectAllMaterialSystemsChange(material)">{{ material.materialName | translate }}</label>
            </ng-template>
        </app-tristate-checkbox>

        <div *ngFor="let linkItem of material.linkableItems">
            <app-checkbox [inputId]="buildCheckboxId(linkItem)"
                          label="{{linkItem.symbol}} ({{linkItem.names[translate.currentLang]}})"
                          [disabled]="isItemLocked(linkItem.id) || globallyDisabled" [ngModel]="linkItem.selected"
                          (ngModelChange)="onItemSelectionChange(linkItem, $event)"></app-checkbox>
        </div>
    </div>
</div>
