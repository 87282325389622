import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Comment, PositionComment} from './comment';

@Injectable()
export class CommentService {

    private static readonly SERVICE_PATH = 'comments';

    constructor(private http: HttpClient) {
    }

    addComment(comment: Comment, offerId: number): Observable<Comment> {
        let params: { [param: string]: string; } = {};
        if (offerId != undefined) {
            params['offerId'] = '' + offerId;
        }
        return this.http.post<Comment>(`${CommentService.SERVICE_PATH}/${offerId != undefined ? 'offer' : 'complaint'}`, comment,
            {params: params});
    }

    getCommentsForOffer(offerId: number): Observable<Comment[]> {
        return this.http.get<Comment[]>(`${CommentService.SERVICE_PATH}/offer/${offerId}`);
    }

    getCommentsForAllOfferPositions(offerId: number): Observable<PositionComment[]> {
        return this.http.get<PositionComment[]>(`${CommentService.SERVICE_PATH}/offer/${offerId}/positions`);
    }

    getCommentsForOfferPosition(offerPositionId: number): Observable<Comment[]> {
        return this.http.get<Comment[]>(`${CommentService.SERVICE_PATH}/offerPosition/${offerPositionId}`);
    }

    getCommentsForComplaintPosition(complaintPositionId: number): Observable<Comment[]> {
        return this.http.get<Comment[]>(`${CommentService.SERVICE_PATH}/complaintPosition/${complaintPositionId}`);
    }

    getCommentsForComplaint(complaintId: number): Observable<Comment[]> {
        return this.http.get<Comment[]>(`${CommentService.SERVICE_PATH}/complaint/${complaintId}`);
    }

    changeReadFlag(complaintId: number): Observable<Comment> {
        return this.http.put<Comment>(`${CommentService.SERVICE_PATH}/changeReadFlag/${complaintId}`, undefined);
    }
}
