<ng-container *ngIf="active">
    <div class="wizard-step-content">
        <div *ngIf="displayHeader" class="wizard-step-header">
            <span>{{ label | translate }}</span>
        </div>

        <div *ngIf="displayHeader" class="wizard-step-header-spacer"></div>

        <ng-content></ng-content>
    </div>
</ng-container>
