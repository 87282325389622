import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AddonsService} from "../../../window-system/addons/addons.service";
import {MaterialService} from "../../../window-system/material/material.service";

@Injectable()
export class ConfigSidebarFieldImageService {

    constructor(
        private readonly addonService: AddonsService,
        private readonly materialService: MaterialService
    ) {
    }

    getAddonImage(addonId: number): Observable<string> {
        return this.addonService.getImageForItem(addonId);
    }

    getMaterialImage(materialId: number): Observable<string> {
        return this.materialService.getImageForItem(materialId);
    }
}
