export class FloatOps {

    private static EPS = 0.0000005; // comparison precision
    private static eps(a: number, b: number) { // eslint-disable-line @typescript-eslint/no-unused-vars
        // For a and b to be nearly equal, they must have nearly the same magnitude.
        // This means that we can ignore b since it either has the same magnitude or the comparison will fail anyway.
        const aa = Math.abs(a) + 1.0;
        if (!Number.isFinite(aa)) {
            return FloatOps.EPS;
        } else {
            return FloatOps.EPS * aa;
        }
    }

    // returns true if a == b
    static eq(a: number, b: number) {
        return (a === b) || (Math.abs(a - b) <= FloatOps.eps(a, b));
    }

    // returns true if a != b
    static ne(a: number, b: number) {
        return !FloatOps.eq(a, b);
    }

    // returns true if a > b
    static gt(a: number, b: number) {
        return a > b + FloatOps.eps(a, b);
    }

    // returns true if a >= b
    static ge(a: number, b: number) {
        return a > b - FloatOps.eps(a, b);
    }

    // returns true if a < b
    static lt(a: number, b: number) {
        return a < b - FloatOps.eps(a, b);
    }

    // returns true if a <= b
    static le(a: number, b: number) {
        return a < b + FloatOps.eps(a, b);
    }

    static round(value: number): number {
        if (value < 0) {
            return -Math.round(-value);
        }
        return Math.round(value);
    }
}
