import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {DeliveryList} from "../delivery-list";

@Component({
    selector: 'app-delivery-list-form',
    templateUrl: './delivery-list-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryListFormComponent {

    @Input()
    deliveryList: DeliveryList;

    @Input()
    validationErrors: ValidationErrors = {};
}
