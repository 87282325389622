<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.MAINTENANCE_SETTINGS.GENERAL' | translate }}</div>
    </div>
    <div class="new-form-400">
        <div class="new-form-row">
            <app-simple-button buttonId="changeMaintenanceStatusButton"
                               label="{{ (maintenanceActive ? 'SETTINGS.SECTION.MAINTENANCE_SETTINGS.DISABLE' : 'SETTINGS.SECTION.MAINTENANCE_SETTINGS.ENABLE') | translate }}"
                               type="main-action" [size]="40" (onClick)="toggleMaintenanceStatus()"></app-simple-button>
        </div>
        <ng-container *ngIf="permissions.canEditMotlawaConfiguration()">
            <div class="new-form-row">
                <h3>{{ 'ADMIN_PANEL.APPLICATION_STATUS.MOTLAWA_DB_CONNECTION_HEADER' | translate }}</h3>
            </div>
            <div class="new-form-row">
                <app-radio-button inputId="motlawaDbConnectionIdRadioButton"
                                  [options]="motlawaConnectionIds" [horizontal]="false"
                                  [(ngModel)]="motlawaDbConnectionId">
                    <ng-template pTemplate="option-label" let-option let-index="index">
                        <div class="motlawa-connection-row">
                            <div>
                                {{ option.label }}
                            </div>
                            <div>
                                <app-simple-button [buttonId]="'testConnectionButton_' + index"
                                                   [label]="'ADMIN_PANEL.APPLICATION_STATUS.MOTLAWA_DB_CONNECTION_TEST' | translate"
                                                   [type]="getConnectionStatusButtonType(option.value)" [size]="24"
                                                   [disabled]="isConnectionStatusCheckButtonDisabled(option.value)"
                                                   (onClick)="handleTestConnectionClick($event, option.value)"></app-simple-button>
                            </div>
                        </div>
                    </ng-template>
                </app-radio-button>
            </div>
            <div class="new-form-row">
                <app-simple-button buttonId="changeMotlawaDbConnectionButton"
                                   [label]="'GENERAL.SAVE' | translate" type="main-action" [size]="40"
                                   (onClick)="saveMotlawaDbConnection()"></app-simple-button>
            </div>
        </ng-container>
    </div>
</div>
