import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class RoofGlazingPackage implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    glasses: MultilanguageField;
    symbol: string;
    glassesQuantity: number;
    ug: number;
    active: boolean;
    sortIndex: number;
    description: { [propertyId: number]: number; };

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.glasses = new MultilanguageField();
        this.glassesQuantity = 1;
        this.active = true;
        this.sortIndex = 1;
        this.description = {};
    }
}
