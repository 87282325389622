import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {UrlInterceptor} from '../../../common/http/url.interceptor';
import {ApplicationResource, KnownApplicationResources} from './application-resource';

@Injectable()
export class ApplicationResourceService implements CrudService<ApplicationResource> {

    private static readonly API_URL = 'applicationResource';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    static getItemUrlByName(knownResource: KnownApplicationResources): string {
        return UrlInterceptor.getUrl(`${ApplicationResourceService.API_URL}/name/${KnownApplicationResources[knownResource]}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ApplicationResource>> {
        return undefined;
    }

    getItem(itemId: number): Observable<ApplicationResource> {
        return this.http.get(`${ApplicationResourceService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(ApplicationResource));
    }

    getItemContent(itemId: number): Observable<File> {
        return this.http.get(`${ApplicationResourceService.API_URL}/${itemId}`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(item: ApplicationResource, file?: File): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: ApplicationResource, file: File): Observable<number> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put<void>(`${ApplicationResourceService.API_URL}/${itemId}`, formData)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }
}
