<div *ngIf="description" class="catalog-description">
    <ng-container *ngFor="let row of description; let i = index">
        <div class="catalog-property-label" [ngClass]="'grid-row: ' + i">{{row.property.name | multilanguageTranslate}}</div>
        <div class="catalog-spacer" [ngClass]="'grid-row: ' + i"></div>
        <ng-container *ngIf="row.option.icon == null">
            <div [ngClass]="'grid-row: ' + i" class="catalog-option">
                <span [innerHTML]="row.option.value | multilanguageTranslate | appSafeValue: 'html'"></span>
            </div>
        </ng-container>
        <ng-container *ngIf="row.option.icon != null">
            <div class="catalog-option-icon" [ngClass]="'grid-row: ' + i"><img *ngIf="row.option.icon" [src]="row.option.icon | appSafeValue: 'url'" /></div>
            <div class="catalog-option-label" [ngClass]="'grid-row: ' + i"><span [innerHTML]="row.option.value | multilanguageTranslate | appSafeValue: 'html'"></span></div>
        </ng-container>
    </ng-container>
</div>
