export abstract class Validator {
    protected abstract getErrorCode(): string;

    abstract isValid(input: any): boolean;

    public validate(input: any): string {
        if (this.isValid(input)) {
            return undefined;
        } else {
            return this.getErrorCode();
        }
    }
}

export class NotNullValidator extends Validator {
    constructor() {
        super();
    }

    protected getErrorCode(): string {
        return "not_null";
    }

    isValid(input: any): boolean {
        return input != null;
    }
}

export class NotBlankValidator extends Validator {
    constructor() {
        super();
    }

    protected getErrorCode(): string {
        return 'not_empty';
    }

    isValid(input: any): boolean {
        if (typeof input !== 'string') {
            return true;
        }
        return input.trim() !== '';
    }
}

export class DecimalValidator extends Validator {
    constructor() {
        super();
    }

    protected getErrorCode(): string {
        return "not_decimal";
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        return !isNaN(Number(input.toString().replace(",", ".")));
    }
}

export class IntegerValidator extends Validator {
    constructor() {
        super();
    }

    protected getErrorCode(): string {
        return "not_int";
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        return Number.isInteger(Number(input));
    }
}

export class SizeValidator extends Validator {

    constructor(private readonly min: number,
                private readonly max: number,
                private readonly inclusiveMin: boolean,
                private readonly inclusiveMax: boolean) {
        super();
    }

    protected getErrorCode(): string {
        return 'not_in_range';
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        if (typeof input === 'string' || Array.isArray(input)) {
            if (input.length < this.min) {
                return false;
            }
            if (input.length > this.max) {
                return false;
            }
            if (!this.inclusiveMin && input.length === this.min) {
                return false;
            }
            if (!this.inclusiveMax && input.length === this.max) {
                return false;
            }
        }
        return true;
    }
}

export class WidthsValidator extends Validator {

    private errorCode: string;
    private elementValidator: RangeValidator;
    private single: boolean;

    constructor(elementFrom: number, elementTo: number, inclusiveFrom: boolean, inclusiveTo: boolean, single: boolean) {
        super();
        this.elementValidator = new RangeValidator(elementFrom, elementTo, inclusiveFrom, inclusiveTo);
        this.single = single;
    }

    protected getErrorCode(): string {
        return this.errorCode;
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        const format = /^(?:(?:[0-9]+)|(?:[0-9]+[,.][0-9]+)) ?- ?(?:(?:[0-9]+)|(?:[0-9]+[,.][0-9]+))$/;
        const splitted = this.single ? [input] : input.split(';');
        const validateElement = elem => {
            if (!format.test(elem.trim())) {
                this.errorCode = 'pattern_not_matched';
                return false;
            }
            const [min, max] = elem.split('-').map(limit => limit.trim());
            if (!this.elementValidator.isValid(min)) {
                this.errorCode = this.elementValidator.validate(min);
                return false;
            }
            if (!this.elementValidator.isValid(max)) {
                this.errorCode = this.elementValidator.validate(max);
                return false;
            }
            return true;
        };
        for (let elem of splitted) {
            if (!validateElement(elem)) {
                return false;
            }
        }
        return true;
    }
}

export class RangeValidator extends Validator {
    private from: number;
    private to: number;
    private errorCode: string;
    private inclusiveFrom: boolean;
    private inclusiveTo: boolean;

    constructor(from: number, to: number, inclusiveFrom: boolean, inclusiveTo: boolean) {
        super();
        this.from = from;
        this.to = to;
        this.inclusiveFrom = inclusiveFrom;
        this.inclusiveTo = inclusiveTo;
    }

    protected getErrorCode(): string {
        return this.errorCode;
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        let inputNumber = Number(input.toString().replace(",", "."));
        if (!isNaN(inputNumber)) {
            if (this.from != null && // if inclusive use <= else use <
                ((this.inclusiveFrom && inputNumber < this.from) ||
                    (!this.inclusiveFrom && inputNumber <= this.from))) {
                this.errorCode = "below_min";
                return false;
            } else if (this.to != null && // if inclusive use <= else use <
                ((this.inclusiveTo && inputNumber > this.to) ||
                    (!this.inclusiveTo && inputNumber >= this.to))) {
                this.errorCode = "over.max";
                return false;
            } else {
                return true;
            }
        } else {
            return null;
        }
    }
}

export class DateRangeValidator extends Validator {
    private from: Date;
    private to: Date;
    private errorCode: string;
    private inclusiveFrom: boolean;
    private inclusiveTo: boolean;

    constructor(from: Date, to: Date, inclusiveFrom: boolean, inclusiveTo: boolean) {
        super();
        this.from = from;
        this.to = to;
        this.inclusiveFrom = inclusiveFrom;
        this.inclusiveTo = inclusiveTo;
    }

    protected getErrorCode(): string {
        return this.errorCode;
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        if (!(input instanceof Date)) {
            return false;
        }
        if (this.from != null && // if inclusive use <= else use <
            ((this.inclusiveFrom && input < this.from) ||
                (!this.inclusiveFrom && input <= this.from))) {
            this.errorCode = "below_min";
            return false;
        } else if (this.to != null && // if inclusive use <= else use <
            ((this.inclusiveTo && input > this.to) ||
                (!this.inclusiveTo && input >= this.to))) {
            this.errorCode = "over.max";
            return false;
        }
        return true;
    }
}

export class ScopeValidator extends Validator {

    private readonly decimalPattern = '\\d+(?:\\.|,)?\\d*';
    private readonly negativeDecimalPattern = '-?\\d+(?:\\.|,)?\\d*';
    private readonly decimalInBracketsPattern = `\\(${this.negativeDecimalPattern}\\)`;
    private readonly rangeLimitValuePattern = `(${this.decimalPattern}|${this.decimalInBracketsPattern})`;

    private errorCode: string;
    private valid = true;

    constructor() {
        super();
    }

    private parse(scope: string) {
        const matchedLimits = this.getMatchedLimits(scope);
        if (!matchedLimits) {
            this.valid = false;
            this.errorCode = "invalid_scope";
            return;
        }
        const {from, to} = matchedLimits;
        if (isNaN(from) || isNaN(to)) {
            this.valid = false;
            this.errorCode = "invalid_scope";
            return;
        }
        if (from > to) {
            this.valid = false;
            this.errorCode = "invalid_scope";
            return;
        }
        this.valid = true;
    }

    public getMatchedLimits(string: string): { from: number, to: number } {
        if (this.getSingleValueRegexp().test(string.trim())) {
            const value = this.prepareRangeProperty(string.trim());
            return {
                from: value,
                to: value
            };
        }

        const rangeRegexp = this.getRangeRegexp();
        const matchedGroups = string.match(rangeRegexp);
        if (!matchedGroups || matchedGroups.length !== 3) {
            return null;
        }
        return {
            from: this.prepareRangeProperty(matchedGroups[1]),
            to: this.prepareRangeProperty(matchedGroups[2])
        };
    }

    private prepareRangeProperty(value: string) {
        return +(value.replace("(", "").replace(")", "").replace(',', "."));
    }

    private getSingleValueRegexp() {
        return new RegExp(`^(${this.rangeLimitValuePattern}|${this.negativeDecimalPattern})$`);
    }

    private getRangeRegexp(): RegExp {
        return new RegExp(`^${this.rangeLimitValuePattern}-${this.rangeLimitValuePattern}$`);
    }

    protected getErrorCode(): string {
        return this.errorCode;
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        this.parse(input);
        return this.valid;
    }
}

class CustomValidator extends Validator {

    constructor(private validator: ((value: any) => string | undefined)) {
        super();
    }

    protected getErrorCode(): string {
        return '';
    }

    isValid(input: any): boolean {
        if (input == undefined) {
            return true;
        }
        return this.validator(input) == undefined;
    }

    validate(input: any): string {
        return this.validator(input);
    }
}

export class MultiValidator extends Validator {
    private validators: Validator[] = [];
    private errorString: string;
    private errorCode;

    private constructor(errorString: string) {
        super();
        this.errorString = errorString;
    }

    public static of(errorString: string): MultiValidator {
        return new MultiValidator(errorString);
    }

    public withIntegerValidator(): MultiValidator {
        this.validators.push(new IntegerValidator());
        return this;
    }

    public withDecimalValidator(): MultiValidator {
        this.validators.push(new DecimalValidator());
        return this;
    }

    public withSizeValidator(min = -Infinity, max = Infinity, inclusiveMin = true, inclusiveMax = true): MultiValidator {
        this.validators.push(new SizeValidator(min, max, inclusiveMin, inclusiveMax));
        return this;
    }

    // inclusive by default
    public withRangeValidator(from: number, to: number, inclusiveFrom = true, inclusiveTo = true): MultiValidator {
        this.validators.push(new RangeValidator(from, to, inclusiveFrom, inclusiveTo));
        return this;
    }

    public withDateRangeValidator(from: Date, to: Date, inclusiveFrom = true, inclusiveTo = true): MultiValidator {
        this.validators.push(new DateRangeValidator(from, to, inclusiveFrom, inclusiveTo));
        return this;
    }

    public withWidthsValidator(elementFrom: number, elementTo: number, inclusiveFrom = true, inclusiveTo = true): MultiValidator {
        this.validators.push(new WidthsValidator(elementFrom, elementTo, inclusiveFrom, inclusiveTo, false));
        return this;
    }

    public withSingleWidthValidator(elementFrom: number, elementTo: number, inclusiveFrom = true, inclusiveTo = true): MultiValidator {
        this.validators.push(new WidthsValidator(elementFrom, elementTo, inclusiveFrom, inclusiveTo, true));
        return this;
    }

    public withNotNullValidator() {
        this.validators.push(new NotNullValidator());
        return this;
    }

    public withNotBlankValidator() {
        this.validators.push(new NotBlankValidator());
        return this;
    }

    public withCustomValidator(validator: ((value: any) => string | undefined)) {
        this.validators.push(new CustomValidator(validator));
        return this;
    }

    protected getErrorCode(): string {
        return this.errorCode;
    }

    private recordError(code: string): boolean {
        this.errorCode = this.errorString + "." + code;
        return false;
    }

    isValid(input: any): boolean {
        for (let validator of this.validators) {
            if (!validator.isValid(input)) {
                return this.recordError(validator.validate(input));
            }
        }
        return true;
    }
}

export class NotEmptyValidator extends Validator {
    private errorString: string;

    private constructor(errorString: string) {
        super();
        this.errorString = errorString;
    }

    public static of(errorString: string): NotEmptyValidator {
        return new NotEmptyValidator(errorString);
    }

    isValid(input: string): boolean {
        return input != null && input.length > 0;
    }

    protected getErrorCode(): string {
        return `${this.errorString}.not_empty`;
    }
}
