<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'DELIVERY_LIST.FORM.NAME' | translate }}" [maxlength]="100"
                        [(validationMessageKey)]="validationErrors['name']" [required]="true"
                        [(ngModel)]="deliveryList.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="dispatchDate" label="{{ 'DELIVERY_LIST.FORM.DISPATCH_DATE' | translate }}" [utc]="true"
                      [(validationMessageKey)]="validationErrors['dispatchDate']" [required]="true" [inline]="true"
                      [(ngModel)]="deliveryList.dispatchDate"></app-calendar>
    </div>
</div>
