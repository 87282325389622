import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Table} from 'primeng/table';
import {timer} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from '../common/crud-common/paginable.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../common/DatatableHelper';
import {ApplicationInfo} from "./application-info";
import {ApplicationInfoService} from "./application-info.service";
import {ApplicationServerStatus} from './application-server-status';
import {ApplicationServerStatusService} from './application-server-status.service';

@Component({
    selector: 'app-application-info',
    templateUrl: './application-info.component.html',
    styleUrls: ['./application-info.css'],
    providers: [ApplicationInfoService, ApplicationServerStatusService]
})
export class ApplicationInfoComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    @ViewChild('table', {static: true})
    table: Table;

    backendInfo: ApplicationInfo;
    frontendInfo: ApplicationInfo;
    frontendVersion: string;

    applicationServerStatuses: ApplicationServerStatus[];
    selectedItem: ApplicationServerStatus;

    private readonly applicationInfoService: ApplicationInfoService;
    private readonly applicationServerStatusService: ApplicationServerStatusService;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'ApplicationInfoComponent', false);
        this.applicationInfoService = injector.get(ApplicationInfoService);
        this.applicationServerStatusService = injector.get(ApplicationServerStatusService);
        this.frontendVersion = environment.version;
    }

    ngOnInit() {
        super.ngOnInit();
        this.applicationInfoService.getBackendInfo().subscribe({
            next: applicationInfo => {
                this.backendInfo = applicationInfo;
                this.changeDetector.markForCheck();
            }
        });
        this.applicationInfoService.getFrontendInfo().subscribe({
            next: response => {
                this.frontendInfo = this.mapFrontendApplicationInfo(response);
                this.changeDetector.markForCheck();
            },
            error: error => {
                if (error.status === 404) {
                    console.info('Frontend application info not available');
                } else {
                    console.error(error);
                }
            }
        });
    }

    showDialogToAdd(): void {
        throw new Error("Method not implemented.");
    }

    submit(): void {
        throw new Error("Method not implemented.");
    }

    onRowSelect(event: { data: any; } & KeepSelectedItemEventParams): void {
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.applicationServerStatusService.findAll()
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe(statuses => {
                this.applicationServerStatuses = statuses;
                this.changeDetector.markForCheck();
                const maxPing = statuses.reduce((maxDate: Date, status: ApplicationServerStatus) => {
                    if (status.lastPing == undefined) {
                        return maxDate;
                    }
                    if (maxDate == undefined) {
                        return status.lastPing;
                    }
                    return maxDate < status.lastPing ? status.lastPing : maxDate;
                }, undefined);
                const nextRefresh = 5 * 60 * 1000
                    + (maxPing != undefined
                        ? maxPing.getTime() - new Date().getTime()
                        : 0);
                timer(nextRefresh > 0 ? nextRefresh : 5 * 60 * 1000)
                    .pipe(takeUntil(this.componentDestroyed))
                    .subscribe(() => {
                        if (this.table != undefined) {
                            this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
                        }
                    });
            });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    rowTrackById = (index: number, item: ApplicationServerStatus): string => {
        return `${item.id}`;
    }

    isOnline(status: ApplicationServerStatus): boolean {
        if (status.lastPing == undefined) {
            return false;
        }
        // last ping was less than 10 minutes ago
        return status.lastPing.getTime() + 10 * 60 * 1000 > new Date().getTime();
    }

    deleteSelectedApplicationServer(status: ApplicationServerStatus): void {
        const index = this.applicationServerStatuses.indexOf(status);
        if (index >= 0) {
            this.applicationServerStatuses.splice(index, 1);
            this.applicationServerStatuses = [...this.applicationServerStatuses];
        }
        this.applicationServerStatusService.deleteStatus(status.id).subscribe();
    }

    private mapFrontendApplicationInfo(rawJson: any) {
        let applicationInfo = new ApplicationInfo();
        applicationInfo.version = rawJson['git.build.version'];
        applicationInfo.branch = rawJson['git.branch'];
        applicationInfo.describe = rawJson['git.commit.id.describe'];
        applicationInfo.commitId = rawJson['git.commit.id'];
        applicationInfo.commitIdAbbrev = rawJson['git.commit.id.abbrev'];
        applicationInfo.buildUserName = rawJson['git.build.user.name'];
        applicationInfo.buildUserEmail = rawJson['git.build.user.email'];
        applicationInfo.buildTime = rawJson['git.build.time'];
        applicationInfo.commitUserName = rawJson['git.commit.user.name'];
        applicationInfo.commitUserEmail = rawJson['git.commit.user.email'];
        applicationInfo.commitMessageFull = rawJson['git.commit.message.full'];
        applicationInfo.commitMessageShort = rawJson['git.commit.message.short'];
        applicationInfo.commitTime = rawJson['git.commit.time'];
        return applicationInfo;
    }
}
