import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {saveAs} from "file-saver";
import {forkJoin, Observable} from "rxjs";

export class CSVColumn {
    label: string;
    field: string;
}

export interface CSVDataSource {
    getExportData(): Observable<object[]>;
    getExportColumns(): Observable<CSVColumn[]>;
}

export interface CSVExportable extends CSVDataSource {
    exportCSV(filename: string): void;
}

@Injectable()
export class ExportComponent {

    constructor(public translate: TranslateService) {
    }

    public exportCSV(dataSource: CSVDataSource, filename: string) {
        forkJoin({
            columns: dataSource.getExportColumns(),
            rows: dataSource.getExportData(),
            filename: this.translate.get(filename)
        }).subscribe({
            next: data => {
                let type = "data:text/csv;charset=utf-8;";
                let csv = "";
                let columnDelimiter = ';';
                let lineDelimiter = '\n';
                let columnOptions = data.columns;
                filename = data.filename + ".csv";

                let headers: string[] = [];
                columnOptions.forEach(columnOption =>
                    headers.push(this.getValue(columnOption.label)));
                csv += headers.join(columnDelimiter) + lineDelimiter;

                data.rows.forEach(row => {
                    let values = columnOptions.map(columnOption => this.getValue(this.getProperty(row, columnOption.field)));
                    csv += values.join(columnDelimiter) + lineDelimiter;
                });
                const BOM = "\uFEFF";
                saveAs(new Blob([BOM + csv], {type: type}), filename, true);
            },
            error: error => {
                console.error('CSV export error:', error);
            }
        });
    }

    private getProperty(root: object, path: string): unknown {
        let value = null;
        let fields = path.split('.');
        fields.forEach(field => {
            if (value == null) {
                value = root[field];
            } else {
                value = value[field];
            }
        });
        return value;
    }

    private getValue(value: unknown | undefined): string {
        if (value == null) {
            return "";
        }
        return value.toLocaleString()
            .replace(/\n/g, " ")
            .replace(/\r/g, " ")
            .replace(/\t/g, " ");
    }
}
