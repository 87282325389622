import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";
import {ConfigDescriptorLevel} from "../../../common/enums/ConfigDescriptorLevel.enum";

export class ConfigTypeDescriptor implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    sortIndex: number;
    descriptorLevel: ConfigDescriptorLevel;

    static fromJSON(jsonObject): ConfigTypeDescriptor {
        const descriptor = new ConfigTypeDescriptor();
        descriptor.id = jsonObject.id;
        descriptor.name = MultilanguageField.fromJSON(jsonObject.name);
        descriptor.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        descriptor.symbol = jsonObject.symbol;
        descriptor.sortIndex = jsonObject.sortIndex;
        descriptor.descriptorLevel = jsonObject.descriptorLevel;
        return descriptor;
    }
}
