<div class="dialog-window">
    <p-dialog #dialog
              [focusOnShow]="false"
              [modal]="true"
              [resizable]="false"
              [visible]="displayDialog"
              (onShow)="afterShow()"
              (onHide)="dialogClosed.emit()">
        <p-header>
            <div class="p-dialog-title">{{ getDialogHeader() | translate }}</div>
        </p-header>
        <div class="scrolled-dialog">
            <div class="flex-column">
                <div class="new-form-600">
                    <div class="new-form-row" *ngFor="let warning of warnings">
                        <span>{{ warning }}</span>
                    </div>
                    <div class="new-form-row flex-justify-center">
                        <p-dataTable #dt
                                     selectionMode="single"
                                     [responsive]="true"
                                     [rowTrackBy]="businessTypesTrackBy"
                                     [value]="filteredWindowBusinessTypes"
                                     [(selection)]="selectedBusinessType"
                                     (onRowDblclick)="addWindowSystem($event.data)"
                                     (onRowSelect)="selectBusinessType($event.data)">
                            <p-column field="tableField" [header]="'PRICE_TABLES.FORM.TYPE' | translate" [sortable]="false" [filter]="false">
                                <ng-template let-item="rowData" pTemplate="body">
                                    <div class="row">
                                        <img *ngIf="businessTypeIcons[item.id]" class="image" [src]="businessTypeIcons[item.id]" />
                                        <div *ngIf="businessTypeNames[item.id]" class="unselectable name" [attr.data-value]="businessTypesTrackBy(null, item)">
                                            <span>{{ businessTypeNames[item.id] }}</span>
                                        </div>
                                        <div *ngIf="businessTypeDescriptions[item.id]" class="description" [attr.data-value]="businessTypesTrackBy(null, item)">{{businessTypeDescriptions[item.id]}}</div>
                                    </div>
                                </ng-template>
                            </p-column>
                        </p-dataTable>
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane above-overlay">
                <app-input-number *ngIf="canChangeWindowCount" inputId="windowCountInput"
                                  label="{{ (addVertically ? 'OFFER.DRAWING.SYSTEM_ADD_PRODUCT_COUNT_VERTICAL' :
                                'OFFER.DRAWING.SYSTEM_ADD_PRODUCT_COUNT_HORIZONTAL') | translate }}" [min]="1" [max]="9"
                                  [maxlength]="1" [step]="1" [allowOnlyIntegers]="true" [ngModel]="windowCount"
                                  (ngModelChange)="handleWindowCountChange($event)"></app-input-number>
                <div class="dialog-footer-error-message" *ngIf="validationErrors['selection']">
                    {{validationErrors['selection'] | translate}}
                </div>
                <app-simple-button buttonId="saveWindowSystemAndType" label="{{ getSubmitButtonLabel() | translate }}"
                                   type="main-action" [size]="40" [disabled]="false"
                                   (onClick)="addWindowSystem(selectedBusinessType)"></app-simple-button>
                <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="dialogClosed.emit()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
