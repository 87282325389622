import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ColorTarget, ColorType} from '../../../ColorType';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, ColorField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ColorFieldUsage} from "../catalog-field-usage";
import {ColorHelperInterface} from "../catalog-field-usage-helper-interfaces";
import {GateSystemService} from '../gate-system/gate-system.service';
import {LinkableEntities} from '../link-selection/link-selection.component';
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {Color} from './color';
import {ColorService} from './color.service';
import {WebshopColorOptions} from './webshop-color-options';
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {ConfigSystemService} from "../config-system/config-system.service";

@Component({
    selector: 'app-color',
    templateUrl: './color.component.html',
    styleUrls: ['./color.component.css', '../../shared-styles.css'],
    providers: [ColorService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService, GateSystemService, ConfigSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorComponent extends SingleSystemCheckboxCrudComponent<Color, ColorService> implements OnInit, ColorHelperInterface {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    readonly entranceSystemTypeGroups = [ProductTypeGroup.ENTRANCE];

    LinkableEntity = LinkableEntities;

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS',
        ENTRANCE_SYSTEMS: 'ENTRANCE_SYSTEMS',
        GATE_SYSTEMS: 'GATE_SYSTEMS',
        CONFIG_SYSTEMS: 'CONFIG_SYSTEMS',
    };

    private readonly gateSystemService: GateSystemService;
    private readonly configSystemService: ConfigSystemService;

    @Input()
    colorTarget: ColorTarget;

    allowedColorTypes: ColorType[];

    filterTypes: Observable<SelectItem[]>;
    filterMaterials: Observable<SelectItem[]>;

    item: Color;
    addonMaterialColorType = 'ADDON_MATERIAL';
    validateDataStep: WizardStepValidator;
    availableColors: SelectItem[] = [];

    webshopTexture: File;

    gateSystems: ItemForCatalogLinking[];
    selectedGateSystems: number[];

    configSystems: ItemForCatalogLinking[];
    selectedConfigSystems: number[];

    editPermits: FieldLimitation[] = [];
    fieldUsage: ColorFieldUsage;
    CatalogTab = CatalogTab;
    ColorField = ColorField;

    @ViewChild('dt') datatable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, ColorService, 'COLOR', 'Color');
        this.gateSystemService = injector.get(GateSystemService);
        this.configSystemService = injector.get(ConfigSystemService);
        this.item = new Color();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new ColorFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.allowedColorTypes = this.colorTarget.colorTypes;
        forkJoin({
            gateSystems: this.gateSystemService.getGatesForCatalogLinking(),
            configSystems: this.configSystemService.getConfigAddonsForCatalogLinking(),
        }).subscribe(data => {
            this.gateSystems = data.gateSystems;
            this.configSystems = data.configSystems;
            this.changeDetector.markForCheck();
        });
        this.initTableFilters();
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.COLORS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    loadItemsLazy(event: LazyLoadEvent) {
        let typeFilter = event.filters['type'];
        if (typeFilter == undefined || !typeFilter.value) {
            event.filters['type'] = {value: `${this.allowedColorTypes.join()}`};
        }
        super.loadItemsLazy(event);
    }

    isGeneralColor() {
        return this.colorTarget.target === 'General';
    }

    isConfigColor() {
        return this.colorTarget.target === 'Config';
    }

    protected getApiUrl(): string {
        return 'colors';
    }

    clearAllCheckboxes() {
        super.clearAllCheckboxes();
        this.selectedGateSystems = [];
        this.selectedConfigSystems = [];
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    getItemWithImage(colorId: number) {
        forkJoin({
            item: this.itemService.getItem(colorId),
            image: this.itemService.getImageAsFile(colorId),
            webshopTexture: this.itemService.getWebshopTexture(colorId),
            linkedWindowSystems: this.getLinkedWindowSystems(colorId),
            allGrillColors: this.itemService.getAllActiveGrillColors(),
            linkedGateSystems: this.itemService.getLinkedGateSystems(colorId),
            linkedConfigSystems: this.itemService.getLinkedConfigSystems(colorId),
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.image;
                this.webshopTexture = data.webshopTexture;
                this.selectedWindowSystems = data.linkedWindowSystems;
                this.selectedGateSystems = data.linkedGateSystems;
                this.selectedConfigSystems = data.linkedConfigSystems;

                if (this.item.webshopHex != undefined) {
                    this.item.selectedWebshopColorOption = WebshopColorOptions.HEX;
                } else if (this.webshopTexture != undefined) {
                    this.item.selectedWebshopColorOption = WebshopColorOptions.TEXTURE;
                }

                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.availableColors = data.allGrillColors.data.map(c => {
                    return {label: c.names[this.translate.currentLang], value: c.id};
                });
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image', 'webshopTexture');
        if (!this.item.names[this.userLang]) {
            this.validationErrors[`names[${this.userLang}]`] = `error.colorDto.names[${this.userLang}].not_empty`;
        }
        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.colorDto.symbol.not_empty';
        }
        if (!this.item.type) {
            this.validationErrors['type'] = 'error.colorDto.type.not_empty';
        } else if (this.item.type === ColorType.RAL_PALETTE_CUSTOM || this.item.type === ColorType.NCS_PALETTE_CUSTOM) {
            if (!/^#[0-9A-Fa-f]{6}$/.test(this.item.ralHex)) {
                this.validationErrors['ralHex'] = 'error.colorDto.ralHex.pattern_not_matched';
            }
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.colorDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.item.selectedWebshopColorOption == undefined
            && this.item.type !== ColorType.ADDON_MATERIAL && this.item.type !== ColorType.GATE_CORE) {
            this.validationErrors['selectedWebshopColorOption'] = 'error.colorDto.selectedWebshopColorOption.not_null';
        }

        if (this.item.selectedWebshopColorOption === WebshopColorOptions.HEX || this.item.type === ColorType.GATE_CORE) {
            if (this.item.webshopHex == undefined) {
                this.validationErrors['webshopHex'] = 'error.colorDto.webshopHex.not_empty';
            } else if (!/^#[0-9A-Fa-f]{6}$/.test(this.item.webshopHex)) {
                this.validationErrors['webshopHex'] = 'error.colorDto.webshopHex.pattern_not_matched';
            }
        }

        if (this.item.selectedWebshopColorOption === WebshopColorOptions.TEXTURE &&
            (this.webshopTexture == undefined || this.webshopTexture.size === 0)) {
            this.validationErrors['webshopTexture'] = 'error.colorDto.webshopTexture.not_empty';
        }

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            this.deactivateWindowSystemsForConfigurableAddonColors();
            let observable: Observable<number>;
            const editLinksAfterSave = (entityId: number) => {
                return forkJoin({
                    windowSystemLinks: this.itemService.editLinks(this.getApiUrl(), entityId, this.prepareSystemIdsForRequest()),
                    gateSystemLinks: this.itemService.editGateSystemLinks(entityId, this.selectedGateSystems),
                    configSystemLinks: this.itemService.editConfigSystemLinks(entityId, this.selectedConfigSystems),
                }).pipe(
                    map(() => entityId)
                );
            };
            if (this.copyMode) {
                observable = this.itemService.copyItem(this.selectedItem.id, this.item, this.file, this.webshopTexture)
                    .pipe(mergeMap(editLinksAfterSave));
            } else {
                observable = this.itemService.saveItem(this.item, this.file, this.webshopTexture).pipe(mergeMap(editLinksAfterSave));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    getNewItem(): Color {
        return new Color();
    }

    initTableFilters(): void {
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];

        this.filterTypes = this.translatedSelectItemService.buildSortedDropdown(this.allowedColorTypes, 'COLOR_TYPES.', '');
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    private deactivateWindowSystemsForConfigurableAddonColors(): void {
        if (this.item.type === this.addonMaterialColorType) {
            this.selectedWindowSystems = [];
        }
    }

    onWebshopTextureChange(newTexture) {
        this.webshopTexture = newTexture;
        if (!newTexture) {
            this.webshopTexture = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    resetWebshopTexture(): void {
        this.webshopTexture = new File([], null);
        this.validationErrors['webshopTexture'] = undefined;
    }

    hasWindowSystemLinks() {
        return this.item.type !== ColorType.ADDON_MATERIAL && this.item.type !== ColorType.GATE_CORE;
    }

    hasGateSystemLinks() {
        return this.item.type !== ColorType.ADDON_MATERIAL;
    }

    hasConfigSystemLinks() {
        return this.colorTarget.target === 'Config';
    }

    handleTypeChange(type: ColorType) {
        if (type === ColorType.ADDON_MATERIAL) {
            this.selectedWindowSystems = [];
            this.selectedGateSystems = [];
        }
    }

    isPaletteCustom(): boolean {
        return false;
    }

    isTextureSelected(): boolean {
        return false;
    }

    isWebshopHexSelected(): boolean {
        return false;
    }

    shouldDisableCoreCheckbox(): boolean {
        return false;
    }

    shouldForceCoreSelection(): boolean {
        return false;
    }
}
