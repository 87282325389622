<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(GrillField.NAME)"
                            inputId="name" label="{{ 'GRILL.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [disabled]="fieldUsage.disabled(GrillField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(GrillField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'GRILL.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GrillField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(GrillField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'GRILL.FORM.SYMBOL' | translate }}"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [disabled]="fieldUsage.disabled(GrillField.SYMBOL)"
                        [(ngModel)]="item.symbol" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.TYPE)">
        <app-select inputId="type" label="{{ 'GRILL.FORM.TYPE.HEADER' | translate }}"
                    [(validationMessageKey)]="validationErrors['type']" [options]="types" [required]="true"
                    [disabled]="fieldUsage.disabled(GrillField.TYPE)"
                    [ngModel]="item.type" (ngModelChange)="onTypeChange($event)"></app-select>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.WIDTH)">
        <app-input-number inputId="width" label="{{ 'GRILL.FORM.WIDTH' | translate }}"
                          [(validationMessageKey)]="validationErrors['width']" [min]="1" [max]="999"
                          [disabled]="fieldUsage.disabled(GrillField.WIDTH)"
                          [required]="true" [(ngModel)]="item.width"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.MAX_ALLOWED_SEGMENT_LENGTH)">
        <app-input-number inputId="maxAllowedSegmentLength" label="{{ 'GRILL.FORM.MAX_ALLOWED_SEGMENT_LENGTH' | translate }}"
                          [(validationMessageKey)]="validationErrors['maxAllowedSegmentLength']" [min]="1" [max]="9999"
                          [disabled]="fieldUsage.disabled(GrillField.MAX_ALLOWED_SEGMENT_LENGTH)"
                          [required]="true" [(ngModel)]="item.maxAllowedSegmentLength"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.MIN_FRAME_WIDTH)">
        <app-input-number inputId="minFrameWidth" label="{{ 'GRILL.FORM.MIN_FRAME_WIDTH' | translate }}"
                          [(validationMessageKey)]="validationErrors['minFrameWidth']" [min]="1" [max]="9999"
                          [disabled]="fieldUsage.disabled(GrillField.MIN_FRAME_WIDTH)"
                          [required]="true" [(ngModel)]="item.minFrameWidth"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.ANGLED)">
        <app-checkbox inputId="angled" label="{{ 'GRILL.FORM.ANGLED' | translate }}"
                      [disabled]="fieldUsage.disabled(GrillField.ANGLED)"
                      [(ngModel)]="item.angled"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(GrillField.CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS)">
        <app-checkbox inputId="canNotBeCombinedWithOtherGrills" label="{{ 'GRILL.FORM.CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS' | translate }}"
                      [disabled]="fieldUsage.disabled(GrillField.CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS)"
                      [(ngModel)]="item.canNotBeCombinedWithOtherGrills"></app-checkbox>
    </div>
    <div *ngIf="fieldUsage.show(GrillField.ACTIVE)" class="new-form-row">
            <app-checkbox inputId="active"
                          label="{{ 'GRILL.FORM.ACTIVE' | translate }}"
                          [disabled]="fieldUsage.disabled(GrillField.ACTIVE)"
                          [(validationMessageKey)]="validationErrors['active']"
                          [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GrillField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GRILL.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GrillField.IMAGE_SECTION)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion>
        <p-accordionTab *ngIf="fieldUsage.show(GrillField.COLORS)"
                        header="{{ 'GRILL.FORM.COLORS' | translate }}" [selected]="false" id="colorSectionAccordion">
            <p-listbox [options]="availableColors" [(ngModel)]="item.colorIds" [multiple]="true"
                       [disabled]="fieldUsage.disabled(GrillField.COLORS)"
                       id="colors" (ngModelChange)="onSelectedColorsChange()"></p-listbox>
        </p-accordionTab>
        <div *ngIf="validationErrors['colorIds'] != undefined" class="new-form-field-label-error-spacer"></div>
        <div *ngIf="validationErrors['colorIds'] != undefined" class="new-form-field-error-message">{{
            validationErrors['colorIds'] | translate }}
        </div>
        <p-accordionTab *ngIf="fieldUsage.show(GrillField.IMAGE_SECTION)"
                        header="{{ 'GRILL.FORM.IMAGE_SECTION' | translate }}"
                        [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(GrillField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'GRILL.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GRILL.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GRILL.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
