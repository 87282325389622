import {MultilanguageFieldInterface} from '../../../../../../../window-designer/catalog-data/multilanguage-field-interface';

export class Opcja {
    static AUTO_OPTION_SYMBOL = 'auto';
    static DO_NOT_CHANGE_OPTION_SYMBOL = 'DO_NOT_CHANGE';

    nameCode: string;
    nazwa?: MultilanguageFieldInterface; // only Color and Material
    symbol: string;
    domyslna: boolean;
    tylkoDla: string;
    selectedIf: string;
    hidden: boolean;

    // runtime only - not in json
    isSelectedByMasterValue?: (masterValue: string) => boolean;
}
