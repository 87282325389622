import {ValidationErrors} from '../../../common/validation-errors';
import {GateEditorField} from './gate-editor-field';
import {GateEditorFieldContentProvider} from './gate-editor-field-content-provider';
import {GateEditorComponent} from "./gate-editor.component";

export enum AccordionTabId {
    GENERAL = 'GENERAL'
}

export class GateEditorFieldStates {

    public static readonly SECTION_FIELDS = new Map<AccordionTabId, GateEditorField[]>([
        [AccordionTabId.GENERAL, [GateEditorField.WIDTH, GateEditorField.HEIGHT, GateEditorField.LINTEL_HEIGHT, GateEditorField.GATE_WALL,
            GateEditorField.RAIL_SYSTEM, GateEditorField.CORE_COLOR, GateEditorField.EXTERNAL_COLOR, GateEditorField.INTERNAL_COLOR, GateEditorField.GATE_PANEL_TYPE,
            GateEditorField.CONTROL]]
    ]);

    constructor(private readonly editor: GateEditorComponent,
                private readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider) {
    }

    isVisible(field: GateEditorField | string): boolean {
        switch (field) {
            case GateEditorField.WIDTH:
            case GateEditorField.HEIGHT:
            case GateEditorField.LINTEL_HEIGHT:
                return true;
            case GateEditorField.QUANTITY:
            case GateEditorField.GATE_SYSTEM:
            case GateEditorField.DESCRIPTION:
            case GateEditorField.ADDONS:
                return !this.sidebarOnlyMode;
            case GateEditorField.RAIL_SYSTEM:
            case GateEditorField.GATE_WALL:
            case GateEditorField.CORE_COLOR:
            case GateEditorField.EXTERNAL_COLOR:
            case GateEditorField.INTERNAL_COLOR:
            case GateEditorField.GATE_PANEL_TYPE:
                return this.hasItemsForField(field);
            case GateEditorField.COVERING_COLORS:
                return this.isVisible(GateEditorField.CORE_COLOR);
            default:
                // addons
                if (!Object.keys(GateEditorField).includes(field)) {
                    return this.hasItemsForField(field);
                }
                break;
        }
        return true;
    }

    isNotValid(field: GateEditorField): boolean {
        if (this.sidebarOnlyMode) {
            return false;
        }

        switch (field) {
            case GateEditorField.WIDTH:
                return this.editor.data && this.editor.data.width == null;
            case GateEditorField.HEIGHT:
                return this.editor.data && this.editor.data.height == null;
            case GateEditorField.LINTEL_HEIGHT:
                return this.editor.data && this.editor.data.lintelHeight == null;
            case GateEditorField.GATE_WALL:
                return this.editor.data && (this.editor.data.wall1 == null || this.editor.data.wall2 == null);
            case GateEditorField.RAIL_SYSTEM:
                return this.editor.data && this.editor.data.railSystemId == null;
            case GateEditorField.CORE_COLOR:
                return this.editor.data && this.editor.data.coreColorId == null && this.isVisible(GateEditorField.CORE_COLOR);
            case GateEditorField.EXTERNAL_COLOR:
                return this.editor.data && this.editor.data.externalColorId == null && !this.isVisible(GateEditorField.CORE_COLOR);
            case GateEditorField.INTERNAL_COLOR:
                return this.editor.data && this.editor.data.internalColorId == null && !this.isVisible(GateEditorField.CORE_COLOR);
            case GateEditorField.GATE_PANEL_TYPE:
                return this.editor.data && this.editor.data.gatePanelTypeId == null;
            case GateEditorField.CONTROL:
                return this.editor.data && this.editor.data.control == null;
            default:
                return false;
        }
    }

    isAnyErrorPresentInTab(validationErrors: ValidationErrors, groupId?: number): boolean {
        if (groupId == null || validationErrors == null) {
            return false;
        }
        let groupKeys = Object.keys(validationErrors).filter(key => key.startsWith(groupId + '_'));
        return groupKeys.map(key => validationErrors[key]).some(value => value != null);
    }

    isAnyErrorPresentInGeneralAccordionTab(validationErrors: ValidationErrors): boolean {
        return this.isAnyErrorPresentInAccordionTab(validationErrors, AccordionTabId.GENERAL);
    }

    get sidebarOnlyMode(): boolean {
        return this.editor == undefined;
    }

    private isAnyErrorPresentInAccordionTab(validationErrors: ValidationErrors, accordionTabId: AccordionTabId): boolean {
        const fields = GateEditorFieldStates.SECTION_FIELDS.get(accordionTabId);
        if (fields == undefined) {
            return false;
        }
        return fields.some(field => validationErrors[field] != undefined);
    }

    private hasItemsForField(field: GateEditorField | string): boolean {
        const items = this.gateEditorFieldContentProvider.getItems(field);
        return items != undefined && items.length > 0;
    }
}
