import {SubWindowData} from "../drawing-data/SubWindowData";
import {CompositionType} from "../drawing-data/CompositionType";
import {OpeningDirectionUtils} from "../window-types/opening-direction-utils";
import {OpeningDirection} from "../window-types/opening-direction";

export class SubwindowSortHelper {

    public static getDependencies(subwindows: SubWindowData[]): Map<SubWindowData, SubWindowData[]> {
        let dependencies = new Map<SubWindowData, SubWindowData[]>();
        subwindows.forEach(sw => dependencies.set(sw, []));

        for (let i = 0; i + 1 < subwindows.length; i++) {
            let leftSubwindow = subwindows[i];
            let rightSubwindow = subwindows[i + 1];
            if (
                leftSubwindow.profilesComposition.right === CompositionType.SLIDING_OVERLAP &&
                rightSubwindow.profilesComposition.left === CompositionType.SLIDING_OVERLAP &&
                OpeningDirectionUtils.areSubwindowsOpeningInTheSameDirection(leftSubwindow.typeCode,
                    rightSubwindow.typeCode)
            ) {
                if (OpeningDirectionUtils.getSubwindowOpening(leftSubwindow.typeCode) === OpeningDirection.L) {
                    // can't paint right subwindow until left one is in place (they overlap)
                    dependencies.set(rightSubwindow, [...dependencies.get(rightSubwindow), leftSubwindow]);
                } else {
                    // can't paint left subwindow until right one is in place (they overlap)
                    dependencies.set(leftSubwindow, [...dependencies.get(leftSubwindow), rightSubwindow]);
                }
            }
        }
        return dependencies;
    }
}
