<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'PRICE_LISTS_DEFINITION.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'PRICE_LISTS_DEFINITION.FORM.STATUS' | translate }}"
                               [displaySelectedLabel]="false" [options]="filterPricelistStatus | async"
                               [ngModel]="selectedStatuses"
                               (ngModelChange)="handleSelectedStatusesChange($event)"></p-multiSelect>
                <app-simple-button *ngIf="permissions.canCreatePricelist()"
                                   label="{{ 'PRICE_LISTS_DEFINITION.LIST.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="name" header="{{ 'PRICE_LISTS_DEFINITION.HEADERS.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.name}}
            </ng-template>
        </p-column>

        <p-column field="validFrom" header="{{ 'PRICE_LISTS_DEFINITION.HEADERS.VALID_FROM' | translate }}"
                  [sortable]="true" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.validFrom | momentCurrentLang | amDateFormat: 'L' }}
            </ng-template>
        </p-column>

        <p-column field="status" header="{{ 'PRICE_LISTS_DEFINITION.FORM.STATUS' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{('PRICE_LISTS_DEFINITION.FORM.' + item.status) | translate}}
            </ng-template>
        </p-column>

        <!-- Is current pricelist -->
        <p-column field="current" header="{{ 'PRICE_LISTS_DEFINITION.FORM.CURRENT' | translate }}"
                  [filter]="showFilters" filterMatchMode="contains" [filterValues]="filterCurrent | async">
            <ng-template let-item="rowData" pTemplate="body">
                {{ (item.current ? 'GENERAL.YES' : 'GENERAL.NO') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'PRICE_LISTS_DEFINITION.FORM.ADD_TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="add()">
    <app-wizard-step label="{{ 'PRICE_LISTS_DEFINITION.FORM.DETAILS_STEP' | translate }}">
        <app-pricelist-form [pricelist]="item" [validationErrors]="validationErrors" [canEdit]="true"></app-pricelist-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog #editDialog *ngIf="!newItem && displayDialog" header="{{ 'PRICE_LISTS_DEFINITION.FORM.EDIT_TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="edit()">
    <app-wizard-step label="{{ 'PRICE_LISTS_DEFINITION.FORM.DETAILS_STEP' | translate }}">
        <app-pricelist-form [pricelist]="item" [validationErrors]="validationErrors"
                            [canEdit]="permissions.canEditPricelist()"></app-pricelist-form>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="permissions.canEditPricelist()" buttonId="wizard-dialog-save"
                               label="{{'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="editDialog.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="editDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
