<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'PROFILE.TITLE' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.PROFILE.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.PROFILE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="profileTable">

        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(ProfileField.NAME)"
                          header="{{'PROFILE.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="name"></p-column>
                <p-column [rowspan]="2" [sortable]="true" field="sortIndex" header="{{'PROFILE.FORM.SORT_INDEX'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(ProfileField.SHORTCUT)"
                          header="{{'PROFILE.FORM.SHORTCUT'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(ProfileField.SYMBOL)"
                          header="{{'PROFILE.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(ProfileField.TYPE)"
                          header="{{'PROFILE.FORM.TYPE.HEADER'|translate}}" [rowspan]="2" [sortable]="false"
                          [filter]="showFilters" filterMatchMode="contains" field="type"
                          [filterValues]="filterType | async"></p-column>
                <p-column *ngIf="fieldUsage.show(ProfileField.WIDTH_EXTERNAL)"
                          header="{{'PROFILE.FORM.WIDTH_EXTERNAL'|translate}}" [rowspan]="2" field="widthExternal"
                          [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                          filterType="number" [filterNumericMinValue]="1" [filterNumericMaxValue]="99999"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'PROFILE.FORM.SYSTEMS'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(ProfileField.ACTIVE)"
                          header="{{'PROFILE.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <!--Name-->
        <p-column *ngIf="fieldUsage.show(ProfileField.NAME)" [filter]="showFilters" [sortable]="true" field="name" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.names[userLang]}}</span>
            </ng-template>
        </p-column>

        <!--Sort Index-->
        <p-column *ngIf="fieldUsage.show(ProfileField.SORT_INDEX)" [sortable]="true" field="sortIndex"></p-column>

        <!--shortcut-->
        <p-column *ngIf="fieldUsage.show(ProfileField.SHORTCUT)"
                  field="shortcut" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.shortcut[userLang]}}</span>
            </ng-template>
        </p-column>

        <!--Symbol-->
        <p-column *ngIf="fieldUsage.show(ProfileField.SYMBOL)"
                  field="symbol" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.symbol}}</span>
            </ng-template>
        </p-column>

        <!--Type-->
        <p-column *ngIf="fieldUsage.show(ProfileField.TYPE)" field="type">
            <ng-template let-item="rowData" pTemplate="body">
                {{'PROFILE.FORM.TYPE.'+item.type | translate}}
            </ng-template>
        </p-column>

        <!--Width External-->
        <p-column *ngIf="fieldUsage.show(ProfileField.WIDTH_EXTERNAL)"
                  field="widthExternal" [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                  filterType="number"
                  [filterNumericMinValue]="1">
            {{item.compositionDistances?.defaultWidth}}
        </p-column>

        <!--System - PCV-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.pcv)" (ngModelChange)="materialChange(item, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminium)" (ngModelChange)="materialChange(item, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.wood)" (ngModelChange)="materialChange(item, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium and wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminiumWoodMix)" (ngModelChange)="materialChange(item, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--Status-->
        <p-column *ngIf="fieldUsage.show(ProfileField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'PROFILE.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'PROFILE.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>

    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'PROFILE.ADD_PROFILE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'PROFILE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-profile-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file" (fileChange)="onFileChange($event)"
                          [types]="availableProfileTypes | async"></app-profile-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'PROFILE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [validate]="validateSystemStep">
        <div class="new-form-60vw">
            <ng-container *ngIf="validationErrors['type'] != undefined">
                <span class="new-form-field-error-icon new-form-field-error-icon-select material-icons">error</span>
                <div class="new-form-field-error-message">
                    {{ validationErrors['type'] | translate }}
                </div>
            </ng-container>
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'PROFILE.STEPS.ENTRANCE_SYSTEMS' | translate }}" [id]="STEPS.ENTRANCE_SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [validate]="validateSystemStep">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="entranceSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.PROFILE.COPY' : 'PROFILE.EDIT_PROFILE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'PROFILE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-profile-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file" (fileChange)="onFileChange($event)"
                          [types]="availableProfileTypes | async"></app-profile-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'PROFILE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [validate]="validateSystemStep">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [lockedItemsIds]="getLockedWindowSystemIds()"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'PROFILE.STEPS.ENTRANCE_SYSTEMS' | translate }}" [id]="STEPS.ENTRANCE_SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [validate]="validateSystemStep">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [lockedItemsIds]="getLockedWindowSystemIds()"
                                [showToggleAllButton]="true" [windowSystemTypeGroups]="entranceSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
