import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, DefaultUrlSerializer, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccessData} from '../features/AccessData';
import {CurrentUserService} from './current-user.service';

@Injectable()
export class OfferViewRestrictionGuard  {

    private readonly urlSerializer = new DefaultUrlSerializer();

    constructor(private readonly currentUserService: CurrentUserService,
                private readonly http: HttpClient) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
        const restrictedToOfferNumber = this.currentUserService.restrictedToOfferNumber;
        if (restrictedToOfferNumber != undefined) {
            return this.http.get<number>('offers/currentRestrictedOfferId').pipe(map(offerId => {
                if (!state.url.startsWith(`${AccessData.path.offer()}/${offerId}`)) {
                    return this.urlSerializer.parse(`${AccessData.path.offer()}/${offerId}/${AccessData.offerPositionURLSuffix};tryToAcquireLock=false`);
                }
                return true;
            }));
        }
        return true;
    }
}
