import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from "rxjs";
import {finalize} from "rxjs/operators";
import * as _ from 'underscore';
import {WindowTypeCodeParser} from "../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {MultilanguageField} from "../../../supportedLanguages";
import {WebshopBusinessType} from "./webshop-business-type";
import {WebshopBusinessTypeService} from "./webshop-business-type.service";

@Component({
    selector: 'app-webshop-business-type',
    templateUrl: './webshop-business-type.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [DataServiceHelper, WebshopBusinessTypeService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopBusinessTypeComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    readonly STEPS = {
        DATA: 'DATA'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateForm()
    };

    webshopBusinessTypes: WebshopBusinessType[];
    selectedWebshopBusinessType: WebshopBusinessType;
    filterTypes: Observable<SelectItem[]>;
    nameParser: (typeCode: WindowTypeCode) => string;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayDialog = false;
    userLang: string;

    @ViewChild('dt', {static: true}) datatable;

    constructor(private webshopBusinessTypeService: WebshopBusinessTypeService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private errorHandler: CommonErrorHandler,
                private translate: TranslateService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopBusinessTypeComponent', false);
        this.selectedWebshopBusinessType = new WebshopBusinessType();
        this.nameParser = WindowTypeCodeParser.getTypeName;
        this.initDefaultSortOrder();
        this.userLang = translate.currentLang;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initShownColumns();
        this.filterTypes = this.translatedSelectItemService.buildSortedDropdown(WindowTypeCode, WindowTypeCodeParser.getTypeName, '');
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.webshopBusinessTypeService.getItem(event.data.id).subscribe(
            businessType => {
                this.selectedWebshopBusinessType = businessType;
                this.setDisplayDialog(true);
                this.keepSelectedItemIndex(event);
            }
        );
    }

    cloneBusinessType(businessType: WebshopBusinessType): WebshopBusinessType {
        let newBusinessType = _.clone(businessType) as WebshopBusinessType;
        newBusinessType.names = (_.clone(businessType.names) as MultilanguageField);
        return newBusinessType;
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.webshopBusinessTypeService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WebshopBusinessTypeComponent `getPage` success:', data);
                    this.webshopBusinessTypes = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedWebshopBusinessType = this.restoreSelectionAfterLoad(this.selectedWebshopBusinessType, this.webshopBusinessTypes, event);
                },
                error: error => {
                    console.error('WebshopBusinessTypeComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WebshopBusinessTypeComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }

        if (!this.validationErrorsPresent()) {
            this.setSaveInProgress(true);
            this.webshopBusinessTypeService.editItem(this.selectedWebshopBusinessType).pipe(finalize(() => this.setSaveInProgress(false)))
                .subscribe({
                    next: () => {
                        this.reloadDatatable();
                        this.showSuccessMessage();
                        this.cancel();
                    },
                    error: error => {
                        this.validationErrors = this.errorHandler.handle(error);
                        this.changeDetector.markForCheck();
                        console.error('WebshopBusinessTypeComponent `getPage` error:', error);
                    }
                });
        }
    }

    validateForm(): Observable<boolean> {
        this.validationErrors = {};
        this.validationErrors['sortIndex'] = MultiValidator.of('error.webshopBusinessTypeDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.selectedWebshopBusinessType.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.changeDetector.markForCheck();
            return of(false);
        }

        return of(true);
    }

    showSuccessMessage() {
        this.growlMessageController.info('WEBSHOP_BUSINESS_TYPE.UPDATED');
    }

    setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    cancel() {
        this.setDisplayDialog(false);
    }

    showDialogToAdd(): void {
    }
}
