import {SelectItem} from 'primeng/api/selectitem';
import {AddonInterface} from './addon-interface';
import {ColorInterface, getColorFormattedNameWithGroup} from './color-interface';
import {MultilanguageFieldInterface} from './multilanguage-field-interface';
import {Price} from './Price';
import {SupplierInterface} from './supplier-interface';
import {QuantityType} from "../enums/QuantityType";

export class PositionListAddon implements AddonInterface {
    id: number;
    name: MultilanguageFieldInterface;
    category: string;
    addonFor: string;
    supplier: SupplierInterface;
    subsystemId: number;
    price: Price;
    defaultQuantity: any;
    quantityType: QuantityType;
    changeQuantity: boolean;
    chooseColor: boolean;
    insideColors: ColorInterface[];
    outsideColors: ColorInterface[];
    coreColors: ColorInterface[];
    pcn: string;
    active: boolean;
    removable: boolean;
    customNumericValue: number;
    customStringValue: string;
    singlePositionAddon: boolean;
    showGateDisclaimer: boolean;
    public checked = false;
    public quantity: number;
    public previousQuantity: number;
    public availableInsideColors: SelectItem[] = [];
    public availableOutsideColors: SelectItem[] = [];
    public availableCoreColors: SelectItem[] = [];
    public selectedInsideColor: ColorInterface;
    public selectedOutsideColor: ColorInterface;
    public selectedCoreColor: ColorInterface;
    public otherInfo: string;
    public lockRecalculateQuantity: boolean;
    public defaultAddon = false;

    constructor(addon: AddonInterface, userLang: string, otherInfo?: string, calculatedDefaultQuantity?: number) {
        if (addon) {
            this.id = addon.id;
            this.name = addon.name;
            this.category = addon.category;
            this.addonFor = addon.addonFor;
            this.supplier = addon.supplier;
            this.subsystemId = addon.subsystemId;
            this.price = addon.price;
            this.defaultQuantity = addon.defaultQuantity;
            this.quantityType = addon.quantityType;
            this.changeQuantity = addon.changeQuantity;
            this.chooseColor = addon.chooseColor;
            this.otherInfo = otherInfo;
            this.removable = addon.removable;
            this.customNumericValue = addon.customNumericValue;
            this.customStringValue = addon.customStringValue;
            this.showGateDisclaimer = addon.showGateDisclaimer;

            this.initAvailableInsideColors(addon, userLang);
            this.initAvailableOutsideColors(addon, userLang);
            this.initAvailableCoreColors(addon, userLang);

            this.pcn = addon.pcn;
            this.active = addon.active;

            if (calculatedDefaultQuantity == null) {
                this.quantity = Number(addon.defaultQuantity);
            } else {
                this.quantity = calculatedDefaultQuantity;
            }

            this.previousQuantity = this.quantity;
            this.lockRecalculateQuantity = false;
        }
    }

    private initAvailableInsideColors(addon: AddonInterface, userLang: string): void {
        this.availableInsideColors = [];

        addon.insideColors.forEach(ac => {
            this.availableInsideColors.push({label: getColorFormattedNameWithGroup(ac, userLang), value: ac});
        });

        this.insideColors = [];
    }

    private initAvailableOutsideColors(addon: AddonInterface, userLang: string): void {
        this.availableOutsideColors = [];

        addon.outsideColors.forEach(ac => {
            this.availableOutsideColors.push({label: getColorFormattedNameWithGroup(ac, userLang), value: ac});
        });

        this.outsideColors = [];
    }

    private initAvailableCoreColors(addon: AddonInterface, userLang: string): void {
        this.availableCoreColors = [];

        addon.coreColors.forEach(ac => {
            this.availableCoreColors.push({label: getColorFormattedNameWithGroup(ac, userLang), value: ac});
        });

        this.coreColors = [];
    }
}
