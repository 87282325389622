import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {MotlawaIntegrationInfo} from "../../common/MotlawaIntegrationInfo";
import {ValidationErrors} from "../../common/validation-errors";

@Component({
    selector: 'app-motlawa-integration-input',
    templateUrl: './motlawa-integration-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MotlawaIntegrationInputComponent {

    @Input() value: MotlawaIntegrationInfo;

    @Input() header: string;

    @Input() validationErrors: ValidationErrors;

    @Input() validationErrorsPrefix: string;

    @Input() disabled: boolean;

    getId(field: string): string {
        return this.combineWithPrefix(field, '_');
    }

    getValidationKey(field: string): string {
        return this.combineWithPrefix(field, '.');
    }

    private combineWithPrefix(field: string, separator: string): string {
        if (this.validationErrorsPrefix == undefined) {
            return field;
        }
        return `${this.validationErrorsPrefix}${separator}${field}`;
    }
}
