import {ConfigAddonOpening} from '../entities/ConfigAddonOpening';
import {RandomIdGenerator} from "../utils/RandomIdGenerator";
import {SubWindowTypeCode} from "../window-types/subwindow-type-code";
import {AreaSpecification} from "./AreaSpecification";
import {Handle} from "./Handle";
import {Mullion} from "./Mullion";
import {ProfilesComposition} from "./ProfilesComposition";
import {SubWindowShape} from "./SubWindowShape";
import {WindowAddon} from "./WindowAddon";
import {Wing} from "./Wing";

export class SubWindowData {
    generatedId: string;
    shape: SubWindowShape;
    configurableAddonIds: number[];
    typeCode: SubWindowTypeCode;
    isLeading: boolean;
    isActive: boolean;
    points: number[];
    handle: Handle;
    wing: Wing;
    areasSpecification: AreaSpecification[];
    cut: number[];
    neighboringFramesLengths: number[];
    ventilationPluginWidth: number;
    mullions: Mullion[];
    profilesComposition: ProfilesComposition;
    ventilator: WindowAddon = new WindowAddon();
    drip: WindowAddon = new WindowAddon();
    coupler: WindowAddon = new WindowAddon();
    subWindowNameSuffix: string;
    functionsQuantity: number;
    openings: ConfigAddonOpening[] = [];
    relevantCutIds: string[];

    constructor() {
        this.generatedId = RandomIdGenerator.generate();
    }
}
