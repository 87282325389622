import {Injectable} from '@angular/core';

@Injectable()
export class ValidationService {

    /**
     * returns the array of fields for which validation fails
     */
    validateNotEmpty(dto: any, fields: string[], validationErrors: { [key: string]: string; }, entityName: string) {
        for (let field of fields) {
            let fieldValue = this.getNestedProp(dto, field);
            if (!fieldValue || !fieldValue.trim()) {
                validationErrors[field] = 'error.' + entityName + '.' + field + '.not_empty';
            }
        }
    }

    private getNestedProp(obj, desc) {
        var arr = desc.split('.');
        while (arr.length && (obj = obj[arr.shift()])) ;
        return obj;
    }
}
