import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AllAddons} from '../../../../../window-designer/all-addons';
import {ColorInterface} from '../../../../../window-designer/catalog-data/color-interface';
import {CustomTranslationsInterface} from '../../../../../window-designer/catalog-data/custom-translations-interface';
import {DecorativeFillingListingInterface} from '../../../../../window-designer/catalog-data/decorative-filling-interface';
import {DistanceFrameInterface} from "../../../../../window-designer/catalog-data/distance-frame-interface";
import {GlassInterface} from '../../../../../window-designer/catalog-data/glass-interface';
import {GlazingBeadInterface} from '../../../../../window-designer/catalog-data/glazing-bead-interface';
import {GrillInterface} from '../../../../../window-designer/catalog-data/grill-interface';
import {ProfileListingInterface} from '../../../../../window-designer/catalog-data/profile-interface';
import {SubwindowTypeInterface, SubwindowTypeListingInterface} from '../../../../../window-designer/catalog-data/subwindow-type-interface';
import {WindowSystemInterface} from '../../../../../window-designer/catalog-data/window-system-interface';
import {
    BulkWindowDesignerDataForSystem,
    WindowDesignerDataServiceInterface
} from '../../../../../window-designer/window-designer-data.service-interface';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {Addon} from '../../../window-system/addons/addon';

@Injectable()
export class WindowDesignerDataService implements WindowDesignerDataServiceInterface {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getWindowSystem(windowSystemId: number): Observable<WindowSystemInterface> {
        return this.http.get<WindowSystemInterface>(`system/${windowSystemId}`);
    }

    getGrills(windowSystemId: number): Observable<GrillInterface[]> {
        return this.http.get<GrillInterface[]>(`grills/forWindowSystem/${windowSystemId}`);
    }

    getGlazingBeads(windowSystemId: number): Observable<GlazingBeadInterface[]> {
        return this.http.get<GlazingBeadInterface[]>(`glazing-beads/forWindowSystem/${windowSystemId}`);
    }

    getGlasses(windowSystemId: number): Observable<GlassInterface[]> {
        return this.http.get<GlassInterface[]>(`glass/forWindowSystem/${windowSystemId}`);
    }

    getFrames(windowSystemId: number): Observable<DistanceFrameInterface[]> {
        return this.http.get<DistanceFrameInterface[]>(`distanceframe/forWindowSystem/${windowSystemId}`);
    }

    getProfiles(windowSystemId: number): Observable<ProfileListingInterface> {
        return this.http.get<ProfileListingInterface>(`system/${windowSystemId}/profiles`);
    }

    getDecorativeFillings(windowSystemId: number): Observable<DecorativeFillingListingInterface> {
        return this.http.get<DecorativeFillingListingInterface>(`system/${windowSystemId}/decorative-fillings`);
    }

    getSubwindowTypes(): Observable<SubwindowTypeInterface[]> {
        let params = this.dataServiceHelper.prepareSearchParams(0, undefined, undefined, 'id', 1);
        return this.http.get<SubwindowTypeListingInterface>('subwindowTypes', {params: params}).pipe(
            map(responseData => responseData.data));
    }

    getCustomTranslations(): Observable<CustomTranslationsInterface> {
        return this.http.get<CustomTranslationsInterface>(`translations/custom`);
    }

    getDataBulk(windowSystemId: number): Observable<BulkWindowDesignerDataForSystem> {
        return this.http.get<BulkWindowDesignerDataForSystem>(`system/${windowSystemId}/bulkWindowDesignerData`);
    }

    getAddonsByIds(addonsIds: number[]): Observable<AllAddons> {
        return this.http.get<Addon[]>('addons/added?ids=' + addonsIds.join(","))
            .pipe(map(result => {
                const allAddons = new AllAddons();
                allAddons.fill(result);
                return allAddons;
            }));
    }

    getColorById(colorId: number): Observable<ColorInterface> {
        return this.http.get<ColorInterface>(`colors/${colorId}`);
    }
}
