export abstract class AbstractSellDealerDiscount {
    id: number;
    cascade: boolean;
    value: string;
    calculatedDiscountValue: number;
    groupCalculatedDiscountValue: number;

    static fromJSON(newDiscount: AbstractSellDealerDiscount, jsonObject: any): void {
        newDiscount.id = jsonObject['id'];
        newDiscount.cascade = jsonObject['cascade'];
        newDiscount.value = jsonObject['value'];
        newDiscount.calculatedDiscountValue = jsonObject['calculatedDiscountValue'];
        newDiscount.groupCalculatedDiscountValue = jsonObject['groupCalculatedDiscountValue'];
    }
}
