import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {ValidationErrors} from '../../../../common/validation-errors';
import {MultilanguageField, SupportedLanguages} from '../../../../supportedLanguages';

@Component({
    selector: 'app-config-addon-glamour-print-info-form',
    templateUrl: './config-addon-glamour-print-info-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAddonGlamourPrintInfoFormComponent {

    supportedLanguages = SupportedLanguages.languages;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    horizontalPrintImageFile: File;

    @Output()
    readonly horizontalPrintImageFileChange = new EventEmitter<File>();

    @Input()
    verticalPrintImageFile: File;

    @Output()
    readonly verticalPrintImageFileChange = new EventEmitter<File>();

    @Input()
    glamourDescription: MultilanguageField;

    @Input()
    disabled: boolean;

    @Output()
    readonly glamourDescriptionChange = new EventEmitter<{text: string, lang: keyof MultilanguageFieldInterface}>();

    handleHorizontalPrintImageFileChanged(file: File): void {
        this.horizontalPrintImageFileChange.emit(file);
    }

    handleVerticalPrintImageFileChanged(file: File): void {
        this.verticalPrintImageFileChange.emit(file);
    }

    handleGlamourDescriptionChanged(description: string, lang: keyof MultilanguageFieldInterface): void {
        this.glamourDescriptionChange.emit({text: description, lang});
    }
}
