<ng-template #unavailableTemplate let-id="id" let-items="items" let-canBeEmpty="canBeEmpty">
    <div *ngIf="id | isNotAvailable: items: canBeEmpty" class="new-form-field-error-message">
        {{ 'ERROR.DESIGNER.NO_LONGER_AVAILABLE' | translate }}
    </div>
</ng-template>

<p-accordion #mainAcc [multiple]="true" class="config-designer-tab" id="general-tab">

    <div class="new-form-row" *ngIf="fields.isVisible(Fields.SYSTEM)">
        <div class="input-with-action-container">
            <div class="new-form-field">
                <label>{{ 'OFFER.TABS.SECTION.MAIN.CONFIG_SYSTEM' | translate }}</label>
                {{ selectedSystem?.name | multilanguageTranslate }}
            </div>
        </div>
        <div class="new-form-field flex-row">
            <span class="spacer"></span>
            <app-simple-button buttonId="systemChange" [disabled]="readOnlyMode"
                               label="{{ 'OFFER.TABS.SECTION.MAIN.CONFIG_SYSTEM_CHANGE' | translate }}"
                               [inlineLabel]="false" icon="swap_horiz" type="filter" align="right"
                               tooltipPosition="left" (onClick)="onSystemChange.emit()"></app-simple-button>
        </div>
    </div>

    <div class="new-form-row" *ngIf="fields.isVisible(Fields.QUANTITY)">
        <app-input-number [inputId]="Fields.QUANTITY +'_id'"
                          label="{{ 'OFFER.TABS.SECTION.MAIN.QUANTITY' | translate }}"
                          [(validationMessageKey)]="validationErrors[Fields.QUANTITY]" [disabled]="readOnlyMode"
                          (onFocus)="accordionTabState.main = true" [min]="1" [max]="1000000"
                          [allowOnlyIntegers]="true" [(ngModel)]="position.quantity"
                          [modelOptions]="{updateOn: 'blur'}">
            <ng-template pTemplate="validationMessage"></ng-template>
        </app-input-number>
    </div>

    <div class="new-form-row">
        <div *ngIf="parentElementDescription" class="new-form-row">
            <div class="new-form-field">
                <label>{{ 'OFFER.POSITIONS.ADDON_CONFIG.PARENT_APPLICATIONS.LABEL' | translate }}</label>
                <div class="new-form-field-input-container parent-applications">
                    <ng-container *ngFor="let description of parentElementDescription">
                        <div>
                            {{ 'OFFER.POSITIONS.ADDON_CONFIG.PARENT_APPLICATIONS.' + description.application | translate
                            }}
                        </div>
                        <div>
                            <ng-container *ngIf="showParentInfoIndex(description.application)">
                                {{ description.index }}
                            </ng-container>
                            {{getDescription(description)}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <p-accordionTab *ngIf="fields.isVisible('DIMENSION_TAB')"
                    [(selected)]="accordionTabState.main" id="mainSectionAccordion"
                    [ngClass]="{'accordion-header-with-errors': fields.isAnyErrorPresentInGeneralAccordionTab(validationErrors)}">
        <p-header>
            {{ 'OFFER.POSITIONS.ADDON_CONFIG.GROUP.BULK_DIMENSIONS' | translate }}
            <a (click)="$event.stopPropagation()" *ngIf="selectedSystem?.sizingVideoLink" class="dimensions-link" href="{{selectedSystem.sizingVideoLink}}" target="_blank">{{'OFFER.POSITIONS.ADDON_CONFIG.SIZING_LINK' | translate}}</a>
            <span (click)="$event.stopPropagation(); handlePhotoDownloadClick()" *ngIf="!selectedSystem?.sizingVideoLink" class="dimensions-link">{{'OFFER.POSITIONS.ADDON_CONFIG.SIZING_LINK' | translate}}</span>
        </p-header>
        <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6]">
            <app-input-number *ngIf="fields.isVisible(Fields['DIM_' + i])" [inputId]="Fields['DIM_' + i] +'_id'"
                              [(validationMessageKey)]="validationErrors[Fields['DIM_' + i]]" [disabled]="readOnlyMode"
                              (onFocus)="accordionTabState.main = true" (onBlur)="handleFieldBlur(Fields['DIM_' + i], getDataField(i))"
                              [shouldRoundToStep]="true" [min]="1" [max]="20000" [step]="selectedSystem?.dimensionStep"
                              [allowOnlyIntegers]="true" [ngModel]="data['wym' + i]"
                              (ngModelChange)="handleFieldValueChange(Fields['DIM_' + i], getDataField(i), $event)">
                <ng-template let-inputId="inputId" let-label="label" pTemplate="label">
                    <app-sidebar-field-label [inputId]="inputId" [label]="('CONFIG_DIMENSION.' + selectedSystem.id + '.NAME.DIM_' + i) | translate"
                                             [showImage]="false" [tooltipLabel]="getTooltipLabel(i)"></app-sidebar-field-label>
                </ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
        </ng-container>
    </p-accordionTab>

    <p-accordionTab *ngFor="let group of addonCategoryGroups; let firstGroup = first" header="{{ group.name[translate.currentLang] }}"
                    [id]="addonCategoryGroupName + group.id"
                    [(selected)]="accordionTabState[group.id]"
                    [ngClass]="{'accordion-header-with-errors': fields.isAnyErrorPresentInTab(validationErrors, group.id,
                             ((selectedSystem.materialColorCategoryGroupId == null && firstGroup) || group.id === selectedSystem.materialColorCategoryGroupId))}">
        <ng-container *ngIf="((selectedSystem.materialColorCategoryGroupId == null && firstGroup) || group.id === selectedSystem.materialColorCategoryGroupId)
                              && selectedSystem.materialColorPrevCategoryId == null">
            <ng-container *ngTemplateOutlet="materialAndColor; context: { groupId: group.id, isColorVisible: fields.isVisible(Fields.COLOR) }"></ng-container>
        </ng-container>

        <ng-container *ngFor="let category of group.categories; let firstCategory = first">
            <ng-container *ngIf="fields.isVisible(category.symbol)">
                <ng-container *vLet="editorFieldContentProvider.getItemsStream(category.symbol) | async as items">
                    <app-select-or-checkbox
                            [ngClass]="{'has-new-options': editorFieldContentProvider.hasNewItems(category.symbol)}"
                            [inputId]="category.symbol + '_id'" label="{{ category.name[translate.currentLang] }}"
                            [(validationMessageKey)]="validationErrors[group.id + '_' + category.symbol]"
                            [disabled]="readOnlyMode" (onFocus)="accordionTabState[group.id] = true" [options]="items"
                            [checkAvailability]="true" [allowSelectingNone]="!category.required || noOptionsAvailable(items)" [required]="category.required && !noOptionsAvailable(items)"
                            [fakeSelect]="category.tileDialog"
                            (onClick)="editorFieldContentProvider.clearHasNewItemsMarker(category.symbol);openTileDialog(category, items)"
                            [value]="data.sidebarAddons[category.symbol]"
                            [noLongerAvailable]="data.sidebarAddons[category.symbol] | isNotAvailable:items:true"
                            (valueChange)="handleSidebarFieldValueChange(category.symbol, category.symbol, $event)">
                        <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [tooltipLabel]="category.info[translate.currentLang]"
                                                     (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                     [disabled]="disabled"></app-sidebar-field-label>
                        </ng-template>
                        <ng-template #validationMessageTemplate>
                            <ng-container
                                    *ngTemplateOutlet="unavailableTemplate; context: { id: data.sidebarAddons[category.symbol], items: items, canBeEmpty: true }"></ng-container>
                        </ng-template>
                    </app-select-or-checkbox>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="group.id === selectedSystem.materialColorCategoryGroupId
                              && category.id === selectedSystem.materialColorPrevCategoryId">
                <ng-container *ngTemplateOutlet="materialAndColor; context: { groupId: group.id, isColorVisible: fields.isVisible(Fields.COLOR) }"></ng-container>
            </ng-container>
        </ng-container>
    </p-accordionTab>

    <!--Description-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.DESCRIPTION.SECTION_TITLE' | translate }}"
                    *ngIf="fields.isVisible(Fields.DESCRIPTION)" [(selected)]="accordionTabState.description"
                    id="descriptionAccordion" class="p-accordion-button"
                    (click)="showDescriptionDialog()"></p-accordionTab>

    <!--Global settings-->
    <p-accordionTab *ngIf="!readOnlyMode" header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}"
                    [(selected)]="accordionTabState.defaults" id="globalSettingsAccordion">

        <div class="new-form-row">
            <app-select inputId="globalSettingsSelect" [(validationMessageKey)]="validationErrors['level']"
                        [options]="defaultsLevels" [required]="false"
                        (onFocus)="accordionTabState.defaults = true" [ngModel]="defaultsLevel"
                        [translateLabels]="true" (ngModelChange)="defaultsLevelChange.emit($event)">
                <ng-template pTemplate="label"></ng-template>
            </app-select>
        </div>

        <div class="new-form-row" *ngIf="!fields.sidebarOnlyMode">
            <app-checkbox inputId="globalSettingsOverrideLowerLevels"
                          label="{{ 'OFFER.TABS.SECTION.DEFAULTS.OVERRIDE' | translate }}"
                          [ngModel]="defaultsOverrideLowerLevel"
                          (ngModelChange)="defaultsOverrideLowerLevelChange.emit($event)"></app-checkbox>
        </div>

        <div class="new-form-row">
            <div class="new-form-field">
                <app-simple-button id="globalSettingsSaveButtonContainer" buttonId="globalSettingsSaveButton"
                                   label="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" type="main-action"
                                   [size]="40" fixedWidth="100%" (onClick)="defaultsSavedClick.emit($event)"
                                   (onFocus)="accordionTabState.defaults = true"></app-simple-button>
            </div>
        </div>

        <div *ngIf="data.usedGlobalSettingsLevel && !data.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + data.usedGlobalSettingsLevel | translate }}
            </div>
        </div>

        <div *ngIf="data.usedGlobalSettingsLevel && data.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + data.usedGlobalSettingsLevel | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.MODIFIED' | translate }}
            </div>
        </div>
    </p-accordionTab>
</p-accordion>

<app-tile-select-dialog *ngIf="showTileDialog"
                        [dialogHeader]="category.name[translate.currentLang]"
                        [sortedTileItems]="tileDialogItems"
                        [tileValue]="tileValue"
                        [tileSelectLinks]="links" [dataLoaded]="true"
                        (onClose)="closeTileDialog()"
                        (onSave)="handleSidebarFieldValueChange(category.symbol, category.symbol, $event.tileValue)"
                        (openLargeImageInNewTab)="openAddonFullImage($event.tileValue, $event.hex)">
</app-tile-select-dialog>

<app-tile-select-dialog *ngIf="showMaterialColorDialog"
                           [dialogHeader]="'OFFER.TABS.SECTION.MAIN.MATERIAL_AND_COLOR'"
                           [selectLabel]="getMaterialLabel()"
                           [sortedSelectItems]="editorFieldContentProvider.getItemsStream(Fields.MATERIAL) | async"
                           [sortedTileItems]="editorFieldContentProvider.getItemsStream(Fields.COLOR) | async"
                           [selectValue]="data.materialId" [tileValue]="data.colorId"
                           [tileSelectLinks]="colorMaterialLinks"  [dataLoaded]="systemLinksLoaded"
                           [skipSelect]="false"
                           (onClose)="showMaterialColorDialog = false"
                           (onSave)="onMaterialColorSave($event)"
                           (openLargeImageInNewTab)="openFullImage($event.selectValue, $event.tileValue, $event.hex)">
</app-tile-select-dialog>

<ng-template #materialAndColor let-groupId="groupId" let-isColorVisible="isColorVisible">
    <ng-container *vLet="editorFieldContentProvider.getItemsStream(Fields.COLOR) | async as colors">
        <div class="new-form-row" [id]="Fields.MATERIAL" *ngIf="fields.isVisible(Fields.MATERIAL)">
            <ng-container *vLet="editorFieldContentProvider.getItemsStream(Fields.MATERIAL) | async as items">
                <app-select
                    [ngClass]="{'has-new-options': editorFieldContentProvider.hasNewItems(Fields.MATERIAL)}"
                    [inputId]="Fields.MATERIAL" [fakeSelect]="isColorVisible"
                    [label]="getMaterialLabel() | translate"
                    [(validationMessageKey)]="validationErrors[Fields.MATERIAL]" [disabled]="readOnlyMode"
                    (onFocus)="accordionTabState[groupId] = true" [options]="items" [checkAvailability]="true"
                    [noLongerAvailable]="data.materialId | isNotAvailable:items:true"
                    (onClick)="openMaterialAndColorDialog(isColorVisible)"
                    [ngModel]="data.materialId" (ngModelChange)="materialChange($event, isColorVisible)">
                    <ng-template pTemplate="validationMessage">
                        <ng-container
                            *ngTemplateOutlet="unavailableTemplate; context: { id: data.materialId, items: items, canBeEmpty: true }"></ng-container>
                    </ng-template>
                    <ng-template pTemplate="label" let-inputId="inputId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label" [showImage]="!isColorVisible"
                                                 (onShowImage)="handleShowImage(imageService.getMaterialImage(data.materialId), label)"
                                                 [disabled]="data.materialId == null"></app-sidebar-field-label>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="isColorVisible || (data.colorId | isNotAvailable:colors)">
            <app-select
                [ngClass]="{'has-new-options': editorFieldContentProvider.hasNewItems(Fields.COLOR)}"
                [inputId]="Fields.COLOR" [fakeSelect]="true"
                [label]="getColorLabel() | translate"
                [(validationMessageKey)]="validationErrors[Fields.COLOR]" [disabled]="readOnlyMode"
                (onFocus)="accordionTabState[groupId] = true" [options]="colors" [checkAvailability]="true"
                [noLongerAvailable]="data.colorId | isNotAvailable:colors:true"
                (onClick)="openMaterialAndColorDialog()" [allowSelectingNone]="data.colorId | isNotAvailable:colors"
                [ngModel]="data.colorId">
                <ng-template pTemplate="validationMessage">
                    <ng-container
                        *ngTemplateOutlet="unavailableTemplate; context: { id: data.colorId, items: colors, canBeEmpty: true }"></ng-container>
                </ng-template>
            </app-select>
        </div>
    </ng-container>

    <div class="new-form-row" *ngIf="fields.isVisible(Fields.MATERIAL) && isColorVisible">
        <div class="new-form-field" style="text-align: center">
            <img *ngIf="materialColorThumbnail as image"
                 class="material-color-thumbnail"
                 (click)="handleOpenFullImage()"
                 [src]="image | appSafeValue: 'url'">
            <span *ngIf="!materialColorThumbnail"
                  class="image-placeholder"
                  [ngStyle]="{'backgroundColor': hex}"
                  (click)="handleHexFullScreen()"></span>
        </div>
    </div>
</ng-template>
