import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {AssemblyUnit} from '../assembly/assembly';
import {Transport} from "./transport";
import {TransportService} from "./transport.service";
import {TransportValidator} from "./transport.validator";

@Component({
    selector: 'app-transport',
    templateUrl: './transport.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [TransportService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportComponent extends CrudCommonComponent<Transport, TransportService> implements OnInit {

    assemblyUnitsFilter: Observable<SelectItem[]>;
    availableAssemblyUnits: Observable<SelectItem[]>;

    @ViewChild(DataTable)
    dataTable: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, TransportService, 'TRANSPORT', 'Transport');
        this.item = new Transport();
        this.initDefaultSortOrder();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.assemblyUnitsFilter = this.translatedSelectItemService.buildSortedDropdown(AssemblyUnit, 'ASSEMBLY.UNITS.', '');
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.availableAssemblyUnits = this.assemblyUnitsFilter.pipe(map(units => units.slice(1)));
    }

    getNewItem(): Transport {
        return new Transport();
    }

    showDialogToCopy(): void {
        if (this.selectedItem != undefined) {
            this.itemId = undefined;
            this.item = _.clone(this.selectedItem);
            this.item.id = undefined;
            this.setDisplayDialog(true);
        }
    }

    validateForm(): void {
        this.validationErrors = TransportValidator.validate(this.item);
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    getDialogHeaderText(): string {
        return `NAVIGATION.ITEMS.WINDOW-SYSTEM.TRANSPORT.${this.copyMode ? 'COPY' : 'EDIT-TRANSPORT'}`;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
