import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../common/validation-errors';
import {PaymentPackage} from '../payment-package';

@Component({
    selector: 'app-payment-package-subsystem-selection-form',
    templateUrl: './payment-package-subsystem-selection-form.component.html',
    styleUrls: ['./payment-package-subsystem-selection-form.component.css']
})
export class PaymentPackageSubsystemSelectionFormComponent {

    @Input()
    paymentPackage: PaymentPackage;

    @Input()
    subsystems: SelectItem[];

    @Input()
    validationErrors: ValidationErrors = {};

    @Output()
    onDblClick = new EventEmitter<any>();

    selectedSubsystem: number;

    handleSubsystemSelectionChange(subsystem: number): void {
        this.validationErrors['subsystemId'] = undefined;
        this.selectedSubsystem = subsystem;
        this.paymentPackage.subsystemId = subsystem;
    }

    handleDblClick(event: any): void {
        this.onDblClick.emit(event);
    }
}
