import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TreeNode} from 'primeng/api/treenode';
import * as _ from 'underscore';
import {Permissions} from '../../../auth/permission.service';
import {OfferHistoryEntry} from '../offer';
import {OffersService} from '../offer-service';

@Component({
    selector: 'app-offer-history',
    templateUrl: './offer-history.component.html',
    styleUrls: ['./offer-history.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OffersService]
})
export class OfferHistoryComponent implements OnInit {

    @Input()
    offerId: number;

    history: OfferHistoryEntry[] = [];
    historyTree: TreeNode[];
    first = 0;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;

    constructor(private readonly offerService: OffersService,
                public readonly permissions: Permissions,
                private readonly translateService: TranslateService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.offerService.getHistory(this.offerId).subscribe(history => {
            this.history = history;

            const groupedByRequest = _.groupBy(history, e => e.requestId);
            this.historyTree = Object.entries(groupedByRequest)
                .map(([requestId, entries]) => {
                    return {
                        data: { request: entries[0] },
                        children: entries.map(child => ({ data: { entry: child } }))
                    };
                });
            this.changeDetector.markForCheck();
        });
    }
}
