import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit, Type} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {isObservable, Observable, Observer, of, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ApplicationPrivilege} from '../../auth/application-privilege';
import {Permissions} from '../../auth/permission.service';
import {OnceFlag} from '../../shared/once-flag';
import {CommonErrorHandler} from '../CommonErrorHandler';
import {TranslatedSelectItemService} from '../service/translated-select-item.service';
import {TranslatedSelectItem} from '../service/translated.select.item';
import {TranslatedSelectItemBuilder} from '../service/translated.select.item.builder';
import {CrudService} from './crud.service';
import {CrudItem} from './crudItem';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from './paginable.component';

@Directive()
export abstract class CrudCommonComponent<Item extends CrudItem, Service extends CrudService<Item>>
    extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    protected langTranslateSubscription: Subscription;
    item: Item;
    itemList: Item[];
    userLang: string;
    displayDialog: boolean;
    newItem: boolean;
    selectedItem: Item;
    protected activeTranslationKey: string;
    protected inactiveTranslationKey: string;
    protected itemId: number;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    file: File;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    errors: CommonErrorHandler;

    public translate: TranslateService;
    protected translatedSelectItemService: TranslatedSelectItemService;
    protected router: Router;
    public permissions: Permissions;
    protected itemService: Service;

    protected readonly dialogHideHelper = new OnceFlag();

    protected constructor(injector: Injector,
                          changeDetector: ChangeDetectorRef,
                          copySupported: boolean,
                          serviceType: Type<Service>,
                          public translationKey: string,
                          public entityName: string) {
        super(injector, changeDetector, entityName + 'Component', copySupported);
        this.translate = injector.get(TranslateService);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
        this.router = injector.get(Router);
        this.permissions = injector.get(Permissions);
        this.itemService = injector.get(serviceType);
        this.errors = injector.get(CommonErrorHandler);
        this.setTranslationKey(translationKey);
        this.userLang = this.translate.currentLang;
        this.langTranslateSubscription = this.translate.onLangChange.subscribe(event => {
            this.userLang = event.lang;
            this.reloadDatatable();
            this.onLanguageChange(event.translations);
            this.changeDetector.markForCheck();
        });
        this.validationErrors = {};
    }

    static buildActiveDropdown(customTranslationKey?: string): TranslatedSelectItem[] {
        let filterBuilder = TranslatedSelectItemBuilder.create().add('', '');
        let activeKey = 'GENERAL.ONLY_ACTIVE_F';
        let inactiveKey = 'GENERAL.ONLY_INACTIVE_F';

        if (customTranslationKey) {
            activeKey = customTranslationKey + '.ACTIVE';
            inactiveKey = customTranslationKey + '.INACTIVE';
        }

        filterBuilder.add(activeKey, true);
        filterBuilder.add(inactiveKey, false);

        return filterBuilder.build();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.langTranslateSubscription.unsubscribe();
    }

    // Actions done before add and edit like assigning every fields to new object;
    prepareItemForRequest(): Item {
        return this.item;
    }

    // Implement like this: return new class implementing CrudItem;
    abstract getNewItem(): Item;

    loadEditedItem(event: { data: Item }): Observable<Item> {
        return of(this.cloneItem(event.data));
    }

    // Self explanatory name, override if needed
    protected validateForm(): void | boolean | Observable<boolean> {
        return of(!this.validationErrorsPresent());
    }

    // Self explanatory name, override if needed
    protected onLanguageChange(newTranslations?: any): void {
    }

    // Self explanatory name, override if needed
    protected afterSuccessLoad(): void {
    }

    // Self explanatory name, override if needed
    protected afterDialogOpen(): void {
    }

    // Returns active user language as the two letter country code eg.: 'pl'.
    protected getActiveLanguage(): string {
        return this.userLang;
    }

    onRowSelect(event: { data: Item } & KeepSelectedItemEventParams): void {
        this.resetFile();
        this.validationErrors = {};
        this.newItem = false;
        this.itemId = event.data.id;
        this.selectedItem = event.data;
        this.keepSelectedItemIndex(event);
        this.loadEditedItem(event).subscribe(item => {
            this.item = item;
            this.setDisplayDialog(true);
            this.afterDialogOpen();
        });
    }

    private setTranslationKey(key: string): void {
        this.activeTranslationKey = key + '.FORM.ACTIVE';
        this.inactiveTranslationKey = key + '.FORM.INACTIVE';
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        super.loadItemsLazy(event);
        if (event) {
            let componentName = this.entityName + 'ListComponent';
            this.itemService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
                .pipe(finalize(() => this.hideDataLoadingIndicator()))
                .subscribe({
                    next: data => {
                        console.info(componentName + ' `getPage` success:');
                        this.itemList = data.data;
                        this.totalRecords = data.totalRecords;
                        this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                        this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                        this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.itemList, event);
                        this.afterSuccessLoad();
                    },
                    error: error => {
                        console.error(componentName + ' `getPage` error:', error);
                        this.setErrors(error);
                    },
                    complete: () => {
                        console.info(componentName + ' `getPage` completed!');
                        this.changeDetector.markForCheck();
                    }
                });
        }
    }

    private cloneItem(item: Item): Item {
        let newItem = this.getNewItem();
        for (let prop in item) {
            if (item[prop] != null) {
                if (Array.isArray(item[prop])) {
                    newItem[prop] = <any>(<any[]><any>item[prop]).slice();
                } else {
                    newItem[prop] = item[prop];
                }
            }
        }
        return newItem;
    }

    showSuccessMessage(): void {
        let tmpTranslate = this.translationKey + '.' + this.translationKey;
        if (this.newItem || this.copyMode) {
            this.growlMessageController.info(tmpTranslate + '_CREATED');
            this.newItem = false;
        } else {
            this.growlMessageController.info(tmpTranslate + '_UPDATED');
        }
    }

    protected cleanUpAndReload(preventLazyLoad = false): void {
        this.dialogHideHelper.call(() => {
            this.copyMode = false;
            this.newItem = false;
            if (!preventLazyLoad) {
                this.reloadDatatable();
            }
            this.setDisplayDialog(false);
        });
    }

    showDialogToAdd(): void {
        this.validationErrors = {};
        this.itemId = undefined;
        this.newItem = true;
        this.item = this.getNewItem();
        if ('active' in this.item) {
            this.item['active'] = true;
        }
        this.setDisplayDialog(true);
        this.resetFile();
        this.afterDialogOpen();
    }

    submit(): void {
        if (this.itemId) {
            this.edit();
        } else {
            this.add();
        }
    }

    protected validateFormImpl(): Observable<boolean> {
        let validateResult: void | boolean | Observable<boolean> = this.validateForm();
        if (isObservable(validateResult)) {
            return validateResult;
        } else if (typeof validateResult === 'boolean') {
            return of(validateResult);
        } else {
            return of(!this.validationErrorsPresent());
        }
    }

    add(): void {
        this.validateFormImpl().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            let requestItem = this.prepareItemForRequest();

            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            this.itemService.addItem(requestItem, this.file).subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    edit(): void {
        this.validateFormImpl().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            let requestItem = this.prepareItemForRequest();

            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            this.itemService.editItem(this.itemId, requestItem, this.file).subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    protected genericCleanupAndReloadSuccessObserver(): Observer<number> {
        return {
            next: (itemId) => {
                // select new item in table
                if (this.newItem || this.copyMode) {
                    this.selectedItem = this.getNewItem();
                    this.selectedItem.id = itemId;
                }
            },
            error: errorMessage => {
                this.genericErrorHandler(errorMessage);
                this.setSaveInProgress(false);
            },
            complete: () => {
                let preventLazyLoad = this.newItem;
                this.showSuccessMessage();
                this.cleanUpAndReload(preventLazyLoad);
                this.hotkeysService.remove(this.enterHotkey);
                this.setSaveInProgress(false);
            }
        };
    }

    protected genericErrorHandler(error: HttpErrorResponse | Error): void {
        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(error));
        this.changeDetector.markForCheck();
    }

    cancel(): void {
        this.dialogHideHelper.call(() => {
            this.copyMode = false;
            this.newItem = false;
            this.setDisplayDialog(false);
            this.item = this.getNewItem();
            this.resetFile();
            this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedItem);
        });
    }

    protected resetFile(): void {
        this.file = null;
    }

    onFileChange(newFile: File): void {
        this.file = newFile;
        if (!newFile) {
            this.resetFile();
            this.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    isPermitted(requiredPermission: { roles: ApplicationPrivilege[] }): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    setErrors(error: HttpErrorResponse | Error): void {
        this.errors.handle(error);
    }

    protected setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
            if (display) {
                this.dialogHideHelper.reset();
            }
        }
    }

    rowTrackById = (index: number, item: Item): string => {
        return `${item.id}`;
    }
}
