<p-dialog [visible]="true" (onHide)="cancel()" [modal]="true" [resizable]="false" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_DEALER_DISCOUNT' | translate }}
        </div>
    </p-header>
    <div class="new-form-400">
        <div class="new-form-row">
            <app-input-text inputId="discountValue"
                            label="{{ 'OFFER.PRODUCTION_ORDERS.CHANGE_DEALER_DISCOUNT.VALUE' | translate }}"
                            [maxlength]="100"
                            [(validationMessageKey)]="validationErrors['discountValue']"
                            [(ngModel)]="temporaryDiscount.discountValue" [required]="true"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-checkbox inputId="cascade"
                          label="{{ 'OFFER.PRODUCTION_ORDERS.CHANGE_DEALER_DISCOUNT.CASCADE' | translate }}"
                          [(validationMessageKey)]="validationErrors['cascade']"
                          [(ngModel)]="temporaryDiscount.cascade"></app-checkbox>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveDealerDiscountButton" label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="cancel()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
