<div [attr.id]="dialogId" class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true" (onHide)="cancel()"
              [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title confirm-action-header">
                {{ 'TERMS_OF_USE.DIALOG.HEADER' | translate }}
            </div>
        </p-header>

        <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
            <div class="new-form-row">
                <p-dataTable [value]="userConsents"
                             [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
                    <p-column field="termsOfUseVersion" header="{{ 'TERMS_OF_USE.DIALOG.TERMS_OF_USE_VERSION' | translate }}"
                              [sortable]="false">
                    </p-column>
                    <p-column field="date" header="{{ 'TERMS_OF_USE.DIALOG.DATE_OF_CONSENT' | translate }}"
                              [sortable]="false">
                        <ng-template let-consent="rowData" pTemplate="body">
                            {{ consent.date | momentCurrentLang | amDateFormat: 'L LT' }}
                        </ng-template>
                    </p-column>
                </p-dataTable>
            </div>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="cancel"
                                   [label]="'GENERAL.CANCEL' | translate"
                                   type="cancel" [size]="40" (onClick)="cancel()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
