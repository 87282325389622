<div class="report-container" *ngIf="ready">
    <div class="report-item report-charts-block">
        <div class="report-header">
            <div class="date-range-selection-container">{{ 'REPORTS.DATE_RANGE_SELECTOR_LABEL' | translate }}
                <button-with-menu buttonId="dateRangeSelection"
                                  buttonLabel="{{ ('GENERAL.DATE_RANGE_KIND.' + dateRange) | translate }}"
                                  rightButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="12"
                                  [menuType]="menuType.BOTTOM_RIGHT" [menuElements]="selectableDateRanges"
                                  (menuElementSelected)="handleDateRangeChange($event)">
                </button-with-menu>
            </div>
            <app-simple-button *ngIf="permissions.isKoordynator()" buttonId="show-settings-button"
                               [label]="'DASHBOARD.REPORTS.SETTINGS.BUTTON' | translate" [inlineLabel]="false"
                               icon="settings" type="action" [size]="28"
                               (onClick)="showSettingsDialog()"></app-simple-button>
            <ng-container *ngTemplateOutlet="explanationDialogButton; context: { reportType: 'CHARTS' }"></ng-container>
            <div *ngIf="!permissions.isKoordynator()" class="report-header-explanation-padding"></div>
        </div>
        <ng-container *ngFor="let mode of chartModes">
            <ng-container *vLet="getReport(mode) as report">
                <div class="report-chart-container" *vLet="getColor(report.difference) as color">
                    <div class="report-chart-summary" [ngStyle]="{'border-color': color}">
                        <p class="report-chart-summary-label">{{ ('REPORTS.MODE.' + mode) | translate }}:</p>
                        <div class="report-total-amount" [ngStyle]="{'color': color}">
                            <i [ngClass]="report.difference < 0 ? 'fa-rotate-180' : ''"
                               class="fa font-awesome fas fa-arrow-up"></i>{{compactNumberFormat(report.total)}}
                        </div>
                    </div>
                    <div class="report-chart-background">
                        <div class="report-chart">
                            <p-chart type="line" [options]="report.chart.options" [data]="report.chart.data"></p-chart>
                        </div>
                    </div>
                    <app-card-fold [backgroundColor]="'#ffffff'"
                                   [corner]="FoldCorner.BOTTOM_RIGHT"
                                   [foldColor]="color"></app-card-fold>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="report-item">
        <div class="report-header"><b class="report-header-text">{{ 'DASHBOARD.REPORTS.CONVERSIONS_LABEL' | translate }}</b>
            <ng-container
                    *ngTemplateOutlet="explanationDialogButton; context: { reportType: 'CONVERSIONS' }"></ng-container>
            <div class="report-header-explanation-padding"></div>
        </div>
        <div>
            <div class="report-covnersion-content">
                <app-gauge-chart [greenColor]="GREEN_COLOR" [offersCount]="conversionReportData.offers"
                                 [ordersCount]="conversionReportData.orders"
                                 [gaugePercent]="(conversionReportData.orders / conversionReportData.offers)"></app-gauge-chart>
            </div>
        </div>
    </div>
    <div *ngIf="enableSalesTargets && (permissions.isOperator() || permissions.isHandlowiec())" class="report-item">
        <div class="report-header"><b class="report-header-text">{{ 'DASHBOARD.REPORTS.PLANS_LABEL' | translate: { year: currentYear } }}</b>
            <ng-container *ngTemplateOutlet="explanationDialogButton; context: { reportType: 'PLANS' }"></ng-container>
            <div class="report-header-explanation-padding"></div>
        </div>
        <div class="report-plans-content">
            <div *ngIf="!hasCurrentYearSalesTarget">
                {{ 'DASHBOARD.REPORTS.SALES_TARGET_NOT_AVAILABLE' | translate }}
            </div>
            <ng-container *ngIf="hasCurrentYearSalesTarget">
                <h1 class="progress">
                    {{ formatCurrency(salesTarget.currentYear.valuePLN) }}
                    / {{ formatCurrency(salesTarget.currentYearTarget) }}
                </h1>
                <div class="battery">
                    <svg width="100%" height="100%" viewBox="0 0 405 256">
                        <path d="M384,0 L384,75 L405,75 L405,180 L384,180 L384,256 L0,256 L0,0 L384,0 Z M363,21 L21,21 L21,233 L363,233 L363,21 Z "/>
                        <path d="M342,42 L342,213 L42,213 L42,42 L320,42 Z"
                              [attr.transform]="'scale(' + salesTargetProgress + ',1)'"
                              transform-origin="42 0"
                              [attr.fill]="salesTargetBackground"/>
                    </svg>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!enableSalesTargets" class="report-item">
        <div class="report-header"><b class="report-header-text">{{ ('DASHBOARD.REPORTS.PLANS_LABEL') | translate }}</b>
            <ng-container
                    *ngTemplateOutlet="explanationDialogButton; context: { reportType: 'PLANS' }"></ng-container>
            <div class="report-header-explanation-padding"></div>
        </div>
        <div class="report-plans-content">{{ ('DASHBOARD.REPORTS.TBD_LABEL') | translate }}</div>
    </div>
    <ng-template #chartTooltipTemplate
                 let-chartTitle="chartTitle"
                 let-currentYearLabel="currentYearLabel"
                 let-currentYearValue="currentYearValue"
                 let-previousYearLabel="previousYearLabel"
                 let-previousYearValue="previousYearValue"
                 let-top="top" let-left="left" let-titleFontColor="titleFontColor">
        <div class="report-chart-tooltip" [ngStyle]="{ 'top.px': top, 'left.px': left }">
            <div class="report-chart-tooltip-title" [ngStyle]="{ color: titleFontColor }">{{ chartTitle }}</div>
            <div class="report-chart-tooltip-body report-chart-tooltip-curr-year"><span class="report-chart-tooltip-date">{{ currentYearLabel }}</span> 🡢 <b><span class="report-chart-tooltip-value">{{ currentYearValue }}</span></b></div>
            <div class="report-chart-tooltip-body report-chart-tooltip-prev-year"><span class="report-chart-tooltip-date">{{ previousYearLabel }}</span> 🡢 <span class="report-chart-tooltip-value">{{ previousYearValue }}</span></div>
        </div>
    </ng-template>
    <!-- tooltip placed here for z-index reasons (it must be above all charts) -->
    <div #chartTooltip></div>
</div>

<ng-template #explanationDialogButton let-reportType="reportType">
    <div (click)="showExplanationDialog(reportType)" class="report-header-explanation-button"></div>
</ng-template>

<p-dialog #dialog *ngIf="settingsDialogState.exists" [resizable]="false"
          [(visible)]="settingsDialogState.visible" [modal]="true" (onHide)="handleSettingsDialogClosed()">
    <p-header>
        <div class="p-dialog-title dialog-header">
            {{ 'DASHBOARD.REPORTS.SETTINGS.HEADER' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-60vw dialog-content">
        <app-dashboard-report-settings-form [settings]="tempReportSettings"></app-dashboard-report-settings-form>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action"
                               [size]="40" (onClick)="handleSettingsDialogSaved(); dialog.close($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog #setSalesTargetDialog [(visible)]="showSetSalesTargetDialog" [modal]="true">
    <p-header>
        <div class="p-dialog-title dialog-header">
            {{ 'DASHBOARD.REPORTS.SET_SALES_TARGET.HEADER' | translate: { year: currentYear } }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600">
        <div class="new-form-600">
            <div *ngIf="hasPreviousYearSalesTarget" class="new-form-row previous-year-body">
                {{ 'DASHBOARD.REPORTS.SET_SALES_TARGET.PREVIOUS_YEAR_BODY' | translate: {
                    previousYear: currentYear - 1,
                    previousYearValue: formatCurrency(salesTarget.previousYear.valuePLN),
                    previousYearTarget: formatCurrency(salesTarget.previousYearTarget)
                } }}
            </div>
            <div class="new-form-row">
                <app-input-number inputId="currentYearSalesTargetInput"
                                  label="{{ 'SUBSYSTEM-DETAILS.FORM.CURRENT_YEAR_SALES_TARGET' | translate }}"
                                  [min]="0" [allowOnlyIntegers]="true"
                                  [(ngModel)]="newSalesTarget"></app-input-number>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action"
                               [size]="40" [disabled]="newSalesTarget == undefined"
                               (onClick)="handleSalesTargetDialogSaved(); setSalesTargetDialog.close($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel"
                               [size]="40" (onClick)="setSalesTargetDialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
