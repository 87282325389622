export enum CatalogItemType {
    ADDON = 'ADDON',
    COLOR = 'COLOR',
    DISTANCE_FRAME = 'DISTANCE_FRAME',
    GLASS = 'GLASS',
    GRILL = 'GRILL',
    MATERIAL = 'MATERIAL',
    OTHER_FILLING = 'OTHER_FILLING',
    DECORATIVE_FILLING = 'DECORATIVE_FILLING',
    SEAL = 'SEAL',
    WINDOW_SYSTEM = 'WINDOW_SYSTEM',
    CONFIGURABLE_ADDON = 'CONFIGURABLE_ADDON',
    PROFILE_MULLION = 'PROFILE_MULLION',
    PROFILE = 'PROFILE',
    BUSINESS_TYPE = 'BUSINESS_TYPE',
    GLAZING_BEAD = 'GLAZING_BEAD',
    ASSEMBLY = 'ASSEMBLY',
    GENERAL_WINDOW_CHARGE = 'GENERAL_WINDOW_CHARGE',
    SHAPE = 'SHAPE',
    GLAZING = 'GLAZING',
    GLAZING_PACKAGE = 'GLAZING_PACKAGE',
    WEBSHOP_GLAZING_PACKAGE = 'WEBSHOP_GLAZING_PACKAGE',
    ROOF_GLAZING_PACKAGE = 'ROOF_GLAZING_PACKAGE',
    ENTRANCE_GLAZING_PACKAGE = 'ENTRANCE_GLAZING_PACKAGE',
    GATE_SYSTEM = 'GATE_SYSTEM',
    RAIL_SYSTEM = 'RAIL_SYSTEM',
    GATE_PANEL_TYPE = 'GATE_PANEL_TYPE',
    CONFIG_SYSTEM = 'CONFIG_SYSTEM',
}

export class PricingItem {
    relatedItemId: number;
    relatedItemType: CatalogItemType;
}
