import {GrillSegmentType} from "./GrillSegmentType";
import {LineGrillSegment} from "./LineGrillSegment";

export class PricingGrillSegment extends LineGrillSegment {
    angled: boolean;

    constructor(public points: number[]) {
        super(points, GrillSegmentType.PRICING);
    }
}
