<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-60vw">
            <div class="new-form-row">
                <app-input-text inputId="sender"
                                [(ngModel)]="mailConfig.sender"
                                label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.SENDER' | translate }}"
                                [maxlength]="255" [required]="true"
                                [(validationMessageKey)]="validationErrors['sender']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="bcc"
                                [(ngModel)]="mailConfig.bcc"
                                label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.BCC' | translate }}"
                                [maxlength]="255" [required]="false"
                                [(validationMessageKey)]="validationErrors['bcc']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="partnerBcc"
                                [(ngModel)]="mailConfig.partnerOffersBcc"
                                label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.PARTNER_OFFER_BCC' | translate }}"
                                [maxlength]="255" [required]="false"
                                [(validationMessageKey)]="validationErrors['partnerOffersBcc']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-checkbox inputId="attachFile" label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.ATTACH_FILE' | translate }}"
                              [(ngModel)]="mailConfig.attachFile"></app-checkbox>
            </div>

            <div class="new-form-row">
                <h3>{{ "SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.TITLE" | translate }}</h3>
            </div>

            <ng-container *ngIf="!isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="host" [(ngModel)]="mailConfig.mailBoxConfiguration.host"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.HOST' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.host']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="port" [(ngModel)]="mailConfig.mailBoxConfiguration.port"
                                      label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.PORT' | translate }}"
                                      [min]="1" [max]="9999" [allowOnlyIntegers]="true" [required]="true"
                                      [(validationMessageKey)]="validationErrors['mailBoxConfiguration.port']"></app-input-number>
                </div>
            </ng-container>

            <div class="new-form-row">
                <app-radio-button inputId="sslMode"
                                  label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.SSL_MODE' | translate }}"
                                  [(validationMessageKey)]="validationErrors['mailBoxConfiguration.sslMode']"
                                  [options]="sslModes | async" [required]="true"
                                  [(ngModel)]="mailConfig.mailBoxConfiguration.sslMode"></app-radio-button>
            </div>

            <ng-container *ngIf="!isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="username" [(ngModel)]="mailConfig.mailBoxConfiguration.username"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.USERNAME' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.username']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="password" [(ngModel)]="mailConfig.mailBoxConfiguration.password"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BOX_CONFIGURATION.PASSWORD' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.password']"></app-input-text>
                </div>
            </ng-container>

            <ng-container *ngIf="isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="host" [(ngModel)]="mailConfig.mailBoxConfiguration.host"
                                    label="{{ 'MAIL_SETTINGS.FORM.TENANT_ID' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.host']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="username" [(ngModel)]="mailConfig.mailBoxConfiguration.username"
                                    label="{{ 'MAIL_SETTINGS.FORM.CLIENT_ID' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.username']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="password" [(ngModel)]="mailConfig.mailBoxConfiguration.password"
                                    label="{{ 'MAIL_SETTINGS.FORM.CLIENT_SECRET' | translate }}"
                                    [maxlength]="255" [required]="true"
                                    [(validationMessageKey)]="validationErrors['mailBoxConfiguration.password']"></app-input-text>
                </div>
            </ng-container>

            <div class="new-form-row">
                <app-input-text inputId="mailTitle"
                                [(ngModel)]="mailConfig.mailTitle"
                                label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_TITLE' | translate }}"
                                [maxlength]="100" [required]="true"
                                [(validationMessageKey)]="validationErrors['mailTitle']"></app-input-text>
            </div>

            <div class="new-form-row mail-html-input">
                <div>
                    <app-text-area inputId="mailPreheader" [(ngModel)]="mailConfig.mailPreheader"
                                   label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_PREHEADER' | translate }}"
                                   [(validationMessageKey)]="validationErrors['mailPreheader']"></app-text-area>
                </div>
            </div>
            <div class="new-form-row mail-html-input">
                <div>
                    <app-text-area inputId="mailBody" [(ngModel)]="mailConfig.mailBody"
                                   label="{{ 'SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.FORM.MAIL_BODY' | translate }}"
                                   [(validationMessageKey)]="validationErrors['mailBody']"></app-text-area>
                </div>
            </div>
        </div>
    </div>
    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button buttonId="saveSubsystemWebshopMailConfigurationButton"
                               label="{{ 'GENERAL.SAVE' | translate }}"
                               icon="check"
                               type="main-action"
                               [size]="40"
                               (onClick)="save()"></app-simple-button>
        </div>
    </div>
</div>
