import {JsonDeserializable} from "../../../common/crud-common/crudItem";

@JsonDeserializable
export class ProductionOrderStatisticsPoint {

    date: string;
    valuePLN: number;
    valueCurrency: number;

    static fromJSON(jsonObject: any): ProductionOrderStatisticsPoint {
        return Object.assign(new ProductionOrderStatisticsPoint(), jsonObject);
    }
}
