import {PlatformLocation} from "@angular/common";
import {HttpErrorResponse} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, Subject} from "rxjs";
import {finalize, map} from 'rxjs/operators';
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from "../../../auth/permission.service";
import {StorageService} from "../../../auth/storage.service";
import {UserUiConfigService} from "../../../auth/uiconfig/userUiConfig.service";
import {ButtonWithMenuElementSelectedEvent} from "../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../common/button-with-menu/MenuType";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {Country} from '../../../common/enums/country';
import {OfferStatus} from "../../../common/enums/OfferStatus";
import {ExchangeService} from "../../../common/exchange.service";
import {OfferStatusProvider} from "../../../common/offerStatusProvider";
import {SavedFilterService} from "../../../common/saved-filter/saved-filter.service";
import {CrudComponent} from "../../../common/service/crud.component";
import {DataTableColumnBuilder, SavedShownColumns} from "../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../common/service/export.component";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {TranslatedSelectItemBuilder} from "../../../common/service/translated.select.item.builder";
import {Currencies} from "../../../currencies";
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ErrorResponse} from "../../errors/errorResponse";
import {SubsystemService} from '../../subsystem/subsystem.service';
import {HasSavedFilter} from '../has-saved-filter';
import {Offer} from "../offer";
import {prepareOfferOrderForCsvExport} from '../offer-csv-helpers';
import {OffersService} from "../offer-service";
import {PaymentPackageService} from "../payment-package/payment-package.service";
import {PrintableItem} from '../print-dialog/print-dialog.component';
import {PrintableSection} from '../print-dialog/printable-section.enum';
import {
    ShippingSimulationFloatButton
} from "../shipping/shipping-simulation/shipping-simulation-float-button/shipping-simulation-float-button";
import {
    ShippingSimulationSidebarComponent
} from "../shipping/shipping-simulation/shipping-simulation-sidebar/shipping-simulation-sidebar.component";
import {ShippingSimulationService} from "../shipping/shipping-simulation/shipping-simulation.service";
import {StatusTransitionDialogService} from "../status-transition-dialog/status-transition-dialog.service";
import {StatusTransition} from "../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../status-transition-dialog/StatusTransitionHelper";
import {Comment} from "./comment";
import {CreateOfferMode} from "./create-offer/create-offer-mode";
import {CommentDialogMode} from "./position/position-list/comment-dialog/comment-dialog-mode";
import {DialogType} from "./position/position-list/position-list-dialogs";

enum OfferDialogId {
    NONE = -1,
    DELETE_CONFIRM = 1,
    BULK_STATUS_CHANGE = 2,
    HIDE_DELETED_OFFER = 3,
    COMMENTS = 6,
    MESSAGES = 7,
    HISTORY = 8,
    CONFIRM_PARTIAL_SIMULATION = 9,
    DETAILED_PRICING = 10,
}

type OfferAction = 'DELETE' | 'COMMENTS' | 'COPY' | 'COPY_TO_PARTNER' | 'SHOW_DETAILS' | 'SHOW_MESSAGES'
    | 'CHANGE_STATUS' | 'REMOVE_LOCK' | 'REVERT_STATUS_TO_NEW_OFFER' | 'SHOW_HISTORY' | 'HIDE_DELETED_OFFER' | 'DETAILED_PRICING';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['../../shared-styles.css', '../../../common/offer-status-colors.css'],
    providers: [OffersService, SubsystemService, ExportComponent, StorageService, UserUiConfigService, ExchangeService,
        StatusTransitionDialogService, DataServiceHelper, PaymentPackageService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffersComponent extends CrudComponent implements OnInit, OnDestroy, HasSavedFilter {

    private readonly ACQUIRE_OFFERLOCK_ID = 'acquireOfferLock';
    readonly OFFERS_TABLE_ID: string = 'offersTable';

    private ignoreStoredFilter: boolean;
    private showPageWarning = false;

    menuType = MenuType;
    readonly OfferDialogId = OfferDialogId;

    currentActionOffer: Offer;
    currentUserLogin: string;
    dialogType = DialogType;
    openedDialogNumber: OfferDialogId = OfferDialogId.NONE;
    newComment: Comment = new Comment();
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    offers: Offer[];
    selectedItem: Offer;
    selectedItems: Offer[] = [];
    printableItems: PrintableItem[] = [];
    userLang: string;
    availableSubsystems: SelectItem[] = [];
    availableStatuses: TranslatedSelectItem[] = [];
    availableCurrencies: MenuElement[] = [];
    selectedSubsystems: string[] = [];
    selectedStatuses: OfferStatus[] = [];
    selectedCurrency;
    defaultCurrency: Currencies;
    incomingClientId: number;
    filterByCurrentUser: boolean;
    filterByOfferNumber: string;
    filterByClientGroup: string;
    allSelectedState = TristateCheckboxState.UNCHECKED;
    spaceHotkey: Hotkey;
    bulkStatusChangeHotkey: Hotkey;

    commentDialogMode: CommentDialogMode = CommentDialogMode.OFFER;

    showPrintButton = false;
    shippingCalculationInProgress: boolean;

    transitionsDialogVisible = false;
    possibleTransitions: StatusTransition[] = [];
    printDialogVisible = false;
    PrintableSection = PrintableSection;

    filterCountry: Observable<SelectItem[]>;
    countryUsedFilter: SelectItem;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    @ViewChild('dt', {static: true}) datatable: Table;
    @ViewChild('shippingSidebar', {static: true}) shippingSimulationSidebar: ShippingSimulationSidebarComponent;
    @ViewChild('shippingFloatButton', {static: true}) shippingFloatButton: ShippingSimulationFloatButton;

    private componentInitialized = new Subject<void>();

    constructor(public permissions: Permissions,
                private currentUserService: CurrentUserService,
                public router: Router,
                private route: ActivatedRoute,
                private location: PlatformLocation,
                private subsystemService: SubsystemService,
                public offerService: OffersService,
                public exchangeService: ExchangeService,
                private translatedSelectItemService: TranslatedSelectItemService,
                public storage: StorageService,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private statusTransitionDialogService: StatusTransitionDialogService,
                private shippingSimulationService: ShippingSimulationService,
                private errors: CommonErrorHandler) {
        super(injector, changeDetector, 'OffersComponent', true);
        this.userLang = this.translate.currentLang;
        this.spaceHotkey = new Hotkey('space', (): boolean => {
            this.changeSelectionOfItem();
            return false;
        });
        this.bulkStatusChangeHotkey = new Hotkey('ctrl+shift+s', () => {
            this.setOpenedDialogNumber(OfferDialogId.BULK_STATUS_CHANGE);
            return false;
        }, undefined, this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}) ? 'GENERAL.HOTKEYS.MASS_STATUS_CHANGE' : undefined);
    }

    getDatatable(): TableToDatatableInterfaceAdapter {
        return TableToDatatableInterfaceAdapter.create(this.datatable);
    }

    ngOnInit() {
        this.filterCountry = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', '');
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.showPageWarning = this.route.snapshot.paramMap.has('showPageWarning');
        this.filterByOfferNumber = this.route.snapshot.queryParamMap.get('offerNumber');
        this.filterByClientGroup = this.route.snapshot.queryParamMap.get('filterByClientGroup');
        this.currentUserLogin = this.currentUserService.currentUserName;
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            this.hotkeysService.add(this.newElementHotkey);
            this.hotkeysService.add(this.copyHotkey);
        }
        this.initShownColumns();

        this.availableCurrencies = Object.keys(Currencies).map(currency => new MenuElementBuilder()
            .setTitle(currency).setIdentifier(currency).build());

        if (this.shippingSimulationService.checkCalculationFinishedInterval) {
            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.checkCalculationFinishedInterval.pipe(
                finalize(() => {
                    this.shippingCalculationInProgress = false;
                    this.changeDetector.markForCheck();
                }))
                .subscribe();
        }
        this.shippingFloatButton.refresh();

        this.createTable();
        this.initIncomingClientId();
        this.resetDefaultFilterSelection();
        this.initLastSelectedItem();
        this.initFilterByCurrentUser();
        forkJoin({
            defaultCurrency: this.subsystemService.getDefaultCurrency(),
            subsystems: this.subsystemService.getSelectionItems(),
            exchangeRates: this.exchangeService.initializeExchangeRates(),
            isWebshopPartner: this.subsystemService.currentUserSubsystemHasPartners()
        }).subscribe({
            next: data => {
                this.defaultCurrency = data.defaultCurrency;
                this.selectedCurrency = this.defaultCurrency;
                this.availableSubsystems = data.subsystems.sort((a, b) => a.label.localeCompare(b.label));
                this.langTranslateSubscription.push(this.translate.onLangChange.subscribe(event => {
                    this.userLang = event.lang;
                    this.loadItemsLazy(this.lastLoadEvent);
                }));
                this.exchangeService.storeExchangeRates(data.exchangeRates);
                this.initAvailableStatutes(data.isWebshopPartner);
            },
            complete: () => {
                this.hotkeysService.add(this.spaceHotkey);
                this.hotkeysService.add(this.bulkStatusChangeHotkey);
                this.componentInitialized.complete();
                this.changeDetector.markForCheck();
            }
        });
    }

    private initIncomingClientId(): void {
        if (this.route.snapshot.queryParamMap.has('clientId')) {
            this.incomingClientId = +this.route.snapshot.queryParamMap.get('clientId');
        }
    }

    private initFilterByCurrentUser(): void {
        if (this.filterByCurrentUser == undefined) {
            this.filterByCurrentUser = true;
        }
        if (this.route.snapshot.paramMap.has('forCurrentUser')) {
            this.filterByCurrentUser = this.route.snapshot.paramMap.get('forCurrentUser') === 'true';
        }
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.spaceHotkey);
        this.hotkeysService.remove(this.bulkStatusChangeHotkey);
        super.ngOnDestroy();
    }

    private initAvailableStatutes(isWebshopPartner: boolean): void {
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('OFFER.STATUS.NEW_OFFER', OfferStatus.NEW_OFFER)
                .add('OFFER.STATUS.TO_REVISE', OfferStatus.TO_REVISE)
                .add('OFFER.STATUS.TO_SEND', OfferStatus.TO_SEND)
                .add('OFFER.STATUS.DELETED', OfferStatus.DELETED)
                .add('OFFER.STATUS.TO_VERIFY', OfferStatus.TO_VERIFY)
                .add('OFFER.STATUS.REVIEWED', OfferStatus.REVIEWED)
                .add('OFFER.STATUS.TO_REVIEW', OfferStatus.TO_REVIEW)
                .add('OFFER.STATUS.COPY_DRAFT', OfferStatus.COPY_DRAFT)
                .build();
        }

        if (this.isPermitted({roles: ['ROLE_OPERATOR']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('OFFER.STATUS.NEW_OFFER', OfferStatus.NEW_OFFER)
                .addConditionally('OFFER.STATUS.PARTNER_NEW_OFFER', OfferStatus.PARTNER_NEW_OFFER, isWebshopPartner)
                .add('OFFER.STATUS.SENT_TO_PARTNER', OfferStatus.SENT_TO_PARTNER)
                .add('OFFER.STATUS.TO_REVISE', OfferStatus.TO_REVISE)
                .add('OFFER.STATUS.TO_SEND', OfferStatus.TO_SEND)
                .add('OFFER.STATUS.TO_REVIEW', OfferStatus.TO_REVIEW)
                .add('OFFER.STATUS.REVIEWED', OfferStatus.REVIEWED)
                .add('OFFER.STATUS.TO_VERIFY', OfferStatus.TO_VERIFY)
                .add('OFFER.STATUS.VERIFICATION_FOR_APPROVAL', OfferStatus.VERIFICATION_FOR_APPROVAL)
                .add('OFFER.STATUS.VERIFICATION_REJECTED', OfferStatus.VERIFICATION_REJECTED)
                .add('OFFER.STATUS.VERIFICATION_APPROVED', OfferStatus.VERIFICATION_APPROVED)
                .add('OFFER.STATUS.COPY_DRAFT', OfferStatus.COPY_DRAFT)
                .add('OFFER.STATUS.DELETED', OfferStatus.DELETED)
                .add('OFFER.STATUS.CANCEL', OfferStatus.CANCEL)
                .build();
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('OFFER.STATUS.NEW_OFFER', OfferStatus.NEW_OFFER)
                .add('OFFER.STATUS.PARTNER_NEW_OFFER', OfferStatus.PARTNER_NEW_OFFER)
                .add('OFFER.STATUS.SENT_TO_PARTNER', OfferStatus.SENT_TO_PARTNER)
                .add('OFFER.STATUS.TO_REVISE', OfferStatus.TO_REVISE)
                .add('OFFER.STATUS.TO_SEND', OfferStatus.TO_SEND)
                .add('OFFER.STATUS.TO_REVIEW', OfferStatus.TO_REVIEW)
                .add('OFFER.STATUS.REVIEWED', OfferStatus.REVIEWED)
                .add('OFFER.STATUS.TO_VERIFY', OfferStatus.TO_VERIFY)
                .add('OFFER.STATUS.VERIFICATION_FOR_APPROVAL', OfferStatus.VERIFICATION_FOR_APPROVAL)
                .add('OFFER.STATUS.VERIFICATION_REJECTED', OfferStatus.VERIFICATION_REJECTED)
                .add('OFFER.STATUS.VERIFICATION_APPROVED', OfferStatus.VERIFICATION_APPROVED)
                .add('OFFER.STATUS.COPY_DRAFT', OfferStatus.COPY_DRAFT)
                .add('OFFER.STATUS.DELETED', OfferStatus.DELETED)
                .add('OFFER.STATUS.CANCEL', OfferStatus.CANCEL)
                .build();
        }

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('OFFER.STATUS.NEW_OFFER', OfferStatus.NEW_OFFER)
                .add('OFFER.STATUS.PARTNER_NEW_OFFER', OfferStatus.PARTNER_NEW_OFFER)
                .add('OFFER.STATUS.SENT_TO_PARTNER', OfferStatus.SENT_TO_PARTNER)
                .add('OFFER.STATUS.TO_REVISE', OfferStatus.TO_REVISE)
                .add('OFFER.STATUS.TO_SEND', OfferStatus.TO_SEND)
                .add('OFFER.STATUS.TO_REVIEW', OfferStatus.TO_REVIEW)
                .add('OFFER.STATUS.REVIEWED', OfferStatus.REVIEWED)
                .add('OFFER.STATUS.TO_VERIFY', OfferStatus.TO_VERIFY)
                .add('OFFER.STATUS.VERIFICATION_FOR_APPROVAL', OfferStatus.VERIFICATION_FOR_APPROVAL)
                .add('OFFER.STATUS.VERIFICATION_REJECTED', OfferStatus.VERIFICATION_REJECTED)
                .add('OFFER.STATUS.VERIFICATION_APPROVED', OfferStatus.VERIFICATION_APPROVED)
                .add('OFFER.STATUS.COPY_DRAFT', OfferStatus.COPY_DRAFT)
                .add('OFFER.STATUS.DELETED', OfferStatus.DELETED)
                .add('OFFER.STATUS.CANCEL', OfferStatus.CANCEL)
                .build();
        }
    }

    submit() {
    }

    selectAllChange() {
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.offers);
        }

        this.handlePrintButtonState();

        this.changeDetector.markForCheck();
    }

    isSelectedItem(item: Offer): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: Offer) {
        let index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }
        this.handlePrintButtonState();

        this.refreshAllSelectedFlag();
    }

    private handlePrintButtonState() {
        const anyOfferInDeletedStatus = this.selectedItems.some(offer => offer.status === OfferStatus.DELETED);
        this.showPrintButton = this.selectedItems.length > 0 && this.selectedItems.length <= 20 && !anyOfferInDeletedStatus;
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.offers.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    resetDefaultFilterSelection() {
        let forcedStatuses = this.route.snapshot.paramMap.get('statusFilter');
        let selectedStatuses: OfferStatus[];
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        } else {
            if (this.filterByOfferNumber || this.filterByClientGroup) {
                this.filterByCurrentUser = false;
                this.lastLoadEvent = {};
                let filter = {
                    filters: {
                        offerNumber: {value: this.filterByOfferNumber},
                        clientGroupName: {value: this.filterByClientGroup}
                    }
                };
                this.applySavedFilter(filter);
                selectedStatuses = OfferStatusProvider.getOfferStatusesBeforeOrder();
                selectedStatuses.push(OfferStatus.DELETED);
            }
        }
        if (forcedStatuses) {
            selectedStatuses = forcedStatuses.split(',').map(status => OfferStatus[status]);
        }
        this.restoreStatusesSelection(selectedStatuses);
    }

    private restoreStatusesSelection(selectedStatuses: OfferStatus[]): void {
        if (selectedStatuses) {
            this.selectedStatuses = selectedStatuses;
            if (!this.columnByField['status']) {
                this.onDisplayedColumnsChange([...this.selectedColumns, 'status']);
            }
        } else {
            if (this.selectedStatuses.length === 0) {
                if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})) {
                    this.selectedStatuses.push(OfferStatus.NEW_OFFER);
                    this.selectedStatuses.push(OfferStatus.TO_REVISE);
                }
                if (this.isPermitted({roles: ['ROLE_OPERATOR']})) {
                    for (let offerStatus of OfferStatusProvider.getOfferStatusesBeforeOrder()) {
                        this.selectedStatuses.push(offerStatus);
                    }
                    if (this.incomingClientId) {
                        this.selectedStatuses.push(OfferStatus.DELETED);
                    }
                }
                if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
                    this.selectedStatuses.push(OfferStatus.TO_REVIEW, OfferStatus.TO_VERIFY);
                }
            }
        }
        if (this.selectedStatuses.length > 0) {
            const statusColumn = this.getColumns().find(column => column.field === 'status');
            if (statusColumn != undefined) {
                statusColumn.defaultFilterValue = {
                    label: '',
                    value: this.selectedStatuses.join(';')
                };
            }
            this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.datatable.filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    handleStatusFilterChange(statuses: OfferStatus[]): void {
        this.selectedStatuses = statuses;
        this.datatable.filter(statuses.join(';'), 'status', 'in');
    }

    filterClientId(event: LazyLoadEvent): void {
        if (this.incomingClientId != undefined) {
            event.filters['clientId'] = {value: this.incomingClientId.toString(), matchMode: undefined};
        }
    }

    filterCurrentUser(event: LazyLoadEvent): void {
        let forCurrentUser = this.incomingClientId != undefined ? false : this.filterByCurrentUser;
        event.filters['forCurrentUser'] = {value: forCurrentUser, matchMode: undefined};
    }

    private filterExchangeRate(event: LazyLoadEvent): void {
        this.exchangeService.applyRateToRangeFilters(['retailSellNetVal', 'retailSellGrossVal', 'sellNetVal', 'sellGrossVal',
            'buyVenskaNetVal', 'buyVenskaGrossVal', 'buyNetVal', 'buyGrossVal', 'netIncome', 'grossIncome',
            'netVenskaIncome', 'grossVenskaIncome', 'sourceOfferSellNetVal', 'sourceOfferSellGrossVal'], event, this.selectedCurrency);
    }

    getPriceInDefaultCurrency(priceInPln: number, priceInOfferCurrency: number, exchangeRate: number,
                              subsystemManualExchangeRate?: number): string {
        if (priceInOfferCurrency != undefined) {
            return priceInOfferCurrency.toFixed(2);
        }
        return ExchangeService.getPriceInDefaultCurrency(priceInPln, exchangeRate, subsystemManualExchangeRate);
    }

    getPriceInSelectedCurrency(priceInPln: number, priceInOfferCurrency: number,
                               offer: Offer, applySubsystemManualExchangeRate = false): string {
        if (this.selectedCurrency === offer.currency) {
            if (priceInOfferCurrency != undefined) {
                return priceInOfferCurrency.toFixed(2);
            }
            if (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate) {
                return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate, offer.subsystemManualExchangeRate);
            }
            return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate);
        }
        if (priceInPln == undefined) {
            const plnPriceToOfferCurrencyRatio = (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate)
                ? offer.subsystemManualExchangeRate
                : offer.exchangeRate;
            return this.exchangeService.getPriceInCurrency(priceInOfferCurrency, offer.currency, this.selectedCurrency,
                plnPriceToOfferCurrencyRatio);
        }
        return this.exchangeService.getPLNPriceInCurrency(priceInPln, this.selectedCurrency);
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        if (this.filterByOfferNumber != undefined && shownColumns != undefined && shownColumns.offerNumber == undefined) {
            shownColumns.offerNumber = {};
        }
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('offerNumber', 'OFFER.FORM.OFFER_NUMBER', true)
            .add('alternateOfferNumber', 'OFFER.FORM.ALT_OFFER_NUMBER', this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}))
            .makeFilterable().makeSortable()
            .add('createdDate', 'OFFER.FORM.CREATED_DATE', false).makeFilterable().makeSortable()
            .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('lastStatusChange', 'OFFER.FORM.LAST_STATUS_CHANGE', true)
            .add('status', 'OFFER.FORM.STATUS', true).setFilterValues(this.availableStatuses)
            .add('lastModifiedDate', 'OFFER.FORM.LAST_MODIFIED_DATE', true)
            .add('validFrom', 'OFFER.FORM.VALID_FROM', false).makeFilterable().makeSortable()
            .add('validTo', 'OFFER.FORM.VALID_TO', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('subsystemName', 'OFFER.FORM.SUBSYSTEM', false).makeFilterable().makeSortable();
        }

        builder.add('clientManagerName', 'OFFER.FORM.CLIENT_MANAGER', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}))
            .makeFilterable().makeSortable()
            .add('subClientManagerName', 'OFFER.FORM.SUB_CLIENT_MANAGER', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}))
            .makeFilterable().makeSortable()
            .add('clientGroupName', 'OFFER.FORM.CLIENT_GROUP', this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR']}))
            .makeFilterable().makeSortable()
            .add('merchantGroupName', 'OFFER.FORM.MERCHANT_GROUP', this.isPermitted({roles: ['ROLE_OPERATOR']})).makeFilterable()
            .add('clientName', 'OFFER.FORM.CLIENT_NAME', true)
            .add('clientEmail', 'OFFER.FORM.CLIENT_EMAIL', true)
            .add('clientIdentifier', 'OFFER.FORM.CLIENT_IDENTIFIER', false).makeFilterable().makeSortable()
            .add('vatSell', 'OFFER.FORM.VAT_SELL', false).makeFilterable().makeSortable()
            .add('sellNetVal', 'OFFER.FORM.SELL_NET_VAL', true)
            .add('sellGrossVal', 'OFFER.FORM.SELL_GROSS_VAL', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']})) {
            builder.add('vatBuy', 'OFFER.FORM.VAT_BUY', false).makeFilterable().makeSortable()
                .add('buyNetVal', 'OFFER.FORM.BUY_NET_VAL', true)
                .add('buyGrossVal', 'OFFER.FORM.BUY_GROSS_VAL', false).makeFilterable().makeSortable();
        }

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('buyVenskaNetVal', 'OFFER.FORM.BUY_VENSKA_NET_VAL', true)
                .add('buyVenskaGrossVal', 'OFFER.FORM.BUY_VENSKA_GROSS_VAL', false).makeFilterable().makeSortable();
        }

        builder.add('ownAddonsNetCost', 'OFFER.FORM.OWN_ADDONS_NET_COST', true).makeFilterable().makeSortable()
            .add('ownAddonsGrossCost', 'OFFER.FORM.OWN_ADDONS_GROSS_COST', false).makeFilterable().makeSortable()
            .add('assemblyNetCost', 'OFFER.FORM.ASSEMBLY_NET_COST', true).makeFilterable().makeSortable()
            .add('assemblyGrossCost', 'OFFER.FORM.ASSEMBLY_GROSS_COST', false).makeFilterable().makeSortable()
            .add('transportNetCost', 'OFFER.FORM.TRANSPORT_NET_COST', true).makeFilterable().makeSortable()
            .add('transportGrossCost', 'OFFER.FORM.TRANSPORT_GROSS_COST', false).makeFilterable().makeSortable()
            .add('windowsCount', 'OFFER.FORM.WINDOWS_COUNT', true).makeFilterable().makeSortable();
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR']})) {
            builder.add('netIncome', 'OFFER.FORM.NET_INCOME', false).makeFilterable().makeSortable()
                .add('grossIncome', 'OFFER.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
        }

        if (this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
            builder.add('netRetailIncome', 'OFFER.FORM.NET_RETAIL_INCOME', false).makeFilterable().makeSortable()
                .add('grossRetailIncome', 'OFFER.FORM.GROSS_RETAIL_INCOME', false).makeFilterable().makeSortable()
                .add('vatRetailSell', 'OFFER.FORM.VAT_RETAIL_SELL', false).makeFilterable().makeSortable()
                .add('retailSellNetVal', 'OFFER.FORM.RETAIL_SELL_NET_VAL', true)
                .add('retailSellGrossVal', 'OFFER.FORM.RETAIL_SELL_GROSS_VAL', false).makeFilterable().makeSortable()
                .add('sellerClientGroupName', 'OFFER.FORM.SELLER_CLIENT_GROUP', true).makeFilterable().makeSortable()
                .add('sellerClientName', 'OFFER.FORM.SELLER_CLIENT_NAME', true).makeFilterable().makeSortable()
                .add('sellerClientEmail', 'OFFER.FORM.SELLER_CLIENT_EMAIL', true).makeFilterable().makeSortable()
                .add('sellerClientIdentifier', 'OFFER.FORM.SELLER_CLIENT_IDENTIFIER', false).makeFilterable().makeSortable();
        }

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('netVenskaIncome', 'OFFER.FORM.NET_INCOME', false)
                .add('grossVenskaIncome', 'OFFER.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
        }

        builder.add('merchantName', 'OFFER.FORM.MERCHANT_NAME', this.isPermitted({roles: ['ROLE_OPERATOR']}))
            .makeFilterable()
            .makeSortable();

        builder.add('deliveryAddressStreet', 'OFFER.FORM.ADDRESS.STREET', false).makeFilterable().makeSortable()
            .add('deliveryAddressCity', 'OFFER.FORM.ADDRESS.CITY', false).makeFilterable().makeSortable()
            .add('deliveryAddressZip', 'OFFER.FORM.ADDRESS.ZIP', false).makeFilterable().makeSortable()
            .add('deliveryAddressCountry', 'OFFER.FORM.ADDRESS.COUNTRY', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_READ_WEBSHOP_DETAILS']})) {
            builder.add('sourceOfferNumber', 'OFFER.FORM.SOURCE_OFFER_NUMBER', true)
                .add('sourceOfferSellVat', 'OFFER.FORM.SOURCE_OFFER_VAT', false).makeFilterable().makeSortable()
                .add('sourceOfferSellNetVal', 'OFFER.FORM.SOURCE_OFFER_NET_VAL', true)
                .add('sourceOfferSellGrossVal', 'OFFER.FORM.SOURCE_OFFER_GROSS_VAL', false).makeFilterable().makeSortable();
        }

        super.init(builder.build());
        this.saveShownColumns();
    }

    rowTrackById(index: number, item: Offer) {
        return item.id;
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true): void {
        this.componentInitialized.subscribe({
            complete: () => {
                this.loadItemsLazySubscription(event, updateOriginalTableFilters);
            }
        });
    }

    private loadItemsLazySubscription(event: LazyLoadEvent, updateOriginalTableFilters: boolean): void {
        super.loadItemsLazy(event);
        event.filters['isOrder'] = {value: 'false'};
        this.filterCurrentUser(event);
        this.storeLazyLoadEvent(event, this.storage);
        this.filterClientId(event);
        this.filterExchangeRate(event);
        if (event.origin == undefined) {
            event.origin = this.getDatatable();
        }
        this.lastLoadEvent = event;
        this.onDataLazyLoad.emit(this.lastLoadEvent);
        if (updateOriginalTableFilters) {
            this.onUpdateOriginalFilters.emit(this.lastLoadEvent);
        }
        this.allSelectedState = TristateCheckboxState.UNCHECKED;
        this.selectedItems = [];
        this.handlePrintButtonState();
        this.offerService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('OffersComponent `getPage` success:');
                    this.offers = data.data;
                    this.totalRecords = data.totalRecords;
                    this.totalRecordsChange.emit(this.totalRecords);
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    const selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.offers, event);
                    if (this.showPageWarning && this.selectedItem != undefined && selectedItem != undefined
                        && this.selectedItem.id !== selectedItem.id) {
                        this.growlMessageController.info('OFFER.LIST.SELECTION_ON_OTHER_PAGE');
                    }
                    this.showPageWarning = false;
                    this.selectedItem = selectedItem;
                },
                error: error => {
                    console.error('OffersComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('OffersComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().resetFilters();
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        // delete legacy filter
        delete filter.filters.subsystemName;

        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applySelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['forCurrentUser'];
        if (currentUserFilter != undefined) {
            this.filterByCurrentUser = '' + currentUserFilter.value === 'true';
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedStatuses, filter.filters['status']);
        this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    applySelectFilters(filter: LazyLoadEvent): void {
        let defaultDeliveryAddressCountryFilter = filter.filters['deliveryAddressCountry'];
        this.countryUsedFilter = defaultDeliveryAddressCountryFilter && defaultDeliveryAddressCountryFilter.value;
        this.getDatatable().setFilterValue(this.countryUsedFilter, 'deliveryAddressCountry', 'contains');
        this.changeDetector.markForCheck();
    }

    private initLastSelectedItem(): void {
        if (this.route.snapshot.paramMap.has('lastSelection')) {
            this.selectedItem = new Offer();
            this.selectedItem.id = +this.route.snapshot.paramMap.get('lastSelection');
        }
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    getExportData(): Observable<object[]> {
        const loadEvent = this.lastLoadEvent;
        return forkJoin({
            offers: this.offerService.getItems(0, null, loadEvent.filters, loadEvent.sortField, loadEvent.sortOrder),
            translations: this.translate.get(Object.keys(OfferStatus).map(status => 'OFFER.STATUS.' + status))
        }).pipe(map(list => list.offers.data.map(item =>
            prepareOfferOrderForCsvExport(item, (item2) => list.translations['OFFER.STATUS.' + item2.status], []))));
    }

    redirectToCreateOfferComponent() {
        this.router.navigate(['/features/create-offer', {
            mode: CreateOfferMode.NEW
        }]);
        return false;
    }

    removeFiltersNotSetOnTableColumns() {
        this.incomingClientId = undefined;
        this.filterByCurrentUser = false;
        this.location.replaceState(null, null, 'features/offer;component=offer');
        if (this.lastLoadEvent) {
            this.lastLoadEvent.filters['clientId'] = undefined;
        }
        this.loadItemsLazy(this.lastLoadEvent);
        return false;
    }

    enableFilterByCurrentUser() {
        this.filterByCurrentUser = true;
        this.loadItemsLazy(this.lastLoadEvent);
        return false;
    }

    actionOnClick(action: OfferAction, offer: Offer) {
        console.info("OffersComponent: clicked action '" + action + "' for offer: " + offer.id);
        this.currentActionOffer = offer;
        switch (action) {
            case 'DELETE':
                this.setOpenedDialogNumber(OfferDialogId.DELETE_CONFIRM);
                break;
            case 'COMMENTS':
                this.newComment = new Comment();
                this.newComment.offerId = this.currentActionOffer.id;
                this.setOpenedDialogNumber(OfferDialogId.COMMENTS);
                break;
            case 'COPY':
                this.copyOffer(offer);
                break;
            case 'COPY_TO_PARTNER':
                this.copyOffer(offer, CreateOfferMode.COPY_TO_PARTNER);
                break;
            case 'SHOW_DETAILS':
                this.tryToAcquireOfferLockAndNavigateToPositionList(this.currentActionOffer.id, this.currentActionOffer.offerLockUserLogin);
                break;
            case 'SHOW_MESSAGES':
                this.setOpenedDialogNumber(OfferDialogId.MESSAGES);
                break;
            case 'CHANGE_STATUS':
                this.possibleTransitions = this.statusTransitionDialogService.createPossibleTransitions(offer,
                    (actionName): void => {
                        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                        this.onActionSuccess(actionName);
                    },
                    (actionName, error): void => {
                        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                        this.onActionError(actionName, error);
                    });

                if (this.possibleTransitions.length === 1) {
                    this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);

                    this.possibleTransitions[0].item.command();
                } else if (this.possibleTransitions.length > 1) {
                    this.transitionsDialogVisible = true;
                }

                break;
            case 'REMOVE_LOCK':
                this.offerService.unblockOffer(this.currentActionOffer.id).subscribe({
                    complete: () => {
                        this.showActionSuccessMessage(action);
                        this.currentActionOffer.offerLockUserLogin = null;
                        this.changeDetector.markForCheck();
                    }
                });
                break;
            case 'REVERT_STATUS_TO_NEW_OFFER':
                this.subscribeAction(this.offerService.revertStatusToNewOffer(this.currentActionOffer.id), action);
                break;
            case 'SHOW_HISTORY':
                this.setOpenedDialogNumber(OfferDialogId.HISTORY);
                break;
            case 'HIDE_DELETED_OFFER':
                this.setOpenedDialogNumber(OfferDialogId.HIDE_DELETED_OFFER);
                break;
            case "DETAILED_PRICING":
                this.setOpenedDialogNumber(OfferDialogId.DETAILED_PRICING);
                break;
            default:
                console.error("OffersComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    performAction(action: OfferAction) {
        console.info("OffersComponent: performing action '" + action + "' for offer: " + this.currentActionOffer.id);
        switch (action) {
            case 'DELETE':
                this.subscribeAction(this.offerService.deleteTheOffer(this.currentActionOffer.id), action);
                break;
            case 'HIDE_DELETED_OFFER':
                this.subscribeAction(this.offerService.hideDeletedOffer(this.currentActionOffer.id), action);
                break;
            default:
                console.error("OffersComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    closeTheDialog() {
        this.setOpenedDialogNumber(OfferDialogId.NONE);
        this.newComment = new Comment();
        this.clearError('comment');
    }

    private subscribeAction(action: Observable<void>, actionName: OfferAction) {
        action.subscribe({
            error: error => {
                this.onActionError(actionName, error);
            },
            complete: () => {
                this.onActionSuccess(actionName);
            }
        });
    }

    onActionSuccess(actionName: string): void {
        this.showActionSuccessMessage(actionName);
        this.loadItemsLazy(this.lastLoadEvent);
        console.info('OffersComponent action `' + actionName + '` completed!');
    }

    onActionError(actionName: string, error: HttpErrorResponse): void {
        console.error('OffersComponent action `' + actionName + '` error:', error);
        this.loadItemsLazy(this.lastLoadEvent);
        let errorResponse = new ErrorResponse(error.error);
        if (errorResponse.is400()) {
            this.showErrorMessage(errorResponse);
        } else {
            this.showErrorMessage(errorResponse);
            this.errors.handle(error);
        }
    }

    private showActionSuccessMessage(actionName: string) {
        let message = 'OFFER.ACTIONS.ON_SUCCESS.' + actionName;
        this.growlMessageController.info(message);
    }

    protected showErrorMessage(errorResponse: ErrorResponse) {
        this.growlMessageController.error(errorResponse.message);
    }

    copyOffer(offer: Offer, mode = CreateOfferMode.COPY) {
        let params = {
            offerId: offer.id,
            mode: mode
        };
        if (mode === CreateOfferMode.COPY_TO_PARTNER) {
            params['clientId'] = offer.clientId;
        }
        this.router.navigate(['/features/create-offer', params]);
        return false;
    }

    onRowSelect(event) {
    }

    redirectToPositionList(offer: Offer): void {
        this.tryToAcquireOfferLockAndNavigateToPositionList(offer.id, offer.offerLockUserLogin);
    }

    doShowDialogToAdd() {
        // skip all other things here, since we redirect anyway
        this.showDialogToAdd();
    }

    showDialogToAdd() {
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            this.redirectToCreateOfferComponent();
        }
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.copyOffer(this.selectedItem);
        }
    }

    private changeSelectionOfItem() {
        if (this.selectedItem) {
            this.selectItem(this.selectedItem);
            this.changeDetector.markForCheck();
        }
    }

    protected showAction(action: OfferAction, offer: Offer): boolean {
        switch (action) {
            case 'DELETE':
            case 'COPY':
                return offer.status !== OfferStatus.DELETED;
            case 'COPY_TO_PARTNER':
                return offer.status !== OfferStatus.DELETED && this.permissions.canCopyOfferToPartner();
            case 'SHOW_MESSAGES':
                return offer.status !== OfferStatus.DELETED && offer.highestMessageSeverity != null || offer.pricingOutdated;
            case 'COMMENTS':
            case 'CHANGE_STATUS':
                return true;
            case 'REMOVE_LOCK':
                return offer.offerLockUserLogin != null && this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPERATOR']}) &&
                    this.permissions.userCanEditOffer(offer.status);
            case 'REVERT_STATUS_TO_NEW_OFFER':
            case 'HIDE_DELETED_OFFER':
                return offer.status === OfferStatus.DELETED;
            case 'SHOW_HISTORY':
                return true;
            case 'DETAILED_PRICING':
                return this.permissions.canViewDetailedPricing();
            default:
                return false;
        }
    }

    protected actionEnabled(action: OfferAction, offer: any): boolean {
        switch (action) {
            case 'DELETE':
            case 'CHANGE_STATUS':
            case 'HIDE_DELETED_OFFER':
                return offer.offerLockUserLogin == null || offer.offerLockUserLogin === this.currentUserLogin;
            default:
                return true;
        }
    }

    private addActionButton(action: OfferAction, offer: Offer, rowActions: MenuElement[], id: string, translation?: string): void {
        if (this.showAction(action, offer)) {
            let translationKey = translation || 'OFFER.ACTIONS.TOOLTIPS.' + action;
            let builder = new MenuElementBuilder().setTranslationKey(translationKey).setIdentifier(action + id)
                .setDisabled(!this.actionEnabled(action, offer));
            rowActions.push(builder.build());
        }
    }

    private getOfferSuffix(offer: Offer) {
        return '___' + offer.id;
    }

    getRowActionsBuilder(offer: Offer): () => MenuElement[] {
        return () => this.buildRowActions(offer);
    }

    protected buildRowActions(offer: Offer): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let offerSuffix: string = this.getOfferSuffix(offer);

        const possibleTransitions = this.statusTransitionDialogService.createPossibleTransitions(offer);
        if (possibleTransitions.length > 0 || offer.status === OfferStatus.COPY_DRAFT) {
            this.addActionButton('DELETE', offer, rowActions, offerSuffix);
        }
        this.addActionButton('SHOW_MESSAGES', offer, rowActions, offerSuffix);
        this.addActionButton('COPY', offer, rowActions, offerSuffix);
        if (offer.dataSource === 'WEBSHOP') {
            this.addActionButton('COPY_TO_PARTNER', offer, rowActions, offerSuffix);
        }
        this.addActionButton('COMMENTS', offer, rowActions, offerSuffix);
        this.addChangeStatusRowAction(offer, rowActions, offerSuffix);
        this.addLockedByInfo(offer, rowActions);
        this.addActionButton('REMOVE_LOCK', offer, rowActions, offerSuffix);
        this.addActionButton('REVERT_STATUS_TO_NEW_OFFER', offer, rowActions, offerSuffix);
        this.addActionButton('SHOW_HISTORY', offer, rowActions, offerSuffix);
        this.addActionButton('HIDE_DELETED_OFFER', offer, rowActions, offerSuffix);
        this.addActionButton('DETAILED_PRICING', offer, rowActions, offerSuffix);

        return rowActions;
    }

    private addChangeStatusRowAction(offer: Offer, rowActions: MenuElement[], offerSuffix: string): void {
        let possibleTransitions = this.statusTransitionDialogService.createPossibleTransitions(offer);

        if (possibleTransitions.length === 1) {
            this.addActionButton('CHANGE_STATUS', offer, rowActions, offerSuffix, possibleTransitions[0].item.label);
        } else if (possibleTransitions.length > 1) {
            this.addActionButton('CHANGE_STATUS', offer, rowActions, offerSuffix, 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP');
        }
    }

    private addLockedByInfo(offer: Offer, rowActions: MenuElement[]): void {
        if (offer.offerLockUserLogin != undefined) {
            let params = {userLogin: offer.offerLockUserLogin};
            let title = this.translate.instant('OFFER.ACTIONS.TOOLTIPS.LOCKED_BY_INFO', params);
            let builder = new MenuElementBuilder().setTitle(title).setIdentifier('LOCKED_BY_INFO_' + offer.id)
                .setDisabled(true);
            rowActions.push(builder.build());
        }
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent): void {
        let data = event.identifier.split('___');
        let action = data[0];

        let offerId = +data[1];
        let found = this.offers.find(offer => offer.id === offerId);

        if (found) {
            this.actionOnClick(action as any, found);
        } else {
            console.error("Action called for non existing positionId " + offerId);
        }
    }

    private setOpenedDialogNumber(dialogNumber: OfferDialogId): void {
        if (this.openedDialogNumber !== dialogNumber) {
            this.openedDialogNumber = dialogNumber;
            this.changeDetector.markForCheck();
        }
    }

    handleCurrencyMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.selectedCurrency = event.identifier;
    }

    resetCommentDialog(commentAddedOrEdited: boolean): void {
        if (commentAddedOrEdited) {
            this.loadItemsLazy(this.lastLoadEvent);
        }

        this.closeTheDialog();
    }

    onSaveCommentSuccess(): void {
        this.showActionSuccessMessage('ADD_COMMENT');
    }

    changeSemicolonsToLineBreaks(groups: string): string {
        return groups.replace(/;/g, "\n");
    }

    containsSemicolon(groups: string): boolean {
        return groups.indexOf(";") > 0;
    }

    hideTransitionsDialog(): void {
        this.transitionsDialogVisible = false;
        this.possibleTransitions = [];

        this.changeDetector.markForCheck();
    }

    changeStatus(selectedTransition: StatusTransition): void {
        if (selectedTransition) {
            switch (selectedTransition.action) {
                case 'CHANGE_STATUS':
                    this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                    selectedTransition.item.command();
                    this.hideTransitionsDialog();
                    break;
                default:
                    console.error("Unsupported status transition.", selectedTransition.action);
                    break;
            }
        }
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    addSelectedToShipping(stopIfSimulationImpossibleForAnyPosition: boolean): void {
        this.shippingSimulationService.addOffersToDraft(this.selectedItems.map(item => item.id), stopIfSimulationImpossibleForAnyPosition)
            .subscribe({
                next: result => {
                    if (stopIfSimulationImpossibleForAnyPosition && !result.allPositionsCanBeSimulated) {
                        this.setOpenedDialogNumber(OfferDialogId.CONFIRM_PARTIAL_SIMULATION);
                        return;
                    }
                    this.shippingFloatButton.refresh();

                    if (this.selectedItems.length === result.offersWithWindowPositions) {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFERS_ADDED');
                    } else if (this.selectedItems.length === 1 && result.offersWithWindowPositions === 0) {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFER_WITHOUT_SYSTEM_POSITIONS');
                    } else {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFERS_PARTIALLY_ADDED');
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.growlMessageController.error(new ErrorResponse(error.error).message);
                }
            });
    }

    showShippingSidebar(): void {
        this.shippingSimulationSidebar.show();
    }

    shippingSidebarHidden(): void {
        this.shippingFloatButton.refresh();
    }

    shippingCalculationStarted(): void {
        this.shippingCalculationInProgress = true;
    }

    shippingCalculationFinished(): void {
        this.shippingCalculationInProgress = false;
    }

    private navigateToPositionList(offerId: number, hasLock: boolean): void {
        this.router.navigate(['/features/offer', offerId, 'position', {hasLock: hasLock}]);
    }

    private tryToAcquireOfferLockAndNavigateToPositionList(offerId: number, offerLockUserLogin: string) {
        if (offerLockUserLogin == null) {
            this.blockUiController.block(this.ACQUIRE_OFFERLOCK_ID);
            this.offerService.blockOffer(offerId).subscribe(
                hasLock => {
                    this.blockUiController.unblock(this.ACQUIRE_OFFERLOCK_ID);
                    this.navigateToPositionList(offerId, hasLock);
                });
        } else {
            this.navigateToPositionList(offerId, offerLockUserLogin === this.currentUserLogin);
        }
    }

    public openPrintDialog() {
        this.printableItems = this.selectedItems.map(item => new PrintableItem(item.id, item.offerNumber));
        this.printDialogVisible = true;
    }

    showUnreadCommentIcon(offer: Offer): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return offer.hasCommentUnreadByVenska;
        }
        return offer.hasCommentUnreadBySubsystem;
    }

    showCommentExistsIcon(offer: Offer): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return !offer.hasCommentUnreadByVenska && offer.hasComment;
        }
        return !offer.hasCommentUnreadBySubsystem && offer.hasComment;
    }

    handleCountryFilterChange(country) {
        this.countryUsedFilter = country;
        this.datatable.filter(country, 'deliveryAddressCountry', 'contains');
    }

    handleBulkStatusChangeDialogClose(refreshList: boolean): void {
        if (refreshList) {
            this.onActionSuccess('BULK_STATUS_CHANGE');
        }
        this.closeTheDialog();
    }
}
