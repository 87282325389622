<p-dialog [visible]="true" [resizable]="false" [modal]="true" (onHide)="resetDialog()" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-400">
        <div class="new-form-row" *ngIf="buySellOptions != undefined">
            <app-radio-button inputId="buy" [(validationMessageKey)]="validationErrors['priceIsBuy']"
                              [options]="buySellOptions | async" [(ngModel)]="displayedDialogData.buy"></app-radio-button>
        </div>
        <div class="new-form-row">
            <app-radio-button inputId="net" [(validationMessageKey)]="validationErrors['priceIsNet']"
                              [options]="netGrossOptions | async" [(ngModel)]="displayedDialogData.net"></app-radio-button>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="price" label="{{ 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.NEW_PRICE' | translate:{currency: 'PLN'} }}"
                            [(validationMessageKey)]="validationErrors['price']"
                            [ngModel]="displayedDialogData.price_text"
                            (ngModelChange)="onPriceChange($event)"></app-input-text>
        </div>
        <ng-container *ngIf="!displayedDialogData.buy && !isInPln">
            <div class="new-form-row">
                <div class="exchange-rate-info">{{exchangeRateInfo}}</div>
            </div>
            <div class="new-form-row">
                <app-input-text inputId="priceInCurrency" disabled label="{{ 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.NEW_PRICE' | translate:{currency: displayedDialogData.currency} }}"
                                [ngModel]="priceInCurrency"></app-input-text>
            </div>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="submitPriceChangeButton" label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
