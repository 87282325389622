import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {ParsedOfferNumber} from "./parsed-offer-number";

@Component({
    selector: 'app-offer-number',
    templateUrl: './offer-number.component.html',
    styleUrls: ['./offer-number.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferNumberComponent implements OnInit {

    private readonly OFFER_NUMBER_SEPARATOR: string = '/';

    @Input()
    offerNumber: string;

    @Input()
    isOffer: boolean;

    @Input()
    isProductionOrder: boolean;

    parsedOfferNumber: ParsedOfferNumber;

    isInvalidOfferNumber: boolean = false;

    ngOnInit(): void {
        this.parsedOfferNumber = this.parseOfferNumber(this.offerNumber);
    }

    private parseOfferNumber(offerNumber: string): ParsedOfferNumber {
        let parsed = new ParsedOfferNumber();

        if (offerNumber) {
            let parts = offerNumber.split(this.OFFER_NUMBER_SEPARATOR);

            if (this.isOffer && parts.length == 4) {
                let [year, subsystemId, userId, offerNumber] = parts;
                Object.assign(parsed, {year, subsystemId, userId, offerNumber});
            } else if (this.isProductionOrder && parts.length == 6) {
                let [orderIndex, year, supplierId, subsystemId, userId, offerNumber] = parts;
                Object.assign(parsed, {orderIndex, year, supplierId, subsystemId, userId, offerNumber});
            } else {
                this.isInvalidOfferNumber = true;
            }
        } else {
            this.isInvalidOfferNumber = true;
        }

        return parsed;
    }
}