import {ChangeDetectorRef, ElementRef, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {FilterService} from 'primeng/api/filterservice';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Table} from 'primeng/table';
import {TristateCheckboxState} from '../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {DateRangeFilter, DateRangeKind} from './date-range-filter';

export interface DatatableReloadableInterface {
    onLazyLoad: EventEmitter<any>;
    createLazyLoadMetadata(): any;
}

export interface DatatableInterface extends DatatableReloadableInterface {
    el: ElementRef;
    changeDetector: ChangeDetectorRef;
    filters: { [s: string]: FilterMetadata | FilterMetadata[] };
    filter(value: any, field: any, matchMode: any): void;
    setFilterValue(value: any, field: any, matchMode: any): void;
    setSingleSortProperties(sortField: string, sortOrder: number): void;
    focusOnRowWithTabIndex(index: any): void;
    focusOnRowWithItem(item: any): void;
    calculateIndex(index: any): number;
}

export class DatatableHelper {

    private static readonly filterFieldCssClass = '.p-column-filter';

    private static focusOnRow(lazyLoadEvent: LazyLoadEvent, rowIndex = 0) {
        let dt = lazyLoadEvent.origin as DatatableInterface;
        DatatableHelper.focusOnTableRow(dt, rowIndex);
    }

    static focusOnRowIfNotEditingFilters(lazyLoadEvent: LazyLoadEvent, rowIndex = 0) {
        let dt = lazyLoadEvent.origin as DatatableInterface;
        if (dt != null && !DatatableHelper.isColumnFilterFocused(dt)) {
            DatatableHelper.focusOnRow(lazyLoadEvent, rowIndex);
        }
    }

    static focusOnTableRow(dt: DatatableInterface, rowIndex = 0) {
        if (dt) {
            setTimeout(() => {
                dt.focusOnRowWithTabIndex(dt.calculateIndex(rowIndex));
                dt.changeDetector.markForCheck();
            }, 100);
        }
    }

    static focusOnRowWithIndex(dt: DatatableInterface, selectedItemTabIndex: number) {
        dt.focusOnRowWithTabIndex(selectedItemTabIndex);
    }

    static isColumnFilterFocused(dt: DatatableInterface): boolean {
        return dt.el.nativeElement.querySelector(DatatableHelper.filterFieldCssClass + ':focus') != undefined;
    }

    static focusOnRowWithElement(datatable: DatatableInterface, tableElement: any, timeout = 100) {
        if (datatable) {
            setTimeout(() => datatable.focusOnRowWithItem(tableElement), timeout);
        }
    }

    static mapStateToTriStateCheckboxState(state: string) {
        if (state === 'CHECKED') {
            return TristateCheckboxState.CHECKED;
        }
        if (state === 'CHECKED_PARTIALLY') {
            return TristateCheckboxState.CHECKED_PARTIALLY;
        }
        return TristateCheckboxState.UNCHECKED;
    }

    static dateRangeFilter(currentDate: Date, value: any, filter: DateRangeFilter): boolean {
        if (filter == undefined) {
            return true;
        }
        if (!(value instanceof Date)) {
            return false;
        }
        const dateValue = value as Date;
        let from = undefined;
        let to = undefined;
        if (filter.kind === DateRangeKind.INPUT_SINGLE) {
            if (filter.from == undefined) {
                return true;
            }
            const fromDate = moment(filter.from, DateRangeFilter.DATE_FORMAT).toDate();
            return dateValue.getDate() === fromDate.getDate()
                && dateValue.getMonth() === fromDate.getMonth()
                && dateValue.getFullYear() === fromDate.getFullYear();
        } else {
            let range = DatatableHelper.getRangeForFilter(currentDate, filter);
            from = range.from && range.from.toDate();
            to = range.to && range.to.toDate();
        }
        if (from != undefined && dateValue < from) {
            return false;
        }
        if (to != undefined && dateValue > to) {
            return false;
        }
        return true;
    }

    static getRangeForFilter(currentDate: Date, filter: DateRangeFilter): { from: moment.Moment, to: moment.Moment } {
        switch (filter.kind) {
            case DateRangeKind.INPUT_SINGLE:
                break;
            case DateRangeKind.INPUT_RANGE:
                return {
                    from: filter.from != undefined ? moment(filter.from, DateRangeFilter.DATE_FORMAT).startOf('day') : undefined,
                    to: filter.to != undefined ? moment(filter.to, DateRangeFilter.DATE_FORMAT).endOf('day') : undefined
                };
            case DateRangeKind.CURRENT_WEEK:
                return {
                    from: moment(currentDate).startOf('isoWeek'),
                    to: moment(currentDate).endOf('isoWeek')
                };
            case DateRangeKind.CURRENT_MONTH:
                return {
                    from: moment(currentDate).startOf('month'),
                    to: moment(currentDate).endOf('month')
                };
            case DateRangeKind.CURRENT_YEAR:
                return {
                    from: moment(currentDate).startOf('year'),
                    to: moment(currentDate).endOf('year')
                };
            case DateRangeKind.PAST_7_DAYS:
                return {
                    from: moment(currentDate).startOf('day').subtract(7, 'days'),
                    to: moment(currentDate).startOf('day')
                };
            case DateRangeKind.PAST_30_DAYS:
                return {
                    from: moment(currentDate).startOf('day').subtract(30, 'days'),
                    to: moment(currentDate).startOf('day')
                };
            case DateRangeKind.PAST_YEAR:
                return {
                    from: moment(currentDate).startOf('day').subtract(1, 'year'),
                    to: moment(currentDate).startOf('day')
                };
            default:
                break;
        }
        return undefined;
    }

    static initializeDateRangeFiltering(service: FilterService): void {
        if (service.filters['dateRange'] == undefined) {
            service.register('dateRange', (value: any, filter: DateRangeFilter) => {
                return DatatableHelper.dateRangeFilter(new Date(), value, filter);
            });
        }
    }

    static reload(table: DatatableReloadableInterface): void {
        table.onLazyLoad.emit(table.createLazyLoadMetadata());
    }
}

export class TableToDatatableInterfaceAdapter implements DatatableInterface {

    get el() { return this.table.el; }
    get changeDetector() { return this.table.cd; }
    get filters() { return this.table.filters; }
    get onLazyLoad() { return this.table.onLazyLoad; }

    private constructor(private table: Table) {
    }

    static create(table: Table): TableToDatatableInterfaceAdapter {
        return table != undefined ? new TableToDatatableInterfaceAdapter(table) : undefined;
    }

    filter(value: any, field: any, matchMode: any): void {
        this.table.filter(value, field, matchMode);
    }

    calculateIndex(index: any): number {
        return index;
    }

    focusOnRowWithItem(item: any): void {
        const itemIndex = (this.table.filteredValue || this.table.value).indexOf(item);
        if (itemIndex >= 0) {
            this.focusOnRowWithTabIndex(itemIndex);
        }
    }

    focusOnRowWithTabIndex(index: number): void {
        const rows = (this.el.nativeElement as Element).querySelectorAll('tr');
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].tabIndex === index) {
                rows[i].focus();
                break;
            }
        }
    }

    setFilterValue(value: any, field: any, matchMode: any): void {
        if (!this.table.isFilterBlank(value)) {
            this.table.filters[field] = {value: value, matchMode: matchMode};
        } else if (this.table.filters[field]) {
            delete this.table.filters[field];
        }
    }

    setSingleSortProperties(sortField: string, sortOrder: number): void {
        this.table.setSingleSortProperties(sortField, sortOrder);
    }

    resetFilters() {
        this.table.filters = {};
    }

    createLazyLoadMetadata(): any {
        return this.table.createLazyLoadMetadata();
    }
}
