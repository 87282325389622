import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {ConfigSystem} from "../config-system";
import {Supplier} from "../../../supplier/supplier";
import {ConfigTypeDescriptor} from "../../config-type-descriptor/config-type-descriptor";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {TranslatedSelectItemImpl} from "../../../../common/service/translated.select.item.impl";
import {MessageSeverity} from "../../../offer/offers/message";
import {AddonCategoryGroup} from "../../addon-category-group/addon-category-group";
import {TranslateService} from "@ngx-translate/core";
import {AddonCategory} from "../../addon-category/addon-category";
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {MultilanguageField} from "../../../../supportedLanguages";

@Component({
    selector: 'app-config-system-form',
    templateUrl: './config-system-form.component.html',
    styleUrls: ['./config-system-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSystemFormComponent implements OnInit {

    @Input() item: ConfigSystem;
    @Input() addonCategoryGroups: AddonCategoryGroup[];
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() designerImage: File;
    @Input() imageLarge: File;
    @Input() suppliers: SelectItem[];
    @Input() categories: SelectItem[];
    @Input() functions: SelectItem[];
    @Input() messageLevelsOffer: { [severity: string]: boolean };
    @Input() messageLevelsOrder: { [severity: string]: boolean };
    @Input() printableMessageSeverityLevels: MessageSeverity[];
    @Input() editPermits: FieldLimitation[] = [];
    @Input() labelKeysMap: Map<string, MultilanguageField> = new Map<string, MultilanguageField>();
    @Output() readonly designerImageChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() readonly imageLargeChange: EventEmitter<File> = new EventEmitter<File>();

    availableApplicableTo: TranslatedSelectItem[] = [];
    availableCategories: AddonCategory[] = [];
    formatAddonCategoryGroupsOption: (option: AddonCategoryGroup | AddonCategory) => SelectItem;

    constructor(private translate: TranslateService) {
        this.formatAddonCategoryGroupsOption = (option: AddonCategoryGroup) => {
            return {
                label: option.name[this.translate.currentLang],
                value: option.id,
            };
        };
    }

    getSupplierKey = (supplier: Supplier) => supplier ? supplier.id : undefined;

    getCategoryKey = (category: ConfigTypeDescriptor) => category ? category.id : undefined;

    getFunctionKey = (functionDescriptor: ConfigTypeDescriptor) => functionDescriptor ? functionDescriptor.id : undefined;

    ngOnInit() {
        this.availableApplicableTo = Object.keys(ConfigAddonApplication).filter(element => element !== ConfigAddonApplication.WINDOW)
            .map(element => new TranslatedSelectItemImpl('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + element, element));

        this.onAddonCategoryGroupChange(this.item.materialColorCategoryGroupId);
    }

    onDesignerImageChange(file: File): void {
        this.designerImageChange.emit(file);
        this.designerImage = file;
    }

    onImageLargeChange(file: File): void {
        this.imageLargeChange.emit(file);
        this.imageLarge = file;
    }

    handleMessageLevelChange(messageLevels: { [severity: string]: boolean }, severity: string, value: boolean): void {
        messageLevels[severity] = value;
    }

    onAddonCategoryGroupChange(addonCategoryGroupId: number) {
        this.item.materialColorCategoryGroupId = addonCategoryGroupId;
        this.item.materialColorPrevCategoryId = null;
        let selectedGroup = addonCategoryGroupId && this.addonCategoryGroups.find(group => group.id === addonCategoryGroupId);
        if (selectedGroup == null) {
            this.availableCategories = [];
            return;
        }
        this.availableCategories = selectedGroup.categories;
    }

    getLabelKey(field: 'MATERIAL' | 'COLOR', target: 'FRONTEND' | 'PRINT') {
        let transTarget = target === 'FRONTEND' ? 'NAME' : 'PRINT_NAME';
        const id = this.item.id != undefined ? this.item.id : 'id';
        return `CONFIG_LABEL.${id}.${transTarget}.${field}`;
    }
}
