import {KeyValue} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {SupportedLanguages} from '../../../../supportedLanguages';
import {WindowSystemWebShopInfo} from '../window-system-web-shop-info';

@Component({
    selector: 'app-window-system-definition-web-shop-form',
    templateUrl: './window-system-definition-web-shop-form.component.html',
    styleUrls: ['./window-system-definition-web-shop-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionWebShopFormComponent {

    @Input() windowSystemWebShopInfo: WindowSystemWebShopInfo;
    @Input() validationErrors: ValidationErrors;
    @Input() webshopImageFile: File;
    @Output() readonly webshopImageFileChange = new EventEmitter<File>();
    @Input() webshopLargeImageFile: File;
    @Output() readonly webshopLargeImageFileChange = new EventEmitter<File>();
    @Input() webshopLargeImageMobileFile: File;
    @Output() readonly webshopLargeImageMobileFileChange = new EventEmitter<File>();
    @Input() webshopSliderImageFiles: Map<string, { file: File }>;
    @Output() readonly webshopSliderImageFileAdded = new EventEmitter<void>();
    @Output() readonly webshopSliderImageFileChanged = new EventEmitter<{ id: string, file: File }>();
    @Input() disabled: boolean;

    readonly SupportedLanguages = SupportedLanguages;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    handleWebshopImageFileChange(file: File): void {
        this.webshopImageFileChange.emit(file);
        this.webshopImageFile = file;
    }

    handleWebshopLargeImageFileChange(file: File): void {
        this.webshopLargeImageFileChange.emit(file);
        this.webshopLargeImageFile = file;
    }

    handleWebshopLargeImageMobileFileChange(file: File): void {
        this.webshopLargeImageMobileFileChange.emit(file);
        this.webshopLargeImageMobileFile = file;
    }

    handleWebshopAddSliderImageClick(): void {
        this.webshopSliderImageFileAdded.emit(); // will add new key to webshopSliderImageFiles
        this.changeDetector.markForCheck();
    }

    handleWebshopSliderImageFileChange(id: string, file: File): void {
        this.webshopSliderImageFileChanged.emit({id, file});
        this.changeDetector.markForCheck();
    }

    trackSliderImage(index: number, item: KeyValue<string, { file: File }>): string {
        return item.key;
    }
}
