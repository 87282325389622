<app-global-change-confirmation (onSubmit)="submit($event)"
                                [visible]="visible"
                                (visibleChange)="submit(false)"
                                [allPositionsContainErrors]="allPositionsContainErrors()"
                                [somePositionsContainErrors]="somePositionsContainErrors()">
    <div *ngFor="let position of dialogData.positions" class="position">
        <div class="name-status">
            <div class="name">
                {{ position.printOrder + ' - '}}
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.POSITION' | translate }}
                {{ position.name | multilanguageTranslate }}
            </div>
            <div *ngIf="positionContainErrors(position)" class="status failure">
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.NOT_VALID' | translate }}
            </div>
            <div *ngIf="!positionContainErrors(position)" class="status success">
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.VALID' | translate }}
            </div>
        </div>
        <ng-container *ngFor="let msg of resultsByPosition[position.id].messages">
            <app-pricing-message [message]="msg"></app-pricing-message>
        </ng-container>
    </div>
    <div>
        {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.CLOSE_WARN' | translate }}
    </div>
</app-global-change-confirmation>
