<div *ngIf="validationErrorPresent" class="new-form-field-error-message">
    {{ "OFFER.DRAWING.PRICING.ERROR" | translate }}
</div>
<div *ngIf="!validationErrorPresent">
    <div *ngIf="pricing!=null && pricingErrorPresent">
        <div *ngFor="let message of pricing.pricingExceptionMessages">
            <div class="pricing-message-padding">
                <app-pricing-message [message]="message"></app-pricing-message>
            </div>
        </div>
        <div *ngFor="let product of pricing.products">
            <div *ngFor="let message of product.messages">
                <div *ngIf="!message.validationMessage" class="pricing-message-padding">
                    <app-pricing-message [message]="message"></app-pricing-message>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pricing!=null && !pricingErrorPresent && !missingPricingForConfigAddon">
        <ng-container *ngFor="let product of pricing.products; let i = index">
            <ng-container *ngIf="chargesPerProduct.get(i) != undefined && chargesPerProduct.get(i).length > 0">
            <div class="product-type-grid">
                <div *ngIf="(product.positionName | json) == '{}'" class="primary-text">
                    {{"OFFER.TABS.PRICING.PRODUCT.TYPE.GENERAL" | translate}}
                </div>
                <div *ngIf="(product.positionName | json) != '{}'" class="primary-text">
                    {{"OFFER.TABS.PRICING.PRODUCT.TYPE." + (product | productType) |
                    translate}}
                    {{product.positionName[translate.currentLang]}} {{product.dimension | convertMillimetersToInches | async}}
                </div>
                <div class="primary-text" *ngIf="product.sellPrice != null && product.sellPrice.netValue != null">
                    <span>{{formatPriceInOfferCurrency(product)}}</span>
                </div>
                <div></div>
            </div>
            <div class="product-pricing-grid" *ngIf="shouldShowPricingDetails(product)">
                <ng-container *ngFor="let component of chargesPerProduct.get(i)">
                    <div class="chargeType" [id]="component.charge.type + '_chargeType'" *ngIf="chargeTypeHasNameInPricing(component.charge.type)">
                        {{ getChargeName(component.charge) | translate }}
                    </div>
                    <div class="chargeType" [id]="component.charge.type + '_chargeType'" *ngIf="!chargeTypeHasNameInPricing(component.charge.type)">
                        {{"OFFER.TABS.PRICING.COMPONENT.TYPE." + component.charge.type | translate}}
                    </div>
                    <div class="quantity" [id]="component.charge.type + '_quantity'">
                        <span>{{formatChargeQuantityFull(component)}}</span>
                    </div>
                    <div class="arrow" [id]="component.charge.type + '_arrow'">
                        <span>{{(component.count === 1 && component.charge.quantity === 1) ? '' : '🡢'}}</span>
                    </div>
                    <div class="values" [id]="component.charge.type + '_values'">
                        <span>{{formatPriceInOfferCurrency(component.charge, component.count)}}</span>
                    </div>
                </ng-container>
            </div>

            <div *ngFor="let message of product.messages">
                <div *ngIf="!message.validationMessage" class="pricing-message-padding">
                    <app-pricing-message [message]="message"></app-pricing-message>
                </div>
            </div>
            </ng-container>
        </ng-container>
        <div class="total-grid">
            <div class="primary-text">{{"OFFER.TABS.PRICING.SUM" | translate}}</div>
            <div class="primary-text" *ngIf="pricing.sellPrice != null && pricing.sellPrice.netValue != null">
                <span>{{formatPriceInOfferCurrency(pricing)}}</span>
            </div>
            <div class="secondary-text" *ngIf="pricing.sellPrice==null || pricing.sellPrice.netValue == null">
                {{"OFFER.TABS.PRICING.ERROR.NOT_POSSIBLE" | translate}}
            </div>
        </div>
    </div>

    <div *ngIf="pricing==null && !errorOccured">
        {{"OFFER.TABS.PRICING.IN_PROGRESS" | translate}}
    </div>
    <div *ngIf="errorOccured" class="new-form-field-error-message">
        {{"OFFER.TABS.PRICING.ERROR.GENERAL" | translate}}
        <div *ngIf="pricing != null && pricing.pricingExceptionMessages?.length == 0">
            {{"OFFER.TABS.PRICING.ERROR.PREVIOUS" | translate}}
        </div>
    </div>

    <app-config-addon-pricing *ngIf="pricing != null" [offer]="offer"
                              [configAddonPricingsAndModels]="configAddonPricingsAndModels"
                              [validationMode]="false"></app-config-addon-pricing>
</div>
