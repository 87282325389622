import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {WindowDimensions} from '../../../../window-designer/entities/window-dimensions';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, DimensionsField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {WindowDimensionsFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from "../single-system-checkbox-crud/single-system-checkbox-crud.component";
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {WindowDimensionsType} from "./window-dimensions-type";
import {WindowDimensionsWithMaterials} from "./window-dimensions-with-materials";
import {WindowDimensionsService} from './window-dimensions.service';

@Component({
    selector: 'app-window-dimensions',
    templateUrl: './window-dimensions.component.html',
    providers: [WindowDimensionsService, WindowSystemDefinitionService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line:max-line-length
export class WindowDimensionsComponent extends SingleSystemCheckboxCrudComponent<WindowDimensions, WindowDimensionsService> implements OnInit {

    item: WindowDimensionsWithMaterials;

    @ViewChild('dt') datatable;

    @Input()
    type: WindowDimensionsType;

    showSystemLinks = false;

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateForm()
        },
        SYSTEMS: {
            id: 'SYSTEMS',
            validate: () => of(true)
        }
    };

    editPermits: FieldLimitation[] = [];
    fieldUsage: WindowDimensionsFieldUsage;
    CatalogField = CatalogTab;
    DimensionsField = DimensionsField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, WindowDimensionsService, 'WINDOW_DIMENSIONS', 'WindowDimensions');
        this.item = new WindowDimensionsWithMaterials();
        this.initDefaultSortOrder();
        this.fieldUsage = new WindowDimensionsFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.showSystemLinks = this.type === WindowDimensionsType.ENTRANCE;
        const catalogElement = this.type === WindowDimensionsType.ENTRANCE ? CatalogElement.ENTRANCE_DIMENSIONS
            : CatalogElement.ROOF_DIMENSIONS;
        this.editCatalogPermitsService.getPermitsByCatalogElement(catalogElement).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    protected getApiUrl(): string {
        return 'windowDimensions';
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        if (!event.filters.hasOwnProperty('type')) {
            event.filters.type = {value: this.type};
        }
        super.loadItemsLazy(event);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    getNewItem(): WindowDimensionsWithMaterials {
        let newItem = new WindowDimensionsWithMaterials();
        newItem.type = this.type;
        return newItem;
    }

    prepareItemForRequest(): WindowDimensionsWithMaterials {
        return this.item;
    }

    cloneDimension(dimensions: WindowDimensionsWithMaterials): WindowDimensionsWithMaterials {
        let newDimensions = <WindowDimensionsWithMaterials>_.clone(dimensions);
        return newDimensions;
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.item = this.cloneDimension(<WindowDimensionsWithMaterials>this.selectedItem);
            this.item.id = undefined;
            this.getLinkedSystems(this.selectedItem.id, () => this.setDisplayDialog(true));
        }
    }

    onRowSelect(event) {
        this.getLinkedSystems(event.data.id, () => super.onRowSelect(event));
    }

    getLinkedSystems(itemId: number, onComplete: () => void) {
        (this.showSystemLinks ? this.itemService.getLinkedSystems(this.getApiUrl() + '/linked/' + itemId) :
            of([] as number[])).subscribe({
            next: data => {
                this.selectedWindowSystems = data;
                this.item.type = this.type;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                onComplete();
            }
        });
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            this.validationErrors = {};
            let observable: Observable<number>;
            if (this.copyMode) {
                observable =
                    this.itemService.copy(this.selectedItem.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
            } else {
                observable = this.itemService.saveItem(this.item).pipe(mergeMap(this.editLinksAfterSave()));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    validateForm(): Observable<boolean> {
        let errors = {};

        if (this.item.symbol == null || this.item.symbol === '') {
            errors['symbol'] = 'error.windowDimensionsDto.symbol.not_empty';
        }

        errors['width'] = MultiValidator.of('error.windowDimensionsDto.width')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 99999, false, true)
            .validate(this.item.width);

        errors['height'] = MultiValidator.of('error.windowDimensionsDto.height')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 99999, false, true)
            .validate(this.item.height);

        errors['sortIndex'] = MultiValidator.of('error.windowDimensionsDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            this.changeDetector.markForCheck();
            return of(false);
        }

        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    onSizeChange(): void {
        this.validationErrors['width'] = undefined;
        this.validationErrors['height'] = undefined;
    }

    get windowSystemTypeGroups(): ProductTypeGroup[] {
        switch (this.type) {
            case WindowDimensionsType.ROOF:
                return [ProductTypeGroup.ROOF];
            case WindowDimensionsType.ENTRANCE:
                return [ProductTypeGroup.ENTRANCE];
            default:
                break;
        }
        return [];
    }
}
