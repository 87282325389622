import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {GrillTypes} from "../../../../../window-designer/enums/GrillTypes";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {GrillField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GrillFieldUsage} from "../../catalog-field-usage";
import {Grill} from "../grill";

@Component({
    selector: 'app-grill-form',
    templateUrl: './grill-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrillFormComponent {

    @Input() item: Grill;

    @Input()
    validationErrors: any;

    @Input()
    availableColors: SelectItem[];

    @Input()
    types: SelectItem[];

    @Input()
    file: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    @Output()
    selectedColorsChange = new EventEmitter<void>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;
    GrillTypes = GrillTypes;

    fieldUsage: GrillFieldUsage;
    GrillField = GrillField;

    constructor() {
        this.fieldUsage = new GrillFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onSelectedColorsChange(): void {
        this.selectedColorsChange.emit();
    }

    onTypeChange(type: GrillTypes) {
        this.item.type = type;
        if (type !== GrillTypes.INNER) {
            this.item.canNotBeCombinedWithOtherGrills = false;
        }
    }
}
