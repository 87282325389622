import {AddonInterface} from '../catalog-data/addon-interface';
import {WindowAddon} from "../drawing-data/WindowAddon";
import {WindowAddonCalculationMode} from "../enums/WindowAddonCalculationMode";
import {AddonDefaultQuantityCalculator} from "./addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {AddonQuantityDataSource} from "./addons-default-quantity-calculator/AddonQuantityDataSource";
import {RandomIdGenerator} from "./RandomIdGenerator";
import {QuantityType} from "../enums/QuantityType";

export class WindowAddonMapper {

    public static mapToWindowAddon(windowAddon: WindowAddon, addon: AddonInterface, mode: WindowAddonCalculationMode,
                                   dataSource?: AddonQuantityDataSource): void {
        if (addon != null && addon.id != null) {
            let quantity: number;
            switch (mode) {
                case WindowAddonCalculationMode.GLOBAL:
                    quantity = AddonDefaultQuantityCalculator.calculate(addon, dataSource);
                    break;
                case WindowAddonCalculationMode.ROOF_GLOBAL:

                    break;
                case WindowAddonCalculationMode.SINGLE_ELEMENT:
                    quantity = addon.defaultQuantity;
                    break;
                default:
                    quantity = 0;
                    console.error("WindowAddonMapper - Unsupported window addon calculation mode: " + WindowAddonCalculationMode[mode]);
                    break;
            }
            WindowAddonMapper.edit(windowAddon, addon.id, quantity, addon.quantityType, "");
        }
    }

    public static edit(windowAddon: WindowAddon, addonId: number, quantity?: number, quantityType?: QuantityType, description?: string): void {
        if (windowAddon.addonId == null && addonId != null) {
            windowAddon.generatedId = RandomIdGenerator.generate();
        } else if (windowAddon.addonId != null && addonId == null) {
            windowAddon.generatedId = undefined;
        }
        windowAddon.addonId = addonId;
        windowAddon.quantity = quantity;
        windowAddon.quantityType = quantityType;
        windowAddon.description = description;
    }
}
