<div class="list-content">
    <div class="table-header">
        <div class="title">{{ tableHeader | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <app-simple-button buttonId="showReportWizard" label="{{ 'USER_ACTIVITY_REPORT.ADVANCED_FILTER' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="showReportWizard = true; changeDetector.markForCheck()"></app-simple-button>

				<app-simple-button *ngIf="permissions.isKoordynator()" buttonId="showMailReportWizard"
								   label="{{ 'USER_ACTIVITY_REPORT.MAIL_SETTINGS' | translate }}"
								   icon="mail" type="action"
								   (onClick)="openMailConfiguration(); changeDetector.markForCheck()"></app-simple-button>

				<!--CSV export-->
                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #dt [value]="users" [rows]="paginated ? chosenRowsPerPage : MAX_ROWS_WITHOUT_PAGINATION"
                 [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="false" [first]="chosenPageNumber"
                 [metaKeySelection]="true" (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadUserActivitiesLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedUser">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="login" [ngClass]="columnHeaderClasses('login')">
                        <span>{{ 'USER-DETAILS.FORM.LOGIN' | translate }}</span>
                        <p-sortIcon field="login"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="login"
                               (input)="dt.filter(getInputEventValue($event), 'login', 'contains')">
                    </th>
                    <th pSortableColumn="firstName" [ngClass]="columnHeaderClasses('firstName')">
                        <span>{{ 'USER-DETAILS.FORM.FIRST_NAME' | translate }}</span>
                        <p-sortIcon field="firstName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="firstName"
                               (input)="dt.filter(getInputEventValue($event), 'firstName', 'contains')">
                    </th>
                    <th pSortableColumn="lastName" [ngClass]="columnHeaderClasses('lastName')">
                        <span>{{ 'USER-DETAILS.FORM.LAST_NAME' | translate }}</span>
                        <p-sortIcon field="lastName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="lastName"
                               (input)="dt.filter(getInputEventValue($event), 'lastName', 'contains')">
                    </th>
                    <th [ngClass]="columnHeaderClasses('roleName')">
                        <span>{{ 'USER-DETAILS.FORM.ROLE' | translate }}</span>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="filterRoles | async"
                                           defaultLabel="{{ 'USER-DETAILS.FORM.ROLE' | translate }}" appendTo="body"
                                           [displaySelectedLabel]="false" [ngModel]="selectedRoles"
                                           (ngModelChange)="handleRoleFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th pSortableColumn="subsystemIds" [ngClass]="columnHeaderClasses('subsystemIds')">
                        <span>{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}</span>
                        <p-sortIcon field="subsystemIds"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="subsystems"
                                           defaultLabel="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false"
                                           [ngModel]="selectedSubsystems"
                                           (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th [pSortableColumn]="activityType" [ngClass]="columnHeaderClasses(activityType)">
                        <span>{{ activityTypeLabel | translate }}</span>
                        <p-sortIcon [field]="activityType"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" [pSelectableRow]="user" [pSelectableRowIndex]="rowIndex">
                    <td>
                        {{ user.login }}
                    </td>
                    <td>
                        {{ user.firstName }}
                    </td>
                    <td>
                        {{ user.lastName }}
                    </td>
                    <td>
                        {{ getRoleTranslationKey(user.roleName) | translate }}
                    </td>
                    <td>
                        {{ getSubsystemsName(user.subsystemId) }}
                    </td>
                    <td>
                        {{ user[activityType] | momentCurrentLang | amTimeAgo }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="p-datatable-emptymessage-row">
                    <td colspan="6" class="p-datatable-emptymessage">
                        <span>{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator *ngIf="paginated" [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
<p-dialog #reportParamsWizardDialog [resizable]="false" [(visible)]="showReportWizard">
	<p-header>{{ 'USER_ACTIVITY_REPORT.ADVANCED_FILTER' | translate }}</p-header>
	<div class="scrolled-dialog scrolled-dialog-60vw">
		<div class="new-form-60vw">
			<app-user-activity-report-wizard #reportParamsWizard [(filter)]="criteriaTreeFilter"
											 (saved)="reportParamsWizardDialog.close($event); handleCustomFilterChange()"></app-user-activity-report-wizard>
		</div>
	</div>
	<p-footer>
		<div class="p-dialog-buttonpane">
			<app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action" [size]="40"
							   (onClick)="reportParamsWizard.save($event)"></app-simple-button>
			<app-simple-button buttonId="dialog-cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
							   (onClick)="reportParamsWizard.reset(); reportParamsWizardDialog.close($event)"></app-simple-button>
		</div>
	</p-footer>
</p-dialog>

<p-dialog #reportMailParamsWizardDialog [resizable]="false" [(visible)]="showReportMailWizard">
	<p-header>{{ 'USER_ACTIVITY_REPORT.MAIL_SETTINGS' | translate }}</p-header>
	<div class="scrolled-dialog scrolled-dialog-60vw">
		<app-user-activity-report-mail-settings #reportMailSettings></app-user-activity-report-mail-settings>
		<div class="new-form-60vw">
			<ng-container *ngIf="mailReportSettings != undefined">
				<div class="new-form-row">
					<app-checkbox inputId="mailActiveCheckbox" label="{{ 'GENERAL.ONLY_ACTIVE_F' | translate }}"
								  [(ngModel)]="mailReportSettings.active"></app-checkbox>
				</div>
				<div class="new-form-row">
					<app-input-number inputId="mailFrequencyInput"
									  label="{{ 'USER_ACTIVITY_REPORT.FORM.REPORT_FREQUENCY' | translate }}" [min]="1" [max]="28" [step]="1"
									  [allowOnlyIntegers]="true" [(ngModel)]="mailReportSettings.reportFrequency"></app-input-number>
					<app-select inputId="mailFrequencyUnitInput"
								placeholder="{{ 'USER_ACTIVITY_REPORT.FORM.REPORT_FREQUENCY_UNIT.PLACEHOLDER' | translate }}"
								[options]="timeUnits | async" [(ngModel)]="mailReportSettings.reportFrequencyUnit">
						<ng-template pTemplate="label"></ng-template>
					</app-select>
				</div>
			</ng-container>
			<div class="new-form-row">
				<h3>{{ 'USER_ACTIVITY_REPORT.FORM.CRITERIA_TREE' | translate }}</h3>
			</div>
			<app-user-activity-report-wizard #reportMailParamsWizard [(filter)]="mailCriteriaTreeFilter"
											 (saved)="handleReportConfigurationSave($event)"></app-user-activity-report-wizard>
		</div>
	</div>
	<p-footer>
		<div class="p-dialog-buttonpane">
			<app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action" [size]="40"
							   (onClick)="reportMailParamsWizard.save($event)"></app-simple-button>
			<app-simple-button buttonId="dialog-cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
							   (onClick)="reportMailParamsWizard.reset(); reportMailParamsWizardDialog.close($event)"></app-simple-button>
		</div>
	</p-footer>
</p-dialog>
