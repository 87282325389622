import {DrawingData} from "./drawing-data/drawing-data";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {WindowData} from "./drawing-data/WindowData";
import {DrawingUtil, MinMaxXY} from "./drawing-util";
import {OpeningDirection} from "./window-types/opening-direction";
import {OpeningDirectionUtils} from "./window-types/opening-direction-utils";
import {SubWindowTypeCode} from "./window-types/subwindow-type-code";

export class WindowNeighbourCheck {
    static canAddNewWindow(newWindow: WindowData, side: string, data: DrawingData, totalBoundingBox: MinMaxXY, allowFestAtHinges: boolean) {
        if (side === 'right') {
            for (let subwindow of newWindow.subWindows) {
                if (this.isLeftOpening(subwindow.typeCode)) {
                    let mostRightWindowTypeCode = this.getMostRightWindowTypeCode(data, totalBoundingBox);
                    if (this.isAtLeastOneRightOpening(mostRightWindowTypeCode)
                        || (this.isAtLeastOneFest(mostRightWindowTypeCode) && !allowFestAtHinges)) {
                        return false;
                    }
                } else if (subwindow.typeCode === SubWindowTypeCode.F) {
                    let mostRightWindowTypeCode = this.getMostRightWindowTypeCode(data, totalBoundingBox);
                    if (this.isAtLeastOneRightOpening(mostRightWindowTypeCode) && !allowFestAtHinges) {
                        return false;
                    }
                }
            }
        } else if (side === 'left') {
            for (let subwindow of newWindow.subWindows) {
                if (this.isRightOpening(subwindow.typeCode)) {
                    let mostLeftWindowTypeCode = this.getMostLeftWindowTypeCode(data, totalBoundingBox);
                    if (this.isAtLeastOneLeftOpening(mostLeftWindowTypeCode)
                        || (this.isAtLeastOneFest(mostLeftWindowTypeCode) && !allowFestAtHinges)) {
                        return false;
                    }
                } else if (subwindow.typeCode === SubWindowTypeCode.F) {
                    let mostLeftWindowTypeCode = this.getMostLeftWindowTypeCode(data, totalBoundingBox);
                    if (this.isAtLeastOneLeftOpening(mostLeftWindowTypeCode) && !allowFestAtHinges) {
                        return false;
                    }
                }
            }
        } else if (side === 'center') {
            return data.windows.length === 0;
        }
        return true;
    }

    public static validateAfterBusinessTypeChange(changedWindow: WindowData, data: DrawingData): boolean {
        for (let subwindow of changedWindow.subWindows) {
            let neighbours = this.getNeighbours(subwindow, data, true);
            if (WindowNeighbourCheck.isLeftOpening(subwindow.typeCode) &&
                WindowNeighbourCheck.isAtLeastOneRightOpening(this.getTypeCodes(neighbours.left))) {
                return false;
            } else if (WindowNeighbourCheck.isRightOpening(subwindow.typeCode) &&
                WindowNeighbourCheck.isAtLeastOneLeftOpening(this.getTypeCodes(neighbours.right))) {
                return false;
            }
        }
        return true;
    }

    private static getTypeCodes(subwindows: SubWindowData[]) {
        return subwindows.map(subwindow => subwindow.typeCode);
    }

    public static isLeftOpening(newWindowType: SubWindowTypeCode) {
        return OpeningDirectionUtils.getSubwindowOpening(newWindowType) === OpeningDirection.L;
    }

    public static isRightOpening(newWindowType: SubWindowTypeCode) {
        return OpeningDirectionUtils.getSubwindowOpening(newWindowType) === OpeningDirection.P;
    }

    private static getMostRightWindowTypeCode(data: DrawingData, totalBoundingBox: MinMaxXY): SubWindowTypeCode[] {
        let subwindows = [].concat.apply([], data.windows.map(window => window.subWindows));
        return subwindows.filter(sw => DrawingUtil.calculateMinMaxFromPolygon(sw.points).maxX === totalBoundingBox.maxX)
                         .map(sw => sw.typeCode);
    }

    private static getMostLeftWindowTypeCode(data: DrawingData, totalBoundingBox: MinMaxXY): SubWindowTypeCode[] {
        let subwindows = [].concat.apply([], data.windows.map(window => window.subWindows));
        return subwindows.filter(sw => DrawingUtil.calculateMinMaxFromPolygon(sw.points).minX === totalBoundingBox.minX)
                         .map(sw => sw.typeCode);
    }

    private static isAtLeastOneLeftOpening(typeCodes: SubWindowTypeCode[]): boolean {
        for (let typeCode of typeCodes) {
            if (WindowNeighbourCheck.isLeftOpening(typeCode)) {
                return true;
            }
        }
        return false;
    }

    private static isAtLeastOneFest(typeCodes: SubWindowTypeCode[]): boolean {
        for (let typeCode of typeCodes) {
            if (typeCode === SubWindowTypeCode.F) {
                return true;
            }
        }
        return false;
    }

    private static isAtLeastOneRightOpening(typeCodes: SubWindowTypeCode[]): boolean {
        for (let typeCode of typeCodes) {
            if (WindowNeighbourCheck.isRightOpening(typeCode)) {
                return true;
            }
        }
        return false;
    }

    public static getNeighbours(changedSubwindow: SubWindowData, data: DrawingData, ignoreOtherSubwindowsInSameWindow = false): {
        left: SubWindowData[], right: SubWindowData[], top: SubWindowData[], bottom: SubWindowData[]
    } {
        let changedBox = DrawingUtil.calculateMinMaxFromPolygon(changedSubwindow.points, false);
        let otherSubwindows: SubWindowData[];
        if (ignoreOtherSubwindowsInSameWindow) {
            let otherWindows = data.windows.filter(
                window => window.subWindows.every(subwindow => subwindow.generatedId !== changedSubwindow.generatedId));
            otherSubwindows = [].concat.apply([], otherWindows.map(window => window.subWindows));
        } else {
            otherSubwindows = [].concat.apply([], data.windows.map(window => window.subWindows))
                .filter(sw => sw.generatedId !== changedSubwindow.generatedId);
        }
        let left: SubWindowData[] = [];
        let right: SubWindowData[] = [];
        let top: SubWindowData[] = [];
        let bottom: SubWindowData[] = [];
        otherSubwindows.forEach(otherSubwindow => {
            let otherBox = DrawingUtil.calculateMinMaxFromPolygon(otherSubwindow.points, false);
            if (this.areBoxesOnSameLevelVertically(changedBox, otherBox)) {
                if (otherBox.maxX === changedBox.minX) {
                    left.push(otherSubwindow);
                } else if (otherBox.minX === changedBox.maxX) {
                    right.push(otherSubwindow);
                }
            }
            if (this.areBoxesOnSameLevelHorizontally(changedBox, otherBox)) {
                if (otherBox.maxY === changedBox.minY) {
                    top.push(otherSubwindow);
                } else if (otherBox.minY === changedBox.maxY) {
                    bottom.push(otherSubwindow);
                }
            }
        });
        return {left, right, top, bottom};
    }

    private static areBoxesOnSameLevelVertically(boxA: MinMaxXY, boxB: MinMaxXY): boolean {
        return this.areRangesOverlapping(boxA.minY, boxA.maxY, boxB.minY, boxB.maxY);
    }

    private static areBoxesOnSameLevelHorizontally(boxA: MinMaxXY, boxB: MinMaxXY): boolean {
        return this.areRangesOverlapping(boxA.minX, boxA.maxX, boxB.minX, boxB.maxX);
    }

    private static areRangesOverlapping(aMin: number, aMax: number, bMin: number, bMax: number): boolean {
        return (aMin < bMax && bMax <= aMax) || (aMin <= bMin || bMin < aMax);
    }
}
