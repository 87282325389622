import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CatalogProperty} from "../catalog-property/catalog-property";
import {CatalogOption} from "../catalog-option/catalog-option";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-catalog-configuration-display',
    templateUrl: './catalog-configuration-display.component.html',
    styleUrls: ['./catalog-configuration-display.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogConfigurationDisplayComponent {

    @Input()
    description: {property: CatalogProperty, option: CatalogOption}[];

    @Input()
    headerLabel: string;

    @Input() set itemName(name: string) {
        this.nameLabel = this.translate.instant(this.headerLabel) + ' ' + name;
    }

    @Output() openStorageFile: EventEmitter<number> = new EventEmitter<number>();

    translate: TranslateService;
    sanitizer: DomSanitizer;
    nameLabel: string;

    constructor(injector: Injector) {
        this.translate = injector.get(TranslateService);
        this.sanitizer = injector.get(DomSanitizer);
    }
}
