<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASSES.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.ADD-GLASS' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="glassTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(GlassField.NAME)"
                          header="{{'GLASS.FORM.NAME'|translate}}" [rowspan]="3" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column [rowspan]="3" [sortable]="true" field="sortIndex" header="{{'GLASS.FORM.SORT_INDEX' | translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.SHORTCUT)"
                          header="{{'GLASS.FORM.SHORTCUT'|translate}}" [rowspan]="3" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.SYMBOL)"
                          header="{{'GLASS.FORM.SYMBOL'|translate}}" [rowspan]="3" field="symbol" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.THICKNESS)"
                          header="{{'GLASS.FORM.THICKNESS_COLUMN'|translate}}" [rowspan]="3" field="thickness"
                          [sortable]="true"
                          [filter]="showFilters" [filterValues]="filterThickness | async"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.ESG)"
                          header="{{'GLASS.FORM.ESG'|translate}}" [rowspan]="3" field="esg" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterEsg | async"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.TMP)"
                          header="{{'GLASS.FORM.TMP'|translate}}" [rowspan]="3" field="tmp" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterTmp | async"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"
                          header="{{'GLASS.FORM.PACKAGE_PLACEMENT'|translate}}" [colspan]="11"></p-column>
                <p-column *ngIf="fieldUsage.show(GlassField.ACTIVE)"
                          header="{{'GLASS.FORM.STATUS'|translate}}" [rowspan]="3" field="active" [sortable]="false"
                          [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="{{'GLASS.FORM.1_GLASS'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [colspan]="2" header="{{'GLASS.FORM.2_GLASS'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [colspan]="3" header="{{'GLASS.FORM.3_GLASS'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [colspan]="4" header="{{'GLASS.FORM.4_GLASS'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [rowspan]="2" header="{{'GLASS.FORM.ALL_SYSTEMS'|translate}}"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="3"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="3"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="4"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <p-column *ngIf="fieldUsage.show(GlassField.NAME)"
                  field="name" header="{{ 'GLASS.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template let-glass="rowData" pTemplate="body">
                {{glass.name[userLang]}}
            </ng-template>
        </p-column>

        <p-column [sortable]="true" field="sortIndex" header="{{ 'GLASS.FORM.SORT_INDEX' | translate }}"></p-column>
        <p-column *ngIf="fieldUsage.show(GlassField.SHORTCUT)" [filter]="showFilters" [sortable]="true" field="shortcut" header="{{ 'GLASS.FORM.SHORTCUT' | translate }}"
                  filterMatchMode="contains">
            <ng-template let-glass="rowData" pTemplate="body">
                {{glass.shortcut[userLang]}}
            </ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(GlassField.SYMBOL)" field="symbol"></p-column>
        <p-column *ngIf="fieldUsage.show(GlassField.THICKNESS)" field="thickness"></p-column>
        <p-column *ngIf="fieldUsage.show(GlassField.ESG)" field="esg">
            <ng-template let-glass="rowData" pTemplate="body">
                {{(glass.esg ? 'GENERAL.YES' : 'GENERAL.NO') | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GlassField.TMP)" field="tmp">
            <ng-template let-glass="rowData" pTemplate="body">
                {{(glass.tmp ? 'GENERAL.YES' : 'GENERAL.NO') | translate}}
            </ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position1of1" header="1/1">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position1of1" (ngModelChange)="editGlassLinkEvent(glass, 1, 1, glass.position1of1)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position1of2" header="1/2">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position1of2" (ngModelChange)="editGlassLinkEvent(glass, 1, 2, glass.position1of2)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position2of2" header="2/2">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position2of2" (ngModelChange)="editGlassLinkEvent(glass, 2, 2, glass.position2of2)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position1of3" header="1/3">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position1of3" (ngModelChange)="editGlassLinkEvent(glass, 1, 3, glass.position1of3)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position2of3" header="2/3">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position2of3" (ngModelChange)="editGlassLinkEvent(glass, 2, 3, glass.position2of3)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position3of3" header="3/3">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position3of3" (ngModelChange)="editGlassLinkEvent(glass, 3, 3, glass.position3of3)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position1of4" header="1/4">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position1of4" (ngModelChange)="editGlassLinkEvent(glass, 1, 4, glass.position1of4)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position2of4" header="2/4">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position2of4" (ngModelChange)="editGlassLinkEvent(glass, 2, 4, glass.position2of4)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position3of4" header="3/4">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position3of4" (ngModelChange)="editGlassLinkEvent(glass, 3, 4, glass.position3of4)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="position4of4" header="4/4">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.position4of4" (ngModelChange)="editGlassLinkEvent(glass, 4, 4, glass.position4of4)"></app-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" field="all" header="{{ 'GLASS.FORM.ALL' | translate }}">
            <ng-template let-glass="rowData" pTemplate="body">
                <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                              [(ngModel)]="glass.allPositions" (ngModelChange)="checkAll(glass.allPositions, glass.id)"></app-checkbox>
            </ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(GlassField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-glass="rowData" pTemplate="body">
                {{(glass.active ? 'GLASS.FORM.ACTIVE' : 'GLASS.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.ADD-GLASS' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.GENERAL-DATA' | translate }}"
                     [validate]="validateGeneralDataStep">
        <app-glass-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [copyMode]="false" [editMode]="false"
                        [file]="file" (fileChange)="onFileChange($event)" [glamourPrintIconFile]="glamourPrintIconFile"
                        (glamourPrintIconFileChange)="onGlamourPrintIconFileChange($event)"></app-glass-form>
    </app-wizard-step>

    <app-wizard-step label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.SYSTEMS' | translate }}"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="windowSystemsByDefaultGlassId.get(item.id) || []"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<!-- COPY & EDIT dialog -->
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.EDIT-GLASS') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.GENERAL-DATA' | translate }}"
                     [validate]="validateGeneralDataStep">
        <app-glass-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [copyMode]="copyMode" [editMode]="!copyMode"
                        [file]="file" (fileChange)="onFileChange($event)" [glamourPrintIconFile]="glamourPrintIconFile"
                        (glamourPrintIconFileChange)="onGlamourPrintIconFileChange($event)"></app-glass-form>
    </app-wizard-step>

    <app-wizard-step label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLASS.SYSTEMS' | translate }}" id="systems"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="item.linkedSystemIds"
                                [lockedItemsIds]="windowSystemsByDefaultGlassId.get(item.id) || []"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
