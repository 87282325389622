import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {Message} from 'primeng/api/message';
import {SelectItem} from 'primeng/api/selectitem';
import {Listbox} from 'primeng/listbox';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {Permissions} from "../../../../auth/permission.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {Listing} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {ContactMethod} from '../../../../common/enums/contact-method.enum';
import {OfferStatus} from '../../../../common/enums/OfferStatus';
import {ResponseError} from "../../../../common/error.handler";
import {FocusOnElement} from "../../../../common/FocusOnElement";
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {ListboxSelectionItem} from "../../../../common/listbox-selection/listbox-selection.component";
import {ValidationService} from "../../../../common/service/validation.service";
import {ValidationErrors} from '../../../../common/validation-errors';
import {WizardStepChangeEvent} from '../../../../form-inputs/wizard/wizard-base';
import {WizardStepValidator} from '../../../../form-inputs/wizard/wizard-step.component';
import {AccessData} from '../../../AccessData';
import {ClientGroupService} from '../../../client-group/client-group.service';
import {ClientGroup} from '../../../client-group/ClientGroup';
import {AddClientSubmitEvent} from "../../../client/add-client-submit-event";
import {Client} from '../../../client/client';
import {ClientService} from '../../../client/client.service';
import {AddressService} from '../../../subsystem/address.service';
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {TabularAddress} from '../../../subsystem/tabularAddress';
import {User} from '../../../user/user';
import {UserService} from '../../../user/user.service';
import {Offer} from '../../offer';
import {OffersService} from '../../offer-service';
import {CreateOfferComponentService} from "./create-offer-component.service";
import {CreateOfferMode} from "./create-offer-mode";

@Component({
    selector: 'app-create-offer',
    templateUrl: './create-offer.component.html',
    styleUrls: ['./create-offer.component.css'],
    providers: [SubsystemService, OffersService, UserService, ClientService, DataServiceHelper, CreateOfferComponentService,
        ClientGroupService, ValidationService, AddressService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOfferComponent {

    static readonly BLOCK_UI_INIT: string = 'CreateOfferComponent.initEditOffer';
    static readonly BLOCK_UI_STEP_CHANGE: string = 'CreateOfferComponent.onStepChange';
    static readonly BLOCK_UI_SAVE: string = 'CreateOfferComponent.save';

    readonly STEPS = {
        SELECT_USER: 'select-user',
        SELECT_CLIENT: 'select-client',
        DETAILS: 'details',
        HISTORY: 'history'
    };

    canEdit = true;

    offer: Offer;
    validationErrors: ValidationErrors = {};
    updateExchangeRate = false;
    showHistory = false;

    // route parameters
    forceClientId: number;
    forceUserId: number;
    createOfferMode: CreateOfferMode;
    originalOfferId: number;

    // user selection
    selectedUser: User;
    selectedUserItem: ListboxSelectionItem;
    availableUsers: SelectItem[] = [];
    usersList: User[];
    validateUserSelectionStep: WizardStepValidator;
    availableSubsystems: SelectItem[] = [];
    selectedSubsystemId: number;

    // client selection
    clients: SelectItem[] = [];
    clientsList: Client[];
    selectedClient: Client;
    selectedClientItem: ListboxSelectionItem;
    validateClientSelectionStep: WizardStepValidator;

    // details
    validFromDate: Date;
    validToDate: Date;
    maxValidTo: Date;
    validateDetailsStep: WizardStepValidator;

    createdClient: Client;
    existingGroups: ClientGroup[] = [];

    AccessData = AccessData;

    deliveryAddress: TabularAddress;
    addresses: TabularAddress[];
    deliveryAddressSelectionDialogVisible = false;

    subsystemsAlreadySentTo: string[] = null;
    showConfirmationDialog = false;

    @ViewChild('userListFilterRef')
    userListFilter: ElementRef;

    @ViewChild('userListRef')
    userList: Listbox;

    @ViewChild('clientListFilterRef')
    clientListFilter: ElementRef;

    private saveInProgress = false;
    private defaultDurationDays = 14;

    constructor(private translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private activatedRoute: ActivatedRoute,
                private clientGroupService: ClientGroupService,
                private offerService: OffersService,
                private userService: UserService,
                private clientService: ClientService,
                private addressService: AddressService,
                public permissions: Permissions,
                public blockUiController: BlockUiController,
                private createOfferService: CreateOfferComponentService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler,
                private router: Router,
                private currentUserService: CurrentUserService) {

        this.readRouteParams();
        this.validateClientSelectionStep = () => this.validateClientSelection();
        this.validateDetailsStep = () => this.validateDetails();
        this.validateUserSelectionStep = () => this.validateUserSelection();
        this.initOfferData();
        this.validationErrors = {};
    }

    exitWizard(): void {
        if (this.isMode(CreateOfferMode.EDIT)) {
            this.goToOfferPositionList(this.offer.id);
        } else if (this.forceClientId == undefined || this.isMode(CreateOfferMode.COPY_TO_PARTNER)) {
            this.goToOfferList();
        } else {
            this.goToClientList();
        }
    }

    private initOfferData(): void {
        this.offer = new Offer();
        this.validFromDate = new Date();

        switch (this.createOfferMode) {
            case CreateOfferMode.NEW:
                this.initNewOffer();
                break;
            case CreateOfferMode.EDIT:
                this.initEditOffer();
                break;
            case CreateOfferMode.COPY:
            case CreateOfferMode.COPY_TO_PARTNER:
                this.updateExchangeRate = true;
                this.initCopyOffer();
                break;
        }
    }

    private initNewOffer(): void {
        this.offer.status = OfferStatus.NEW_OFFER;
    }

    private initCopyOffer(): void {
        this.blockUiController.block(CreateOfferComponent.BLOCK_UI_INIT);
        this.offerService.getItem(this.originalOfferId).pipe(
            finalize(() => this.blockUiController.unblock(CreateOfferComponent.BLOCK_UI_INIT))
        ).subscribe({
            next: data => {
                this.offer = data;
                this.offer.id = null;
                this.initSelectedClient(this.clients ? this.clients.map(client => client.value) : []);
                this.deliveryAddress = new TabularAddress(this.offer.deliveryAddressId, this.offer.deliveryAddressStreet,
                    this.offer.deliveryAddressCity, this.offer.deliveryAddressZip, this.offer.deliveryAddressCountry);
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    onStepChange(event: WizardStepChangeEvent): void {
        let stepInitializeObservable: Observable<any>;

        switch (event.newId) {
            case this.STEPS.SELECT_USER:
                if (event.oldIndex == undefined || event.newIndex > event.oldIndex) {
                    stepInitializeObservable = this.initSubsystemsAndUsers();
                }
                break;
            case this.STEPS.SELECT_CLIENT:
                stepInitializeObservable = this.getOrLoadUser();
                if (event.oldIndex == undefined || event.newIndex > event.oldIndex) {
                    stepInitializeObservable = stepInitializeObservable.pipe(mergeMap(user => {
                        return this.loadClients(user).pipe(tap(clients => {
                            this.loadClientsCallback(clients);
                        }));
                    }));
                }
                break;
            case this.STEPS.DETAILS:
                stepInitializeObservable = forkJoin({
                    user: this.getOrLoadUser(),
                    durationDays: this.isMode(CreateOfferMode.EDIT) ? of(undefined) :
                        this.offerService.getDefaultOfferDurationDays(
                            this.forceClientId != undefined ? this.forceClientId : this.selectedClient.id,
                            this.selectedSubsystemId),
                    selectedClient: this.forceClientId != undefined
                        ? this.clientService.getClient(this.forceClientId)
                        : of<Client>(undefined)
                }).pipe(tap(data => {
                    if (!this.isMode(CreateOfferMode.EDIT)) {
                        this.defaultDurationDays = data.durationDays;
                        this.setValidToDateBasedOnValidFromDate();
                    }
                    if (this.forceClientId != undefined) {
                        this.selectedClient = data.selectedClient;
                        this.selectedClientItem = this.mapClientToListboxItem(data.selectedClient);
                    }
                }));
                break;
        }

        if (stepInitializeObservable != null) {
            this.blockUiController.block(CreateOfferComponent.BLOCK_UI_STEP_CHANGE);
            stepInitializeObservable.pipe(
                finalize(() => this.blockUiController.unblock(CreateOfferComponent.BLOCK_UI_STEP_CHANGE))
            ).subscribe({
                complete: () => {
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private initEditOffer(): void {
        this.blockUiController.block(CreateOfferComponent.BLOCK_UI_INIT);
        forkJoin({
            offer: this.offerService.getItem(this.originalOfferId),
            durationDays: this.offerService.getDefaultOfferDurationDays(this.originalOfferId)
        }).pipe(
            finalize(() => this.blockUiController.unblock(CreateOfferComponent.BLOCK_UI_INIT))
        ).subscribe({
            next: data => {
                this.offer = data.offer;
                this.validFromDate = new Date(this.offer.validFrom);
                this.validToDate = new Date(this.offer.validTo);
                this.deliveryAddress = new TabularAddress(this.offer.deliveryAddressId, this.offer.deliveryAddressStreet,
                    this.offer.deliveryAddressCity, this.offer.deliveryAddressZip, this.offer.deliveryAddressCountry);
                this.defaultDurationDays = data.durationDays;
                this.refreshValidToMaxCalendarValue();
                this.canEdit = this.offer.offerLockUserLogin === this.currentUserService.currentUserName
                    && this.permissions.userCanEditOffer(this.offer.status) && !this.offer.vatBlocksEdit;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    validateClientSelection(): boolean {
        this.validationErrors = {};
        if (this.selectedClient == undefined) {
            this.validationErrors['selectedItem'] = 'error.offerDto.selectedClient.not_selected';
            return false;
        }
        return true;
    }

    saveOffer(): void {
        if (!this.canEdit) {
            this.exitWizard();
            return;
        }
        this.fillFormData();
        this.blockUiController.block(CreateOfferComponent.BLOCK_UI_SAVE);
        if (this.isMode(CreateOfferMode.COPY, CreateOfferMode.COPY_TO_PARTNER)) {
            let observable: Observable<string[]>;
            if (this.subsystemsAlreadySentTo != null) {
                observable = of(this.subsystemsAlreadySentTo);
            } else if (this.isMode(CreateOfferMode.COPY_TO_PARTNER)) {
                observable = this.offerService.checkForPartnerOfferResending(this.originalOfferId);
            } else {
                observable = of([]);
            }
            observable.subscribe(subsystems => {
                this.subsystemsAlreadySentTo = subsystems;
                if (this.isRemovingSubsystemsOwnPositionsNececary() || this.subsystemsAlreadySentTo.length > 0) {
                    this.blockUiController.unblock(CreateOfferComponent.BLOCK_UI_SAVE);
                    this.showConfirmationDialog = true;
                    this.changeDetector.markForCheck();
                } else {
                    this.save();
                }
            });
        } else {
            this.save();
        }
    }

    private fillFormData(): void {
        if (this.isUserSelectionStepEnabled()) {
            this.offer.merchantId = this.selectedUser.id;
        }
        if (this.selectedUser.clientId) {
            this.offer.clientId = this.selectedUser.clientId;
            this.offer.sellerClientId = this.selectedClient.id;
        } else {
            this.offer.clientId = this.selectedClient.id;
            this.offer.clientIdentifier = this.selectedClient.identifier;
            this.offer.clientName = this.selectedClient.name;
            this.offer.clientEmail = this.selectedClient.email;
            this.offer.sellerClientId = null;
        }

        this.offer.validFrom = this.validFromDate;
        this.offer.validTo = this.validToDate;
    }

    public save(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        if (this.deliveryAddress != null) {
            this.offer.deliveryAddressId = this.deliveryAddress.id;
        }
        this.createOfferService.save(this.createOfferMode, this.offer, this.validationErrors, this.originalOfferId, this.updateExchangeRate).pipe(
            finalize(() => {
                this.saveInProgress = false;
                this.blockUiController.unblock(CreateOfferComponent.BLOCK_UI_SAVE);
                this.changeDetector.markForCheck();
            })
        ).subscribe({
            next: offerId => {
                if (this.createOfferMode === CreateOfferMode.COPY_TO_PARTNER && !this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
                    this.growlMessageController.info('OFFER.CREATE_OFFER.SENT_TO_PARTNER_BUT_LACKING_PERMISSIONS');
                    this.goToOfferList();
                } else {
                    this.goToOfferPositionList(offerId);
                }
            },
            error: error => {
                this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(error));
            }
        });
    }

    private showGrowlMessages(messages: Message[]): void {
        for (let message of messages) {
            this.growlMessageController.show(message);
        }
    }

    closeTheDialog(): void {
        this.showConfirmationDialog = false;
    }

    getConfirmationDialogContent(): string[] {
        let content = [];
        if (this.isRemovingSubsystemsOwnPositionsNececary()) {
            content.push(this.translate.instant('OFFER.FORM.REMOVING_ADDONS_AND_ASSEMBLY_DIALOG_CONFIRMATION_HEADER'));
        }
        if (this.subsystemsAlreadySentTo.length > 0) {
            content.push(this.translate.instant('OFFER.FORM.RESENDING_PARTNER_OFFER_DIALOG_CONFIRMATION_HEADER',
                {subsystems: this.subsystemsAlreadySentTo}));
        }
        return content;
    }

    isRemovingSubsystemsOwnPositionsNececary(): boolean {
        if (!this.offer.subsystemOwnPositionPresent) {
            return false;
        }
        if (this.isUserSelectionStepEnabled() && +this.selectedSubsystemId !== this.offer.subsystemId) {
            return true;
        }
        return false;
    }

    subsystemSelectionChange(subsystemId: number): void {
        this.selectedSubsystemId = subsystemId;

        if (this.selectedSubsystemId != undefined) {
            this.createOfferService.loadUsersBySubsystem(this.selectedSubsystemId, this.isMode(CreateOfferMode.COPY_TO_PARTNER))
                .subscribe(users => {
                    this.usersList = users.data;
                    this.availableUsers = users.data.map(user => {
                        return {
                            label: user.firstName + ' ' + user.lastName,
                            value: this.mapUserToListboxItem(user)
                        };
                    });

                    this.changeDetector.markForCheck();
                });
        }
    }

    private loadClients(user: User): Observable<Listing<Client>> {
        let filters = {
            active: {
                value: 'true'
            },
            visibleToUser: {
                value: user.login
            }
        };
        return this.clientService.getClients(0, 99999, filters, "id", 0);
    }

    mapClientToListboxItem(client: Client): ListboxSelectionItem {
        return new ListboxSelectionItem(client.id, [client.name, client.groupName, client.email]);
    }

    onClientAdded(event: AddClientSubmitEvent): void {
        this.showGrowlMessages(event.msgs);
        this.createdClient = undefined;
        this.loadClients(this.selectedUser).subscribe(clients => {
            this.loadClientsCallback(clients);
            this.changeDetector.markForCheck();
        });
    }

    prepareDataForNewClient(): void {
        forkJoin({
            clientGroups: this.clientGroupService.getClientGroups(undefined, undefined, {
                subsystemId: {value: this.selectedSubsystemId}
            }, 'name', 1),
            client: of(new Client())
        }).subscribe({
            next: data => {
                this.existingGroups = data.clientGroups.data;
                this.createdClient = data.client;
                this.createdClient.owner = this.selectedUser.login;
                this.changeDetector.markForCheck();
            },
            error: error => {
                throw new ResponseError(error);
            },
            complete: () => {
                FocusOnElement.tryToFocus('groupName', 0, 3, 100);
                console.debug('prepareDataForClient` completed!');
            }
        });
    }

    handleClientSelection(selectedItem: ListboxSelectionItem) {
        this.selectedClient = this.clientsList.find(client => client.id === selectedItem.id);
        this.validateClientSelection();
    }

    private constructSearchLabel(client: Client) {
        return [client.name, client.groupName, client.email].join(';');
    }

    setValidToDateBasedOnValidFromDate() {
        this.validToDate = moment(this.validFromDate).add(this.defaultDurationDays, "days").toDate();
        this.refreshValidToMaxCalendarValue();
    }

    refreshValidToMaxCalendarValue() {
        this.maxValidTo = moment(this.validFromDate).add(2, "months").toDate();
    }

    private validateDetails(): boolean {
        this.validationErrors = {};
        if (!this.offer.validFrom) {
            this.validationErrors['validFrom'] = 'error.offerDto.validFrom.not_empty';
        }
        if (!this.offer.validTo) {
            this.validationErrors['validTo'] = 'error.offerDto.validTo.not_empty';
        } else {
            if (this.offer.validTo > this.maxValidTo) {
                this.validationErrors['validTo'] = 'error.offerDto.validTo.over_max';
            }
            if (this.offer.validTo < this.offer.validFrom) {
                this.validationErrors['validTo'] = 'error.offerDto.validTo.over_validFrom';
            }
        }
        return Object.keys(this.validationErrors).length === 0;
    }

    mapUserToListboxItem(user: User): ListboxSelectionItem {
        return new ListboxSelectionItem(user.id, [user.firstName, user.lastName, user.roleName]);
    }

    private readRouteParams(): void {
        this.forceClientId = this.getRouteParamIfPresent('clientId');
        this.createOfferMode = this.getRouteParamIfPresent('mode');
        this.originalOfferId = this.getRouteParamIfPresent('offerId');
        this.forceUserId = this.getRouteParamIfPresent('userId');
    }

    private getRouteParamIfPresent(paramName: string): any {
        return this.activatedRoute.snapshot.paramMap.has(paramName) ?
            +this.activatedRoute.snapshot.paramMap.get(paramName) : undefined;
    }

    private initSubsystemsAndUsers(): Observable<SelectItem[]> {
        return this.createOfferService.loadAvailableSubsystems(this.createOfferMode === CreateOfferMode.COPY_TO_PARTNER)
            .pipe(tap(subsystems => {
                this.availableSubsystems = subsystems;
                if (this.availableSubsystems.length > 0) {
                    this.subsystemSelectionChange(this.availableSubsystems[0].value);
                }
            }));
    }

    isUpdateExchangeRateFieldVisible(): boolean {
        return this.isMode(CreateOfferMode.COPY, CreateOfferMode.COPY_TO_PARTNER, CreateOfferMode.EDIT);
    }

    isUserSelectionStepEnabled() {
        return (this.isMode(CreateOfferMode.NEW, CreateOfferMode.COPY) && this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}))
            || (this.isMode(CreateOfferMode.COPY_TO_PARTNER) && this.permissions.canCopyOfferToPartner());
    }

    handleUserSelection(selectedItem: ListboxSelectionItem) {
        this.selectedUser = this.usersList.find(user => user.id === selectedItem.id);
        this.validateUserSelection();
    }

    getWizardTitle(): string {
        switch (this.createOfferMode) {
            case CreateOfferMode.COPY:
                return 'OFFER.CREATE_OFFER.COPY_TITLE';
            case CreateOfferMode.COPY_TO_PARTNER:
                return 'OFFER.CREATE_OFFER.COPY_TO_PARTNER_TITLE';
            case CreateOfferMode.EDIT:
                return 'OFFER.CREATE_OFFER.EDIT_TITLE';
            case CreateOfferMode.NEW:
                return 'OFFER.CREATE_OFFER.TITLE';
        }

        return '';
    }

    handleAddressSelected(address: TabularAddress): void {
        delete this.validationErrors['deliveryAddress'];
        this.deliveryAddress = this.addresses.find(subsystemAddress => address.id === subsystemAddress.id);
        this.deliveryAddressSelectionDialogVisible = false;
    }

    handleAddressSelectionVisibilityChange(visible: boolean): void {
        if (!visible) {
            this.deliveryAddressSelectionDialogVisible = false;
        }
    }

    showDeliveryAddressSelectionDialog(): void {
        if (this.addresses == undefined) {
            this.addressService.getUsableAddresses(this.selectedUser.subsystemId, this.offer.id).subscribe({
                next: (data: TabularAddress[]) => {
                    this.addresses = data;
                    this.deliveryAddressSelectionDialogVisible = true;
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
            return;
        }
        this.deliveryAddressSelectionDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    isMode(...modes: CreateOfferMode[]): boolean {
        return modes.some(mode => mode === this.createOfferMode);
    }

    getContactMethodString(offer: Offer): Observable<string> {
        if (offer.webshopPreferredContactMethod.length === 0) {
            return this.translate.get('OFFER.CREATE_OFFER.FORM.CONTACT_METHOD.NONE');
        }
        const fullKey = (contactMethod: ContactMethod): string => {
            return 'OFFER.CREATE_OFFER.FORM.CONTACT_METHOD.' + contactMethod;
        };
        return this.translate.get(offer.webshopPreferredContactMethod.map(fullKey))
            .pipe(map(translations => offer.webshopPreferredContactMethod.map(cm => translations[fullKey(cm)]).join(', ')));
    }

    private goToOfferList(): void {
        this.router.navigate(['/features/offer']);
    }

    private goToClientList(): void {
        this.router.navigate(['/features/client']);
    }

    private goToOfferPositionList(offerId: number): void {
        this.router.navigate(['/features/offer', offerId, 'position']);
    }

    private initSelectedClient(clients: Client[]) {
        if (!this.selectedClient) {
            let foundClient = clients ? clients.find(client => client.id === this.offer.clientId) : null;

            if (foundClient) {
                this.selectedClient = foundClient;
                this.selectedClientItem = this.mapClientToListboxItem(foundClient);

                this.changeDetector.detectChanges();
            }
        }
    }

    private getOrLoadUser(): Observable<User> {
        if (this.selectedUser == undefined) {
            return this.userService.getUserProfile().pipe(tap(user => {
                this.selectedUser = user;
                this.selectedUserItem = this.mapUserToListboxItem(user);
                if (this.isMode(CreateOfferMode.NEW)) {
                    this.addressService.getUsableDeliveryAddress(this.selectedUser.subsystemId, this.offer.id).subscribe({
                        next: data => {
                            this.deliveryAddress = data;
                            this.changeDetector.markForCheck();
                        },
                        error: error => this.errors.handle(error)
                    });
                }
            }));
        } else {
            let userObservable = of(this.selectedUser);
            if (this.isMode(CreateOfferMode.COPY) && this.selectedUser.subsystemId !== this.offer.subsystemId) {
                userObservable = userObservable.pipe(mergeMap(user => {
                    return this.addressService.getUsableDeliveryAddress(this.selectedUser.subsystemId, undefined).pipe(
                        catchError(error => {
                            return throwError(() => this.errors.handle(error));
                        }),
                        map(address => {
                            this.deliveryAddress = address;
                            this.changeDetector.markForCheck();
                            return user;
                        }));
                }));
            }
            return userObservable;
        }
    }

    private loadClientsCallback(clients: Listing<Client>): void {
        this.initSelectedClient(clients.data);

        this.clientsList = clients.data;
        this.clients = clients.data.map(client => {
            return {
                label: this.constructSearchLabel(client),
                value: this.mapClientToListboxItem(client)
            };
        });
    }

    private validateUserSelection(): boolean {
        this.validationErrors = {};

        if (this.selectedSubsystemId == null) {
            this.validationErrors['subsystem'] = 'error.offerDto.subsystem.not_selected';
        }

        if (this.selectedUser == null) {
            this.validationErrors['selectedItem'] = 'error.offerDto.selectedUser.not_selected';
        }

        return Object.keys(this.validationErrors).length === 0;
    }

    get historyViewAllowed() {
        return this.isMode(CreateOfferMode.EDIT);
    }
}
