<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.REDRAW_WINDOWS.GENERAL' | translate }}</div>
    </div>
    <div class="new-form-400">
        <h3 *ngIf="isRedrawInProgress" class="new-form-row in-progress-info">
            {{ 'error.redrawWindows.inProgress' | translate }}
        </h3>

        <h3 class="new-form-row">
            {{ 'REDRAW_WINDOWS.OFFERS_CREATE_DATES_RANGE' | translate }}
        </h3>
        <div class="new-form-row">
            <app-calendar inputId="valid-from" label="{{ 'REDRAW_WINDOWS.DATE_FROM' | translate }}"
                          [utc]="true" [(ngModel)]="offerCreatedFromDate"
                          [(validationMessageKey)]="validationErrors['offerCreatedFromDate']"
            ></app-calendar>
            <app-calendar inputId="valid-to" label="{{ 'REDRAW_WINDOWS.DATE_TO' | translate }}"
                          [utc]="true" [(ngModel)]="offerCreatedToDate"
                          [(validationMessageKey)]="validationErrors['offerCreatedToDate']"
            ></app-calendar>
        </div>
        <div class="new-form-row">
            <app-simple-button buttonId="clearDates"
                               label="{{ 'REDRAW_WINDOWS.CLEAR_DATES' | translate }}"
                               [size]="40" type="delete"
                               [disabled]="offerCreatedFromDate == null && offerCreatedToDate == null"
                               (onClick)="clearDates()"></app-simple-button>
        </div>

        <h3 class="new-form-row">
            {{ 'REDRAW_WINDOWS.EXECUTE' | translate }}
        </h3>
        <div class="new-form-row">
            <app-checkbox *ngIf="offerCreatedFromDate == null && offerCreatedToDate == null"
                          inputId="iAmNotARobotCheckbox"
                          label="{{ 'REDRAW_WINDOWS.VERIFY' | translate }}"
                          [(ngModel)]="verified"></app-checkbox>
        </div>
        <div class="new-form-row">
            <app-checkbox inputId="withSvgCheckbox"
                          label="{{ 'REDRAW_WINDOWS.WITH_SVG' | translate }}"
                          [(ngModel)]="withSvg"></app-checkbox>
        </div>
        <div class="new-form-row">
            <app-simple-button buttonId="redrawAllPositions"
                               label="{{ 'REDRAW_WINDOWS.EXECUTE' | translate }}"
                               [size]="40" type="delete"
                               [disabled]="isRedrawInProgress || (offerCreatedFromDate == null && offerCreatedToDate == null && !verified)"
                               (onClick)="executeDrawingDatasUpdate()"></app-simple-button>
        </div>
    </div>
</div>
