import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemArrayToJson, mapItemToJson} from '../../common/crud-common/crudItem';
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {SelectItemImpl} from '../../common/service/select.item.impl';
import {ValidationErrors} from '../../common/validation-errors';
import {ValidationErrorsHelper} from '../../common/ValidationErrorsHelper';
import {Currencies} from '../../currencies';
import {
    SubsystemSalesTargetChangeHistoryEntry
} from '../settings/subsystem-sales-target-settings/subsystem-sales-target-change-history-entry';
import {UserList} from '../user/user-list';
import {Subsystem, SubsystemCurrencyData} from './subsystem';
import {SubsystemUserActivityReport} from './subsystem-user-activity-report/subsystem-user-activity-report';
import {SubsystemSelectionItem} from './SubsystemSelectionItem';
import {TabularAddress} from './tabularAddress';

@Injectable()
export class SubsystemService {

    public static readonly API_URL = 'subsystem';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string, sortOrder: number): Observable<Listing<Subsystem>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('subsystems', {params: params}).pipe(
            map(response => Listing.fromJSON(Subsystem, response)));
    }

    getItem(itemId: number): Observable<Subsystem> {
        return this.http.get<object>(`${SubsystemService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(Subsystem));
    }

    getAddresses(subsystemId: number): Observable<TabularAddress[]> {
        return this.http.get<TabularAddress[]>(`${SubsystemService.API_URL}/${subsystemId}/addresses`)
            .pipe(map(response => response.map(TabularAddress.fromJSON)));
    }

    save(subsystem: Subsystem, addresses: TabularAddress[], correspondenceAddress: TabularAddress,
         deliveryAddress: TabularAddress): Observable<number> {
        let body = JSON.stringify(subsystem);
        let formData = new FormData();
        formData.append('subsystemDto', new Blob([body], {
            type: 'application/json'
        }));
        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }
        if (subsystem.id != undefined) {
            return this.http.put<void>(`${SubsystemService.API_URL}/${subsystem.id}`, formData)
                .pipe(this.dataServiceHelper.mapToExistingItemId(subsystem.id));
        }
        return this.http.post<void>(SubsystemService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(SubsystemService.API_URL));
    }

    saveFiles(subsystemId: number, logoGlamour: File, glamourTitlePageImage: File, template: File, confirmationTemplate: File, glamourAnnotations: File): Observable<void> {
        let formData = new FormData();
        this.appendImageFile(formData, logoGlamour, 'logoGlamour');
        this.appendImageFile(formData, glamourTitlePageImage, 'glamourTitlePageImage');
        this.appendImageFile(formData, template, 'template');
        this.appendImageFile(formData, confirmationTemplate, 'confirmationTemplate');
        this.appendImageFile(formData, glamourAnnotations, 'glamourAnnotations');

        return this.http.post<void>(`${SubsystemService.API_URL}/${subsystemId}/files`, formData);
    }

    saveAddresses(addresses: TabularAddress[], correspondenceAddress: TabularAddress, deliveryAddress: TabularAddress): Observable<any> {
        let formData = new FormData();

        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }
        return this.http.post(`${SubsystemService.API_URL}/saveAddresses`, formData, {observe: 'response'});
    }

    validateDetails(subsystem: Subsystem): Observable<ValidationErrors> {
        let body = JSON.stringify(subsystem);
        let formData = new FormData();
        formData.append('subsystemDto', new Blob([body], {
            type: 'application/json'
        }));

        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${SubsystemService.API_URL}/validateDetails`, formData));
    }

    validateAddresses(addresses: TabularAddress[]): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${SubsystemService.API_URL}/validateAddresses`, addresses));
    }

    validateAddress(subsystem: Subsystem, addresses: TabularAddress[], correspondenceAddress: TabularAddress,
                    deliveryAddress: TabularAddress): Observable<ValidationErrors> {
        const formData = new FormData();
        formData.append('subsystemDto', new Blob([JSON.stringify(subsystem)], {type: 'application/json'}));
        formData.append('addresses', new Blob([JSON.stringify(addresses)], {type: 'application/json'}));
        if (correspondenceAddress != undefined) {
            formData.append('correspondenceAddress', new Blob([JSON.stringify(correspondenceAddress)], {type: 'application/json'}));
        }
        if (deliveryAddress != undefined) {
            formData.append('deliveryAddress', new Blob([JSON.stringify(deliveryAddress)], {type: 'application/json'}));
        }
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${SubsystemService.API_URL}/validateAddress`, formData));
    }

    private appendImageFile(formData: FormData, file: File, filename: string) {
        if (file != undefined) {
            formData.append(filename, file);
        }
    }

    getClientManagers(): Observable<UserList> {
        return this.http.get<UserList>('users/clientmanagers');
    }

    getNames(limitToPartners = false): Observable<Listing<SubsystemSelectionItem>> {
        let params = {};
        if (limitToPartners) {
            params['limitToPartners'] = 'true';
        }
        return this.http.get<Listing<object>>('subsystems/names', {params: params}).pipe(
            map(response => Listing.fromJSON(SubsystemSelectionItem, response)));
    }

    getSelectionItems(limitToPartners = false): Observable<SelectItem<string>[]> {
        return this.getNames(limitToPartners).pipe(map(response => response.data.map(s => new SelectItemImpl(s.name, `${s.id}`))));
    }

    isWebshopEnabledForCurrentUserSubsystem(): Observable<boolean> {
        return this.http.get<boolean>('subsystem/isWebshopEnabledForCurrentUserSubsystem');
    }

    getSubsystemForCurrentUser(): Observable<Subsystem> {
        return this.http.get<object>(`${SubsystemService.API_URL}/currentUser`)
            .pipe(mapItemToJson(Subsystem));
    }

    getDefaultCurrency(): Observable<Currencies> {
        return this.http.get<Currencies>(`${SubsystemService.API_URL}/currentUserSubsystemCurrency`);
    }

    getSubsystemCurrencyData(subsystemId): Observable<SubsystemCurrencyData> {
        return this.http.get<SubsystemCurrencyData>(`${SubsystemService.API_URL}/currencyData`, {params: {subsystemId}})
            .pipe(mapItemToJson(SubsystemCurrencyData));
    }

    currentUserSubsystemHasPartners(): Observable<boolean> {
        return this.http.get<boolean>(`${SubsystemService.API_URL}/currentUserSubsystemHasPartners`);
    }

    saveSalesTargets(subsystemIds: number[], value: number): Observable<void> {
        const params = {subsystemId: subsystemIds.join()};
        return this.http.post<void>(`${SubsystemService.API_URL}/subsystemSalesTarget`, value, {params});
    }

    getSalesTargetHistory(subsystemId: number): Observable<SubsystemSalesTargetChangeHistoryEntry[]> {
        return this.http.get<object[]>(`${SubsystemService.API_URL}/${subsystemId}/salesTargetHistory`)
            .pipe(mapItemArrayToJson(SubsystemSalesTargetChangeHistoryEntry));
    }

    hasNonGroupProfitMargins(subsystemId: number[]): Observable<{ [subsystemId: number]: boolean }> {
        return this.http.post<{ [subsystemId: number]: boolean }>(`${SubsystemService.API_URL}s/hasNonGroupProfitMargins`, subsystemId)
    }

    getUserActivityReport(subsystemId: number): Observable<SubsystemUserActivityReport[]> {
        return this.http.get<object[]>(`${SubsystemService.API_URL}/${subsystemId}/userActivityReport`)
            .pipe(mapItemArrayToJson(SubsystemUserActivityReport));
    }
}
