import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {MarkedProduct} from "./marked-product";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class MarkProductsService {

    private static readonly API_URL = 'markedProduct';

    constructor(private http: HttpClient) { }

    save(markedProduct: MarkedProduct) {
        return this.http.post<void>(`${MarkProductsService.API_URL}`, markedProduct);
    }

    getItems(): Observable<MarkedProduct[]> {
        return this.http.get<object[]>(`${MarkProductsService.API_URL}`)
            .pipe(map(response => response.map(MarkedProduct.fromJSON)));
    }
}
