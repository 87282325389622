import {CrudItem} from '../crud-common/crudItem';

export class BaseAddonProfitMargin implements CrudItem {
    id: number;
    addonCategory: string;
    isConfigAddon: boolean;
    isBulkConfigAddon: boolean;
    value: number;
    valueString: string;
}
