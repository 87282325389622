import {PlatformLocation} from "@angular/common";
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {SelectItem} from "primeng/api/selectitem";
import {DataTable} from "primeng/datatable";
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {WindowSystemType} from "../../../../../window-designer/catalog-data/window-system-interface";
import {CommonErrorHandler} from '../../../../common/CommonErrorHandler';
import {CrudCommonComponent} from "../../../../common/crud-common/crud.component";
import {ComponentWithUserConfigAndPaginator} from "../../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {MaterialType} from "../../../../common/enums/MaterialType";
import {TranslatedSelectItemService} from "../../../../common/service/translated-select-item.service";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {BusinessTypeService} from "../../business-type/business-type.service";
import {BusinessType} from '../../business-type/BusinessType';
import {WindowSystemWebShopInfo} from "../../window-system-definition/window-system-web-shop-info";
import {WindowSystemWebShopInfoService} from "../../window-system-definition/window-system-web-shop-info.service";
import {WindowSystemWebshopSettings} from "./window-system-webshop-settings";
import {WindowSystemWebshopService} from "./window-system-webshop.service";

@Component({
    selector: 'app-window-system-webshop',
    templateUrl: './window-system-webshop.component.html',
    styleUrls: ['./window-system-webshop.component.css', '../../../shared-styles.css'],
    providers: [WindowSystemWebShopInfoService, DataServiceHelper, BusinessTypeService, TranslatedSelectItemService,
        WindowSystemWebshopService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemWebshopComponent extends ComponentWithUserConfigAndPaginator implements OnInit, AfterViewInit {

    readonly STEPS = {
        DATA: 'DATA',
        IMAGE: 'IMAGE',
        BUSINESS_TYPES: 'BUSINESS_TYPES',
        AREA_BAR_CODES: 'AREA_BAR_CODES'
    };

    readonly VALIDATORS = {
        DATA: () => of(true),
        IMAGE: () => of(true),
        BUSINESS_TYPES: () => of(this.validateBusinessTypes()),
        AREA_BAR_CODES: () => of(true)
    };

    windowSystemWebshopSettings: WindowSystemWebshopSettings[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    filterMaterial: Observable<SelectItem[]>;
    filterType: Observable<SelectItem[]>;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;

    displayDialog = false;
    userLang: string;
    selectedRecord: WindowSystemWebshopSettings;

    @ViewChild('dt', {static: true}) datatable: DataTable;
    windowSystemWebShopInfo: WindowSystemWebShopInfo;

    imagePreview: SafeResourceUrl;
    protected sanitizer: DomSanitizer;
    item: WindowSystemWebshopSettings;
    availableBusinessTypes: BusinessType[];

    constructor(private windowSystemWebshopService: WindowSystemWebshopService,
                private windowSystemWebShopInfoService: WindowSystemWebShopInfoService,
                private location: PlatformLocation,
                private businessTypeService: BusinessTypeService,
                public translate: TranslateService,
                private errorHandler: CommonErrorHandler,
                private translatedSelectItemService: TranslatedSelectItemService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopComponent', false);
        this.userLang = translate.currentLang;
        this.sanitizer = injector.get(DomSanitizer);
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterMaterial = this.translatedSelectItemService.buildUnsortedDropdown(MaterialType, 'MATERIAL.',
            'WINDOW-SYSTEM-DEFINITION.MATERIAL.ALL');
        this.filterType = this.translatedSelectItemService.buildSortedDropdown(WindowSystemType.getWindowAndTerraceSystemsTypes().map(type => type.type),
            'WINDOW-SYSTEM-DEFINITION.SYSTEM_TYPE.', '');
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.getItemWithImage(event.data.windowSystemId);
        this.keepSelectedItemIndex(event);
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.windowSystemWebshopService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WindowSystemWebshopComponent `getPage` success:', data);
                    this.windowSystemWebshopSettings = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedRecord = this.restoreSelectionAfterLoad(this.selectedRecord, this.windowSystemWebshopSettings, event);
                },
                error: error => {
                    console.error('WindowSystemWebshopComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WindowSystemWebshopComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    validateBusinessTypes(): boolean {
        if (this.item.enabledInWebshop && this.item.availableBusinessTypesIds.length === 0) {
            this.validationErrors = Object.assign({}, this.validationErrors, {businessTypes: 'error.windowSystemWebshopSettingsDto.availableBusinessTypesIds.not_empty'});
            this.changeDetector.markForCheck();
            return false;
        }
        return true;
    }

    private validateForm(): boolean {
        this.validationErrors = {};
        return this.validateBusinessTypes();
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            this.windowSystemWebshopService.saveItem(this.item).pipe(tap(() => this.showSuccessMessage())).subscribe({
                next: () => {
                    this.reloadDatatable();
                    this.cancel();
                },
                error: error => {
                    console.error('WindowSystemWebshopComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                }
            });
        }
    }

    getItemWithImage(windowSystemId: number) {
        forkJoin({
            item: (this.selectedRecord.id ? this.windowSystemWebshopService.getItem(windowSystemId) : of(undefined)),
            image: this.windowSystemWebShopInfoService.getImage(windowSystemId),
            windowSystemWebShopInfo: this.windowSystemWebShopInfoService.getWindowSystemWebShopInfo(windowSystemId),
            availableBusinessTypes: this.businessTypeService.getBusinessTypesByWindowSystem(windowSystemId)
        }).subscribe({
            next: data => {
                this.item = data.item ? data.item : Object.assign({}, this.selectedRecord);
                if (data.image) {
                    this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(data.image);
                } else {
                    this.imagePreview = undefined;
                }
                this.windowSystemWebShopInfo = data.windowSystemWebShopInfo;
                this.availableBusinessTypes = data.availableBusinessTypes;
            },
            error: error => {
                this.errorHandler.handle(error);
            },
            complete: () => {
                console.debug('getWindowSystemData for subsystem completed!');
                this.setDisplayDialog(true);
            }
        });
    }

    onBusinessTypeSelectionChange(businessTypeId: number): void {
        if (this.isBusinessTypeSelected(businessTypeId)) {
            this.item.availableBusinessTypesIds = _.without(this.item.availableBusinessTypesIds, businessTypeId);
        } else {
            this.item.availableBusinessTypesIds.push(businessTypeId);
        }
    }

    isBusinessTypeSelected(businessTypeId: number): boolean {
        return this.item.availableBusinessTypesIds.includes(businessTypeId);
    }

    toggleAllBusinessTypes(): void {
        if (this.item.availableBusinessTypesIds.length === this.availableBusinessTypes.length) {
            this.item.availableBusinessTypesIds = [];
        } else {
            this.item.availableBusinessTypesIds = this.availableBusinessTypes.map(type => type.id);
        }
    }

    setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    cancel() {
        this.setDisplayDialog(false);
    }

    showSuccessMessage() {
        this.growlMessageController.info('WINDOW_SYSTEM_WEBSHOP_SETTINGS.UPDATED');
    }

    showDialogToAdd(): void {
    }
}
