import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import * as _ from 'underscore';
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {WindowTypeCodeParser} from "../../../../../window-designer/utils/WindowTypeCodeParser";
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";
import {WindowTypeCode} from "../../../../../window-designer/window-types/window-type-code";
import {MaterialType} from '../../../../common/enums/MaterialType';
import {CatalogOption} from "../../../catalog-creator/catalog-option/catalog-option";
import {CatalogProperty} from "../../../catalog-creator/catalog-property/catalog-property";
import {BusinessTypeBasic} from "../../../window-system/business-type/BusinessTypeBasic";
import {WindowEditorWindowSystemInterface} from "../../window-editor/window-editor-window-system-interface";
import {AddProductDialogDataProviderComponent} from "./add-product-dialog-data-provider.component";

@Component({
    selector: 'app-add-window-dialog',
    templateUrl: './add-product-dialog-data-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddWindowDialogComponent extends AddProductDialogDataProviderComponent<BusinessTypeBasic> implements OnInit {

    @Input() data: DrawingData;
    @Input() windowSystems: WindowEditorWindowSystemInterface[];

    tableHeader = 'PRICE_TABLES.FORM.TYPE';
    dropdownSelectLabel = 'PRICE_TABLES.FORM.SYSTEM_NAME';
    showDropdown = true;

    constructor(translate: TranslateService,
                domSanitizer: DomSanitizer,
                changeDetector: ChangeDetectorRef) {
        super(translate, domSanitizer, changeDetector);
    }

    static generateTypeIconUrl(doors: boolean, typeCode: WindowTypeCode): string {
        let businessTypeCategory = doors ? 'doors' : 'windows';
        return `assets/images/window-designer/business-type-icons/${businessTypeCategory}/${typeCode}.png`;
    }

    prepareDataToSubmit() {
       return {systemId: this.selectedDropdownSelectOption, businessType: this.selectedTableOption.type};
    }

    ngOnInit() {
        this.initTabs();
        // no initial data handling - for windows this dialog is on start only. Change of system is a pure select.
        let lastAddedSystem = this.windowSystems.find(system => system.id === this.data.windowSystemId);
        let selectedSystem = lastAddedSystem || _.chain(this.windowSystems)
            .sortBy(item => item.sortIndex)
            .first()
            .value();
        let selectedBusinessType = _.chain(this.items)
            .filter(item => item.windowSystems.includes(selectedSystem.id))
            .sortBy(item => item.sortIndex)
            .first()
            .value();
        this.handleTabChange(selectedSystem.material);
        this.handleDropdownChange(selectedSystem.id);
        this.handleValueChange(selectedBusinessType);
        this.changeDetector.markForCheck();
    }

    private initTabs(): void {
        this.initMaterialTabs();
    }

    getWindowSystems() {
        return (this.windowSystems || []);
    }

    private getEntityTabIndex(item: WindowEditorWindowSystemInterface): string {
        return item.material;
    }

    handleTabChange(material: MaterialType): void {
        this.activeTabItem = this.tabItems.find(tab => tab.tabindex === material);
        let filteredItems = _.chain(this.windowSystems || [])
            .filter(item => this.getEntityTabIndex(item) === this.activeTabItem.tabindex)
            .sortBy(item => item.sortIndex)
            .value();
        this.initDropdown(filteredItems);
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleDropdownChange(selectedItem.id);
    }

    public initDropdown(items: WindowEditorWindowSystemInterface[]): void {
        this.dropdownSelectOptions = _.chain(items)
            .sortBy(item => item.sortIndex)
            .map(item => this.prepareDropdownOption(item))
            .value();
        this.showDropdown = this.dropdownSelectOptions.length > 1;
    }

    private prepareDropdownOption(item: WindowEditorWindowSystemInterface): SelectItem {
        return {
            label: item.names[this.translate.currentLang],
            value: item.id
        };
    }

    getTypeIconUrl(typeCode: WindowTypeCode): string {
        return AddWindowDialogComponent.generateTypeIconUrl(this.getSelectedSystem().doors, typeCode);
    }

    private getSelectedSystem() {
        return this.windowSystems.find(system => system.id === this.selectedDropdownSelectOption);
    }

    handleDropdownChange(dropdownValue: number): void {
        this.selectedDropdownSelectOption = dropdownValue;
        this.setSelectedSystemImage();
        let filteredItems = _.chain(this.items || [])
            .filter(model => model.windowSystems.includes(this.selectedDropdownSelectOption))
            .sortBy(item => item.sortIndex)
            .value();
        this.tableOptions = filteredItems;
        for (let item of filteredItems) {
            this.tableOptionNames[item.id] = item.name[this.translate.currentLang];
            this.tableOptionIcons[item.id] = this.getTypeIconUrl(item.type);
            if (item.description != null) {
                this.tableOptionDescriptions[item.id] = item.description[this.translate.currentLang];
            }
        }
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleValueChange(selectedItem);
    }

    private setSelectedSystemImage() {
        let selectedSystemImage = this.getSelectedSystem().imageSmall;
        this.selectedValueImage = null;
        if (selectedSystemImage != null) {
            this.selectedValueImage = this.domSanitizer.bypassSecurityTrustUrl(selectedSystemImage);
        }
        this.largeImageId = this.getSelectedSystem().id;
    }

    handleValueChange(item: BusinessTypeBasic): void {
        this.selectedTableOption = item;
        if (this.selectedTableOption != undefined) {
            this.selectedValueDescription = this.prepareDescription(this.windowSystems.find(system => system.id === this.selectedDropdownSelectOption).description);
        }
    }

    canShowDescriptionOptionProperty(option: CatalogOption, property: CatalogProperty): boolean {
        const windowAttributes = WindowTypeCodeParser.parseTypeCode(this.selectedTableOption.type);
        if (property.availableInFixedWindows
            && windowAttributes.subwindows.some(sw => sw.type === SubWindowTypeCode.F || sw.type === SubWindowTypeCode.FF)) {
            return true;
        }
        if (property.availableInNonFixedWindows
            && windowAttributes.subwindows.some(sw => sw.type !== SubWindowTypeCode.F && sw.type !== SubWindowTypeCode.FF)) {
            return true;
        }
        return false;
    }
}
