import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class GraspDistanceFrameCategory implements CrudItem {
    id: number = undefined;
    name = new MultilanguageField();
    sortIndex = 1;
    active = true;

    static fromJSON(jsonObject: any): GraspDistanceFrameCategory {
        const graspDistanceFrameCategory = new GraspDistanceFrameCategory();
        graspDistanceFrameCategory.id = jsonObject['id'];
        graspDistanceFrameCategory.name = MultilanguageField.fromJSON(jsonObject['name']);
        graspDistanceFrameCategory.sortIndex = jsonObject['sortIndex'];
        graspDistanceFrameCategory.active = jsonObject['active'];
        return graspDistanceFrameCategory;
    }
}
