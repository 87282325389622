import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemToJson} from "../../../../common/crud-common/crudItem";
import {Listing, mapListingToJson} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {WindowSystemWebshopSettings} from './window-system-webshop-settings';

@Injectable()
export class WindowSystemWebshopService {

    private static readonly API_URL = 'windowSystemWebshopSettings';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WindowSystemWebshopSettings>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(WindowSystemWebshopService.API_URL, {params: params})
            .pipe(mapListingToJson(WindowSystemWebshopSettings));
    }

    saveItem(item: WindowSystemWebshopSettings): Observable<number> {
        return this.http.post<void>(`${WindowSystemWebshopService.API_URL}`, item, {observe: 'response'}).pipe(
            map(response => item.id != undefined
                ? item.id
                : this.dataServiceHelper.getNewItemId(WindowSystemWebshopService.API_URL, response)));
    }

    getItem(windowSystemId: number): Observable<WindowSystemWebshopSettings> {
        return this.http.get<object>(`${WindowSystemWebshopService.API_URL}/${windowSystemId}`)
            .pipe(mapItemToJson(WindowSystemWebshopSettings));
    }
}
