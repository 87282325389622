import {OfferStatusProvider} from "../../../../common/offerStatusProvider";
import {ShippingSimulationOfferPositionModel} from "./shipping-simulation-offer-position.model";
import {OfferStatus} from "../../../../common/enums/OfferStatus";

export class ShippingSimulationOfferModel {

    private readonly SHORT_LIST_POSITIONS_COUNT = 4;

    id: number;
    offerNumber: string;
    isOrder: boolean;
    status: OfferStatus;
    clientName: string;
    displayShortList: boolean = true;

    positions: ShippingSimulationOfferPositionModel[] = [];

    static clone(offer: ShippingSimulationOfferModel): ShippingSimulationOfferModel {
        let model = new ShippingSimulationOfferModel();
        model.id = offer.id;
        model.offerNumber = offer.offerNumber;
        model.isOrder = OfferStatusProvider.isOrderStatus(offer.status);
        model.status = offer.status;
        model.clientName = offer.clientName;
        model.positions = [];

        return model;
    }

    getPositionDisplayList(): ShippingSimulationOfferPositionModel[] {
        if (this.displayShortList) {
            return this.positions.slice(0, this.SHORT_LIST_POSITIONS_COUNT);
        } else {
            return this.positions;
        }
    }

    getNumberOfHiddenPositions(): number {
        return this.positions.length > this.SHORT_LIST_POSITIONS_COUNT ? this.positions.length - this.SHORT_LIST_POSITIONS_COUNT : 0;
    }
}