import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../../../common/CommonErrorHandler';
import {OnceFlag} from "../../../../../../shared/once-flag";
import {PositionType} from '../../../../AbstractPosition';
import {PositionService} from '../../position.service';
import {FinalizeBulkChange} from '../FinalizeBulkChange';
import {Position} from '../position';
import {BulkChangeConfirmationDialogData} from '../position-list-dialogs';

@Component({
    selector: 'app-bulk-change-confirmation',
    templateUrl: './bulk-change-confirmation.component.html',
    styleUrls: ['./bulk-change-confirmation.component.css'],
    providers: [PositionService]
})
export class BulkChangeConfirmationComponent {

    private static readonly SUBMIT_BULK_CHANGE_DIALOG_ID = 'submitBulkChangeDialog';

    @Input()
    dialogData: BulkChangeConfirmationDialogData;

    @Output()
    onSubmit = new EventEmitter<void>();

    readonly hideHelper = new OnceFlag();

    constructor(protected offerPositionService: PositionService,
                public translate: TranslateService,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
    }

    submitDialog(accepted: boolean): void {
        this.hideHelper.call(() => {
            const excludedPositionIds = Object.keys(this.dialogData.changeResult.validationResults)
                .filter(positionId => this.positionWithIdContainErrors(+positionId))
                .map(positionId => +positionId);
            let bulkChangeId = this.dialogData.changeResult.bulkChangeId;
            if (accepted) {
                this.dialogData.accepted = true;
                this.dialogData.result = new FinalizeBulkChange(bulkChangeId, excludedPositionIds);
                this.onSubmit.emit();
            } else {
                this.blockUiController.block(BulkChangeConfirmationComponent.SUBMIT_BULK_CHANGE_DIALOG_ID);
                this.offerPositionService.rejectBulkChange(bulkChangeId)
                    .pipe(finalize(() => this.blockUiController.unblock(BulkChangeConfirmationComponent.SUBMIT_BULK_CHANGE_DIALOG_ID)))
                    .subscribe({
                        error: (error) => {
                            this.errors.handle(error);
                        },
                        complete: () => {
                            this.dialogData.accepted = false;
                            this.onSubmit.emit();
                        }
                    });
            }
        });
    }

    positionContainErrors(offerPosition: Position): boolean {
        return this.positionWithIdContainErrors(offerPosition.id);
    }

    private positionWithIdContainErrors(positionId: number): boolean {
        return this.dialogData.changeResult.hasValidationErrors(positionId);
    }

    somePositionsContainErrors(): boolean {
        return this.getOnlyWindowSystems(this.dialogData.modifiedPositions).some(position => this.positionContainErrors(position));
    }

    allPositionsContainErrors(): boolean {
        return this.getOnlyWindowSystems(this.dialogData.modifiedPositions).every(position => this.positionContainErrors(position));
    }

    getOnlyWindowSystems(positions: Position[]) {
        return positions.filter(pos => pos.type === PositionType.SYSTEM);
    }
}
