import {TranslatedSelectItem} from "./translated.select.item";
import {TranslatedSelectItemImpl} from "./translated.select.item.impl";

export class TranslatedSelectItemBuilder {
  private items: TranslatedSelectItem[];
  private item: TranslatedSelectItem;

  public static create() : TranslatedSelectItemBuilder {
    return new TranslatedSelectItemBuilder();
  }

  private constructor(){
    this.items = [];
  }

  public addItem(item: TranslatedSelectItem) {
    this.item = item;
    this.items.push(this.item);
    return this;
  }

  public add(labelKey: string, value: any) : TranslatedSelectItemBuilder {
    return this.addConditionally(labelKey, value, true);
  }

  public addConditionally(labelKey: string, value: any, shouldAdd: boolean) : TranslatedSelectItemBuilder {
    if (shouldAdd) {
        this.addItem(new TranslatedSelectItemImpl(labelKey, value));
    }
    return this;
  }

  public build(): TranslatedSelectItem[] {
    return this.items;
  }
}
