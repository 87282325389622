import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {SidenavController} from '../../../../../sidenav-controller';
import {KnownApplicationResources} from "../../../../admin-panel/application-resource/application-resource";
import {ApplicationResourceService} from "../../../../admin-panel/application-resource/application-resource.service";

@Component({
    selector: 'app-external-user-offer-edit-completed',
    templateUrl: './external-user-offer-edit-completed.component.html',
    styleUrls: ['./external-user-offer-edit-completed.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalUserOfferEditCompletedComponent implements OnInit, OnDestroy {

    constructor(private readonly sidenavController: SidenavController) {
    }

    ngOnInit(): void {
        this.sidenavController.hide();
    }

    ngOnDestroy() {
        this.sidenavController.show();
    }

    get backgroundImageUrl(): string {
        return ApplicationResourceService.getItemUrlByName(KnownApplicationResources.LOGIN_BACKGROUND);
    }
}
