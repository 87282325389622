import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemWebshopInfo} from './subsystem-webshop-info';

@Injectable()
export class SubsystemWebshopInfoService {

    private static readonly API_URL = 'subsystemWebshopInfo';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItem(): Observable<SubsystemWebshopInfo> {
        type SubsystemWebshopInfoInput = Omit<SubsystemWebshopInfo, 'footer' | 'icon' | 'logo' | 'logoMobile'> & {
            footerFileName: string,
            footer: string,
            footerFileType: string,
            iconFileName: string,
            icon: string,
            iconFileType: string,
            logoFileName: string,
            logo: string,
            logoFileType: string,
            logoMobileFileName: string,
            logoMobile: string,
            logoMobileFileType: string,
        };
        return this.http.get<SubsystemWebshopInfoInput>(SubsystemWebshopInfoService.API_URL)
            .pipe(mergeMap(response => {
                const subsystemWebshopInfo = SubsystemWebshopInfo.fromJSON(response);
                return forkJoin({
                    footer: response.footer != undefined
                        ? this.dataServiceHelper.getArrayBufferFromDataUri(response.footer) : of<ArrayBuffer>(undefined),
                    icon: response.icon != undefined
                        ? this.dataServiceHelper.getArrayBufferFromDataUri(response.icon) : of<ArrayBuffer>(undefined),
                    logo: response.logo != undefined
                        ? this.dataServiceHelper.getArrayBufferFromDataUri(response.logo) : of<ArrayBuffer>(undefined),
                    logoMobile: response.logoMobile != undefined
                        ? this.dataServiceHelper.getArrayBufferFromDataUri(response.logoMobile) : of<ArrayBuffer>(undefined)
                }).pipe(map(imageBuffers => {
                    if (imageBuffers.footer != undefined) {
                        subsystemWebshopInfo.footer = new File([imageBuffers.footer], response.footerFileName,
                            {type: response.footerFileType});
                    }
                    if (imageBuffers.icon != undefined) {
                        subsystemWebshopInfo.icon = new File([imageBuffers.icon], response.iconFileName, {type: response.iconFileType});
                    }
                    if (imageBuffers.logo != undefined) {
                        subsystemWebshopInfo.logo = new File([imageBuffers.logo], response.logoFileName, {type: response.logoFileType});
                    }
                    if (imageBuffers.logoMobile != undefined) {
                        subsystemWebshopInfo.logoMobile = new File([imageBuffers.logoMobile],
                            response.logoMobileFileName, {type: response.logoMobileFileType});
                    }
                    return subsystemWebshopInfo;
                }));
            }));
    }

    saveItem(item: SubsystemWebshopInfo, footer: File | undefined, icon: File | undefined, logo: File | undefined,
             logoMobile: File | undefined): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('subsystemWebshopInfoDto', new Blob([body], {
            type: 'application/json'
        }));
        if (footer != undefined) {
            formData.append('footer', footer);
        }
        if (icon != undefined) {
            formData.append('icon', icon);
        }
        if (logo != undefined) {
            formData.append('logo', logo);
        }
        if (logoMobile != undefined) {
            formData.append('logoMobile', logoMobile);
        }
        return this.http.post(SubsystemWebshopInfoService.API_URL, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(SubsystemWebshopInfoService.API_URL, response)));
    }

    getLanguage(): Observable<keyof MultilanguageFieldInterface> {
        return this.http.get(`${SubsystemWebshopInfoService.API_URL}/language`, {responseType: 'text'})
            .pipe(map(language => language as keyof MultilanguageFieldInterface));
    }
}
