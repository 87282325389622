import {ConfigAddonParentInfo} from "./ConfigAddonParentInfo";
import {ConfigAddonApplication} from "../enums/ConfigAddonApplication";
import {AddonAttribute} from "./AddonAttribute";
import {ConfigurableAddonDefinitionType} from "../enums/ConfigurableAddonDefinitionType";
import {ConfigAddonOpening} from "./ConfigAddonOpening";
import {ConfigData} from "../../app/features/offer/config-editor/config-data";

export class ConfigurableAddon {
    offerId: number;
    parentElementDescription: ConfigAddonParentInfo[];
    openings: ConfigAddonOpening[] = [];
    application: ConfigAddonApplication;
    definitionId: number;
    windowSystemId: number;
    glazingBeadId: number;
    parentInfo: string;
    dimensionsOverride: string;
    wingedParent = true;

    configurableAddonDefinitionType: ConfigurableAddonDefinitionType;
    attributes: AddonAttribute[] = [];
    configData: ConfigData;
}
