import * as _ from 'underscore';
import {EnumUtils} from '../../shared/enum-utils';
import {ErrorNames} from "../utils/ErrorNames";
import {OpeningDirection} from "./opening-direction";
import {SubwindowAttributes} from "./subwindow-attributes";
import {SubWindowTypeCode} from "./subwindow-type-code";

export class OpeningDirectionUtils {

    public static getReversableOpeningDirectionCharacters(): OpeningDirection[] {
        return [OpeningDirection.L, OpeningDirection.P];
    }

    public static getSubwindowOpening(typeCode: SubWindowTypeCode): OpeningDirection {
        return this.getOpeningForSuffix(SubWindowTypeCode[typeCode].slice(-1));
    }

    private static getOpeningForSuffix(suffix: string): OpeningDirection {
        switch (suffix) {
            case 'L':
                return OpeningDirection.L;
            case 'P' :
                return OpeningDirection.P;
            case 'U':
                return OpeningDirection.G;
            case 'G':
            case 'D':
            case 'A':
                return OpeningDirection.D;
            default:
                return undefined;
        }
    }

    public static subwindowTypeCodeEndsWithReversableDirection(typeCode: SubWindowTypeCode): boolean {
        let opening = this.getSubwindowOpening(typeCode);
        return _.contains(this.getReversableOpeningDirectionCharacters(), opening);
    }

    public static reverseSubwindowOpeningDirection(subwindowAttributes: SubwindowAttributes): void {
        if (this.subwindowTypeCodeEndsWithReversableDirection(subwindowAttributes.type)) {
            let typeString = SubWindowTypeCode[subwindowAttributes.type];
            let rawType = typeString.slice(0, -1);
            let direction = EnumUtils.fromString(OpeningDirection, typeString.slice(-1));
            let revesedDirection = OpeningDirection[this.getReverseDirection(direction)];
            let newTypeString = rawType + revesedDirection;
            let reversedType = EnumUtils.fromString(SubWindowTypeCode, newTypeString);
            if (reversedType == undefined) {
                let noMirrorTypeError = new Error(
                    'OpeningDirectionUtils.reverseSubwindowOpeningDirection: No mirror subwindow type found for: ' +
                    typeString);
                noMirrorTypeError.name = ErrorNames.NO_MIRROR_TYPE_FOR_SUBWINDOW;
            }
            subwindowAttributes.type = reversedType;
        }
    }

    private static getReverseDirection(direction: OpeningDirection): OpeningDirection {
        switch (direction) {
            case OpeningDirection.L:
                return OpeningDirection.P;
            case OpeningDirection.P:
                return OpeningDirection.L;
            default:
                return undefined;
        }
    }

    public static areSubwindowsOpeningInOppositeDirections(typeCodeA: SubWindowTypeCode, typeCodeB: SubWindowTypeCode): boolean {
        return this.getSubwindowOpening(typeCodeA) === this.getReverseDirection(this.getSubwindowOpening(typeCodeB));
    }

    public static areSubwindowsOpeningInTheSameDirection(typeCodeA: SubWindowTypeCode, typeCodeB: SubWindowTypeCode): boolean {
        return this.getSubwindowOpening(typeCodeA) === this.getSubwindowOpening(typeCodeB);
    }
}
