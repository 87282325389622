import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {PaginatorRowsPerPageOptions} from "../../../../../../common/crud-common/paginatorRowsPerPageOptions";
import {TristateCheckboxState} from "../../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {Position, PositionSortGroup} from "../position";

export class PositionTableData {
    positions: Position[] = [];
    selectedPosition: Position = undefined;
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    rowsPerPage = PaginatorRowsPerPageOptions.defaultValue;
    canFocusFirstRow = false;
    // checkbox column selections
    selectedItems: Position[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    showFilters = false;
    readonly showProfitMarginColumn;
    readonly showDealerDiscountColumn;
    showRealDimensionsColumn = false;
    systemSelectionRestored = null;
    windowsCount = null;

    // sort group
    constructor(public sortGroup: PositionSortGroup) {
        switch (sortGroup) {
            case PositionSortGroup.SYSTEM:
                this.showProfitMarginColumn = true;
                this.showDealerDiscountColumn = true;
                break;
            case PositionSortGroup.OWN_ADDON:
                this.showProfitMarginColumn = true;
                this.showDealerDiscountColumn = false;
                break;
            case PositionSortGroup.ASSEMBLY:
            case PositionSortGroup.OWN_TRANSPORT:
                this.showProfitMarginColumn = false;
                this.showDealerDiscountColumn = false;
                break;
        }
    }

    hasWindowPositions(): boolean {
        return this.windowsCount;
    }

    getWindowPositionsCount(): number {
        return this.windowsCount;
    }
}
