import {RandomIdGenerator} from "../utils/RandomIdGenerator";

export class Wing {
    generatedId: string;
    width: number;
    height: number;

    constructor() {
        this.generatedId = RandomIdGenerator.generate();
    }
}
