import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CrudService} from "../../../common/crud-common/crud.service";
import {mapItemArrayToJson} from "../../../common/crud-common/crudItem";
import {Listing, mapListingToJson} from "../../../common/crud-common/crudItemList";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ListOfIds} from "../../ListOfIds";
import {GraspGlazingPackage} from "./grasp-glazing-package";

@Injectable()
export class GraspGlazingPackageService implements CrudService<GraspGlazingPackage> {

    private static readonly API_URL = 'graspGlazingPackage';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(item: GraspGlazingPackage): Observable<number> {
        return this.saveItem(item);
    }

    editItem(itemId: number, item: GraspGlazingPackage): Observable<number> {
        return this.saveItem(item, itemId);
    }

    getItem(itemId: number): Observable<GraspGlazingPackage> {
        return this.http.get<GraspGlazingPackage>(`${GraspGlazingPackageService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<GraspGlazingPackage>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GraspGlazingPackage>>(GraspGlazingPackageService.API_URL, {params: params});
    }

    saveItem(item: GraspGlazingPackage, itemId?: number): Observable<number> {
        const url = itemId ? `${GraspGlazingPackageService.API_URL}/${itemId}` : GraspGlazingPackageService.API_URL;
        const body = JSON.stringify(item);
        const formData = new FormData();
        formData.append('graspGlazingPackageDto', new Blob([body], {type: 'application/json'}));

        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('graspGlazingPackage', response)));
    }

    validateGeneralData(item: GraspGlazingPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GraspGlazingPackageService.API_URL}/validateGeneralData`, item));
    }

    validateGlazingData(item: GraspGlazingPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GraspGlazingPackageService.API_URL}/validateGlazingData`, item));
    }

    copy(id: number, item: GraspGlazingPackage) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('graspGlazingPackageDto', new Blob([body], {type: 'application/json'}));

        return this.http.post(`${GraspGlazingPackageService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('graspGlazingPackage', response)));
    }

    getActiveItems(windowSystemId: number, selectedPackageIds: number[] = null, readOnly = false) {
        const params = selectedPackageIds ? {selectedPackageIds: selectedPackageIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<object[]>(`${GraspGlazingPackageService.API_URL}/glazingPackagesForSystem/${windowSystemId}`,
            {params: params}).pipe(mapItemArrayToJson(GraspGlazingPackage));
    }

    getActiveItemsForSystems(systemIds: number[], requireAllWindowSystems = false) {
        const params = systemIds ? {windowSystemIds: systemIds.toString()} : {};
        if (requireAllWindowSystems != undefined) {
            params['requireAllSystems'] = `${requireAllWindowSystems}`;
        }
        return this.http.get<Listing<object>>(`${GraspGlazingPackageService.API_URL}/glazingPackagesForSystems`, {params: params})
            .pipe(mapListingToJson(GraspGlazingPackage));
    }

    getLinkedSystems(modelId: number): Observable<number[]> {
        return this.http.get<number[]>(`${GraspGlazingPackageService.API_URL}/linked/${modelId}`);
    }

    editLinks(glazingPackageId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`${GraspGlazingPackageService.API_URL}/link/${glazingPackageId}`, systemIds).pipe(map(() => 0));
    }
}
