<div class="listbox-selection">
    <div class="listbox-selection-filter">
        <span class="material-icons">search</span>
        <input #listFilterRef (input)="listRef.onFilter($event)" class="p-inputtext p-component"
               id="listbox-selection-search" placeholder="{{ 'GENERAL.SEARCH' | translate }}"
               type="text">
    </div>
    <div class="new-form-field">
        <div class="new-form-field-input-container">
            <div *ngIf="validationErrors['selectedItem'] != undefined" class="new-form-field-error-message">
                {{ validationErrors['selectedItem'] | translate }}
            </div>
            <p-listbox #listRef (ngModelChange)="onItemChange.emit($event)"
                       (onDblClick)="handleDoubleClick()" [disabled]="disabled"
                       [invalid]="validationErrors['selectedItem'] != undefined"
                       [ngModel]="item"
                       [options]="options"
                       [filterValue]="listFilterRef.value"
                       dataKey="id" styleClass="listbox-selection-list">
                <ng-template let-item pTemplate="item">
                    <ng-container *ngFor="let i of array.from(array(columnsCount).keys())">
                        <div *ngIf="item.value.labels.length > i; else elseBlock"
                             [ngStyle]="{'width.%': (100/columnsCount)}"
                             class="listbox-selection-column" title="{{item.value.labels[i]}}">
                            {{item.value.labels[i]}}
                        </div>
                        <ng-template #elseBlock>
                            <div class="listbox-selection-column"></div>
                        </ng-template>
                    </ng-container>
                </ng-template>
            </p-listbox>
        </div>
    </div>
</div>
