import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Glazing} from '../../../window-designer/catalog-data/glazing';
import {DistanceFrame} from '../../features/window-system/distance-frame/distanceFrame';
import {GlassWithPosition} from '../../features/window-system/glass/glassWithPositions';
import {GlazingBead} from '../../features/window-system/glazing-bead/glazing-bead';
import {GlazingBeadService} from '../../features/window-system/glazing-bead/glazing-bead.service';
import {GlazingPackage, GlazingPackageTarget} from '../../features/window-system/glazing-package/glazing-package';
import {CommonErrorHandler} from '../CommonErrorHandler';
import {GlassSelectionValidator} from '../glass-selection/GlassSelectionValidator';
import {ValidationErrors} from '../validation-errors';
import {ValidationErrorsHelper} from '../ValidationErrorsHelper';

type GlazingIndex = 1 | 2 | 3 | 4;

export class GlazingPackagesValidationErrors {
    glazing1: ValidationErrors;
    glazing2: ValidationErrors;
    glazing3: ValidationErrors;
    glazing4: ValidationErrors;
    glazingPackageForAreaRanges: ValidationErrors;

    constructor() {
        this.glazing1 = {};
        this.glazing2 = {};
        this.glazing3 = {};
        this.glazing4 = {};
        this.glazingPackageForAreaRanges = {};
    }

    glazingHasErrors(index: GlazingIndex): boolean {
        return ValidationErrorsHelper.validationErrorsPresent(this[`glazing${index}`])
            || Object.prototype.hasOwnProperty.call(this.glazingPackageForAreaRanges, `glazing${index}`);
    }

    validationErrorsPresent(): boolean {
        return ValidationErrorsHelper.validationErrorsPresent(this.glazing1) ||
            ValidationErrorsHelper.validationErrorsPresent(this.glazing2) ||
            ValidationErrorsHelper.validationErrorsPresent(this.glazing3) ||
            ValidationErrorsHelper.validationErrorsPresent(this.glazing4) ||
            ValidationErrorsHelper.validationErrorsPresent(this.glazingPackageForAreaRanges);
    }

    getErrorsNotLinkedToField(index: GlazingIndex): string[] {
        return Object.keys(this[`glazing${index}`])
            .filter(field => (<string[]>GlassSelectionValidator.VALIDATED_KEYS).indexOf(field) === -1)
            .map(field => this[`glazing${index}`][field]);
    }
}

@Component({
    selector: 'app-glazing-packages-form',
    templateUrl: './glazing-packages-form.component.html',
    providers: [GlazingBeadService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingPackagesFormComponent implements OnInit {

    @Input()
    item: GlazingPackage;

    @Input()
    glazingWidths: string;

    @Input()
    validationErrors: GlazingPackagesValidationErrors;

    @Input()
    glasses: GlassWithPosition[];

    @Input()
    frames: DistanceFrame[];

    @Input()
    readOnly = false;

    @Input()
    supportPackagesForAreaRanges = false;

    @Input()
    target: GlazingPackageTarget;

    @Input()
    addMode = false;

    glazingPackageTargets = GlazingPackageTarget;
    accordionTabSelected = [false, false, false, false];
    availableGlazingBeads: GlazingBead[];

    constructor(private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private glazingBeadService: GlazingBeadService) {
    }

    ngOnInit(): void {
        this.glazingBeadService.getAllActive().subscribe({
            next: data => this.availableGlazingBeads = data.data || [],
            error: error => this.errors.handle(error)
        });
    }

    handleHasGlazingChange(index: GlazingIndex, hasGlazing: boolean): void {
        this.item[`hasGlazing${index}`] = hasGlazing;
        this.item[`glazing${index}`] = new Glazing();
        this.clearGlazingValidationErrors(index);
        if (!hasGlazing) {
            this.accordionTabSelected[index - 1] = false;
            this.item.glazingPackageForAreaRangeList = this.item.glazingPackageForAreaRangeList
                .filter(glazingPackageForAreaRange => glazingPackageForAreaRange.glassCount !== index);
        }
        this.changeDetector.markForCheck();
    }

    clearGlazingValidationErrors(index: GlazingIndex): void {
        this.validationErrors[`glazing${index}`] = {};
        this.changeDetector.markForCheck();
    }
}
