<div class="dialog-window">
    <p-dialog #dialog [visible]="true" (visibleChange)="hide(false)" [resizable]="false" [closable]="true"
              [closeOnEscape]="true" [modal]="true" [focusOnShow]="false"
              header="{{ 'OFFER.ACTIONS.DIALOGS.BULK_STATUS_CHANGE.HEADER' | translate }}">
        <div class="new-form-400">
            <div *ngIf="!isAllowedBulkTransitionStatus"
                 class="new-form-field-error-message">{{ 'OFFER.ACTIONS.DIALOGS.BULK_STATUS_CHANGE.NOT_ALLOWED_STATUS' | translate }}
            </div>
            <div *ngIf="!allSelectedOffersHaveSameStatus"
                 class="new-form-field-error-message">{{ 'OFFER.ACTIONS.DIALOGS.BULK_STATUS_CHANGE.DIFFERENT_STATUS_WARNING' | translate }}
            </div>
            <div *ngIf="!allSelectedOffersHaveValidPricing"
                 class="new-form-field-error-message">{{ 'OFFER.ACTIONS.DIALOGS.BULK_STATUS_CHANGE.PRICING_OUTDATED_WARNING' | translate }}
            </div>
            <div>&nbsp;</div>
            <div *ngFor="let offer of selectedItems">
                {{ offer.offerNumber }}
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button
                        *ngIf="isAllowedBulkTransitionStatus && allSelectedOffersHaveSameStatus && allSelectedOffersHaveValidPricing"
                        buttonId="wizard-dialog-save" label="{{ submitLabel | translate }}" type="main-action"
                        [size]="40" [disabled]="blockUiController.isBlocked()" (onClick)="submit()"></app-simple-button>
                <app-simple-button buttonId="wizard-dialog-cancel" label="{{ 'GENERAL.CANCEL' | translate }}"
                                   type="cancel" [size]="40" [disabled]="blockUiController.isBlocked()"
                                   (onClick)="dialog.close($event)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
