import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EditCatalogPermits} from "./edit-catalog-permits";
import {HttpClient} from "@angular/common/http";
import {CatalogElement} from "./catalog-element.enum";
import {DataServiceHelper} from "../../../common/dataServiceHelper";

@Injectable({
    providedIn: 'root'
})
export class EditCatalogPermitsService {

    private static readonly API_URL = 'catalogEditPermits';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    getPermitsByRole(roleId: number): Observable<EditCatalogPermits[]> {
        return this.http.get<EditCatalogPermits[]>(`${EditCatalogPermitsService.API_URL}/forRole/${roleId}`);
    }

    getPermitsByCatalogElement(catalogElement: CatalogElement): Observable<EditCatalogPermits> {
        return this.http.get<EditCatalogPermits>(`${EditCatalogPermitsService.API_URL}/forCatalog/${catalogElement}`);
    }

    saveItem(permits: EditCatalogPermits): Observable<number> {
        let body = JSON.stringify(permits);
        let formData = new FormData();
        formData.append("catalogEditPermitsDto", new Blob([body], {
            type: "application/json"
        }));
        return this.http.post(EditCatalogPermitsService.API_URL, formData, {observe: 'response'}).pipe(
            map(response => permits.id || this.dataServiceHelper.getNewItemId(EditCatalogPermitsService.API_URL, response)));
    }
}
