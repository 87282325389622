import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    Output,
    TrackByFunction,
    ViewChild
} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {DataTable} from 'primeng/datatable';
import {Permissions} from "../../../../auth/permission.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {PaginatorRowsPerPageOptions} from "../../../../common/crud-common/paginatorRowsPerPageOptions";
import {DataTableColumn} from "../../../../common/service/data.table.column";
import {TristateCheckboxState} from '../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {Palette} from '../palette';
import {PaletteAdditionalElement} from '../palette-additional-element';
import {PaletteAdditionalElementsTableData} from './palette-additional-elements-table-data';

@Component({
    selector: 'app-palette-additional-elements-table',
    templateUrl: './palette-additional-elements-table.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [Permissions],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteAdditionalElementsTableComponent {

    @Input()
    tableData: PaletteAdditionalElementsTableData;

    @Input()
    palette: Palette;

    @Input()
    canEdit: boolean;

    @Input()
    columns: DataTableColumn[];

    @ViewChild('dataTable', {static: true})
    dataTable: DataTable;

    @Output()
    onLazyLoad = new EventEmitter<any>();

    limitedRowsPerPageOptions = PaginatorRowsPerPageOptions.limitedValues;

    refreshCounter = 0;

    private readonly blockUiController: BlockUiController;
    private readonly changeDetector: ChangeDetectorRef;
    private readonly errors: CommonErrorHandler;
    private readonly translate: TranslateService;

    constructor(injector: Injector) {
        this.blockUiController = injector.get(BlockUiController);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.errors = injector.get(CommonErrorHandler);
        this.translate = injector.get(TranslateService);
    }

    getDataTable(): DataTable {
        return this.dataTable;
    }

    getPalette(): Palette {
        return this.palette;
    }

    rowTrackBy: TrackByFunction<PaletteAdditionalElement> = (index: number, paletteAdditionalElement: PaletteAdditionalElement) => {
        return `${paletteAdditionalElement.id}_${this.refreshCounter}`;
    }

    isFilterable() {
        return this.tableData.showFilters;
    }

    selectAllChange(state: TristateCheckboxState): void {
        this.tableData.allSelectedState = state;
        this.tableData.selectedItems = [];
        if (state === TristateCheckboxState.CHECKED) {
            this.tableData.selectedItems.push(...this.tableData.paletteAdditionalElements);
        }
    }

    isSelectedItem(item: PaletteAdditionalElement): boolean {
        return this.tableData.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: PaletteAdditionalElement): void {
        let index = this.tableData.selectedItems.indexOf(item);
        if (index > -1) {
            this.tableData.selectedItems.splice(index, 1);
        } else {
            this.tableData.selectedItems.push(item);
        }
        if (this.tableData.selectedItems.length === this.tableData.paletteAdditionalElements.length) {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.tableData.selectedItems.length === 0) {
            this.tableData.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    emitLazyLoad(event: any): void {
        if (this.tableData.rowsPerPage !== event.rows) {
            this.tableData.rowsPerPage = event.rows;
        }
        this.onLazyLoad.emit(event);
    }

    refreshTable(clearRowCache: boolean): void {
        if (clearRowCache) {
            this.refreshCounter += 1;
        }
        this.changeDetector.markForCheck();
    }
}
