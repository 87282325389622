import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {PaginatorRowsPerPageOptions} from "../../../../common/crud-common/paginatorRowsPerPageOptions";
import {TristateCheckboxState} from "../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ProductionOrder} from "../../production-orders/ProductionOrder";

export class PaletteTableData {
    productionOrders: ProductionOrder[] = [];
    selected: ProductionOrder = undefined;
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    rowsPerPage = PaginatorRowsPerPageOptions.defaultValue;
    selectedItems: ProductionOrder[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    showFilters = false;

    constructor(productionOrders: ProductionOrder[]) {
        this.productionOrders = productionOrders;
    }
}
