import {PriceWithTax} from "../../offer";
import {DetailedPricingSource} from "./DetailedPricingSource";

export enum CostType {
    VENSKA = 'VENSKA',
    SUBSYSTEM = 'SUBSYSTEM',
    CLIENT = 'CLIENT',
    SELLER = 'SELLER'
}

export class PricingModification {
    source: DetailedPricingSource;
    value: number;
    priceBefore: PriceWithTax;
    priceAfter: PriceWithTax;
    cascade?: boolean;
    modificationComponents?: string;
    costType?: CostType;
    bolded?: boolean;

    constructor(costType?: CostType, priceAfter?: PriceWithTax, bolded?: boolean) {
        this.priceAfter = priceAfter;
        this.costType = costType;
        this.bolded = bolded;
    }
}
