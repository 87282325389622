<div class="wizard wizard-header">
    <div class="wizard-header-left"></div>
    <div class="wizard-header-center">
        <div class="wizard-header-text">
            {{ header }}
        </div>
    </div>
    <div class="wizard-header-right"></div>
</div>
<div class="wizard wizard-content">
    <ng-content></ng-content>
</div>
<div class="wizard wizard-footer">
    <div class="wizard-footer-left">
        <app-simple-button buttonId="wizard-prev" label="{{ 'WIZARD.PREV' | translate }}" icon="keyboard_backspace"
                           type="cancel" [size]="36" (onClick)="previousStep()"></app-simple-button>
    </div>
    <div class="wizard-footer-center wizard-steps-container">
        <div *ngFor="let step of steps; let i = index" class="wizard-step flex-column"
             [ngClass]="[step.active ? 'wizard-step-active' : 'wizard-step-inactive']">
            <ng-container *ngIf="displayStepsProgression">
                <div class="wizard-step-label" [tooltipDisabled]="isTooltipHidden(i)" [pTooltip]='step.label'
                     id="wizardStepLabel{{i}}">
                    {{step.label}}
                </div>
                <div class="wizard-step-status-line">
                    <div class="wizard-step-line" [style.visibility]="i !== 0 ? 'visible' : 'hidden'"></div>
                    <div class="wizard-step-circle"></div>
                    <div class="wizard-step-line" [style.visibility]="i !== steps.length - 1 ? 'visible' : 'hidden'"></div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="wizard-footer-right">
        <app-simple-button id="wizard-next-container" buttonId="wizard-next" label="{{ (nextButtonLabel || 'WIZARD.NEXT') | translate }}"
                           type="main-action" [size]="48" fixedWidth="144px" (onClick)="nextStep()"></app-simple-button>
    </div>
</div>
