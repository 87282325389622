import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {MaterialType} from '../../../common/enums/MaterialType';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {Pricing} from '../../offer/window-editor/sidebar/pricing/Pricing';
import {WebshopRecommendedConfiguration} from './webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from './webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration',
    templateUrl: './webshop-recommended-configuration.component.html',
    providers: [WebshopRecommendedConfigurationService]
})
export class WebshopRecommendedConfigurationComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        BUSINESS_TYPE: 'BUSINESS_TYPE',
        SIZE: 'SIZE',
        MODEL: 'WINDOW_SYSTEM_MODEL',
        GLAZING: 'GLAZING',
        COLOR: 'COLOR',
        AREA_BARS: 'AREA_BARS',
        WEBSHOP_CHARGES: 'WEBSHOP_CHARGES'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateForm(),
        BUSINESS_TYPE: () => this.validateBusinessTypeForm(),
        SIZE: () => this.validateSizeForm(),
        MODEL: () => this.validateWindowSystemModelForm(),
        GLAZING: () => this.validateGlazingForm()
    };

    itemList: WebshopRecommendedConfiguration[];
    item: WebshopRecommendedConfiguration;
    selectedItem: WebshopRecommendedConfiguration;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayDialog = false;
    newItem: boolean;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;

    @ViewChild('dt')
    dataTable: DataTable;

    constructor(private webshopRecommendedConfigurationService: WebshopRecommendedConfigurationService,
                private errorHandler: CommonErrorHandler,
                public translate: TranslateService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopRecommendedConfiguration2Component', false);
        this.initDefaultSortOrder();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initShownColumns();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.webshopRecommendedConfigurationService.getItems(event.first, event.rows, event.filters, event.sortField,
            event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WebshopRecommendedConfigurationComponent `getPage` success:', data);
                    this.itemList = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.itemList, event);
                },
                error: error => {
                    console.error('WebshopRecommendedConfigurationComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WebshopRecommendedConfigurationComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }

        if (!this.validationErrorsPresent()) {
            this.setSaveInProgress(true);
            this.webshopRecommendedConfigurationService.saveItem(this.item).pipe(finalize(() => this.setSaveInProgress(false)))
                .subscribe({
                    next: () => {
                        this.reloadDatatable();
                        this.showSuccessMessage();
                        this.cancel();
                    },
                    error: error => {
                        if (error instanceof HttpErrorResponse) {
                            if ('validationMessages' in error.error) {
                                this.validationErrors = {pricing: <any>Pricing.getAllMessages(error.error)};
                                this.changeDetector.markForCheck();
                                return;
                            }
                        }
                        this.validationErrors = this.errorHandler.handle(error);
                        this.changeDetector.markForCheck();
                        console.error('WebshopRecommendedConfigurationComponent `submit` error:', error);
                    }
                });
        }
    }

    private validateForm(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['validFrom'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.validFrom')
            .withNotNullValidator()
            .validate(this.item.validFrom);

        if (!this.item.indefinite) {
            validationErrors['validTo'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.validTo')
                .withNotNullValidator()
                .validate(this.item.validTo);
        }

        if (this.item.id != undefined) {
            validationErrors['sortIndex'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);
        } else {
            validationErrors['sortIndex'] = undefined;
        }

        return this.processValidationResults(validationErrors);
    }

    private validateBusinessTypeForm(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['businessTypeId'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.businessTypeId')
            .withNotNullValidator().validate(this.item.businessTypeId);

        return this.processValidationResults(validationErrors);
    }

    private validateSizeForm(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['width'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.width')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(1, 9999, true, false).validate(this.item.productConfiguration.windowWidth);

        validationErrors['height'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.height')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(1, 9999, true, false).validate(this.item.productConfiguration.windowHeight);

        if (this.item.productConfiguration.fillingMullionPosition != undefined) {
            validationErrors['productConfiguration.fillingMullionPosition'] =
                MultiValidator.of('error.webshopRecommendedConfigurationDto.productConfiguration.fillingMullionPosition')
                    .withNotNullValidator()
                    .withDecimalValidator()
                    .withRangeValidator(Math.ceil(this.item.productConfiguration.windowHeight * 0.25),
                        Math.floor(this.item.productConfiguration.windowHeight * 0.75), true, true)
                    .validate(this.item.productConfiguration.fillingMullionPosition);
        } else {
            validationErrors['productConfiguration.fillingMullionPosition'] = undefined;
        }

        return this.processValidationResults(validationErrors);
    }

    private validateWindowSystemModelForm(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['windowSystemModelId'] = MultiValidator.of('error.webshopRecommendedConfigurationDto.windowSystemModelId')
            .withNotNullValidator().validate(this.item.windowSystemModelId);

        return this.processValidationResults(validationErrors);
    }

    private validateGlazingForm(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['productConfiguration.glazingGlassQuantity'] =
            MultiValidator.of('error.webshopRecommendedConfigurationDto.productConfiguration.glazingGlassQuantity')
                .withNotNullValidator().validate(this.item.productConfiguration.glazingGlassQuantity);

        validationErrors['productConfiguration.glazingPackageId'] =
            MultiValidator.of('error.webshopRecommendedConfigurationDto.productConfiguration.glazingPackageId')
                .withNotNullValidator().validate(this.item.productConfiguration.glazingPackageId);

        return this.processValidationResults(validationErrors);
    }

    showSuccessMessage() {
        if (this.newItem) {
            this.growlMessageController.info('WEBSHOP_RECOMMENDED_CONFIGURATION.CREATED');
        } else {
            this.growlMessageController.info('WEBSHOP_RECOMMENDED_CONFIGURATION.UPDATED');
        }
    }

    cancel() {
        this.newItem = false;
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedItem);
    }

    setDisplayDialog(display: boolean): void {
        this.validationErrors = {};
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    onRowSelect(event) {
        this.webshopRecommendedConfigurationService.getItem(event.data.id).subscribe(item => {
            this.item = item;
            this.setDisplayDialog(true);
        });
        this.keepSelectedItemIndex(event);
    }

    showDialogToAdd(): void {
        this.validationErrors = {};
        this.item = new WebshopRecommendedConfiguration();
        this.item.productConfiguration.windowSystemMaterial = MaterialType.PCV; // same hardcode as in webshop
        this.newItem = true;
    }
}
