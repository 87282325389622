import {GrillInterface} from '../../../../window-designer/catalog-data/grill-interface';
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";
import {GrillTypes} from "../../../../window-designer/enums/GrillTypes";

export class Grill implements CrudItem, GrillInterface {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    type: GrillTypes;
    width: number;
    minFrameWidth: number;
    maxAllowedSegmentLength: number;
    angled: boolean;

    pcv: boolean;
    aluminium: boolean;
    wood: boolean;
    aluminiumWoodMix: boolean;

    active: boolean;
    colorIds: any;
    sortIndex: number;
    canNotBeCombinedWithOtherGrills: boolean;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.active = true;
        this.angled = true;
        this.type = GrillTypes.INNER;
        this.colorIds = [];
        this.shortcut = new MultilanguageField();
        this.sortIndex = 1;
    }
}
