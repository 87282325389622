export enum ProductTypeGroup {
    DEFAULT = 'DEFAULT',
    ROOF = 'ROOF',
    TERRACE = 'TERRACE',
    ENTRANCE = 'ENTRANCE',
    GATE = 'GATE',
    CONFIG_ADDON = 'CONFIG_ADDON',
    BULK_ADDON = 'BULK_ADDON',
    SUBSYSTEM_BULK_ADDON = 'SUBSYSTEM_BULK_ADDON',
    ASSEMBLY = 'ASSEMBLY',
    TRANSPORT = 'TRANSPORT',
}
