import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {OnceFlag} from "../../../../../shared/once-flag";
import * as _ from 'underscore';
import {BlockUiController} from "../../../../../block-ui/block-ui-controller";
import {SelectDialogTile} from "./select-dialog-tile";

@Component({
    selector: 'app-tile-select-dialog',
    templateUrl: './tile-select-dialog.component.html',
    styleUrls: ['./tile-select-dialog.component.css'],
    providers: [DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileSelectDialogComponent implements OnInit, OnChanges {

    public static readonly LOADING_DATA = 'TileSelectDialogComponent loading data';
    public static readonly DUMMY_SELECT_VALUE = 1; // for dialog without select
    private readonly dialogHideHelper = new OnceFlag();

    @Input() dialogHeader: string;
    @Input() selectLabel: string;

    @Input() selectValue: number = TileSelectDialogComponent.DUMMY_SELECT_VALUE;
    @Input() sortedSelectItems: SelectItem[] = [{label: 'dummy', value: TileSelectDialogComponent.DUMMY_SELECT_VALUE, available: true}];
    @Input() sortedTileItems: SelectItem[] = [];
    @Input() tileSelectLinks: SelectTileLinkable[];
    @Input() tileValue: number;
    @Input() dataLoaded: boolean;
    @Input() skipSelect = true;

    @Output() onClose = new EventEmitter<void>();
    @Output() onSave = new EventEmitter<{selectValue: number, tileValue: number}>();
    @Output() openLargeImageInNewTab = new EventEmitter<{selectValue: number, tileValue: number, hex?: string}>();

    visible = true;
    validationErrors = {};
    selectedSelectValue: number;
    selectedTileValue: number;

    availableTiles: SelectDialogTile[];

    constructor(private changeDetector: ChangeDetectorRef,
                private blockUiController: BlockUiController) {}

    ngOnInit() {
        this.init();
        if (!this.dataLoaded) {
            this.blockUiController.block(TileSelectDialogComponent.LOADING_DATA);
        }
    }

    private init() {
        if (this.selectValue != null) {
            this.onSelectChange(this.selectValue);
            this.onTileSelect(this.tileValue);
        } else if (this.sortedSelectItems.length > 0) {
            this.onSelectChange(this.sortedSelectItems[0].value);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('dataLoaded' in changes) {
            const dataLoaded = changes['dataLoaded'];
            if (dataLoaded.currentValue) {
                this.blockUiController.unblock(TileSelectDialogComponent.LOADING_DATA);
            }
        }
        if ('tileSelectLinks' in changes) {
            this.init();
        }
    }

    closeDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    submit(): void {
        this.dialogHideHelper.call(() => this.onSave.emit({selectValue: this.selectedSelectValue, tileValue: this.selectedTileValue}));
    }

    onSelectChange(selectValue: number): void {
        this.selectedSelectValue = selectValue;
        let selectedSelectValue = this.sortedSelectItems.find(selectItem => selectItem.value === selectValue);

        let tileSelectLinks: {[tileValue_selectValue: string]: SelectTileLink} = this.tileSelectLinks.map(link => link.toSelectTileLink())
            .reduce((aggregate, next) => {
                let key = this.getKey(next.tileValue, next.selectValue);
                aggregate[key] = next;
                return aggregate;
            }, {});
        this.availableTiles = _.chain(this.sortedTileItems)
            .filter(tileItem => tileItem.available || (selectValue === this.selectValue)) // show disabled color only for material saved on position
            .filter(tileItem => (selectedSelectValue && selectedSelectValue.available) || (tileItem.value === this.tileValue)) // if material is no longer available show only color saved on position
            .map(tileItem => tileSelectLinks[this.getKey(tileItem.value, selectValue)])
            .filter(link => link != null)
            .filter(link => link.selectValue === selectValue)
            .map(link => {
                let selectedTile = this.sortedTileItems.find(color => color.value === link.tileValue);
                let tileName = selectedTile.label;
                return {
                    tileValue: link.tileValue,
                    tileName: tileName,
                    image: link.image,
                    hex: link.hex || '#ffffff',
                    available: selectedTile.available
                };
            }).value();

        if (this.availableTiles.length > 0) {
            this.onTileSelect(this.availableTiles[0].tileValue);
        }
        this.changeDetector.markForCheck();
    }

    private getKey(tileValue: number, selectValue: number): string {
        return `${tileValue}_${selectValue}`;
    }

    onTileSelect(tileValue: number) {
        this.selectedTileValue = tileValue;
    }

    openFullImage(tileValue: number, hex?: string) {
        this.openLargeImageInNewTab.emit({tileValue, selectValue: this.selectedSelectValue, hex});
    }
}

export class SelectTileLink implements SelectTileLinkable {
    selectValue: number;
    tileValue: number;
    image: string;
    hex: string;

    toSelectTileLink() {
        return this;
    }
}

export interface SelectTileLinkable {
    toSelectTileLink(): SelectTileLink;
}
