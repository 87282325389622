import {GlazingPackageInterface} from '../catalog-data/glazing-package-interface';
import {FillingType} from '../drawing-data/FillingType';
import {WindowAddon} from '../drawing-data/WindowAddon';

export class WindowSystemDefaults {

    // general tab ids
    colorIdCore: number;
    colorIdExternal: number;
    colorIdInternal: number;
    doorstepId: number;
    frameProfileId: number;
    channelSectionId: number;
    constructionalMullionId: number;
    movablePostId: number;
    sealColorExternalId: number;
    sealColorInternalId: number;

    // general tab addons turned to ids
    coverId: number;
    frameEnhancementId: number;
    fittingBrakeId: number;
    fittingSlidingId: number;
    fittingTypeId: number;
    fittingEspagnoletteTypeId: number;
    fittingVerandaId: number;
    fittingInsertionId: number;
    fittingMainInsertionId: number;
    fittingAdditionalInsertionId: number;
    fittingLockId: number;
    fittingLockTerraceId: number;
    fittingLockTerraceLocation: string;
    fittingAutomaticDriveId: number;
    handleTypeId: number;
    millingId: number;
    millingNorwegianId: number;
    underWindowBeadId: number;
    underwindowProfileId: number;
    weldTypeId: number;
    terraceHandleId: number;
    terraceHandleLayout: string;

    // filling
    glazingBeadId: number;
    fillingType: FillingType;
    fillingWidth: number;
    glazingGlassQn: number;
    glass1id: number;
    glass2id: number;
    glass3id: number;
    glass4id: number;
    glazingPackage: GlazingPackageInterface;
    externalColorId: number;
    internalColorId: number;
    coreColorId: number;
    decorativeFillingId: number;
    fillingId: number;
    frame1id: number;
    frame2id: number;
    frame3id: number;
    webshopGlazingPackageId: number;
    terraceGlazingPackageId: number;
    glazingPackageId: number;
    glazingCategoryId: number;
    glazingFrameCategoryId: number;

    opening: string;
    view: string;
    windowFunction: string;

    addons: WindowAddon[];
}
