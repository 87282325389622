import {MultilanguageField} from '../../../supportedLanguages';
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';

export enum DataModificationTarget {
    WINDOW_UPSELLING = 'WINDOW_UPSELLING',
    WEBSHOP_CHARGE = 'WEBSHOP_CHARGE'
}

export function isDataModificationMode(mode: any): boolean {
    return Object.values(DataModificationTarget).includes(mode);
}

export function isUpsellingMode(mode: any): boolean {
    return DataModificationTarget.WINDOW_UPSELLING === mode;
}

export class WebshopCharge {
    id: number;
    name: MultilanguageField;
    sortIndex: number;
    active: boolean;
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    info: MultilanguageField;
    value: WindowSystemDefaults;
    target: DataModificationTarget;
    image: string;

    constructor(target: DataModificationTarget) {
        this.name = new MultilanguageField();
        this.windowSystemName = new MultilanguageField();
        this.info = new MultilanguageField();
        this.sortIndex = 1;
        this.target = target;
    }
}
