<div class="new-form-600">

    <!-- TYPE -->
    <div class="new-form-row">
        <app-input-text *ngIf="fieldUsage.show(BusinessTypeField.WINDOW_TYPE_CODE)"
                        inputId="type" label="{{ 'BUSINESSTYPE.FORM.WINDOWTYPECODE' | translate }}"
                        [disabled]="fieldUsage.disabled(BusinessTypeField.WINDOW_TYPE_CODE)"
                        [ngModel]="nameParser(item.type)">
        </app-input-text>
    </div>

    <!-- NAME -->
    <app-translation-fields *ngIf="fieldUsage.show(BusinessTypeField.NAME)"
                            inputId="name" label="{{ 'BUSINESSTYPE.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(BusinessTypeField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.names"></app-translation-fields>

    <!-- DESCRIPTION -->
    <app-translation-fields *ngIf="fieldUsage.show(BusinessTypeField.DESCRIPTION)"
                            inputId="name" label="{{ 'BUSINESSTYPE.FORM.DESCRIPTION' | translate }}"
                            validationKeyPrefix="descriptions" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(BusinessTypeField.DESCRIPTION)"
                            [maxlength]="2000" [required]="true"
                            [field]="item.descriptions"></app-translation-fields>

    <!-- ACTIVE -->
    <div *ngIf="fieldUsage.show(BusinessTypeField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'BUSINESSTYPE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(BusinessTypeField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <!-- SORT INDEX -->
    <div *ngIf="fieldUsage.show(BusinessTypeField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'BUSINESSTYPE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(BusinessTypeField.MIN_WIDTH_IN_WEBSHOP)">
        <app-input-number inputId="minWidthInWebshop" label="{{ 'BUSINESSTYPE.FORM.MIN_WIDTH_IN_WEBSHOP' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.MIN_WIDTH_IN_WEBSHOP)"
                          [(validationMessageKey)]="validationErrors['minWidthInWebshop']" [min]="1" [step]="1"
                          [allowOnlyIntegers]="true" [(ngModel)]="item.minWidthInWebshop"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(BusinessTypeField.MAX_WIDTH_IN_WEBSHOP)">
        <app-input-number inputId="maxWidthInWebshop" label="{{ 'BUSINESSTYPE.FORM.MAX_WIDTH_IN_WEBSHOP' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.MAX_WIDTH_IN_WEBSHOP)"
                          [(validationMessageKey)]="validationErrors['maxWidthInWebshop']" [min]="1" [step]="1"
                          [allowOnlyIntegers]="true" [(ngModel)]="item.maxWidthInWebshop"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(BusinessTypeField.MIN_HEIGHT_IN_WEBSHOP)">
        <app-input-number inputId="minHeightInWebshop" label="{{ 'BUSINESSTYPE.FORM.MIN_HEIGHT_IN_WEBSHOP' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.MIN_HEIGHT_IN_WEBSHOP)"
                          [(validationMessageKey)]="validationErrors['minHeightInWebshop']" [min]="1" [step]="1"
                          [allowOnlyIntegers]="true" [(ngModel)]="item.minHeightInWebshop"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(BusinessTypeField.MAX_HEIGHT_IN_WEBSHOP)">
        <app-input-number inputId="maxHeightInWebshop" label="{{ 'BUSINESSTYPE.FORM.MAX_HEIGHT_IN_WEBSHOP' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.MAX_HEIGHT_IN_WEBSHOP)"
                          [(validationMessageKey)]="validationErrors['maxHeightInWebshop']" [min]="1" [step]="1"
                          [allowOnlyIntegers]="true" [(ngModel)]="item.maxHeightInWebshop"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(BusinessTypeField.NUMBER_OF_RAILS)">
        <app-input-number inputId="numberOfRails" label="{{ 'BUSINESSTYPE.FORM.NUMBER_OF_RAILS' | translate }}"
                          [disabled]="fieldUsage.disabled(BusinessTypeField.NUMBER_OF_RAILS)"
                          [(validationMessageKey)]="validationErrors['numberOfRails']" [min]="0" [max]="9" [step]="1"
                          [allowOnlyIntegers]="true" [(ngModel)]="item.numberOfRails"></app-input-number>
    </div>
</div>
