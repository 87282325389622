import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {forkJoin, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {GlazingPackagesValidationErrors} from '../../../common/glazing-packages/glazing-packages-form.component';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {DistanceFrameService} from '../distance-frame/distance-frame.service';
import {DistanceFrameListing} from '../distance-frame/distanceFrame-list';
import {GlassService} from '../glass/glass.service';
import {GlassWithPosition} from '../glass/glassWithPositions';
import {GlazingPackageTarget} from '../glazing-package/glazing-package';
import {WebshopGlazingPackage} from '../glazing-package/webshop-glazing-package/webshop-glazing-package';
import {GraspGlazingPackage} from "../grasp-glazing-package/grasp-glazing-package";
import {GraspGlazingPackageService} from "../grasp-glazing-package/grasp-glazing-package.service";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {WebshopGlazingPackagePerSubsystem} from './webshop-glazing-package-per-subsystem';
import {WebshopGlazingPackagePerSubsystemService} from './webshop-glazing-package-per-subsystem.service';

@Component({
    selector: 'app-webshop-glazing-package-per-subsystem',
    templateUrl: './webshop-glazing-package-per-subsystem.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [DataServiceHelper, WebshopGlazingPackagePerSubsystemService, GlassService, DistanceFrameService,
        GraspGlazingPackageService, WindowSystemDefinitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopGlazingPackagePerSubsystemComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        GLAZING: 'GLAZING'
    };

    readonly VALIDATORS = {
        DATA: () => of(true),
        GLAZING: () => of(true)
    };

    webshopGlazingPackagesPerSubsystem: WebshopGlazingPackagePerSubsystem[];
    selectedWebshopGlazingPackagePerSubsystem: WebshopGlazingPackagePerSubsystem;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayDialog = false;
    userLang: string;
    glazingValidationErrors = new GlazingPackagesValidationErrors();
    glasses: GlassWithPosition[];
    frames: DistanceFrameListing[];
    graspGlazingPackages: GraspGlazingPackage[];
    private windowSystems: ItemForCatalogLinking[];
    glazingPackageTargets = GlazingPackageTarget;

    @ViewChild('dt', {static: true}) datatable;

    constructor(private webshopGlazingPackagePerSubsystemService: WebshopGlazingPackagePerSubsystemService,
                private errorHandler: CommonErrorHandler,
                private translate: TranslateService,
                private glassService: GlassService,
                private frameService: DistanceFrameService,
                private readonly graspGlazingPackageService: GraspGlazingPackageService,
                private readonly windowSystemDefinitionService: WindowSystemDefinitionService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopGlazingPackagePerSubsystemComponent', false);
        this.selectedWebshopGlazingPackagePerSubsystem = new WebshopGlazingPackagePerSubsystem();
        this.userLang = translate.currentLang;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initShownColumns();
        forkJoin({
            glasses: this.glassService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            frames: this.frameService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            graspGlazingPackages: this.graspGlazingPackageService.getItems(undefined, undefined, {active: {value: 'true'}}, 'sortIndex', 0),
            windowSystems: this.windowSystemDefinitionService.getSystemsForCatalogLinking()
        }).subscribe(data => {
            this.glasses = data.glasses.data;
            this.frames = data.frames.data;
            this.graspGlazingPackages = data.graspGlazingPackages.data;
            this.windowSystems = data.windowSystems;
        });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event: any): void {
        this.selectedWebshopGlazingPackagePerSubsystem = this.cloneWebshopGlazingPackagePerSubsystem(event.data);
        this.setDisplayDialog(true);
        this.keepSelectedItemIndex(event);
    }

    cloneWebshopGlazingPackagePerSubsystem(webshopGlazingPackagePerSubsystem: WebshopGlazingPackagePerSubsystem): WebshopGlazingPackagePerSubsystem {
        let newWebshopGlazingPackagePerSubsystem = <WebshopGlazingPackagePerSubsystem>_.clone(webshopGlazingPackagePerSubsystem);
        newWebshopGlazingPackagePerSubsystem.webshopGlazingPackage = WebshopGlazingPackage.copy(webshopGlazingPackagePerSubsystem.webshopGlazingPackage);

        return newWebshopGlazingPackagePerSubsystem;
    }

    setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.webshopGlazingPackagePerSubsystemService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WebshopGlazingPackagePerSubsystemComponent `getPage` success:', data);
                    this.webshopGlazingPackagesPerSubsystem = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedWebshopGlazingPackagePerSubsystem =
                        this.restoreSelectionAfterLoad(this.selectedWebshopGlazingPackagePerSubsystem, this.webshopGlazingPackagesPerSubsystem, event);
                },
                error: error => {
                    console.error('WebshopGlazingPackagePerSubsystemComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WebshopGlazingPackagePerSubsystemComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    submit(): void {
        if (this.isSaveInProgress()) {
            return;
        }

        if (!this.validationErrorsPresent()) {
            this.setSaveInProgress(true);
            this.webshopGlazingPackagePerSubsystemService.editItem(this.selectedWebshopGlazingPackagePerSubsystem).pipe(
                finalize(() => this.setSaveInProgress(false))
            ).subscribe({
                next: () => {
                    this.reloadDatatable();
                    this.showSuccessMessage();
                    this.cancel();
                },
                error: error => {
                    this.validationErrors = this.errorHandler.handle(error);
                    this.changeDetector.markForCheck();
                    console.error('WebshopGlazingPackagePerSubsystemComponent `getPage` error:', error);
                }
            });
        }
    }

    showSuccessMessage() {
        this.growlMessageController.info('WEBSHOP_GLAZING_PACKAGE_PER_SUBSYSTEM.UPDATED');
    }

    cancel() {
        this.setDisplayDialog(false);
    }

    showDialogToAdd(): void {
    }

    hasPredefinedGlazingPackage(item: WebshopGlazingPackage): boolean {
        return this.windowSystems
            .some(ws => WindowSystemType.getByName(ws.systemType).predefinedGlazing && item.linkedSystemIds.includes(ws.id));
    }
}
