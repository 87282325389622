import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {GlazingPackage} from "../glazing-package/glazing-package";
import {Glass} from './glass';
import {GlassWithPosition} from './glassWithPositions';

@Injectable()
export class GlassService implements CrudService<GlassWithPosition> {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GlassWithPosition>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GlassWithPosition>>('glasses', {params: params});
    }

    getItem(itemId: number): Observable<GlassWithPosition> {
        return this.http.get<GlassWithPosition>(`glass/${itemId}`);
    }

    addItem(item: GlassWithPosition): Observable<number> {
        // handled by saveGlass
        return this.saveGlass(item, undefined, undefined);
    }

    editItem(itemId: number, item: GlassWithPosition): Observable<number> {
        // handled by saveGlass
        return this.saveGlass(item, undefined, undefined);
    }

    saveGlass(glass: Glass, file: File, glamourPrintIconFile: File): Observable<number> {
        let body = JSON.stringify(glass);

        let formData = new FormData();
        formData.append('glassDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file != undefined) {
            formData.append('file', file);
        }
        if (glamourPrintIconFile != undefined) {
            formData.append('glamourPrintIconFile', glamourPrintIconFile);
        }
        return this.http.post('glass', formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('glass', response)));
    }

    getImage(glassId: number): Observable<string> {
        return this.http.get(`glass/${glassId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(glassId: number): Observable<File> {
        return this.http.get(`glass/${glassId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getGlamourPrintIconAsFile(glassId: number): Observable<File> {
        return this.http.get(`glass/${glassId}/glamourPrintIcon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getThicknesses(): Observable<number[]> {
        return this.http.get<number[]>('glass/thicknesses');
    }

    removeLink(glassId: number, glassPosition: number, glassCount: number): Observable<void> {
        return this.http.delete<void>('glassInGlazingType', {
            params: {
                glassId: '' + glassId,
                position: '' + glassPosition,
                glassCount: '' + glassCount
            }
        });
    }

    addLink(glassInGlazingType): Observable<void> {
        return this.http.post<void>('glassInGlazingType', glassInGlazingType);
    }

    toggleLinks(glassId: number, isAllSelected: boolean): Observable<void> {
        if (isAllSelected) {
            return this.http.post<void>(`glassesInGlazingType/${glassId}`, undefined);
        } else {
            return this.http.delete<void>(`glassesInGlazingType/${glassId}`);
        }
    }

    copy(id: number, glass: Glass, file: File, glamourPrintIconFile: File): Observable<number> {
        let body = JSON.stringify(glass);

        let formData = new FormData();
        formData.append('glassDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file != undefined) {
            formData.append('file', file);
        }
        if (glamourPrintIconFile != undefined) {
            formData.append('glamourPrintIconFile', glamourPrintIconFile);
        }
        return this.http.post(`glass/${id}/copy`, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('glass', response)));
    }

    setGlassWindowSystemLinks(glassId: number, linkedSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`glass/link/${glassId}`, linkedSystemIds);
    }

    getGlassesForWindowSystem(windowSystemId: number, selectedGlassIds: number[] = null, readOnly = false): Observable<GlassWithPosition[]> {
        const params = selectedGlassIds ? {selectedGlassIds: selectedGlassIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<GlassWithPosition[]>(`glass/forWindowSystem/${windowSystemId}`, {params: params});
    }

    validate(glass: Glass): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('glass/validate', glass));
    }

    getGlasses(ids: number[]): Observable<Glass[]> {
        return this.http.get<Glass[]>('glass/bulk', {params: {glassIds: ids.map(id => '' + id)}});
    }

    getActiveTerraceGlazings(): Observable<GlazingPackage[]> {
        return this.http.get<GlazingPackage[]>('glazingPackages/glazingPackagesForTerraceWindowSystems');
    }

    getGlassIdsUsedInWindowSystemDefaultGlazingPackage(): Observable<{ id: number, windowSystemIds: number[] }[]> {
        return this.http.get<{ id: number, windowSystemIds: number[] }[]>('glass/listUsedInDefaultGlazingPackages');
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('glasses/names', {params: params});
    }
}
