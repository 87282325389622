import {Pipe, PipeTransform} from '@angular/core';
import {News} from "./news";
import * as moment from "moment";

@Pipe({
    name: 'newsColourClass'
})
export class NewsColourClassPipe implements PipeTransform {

    transform(news: News): string {
        const today = moment();
        if (!news.published) {
            return 'draft';
        } else if (news.published && moment(news.validFrom).isBefore(today) && moment(news.validTo).isAfter(today)) {
            return 'published-active';
        } else if (news.published && moment(news.validFrom).isAfter(today)) {
            return 'published-planned';
        }
        return '';
    }

}
