<p-dialog [resizable]="false" [visible]="dataInitialized" [modal]="true" (onHide)="close()"
          [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.SHOW_SUMMARY.TITLE' | translate }}
        </div>
    </p-header>

    <div class="scrolled-dialog scrolled-dialog-900">
        <p-dataTable [value]="summary" [seqNum]="99">
            <p-column field="label">
                <ng-template pTemplate="body" let-row="rowData">
                    {{ row.label | translate }}
                </ng-template>
            </p-column>
            <p-column field="value">
                <ng-template pTemplate="body" let-row="rowData">
                    <div>{{formatPriceInSelectedCurrency(row.value)}}</div>
                    <div class="secondary-price" *ngIf="selectedCurrency != offer.currency">
                        {{formatPriceInOfferCurrency(row.value)}}
                    </div>
                </ng-template>
            </p-column>
        </p-dataTable>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
