import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SupportedLanguages} from '../../../../supportedLanguages';
import {AddonCategoryGroup} from "../addon-category-group";
import {COMMON_INPUT_LENGTH_LIMITS} from "../../../../common/crud-common/common-input-length-limits";
import {SelectItem} from "primeng/api/selectitem";
import {SelectItemImpl} from "../../../../common/service/select.item.impl";
import {TranslateService} from "@ngx-translate/core";
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {AddonCategoryGroupFieldUsage} from "../../catalog-field-usage";
import {AddonCategoryGroupField} from "../../../admin-panel/edit-catalog-permits/catalog-field.enum";

@Component({
    selector: 'app-addon-category-group-form',
    templateUrl: './addon-category-group-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonCategoryGroupFormComponent {

    supportedLanguages = SupportedLanguages.languages;
    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @Input() item: AddonCategoryGroup;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[];

    fieldUsage: AddonCategoryGroupFieldUsage;
    AddonCategoryGroupField = AddonCategoryGroupField;

    constructor(public translate: TranslateService) {
        this.fieldUsage = new AddonCategoryGroupFieldUsage(this);
    }

    getCategories(): SelectItem[] {
        return this.item.categories.map(category => new SelectItemImpl(category.name[this.translate.currentLang], category.id));
    }
}
