import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {of} from 'rxjs';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {Mullion} from "../../../../window-designer/drawing-data/Mullion";
import {SubWindowData} from "../../../../window-designer/drawing-data/SubWindowData";
import {Veneer} from "../../../../window-designer/drawing-data/Veneer";
import {ProfilesCompositionDistances} from '../../../../window-designer/profiles-composition-distances';
import {MullionUtils} from "../../../../window-designer/utils/MullionUtils";
import {Profile} from "../../window-system/profile/profile";
import {ProfileService} from '../../window-system/profile/profile.service';
import {WindowEditorWindowSystemInterface} from '../window-editor/window-editor-window-system-interface';
import {VeneerEvent} from "./VeneerEvent";

@Component({
    selector: 'app-veneer',
    templateUrl: './veneer.component.html',
    providers: [ProfileService]
})
export class VeneerComponent implements OnChanges {

    static readonly VENEER_HEIGHT_INPUT_ID = 'veneer-height';

    @Input() systemId: number;
    @Input() supplierId: number;
    @Input() mullion: Mullion;
    @Input() subwindow: SubWindowData;
    @Input() windowSystems: WindowEditorWindowSystemInterface[];
    @Input() profileCompositionDistances: ProfilesCompositionDistances;
    @Input() readOnlyMode: boolean;

    @Output() veneerEvent = new EventEmitter<VeneerEvent>();
    @Output() onFocus = new EventEmitter<FocusEvent>();

    allActiveFrameProfiles: Profile[];
    windowSystemsOptions: SelectItem[];
    framesOptions: SelectItem[] = [];
    windowTypeOptions: SelectItem[];

    tempVeneer: Veneer = new Veneer();
    distanceOutOfBounds = false;

    constructor(private translate: TranslateService, private profileService: ProfileService) {
        this.prepareWindowTypes();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('windowSystems' in changes || 'supplierId' in changes) {
            this.windowSystemsOptions = this.getLabels(this.windowSystems, () => true);
        }
        if ('systemId' in changes) {
            let v = this.mullion.veneer;
            (v.systemId != undefined ? this.profileService.getActiveFramesForWindowSystem(v.systemId) :
                of<Profile[]>([]))
                .subscribe(frames => {
                    this.rebuildFramesDropDown(frames);
                    this.tempVeneer = new Veneer(v.systemId, v.frameId, v.isWinged, v.distance, v.valid);
                    this.generateVeneerEvent();
                });
        }
    }

    private rebuildFramesDropDown(frames: Profile[]): void {
        this.allActiveFrameProfiles = frames;
        let frame = this.allActiveFrameProfiles.find(f => f.id === this.tempVeneer.frameId);
        this.tempVeneer.frameId = frame != null ? frame.id : undefined;
        this.framesOptions = this.getLabels(frames, () => true);
    }

    private getLabels<T extends { id: number, names: MultilanguageFieldInterface }>(objects: T[],
                                                                                    filter: (obj: T) => boolean): SelectItem[] {
        if (objects != undefined) {
            return objects.filter(filter).map(object => {
                return {
                    label: object.names[this.translate.currentLang],
                    value: object.id
                };
            });
        } else {
            return [];
        }
    }

    handleWindowSystemChange(windowSystemId: number): void {
        this.tempVeneer.systemId = windowSystemId;
        if (windowSystemId != undefined) {
            this.profileService.getActiveFramesForWindowSystem(windowSystemId).subscribe(frames => {
                this.rebuildFramesDropDown(frames);
                this.afterChange();
            });
        }
    }

    afterChange(): void {
        const event = this.generateVeneerEvent();
        if (event != undefined) {
            this.veneerEvent.emit(event);
        }
    }

    generateVeneerEvent(): VeneerEvent {
        let v = this.tempVeneer;
        if (v.active) {
            if (v.systemId && v.frameId && v.distance && (v.isWinged !== undefined)) {
                let veneerDistance = this.calculateVeneerDistance();
                if (veneerDistance) {
                    this.tempVeneer.valid = true;
                    return new VeneerEvent(this.mullion, true, veneerDistance, this.tempVeneer, this.subwindow);
                }
            }
            this.tempVeneer.valid = false;
            return undefined;
        } else {
            this.tempVeneer.valid = false;
            return new VeneerEvent(this.mullion, false, null, this.tempVeneer, this.subwindow);
        }
    }

    private prepareWindowTypes(): void {
        let fix = {value: false, label: 'OFFER.DRAWING.VENEER.WINDOW_TYPE.FIX'};
        let winged = {value: true, label: 'OFFER.DRAWING.VENEER.WINDOW_TYPE.OTHER'};
        this.windowTypeOptions = [fix, winged];
    }

    private calculateVeneerDistance(): number {
        let nFrame = this.allActiveFrameProfiles.find(f => f.id === this.tempVeneer.frameId);
        let nSystem = this.windowSystems.find(s => s.id === this.tempVeneer.systemId);
        let neighboringWindowOffset = this.tempVeneer.isWinged ?
            (nFrame.compositionDistances.defaultWidth + nSystem.wingWidth.defaultWidth - nSystem.wingImpositionWidth.defaultWidth) :
            nFrame.compositionDistances.defaultWidth;
        let mullionWidth = MullionUtils.getExternalWidth(this.mullion.width, this.profileCompositionDistances);
        let distance = this.tempVeneer.distance - neighboringWindowOffset + (mullionWidth / 2);
        return this.validateDistance(distance);
    }

    private validateDistance(distance: number): number {
        let height = this.subwindow.points[5] - this.subwindow.points[1];
        let valid = (1 < distance && distance < height);
        this.distanceOutOfBounds = !valid;
        return valid ? distance : null;
    }

    isMullionEligible(mullion: Mullion): boolean {
        return MullionUtils.isMullionEligibleForVeneering(mullion);
    }
}
