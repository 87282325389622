import {HttpErrorResponse} from '@angular/common/http';
import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin} from "rxjs";
import {Permissions} from "../../../auth/permission.service";
import {StorageService} from "../../../auth/storage.service";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ResponseError} from "../../../common/error.handler";
import {FocusOnElement} from "../../../common/FocusOnElement";
import {ValidationService} from "../../../common/service/validation.service";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {SidenavController} from "../../../sidenav-controller";
import {RoleService} from '../../admin-panel/role/role.service';
import {ErrorResponse} from "../../errors/errorResponse";
import {SubsystemService} from '../../subsystem/subsystem.service';
import {User} from "../user";
import {UserDetailsValidator} from "../user-details-validator";
import {UserService} from "../user.service";

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    providers: [UserService, RoleService, DataServiceHelper, ValidationService, StorageService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateUserComponent implements OnInit, OnDestroy, AfterContentInit {

    readonly STEPS = {
        FILL_DETAILS: 'fill-details'
    };

    user: User = new User();
    file: File;
    validationErrors = {};
    existingPrimaryRoles: SelectItem[] = [];
    existingOtherRoles: SelectItem[] = [];
    existingSubsystems: SelectItem[] = [];

    validateDetailsStep: WizardStepValidator;
    userDetailsValidator: UserDetailsValidator;

    private saveInProgress: boolean;

    constructor(public translate: TranslateService,
                private sidenavController: SidenavController,
                private validationService: ValidationService,
                private router: Router,
                public userService: UserService,
                public roleService: RoleService,
                public changeDetector: ChangeDetectorRef,
                public storage: StorageService,
                public permissions: Permissions,
                private subsystemService: SubsystemService) {
        this.userDetailsValidator = new UserDetailsValidator(validationService);
        this.validateDetailsStep = () => {
            this.validationErrors = {};
            return this.userDetailsValidator.validateForm(this.user, this.file, this.validationErrors);
        };
    }

    ngOnInit() {
        this.getRolesAndSubsystems();
        this.sidenavController.hide();
    }

    ngOnDestroy() {
        this.sidenavController.show();
    }

    ngAfterContentInit() {
        FocusOnElement.tryToFocus('login', 0, 3, 100);
    }

    submit() {
        if (!this.isSaveInProgress()) {
            this.setSaveInProgress(true);
            this.userService.saveUser(this.user, this.file).subscribe({
                complete: () => {
                    this.setSaveInProgress(false);
                    this.exitWizard();
                },
                error: (err: HttpErrorResponse) => {
                    this.setSaveInProgress(false);
                    let errorResponse = new ErrorResponse(err.error);
                    if (errorResponse.is400()) {
                        this.validationErrors = {};
                        for (let property in errorResponse.invalidFields) {
                            this.validationErrors[property] = errorResponse.invalidFields[property];
                        }
                        this.changeDetector.markForCheck();
                    } else {
                        throw new ResponseError(err);
                    }
                }
            });
        }
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    exitWizard() {
        this.router.navigate(['features/user']);
    }

    getRolesAndSubsystems(): void {
        forkJoin({
            primaryRoles: this.roleService.getItems(undefined, undefined, {
                primary: {value: 'true'},
                includeSystemRoles: {value: 'false'}
            }, undefined, undefined),
            otherRoles: this.roleService.getItems(undefined, undefined, {
                primary: {value: 'false'}
            }, undefined, undefined),
            subsystems: this.subsystemService.getSelectionItems()
        })
        .subscribe({
            next: data => {
                this.existingPrimaryRoles = data.primaryRoles.data.map(role => ({label: 'ROLE.' + role.roleName, value: role.roleName}));
                this.existingOtherRoles = data.otherRoles.data.map(role => ({label: role.roleName, value: role.id}));
                this.existingSubsystems = data.subsystems;
            },
            error: error => {
                throw new ResponseError(error);
            },
            complete: () => {
                console.debug('Getting roles, subsystems, user and signature completed!');
            }
        });
    }

    handleFileChange(file) {
        this.file = file;
    }
}
