import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {WindowData} from "../../../../../window-designer/drawing-data/WindowData";
import {SubwindowTypes} from "../../../../../window-designer/subwindow-types";
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {WindowTypeCodeParser} from "../../../../../window-designer/utils/WindowTypeCodeParser";
import {Tool} from '../../../../../window-designer/window-designer-interface';
import {WindowAttributes} from "../../../../../window-designer/window-types/window-attributes";
import {WindowTypeCode} from "../../../../../window-designer/window-types/window-type-code";
import {BusinessType} from "../../../window-system/business-type/BusinessType";
import {WindowEditorWindowSystemInterface} from '../window-editor-window-system-interface';
import {Dialog} from "primeng/dialog";
import {OnceFlag} from "../../../../shared/once-flag";
import {AddWindowDialogComponent} from "../../add-product-dialog/add-product-dialog-data-provider/add-window-dialog.component";

export interface AddWindowEvent {
    type: WindowTypeCode;
    windowCount: number;
}

@Component({
    selector: 'app-add-subwindow-dialog',
    templateUrl: './add-subwindow-dialog.component.html',
    styleUrls: ['./add-subwindow-dialog.component.css', '../../add-product-dialog/add-product-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSubwindowDialogComponent implements OnInit {

    @Input() businessTypeChangeSubwindowId: string;
    @Input() data: DrawingData;
    @Input() displayDialog: boolean;
    @Input() subwindowTypes: SubwindowTypes;
    @Input() windowBusinessTypes: BusinessType[];
    @Input() windowSystems: WindowEditorWindowSystemInterface[];
    @Input() mode: Tool;
    @Input() addVertically: boolean;

    @Output() businessTypeSelected = new EventEmitter<AddWindowEvent>();
    @Output() dialogClosed = new EventEmitter();

    businessTypesTrackBy: (index: number, item: BusinessType) => any;
    filteredWindowBusinessTypes: BusinessType[];
    businessTypeNames: string[] = [];
    businessTypeIcons: string[] = [];
    businessTypeDescriptions: string[] = [];
    filteredWindowSystems: SelectItem[];
    validationErrors = {};
    warnings: string[] = [];
    selectedBusinessType: BusinessType;
    windowCount = 1;

    @ViewChild('dialog', {static: true}) dialog: Dialog;
    readonly dialogHideHelper = new OnceFlag();

    constructor(public translate: TranslateService,
                public changeDetector: ChangeDetectorRef) {
        this.businessTypesTrackBy = (index: number, item: BusinessType) => item.type;
    }

    ngOnInit() {
        this.filterBusinessTypes();
        if (this.showMultiSubwindowBusinessTypeWarning()) {
            this.translate.get('OFFER.DRAWING.CHANGE_BUSINESS_TYPE_MULTIQUARTER_WARNING',
                {quarterType: this.getExistingSubwindowName(), businessType: this.getExistingWindowType()}
            ).subscribe(warning => {
                this.warnings = [warning];
            });
        }
    }

    filterBusinessTypes() {
        this.filteredWindowBusinessTypes = [];
        let existingWindowAttributes: WindowAttributes;
        if (this.windowBusinessTypes !== undefined) {
            if (this.existingBusinessTypeChangeMode()) {
                let existingWindow = this.findExistingWindow();
                let originalAttributes = WindowTypeCodeParser.parseTypeCode(existingWindow.typeCode);
                // account for cuts, so we only show matching types in the dialog
                originalAttributes.subwindows.length = existingWindow.subWindows.length;
                existingWindowAttributes = originalAttributes;
            }
            this.filteredWindowBusinessTypes =
                this.windowBusinessTypes.filter(businessType => this.validateBusinessType(businessType, existingWindowAttributes));
        }
        if (this.filteredWindowBusinessTypes.length > 0) {
            this.selectedBusinessType = this.filteredWindowBusinessTypes[0];
        }
        for (let bt of this.filteredWindowBusinessTypes) {
            this.businessTypeNames[bt.id] = bt.names[this.translate.currentLang];
            this.businessTypeIcons[bt.id] = this.getTypeIconUrl(bt);
            this.businessTypeDescriptions[bt.id] = bt.descriptions[this.translate.currentLang];
        }
    }

    existingBusinessTypeChangeMode(): boolean {
        return this.businessTypeChangeSubwindowId != undefined;
    }

    private findExistingWindow(): WindowData {
        return this.data.windows
            .find(window => window.subWindows
                .some(subwindow => subwindow.generatedId === this.businessTypeChangeSubwindowId));
    }

    private validateBusinessType(businessType: BusinessType, existingWindowAttributes: WindowAttributes): boolean {
        if (this.existingBusinessTypeChangeMode()) {
            if (businessType.type === existingWindowAttributes.typeCode) {
                // ignore self for type change
                return false;
            }
            let businessTypeAttributes = WindowTypeCodeParser.parseTypeCode(businessType.type);
            return this.attributesMatch(businessTypeAttributes, existingWindowAttributes);
        } else {
            return !(this.isAnotherWindowAlreadyAdded() && this.isEllipticalWindowBusinessType(businessType));
        }
    }

    private attributesMatch(attributesA: WindowAttributes, attributesB: WindowAttributes): boolean {
        return attributesA.eliptical === attributesB.eliptical
            && attributesA.vertical === attributesB.vertical
            && attributesA.subwindows.length === attributesB.subwindows.length;
    }

    private isAnotherWindowAlreadyAdded() {
        return !(this.data && this.data.windows && this.data.windows.length === 0);
    }

    private isEllipticalWindowBusinessType(businessType: BusinessType): boolean {
        return WindowTypeCodeParser.parseTypeCode(businessType.type).eliptical;
    }

    getTypeIconUrl(businessType: BusinessType): string {
        const windowSystem = this.getWindowSystem();
        return AddWindowDialogComponent.generateTypeIconUrl(windowSystem.doors, businessType.type);
    }

    showMultiSubwindowBusinessTypeWarning(): boolean {
        return this.existingBusinessTypeChangeMode() && (this.findExistingWindow().subWindows.length > 1);
    }

    getExistingSubwindowName(): string {
        let subwindows = [].concat.apply([], this.data.windows.map(window => window.subWindows));
        let subwindow = subwindows.find(sw => sw.generatedId === this.businessTypeChangeSubwindowId);
        if (subwindow == undefined) {
            console.error("Subwindow not found by generetedId " + this.businessTypeChangeSubwindowId);
            return "";
        }
        let type = this.subwindowTypes.get(subwindow.typeCode);
        return type.names[this.translate.currentLang];
    }

    getExistingWindowType(): string {
        let typeCode = this.findExistingWindow().typeCode;
        let businessType = this.windowBusinessTypes.find(bt => bt.type === typeCode);
        if (businessType == undefined) {
            console.error("Business type not found for code: " + WindowTypeCode[typeCode]);
            return "";
        }
        return businessType.names[this.translate.currentLang];
    }

    addWindowSystem(businessType?: BusinessType): void {
        if (businessType != null) {
            this.selectBusinessType(businessType);
        }
        if (!this.validateNewWindow()) {
            return;
        }
        let event = {
            type: this.selectedBusinessType.type,
            windowCount: this.windowCount
        };
        this.businessTypeSelected.emit(event);
    }

    selectBusinessType(businessType: BusinessType) {
        delete this.validationErrors['selection'];
        this.selectedBusinessType = businessType;
    }

    private validateNewWindow(): boolean {
        this.validationErrors = {};
        if (this.selectedBusinessType === undefined) {
            this.validationErrors['selection'] = ErrorNames.BUSINESSTYPE_MISSING_VALUE;
        }
        return Object.keys(this.validationErrors).length === 0;
    }

    getSubmitButtonLabel(): string {
        return this.existingBusinessTypeChangeMode() ? 'OFFER.TABS.SECTION.MAIN.BUSINESS_TYPE_CHANGE' :
            'OFFER.DRAWING.SYSTEM_ADD';
    }

    private getWindowSystem(): WindowEditorWindowSystemInterface {
        return this.windowSystems.find(ws => ws.id === this.data.windowSystemId);
    }

    getDialogHeader(): string {
        return this.existingBusinessTypeChangeMode() ? 'OFFER.DRAWING.CHANGE_BUSINESS_TYPE' : 'OFFER.DRAWING.SYSTEM_ADD_PRODUCT';
    }

    get canChangeWindowCount() {
        return this.mode === Tool.NEW_SUBWINDOW && this.getWindowSystem()
            && this.getWindowSystem().canCombineBusinessTypes
            && this.data.windows.length !== 0;
    }

    center() {
        this.dialog.center();
        this.changeDetector.markForCheck();
    }

    afterShow() {
        this.dialog.center();
        this.dialogHideHelper.reset();
    }

    handleWindowCountChange(windowCount: number): void {
        this.windowCount = windowCount;
    }
}
