import * as _ from 'underscore';
import {DropDownExtraOptions} from '../../../shared/drop-down-extra-options';
import {AddonInterface} from '../../catalog-data/addon-interface';
import {GlassInterface} from '../../catalog-data/glass-interface';
import {Glazing} from '../../catalog-data/glazing';
import {PositionListAddon} from '../../catalog-data/position-list-addon';
import {AreaSpecification} from '../../drawing-data/AreaSpecification';
import {CutData} from '../../drawing-data/CutData';
import {DrawingData} from '../../drawing-data/drawing-data';
import {FillingType} from '../../drawing-data/FillingType';
import {FittingTerraceLockLocation} from '../../drawing-data/FittingTerraceLockLocation';
import {SubWindowData} from '../../drawing-data/SubWindowData';
import {TerraceHandleLayout} from '../../drawing-data/TerraceHandleLayout';
import {WindowData} from '../../drawing-data/WindowData';
import {WindowDimensions} from "../../entities/window-dimensions";
import {AddonFor} from '../../enums/AddonFor';
import {QuantityType} from '../../enums/QuantityType';
import {PolygonGeometryCalculator} from '../../PolygonGeometryCalculator';
import {StaticDataHelper} from '../../static-data-helper';
import {SubwindowTypes} from "../../subwindow-types";
import {WindowCalculator} from '../../window-calculator';
import {SizeUnitsConverter} from "../SizeUnitsConverter";
import {AddonDefaultQuantityExpresionCalculator} from './AddonDefaultQuantityExpresionCalculator';
import {AddonQuantityDataSource} from './AddonQuantityDataSource';

export class AddonDefaultQuantityCalculator {

    private static THOUSAND_DIVISOR = 1000;

    static calculate(addon: AddonInterface, dataSource: AddonQuantityDataSource): number {
        let calculatedQuantity;
        if (AddonDefaultQuantityCalculator.isQuantityExpression(addon)) {
            calculatedQuantity =
                AddonDefaultQuantityExpresionCalculator.calculate(addon.defaultQuantity, dataSource, addon.quantityType);
        } else {
            calculatedQuantity = AddonDefaultQuantityCalculator.calculateQuantity(addon, dataSource);
        }

        return AddonDefaultQuantityCalculator.roundNumberToThirdDecimalPlace(calculatedQuantity);
    }

    private static isQuantityExpression(addon: AddonInterface): boolean {
        return Number.isNaN(Number(addon.defaultQuantity)) && AddonDefaultQuantityExpresionCalculator.isValid(addon.defaultQuantity);
    }

    private static calculateQuantity(addon: AddonInterface, dataSource: AddonQuantityDataSource): number {
        let count = dataSource.quantities[addon.addonFor];
        if (count == null) {
            throw new Error('Unsupported AddonFor: ' + addon.addonFor);
        }
        return addon.defaultQuantity * count;
    }

    public static prepareDataSource(drawingData: DrawingData, staticData: StaticDataHelper): AddonQuantityDataSource {
        let windows: WindowData[] = drawingData.windows;
        let subWindows: SubWindowData[] = [].concat.apply([], windows.map(window => window.subWindows));
        let subwindowQuantity = subWindows.length;
        let wingsQuantity = subWindows.filter(subWindow => !WindowCalculator.isSubWindowFixed(subWindow)).length;
        let activeWingsQuantity = subWindows.filter(subWindow => WindowCalculator.isSubWindowActive(subWindow)).length;
        let passiveWingsQuantity = wingsQuantity - activeWingsQuantity;
        let wingFunctions = subWindows.map(sw => sw.functionsQuantity).reduce((a, b) => a + b, 0);
        let areas: AreaSpecification[] = [].concat.apply([], subWindows.map(subWindow => subWindow.areasSpecification));
        let doorstepWidth = SizeUnitsConverter.mmToMeter(AddonDefaultQuantityCalculator.calculateDoorstepWidth(drawingData));
        let glazingNonEsgArea = this.getGlazingNonEsgArea(subWindows, staticData);

        let windowPerimeter = PolygonGeometryCalculator.calculatePolygonPerimeterForWindow(drawingData);
        let windowHeight = PolygonGeometryCalculator.calculatePolygonHeightForWindow(drawingData);
        let windowWidth = PolygonGeometryCalculator.calculatePolygonWidthForWindow(drawingData);
        let windowArea = windowHeight * windowWidth;

        let dataSource = new AddonQuantityDataSource();
        dataSource.quantities[AddonFor.AREA] = areas.length;
        dataSource.quantities[AddonFor.AREA_SIZE] = areas
            .filter(a => a.filling.type === FillingType.GLASS)
            .reduce((totalGlazingArea, area) => {
                return totalGlazingArea + SizeUnitsConverter.mmSidesToMeterSquared(area.width, area.height);
            }, 0);
        dataSource.quantities[AddonFor.DOORSTEP_WIDTH] = doorstepWidth;
        dataSource.quantities[AddonFor.GLAZING_NON_ESG] = glazingNonEsgArea;
        dataSource.quantities[AddonFor.QUARTER] = subwindowQuantity;
        dataSource.quantities[AddonFor.WINDOW] = 1;
        dataSource.quantities[AddonFor.WING] = wingsQuantity;
        dataSource.quantities[AddonFor.WING_ACTIVE] = activeWingsQuantity;
        dataSource.quantities[AddonFor.WING_FUNCTIONS] = wingFunctions;
        dataSource.quantities[AddonFor.WING_PASSIVE] = passiveWingsQuantity;
        dataSource.quantities[AddonFor.TERRACE_LOCK] = this.prepareTerraceLockQuantity(drawingData, subwindowQuantity);
        dataSource.quantities[AddonFor.TERRACE_HANDLE] = this.calculateTerraceHandleQuantity(drawingData, subwindowQuantity) * windowHeight / 1000;

        dataSource.windowArea = windowArea;
        dataSource.windowHeight = windowHeight;
        dataSource.windowPerimeter = windowPerimeter;
        dataSource.windowWidth = windowWidth;
        return dataSource;
    }

    private static prepareTerraceLockQuantity(drawingData: DrawingData, subwindowQuantity: number): number {
        if (drawingData.specification.fittingLockTerraceLocation == undefined
            || drawingData.specification.fittingLockTerraceLocation === DropDownExtraOptions.DO_NOT_CHANGE) {
            return 0;
        }

        switch (drawingData.specification.fittingLockTerraceLocation) {
            case FittingTerraceLockLocation.LEFT:
            case FittingTerraceLockLocation.RIGHT:
                return Math.min(1, subwindowQuantity);
            case FittingTerraceLockLocation.EDGES:
                return Math.min(2, subwindowQuantity);
            case FittingTerraceLockLocation.EDGES_AND_RIGHT_MIDDLE:
            case FittingTerraceLockLocation.EDGES_AND_LEFT_MIDDLE:
                return Math.min(3, subwindowQuantity);
            default:
                throw new Error('Unsupported FittingTerraceLockLocation type: ' + drawingData.specification.fittingLockTerraceLocation);
        }
    }

    public static prepareRoofDataSource(dimensions: WindowDimensions): AddonQuantityDataSource {
        const dataSource = new AddonQuantityDataSource();

        dataSource.windowHeight = SizeUnitsConverter.cmToMillimeter(dimensions.height);
        dataSource.windowWidth = SizeUnitsConverter.cmToMillimeter(dimensions.width);
        dataSource.windowArea = dataSource.windowHeight * dataSource.windowWidth;
        dataSource.windowPerimeter = (dataSource.windowHeight + dataSource.windowWidth) * 2;

        dataSource.quantities[AddonFor.AREA] = 1;
        dataSource.quantities[AddonFor.AREA_SIZE] = dataSource.windowArea;
        dataSource.quantities[AddonFor.DOORSTEP_WIDTH] = 0;
        dataSource.quantities[AddonFor.GLAZING_NON_ESG] = 0;
        dataSource.quantities[AddonFor.QUARTER] = 1;
        dataSource.quantities[AddonFor.WINDOW] = 1;
        dataSource.quantities[AddonFor.WING] = 1;
        dataSource.quantities[AddonFor.WING_ACTIVE] = 1;
        dataSource.quantities[AddonFor.WING_FUNCTIONS] = 1;
        dataSource.quantities[AddonFor.WING_PASSIVE] = 0;
        dataSource.quantities[AddonFor.TERRACE_LOCK] = 0;
        dataSource.quantities[AddonFor.TERRACE_HANDLE] = 0;

        return dataSource;
    }

    public static prepareGateDataSource(): AddonQuantityDataSource {
        const dataSource = new AddonQuantityDataSource();
        // for future needs
        dataSource.quantities[AddonFor.AREA] = 0;
        dataSource.quantities[AddonFor.AREA_SIZE] = 0;
        dataSource.quantities[AddonFor.DOORSTEP_WIDTH] = 0;
        dataSource.quantities[AddonFor.GLAZING_NON_ESG] = 0;
        dataSource.quantities[AddonFor.QUARTER] = 0;
        dataSource.quantities[AddonFor.WINDOW] = 0;
        dataSource.quantities[AddonFor.WING] = 0;
        dataSource.quantities[AddonFor.WING_ACTIVE] = 0;
        dataSource.quantities[AddonFor.WING_FUNCTIONS] = 0;
        dataSource.quantities[AddonFor.WING_PASSIVE] = 0;
        dataSource.quantities[AddonFor.TERRACE_LOCK] = 0;
        dataSource.quantities[AddonFor.TERRACE_HANDLE] = 0;
        dataSource.quantities[AddonFor.GATE] = 1;

        return dataSource;
    }

    private static getGlazingNonEsgArea(subWindows: SubWindowData[], staticData: StaticDataHelper): number {
        return _.flatten(subWindows.map(subWindow => subWindow.areasSpecification))
            .filter(area => area.filling.type === FillingType.GLASS || area.filling.type === FillingType.DECORATIVE_FILLING)
            .map(area => {
                let nonEsgGlassesCount = AddonDefaultQuantityCalculator.countNonEsgGlasses(area.glazing, staticData.glasses);
                let glazingArea = 0;
                if (area.filling.type === FillingType.DECORATIVE_FILLING) {
                    let filling = staticData.decorativeFillings && staticData.decorativeFillings.find(
                        decorativeFilling => decorativeFilling.id === area.filling.decorativeFillingId);
                    glazingArea = filling ? filling.glazingSurface : 0;
                } else {
                    glazingArea = SizeUnitsConverter.mmToMeter(area.width) * SizeUnitsConverter.mmToMeter(area.height);
                }
                return glazingArea * nonEsgGlassesCount;
            }).reduce((total, num) => total + num, 0);
    }

    private static countNonEsgGlasses(glazing: Glazing, glasses: GlassInterface[]): number {
        return [glazing.glass1id, glazing.glass2id, glazing.glass3id, glazing.glass4id].filter(id => id != null)
            .map(id => glasses.find(glass => glass.id === id)).filter(glass => glass != undefined && !glass.esg).length;
    }

    // Instead of ToFixed() because we do not want to get .00 decimals
    private static roundNumberToThirdDecimalPlace(value: number) {
        return Math.round(value * this.THOUSAND_DIVISOR) / this.THOUSAND_DIVISOR;
    }

    private static calculateDoorstepWidth(drawingData: DrawingData): number {
        let maxY = AddonDefaultQuantityCalculator.getConstructionMaxY(drawingData);
        return _.flatten(drawingData.windows.map(window => window.subWindows))
            .filter(subwindow => !WindowCalculator.isSubWindowFixed(subwindow))
            .filter(subwindow => AddonDefaultQuantityCalculator.getSubwindowMaxY(subwindow, drawingData.cuts) === maxY)
            .map(subwindow => AddonDefaultQuantityCalculator.getSubwindowWidth(subwindow, drawingData.cuts))
            .reduce((total, width) => total + width, 0);
    }

    private static getConstructionMaxY(drawingData: DrawingData): number {
        let framePoints = WindowCalculator.getOuterFramePoints(drawingData.windows, drawingData.cuts);
        return AddonDefaultQuantityCalculator.getMaxY(framePoints);
    }

    private static getSubwindowMaxY(subwindow: SubWindowData, cuts: CutData[]): number {
        let framePoints = WindowCalculator.getOuterSubwindowPoints(subwindow, cuts);
        return AddonDefaultQuantityCalculator.getMaxY(framePoints);
    }

    private static getSubwindowWidth(subwindow: SubWindowData, cuts: CutData[]): number {
        let framePoints = WindowCalculator.getOuterSubwindowPoints(subwindow, cuts);
        let xCoordsDesc = framePoints.filter((coord, index) => index % 2 === 0).sort((a, b) => b - a);
        return _.first(xCoordsDesc) - _.last(xCoordsDesc);
    }

    private static getMaxY(coordinates: number[]): number {
        return _.max(coordinates.filter((coord, index) => index % 2 !== 0));
    }

    private static setSubwindowFunctionsQuantity(data: DrawingData, subwindowTypes: SubwindowTypes): void {
        data.windows.forEach(w => {
            w.subWindows.forEach(sw => {
                sw.functionsQuantity = subwindowTypes.getFunctions(sw.typeCode);
            });
        });
    }

    public static recalculateAll(data: DrawingData, subwindowTypes: SubwindowTypes, addonDtos: PositionListAddon[],
                                 staticData: StaticDataHelper): void {
        this.setSubwindowFunctionsQuantity(data, subwindowTypes);
        let dataSource = AddonDefaultQuantityCalculator.prepareDataSource(data, staticData);
        data.addons.forEach(windowAddon => {
            let addonDto = addonDtos.find(addedWindowAddon => addedWindowAddon.id === windowAddon.addonId);
            if (addonDto && !addonDto.lockRecalculateQuantity) {
                let calculatedQuantity = AddonDefaultQuantityCalculator.calculate(addonDto, dataSource);
                addonDto.quantity = calculatedQuantity;
                windowAddon.quantity = calculatedQuantity;
            }
        });
    }

    public static recalculateAllOnGlobalChange(data: DrawingData, subwindowTypes: SubwindowTypes,
                                               addonDtos: AddonInterface[], staticData: StaticDataHelper): void {
        this.setSubwindowFunctionsQuantity(data, subwindowTypes);
        let dataSource = AddonDefaultQuantityCalculator.prepareDataSource(data, staticData);
        data.addons.forEach(windowAddon => {
            let addonDto = addonDtos.find(addedWindowAddon => addedWindowAddon.id === windowAddon.addonId);
            windowAddon.quantity = AddonDefaultQuantityCalculator.calculate(addonDto, dataSource);
        });
    }

    private static calculateTerraceHandleQuantity(drawingData: DrawingData, subwindowQuantity: number): number {
        if (drawingData.specification.terraceHandleLayout == undefined
            || drawingData.specification.terraceHandleLayout === DropDownExtraOptions.DO_NOT_CHANGE) {
            return 0;
        }
        switch (drawingData.specification.terraceHandleLayout) {
            case TerraceHandleLayout.EDGES_ONLY_INSIDE:
                return 2;
            case TerraceHandleLayout.EVERYWHERE:
                return subwindowQuantity * 2;
            case TerraceHandleLayout.EVERYWHERE_ONLY_INSIDE:
                return subwindowQuantity + 1; // not 100% confirmed
            case TerraceHandleLayout.CENTER:
                return 2;
            case TerraceHandleLayout.NOWHERE:
                return 0;
            default:
                throw new Error(`Unsupported TerraceHandleLayout ${drawingData.specification.terraceHandleLayout}`);
        }
    }
}
