import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogPropertyTarget} from "../CatalogPropertyTarget";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";

export class CatalogProperty {
    id: number;
    name: MultilanguageField;
    active: boolean;
    sortIndex: number;
    target: CatalogPropertyTarget;
    options: number[];
    visibleInApplication: boolean;
    visibleOnPrint: boolean;
    availableInFixedWindows?: boolean;
    availableInNonFixedWindows?: boolean;
    applicableTo: ConfigAddonApplication[];

    constructor(target: CatalogPropertyTarget) {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.active = true;
        this.sortIndex = 1;
        this.target = target;
        this.options = [];
        this.visibleInApplication = true;
        this.visibleOnPrint = true;
        this.availableInFixedWindows = target === CatalogPropertyTarget.WINDOW_SYSTEMS ? true : undefined;
        this.availableInNonFixedWindows = target === CatalogPropertyTarget.WINDOW_SYSTEMS ? true : undefined;
        this.applicableTo = [];
    }
}
