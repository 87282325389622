import {DrawingData} from "../drawing-data/drawing-data";
import {ErrorNames} from "./ErrorNames";

export abstract class GrillSegmentIdGenerator {

    private static minUsedGrillId: number;
    private static maxUsedGrillId: number;

    public static init(dData: DrawingData) {
        let min = 0;
        let max = 0;
        dData.windows.forEach(
            w => w.subWindows.forEach(sw => sw.areasSpecification.forEach(as => as.grills.forEach(g => {
                (g.drawingSegments || []).forEach(gs => {
                    min = Math.min(min, gs.id);
                });
            }))));
        dData.windows.forEach(w => w.subWindows.forEach(
            sw => sw.mullions.forEach(mullion => ((mullion.drawingSegments || []).forEach(gs => {
                min = Math.min(min, gs.id);
            })))));
        dData.windows.forEach(
            w => w.subWindows.forEach(sw => sw.areasSpecification.forEach(as => as.grills.forEach(g => {
                (g.pricingSegments || []).forEach(gs => {
                    max = Math.max(max, gs.id);
                });
            }))));
        dData.windows.forEach(w => w.subWindows.forEach(
            sw => sw.mullions.forEach(mullion => ((mullion.pricingSegments || []).forEach(gs => {
                max = Math.max(max, gs.id);
            })))));
        this.minUsedGrillId = min;
        this.maxUsedGrillId = max;
    }

    public static getNextId(positive: boolean): number {
        if (this.maxUsedGrillId == null) {
            let err = new Error("GrillSegmentIdGenerator: Not initialized!");
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return positive ? ++this.maxUsedGrillId : --this.minUsedGrillId;
    }
}
