export enum CechaGrupa {
    DIMENSIONS = "DIMENSIONS",
    BULK_DIMENSIONS = "BULK_DIMENSIONS",
    BASIC_PROPS = "BASIC_PROPS",
    GLAZING = "GLAZING",
    DOORS = "DOORS",
    ACCESSORIES = "ACCESSORIES",
    DECORATIONS = "DECORATIONS",
    AUTOMATICS = "AUTOMATICS",
    ADDITIONAL_INFO = "ADDITIONAL_INFO"
}
