import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-sidebar-field-label',
    templateUrl: './sidebar-field-label.component.html',
    styleUrls: ['./sidebar-field-label.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SidebarFieldLabelComponent {

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    tooltipLabel: string;

    @Input()
    disabled: boolean;

    @Input()
    showImage = true;

    @Output()
    onShowImage = new EventEmitter<void>();

    handleShowImage(): void {
        this.onShowImage.emit(undefined);
    }
}
