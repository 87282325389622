import {Grill} from "./Grill";
import {GrillType} from "./GrillType";
import {Veneer} from "./Veneer";

export class Mullion extends Grill {
    type: GrillType.MULLION;
    isConstructional: boolean;
    veneer: Veneer;
    length: number;

    constructor() {
        super(GrillType.MULLION);
        this.veneer = new Veneer();
    }
}
