import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SliderComponent, SliderSlideDirective} from './slider.component';

@NgModule({
    declarations: [SliderSlideDirective, SliderComponent],
    imports: [CommonModule],
    exports: [SliderSlideDirective, SliderComponent]
})
export class SliderModule {
}
