<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" [closable]="true" [closeOnEscape]="true"
              (onHide)="hide()" [modal]="true" [focusOnShow]="false">

        <p-header>
            <div class="p-dialog-title">
                {{ 'MOTLAWA_INTEGRATION_DIALOG.DISTRIBUTION_HEADER' | translate }}
            </div>
        </p-header>

        <div class="new-form-60vw">
            <ng-container *ngIf="supplierIds.length > 0">
                <div class="new-form-row">
                    <h3>{{ 'MOTLAWA_INTEGRATION_DIALOG.ADDONS_DISTRIBUTION_HEADER' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <h4>{{ 'MOTLAWA_INTEGRATION_DIALOG.ADDONS_DISTRIBUTION_HINT' | translate }}</h4>
                </div>

                <ng-container *ngFor="let supplierId of supplierIds">
                    <ng-container
                        *ngTemplateOutlet="possibilitySelect; context: { data: dialogsData.addonsSelections, supplierId: supplierId }">
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="offerChargesSupplierIds.length > 0">
                <div class="new-form-row">
                    <h3>{{ 'MOTLAWA_INTEGRATION_DIALOG.OFFER_CHARGES_DISTRIBUTION_HEADER' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <h4>{{ 'MOTLAWA_INTEGRATION_DIALOG.OFFER_CHARGES_DISTRIBUTION_HINT' | translate }}</h4>
                </div>

                <ng-container *ngFor="let supplierId of offerChargesSupplierIds">
                    <ng-container
                        *ngTemplateOutlet="possibilitySelect; context: { data: dialogsData.offerChargesSelections, supplierId: supplierId }">
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirmAddonDistributions"
                                   label="{{ 'WIZARD.NEXT' | translate }}"
                                   type="main-action" [size]="40" (onClick)="confirm()"></app-simple-button>
                <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>

<ng-template #possibilitySelect let-data="data" let-supplierId="supplierId">
    <div class="new-form-row"><h3>{{ data.supplierNamesById[supplierId] }}</h3></div>
    <div *ngFor="let possibilities of getSupplierPossibilities(data, supplierId)"
         class="new-form-row">
        <app-select [inputId]="'supplierId_' + supplierId + '_offerId_' + possibilities.offerId"
                    label="{{ possibilities.offerNumber }}"
                    [options]="possibilities.possibilities" [optionFormatter]="optionFormatter"
                    [ngModel]="getSelection(data, supplierId, possibilities.offerId).selection"
                    (ngModelChange)="setSelection(data, supplierId, possibilities.offerId, $event)"></app-select>
    </div>
</ng-template>

