import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {interval, Observable, Subject} from 'rxjs';
import {map, mergeMap, share, take, takeWhile, tap} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {TabularAddress} from '../../../subsystem/tabularAddress';
import {Position} from '../../offers/position/position-list/position';
import {ShippingSimulationListModel} from './shipping-simulation-list.model';
import {ShippingSimulationOfferModel} from './shipping-simulation-offer.model';
import {ShippingSimulationPositionListModel} from './shipping-simulation-position-list.model';
import {ShippingSimulationPositionModel} from './shipping-simulation-position.model';
import {ShippingSimulationModel} from './shipping-simulation.model';

@Injectable()
export class ShippingSimulationService {

    private readonly API_URL = 'shippingSimulation';

    shippingSimulation: ShippingSimulationModel;
    shippingOffers: ShippingSimulationOfferModel[] = [];
    checkCalculationFinishedInterval: Observable<ShippingSimulationModel>;

    constructor(private http: HttpClient,
                private dataServiceHelper: DataServiceHelper,
                private errors: CommonErrorHandler,
                private growl: GrowlMessageController) {
    }

    private static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    addOfferPositionsToDraft(positions: Position[]): Observable<void> {
        const listOfIds = positions.map(position => position.id);
        return this.http.post<void>(`${this.API_URL}/addOfferPositionsToSimulation/${this.shippingSimulation.id}`, listOfIds);
    }

    getDraftForCurrentUser(): Observable<ShippingSimulationModel> {
        return this.http.get<ShippingSimulationModel>(`${this.API_URL}/getDraftForCurrentUser`).pipe(
            tap(response => {
                this.shippingSimulation = response;
            }));
    }

    getSimulationById(simulationId: number): Observable<ShippingSimulationModel> {
        return this.http.get<ShippingSimulationModel>(`${this.API_URL}/getSimulationById/${simulationId}`).pipe(
            tap(response => {
                this.shippingSimulation = response;
            }));
    }

    removeAllPositionsFromSimulation(simulationId: number): Observable<void> {
        return this.http.get<void>(`${this.API_URL}/clearSimulation/${simulationId}`);
    }

    saveDraft(simulationName: string): Observable<ShippingSimulationModel> {
        return this.http.post<void>(`${this.API_URL}/saveDraft`, simulationName, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            tap(() => {
                this.shippingSimulation.name = simulationName;
                this.shippingSimulation.draft = false;
                this.shippingSimulation.createdDate = new Date();
            }),
            map(() => ({...this.shippingSimulation})));
    }

    deleteSimulation(simulationId: number): Observable<void> {
        return this.http.delete<void>(`${this.API_URL}/deleteSimulation/${simulationId}`);
    }

    addOffersToDraft(offerIds: number[], stopIfSimulationImpossibleForAnyPosition: boolean): Observable<{
        offersWithWindowPositions: number;
        allPositionsCanBeSimulated: boolean;
    }> {
        return this.http.post<{
            offersWithWindowPositions: number;
            allPositionsCanBeSimulated: boolean;
        }>(`${this.API_URL}/addOffersToSimulation/${this.shippingSimulation.id}`, offerIds, {
            params: {
                stopIfSimulationImpossibleForAnyPosition: `${stopIfSimulationImpossibleForAnyPosition}`
            }
        });
    }

    createCheckCalculationFinishedInterval(simulationId: number, endNotifier?: Subject<boolean>): Observable<ShippingSimulationModel> {
        this.checkCalculationFinishedInterval = interval(2000).pipe(
            take(150),
            mergeMap(() => this.getSimulationById(simulationId)),
            takeWhile(simulation => {
                return (endNotifier && !endNotifier.isStopped && simulation.calculationInProgress) || simulation.calculationInProgress;
            }),
            share());
        this.checkCalculationFinishedInterval.subscribe({
            complete: () => {
                if (this.shippingSimulation.calculationFailedCode) {
                    this.growl.error(this.shippingSimulation.calculationFailedCode);
                }
                this.checkCalculationFinishedInterval = null;
            },
            error: (error) => this.errors.handle(error)
        });
        return this.checkCalculationFinishedInterval;
    }

    private mapShippingPositionsToOffers(shippingPositions: ShippingSimulationPositionModel[]): ShippingSimulationOfferModel[] {
        let offers: ShippingSimulationOfferModel[] = [];

        shippingPositions.forEach(shippingPosition => {
            let offer = offers.find(storedOffer => shippingPosition.offer.id === storedOffer.id);

            if (offer == null) {
                offer = ShippingSimulationOfferModel.clone(shippingPosition.offer);
                offers.push(offer);
            }

            if (offer.positions == null) {
                offer.positions = [];
            }

            shippingPosition.offerPosition.shippingPositionId = shippingPosition.id;
            offer.positions.push(shippingPosition.offerPosition);
        });

        return offers;
    }

    removeOfferFromDraft(offerId: number): Observable<void> {
        return this.http.get<void>(`${this.API_URL}/removeOffer/${this.shippingSimulation.id}/${offerId}`);
    }

    removeSimulationPositionsFromDraft(simulationPositionIds: number[]): Observable<void> {
        return this.http.post<void>(`${this.API_URL}/removeSimulationPositions`, simulationPositionIds);
    }

    setDeliveryAddress(address: TabularAddress): Observable<void> {
        return this.http.put<void>(`${this.API_URL}/${this.shippingSimulation.id}/setDeliveryAddress`, address.id);
    }

    getOffers(): ShippingSimulationOfferModel[] {
        return this.shippingOffers;
    }

    getPositionCount(): number {
        let positionCounts = this.shippingOffers.map(offer => offer.positions.length);
        return positionCounts.length > 0 ? positionCounts.reduce((a, b) => a + b) : 0;
    }

    getOfferCount(): number {
        return this.shippingOffers.length;
    }

    switchPositionDisplay(offerId: number): void {
        let offer = this.getOffers().find(o => o.id === offerId);
        if (offer) {
            offer.displayShortList = !offer.displayShortList;
        }
    }

    calculateTransport(useBigTrucks: boolean, experimental = false) {
        return this.http.post(`${this.API_URL}/calculateShipping/${this.shippingSimulation.id}`, undefined, {
            params: {
                useBigTrucks: `${useBigTrucks}`,
                experimental: `${experimental}`
            }
        });
    }

    getSimulationPositions(simulationId: number, offset: number, pageSize: number, sortColumn: string, sortOrder: number):
        Observable<ShippingSimulationPositionListModel> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, {simulationId: {value: '' + simulationId}},
            sortColumn, sortOrder);
        return this.http.get<ShippingSimulationPositionListModel>(`${this.API_URL}/getPositions`, {params: params}).pipe(
            tap(response => {
                this.shippingOffers = this.mapShippingPositionsToOffers(response.data);
            }));
    }

    getSimulations(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
                   sortColumn: string, sortOrder: number): Observable<ShippingSimulationListModel> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, {
            slots: ShippingSimulationService.isNumeric
        });
        return this.http.get<ShippingSimulationListModel>(this.API_URL + '/getSimulations', {params: params});
    }

    getAllSimulationPositions(simulationId: number): Observable<ShippingSimulationPositionListModel> {
        return this.getSimulationPositions(simulationId, 0, null, null, null);
    }

    calculateShippingCostForMultipleSimulations(shippingSimulationIds: number[]): Observable<number> {
        return this.http.post<number>(`${this.API_URL}/calculatePrice`, shippingSimulationIds);
    }
}
