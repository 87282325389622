import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {CSVColumn, CSVDataSource, ExportComponent} from '../../../common/service/export.component';
import {OfferCounterReportModel} from "../offer-counter-report.model";
import {OfferSupplierCounterService} from "./offer-supplier-counter.service";

@Component({
    selector: 'app-offer-supplier-counter',
    templateUrl: './offer-supplier-counter.component.html',
    styleUrls: ['../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OfferSupplierCounterService, ExportComponent]
})
export class OfferSupplierCounterComponent implements OnInit, CSVDataSource {

    offerCounts: OfferCounterReportModel[] = [];
    years: string[] = [];
    defaultSortColumn: string = 'groupName';
    defaultSortOrder: number = 1;

    constructor(private offerSupplierCounterService: OfferSupplierCounterService,
                private changeDetector: ChangeDetectorRef,
                protected exportComponent: ExportComponent,
                private translate: TranslateService) {}

    ngOnInit(): void {
        this.offerSupplierCounterService.countOrdersBySupplierAndYear().subscribe(responseData => {
            this.offerCounts = responseData.data;
            this.initYears();
            this.changeDetector.markForCheck();
        });
    }

    private initYears(): void {
        let groupedYears: number[] = [];

        this.offerCounts.forEach(offerCount => {
            Object.keys(offerCount.countByYear).forEach(year => {
                if (groupedYears.find(groupedYear => '' + groupedYear == year) == null) {
                    groupedYears.push(Number.parseInt(year));
                }
            });
        });

        groupedYears.sort((a, b) => b - a);
        this.years.push(...groupedYears.map(year => '' + year));
    }

    public exportCSV(filename: string): void {
        this.exportComponent.exportCSV(this, filename);
    }

    getExportData(): Observable<object[]> {
        return of(this.offerCounts);
    }

    getExportColumns(): Observable<CSVColumn[]> {
        return this.translate.get('ADMIN_PANEL.OFFER_SUPPLIER_COUNTER.FORM.SUPPLIER').pipe(map(supplierLabel => [
            {
                label: supplierLabel,
                field: 'groupName'
            }, ...this.years.map(y => {
                return {
                    label: y,
                    field: 'countByYear.' + y
                };
            })
        ]));
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        if (event) {
            if (event.sortField === 'groupName') {
                this.offerCounts = _.sortBy(this.offerCounts, 'groupName');
            } else if (event.sortField != null) {
            }
            this.offerCounts = _.chain(this.offerCounts)
                .sortBy('groupName')
                .sortBy(obj => obj.countByYear[event.sortField] || 0)
                .value();

            if (event.sortOrder < 0) {
                this.offerCounts = this.offerCounts.reverse();
            }
        }
    }
}
