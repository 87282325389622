import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class Role {
    id: number;
    roleName: string;
    privileges: number[] = [];
    primary: boolean;

    static fromJSON(jsonObject: any): Role {
        const role = new Role();
        role.id = jsonObject['id'];
        role.roleName = jsonObject['roleName'];
        if (jsonObject['privileges'] != undefined) {
            role.privileges = [...jsonObject['privileges']];
        }
        role.primary = jsonObject['primary'];
        return role;
    }
}
