import {HttpErrorResponse} from "@angular/common/http";
import {
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    createComponent,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {AbstractWindowDesigner} from '../../../../../../../window-designer/abstract-window-designer';
import {CachingWindowDesignerDataService} from '../../../../../../../window-designer/caching-window-designer-data-service';
import {Glazing} from "../../../../../../../window-designer/catalog-data/glazing";
import {WindowSystemInterface, WindowSystemType} from "../../../../../../../window-designer/catalog-data/window-system-interface";
import {DrawingData} from "../../../../../../../window-designer/drawing-data/drawing-data";
import {GlazingHelper} from "../../../../../../../window-designer/glazing-helper";
import {MaxWindowDimensions} from '../../../../../../../window-designer/max-window-dimensions';
import {ProfilesCompositionDistances} from '../../../../../../../window-designer/profiles-composition-distances';
import {WindowTypeCode} from '../../../../../../../window-designer/window-types/window-type-code';
import {Permissions} from '../../../../../../auth/permission.service';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {GlassSelectionValidator} from "../../../../../../common/glass-selection/GlassSelectionValidator";
import {MultilanguageFieldSelectItem} from '../../../../../../common/service/select-item-multilanguage-field-translate.pipe';
import {ValidationErrors} from "../../../../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../../../../common/ValidationErrorsHelper";
import {MultiValidator} from "../../../../../../shared/validator/input-validator";
import {WindowSystemDefaultsState} from "../../../../../settings/system-defaults/system-default-state";
import {BusinessTypeService} from '../../../../../window-system/business-type/business-type.service';
import {DistanceFrameService} from "../../../../../window-system/distance-frame/distance-frame.service";
import {DistanceFrame} from "../../../../../window-system/distance-frame/distanceFrame";
import {GlassService} from "../../../../../window-system/glass/glass.service";
import {GlassWithPosition} from "../../../../../window-system/glass/glassWithPositions";
import {
    GraspDistanceFrameCategoryService
} from "../../../../../window-system/grasp-distance-frame-category/grasp-distance-frame-category.service";
import {GraspGlazingCategoryService} from "../../../../../window-system/grasp-glazing-categories/grasp-glazing-category.service";
import {GraspGlazingPackage} from "../../../../../window-system/grasp-glazing-package/grasp-glazing-package";
import {GraspGlazingPackageService} from "../../../../../window-system/grasp-glazing-package/grasp-glazing-package.service";
import {WindowSystemDefinitionService} from '../../../../../window-system/window-system-definition/window-system-definition.service';
import {AbstractPosition} from '../../../../AbstractPosition';
import {SystemDefaultsService} from '../../../../window-editor/system-defaults.service';
import {WindowDesignerDataService} from '../../../../window-editor/window-designer/window-designer-data.service';
import {WindowDesignerComponent} from '../../../../window-editor/window-designer/window-designer.component';
import {WindowEditorWindowSystemInterface} from '../../../../window-editor/window-editor-window-system-interface';
import {PositionService} from '../../position.service';
import {Position, PositionFactory} from '../position';
import {standaloneGlazingPackageWindowDesignerVisibilitySettings} from './standalone-glazing-package-window-designer-visibility-settings';

@Component({
    selector: 'app-add-standalone-glazing-package',
    templateUrl: './add-standalone-glazing-package.component.html',
    providers: [SystemDefaultsService, BusinessTypeService, GraspGlazingCategoryService, GraspDistanceFrameCategoryService,
        GraspGlazingPackageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStandaloneGlazingPackageComponent implements OnInit {

    @Input()
    offerId: number;

    @Input()
    position: AbstractPosition;

    @Input()
    canEditOffer: boolean;

    @Output()
    readonly saved = new EventEmitter<void>();

    @Output()
    readonly closed = new EventEmitter<void>();

    availableWindowSystems: MultilanguageFieldSelectItem[] = [];
    selectedWindowSystem: WindowEditorWindowSystemInterface;
    usesPredefinedGlazingPackages = false;
    glazingPackageWidth: number;
    glazingPackageHeight: number;
    glazingPackageQuantity: number;
    glazingPackageCategoryId: number;
    glazingPackageFrameCatagoryId: number;
    glazingPackageId: number;

    glasses: GlassWithPosition[] = [];
    frames: DistanceFrame[] = [];
    glazing: Glazing;
    glazingGlassNumber: number[] = [];
    predefinedGlazingGlassQuantities: SelectItem[] = [];
    predefinedGlazingPackageCategories: MultilanguageFieldSelectItem[] = [];
    predefinedGlazingPackageFrameCategories: MultilanguageFieldSelectItem[] = [];
    allPredefinedGlazingPackages: GraspGlazingPackage[] = [];
    predefinedGlazingPackages: MultilanguageFieldSelectItem[] = [];
    loading = true;

    windowSystemDefaults: WindowSystemDefaultsState;

    maxDimensions = new MaxWindowDimensions();

    validationErrors: ValidationErrors = {};

    visible = true;
    saveInProgress = false;
    headerLabelKey = 'OFFER.POSITIONS.ADD_STANDALONE_GLAZING_PACKAGE';

    constructor(private readonly windowSystemService: WindowSystemDefinitionService,
                private readonly windowSystemDefaultsService: SystemDefaultsService,
                private readonly windowDesignerDataService: WindowDesignerDataService,
                private readonly businessTypeService: BusinessTypeService,
                private readonly positionService: PositionService,
                private readonly glassService: GlassService,
                private readonly frameService: DistanceFrameService,
                private readonly graspGlazingCategoryService: GraspGlazingCategoryService,
                private readonly graspDistanceFrameCategoryService: GraspDistanceFrameCategoryService,
                private readonly graspGlazingPackageService: GraspGlazingPackageService,
                private readonly errors: CommonErrorHandler,
                private readonly permissions: Permissions,
                private readonly changeDetector: ChangeDetectorRef,
                private readonly application: ApplicationRef,
                private readonly injector: Injector) {
    }

    get readOnlyMode(): boolean {
        if (!this.canEditOffer) {
            return true;
        }
        const validationDisabled = this.position != undefined && this.position['validationDisabled'];
        return validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun();
    }

    ngOnInit(): void {
        forkJoin({
            windowSystems: this.businessTypeService.getWindowSystemsByBusinessTypes([WindowTypeCode.F],
                WindowSystemType.getWindowSystemTypes()),
            selectedWindowSystem: this.position != undefined
                ? this.windowSystemService.getSystem(this.position.windowSystemId)
                : of<WindowSystemInterface>(undefined)
        }).subscribe(data => {
            this.glazing = new Glazing();
            this.availableWindowSystems = data.windowSystems.filter(ws => ws.canHaveStandaloneGlazingPackages).map(ws => ({
                labelKey: ws.names,
                value: ws
            }));
            if (this.position != undefined) {
                this.selectedWindowSystem = data.windowSystems.find(ws => ws.id === this.position.windowSystemId);
                this.usesPredefinedGlazingPackages = WindowSystemType.isPredefinedGlazing(
                    WindowSystemType.getByName(data.selectedWindowSystem.systemType));
                this.headerLabelKey = 'OFFER.POSITIONS.EDIT_STANDALONE_GLAZING_PACKAGE';
                this.glazingPackageQuantity = this.position.quantity;
                const drawingData: DrawingData = JSON.parse(this.position.data);
                const dimensions = this.position.dimensions.split('x').map(value => +value);
                this.glazingPackageWidth = dimensions[0];
                this.glazingPackageHeight = dimensions[1];
                const area = drawingData.windows[0].subWindows[0].areasSpecification[0];
                const glazing = area.glazing;
                this.assingGlazingItems(glazing, area, data.selectedWindowSystem);
                this.changeDetector.markForCheck();
            }
            this.loading = false;
        });
    }

    onSystemChange(selectedSystem: WindowEditorWindowSystemInterface): void {
        this.selectedWindowSystem = selectedSystem;
        if (this.selectedWindowSystem != undefined) {
            this.windowSystemService.getWindowSystemToUseForStandaloneGlazingPackage(this.selectedWindowSystem.id).pipe(
                mergeMap(resolvedWindowSystemId =>
                    forkJoin({
                        system: this.windowSystemService.getSystem(resolvedWindowSystemId),
                        defaults: this.windowSystemDefaultsService.getDefaultsForWindow(resolvedWindowSystemId, this.offerId, false, true)
                    })))
                .subscribe(data => {
                    this.usesPredefinedGlazingPackages = WindowSystemType.isPredefinedGlazing(
                        WindowSystemType.getByName(data.system.systemType));
                    this.windowSystemDefaults = data.defaults;
                    const glazing = new Glazing();
                    glazing.glass1id = data.defaults.value.glass1id;
                    glazing.glass2id = data.defaults.value.glass2id;
                    glazing.glass3id = data.defaults.value.glass3id;
                    glazing.glass4id = data.defaults.value.glass4id;
                    glazing.frame1id = data.defaults.value.frame1id;
                    glazing.frame2id = data.defaults.value.frame2id;
                    glazing.frame3id = data.defaults.value.frame3id;
                    glazing.glazingGlassQuantity = data.defaults.value.glazingGlassQn;
                    this.assingGlazingItems(glazing, {
                        glazingCategoryId: data.defaults.value.glazingCategoryId,
                        glazingFrameCategoryId: data.defaults.value.glazingFrameCategoryId,
                        glazingPackageId: data.defaults.value.glazingPackageId
                    }, data.system);
                    this.changeDetector.markForCheck();
                });
        }
    }

    private createGlazingPackagePosition(windowSystemId: number): Observable<Position> {
        return this.windowSystemService.getWindowSystemToUseForStandaloneGlazingPackage(windowSystemId).pipe(
            mergeMap(resolvedWindowSystemId => forkJoin({
                system: this.windowSystemService.getSystem(resolvedWindowSystemId),
                defaults: this.position != undefined ?
                    this.windowSystemDefaultsService.getDefaultsForWindow(resolvedWindowSystemId, this.offerId, false, true)
                    : of<WindowSystemDefaultsState>(undefined),
                profiles: this.windowSystemService.getSystemsProfiles(resolvedWindowSystemId).pipe(map(listing => listing.data))
            })),
            map(catalogData => {
                if (this.position != undefined) {
                    this.windowSystemDefaults = catalogData.defaults;
                }
                this.reassignGlazing();
                const profileCompositionDistances = new ProfilesCompositionDistances();
                profileCompositionDistances.prepareSystem(catalogData.system);
                profileCompositionDistances.prepareProfileDistances(catalogData.profiles,
                    this.windowSystemDefaults.value);
                const drawingData = AbstractWindowDesigner.createDrawingDataForFWindowForGlazingPackageFromArea(
                    this.glazingPackageWidth, this.glazingPackageHeight, catalogData.system, profileCompositionDistances,
                    this.windowSystemDefaults.value, this.windowSystemDefaults.value.sealColorInternalId,
                    this.windowSystemDefaults.value.sealColorExternalId, undefined);

                const position = this.position != undefined
                    ? this.position as Position
                    : PositionFactory.standaloneGlazingPackage(this.offerId);
                position.data = JSON.stringify(drawingData);
                position.windowSystemId = drawingData.windowSystemId;
                position.dimensions = `${this.glazingPackageWidth}x${this.glazingPackageHeight}`;
                position.otherInfo = `Pakiet szybowy o wymiarze ${this.glazingPackageWidth}x${this.glazingPackageHeight}`;
                position.quantity = this.glazingPackageQuantity;
                return position;
            }));
    }

    private reassignGlazing(): void {
        this.windowSystemDefaults.value.glass1id = this.glazing.glass1id;
        this.windowSystemDefaults.value.glass2id = this.glazing.glass2id;
        this.windowSystemDefaults.value.glass3id = this.glazing.glass3id;
        this.windowSystemDefaults.value.glass4id = this.glazing.glass4id;
        this.windowSystemDefaults.value.frame1id = this.glazing.frame1id;
        this.windowSystemDefaults.value.frame2id = this.glazing.frame2id;
        this.windowSystemDefaults.value.frame3id = this.glazing.frame3id;
        this.windowSystemDefaults.value.glazingGlassQn = this.glazing.glazingGlassQuantity;
        this.windowSystemDefaults.value.glazingCategoryId = this.glazingPackageCategoryId;
        this.windowSystemDefaults.value.glazingFrameCategoryId = this.glazingPackageFrameCatagoryId;
        this.windowSystemDefaults.value.glazingPackageId = this.glazingPackageId;
    }

    submit(): void {
        this.validateForm();
        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors) || this.containsUnavailableItems()) {
            return;
        }
        this.saveInProgress = true;
        const windowDesignerComponentRef = createComponent(WindowDesignerComponent, {
            environmentInjector: this.application.injector,
            elementInjector: this.injector
        });
        const windowDesigner = windowDesignerComponentRef.instance;
        windowDesigner.redrawPreviewMode = true;
        windowDesigner.visibilitySettings = standaloneGlazingPackageWindowDesignerVisibilitySettings;
        windowDesignerComponentRef.changeDetectorRef.detectChanges();
        this.createGlazingPackagePosition(this.selectedWindowSystem.id).pipe(mergeMap(position => {
            const catalogDataCache = new CachingWindowDesignerDataService(this.windowDesignerDataService);
            return windowDesigner.getSvgsForRedrawing(position.data, catalogDataCache)
                .pipe(mergeMap(svgs => this.positionService.saveItem(position, svgs)));
        })).subscribe({
            complete: () => {
                windowDesignerComponentRef.destroy();
                this.visible = false;
                this.saved.emit();
                this.changeDetector.markForCheck();
            },
            error: error => {
                windowDesignerComponentRef.destroy();
                this.saveInProgress = false;
                if (error instanceof HttpErrorResponse) {
                    this.validationErrors = this.errors.handle(error);
                } else {
                    this.errors.handleFE(error);
                }
                this.changeDetector.markForCheck();
            }
        });
    }

    private validateForm(): void {
        this.validationErrors = Object.assign({
            windowSystem: MultiValidator.of('error.offerPosition.glazingPackage.windowSystem')
                .withNotNullValidator().validate(this.selectedWindowSystem),
            glazingPackageQuantity: MultiValidator.of('error.offerPosition.glazingPackage.quantity')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 1000000)
                .validate(this.glazingPackageQuantity),
            glazingPackageWidth: MultiValidator.of('error.offerPosition.glazingPackage.width')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(this.maxDimensions.MIN_WIDTH, this.maxDimensions.MAX_WIDTH)
                .validate(this.glazingPackageWidth),
            glazingPackageHeight: MultiValidator.of('error.offerPosition.glazingPackage.height')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(this.maxDimensions.MIN_HEIGHT, this.maxDimensions.MAX_HEIGHT)
                .validate(this.glazingPackageHeight)
        }, new GlassSelectionValidator().validate(this.glazing), this.validatePredefinedGlazingPackage());
    }

    private getUsedGlazingIds(glazing: Glazing, field: 'glass' | 'frame'): number[] {
        if (!glazing) {
            return [];
        }
        const glasses: number[] = [];
        for (let i = 1; i <= 4; i++) {
            const itemId = glazing[field + i + 'id'];
            if (itemId) {
                glasses.push(itemId);
            }
        }
        return glasses;
    }

    private assingGlazingItems(glazing: Glazing, glazingPackage: {
        glazingCategoryId: number | undefined;
        glazingFrameCategoryId: number | undefined;
        glazingPackageId: number | undefined;
    }, windowSystem: WindowSystemInterface): void {
        forkJoin({
            glasses: this.glassService.getGlassesForWindowSystem(windowSystem.id, this.getUsedGlazingIds(glazing, 'glass')),
            frames: this.frameService.getDistanceFramesForWindowSystem(windowSystem.id, this.getUsedGlazingIds(glazing, 'frame')),
            graspGlazingCategories: this.graspGlazingCategoryService.getActiveItems(),
            graspDistanceFrameCategories: this.graspDistanceFrameCategoryService.getActiveItems(),
            graspGlazingPackages: this.graspGlazingPackageService.getActiveItems(windowSystem.id)
        }).subscribe(data => {
            this.glasses = data.glasses;
            this.frames = data.frames;
            this.glazing = glazing;
            this.glazingPackageCategoryId = glazingPackage.glazingCategoryId;
            this.glazingPackageFrameCatagoryId = glazingPackage.glazingFrameCategoryId;
            this.glazingPackageId = glazingPackage.glazingPackageId;
            this.glazingGlassNumber = GlazingHelper.getAvailableGlassCount(windowSystem, false);
            this.predefinedGlazingGlassQuantities = Array.from(new Set<number>(data.graspGlazingPackages
                .map(p => p.glazing.glazingGlassQuantity)))
                .sort((a, b) => a - b)
                .map(quantity => ({
                    label: `${quantity}`,
                    value: quantity,
                    available: true
                }));
            this.predefinedGlazingPackageCategories = data.graspGlazingCategories.data.map(ggc => ({
                labelKey: ggc.name,
                value: ggc.id,
                available: true
            }));
            this.predefinedGlazingPackageFrameCategories = data.graspDistanceFrameCategories.map(gdfc => ({
                labelKey: gdfc.name,
                value: gdfc.id,
                available: true
            }));
            this.allPredefinedGlazingPackages = data.graspGlazingPackages;
            this.filterPredefinedGlazingPackages();
            this.changeDetector.markForCheck();
        });
    }

    private containsUnavailableItems(): boolean {
        let unavailableItemsSelected = false;
        const selectedGlasses = [];
        for (let i = 1; i <= 4; i++) {
            if (this.glazing['glass' + i + 'id'] != null) {
                selectedGlasses.push(this.glazing['glass' + i + 'id']);
            }
        }
        const selectedFrames = [];
        for (let i = 1; i <= 3; i++) {
            if (this.glazing['frame' + i + 'id'] != null) {
                selectedFrames.push(this.glazing['frame' + i + 'id']);
            }
        }
        selectedGlasses.forEach(item => {
            if (this.glasses.some(glass => glass.id === item && !glass.active)) {
                unavailableItemsSelected = true;
            }
        });
        if (!unavailableItemsSelected) {
            selectedFrames.forEach(item => {
                if (this.frames.some(frame => frame.id === item && !frame.active)) {
                    unavailableItemsSelected = true;
                }
            });
        }
        return unavailableItemsSelected;
    }

    handleGlassQuantityChange(glassQuantity: number): void {
        this.glazing.glazingGlassQuantity = glassQuantity;
    }

    private filterPredefinedGlazingPackages(): void {
        if (this.glazing.glazingGlassQuantity && this.glazingPackageCategoryId && this.glazingPackageFrameCatagoryId) {
            const allPackages = this.allPredefinedGlazingPackages.filter(gp => gp.glazingCategoryId === this.glazingPackageCategoryId
                && gp.frameCategoryId === this.glazingPackageFrameCatagoryId
                && gp.glazing.glazingGlassQuantity === this.glazing.glazingGlassQuantity);
            this.predefinedGlazingPackages = allPackages.map(ggp => ({
                labelKey: ggp.name,
                value: ggp.id,
                available: true
            }));
            this.changeDetector.markForCheck();
        }
    }

    handlePredefinedGlazingPackageQuantityChange(quantity: number): void {
        this.glazing.glazingGlassQuantity = quantity;
        this.filterPredefinedGlazingPackages();
    }

    handlePredefinedGlazingPackageCategoryChange(glazingPackageCategoryId: number): void {
        this.glazingPackageCategoryId = glazingPackageCategoryId;
        this.filterPredefinedGlazingPackages();
    }

    handlePredefinedGlazingPackageFrameCategoryChange(glazingPackageFrameCatagoryId: number): void {
        this.glazingPackageFrameCatagoryId = glazingPackageFrameCatagoryId;
        this.filterPredefinedGlazingPackages();
    }

    handlePredefinedGlazingPackageChange(glazingPackageId: number): void {
        this.glazingPackageId = glazingPackageId;
    }

    private validatePredefinedGlazingPackage(): ValidationErrors {
        const validationErrors = {};
        if (this.usesPredefinedGlazingPackages) {
            validationErrors['quantity'] = this.glazing.glazingGlassQuantity == undefined
                ? 'error.offerPosition.bulkChange.selection.not_empty'
                : undefined;
            validationErrors['category'] = this.glazingPackageCategoryId == undefined
                ? 'error.offerPosition.bulkChange.selection.not_empty'
                : undefined;
            validationErrors['frameCategory'] = this.glazingPackageFrameCatagoryId == undefined
                ? 'error.offerPosition.bulkChange.selection.not_empty'
                : undefined;
            validationErrors['glazingPackage'] = this.glazingPackageId == undefined
                ? 'error.offerPosition.bulkChange.selection.not_empty'
                : undefined;
        }
        return validationErrors;
    }
}
