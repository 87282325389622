import {Address} from "../../../common/address";
import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class VenskaData {

    name: string;
    phoneNumber: string;
    email: string;
    correspondenceAddress: Address;
    deliveryAddress: Address;
    vatSell: number;
    vatBuy: number;
    defaultOfferDurationDays: number;

    constructor() {
        this.deliveryAddress = new Address();
        this.correspondenceAddress = new Address();
    }

    static fromJSON(jsonObject: any): VenskaData {
        const venskaData = new VenskaData();
        venskaData.name = jsonObject.name;
        venskaData.phoneNumber = jsonObject.phoneNumber;
        venskaData.email = jsonObject.email;
        venskaData.correspondenceAddress = Address.fromJSON(jsonObject.correspondenceAddress);
        venskaData.deliveryAddress = Address.fromJSON(jsonObject.deliveryAddress);
        venskaData.vatSell = jsonObject.vatSell;
        venskaData.vatBuy = jsonObject.vatBuy;
        venskaData.defaultOfferDurationDays = jsonObject.defaultOfferDurationDays;
        return venskaData;
    }
}
