import {GateControl} from "../../../../gate-painter/enums/gate-control.enum";

export class GateSystemDefaults {
    width: number;
    height: number;
    lintelHeight: number;
    railSystemId: number;
    coreColorId: number;
    wall1: number;
    wall2: number;
    externalColorId: number;
    internalColorId: number;
    gatePanelTypeId: number;
    control: GateControl;
    sidebarAddons: { [addonCategorySymbol: string]: number } = {};

    static copy(defaults: GateSystemDefaults): GateSystemDefaults {
        const clone = new GateSystemDefaults();
        clone.width = defaults.width;
        clone.height = defaults.height;
        clone.lintelHeight = defaults.lintelHeight;
        clone.railSystemId = defaults.railSystemId;
        clone.coreColorId = defaults.coreColorId;
        clone.wall1 = defaults.wall1;
        clone.wall2 = defaults.wall2;
        clone.externalColorId = defaults.externalColorId;
        clone.internalColorId = defaults.internalColorId;
        clone.gatePanelTypeId = defaults.gatePanelTypeId;
        clone.control = defaults.control;
        clone.sidebarAddons = Object.assign({}, defaults.sidebarAddons);
        return clone;
    }
}
