import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class PromotionInformation implements CrudItem {

    id: number;
    validFrom: Date;
    showExpiresSoonDate: Date;
    validTo: Date;
    active: boolean;
    text = new MultilanguageField();

    static fromJSON(jsonObject: any): PromotionInformation {
        const promotionInformation = new PromotionInformation();
        promotionInformation.id = jsonObject.id;
        if (jsonObject.validFrom != undefined) {
            promotionInformation.validFrom = new Date(jsonObject.validFrom);
        }
        if (jsonObject.showExpiresSoonDate != undefined) {
            promotionInformation.showExpiresSoonDate = new Date(jsonObject.showExpiresSoonDate);
        }
        if (jsonObject.validTo != undefined) {
            promotionInformation.validTo = new Date(jsonObject.validTo);
        }
        promotionInformation.active = jsonObject.active;
        if (jsonObject.text != undefined) {
            promotionInformation.text = MultilanguageField.fromJSON(jsonObject.text);
        }
        return promotionInformation;
    }
}
