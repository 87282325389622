export enum DocumentType {
    ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
    RETAIL_OFFER = 'RETAIL_OFFER',
    OFFER = 'OFFER',
    GLAMOUR_OFFER = 'GLAMOUR_OFFER',
    GLAMOUR_UPSELLING = 'GLAMOUR_UPSELLING',
    GLAMOUR_ROT = 'GLAMOUR_ROT',
    GLAMOUR_ENTRANCE_DOOR_POSITION = 'GLAMOUR_ENTRANCE_DOOR_POSITION',
    GLAMOUR_GATE_POSITION = 'GLAMOUR_GATE_POSITION',
    GLAMOUR_WINDOW_POSITION = 'GLAMOUR_WINDOW_POSITION',
    GLAMOUR_TERRACE_WINDOW_POSITION = 'GLAMOUR_TERRACE_WINDOW_POSITION',
    GLAMOUR_WINDOW_BOOKLET = 'GLAMOUR_WINDOW_BOOKLET',
    GLAMOUR_ROOF_WINDOW_POSITION = 'GLAMOUR_ROOF_WINDOW_POSITION',
    GLAMOUR_ROOF_WINDOW_BOOKLET = 'GLAMOUR_ROOF_WINDOW_BOOKLET',
    GLAMOUR_ADDON_POSITIONS = 'GLAMOUR_ADDON_POSITIONS',
    GLAMOUR_CONFIGURABLE_ADDON_POSITIONS = 'GLAMOUR_CONFIGURABLE_ADDON_POSITIONS',
    GLAMOUR_CONFIGURABLE_ADDON_BOOKLET = 'GLAMOUR_CONFIGURABLE_ADDON_BOOKLET',
    GLAMOUR_OFFER_WEBSHOP = 'GLAMOUR_OFFER_WEBSHOP',
    DISTRIBUTION_OFFER = 'DISTRIBUTION_OFFER',
    ORDER = 'ORDER',
    ORDER_WITH_FIXED_EXCHANGE_RATE = 'ORDER_WITH_FIXED_EXCHANGE_RATE',
    PRODUCTION_ORDER = 'PRODUCTION_ORDER',
    CONJUNCTION = 'CONJUNCTION',
    COMPLAINT = 'COMPLAINT',
    DELIVERY_LIST = 'DELIVERY_LIST',
    CHECKLIST = 'CHECKLIST',
    TECHNICAL_DRAWING = 'TECHNICAL_DRAWING',
    GLAMOUR_DEFAULT_ANNOTATIONS = 'GLAMOUR_DEFAULT_ANNOTATIONS',
    MACHINE_READABLE_EXPORT = 'MACHINE_READABLE_EXPORT',
    PREAUTHENTICATED_OFFER_LINK = 'PREAUTHENTICATED_OFFER_LINK'
}
