import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    Directive,
    ElementRef, EventEmitter,
    Host,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import * as jQuery from 'jquery';
import 'slick-carousel';
import {DocumentFragmentService} from "./document-fragment.service";

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.css']
})
export class SliderComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    @Input()
    infinite?: boolean;

    @Input()
    maxSlidesToShow?: number;

    @Input()
    slidesToScroll?: number;

    @Input()
    initialSlide?: number;

    @Input()
    slideWidthForResponsiveMode?: number;

    @Input()
    autoplay?: { enabled: true, speed: number };

    @Input()
    showArrows = true;

    @Input()
    variableWidth?: boolean;

    @Output()
    readonly slideIndexAfterChange = new EventEmitter<any>();

    private readonly items: SliderSlideDirective[] = [];
    private readonly addedItems: SliderSlideDirective[] = [];
    private readonly removedItems: SliderSlideDirective[] = [];

    constructor(private sliderElement: ElementRef<HTMLElement>,
                private documentFragmentService: DocumentFragmentService,
                private zone: NgZone) {
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            const slick = jQuery(this.sliderElement.nativeElement);
            const addLastActiveClass = () => {
                slick.find('.slick-active').last().addClass('slick-active-last');
            };
            const removeLastActiveClass = () => {
                slick.find('.slick-active-last').removeClass('slick-active-last');
            };
            const emitChange = (slide: any) => {
                this.slideIndexAfterChange.emit(slide);
            };
            slick.one('init', addLastActiveClass);
            slick.on('beforeChange', removeLastActiveClass);
            slick.on('afterChange', (slickO: any, currentSlide) => {
                removeLastActiveClass();
                addLastActiveClass();
                this.zone.runTask(() => emitChange(currentSlide.currentSlide));
            });
            slick.slick({
                arrows: this.showArrows,
                autoplay: this.autoplay != undefined ? this.autoplay.enabled : undefined,
                autoplaySpeed: this.autoplay != undefined ? this.autoplay.speed : undefined,
                focusOnChange: false,
                infinite: this.infinite,
                nextArrow: this.createSliderNavigationButton('next'),
                prevArrow: this.createSliderNavigationButton('prev'),
                respondTo: 'slider',
                responsive: this.createResponsiveBreakpoints(),
                rows: 0,
                slidesToScroll: this.slidesToScroll == undefined ? this.maxSlidesToShow : this.slidesToScroll,
                initialSlide: this.initialSlide == undefined ? 0 : Math.max(this.initialSlide, 0),
                slidesToShow: this.maxSlidesToShow,
                variableWidth: this.variableWidth,
            });
            let draggable = slick.find('.slick-list');
            // dragging start
            draggable.on('touchstart.app-slider mousedown.app-slider', removeLastActiveClass);
            // dragging end
            draggable.on('touchend.app-slider mouseup.app-slider touchcancel.app-slider mouseleave.app-slider', addLastActiveClass);
        });
    }

    ngAfterViewChecked(): void {
        const jqElement = jQuery(this.sliderElement.nativeElement);
        const changed = this.addedItems.length > 0 || this.removedItems.length > 0;
        if (this.addedItems.length > 0) {
            let fragment = this.documentFragmentService.createDocumentFragment('').get();
            for (let addedItem of this.addedItems) {
                fragment.append(addedItem.element.nativeElement);
                this.items.push(addedItem);
            }
            this.zone.runOutsideAngular(() => jqElement.slick('slickAdd', fragment));
            this.addedItems.length = 0;
        }
        for (let removedItem of this.removedItems) {
            const index = this.items.indexOf(removedItem);
            this.zone.runOutsideAngular(() => jqElement.slick('slickRemove', index));
            this.items.splice(index, 1);
        }
        this.removedItems.length = 0;
        if (changed) {
            // refresh cloned slides with changes to embedded components from change detection
            this.zone.runOutsideAngular(() => jqElement.slick('slickSetOption', {}, true));
        }
    }

    ngOnDestroy(): void {
        this.zone.runOutsideAngular(() => {
            let slick = jQuery(this.sliderElement.nativeElement);
            let draggable = slick.find('.slick-list');
            draggable.off('touchstart.app-slider mousedown.app-slider touchend.app-slider mouseup.app-slider touchcancel.app-slider mouseleave.app-slider');
            slick.off('afterChange');
            slick.slick('unslick');
        });
    }

    addItem(slide: SliderSlideDirective): void {
        this.addedItems.push(slide);
    }

    removeItem(slide: SliderSlideDirective): void {
        this.removedItems.push(slide);
    }

    private createSliderNavigationButton(type: 'prev' | 'next') {
        const btn = document.createElement('button');
        btn.classList.add('slider-scroll-button', `slick-${type}`);
        btn.type = 'button';
        // hack for slick - it passes the nextArrow/prevArrow config elements to regex.test() before appending to document
        btn.toString = function() {
            return (this as HTMLButtonElement).outerHTML;
        };
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href',
            `assets/stylable_icons.svg#arrow_${type === 'prev' ? 'left' : 'right'}`);
        svg.appendChild(use);
        btn.appendChild(svg);
        return btn;
    }

    createResponsiveBreakpoints(): object | undefined {
        if (this.maxSlidesToShow == undefined || this.slideWidthForResponsiveMode == undefined) {
            return undefined;
        }
        const result: { breakpoint: number, settings: { slidesToScroll: number, slidesToShow: number } }[] = [];
        const slideCount = this.maxSlidesToShow;
        for (let i = 0; i < slideCount; ++i) {
            result.push({
                breakpoint: (i + 1) * this.slideWidthForResponsiveMode,
                settings: {
                    slidesToScroll: Math.max(i, 1),
                    slidesToShow: Math.max(i, 1)
                }
            });
        }
        return result;
    }
}

@Directive({
    selector: '[appSliderSlide]'
})
export class SliderSlideDirective implements OnInit, OnDestroy {

    constructor(public element: ElementRef<HTMLElement>,
                @Host() private slider: SliderComponent) {
    }

    ngOnInit(): void {
        this.slider.addItem(this);
    }

    ngOnDestroy(): void {
        this.slider.removeItem(this);
    }
}
