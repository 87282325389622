<div class="list-content settings">
    <div *ngIf="componentType !== 'userActivityReport'" class="table-header">
        <div class="title">{{ formHeaderKey | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-60vw">
            <div class="new-form-row">
                <h3>{{ "MAIL_SETTINGS.FORM.TITLE" | translate }}</h3>
            </div>
            <ng-container *ngIf="!isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="host" [(ngModel)]="mailSettings.host"
                                    label="{{ 'MAIL_SETTINGS.FORM.HOST' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['host']"></app-input-text>
                </div>
                <div class="new-form-row">
                    <app-input-number inputId="port" [(ngModel)]="mailSettings.port"
                                      label="{{ 'MAIL_SETTINGS.FORM.PORT' | translate }}" [min]="1" [max]="9999"
                                      [allowOnlyIntegers]="true" [required]="true"
                                      [(validationMessageKey)]="validationErrors['port']"></app-input-number>
                </div>
            </ng-container>
            <div class="new-form-row">
                <app-radio-button inputId="sslMode" label="{{ 'MAIL_SETTINGS.FORM.SSL_MODE' | translate }}"
                                  [(validationMessageKey)]="validationErrors['sslMode']" [options]="sslModes | async"
                                  [required]="true" [(ngModel)]="mailSettings.sslMode"></app-radio-button>
            </div>
            <ng-container *ngIf="!isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="username" [(ngModel)]="mailSettings.username"
                                    label="{{ 'MAIL_SETTINGS.FORM.USERNAME' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['username']"></app-input-text>
                </div>
                <div class="new-form-row">
                    <app-input-text inputId="password" [(ngModel)]="mailSettings.password"
                                    label="{{ 'MAIL_SETTINGS.FORM.PASSWORD' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['password']"></app-input-text>
                </div>
            </ng-container>
            <ng-container *ngIf="isAzure">
                <div class="new-form-row">
                    <app-input-text inputId="host" [(ngModel)]="mailSettings.host"
                                    label="{{ 'MAIL_SETTINGS.FORM.TENANT_ID' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['host']"></app-input-text>
                </div>
                <div class="new-form-row">
                    <app-input-text inputId="username" [(ngModel)]="mailSettings.username"
                                    label="{{ 'MAIL_SETTINGS.FORM.CLIENT_ID' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['username']"></app-input-text>
                </div>
                <div class="new-form-row">
                    <app-input-text inputId="password" [(ngModel)]="mailSettings.password"
                                    label="{{ 'MAIL_SETTINGS.FORM.CLIENT_SECRET' | translate }}" [maxlength]="255"
                                    [required]="true"
                                    [(validationMessageKey)]="validationErrors['password']"></app-input-text>
                </div>
            </ng-container>
            <div class="new-form-row">
                <app-input-text inputId="senderAddress" [(ngModel)]="mailSettings.senderAddress"
                                label="{{ 'MAIL_SETTINGS.FORM.SENDER_ADDRESS' | translate }}" [maxlength]="255"
                                [required]="true"
                                [(validationMessageKey)]="validationErrors['senderAddress']"></app-input-text>
            </div>
            <div *ngIf="showBccField" class="new-form-row">
                <app-input-text inputId="bcc" [(ngModel)]="mailSettings.bccAddresses"
                                label="{{ 'MAIL_SETTINGS.FORM.BCC_ADDRESSES' | translate }}" [maxlength]="255"
                                [required]="false"
                                [(validationMessageKey)]="validationErrors['bccAddresses']"></app-input-text>
            </div>
            <p-tabView class="full-width-container">
                <p-tabPanel *ngFor="let lang of supportedLanguages" header="{{ lang.name | translate }}"
                            [headerStyleClass]="validationErrors['mailSubject[' + lang.code + ']'] != undefined || validationErrors['mailBody[' + lang.code + ']'] != undefined ? 'errors-on-tab': ''">
                    <div class="new-form-row">
                        <app-input-text [inputId]="'mailSubject_' + lang.code" [(ngModel)]="mailSubject[lang.code]"
                                        label="{{ 'MAIL_SETTINGS.FORM.MAIL_SUBJECT' | translate }}" [maxlength]="100"
                                        [required]="true"
                                        [(validationMessageKey)]="validationErrors['mailSubject[' + lang.code + ']']"></app-input-text>
                    </div>
                    <div class="new-form-row">
                        <div *ngIf="componentType === 'offerStatusChange' || componentType === 'userActivityReport'">
                            <app-text-area [inputId]="'mailBody' + lang.code" [(ngModel)]="mailBody[lang.code]"
                                           label="{{ 'MAIL_SETTINGS.FORM.MAIL_BODY' | translate }}"
                                           [(validationMessageKey)]="validationErrors['mailBody[' + lang.code + ']']"></app-text-area>
                        </div>
                        <div *ngIf="componentType === 'passwordReset'" class="new-form-field">
                            <div class="new-form-field-label-container">
                                <div><label>{{'MAIL_SETTINGS.FORM.MAIL_BODY' | translate}}</label></div>
                                <div *ngIf="validationErrors['mailBody[' + lang.code + ']' ] != undefined"
                                     class="new-form-field-label-error-spacer"></div>
                            </div>
                            <div class="new-form-field-input-container">
                                <p-editor [(ngModel)]="mailBody[lang.code]"
                                          [style]="{'height':'350px', 'border': '1px solid #E8E8E8', 'border-radius': '2px'}"></p-editor>
                                <div *ngIf="validationErrors['mailBody[' + lang.code + ']' ] != undefined"
                                     class="new-form-field-error-message">
                                    {{ validationErrors['mailBody[' + lang.code + ']'] | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <div *ngIf="componentType !== 'userActivityReport'" class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button buttonId="saveMailSettingsButton" label="{{ 'GENERAL.SAVE' | translate }}" icon="check"
                               type="main-action" [size]="40" (onClick)="save()"></app-simple-button>
        </div>
    </div>
</div>
