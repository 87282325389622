import {AddonCategoryEnum} from "../../../../window-designer/enums/AddonCategoryEnum";
import {Addon} from "./addon";

export class AddonCustomFieldsUtil {

    public static showCustomValueField(addon: Addon): boolean {
        switch (addon.category) {
            case AddonCategoryEnum.CONNECTORS_EXTENDERS_ALUMINIUM:
            case AddonCategoryEnum.CONNECTORS_EXTENDERS_ALUMINIUM_WOOD_MIX:
            case AddonCategoryEnum.CONNECTORS_EXTENDERS_PCV:
            case AddonCategoryEnum.CONNECTORS_EXTENDERS_WOOD:
                return true;
            default:
                return false;
        }
    }
}
