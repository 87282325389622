import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ScheduledJob} from './scheduled-job';

@Injectable()
export class ScheduledJobService {

    private static readonly SERVICE_PATH = 'scheduledJob';

    constructor(private http: HttpClient) {
    }

    public getJobs(): Observable<ScheduledJob[]> {
        return this.http.get<object[]>(`${ScheduledJobService.SERVICE_PATH}/getJobs`).pipe(
            map(response => response.map(ScheduledJob.fromJSON)));
    }
}
