import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {ValidationErrors} from '../../../../common/validation-errors';
import {DeliveryList} from "../delivery-list";

@Component({
    selector: 'app-delivery-list-subsystem-selection-form',
    templateUrl: './delivery-list-subsystem-selection-form.component.html',
    styleUrls: ['./delivery-list-subsystem-selection-form.component.css']
})
export class DeliveryListSubsystemSelectionFormComponent {

    @Input()
    deliveryList: DeliveryList;

    @Input()
    subsystems: SelectItem[];

    @Input()
    validationErrors: ValidationErrors = {};

    @Output()
    onDblClick = new EventEmitter<any>();

    selectedSubsystemId: number;

    handleSubsystemSelectionChange(subsystemId: number): void {
        this.selectedSubsystemId = subsystemId;
        this.deliveryList.subsystemId = subsystemId;
    }

    handleDblClick(event: any): void {
        this.onDblClick.emit(event);
    }
}
