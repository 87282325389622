<app-wizard-dialog #wizard [header]="getDialogHeader() | translate" (onCancel)="cancel()" (onComplete)="submit()"
                   dialogStyleClass="width-60vw-no-padding" [contentStyleClass]="getDialogStyle()">
    <app-wizard-step label="{{ 'CLIENT-DETAILS.FORM.GENERAL' | translate }}" [id]="STEPS.GENERAL"
                     [validate]="generalStepValidator">
        <div [ngClass]="{'new-form-600': !canEdit(), 'new-form-60vw': canEdit()}">
            <div class="new-form-row">
                <app-input-text inputId="name" label="{{ 'CLIENT-GROUP.FORM.NAME' | translate }}"
                                [(validationMessageKey)]="validationErrors['name']" [maxlength]="120" [required]="true"
                                [disabled]="!canEdit()" [(ngModel)]="clientGroup.name"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="defaultOfferDurationDays"
                                  label="{{ 'CLIENT-GROUP.FORM.DEFAULT_OFFER_DURATION_DAYS' | translate }}"
                                  [(validationMessageKey)]="validationErrors['defaultOfferDurationDays']" [min]="1"
                                  [max]="31" [step]="1" [disabled]="!canEdit()"
                                  [(ngModel)]="clientGroup.defaultOfferDurationDays"></app-input-number>
            </div>
            <app-simple-profit-margin-source *ngIf="simpleProfitMarginSource" [profitMargins]="simpleProfitMarginSource"
                                             [suppliers]="suppliers" [validationErrors]="validationErrors"
                                             validationPrefix="simpleProfitMarginSource." [disabled]="!canEdit()"
                                             (onSetAllClicked)="clientGroup.bulkAddonProfitMargin = $event"></app-simple-profit-margin-source>
            <div class="new-form-row">
                <app-input-text inputId="bulkAddonProfitMargin"
                                label="{{ 'CLIENT-GROUP.FORM.BULK_ADDON_PROFIT_MARGIN' | translate }}"
                                [(validationMessageKey)]="validationErrors['bulkAddonProfitMargin']" [maxlength]="250"
                                [disabled]="!canEdit()"
                                [(ngModel)]="clientGroup.bulkAddonProfitMargin"></app-input-text>
            </div>
            <div class="new-form-row" *ngIf="!permissions.isHandlowiec()">
                <app-checkbox inputId="enableSimpleProfitMarginSourceCheckbox"
                              label="{{ 'CLIENT-GROUP.FORM.USE_SIMPLE_PROFIT_MARGINS' | translate }}"
                              [ngModel]="simpleProfitMarginSource != undefined"
                              (onClick)="handleEnableSimpleProfitMarginSourceBeforeChange($event)"
                              (ngModelChange)="handleEnableSimpleProfitMarginSourceChange($event)"></app-checkbox>
            </div>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="canEdit() && !simpleProfitMarginSource" label="{{ 'CLIENT-GROUP.FORM.PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.SYSTEM_MARGINS">
        <app-profit-margin #childTable entityType="clientGroup" [clientGroupId]="clientGroup.id"
                           [userCanEdit]="true" [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step *ngIf="canEdit() && !simpleProfitMarginSource" label="{{ 'CLIENT-GROUP.FORM.ROOF_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.ROOF_SYSTEM_MARGINS">
        <app-profit-margin #childTable entityType="clientGroup" [clientGroupId]="clientGroup.id"
                           [userCanEdit]="true" [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step *ngIf="canEdit() && !simpleProfitMarginSource" label="{{ 'CLIENT-GROUP.FORM.ENTRANCE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.ENTRANCE_SYSTEM_MARGINS">
        <app-profit-margin #childTable entityType="clientGroup" [clientGroupId]="clientGroup.id"
                           [userCanEdit]="true" [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step *ngIf="canEdit() && !simpleProfitMarginSource" label="{{ 'CLIENT-GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable entityType="clientGroup"
                           class="app-client-group-addon-profit-margin-config-addons"
                           [clientGroupId]="clientGroup.id" [target]="possibleTargets.CONFIG"
                           [userCanEdit]="true"
                           [globalMarginUpdateButtonId]="clientGroupConfAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="clientGroupConfAddGlobalMarginInputId"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step *ngIf="canEdit() && !simpleProfitMarginSource" label="{{ 'CLIENT-GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.GATES_MARGINS">
        <app-profit-margin #childTable entityType="clientGroup" [clientGroupId]="clientGroup.id" [target]="possibleTargets.GATE"
                           [userCanEdit]="true" [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="gatesGlobalMarginInputId"></app-profit-margin>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveButton" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" [disabled]="!canEdit()" (onClick)="wizard.submit()"></app-simple-button>
            <app-simple-button *ngIf="canDeleteGroup"
                               buttonId="deleteButton" label="{{ 'GENERAL.DELETE' | translate}}" [inlineLabel]="false"
                               icon="delete" type="delete" [size]="40" (onClick)="button_delete()"></app-simple-button>
            <app-simple-button buttonId="cancelButton" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="wizard.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
<app-confirm-action-dialog *ngIf="displayYesNoDialog" (onCancel)="showYesNoDialog(false)"
                           (onConfirmed)="showYesNoDialog(false);delete()"
                           [question]="'GENERAL.DELETE_CONFIRMATION' | translate"
                           [header]="'GENERAL.DIALOG.DELETING' | translate"></app-confirm-action-dialog>
<app-confirm-action-dialog *ngIf="showClearingSimpleProfitMarginsWarning"
                           header="{{ 'SIMPLE_PROFIT_MARGINS_SOURCE.CLEAR_WARNING_HEADER' }}"
                           question="{{ 'SIMPLE_PROFIT_MARGINS_SOURCE.CLEAR_WARNING_BODY' }}"
                           confirmButtonLabel="{{ 'GENERAL.OK' | translate }}"
                           discardButtonLabel="{{ 'GENERAL.CANCEL' | translate }}"
                           dialogId="showClearingSimpleProfitMarginsWarningDialog"
                           (onConfirmed)="handleClearingSimpleProfitMarginsWarningConfirm()"
                           (onCancel)="showClearingSimpleProfitMarginsWarning = false"></app-confirm-action-dialog>
