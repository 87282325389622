<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" [(ngModel)]="item.name" [required]="true"
                        label="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.NAME' | translate }}"
                        [maxlength]="100" [(validationMessageKey)]="validationErrors['name']"></app-input-text>
    </div>

    <div class="new-form-row">
        <app-select inputId="documentType" label="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.DOCUMENT_TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors['documentType']" [options]="documentTypeOptions"
                    [ngModel]="item.documentType" (ngModelChange)="onDocumentTypeChange($event)" [required]="true"></app-select>
    </div>

    <div class="new-form-row">
        <app-checkbox inputId="active"
                      label="{{ 'SEAL.FORM.ACTIVE' | translate }}"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div class="new-form-row">
        <app-file-upload inputId="file" label="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.SELECT_FILE_UPLOAD' | translate }}"
                         [(validationMessageKey)]="validationErrors['file']" [required]="true"
                         addButtonLabel="SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.SELECT_FILE_UPLOAD"
                         changeButtonLabel="SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.CHANGE_FILE_UPLOAD" [maxSize]="7000000"
                         [image]="false" accept=".doc,.docx" [ngModel]="file"
                         (ngModelChange)="changeFile($event)"></app-file-upload>
    </div>

    <p-accordion>
        <p-accordionTab *ngIf="item.documentType !== documentType.PRODUCTION_ORDER"
                        header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.LANGUAGES' | translate }}"
                        [selected]="true" [ngClass]="{'accordion-header-with-errors': validationErrors['languages']}">
            <p-listbox [options]="languageOptions"
                       [(ngModel)]="item.languages"
                       [disabled]="false"
                       [multiple]="true"></p-listbox>
        </p-accordionTab>
        <div *ngIf="validationErrors['languages'] != undefined" class="new-form-field-label-error-spacer"></div>
        <div *ngIf="validationErrors['languages'] != undefined" class="new-form-field-error-message">{{
            validationErrors['languages'] | translate }}
        </div>

        <p-accordionTab header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.DOCUMENT_TEMPLATE_TYPE' | translate }}" [selected]="false">
            <p-listbox [options]="documentTemplateTypeOptions"
                       [(ngModel)]="item.documentTemplateTypes"
                       [disabled]="false"
                       [multiple]="true"></p-listbox>
        </p-accordionTab>

        <p-accordionTab *ngIf="item.documentType === documentType.PRODUCTION_ORDER"
                        header="{{ 'SETTINGS.SECTION.DOCUMENT_TEMPLATES.FORM.SUPPLIERS' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['suppliersIds']}">
            <p-listbox [options]="suppliersOptions"
                       [(ngModel)]="item.suppliersIds"
                       [disabled]="false"
                       [multiple]="true"></p-listbox>
        </p-accordionTab>
        <div *ngIf="validationErrors['suppliersIds'] != undefined" class="new-form-field-label-error-spacer"></div>
        <div *ngIf="validationErrors['suppliersIds'] != undefined" class="new-form-field-error-message">{{
            validationErrors['suppliersIds'] | translate }}
        </div>
    </p-accordion>
</div>
