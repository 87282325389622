import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {OtherFilling} from './otherFilling';
import {OtherFillingList} from './otherFillingList';

@Injectable()
export class OtherFillingService extends SingleSystemCheckboxCrudService<OtherFilling> {

    private static API_URL = 'otherFillings';

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string, sortOrder: number): Observable<OtherFillingList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<OtherFillingList>(OtherFillingService.API_URL, {params: params});
    }

    addItem(filling: OtherFilling): Observable<number> {
        return this.http.post(OtherFillingService.API_URL, filling, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(OtherFillingService.API_URL, response)));
    }

    editItem(fillingId: number, filling: OtherFilling): Observable<number> {
        return this.http.put(`${OtherFillingService.API_URL}/${fillingId}`, filling).pipe(map(() => fillingId));
    }

    getItem(fillingId: number): Observable<OtherFilling> {
        return this.http.get<OtherFilling>(`${OtherFillingService.API_URL}/${fillingId}`);
    }

    copy(id: number, item: OtherFilling): Observable<number> {
        return this.http.post(`${OtherFillingService.API_URL}/${id}/copy`, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(OtherFillingService.API_URL, response)));
    }

    validate(item: OtherFilling): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${OtherFillingService.API_URL}/validate`, item));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${OtherFillingService.API_URL}/names`, {params: params});
    }
}
