import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Permissions} from '../../../../auth/permission.service';
import {MessageSeverity, PositionMessage} from '../../../offer/offers/message';
import {PricingTableComponent, PricingTableDataRow} from '../../pricing-table/pricing-table.component';
import {SheetTableComponent} from "../../sheet-table/sheet-table.component";
import {PriceTableService} from '../price-table.service';
import {PriceTable} from '../priceTable';
import {PriceTableItem} from '../priceTableItem';
import {Type2WindowSystem} from '../type2windowSystem';
import {Material2ConfigSystem} from "../material2ConfigSystem";

@Component({
    selector: 'app-window-pricingtable',
    templateUrl: './window-pricingtable.component.html',
    styleUrls: ['./window-pricingtable.component.css', '../../../shared-styles.css'],
    providers: [PriceTableService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowPricingtableComponent extends PricingTableComponent implements OnInit, OnChanges {

    @Input() tableInfo: Type2WindowSystem | Material2ConfigSystem;
    @Input() priceTable: PriceTable;
    @Input() selectedPricelistIsFuture: boolean;
    @Input() canDownloadExternalPriceTable: boolean;
    @Input() isNew: boolean;
    @Output() readonly downloadExternalPriceTable = new EventEmitter<{ code: string, future: boolean }>();

    @ViewChild(SheetTableComponent, {static: true}) sheetTable: SheetTableComponent;

    cellStyleClasses: { col: number, row: number, classes: string[] }[];

    constructor(permissions: Permissions,
                translate: TranslateService,
                changeDetector: ChangeDetectorRef) {
        super(permissions, translate, changeDetector);
    }

    ngOnInit(): void {
        if (!this.selectedPricelistIsFuture) {
            this.priceTable.markOutdatedPricesWhenActivated = true;
        }
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const priceTableChange = changes['priceTable'];
        if (priceTableChange != undefined) {
            this.init();
        }
    }

    protected prepareData(): PricingTableDataRow[] {
        const oldData = this.data;
        let data = (this.priceTable.items || []).map(dataRow => {
            const priceTableItem = {...dataRow};
            priceTableItem.height += this.priceTable.externalPriceTableSizeAdjustment;
            priceTableItem.width += this.priceTable.externalPriceTableSizeAdjustment;
            return priceTableItem;
        });

        let widthsSorted = Array.from(new Set(data.map(item => item.width)));
        widthsSorted.sort((a, b) => a - b);

        let heightsSorted = Array.from(new Set(data.map(item => item.height)));
        heightsSorted.sort((a, b) => a - b);

        let x = [0, ...widthsSorted];
        let y = heightsSorted.map(height => {
            let values: number[] = [];
            data.filter(item => item.height === height).forEach(item => {
                let widthIndex = widthsSorted.findIndex(val => val === item.width);
                values[widthIndex] = item.value;
            });

            return [height, ...values];
        });
        const newData = [x, ...y].map(arr => new PricingTableDataRow(arr));
        const addedWidths: string[] = [];
        const addedHeights: string[] = [];
        this.cellStyleClasses = [];
        if (oldData.length > 0) {
            for (let row = 0; row < newData.length; ++row) {
                let newRow = newData[row];
                let oldRow = oldData.find(or => or[0] === newRow[0]);
                if (oldRow == undefined) {
                    addedHeights.push(newRow[0]);
                    for (let col = 1; col < Object.keys(newRow).length; ++col) {
                        this.cellStyleClasses.push({col: col, row: row, classes: ['window-pricingtable-added-value']});
                    }
                } else if (row === 0) {
                    for (let [col, newColumn] of Object.entries(newRow)) {
                        if (Object.values(oldData[0]).findIndex(oldColumn => oldColumn === newColumn) < 0) {
                            addedWidths.push(newColumn);
                            for (let j = 1; j < newData.length; ++j) {
                                this.cellStyleClasses.push({col: +col, row: j, classes: ['window-pricingtable-added-value']});
                            }
                        }
                    }
                } else {
                    for (let col = 1; col < Object.keys(newData[0]).length; ++col) {
                        // find old value
                        const oldColumnIndex = Object.values(oldData[0]).findIndex(oldColumn => oldColumn === newData[0][col]);
                        if (oldRow[oldColumnIndex] !== newRow[col]) {
                            this.cellStyleClasses.push({col: col, row: row, classes: ['window-pricingtable-changed-value']});
                        }
                    }
                }
            }
        }
        return newData;
    }

    onSubmit(): PriceTable {
        let widths = this.data[0];
        let heights = this.data.map(row => row[0]);

        let items: PriceTableItem[] = [];
        this.data.forEach((row, rowIndex) => {
            if (rowIndex === 0) {
                return;
            }
            if (!(row != null && row[0] != null && Object.keys(row).length > 1)) {
                return;
            }
            let rowValues = Object.keys(row).map(cellIndex => new PriceTableItem(widths[cellIndex], heights[rowIndex],
                row[cellIndex] ? row[cellIndex].toString().replace(',', '.') : row[cellIndex]));
            rowValues.shift(); // skip row header cell
            items.push(...rowValues);
        });

        let sanitizedItems = items.filter(item => item.isValid());

        if (!this.isTableFilled(widths, heights, sanitizedItems)) {
            const errorCode = "error.pricetableItemDto.table.missingElement";
            this.error = new PositionMessage();
            this.error.messageCode = errorCode;
            this.error.severity = MessageSeverity.ERROR;
            this.changeDetector.markForCheck();
            return undefined;
        }

        return {
            ...this.priceTable,
            items: sanitizedItems
        };
    }

    isTableFilled(widths, heights, sanitizedItems): boolean {
        if (widths === undefined || heights === undefined) {
            return false;
        }
        let expectedItems = (Object.keys(widths).length - 1) * (Object.keys(heights).length - 1);
        return expectedItems === sanitizedItems.length;
    }

    setErrorCode = (row: number, column: number) => {
        return "error.pricetableItemDto.table." + ((column === 0 || row === 0) ? "invalidHeader" : "invalidElement");
    }

    validateValue(value: any, column: number, row: number): boolean {
        return !Number.isNaN(parseFloat(value)) && Number.isFinite(Number(value))
            && (column === 0 && row === 0 ? true : Number(value) > 0);
    }
}
