import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class EntranceModel implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    sortIndex: number;
    glazingPackageId: number;
    image: string;
    image2: string;
    active = true;
    uw: number;

    static fromJSON(jsonObject: any): EntranceModel {
        const model = new EntranceModel();
        model.id = jsonObject.id;
        model.name = MultilanguageField.fromJSON(jsonObject.name);
        model.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        model.symbol = jsonObject.symbol;
        model.sortIndex = jsonObject.sortIndex;
        model.glazingPackageId = jsonObject.glazingPackageId;
        model.image = jsonObject.image;
        model.image2 = jsonObject.image2;
        model.active = jsonObject.active;
        model.uw = jsonObject.uw;
        return model;
    }
}
