<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(GateWallField.NAME)"
                            inputId="name"
                            label="{{ 'GATE_WALL.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="100"
                            [disabled]="fieldUsage.disabled(GateWallField.NAME)"
                            [required]="true" [field]="item.name"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(GateWallField.SHORTCUT)"
                            inputId="shortcut"
                            label="{{ 'GATE_WALL.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors" [maxlength]="100"
                            [disabled]="fieldUsage.disabled(GateWallField.SHORTCUT)"
                            [required]="false" [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(GateWallField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'GATE_WALL.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(GateWallField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(GateWallField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GATE_WALL.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GateWallField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GateWallField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GATE_WALL.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GateWallField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion>
        <p-accordionTab *ngIf="fieldUsage.show(GateWallField.LOW_RESOLUTION_PREVIEW_IMAGE)"
                        header="{{ 'GATE_WALL.FORM.LOW_RESOLUTION_PREVIEW_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['lowResolutionPreviewImage'] != undefined}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="lowResolutionPreviewImage"
                                 [disabled]="fieldUsage.disabled(GateWallField.LOW_RESOLUTION_PREVIEW_IMAGE)"
                                 (ngModelChange)="lowResolutionPreviewImageChange.emit($event)" [maxSize]="150000"
                                 [maxWidth]="400" [maxHeight]="400"
                                 [addButtonLabel]="'GATE_WALL.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GATE_WALL.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GATE_WALL.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['lowResolutionPreviewImage']"></app-file-upload>
            </div>
        </p-accordionTab>
        <p-accordionTab *ngIf="fieldUsage.show(GateWallField.HIGH_RESOLUTION_PREVIEW_IMAGE)"
                        header="{{ 'GATE_WALL.FORM.HIGH_RESOLUTION_PREVIEW_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['highResolutionPreviewImage'] != undefined}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="highResolutionPreviewImage"
                                 [disabled]="fieldUsage.disabled(GateWallField.HIGH_RESOLUTION_PREVIEW_IMAGE)"
                                 (ngModelChange)="highResolutionPreviewImageChange.emit($event)" [maxSize]="5242880"
                                 [maxWidth]="1500" [maxHeight]="1000"
                                 [addButtonLabel]="'GATE_WALL.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GATE_WALL.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GATE_WALL.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['highResolutionPreviewImage']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
