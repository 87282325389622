<ng-template #criteriaTemplate let-node="node" let-index="index">
	<div class="new-form-row">
		<app-select inputId="variable-select-{{ index }}"
					placeholder="{{ 'USER_ACTIVITY_REPORT.FORM.VARIABLE' | translate }}"
					[(validationMessageKey)]="validationErrors['variable-select-{{ index }}']"
					[options]="variables | async" [required]="true" [(ngModel)]="node.criteria.variable">
			<ng-template pTemplate="label"></ng-template>
		</app-select>
		<app-select inputId="operator-select-{{ index }}"
					placeholder="{{ 'USER_ACTIVITY_REPORT.FORM.OPERATOR' | translate }}"
					[(validationMessageKey)]="validationErrors['operator-select-{{ index }}']"
					[options]="operators | async" [required]="true" [(ngModel)]="node.criteria.operator">
			<ng-template pTemplate="label"></ng-template>
		</app-select>
		<app-input-number inputId="value-input-{{ index }}"
						  placeholder="{{ 'USER_ACTIVITY_REPORT.FORM.VALUE' | translate }}"
						  [(validationMessageKey)]="validationErrors['value-input-{{ index }}']" [required]="true"
						  [min]="0" [(ngModel)]="node.criteria.value">
			<ng-template pTemplate="label"></ng-template>
		</app-input-number>
		<div class="new-form-field">
			<app-simple-button buttonId="node-delete-button-{{ index }}"
							   label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
							   type="delete" [size]="40" fixedWidth="40px"
							   (onClick)="handleDeleteNode(node)"></app-simple-button>
		</div>
	</div>
</ng-template>

<ng-template #criteriaTreeNodeTemplate let-node="node" let-depth="depth" let-index="index">
	<ng-container *ngIf="node.children != undefined">
		<div *ngIf="depth !== 0" class="new-form-row" style="padding-left: 8px">(</div>
		<div [style.padding-left.px]="depth !== 0 ? 24 : 0">
			<div class="new-form-row new-form-row-narrow-inputs logic-input">
				<app-select inputId="logic-select-{{ index }}"
							label="{{ 'USER_ACTIVITY_REPORT.FORM.LOGIC' | translate }}"
							[(validationMessageKey)]="validationErrors['logic-select-{{ index }}']"
							[options]="childLogicOperators | async" [required]="true" [(ngModel)]="node.logic"></app-select>
				<div class="new-form-field">
					<app-simple-button *ngIf="node.parent != undefined" buttonId="group-delete-button-{{ index }}"
									   label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
									   type="delete" [size]="40" fixedWidth="40px"
									   (onClick)="handleDeleteNode(node)"></app-simple-button>
				</div>
			</div>
			<ng-container *ngFor="let child of node.children; let i = index">
				<ng-container *ngTemplateOutlet="criteriaTreeNodeTemplate; context: {
				node: child,
				depth: depth + 1,
				index: index + '_' + i }"></ng-container>
			</ng-container>
			<div class="new-form-row">
				<div>
					<app-simple-button buttonId="add-criteria-button-{{ index }}"
									   label="{{ 'USER_ACTIVITY_REPORT.FORM.ADD_CHILD' | translate }}" [inlineLabel]="false" icon="add"
									   type="secondary-action" [size]="32" fixedWidth="32px"
									   (onClick)="handleAddCriteriaNode(node)"></app-simple-button>
					<app-simple-button buttonId="add-group-button-{{ index }}"
									   label="{{ 'USER_ACTIVITY_REPORT.FORM.ADD_PARENT' | translate }}" [inlineLabel]="false" icon="add"
									   type="main-action" [size]="32" fixedWidth="32px"
									   (onClick)="handleAddGroupNode(node)"></app-simple-button>
				</div>
			</div>
		</div>
		<div *ngIf="depth !== 0" class="new-form-row" style="padding-left: 8px">)</div>
	</ng-container>
	<ng-container *ngIf="node.criteria != undefined">
		<ng-container *ngTemplateOutlet="criteriaTemplate; context: { node: node, index: index }"></ng-container>
	</ng-container>
</ng-template>

<app-form-date-range-filter [dateRangeKinds]="dateRangeKinds" [(value)]="dateRange"></app-form-date-range-filter>
<ng-container *ngTemplateOutlet="criteriaTreeNodeTemplate; context: {
	node: criteriaTree,
	depth: 0,
	index: '0' }"></ng-container>
