import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {MotlawaBulkAddonsDistributionPossibilities} from "./motlawa-bulk-addons-distribution-possibilities";
import {MotlawaIntegrationData} from "./motlawa-integration-data";
import {MotlawaBulkAddonsDistributionSelection} from "./motlawa-bulk-addons-distribution-selection";

@Injectable()
export class MotlawaIntegrationService {

    private static readonly API_URL = 'motlawaIntegration';

    constructor(private http: HttpClient) {
    }

    checkForBulkAddons(itemId: number): Observable<Listing<MotlawaBulkAddonsDistributionPossibilities>> {
        return this.http.get<Listing<object>>(`${MotlawaIntegrationService.API_URL}/${itemId}/checkForBulkAddons`)
            .pipe(mapListingToJson(MotlawaBulkAddonsDistributionPossibilities));
    }

    checkForOfferCharges(itemId: number): Observable<Listing<MotlawaBulkAddonsDistributionPossibilities>> {
        return this.http.get<Listing<object>>(`${MotlawaIntegrationService.API_URL}/${itemId}/checkForOfferCharges`)
            .pipe(mapListingToJson(MotlawaBulkAddonsDistributionPossibilities));
    }

    generateMotlawaData(itemId: number,
                        bulkAddonSelections: MotlawaBulkAddonsDistributionSelection[],
                        offerChargesSelections: MotlawaBulkAddonsDistributionSelection[]): Observable<MotlawaIntegrationData> {
        let formData = this.buildSelectionsForm(bulkAddonSelections, offerChargesSelections);
        return this.http.post(`${MotlawaIntegrationService.API_URL}/${itemId}/generateMotlawaData`, formData)
            .pipe(map(response => MotlawaIntegrationData.fromJSON(response)));
    }

    sendMotlawaData(itemId: number,
                    bulkAddonSelections: MotlawaBulkAddonsDistributionSelection[],
                    offerChargesSelections: MotlawaBulkAddonsDistributionSelection[]): Observable<string> {
        let formData = this.buildSelectionsForm(bulkAddonSelections, offerChargesSelections);
        return this.http.post(`${MotlawaIntegrationService.API_URL}/${itemId}/sendMotlawaData`, formData,
            {responseType: 'text'});
    }

    private buildSelectionsForm(bulkAddonSelections: MotlawaBulkAddonsDistributionSelection[],
                                offerChargesSelections: MotlawaBulkAddonsDistributionSelection[]) {
        let formData = new FormData();
        formData.append('bulkAddonSelections', new Blob([JSON.stringify(bulkAddonSelections)], {
            type: 'application/json'
        }));
        formData.append('offerChargesSelections', new Blob([JSON.stringify(offerChargesSelections)], {
            type: 'application/json'
        }));
        return formData;
    }

    getDataSourceIds(): Observable<string[]> {
        return this.http.get<string[]>(`${MotlawaIntegrationService.API_URL}/datasources`);
    }

    testDataSource(id: string): Observable<void> {
        return this.http.post<void>(`${MotlawaIntegrationService.API_URL}/datasource/test`, undefined, {
            params: {
                name: id
            }
        });
    }
}
