import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {AbstractSellDealerDiscount} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount';

@JsonDeserializable
export class SubsystemGroupSupplierSellDealerDiscount extends AbstractSellDealerDiscount {
    supplierId: number;
    supplierName: string;

    static fromJSON(jsonObject: any): SubsystemGroupSupplierSellDealerDiscount {
        return Object.assign(new SubsystemGroupSupplierSellDealerDiscount(), jsonObject);
    }
}
