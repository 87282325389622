import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {VenskaData} from './VenskaData';

@Injectable()
export class VenskaSettingsService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getVenskaData(): Observable<VenskaData> {
        return this.http.get<object>('venskaData').pipe(map(VenskaData.fromJSON));
    }

    updateVenskaData(venskaData: VenskaData, file: File, logoGlamourFile: File): Observable<void> {
        let body = JSON.stringify(venskaData);
        let formData = new FormData();
        formData.append('venskaDataDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('logo', file);
        }
        if (logoGlamourFile) {
            formData.append('logoGlamour', logoGlamourFile);
        }
        return this.http.post<void>('venskaData', formData);
    }

    getLogo(): Observable<File> {
        return this.http.get('venskaData/image', {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getLogoGlamour(): Observable<File> {
        return this.http.get('venskaData/logoGlamour', {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }
}
