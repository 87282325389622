import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {SelectItem} from 'primeng/api/selectitem';
import {EMPTY} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {UserRoleTypes} from '../../../common/enums/UserRoleTypes';
import {ResponseError} from "../../../common/error.handler";
import {ValidationService} from "../../../common/service/validation.service";
import {OnceFlag} from '../../../shared/once-flag';
import {RoleService} from '../../admin-panel/role/role.service';
import {ErrorResponse} from "../../errors/errorResponse";
import {User} from "../user";
import {UserDetailsValidator} from "../user-details-validator";
import {UserService} from "../user.service";

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    providers: [UserService, DataServiceHelper, ValidationService, RoleService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserComponent implements OnInit, OnDestroy {

    @Input() user: User;
    @Input() file: File;
    @Output() readonly fileChange = new EventEmitter<File>();

    validationErrors = {};
    @Input() existingRoles: SelectItem[] = [];
    @Input() existingSubsystems: SelectItem[] = [];
    availableRoles: SelectItem[] = [];
    secondaryRoles: SelectItem[] = [];

    userDetailsValidator: UserDetailsValidator;
    private saveInProgress: boolean;

    @Output() onCancel = new EventEmitter();
    @Output() onSave = new EventEmitter();
    @Output() onShowSubstituteUsersDialog = new EventEmitter<User[]>();
    @Output() onNoSuitableUsers = new EventEmitter<void>();

    protected enterHotkey: Hotkey;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private translate: TranslateService,
                validationService: ValidationService,
                private userService: UserService,
                private roleService: RoleService,
                private changeDetector: ChangeDetectorRef,
                private hotkeyService: HotkeysService) {
        this.userDetailsValidator = new UserDetailsValidator(validationService);
        this.enterHotkey = new Hotkey('enter', () => {
            this.submit();
            return false;
        }, ['INPUT']);
    }

    ngOnInit() {
        this.hotkeyService.add(this.enterHotkey);
        this.setAvailableRoles();
    }

    private setAvailableRoles() {
        this.roleService.getItems(undefined, undefined, {primary: {value: 'false'}}, undefined, undefined)
            .subscribe(roles => {
                this.secondaryRoles = roles.data.map(role => ({label: role.roleName, value: role.id}));
                this.changeDetector.markForCheck();
            });
        let changeableRoles = [UserRoleTypes.HANDLOWIEC.toString(), UserRoleTypes.OPERATOR.toString()];
        this.availableRoles = this.existingRoles.filter(role => role.value === this.user.roleName ||
            (changeableRoles.includes(role.value) && changeableRoles.includes(this.user.roleName)));
    }

    ngOnDestroy() {
        this.hotkeyService.remove(this.enterHotkey);
    }

    handleFileChange(file) {
        this.fileChange.emit(file);
        this.file = file;
    }

    submit() {
        if (!this.userDetailsValidator.validateForm(this.user, this.file, this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return;
        }
        if (!this.isSaveInProgress()) {
            this.setSaveInProgress(true);

            if (!this.user.id || this.user.active || this.user.roleName === UserRoleTypes[UserRoleTypes.SPRZEDAWCA]) {
                this.dialogHideHelper.call(() => this.saveUser());
                return;
            }
            this.userService.hasAnyClient(this.user.id).pipe(mergeMap(hasClients => {
                if (!hasClients) {
                    this.dialogHideHelper.call(() => this.saveUser());
                    return EMPTY;
                }
                return this.userService.getUsersBySubsystem(this.user.subsystemId);
            })).subscribe({
                next: (userList: Listing<User>) => {
                    let substituteUsers = userList.data.filter(s => s.id !== this.user.id && s.active);

                    if (substituteUsers.length > 0) {
                        this.dialogHideHelper.call(() => this.emitOnShowSubstituteUsersDialog(substituteUsers));
                    } else {
                        this.emitOnNoSuitableUsers();
                    }
                },
                error: () => {
                    this.emitOnNoSuitableUsers();
                }
            });
        }
    }

    private saveUser() {
        this.userService.saveUser(this.user, this.file).subscribe({
            complete: () => {
                this.setSaveInProgress(false);
                this.onSave.emit();
            },
            error: (err: HttpErrorResponse) => {
                this.setSaveInProgress(false);
                this.dialogHideHelper.reset();
                let errorResponse = new ErrorResponse(err.error);
                if (errorResponse.is400()) {
                    this.validationErrors = {};
                    for (let property in errorResponse.invalidFields) {
                        this.validationErrors[property] = errorResponse.invalidFields[property];
                    }
                    this.changeDetector.markForCheck();
                } else {
                    throw new ResponseError(err);
                }
            }
        });
    }

    private emitOnShowSubstituteUsersDialog(substituteUsers: User[]): void {
        this.onShowSubstituteUsersDialog.emit(substituteUsers);
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    public cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }

    private emitOnNoSuitableUsers(): void {
        this.onNoSuitableUsers.emit();
    }
}
