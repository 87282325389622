<div class="new-form-60vw">
    <div class="new-form-row" *ngIf="fieldUsage.show(WindowDependentOptionField.SYSTEM)">
        <app-select inputId="windowSystemSelect"
                    label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.WINDOW_SYSTEM' | translate }}" autofocus
                    [(validationMessageKey)]="validationErrors['windowSystemId']" [options]="windowSystems | async"
                    [disabled]="fieldUsage.disabled(WindowDependentOptionField.SYSTEM)"
                    [required]="true" [optionFormatter]="formatWindowSystemOption" [ngModel]="item.windowSystemId"
                    (ngModelChange)="handleWindowSystemChange($event)"></app-select>
    </div>

    <div *ngIf="fieldUsage.show(WindowDependentOptionField.NAME)" class="new-form-row">
        <app-input-text inputId="name" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.NAME' | translate }}"
                        [disabled]="fieldUsage.disabled(WindowDependentOptionField.NAME)"
                        [(validationMessageKey)]="validationErrors['name']"
                        [maxlength]="255" [(ngModel)]="item.name"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(WindowDependentOptionField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="name"
                      label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(WindowDependentOptionField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(WindowDependentOptionField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(WindowDependentOptionField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
