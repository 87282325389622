import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SkipInterceptorsInterceptor} from './skip-interceptors.interceptor';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

    static readonly NAME = 'UrlInterceptor';

    static getUrl(url: string): string {
        return !url.match("http.*") ? environment.apiURL + url : url;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (SkipInterceptorsInterceptor.isSkipped(req, UrlInterceptor.NAME)) {
            return next.handle(req);
        }
        return next.handle(req.clone({
            url: UrlInterceptor.getUrl(req.url),
            setHeaders: {
                ApplicationId: 'vincent-frontend'
            }
        }));
    }
}
