<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ getTableHeader() | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="permissions.canEditDealerDiscount()"
                                   label="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <ng-container *ngIf="mode === DealerDiscountType.PER_SYSTEM">
            <p-column field="windowSystemName"
                      header="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}" [sortable]="true"
                      [filter]="showFilters" filterMatchMode="contains">
                <ng-template let-item="rowData" pTemplate="body">
                    {{item.windowSystemName[userLang]}}
                </ng-template>
            </p-column>
            <p-column field="supplierCompanyName" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SUPPLIER' | translate }}"
                      [sortable]="true"
                      [filter]="showFilters" filterMatchMode="contains">
                <ng-template let-item="rowData" pTemplate="body">
                    {{item.supplierCompanyName}}
                </ng-template>
            </p-column>
        </ng-container>

        <p-column *ngIf="mode === DealerDiscountType.PER_CONFIGURABLE_ADDON" field="systemName"
                  header="{{ 'CONFIGURABLE_ADDONS.DEFINITION_TYPE' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.systemName[userLang] }}
            </ng-template>
        </p-column>
        <p-column *ngIf="mode === DealerDiscountType.PER_SUPPLIER" field="supplierCompanyName"
                  header="{{ 'SUPPLIER-DETAILS.FORM.COMPANY-NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
        </p-column>

        <p-column field="calculatedDiscountValue" header="{{ 'DEALER_DISCOUNTS.FORM.VALUE' | translate }}"
                  [sortable]="true">
            <ng-template let-item="rowData" pTemplate="body">
                {{item.calculatedDiscountValue.toString().replace('.',',') + ' ('+ item.discountValue + ')'}}
            </ng-template>
        </p-column>

        <p-column field="validFrom" header="{{ 'DEALER_DISCOUNTS.FORM.VALID_FROM' | translate }}"
                  [sortable]="true" [filter]="showFilters" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.validFrom | momentCurrentLang | amDateFormat: 'L' }}
            </ng-template>
        </p-column>

        <p-column field="active" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter"
                  header="{{ 'DEALER_DISCOUNTS.FORM.STATUS' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{(item.active ? 'DEALER_DISCOUNTS.FORM.ACTIVE' : 'DEALER_DISCOUNTS.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>

        <p-column *ngIf="mode === DealerDiscountType.PER_SYSTEM && permissions.canEditDealerDiscount()"
                  header="{{ 'DEALER_DISCOUNTS.FORM.ACTIONS' | translate }}"
                  [sortable]="false">
            <ng-template let-item="rowData" pTemplate="body">
                <app-simple-button *ngIf="item.active" icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                   label="{{'DEALER_DISCOUNTS.FORM.DEACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, false)"></app-simple-button>
                <app-simple-button *ngIf="!item.active" icon="check" type="confirm" [size]="36" [inlineLabel]="false"
                                   label="{{'DEALER_DISCOUNTS.FORM.ACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, true)"></app-simple-button>
            </ng-template>
        </p-column>

        <!-- is current for system -->
        <p-column header="{{ 'DEALER_DISCOUNTS.FORM.CURRENT_FOR' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{(item.isCurrent ? 'GENERAL.YES' : 'GENERAL.NO') | translate}}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'DEALER_DISCOUNTS.FORM.ADD_TITLE' | translate }}"
            (onCancel)="cancel()" (onComplete)="add()">
    <app-wizard-step label="{{ 'DEALER_DISCOUNTS.FORM.DETAILS_STEP' | translate }}">
        <app-dealer-discount-form [dealerDiscount]="item"
                                  [targetIdField]="getTargetIdField()"
                                  [targetLabel]="getTargetLabel() | translate"
                                  [targetOptions]="getTargetOptions()"
                                  [validationErrors]="validationErrors"
                                  [canEdit]="true"></app-dealer-discount-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog #editDialog *ngIf="!newItem && displayDialog"
                   header="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.EDIT' | translate }}" (onCancel)="cancel()"
                   (onComplete)="edit()">
    <app-wizard-step>
        <app-dealer-discount-form [dealerDiscount]="item" [targetIdField]="getTargetIdField()"
                                  [targetLabel]="getTargetLabel() | translate" [targetOptions]="getTargetOptions()"
                                  [validationErrors]="validationErrors"
                                  [canEdit]="permissions.canEditDealerDiscount()"></app-dealer-discount-form>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="permissions.canEditDealerDiscount()" buttonId="wizard-dialog-save"
                               label="{{'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="editDialog.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="editDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
