import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LinkableEntities} from '../link-selection/link-selection.component';
import {RailSystemService} from "./rail-system.service";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {RailSystem} from "./rail-system";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {forkJoin, Observable, of} from "rxjs";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import {MultilanguageField} from "../../../supportedLanguages";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {GateSystemService} from "../gate-system/gate-system.service";
import {ListOfIds} from "../../ListOfIds";
import {RailSystemFieldUsage} from "../catalog-field-usage";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {CatalogTab, RailSystemField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";

@Component({
    selector: 'app-rail-system',
    templateUrl: './rail-system.component.html',
    providers: [RailSystemService, TranslatedSelectItemService, GateSystemService]
})
export class RailSystemComponent extends CrudCommonComponent<RailSystem, RailSystemService> implements OnInit {

    readonly LinkableEntity = LinkableEntities;

    readonly STEPS = {
        DATA: 'DATA',
        GATE_SYSTEMS: 'GATE_SYSTEMS'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    selectedGateSystems: number[];
    gateSystems: ItemForCatalogLinking[];

    designerFile: File;
    additionalIcon: File;

    editPermits: FieldLimitation[] = [];
    fieldUsage: RailSystemFieldUsage;
    CatalogField = CatalogTab;
    RailSystemField = RailSystemField;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private railSystemService: RailSystemService,
        private gateSystemService: GateSystemService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, RailSystemService, 'RAIL_SYSTEM', 'RailSystem');
        this.validateDataStep = () => this.validateForm();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fieldUsage = new RailSystemFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gateSystemService.getGatesForCatalogLinking().subscribe(systems => {
            this.gateSystems = systems;
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.RAIL_SYSTEMS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): RailSystem {
        return new RailSystem();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.resetFiles();
        this.prepareDataForRail(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    resetFiles() {
        this.file = null;
        this.designerFile = null;
        this.additionalIcon = null;
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.railSystemDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.railSystemDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] =
            MultiValidator.of('error.railSystemDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.railSystemDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);
                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copy(this.selectedItem.id, this.item, this.file, this.designerFile, this.additionalIcon);
                } else {
                    observable = this.itemService.saveRailSystem(this.item, this.file, this.designerFile, this.additionalIcon);
                }

                observable = observable.pipe(mergeMap(railId => {
                    const ids = new ListOfIds();
                    ids.ids = this.selectedGateSystems;
                    return this.itemService.editLinks(railId, ids);
                }));

                observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    prepareDataForRail(railId: number) {
        forkJoin({
            rail: (railId != undefined ? this.railSystemService.getItem(railId) : of(new RailSystem())),
            image: (railId != undefined ? this.railSystemService.getImage(railId) : of<File>(undefined)),
            designerImage: (railId != undefined ? this.railSystemService.getDesignerImage(railId) : of<File>(undefined)),
            additionalIcon: (railId != undefined ? this.railSystemService.getAdditionalIcon(railId) : of<File>(undefined)),
            linkedGates: (railId !== null ? this.railSystemService.getLinkedModels(railId) : of<number[]>([]))
        }).subscribe({
            next: data => {
                this.newItem = railId == undefined;
                this.item = data.rail;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.file = data.image;
                this.designerFile = data.designerImage;
                this.additionalIcon = data.additionalIcon;
                this.selectedGateSystems = data.linkedGates;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.resetFiles();
        this.validationErrors = {};
        this.prepareDataForRail(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.resetFiles();
            this.prepareDataForRail(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.resetFiles();
        this.reloadDatatable();
    }

    onDesignerFileChange(newFile: File): void {
        this.designerFile = newFile;
        if (!newFile) {
            this.designerFile = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    onAdditionalIconChange(newFile: File): void {
        this.additionalIcon = newFile;
        if (!newFile) {
            this.additionalIcon = new File([], null);
        }
        this.changeDetector.markForCheck();
    }
}
