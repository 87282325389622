<div class="profit-margin-container" [ngClass]="{'editable': canEdit, 'not-editable': !canEdit}">
    <!--Toolbar-->
    <div *ngIf="canEdit" class="table-header">
        <div class="table-header-buttons">
            <div class="flex-row">
                <div class="profit-margin-global-input-container">
                    <app-input-text [inputId]="globalMarginUpdateInputId"
                                    [(validationMessageKey)]="validationErrors[globalMarginUpdateInputId]"
                                    [(ngModel)]="globalProfitMargin"
                                    (keydown.enter)="applyGlobalProfitMargin($event)">
                        <ng-template pTemplate="label"></ng-template>
                    </app-input-text>
                </div>
                <app-simple-button [buttonId]="globalMarginUpdateButtonId"
                                   label="{{ 'SELL_DEALER_DISCOUNT.FORM.SET_ALL_VALUES' | translate }}" icon="beenhere"
                                   type="action" [size]="40" (onClick)="applyGlobalProfitMargin($event)"
                                   (keydown.enter)="applyGlobalProfitMargin($event)"></app-simple-button>
            </div>
        </div>
    </div>

    <p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [editable]="true" [responsive]="true" [(selection)]="selectedItem"
                 (onEditInit)="initEdit($event)" (onEditComplete)="commitEdit($event)"
                 (onPage)="handlePageChange($event)" (onSort)="changeDetector.markForCheck()"
                 (onFilter)="changeDetector.markForCheck()">
        <ng-container *ngFor="let column of extraColumns">
            <p-column [field]="column.field" [header]="column.header | translate" [filter]="column.filterable"
                      [filterMatchMode]="column.filterMatchMode"
                      [filterValues]="isAsyncFilterValueList(column.filterValues) ? (column.filterValues | async) : (column.filterValues | selectItemTranslate)"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-item="rowData" pTemplate="body">
                    {{ formatExtraColumn(column, item) | async }}
                </ng-template>
            </p-column>
        </ng-container>
        <p-column *ngIf="isGroupValueVisible()" field="groupCalculatedDiscountValue"
                  header="{{ 'SELL_DEALER_DISCOUNT.FORM.GROUP_VALUE' | translate }}"
                  [style]="{'width':'15%'}"></p-column>
        <p-column field="editValue" header="{{ 'SELL_DEALER_DISCOUNT.FORM.VALUE' | translate }}" [editable]="canEdit"
                  [style]="{'width':'15%'}">
            <ng-template let-item="rowData" pTemplate="body">
                <span [ngClass]="{'disabled': !canEdit}">
                    <div>
                        <span class="editable-cell-value-span">{{ item.value }}</span>
                        <span *ngIf="canEdit" class="material-icons editable-cell-value-span-icon"></span>
                    </div>
                    <div *ngIf="item.calculatedDiscountValue != item.value && item.calculatedDiscountValue != undefined">
                        ({{item.calculatedDiscountValue}})
                    </div>
                </span>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
