export class GuidesDialogData {
    displayDialog: boolean;
    oldValue: number;
    newValue: number;
    locked: boolean;
    oldLocked: boolean;
    hideLock: boolean;
    isChangeVertical: boolean;
    saveDisabled: boolean;

    constructor() {
        this.displayDialog = false;
        this.oldValue = 0;
        this.newValue = 0;
        this.locked = false;
        this.oldLocked = false;
        this.hideLock = false;
        this.isChangeVertical = false;
        this.saveDisabled = false;
    }
}
