<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.ROLE.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="permissions.canEditRole()" buttonId="add"
                                   label="{{ 'ADMIN_PANEL.ROLE.ADD' | translate }}" icon="add" type="main-action"
                                   [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" dataKey="id"
                 [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="roleName" [ngClass]="columnHeaderClasses('roleName')">
                        <span>{{ 'ROLE.FORM.NAME' | translate }}</span>
                        <p-sortIcon field="roleName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="roleName"
                               (input)="table.filter(getInputEventValue($event), 'roleName', 'contains')">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" [pSelectableRow]="role" [pSelectableRowIndex]="rowIndex"
                    (dblclick)="doOnRowSelect({ data: role })" [app-table-keyboard-navigation]="role"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({ data: role })">
                    <td>
                        {{ role.roleName }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
<app-wizard *ngIf="newItem" header="{{ 'ADMIN_PANEL.ROLE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step [id]="'DATA'">
        <app-role-form [role]="item" [validationErrors]="validationErrors" [privileges]="privileges"></app-role-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog #wizardDialog *ngIf="!newItem && displayDialog" header="{{ 'ADMIN_PANEL.ROLE.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [id]="'DATA'">
        <app-role-form [role]="item" [validationErrors]="validationErrors" [privileges]="privileges"></app-role-form>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="!item.primary" buttonId="wizard-dialog-save"
                               label="{{'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="wizardDialog.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="wizardDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
