import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {SkipInterceptorsInterceptor} from '../common/http/skip-interceptors.interceptor';
import {JwtHelperService} from './jwt-helper.service';
import {LogoutHelperService} from './logout-helper.service';
import {Permissions} from './permission.service';
import {StorageKey, StorageService} from './storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    static readonly NAME = 'JwtInterceptor';
    static readonly SKIP_ERROR_HANDLING_HEADER = 'X-Jwt-SkipErrorHandling';

    constructor(private storageService: StorageService,
                private logoutHelper: LogoutHelperService,
                private permissions: Permissions,
                private jwtHelper: JwtHelperService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skipErrorHandling = req.headers.has(JwtInterceptor.SKIP_ERROR_HANDLING_HEADER);
        let headers = req.headers.delete(JwtInterceptor.SKIP_ERROR_HANDLING_HEADER);
        if (SkipInterceptorsInterceptor.isSkipped(req, JwtInterceptor.NAME)) {
            return next.handle(req.clone({ headers: headers }));
        }
        const token = this.storageService.get(StorageKey.JWT);
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }
        const impersonatorToken = this.storageService.get(StorageKey.MAIN_JWT);
        if (impersonatorToken != undefined) {
            headers = headers.set('mainJwt', impersonatorToken);
        }
        return next.handle(req.clone({
            headers: headers
        })).pipe(
            tap(response => {
                if (response.type === HttpEventType.Response) {
                    this.checkForNewTokens(response);
                }
            }),
            catchError(err => skipErrorHandling ? throwError(() => err) : this.handleError(err))
        );
    }

    private checkForNewTokens(response: HttpResponse<any>): void {
        if (response.headers.has('Authorization')) {
            this.storageService.set(StorageKey.JWT, response.headers.get('Authorization'));
        }
        if (response.headers.has('mainJwt')) {
            this.storageService.set(StorageKey.MAIN_JWT, response.headers.get('mainJwt'));
        }
    }

    private handleError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (this.jwtHelper.isTokenExpired()
            || error.headers.get('AuthError') === 'DeprecatedFrontendVersionException'
            || error.headers.get('AuthError') === 'AuthenticationTokenExpiredException') {
            // logout on expired token
            console.error('Login token expired - redirecting to login page');
            setTimeout(() => {
                this.logoutHelper.logout();
            }, 1);
            return EMPTY; // make sure current subscribers are not notified
        }
        if (error.status === 403 || error.status === 401 || error.status === 423) {
            // we have a token which is valid on our side but not valid serverside
            // or simply dont have permissions to perform that request
            // but at the same time we don't know if its token or permission issue
            // so redirect to no permissions component and show a login button there
            console.error('User not permitted to view page - prompting to log in or go back');
            setTimeout(() => {
                this.permissions.redirectToNoPermissionsErrorPage(true);
            }, 1);
            return EMPTY; // make sure current subscribers are not notified
        }
        // call standard error for GlobalErrorHandler if this is not a permission related error
        return throwError(() => error);
    }
}
