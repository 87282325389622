import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'underscore';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {OnceFlag} from "../../../../../../shared/once-flag";
import {PositionType} from '../../../../AbstractPosition';
import {PositionService} from "../../position.service";
import {Position} from "../position";
import {GlobalConfigAddonsAddConfirmationDialogData} from "../position-list-dialogs";
import {GlobalConfigAddonAddResult} from './globalConfigAddonAddResult';
import {MessageSeverity, PositionMessage} from "../../../message";

export enum SubmitStatus {
    OK,
    CANCELLED
}

@Component({
    selector: 'app-global-config-addons-add-confirmation',
    templateUrl: './global-config-addons-add-confirmation.component.html',
    styleUrls: ['./bulk-change-confirmation.component.css'],
    providers: [PositionService]
})

export class GlobalConfigAddonsAddConfirmationComponent implements OnInit {

    private static readonly SUBMIT_GLOBAL_CONFIG_ADDONS_ADD_DIALOG_ID = 'submitGlobalConfigAddonsAddDialog';

    @Input()
    dialogData: GlobalConfigAddonsAddConfirmationDialogData;

    @Output()
    onSubmit = new EventEmitter<SubmitStatus>();

    readonly dialogHideHelper = new OnceFlag();

    allPositionsContainErrors: boolean;
    somePositionsContainErrors: boolean;
    errorsPerWindowPosition = new Map<number, string[]>();
    messagesPerWindowPosition = new Map<number, PositionMessage[]>();

    constructor(protected offerPositionService: PositionService,
                public translate: TranslateService,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
    }

    ngOnInit(): void {
        this.dialogData.modifiedPositions.forEach(windowPos => {
            let windowPosId = windowPos.id;
            let addonAddResults = this.dialogData.configsToAdd.filter(addonResult => addonResult.windowPositionId === windowPosId);

            let errorCodes = _.flatten(addonAddResults.filter(res => res.windowPositionId === windowPosId).map(res => res.errorCodes));
            if (errorCodes.length > 0) {
                this.errorsPerWindowPosition.set(windowPos.id, errorCodes);
            }

            let messages = [];
            addonAddResults.forEach(res => {
                Object.keys(MessageSeverity).forEach(severity => {
                    if (res.messages && res.messages[severity]) {
                        for (let mess of res.messages[severity]) {
                            const message = new PositionMessage();
                            message.messageCode = mess;
                            message.severity = MessageSeverity[severity];
                            messages.push(message);
                        }
                    }
                });
            });
            if (messages.length > 0) {
                this.messagesPerWindowPosition.set(windowPos.id, messages);
            }
        });
        this.allPositionsContainErrors = _.every(this.dialogData.modifiedPositions, position => this.positionContainErrors(position));
        this.somePositionsContainErrors = _.some(this.dialogData.modifiedPositions, position => this.positionContainErrors(position));
    }

    submitDialog(accepted: boolean): void {
        this.dialogHideHelper.call(() => {
            this.blockUiController.block(GlobalConfigAddonsAddConfirmationComponent.SUBMIT_GLOBAL_CONFIG_ADDONS_ADD_DIALOG_ID);

            if (accepted) {
            let addon = this.dialogData.addon;
            let configPosition = new Position(this.dialogData.configurableAddonName, PositionType.CONFIG_SYSTEM,
                addon.configurableAddonDefinitionType, "", 1, null, null,
                null, null, null, null, null, null, null, null,
                JSON.stringify(addon), addon.offerId, null);
            configPosition.configSystemId = addon.configData.configSystemId;

                let validConfigs = this.dialogData.configsToAdd.filter(result => GlobalConfigAddonAddResult.isValid(result));
                this.offerPositionService.addConfigAddonsGlobally(validConfigs, this.dialogData.modifiedPositions[0].offerId, configPosition, this.dialogData.isUpselling).subscribe({
                    error: error => {
                        this.blockUiController.unblock(GlobalConfigAddonsAddConfirmationComponent.SUBMIT_GLOBAL_CONFIG_ADDONS_ADD_DIALOG_ID);
                        this.errors.handle(error);
                    },
                    complete: () => {
                        this.onSubmit.emit(SubmitStatus.OK);
                        this.blockUiController.unblock(GlobalConfigAddonsAddConfirmationComponent.SUBMIT_GLOBAL_CONFIG_ADDONS_ADD_DIALOG_ID);
                    }
                });
            } else {
                this.onSubmit.emit(SubmitStatus.CANCELLED);
                this.blockUiController.unblock(GlobalConfigAddonsAddConfirmationComponent.SUBMIT_GLOBAL_CONFIG_ADDONS_ADD_DIALOG_ID);
            }
        });
    }

    positionContainErrors(offerPosition: Position): boolean {
        let windowPosId = offerPosition.id;
        return this.errorsPerWindowPosition.has(windowPosId) || (this.messagesPerWindowPosition.has(windowPosId)
            && this.messagesPerWindowPosition.get(windowPosId).some(message => message.severity === MessageSeverity.ERROR));
    }

    getPositionErrors(positionId: number): string[] {
        return _.uniq([
            ...this.errorsPerWindowPosition.has(positionId) ? this.errorsPerWindowPosition.get(positionId) : []
        ]);
    }

    getPositionMessages(positionId: number): PositionMessage[] {
        if (this.messagesPerWindowPosition.has(positionId)) {
             return this.messagesPerWindowPosition.get(positionId);
        }
        return [];
    }
}
