import {Component, EventEmitter, forwardRef, Input} from '@angular/core';
import {forkJoin, isObservable, Observable, of} from 'rxjs';
import {AbstractInputComponent, FormHandler} from '../inputs/abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../inputs/form-handler-token';

export type WizardStepValidator = () => boolean | Observable<boolean>;

export function callWizardStepValidators(validators: WizardStepValidator[]): Observable<boolean[]> {
    return forkJoin(validators.map(validator => {
        const result = validator();
        return isObservable(result) ? result : of(result);
    }));
}

export interface WizardStepValueChangeEvent {
    stepId: string;
    value: any;
    input: AbstractInputComponent;
}

@Component({
    selector: 'app-wizard-step',
    templateUrl: './wizard-step.component.html',
    styleUrls: ['./wizard.component.css'],
    providers: [{provide: FORM_HANDLER, useExisting: forwardRef(() => WizardStepComponent)}]
})
export class WizardStepComponent extends FormHandler {

    @Input()
    label: string;

    @Input()
    id: string;

    @Input()
    validate: WizardStepValidator;

    @Input()
    displayHeader: boolean;

    active: boolean;

    valueChange: EventEmitter<WizardStepValueChangeEvent>;

    constructor() {
        super();
        this.label = '';
        this.validate = () => true;
        this.displayHeader = true;
        this.active = false;
        this.valueChange = new EventEmitter<WizardStepValueChangeEvent>();
    }

    validateStep(): Observable<boolean> {
        let result = this.validate();
        if (!isObservable(result)) {
            return of(result);
        }
        return result;
    }

    onInputChange(value: any, input: AbstractInputComponent): void {
        this.valueChange.emit({stepId: this.id, value: value, input: input});
    }
}
