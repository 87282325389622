<div id="background" [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')'}">
    <div id="content">
        <div class="form-wrapper">
            <div id="logo"></div>
            <ng-container [ngSwitch]="displayedForm">
                <app-login-form *ngSwitchCase="'login'" [login]="user.login"
                                (loginChange)="user.login = $event; errorMessage = undefined" [password]="user.password"
                                (passwordChange)="user.password = $event; errorMessage = undefined" (submit)="submit()"
                                (resetPassword)="handleResetPasswordClick()"></app-login-form>
                <app-send-reset-password-mail-form *ngSwitchCase="'resetPasswordMail'" [login]="user.login"
                                                   (returnClick)="handleReturnClick()"
                                                   (successMessageChange)="successMessage = $event; errorMessage = undefined"
                                                   (errorMessageChange)="errorMessage = $event; successMessage = undefined"></app-send-reset-password-mail-form>
                <app-reset-password-form *ngSwitchCase="'resetPassword'" [resetPasswordToken]="resetPasswordToken"
                                         (returnClick)="handleReturnClick()"
                                         (passwordSuccessfullyChanged)="handlePasswordSuccessfullyChanged($event)"
                                         (errorMessageChange)="errorMessage = $event"></app-reset-password-form>
            </ng-container>
            <div *ngIf="successMessage" class="info-message"
                 id="loginSuccessMessage">{{ successMessage | translate }}</div>
            <div *ngIf="errorMessage" class="error-message" id="loginErrorMessage">{{ errorMessage | translate }}</div>
            <div class="help">{{ 'LOGIN.FORM.CONTACT' | translate }}
                <br><a [attr.href]="'mailto:' + ('LOGIN.FORM.EMAIL' | translate)">{{ 'LOGIN.FORM.EMAIL' | translate }}</a>
            </div>
        </div>
    </div>
</div>

<app-terms-of-use-dialog *ngIf="displayTermsOfUseDialog" [termsOfUse]="termsOfUse" dialogId="termsOfUseDialog"
                         (onConfirmed)="termsOfUseConfirmed()"
                         (onCancel)="termsOfUseDeclined()"></app-terms-of-use-dialog>
