<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.OFFERS.DELIVERY_LISTS' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="toggle-mine-filter-button"
                                   label="{{ (isShowingOnlyOwn() ? 'GENERAL.CLEAR_FILTERS' : 'GENERAL.FILTER_SHOW_MINE') | translate }}"
                                   icon="filter_list" type="action" (onClick)="toggleShowOnlyOwn()"></app-simple-button>
                <app-simple-button *ngIf="canEdit" buttonId="add" label="{{ 'DELIVERY_LIST.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt id="deliveryListTable" [attr.id]="TABLE_ID" [paginator]="false" [rows]="chosenRowsPerPage"
                 [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedDeliveryList" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder" dataKey="id"
                 [value]="deliveryLists">

        <!-- ACTIONS -->
        <p-column header="{{ 'OFFER.FORM.ACTIONS' | translate }}" [sortable]="false">
            <ng-template let-deliveryList="rowData" pTemplate="body">
                <div class="table-row-action-buttons">
                    <app-simple-button [buttonId]="'show_' + deliveryList.id" [icon]="canEdit ? 'edit' : 'search' "
                                       [size]="36" label="{{'DELIVERY_LIST.ACTIONS.TOOLTIPS.EDIT' | translate}}"
                                       [inlineLabel]="false" type="main-action"
                                       (onClick)="goToDeliveryList(deliveryList.id)"></app-simple-button>

                    <button-with-menu [buttonId]="'more_' + deliveryList.id" rightButtonIcon="more_horiz"
                                      buttonType="filter" [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                      buttonLabel="{{'DELIVERY_LIST.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate}}"
                                      [buttonInlineLabel]="false"
                                      [menuElementBuilder]="getRowActionsBuilder(deliveryList)"
                                      (menuElementSelected)="handleRowAction($event)"></button-with-menu>
                </div>
            </ng-template>
        </p-column>

        <ng-container *ngFor="let column of columns">
            <p-column *ngIf="column.field === 'name'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue"></p-column>
            <p-column *ngIf="column.field === 'creationDate' || column.field === 'dispatchDate'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue"
                      styleClass="datatable-date-range-column">
                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!column.filter"></div>
                </ng-template>
                <ng-template let-deliveryList="rowData" pTemplate="body">
                    {{ (column.field === 'creationDate' ? deliveryList.creationDate : deliveryList.dispatchDate) | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>

            <!-- STATUS -->
            <p-column *ngIf="column.field == 'status'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="false">

                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="availableStatuses | selectItemTranslate"
                                       defaultLabel="{{ 'DELIVERY_LIST.LIST.STATUS' | translate }}" appendTo="body"
                                       [displaySelectedLabel]="false" [ngModel]="selectedStatuses"
                                       (ngModelChange)="handleStatusFilterChange($event)"></p-multiSelect>
                    </div>
                </ng-template>
                <ng-template let-deliveryList="rowData" pTemplate="body">
                    <div [ngClass]="'status-color ' + deliveryList.status">
                        {{ deliveryList.status ? ('DELIVERY_LIST.STATUS.' + deliveryList.status | translate) : ''}}
                    </div>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'ownerName'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-deliveryList="rowData" pTemplate="body">
                    {{ deliveryList.ownerFirstName + ' ' + deliveryList.ownerLastName }}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'subsystemName'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="true"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="subsystems"
                                       defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                       (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                    </div>
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'subsystemClientManager'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-deliveryList="rowData" pTemplate="body">
                    {{ deliveryList.subsystemClientManagerFirstName + ' ' + deliveryList.subsystemClientManagerLastName }}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field === 'subsystemSubClientManager'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter"
                      [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-deliveryList="rowData" pTemplate="body">
                    {{ (deliveryList.subsystemSubClientManagerFirstName ? deliveryList.subsystemSubClientManagerFirstName : '')
                        + ' '
                        + (deliveryList.subsystemSubClientManagerLastName ? deliveryList.subsystemSubClientManagerLastName : '') }}
                </ng-template>
            </p-column>
        </ng-container>

    </p-dataTable>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>

<app-print-dialog *ngIf="printDialogVisible" [printableSection]="PrintableSection.DELIVERY_LISTS" [selectedItems]="printableItems"
                  (onCloseDialog)="printDialogVisible = false"></app-print-dialog>
