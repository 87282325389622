<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'PALETTE.FORM.NAME' | translate }}" [maxlength]="100"
                        [(validationMessageKey)]="validationErrors['name']" [required]="true"
                        [(ngModel)]="palette.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="quantity" label="{{ 'PALETTE.FORM.QUANTITY' | translate }}" [min]="1" [max]="100"
                          [(validationMessageKey)]="validationErrors['quantity']" [required]="true"
                          [(ngModel)]="palette.quantity"></app-input-number>
    </div>
</div>
