<p-dialog [visible]="visible" (visibleChange)="visibleChange.emit($event)" [modal]="true" [focusOnShow]="false"
          [resizable]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="scrolled-dialog">
            <div id="positions-validation-status" class="flex-column">
                <ng-content></ng-content>
                <div id="info" *ngIf="!allPositionsContainErrors">
                    <div *ngIf="!somePositionsContainErrors">{{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.BODY' | translate }}</div>
                    <div *ngIf="somePositionsContainErrors" class="warn">{{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.BODY_WARN' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="!allPositionsContainErrors" buttonId="bulk-change-save-button" label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40"
                               (onClick)="onSubmit.emit(true)"></app-simple-button>
            <app-simple-button *ngIf="!allPositionsContainErrors" label="{{ 'GENERAL.CANCEL' | translate }}"
                               type="cancel" [size]="40"
                               (onClick)="onSubmit.emit(false)"></app-simple-button>
            <app-simple-button *ngIf="allPositionsContainErrors" buttonId="okButtonId" label="{{ 'GENERAL.OK' | translate }}"
                               type="main-action" [size]="40"
                               (onClick)="onSubmit.emit(false)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
