import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {ConfigurableAddon} from '../../../../../../window-designer/entities/ConfigurableAddon';
import {Position} from "./position";

export class OfferPositionModel {
    offerPosition: Position;
    mappedData: DrawingData | ConfigurableAddon;
    mappedDataBeforeChanges: DrawingData | ConfigurableAddon;

    constructor(offerPosition: Position) {
        this.offerPosition = offerPosition;
        this.mappedData = JSON.parse(offerPosition.data);
        this.mappedDataBeforeChanges = JSON.parse(offerPosition.data);
    }
}
