import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from '../../../common/crud-common/paginable.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {MultilanguageField, SupportedLanguages} from '../../../supportedLanguages';
import {TranslationTarget} from '../../translations/ui-translations/translation-target.enum';
import {UiTranslation} from '../../translations/ui-translations/ui-translation';
import {SubsystemWebshopInfoService} from '../subsystem-webshop-info/subsystem-webshop-info.service';
import {WebshopUiDefaultTranslation} from './webshop-ui-default-translation';
import {WebshopUiTranslationsService} from './webshop-ui-translations.service';

@Component({
    selector: 'app-webshop-ui-translations',
    templateUrl: './webshop-ui-translations.component.html',
    styleUrls: ['./webshop-ui-translations.component.css'],
    providers: [WebshopUiTranslationsService, SubsystemWebshopInfoService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopUiTranslationsComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    readonly supportedLanguages = SupportedLanguages.languages;

    translations: UiTranslation[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedItem: UiTranslation;
    availableKeys: SelectItem[];
    availableTargets: Observable<SelectItem[]>;
    selectedTargets: TranslationTarget[];

    newItem = false;
    editedItem: UiTranslation;

    webshopLanguage: keyof MultilanguageFieldInterface;
    defaultTranslations: Map<string, WebshopUiDefaultTranslation>;
    translate: TranslateService;

    private readonly translationsService: WebshopUiTranslationsService;
    private readonly subsystemWebshopInfoService: SubsystemWebshopInfoService;
    private readonly errors: CommonErrorHandler;
    private readonly translatedSelectItemService: TranslatedSelectItemService;

    @ViewChild('table', {static: true})
    private table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopUiTranslationsComponent', false);
        this.translate = injector.get(TranslateService);
        this.translationsService = injector.get(WebshopUiTranslationsService);
        this.subsystemWebshopInfoService = injector.get(SubsystemWebshopInfoService);
        this.errors = injector.get(CommonErrorHandler);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.availableTargets = this.translatedSelectItemService.buildUnsortedDropdown(TranslationTarget, 'TRANSLATION_TARGET.', undefined);

        this.handleTargetFilterChange([TranslationTarget.WEBSHOP]);
        this.subsystemWebshopInfoService.getLanguage().pipe(mergeMap(language => {
            this.webshopLanguage = language;
            return this.translationsService.getAvailableTranslationKeys(TranslationTarget.WEBSHOP, language);
        })).subscribe(defaultTranslations => {
            this.defaultTranslations = new Map(defaultTranslations.map(defaultTranslation => [defaultTranslation.key, defaultTranslation]));
            this.availableKeys = defaultTranslations
                .filter(defaultTranslation => !defaultTranslation.hasSubsystemOverride)
                .map(defaultTranslation => ({label: defaultTranslation.key, value: defaultTranslation.key}));
            this.changeDetector.markForCheck();
        });
    }

    showDialogToAdd(): void {
        this.newItem = true;
        this.editedItem = new UiTranslation();
        this.editedItem.target = TranslationTarget.WEBSHOP;
        this.editedItem.text = new MultilanguageField();
    }

    submit(): void {
        // fill all languages
        this.editedItem.text = new MultilanguageField(this.editedItem.text[this.webshopLanguage]);

        this.translationsService.saveTranslations(this.editedItem).subscribe({
            next: () => {
                if (this.newItem) {
                    this.defaultTranslations.get(this.editedItem.key).hasSubsystemOverride = true;
                    const editedKeyIndex = this.availableKeys.findIndex(key => key.value === this.editedItem.key);
                    if (editedKeyIndex >= 0) {
                        this.availableKeys.splice(editedKeyIndex, 1);
                        this.availableKeys = [...this.availableKeys];
                    }
                } else {
                    this.selectedItem.text = this.editedItem.text;
                    this.selectedItem.lastModifiedManually = new Date();
                }
                this.newItem = false;
                this.editedItem = undefined;
                this.loadItemsLazy(this.table.createLazyLoadMetadata());
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    cancel(): void {
        this.newItem = false;
        this.editedItem = undefined;
    }

    onRowSelect(event: { data: UiTranslation } & KeepSelectedItemEventParams): void {
        this.editedItem = UiTranslation.fromJSON(event.data);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        super.loadItemsLazy(event);
        this.translationsService.getTranslations(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => {
                this.blockUiController.unblock('TranslationsComponentData');
                this.hideDataLoadingIndicator();
            }))
            .subscribe({
                next: data => {
                    this.translations = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.translations, event, item => item.key);
                },
                error: error => {
                    this.errors.handle(error);
                    this.changeDetector.markForCheck();
                },
                complete: () => {
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    rowTrackById(index: number, item: UiTranslation): string {
        return item.key;
    }

    handleTargetFilterChange(targets: TranslationTarget[]): void {
        this.selectedTargets = targets;
        this.table.filter(targets.join(';'), 'target', 'in');
    }

    handleTextFilterChange(value: string, code: keyof MultilanguageFieldInterface): void {
        let textFilter = this.table.filters['text'];
        if (textFilter == undefined && value == undefined) {
            // filter doesnt exist and new filter is not set
            return;
        }
        let textFilterValue = textFilter != undefined ? (Array.isArray(textFilter) ? textFilter[0].value : textFilter.value) : {};
        textFilterValue[code] = value;
        if (Object.values(textFilterValue).filter(v => !!v).length === 0) {
            textFilterValue = undefined;
        }
        this.table.filter(textFilterValue, 'text', 'contains');
    }

    deleteTranslation(translation: UiTranslation): void {
        this.translationsService.deleteTranslation(translation.key, translation.target).subscribe(() => {
            this.defaultTranslations.get(translation.key).hasSubsystemOverride = false;
            this.availableKeys = [...this.availableKeys, {label: translation.key, value: translation.key}];
            this.availableKeys.sort((a, b) => a.label.localeCompare(b.label));

            this.loadItemsLazy(this.table.createLazyLoadMetadata());
        });
    }
}
