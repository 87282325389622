import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {StorageKey} from '../../auth/storage.service';

@Injectable()
export class IsLoggedService {

    private itemSource: BehaviorSubject<boolean>;
    public item$: Observable<boolean>;

    constructor() {
        this.itemSource = new BehaviorSubject(window.localStorage.getItem(StorageKey.JWT.toString()) != undefined);
        this.item$ = this.itemSource.asObservable();
    }

    changeState(isLoggedIn) {
        this.itemSource.next(isLoggedIn);
    }
}

let isLoggedService: IsLoggedService = undefined;
export function isLoggedServiceSingletonFactory() {
    if (isLoggedService == undefined) {
        isLoggedService = new IsLoggedService();
    }
    return isLoggedService;
}
