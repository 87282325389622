import {ChangeDetectorRef, Directive, Injector, Input, OnInit, Type, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {GlassSelectionValidator} from '../../../common/glass-selection/GlassSelectionValidator';
import {GlazingPackagesValidationErrors} from '../../../common/glazing-packages/glazing-packages-form.component';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogTab, SystemGlazingPackageField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from '../../admin-panel/edit-catalog-permits/field-limitation';
import {GlazingPackageFieldUsage} from '../catalog-field-usage';
import {CatalogItemTagsService} from '../catalog-item-tags/catalog-item-tags.service';
import {DistanceFrameService} from '../distance-frame/distance-frame.service';
import {DistanceFrameListing} from '../distance-frame/distanceFrame-list';
import {GlassService} from '../glass/glass.service';
import {GlassWithPosition} from '../glass/glassWithPositions';
import {GraspGlazingPackage} from '../grasp-glazing-package/grasp-glazing-package';
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {AbstractGlazingPackageService} from './abstract-glazing-package.service';
import {GlazingPackage, GlazingPackageTarget} from './glazing-package';
import {WebshopGlazingPackage} from './webshop-glazing-package/webshop-glazing-package';

@Directive()
export abstract class AbstractGlazingPackageComponent<Item extends GlazingPackage, Service extends AbstractGlazingPackageService<Item>>
    extends SingleSystemCheckboxCrudComponent<Item, Service> implements OnInit {

    filterHasGlazing: Observable<SelectItem[]>;
    glasses: GlassWithPosition[];
    frames: DistanceFrameListing[];
    tags: SelectItem[];
    graspGlazingPackages: GraspGlazingPackage[];

    glazingValidationErrors = new GlazingPackagesValidationErrors();
    glazingPackageTargets = GlazingPackageTarget;

    editPermits: FieldLimitation[] = [];
    fieldUsage: GlazingPackageFieldUsage;
    CatalogTab = CatalogTab;
    SystemGlazingPackageField = SystemGlazingPackageField;
    ProductTypeGroup = ProductTypeGroup;
    WindowSystemType = WindowSystemType;

    @Input('target')
    targetInput: GlazingPackageTarget;

    @ViewChild('dt') datatable;

    readonly STEPS = {
        DATA: 'DATA',
        GLAZING: 'GLAZING',
        SYSTEMS: 'SYSTEMS'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateData(),
        GLAZING: () => this.validateGlazingData(),
        SYSTEMS: () => of(true)
    };

    glassService: GlassService;
    frameService: DistanceFrameService;
    catalogItemTagsService: CatalogItemTagsService;
    private readonly activatedRoute: ActivatedRoute;

    get target(): GlazingPackageTarget {
        if (this.targetInput) {
            return this.targetInput;
        }
        if (this.activatedRoute.snapshot.data.target) {
            return this.activatedRoute.snapshot.data.target;
        }
        return undefined;
    }

    protected constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                serviceType: Type<Service>,
                translationKey: string,
                entityName: string) {
        super(injector, changeDetector, true, serviceType, translationKey, entityName);
        this.glassService = injector.get(GlassService);
        this.frameService = injector.get(DistanceFrameService);
        this.catalogItemTagsService = injector.get(CatalogItemTagsService);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterHasGlazing = this.translatedSelectItemService.buildUnsortedDropdown(['YES', 'NO'], 'GENERAL.', '');
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        this.item = this.getNewItem();
        super.ngOnInit();
        forkJoin({
            glasses: this.glassService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            frames: this.frameService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            tags: this.catalogItemTagsService.getAllActive(),
        }).subscribe(data => {
            this.glasses = data.glasses.data;
            this.frames = data.frames.data;
            this.tags = data.tags.map(tag => ({label: tag.tagText[this.translate.currentLang], value: tag}));
        });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    validateData(): Observable<boolean> {
        const validationErrors = {};

        if (this.item.names[this.userLang] == null || this.item.names[this.userLang] === '') {
            validationErrors[`names[${this.userLang}]`] = `error.webshopGlazingPackageDto.names[${this.userLang}].not_empty`;
        }

        if (this.item.sortIndex != undefined) {
            validationErrors['sortIndex'] = MultiValidator.of('error.webshopGlazingPackageDto.sortIndex')
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(this.item.sortIndex);
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }

        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    validateGlazingData(): Observable<boolean> {
        const validationErrors = new GlazingPackagesValidationErrors();
        if (this.target !== GlazingPackageTarget.UPSELLING_GLAZING && this.target !== GlazingPackageTarget.TERRACE_SYSTEM) {
            if (this.item.hasGlazing1) {
                validationErrors.glazing1 = new GlassSelectionValidator().validate(this.item.glazing1);
            }
            if (this.item.hasGlazing2) {
                validationErrors.glazing2 = new GlassSelectionValidator().validate(this.item.glazing2);
            }
            if (this.item.hasGlazing3) {
                validationErrors.glazing3 = new GlassSelectionValidator().validate(this.item.glazing3);
            }
            if (this.item.hasGlazing4) {
                validationErrors.glazing4 = new GlassSelectionValidator().validate(this.item.glazing4);
            }
            if (validationErrors.validationErrorsPresent()) {
                this.glazingValidationErrors = validationErrors;
                return of(false);
            }
        }

        return this.itemService.validateGlazingData(this.item).pipe(
            tap(backendValidationErrors => {
                this.glazingValidationErrors = this.mapGlazingValidationErrors(backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    mapGlazingValidationErrors(rawValidationErrors: ValidationErrors): GlazingPackagesValidationErrors {
        let rangesErrors = Object.assign({}, _.chain(rawValidationErrors)
            .pairs()
            .filter(kvpair => kvpair[1].startsWith('error.glazingPackageForAreaRanges'))
            .reduce((errors, kvpair: [string, string]) => {
                const nestedKeys = kvpair[0].split('.');
                let nestedObj = errors;
                while (nestedKeys.length > 1) {
                    const key = nestedKeys.shift();
                    if (nestedObj.hasOwnProperty(key)) {
                        nestedObj = nestedObj[key];
                    } else {
                        nestedObj[key] = {};
                        nestedObj = nestedObj[key];
                    }
                }
                if (nestedKeys.length === 1) {
                    let value = kvpair[1].replace(/\.-?[0-9]+/, '');
                    nestedObj[nestedKeys.shift()] = value;
                }
                return errors;
            }, {})
            .value());

        return Object.assign(new GlazingPackagesValidationErrors(), {glazingPackageForAreaRanges: rangesErrors}, _.chain(rawValidationErrors)
            .pairs()
            .filter(kvpair => !kvpair[1].startsWith('error.glazingPackageForAreaRanges'))
            .reduce((errors, kvpair) => {
                const nestedKeys = kvpair[0].split('.');
                if (nestedKeys.length === 1) {
                    nestedKeys.push('');
                }
                const errorMessage = kvpair[1].endsWith('.is_empty') ? 'error.offerPosition.bulkChange.selection.not_empty' : kvpair[1];
                let obj = errors;
                while (nestedKeys.length > 1) {
                    const key = nestedKeys.shift();
                    obj = errors[key] = errors[key] || {};
                }
                obj[nestedKeys.shift()] = errorMessage;
                return errors;
            }, {})
            .value());
    }

    abstract submit(): void;

    validationErrorsPresent(): boolean {
        return super.validationErrorsPresent() || this.glazingValidationErrors.validationErrorsPresent();
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.getGlazingPackage(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        super.onRowSelect(event);
        this.setDisplayDialog(false);
        this.getGlazingPackage(event.data.id);
    }

    abstract getGlazingPackage(glazingId: number): void;

    loadItemsLazy(event: LazyLoadEvent): void {
        let modifiedEvent = event;
        if (!modifiedEvent.filters.hasOwnProperty('target')) {
            modifiedEvent.filters.target = {value: this.target};
        }
        super.loadItemsLazy(modifiedEvent);
    }

    protected getApiUrl(): string {
        return this.itemService.getApiUrl();
    }

    get windowSystemTypeGroups(): ProductTypeGroup[] {
        switch (this.target) {
            case GlazingPackageTarget.WEBSHOP_GLAZING:
            case GlazingPackageTarget.UPSELLING_GLAZING:
                return [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];
            case GlazingPackageTarget.SYSTEM_DEFINITION:
            case GlazingPackageTarget.DECORATIVE_GLAZING:
                return [];
            case GlazingPackageTarget.TERRACE_SYSTEM:
                return [ProductTypeGroup.TERRACE];
        }
    }

    isWebshopGlazingPackage(glazingPackage: GlazingPackage): glazingPackage is WebshopGlazingPackage {
        return glazingPackage.target === GlazingPackageTarget.WEBSHOP_GLAZING;
    }

    asWebshopGlazingPackage(glazingPackage: GlazingPackage): WebshopGlazingPackage {
        return this.isWebshopGlazingPackage(glazingPackage) ? glazingPackage : undefined;
    }

    hasWindowSystemSelected(): boolean {
        const windowSystemTypeGroups = this.windowSystemTypeGroups;
        return this.windowSystems
            .some(ws => windowSystemTypeGroups.includes(WindowSystemType.getByName(ws.systemType).group)
                && this.item.linkedSystemIds.includes(ws.id));
    }

    hasWebshopAdditionalWindowSystemSelected(): boolean {
        return this.windowSystems
            .some(ws => WindowSystemType.getByName(ws.systemType).predefinedGlazing
                && this.item.linkedSystemIds.includes(ws.id));
    }
}
