import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable} from 'rxjs';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ConfigDependentOptionField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {ConfigEditorFieldContentProvider} from '../../../offer/config-editor/config-editor-field-content-provider';
import {ConfigDependentOptionFieldUsage} from "../../catalog-field-usage";
import {ConfigSystem} from '../../config-system/config-system';
import {ConfigDesignerCatalogDependentOptionsSet} from '../config-designer-catalog-dependent-options-set';

@Component({
    selector: 'app-config-designer-catalog-dependent-option-form',
    templateUrl: './config-designer-catalog-dependent-option-form.component.html'
})
export class ConfigDesignerCatalogDependentOptionFormComponent {

    @Input()
    item: ConfigDesignerCatalogDependentOptionsSet;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    configSystems: Observable<ConfigSystem[]>;

    @Input()
    nextStepsFilled: boolean;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: ConfigDependentOptionFieldUsage;
    ConfigDependentOptionField = ConfigDependentOptionField;

    constructor(private readonly translate: TranslateService,
                private readonly configEditorFieldContentProvider: ConfigEditorFieldContentProvider) {
        this.fieldUsage = new ConfigDependentOptionFieldUsage(this);
    }

    formatConfigSystemOption = (configSystem: ConfigSystem): SelectItem => {
        return {
            label: configSystem.name[this.translate.currentLang],
            value: configSystem.id
        };
    }

    handleConfigSystemChange(windowSystemId: number): void {
        this.item.configSystemId = windowSystemId;
        forkJoin({
            // doesNotWorkWithoutThisDummy: of(`FIX: error TS2339: Property 'windowSystemModel' does not exist on type 'never'.`),
            ...this.configEditorFieldContentProvider.getCatalogDataSource(windowSystemId, undefined, false)
        }).subscribe(catalogData => {
            this.configEditorFieldContentProvider.initCatalog(catalogData);
        });
    }
}
