import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {PromotionCode} from '../promotion-code';

@Component({
    selector: 'app-promotion-code-form',
    templateUrl: './promotion-code-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionCodeFormComponent {

    @Input()
    promotionCode: PromotionCode;

    @Input()
    validationErrors: ValidationErrors;
}
