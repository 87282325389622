import {RandomIdGenerator} from "../utils/RandomIdGenerator";
import {FieldWithPosition} from "./FieldWithPosition";
import {GrillSegment} from "./GrillSegment";
import {GrillSegmentDto} from "./GrillSegmentDto";
import {GrillType} from "./GrillType";
import {PricingGrillSegment} from "./PricingGrillSegment";
import {RelativePosition} from "./RelativePosition";
import {GrillTypes} from "../enums/GrillTypes";

export abstract class Grill {
    generatedId: string;
    id: number;
    type: GrillType;
    width: number;
    segments: GrillSegmentDto[] = [];
    positions: RelativePosition[] = [];
    positionsPoints: number[];
    colorId: number;

    // front-only helper fields:
    parentFields: FieldWithPosition[] = [];
    drawingSegments: GrillSegment[] = [];
    pricingSegments: PricingGrillSegment[] = [];
    canBeAngled = true;
    constructionType: GrillTypes;

    constructor(type: GrillType) {
        this.generatedId = RandomIdGenerator.generate();
        this.type = type;
    }
}
