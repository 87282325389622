import {Component, Input, OnInit} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WebshopGlazingPackage} from '../../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';
import {WebshopCharge} from "../../../window-system/webshop-charge/WebshopCharge";
import {WindowSystemName} from '../../../window-system/window-system-definition/window-system-definition';
import {UpsellingProposition} from "../upselling-proposition";
import {UpsellingPickListGlazing} from "./upselling-pick-list-glazing";

@Component({
    selector: 'app-upselling-proposition-glazings',
    templateUrl: './upselling-proposition-glazings.component.html',
    styleUrls: ['./upselling-proposition-glazings.component.css']
})
export class UpsellingPropositionGlazingsComponent implements OnInit {

    @Input() item: UpsellingProposition;
    @Input() validationErrors: ValidationErrors;
    @Input() allActiveGlazings: WebshopGlazingPackage[];
    @Input() windowSystemNames: WindowSystemName[];
    @Input() userLang: string;

    allMappedGlazings: UpsellingPickListGlazing[];
    availableGlazings: UpsellingPickListGlazing[] = [];
    selectedGlazings: UpsellingPickListGlazing[] = [];
    overlappingSystemNames: string;

    ngOnInit() {
        this.allMappedGlazings =
            this.allActiveGlazings.map(glazing => new UpsellingPickListGlazing(glazing, this.userLang));
        this.selectedGlazings =
            this.allMappedGlazings.filter(glazing => this.item.glazingPackageIds.includes(glazing.id));
        this.availableGlazings =
            this.allMappedGlazings.filter(glazing => !this.item.glazingPackageIds.includes(glazing.id));
        this.countGlazingsPerSystem();
    }

    handleMoveToSource(moved: WebshopCharge[]): void {
        for (let movedOption of moved) {
            let selectedIndex = this.item.glazingPackageIds.findIndex(orderId => orderId === movedOption.id);
            if (selectedIndex !== -1) {
                this.item.glazingPackageIds.splice(selectedIndex, 1);
            }
        }
        this.sortGlazings(this.availableGlazings);
        this.countGlazingsPerSystem();
    }

    handleMoveToTarget(moved: WebshopCharge[]): void {
        for (let movedOption of moved) {
            this.item.glazingPackageIds.push(movedOption.id);
        }
        this.sortGlazings(this.selectedGlazings);
        this.countGlazingsPerSystem();
    }

    sortGlazings(glazings: UpsellingPickListGlazing[]): void {
        glazings.sort((a, b) => a.sortIndex - b.sortIndex);
    }

    countGlazingsPerSystem(): void {
        let selectedPerSystem = new Map<number, number>();
        this.selectedGlazings.forEach(glazing => {
            glazing.windowSystemIds.forEach(windowSystemId => {
                if (selectedPerSystem.has(windowSystemId)) {
                    selectedPerSystem.set(windowSystemId, selectedPerSystem.get(windowSystemId) + 1);
                } else {
                    selectedPerSystem.set(windowSystemId, 1);
                }
            });
        });

        let systemIdsSelectedOnce = [];
        let systemIdsSelectedMoreThanOnce = [];
        let entries = selectedPerSystem.entries();
        let mapEntry = entries.next();
        while (!mapEntry.done) {
            let systemId = mapEntry.value[0];
            let count = mapEntry.value[1];
            if (count === 1) {
                systemIdsSelectedOnce.push(systemId);
            } else if (count > 1) {
                systemIdsSelectedMoreThanOnce.push(systemId);
            }
            mapEntry = entries.next();
        }

        this.allMappedGlazings.forEach(glazing => {
            glazing.warnInSource = glazing.windowSystemIds.some(systemId => systemIdsSelectedOnce.includes(systemId) || systemIdsSelectedMoreThanOnce.includes(systemId));
            glazing.warnInTarget = glazing.windowSystemIds.some(systemId => systemIdsSelectedMoreThanOnce.includes(systemId));
        });
        this.validateGlazings(systemIdsSelectedMoreThanOnce);
    }

    validateGlazings(systemIdsSelectedMoreThanOnce: number[] = []): void {
        this.overlappingSystemNames = undefined;
        if (systemIdsSelectedMoreThanOnce.length > 0) {
            this.validationErrors['webshopGlazingIds'] = 'error.upsellingProposition.webshopGlazingIds.not_unique';
            this.overlappingSystemNames = systemIdsSelectedMoreThanOnce
                .map(systemId => this.windowSystemNames.find(systemData => systemData.id === systemId))
                .filter(systemData => systemData != null)
                .map(systemData => systemData.names[this.userLang])
                .join(", ");
        } else {
            this.validationErrors['webshopGlazingIds'] = undefined;
        }
    }
}
