import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ConfigSystem} from "../config-system";
import {MultilanguageField} from "../../../../supportedLanguages";
import {SelectItem} from "primeng/api/selectitem";

@Component({
    selector: 'app-config-system-dimensions-form',
    templateUrl: './config-system-dimensions-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSystemDimensionsFormComponent implements OnInit {

    @Input() item: ConfigSystem;
    @Input() validationErrors: any;
    @Input() dimensionLabelKeysMap: Map<string, MultilanguageField> = new Map<string, MultilanguageField>();
    @Input() canUploadToAmazon = false;
    @Input() amazonS3Files: SelectItem[] = [];
    @Output() uploadedFileChange: EventEmitter<File> = new EventEmitter<File>();

    uploadedFile: File;
    selectedFile: string;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.selectedFile = this.item.amazonS3FileName;
    }

    bulkTabHasErrors(): boolean {
        return Object.getOwnPropertyNames(this.validationErrors)
            .filter(propName => propName.startsWith('name_bulk_dim') || propName.startsWith('print_name_bulk'))
            .some(propName => this.validationErrors[propName] != null);
    }

    windowTabHasErrors(): boolean {
        return Object.getOwnPropertyNames(this.validationErrors)
            .filter(propName => propName.startsWith('name_window') || propName.startsWith('print_name_window'))
            .some(propName => this.validationErrors[propName] != null);
    }

    handleUploadedFileChange(file: File): void {
        this.uploadedFile = file || new File([], null);
        this.uploadedFileChange.emit(this.uploadedFile);
        this.selectedFile = undefined;
        this.item.amazonS3FileName = this.uploadedFile.name;
        this.changeDetector.markForCheck();
    }

    handleUnusedFileSelectionChange(file: string): void {
        this.uploadedFile = undefined;
        this.uploadedFileChange.emit(this.uploadedFile);
        this.selectedFile = file;
        this.item.amazonS3FileName = this.selectedFile;
        this.changeDetector.markForCheck();
    }
}
