import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SubsystemAddressListFormComponent} from '../subsystem-address-list-form/subsystem-address-list-form.component';
import {TabularAddress} from '../tabularAddress';

@Component({
    selector: 'app-subsystem-address-selection-dialog',
    templateUrl: './subsystem-address-selection-dialog.component.html'
})
export class SubsystemAddressSelectionDialogComponent implements OnChanges {

    @Input()
    addresses: TabularAddress[];

    @Output()
    readonly addressesChange = new EventEmitter<TabularAddress[]>();

    @Input()
    selectedAddress: TabularAddress;

    @Output()
    readonly selectedAddressChange = new EventEmitter<TabularAddress>();

    @Input()
    visible = false;

    @Output()
    readonly visibleChange = new EventEmitter<boolean>();

    @Input()
    canEdit: boolean;

    @Input()
    selectable: boolean;

    @ViewChild('addressForm', {static: true})
    addressForm: SubsystemAddressListFormComponent;

    ngOnChanges(changes: SimpleChanges): void {
        if ('visible' in changes) {
            this.addressForm.reset();
        }
    }

    handleSelect(): void {
        if (this.selectable) {
            this.selectedAddressChange.emit(this.selectedAddress);
        } else {
            this.selectedAddressChange.emit();
        }
        this.visibleChange.emit(false);
    }

    handleModify(addresses: TabularAddress[]): void {
        this.addressesChange.emit(addresses);
    }
}
