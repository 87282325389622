import {Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {DisplayUnitService, SupportedDisplayUnitSystem} from '../../../../common/service/display-unit.service';

@Directive({
    selector: '[app-designer-length-unit-hider]'
})
export class DesignerLengthUnitHiderDirective implements OnInit, OnDestroy {

    private readonly HIDE_CLASSES = {'hide-millimeters': true, 'hide-inches': true};
    private subscription: Subscription;

    constructor(private readonly host: ElementRef<Element>,
                private readonly renderer: Renderer2,
                private readonly displayUnitService: DisplayUnitService) {
    }

    ngOnInit() {
        this.subscription = this.displayUnitService.system.subscribe(system => {
            this.updateClass(system);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private updateClass(system: SupportedDisplayUnitSystem): void {
        Object.keys(this.HIDE_CLASSES).forEach(css => this.renderer.removeClass(this.host.nativeElement, css));
        const classesToAdd = {...this.HIDE_CLASSES};
        switch (system) {
            case SupportedDisplayUnitSystem.IMPERIAL:
                classesToAdd['hide-inches'] = false;
                break;
            default:
                classesToAdd['hide-millimeters'] = false;
                break;
        }
        Object.entries(classesToAdd)
            .filter(e => e[1])
            .forEach(e => this.renderer.addClass(this.host.nativeElement, e[0]));
    }
}
