import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WindowSystemDefinitionDimensionPrice} from './window-system-definition-dimension-price';

@Injectable()
export class WindowSystemDefinitionDimensionPriceService implements CrudService<WindowSystemDefinitionDimensionPrice> {

    private static readonly API_URL = 'roofSystemDimensionPrices';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: WindowSystemDefinitionDimensionPrice): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: WindowSystemDefinitionDimensionPrice): Observable<number> {
        return undefined;
    }

    getItem(itemId: number): Observable<WindowSystemDefinitionDimensionPrice> {
        return undefined;
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WindowSystemDefinitionDimensionPrice>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WindowSystemDefinitionDimensionPrice>>(WindowSystemDefinitionDimensionPriceService.API_URL,
            {params: params});
    }

    getAllItems(filters: any): Observable<Listing<WindowSystemDefinitionDimensionPrice>> {
        return this.getItems(0, null, filters, undefined, undefined);
    }

    save(dataList: WindowSystemDefinitionDimensionPrice[]): Observable<void> {
        return this.http.post<void>(WindowSystemDefinitionDimensionPriceService.API_URL, dataList);
    }
}
