import {GlassInterface} from '../../../../window-designer/catalog-data/glass-interface';
import {Glass} from "./glass";

export class GlassWithPosition extends Glass implements GlassInterface {
    position1of1: boolean;
    position1of2: boolean;
    position2of2: boolean;
    position1of3: boolean;
    position2of3: boolean;
    position3of3: boolean;
    position1of4: boolean;
    position2of4: boolean;
    position3of4: boolean;
    position4of4: boolean;
    allPositions: boolean;

    constructor() {
        super();
    }
}
