import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {GlassField} from "../../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GlassFieldUsage} from "../../catalog-field-usage";
import {WindowSystemDefinitionService} from "../../window-system-definition/window-system-definition.service";
import {Glass} from "../glass";
import {GlassService} from "../glass.service";

@Component({
    selector: 'app-glass-form',
    templateUrl: './glass-form.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [GlassService, WindowSystemDefinitionService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlassFormComponent {

    @Input()
    item: Glass;

    @Input()
    validationErrors: any = {};

    @Input()
    copyMode = false;

    @Input()
    editMode = false;

    @Input()
    file: File;

    @Input()
    glamourPrintIconFile: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    @Output()
    readonly glamourPrintIconFileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: GlassFieldUsage;
    GlassField = GlassField;

    constructor() {
        this.fieldUsage = new GlassFieldUsage(this);
    }

    fileChanged(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    glamourPrintIconFileChanged(file: File): void {
        this.glamourPrintIconFileChange.emit(file);
        this.glamourPrintIconFile = file;
    }
}
