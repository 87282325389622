<div *ngIf="isInvalidOfferNumber">
    {{ offerNumber }}
</div>

<div *ngIf="!isInvalidOfferNumber && isOffer && !isProductionOrder">
    <span>{{ parsedOfferNumber.year }}</span>/<span>{{ parsedOfferNumber.subsystemId }}</span>/<span class="offer-number-part-bold">{{ parsedOfferNumber.userId }}</span>/<span class="offer-number-part-bold">{{ parsedOfferNumber.offerNumber }}</span>
</div>

<div *ngIf="!isInvalidOfferNumber && !isOffer && isProductionOrder">
    <span class="offer-number-part-bold">{{ parsedOfferNumber.orderIndex }}</span>/<span class="offer-number-part-bold">{{ parsedOfferNumber.year}}</span>/<span>{{ parsedOfferNumber.supplierId  }}</span>/<span>{{ parsedOfferNumber.subsystemId }}</span>/<span>{{ parsedOfferNumber.userId }}</span>/<span>{{ parsedOfferNumber.offerNumber }}</span>
</div>
