import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ProfitMarginTarget} from './profit-margin-target';
import {ProfitMargin} from './profitMargin';

@Injectable()
export class ProfitMarginService implements CrudService<ProfitMargin> {

    private api: string;
    private apiAll: string;

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: ProfitMargin): Observable<number> {
        console.error('profit-margin.service.ts: method saveItem not implemented');
        return undefined;
    }

    editItem(itemId: number, item: ProfitMargin): Observable<number> {
        console.error('profit-margin.service.ts: method editItem not implemented');
        return undefined;
    }

    getItem(itemId: number): Observable<ProfitMargin> {
        console.error('profit-margin.service.ts: method getItem not implemented');
        return undefined;
    }

    public setApi(apiAll: string, api: string) {
        this.api = api;
        this.apiAll = apiAll;
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ProfitMargin>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<ProfitMargin>>(this.apiAll, {params: params});
    }

    getAllItems(filters: any): Observable<Listing<ProfitMargin>> {
        return this.getItems(0, null, filters, 'id', 1);
    }

    save(editedList: any[], target: ProfitMarginTarget): Observable<void> {
        let params = {};
        if (target != undefined) {
            params['target'] = `${target}`;
        }
        return this.http.post<void>(this.api, editedList, {params: params});
    }

    overrideGroupMemberMargins(editedList: any[], target: ProfitMarginTarget): Observable<void> {
        let params = {};
        if (target != undefined) {
            params['target'] = `${target}`;
        }
        return this.http.post<void>(`${this.api}/overrideGroupMembers`, editedList, {params: params});
    }
}
