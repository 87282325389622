import {MultilanguageFieldInterface} from '../window-designer/catalog-data/multilanguage-field-interface';

export interface SupportedLanguage {
    code: keyof MultilanguageFieldInterface;
    name: string;
    locale: string;
}

export const SupportedLanguages: { languages: SupportedLanguage[] } = {
    // first language is the default language selected in forms
    languages: [
        {
            code: 'en',
            name: 'LANGUAGES.ENGLISH',
            locale: 'en-GB'
        },
        {
            code: 'pl',
            name: 'LANGUAGES.POLISH',
            locale: 'pl-PL'
        },
        {
            code: 'no',
            name: 'LANGUAGES.NORWEGIAN',
            locale: 'nb-NO'
        },
        {
            code: 'se',
            name: 'LANGUAGES.SWEDISH',
            locale: 'sv-SE'
        },
        {
            code: 'ca',
            name: 'LANGUAGES.CANADIAN',
            locale: 'en-CA'
        },
        {
            code: 'da',
            name: 'LANGUAGES.DANISH',
            locale: 'da-DK'
        },
        {
            code: 'de',
            name: 'LANGUAGES.GERMAN',
            locale: 'de-DE'
        }
    ]
};

export function getLanguageInfoByCode(code: keyof MultilanguageFieldInterface): SupportedLanguage;
export function getLanguageInfoByCode(code: string): SupportedLanguage | undefined;
export function getLanguageInfoByCode(code: string): SupportedLanguage | undefined {
    return SupportedLanguages.languages.find(sl => sl.code === code);
}

export function getLanguageNameByCode(code: keyof MultilanguageFieldInterface): string;
export function getLanguageNameByCode(code: string): string | undefined;
export function getLanguageNameByCode(code: string): string | undefined {
    const language = getLanguageInfoByCode(code);
    return language != undefined ? language.name : undefined;
}

export class MultilanguageField implements MultilanguageFieldInterface {
    pl?: string;
    de?: string;
    en?: string;
    cz?: string;
    sk?: string;
    fr?: string;
    it?: string;
    es?: string;
    ru?: string;
    no?: string;
    se?: string;
    nl?: string;
    da?: string;
    ca?: string;

    constructor(commonName?: string) {
        this.pl = commonName;
        this.de = commonName;
        this.en = commonName;
        this.cz = commonName;
        this.sk = commonName;
        this.fr = commonName;
        this.it = commonName;
        this.es = commonName;
        this.ru = commonName;
        this.no = commonName;
        this.se = commonName;
        this.nl = commonName;
        this.da = commonName;
        this.ca = commonName;
    }

    public static fromJSON(jsonObject: any): MultilanguageField {
        let multilanguageField = new MultilanguageField();
        multilanguageField.pl = jsonObject.pl;
        multilanguageField.de = jsonObject.de;
        multilanguageField.en = jsonObject.en;
        multilanguageField.cz = jsonObject.cz;
        multilanguageField.sk = jsonObject.sk;
        multilanguageField.fr = jsonObject.fr;
        multilanguageField.it = jsonObject.it;
        multilanguageField.es = jsonObject.es;
        multilanguageField.ru = jsonObject.ru;
        multilanguageField.no = jsonObject.no;
        multilanguageField.se = jsonObject.se;
        multilanguageField.nl = jsonObject.nl;
        multilanguageField.da = jsonObject.da;
        multilanguageField.ca = jsonObject.ca;
        return multilanguageField;
    }
}
