import {ValidationErrors} from '../../../common/validation-errors';
import {ConfigEditorField} from "./config-editor-field";
import {ConfigEditorComponent} from "./config-editor.component";
import {ConfigEditorFieldContentProvider} from "./config-editor-field-content-provider";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {ConfigSystem} from "../../window-system/config-system/config-system";

export enum AccordionTabId {
    GENERAL = 'GENERAL'
}

export class ConfigEditorFieldStates {

    public static readonly SECTION_FIELDS = new Map<AccordionTabId, ConfigEditorField[]>([
        [AccordionTabId.GENERAL, [ConfigEditorField.DIM_1, ConfigEditorField.DIM_2, ConfigEditorField.DIM_3,
            ConfigEditorField.DIM_4, ConfigEditorField.DIM_5, ConfigEditorField.DIM_6]]
    ]);

    constructor(private readonly editor: ConfigEditorComponent,
                private readonly editorFieldContentProvider: ConfigEditorFieldContentProvider,
                private readonly editorMock?: {selectedConfigSystem: ConfigSystem}) {
    }

    isVisible(field: ConfigEditorField | string): boolean {
        if (field === 'DIMENSION_TAB') {
            return this.sidebarOnlyMode || this.editor.applicableTo.includes(ConfigAddonApplication.INDEPENDENT);
        }
        let selectedConfigSystem = (this.editor == null ? this.editorMock : this.editor).selectedConfigSystem;
        switch (field) {
            case ConfigEditorField.MATERIAL:
                return true;
            case ConfigEditorField.COLOR:
                return this.sidebarOnlyMode || !selectedConfigSystem.hideColorSelect;
            case ConfigEditorField.DIM_1:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension1;
            case ConfigEditorField.DIM_2:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension2;
            case ConfigEditorField.DIM_3:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension3;
            case ConfigEditorField.DIM_4:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension4;
            case ConfigEditorField.DIM_5:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension5;
            case ConfigEditorField.DIM_6:
                return this.isVisible('DIMENSION_TAB') && selectedConfigSystem && selectedConfigSystem.hasDimension6;
            case ConfigEditorField.QUANTITY:
            case ConfigEditorField.SYSTEM:
            case ConfigEditorField.DESCRIPTION:
                return !this.sidebarOnlyMode;
            default:
                // addons
                if (!Object.keys(ConfigEditorField).includes(field)) {
                    return this.hasItemsForField(field);
                }
                break;
        }
        return true;
    }

    isNotValid(field: ConfigEditorField): boolean {
        if (this.sidebarOnlyMode) {
            return false;
        }

        switch (field) {
            case ConfigEditorField.DIM_1:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym1 == null;
            case ConfigEditorField.DIM_2:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym2 == null;
            case ConfigEditorField.DIM_3:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym3 == null;
            case ConfigEditorField.DIM_4:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym4 == null;
            case ConfigEditorField.DIM_5:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym5 == null;
            case ConfigEditorField.DIM_6:
                return this.isVisible(field) && this.editor.data && this.editor.data.wym6 == null;
            case ConfigEditorField.COLOR:
                return this.editor.data && this.editor.data.colorId == null;
            case ConfigEditorField.MATERIAL:
                return this.editor.data && this.editor.data.materialId == null;
            default:
                return false;
        }
    }

    isAnyErrorPresentInTab(validationErrors: ValidationErrors, groupId?: number, containsMaterialAndColor = false): boolean {
        if (groupId == null || validationErrors == null) {
            return false;
        }
        let groupKeys = Object.keys(validationErrors).filter(key => key.startsWith(groupId + '_'));
        if (containsMaterialAndColor) {
            groupKeys.push(ConfigEditorField.COLOR, ConfigEditorField.MATERIAL);
        }
        return groupKeys.map(key => validationErrors[key]).some(value => value != null);
    }

    isAnyErrorPresentInGeneralAccordionTab(validationErrors: ValidationErrors): boolean {
        return this.isAnyErrorPresentInAccordionTab(validationErrors, AccordionTabId.GENERAL);
    }

    get sidebarOnlyMode(): boolean {
        return this.editor == undefined;
    }

    private isAnyErrorPresentInAccordionTab(validationErrors: ValidationErrors, accordionTabId: AccordionTabId): boolean {
        const fields = ConfigEditorFieldStates.SECTION_FIELDS.get(accordionTabId);
        if (fields == undefined) {
            return false;
        }
        return fields.some(field => validationErrors[field] != undefined);
    }

    private hasItemsForField(field: ConfigEditorField | string): boolean {
        const items = this.editorFieldContentProvider.getItems(field);
        return items != undefined && items.length > 0;
    }
}
