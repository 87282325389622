import {JsonDeserializable} from "../../../common/crud-common/crudItem";
import {Currencies} from "../../../currencies";
import {ProductionOrderStatisticsPoint} from "./production-order-statistics-point";

@JsonDeserializable
export class ProductionOrderSalesTargetStatistics {

    currency: Currencies;
    previousYear: ProductionOrderStatisticsPoint;
    previousYearTarget: number;
    currentYear: ProductionOrderStatisticsPoint;
    currentYearTarget: number;

    static fromJSON(jsonObject: object): ProductionOrderSalesTargetStatistics {
        const statistics = new ProductionOrderSalesTargetStatistics();
        statistics.currency = jsonObject['currency'];
        if (jsonObject['previousYear'] != undefined) {
            statistics.previousYear = ProductionOrderStatisticsPoint.fromJSON(jsonObject['previousYear']);
        }
        statistics.previousYearTarget = jsonObject['previousYearTarget'];
        if (jsonObject['currentYear'] != undefined) {
            statistics.currentYear = ProductionOrderStatisticsPoint.fromJSON(jsonObject['currentYear']);
        }
        statistics.currentYearTarget = jsonObject['currentYearTarget'];
        return statistics;
    }
}
