<p-dialog [visible]="visible && position && ready" [resizable]="false" [modal]="true"
          (onHide)="resetDialog()" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.SHOW_DESCRIPTION' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-text-area inputId="description" [maxlength]="lengthLimit.OTHER_INFO_MAX_LENGTH" [(ngModel)]="otherInfo" [disabled]="readOnlyMode">
                <ng-template pTemplate="label">
                    <label class="hidden"></label>
                </ng-template>
            </app-text-area>
        </div>
        <div class="new-form-row" *ngIf="otherInfoTemplates.length > 0">
            <app-select inputId="type" [options]="otherInfoTemplates" [(ngModel)]="selectedTemplate">
                <ng-template pTemplate="label">
                    <app-simple-button id="addTemplateToDescriptionButton" icon="add" type="action" [size]="40"
                                       [disabled]="otherInfo.length >= lengthLimit.OTHER_INFO_MAX_LENGTH"
                                       (onClick)="addTemplateToDescription()"></app-simple-button>
                </ng-template>
            </app-select>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               [disabled]="readOnlyMode" (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
