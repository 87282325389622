import {Injectable} from "@angular/core";
import {Permissions} from "../../../auth/permission.service";
import {Complaint} from "./complaint";
import {ComplaintStatus} from "./complaint-status";
import {ComplaintService} from "./complaint.service";
import {StatusTransition} from "../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../status-transition-dialog/StatusTransitionHelper";

@Injectable()
export class ComplaintStatusTransitionService {

    constructor(private permissions: Permissions,
                private complaintService: ComplaintService) {}

    public createPossibleTransitions(complaint: Complaint, successCallback?, errorCallback?): StatusTransition[] {
        let possibleTransitions = [];
        let addTransition = (label: string, icon: string, apiCommand: () => any, id: string) => {
            StatusTransitionHelper.genericAddTransition(possibleTransitions, label, icon, apiCommand, id, successCallback, errorCallback);
        };

        if (this.isPermitted({roles: ['ROLE_OPERATOR']}) && (complaint.status === undefined)) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SEND_TO_VERIFY_TO_VENSKA',
                'rate_review',
                () => {},
                'SEND_TO_VERIFY_TO_VENSKA'
            );
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SAVE_WITHOUT_SENDING',
                'save',
                () => {},
                'SAVE_WITHOUT_SENDING'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPERATOR']}) && (complaint.status === ComplaintStatus.NEW || complaint.status === ComplaintStatus.TO_REVISE)) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SEND_TO_VERIFY',
                'rate_review',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.TO_VERIFY),
                'SEND_TO_VERIFY'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && complaint.status === ComplaintStatus.TO_VERIFY) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SEND_BACK_TO_OPERATOR',
                'call_received',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.TO_REVISE),
                'SEND_BACK_TO_OPERATOR'
            );
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SEND_TO_EXAMINATION',
                'navigate_next',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.EXAMINATION_IN_PROGRESS),
                'SEND_TO_EXAMINATION'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && complaint.status === ComplaintStatus.EXAMINATION_IN_PROGRESS) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.SEND_BACK_TO_OPERATOR',
                'call_received',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.TO_REVISE),
                'SEND_BACK_TO_OPERATOR'
            );
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.ACCEPT',
                'navigate_next',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.ACCEPTED),
                'ACCEPT'
            );
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.REJECT',
                'clear',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.REJECTED),
                'REJECT'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && complaint.status === ComplaintStatus.ACCEPTED) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.REJECT',
                'clear',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.REJECTED),
                'REJECT'
            );
        }

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) && complaint.status === ComplaintStatus.REJECTED) {
            addTransition(
                'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS.ACCEPT',
                'navigate_next',
                () => this.complaintService.setStatus(complaint.id, ComplaintStatus.ACCEPTED),
                'ACCEPT'
            );
        }

        return possibleTransitions;
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }
}
