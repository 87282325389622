import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {Permissions} from "../../../../auth/permission.service";
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ComplaintPosition} from "../complaint-position";
import {ComplaintType} from "../complaint-type";
import {ComplaintService} from '../complaint.service';

@Component({
    selector: 'app-complaint-position-form',
    templateUrl: './complaint-position-form.component.html',
    providers: [TranslatedSelectItemService, ComplaintService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplaintPositionFormComponent {

    @Input() position: ComplaintPosition;
    @Input() validationErrors: any;

    @Output() onShowMovieInstruction: EventEmitter<void> = new EventEmitter<void>();
    @Output() onShowPhotoInstruction: EventEmitter<void> = new EventEmitter<void>();
    @Output() onMovieFileChange = new EventEmitter<File>();
    @Output() onPhotoFileChange = new EventEmitter<File>();

    complaintTypeItems: Observable<SelectItem[]>;
    movieFile: File;
    photoFile: File;

    constructor(private translatedSelectItemService: TranslatedSelectItemService,
                private permissions: Permissions,
                private complaintService: ComplaintService) {

        this.initComplaintTypeItems();
    }

    private initComplaintTypeItems(): void {
        this.complaintTypeItems = this.translatedSelectItemService.buildUnsortedDropdown(ComplaintType,
            'OFFER.COMPLAINT.FORM.COMPLAINT_TYPE.', undefined);
    }

    handleComplaintTypeChange(complaintTypes: ComplaintType[]): void {
        this.position.complaintTypes = complaintTypes;
        this.validationErrors['complaintType-' + this.position.offerPositionId] = undefined;
    }

    showMovieInstructionDialog(): void {
        this.onShowMovieInstruction.emit();
    }

    showPhotoInstructionDialog(): void {
        this.onShowPhotoInstruction.emit();
    }

    handleMovieFileChanged(file: File): void {
        this.movieFile = file;
        this.onMovieFileChange.emit(file);
    }

    handlePhotoFileChanged(file: File): void {
        this.photoFile = file;
        this.onPhotoFileChange.emit(file);
    }

    handleMovieDownloadClick(): boolean {
        this.complaintService.getComplaintPositionMovieUrl(this.position.id).subscribe(url => window.open(url, '_blank'));
        return false;
    }

    handlePhotoDownloadClick(): boolean {
        this.complaintService.getComplaintPositionPhotoUrl(this.position.id).subscribe(url => window.open(url, '_blank'));
        return false;
    }

    isTranslatedDescriptionVisible(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    isDescriptionDisabled(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']});
    }

    isTranslatedDescriptionRequired(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']});
    }
}
