import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {EntranceModel} from "./entrance-model";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ListOfIds} from "../../ListOfIds";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {EntranceDoorData} from "../../offer/window-editor/roof-window-editor/entrance-door-data";
import {OpeningOption} from "../../../../window-designer/catalog-data/window-system-interface";
import {CatalogItemExtended} from "./entrance-model-basic";

@Injectable()
export class EntranceModelService implements CrudService<EntranceModel> {

    private static readonly API_URL = 'entranceModel';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(item: EntranceModel, file?: File, file2?: File): Observable<number> {
        return this.saveEntranceModel(item, file, file2);
    }

    editItem(itemId: number, item: EntranceModel, file?: File, file2?: File): Observable<number> {
        return this.saveEntranceModel(item, file, file2, itemId);
    }

    getItem(itemId: number, withViews = false): Observable<EntranceModel> {
        let params: { [param: string]: string | string[] } = {};
        params.withViews = `${withViews}`;
        return this.http.get<EntranceModel>(`${EntranceModelService.API_URL}/${itemId}`, {params: params});
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<EntranceModel>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<EntranceModel>>(EntranceModelService.API_URL, {params: params});
    }

    getImage(itemId: number): Observable<File> {
        return this.http.get(`${EntranceModelService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImage2(itemId: number): Observable<File> {
        return this.http.get(`${EntranceModelService.API_URL}/${itemId}/image2`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getWindowEditorImage(data: EntranceDoorData): Observable<string> {
        let postfix = data.opening === OpeningOption.OUTSIDE ? 'image' : 'image2';
        return this.http.get(`${EntranceModelService.API_URL}/${data.entranceModelId}/${postfix}`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    saveEntranceModel(item: EntranceModel, file: File, file2: File, itemId?: number): Observable<number> {
        const url = itemId ? `${EntranceModelService.API_URL}/${itemId}` : EntranceModelService.API_URL;
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('entranceModelDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (file2) {
            formData.append('file2', file2);
        }
        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('entranceModel', response)));
    }

    copy(id: number, item: EntranceModel, file: File, file2: File) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('entranceModelDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (file2) {
            formData.append('file2', file2);
        }
        return this.http.post(`${EntranceModelService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('entranceModel', response)));
    }

    validate(item: EntranceModel): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${EntranceModelService.API_URL}/validate`, item));
    }

    getLinkedSystems(modelId: number): Observable<number[]> {
        return this.http.get<number[]>(`${EntranceModelService.API_URL}/linked/${modelId}`);
    }

    editLinks(modelId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`${EntranceModelService.API_URL}/link/${modelId}`, systemIds).pipe(map(() => 0));
    }

    getModelsForCatalogLinking(): Observable<ItemForCatalogLinking[]> {
        return this.http.get<ItemForCatalogLinking[]>(`${EntranceModelService.API_URL}/forCatalogLinking`);
    }

    getModelsForAddDialog(selectedModelId?: number): Observable<CatalogItemExtended[]> {
        const params = {};
        if (selectedModelId != undefined) {
            params['selectedModelId'] = `${selectedModelId}`;
        }
        return this.http.get<CatalogItemExtended[]>(`${EntranceModelService.API_URL}/forAddDialog`, {params: params});
    }
}
