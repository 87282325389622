<div class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true" (onHide)="hide()"
              [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title confirm-action-header">
                {{ 'OFFER.DETAILED_PRICING.HELP.HEADER' | translate }}
            </div>
        </p-header>
        <div class="new-form-60vw">
            <div class="help-info">
                <div>{{ 'OFFER.DETAILED_PRICING.HELP.INFORMATION' | translate }}</div>
            </div>
            <div class="help-info">
                <div>{{ 'OFFER.DETAILED_PRICING.HELP.COLORS_LABEL' | translate }}</div>
                <div class="help-colors-explanation">
                    <div class="cost-color VENSKA">123,15</div>
                    <span>{{ 'OFFER.DETAILED_PRICING.HELP.VENSKA_COLOR' | translate }}</span>
                </div>
                <div class="help-colors-explanation">
                    <div class="cost-color SUBSYSTEM">123,15</div>
                    <span>{{ 'OFFER.DETAILED_PRICING.HELP.SUBSYSTEM_COLOR' | translate }}</span>
                </div>
                <div class="help-colors-explanation">
                    <div class="cost-color CLIENT">123,15</div>
                    <span>{{ 'OFFER.DETAILED_PRICING.HELP.CLIENT_COLOR' | translate }}</span>
                </div>
                <div class="help-colors-explanation">
                    <div class="cost-color SELLER">123,15</div>
                    <span>{{ 'OFFER.DETAILED_PRICING.HELP.SELLER_COLOR' | translate }}</span>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm"
                                   [label]="'GENERAL.OK' | translate"
                                   type="main-action" [size]="40" (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
