<ng-template #defaultLabel>
    <label *ngIf="label" [attr.for]="inputId" class="new-form-checkbox-label">{{ label }}</label>
</ng-template>

<div #container class="new-form-field">
    <div class="new-form-checkbox-large-form-padding"></div>
    <div class="new-form-field-input-container">
        <div class="new-form-checkbox-container">
            <label class="input-checkbox">
                <a class="material-icons checkbox" [ngClass]="{'disabled':disabled}"
                   (click)="handleValueChange(state.CHECKED)"
                   *ngIf="value === state.UNCHECKED">check_box_outline_blank</a>
                <a class="material-icons checkbox" [ngClass]="{'disabled': disabled, 'checked': !disabled}"
                   (click)="handleValueChange(state.UNCHECKED)" *ngIf="value === state.CHECKED">check_box</a>
                <a class="material-icons checkbox" [ngClass]="{'disabled': disabled, 'checked': !disabled}"
                   (click)="handleValueChange(state.CHECKED)" *ngIf="value === state.CHECKED_PARTIALLY">indeterminate_check_box</a>
            </label>
            <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
        </div>
        <div *ngIf="validationMessageKey != undefined" class="new-form-field-error-message">
            {{ validationMessageKey | translate }}
        </div>
    </div>
</div>
