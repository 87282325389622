import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {WindowSystemModelService} from './window-system-model-service';
import {WindowSystemModel} from './WindowSystemModel';

@Component({
    selector: 'app-window-system-model',
    templateUrl: './window-system-model.component.html',
    providers: [WindowSystemModelService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemModelComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    selectedWindowSystemModel: WindowSystemModel;
    windowSystemModels: WindowSystemModel[];
    editedWindowSystemId: number;
    editedSortIndex: number;
    editedModel: WindowSystemModel;

    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    displayModelEditor = false;
    displayChangeSortIndexDialog = false;

    @ViewChild('dt') datatable;

    constructor(private windowSystemModelService: WindowSystemModelService,
                private translatedSelectItemService: TranslatedSelectItemService,
                public translate: TranslateService,
                private errorHandler: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WindowSystemModelComponent', false);
        this.selectedWindowSystemModel = new WindowSystemModel();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initShownColumns();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);

        return this.windowSystemModelService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('WindowSystemModelComponent `getPage` success:', data);
                    this.windowSystemModels = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedWindowSystemModel =
                        this.restoreSelectionAfterLoad(this.selectedWindowSystemModel, this.windowSystemModels, event);
                },
                error: error => {
                    console.error('WindowSystemModelComponent `getPage` error:', error);
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    console.info('WindowSystemModelComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    onRowSelect(event: any): void {
        this.selectedWindowSystemModel = _.clone(event.data);
        this.editedWindowSystemId = event.data.windowSystemId;
        this.setDisplayModelEditor(true);
        this.keepSelectedItemIndex(event);
    }

    setDisplayModelEditor(display: boolean): void {
        if (this.displayModelEditor !== display) {
            this.displayModelEditor = display;
            this.changeDetector.markForCheck();
        }
    }

    showDialogToAdd(): void {
        this.editedWindowSystemId = undefined;
        this.setDisplayModelEditor(true);
        this.changeDetector.markForCheck();
    }

    submit(): void {
    }

    changeActiveStatus(model: WindowSystemModel, active: boolean) {
        this.editedModel = _.clone(model);
        this.editedModel.active = active;
        this.edit();
    }

    changeSortIndex() {
        this.editedModel.sortIndex = this.editedSortIndex;
        this.edit();
    }

    edit(): void {
        this.validateForm();

        if (this.validationErrorsPresent()) {
            this.changeDetector.markForCheck();
            return;
        }

        if (this.isSaveInProgress()) {
            return;
        } else {
            this.setSaveInProgress(true);
        }

        this.windowSystemModelService.edit(this.editedModel.id, this.editedModel)
            .pipe(finalize(() => this.setSaveInProgress(false)))
            .subscribe({
                next: () => {
                    this.reloadDatatable();
                    this.showSuccessMessage();
                    this.closeSortIndexDialog();
                },
                error: error => {
                    this.validationErrors = this.errorHandler.handle(error);
                    this.changeDetector.markForCheck();
                    console.error('WindowSystemModelComponent `getPage` error:', error);
                }
            });
    }

    validateForm(): void {
        this.validationErrors = {};

        this.validationErrors['sortIndex'] = MultiValidator.of('error.windowSystemModelDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.editedModel.sortIndex);
    }

    closeSortIndexDialog(): void {
        this.validationErrors = {};
        this.displayChangeSortIndexDialog = false;
        this.changeDetector.markForCheck();

    }

    displaySortIndexDialog(model: WindowSystemModel): void {
        this.editedModel = _.clone(model);
        this.editedSortIndex = model.sortIndex;
        this.displayChangeSortIndexDialog = true;
        this.changeDetector.markForCheck();

    }

    showSuccessMessage() {
        this.growlMessageController.info('WINDOW_SYSTEM_MODEL.UPDATED');
    }
}
