import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {GlazingPackageFieldUsage} from "../catalog-field-usage";
import {CatalogItemTagsService} from '../catalog-item-tags/catalog-item-tags.service';
import {DistanceFrameService} from '../distance-frame/distance-frame.service';
import {GlassService} from '../glass/glass.service';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {AbstractGlazingPackageComponent} from './abstract-glazing-package.component';
import {GlazingPackage, GlazingPackageTarget} from './glazing-package';
import {GlazingPackageService} from './glazing-package.service';

@Component({
    selector: 'app-glazing-package',
    templateUrl: './glazing-package.component.html',
    providers: [GlazingPackageService, DataServiceHelper, TranslatedSelectItemService, GlassService, DistanceFrameService,
        CatalogItemTagsService, WindowSystemDefinitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingPackageComponent extends AbstractGlazingPackageComponent<GlazingPackage, GlazingPackageService> implements OnInit {

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, GlazingPackageService, 'GLAZING_PACKAGE', 'GlazingPackage');
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterHasGlazing = this.translatedSelectItemService.buildUnsortedDropdown(['YES', 'NO'], 'GENERAL.', '');
        this.initDefaultSortOrder();
        this.fieldUsage = new GlazingPackageFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        const catalogElement = this.target === GlazingPackageTarget.SYSTEM_DEFINITION ? CatalogElement.SYSTEM_GLAZING_PACKAGES
            : CatalogElement.SYSTEM_DECORATIVE_GLAZING_PACKAGES;
        this.editCatalogPermitsService.getPermitsByCatalogElement(catalogElement).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getNewItem(): GlazingPackage {
        return new GlazingPackage(this.target);
    }

    submit() {
        if (!this.validationErrorsPresent()) {
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item);
            } else {
                observable = this.itemService.saveItem(this.item);
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    getGlazingPackage(glazingId: number) {
        this.itemService.getItem(glazingId).subscribe({
            next: item => {
                this.item = item;
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }
}
