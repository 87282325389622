<ng-template #defaultLabel>
    <div class="new-form-field-input-container">
        <div class="new-form-row">
            <div class="p-dialog-buttonpane">
                <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate: {
        maxSize: formatSize(maxSize),
        accepted: accept,
        maxWidth: (maxWidth || DEFAULT_MAX_WIDTH),
        maxHeight: (maxHeight || DEFAULT_MAX_HEIGHT),
        widthToHeightRatio: formatWidthToHeightRatio(widthToHeightRatio)
    } }}
    </div>
</ng-template>
<ng-template #defaultDownloadButton>
    <div class="new-form-row" *ngIf="showDownloadButton()">
        <a href="#" (click)="downloadFile()">{{value.name}}</a>
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container">
        <div class="new-form-row">
            <div class="p-dialog-buttonpane flex-row">
                <button type="button" (click)="openFileBrowser()" [disabled]="disabled" (focus)="handleFocus($event)"
                        (blur)="handleBlur($event)">
                    {{ (src ? changeButtonLabel : addButtonLabel) | translate}}
                </button>
                <button *ngIf="showDeleteButton()" type="button" (click)="deleteFile()" [disabled]="disabled"
                        (focus)="handleFocus($event)" (blur)="handleBlur($event)">
                    {{ deleteButtonLabel | translate}}
                </button>

                <button *ngIf="showImageCropper && src && image" type="button" (click)="imageCropper.crop(); cropFile()" [disabled]="disabled"
                        (focus)="handleFocus($event)" (blur)="handleBlur($event)">
                    {{ cropButtonLabel | translate}}
                </button>
            </div>
        </div>
        <div class="new-form-row">
            <div class="p-dialog-buttonpane">
                <ng-container *ngIf="src && image">
                    <img *ngIf="!showImageCropper" id="imagePreview" [src]="src"
                         [ngStyle]="{'max-width': '100%', 'max-height.px': maxHeight || DEFAULT_MAX_HEIGHT}" />

                    <ng-container *ngIf="showImageCropper">
                        <image-cropper #imageCropper
                                       [ngStyle]="{'max-width': '100%', 'max-height.px': maxHeight || DEFAULT_MAX_HEIGHT}"
                                       [imageFile]="imageFile"
                                       [format]="cropperFormat"
                                       [maintainAspectRatio]="widthToHeightRatio != undefined"
                                       [aspectRatio]="widthToHeightRatio"
                                       [resizeToWidth]="maxWidth"
                                       [resizeToHeight]="maxHeight"
                                       [onlyScaleDown]="true"
                                       [autoCrop]="false"
                                       [output]="'base64'"
                                       (imageCropped)="imageCropped($event)"
                        ></image-cropper>
                    </ng-container>
                </ng-container>

                <input type="file" [attr.id]="inputId" class="hiddenInput" [accept]="accept"
                       (change)="onFileChangeEvent($event)" [required]="required" [disabled]="disabled">
                <ng-container *ngTemplateOutlet="downloadButtonTemplate"></ng-container>
            </div>
        </div>
        <ng-container *ngIf="validationMessageKey != undefined">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
