<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>

        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container">
        <!-- [dateFormat]="undefined" is not a mistake,
             it overrides the default format ('mm/dd/yy') that, if set, overrides format from locale
        -->
        <p-calendar [inputId]="inputId" [disabled]="disabled" [inline]="inline" appendTo="body" [readonlyInput]="true"
                    (onFocus)="handleFocus($event)" (onBlur)="handleBlur($event)" (onSelect)="handleOnSelect($event)"
                    [minDate]="minDateLocal" [maxDate]="maxDateLocal" [dateFormat]="calendarLocale.dateFormat"
                    [firstDayOfWeek]="calendarLocale.firstDayOfWeek"
                    [showTime]="showTime" [showWeek]="true" [ngModel]="dateLocal"
                    (ngModelChange)="handleChange($event)"></p-calendar>
        <ng-container *ngIf="validationMessageKey != undefined">
            <span class="new-form-field-error-icon material-icons">error</span>
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
