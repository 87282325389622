import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {AddonFor} from "../../../../../window-designer/enums/AddonFor";
import {Permissions} from '../../../../auth/permission.service';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {MotlawaIntegrationInfo} from '../../../../common/MotlawaIntegrationInfo';
import {TranslatedSelectItemBuilder} from "../../../../common/service/translated.select.item.builder";
import {Supplier} from '../../../supplier/supplier';
import {ItemForCatalogLinking} from '../../single-system-checkbox-crud/item-for-catalog-linking';
import {Addon, AutoOption} from "../addon";
import {PriceType} from "../PriceType";
import {Currencies} from "../../../../currencies";
import {AddonCategoryEnum, AddonTarget} from "../../../../../window-designer/enums/AddonCategoryEnum";
import {AddonAvailableIn} from "../../../../../window-designer/enums/addon-available-in.enum";
import {SupportedLanguages} from "../../../../supportedLanguages";
import {AddonCustomFieldsUtil} from "../addon-custom-fields-util";
import {AddonField} from "../../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {AddonFieldUsage} from "../../catalog-field-usage";
import {AddonHelperInterface} from "../../catalog-field-usage-helper-interfaces";
import {TranslatedSelectItemService} from "../../../../common/service/translated-select-item.service";
import {Observable} from "rxjs";

@Component({
    selector: 'app-addon-form',
    templateUrl: './addon-form.component.html',
    styleUrls: ['./addon-form.component.css', '../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonFormComponent implements OnChanges, OnInit, AddonHelperInterface {

    supportedLanguages = SupportedLanguages.languages;

    @Input()
    addon: Addon;

    @Input()
    isNew: boolean;

    @Input()
    addonCategoryTarget: AddonTarget;

    @Input()
    motlawaInfo: MotlawaIntegrationInfo;

    @Input()
    validationErrors: any;

    @Input()
    categories: SelectItem[];

    @Input()
    addonFor: SelectItem[];

    @Input()
    suppliers: SelectItem[];

    @Input()
    coreColors: SelectItem[];

    @Input()
    insideColors: SelectItem[];

    @Input()
    outsideColors: SelectItem[];

    @Input()
    quantityTypes: SelectItem[];

    @Input()
    windowSystems: ItemForCatalogLinking[];

    priceTypes: SelectItem[];

    @Input()
    file: File;

    @Input()
    currency: Currencies;

    @Output()
    fileChange = new EventEmitter<File>();

    @Output()
    tileChange = new EventEmitter<File>();

    @Input()
    additionalIcon: File;

    @Input()
    tile: File;

    @Input()
    editPermits: FieldLimitation[];

    @Output()
    readonly additionalIconChange = new EventEmitter<File>();

    availableInOptions: SelectItem[];
    filteredAvailableInOptions: SelectItem[];
    filteredWindowSystems: SelectItem[] = [];
    gateNotSidebarCategory = AddonCategoryEnum.GATE_NOT_SIDEBAR;
    gateSidebarCategory = AddonCategoryEnum.GATE_SIDEBAR;

    fieldUsage: AddonFieldUsage;
    AddonField = AddonField;

    autoOptions: Observable<SelectItem[]>;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public permissions: Permissions,
                public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                public changeDetector: ChangeDetectorRef) {
        let selectItemBuilder = TranslatedSelectItemBuilder.create();
        Object.keys(PriceType).forEach(priceType => selectItemBuilder.add('ADDONS.FORM.PRICE_TYPE.' + priceType, priceType));
        this.priceTypes = selectItemBuilder.build();
        let selectItemBuilderAvailable = TranslatedSelectItemBuilder.create();
        Object.keys(AddonAvailableIn).forEach(availableIn => selectItemBuilderAvailable.add('ADDONS.FORM.AVAILABLE_IN.' + availableIn, availableIn));
        this.availableInOptions = selectItemBuilderAvailable.build();
        this.fieldUsage = new AddonFieldUsage(this);
    }

    ngOnInit() {
        switch (this.addonCategoryTarget) {
            case "Config":
                this.addon.category = AddonCategoryEnum[AddonCategoryEnum.CONFIG_ADDON_SIDEBAR];
                this.addon.addonFor = AddonFor[AddonFor.CONFIG_ADDON];
                this.addon.availableIn = AddonAvailableIn.SIDEBAR;
                this.addon.unitWeight = 0;
                if (this.isNew) {
                    this.addon.price.type = PriceType[PriceType.AMOUNT];
                    this.priceTypeChanged();
                    this.addon.price.value = 0;
                }
                this.addon.chooseColor = false;
                this.addon.defaultQuantity = 1;
                this.addon.availableIn = AddonAvailableIn.SIDEBAR;
                this.buildAutoOptions();
                break;
            case "Gate":
                this.addon.addonFor = AddonFor[AddonFor.GATE];
                if (this.isNew) {
                    this.addon.price.type = PriceType.DROOLS;
                    this.priceTypeChanged();
                }
                this.addon.chooseColor = false;
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('addon' in changes) {
            const addonChange = changes['addon'];
            if ((addonChange.currentValue as Addon).supplier != undefined) {
                this.filterWindowSystems();
            } else {
                this.filteredWindowSystems = [];
            }
            const addonCategory = AddonCategoryEnum[(addonChange.currentValue as Addon).category];
            this.filteredAvailableInOptions = this.filterAvailableInOptions(addonCategory);
        }
    }

    onCategoryChange(category: AddonCategoryEnum): void {
        this.addon.category = category;
        this.filteredAvailableInOptions = this.filterAvailableInOptions(category);
        if (category === AddonCategoryEnum.GATE_SIDEBAR) {
            this.addon.defaultQuantity = 1;
            this.addon.removable = false;
            this.addon.changeQuantity = false;
            this.addon.price.type = PriceType.DROOLS;
            this.priceTypeChanged();
        }
        if (this.addonCategoryTarget === 'Gate' ||  this.addonCategoryTarget === 'Config') {
            this.addon.availableIn = category === AddonCategoryEnum.GATE_NOT_SIDEBAR ? AddonAvailableIn.DIALOG : AddonAvailableIn.SIDEBAR;
        }
    }

    onAddonForChange(addonFor: string): void {
        this.addon.addonFor = addonFor;
        if (this.addon.addonFor !== AddonFor[AddonFor.BULK]) {
            this.addon.chooseColor = false;
            this.addon.coreColors = [];
            this.addon.insideColors = [];
            this.addon.insideColors = [];
            this.addon.removable = true;
            if (this.addon.addonFor === AddonFor[AddonFor.WING_FUNCTIONS_GROUP]) {
                this.addon.price.type = PriceType[PriceType.PRICE_GROUP];
                this.addon.changeQuantity = false;
                this.addon.defaultQuantity = 1;
            }
            this.addon.profitMarginWindowSystemId = undefined;
        } else if (this.addon.price.type === 'PRICE_GROUP') {
            this.addon.chooseColor = true;
        }
        this.setDefaultOptionsForAddon();
    }

    onSupplierChange(supplier: Supplier): void {
        this.addon.supplier = supplier;
        if (supplier != null) {
            this.filterWindowSystems();
        }
    }

    isWingFunctionsGroup(): boolean {
        return this.addon.addonFor === AddonFor[AddonFor.WING_FUNCTIONS_GROUP];
    }

    onPriceTypeChange(priceType: string): void {
        this.addon.price.type = priceType;
        if (priceType === 'PRICE_GROUP' && this.addon.addonFor === AddonFor[AddonFor.BULK]) {
            this.addon.chooseColor = true;
        }
        this.priceTypeChanged();
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onTileChange(tile: File): void {
        this.tileChange.emit(tile);
        this.tile = tile;
    }

    private setDefaultOptionsForAddon() {
        if (this.addon.addonFor === AddonFor[AddonFor.BULK]) {
            this.addon.changeQuantity = true;
            this.addon.availableIn = AddonAvailableIn.BOTH;
            this.priceTypeChanged();
            this.addon.price.type = undefined;
        } else {
            this.clearColorParams();
        }
    }

    private clearColorParams() {
        this.addon.chooseColor = false;
        this.addon.insideColors = [];
        this.addon.outsideColors = [];
    }

    private priceTypeChanged() {
        this.validationErrors['price.type'] = undefined;
        this.validationErrors['price.value'] = undefined;
        this.validationErrors['price.venskaBuyNetPrice'] = undefined;
        this.validationErrors['price.venskaSellNetPrice'] = undefined;
        this.validationErrors['price.priceGroupDesc'] = undefined;
        this.addon.price.priceGroupDesc = undefined;
        this.addon.price.value = undefined;
        this.addon.price.venskaSellNetPrice = undefined;
    }

    isAddonForWindow() {
        return this.addon.addonFor === AddonFor[AddonFor.WINDOW];
    }

    isWindowAddon() {
        return this.addonCategoryTarget === 'Window';
    }

    isGateAddon() {
        return this.addonCategoryTarget === 'Gate';
    }

    isConfigAddon() {
        return this.addonCategoryTarget === 'Config';
    }

    canHavePrice() {
        return this.addon.category !== AddonCategoryEnum.GATE_SIDEBAR;
    }

    isChangeQuantityDisabled(): boolean {
        return AddonFor[this.addon.addonFor] === AddonFor.BULK || !this.addon.removable ||
            this.addon.addonFor === AddonFor[AddonFor.WING_FUNCTIONS_GROUP];
    }

    isRemovableDisabled(): boolean {
        return AddonFor[this.addon.addonFor] === AddonFor.BULK;
    }

    clearError(controlName: string) {
        this.validationErrors[controlName] = undefined;
        this.changeDetector.markForCheck();
    }

    removableChange(): void {
        if (!this.addon.removable) {
            this.addon.changeQuantity = false;
        }
    }

    getPriceGroupHelpTooltip(): string {
        if (this.isWingFunctionsGroup()) {
            return 'ADDONS.FORM.PRICE_GROUP_WING_FUNCTON_TOOLTIP';
        } else if (this.addon.price.type === PriceType[PriceType.PRICE_GROUP]) {
            return 'ADDONS.FORM.PRICE_GROUP_TOOLTIP';
        }
        return '';
    }

    formatValidationMessage(value: string): string {
        return value != undefined ? value.replace(`[${this.translate.currentLang}]`, '') : undefined;
    }

    handleValidationMessageChange(value: string, key: string): void {
        this.validationErrors[key] = value;
        this.changeDetector.markForCheck();
    }

    private filterWindowSystems() {
        this.filteredWindowSystems = this.windowSystems.filter(windowSystem => windowSystem.supplierId === this.addon.supplier.id)
            .map(windowSystem => ({label: windowSystem.names[this.translate.currentLang], value: windowSystem.id}));
    }

    isWindowSystemSelectionDisabled() {
        return AddonFor[this.addon.addonFor] !== AddonFor.BULK;
    }

    showCustomValueField(): boolean {
        return AddonCustomFieldsUtil.showCustomValueField(this.addon);
    }

    filterAvailableInOptions(selectedCategory: AddonCategoryEnum): SelectItem[] {
        switch (selectedCategory) {
            case AddonCategoryEnum.GATE_SIDEBAR:
                return this.availableInOptions.filter(option => option.value !== AddonAvailableIn.DIALOG);
            case AddonCategoryEnum.GATE_NOT_SIDEBAR:
                return [];
            default:
                return this.availableInOptions;
        }
    }

    buildAutoOptions() {
        this.autoOptions = this.translatedSelectItemService.buildUnsortedDropdown(AutoOption, 'AUTO_OPTIONS.', null);
    }
}
