<div class="list-content webshop-glazing-help">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.WEBSHOP_HELP.GENERAL' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #table [value]="items" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="columnHeaderClasses('language')">
                        <span>{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.LANGUAGE' | translate }}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-help let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex"
                    (dblclick)="doOnRowSelect({data: help, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    [pSelectableRow]="help" [pSelectableRowIndex]="rowIndex" [app-table-keyboard-navigation]="help"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({data: help, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    class="datatable-data">
                    <td>
                        {{ help.language | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template #paginatorPageInfoTemplate>
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
        <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                     styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                     [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                     [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
    </div>
</div>

<app-wizard-dialog *ngIf="displayDialog" header="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.EDIT' | translate }}"
                   (onCancel)="displayDialog = false" (onComplete)="submit()">
    <app-wizard-step>
        <div class="settings-form-wrapper">
            <div class="new-form-600">
                <div class="new-form-row">
                    <app-select inputId="webshop-sizing-language"
                                label="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.LANGUAGE' | translate }}"
                                [(validationMessageKey)]="validationErrors['language']" [options]="supportedLanguages"
                                [translateLabels]="true" [required]="true" [(ngModel)]="help.language"></app-select>
                </div>
                <div class="new-form-row">
                    <app-input-text inputId="webshop-sizing-file-preview"
                                    label="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.FILE' | translate }}"
                                    [ngModel]="help.amazonS3FileName" disabled></app-input-text>
                </div>
                <div *ngIf="canUploadToAmazon" class="new-form-row">
                    <app-file-upload inputId="webshop-sizing-file-upload-input"
                                     label="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.UPLOAD_FILE' | translate }}"
                                     addButtonLabel="{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.SELECT_FILE_UPLOAD' | translate }}"
                                     [(validationMessageKey)]="validationErrors['amazonS3File']" [maxSize]="10485760"
                                     [image]="false" accept="video/*" [ngModel]="uploadedFile"
                                     (ngModelChange)="handleUploadedFileChange($event)"></app-file-upload>
                </div>
                <div class="new-form-row">
                    <div>
                        <h3>{{ 'SETTINGS.SECTION.WEBSHOP_HELP.FORM.CHOOSE_FILE_ON_AMAZON' | translate }}</h3>
                        <p-listbox [options]="unusedAmazonS3Files" [ngModel]="selectedUnusedFile"
                                   (ngModelChange)="handleUnusedFileSelectionChange($event)"></p-listbox>
                    </div>
                </div>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
