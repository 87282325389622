import * as _ from 'underscore';
import {DrawingData} from '../../../../../../window-designer/drawing-data/drawing-data';
import {ConfigurableAddon} from '../../../../../../window-designer/entities/ConfigurableAddon';
import {ProfilesCompositionDistances} from '../../../../../../window-designer/profiles-composition-distances';
import {Profile} from '../../../../window-system/profile/profile';
import {
    WindowSystemDefinition,
    WindowSystemsFrameProfilesList
} from '../../../../window-system/window-system-definition/window-system-definition';
import {ConfigurableAddonUtils} from '../../../window-editor/drawing-tool/ConfigurableAddonUtils';
import {ConfigurableAddonPositionModel} from '../../../window-editor/sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {OfferPositionModel} from './OfferPositionModel';

export class BulkChangesHelper {

    public static getSystemsAndProfilesGroupedBySystemId(responseList: {
                                                             system: WindowSystemDefinition,
                                                             profiles: WindowSystemsFrameProfilesList
                                                         }[]): Map<number, { system: WindowSystemDefinition, profiles: Profile[] }> {
        let response = new Map<number, { system: WindowSystemDefinition, profiles: Profile[] }>();
        responseList.forEach(data => {
            response.set(data.system.id, {system: data.system, profiles: data.profiles.data});
        });
        return response;
    }

    public static getDatasGroupedBySystemIds(positionDataPairs: OfferPositionModel[]): Map<number, OfferPositionModel[]> {
        let usedSystemIds = _.uniq(positionDataPairs.map(ud => ud.mappedData.windowSystemId));
        let datasBySystemId = new Map<number, OfferPositionModel[]>();
        usedSystemIds.forEach(wsId => {
            datasBySystemId.set(wsId, positionDataPairs.filter(ud => ud.mappedData.windowSystemId === wsId));
        });
        return datasBySystemId;
    }

    public static getRelevantConfigurableAddonsForWindow(configurableAddons: OfferPositionModel[],
                                                         window: OfferPositionModel): ConfigurableAddonPositionModel[] {
        return configurableAddons.filter(
            config => config.offerPosition.parentOfferPositionId === window.offerPosition.id)
            .map(config => new ConfigurableAddonPositionModel(config.offerPosition, config.mappedData as ConfigurableAddon));
    }

    public static getResizedConfigurableAddonsForWindow(configurableAddons: OfferPositionModel[],
                                                        profileCompositionDistances: ProfilesCompositionDistances,
                                                        window: OfferPositionModel): ConfigurableAddonPositionModel[] {
        let currentWindowConfigs = BulkChangesHelper.getRelevantConfigurableAddonsForWindow(configurableAddons, window);
        ConfigurableAddonUtils.resizeAll(window.mappedData as DrawingData, currentWindowConfigs, profileCompositionDistances, false);
        return currentWindowConfigs;
    }

    public static constructionSpecificationChanged(model: OfferPositionModel): boolean {
        let oldSpecification = (model.mappedDataBeforeChanges as DrawingData).specification;
        let newSpecification = (model.mappedData as DrawingData).specification;
        return newSpecification.frameProfileId !== oldSpecification.frameProfileId
            || newSpecification.doorstepId !== oldSpecification.doorstepId
            || newSpecification.channelSectionId !== oldSpecification.channelSectionId
            || newSpecification.constructionalMullionId !== oldSpecification.constructionalMullionId
            || newSpecification.movablePostId !== oldSpecification.movablePostId;
    }
}
