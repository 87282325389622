<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.SUBSYSTEM_SALES_TARGET' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-60vw">
            <div class="new-form-row">
                <div class="new-form-row">
                    <app-input-number inputId="currentYearSalesTargetInput"
                                      label="{{ 'SUBSYSTEM-DETAILS.FORM.CURRENT_YEAR_SALES_TARGET' | translate }}"
                                      [(validationMessageKey)]="validationErrors['currentYearSalesTarget']" [min]="0"
                                      [allowOnlyIntegers]="true"
                                      [(ngModel)]="currentYearSalesTarget"></app-input-number>
                </div>
            </div>
            <ng-container *ngIf="canChooseSubsystems">
                <div class="new-form-row">
                    <p-pickList #pickList [source]="availableSubsystems" [target]="selectedSubsystems"
                                sourceHeader="{{ 'DASHBOARD.REPORTS.SETTINGS.SUBSYSTEMS.AVAILABLE' | translate }}"
                                targetHeader="{{ 'DASHBOARD.REPORTS.SETTINGS.SUBSYSTEMS.SELECTED' | translate }}"
                                [responsive]="true" filterBy="name,defaultCurrency,clientManager.login"
                                [sourceStyle]="{'height': '40vh'}" [targetStyle]="{'height': '40vh'}"
                                [showSourceControls]="false" [showTargetControls]="false">
                        <ng-template let-subsystem pTemplate="item">
                            <div class="subsystem-picklist-row">
                                <div class="subsystem-picklist-column-name">{{ subsystem.name }}</div>
                                <div class="subsystem-picklist-column-currency">{{ subsystem.defaultCurrency }}</div>
                                <div class="subsystem-picklist-column-manager">{{ subsystem.clientManager.login }}</div>
                            </div>
                        </ng-template>
                    </p-pickList>
                </div>
                <div class="new-form-row">
                    <div class="new-form-field">
                        <app-simple-button buttonId="showHistoryButton1"
                                           label="{{ 'SUBSYSTEM-DETAILS.FORM.SHOW_SALES_TARGET_HISTORY' | translate }}"
                                           icon="history" type="main-action" [size]="40"
                                           [disabled]="!pickList?.selectedItemsSource?.length || changeHistory != undefined"
                                           align="center"
                                           (onClick)="showHistory(pickList.selectedItemsSource[0])"></app-simple-button>
                    </div>
                    <div class="new-form-field">
                        <app-simple-button buttonId="showHistoryButton2"
                                           label="{{ 'SUBSYSTEM-DETAILS.FORM.SHOW_SALES_TARGET_HISTORY' | translate }}"
                                           icon="history" type="main-action" [size]="40"
                                           [disabled]="!pickList?.selectedItemsTarget?.length || changeHistory != undefined"
                                           align="center"
                                           (onClick)="showHistory(pickList.selectedItemsTarget[0])"></app-simple-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button buttonId="saveButton" label="{{ 'GENERAL.SAVE' | translate }}"
                               icon="check" type="main-action" [size]="40"
                               [disabled]="currentYearSalesTarget == undefined || !hasSelectedSubsystem"
                               (onClick)="submit()"></app-simple-button>
        </div>
    </div>
</div>
<p-dialog #dialog [visible]="changeHistory != undefined" (visibleChange)="changeHistory = undefined">
    <p-header>
        <div class="p-dialog-title">{{ 'SUBSYSTEM-DETAILS.FORM.SALES_TARGET_HISTORY' | translate }}</div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <p-table [value]="changeHistory">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'SUBSYSTEM-DETAILS.FORM.SALES_TARGET_HISTORY_DATE' | translate }}</th>
                    <th>{{ 'SUBSYSTEM-DETAILS.FORM.SALES_TARGET_HISTORY_MODIFIED_BY' | translate }}</th>
                    <th>{{ 'SUBSYSTEM-DETAILS.FORM.SALES_TARGET_HISTORY_OLD_VALUE' | translate }}</th>
                    <th>{{ 'SUBSYSTEM-DETAILS.FORM.SALES_TARGET_HISTORY_NEW_VALUE' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-historyEntry>
                <tr>
                    <td>{{ historyEntry.modificationDate | momentCurrentLang | amDateFormat: 'L LT' }}</td>
                    <td>{{ historyEntry.modifiedBy }}</td>
                    <td>{{ historyEntry.oldValue != undefined ? historyEntry.oldValue : 'NULL' }}</td>
                    <td>{{ historyEntry.newValue != undefined ? historyEntry.newValue : 'NULL' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-close" label="{{ 'GENERAL.OK' | translate }}" type="main-action"
                               [size]="40" (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
