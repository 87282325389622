import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RotData} from "./RotData";

@Injectable()
export class RotDataService {

    private static readonly API_URL = 'rotData';

    constructor(private http: HttpClient) {
    }

    getRotData(offerId: number): Observable<RotData> {
        return this.http.get<object>(`${RotDataService.API_URL}/${offerId}`).pipe(map(RotData.fromJSON));
    }

    updateRotData(rotData: RotData, offerId: number): Observable<void> {
        return this.http.put<void>(`${RotDataService.API_URL}/${offerId}`, rotData);
    }

    createRotData(rotData: RotData): Observable<void> {
        return this.http.post<void>(`${RotDataService.API_URL}/${rotData.offerId}`, rotData);
    }
}
