<div id="container" [ngClass]="{'hidden-details': !showDetails }">
    <div class="one" id="one">
        <ng-content></ng-content>
    </div>

    <div class="two" id="two" [ngClass]="{'display-none': !showDetails}">
        <div class="two-top" id="two-top" [ngStyle]="{ 'height.%': resizableTopElementHeight * 100 }">
            <app-picture #picture [style.height]="'100%'" (pictureClicked)="handlePictureClick($event)"></app-picture>
        </div>

        <div *ngIf="otherInfo != undefined" id="other-info-preview" class="other-info-preview">
            <div class="other-info-header">{{ 'OFFER.POSITIONS.DIALOGS.SHOW_DESCRIPTION' | translate }}</div>
            <div *ngIf="otherInfoNeedsTooltip" pTooltip="{{ otherInfo }}" tooltipPosition="top"
                 tooltipStyleClass="other-info-tooltip">{{ otherInfoPreview }}...
            </div>
            <div *ngIf="!otherInfoNeedsTooltip">{{ otherInfo }}</div>
        </div>

        <div class="two-middle" id="two-middle">
            <app-window-properties #windowProperties></app-window-properties>
        </div>

        <div class="toggle-details" id="two-bottom" (click)="showDetails = !showDetails">
            <app-simple-button label="{{ 'OFFER.TABS.SHOW_SIDEBAR' | translate }}" [inlineLabel]="false"
                               [icon]="'last_page'" [size]="40"></app-simple-button>
        </div>
    </div>

    <div class="two toggle-details" [ngClass]="{'display-none': showDetails}" (click)="showDetails = !showDetails">
        <app-simple-button label="{{ 'OFFER.TABS.HIDE_SIDEBAR' | translate }}" [inlineLabel]="false"
                           [icon]="'first_page'" [size]="40"></app-simple-button>
    </div>
</div>
