<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUPS.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUP.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="addonCategoryTable">
        <p-column *ngIf="fieldUsage.show(AddonCategoryGroupField.NAME)"
                  field="name" sortField="{{ 'name.' + translate.currentLang }}" filterField="name"
                  header="{{ 'ADDON_CATEGORY_GROUP.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [sortable]="true" field="sortIndex" header="{{ 'ADDON_CATEGORY_GROUP.FORM.SORT_INDEX' | translate }}"></p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUP.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step label="{{ 'ADDON_CATEGORY_GROUP.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-addon-category-group-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-addon-category-group-form>
    </app-wizard-step>

</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_CATEGORY_GROUP.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'ADDON_CATEGORY_GROUP.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-addon-category-group-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-addon-category-group-form>
    </app-wizard-step>

    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveButton" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" (onClick)="submit()"></app-simple-button>
            <app-simple-button buttonId="cancelButton" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="cancel()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
