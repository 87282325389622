import {WindowAddon} from "../../../../window-designer/drawing-data/WindowAddon";
import {WindowAddonData} from "../../../../window-designer/drawing-data/WindowAddonData";
import {RandomIdGenerator} from "../../../../window-designer/utils/RandomIdGenerator";
import {GateSystemDefaults} from '../../settings/gate-system-defaults/gate-system-defaults';
import {GateSystemDefaultsState} from "../../settings/system-defaults/system-default-state";

export class GateData extends GateSystemDefaults implements WindowAddonData {
    generatedId: string = RandomIdGenerator.generate();
    width: number;
    height: number;
    lintelHeight: number;
    wall1: number;
    wall2: number;
    gateSystemId: number;
    usedGlobalSettingsLevel: string;
    usedGlobalSettingsChanged: boolean;
    addons: WindowAddon[] = [];

    constructor() {
        super();
        this.generatedId = RandomIdGenerator.generate();
    }

    static applyDefaults(data: GateData, defaults: GateSystemDefaultsState): void {
        const level = GateSystemDefaultsState.getLevel(defaults);
        data.railSystemId = defaults.value && defaults.value.railSystemId || undefined;
        data.wall1 = defaults.value && defaults.value.wall1 || undefined;
        data.wall2 = defaults.value && defaults.value.wall2 || undefined;
        data.coreColorId = defaults.value && defaults.value.coreColorId || undefined;
        data.externalColorId = defaults.value && defaults.value.externalColorId || undefined;
        data.internalColorId = defaults.value && defaults.value.internalColorId || undefined;
        data.gatePanelTypeId = defaults.value && defaults.value.gatePanelTypeId || undefined;
        data.control = defaults.value && defaults.value.control || undefined;
        data.sidebarAddons = defaults.value && defaults.value.sidebarAddons || undefined;
        data.usedGlobalSettingsLevel = level;
        if (data.width == null && level) {
            data.width = defaults.value.width;
        }
        if (data.height == null && level) {
            data.height = defaults.value.height;
        }
        if (data.lintelHeight == null && level) {
            data.lintelHeight = defaults.value.lintelHeight;
        }
    }
}
