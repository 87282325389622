<div *ngIf="!displayWebshopChargeEditor" class="list-content">
    <div class="table-header">
        <div class="title">{{(target === dataModificationTargets.WEBSHOP_CHARGE ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_CHARGES.LIST' : 'ADMIN_PANEL.WINDOW_UPSELLING_SETTINGS.LIST') | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ (target === dataModificationTargets.WEBSHOP_CHARGE ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_CHARGE.ADD' : 'ADMIN_PANEL.WINDOW_UPSELLING_SETTINGS.ADD') | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>

    <p-dataTable #dt [value]="webshopCharges" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedWebshopCharge"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 id="webshopChargesTable">

        <p-column field="name" header="{{ 'WEBSHOP_CHARGE.FORM.NAME' | translate }}"
                  [sortable]="true"
                  [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.name[userLang]}}
            </ng-template>
        </p-column>

        <p-column field="windowSystemName" header="{{ 'WEBSHOP_CHARGE.FORM.WINDOW_SYSTEM_NAME' | translate }}"
                  [sortable]="true"
                  [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.windowSystemName[userLang]}}
            </ng-template>
        </p-column>

        <p-column field="sortIndex" [style]="{'max-width': '200px'}" header="{{ 'WINDOW_SYSTEM_MODEL.FORM.SORT_INDEX' | translate }}" [sortable]="true">
        </p-column>
        <p-column [style]="{'width': '100px'}">
            <ng-template let-item="rowData" pTemplate="body" >
                <app-simple-button icon="edit" type="main-action" [size]="36" [inlineLabel]="false"
                                   label="{{'WEBSHOP_CHARGE.FORM.EDIT_DETAILS' | translate }}"
                                   (onClick)="displaySortIndexDialog(item)"></app-simple-button>
            </ng-template>
        </p-column>

        <p-column field="active" header="{{ 'WEBSHOP_CHARGE.FORM.STATUS' | translate }}" [sortable]="false"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body" >
                {{(item.active ? 'WEBSHOP_CHARGE.FORM.ACTIVE' : 'WEBSHOP_CHARGE.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <p-column [style]="{'width': '100px'}">
            <ng-template let-item="rowData" pTemplate="body">
                <app-simple-button *ngIf="item.active" icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                   label="{{'WEBSHOP_CHARGE.FORM.DEACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, false)"></app-simple-button>
                <app-simple-button *ngIf="!item.active" icon="check" type="confirm" [size]="36" [inlineLabel]="false"
                                   label="{{'WEBSHOP_CHARGE.FORM.ACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, true)"></app-simple-button>

            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-window-editor *ngIf="displayWebshopChargeEditor" [sidebarOnlyMode]="true" [dataModificationMode]="target"
                   [webshopCharge]="selectedWebshopCharge"
                   (onWebshopChargeSave)="setDisplayWebshopChargeEditor(false)"
                   (onWebshopChargeCancel)="setDisplayWebshopChargeEditor(false)"></app-window-editor>

<p-dialog id="sort-index-change-dialog" [(visible)]="displayChangeSortIndexDialog"
          [resizable]="false" [modal]="true" [focusOnShow]="false"
          header="{{ 'WEBSHOP_CHARGE.EDIT_DETAILS_DIALOG_HEADER' | translate }}"
          (onHide)="closeSortIndexDialog()">

    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <div class="new-form-row">
            <app-input-number inputId="sortIndex"
                              label="{{ 'WEBSHOP_CHARGE.FORM.SORT_INDEX' | translate }}"
                              [(validationMessageKey)]="validationErrors['sortIndex']"
                              [(ngModel)]="editedSortIndex"></app-input-number>
        </div>

        <p-tabView class="full-width-container">
            <p-tabPanel *ngFor="let lang of supportedLanguages" header="{{lang.name | translate}}">
                <div class="new-form-row">
                    <app-text-area inputId="{{'info_' + lang.code}}"
                                   label="{{ 'WEBSHOP_CHARGE.FORM.INFO' | translate: {lang: lang.code} }}"
                                   [(validationMessageKey)]="validationErrors['info_' + lang.code]" [maxlength]="512"
                                   [(ngModel)]="selectedWebshopCharge?.info && selectedWebshopCharge?.info[lang.code]"></app-text-area>
                </div>
            </p-tabPanel>
        </p-tabView>

        <ng-container *ngIf="target === dataModificationTargets.WEBSHOP_CHARGE">
            <app-file-upload inputId="image" [ngModel]="file"
                             (ngModelChange)="onFileChange($event, 'file')"
                             [maxSize]="150000" [maxWidth]="500" [maxHeight]="500"
                             [addButtonLabel]="'WEBSHOP_CHARGE.FORM.SELECT_FILE_UPLOAD'"
                             [changeButtonLabel]="'WEBSHOP_CHARGE.FORM.CHANGE_FILE_UPLOAD'"
                             [deleteButtonLabel]="'WEBSHOP_CHARGE.FORM.DELETE_FILE_UPLOAD'"
                             [(validationMessageKey)]="validationErrors['image']"></app-file-upload>

            <app-file-upload inputId="imageBig" [ngModel]="fileBig"
                             (ngModelChange)="onFileChange($event, 'fileBig')"
                             [maxSize]="3000000" [maxWidth]="5000" [maxHeight]="5000"
                             [addButtonLabel]="'WEBSHOP_CHARGE.FORM.SELECT_FILE_BIG_UPLOAD'"
                             [changeButtonLabel]="'WEBSHOP_CHARGE.FORM.CHANGE_FILE_BIG_UPLOAD'"
                             [deleteButtonLabel]="'WEBSHOP_CHARGE.FORM.DELETE_FILE_BIG_UPLOAD'"
                             [(validationMessageKey)]="validationErrors['imageBig']"></app-file-upload>
        </ng-container>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="window-system-defaults-replace-confirm-button"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="changeSortIndex()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeSortIndexDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

