import * as _ from 'underscore';
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {HandleState} from "../../../../../window-designer/drawing-data/HandleState";
import {SubWindowData} from "../../../../../window-designer/drawing-data/SubWindowData";
import {WindowAddon} from "../../../../../window-designer/drawing-data/WindowAddon";
import {WindowData} from "../../../../../window-designer/drawing-data/WindowData";
import {Wing} from "../../../../../window-designer/drawing-data/Wing";
import {ConfigAddonApplication} from '../../../../../window-designer/enums/ConfigAddonApplication';
import {HandleDataFactory} from "../../../../../window-designer/handle-data-factory";
import {DrawingDataSpecificationUtil} from "../../../../../window-designer/utils/DrawingDataSpecificationUtil";
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {VentilationUtils} from "../../../../../window-designer/utils/VentilationUtils";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {WindowNeighbourCheck} from "../../../../../window-designer/window-neighbour-check";
import {SubWindowTypeCode} from '../../../../../window-designer/window-types/subwindow-type-code';
import {WindowAttributes} from "../../../../../window-designer/window-types/window-attributes";
import {WindowSystemDefinition} from "../../../window-system/window-system-definition/window-system-definition";
import {CategoryWithAutoOptions} from "../../config-editor/config-editor.component";
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {ConfigurableAddonUtils} from "./ConfigurableAddonUtils";

export class ChangeBusinessTypeUtils {

    public static change(windowData: WindowData, newAttributes: WindowAttributes, data: DrawingData, windowSystem: WindowSystemDefinition) {
        windowData.typeCode = newAttributes.typeCode;
        for (let i = 0; i < windowData.subWindows.length; i++) {
            let subwindow = windowData.subWindows[i];
            subwindow.typeCode = newAttributes.subwindows[i].type;
            subwindow.isLeading = newAttributes.subwindows[i].isLeading;
            subwindow.profilesComposition = newAttributes.profilesComposition[i];

            this.changeHandle(subwindow);
            if (WindowCalculator.isSubWindowF(subwindow)) {
                subwindow.wing = undefined;
            } else {
                subwindow.wing = subwindow.wing || new Wing();
            }
            if (!VentilationUtils.canHaveCoupler(subwindow)) {
                subwindow.coupler = new WindowAddon();
            }
        }

        DrawingDataSpecificationUtil.clearUnnecessaryFields(data.specification, data.windows, {windowSystem: windowSystem});
        this.removeWinglessQuarterAddonsInWinged(data);
        if (!WindowNeighbourCheck.validateAfterBusinessTypeChange(windowData, data)) {
            let neighbourCheckError = new Error("WindowNeighbourCheck.validateAfterBusinessTypeChange failed.");
            neighbourCheckError.name = ErrorNames.BAD_NEIGHBOUR_BUSINNSS_TYPE;
            throw neighbourCheckError;
        }
    }

    private static removeWinglessQuarterAddonsInWinged(data: DrawingData): void {
        data.windows.forEach(window => {
            window.subWindows.filter(sw => sw.typeCode !== SubWindowTypeCode.F)
                .forEach(sw => {
                    sw.coupler = new WindowAddon();
                });
        });
    }

    private static changeHandle(subwindow: SubWindowData) {
        if (!WindowCalculator.hasHandle(subwindow)) {
            subwindow.handle = undefined;
        } else if (subwindow.handle == undefined || subwindow.handle.state === HandleState.DEFAULT) {
            HandleDataFactory.createStartingHandle(subwindow);
        }
    }

    public static changeConfigAddonsSideAttribute(windowData: WindowData, configAddonPositions: ConfigurableAddonPositionModel[], categoriesWithAutoOptions: CategoryWithAutoOptions[] = []): boolean {
        let configAddonSideChanged = false;
        let autoCategorySymbols = categoriesWithAutoOptions.map(category => category.symbol);
        for (let i = 0; i < windowData.subWindows.length; i++) {
            let subwindow = windowData.subWindows[i];
            let subwindowConfigAddonIds = _.flatten(subwindow.areasSpecification.map(area => area.configurableAddonIds));
            subwindowConfigAddonIds = subwindowConfigAddonIds.concat(subwindow.configurableAddonIds);

            let subwindowConfigAddons = configAddonPositions
                .filter(configAddonPosition => configAddonPosition.configurableAddon)
                .filter(configAddonPosition => configAddonPosition.configurableAddon.application === ConfigAddonApplication.AREA
                                               || configAddonPosition.configurableAddon.application === ConfigAddonApplication.SUBWINDOW)
                .filter(configAddonPosition => subwindowConfigAddonIds.find(
                    id => id === configAddonPosition.position.id || id === configAddonPosition.position.assignedId));

            _.flatten(subwindowConfigAddons
                .map(pos => pos.configurableAddon)
                .filter(addon => autoCategorySymbols.some(autoCategorySymbol => addon.configData.sidebarAddons[autoCategorySymbol] != null)))
                .forEach(addon => {
                    ConfigurableAddonUtils.setStronaAuto(categoriesWithAutoOptions, addon, subwindow.typeCode, true);
                });
        }
        return configAddonSideChanged;
    }
}
