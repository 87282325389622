import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {WindowEditorPositionData} from '../../window-editor-offer-interfaces';

export class GlazingPackagePositionModel {
    position: WindowEditorPositionData;
    window: DrawingData;

    constructor(position: WindowEditorPositionData, window: DrawingData) {
        this.position = position;
        this.window = window;
    }
}
