import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Directive, Injector, OnInit, Type} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {CrudItem} from '../../../common/crud-common/crudItem';
import {ErrorResponse} from '../../errors/errorResponse';
import {ListOfIds} from '../../ListOfIds';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ItemForCatalogLinking} from './item-for-catalog-linking';
import {SingleSystemCheckboxCrudService} from './single-system-checkbox-crud.service';
import {WindowSystemMaterialLinks} from './window-system-material-links';

@Directive()
export abstract class SingleSystemCheckboxCrudComponent<Item extends CrudItem, Service extends SingleSystemCheckboxCrudService<Item>>
    extends CrudCommonComponent<Item, Service> implements OnInit {

    public windowSystemDefinitionService: WindowSystemDefinitionService;
    public windowSystems: ItemForCatalogLinking[];
    public selectedWindowSystems: number[];

    protected constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                copySupported: boolean,
                serviceType: Type<Service>,
                translationKey: string,
                entityName: string) {
        super(injector, changeDetector, copySupported, serviceType, translationKey, entityName);
        this.windowSystemDefinitionService = injector.get(WindowSystemDefinitionService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getWindowSystems();
    }

    protected abstract getApiUrl(): string;

    showDialogToAdd() {
        this.clearAllCheckboxes();
        super.showDialogToAdd();
    }

    public prepareSystemIdsForRequest() {
        let systemIds = new ListOfIds();
        systemIds.ids = this.selectedWindowSystems;
        return systemIds;
    }

    public clearAllCheckboxes() {
        this.selectedWindowSystems = [];
    }

    public getWindowSystems() {
        return this.windowSystemDefinitionService.getSystemsForCatalogLinking().subscribe({
            next: data => {
                this.windowSystems = data;
            },
            error: error => {
                console.error('SingleSystemCheckboxComponent `getWindowSystems` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('SingleSystemCheckboxComponent `getWindowSystems` completed!');
            }
        });
    }

    public getLinkedWindowSystems(entityId: number): Observable<number[]> {
        return this.itemService.getLinkedSystems(this.getApiUrl() + '/linked/' + entityId);
    }

    public materialChange(entity: Item & WindowSystemMaterialLinks, material: string) {
        let materialField: keyof WindowSystemMaterialLinks;
        if (material === 'GATES') {
            materialField = 'gates';
        } else if (this.windowSystems.filter(system => system.material === material).length === 0) {
            this.growlMessageController.warning(this.translationKey + '.WARNING.NO_ACTIVE_WINDOW_SYSTEMS_TO_ASSIGN');
            this.cleanUpAndReload();
            return;
        } else {
            switch (material) {
                case 'PCV':
                    materialField = 'pcv';
                    break;
                case 'ALUMINIUM':
                    materialField = 'aluminium';
                    break;
                case 'WOOD':
                    materialField = 'wood';
                    break;
                case 'ALUMINIUM_WOOD_MIX':
                    materialField = 'aluminiumWoodMix';
                    break;
                default:
                    console.error('Unimplemented material mapping');
                    break;
            }
        }

        let state = entity[materialField] || 'CHECKED';

        if (state === 'CHECKED') {
            entity[materialField] = 'UNCHECKED';
        } else {
            entity[materialField] = 'CHECKED';
        }

        return this.itemService.changeSystemsAssociation(this.getApiUrl(), entity.id, material, state).subscribe({
            next: newStatus => {
                // api may return new status if the action it does is non-trivial
                // for example unlinking distance frames preserves all systems that use this frame in default glazing
                if (newStatus === 'CHECKED' || newStatus === 'CHECKED_PARTIALLY' || newStatus === 'UNCHECKED') {
                    entity[materialField] = newStatus;
                }
                // show growl only if changed
                if (state !== entity[materialField]) {
                    this.showSuccessMessage();
                }
            },
            error: error => {
                entity[materialField] = state;
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    let body = new ErrorResponse(error.error);

                    if (body.invalidFields && body.invalidFields['type']) {
                        this.growlMessageController.error(body.invalidFields['type']);
                    } else {
                        this.growlMessageController.error('PROFILE.ERROR.ASSIGN_TO_MATERIAL_FAILED');
                    }
                    this.changeDetector.markForCheck();
                } else {
                    console.error('`SingleSystemCheckboxComponent.changeSystemsAssociation` error:', error);
                    this.setErrors(error);
                }
            },
            complete: () => {
                console.info('`SingleSystemCheckboxComponent.changeSystemsAssociation` completed!');
                this.changeDetector.markForCheck();
            }
        });
    }

    protected afterSuccessLoad() {
        super.afterSuccessLoad();
        // triStateCheckbox fails to properly initialize the value during first change detection
        setTimeout(() => {
            this.changeDetector.markForCheck();
        }, 1);
    }

    protected editLinksAfterSave(): (entityId: number) => Observable<number> {
        return (entityId: number) => {
            return this.itemService.editLinks(this.getApiUrl(), entityId, this.prepareSystemIdsForRequest()).pipe(
                map(() => entityId));
        };
    }
}
