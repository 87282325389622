<p-accordionTab header="{{ coreColorsHeader | translate }}{{coreColorRequired && !coreColorsDisabled ? '*' : ''}}" [selected]="false"
                [disabled]="coreColorsDisabled"
                *ngIf="showCoreColors">
    <p-listbox [options]="coreColorsOptions" [(ngModel)]="coreColorsModel" (ngModelChange)="coreColorChanged()"
               [multiple]="true" id="core_colors"
               [disabled]="coreColorsDisabled"></p-listbox>
</p-accordionTab>
<div *ngIf="coreColorValidationMessage != undefined" class="new-form-field-label-error-spacer"></div>
<div *ngIf="coreColorValidationMessage != undefined" class="new-form-field-error-message">{{ coreColorValidationMessage
    | translate }}
</div>
<p-accordionTab header="{{ outsideColorsHeader | translate }}{{outsideColorRequired && !outsideColorsDisabled ? '*' : ''}}" [selected]="false"
                [disabled]="outsideColorsDisabled"
                *ngIf="showOutsideColors" id="outsideColorSectionAccordion">
    <p-listbox [options]="outsideColorsOptions" [(ngModel)]="outsideColorsModel" (ngModelChange)="outsideColorChanged()"
               [multiple]="true"
               id="outside_colors" [disabled]="outsideColorsDisabled"></p-listbox>
</p-accordionTab>
<div *ngIf="outsideColorValidationMessage != undefined" class="new-form-field-label-error-spacer"></div>
<div *ngIf="outsideColorValidationMessage != undefined" class="new-form-field-error-message">{{
    outsideColorValidationMessage | translate }}
</div>
<p-accordionTab header="{{ insideColorsHeader | translate }}{{insideColorRequired && !insideColorsDisabled ? '*' : ''}}" [selected]="false"
                [disabled]="insideColorsDisabled"
                *ngIf="showInsideColors" id="insideColorSectionAccordion">
    <p-listbox [options]="insideColorsOptions" [(ngModel)]="insideColorsModel" (ngModelChange)="insideColorChanged()"
               [multiple]="true"
               id="inside_colors" [disabled]="insideColorsDisabled"></p-listbox>
</p-accordionTab>
<div *ngIf="insideColorValidationMessage != undefined" class="new-form-field-label-error-spacer"></div>
<div *ngIf="insideColorValidationMessage != undefined" class="new-form-field-error-message">{{
    insideColorValidationMessage | translate }}
</div>
