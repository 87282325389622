import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OnceFlag} from '../../shared/once-flag';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent {

    @Input() header: string;
    @Input() text: string;
    @Input() confirmButtonLabel: string;
    @Input() hasCancelButton: boolean;

    @Output() onHide: EventEmitter<boolean> = new EventEmitter();

    private readonly dialogHideHelper = new OnceFlag();

    hide(withConfirmation: boolean) {
        this.dialogHideHelper.call(() => this.onHide.emit(withConfirmation));
    }
}
