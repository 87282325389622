<div class="new-form-600">
    <div *ngIf="fieldUsage.show(DimensionsField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'WINDOW_DIMENSIONS.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(DimensionsField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true">
        </app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(DimensionsField.WIDTH)" class="new-form-row">
        <app-input-number inputId="width"
                          label="{{ 'WINDOW_DIMENSIONS.FORM.WIDTH' | translate }}"
                          [disabled]="fieldUsage.disabled(DimensionsField.WIDTH)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['width']" [min]="1" [max]="99999"
                          [maxlength]="5" [required]="true" [ngModel]="item.width" (ngModelChange)="onWidthChange($event)"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(DimensionsField.HEIGHT)" class="new-form-row">
        <app-input-number inputId="height"
                          label="{{ 'WINDOW_DIMENSIONS.FORM.HEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(DimensionsField.HEIGHT)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['height']" [min]="1" [max]="99999"
                          [maxlength]="5" [required]="true" [ngModel]="item.height" (ngModelChange)="onHeightChange($event)"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(DimensionsField.SURFACE)" class="new-form-row">
        <app-input-number inputId="surface"
                          label="{{ 'WINDOW_DIMENSIONS.FORM.SURFACE' | translate }}"
                          [disabled]="fieldUsage.disabled(DimensionsField.SURFACE)"
                          [(validationMessageKey)]="validationErrors['surface']" [min]="1" [max]="99999"
                          [maxlength]="5" [(ngModel)]="item.surface"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(DimensionsField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'WINDOW_DIMENSIONS.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(DimensionsField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(DimensionsField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'WINDOW_DIMENSIONS.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(DimensionsField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>
</div>
