import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.css'],
    providers: [
    ]
})
export class GaugeChartComponent implements OnChanges {

    @Input() ordersCount: number;
    @Input() offersCount: number;
    @Input() gaugePercent: number;
    @Input() greenColor: string;
    @Input() backgroundColor: string;

    arrowRotation = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if ('gaugePercent' in changes) {
            const gaugePercent = changes['gaugePercent'];
            if (gaugePercent.currentValue) {
                this.arrowRotation = 270 * gaugePercent.currentValue;
            }
        }
    }
}
