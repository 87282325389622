<p-dialog [resizable]="false" [modal]="true" (onHide)="close()" [visible]="visible" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.ADD_ASSEMBLY' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-1200 datatable-container">
        <p-dataTable [paginator]="true" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                     [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                     [(selection)]="selectedItem" [lazy]="true" (onLazyLoad)="loadItemsLazy($event)"
                     emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}" [expandableRows]="true"
                     dataKey="template.id" [value]="itemList">
            <p-column>
                <ng-template pTemplate="header">
                    <app-checkbox [(ngModel)]="allSelected" id="selectAllCheckbox"
                                  (ngModelChange)="selectAllChange($event)"></app-checkbox>
                </ng-template>
                <ng-template pTemplate="body" let-data="rowData">
                    <app-checkbox [ngModel]="data.selected" id="{{'selectCheckbox'+data.template.name}}"
                                  (ngModelChange)="selectOne(data, $event)"></app-checkbox>
                </ng-template>
            </p-column>
            <p-column field="name" header="{{ 'ASSEMBLY.FORM.NAME' | translate }}" [sortable]="true"
                      [filter]="true" filterMatchMode="contains">
                <ng-template pTemplate="body" let-data="rowData">
                    {{ data.template.name }}
                </ng-template>
            </p-column>
            <p-column field="assemblyUnit" header="{{ 'ASSEMBLY.FORM.UNIT' | translate }}" [sortable]="true"
                      [filter]="true" [filterValues]="assemblyUnits | async">
                <ng-template pTemplate="body" let-data="rowData">
                    {{ ('ASSEMBLY.UNITS.' + data.template.assemblyUnit) | translate }}
                </ng-template>
            </p-column>
            <p-column header="{{ 'ASSEMBLY.FORM.NET_PRICE' | translate }}">
                <ng-template pTemplate="body" let-data="rowData">
                    {{ data.template.value | price: data.template.currency }}
                </ng-template>
            </p-column>
            <p-column header="{{ 'ASSEMBLY.FORM.GROSS_PRICE' | translate }}">
                <ng-template pTemplate="body" let-data="rowData">
                    {{ calculateGrossPrice(data.template.value) | price: data.template.currency }}
                </ng-template>
            </p-column>
            <p-column header="{{ 'ASSEMBLY.FORM.QUANTITY' | translate }}">
                <ng-template pTemplate="body" let-data="rowData">
                    <div class="assembly-quantity">
                        <input type="number" title="" pInputText min="1" step="1" id="{{'quantityInput'+data.template.name}}"
                               [ngModel]="data.position.quantity"
                               (ngModelChange)="quantityInputChange(data.position, $event)">
                        <app-simple-button *ngIf="isNotMeter(data.template)"
                                           label="{{ 'ASSEMBLY.FORM.FILL_QUANTITY_FROM_OFFER' | translate }}"
                                           [inlineLabel]="false" icon="refresh" type="action" [size]="40"
                                           (onClick)="fillQuantityFromOffer(data)"></app-simple-button>
                    </div>
                </ng-template>
            </p-column>
            <ng-template pTemplate="rowexpansion" let-assembly>
                <div class="new-form-row">
                    <app-text-area inputId="comment"
                                   label="{{ 'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION' | translate }}"
                                   [maxlength]="500"
                                   [(ngModel)]="assembly.position.otherInfo"></app-text-area>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorLeft">
                <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
            </ng-template>
        </p-dataTable>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="assemblySave" label="{{'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" [disabled]="!hasSelection()" (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>

</p-dialog>
