import {Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ValidationService} from "../../../common/service/validation.service";
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ZipCodeValidator} from '../../../common/zip-code-validator';
import {Supplier} from "../supplier";
import {SupplierService} from '../supplier.service';
import {TranslateService} from "@ngx-translate/core";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {Permissions} from "../../../auth/permission.service";

export class SupplierFormValidator {

    constructor(private supplier: Supplier,
                private validationService: ValidationService,
                private supplierService: SupplierService,
                private translate: TranslateService,
                private permissions: Permissions,
                private afterValidationCallback: (validationErrors: ValidationErrors) => void) {
    }

    public validateDetails(): Observable<boolean> {
        // ignore existing errors from other steps
        const detailsErrors = {};
        this.validationService.validateNotEmpty(this.supplier, ["companyName"], detailsErrors, "supplierDto");

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            detailsErrors[`name[${this.translate.currentLang}]`] =
                MultiValidator.of(`error.supplierDto.name[${this.translate.currentLang}]`)
                    .withNotNullValidator()
                    .withNotBlankValidator()
                    .withSizeValidator(0, 100)
                    .validate(this.supplier.name[this.translate.currentLang]);
        }

        if (ValidationErrorsHelper.validationErrorsPresent(detailsErrors)) {
            this.callAfterValidationCallback(detailsErrors);
            return of(false);
        }

        return ValidationErrorsHelper.mapBackendValidationErrors(this.supplierService.validateGeneralDetails(this.supplier))
            .pipe(
                tap(backendErrors => this.callAfterValidationCallback(backendErrors)),
                map(backendErrors => !ValidationErrorsHelper.validationErrorsPresent(backendErrors))
            );
    }

    public validateAddress(): Observable<boolean> {
        // ignore existing errors from other steps
        const addressErrors = {};
        this.validationService.validateNotEmpty(this.supplier,
            ["address.city", "address.country", "address.street", "address.zip"],
            addressErrors, "supplierDto");

        if (addressErrors['address.zip'] == undefined &&
            !new ZipCodeValidator().validate(this.supplier.address.country, this.supplier.address.zip)) {
            addressErrors['address.zip'] = 'error.supplierDto.address.zip.pattern_not_matched';
        }

        if (ValidationErrorsHelper.validationErrorsPresent(addressErrors)) {
            this.callAfterValidationCallback(addressErrors);

            return of(false);
        }

        return ValidationErrorsHelper.mapBackendValidationErrors(this.supplierService.validateAddress(this.supplier))
            .pipe(
                tap(backendErrors => this.callAfterValidationCallback(backendErrors)),
                map(backendErrors => !ValidationErrorsHelper.validationErrorsPresent(backendErrors))
            );
    }

    private callAfterValidationCallback(validationErrors: any): void {
        if (this.afterValidationCallback != null) {
            this.afterValidationCallback(validationErrors);
        }
    }
}
