import * as _ from 'underscore';
import {DistanceFrameInterface} from '../catalog-data/distance-frame-interface';
import {Glazing} from '../catalog-data/glazing';
import {GlazingBeadInterface, isFillingWidthAcceptableForGlazingBead} from '../catalog-data/glazing-bead-interface';
import {GlazingPackageForAreaRange} from '../catalog-data/glazing-package-for-area-range';
import {WindowSystemInterface} from '../catalog-data/window-system-interface';
import {AreaSpecification} from '../drawing-data/AreaSpecification';
import {DrawingData} from '../drawing-data/drawing-data';
import {FillingType} from '../drawing-data/FillingType';
import {GlazingBead} from '../drawing-data/GlazingBead';
import {StaticDataHelper} from '../static-data-helper';
import {SizeUnitsConverter} from "./SizeUnitsConverter";

export class GlassUpsellingValidationUtil {

    public static verifyIfGlassUpsellingCanBeApplied(areaSpec: AreaSpecification, windowSystem: WindowSystemInterface, staticData: StaticDataHelper): void {
        let frames = staticData.frames || [];
        if (windowSystem.glazingPackage == null) {
            throw new Error(`Window system doesnt have default glazing package. Upselling cannot be applied.`);
        }
        let glazingGlassQuantity = areaSpec.glazing.glazingGlassQuantity;
        let glazingPackageToUse = GlassUpsellingValidationUtil.findGlazingForArea(areaSpec, glazingGlassQuantity, windowSystem.glazingPackage.glazingPackageForAreaRangeList);
        let defaultGlazingPackage = (glazingPackageToUse && glazingPackageToUse.glazing) || windowSystem.glazingPackage[`glazing${glazingGlassQuantity}`];
        GlassUpsellingValidationUtil.verifyGlazingsWithDefault(areaSpec.glazing, defaultGlazingPackage, frames);
    }

    public static findGlazingForArea(areaSpec: AreaSpecification, desiredGlassQuantity: number | undefined, glazingPackageForAreaRangeList: GlazingPackageForAreaRange[]): GlazingPackageForAreaRange {
        let area = SizeUnitsConverter.mmToMeter(areaSpec.width) * SizeUnitsConverter.mmToMeter(areaSpec.height);
        return (glazingPackageForAreaRangeList || []).find(packageForAreaRange => {
            return (desiredGlassQuantity == undefined || packageForAreaRange.glassCount === desiredGlassQuantity)
                && packageForAreaRange.bottomAreaLimit <= area && area < packageForAreaRange.topAreaLimit;
        });
    }

    public static verifyGlazingsWithDefault(toVerify: Glazing, defaultTemplate: Glazing, frames: DistanceFrameInterface[]): void {
        let allGlassesAsDefault = toVerify.glass1id === defaultTemplate.glass1id && toVerify.glass2id === defaultTemplate.glass2id
            && toVerify.glass3id === defaultTemplate.glass3id && toVerify.glass4id === defaultTemplate.glass4id;
        if (!allGlassesAsDefault) {
            throw new Error(`Upselling cannot be applied. Glazing:\n${JSON.stringify(toVerify)}\nhas glasses different than default glazing:\n${JSON.stringify(defaultTemplate)}`);
        }
        let allFramesWidthsAsDefaults = GlassUpsellingValidationUtil.glazingFrameSameWidthAsInDefaults(toVerify.frame1id, defaultTemplate.frame1id, frames)
            && GlassUpsellingValidationUtil.glazingFrameSameWidthAsInDefaults(toVerify.frame2id, defaultTemplate.frame2id, frames)
            && GlassUpsellingValidationUtil.glazingFrameSameWidthAsInDefaults(toVerify.frame3id, defaultTemplate.frame3id, frames);
        if (!allFramesWidthsAsDefaults) {
            throw new Error(`Upselling cannot be applied. Glazing:\n${JSON.stringify(toVerify)}\nhas frames with widths different than default glazing:\n${JSON.stringify(defaultTemplate)}`);
        }
    }

    public static glazingFrameSameWidthAsInDefaults(toVerifyId: number, defaultId: number, frames: DistanceFrameInterface[]): boolean {
        if (defaultId == null) {
            return toVerifyId == null;
        }
        if (defaultId === toVerifyId) {
            return true;
        }
        let defaultFrame = frames.find(frame => frame.id === defaultId);
        let toVerifyFrame = frames.find(frame => frame.id === toVerifyId);
        return defaultFrame != null && toVerifyFrame != null && defaultFrame.thickness === toVerifyFrame.thickness;
    }

    public static applyGlazingBasedOnArea(glazingPackageForAreaRangeList: GlazingPackageForAreaRange[], staticData: StaticDataHelper,
                                          data: DrawingData, glazingBeads?: GlazingBeadInterface[], windowSystem?: WindowSystemInterface,
                                          requireDefaultGlazingBeforeChange = true) {
        if ((glazingPackageForAreaRangeList != undefined && glazingPackageForAreaRangeList.length > 0) || (windowSystem && windowSystem.glazingPackage)) {
            data.windows.forEach(window => {
                window.subWindows.forEach(subwindow => {
                    subwindow.areasSpecification.filter(areaSpec => areaSpec.filling.type === FillingType.GLASS)
                        .forEach(areaSpec => {
                            if (requireDefaultGlazingBeforeChange && windowSystem != null) {
                                // upselling cannot be applied if initial glazing different than default
                                GlassUpsellingValidationUtil.verifyIfGlassUpsellingCanBeApplied(areaSpec, windowSystem, staticData);
                            }
                            this.applyGlazingToArea(areaSpec, areaSpec.glazing.glazingGlassQuantity, glazingPackageForAreaRangeList, windowSystem, staticData, glazingBeads);
                        });
                });
            });
        }
    }

    public static applyGlazingToArea(areaSpec: AreaSpecification, desiredGlassQuantity: number, glazingPackageForAreaRangeList: GlazingPackageForAreaRange[],
                                      windowSystem?: WindowSystemInterface, staticData?: StaticDataHelper, glazingBeads?: GlazingBeadInterface[]): void {
        let glazingPackageToUse = GlassUpsellingValidationUtil.findGlazingForArea(areaSpec, null, glazingPackageForAreaRangeList);
        if (glazingPackageToUse != null) {
            if (glazingPackageToUse.glassCount > 1 &&
                (glazingPackageToUse.glazing.frame1id < 0
                || glazingPackageToUse.glazing.frame2id < 0
                || glazingPackageToUse.glazing.frame3id < 0)) {
                throw new Error('Tried to apply glazing with not existing distance frame. '
                    + 'Most probably tried to apply glazingPackageForAreaRange with forced warm frames when at '
                    + 'least one frame doesn\'t have warm alternative. WebshopGlazingPackageForAreaRangeId: '
                    + glazingPackageToUse.id);
            }
            areaSpec.glazing = _.clone(glazingPackageToUse.glazing);
            areaSpec.glazingBead.id = glazingPackageToUse.glazingBeadId;
            if (glazingBeads) {
                GlassUpsellingValidationUtil.applyProperGlazingBead(glazingPackageToUse, glazingBeads, areaSpec, staticData);
            } else {
                areaSpec.glazingBead = new GlazingBead(glazingPackageToUse.glazingBeadId);
            }
        } else if (areaSpec.filling.type === FillingType.DECORATIVE_FILLING && windowSystem && windowSystem.decorativeGlazingPackage) {
            areaSpec.glazing = _.clone(windowSystem.decorativeGlazingPackage[`glazing${desiredGlassQuantity}`]);
        } else if (windowSystem && windowSystem.glazingPackage) {
            areaSpec.glazing = _.clone(windowSystem.glazingPackage[`glazing${desiredGlassQuantity}`]);
        }
        areaSpec.glazing = areaSpec.glazing || new Glazing();
    }

    public static applyProperGlazingBead(glazingPackage: GlazingPackageForAreaRange, glazingBeads: GlazingBeadInterface[],
                                   areaSpec: AreaSpecification, staticData: StaticDataHelper): void {
        let glazingWidth = [
            staticData.glasses.find(glass => glazingPackage.glazing.glass1id === glass.id),
            staticData.glasses.find(glass => glazingPackage.glazing.glass2id === glass.id),
            staticData.glasses.find(glass => glazingPackage.glazing.glass3id === glass.id),
            staticData.glasses.find(glass => glazingPackage.glazing.glass4id === glass.id),
            staticData.frames.find(frame => glazingPackage.glazing.frame1id === frame.id),
            staticData.frames.find(frame => glazingPackage.glazing.frame2id === frame.id),
            staticData.frames.find(frame => glazingPackage.glazing.frame3id === frame.id)
        ].filter(item => item != null).reduce((totalThickness, next) => totalThickness + next.thickness, 0);
        let currentGlazingBead = glazingBeads.find(glazingBead => glazingBead.id === areaSpec.glazingBead.id);
        let glazingFitsWithCurrentGlazingBead = isFillingWidthAcceptableForGlazingBead(glazingWidth, currentGlazingBead);
        if (!glazingFitsWithCurrentGlazingBead) {
            areaSpec.glazingBead = new GlazingBead(glazingPackage.glazingBeadId);
        }
    }
}
