import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {RoofGlazingPackageField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {RoofGlazingPackageFieldUsage} from "../../catalog-field-usage";
import {RoofGlazingPackage} from "../roof-glazing-package";

@Component({
    selector: 'app-roof-glazing-package-form',
    templateUrl: './roof-glazing-package-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoofGlazingPackageFormComponent {

    @Input() item: RoofGlazingPackage;

    @Input()
    validationErrors: any;

    @Input()
    file: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: RoofGlazingPackageFieldUsage;
    RoofGlazingPackageField = RoofGlazingPackageField;

    constructor() {
        this.fieldUsage = new RoofGlazingPackageFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
