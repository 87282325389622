import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output} from "@angular/core";
import {forkJoin} from 'rxjs';
import {BlockUiController} from "../../../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {TristateCheckboxState} from '../../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {OffersService} from "../../../../offer-service";
import {StatusTransitionHelper} from "../../../../status-transition-dialog/StatusTransitionHelper";
import {SupplierInfo} from "../../../../SupplierInfo";

@Component({
    selector: 'app-correction-order-generation-dialog',
    templateUrl: './correction-order-generation-dialog.component.html',
    styleUrls: ['../../../../../shared-styles.css', './correction-order-generation-dialog.component.css'],
    providers: [OffersService]
})
export class CorrectionOrderGenerationDialogComponent implements OnInit {

    private readonly INIT_REQUIRED_OFFER_INFORMATION_ID = 'initRequiredOfferInformation';

    @Input()
    offerId: number;

    @Output()
    onSuccess = new EventEmitter();

    @Output()
    onClose = new EventEmitter();

    suppliers: SupplierInfo[] = [];

    canCreateCorrection: boolean;

    protected blockUiController: BlockUiController;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(injector: Injector,
                private offersService: OffersService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        this.blockUiController = injector.get(BlockUiController);
    }

    ngOnInit() {
        this.blockUiController.block(this.INIT_REQUIRED_OFFER_INFORMATION_ID);
        forkJoin({
            suppliers: this.offersService.getOffersSuppliersList(this.offerId),
            canCreate: this.offersService.canCreateCorrection(this.offerId)
        }).subscribe({
            next: data => {
                let suppliers = data.suppliers;
                suppliers.forEach(s => s.selected = s.hasChanges);
                this.suppliers = suppliers;
                this.canCreateCorrection = data.canCreate;
                this.changeDetector.markForCheck();
                this.blockUiController.unblock(this.INIT_REQUIRED_OFFER_INFORMATION_ID);
            },
            error: (error) => {
                this.blockUiController.unblock(this.INIT_REQUIRED_OFFER_INFORMATION_ID);
                this.errors.handle(error);
            }
        });
    }

    submitDialog(): void {
        this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        let selectedSuppliers = this.getSelectedSuppliers();
        let selectedIds = selectedSuppliers.map(s => s.id);

        this.offersService.confirmCorrection(this.offerId, selectedIds).subscribe({
            complete: () => {
                console.info('ChangeAnnotationsComponent submit done');
                this.done();
            },
            error: error => {
                this.done(error);
            }
        });
    }

    private done(error?: HttpErrorResponse): void {
        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        this.dialogHideHelper.call(() => {
            this.onClose.emit();
            if (error) {
                this.errors.handle(error);
            } else {
                this.onSuccess.emit();
            }
        });
    }

    resetDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    canSubmit(): boolean {
        return this.getSelectedSuppliers().length > 0 && this.canCreateCorrection;
    }

    getSelectedSuppliers(): SupplierInfo[] {
        return this.suppliers.filter(s => s.selected);
    }

    get allSelectedState() {
        const selectedSupplierCount = this.suppliers.reduce((selectedCount, supplier) => selectedCount + (supplier.selected ? 1 : 0), 0);
        if (selectedSupplierCount === 0) {
            return TristateCheckboxState.UNCHECKED;
        }
        if (selectedSupplierCount === this.suppliers.length) {
            return TristateCheckboxState.CHECKED;
        }
        return TristateCheckboxState.CHECKED_PARTIALLY;
    }

    selectAllChange(newState: TristateCheckboxState): void {
        switch (newState) {
            case TristateCheckboxState.CHECKED:
                this.suppliers.forEach(supplier => supplier.selected = true);
                break;
            case TristateCheckboxState.UNCHECKED:
                this.suppliers.forEach(supplier => supplier.selected = false);
                break;
        }
        this.changeDetector.markForCheck();
    }
}
