import {GuideData} from "./GuideData";

export class GuidesData {
    horizontal: GuideData;
    vertical: GuideData;

    constructor() {
        this.horizontal = new GuideData();
        this.vertical = new GuideData();
    }
}
