import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable} from 'rxjs';
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {Country} from '../../../common/enums/country';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {AddressService} from '../../subsystem/address.service';
import {TabularAddress} from '../../subsystem/tabularAddress';
import {UserService} from '../../user/user.service';
import {SellerSettingsValidator} from './seller-settings-validator';
import {SellerSettings} from './seller-settings';
import {SellerSettingsService} from './seller-settings.service';

@Component({
    selector: 'app-seller-settings',
    templateUrl: './seller-settings.component.html',
    styleUrls: ['./seller-settings.component.css', '../settings.component.css'],
    providers: [UserService, DataServiceHelper, SellerSettingsService, TranslatedSelectItemService, AddressService]
})
export class SellerSettingsComponent implements OnInit {

    countries: Observable<SelectItem[]>;
    addresses: TabularAddress[];
    correspondenceAddress: TabularAddress;
    deliveryAddress: TabularAddress;
    correspondenceAddressToSave: TabularAddress;
    deliveryAddressToSave: TabularAddress;
    private saveInProgress = false;
    private validator: SellerSettingsValidator;

    item: SellerSettings;
    addressSelectionDialogType: 'none' | 'correspondence' | 'delivery' | 'modify' = 'none';
    validationErrors = {};

    constructor(private permissions: Permissions,
                private errors: CommonErrorHandler,
                private growlMessageController: GrowlMessageController,
                private translatedSelectItemService: TranslatedSelectItemService,
                private changeDetector: ChangeDetectorRef,
                private service: SellerSettingsService,
                private addressService: AddressService,
                private currentUserService: CurrentUserService) {
        this.item = new SellerSettings();
        this.validator = new SellerSettingsValidator(this.service);
    }

    ngOnInit() {
        this.item.userId = this.currentUserService.currentUserId;
        this.getItemWithImage();
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    getItemWithImage() {
        forkJoin({
            item: this.service.getItem(this.currentUserService.currentUserId),
            addresses: this.service.getAddresses(this.currentUserService.currentUserId),
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.addresses = data.addresses;
                this.correspondenceAddress = this.addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
                this.deliveryAddress = this.addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    showSuccessMessage() {
        this.growlMessageController.info('SELLER_SETTINGS.UPDATED');
    }

    saveChanges() {
        this.validateForm().subscribe(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
                if (this.isSaveInProgress()) {
                    return;
                }

                this.setSaveInProgress(true);
                this.service.saveSettings(this.item, this.addresses, this.correspondenceAddress, this.deliveryAddress).subscribe({
                        next: () => {
                            this.showSuccessMessage();
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        },
                        error: err => {
                            this.validationErrors = this.errors.handle(err);
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        }
                    });
            }
        });
    }

    validateForm(): Observable<ValidationErrors> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors);
        return this.validator.validateAddress(this.item, this.addresses, this.correspondenceAddressToSave, this.deliveryAddressToSave);
    }

    handleAddressesChange(addresses: TabularAddress[]): void {
        this.addresses = addresses;
        if (this.item.primaryCorrespondenceAddressId != undefined) {
            this.correspondenceAddress = addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
            if (this.correspondenceAddress == undefined) {
                this.item.primaryCorrespondenceAddressId = undefined;
            }
        }
        if (this.item.primaryDeliveryAddressId != undefined) {
            this.deliveryAddress = addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
            if (this.deliveryAddress == undefined) {
                this.item.primaryDeliveryAddressId = undefined;
            }
        }
    }

    handleAddressSelected(address: TabularAddress): void {
        switch (this.addressSelectionDialogType) {
            case 'correspondence':
                delete this.validationErrors['correspondenceAddress'];
                this.correspondenceAddress = address;
                this.item.primaryCorrespondenceAddressId = address != undefined ? address.id : undefined;
                break;
            case 'delivery':
                delete this.validationErrors['deliveryAddress'];
                this.deliveryAddress = address;
                this.item.primaryDeliveryAddressId = address != undefined ? address.id : undefined;
                break;
        }
        this.saveAddressChanges();
    }

    handleAddressSelectionVisibilityChange(visible: boolean): void {
        if (!visible) {
            this.addressSelectionDialogType = 'none';
        }
    }

    saveAddressChanges() {
        this.correspondenceAddressToSave = this.correspondenceAddress == null ? this.addresses[0] :  this.correspondenceAddress;
        this.deliveryAddressToSave = this.deliveryAddress == null ? this.addresses[0] :  this.deliveryAddress;
        this.validateForm().subscribe(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
                if (this.isSaveInProgress()) {
                    return;
                }

                this.setSaveInProgress(true);
                this.executeSave();
            }
        });
    }

    private executeSave(): void {
        let observable;
        if (this.item && this.item.id != null) {
            observable = this.service.saveAddresses(this.addresses, this.correspondenceAddress, this.deliveryAddress);
        } else {
            observable = this.service.saveSettings(this.item, this.addresses, this.correspondenceAddressToSave, this.deliveryAddressToSave);
        }
        observable.subscribe({
            next: () => {
                this.showSuccessMessage();
                this.correspondenceAddress = this.correspondenceAddressToSave;
                this.deliveryAddress = this.deliveryAddressToSave;
                this.changeDetector.markForCheck();
                this.setSaveInProgress(false);
            },
            error: err => {
                this.validationErrors = this.errors.handle(err);
                this.changeDetector.markForCheck();
                this.setSaveInProgress(false);
            }
        });
    }

    showAddressSelectionDialog(addressSelectionDialogType: 'none' | 'correspondence' | 'delivery' | 'modify'): void {
        this.addressService.getUsableAddresses().subscribe(addresses => {
            this.addressSelectionDialogType = addressSelectionDialogType;
            this.addresses = addresses;

            switch (this.addressSelectionDialogType) {
                case 'correspondence':
                    this.correspondenceAddress = this.addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
                    break;
                case 'delivery':
                    this.deliveryAddress = this.addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
                    break;
            }
            this.changeDetector.markForCheck();
        });
    }
}
