import {HttpErrorResponse, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, finalize, mergeMap} from 'rxjs/operators';
import {Permissions} from "../../../../auth/permission.service";
import {StorageService} from "../../../../auth/storage.service";
import {ResponseError} from "../../../../common/error.handler";
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {ValidationErrorsHelper} from "../../../../common/ValidationErrorsHelper";
import {WizardStepValidator} from "../../../../form-inputs/wizard/wizard-step.component";
import {AccessData} from "../../../AccessData";
import {ErrorResponse} from "../../../errors/errorResponse";
import {Offer} from "../../offer";
import {OffersService} from "../../offer-service";
import {StatusTransition} from '../../status-transition-dialog/StatusTransition';
import {Complaint} from "../complaint";
import {ComplaintPosition} from "../complaint-position";
import {ComplaintStatus} from "../complaint-status";
import {ComplaintStatusTransitionService} from '../complaint-status-transition.service';
import {ComplaintService} from "../complaint.service";
import {ComplaintPositionFormValidator} from "../form/complaint-position-form.validator";

@Component({
    selector: 'app-create-complaint',
    templateUrl: './create-complaint.component.html',
    styleUrls: ['./create-complaint.component.css', '../../status-transition-dialog/status-transition-dialog.component.css'],
    providers: [OffersService, ComplaintService, ComplaintPositionFormValidator, ComplaintStatusTransitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateComplaintComponent {

    static readonly COMPLAINT_OFFER_POSITIONS_KEY = "complaint_offer_positions";

    readonly STEPS = {
        DETAILS: 'details',
        POSITIONS: 'positions',
        STATUS: 'status'
    };

    validationErrors = {};
    offer: Offer = new Offer();
    routeData: { offerId: number, complaintId: number };
    complaint: Complaint;
    validateDetailsStep: WizardStepValidator;
    validatePositionsStep: WizardStepValidator;
    validateStatusStep: WizardStepValidator;
    isEditMode = false;
    displayPhotoInstructionDialog = false;

    displayMovieInstructionDialog = false;

    statusTransitionInfoDialogVisible = false;
    possibleStatuses: StatusTransition[] = [];
    selectedTransition: StatusTransition;
    selectedTransitionForInfo: StatusTransition;
    saveInProgress = false;

    movieFiles: { [offerPositionId: number]: File } = {};
    photoFiles: { [offerPositionId: number]: File } = {};

    constructor(private changeDetector: ChangeDetectorRef,
                private activatedRoute: ActivatedRoute,
                public permissions: Permissions,
                private router: Router,
                private offersService: OffersService,
                private complaintService: ComplaintService,
                private storage: StorageService,
                private complaintPositionFormValidator: ComplaintPositionFormValidator,
                private complaintStatusTransitionService: ComplaintStatusTransitionService,
                private translate: TranslateService,
                private growls: GrowlMessageController) {

        this.validationErrors = {};
        this.validateDetailsStep = () => this.validateDetails();
        this.validatePositionsStep = () => this.validatePositions();
        this.validateStatusStep = () => this.validateStatus();
        this.readRouteParams();
        this.initMode();
        this.initComplaintData();
    }

    exitWizard(): void {
        if (this.isEditMode) {
            this.redirectToComplaintPositionList(this.complaint.id);
        } else {
            this.redirectToOfferPositionList();
        }
    }

    private initMode() {
        this.isEditMode = this.routeData.complaintId != null;
    }

    redirectToOfferPositionList() {
        this.router.navigate(['/features/offer', this.routeData.offerId, 'position']);
    }

    private redirectToComplaintPositionList(complaintId: number): void {
        this.router.navigate([AccessData.path.complaint(), complaintId, AccessData.complaintPositionURLSuffix]);
    }

    private initComplaintData(): void {
        if (this.isEditMode) {
            this.complaintService.getComplaint(this.routeData.complaintId).subscribe({
                next: complaint => {
                    this.complaint = complaint;
                    this.complaint.deliveryDate = this.mapDateFromJson(complaint.deliveryDate);
                    this.complaint.createdDate = this.mapDateFromJson(complaint.createdDate);
                    this.complaint.expectedShippingDate = this.mapDateFromJson(complaint.expectedShippingDate);
                    this.complaint.sendToVerifyDate = this.mapDateFromJson(complaint.sendToVerifyDate);

                    this.loadOffer();
                },
                error: error => {
                    throw new ResponseError(error);
                }
            });
        } else {
            this.complaint = new Complaint();
            this.complaint.offerId = this.routeData.offerId;
            this.loadOffer();
            this.initComplaintPositions();
            this.possibleStatuses = this.complaintStatusTransitionService.createPossibleTransitions(this.complaint);
        }
    }

    private mapDateFromJson(date: Date): Date {
        let result;

        if (date == null) {
            result = null;
        } else {
            result = new Date(date);
        }

        return result;
    }

    onStepChange(): void {
        this.changeDetector.markForCheck();
    }

    save(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.complaintService.save(this.complaint)
            .pipe(finalize(() => this.saveInProgress = false),
                mergeMap(savedComplaint => {
                    const movies = Object.keys(this.movieFiles)
                        .filter(offerPositionId => this.movieFiles[offerPositionId] != undefined)
                        .map(offerPositionId => +offerPositionId)
                        .map(offerPositionId => ({ offerPositionId: offerPositionId, file: this.movieFiles[offerPositionId] }));
                    if (movies.length === 0) {
                        return of<[Complaint, HttpResponseBase[]]>([savedComplaint, []]);
                    }
                    return forkJoin(
                        movies.map(movie => this.complaintService.saveComplaintPositionMovie(savedComplaint.id,
                            movie.offerPositionId, movie.file).pipe(catchError<HttpResponse<void>, Observable<HttpErrorResponse>>(error => of(error)))),
                    ).pipe(mergeMap(responses => of<[Complaint, HttpResponseBase[]]>([savedComplaint, responses])));
                }),
                mergeMap(saveResponses => {
                    const photos = Object.keys(this.photoFiles)
                        .filter(offerPositionId => this.photoFiles[offerPositionId] != undefined)
                        .map(offerPositionId => +offerPositionId)
                        .map(offerPositionId => ({ offerPositionId: offerPositionId, file: this.photoFiles[offerPositionId] }));
                    if (photos.length === 0) {
                        return of<[Complaint, HttpResponseBase[], HttpResponseBase[]]>([saveResponses[0], saveResponses[1], []]);
                    }
                    return forkJoin(
                        photos.map(movie => this.complaintService.saveComplaintPositionPhoto(saveResponses[0].id,
                            movie.offerPositionId, movie.file).pipe(catchError<HttpResponse<void>, Observable<HttpErrorResponse>>(error => of(error)))),
                    ).pipe(mergeMap(responses => of<[Complaint, HttpResponseBase[], HttpResponseBase[]]>([saveResponses[0], saveResponses[1], responses])));
                }))
            .subscribe({
                next: responses => {
                    this.redirectToComplaintPositionList(responses[0].id);
                    if (responses[1].some(response => !response.ok)) {
                        this.growls.warning('OFFER.COMPLAINT.CREATE.MOVIE_UPLOAD_FAILED');
                    }
                    if (responses[2].some(response => !response.ok)) {
                        this.growls.warning('OFFER.COMPLAINT.CREATE.PHOTO_UPLOAD_FAILED');
                    }
                },
                error: (error: HttpErrorResponse) => {
                    let errorResponse = new ErrorResponse(error.error);

                    if (errorResponse.is400()) {
                        for (let property in errorResponse.invalidFields) {
                            this.validationErrors[property] = errorResponse.invalidFields[property];
                        }

                        this.changeDetector.markForCheck();
                    } else {
                        throw new ResponseError(error);
                    }
                }
            });
    }

    private loadOffer(): void {
        let offerId = this.isEditMode ? this.complaint.offerId : this.routeData.offerId;

        this.offersService.getItem(offerId).subscribe({
            next: offer => {
                this.offer = offer;
                this.changeDetector.markForCheck();
            },
            error: error => {
                throw new ResponseError(error);
            }
        });
    }

    private validateDetails(): boolean {
        this.validationErrors = {};

        if (!this.complaint.deliveryDate) {
            this.validationErrors['deliveryDate'] = 'error.complaintDto.deliveryDate.not_null';
        }

        if (!this.complaint.deliveryDestination) {
            this.validationErrors['deliveryDestination'] = 'error.complaintDto.deliveryDestination.not_null';
        }

        if (!this.complaint.description) {
            this.validationErrors['description'] = 'error.complaintDto.description.not_null';
        }

        if (this.isTranslatedDescriptionVisible() && !this.complaint.translatedDescription) {
            this.validationErrors['translatedDescription'] = 'error.complaintDto.translatedDescription.not_null';
        }

        return !ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private validatePositions(): boolean {
        this.complaint.positions.forEach(complaintPosition => {
            this.validationErrors = Object.assign({}, this.validationErrors,
                this.complaintPositionFormValidator.validate(complaintPosition));
        });

        return !ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private validateStatus(): boolean {
        this.validationErrors = {};

        if (this.selectedTransition === undefined) {
            this.validationErrors['status'] = 'error.complaintDto.status.not_null';
        }

        return !ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private readRouteParams(): void {
        this.routeData = {
            offerId: this.getRouteParamIfPresent('offerId'),
            complaintId: this.getRouteParamIfPresent('complaintId')
        };
    }

    private getRouteParamIfPresent(paramName: string): number | undefined {
        return this.activatedRoute.snapshot.paramMap.has(paramName) ?
            +this.activatedRoute.snapshot.paramMap.get(paramName) : undefined;
    }

    private initComplaintPositions(): void {
        let result: ComplaintPosition[] = [];

        if (this.storage.get(CreateComplaintComponent.COMPLAINT_OFFER_POSITIONS_KEY) != null) {
            result = JSON.parse(this.storage.get(CreateComplaintComponent.COMPLAINT_OFFER_POSITIONS_KEY)).map(position => {
                let complaintPosition = new ComplaintPosition();
                complaintPosition.offerPositionId = position.id;
                complaintPosition.name = position.name;
                complaintPosition.quantity = position.quantity;
                complaintPosition.printOrder = position.printOrder;
                complaintPosition.complaintTypes = [];

                return complaintPosition;
            });
        }

        this.storage.remove(CreateComplaintComponent.COMPLAINT_OFFER_POSITIONS_KEY);
        this.complaint.positions = result;
    }

    createPositionAccordionHeader(position: ComplaintPosition): string {
        let positionName = position.name[this.translate.currentLang];

        return '#' + position.printOrder + ' ' + (positionName == null ? '' : positionName);
    }

    handleDeliveryDateChange(date: Date): void {
        this.complaint.deliveryDate = date;
        if (!this.isExpectedShippingDateDisabled() && this.complaint.expectedShippingDate != undefined &&
            this.complaint.expectedShippingDate < date) {
            this.complaint.expectedShippingDate = undefined;
        }
    }

    handleMovieFileChange(offerPositionId: number, file: File): void {
        this.movieFiles[offerPositionId] = file;
    }

    showMovieInstructionDialog(): void {
        this.displayMovieInstructionDialog = true;
    }

    hideMovieInstructionDialog(): void {
        this.displayMovieInstructionDialog = false;
    }

    handlePhotoFileChange(offerPositionId: number, file: File): void {
        this.photoFiles[offerPositionId] = file;
    }

    showPhotoInstructionDialog(): void {
        this.displayPhotoInstructionDialog = true;
    }

    hidePhotoInstructionDialog(): void {
        this.displayPhotoInstructionDialog = false;
    }

    hasPositionErrors(positionId: number): boolean {
        return this.validationErrors['quantity-' + positionId] != undefined ||
            this.validationErrors['complaintType-' + positionId] != undefined;
    }

    hasStatusError(): boolean {
        return this.validationErrors['status'] != undefined;
    }

    isTranslatedDescriptionVisible(): boolean {
        return this.isEditMode && this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    isDescriptionDisabled(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', "ROLE_OPIEKUN"]});
    }

    isExpectedShippingDateDisabled(): boolean {
        return !(this.isEditMode && this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', "ROLE_OPIEKUN"]}));
    }

    showTransitionInfoDialog(transition: StatusTransition, event): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        this.selectedTransitionForInfo = transition;
        this.statusTransitionInfoDialogVisible = true;
    }

    hideStatusTransitionDialog(): void {
        this.statusTransitionInfoDialogVisible = false;
    }

    getStatusTransitionInfoLabel(): string {
        return this.translate.instant('OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS_TRANSITION_INFO.CONTENT.'
            + this.selectedTransitionForInfo.item.id);
    }

    handleStatusClicked(status: StatusTransition): void {
        this.selectedTransition = status;

        if (this.selectedTransition.item.id === "SAVE_WITHOUT_SENDING") {
            this.complaint.status = ComplaintStatus.NEW;
        } else if (this.selectedTransition.item.id === "SEND_TO_VERIFY_TO_VENSKA") {
            this.complaint.status = ComplaintStatus.TO_VERIFY;
        }

        this.validateStatus();
    }

}
