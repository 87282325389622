import {ColorInterface} from './color-interface';
import {MultilanguageFieldInterface} from './multilanguage-field-interface';
import {Price} from './Price';
import {SupplierInterface} from './supplier-interface';
import {QuantityType} from "../enums/QuantityType";

export interface AddonInterface {
    id: number;
    name: MultilanguageFieldInterface;
    category: string;
    addonFor: string;
    supplier: SupplierInterface;
    subsystemId: number;
    price: Price;
    defaultQuantity: any;
    quantityType: QuantityType;
    changeQuantity: boolean;
    chooseColor: boolean;
    insideColors: ColorInterface[];
    outsideColors: ColorInterface[];
    coreColors: ColorInterface[];
    pcn: string;
    active: boolean;
    removable: boolean;
    customNumericValue: number;
    customStringValue: string;
    singlePositionAddon: boolean;
    showGateDisclaimer: boolean;
}

export function addonQuantityTypeIntegerOnly(quantityType: QuantityType): boolean {
    return ['PIECE', 'SET', 'PAIR'].includes(quantityType);
}
