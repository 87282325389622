<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGES.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGE.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords"
                 selectionMode="single" (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="glazingPackageTable">
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.NAME)" [filter]="showFilters" [sortable]="true" field="name" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.NAME' | translate }}"
                  filterMatchMode="contains">
            <ng-template let-package="rowData" pTemplate="body">
                {{package.name[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="sortIndex" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}" [sortable]="true"></p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.SHORTCUT)" [filter]="showFilters" [sortable]="true" field="shortcut" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.SHORTCUT' | translate }}"
                  filterMatchMode="contains">
            <ng-template let-package="rowData" pTemplate="body">
                {{package.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.GLASSES)" [filter]="showFilters" field="glasses" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES' | translate }}"
                  filterMatchMode="contains">
            <ng-template let-package="rowData" pTemplate="body">
                {{package.glasses[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.SYMBOL)" field="symbol"
                  header="{{ 'ROOF_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
        </p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.GLASSES_QUANTITY)" [filter]="showFilters" [sortable]="true" field="glassesQuantity" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES_QUANTITY' | translate }}"></p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.UG)" [sortable]="true" field="ug" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.UG' | translate }}"></p-column>
        <p-column *ngIf="fieldUsage.show(RoofGlazingPackageField.ACTIVE)" [filter]="showFilters" [sortable]="false" field="active" header="{{ 'ROOF_GLAZING_PACKAGE.FORM.STATUS' | translate }}"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-package="rowData" pTemplate="body">
                {{(package.active ? 'ROOF_GLAZING_PACKAGE.FORM.ACTIVE' : 'ROOF_GLAZING_PACKAGE.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'ROOF_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-roof-glazing-package-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                                  (fileChange)="onFileChange($event)"></app-roof-glazing-package-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGE.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_GLAZING_PACKAGE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'ROOF_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-roof-glazing-package-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                                  (fileChange)="onFileChange($event)"></app-roof-glazing-package-form>
    </app-wizard-step>
</app-wizard-dialog>
