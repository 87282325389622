import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from '../../../common/validation-errors';
import {SubsystemGroup} from '../subsystem-group';

@Component({
    selector: 'app-subsystem-group-form',
    templateUrl: './subsystem-group-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemGroupFormComponent {

    @Input()
    subsystemGroup: SubsystemGroup;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    canEdit: boolean;
}
