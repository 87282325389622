import {AreaSpecification} from "./drawing-data/AreaSpecification";
import {Grill} from "./drawing-data/Grill";
import {GrillGrid} from "./drawing-data/GrillGrid";
import {GrillGridWithRhombusOnIntersections} from "./drawing-data/GrillGridWithRhombusOnIntersections";
import {GrillType} from "./drawing-data/GrillType";
import {RelativePosition} from "./drawing-data/RelativePosition";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {GrillHelper} from "./utils/grill-helper";
import {GenericGrillGrid} from "./drawing-data/GenericGrillGrid";

export class PendingGrillData {
    grill: Grill;
    points: number[];
    positions: RelativePosition[];
    subWindow: SubWindowData;
    area: AreaSpecification;
    svgTarget: Snap.Element;

    constructor(pendingGrillData?: PendingGrillData) {
        this.grill = undefined;
        if (pendingGrillData != null && pendingGrillData.grill != null) {
            let previousGrill = pendingGrillData.grill;
            let grill = GrillHelper.newGrill(previousGrill.type);
            grill.id = previousGrill.id;
            grill.colorId = previousGrill.colorId;
            if (GrillHelper.isGrid(grill)) {
                (grill as GenericGrillGrid).winglessMode = (previousGrill as GenericGrillGrid).winglessMode;
            }
            switch (grill.type) {
                case GrillType.LINE_GRILL:
                case GrillType.MULLION:
                case GrillType.GRID_CROSS_SIMPLE:
                case GrillType.GRID_CROSS_ANGLED:
                    break;
                case GrillType.GRID_STANDARD:
                    (grill as GrillGrid).rows = (previousGrill as GrillGrid).rows;
                    (grill as GrillGrid).columns = (previousGrill as GrillGrid).columns;
                    break;
                case GrillType.GRID_RHOMBUS:
                    (grill as GrillGridWithRhombusOnIntersections).rows = (previousGrill as GrillGridWithRhombusOnIntersections).rows;
                    (grill as GrillGridWithRhombusOnIntersections).columns = (previousGrill as GrillGridWithRhombusOnIntersections).columns;
                    (grill as GrillGridWithRhombusOnIntersections).rhombusWidth = (previousGrill as GrillGridWithRhombusOnIntersections).rhombusWidth;
                    (grill as GrillGridWithRhombusOnIntersections).rhombusHeight = (previousGrill as GrillGridWithRhombusOnIntersections).rhombusHeight;
                    break;
            }
            this.grill = grill;
        }
        this.area = undefined;
        this.svgTarget = undefined;
        this.subWindow = undefined;
        this.points = [];
        this.positions = [];
    }

    getGenericGrillGrid(): GenericGrillGrid {
        return this.grill as GenericGrillGrid;
    }

    getGrillGrid(): GrillGrid {
        return this.grill as GrillGrid;
    }

    getGrillGridWithRhombusOnIntersections(): GrillGridWithRhombusOnIntersections {
        return this.grill as GrillGridWithRhombusOnIntersections;
    }
}
