import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api/menuitem";
import * as _ from "underscore";
import {AddProductDialogDataProviderComponent} from "./add-product-dialog-data-provider.component";
import {ConfigData} from "../../config-editor/config-data";
import {ConfigSystem} from "../../../window-system/config-system/config-system";
import {ConfigTypeDescriptor} from "../../../window-system/config-type-descriptor/config-type-descriptor";
import {SelectItem} from "primeng/api/selectitem";

@Component({
    selector: 'app-add-config-dialog',
    templateUrl: './add-product-dialog-data-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddConfigDialogComponent extends AddProductDialogDataProviderComponent<ConfigSystem> implements OnInit {

    @Input() data: ConfigData;

    tableHeader = 'OFFER.TABS.SECTION.CONFIG.CONFIG';
    dropdownSelectLabel = 'OFFER.DRAWING.CONFIG_FUNCTIONS';
    submitButtonLabel = 'OFFER.DRAWING.CONFIG_ADD';

    constructor(translate: TranslateService,
                domSanitizer: DomSanitizer,
                changeDetector: ChangeDetectorRef) {
        super(translate, domSanitizer, changeDetector);
        this.hasSlider = true;
    }

    ngOnInit() {
        this.initTabs(this.items);
        let selected;
        if (this.data && this.data.configSystemId) {
            selected = this.items.find(item => item.id === this.data.configSystemId);
        } else {
            selected = _.chain(this.items)
                .sortBy(item => item.sortIndex)
                .first()
                .value();
        }
        this.handleTabChange(selected.category);
        this.handleDropdownChange(selected.function.id);
        this.handleValueChange(selected);
        this.changeDetector.markForCheck();
    }

    private initTabs(configs: ConfigSystem[]): void {
        this.tabItems = _.chain(configs)
            .map(item => item.category)
            .uniq(item => item.id)
            .sortBy(descriptor => descriptor.sortIndex)
            .map(descriptor => this.prepareTabMenuItem(descriptor))
            .value();
    }

    private prepareTabMenuItem(category: ConfigTypeDescriptor): MenuItem {
        return {
            label: category.name[this.translate.currentLang],
            icon: 'fa fa-list',
            command: () => this.handleTabChange(category),
            disabled: false,
            tabindex: this.getEntityTabIndex(category)
        };
    }

    private getEntityTabIndex(category: ConfigTypeDescriptor): string {
        return category.symbol;
    }

    handleTabChange(category: ConfigTypeDescriptor): void {
        this.activeTabItem = this.tabItems.find(tab => tab.tabindex === this.getEntityTabIndex(category));
        let filteredItems = _.chain(this.items || [])
            .filter(item => this.getEntityTabIndex(item.category) === this.activeTabItem.tabindex)
            .sortBy(item => item.sortIndex)
            .value();
        this.initDropdown(filteredItems);
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleDropdownChange(selectedItem.function.id);
    }

    public initDropdown(items: ConfigSystem[]): void {
        this.dropdownSelectOptions = _.chain(items)
            .map(item => item.function)
            .uniq(descriptor => descriptor.id)
            .sortBy(descriptor => descriptor.sortIndex)
            .map(descriptor => this.prepareDropdownOption(descriptor))
            .value();
        this.showDropdown = this.dropdownSelectOptions.length > 1;
    }

    private prepareDropdownOption(descriptor: ConfigTypeDescriptor): SelectItem {
        return {
            label: descriptor.name[this.translate.currentLang],
            value: descriptor.id,
            available: true
        };
    }

    handleDropdownChange(dropdownValue: number): void {
        this.selectedDropdownSelectOption = dropdownValue;
        let filteredItems = _.chain(this.items || [])
            .filter(item => this.getEntityTabIndex(item.category) === this.activeTabItem.tabindex)
            .filter(item => item.function.id === this.selectedDropdownSelectOption)
            .sortBy(item => item.sortIndex)
            .value();
        this.tableOptions = filteredItems;
        for (let item of filteredItems) {
            this.tableOptionNames[item.id] = item.name[this.translate.currentLang];
        }
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleValueChange(selectedItem);
    }

    handleValueChange(item: ConfigSystem): void {
        this.selectedTableOption = item;
        this.selectedValueImage = null;
        if (this.selectedTableOption != undefined) {
            this.largeImageId = this.selectedTableOption.id;
            this.selectedValueDescription = this.prepareDescription(item.description);
            if (item.image) {
                this.selectedValueImage = this.domSanitizer.bypassSecurityTrustUrl(item.image);
            }
        }
    }
}
