<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_RECOMMENDED_CONFIGURATION.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_RECOMMENDED_CONFIGURATION.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">
        <p-column [style]="{'vertical-align':'middle', 'width.px':64}">
            <ng-template pTemplate="body" let-item="rowData">
                <span *ngIf="item.configurationInvalid" class="flex-row">
                    <i class="material-icons"
                       title="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.CONFIGURATION_INVALID' | translate }}">report_problem</i>
                </span>
            </ng-template>
        </p-column>
        <p-column field="windowSystemName"
                  header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.WINDOW_SYSTEM_NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.windowSystemName[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column field="businessTypeName"
                  header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.BUSINESS_TYPE_NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.businessTypeName[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column field="nameOverride" header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.NAME_OVERRIDE' | translate }}"
                  [sortable]="true" [filter]="showFilters"></p-column>
        <p-column field="description" header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.DESCRIPTION' | translate }}"
                  [sortable]="true" [filter]="showFilters"></p-column>
        <p-column field="productConfiguration.windowWidth"
                  header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.WIDTH' | translate }}"></p-column>
        <p-column field="productConfiguration.windowHeight"
                  header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.HEIGHT' | translate }}"></p-column>
        <p-column field="sortIndex" header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.SORT_INDEX' | translate }}"
                  [sortable]="true"></p-column>
        <p-column field="active" header="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.STATUS' | translate }}"
                  [sortable]="true" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{ (item.active ? 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.ACTIVE' : 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.INACTIVE') | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard #createWizard *ngIf="newItem"
            header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_RECOMMENDED_CONFIGURATION.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="VALIDATORS.DATA">
        <app-webshop-recommended-configuration-general-form [item]="item"
                                                            [validationErrors]="validationErrors"></app-webshop-recommended-configuration-general-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.BUSINESS_TYPE' | translate }}"
                     [id]="STEPS.BUSINESS_TYPE" [validate]="VALIDATORS.BUSINESS_TYPE">
        <app-webshop-recommended-configuration-business-type-form [item]="item" [validationErrors]="validationErrors"
                                                                  [active]="createWizard.getCurrentStepId() === STEPS.BUSINESS_TYPE"></app-webshop-recommended-configuration-business-type-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.SIZE' | translate }}" [id]="STEPS.SIZE"
                     [validate]="VALIDATORS.SIZE">
        <app-webshop-recommended-configuration-size-form [item]="item"
                                                         [validationErrors]="validationErrors"></app-webshop-recommended-configuration-size-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.WINDOW_SYSTEM_MODEL' | translate }}"
                     [id]="STEPS.MODEL" [validate]="VALIDATORS.MODEL">
        <app-webshop-recommended-configuration-model-form [item]="item" [validationErrors]="validationErrors"
                                                          [active]="createWizard.getCurrentStepId() === STEPS.MODEL"></app-webshop-recommended-configuration-model-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.GLAZING' | translate }}" [id]="STEPS.GLAZING"
                     [validate]="VALIDATORS.GLAZING">
        <app-webshop-recommended-configuration-glazing-form [item]="item" [validationErrors]="validationErrors"
                                                            [active]="createWizard.getCurrentStepId() === STEPS.GLAZING"></app-webshop-recommended-configuration-glazing-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.COLOR' | translate }}" [id]="STEPS.COLOR">
        <app-webshop-recommended-configuration-color-form [item]="item" [validationErrors]="validationErrors"
                                                          [active]="createWizard.getCurrentStepId() === STEPS.COLOR"></app-webshop-recommended-configuration-color-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.AREA_BARS' | translate }}" [id]="STEPS.AREA_BARS">
        <app-webshop-recommended-configuration-area-bars-form [item]="item" [validationErrors]="validationErrors"
                                                              [active]="createWizard.getCurrentStepId() === STEPS.AREA_BARS"></app-webshop-recommended-configuration-area-bars-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.WEBSHOP_CHARGES' | translate }}"
                     [id]="STEPS.WEBSHOP_CHARGES">
        <app-webshop-recommended-configuration-webshop-charges-form [item]="item" [validationErrors]="validationErrors"
                                                                    [active]="createWizard.getCurrentStepId() === STEPS.WEBSHOP_CHARGES"></app-webshop-recommended-configuration-webshop-charges-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog #updateWizard *ngIf="displayDialog"
                   header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_RECOMMENDED_CONFIGURATION.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()"
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step [id]="STEPS.DATA" [validate]="VALIDATORS.DATA"
                     label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.DATA' | translate }}">
        <app-webshop-recommended-configuration-general-form [item]="item"
                                                            [validationErrors]="validationErrors"></app-webshop-recommended-configuration-general-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.BUSINESS_TYPE' | translate }}"
                     [id]="STEPS.BUSINESS_TYPE" [validate]="VALIDATORS.BUSINESS_TYPE">
        <app-webshop-recommended-configuration-business-type-form [item]="item" [validationErrors]="validationErrors"
                                                                  [active]="updateWizard.getCurrentStepId() === STEPS.BUSINESS_TYPE"></app-webshop-recommended-configuration-business-type-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.SIZE' | translate }}" [id]="STEPS.SIZE"
                     [validate]="VALIDATORS.SIZE">
        <app-webshop-recommended-configuration-size-form [item]="item"
                                                         [validationErrors]="validationErrors"></app-webshop-recommended-configuration-size-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.WINDOW_SYSTEM_MODEL' | translate }}"
                     [id]="STEPS.MODEL" [validate]="VALIDATORS.MODEL">
        <app-webshop-recommended-configuration-model-form [item]="item" [validationErrors]="validationErrors"
                                                          [active]="updateWizard.getCurrentStepId() === STEPS.MODEL"></app-webshop-recommended-configuration-model-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.GLAZING' | translate }}" [id]="STEPS.GLAZING"
                     [validate]="VALIDATORS.GLAZING">
        <app-webshop-recommended-configuration-glazing-form [item]="item" [validationErrors]="validationErrors"
                                                            [active]="updateWizard.getCurrentStepId() === STEPS.GLAZING"></app-webshop-recommended-configuration-glazing-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.COLOR' | translate }}" [id]="STEPS.COLOR">
        <app-webshop-recommended-configuration-color-form [item]="item" [validationErrors]="validationErrors"
                                                          [active]="updateWizard.getCurrentStepId() === STEPS.COLOR"></app-webshop-recommended-configuration-color-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.AREA_BARS' | translate }}" [id]="STEPS.AREA_BARS">
        <app-webshop-recommended-configuration-area-bars-form [item]="item" [validationErrors]="validationErrors"
                                                              [active]="updateWizard.getCurrentStepId() === STEPS.AREA_BARS"></app-webshop-recommended-configuration-area-bars-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.WEBSHOP_CHARGES' | translate }}"
                     [id]="STEPS.WEBSHOP_CHARGES">
        <app-webshop-recommended-configuration-webshop-charges-form [item]="item" [validationErrors]="validationErrors"
                                                                    [active]="updateWizard.getCurrentStepId() === STEPS.WEBSHOP_CHARGES"></app-webshop-recommended-configuration-webshop-charges-form>
    </app-wizard-step>
</app-wizard-dialog>
