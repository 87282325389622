<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'CONFIG_TYPE_DESCRIPTOR.TITLE.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button (onClick)="showHideFilters()"
                                   buttonId="toggle-filter-button"
                                   icon="filter_list" label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}" type="filter"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToCopy()" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   buttonId="copy" icon="content_copy" label="{{ 'CONFIG_TYPE_DESCRIPTOR.TITLE.COPY' | translate }}"
                                   type="action"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToAdd()" [hotkey]="newElementHotkey" [size]="40"
                                   align="right" buttonId="add" icon="add" label="{{ 'CONFIG_TYPE_DESCRIPTOR.TITLE.ADD' | translate }}"
                                   type="main-action"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt (onLazyLoad)="loadItemsLazy($event)" (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedItem" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 [lazy]="true" [paginator]="true" [responsive]="true" [rowsPerPageOptions]="rowsPerPageOptions"
                 [rows]="chosenRowsPerPage" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [totalRecords]="totalRecords" [value]="itemList" id="configTypeDescriptorsTable"
                 selectionMode="single">
        <p-column [filter]="showFilters" [sortable]="true" field="name"
                  filterField="name" filterMatchMode="contains" header="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.NAME' | translate }}"
                  sortField="{{ 'name.' + translate.currentLang }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="shortcut"
                  filterField="shortcut" filterMatchMode="contains" header="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SHORTCUT' | translate }}"
                  sortField="{{ 'shortcut.' + translate.currentLang }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.shortcut[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="symbol"
                  filterField="symbol" filterMatchMode="contains" header="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SYMBOL' | translate }}"
                  sortField="symbol">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.symbol }}
            </ng-template>
        </p-column>
        <p-column [sortable]="true" field="sortIndex" header="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SORT_INDEX' | translate }}"></p-column>
        <p-column [filterValues]="filterLevels | async" [filter]="showFilters" field="descriptorLevel"
                  header="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.DESCRIPTOR_LEVEL.LABEL' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                <p>{{ ('CONFIG_TYPE_DESCRIPTOR.FORM.DESCRIPTOR_LEVEL.' + item.descriptorLevel) | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard (onCancel)="cancel()" (onComplete)="submit()" *ngIf="newItem"
            header="{{ 'CONFIG_TYPE_DESCRIPTOR.TITLE.ADD' | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_TYPE_DESCRIPTOR.STEPS.DATA' | translate }}">
        <app-config-type-descriptor-form [item]="item" [validationErrors]="validationErrors"></app-config-type-descriptor-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog (onCancel)="cancel()" (onComplete)="submit()"
                   *ngIf="!newItem && displayDialog" contentStyleClass="scrolled-dialog-60vw" header="{{  (copyMode ? 'CONFIG_TYPE_DESCRIPTOR.TITLE.COPY' : 'CONFIG_TYPE_DESCRIPTOR.TITLE.EDIT') | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_TYPE_DESCRIPTOR.STEPS.DATA' | translate }}">
        <app-config-type-descriptor-form [item]="item" [validationErrors]="validationErrors"></app-config-type-descriptor-form>
    </app-wizard-step>
</app-wizard-dialog>
