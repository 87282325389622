import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationService} from "../../../common/service/validation.service";
import {TermsOfUseService} from './terms-of-use.service';
import {TermsOfUse} from './termsOfUse';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['../settings.component.css', './terms-of-use.component.css'],
    providers: [DataServiceHelper, ValidationService, TermsOfUseService]
})
export class TermsOfUseComponent implements OnInit {

    termsOfUse: TermsOfUse;
    showPreview = false;
    initializeEditor = false;
    notifyUsers = false;
    canEdit: boolean;
    validationErrors = {};

    constructor(private translate: TranslateService,
                private termsOfUseService: TermsOfUseService,
                private permissions: Permissions,
                private growlMessageController: GrowlMessageController,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngOnInit() {
        this.canEdit = this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']});
        this.termsOfUseService.getTermsOfUse().subscribe({
            next: response => {
                this.termsOfUse = response;
                this.initializeEditor = true;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    openPreview() {
        this.showPreview = true;
    }

    closePreview() {
        this.showPreview = false;
    }

    saveTermsOfUse(): void {
        this.termsOfUseService.saveTermsOfUse(this.termsOfUse, this.notifyUsers).subscribe({
            next: () => {
                this.showSuccessMessage();
                if (this.notifyUsers) {
                    this.termsOfUse.version++;
                }
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    private showSuccessMessage(): void {
        let messageKey = 'TERMS_OF_USE.UPDATED.' + (this.notifyUsers ? 'WITH_NOTIFICATION' : 'WITHOUT_NOTIFICATION');
        this.growlMessageController.info(messageKey);
    }

    editTermsContent(termsContent: string): void {
        this.termsOfUse.content = termsContent;
        this.resetErrors();
    }

    private resetErrors() {
        this.validationErrors = {};
        this.changeDetector.markForCheck();
    }
}
