<ng-container *ngIf="showDimensionSection">
    <div class="new-form-field">
        <div class="new-form-field-input-container">
            <img *ngIf="src && image" class="image cursor-zoom-in" id="imagePreview" [src]="src" (click)="openLargeImage()" />
        </div>
        <div class="new-form-field-label-container description-wrapper">
            <span>{{definition?.description[userLang]}}</span>
        </div>
    </div>
</ng-container>

<p-accordion [multiple]="true" styleClass="no-accordion-content-padding">
    <ng-container *ngFor="let group of groups">
        <p-accordionTab *ngIf="showGroup(group) || definition?.isOtherSystem"
                        header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.GROUP.'+ group | translate}}"
                        [ngClass] = "{'accordion-header-with-errors': sectionContainsErrors(group)}"
                        [selected]="isGroup(group, 'DIMENSIONS') || isGroup(group, 'BULK_DIMENSIONS') || isGroup(group, 'BASIC_PROPS')">

            <ng-container *ngFor="let cecha of cechyToShow">
                <ng-container *ngIf="cecha.grupa==group">
                    <div class="new-form-row" *ngIf="!isCechaDependent(cecha)">
                        <app-configurable-addon-field [isMasterCecha]="true" [cecha]="cecha"
                                                      [pricingErrors]="getPricingErrorsForField(cecha.symbol)"
                                                      [errors]="errors" [errorKey]="cecha.symbol"
                                                      [initialValue]="getAttribute(cecha)"
                                                      [userLang]="userLang" [readOnlyMode]="readOnlyMode"
                                                      [displayImageIcon]="displayImageIcon(cecha)"
                                                      (onMasterCechaChange)="setCechyDependentOnCurrentCecha(cecha)"
                                                      (onImageIconClick)="handleFieldImageClick(cecha)"
                                                      (valueChange)="changeItemValue($event, cecha)">
                        </app-configurable-addon-field>
                    </div>
                    <div class="new-form-row" *ngIf="isCechaDependent(cecha) && isToShow(cecha)">
                        <app-configurable-addon-field [cecha]="cecha"
                                                      [pricingErrors]="getPricingErrorsForField(cecha.symbol)"
                                                      [errors]="errors" [errorKey]="cecha.symbol"
                                                      [initialValue]="getAttribute(cecha)"
                                                      [userLang]="userLang" [readOnlyMode]="readOnlyMode"
                                                      [displayImageIcon]="displayImageIcon(cecha)"
                                                      (onImageIconClick)="handleFieldImageClick(cecha)"
                                                      (valueChange)="changeItemValue($event, cecha)">
                        </app-configurable-addon-field>
                    </div>
                </ng-container>
            </ng-container>
        </p-accordionTab>
    </ng-container>
</p-accordion>
<app-window-component-preview-dialog [(previewData)]="colorPreviewData"></app-window-component-preview-dialog>
