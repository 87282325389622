<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.TERMS_OF_USE.GENERAL' | translate }}
            <span *ngIf="initializeEditor">{{'TERMS_OF_USE.VERSION' | translate:{version: termsOfUse.version} }}</span>
        </div>
    </div>
    <div class="settings-form-wrapper">
        <p-editor *ngIf="initializeEditor" [readonly]="!canEdit"
                  [ngModel]="termsOfUse.content" (ngModelChange)="editTermsContent($event)"
                  [ngStyle]="validationErrors['content'] == null ? {} : {border: '1px solid red'}">
            <p-header *ngIf="!canEdit"></p-header>
        </p-editor>
        <div *ngIf="validationErrors['content'] != undefined" class="new-form-field-error-message">
            {{ validationErrors['content'] | translate }}
        </div>
        <div class="new-form-row">
            <div class="new-form-field">
                <app-text-area *ngIf="termsOfUse != undefined" label="{{ 'TERMS_OF_USE.CONSENT_1' | translate }}"
                               [disabled]="!canEdit" [(ngModel)]="termsOfUse.consentCheckbox1"></app-text-area>
            </div>
            <div class="new-form-field">
                <app-text-area *ngIf="termsOfUse != undefined" label="{{ 'TERMS_OF_USE.CONSENT_2' | translate }}"
                               [disabled]="!canEdit" [(ngModel)]="termsOfUse.consentCheckbox2"></app-text-area>
            </div>
        </div>
        <div class="new-form-row">
            <div class="new-form-field">
                <app-text-area *ngIf="termsOfUse != undefined" label="{{ 'TERMS_OF_USE.CONSENT_3' | translate }}"
                               [disabled]="!canEdit" [(ngModel)]="termsOfUse.consentCheckbox3"></app-text-area>
            </div>
            <div class="new-form-field">
                <app-text-area *ngIf="termsOfUse != undefined" label="{{ 'TERMS_OF_USE.CONSENT_4' | translate }}"
                               [disabled]="!canEdit" [(ngModel)]="termsOfUse.consentCheckbox4"></app-text-area>
            </div>
        </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button label="{{ 'GENERAL.PREVIEW' | translate }}" icon="pageview" type="action" [size]="40"
                               (onClick)="openPreview()"></app-simple-button>
            <app-checkbox *ngIf="canEdit" label="{{'GENERAL.SAVE_WITH_NOTIFICATION' | translate}}" inputId="notifyUsers"
                          [(ngModel)]="notifyUsers"></app-checkbox>
            <app-simple-button *ngIf="canEdit" label="{{ 'GENERAL.SAVE' | translate }}" icon="check" type="main-action"
                               [size]="40" (onClick)="saveTermsOfUse()"></app-simple-button>
        </div>
    </div>
</div>

<app-terms-of-use-dialog *ngIf="showPreview" [termsOfUse]="termsOfUse" dialogId="termsOfUseDialog"
                         (onConfirmed)="closePreview()" (onCancel)="closePreview()"></app-terms-of-use-dialog>
