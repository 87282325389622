<div class="table-header">
    <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_AREA_BARS.LIST' | translate }}</div>
    <div class="table-header-buttons">
        <div class="table-header-buttons-container">
            <app-simple-button buttonId="toggle-filter-button"
                               label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                               icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
        </div>
    </div>
</div>
<p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true"
             [lazy]="true" [totalRecords]="totalRecords" selectionMode="single" [responsive]="true"
             (onLazyLoad)="loadItemsLazy($event)" (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedItem"
             [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
    <p-column field="webshopAreaBarsCode" header="{{ 'WEBSHOP_AREA_BARS.FORM.CODE' | translate }}" [sortable]="false"
              [filter]="showFilters" filterMatchMode="equals" [filterValues]="webshopAreaBarsCodeFilter | async">
        <ng-template let-webshopAreaBars="rowData" pTemplate="body">
            {{ webshopAreaBars.webshopAreaBarsCode }}
        </ng-template>
    </p-column>
    <p-column>
        <ng-template let-webshopAreaBars="rowData" pTemplate="body">
            <img class="webshop-area-bars-image" [src]="getIcon(webshopAreaBars.webshopAreaBarsCode)"
                 [alt]="webshopAreaBars.webshopAreaBarsCode">
        </ng-template>
    </p-column>
    <p-column field="sortIndex" header="{{ 'WEBSHOP_AREA_BARS.FORM.SORT_INDEX' | translate }}" [sortable]="true"></p-column>
    <ng-template pTemplate="paginatorLeft">
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
</p-dataTable>
<app-wizard-dialog *ngIf="displayDialog" [header]="'WEBSHOP_AREA_BARS.EDIT' | translate"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step id="data" [validate]="stepValidator">
        <div class="new-form-400">
            <div class="new-form-row">
                <app-select inputId="webshopAreaBarsCodeSelect" [label]="'WEBSHOP_AREA_BARS.FORM.CODE' | translate"
                            disabled [options]="webshopAreaBarsCodeFilter | async"
                            [ngModel]="item.webshopAreaBarsCode"></app-select>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="sortIndexInput" [label]="'WEBSHOP_AREA_BARS.FORM.SORT_INDEX' | translate"
                                  [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                                  [max]="totalRecords" [step]="1" [allowOnlyIntegers]="true" [required]="true"
                                  [(ngModel)]="item.sortIndex"></app-input-number>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
