import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {forkJoin} from 'rxjs';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopChargeWithPrice} from '../../webshop-pricing-interfaces';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration-webshop-charges-form',
    templateUrl: './webshop-recommended-configuration-webshop-charges-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationWebshopChargesFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    allCharges: WebshopChargeWithPrice[];
    availableChargeIds: Set<number>;

    private forceDisabled = false;

    constructor(private service: WebshopRecommendedConfigurationService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            forkJoin({
                allCharges: this.service.getWebshopCharges(Object.assign({}, this.item.productConfiguration, {additionalChargesIds: []})),
                availableCharges: this.service.getWebshopCharges(this.item.productConfiguration)
            }).subscribe(data => {
                this.allCharges = data.allCharges;
                this.availableChargeIds = new Set<number>(data.availableCharges.map(charge => charge.id));
                this.changeDetector.markForCheck();
            });
        }
    }

    isDisabled(charge: WebshopChargeWithPrice): boolean {
        return this.forceDisabled || !this.availableChargeIds.has(charge.id);
    }

    isSelected(charge: WebshopChargeWithPrice): boolean {
        return this.item.productConfiguration.additionalChargesIds != undefined
            && this.item.productConfiguration.additionalChargesIds.includes(charge.id);
    }

    handleSelectionChange(charge: WebshopChargeWithPrice, selected: boolean): void {
        if (this.item.productConfiguration.additionalChargesIds == undefined) {
            this.item.productConfiguration.additionalChargesIds = [];
        }
        const selectedIndex = this.item.productConfiguration.additionalChargesIds.indexOf(charge.id);
        if (selected) {
            if (selectedIndex < 0) {
                this.item.productConfiguration.additionalChargesIds.push(charge.id);
            }
        } else {
            if (selectedIndex >= 0) {
                this.item.productConfiguration.additionalChargesIds.splice(selectedIndex, 1);
            }
        }
        this.forceDisabled = true;
        this.service.getWebshopCharges(this.item.productConfiguration).subscribe(charges => {
            this.forceDisabled = false;
            this.availableChargeIds = new Set<number>(charges.map(c => c.id));
            this.changeDetector.markForCheck();
        });
        this.changeDetector.markForCheck();
    }
}
