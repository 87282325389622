import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {OnceFlag} from "../../../../shared/once-flag";
import {
    MotlawaDistributionPossibilitiesSelectionData,
    MotlawaIntegrationDialogsData
} from "../motlawa-integration-dialogs-data";
import {SelectItemImpl} from "../../../../common/service/select.item.impl";
import {MotlawaBulkAddonsDistributionPossibility} from "../../../../common/enums/motlawa-bulk-addons-distribution-possibility.enum";
import {MotlawaBulkAddonsDistributionSelection} from "../motlawa-bulk-addons-distribution-selection";
import {MotlawaBulkAddonsDistributionPossibilities} from "../motlawa-bulk-addons-distribution-possibilities";

@Component({
    selector: 'app-motlawa-bulk-addons-distribution',
    templateUrl: './motlawa-bulk-addons-distribution-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MotlawaBulkAddonsDistributionDialogComponent implements OnInit{

    @Input() dialogsData: MotlawaIntegrationDialogsData;

    @Output() onHide = new EventEmitter<void>();

    @Output() onConfirm = new EventEmitter<void>();

    private readonly dialogHideHelper = new OnceFlag();

    supplierIds: number[];
    offerChargesSupplierIds: number[];

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.supplierIds = this.dialogsData.addonsSelections ? Object.keys(this.dialogsData.addonsSelections.supplierNamesById).map(id => +id) : [];
        this.offerChargesSupplierIds = this.dialogsData.offerChargesSelections ? Object.keys(this.dialogsData.offerChargesSelections.supplierNamesById).map(id => +id) : [];
    }

    hide(): void {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    confirm(): void {
        this.onConfirm.emit();
    }

    optionFormatter = (possibility: MotlawaBulkAddonsDistributionPossibility) => new SelectItemImpl(
        this.translate.instant(`MOTLAWA_INTEGRATION_DIALOG.DISTRIBUTION_POSSIBILITIES.${possibility}`), possibility);

    getSupplierPossibilities(data: MotlawaDistributionPossibilitiesSelectionData, supplierId: number): MotlawaBulkAddonsDistributionPossibilities[] {
        return data.possibilites.filter(p => p.supplierId === supplierId);
    }

    getSelection(data: MotlawaDistributionPossibilitiesSelectionData, supplierId: number, offerId: number): MotlawaBulkAddonsDistributionSelection {
        return data.selections.find(s => s.supplierId === supplierId && s.offerId === offerId);
    }

    setSelection(data: MotlawaDistributionPossibilitiesSelectionData, supplierId: number, offerId: number, selection: MotlawaBulkAddonsDistributionPossibility) {
        this.getSelection(data, supplierId, offerId).selection = selection;
    }
}
