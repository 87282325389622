<div class="painter">
    <div id="window-svg-container" app-designer-length-unit-hider>
        <svg #svgElement (window:resize)="OnWindowResize($event)" [style.display]="previewVisible ? 'block' : 'none'" class="svg" id="window-drawing-svg" width="100%"
             height="100%">
        </svg>
    </div>
    <div *ngIf="!redrawPreviewMode">
        <app-add-subwindow-dialog #addWindowDialog *ngIf="isAddWindowDialogDisplayed()"
                               (dialogClosed)="changeDisplayAddWindowDialog(false)"
                               [businessTypeChangeSubwindowId]="businessTypeChangeSubwindowId"
                               [displayDialog]="true" [data]="data"
                               [windowSystems]="windowSystems"
                               (businessTypeSelected)="businessTypeSelected($event)"
                               [addVertically]="addVertically"
                               [windowBusinessTypes]="windowBusinessTypes"
                               [subwindowTypes]="subwindowTypes"
                               [mode]="mode"></app-add-subwindow-dialog>

        <app-add-window-dialog *ngIf="isAddNewWindowDialogDisplayed()" [displayDialog]="true"
                               [windowSystems]="windowSystems" [items]="businessTypes"
                               [catalogConfiguration]="catalogConfiguration"
                               [largeImageGetter]="largeImageGetter()"
                               [data]="data" (dialogClosed)="windowSelected($event)"></app-add-window-dialog>

        <p-dialog *ngIf="guidesDialogData.displayDialog" [visible]="true" [resizable]="false"
                  [modal]="true" (onHide)="clearNewWindowErrors(); guidesDialogData.displayDialog = false"
                  (onShow)="focusDimensionFieldSentEvent()" [focusOnShow]="false">

            <p-header>
                <div class="p-dialog-title">
                    {{'OFFER.DRAWING.RESIZE.TITLE' | translate}}
                </div>
            </p-header>

            <div class="scrolled-dialog">
                <div class="new-form-400">
                    <div class="new-form-row">
                        <app-input-number inputId="resizeNewSize" label="{{ 'OFFER.DRAWING.RESIZE.SIZE' | translate }}"
                                          [validationMessageKey]="windowSizeValidationError?.name"
                                          (validationMessageKeyChange)="windowSizeValidationError = undefined"
                                          [disabled]="guidesDialogData.locked"
                                          [min]="guidesDialogData.isChangeVertical ? maxWindowDimensions.MIN_HEIGHT : maxWindowDimensions.MIN_WIDTH"
                                          [max]="guidesDialogData.isChangeVertical ? maxWindowDimensions.MAX_HEIGHT : maxWindowDimensions.MAX_WIDTH"
                                          [(ngModel)]="guidesDialogData.newValue">
                            <ng-template pTemplate="validationMessage">
                                <div *ngIf="windowSizeValidationError != null" class="new-form-field-error-message">
                                    {{ windowSizeValidationError.name | translate: windowSizeValidationError.params }}
                                </div>
                            </ng-template>
                        </app-input-number>
                    </div>

                    <div *ngIf="!guidesDialogData.hideLock" class="new-form-row">
                        <app-checkbox inputId="resizeLockCheckbox"
                                      label="{{ 'OFFER.DRAWING.RESIZE.BLOCK' | translate }}"
                                      [(ngModel)]="guidesDialogData.locked"
                                      (ngModelChange)="focusDimensionFieldSentEvent()">
                            <ng-template pTemplate="label" let-inputId="inputId">
                                <label [attr.for]="inputId" class="new-form-checkbox-label">
                                    <span class="material-icons resize-lock-label">{{ guidesDialogData.locked ? 'lock' : 'lock_open' }}</span>
                                    <span class="resize-lock-label">{{ 'OFFER.DRAWING.RESIZE.BLOCK' | translate }}</span>
                                </label>
                            </ng-template>
                        </app-checkbox>
                    </div>
                </div>
            </div>

            <p-footer>
                <div class="p-dialog-buttonpane">
                    <app-simple-button label="{{ 'OFFER.DRAWING.RESIZE.CHANGE' | translate }}" type="main-action"
                                       [size]="40" [disabled]="guidesDialogData.saveDisabled"
                                       (onClick)="displayChangeSizeEvent()"></app-simple-button>
                    <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                       (onClick)="cancelGuideEditing()"></app-simple-button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
</div>

