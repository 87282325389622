import {MotlawaBulkAddonsDistributionPossibility} from "../../../common/enums/motlawa-bulk-addons-distribution-possibility.enum";
import {MotlawaBulkAddonsDistributionPossibilities} from "./motlawa-bulk-addons-distribution-possibilities";
import {MotlawaIntegrationData} from "./motlawa-integration-data";
import {MotlawaBulkAddonsDistributionSelection} from "./motlawa-bulk-addons-distribution-selection";

export class MotlawaIntegrationDialogsData {
    addonsSelections: MotlawaDistributionPossibilitiesSelectionData;
    offerChargesSelections: MotlawaDistributionPossibilitiesSelectionData;
    paymentPackageId: number;
    results: MotlawaIntegrationData;

    constructor(motlawaBulkAddonsDistributionPossibilities: MotlawaBulkAddonsDistributionPossibilities[],
                motlawaOfferChargesDistributionPossibilities: MotlawaBulkAddonsDistributionPossibilities[],
                paymentPackageId: number) {
        if (motlawaBulkAddonsDistributionPossibilities != null) {
            this.addonsSelections = new MotlawaDistributionPossibilitiesSelectionData(motlawaBulkAddonsDistributionPossibilities);
        }
        if (motlawaOfferChargesDistributionPossibilities != null) {
            this.offerChargesSelections = new MotlawaDistributionPossibilitiesSelectionData(motlawaOfferChargesDistributionPossibilities);
        }
        this.paymentPackageId = paymentPackageId;
    }
}

export class MotlawaDistributionPossibilitiesSelectionData {
    supplierNamesById: { [supplierId: number]: string };
    possibilites: MotlawaBulkAddonsDistributionPossibilities[];
    selections: MotlawaBulkAddonsDistributionSelection[];

    constructor(motlawaDistributionPossibilities: MotlawaBulkAddonsDistributionPossibilities[]) {
        this.possibilites = motlawaDistributionPossibilities;
        this.supplierNamesById = {};
        motlawaDistributionPossibilities.forEach(dp => {
            this.supplierNamesById[dp.supplierId] = dp.supplierName;
        });
        this.selections = motlawaDistributionPossibilities.map(
            dp => new MotlawaBulkAddonsDistributionSelection(dp.supplierId, dp.offerId,
                MotlawaBulkAddonsDistributionPossibility.NOTHING));
    }
}
