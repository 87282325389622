import {SupportedLanguages} from "../../supportedLanguages";

export class User {
    id: number;
    login: string;
    password: string;
    repeatedPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    interfaceLanguage: string;
    otherInfo: string;
    roleName: string;
    roleIds: number[];
    subsystemId: number;
    active: boolean;
    isHidden: boolean;
    displaySideMenuLabels: boolean;
    webshopPartner: boolean;
    accountExpirationDate: Date;
    clientId: number;
    motlawaLogin: string;
    notifyOnOfferStatusChange: boolean;
    onHoliday: boolean;
    enableOfferExport: boolean;
    showProdOrderStatsForUser: boolean;

    constructor() {
        this.id = undefined;
        this.login = undefined;
        this.password = undefined;
        this.repeatedPassword = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.email = undefined;
        this.phone = undefined;
        this.interfaceLanguage = SupportedLanguages.languages[0].code;
        this.otherInfo = undefined;
        this.subsystemId = undefined;
        this.roleName = undefined;
        this.roleIds = [];
        this.active = true;
        this.isHidden = false;
        this.displaySideMenuLabels = true;
        this.webshopPartner = false;
        this.accountExpirationDate = undefined;
        this.clientId = undefined;
        this.motlawaLogin = undefined;
        this.notifyOnOfferStatusChange = false;
        this.onHoliday = false;
        this.enableOfferExport = false;
        this.showProdOrderStatsForUser = true;
    }
}
