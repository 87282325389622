export class ElementsPositionsForGuides {
    xMullions: number[] = [];
    yMullions: number[] = [];
    xMullionsTop: number[] = [];
    yMullionsTop: number[] = [];
    xLineGrills: number[] = [];
    yLineGrills: number[] = [];
    xGrillGrids: number[] = [];
    yGrillGrids: number[] = [];
    xCuts: number[] = [];
    yCuts: number[] = [];
    xSubwindows: number[] = [];
    ySubwindows: number[] = [];
}
