import {MultilanguageField} from "../../../supportedLanguages";

export class Rack {
    id: number;
    name: MultilanguageField;
    weight: number;
    netEuroPrice: number;
    slots: number;
    totalWidth: number;
    totalDepth: number;
    totalHeight: number;
    usableWidth: number;
    usableDepth: number;
    usableHeight: number;
    active: boolean;
    additionallyCalculated: boolean;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.weight = undefined;
        this.netEuroPrice = undefined;
        this.slots = undefined;
        this.totalWidth = undefined;
        this.totalDepth = undefined;
        this.totalHeight = undefined;
        this.usableWidth = undefined;
        this.usableDepth = undefined;
        this.usableHeight = undefined;
        this.active = true;
        this.additionallyCalculated = false;
    }
}
