<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISTANCE_FRAMES.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_CREATE_DISTANCE_FRAME']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISTANCE_FRAMES.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISTANCE_FRAMES.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="framesTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.NAME)"
                          field="name" header="{{ 'DISTANCE-FRAME.FORM.NAME' | translate }}" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column [rowspan]="3" [sortable]="true" field="sortIndex" header="{{ 'DISTANCE-FRAME.FORM.SORT_INDEX' | translate }}"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.SHORTCUT)"
                          field="shortcut" header="{{ 'DISTANCE-FRAME.FORM.SHORTCUT' | translate }}" [rowspan]="3" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.SYMBOL)"
                          field="symbol" header="{{ 'DISTANCE-FRAME.FORM.SYMBOL' | translate }}" [rowspan]="3" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.THICKNESS)"
                          field="thickness" header="{{ 'DISTANCE-FRAME.FORM.THICKNESS' | translate }}" [rowspan]="3" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" filterType="number" [filterNumericMinValue]="1"
                          [filterNumericMaxValue]="1000"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"
                          header="{{ 'DISTANCE-FRAME.FORM.POSITIONS' | translate }}" [colspan]="7"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.GROUP)"
                          field="frameGroup" header="{{ 'DISTANCE-FRAME.FORM.GROUP' | translate }}" [rowspan]="3" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.TYPE)"
                          field="type" header="{{ 'DISTANCE-FRAME.FORM.TYPE' | translate }}" [rowspan]="3" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterType | async"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.WARM_FRAME)"
                          field="warmFrame" header="{{ 'DISTANCE-FRAME.FORM.WARM_FRAME' | translate }}" [rowspan]="3" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterWarmFrame | selectItemTranslate"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{ 'DISTANCE-FRAME.STEPS.SYSTEMS' | translate }}" [colspan]="4" [rowspan]="2"></p-column>
                <p-column *ngIf="fieldUsage.show(DistanceFrameField.ACTIVE)"
                          field="active" header="{{ 'DISTANCE-FRAME.FORM.STATUS' | translate }}" [rowspan]="3" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column></p-column><!-- dummy column, soaks :first-child padding -->
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="{{ 'DISTANCE-FRAME.FORM.2_GLASS' | translate }}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"[colspan]="2" header="{{ 'DISTANCE-FRAME.FORM.3_GLASS' | translate }}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"[colspan]="3" header="{{ 'DISTANCE-FRAME.FORM.4_GLASS' | translate }}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)"[rowspan]="2" header="{{ 'DISTANCE-FRAME.FORM.ALL_POSITIONS' | translate }}"></p-column>
            </p-row>
            <p-row>
                <p-column></p-column><!-- dummy column, soaks :first-child padding -->
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [style]="{'width': '44px'}" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="1"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="2"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" header="3"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.NAME)" field="name">
            <ng-template let-frame="rowData" pTemplate="body">
                {{frame.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="sortIndex"></p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.SHORTCUT)" field="shortcut">
            <ng-template let-frame="rowData" pTemplate="body">
                {{frame.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.SYMBOL)" field="symbol"></p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.THICKNESS)" field="thickness"></p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)" [style]="{'width': '44px'}">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [ngModel]="isFramePositionSelected(frame, 1, 2)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 1, 2)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                  [ngModel]="isFramePositionSelected(frame, 1, 3)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 1, 3)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                  [ngModel]="isFramePositionSelected(frame, 2, 3)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 2, 3)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                  [ngModel]="isFramePositionSelected(frame, 1, 4)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 1, 4)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                  [ngModel]="isFramePositionSelected(frame, 2, 4)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 2, 4)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                  [ngModel]="isFramePositionSelected(frame, 3, 4)"
                                  (ngModelChange)="handleFramePositionSelectionChange(frame, 3, 4)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.POSITION)">
            <ng-template let-frame="rowData" pTemplate="body">
                <span (dblclick)="suppressDoubleClick($event)">
                    <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.POSITION)"
                                           [ngModel]="getFrameAllPositionsSelectionState(frame)"
                                           (ngModelChange)="handleFrameAllPositionsSelectionChange(frame)"></app-tristate-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.GROUP)" field="frameGroup"></p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.TYPE)" field="type">
            <ng-template let-frame="rowData" pTemplate="body">
                {{('DISTANCE-FRAME.FORM.TYPES.'+ frame.type) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.WARM_FRAME)" field="warmFrame">
            <ng-template let-frame="rowData" pTemplate="body">
                {{(frame.warmFrame ? 'DISTANCE-FRAME.WARM_FRAME' : 'DISTANCE-FRAME.COLD_FRAME') | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-frame="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(frame.pcv)" (ngModelChange)="materialChange(frame, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-frame="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(frame.aluminium)" (ngModelChange)="materialChange(frame, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-frame="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(frame.wood)" (ngModelChange)="materialChange(frame, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-frame="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(frame.aluminiumWoodMix)" (ngModelChange)="materialChange(frame, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(DistanceFrameField.ACTIVE)" [rowspan]="3" [sortable]="false" field="active" header="{{ 'DISTANCE-FRAME.FORM.STATUS' | translate }}"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-frame="rowData" pTemplate="body">
                {{(frame.active ? 'DISTANCE-FRAME.FORM.ACTIVE' : 'DISTANCE-FRAME.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'DISTANCE-FRAME.ADD-FRAME-TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'DISTANCE-FRAME.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-distance-frame-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                 [types]="availableTypes | async"
                                 [warmFramesByThickness]="distanceFramesForWarmAlternatives"></app-distance-frame-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'DISTANCE-FRAME.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [lockedItemsIds]="windowSystemsByDefaultFrameId.get(item.id) || []"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.DISTANCE_FRAMES.COPY' : 'DISTANCE-FRAME.EDIT-FRAME-TITLE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'DISTANCE-FRAME.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-distance-frame-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"
                                 [types]="availableTypes | async"
                                 [warmFramesByThickness]="distanceFramesForWarmAlternatives"></app-distance-frame-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'DISTANCE-FRAME.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [lockedItemsIds]="windowSystemsByDefaultFrameId.get(item.id) || []"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
