import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, QueryList, ViewChildren} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {finalize, tap} from "rxjs/operators";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {Pricesheet, PricesheetCategory} from "./pricesheet";
import {PricesheetTabComponent} from "./pricesheet-tab/pricesheet-tab.component";
import {PricesheetValidator} from './pricesheet-validator';
import {PricesheetsService} from "./pricesheets.service";

@Component({
    selector: 'app-pricesheets',
    templateUrl: './pricesheets.component.html',
    styleUrls: ['./pricesheets.component.css', '../../shared-styles.css'],
    providers: [PricesheetsService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricesheetsComponent extends CrudCommonComponent<Pricesheet, PricesheetsService> implements OnInit {

    readonly availableCategories: PricesheetCategory[] = [PricesheetCategory.PRICING, PricesheetCategory.CONFIG_ADDONS,
        PricesheetCategory.VALIDATION, PricesheetCategory.UW, PricesheetCategory.GATE_SYSTEMS];

    availableTypes: SelectItem[] = [];
    categoriesByType = new Map<string, string>();
    selectedCategoryIndex = 0;
    selectedCategory: PricesheetCategory = this.availableCategories[this.selectedCategoryIndex];
    selectedItemByCategory = new Map<string, Pricesheet>();

    @ViewChildren(PricesheetTabComponent)
    datatables: QueryList<PricesheetTabComponent>;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, PricesheetsService, 'PRICESHEET', 'PricingSheet');
        this.item = new Pricesheet();
    }

    getDatatable(): DataTable {
        return undefined;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.itemService.getAvailableTypes().pipe(
            finalize(() => this.hideDataLoadingIndicator())
        ).subscribe({
            next: types => {
                for (let element of types) {
                    this.availableTypes.push({label: 'PRICESHEET.TYPES.' + element.type, value: element.type});
                    this.categoriesByType.set(element.type, element.category);
                }
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    getAvailableTypes(): SelectItem[] {
        let available = this.availableTypes.filter(type => this.selectedCategory === this.categoriesByType.get(type.value));
        return available;
    }

    onTabChange(tabIndex: number) {
        this.selectedCategoryIndex = tabIndex;
        this.selectedCategory = this.availableCategories[this.selectedCategoryIndex];
    }

    cleanUpAndReload() {
        this.datatables.forEach(dt => {
            if (this.availableCategories.indexOf(PricesheetCategory[dt.category]) === this.selectedCategoryIndex) {
                dt.reloadTable();
            }
        });
        super.cleanUpAndReload();
    }

    getNewItem(): Pricesheet {
        return new Pricesheet();
    }

    submit(): void {
        this.validationErrors = PricesheetValidator.validate(this.item, this.file);
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            this.itemService.savePricesheet(this.item, this.file).pipe(
                tap(itemId => {
                    if (this.newItem || this.copyMode) {
                        const category = this.categoriesByType.get(this.item.type);
                        this.selectedCategoryIndex = this.availableCategories.indexOf(PricesheetCategory[category]);
                        const selectedItem = this.getNewItem();
                        selectedItem.id = itemId;
                        this.selectedItemByCategory.set(category, selectedItem);
                    }
                }))
                .subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    setSaveInProgress(saveInProgress: boolean) {
        if (saveInProgress) {
            this.blockUiController.block(this.viewName + 'Submit');
        } else {
            this.blockUiController.unblock(this.viewName + 'Submit');
        }
        super.setSaveInProgress(this.saveInProgress);
    }

    onRowSelect(event) {
        this.itemId = event.data.id;
        this.item = event.data;
        this.itemService.getFileForItem(this.itemId).subscribe({
            next: file => {
                this.file = new File([file], this.item.fileName, {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                this.validationErrors = {};
                this.newItem = false;
                this.itemId = event.data.id;
                this.item = Pricesheet.fromJSON(event.data);
                this.item.validFrom = event.data.validFrom;
                this.selectedItem = event.data;
                this.setDisplayDialog(true);
                setTimeout(() => {
                    this.afterDialogOpen();
                    this.changeDetector.markForCheck();
                });
            },
            error: error => {
                this.setErrors(error);
            }
        });
        this.keepSelectedItemIndex(event);
    }

    handleSelectedItemChange(category: string, selectedItem: Pricesheet): void {
        this.selectedItemByCategory.set(category, selectedItem);
    }
}
