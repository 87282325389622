<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.PRICE-LISTS.PRICE_TABLES_CONFIGS.MENU' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <select class="price-list-select" [ngModel]="selectedPricelist"
                        id="selectedPricelist" (ngModelChange)="pricelistChanged($event)">
                    <option *ngFor="let pricelist of availablePricelists" [ngValue]="pricelist">{{pricelist.name}}
                    </option>
                </select>

                <div class="side-actions">
                    <app-simple-button buttonId="addTable" label="{{ 'NAVIGATION.ITEMS.PRICE_TABLES.EDIT_MULTIPLE' | translate }}"
                                       icon="edit" type="main-action" [size]="40" [disabled]="selectedItems.length == 0"
                                       (onClick)="editTables()"></app-simple-button>
                    <app-simple-button buttonId="addTable" label="{{ 'NAVIGATION.ITEMS.PRICE_TABLES.ADD' | translate }}"
                                       icon="add" type="main-action" [size]="40" [hotkey]="newElementHotkey"
                                       (onClick)="addTable()"></app-simple-button>
                </div>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="material2ConfigSystems" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [(selection)]="selectedRow" (onRowDblclick)="resetSelections(); doOnRowSelect($event)"
                 [lazy]="true" [totalRecords]="totalRecords" (onLazyLoad)="loadPricetablesLazy($event)"
                 [responsive]="true" selectionMode="single"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column [style]="{'vertical-align':'middle'}">
            <ng-template pTemplate="header">
                <app-tristate-checkbox [ngModel]="allSelectedState" (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
            </ng-template>
            <ng-template let-data="rowData" pTemplate="body">
                    <span class="flex-row">
                        <app-checkbox [ngModel]="isSelectedItem(data)" (ngModelChange)="selectItem(data)"></app-checkbox>
                    </span>
            </ng-template>
        </p-column>

        <p-column field="configSystemName" header="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-m2cs="rowData" pTemplate="body">
                {{m2cs.configSystemName[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="materialName" header="{{ 'PRICE_TABLES.FORM.MATERIAL_NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-m2cs="rowData" pTemplate="body">
                {{m2cs.materialName[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="priceTableId" header="{{ 'PRICE_TABLES.FORM.TABLE' | translate }}">
            <ng-template let-material2ConfigSystem="rowData" pTemplate="body">
                <app-simple-button *ngIf="material2ConfigSystem.priceTableId && material2ConfigSystem.priceTableItemsPresent"
                                   icon="check" type="confirm" [size]="36"
                                   (onClick)="editPricetable(material2ConfigSystem)"></app-simple-button>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
    <app-window-pricingtables *ngIf="displayDialog" [priceTables]="[priceTable]"
                              [selectedPricelistIsFuture]="selectedPricelistIsFuture"
                              [tableInfo]="selectedTableInfo" (close)="closeDialog()"
                              [priceTablesToEdit]="selectedItems"
                              [target]="targets.CONFIGS" [systemsWithLinks]="systemsWithLinks" [allMaterials]="materials"
                              (submit)="submitPricingTable($event)"></app-window-pricingtables>
</div>
