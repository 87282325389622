import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {ShippingSimulationService} from "../shipping-simulation.service";
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";

@Component({
    selector: 'app-shipping-simulation-float-button',
    templateUrl: './shipping-simulation-float-button.component.html',
    styleUrls: ['./../../../../../common/float-button.component.css'],
    providers: [DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingSimulationFloatButton {

    @Output()
    onClick: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    calculationInProgress: boolean;

    @Input()
    bottomPosition = 32;

    positionCount: number = 0;
    visible: boolean = false;

    constructor(private shippingSimulationService: ShippingSimulationService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    click(): void {
        this.onClick.emit();
    }

    refresh(): void {
        this.shippingSimulationService.getDraftForCurrentUser().subscribe({
            next: () => {
                this.shippingSimulationService.getAllSimulationPositions(
                    this.shippingSimulationService.shippingSimulation.id).subscribe(() => {
                    if (this.shippingSimulationService.getOfferCount() > 0) {
                        this.positionCount = this.shippingSimulationService.getPositionCount();
                        this.visible = true;
                    } else {
                        this.visible = false;
                    }

                    this.changeDetector.markForCheck();
                });
            },
            error: (error) => this.errors.handle(error)
        });
    }
}
