import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class WindowSystemWebShopInfo {
    id: number;
    description: MultilanguageField;
    longDescription: MultilanguageField;
    priceRating: number;
    energySavingRating: number;
    sliderImageIds: number[];

    constructor() {
        this.description = new MultilanguageField();
        this.longDescription = new MultilanguageField();
        this.priceRating = 1;
        this.energySavingRating = 1;
        this.sliderImageIds = [];
    }

    static fromJSON(jsonObject: any): WindowSystemWebShopInfo {
        let windowSystemWebShopInfo: WindowSystemWebShopInfo = new WindowSystemWebShopInfo();
        windowSystemWebShopInfo.id = jsonObject.id;
        windowSystemWebShopInfo.description = MultilanguageField.fromJSON(jsonObject.description);
        windowSystemWebShopInfo.longDescription = MultilanguageField.fromJSON(jsonObject.longDescription);
        windowSystemWebShopInfo.priceRating = jsonObject.priceRating;
        windowSystemWebShopInfo.energySavingRating = jsonObject.energySavingRating;
        if (jsonObject.sliderImageIds != undefined) {
            windowSystemWebShopInfo.sliderImageIds = [...jsonObject.sliderImageIds];
        }
        return windowSystemWebShopInfo;
    }
}
