import {SubwindowSide} from "./enums/subwindow-side";
import {OpeningDirection} from "./window-types/opening-direction";

export class CompositionDistances {
    defaultWidth: number;
    topWidth: number;
    bottomWidth: number;
    hingedSideWidth: number;
    hingelessSideWidth: number;

    constructor(defaultWidth?: number) {
        this.defaultWidth = defaultWidth;
    }

    static fromJSON(jsonObject: any): CompositionDistances {
        const compositionDistances = new CompositionDistances();
        compositionDistances.defaultWidth = jsonObject.defaultWidth;
        compositionDistances.topWidth = jsonObject.topWidth;
        compositionDistances.bottomWidth = jsonObject.bottomWidth;
        compositionDistances.hingedSideWidth = jsonObject.hingedSideWidth;
        compositionDistances.hingelessSideWidth = jsonObject.hingelessSideWidth;
        return compositionDistances;
    }
}

export class CompositionDistancesUtils {
    public static get(compositionDistances: CompositionDistances, side?: SubwindowSide, opening?: OpeningDirection) {
        let value;
        if (compositionDistances != undefined) {
            if (side != undefined) {
                switch (side) {
                    case SubwindowSide.LEFT:
                        if (opening === OpeningDirection.L) {
                            value = compositionDistances.hingedSideWidth;
                        } else if (opening === OpeningDirection.P) {
                            value = compositionDistances.hingelessSideWidth;
                        }
                        break;
                    case SubwindowSide.RIGHT:
                        if (opening === OpeningDirection.L) {
                            value = compositionDistances.hingelessSideWidth;
                        } else if (opening === OpeningDirection.P) {
                            value = compositionDistances.hingedSideWidth;
                        }
                        break;
                    case SubwindowSide.TOP:
                        value = compositionDistances.topWidth;
                        break;
                    case SubwindowSide.BOTTOM:
                        value = compositionDistances.bottomWidth;
                        break;
                }
            }
            if (value == undefined) {
                value = compositionDistances.defaultWidth;
            }
        }
        return value;
    }
}
