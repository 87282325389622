<p-dialog #dialog [resizable]="false" [(visible)]="visible" (onHide)="onClose.emit()"
          class="dialog-window" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">{{ 'OFFER.TABS.SECTION.MAIN.GATE_PANEL_TYPE' | translate }}</div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600">
        <ng-container *ngFor="let option of options | async">
            <div [ngClass]="{'layout-option': true, 'layout-option-selected': selectedGatePanelTypeId === option.value, 'option-unavailable': isOptionUnavailable(option)}"
                 (click)="handleOnClick(option)">
                <div>{{ option.label | translate }}</div>
                <img *ngIf="imageService.getGatePanelTypeDialogImage(option.value) | async as image" [src]="image"
                     (load)="centerDialog()" />
                <div *ngIf="option.title != null" class="desc" [innerText]="option.title | translate"></div>
            </div>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" label="{{'GENERAL.OK' | translate }}" type="main-action"
                               [size]="40" (onClick)="submit($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
