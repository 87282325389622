<div *ngIf="!newSystem" class="list-content">
    <div class="table-header">
        <div class="title">{{ (roofSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF_SYSTEMS.LIST' :
            entranceSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_SYSTEMS.LIST' :
            'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_SYSTEMS.LIST') | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ (roofSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF-SYSTEM-DEFINITION.COPY' :
                                   entranceSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE-SYSTEM-DEFINITION.COPY' :
                                   'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW-SYSTEM-DEFINITION.COPY') | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedSystem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button *ngIf="incomingSupplierId != undefined"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}" icon="filter_list" type="action"
                                   (onClick)="removeFiltersNotSetOnTableColumns()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ (roofSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ROOF-SYSTEM-DEFINITION.ADD' :
                                   entranceSystem ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE-SYSTEM-DEFINITION.ADD' :
                                   'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW-SYSTEM-DEFINITION.ADD') | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="windowSystemDefinitions" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 [responsive]="true" (onLazyLoad)="loadItemsLazy($event)"
                 (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedSystem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="systemsTable">

        <p-column field="name" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-windowSysDef="rowData" pTemplate="body">
                {{windowSysDef.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="!roofSystem && !entranceSystem" field="sortIndex" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}" [sortable]="true"></p-column>
        <p-column *ngIf="roofSystem" field="roofSystemSortIndex" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}" [sortable]="true"></p-column>
        <p-column *ngIf="entranceSystem" field="entranceSystemSortIndex" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SORT_NUMBER' | translate }}" [sortable]="true"></p-column>
        <p-column *ngIf="!roofSystem && !entranceSystem" field="systemType" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_TYPE' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="filterType | async">
            <ng-template let-windowSysDef="rowData" pTemplate="body">
                {{ windowSysDef.systemType ? (('WINDOW-SYSTEM-DEFINITION.SYSTEM_TYPE.'+windowSysDef.systemType) |
                translate) : windowSysDef.systemType }}
            </ng-template>
        </p-column>
        <p-column field="shortcut" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SHORTCUT' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-windowSysDef="rowData" pTemplate="body">
                {{windowSysDef.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="symbol" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYMBOL' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <p-column field="supplier.companyName" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SUPPLIER' | translate }}"
                  [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>

        <p-column field="pcn" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.PCN' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <p-column field="material" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MATERIAL' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="filterMaterial | async">
            <ng-template let-windowSysDef="rowData" pTemplate="body">
                {{ 'MATERIAL.' + windowSysDef.material | translate }}
            </ng-template>
        </p-column>
        <p-column field="active" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.STATUS' | translate }}" [sortable]="false"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-windowSysDef="rowData" pTemplate="body">
                {{(windowSysDef.active ? 'WINDOW-SYSTEM-DEFINITION.FORM.ACTIVE' :
                'WINDOW-SYSTEM-DEFINITION.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newSystem" header="{{ (roofSystem ? 'WINDOW-SYSTEM-DEFINITION.ADD-ROOF-SYSTEM-DEFINITION' :
        entranceSystem ? 'WINDOW-SYSTEM-DEFINITION.ADD-ENTRANCE-SYSTEM-DEFINITION' : 'WINDOW-SYSTEM-DEFINITION.ADD-WINDOW-SYSTEM-DEFINITION') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'WINDOW-SYSTEM-DEFINITION.GENERAL_TAB_NAME' | translate }}" [id]="STEPS.DATA"
                     [validate]="VALIDATORS.DATA">
        <app-window-system-definition-general-form [editPermits]="editPermits"
                                                   [roofSystem]="roofSystem"
                                                   [entranceSystem]="entranceSystem"
                                                   [windowSystem]="windowSystem"
                                                   [validationErrors]="validationErrors"
                                                   [suppliers]="existingSuppliers"
                                                   [windowEditorImage]="windowEditorImage"
                                                   (windowEditorImageChange)="onWindowEditorImageChange($event)"
                                                   [printoutImage]="printoutImage"
                                                   [roofGlazingPackages]="roofGlazingPackages"
                                                   [tags]="tags"
                                                   (printoutImageChange)="onPrintoutImageChange($event)"></app-window-system-definition-general-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.ANGLES)" [id]="STEPS.ANGLES" label="{{ 'WINDOW-SYSTEM-DEFINITION.ANGLES_TAB_NAME' | translate }}"
                     [validate]="VALIDATORS.ANGLES">
        <app-window-system-definition-angles-form [disabled]="fieldUsage.disabled(WindowSystemTab.ANGLES)"
                                                  [windowSystem]="windowSystem"
                                                  [glazingPackages]="glazingPackages"
                                                  [decorativeGlazingPackages]="decorativeGlazingPackages"
                                                  [validationErrors]="validationErrors"></app-window-system-definition-angles-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.ASSEMBLY)" [id]="STEPS.ASSEMBLY" label="{{ 'WINDOW-SYSTEM-DEFINITION.ASSEMBLY_TAB_NAME' | translate }}"
                     [validate]="VALIDATORS.ASSEMBLY">
        <app-window-system-definition-imposition-form [disabled]="fieldUsage.disabled(WindowSystemTab.ASSEMBLY)"
                                                      [windowSystem]="windowSystem"
                                                      [validationErrors]="validationErrors"
                                                      [windowSystems]="activeWindowSystemsForStandaloneGlazingPackages"></app-window-system-definition-imposition-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DRAWING_TOOLS)" label="{{ 'WINDOW-SYSTEM-DEFINITION.DRAWING_TOOLS_TAB_NAME' | translate }}"
                     [id]="STEPS.DRAWING_TOOLS">
        <app-window-system-definition-drawing-tools-form [disabled]="fieldUsage.disabled(WindowSystemTab.DRAWING_TOOLS)"
                                                         [windowSystemDrawingToolsEnabler]="windowSystem.windowSystemDrawingToolsEnabler">
        </app-window-system-definition-drawing-tools-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DESIGNER_DESCRIPTION)" [id]="STEPS.DESIGNER_DESCRIPTION" label="{{ 'WINDOW-SYSTEM-DEFINITION.DESIGNER_DESCRIPTION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step [disabled]="fieldUsage.disabled(WindowSystemTab.DESIGNER_DESCRIPTION)"
                                               [catalogConfiguration]="catalogConfigurations[CatalogPropertyTarget.WINDOW_SYSTEMS]"
                                               [value]="windowSystem.designerDescription"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.SPECIFICATION)" [id]="STEPS.SPECIFICATION" label="{{ 'WINDOW-SYSTEM-DEFINITION.SPECIFICATION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step [disabled]="fieldUsage.disabled(WindowSystemTab.SPECIFICATION)"
                                               [catalogConfiguration]="catalogConfigurations[CatalogPropertyTarget.WEBSHOP_SYSTEMS_SPECIFICATION]"
                                               [value]="windowSystem.specification"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DESCRIPTION)" [id]="STEPS.DESCRIPTION" label="{{ 'WINDOW-SYSTEM-DEFINITION.DESCRIPTION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step
                [disabled]="fieldUsage.disabled(WindowSystemTab.DESCRIPTION)"
                [catalogConfiguration]="catalogConfigurations[roofSystem ? CatalogPropertyTarget.ROOF_SYSTEMS : entranceSystem ?
                 CatalogPropertyTarget.ENTRANCE_SYSTEMS : CatalogPropertyTarget.WEBSHOP_SYSTEMS_DESCRIPTION]"
                [value]="windowSystem.description"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DIMENSION_PRICE)" label="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.TAB_NAME' | translate }}"
                     [id]="STEPS.DIMENSION_PRICE">
        <div class="new-form-60vw">
            <app-window-system-definition-dimension-price [disabled]="fieldUsage.disabled(WindowSystemTab.DIMENSION_PRICE)"
                                                          [windowSystemId]="windowSystem.id"></app-window-system-definition-dimension-price>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.GLAMOUR_PRINT_INFO)" label="{{ 'WINDOW-SYSTEM-DEFINITION.GLAMOUR_PRINT_INFO.TAB_NAME' | translate }}"
                     [id]="STEPS.GLAMOUR_PRINT_INFO" [validate]="VALIDATORS.GLAMOUR_PRINT_INFO">
        <app-window-system-definition-glamour-print-info-form [disabled]="fieldUsage.disabled(WindowSystemTab.GLAMOUR_PRINT_INFO)"
                                                              [roofSystem]="roofSystem"
                                                              [windowSystem]="windowSystem"
                                                              [validationErrors]="validationErrors"
                                                              [glamourPrintImageFile]="glamourPrintImageFile.file"
                                                              (glamourPrintImageFileChange)="handleGlamourPrintImageFileChange($event)"
                                                              [glamourPrintIconFile]="glamourPrintIconFile"
                                                              (glamourPrintIconFileChange)="handleGlamourPrintIconFileChange($event.language, $event.file)"></app-window-system-definition-glamour-print-info-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="displayDialog && !newSystem" #editWizard
        header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW-SYSTEM-DEFINITION.COPY' : 'WINDOW-SYSTEM-DEFINITION.EDIT-WINDOW-SYSTEM-DEFINITION') | translate }}"
        (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'WINDOW-SYSTEM-DEFINITION.GENERAL_TAB_NAME' | translate }}" [id]="STEPS.DATA"
                     [validate]="VALIDATORS.DATA">
        <app-window-system-definition-general-form [editPermits]="editPermits"
                                                   [roofSystem]="roofSystem"
                                                   [entranceSystem]="entranceSystem"
                                                   [windowSystem]="windowSystem"
                                                   [validationErrors]="validationErrors"
                                                   [suppliers]="existingSuppliers"
                                                   [windowEditorImage]="windowEditorImage"
                                                   (windowEditorImageChange)="onWindowEditorImageChange($event)"
                                                   [printoutImage]="printoutImage"
                                                   [roofGlazingPackages]="roofGlazingPackages"
                                                   [tags]="tags"
                                                   (printoutImageChange)="onPrintoutImageChange($event)"></app-window-system-definition-general-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.ANGLES)" [id]="STEPS.ANGLES" label="{{ 'WINDOW-SYSTEM-DEFINITION.ANGLES_TAB_NAME' | translate }}"
                     [validate]="VALIDATORS.ANGLES">
        <app-window-system-definition-angles-form [disabled]="fieldUsage.disabled(WindowSystemTab.ANGLES)"
                                                  [windowSystem]="windowSystem"
                                                  [glazingPackages]="glazingPackages"
                                                  [decorativeGlazingPackages]="decorativeGlazingPackages"
                                                  [validationErrors]="validationErrors"></app-window-system-definition-angles-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.ASSEMBLY)" [id]="STEPS.ASSEMBLY" label="{{ 'WINDOW-SYSTEM-DEFINITION.ASSEMBLY_TAB_NAME' | translate }}"
                     [validate]="VALIDATORS.ASSEMBLY">
        <app-window-system-definition-imposition-form [disabled]="fieldUsage.disabled(WindowSystemTab.ASSEMBLY)"
                                                      [windowSystem]="windowSystem"
                                                      [validationErrors]="validationErrors"
                                                      [windowSystems]="activeWindowSystemsForStandaloneGlazingPackages"></app-window-system-definition-imposition-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.SHAPES)" label="{{ 'WINDOW-SYSTEM-DEFINITION.SHAPES.TAB_NAME' | translate }}"
                     [id]="STEPS.SHAPES" [validate]="VALIDATORS.SHAPES">
        <app-window-system-definition-shape-form [disabled]="fieldUsage.disabled(WindowSystemTab.SHAPES)"
                                                 [windowSystem]="windowSystem"
                                                 [validationErrors]="validationErrors"
                                                 [availableSubwindowTypes]="availableSubwindowTypes"
                                                 [subwindowTypes]="subwindowTypes"
                                                 [userLang]="userLang"></app-window-system-definition-shape-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DRAWING_TOOLS)" label="{{ 'WINDOW-SYSTEM-DEFINITION.DRAWING_TOOLS_TAB_NAME' | translate }}"
                     [id]="STEPS.DRAWING_TOOLS">
        <app-window-system-definition-drawing-tools-form [disabled]="fieldUsage.disabled(WindowSystemTab.DRAWING_TOOLS)"
                [windowSystemDrawingToolsEnabler]="windowSystem.windowSystemDrawingToolsEnabler">
        </app-window-system-definition-drawing-tools-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.WEB_SHOP)" label="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.TAB_NAME' | translate }}"
                     [id]="STEPS.WEB_SHOP" [validate]="VALIDATORS.WEB_SHOP">
        <!-- *ngIf on content is a hack for p-editor removing all newlines on load -->
        <app-window-system-definition-web-shop-form *ngIf="editWizard.getCurrentStepId() === STEPS.WEB_SHOP"
                                                    [disabled]="fieldUsage.disabled(WindowSystemTab.WEB_SHOP)"
                                                    [windowSystemWebShopInfo]="windowSystemWebShopInfo"
                                                    [webshopImageFile]="webshopImageFile"
                                                    (webshopImageFileChange)="handleWebshopImageFileChange($event)"
                                                    [webshopLargeImageFile]="webshopLargeImageFile"
                                                    (webshopLargeImageFileChange)="handleWebshopLargeImageFileChange($event)"
                                                    [webshopLargeImageMobileFile]="webshopLargeImageMobileFile"
                                                    (webshopLargeImageMobileFileChange)="handleWebshopLargeImageMobileFileChange($event)"
                                                    [webshopSliderImageFiles]="webshopSliderImageFiles"
                                                    (webshopSliderImageFileAdded)="handleWebshopSliderImageFileAdded()"
                                                    (webshopSliderImageFileChanged)="handleWebshopSliderImageFileChanged($event.id, $event.file)"
                                                    [validationErrors]="windowSystemWebShopInfoValidationErrors"></app-window-system-definition-web-shop-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DESIGNER_DESCRIPTION)" [id]="STEPS.DESIGNER_DESCRIPTION" label="{{ 'WINDOW-SYSTEM-DEFINITION.DESIGNER_DESCRIPTION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step [disabled]="fieldUsage.disabled(WindowSystemTab.DESIGNER_DESCRIPTION)"
                                               [catalogConfiguration]="catalogConfigurations[CatalogPropertyTarget.WINDOW_SYSTEMS]"
                                               [value]="windowSystem.designerDescription"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.SPECIFICATION)" [id]="STEPS.SPECIFICATION" label="{{ 'WINDOW-SYSTEM-DEFINITION.SPECIFICATION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step [disabled]="fieldUsage.disabled(WindowSystemTab.SPECIFICATION)"
                                               [catalogConfiguration]="catalogConfigurations[CatalogPropertyTarget.WEBSHOP_SYSTEMS_SPECIFICATION]"
                                               [value]="windowSystem.specification"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DESCRIPTION)" [id]="STEPS.DESCRIPTION" label="{{ 'WINDOW-SYSTEM-DEFINITION.DESCRIPTION.TAB_NAME' | translate }}">
        <app-catalog-configuration-wizard-step
                [disabled]="fieldUsage.disabled(WindowSystemTab.DESCRIPTION)"
                [catalogConfiguration]="catalogConfigurations[roofSystem ? CatalogPropertyTarget.ROOF_SYSTEMS : entranceSystem ?
                 CatalogPropertyTarget.ENTRANCE_SYSTEMS : CatalogPropertyTarget.WEBSHOP_SYSTEMS_DESCRIPTION]"
                [value]="windowSystem.description"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.DIMENSION_PRICE)" label="{{ 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.TAB_NAME' | translate }}"
                     [id]="STEPS.DIMENSION_PRICE">
        <app-window-system-definition-dimension-price [disabled]="fieldUsage.disabled(WindowSystemTab.DIMENSION_PRICE)"
                                                      [windowSystemId]="copyMode ? selectedSystem.id : windowSystem.id"></app-window-system-definition-dimension-price>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(WindowSystemTab.GLAMOUR_PRINT_INFO)" label="{{ 'WINDOW-SYSTEM-DEFINITION.GLAMOUR_PRINT_INFO.TAB_NAME' | translate }}"
                     [id]="STEPS.GLAMOUR_PRINT_INFO" [validate]="VALIDATORS.GLAMOUR_PRINT_INFO">
        <app-window-system-definition-glamour-print-info-form [disabled]="fieldUsage.disabled(WindowSystemTab.GLAMOUR_PRINT_INFO)" [roofSystem]="roofSystem"
                                                              [windowSystem]="windowSystem"
                                                              [validationErrors]="validationErrors"
                                                              [glamourPrintImageFile]="glamourPrintImageFile.file"
                                                              (glamourPrintImageFileChange)="handleGlamourPrintImageFileChange($event)"
                                                              [glamourPrintIconFile]="glamourPrintIconFile"
                                                              (glamourPrintIconFileChange)="handleGlamourPrintIconFileChange($event.language, $event.file)"></app-window-system-definition-glamour-print-info-form>
    </app-wizard-step>
</app-wizard-dialog>
