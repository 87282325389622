export enum DetailedPricingSource {
    MANUAL_CLIENT_MARGIN = 'MANUAL_CLIENT_MARGIN',
    MANUAL_RETAIL_MARGIN = 'MANUAL_RETAIL_MARGIN',
    CLIENT_MARGIN = 'CLIENT_MARGIN',
    CLIENT_GROUP_MARGIN = 'CLIENT_GROUP_MARGIN',
    RETAIL_CLIENT_MARGIN = 'RETAIL_CLIENT_MARGIN',
    RETAIL_CLIENT_GROUP_MARGIN = 'RETAIL_CLIENT_GROUP_MARGIN',
    SUBSYSTEM_MARGIN = 'SUBSYSTEM_MARGIN',
    SUBSYSTEM_GROUP_MARGIN = 'SUBSYSTEM_GROUP_MARGIN',
    SYSTEM_VENSKA_RABATE = 'SYSTEM_VENSKA_RABATE',
    SUPPLIER_VENSKA_RABATE = 'SUPPLIER_VENSKA_RABATE',
    SYSTEM_SUBSYSTEM_RABATE = 'SYSTEM_SUBSYSTEM_RABATE',
    SUPPLIER_SUBSYSTEM_RABATE = 'SUPPLIER_SUBSYSTEM_RABATE',
    SYSTEM_SUBSYSTEM_GROUP_RABATE = 'SYSTEM_SUBSYSTEM_GROUP_RABATE',
    SUPPLIER_SUBSYSTEM_GROUP_RABATE = 'SUPPLIER_SUBSYSTEM_GROUP_RABATE',
    MANUAL_RABATE = 'MANUAL_RABATE',
    MANUAL_RETAIL_RABATE = 'MANUAL_RETAIL_RABATE',
    MANUAL_VENSKA_RABATE= 'MANUAL_VENSKA_RABATE',
    GLOBAL_OFFER_RABATE = 'GLOBAL_OFFER_RABATE',
    GLOBAL_OFFER_RETAIL_RABATE = 'GLOBAL_OFFER_RETAIL_RABATE',
    GLOBAL_OFFER_VENSKA_RABATE = 'GLOBAL_OFFER_VENSKA_RABATE',
    NONE_VENSKA = 'NONE_VENSKA',
    NONE_SUBSYSTEM = 'NONE_SUBSYSTEM',
    NONE_CLIENT = 'NONE_CLIENT',
    NONE_RETAIL = 'NONE_RETAIL'
}
