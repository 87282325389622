import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {FileState} from "../../../common/dataServiceHelper";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {ImagesValidator} from "../../../form-inputs/inputs/file-upload/images-validator";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GateWallField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ListOfIds} from "../../ListOfIds";
import {GateWallFieldUsage} from "../catalog-field-usage";
import {GateSystemService} from "../gate-system/gate-system.service";
import {LinkableEntities} from '../link-selection/link-selection.component';
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {GateWall} from "./gate-wall";
import {GateWallService} from "./gate-wall.service";

@Component({
    selector: 'app-gate-wall',
    templateUrl: './gate-wall.component.html',
    providers: [GateWallService, TranslatedSelectItemService, GateSystemService]
})
export class GateWallComponent extends CrudCommonComponent<GateWall, GateWallService> implements OnInit {

    readonly LinkableEntity = LinkableEntities;

    readonly STEPS = {
        DATA: 'DATA',
        GATE_SYSTEMS: 'GATE_SYSTEMS'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    selectedGateSystems: number[];
    gateSystems: ItemForCatalogLinking[];

    lowResolutionPreviewImage: FileState = {file: undefined, needSave: false};
    highResolutionPreviewImage: FileState = {file: undefined, needSave: false};

    editPermits: FieldLimitation[] = [];
    fieldUsage: GateWallFieldUsage;
    CatalogField = CatalogTab;
    GateWallField = GateWallField;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private gateWallService: GateWallService,
        private gateSystemService: GateSystemService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, GateWallService, 'GATE_WALL', 'GateWall');
        this.validateDataStep = () => this.validateData();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fieldUsage = new GateWallFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gateSystemService.getGatesForCatalogLinking().subscribe(systems => {
            this.gateSystems = systems;
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GATE_WALLS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): GateWall {
        return new GateWall();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.prepareDataForGateWall(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    validateData(): Observable<boolean> {
        const validationErrors = {
            [`name[${this.translate.currentLang}]`]: MultiValidator.of(`error.gateWallDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]),
            [`shortcut[${this.translate.currentLang}]`]: MultiValidator.of(`error.gateWallDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]),
            symbol: MultiValidator.of('error.gateWallDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol),
            sortIndex: MultiValidator.of('error.gateWallDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(this.item.sortIndex)
        };

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return forkJoin({
            lowResolutionPreviewImage: this.lowResolutionPreviewImage.needSave
                ? ImagesValidator.validationErrors(this.lowResolutionPreviewImage.file, 150000, 400, 400)
                : of(undefined as string),
            highResolutionPreviewImage: this.highResolutionPreviewImage.needSave
                ? ImagesValidator.validationErrors(this.highResolutionPreviewImage.file, 5242880, 1500, 1000)
                : of(undefined as string),
        }).pipe(
            mergeMap(imageErrors => {
                Object.assign(this.validationErrors, imageErrors);
                if (this.validationErrorsPresent(this.validationErrors)) {
                    return of(this.validationErrors);
                }
                return this.itemService.validate(this.item);
            }),
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors))
        );
    }

    submit() {
        if (this.validationErrorsPresent()) {
            return;
        }
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        let observable: Observable<number>;
        if (this.copyMode) {
            observable = this.itemService.copy(this.selectedItem.id, this.item,
                this.lowResolutionPreviewImage, this.highResolutionPreviewImage);
        } else {
            observable = this.itemService.saveGateWall(this.item, this.lowResolutionPreviewImage,
                this.highResolutionPreviewImage);
        }
        observable = observable.pipe(mergeMap(gateId => {
            const ids = new ListOfIds();
            ids.ids = this.selectedGateSystems;
            return this.itemService.editLinks(gateId, ids);
        }));
        observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    prepareDataForGateWall(gateId: number) {
        forkJoin({
            wall: (gateId != undefined ? this.gateWallService.getItem(gateId) : of(new GateWall())),
            linkedGates: (gateId !== null ? this.gateWallService.getLinkedModels(gateId) : of<number[]>([])),
            lowResolutionPreviewImage: (gateId !== null ? this.gateWallService.getLowResolutionPreviewImageAsFile(gateId) : of<File>(null)),
            highResolutionPreviewImage: (gateId !== null ? this.gateWallService.getHighResolutionPreviewImageAsFile(gateId) : of<File>(null))
        }).subscribe({
            next: data => {
                this.newItem = gateId == undefined;
                this.item = data.wall;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.lowResolutionPreviewImage = {file: data.lowResolutionPreviewImage, needSave: false};
                this.highResolutionPreviewImage = {file: data.highResolutionPreviewImage, needSave: false};
                this.selectedGateSystems = data.linkedGates;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.prepareDataForGateWall(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.prepareDataForGateWall(this.selectedItem.id);
        }
    }

    handleLowResolutionPreviewImageChange(newFile: File) {
        this.lowResolutionPreviewImage.file = newFile;
        this.lowResolutionPreviewImage.needSave = true;
        if (!newFile) {
            this.lowResolutionPreviewImage.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleHighResolutionPreviewImageChange(newFile: File) {
        this.highResolutionPreviewImage.file = newFile;
        this.highResolutionPreviewImage.needSave = true;
        if (!newFile) {
            this.highResolutionPreviewImage.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetFile() {
        this.lowResolutionPreviewImage = {file: undefined, needSave: false};
        this.highResolutionPreviewImage = {file: undefined, needSave: false};
        this.selectedGateSystems = [];
    }
}
