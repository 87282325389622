import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from "../../../common/validation-errors";
import {GraspDistanceFrameCategoryField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {GraspDistanceFrameCategoryFieldUsage} from "../catalog-field-usage";
import {GraspDistanceFrameCategory} from "./grasp-distance-frame-category";

@Component({
    selector: 'app-grasp-distance-frame-category-form',
    templateUrl: './grasp-distance-frame-category-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraspDistanceFrameCategoryFormComponent {

    @Input()
    item: GraspDistanceFrameCategory;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    editPermits: FieldLimitation[] = [];

    readonly GraspDistanceFrameCategoryField = GraspDistanceFrameCategoryField;
    readonly fieldUsage: GraspDistanceFrameCategoryFieldUsage;

    constructor() {
        this.fieldUsage = new GraspDistanceFrameCategoryFieldUsage(this);
    }
}
