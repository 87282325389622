<div class="new-form-600">
    <div class="new-form-row">
        <app-select [inputId]="targetIdField" [label]="targetLabel"
                    [(validationMessageKey)]="validationErrors[targetIdField]" [options]="targetOptions" [translateLabels]="true"
                    [ngModel]="dealerDiscount[targetIdField]" (ngModelChange)="onTargetChange($event)" [required]="true"
                    [disabled]="!canEdit"></app-select>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'DEALER_DISCOUNTS.FORM.VALID_FROM' | translate }}"
                      [(validationMessageKey)]="validationErrors['validFrom']" [inline]="true" [utc]="true"
                      [minDate]="minValidFromDate" [required]="true" [disabled]="!canEdit"
                      [(ngModel)]="dealerDiscount.validFrom"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="value" label="{{ 'DEALER_DISCOUNTS.FORM.VALUE' | translate }}"
                        [(validationMessageKey)]="validationErrors['value']" [maxlength]="250" [required]="true"
                        [disabled]="!canEdit" [(ngModel)]="dealerDiscount.discountValue"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="cascade" label="{{ 'DEALER_DISCOUNTS.FORM.CASCADE' | translate }}"
                      [(validationMessageKey)]="validationErrors['cascade']" [disabled]="!canEdit"
                      [ngModel]="dealerDiscount.cascade" (ngModelChange)="onCascadeChange($event)"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'DEALER_DISCOUNTS.FORM.ACTIVE' | translate }}"
                      [(validationMessageKey)]="validationErrors['active']" [disabled]="!canEdit"
                      [(ngModel)]="dealerDiscount.active"></app-checkbox>
    </div>
</div>
