import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {WebshopAreaBarsCode} from '../../../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration-area-bars-form',
    templateUrl: './webshop-recommended-configuration-area-bars-form.component.html',
    styleUrls: ['webshop-recommended-configuration-area-bars-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationAreaBarsFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    areaBarsOptions: SelectItem[];

    constructor(private service: WebshopRecommendedConfigurationService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            this.service.getAreaBars(this.item.productConfiguration).subscribe(data => {
                this.areaBarsOptions = [];
                for (let areaBars of data) {
                    this.areaBarsOptions.push({
                        label: areaBars.areaBarCode,
                        value: areaBars.areaBarCode
                    });
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    getIcon(webshopAreaBarsCode: WebshopAreaBarsCode): string {
        return `assets/images/area-bars-icons/area-bars-${webshopAreaBarsCode}.svg`;
    }
}
