import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {concat, forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay} from 'rxjs/operators';
import * as _ from 'underscore';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableHelper, DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {ConfigurableAddonsService} from '../../offer/offers/position/config-addons.service';
import {CatalogItemType} from '../../offer/window-editor/sidebar/pricing/PricingItem';
import {AddonsService} from '../addons/addons.service';
import {BusinessTypeService} from '../business-type/business-type.service';
import {ColorService} from '../color/color.service';
import {DecorativeFillingsService} from '../decorative-filling/decorative-filling.service';
import {DistanceFrameService} from '../distance-frame/distance-frame.service';
import {EntranceGlazingPackageService} from '../entrance-glazing-package/entrance-glazing-package.service';
import {GatePanelTypeService} from '../gate-panel-type/gate-panel-type.service';
import {GateSystemService} from "../gate-system/gate-system.service";
import {GlassService} from '../glass/glass.service';
import {GlazingBeadService} from '../glazing-bead/glazing-bead.service';
import {GlazingPackageService} from '../glazing-package/glazing-package.service';
import {WebshopGlazingPackageService} from '../glazing-package/webshop-glazing-package/webshop-glazing-package.service';
import {GrillService} from '../grill/grill.service';
import {MaterialService} from '../material/material.service';
import {OtherFillingService} from '../other-filling/other-filling.service';
import {ProfileService} from '../profile/profile.service';
import {RailSystemService} from "../rail-system/rail-system.service";
import {RoofGlazingPackageService} from '../roof-glazing-package/roof-glazing-package.service';
import {SealService} from '../seal/seal.service';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {DiscontinuedCatalogItemInfo} from './discontinued-catalog-item-info';
import {DiscontinuedCatalogItemInfoService} from './discontinued-catalog-item-info.service';
import {GateWallService} from "../gate-wall/gate-wall.service";
import {ConfigSystemService} from "../config-system/config-system.service";

interface INameService {
    getItemNames(active?: boolean): Observable<CatalogItemName[]>;
}

@Component({
    selector: 'app-discontinued-catalog-item-info',
    templateUrl: './discontinued-catalog-item-info.component.html',
    providers: [DiscontinuedCatalogItemInfoService, TranslatedSelectItemService, AddonsService, BusinessTypeService, ColorService,
        ConfigurableAddonsService, DecorativeFillingsService, DistanceFrameService, EntranceGlazingPackageService, GatePanelTypeService,
        GateSystemService, GlassService, GlazingBeadService, GlazingPackageService, GrillService, MaterialService, OtherFillingService,
        ProfileService, RailSystemService, RoofGlazingPackageService, SealService, WebshopGlazingPackageService,
        WindowSystemDefinitionService, GateWallService, ConfigSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscontinuedCatalogItemInfoComponent extends CrudCommonComponent<DiscontinuedCatalogItemInfo, DiscontinuedCatalogItemInfoService> {

    readonly STEPS = {
        DATA: 'DATA'
    };

    filterCatalogItemTypeItems: Observable<SelectItem[]>;
    filterCatalogItemTypeState: CatalogItemType;
    catalogItemTypeItems: Observable<SelectItem[]>;
    minDate = moment().startOf('day').add(1, 'days').toDate();

    @ViewChild('table')
    table: Table;

    loadedCatalogItems = new Map<CatalogItemType, Observable<CatalogItemName[]>>();

    creationSelectedCatalogItems: any[];

    private readonly addonService: AddonsService;
    private readonly businessTypeService: BusinessTypeService;
    private readonly colorService: ColorService;
    private readonly configurableAddonDefinitionService: ConfigurableAddonsService;
    private readonly configSystemService: ConfigSystemService;
    private readonly decorativeFillingService: DecorativeFillingsService;
    private readonly distanceFrameService: DistanceFrameService;
    private readonly entranceGlazingPackageService: EntranceGlazingPackageService;
    private readonly gatePanelTypeService: GatePanelTypeService;
    private readonly gateSystemService: GateSystemService;
    private readonly glassService: GlassService;
    private readonly glazingBeadService: GlazingBeadService;
    private readonly glazingPackageService: GlazingPackageService;
    private readonly grillService: GrillService;
    private readonly materialService: MaterialService;
    private readonly otherFillingService: OtherFillingService;
    private readonly profileService: ProfileService;
    private readonly railSystemService: RailSystemService;
    private readonly roofGlazingPackageService: RoofGlazingPackageService;
    private readonly sealService: SealService;
    private readonly webshopGlazingPackageService: WebshopGlazingPackageService;
    private readonly windowSystemService: WindowSystemDefinitionService;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, DiscontinuedCatalogItemInfoService, 'DISCONTINUED_CATALOG_ITEM_INFO', 'DiscontinuedCatalogItemInfo');
        this.addonService = injector.get(AddonsService);
        this.businessTypeService = injector.get(BusinessTypeService);
        this.colorService = injector.get(ColorService);
        this.configurableAddonDefinitionService = injector.get(ConfigurableAddonsService);
        this.configSystemService = injector.get(ConfigSystemService);
        this.decorativeFillingService = injector.get(DecorativeFillingsService);
        this.distanceFrameService = injector.get(DistanceFrameService);
        this.entranceGlazingPackageService = injector.get(EntranceGlazingPackageService);
        this.gatePanelTypeService = injector.get(GatePanelTypeService);
        this.gateSystemService = injector.get(GateSystemService);
        this.glassService = injector.get(GlassService);
        this.glazingBeadService = injector.get(GlazingBeadService);
        this.glazingPackageService = injector.get(GlazingPackageService);
        this.grillService = injector.get(GrillService);
        this.materialService = injector.get(MaterialService);
        this.otherFillingService = injector.get(OtherFillingService);
        this.profileService = injector.get(ProfileService);
        this.railSystemService = injector.get(RailSystemService);
        this.roofGlazingPackageService = injector.get(RoofGlazingPackageService);
        this.sealService = injector.get(SealService);
        this.webshopGlazingPackageService = injector.get(WebshopGlazingPackageService);
        this.windowSystemService = injector.get(WindowSystemDefinitionService);

        this.filterCatalogItemTypeItems = this.translatedSelectItemService
            .buildSortedDropdown(this.getSupportedCatalogItemTypes(), 'CATALOG_ITEM_TYPE.', '');
        this.catalogItemTypeItems = this.filterCatalogItemTypeItems.pipe(map(items => items.filter(item => item.value !== '')));
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): DiscontinuedCatalogItemInfo {
        return new DiscontinuedCatalogItemInfo();
    }

    loadEditedItem(event: { data: DiscontinuedCatalogItemInfo }): Observable<DiscontinuedCatalogItemInfo> {
        return this.itemService.getItem(event.data.id).pipe(
            mergeMap(item => {
                return forkJoin({
                    item: of(item),
                    catalogItems: this.getCatalogItems(item) // load catalog options now
                });
            }),
            map(result => result.item));
    }

    add() {
        if (!this.validateForm()) {
            return;
        }

        if (this.isSaveInProgress()) {
            return;
        } else {
            this.setSaveInProgress(true);
        }

        concat(...this.creationSelectedCatalogItems.map(x => {
            let requestItem = _.clone(this.prepareItemForRequest());
            requestItem.catalogItemId = x;
            return this.itemService.addItem(requestItem);
        })).subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    handleFilterCatalogItemTypeChange(catalogItemType: CatalogItemType): void {
        if (this.filterCatalogItemTypeState === catalogItemType) {
            return;
        }
        this.filterCatalogItemTypeState = catalogItemType;
        this.table.filter(catalogItemType, 'catalogItemType', 'equals');
    }

    getCatalogItems(item: DiscontinuedCatalogItemInfo): Observable<CatalogItemName[]> {
        let catalogItemType = item.catalogItemType;
        let source = this.minDate > item.unavailableFrom ?
            of<CatalogItemName[]>([{id: item.id, name: item.catalogItemName}]) :
            this.loadedCatalogItems.get(catalogItemType);
        if (source == undefined) {
            const serviceObservable = catalogItemType != undefined
                ? this.getItemNameService(catalogItemType).getItemNames(true)
                : of<CatalogItemName[]>([]);
            source = serviceObservable.pipe(shareReplay(1));
            this.loadedCatalogItems.set(catalogItemType, source);
        }
        return source;
    }

    private getItemNameService(catalogItemType: CatalogItemType): INameService {
        switch (catalogItemType) {
            case CatalogItemType.ADDON:
                return this.addonService;
            case CatalogItemType.BUSINESS_TYPE:
                return this.businessTypeService;
            case CatalogItemType.COLOR:
                return this.colorService;
            case CatalogItemType.CONFIGURABLE_ADDON:
                return this.configurableAddonDefinitionService;
            case CatalogItemType.CONFIG_SYSTEM:
                return this.configSystemService;
            case CatalogItemType.DECORATIVE_FILLING:
                return this.decorativeFillingService;
            case CatalogItemType.DISTANCE_FRAME:
                return this.distanceFrameService;
            case CatalogItemType.ENTRANCE_GLAZING_PACKAGE:
                return this.entranceGlazingPackageService;
            case CatalogItemType.GATE_PANEL_TYPE:
                return this.gatePanelTypeService;
            case CatalogItemType.GATE_SYSTEM:
                return this.gateSystemService;
            case CatalogItemType.GLASS:
                return this.glassService;
            case CatalogItemType.GLAZING_BEAD:
                return this.glazingBeadService;
            case CatalogItemType.GLAZING_PACKAGE:
                return this.glazingPackageService;
            case CatalogItemType.GRILL:
                return this.grillService;
            case CatalogItemType.MATERIAL:
                return this.materialService;
            case CatalogItemType.OTHER_FILLING:
                return this.otherFillingService;
            case CatalogItemType.PROFILE:
                return this.profileService;
            case CatalogItemType.RAIL_SYSTEM:
                return this.railSystemService;
            case CatalogItemType.ROOF_GLAZING_PACKAGE:
                return this.roofGlazingPackageService;
            case CatalogItemType.SEAL:
                return this.sealService;
            case CatalogItemType.WEBSHOP_GLAZING_PACKAGE:
                return this.webshopGlazingPackageService;
            case CatalogItemType.WINDOW_SYSTEM:
                return this.windowSystemService;
            default:
                break;
        }
        return undefined;
    }

    private getSupportedCatalogItemTypes(): CatalogItemType[] {
        return Object.keys(CatalogItemType)
            .map(catalogItemType => CatalogItemType[catalogItemType] as CatalogItemType)
            .filter(catalogItemType => this.getItemNameService(catalogItemType) != undefined);
    }

    validateDataStep = (): boolean => {
        this.validationErrors = Object.assign({}, this.validationErrors, {
            catalogItemType: MultiValidator.of('error.discontinuedCatalogItemInfoDto.catalogItemType')
                .withNotNullValidator()
                .validate(this.item.catalogItemType),
            catalogItemId: MultiValidator.of('error.discontinuedCatalogItemInfoDto.catalogItemId')
                .withNotNullValidator()
                .withSizeValidator(1)
                .validate(this.newItem ? this.creationSelectedCatalogItems : this.item.catalogItemId),
            unavailableFrom: MultiValidator.of('error.discontinuedCatalogItemInfoDto.unavailableFrom')
                .withNotNullValidator()
                .withDateRangeValidator(moment().startOf('day').toDate(), undefined)
                .validate(this.item.unavailableFrom),
            nameModificationDate: MultiValidator.of('error.discontinuedCatalogItemInfoDto.nameModificationDate')
                .withNotNullValidator()
                .withDateRangeValidator(moment().startOf('day').toDate(), undefined)
                .validate(this.item.nameModificationDate)
        });
        return !this.validationErrorsPresent();
    }

    protected validateForm(): boolean {
        return this.validateDataStep();
    }

    deleteDiscontinuedItem(item: DiscontinuedCatalogItemInfo): void {
        this.itemService.deleteItem(item.id).subscribe(() => {
            DatatableHelper.reload(this.table);
            this.growlMessageController.info(`${this.translationKey}.${this.translationKey}_DELETED`);
        });
    }
}
