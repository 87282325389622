import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {EMPTY, forkJoin, from, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {GateControl} from "../../../../gate-painter/enums/gate-control.enum";
import {GateImagePaintParams} from "../../../../gate-painter/gate-image-paint-params";
import {GatePanelStampingType} from '../../../../gate-painter/gate-panel-stamping-type';
import {AddonInterface} from "../../../../window-designer/catalog-data/addon-interface";
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {PositionListAddon} from "../../../../window-designer/catalog-data/position-list-addon";
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {SizeUnitsConverter} from "../../../../window-designer/utils/SizeUnitsConverter";
import {WindowAddonUtils} from "../../../../window-designer/utils/WindowAddonUtils";
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {ColorType} from '../../../ColorType';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {SystemType} from "../../../common/enums/SystemType";
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {MissingProfitMarginHandlerService} from '../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SidenavController} from '../../../sidenav-controller';
import {MultilanguageField, SupportedLanguages} from '../../../supportedLanguages';
import {CatalogConfiguration} from '../../catalog-creator/catalog-configuration';
import {CatalogConfigurationService} from "../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../catalog-creator/CatalogPropertyTarget";
import {GateSystemDefaults} from '../../settings/gate-system-defaults/gate-system-defaults';
import {GateSystemDefaultsState} from "../../settings/system-defaults/system-default-state";
import {AddonCategoryGroup} from "../../window-system/addon-category-group/addon-category-group";
import {AddonCategoryGroupService} from "../../window-system/addon-category-group/addon-category-group.service";
import {AddonsService} from "../../window-system/addons/addons.service";
import {Color} from '../../window-system/color/color';
import {ColorService} from '../../window-system/color/color.service';
import {
    GateDesignerCatalogDependentOptionService
} from '../../window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option.service';
import {GatePanelType} from '../../window-system/gate-panel-type/gate-panel-type';
import {GatePanelTypeService} from '../../window-system/gate-panel-type/gate-panel-type.service';
import {GateSystem} from '../../window-system/gate-system/gate-system';
import {GateSystemService} from '../../window-system/gate-system/gate-system.service';
import {GateWallService} from "../../window-system/gate-wall/gate-wall.service";
import {RailSystemService} from '../../window-system/rail-system/rail-system.service';
import {OffersService} from '../offer-service';
import {MessageSeverity, PositionMessage} from "../offers/message";
import {
    WindowAddonActionType,
    WindowAddonSaveData
} from "../offers/position/position-list/add-bulk-addon-position/addon-position/addon-position.component";
import {Position, PositionFactory} from '../offers/position/position-list/position';
import {PositionService} from '../offers/position/position.service';
import {ProductionOrderService} from '../production-orders/production-order-service';
import {ProductionOrdersPositionService} from '../production-orders/production-orders-position/production-orders-position.service';
import {DrawingToolControlsMode} from "../window-editor/drawing-tool-controls/drawing-tool-controls.component";
import {ProfitMarginExistance} from "../window-editor/drawing-tool/ProfitMarginExistance";
import {Pricing} from '../window-editor/sidebar/pricing/Pricing';
import {PricingComponent} from '../window-editor/sidebar/pricing/pricing.component';
import {PricingService} from '../window-editor/sidebar/pricing/pricing.service';
import {CatalogItemType} from "../window-editor/sidebar/pricing/PricingItem";
import {Product} from "../window-editor/sidebar/pricing/Product";
import {ResponseStatusFlags, ResponseStatusHelper} from '../window-editor/sidebar/ResponseStatusFlags';
import {SystemDefaultsService} from "../window-editor/system-defaults.service";
import {WindowComponentPreviewData} from '../window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {
    WindowEditorOfferData,
    WindowEditorPositionData,
    WindowEditorProductionOrderData
} from '../window-editor/window-editor-offer-interfaces';
import {GateData} from './gate-data';
import {GateEditorField} from './gate-editor-field';
import {CatalogLoadResult, GateEditorFieldContentProvider} from './gate-editor-field-content-provider';
import {AccordionTabId, GateEditorFieldStates} from './gate-editor-field-states';
import {GatePainterService} from './gate-painter/gate-painter.service';
import {GateSidebarFieldImageService} from './gate-sidebar/gate-sidebar-field-image.service';
import {GateSidebarComponent, SidebarFieldChangeEvent} from './gate-sidebar/gate-sidebar.component';

const enum SidebarTab {
    GENERAL = 0,
    PRICING = 1,
    VALIDATION = 2
}

@Component({
    selector: 'app-gate-editor',
    templateUrl: './gate-editor.component.html',
    styleUrls: ['../window-editor/common/designers.css', './gate-designers.css'],
    providers: [OffersService, PositionService, ProductionOrderService, ProductionOrdersPositionService,
        GateEditorFieldContentProvider, GateSidebarFieldImageService, GatePainterService, GateSystemService, RailSystemService,
        ColorService, GatePanelTypeService, PricingService, TranslatedSelectItemService, AddonCategoryGroupService, AddonsService,
        CatalogConfigurationService, GateDesignerCatalogDependentOptionService, SystemDefaultsService, GateWallService,
        MissingProfitMarginHandlerService]
})
export class GateEditorComponent implements OnInit, OnDestroy {

    private static readonly BLOCK_SOURCE_ID = 'GateEditor';

    @ViewChild('pricing')
    pricingComponent: PricingComponent;

    @ViewChild(GateSidebarComponent)
    sidebarComponent: GateSidebarComponent;

    @ViewChild('gatePainter', {static: true})
    gatePainter: ElementRef;

    suppliers: SupplierInterface[] = [];
    gates: GateSystem[] = [];
    catalogConfiguration: CatalogConfiguration;
    catalogLoadedForGateSystemId: number;
    colors: Color[];
    gatePanelTypes: GatePanelType[];
    railSystemImageTemplate: string;
    readonly dialogs = {
        description: false,
        confirmReplacing: false,
        addons: false
    };

    defaults: GateSystemDefaultsState;
    defaultsLevel: 'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER';
    defaultsLevels: SelectItem[] = [];
    defaultsOverrideLowerLevel: boolean;

    selectedGateSystem: GateSystem;

    data: GateData;
    originalData: string;
    offer: WindowEditorOfferData;
    offerPosition: WindowEditorPositionData;
    productionOrder: WindowEditorProductionOrderData;
    productionOrderPosition: WindowEditorPositionData;
    validationErrors: ValidationErrors = {};

    pricingStatus: ResponseStatusFlags = new ResponseStatusFlags();
    backendValidationStatus: ResponseStatusFlags = new ResponseStatusFlags();

    readOnlyMode = true;
    showSidebar = true;
    showGateDisclaimer = false;
    sidebarActiveTabIndex = SidebarTab.GENERAL;
    saveInProgress = false;
    showAddDialog = false;
    showExitWithoutSavingConfirmationDialog = false;

    windowComponentPreviewData: WindowComponentPreviewData;
    addonCategoryGroups: AddonCategoryGroup[];
    visibleAddonCategoryGroups: AddonCategoryGroup[];
    addedGateAddons: PositionListAddon[] = [];

    fieldUsage: GateEditorFieldStates;
    SystemType = SystemType;
    DrawingToolControlsMode = DrawingToolControlsMode;

    addonGroupCheckSub: Subscription;

    fieldToFocusOnError: string;
    groupToFocusOnError: string;

    showExitWithMessagesDialog: boolean;
    recentPricingProducts: Product[];
    recentValidationMessages: PositionMessage[] = [];

    readonly printXMLs = new Subject<string>();

    constructor(private readonly router: Router,
                private readonly activatedRoute: ActivatedRoute,
                private readonly translate: TranslateService,
                private readonly translatedSelectItemService: TranslatedSelectItemService,
                private readonly offerService: OffersService,
                private readonly positionService: PositionService,
                private readonly productionOrderService: ProductionOrderService,
                private readonly productionOrderPositionService: ProductionOrdersPositionService,
                private readonly gateSystemService: GateSystemService,
                private readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider,
                private readonly gatePainterService: GatePainterService,
                private readonly railSystemService: RailSystemService,
                private readonly systemDefaultsService: SystemDefaultsService,
                private readonly pricingService: PricingService,
                private readonly sidenavController: SidenavController,
                private readonly blockUiController: BlockUiController,
                private readonly errors: CommonErrorHandler,
                private readonly catalogConfigurationService: CatalogConfigurationService,
                private readonly addonCategoryGroupService: AddonCategoryGroupService,
                private readonly currentUserService: CurrentUserService,
                private readonly growls: GrowlMessageController,
                private readonly permissions: Permissions,
                private readonly changeDetector: ChangeDetectorRef,
                private readonly addonsService: AddonsService,
                private readonly missingProfitMarginHandlerService: MissingProfitMarginHandlerService) {
        this.fieldUsage = new GateEditorFieldStates(this, gateEditorFieldContentProvider);
        this.gateEditorFieldContentProvider.filteringEnabled = true;
    }

    ngOnInit() {
        this.sidenavController.hide();
        this.blockUiController.block(GateEditorComponent.BLOCK_SOURCE_ID);

        const offerId = this.activatedRoute.snapshot.params['offerId'];
        const productionOrderId = this.activatedRoute.snapshot.params['productionOrderId'];
        const positionId = this.activatedRoute.snapshot.params['positionId'];
        const newPosition = positionId === 'new';
        let offerObservable: Observable<WindowEditorOfferData>;
        let offerPositionObservable: Observable<WindowEditorPositionData>;
        let productionOrderObservable: Observable<WindowEditorProductionOrderData>;
        let productionOrderPositionObservable: Observable<WindowEditorPositionData>;
        if (offerId != null) {
            offerObservable = this.offerService.getOfferForWindowEditor(offerId);
            offerPositionObservable = newPosition
                ? of(PositionFactory.gate(offerId))
                : this.positionService.getOfferPositionForWindowEditor(positionId);
            productionOrderObservable = of(null);
            productionOrderPositionObservable = of(null);
        } else {
            offerObservable = of(null);
            offerPositionObservable = of(null);
            productionOrderObservable = this.productionOrderService.getProductionOrderForWindowEditor(productionOrderId);
            productionOrderPositionObservable = this.productionOrderPositionService.getProductionOrderPositionForWindowEditor(positionId);
        }

        this.loadDefaultsLevels();

        forkJoin({
            offer: offerObservable,
            offerPosition: offerPositionObservable,
            productionOrder: productionOrderObservable,
            productionOrderPosition: productionOrderPositionObservable,
            catalogConfiguration: this.loadCatalogConfiguration(newPosition)
        }).pipe(catchError(
            (error) => {
                this.blockUiController.unblock(GateEditorComponent.BLOCK_SOURCE_ID);
                this.errors.handle(error);
                this.router.navigate(['features/offer']);
                return EMPTY;
            }), mergeMap(data => {
            this.catalogConfiguration = data.catalogConfiguration;
            this.offer = data.offer;
            this.offerPosition = data.offerPosition;
            this.productionOrder = data.productionOrder;
            this.productionOrderPosition = data.productionOrderPosition;
            let position = this.offerPosition || this.productionOrderPosition;
            this.readOnlyMode = this.offer == undefined
                || !this.permissions.userCanEditOffer(this.offer.status)
                || (position.validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun())
                || (this.offer.offerLockUserLogin !== this.currentUserService.currentUserName
                    && !this.currentUserService.restrictedToOfferNumber);
            this.data = JSON.parse(position.data);
            this.originalData = position.data;
            return forkJoin({
                gates: this.gateEditorFieldContentProvider.loadGatesForEditor(this.data.gateSystemId),
                ...this.gateEditorFieldContentProvider.getCatalogDataSource(this.data.gateSystemId, this.data),
                defaults: this.data.gateSystemId != undefined ?
                    this.systemDefaultsService.getDefaultsForGate(this.data.gateSystemId,
                        this.offerPosition != undefined ? this.offerPosition.offerId : undefined)
                    : of(new GateSystemDefaultsState()),
                addons: this.loadAddedAddons()
            });
        }), finalize(() => this.blockUiController.unblock(GateEditorComponent.BLOCK_SOURCE_ID))).subscribe(data => {
            (this.addonCategoryGroups || []).forEach(group => {
                group.categories.forEach(category => {
                    this.gateEditorFieldContentProvider.setItems(category.symbol, [], []);
                });
            });
            this.addonCategoryGroups = data.addonCategoryGroups;
            this.visibleAddonCategoryGroups = data.addonCategoryGroups;
            let fieldEnum = this.sanitizeGateDataOnExistingPosition();
            this.gateEditorFieldContentProvider.initializeProvider(fieldEnum);
            this.initGates(data.gates);

            this.initCatalog(this.data.gateSystemId, data);
            this.gateEditorFieldContentProvider.storeSelectedValues(this.data);
            this.defaults = data.defaults;
            this.addedGateAddons = WindowAddonUtils.wrapAddedAddons(data.addons, this.data, this.translate.currentLang, this.growls);

            this.showAddDialog = newPosition;
            this.initRailSystemImage();
        });
        this.addonGroupCheckSub = this.gateEditorFieldContentProvider.verifyAddonCategoryGroups.subscribe(() => {
            this.verifyAddonCategoryGroups();
        });
    }

    ngOnDestroy(): void {
        this.sidenavController.show();
        this.addonGroupCheckSub.unsubscribe();
    }

    private verifyAddonCategoryGroups() {
        for (let group of this.addonCategoryGroups) {
            let visibleCategories = [];
            group.categories.forEach(category => {
                let visible = this.fieldUsage.isVisible(category.symbol);
                if (visible) {
                    visibleCategories.push(category.symbol);
                } else {
                    delete this.validationErrors[group.id + '_' + category.symbol];
                }
            });
            if (visibleCategories.length === 0) {
                this.visibleAddonCategoryGroups = this.visibleAddonCategoryGroups.filter(catGroup => catGroup.id !== group.id);
            } else {
                if (!this.visibleAddonCategoryGroups.some(catGroup => catGroup.id === group.id)) {
                    this.visibleAddonCategoryGroups.push(group);
                    this.visibleAddonCategoryGroups.sort((a, b) => a.sortIndex - b.sortIndex);
                }
            }
        }
    }

    private loadCatalogConfiguration(loadAll: boolean): Observable<CatalogConfiguration> {
        return loadAll ? this.catalogConfigurationService.getForTarget(CatalogPropertyTarget.GATES) : of(null);
    }

    private loadDefaultsLevels(): void {
        this.defaultsLevels.push({
            label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.OFFER',
            value: 'OFFER'
        });
        if (this.permissions.isOperator() || this.permissions.isHandlowiec() || this.permissions.isSprzedawca()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT',
                value: 'CLIENT'
            });
        }
        if (this.permissions.isOperator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT_GROUP',
                value: 'CLIENT_GROUP'
            });
        }
        if (this.permissions.isKoordynator() || this.permissions.isOpiekun() || this.permissions.isOperator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM',
                value: 'SUBSYSTEM'
            });
        }
        if (this.permissions.isKoordynator() || this.permissions.isOpiekun()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM_GROUP',
                value: 'SUBSYSTEM_GROUP'
            });
        }
        if (this.permissions.isKoordynator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.GLOBAL',
                value: 'GLOBAL'
            });
        }
        if (this.defaultsLevels.length > 0) {
            this.defaultsLevel = this.defaultsLevels[0].value;
        }
    }

    exit(): void {
        if (this.dataChanged()) {
            this.showExitWithoutSavingConfirmationDialog = true;
        } else {
            this.showExitWithoutSavingConfirmationDialog = false;
            this.navigateToPositionList();
        }
    }

    exitWithoutSaving(): void {
        this.navigateToPositionList();
    }

    sanitizeGateDataOnExistingPosition(): string[] {
        let validCategories = _.chain(this.addonCategoryGroups)
            .map(group => group.categories)
            .flatten()
            .map(category => category.symbol)
            .value();
        let selectedCategories = Object.keys(this.data.sidebarAddons);
        for (let category of selectedCategories) {
            if (!validCategories.includes(category)) {
                delete this.data.sidebarAddons[category];
            }
        }
        return validCategories;
    }

    navigateToPositionList(): void {
        const matrixParams = {
            ..._.omit(this.activatedRoute.parent.snapshot.params, ['offerId']),
            lastSelection: this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id
        };
        this.router.navigate(['../..', matrixParams], {relativeTo: this.activatedRoute});
    }

    saveAndExit(): void {
        if (this.saveInProgress || this.showExitWithMessagesDialog || this.readOnlyMode || !this.validateAll()) {
            this.focusOnErrorField();
            return;
        }
        if (!this.selectedGateSystem.active) {
            this.growls.error("OFFER.DRAWING.WINDOW_SYSTEM_NOT_AVAILABLE");
            return;
        }
        this.saveInProgress = true;
        this.prepareOfferPosition();
        this.pricingService.evaluateGate(false, this.data, this.offer.id, this.offerPosition.id, false,
            this.offerPosition.validationDisabled).subscribe({
            next: (data: Pricing) => {
                const hasMessages = data.products.some(product => product.messages.length > 0) ||
                    data.validationMessages.length > 0;
                const hasErrors = Pricing.containsForValidation(data, MessageSeverity.ERROR) ||
                    Pricing.containsForValidation(data, MessageSeverity.BLOCKER);
                if (hasErrors || !hasMessages) {
                    this.saveItem();
                } else {
                    this.saveInProgress = false;
                    this.showExitWithMessagesDialog = true;
                    this.recentValidationMessages = data.validationMessages;
                    this.recentPricingProducts = data.products;
                }
            },
            error: error => {
                this.saveInProgress = false;
                this.errors.handle(error);
            }
        });
    }

    saveItem(): void {
        this.printXMLs.pipe(mergeMap(printImage => {
            return this.positionService.saveItem(this.offerPosition as Position, {
                regular: printImage,
                technical: this.prepareGateSideViewForPrint()
            });
        })).subscribe({
            next: newOfferPositionId => {
                let navigateObservable = of(true);
                if (this.offerPosition.id === undefined) {
                    this.offerPosition.id = newOfferPositionId;
                    navigateObservable = navigateObservable.pipe(mergeMap(() =>
                        from(this.router.navigate([`../../${this.offerPosition.id}/gateDesigner`],
                            {relativeTo: this.activatedRoute, replaceUrl: true}))));
                }
                navigateObservable.subscribe({
                    complete: () => {
                        this.saveInProgress = false;
                        this.router.navigate(['../..', {lastSelection: this.offerPosition.id}], {relativeTo: this.activatedRoute});
                    }
                });
            },
            error: error => {
                this.errors.handle(error);
                this.saveInProgress = false;
            }
        });

        this.gatePainterService.requestXML(this.getParams()); // causes app-gate-print-painter to output into printXMLs
    }

    private prepareGateSideViewForPrint(): string {
        let gateSideView = null;
        if (this.gatePainter.nativeElement.childElementCount === 1) {
            let childNode = this.gatePainter.nativeElement.childNodes[0];
            if (childNode.attributes.getNamedItem('fill') != null) {
                childNode.attributes.removeNamedItem('fill');
            }
            gateSideView = new XMLSerializer().serializeToString(childNode);
        }
        return gateSideView;
    }

    validateAll(): boolean {
        this.validationErrors = this.getValidationErrors();
        let errorsPresent = ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);

        const unavailableDetected = this.gateEditorFieldContentProvider.fieldsWithUnavailableValues.length > 0;
        if (unavailableDetected) {
            this.growls.error('OFFER.TABS.ERROR.UNAVAILABLE_ITEMS_PRESENT');
        }

        return !errorsPresent && !unavailableDetected;
    }

    private getValidationErrors(): ValidationErrors {
        const validationErrors = {};
        this.sanitizeGateDataBeforeSave(validationErrors);

        let requiredFields = GateEditorFieldStates.SECTION_FIELDS.get(AccordionTabId.GENERAL);
        const unavailable = this.gateEditorFieldContentProvider.fieldsWithUnavailableValues || [];
        for (const field of requiredFields) {
            if (this.fieldUsage.isNotValid(field) || unavailable.includes(field)) {
                this.setupScrollToError(validationErrors, AccordionTabId.GENERAL, field, field);
            }
        }
        return validationErrors;
    }

    private sanitizeGateDataBeforeSave(validationErrors = {}) {
        const unavailable = this.gateEditorFieldContentProvider.fieldsWithUnavailableValues || [];
        this.addonCategoryGroups.forEach(group => {
            group.categories
                .forEach(category => {
                    let isFilled = this.data.sidebarAddons[category.symbol] != null;
                    let isVisible = this.fieldUsage.isVisible(category.symbol);
                    if (isVisible && ((!isFilled && category.required) || unavailable.includes(category.symbol))) {
                        this.setupScrollToError(validationErrors, group.id, group.id + '_' + category.symbol, category.symbol);
                    } else if (isFilled && !isVisible) {
                        delete this.data.sidebarAddons[category.symbol];
                    }
                });
        });
    }

    private setupScrollToError(validationErrors: ValidationErrors, groupId: string | number, fieldName: string, fieldSymbol: string) {
        validationErrors[fieldName] = "ERROR";
        if (this.fieldToFocusOnError == undefined) {
            this.fieldToFocusOnError = fieldSymbol;
            this.groupToFocusOnError = this.sidebarComponent.addonCategoryGroupName + groupId;
        }
    }

    private prepareOfferPosition(): void {
        this.mapAddons();
        this.offerPosition.data = JSON.stringify(this.data);
        this.offerPosition.dimensions = this.data.width + "x" + this.data.height;
        this.offerPosition.configurableAddons = [];
    }

    openChangeSystemDialog(): void {
        (this.catalogConfiguration == null ? this.loadCatalogConfiguration(true) : of(this.catalogConfiguration)).subscribe(
            catalogConfiguration => {
                this.catalogConfiguration = catalogConfiguration;
                this.showAddDialog = true;
            }
        );
    }

    gateSystemSelected(gateSystemId: number): void {
        this.validationErrors = {};
        if (gateSystemId == undefined) {
            this.onDialogClosed();
            return;
        }
        this.blockUiController.block(GateEditorComponent.BLOCK_SOURCE_ID);
        this.loadProfitMargins(gateSystemId)
            .pipe(catchError(
                (error) => {
                    this.errors.handle(error);
                    return EMPTY;
                }), this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                ...this.offer,
                identifier: {target: 'GATE_SYSTEM', gateSystemId: gateSystemId}
            }), mergeMap(profitMarginValid => {
                if (profitMarginValid) {
                    this.data.gateSystemId = gateSystemId;
                    this.selectedGateSystem = this.gates.find(gate => gate.id === gateSystemId);
                    this.showAddDialog = false;
                    return forkJoin({
                        sidebarAddons: this.addonCategoryGroupService.getGroupsForGateDeep(gateSystemId,
                            _.values(this.data.sidebarAddons)),
                        ...this.gateEditorFieldContentProvider.getCatalogDataSource(gateSystemId, null),
                        defaults: this.systemDefaultsService.getDefaultsForGate(gateSystemId,
                            this.offerPosition != undefined ? this.offerPosition.offerId : undefined)
                    });
                } else {
                    return EMPTY;
                }
            }), finalize(() => this.hideUiBlock())).subscribe(
            (catalogData) => {
                if (this.data.sidebarAddons != null) {
                    this.data.sidebarAddons = {};
                }
                (this.addonCategoryGroups || []).forEach(group => {
                    group.categories.forEach(category => {
                        this.gateEditorFieldContentProvider.setItems(category.symbol, [], [this.data.sidebarAddons[category.symbol]]);
                    });
                });
                this.addonCategoryGroups = catalogData.sidebarAddons;
                this.visibleAddonCategoryGroups = catalogData.sidebarAddons;
                let fieldEnum = _.chain(this.addonCategoryGroups)
                    .map(group => group.categories)
                    .flatten()
                    .map(category => category.symbol)
                    .value();
                this.gateEditorFieldContentProvider.initializeProvider(fieldEnum);
                this.initCatalog(gateSystemId, catalogData);
                this.defaults = catalogData.defaults;
                GateData.applyDefaults(this.data, this.defaults);
                this.gateEditorFieldContentProvider.storeSelectedValues(this.data);
                this.initRailSystemImage();
            });
    }

    private initRailSystemImage() {
        if (this.data.railSystemId != null) {
            // run RAIL_SYSTEM change actions to load image template and show it
            this.runActionsOnFieldChange(
                {field: GateEditorField.RAIL_SYSTEM, newValue: this.data.railSystemId, oldValue: undefined});
        }
    }

    private loadProfitMargins(systemId: number): Observable<ProfitMarginExistance> {
        return this.readOnlyMode ?
            of(new ProfitMarginExistance(true)) :
            this.gateSystemService.validateMarginExistance(systemId, this.offerPosition.offerId);
    }

    onDialogClosed(): void {
        if (this.data.gateSystemId == null) {
            this.exit();
        }
        this.showAddDialog = false;
    }

    private dataChanged(): boolean {
        return this.originalData !== JSON.stringify(this.data);
    }

    private initCatalog(gateSystemId: number, data: CatalogLoadResult) {
        this.catalogLoadedForGateSystemId = gateSystemId;
        this.colors = data.colors;
        this.gatePanelTypes = data.panelTypes;
        const mapSelectItem = (item: { id: number, name: MultilanguageFieldInterface, active: boolean, singlePositionAddon?: boolean, designerComment?: MultilanguageFieldInterface }) => ({
            label: item.name[this.translate.currentLang],
            value: item.id,
            available: item.active,
            singlePositionItem: item.singlePositionAddon,
            title: item.designerComment == null ? null : item.designerComment[this.translate.currentLang],
        });
        const mapSelectItem2 = (item: { id: number, names: MultilanguageFieldInterface, active: boolean }) => ({
            label: item.names[this.translate.currentLang],
            value: item.id,
            available: item.active
        });
        this.gateEditorFieldContentProvider.setItems(GateEditorField.RAIL_SYSTEM, data.railSystems.map(mapSelectItem),
            [this.data.railSystemId]);
        this.gateEditorFieldContentProvider.setItems(GateEditorField.GATE_WALL, data.walls.map(mapSelectItem),
            [this.data.wall1, this.data.wall2]);
        this.gateEditorFieldContentProvider.setItems(GateEditorField.GATE_PANEL_TYPE, data.panelTypes.map(mapSelectItem),
            [this.data.gatePanelTypeId]);

        (this.addonCategoryGroups || []).forEach(group => {
            group.categories.forEach(category => {
                let items = category.addons.map(mapSelectItem);
                this.gateEditorFieldContentProvider.setItems(category.symbol, items, [this.data.sidebarAddons[category.symbol]]);
            });
        });

        const hasRal = data.colors.some(c => c.type === ColorType.RAL_PALETTE_CUSTOM);
        if (hasRal) {
            this.colors.push(this.getEmptyRalColor());
        }
        const colorSelectItems: SelectItem[] = data.colors.filter(color => color.type !== ColorType.GATE_CORE).map(mapSelectItem2);
        const coreColorSelectItems: SelectItem[] = data.colors.filter(color => color.type === ColorType.GATE_CORE).map(mapSelectItem2);
        this.gateEditorFieldContentProvider.setItems(GateEditorField.CORE_COLOR, coreColorSelectItems, [this.data.coreColorId]);
        this.gateEditorFieldContentProvider.setItems(GateEditorField.EXTERNAL_COLOR, colorSelectItems, [this.data.externalColorId]);
        this.gateEditorFieldContentProvider.setItems(GateEditorField.INTERNAL_COLOR, colorSelectItems, [this.data.internalColorId]);
        this.translatedSelectItemService.buildUnsortedDropdown(GateControl, 'GATE_CONTROL.', undefined)
            .subscribe(selectItems => this.gateEditorFieldContentProvider.setItems(GateEditorField.CONTROL, selectItems));
        this.gateEditorFieldContentProvider.storeFieldDependencies(data.fieldDependencies);
    }

    private initGates(gates: GateSystem[]): void {
        this.gates = gates;
        this.selectedGateSystem = this.gates.find(gate => gate.id === this.data.gateSystemId);
        this.collectGateSuppliers();
    }

    private collectGateSuppliers() {
        for (let gate of this.gates) {
            if (!this.suppliers.some(supplier => supplier.id === gate.supplier.id)) {
                this.suppliers.push(gate.supplier);
            }
        }
    }

    private getEmptyRalColor(): Color {
        let ralColor = new Color();
        ralColor.id = ('PLACEHOLDER_RAL' as any);
        ralColor.names = new MultilanguageField();
        for (let supportedLanguage of SupportedLanguages.languages) {
            ralColor.names[supportedLanguage.code] = this.translate.instant("OFFER.TABS.SECTION.COLOR.RAL_PALETTE_CUSTOM");
        }
        ralColor.type = ColorType.RAL_PALETTE_CUSTOM;
        return ralColor;
    }

    private mapAddons(): void {
        // TODO: implement
    }

    generalTabContainsErrors() {
        return ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);

    }

    handleSidebarActiveIndexChange(tabIndex: SidebarTab): void {
        switch (tabIndex) {
            case SidebarTab.GENERAL:
                break;
            case SidebarTab.PRICING:
                this.sanitizeGateDataBeforeSave();
                this.pricingComponent.getPricing(this.data, this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id,
                    [], this.offerPosition != undefined && this.offerPosition.validationDisabled);
                break;
            case SidebarTab.VALIDATION:
                // uses validation messages from pricing, load it
                this.sanitizeGateDataBeforeSave();
                this.pricingComponent.getPricing(this.data, this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id,
                    [], this.offerPosition != undefined && this.offerPosition.validationDisabled);
                break;
        }
    }

    getTabpanelHeaderStyle(status: ResponseStatusFlags): string {
        return ResponseStatusHelper.tabpanelHeaderStyle(status);
    }

    updateValidationPricingStatuses(pricing: Pricing, omitPricing: boolean) {
        ResponseStatusHelper.updateValidationStatuses(this.backendValidationStatus, pricing);
        let pricingTabSelected = this.sidebarActiveTabIndex === SidebarTab.PRICING;
        if (!omitPricing || pricingTabSelected) {
            ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricing);
        }
    }

    handleSidebarFieldChange(event: SidebarFieldChangeEvent): void {
        this.updateDependentFieldsOnChange(event);
        this.updateUsedGlobalSettingsStateOnFieldChange(event);
        this.runActionsOnFieldChange(event);
         if (event.field === GateEditorField.GATE_WALL) {
            this.validateGate();
        }
    }

    private validateGate() {
        this.sanitizeGateDataBeforeSave();
        if (this.offerPosition != undefined && this.offerPosition.validationDisabled) {
            return;
        }
        let offerPositionId = this.offerPosition ? this.offerPosition.id : this.productionOrderPosition.id;
        this.pricingService.evaluateGate(this.readOnlyMode, this.data, this.offer.id, offerPositionId, true)
            .subscribe({
                next: pricingResult => {
                    ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricingResult);
                    ResponseStatusHelper.updateValidationStatuses(this.backendValidationStatus, pricingResult);
                    if (this.pricingStatus.errors) {
                        this.growls.error('OFFER.DRAWING.PRICING.ERROR');
                    }
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
    }

    private needsValidationOnBlur(field: string): boolean {
        switch (field) {
            case GateEditorField.WIDTH:
            case GateEditorField.HEIGHT:
            case GateEditorField.LINTEL_HEIGHT:
            case GateEditorField.RAIL_SYSTEM:
                return true;
            default:
                return false;
        }
    }

    handleSidebarFieldBlur(event: SidebarFieldChangeEvent): void {
        if (this.needsRepaintOnBlur(event.field)) {
            let params = this.getParams();
            this.gatePainterService.paint(params);
        }
        if (this.needsValidationOnBlur(event.field)) {
            this.validateGate();
        }
    }

    private updateDependentFieldsOnChange(event: SidebarFieldChangeEvent): void {
        this.gateEditorFieldContentProvider.notifyFieldChanged(event.field, event.newValue);
        switch (event.field) {
            case GateEditorField.WIDTH:
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.WIDTH_GREATER_THAN, event.newValue);
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.WIDTH_LESS_THAN, event.newValue);
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.AREA_GREATER_THAN,
                    SizeUnitsConverter.mmSidesToMeterSquared(event.newValue, this.data.height));
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.AREA_LESS_THAN,
                    SizeUnitsConverter.mmSidesToMeterSquared(event.newValue, this.data.height));
                break;
            case GateEditorField.HEIGHT:
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.HEIGHT_GREATER_THAN, event.newValue);
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.HEIGHT_LESS_THAN, event.newValue);
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.AREA_GREATER_THAN,
                    SizeUnitsConverter.mmSidesToMeterSquared(this.data.width, event.newValue));
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.AREA_LESS_THAN,
                    SizeUnitsConverter.mmSidesToMeterSquared(this.data.width, event.newValue));
                break;
            case GateEditorField.LINTEL_HEIGHT:
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.LINTEL_HEIGHT_GREATER_THAN, event.newValue);
                this.gateEditorFieldContentProvider.notifyFieldChanged(GateEditorField.LINTEL_HEIGHT_LESS_THAN, event.newValue);
                break;
        }
    }

    private updateUsedGlobalSettingsStateOnFieldChange(event: SidebarFieldChangeEvent): void {
        if (this.fieldIsSavedInDefaults(event.field)) {
            this.data.usedGlobalSettingsChanged = true;
        }
    }

    private runActionsOnFieldChange(event: SidebarFieldChangeEvent) {
        this.setShowGateDisclaimer();
        this.prepareFieldChangeAction(event).subscribe(() => {
            if (this.needsRepaintOnFieldChange(event.field)) {
                let params = this.getParams();
                this.gatePainterService.paint(params);
            }
        });
    }

    private getParams(): GateImagePaintParams {
        let colorId = this.data.externalColorId ? this.data.externalColorId : this.data.coreColorId;
        const color = this.colors.find(c => c.id === colorId);
        const panel = this.gatePanelTypes.find(g => g.id === this.data.gatePanelTypeId);
        return {
            width: this.data.width,
            height: this.data.height,
            lintelHeight: this.data.lintelHeight,
            panelColor: color != undefined ? color.webshopHex : '#DCDCDC',
            panelHeight: panel != undefined && panel.height ? panel.height : 250,
            panelStamping: panel != undefined ? panel.stampingType : GatePanelStampingType.ALUTECH_L,
            control: this.data.control
        };
    }

    onShowDescriptionDialog(): void {
        this.dialogs.description = true;
    }

    onShowAddonsDialog(): void {
        this.dialogs.addons = true;
    }

    private prepareFieldChangeAction(event: SidebarFieldChangeEvent): Observable<void> {
        switch (event.field) {
            case GateEditorField.RAIL_SYSTEM:
                return (event.newValue != undefined
                    ? this.railSystemService.getDesignerImageAsBase64(event.newValue)
                    : of(undefined)).pipe(
                    mergeMap(imageTemplateDataUri => {
                        if (!!imageTemplateDataUri) {
                            return from(fetch(imageTemplateDataUri))
                                .pipe(mergeMap(response => response.ok ? response.text() : of(undefined)));
                        }
                        return of(undefined);
                    }),
                    mergeMap(imageTemplate => {
                        this.railSystemImageTemplate = imageTemplate;
                        return timer(1);
                    }),
                    map(() => undefined)
                );
        }
        return of<void>(undefined);
    }

    private needsRepaintOnBlur(field: string): boolean {
        switch (field) {
            case GateEditorField.WIDTH:
            case GateEditorField.HEIGHT:
            case GateEditorField.LINTEL_HEIGHT:
                return true;
            default:
                return false;
        }
    }

    private needsRepaintOnFieldChange(field: string): boolean {
        switch (field) {
            case GateEditorField.RAIL_SYSTEM:
            case GateEditorField.CONTROL:
            // print only
            case GateEditorField.GATE_PANEL_TYPE:
            case GateEditorField.EXTERNAL_COLOR:
                return true;
            case GateEditorField.CORE_COLOR:
                return this.data.externalColorId == null;
            default:
                return false;
        }
    }

    private fieldIsSavedInDefaults(field: GateEditorField | string): boolean {
        switch (field) {
            case GateEditorField.RAIL_SYSTEM:
            case GateEditorField.EXTERNAL_COLOR:
            case GateEditorField.INTERNAL_COLOR:
            case GateEditorField.GATE_PANEL_TYPE:
            case GateEditorField.CONTROL:
                return true;
            default:
                // addons
                return !Object.keys(GateEditorField).includes(field);
        }
    }

    private hideUiBlock(): void {
        this.blockUiController.unblock(GateEditorComponent.BLOCK_SOURCE_ID);
    }

    windowAddonsSave(windowAddonSaveData: WindowAddonSaveData): void {
        if (windowAddonSaveData.action === WindowAddonActionType.ADD) {
            WindowAddonUtils.addWindowAddonToData(windowAddonSaveData.addonToSave, windowAddonSaveData.positionlistAddon, this.data,
                this.addedGateAddons);
            this.growls.info('OFFER.DRAWING.ADDONS.ADDED');
        } else {
            if (WindowAddonUtils.editWindowAddonInData(windowAddonSaveData.addonToSave, this.data)) {
                this.growls.info('OFFER.DRAWING.ADDONS.EDITED');
            }
        }
        this.setShowGateDisclaimer();
    }

    private setShowGateDisclaimer() {
        if (this.addedGateAddons.some(addon => addon.showGateDisclaimer)) {
            this.showGateDisclaimer = true;
            return;
        }
        for (let addonCategorySymbol in this.data.sidebarAddons) {
            let sidebarAddon = this.data.sidebarAddons[addonCategorySymbol];
            if (sidebarAddon == null) {
                continue;
            }
            for (let addonCategoryGroup of this.addonCategoryGroups) {
                let addonCategory = addonCategoryGroup.categories.find(category => category.symbol === addonCategorySymbol);
                if (addonCategory == null) {
                    continue;
                }
                let addonToNeedDisclaimer = addonCategory.addons.find(addon => addon.id === sidebarAddon).showGateDisclaimer;
                if (addonToNeedDisclaimer) {
                    this.showGateDisclaimer = true;
                    return;
                }
            }
        }
        this.showGateDisclaimer = false;
    }

    windowAddonsRemove(addonId): void {
        WindowAddonUtils.removeWindowAddonToData(addonId, this.data, this.addedGateAddons);
        this.setShowGateDisclaimer();
        this.growls.info('OFFER.DRAWING.ADDONS.REMOVED');
    }

    closeAddonsDialog(): void {
        this.dialogs.addons = false;
        this.changeDetector.markForCheck();
    }

    private loadAddedAddons(): Observable<AddonInterface[]> {
        let ids = this.data.addons.map(addon => addon.addonId);
        if (ids != null && ids.length > 0) {
            return this.addonsService.getItemsByIds(ids, true);
        }
        return of<AddonInterface[]>([]);
    }

    handleDefaultsSavedClick(): void {
        if (this.defaults[this.getFieldNameFromLevel(this.defaultsLevel)]) {
            this.dialogs.confirmReplacing = true;
            return;
        }

        this.saveDefaults();
    }

    saveDefaults(): void {
        const data = GateSystemDefaults.copy(this.data);
        let observable: Observable<any> = of({});

        const offerId = this.offerPosition != undefined ? this.offerPosition.offerId : undefined;
        if (this.defaultsOverrideLowerLevel) {
            observable = observable.pipe(
                mergeMap(() => this.systemDefaultsService.deleteSystemDefaults(
                    CatalogItemType.GATE_SYSTEM, this.defaultsLevel, this.selectedGateSystem.id, offerId)),
                tap(() => {
                    const levels = ['GLOBAL', 'SUBSYSTEM_GROUP', 'SUBSYSTEM', 'CLIENT_GROUP', 'CLIENT', 'OFFER'];
                    const savedIndex = levels.indexOf(this.defaultsLevel) + 1;
                    for (let i = savedIndex; i < levels.length; ++i) {
                        this.defaults[this.getFieldNameFromLevel(levels[i])] = false;
                    }
                }));
        }
        observable.pipe(mergeMap(() => this.systemDefaultsService.save(CatalogItemType.GATE_SYSTEM, this.defaultsLevel, this.selectedGateSystem.id,
            offerId, data))).subscribe({
            complete: () => {
                this.dialogs.confirmReplacing = false;
                this.defaults.value = data;
                this.defaults[this.getFieldNameFromLevel(this.defaultsLevel)] = true;
                this.growls.info('OFFER.TABS.SECTION.DEFAULTS.SAVED');
            },
            error: error => {
                let errors = this.errors.handle(error);
                for (let errorsKey in errors) {
                    this.growls.error(errors[errorsKey]);
                }
            }
        });
    }

    private getFieldNameFromLevel(level: string): keyof Omit<GateSystemDefaultsState, 'value'> {
        switch (level) {
            case 'GLOBAL':
                return 'globalDefaultExisting';
            case 'SUBSYSTEM_GROUP':
                return 'subsystemGroupDefaultExisting';
            case 'SUBSYSTEM':
                return 'subsystemDefaultExisting';
            case 'CLIENT_GROUP':
                return 'clientGroupDefaultExisting';
            case 'CLIENT':
                return 'clientDefaultExisting';
            case 'OFFER':
                return 'offerDefaultExisting';
        }
    }

    private focusOnErrorField() {
        if (this.fieldToFocusOnError) {
            this.handleSidebarActiveIndexChange(SidebarTab.GENERAL);
            if (this.groupToFocusOnError != undefined) {
                this.sidebarComponent.mainAcc.tabs.forEach(at => at.selected = at.id === this.groupToFocusOnError);
            }
            const htmlElement = document.getElementById(this.fieldToFocusOnError + '_id');
            if (htmlElement) {
                setTimeout(() => {
                    document.getElementById(this.fieldToFocusOnError + '_id').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest'
                    });
                    this.fieldToFocusOnError = undefined;
                    this.groupToFocusOnError = undefined;
                }, 0);
            }
        }
    }

    closeExitWithMessagesDialog() {
        this.saveInProgress = false;
        this.showExitWithMessagesDialog = false;
    }

    confirmExitWithMessages(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.saveItem();
    }

    largeImageGetter(): (itemId: number) => Observable<string> {
        return (itemId: number) => {
            return this.gateSystemService.getImage(itemId);
        };
    }
}
