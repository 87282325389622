import {DrawingUtil, MinMaxXY, Point} from "../drawing-util";
import {FloatOps} from '../utils/float-ops';
import {WindowDesignerInterface} from '../window-designer-interface';
import {PainterMode} from "./PainterMode";
import {PainterParams} from "./PainterParams";
import {ScalingPainter} from "./ScalingPainter";

export class TextBBox {
    constructor(public x: number, public y: number, public x2: number, public y2: number) {
    }

    get w() {
        return this.x2 - this.x;
    }

    get h() {
        return this.y2 - this.y;
    }

    toPoints(): number[] {
        return [this.x, this.y, this.x2, this.y, this.x2, this.y2, this.x, this.y2];
    }
}

export class TextPainter {

    private static readonly THUMBNAIL_FONT_SIZE = 18;
    private static readonly TECHNICAL_FONT_SIZE = 24;

    private offerComponent: WindowDesignerInterface;
    private params: PainterParams;

    constructor(offerComponent: WindowDesignerInterface, params: PainterParams) {
        this.offerComponent = offerComponent;
        this.params = params;
    }

    public static simpleText(svg: Snap.Paper, text: string, position: number[], fontSize: number, centered = false,
        vertical = false): Snap.Element {
        let textElement = svg.text(position[0], position[1], text);
        textElement.attr({
            'font-size': fontSize + 'px'
        });
        if (centered) {
            textElement.attr({
                'baseline-shift': '-50%'
            });
        }
        if (vertical) {
            textElement.transform(Snap.matrix().rotate(-90, position[0], position[1]));
        }
        return textElement;
    }

    public static forDrawing(offerComponent: WindowDesignerInterface, params: PainterParams): TextPainter {
        return new TextPainter(offerComponent, params);
    }

    private static getTextScale(viewbox: MinMaxXY, viewport: DOMRect, useSmallerFont: boolean): number {
        const BIAS = 120;       // %

        const vb_width = viewbox.maxX - viewbox.minX;
        const vb_height = viewbox.maxY - viewbox.minY;
        const v_scale = FloatOps.round(100 * vb_width / viewport.width);
        const h_scale = FloatOps.round(100 * vb_height / viewport.height);

        const scale = Math.max(v_scale, h_scale) + (BIAS / (useSmallerFont ? 2 : 1));

        return scale * 0.01;
    }

    private static getLockedCssClass() {
        return 'locked';
    }

    public paintText(text: string, position: number[], locked = false, vertical = false,
        useSmallerFont = false): Snap.Element {
        position = ScalingPainter.scale(this.params, position);
        let textElement = this.offerComponent.svg.text(position[0], position[1], text);
        if (this.params.isRegularMode() && locked) {
            textElement.addClass(TextPainter.getLockedCssClass());
        }
        if (vertical) {
            textElement.transform(this.offerComponent.createSnapMatrix().rotate(-90, position[0], position[1]));
        }
        textElement.attr({
            'font-size': this.getFontSize(useSmallerFont)
        });
        return textElement;
    }

    public getFontSize(useSmallerFont: boolean): number {
        switch (this.params.mode) {
            case PainterMode.REGULAR:
                const viewport = this.offerComponent.getTotalBoundingClientRect();
                let onePercent = viewport.width * 0.01;
                return onePercent * TextPainter.getTextScale(this.offerComponent.totalBoundingBox, viewport,
                    useSmallerFont);
            case PainterMode.THUMBNAIL:
                return TextPainter.THUMBNAIL_FONT_SIZE / (useSmallerFont ? 2 : 1);
            case PainterMode.TECHNICAL:
                return TextPainter.TECHNICAL_FONT_SIZE / (useSmallerFont ? 2 : 1);
            case PainterMode.WEBSHOP:
                return 0;
            default:
                throw new Error(`getFontSize: unsupported painter mode`);
        }
    }

    public preventOverlapsByMovingUpwards(textElement: Snap.Element, overlapPolygons: number[][],
        params: PainterParams): void {
        let x = +textElement.attr('x');
        let y = +textElement.attr('y');
        const fractionBelowCenter = 0.18151935719503287;
        let textMeasure = this.measureText(textElement);
        let width = textMeasure.x;
        let height = textMeasure.y;
        const makePolygon = (offset: number) => {
            return [x - width / 2, y - height * (1 - fractionBelowCenter) + offset,
                x + width / 2, y - height * (1 - fractionBelowCenter) + offset,
                x + width / 2, y + height * fractionBelowCenter + offset,
                x - width / 2, y + height * fractionBelowCenter + offset];
        };
        let transformYoffset = 0;
        let transformStep = 10 * params.scale;
        let textPolygon = makePolygon(0);
        while (overlapPolygons.some(overlapPolygon => DrawingUtil.doPolygonsOverlap(overlapPolygon, textPolygon))) {
            transformYoffset -= transformStep;
            textPolygon = makePolygon(transformYoffset);
        }
        textElement.attr({y: y + transformYoffset});
    }

    measureText(textElement: Snap.Element, logContextBuilder?: () => string): Point {
        const textScaleFactor = 1.17;
        let fontSizeAttr = textElement.attr('font-size');
        fontSizeAttr = fontSizeAttr === '' ? '1px' : fontSizeAttr;
        const tmp = /^(.*?)(?:px)?$/.exec(fontSizeAttr);
        // TEMPORARY - IMPROVE ERROR LOG
        if (tmp == undefined) {
            const message = `TextPainter.measureText: Could not get font-size value from text element. Text: ${textElement.attr(
                'text')}; font-size: ${fontSizeAttr}; context: ${logContextBuilder != undefined ? logContextBuilder() : '<empty>'}`;
            console.error(message);
            throw new Error(message);
        }
        let fontSize = +tmp[1] * textScaleFactor;
        // We measure text using monospace font, where width = 55 at fontSize = 100
        let width = textElement.node.textContent.length * 55 * fontSize / 100;
        return new Point(width, fontSize);

        /* legacy canvas based method
        // re-use canvas object for better performance
        let canvas = this.offerComponent.getTextCanvas();
        let context = canvas.getContext("2d");
        context.font = fontSize + 'px monospace';
        let measures = context.measureText(textElement.node.textContent);
        return new Point(measures.width, fontSize);
        */
    }

    getBBox(textElement: Snap.Element, logContextBuilder?: () => string): TextBBox {
        // text in svg is kind of odd, its not half height above its "cy" point
        const fractionBelowCenter = 0.18151935719503287;
        const measure = this.measureText(textElement, logContextBuilder);
        const cx = +textElement.attr('x');
        const cy = +textElement.attr('y');
        return new TextBBox(cx - measure.x / 2, cy - measure.y * (1 - fractionBelowCenter), cx + measure.x / 2,
            cy + measure.y * fractionBelowCenter);
    }

    public centerVertically(textElement: Snap.Element): void {
        let yOffsetFromCenter = 5; // temp magic number - seems to work well for Roboto font one-liners
        textElement.attr({y: +textElement.attr('y') + yOffsetFromCenter});
    }
}
