import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {ExchangeService} from '../../../../../common/exchange.service';
import {Currencies} from '../../../../../currencies';
import {OnceFlag} from '../../../../../shared/once-flag';
import {ProductionOrderService} from '../../production-order-service';
import {ProductionOrder} from '../../ProductionOrder';

@Component({
    selector: 'app-production-order-summary-dialog',
    templateUrl: './production-order-summary-dialog.component.html',
    styleUrls: ['./production-order-summary-dialog.component.css', '../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionOrderSummaryDialogComponent implements AfterViewInit {

    @Input()
    productionOrder: ProductionOrder;

    @Input()
    selectedCurrency: Currencies;

    @Output()
    onClose: EventEmitter<any> = new EventEmitter();

    summary: any;
    dataInitialized = false;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private exchangeService: ExchangeService,
                private productionOrderService: ProductionOrderService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.loadOfferSummary();
    }

    private loadOfferSummary() {
        this.productionOrderService.getItem(this.productionOrder.id).subscribe(productionOrder => {
            let summaryItem = {
                buy: productionOrder.buyValue,
                venskaBuy: productionOrder.venskaBuyValue,
                netIncome: productionOrder.netVenskaIncome
            };

            this.summary = [summaryItem];
            this.dataInitialized = true;
            this.changeDetector.markForCheck();
        });
    }

    formatPriceInSelectedCurrency(price: number): string {
        if (this.selectedCurrency === this.productionOrder.offerCurrency) {
            return this.formatPriceInOfferCurrency(price);
        }
        return ExchangeService.formatPriceInCurrency(
            this.exchangeService.getPLNPriceInCurrency(price, this.selectedCurrency), this.selectedCurrency);
    }

    formatPriceInOfferCurrency(price: number): string {
        return ExchangeService.formatPriceInCurrency(
            ExchangeService.getPriceInDefaultCurrency(price, this.productionOrder.offerExchangeRate), this.productionOrder.offerCurrency);
    }

    close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }
}
