import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable} from 'rxjs';
import {ValidationErrors} from '../../../../common/validation-errors';
import {GateDependentOptionField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GateEditorFieldContentProvider} from '../../../offer/gate-editor/gate-editor-field-content-provider';
import {GateDependentOptionFieldUsage} from "../../catalog-field-usage";
import {GateSystem} from '../../gate-system/gate-system';
import {GateDesignerCatalogDependentOptionsSet} from '../gate-designer-catalog-dependent-options-set';

@Component({
    selector: 'app-gate-designer-catalog-dependent-option-form',
    templateUrl: './gate-designer-catalog-dependent-option-form.component.html'
})
export class GateDesignerCatalogDependentOptionFormComponent {

    @Input()
    item: GateDesignerCatalogDependentOptionsSet;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    gateSystems: Observable<GateSystem[]>;

    @Input()
    nextStepsFilled: boolean;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: GateDependentOptionFieldUsage;
    GateDependentOptionField = GateDependentOptionField;

    constructor(private readonly translate: TranslateService,
                private readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider) {
        this.fieldUsage = new GateDependentOptionFieldUsage(this);
    }

    formatGateSystemOption = (gateSystem: GateSystem): SelectItem => {
        return {
            label: gateSystem.name[this.translate.currentLang],
            value: gateSystem.id
        };
    }

    handleGateSystemChange(windowSystemId: number): void {
        this.item.gateSystemId = windowSystemId;
        forkJoin({
            // doesNotWorkWithoutThisDummy: of(`FIX: error TS2339: Property 'windowSystemModel' does not exist on type 'never'.`),
            ...this.gateEditorFieldContentProvider.getCatalogDataSource(windowSystemId, undefined)
        }).subscribe(catalogData => {
            this.gateEditorFieldContentProvider.initCatalog(catalogData);
        });
    }
}
