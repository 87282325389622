import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import * as _ from "underscore";
import {Glazing} from "../../../../window-designer/catalog-data/glazing";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {GlassSelectionValidator} from "../../../common/glass-selection/GlassSelectionValidator";
import {MultilanguageFieldSelectItem} from "../../../common/service/select-item-multilanguage-field-translate.pipe";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GraspGlazingPackageField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ListOfIds} from "../../ListOfIds";
import {GraspGlazingPackageFieldUsage} from "../catalog-field-usage";
import {DistanceFrameService} from "../distance-frame/distance-frame.service";
import {DistanceFrame} from "../distance-frame/distanceFrame";
import {GlassService} from "../glass/glass.service";
import {GlassWithPosition} from "../glass/glassWithPositions";
import {GlazingBead} from "../glazing-bead/glazing-bead";
import {GlazingBeadService} from "../glazing-bead/glazing-bead.service";
import {GraspDistanceFrameCategory} from "../grasp-distance-frame-category/grasp-distance-frame-category";
import {GraspDistanceFrameCategoryService} from "../grasp-distance-frame-category/grasp-distance-frame-category.service";
import {GraspGlazingCategory} from "../grasp-glazing-categories/grasp-glazing-category";
import {GraspGlazingCategoryService} from "../grasp-glazing-categories/grasp-glazing-category.service";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {GraspGlazingPackage} from "./grasp-glazing-package";
import {GraspGlazingPackageService} from "./grasp-glazing-package.service";

@Component({
    selector: 'app-grasp-glazing-package',
    templateUrl: './grasp-glazing-package.component.html',
    providers: [TranslatedSelectItemService, GraspGlazingPackageService, GraspGlazingCategoryService, GraspDistanceFrameCategoryService,
        GlassService, DistanceFrameService, WindowSystemDefinitionService, GlazingBeadService]
})
export class GraspGlazingPackageComponent extends CrudCommonComponent<GraspGlazingPackage, GraspGlazingPackageService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        GLAZING: 'GLAZING',
        SYSTEMS: 'SYSTEMS'
    };

    validateDataStep: WizardStepValidator;
    validateGlazingStep: WizardStepValidator;

    selectedWindowSystems: number[];
    windowSystems: ItemForCatalogLinking[];

    readonly VALIDATORS = {
        DATA: () => this.validateDataStep(),
        GLAZING: () => this.validateGlazingStep()
    };

    @ViewChild('dt')
    dataTable: DatatableInterface;

    glasses: GlassWithPosition[] = [];
    frames: DistanceFrame[] = [];
    availableGlazingBeads: GlazingBead[] = [];
    availableCategories: MultilanguageFieldSelectItem[] = [];
    filterCategories: MultilanguageFieldSelectItem[] = [];
    allCategories: GraspGlazingCategory[] = [];
    availableFrameCategories: MultilanguageFieldSelectItem[] = [];
    filterFrameCategories: MultilanguageFieldSelectItem[] = [];
    allFrameCategories: GraspDistanceFrameCategory[] = [];
    availableQuantities: SelectItem[] = [];

    editPermits: FieldLimitation[] = [];
    fieldUsage: GraspGlazingPackageFieldUsage;
    CatalogTab = CatalogTab;
    GraspGlazingPackageField = GraspGlazingPackageField;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private graspGlazingCategoryService: GraspGlazingCategoryService,
        private graspDistanceFrameCategoryService: GraspDistanceFrameCategoryService,
        private windowSystemDefinitionService: WindowSystemDefinitionService,
        private glassService: GlassService,
        private frameService: DistanceFrameService,
        private glazingBeadService: GlazingBeadService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, GraspGlazingPackageService, 'GRASP_GLAZING_PACKAGE', 'GraspGlazingPackage');
        this.validateDataStep = () => this.validateData();
        this.validateGlazingStep = () => this.validateGlazing();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fieldUsage = new GraspGlazingPackageFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        forkJoin({
            glasses: this.glassService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            frames: this.frameService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            glazingBeads: this.glazingBeadService.getItems(0, 0, {active: {value: 'true'}}, null, null),
            categories: this.graspGlazingCategoryService.getActiveItems(),
            frameCategories: this.graspDistanceFrameCategoryService.getActiveItems(),
            windowSystems: this.windowSystemDefinitionService.getSystemsForCatalogLinking(),
        }).subscribe(data => {
            this.glasses = data.glasses.data;
            this.frames = data.frames.data;
            this.allCategories = data.categories.data;
            this.availableCategories = data.categories.data.map(category => ({ labelKey: category.name, value: category.id}));
            this.filterCategories = [{ labelKey: undefined, value: '' }, ...this.availableCategories];
            this.allFrameCategories = data.frameCategories;
            this.availableFrameCategories = data.frameCategories.map(category => ({ labelKey: category.name, value: category.id}));
            this.filterFrameCategories = [{ labelKey: undefined, value: '' }, ...this.availableFrameCategories];
            this.windowSystems = data.windowSystems;
            this.availableGlazingBeads = data.glazingBeads.data;
        });
        this.availableQuantities = _.range(1, 5).map(number => new SelectItemImpl(number.toString(), number));
        this.availableQuantities.unshift(new SelectItemImpl(undefined, ""));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GRASP_GLAZING_PACKAGES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): GraspGlazingPackage {
        const glazingPackage = new GraspGlazingPackage();
        glazingPackage.glazing = new Glazing();
        return glazingPackage;
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.prepareDataForGlazingPackage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    validateData(): Observable<boolean> {
        const validationErrors = {};

        validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.graspGlazingPackageDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        validationErrors['symbol'] =
            MultiValidator.of('error.graspGlazingPackageDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.symbol);

        validationErrors['glazingCategoryId'] = MultiValidator.of('error.graspGlazingPackageDto.glazingCategoryId')
            .withNotNullValidator()
            .validate(this.item.glazingCategoryId);

        validationErrors['frameCategoryId'] = MultiValidator.of('error.graspGlazingPackageDto.frameCategoryId')
            .withNotNullValidator()
            .validate(this.item.frameCategoryId);

        validationErrors['sortIndex'] = MultiValidator.of('error.graspGlazingPackageDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validateGlazing(): Observable<boolean> {
        const validationErrors = {};

        validationErrors['glassQuantity'] = MultiValidator.of('error.graspGlazingPackageDto.glassQuantity')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 4)
            .validate(this.item.glazing.glazingGlassQuantity);

        if (this.item.glazing) {
            const tempGlazingErrors = new GlassSelectionValidator().validate(this.item.glazing);
            validationErrors['frame1id'] = tempGlazingErrors['frame1id'];
            validationErrors['frame2id'] = tempGlazingErrors['frame2id'];
            validationErrors['frame3id'] = tempGlazingErrors['frame3id'];
            validationErrors['glass1id'] = tempGlazingErrors['glass1id'];
            validationErrors['glass2id'] = tempGlazingErrors['glass2id'];
            validationErrors['glass3id'] = tempGlazingErrors['glass3id'];
            validationErrors['glass4id'] = tempGlazingErrors['glass4id'];
        }

        validationErrors['glazingBeadId'] = MultiValidator.of('error.graspGlazingPackageDto.glazingBeadId')
            .withNotNullValidator()
            .validate(this.item.glazingBeadId);

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.itemService.validateGlazingData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item);
            } else {
                observable = this.itemService.addItem(this.item);
            }

            observable = observable.pipe(mergeMap(glazingPackageId => {
                const ids = new ListOfIds();
                ids.ids = this.selectedWindowSystems;
                return this.itemService.editLinks(glazingPackageId, ids);
            }));

            observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                complete: () => {
                    this.showSuccessMessage();
                    this.hideDetails();
                },
                error: err => {
                    this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                    this.changeDetector.markForCheck();
                }
            });
        }

        this.edit();
    }

    prepareDataForGlazingPackage(glazingPackageId: number) {
        forkJoin({
            glazingPackage: glazingPackageId != undefined ? this.itemService.getItem(glazingPackageId) : of(this.getNewItem()),
            linkedSystems: (glazingPackageId != undefined ? this.itemService.getLinkedSystems(glazingPackageId) : of<number[]>([]))
        }).subscribe({
            next: data => {
                this.newItem = glazingPackageId == undefined;
                this.item = data.glazingPackage;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.file = null;
        this.validationErrors = {};
        this.prepareDataForGlazingPackage(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.prepareDataForGlazingPackage(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.resetFile();
        this.reloadDatatable();
    }

    handleGlazingBeadChange(glazingBeadId: number) {
        this.item.glazingBeadId = glazingBeadId;
        this.changeDetector.markForCheck();
    }
}
