<ng-container *vLet="!permissions.isOperator() as isNotOperator">
    <div class="list-content settings">
        <div class="table-header">
            <div class="title">{{ 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO' | translate }}</div>
        </div>
        <div class="settings-form-wrapper">
            <div class="new-form-600">
                <div class="new-form-row">
                    <h3>{{ "SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO" | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="phoneNumber" [(ngModel)]="subsystemWebshopInfo.phoneNumber"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.PHONE_NUMBER' | translate }}"
                                    [maxlength]="50" [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['phoneNumber']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="phoneNumber2" [(ngModel)]="subsystemWebshopInfo.phoneNumber2"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.PHONE_NUMBER_2' | translate }}"
                                    [maxlength]="50" [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['phoneNumber2']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="email" [(ngModel)]="subsystemWebshopInfo.email"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.EMAIL' | translate }}" [maxlength]="255"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['email']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="cardTitle" [(ngModel)]="subsystemWebshopInfo.cardTitle"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CARD_TITLE' | translate }}"
                                    [maxlength]="100" [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['cardTitle']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="webshopCompanyName" [(ngModel)]="subsystemWebshopInfo.webshopCompanyName"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.WEBSHOP_COMPANY_NAME' | translate }}"
                                    [maxlength]="512" [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['webshopCompanyName']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="companyWebsiteUrl" [(ngModel)]="subsystemWebshopInfo.companyWebsiteUrl"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.COMPANY_WEBSITE_URL' | translate }}"
                                    [maxlength]="1024" [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['companyWebsiteUrl']"></app-input-text>
                </div>
            </div>

            <div class="new-form-600">
                <div class="new-form-row">
                    <app-file-upload inputId="logo" [ngModel]="logo"
                                     label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.LOGO' | translate }}"
                                     (ngModelChange)="handleLogoChange($event)"
                                     [maxHeight]="75 * LOGO_SIZE_MULTIPLIER" [maxWidth]="105 * LOGO_SIZE_MULTIPLIER"
                                     [enableImageCropper]="true"
                                     [addButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.SELECT_LOGO_UPLOAD'"
                                     [changeButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CHANGE_LOGO_UPLOAD'"
                                     [deleteButtonLabel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.DELETE_LOGO_UPLOAD'"
                                     [disabled]="isNotOperator"
                                     [(validationMessageKey)]="validationErrors['logo']"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <h3>{{ "SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ADDRESS" | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.street" [(ngModel)]="subsystemWebshopInfo.street"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.STREET' | translate }}" [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['street']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.city" [(ngModel)]="subsystemWebshopInfo.city"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CITY' | translate }}" [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['city']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.country" [(ngModel)]="subsystemWebshopInfo.country"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.COUNTRY' | translate }}" [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['country']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="adddress.zip" [(ngModel)]="subsystemWebshopInfo.zip"
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ZIP' | translate }}" [maxlength]="100"
                                    [disabled]="isNotOperator"
                                    [(validationMessageKey)]="validationErrors['zip']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <span>{{ 'SUBSYSTEM_PARTNER_WEBSHOP_INFO.PRINT_LOGO_INSTRUCTIONS' | translate }} '<a href="#"
                                                                      (click)="requestNavigationToPrintoutData()">{{ 'SETTINGS.SECTION.PRINTOUT_DATA.GENERAL' | translate }}</a>'</span>
                </div>
            </div>

            <div class="new-form-60vw">
                <div class="new-form-row">
                    <app-text-area inputId="individualPartnerUrl" disabled
                                    label="{{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.INDIVIDUAL_PARTNER_URL' | translate }}"
                                    [ngModel]="'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.INDIVIDUAL_PARTNER_URL_VALUE' | translate: {id: subsystemWebshopInfo.subsystemId}"></app-text-area>
                </div>
            </div>
        </div>

        <div class="settings-footer">
            <div *ngIf="!isNotOperator" class="settings-footer-right">
                <app-simple-button buttonId="saveSubsystemButton" label="{{ 'GENERAL.SAVE' | translate }}" icon="check"
                                   type="main-action" [size]="40" (onClick)="save()"></app-simple-button>
            </div>
        </div>
    </div>
</ng-container>
<app-confirm-action-dialog *ngIf="showUnsavedConfirmationDialog"
                           header="{{ 'SUBSYSTEM_PARTNER_WEBSHOP_INFO.CONFIRM_EXIT_WITHOUT_SAVING.HEADER' | translate }}"
                           question="{{ 'SUBSYSTEM_PARTNER_WEBSHOP_INFO.CONFIRM_EXIT_WITHOUT_SAVING.BODY' | translate }}"
                           (onConfirmed)="navigateToPrintoutData()"
                           (onCancel)="showUnsavedConfirmationDialog = false"></app-confirm-action-dialog>
