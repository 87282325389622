<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'PAYMENT_PACKAGE.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']" [required]="true"
                        [(ngModel)]="paymentPackage.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="transportValue" label="{{ 'PAYMENT_PACKAGE.FORM.TRANSPORT_VALUE' | translate }}"
                          [(validationMessageKey)]="validationErrors['transportValue']" [disabled]="isPaid"
                          [min]="0" [max]="99999" [required]="true" [ngModel]="paymentPackage.transportValue"
                          (ngModelChange)="handleTransportValueChange($event)"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="paymentPackageCorrection"
                          label="{{ 'PAYMENT_PACKAGE.FORM.PAYMENT_PACKAGE_CORRECTION' | translate }}"
                          [(validationMessageKey)]="validationErrors['paymentPackageCorrection']" [disabled]="isPaid"
                          [min]="-999999" [max]="999999"
                          [(ngModel)]="paymentPackage.paymentPackageCorrection"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="completionDate" label="{{ 'PAYMENT_PACKAGE.FORM.COMPLETION_DATE' | translate }}"
                      [(validationMessageKey)]="validationErrors['completionDate']" [utc]="true" [minDate]="today"
                      [required]="true" [(ngModel)]="paymentPackage.completionDate"></app-calendar>
    </div>
    <ng-container *ngIf="showSplitTransportValues">
        <div class="new-form-row">
            <app-input-number label="{{ 'PAYMENT_PACKAGE.FORM.TRANSPORT_EU_PART' | translate }}" disabled
                              [ngModel]="euTransportValue != undefined ? euTransportValue.toFixed(2) : euTransportValue">
            </app-input-number>
        </div>
        <div class="new-form-row">
            <app-input-number label="{{ 'PAYMENT_PACKAGE.FORM.TRANSPORT_NON_EU_PART' | translate }}" disabled
                              [ngModel]="nonEuTransportValue != undefined ? nonEuTransportValue.toFixed(2) : nonEuTransportValue">
            </app-input-number>
        </div>
    </ng-container>
</div>
