import * as _ from 'underscore';
import {ProfileInterface, ProfileType} from '../catalog-data/profile-interface';
import {DrawingData} from '../drawing-data/drawing-data';
import {FillingType} from '../drawing-data/FillingType';
import {SubWindowShape} from '../drawing-data/SubWindowShape';
import {UfElementType} from '../drawing-data/UfElementType';
import {MinMaxXY} from '../drawing-util';
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {UfCalculator, UfDataElement} from '../uf-calculator';

export class UwUtils {

    public static shouldCalculateUw(data: DrawingData) {
        let subwindowsArr = _.flatten(data.windows.map(window => window.subWindows));
        return subwindowsArr.every(subWindow => {
            return subWindow.shape === SubWindowShape.RECTANGULAR &&
                subWindow.areasSpecification.every(area => {
                    return !area.mullionObliqueField
                        && area.rectangularShape
                        && area.filling.type === FillingType.GLASS
                        && area.grills.length === 0;
                });
        });
    }

    public static getFlattenedWindowUfData(data: DrawingData, profileCompositionDistances: ProfilesCompositionDistances,
                                           totalBoundingBox: MinMaxXY, mullions: ProfileInterface[],
                                           isValidationDisabled = false) {
        let subwindowsArr = _.flatten(data.windows.map(window => window.subWindows));
        let ufDataElements = _.flatten(subwindowsArr.map(sub => UfCalculator.calculateUfData(sub, totalBoundingBox,
            profileCompositionDistances, data.cuts, isValidationDisabled)));
        let groupedUfDataElements = _.values(_.groupBy(ufDataElements, item => item.elementType + item.id));
        let reducedUfDataElements = groupedUfDataElements.map(arr => arr.reduce((sum, item) => {
            if (sum == undefined) {
                return item;
            }
            return {
                ...sum,
                length: sum.length + item.length,
                area: sum.area + item.area
            };
        }, null));
        return reducedUfDataElements.map(item => UwUtils.setSymbolInUfDataElementsWithMullion(item, mullions));
    }

    private static setSymbolInUfDataElementsWithMullion = (item: UfDataElement, mullions: ProfileInterface[]) => {
        switch (item.elementType) {
            case UfElementType.CONSTR_MULLION:
            case UfElementType.WING_MULLION:
                return {
                    ...item,
                    symbol: (mullions.find(mullion => mullion.type === ProfileType.CONSTRUCTIONAL_MULLION) || {symbol: undefined}).symbol
                };
            case UfElementType.MULLION:
                return {
                    ...item,
                    symbol: (mullions.find(mullion => mullion.id === item.id) || {symbol: undefined}).symbol
                };
            default:
                return item;
        }
    }
}
