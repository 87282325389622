import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {SupplierService} from "../../../supplier/supplier.service";
import {ConfigSystemService} from "../../../window-system/config-system/config-system.service";
import {getTomorrowDateUTC} from '../../tomorrow-date-helper';
import {Pricesheet, PricesheetCategory} from '../pricesheet';

@Component({
    selector: 'app-pricesheet-form',
    templateUrl: './pricesheet-form.component.html',
    styleUrls: ['../pricesheets.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SupplierService, ConfigSystemService]
})
export class PricesheetFormComponent implements OnInit {

    @Input()
    pricesheet: Pricesheet;

    @Input()
    category: PricesheetCategory;

    @Input()
    types: SelectItem[];

    @Input()
    validationErrors: any;

    @Input()
    file: File;

    @Input()
    canEdit: boolean;

    @Output()
    fileChange = new EventEmitter<File>();

    minValidFromDate: Date;

    notifySelectItems: SelectItem[] = [];

    constructor(private supplierService: SupplierService,
                private configSystemService: ConfigSystemService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.initValidFromDate();
        if (!this.isConfigAddons) {
            this.supplierService.getSuppliers(undefined, undefined, undefined, undefined, undefined).subscribe(res => {
                this.notifySelectItems = res.data.map(supplier => ({
                    label: supplier.companyName,
                    value: supplier.id
                }));
            });
        } else {
            this.configSystemService.getItems(undefined, undefined, undefined, undefined, undefined).subscribe(data => {
                this.notifySelectItems = data.data.map(configSystem => ({
                    label: configSystem.name[this.translate.currentLang],
                    value: configSystem.id
                }));
            });
        }
    }

    get isConfigAddons(): boolean {
        return this.category === PricesheetCategory.CONFIG_ADDONS;
    }

    private initValidFromDate(): void {
        if (this.pricesheet.id == undefined) {
            this.minValidFromDate = getTomorrowDateUTC();
        }
    }
}
