import {ChangeDetectorRef, Directive, Injector, OnInit, Type, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {CrudService} from "../../../common/crud-common/crud.service";
import {DealerDiscount} from "./dealer-discount";

export enum DealerDiscountType {
    PER_SYSTEM,
    PER_SUPPLIER,
    PER_CONFIGURABLE_ADDON
}

@Directive()
export abstract class DealerDiscountComponent<Item extends DealerDiscount, Service extends CrudService<Item>>
    extends CrudCommonComponent<Item, Service> implements OnInit {

    @ViewChild('dt') datatable;

    mode: DealerDiscountType;

    DealerDiscountType = DealerDiscountType;

    protected constructor(injector: Injector,
                          changeDetector: ChangeDetectorRef,
                          serviceType: Type<Service>,
                          entityName: string,
                          mode: DealerDiscountType) {
        super(injector, changeDetector, false, serviceType, 'DEALER_DISCOUNTS', entityName);
        this.mode = mode;
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    protected afterSuccessLoad() {
        for (let item of this.itemList) {
            let dealerDiscount = <DealerDiscount>item;
            dealerDiscount.validFrom = new Date(<any>dealerDiscount.validFrom);
        }
    }

    protected afterDialogOpen() {
        setTimeout(() => {
            this.changeDetector.markForCheck();
        });
    }

    onRowSelect(event) {
        if (event.data.isCurrent) {
            this.growlMessageController.error('DEALER_DISCOUNTS.CANNOT_EDIT_ACTIVE');
        } else {
            super.onRowSelect(event);
        }
    }

    changeActiveStatus(discount, active: boolean) {
        discount.active = active;
        this.itemId = discount.id;
        this.item = discount;
        this.edit();
    }

    abstract getTargetOptions(): SelectItem[];

    getTableHeader(): string {
        switch (this.mode) {
            case DealerDiscountType.PER_SYSTEM:
                return 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.SYSTEMS.MENU';
            case DealerDiscountType.PER_SUPPLIER:
                return 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.SUPPLIERS.MENU';
            case DealerDiscountType.PER_CONFIGURABLE_ADDON:
                return 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.CONFIGURABLE_ADDONS.MENU';
            default:
                break;
        }
        return undefined;
    }

    getTargetIdField(): 'windowSystemId' | 'supplierId' | 'systemId' {
        switch (this.mode) {
            case DealerDiscountType.PER_SYSTEM:
                return 'windowSystemId';
            case DealerDiscountType.PER_SUPPLIER:
                return 'supplierId';
            case DealerDiscountType.PER_CONFIGURABLE_ADDON:
                return 'systemId';
            default:
                break;
        }
        return undefined;
    }

    getTargetLabel(): string {
        switch (this.mode) {
            case DealerDiscountType.PER_SYSTEM:
                return 'DEALER_DISCOUNTS.FORM.SYSTEM-NAME';
            case DealerDiscountType.PER_SUPPLIER:
                return 'SUPPLIER-DETAILS.FORM.COMPANY-NAME';
            case DealerDiscountType.PER_CONFIGURABLE_ADDON:
                return 'CONFIGURABLE_ADDONS.DEFINITION_TYPE';
            default:
                break;
        }
        return undefined;
    }
}
