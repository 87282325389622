import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, mergeMap, tap} from 'rxjs/operators';
import {MapToJsonTransformer} from "../../../shared/map-to-json-transformer";
import {PrintConfig} from "../../features/offer/print-dialog/print-config";
import {PrintDialogTab} from "../../features/offer/print-dialog/print-dialog-tab";
import {PrintableSection} from '../../features/offer/print-dialog/printable-section.enum';
import {StorageKey, StorageService} from '../storage.service';
import {GlobalUiConfig, UserUiConfig} from './userUiConfig';

@Injectable()
export class UserUiConfigService implements OnDestroy {

    private static readonly SERVICE_LINK: string = 'useruiconfig';
    private static readonly PRINT_SETTINGS_KEY: string = 'printSettings';
    private static readonly PRINT_SETTINGS_LANGUAGES_KEY: string = 'printSettingsLanguages';
    private static readonly GLOBAL_SETTINGS_KEY = 'globalSettings';

    private remoteSaveEvents = new Subject<UserUiConfig>();

    constructor(private http: HttpClient,
                private storageService: StorageService) {
        this.remoteSaveEvents.pipe(
            debounceTime(1000),
            distinctUntilChanged((x, y) => x.config === y.config),
            mergeMap(userUiConfig => this.http.post<void>(UserUiConfigService.SERVICE_LINK, userUiConfig).pipe(
                tap({
                    next: () => this.storageService.set(StorageKey.UI_CONFIG, JSON.stringify(userUiConfig)),
                    error: error => console.error('Error while saving user UI config: ', error)
                }),
                catchError(() => of(undefined))))
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.remoteSaveEvents.complete();
    }

    public getConfigForTheView(viewName: string, key: string): any {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        let config = userUiConfig.config;
        if (!config) {
            return null;
        }
        let configForThisView = config[viewName];
        if (!configForThisView) {
            return null;
        }
        return configForThisView[key];
    }

    public getPrintConfig(printableSection: PrintableSection): Map<PrintDialogTab, PrintConfig> {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        let config = userUiConfig.config;
        if (!config) {
            return null;
        }
        let printSettings = config[UserUiConfigService.PRINT_SETTINGS_KEY];
        if (!printSettings) {
            return null;
        }
        return JSON.parse(printSettings[printableSection], MapToJsonTransformer.reviver);
    }

    public getGlobalSetting<TKey extends keyof GlobalUiConfig>(key: TKey): GlobalUiConfig[TKey] {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        let config = userUiConfig.config;
        if (!config) {
            return null;
        }
        let globalConfig: GlobalUiConfig = config[UserUiConfigService.GLOBAL_SETTINGS_KEY];
        if (!globalConfig) {
            return null;
        }
        return globalConfig[key];
    }

    public saveConfigForTheView(viewName: string, key: string, value: any): void {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        if (!userUiConfig.config) {
            userUiConfig.config = {};
        }
        if (!userUiConfig.config[viewName]) {
            userUiConfig.config[viewName] = {};
        }
        if (userUiConfig.config[viewName][key] === value) {
            return; // we don't want to call the remote server when the value doesn't change
        }
        userUiConfig.config[viewName][key] = value;
        this.saveUserUiConfig(userUiConfig);
    }

    public savePrintConfig(printableSection: PrintableSection, printConfigs: Map<PrintDialogTab, PrintConfig>, documentTypeKey?: string, language?: string): void {
        let transformedConfigs = JSON.stringify(printConfigs, MapToJsonTransformer.replacer);

        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        if (!userUiConfig.config) {
            userUiConfig.config = {};
        }
        if (!userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_KEY]) {
            userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_KEY] = {};
        }
        if (!userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_LANGUAGES_KEY]) {
            userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_LANGUAGES_KEY] = {};
        }
        if (userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_KEY][printableSection] === transformedConfigs
            && userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_LANGUAGES_KEY][documentTypeKey] === language) {
            return;
        }
        if (documentTypeKey && language) {
            userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_LANGUAGES_KEY][documentTypeKey] = language;
        }
        userUiConfig.config[UserUiConfigService.PRINT_SETTINGS_KEY][printableSection] = transformedConfigs;
        this.saveUserUiConfig(userUiConfig);
    }

    public saveGlobalSetting<TKey extends keyof GlobalUiConfig>(key: TKey, value: GlobalUiConfig[TKey]): void {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        if (!userUiConfig.config) {
            userUiConfig.config = {};
        }
        if (!userUiConfig.config[UserUiConfigService.GLOBAL_SETTINGS_KEY]) {
            userUiConfig.config[UserUiConfigService.GLOBAL_SETTINGS_KEY] = {};
        }
        if (userUiConfig.config[UserUiConfigService.GLOBAL_SETTINGS_KEY][key] === value) {
            return; // we don't want to call the remote server when the value doesn't change
        }
        userUiConfig.config[UserUiConfigService.GLOBAL_SETTINGS_KEY][key] = value;
        this.saveUserUiConfig(userUiConfig);
    }

    private getUserUiConfig(): UserUiConfig {
        let userUiConfig: UserUiConfig = JSON.parse(this.storageService.get(StorageKey.UI_CONFIG));
        if (typeof userUiConfig.config === 'string') {
            userUiConfig.config = JSON.parse(userUiConfig.config);
        }
        return userUiConfig;
    }

    private saveUserUiConfig(userUiConfig: UserUiConfig): void {
        this.remoteSaveUserUiConfig(userUiConfig);
    }

    public remoteFetchUserUiConfig(): Observable<string> {
        return this.http.get(UserUiConfigService.SERVICE_LINK, {responseType: 'text'});
    }

    public storeUserUiConfig(uiConfig: string): void {
        this.storageService.set(StorageKey.UI_CONFIG, uiConfig);
    }

    private remoteSaveUserUiConfig(userUiConfig: UserUiConfig): void {
        userUiConfig.config = JSON.stringify(userUiConfig.config);
        this.remoteSaveEvents.next(userUiConfig);
    }

    public getPrintLanguages(): Map<string, string> {
        let userUiConfig: UserUiConfig = this.getUserUiConfig();
        let config = userUiConfig.config;
        if (!config) {
            return null;
        }
        return config[UserUiConfigService.PRINT_SETTINGS_LANGUAGES_KEY];
    }
}
