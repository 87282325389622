import {Component} from '@angular/core';

@Component({
    selector: 'app-maintenance-page',
    templateUrl: './maintenance-page.component.html',
    styleUrls: ['./maintenance-page.component.css']
})
export class MaintenancePageComponent {

    constructor() {
    }

    getErrorImageUrl(): string {
        return 'assets/images/maintenance.png';
    }
}
