<p-dialog [resizable]="false" [modal]="true" (onHide)="close()" [visible]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.COMPARE' | translate }}
        </div>
    </p-header>
    <div class="new-form-60vw">
        <app-window-properties [properties]="properties" [positionType]="positionType" [showProperties]="true">
        </app-window-properties>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'OFFER.ACTIONS.DIALOGS.BUTTON_CLOSE' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
