import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Observable, of, Subscription} from 'rxjs';
import * as _ from 'underscore';
import {DoorSide} from "../../../../../../window-designer/catalog-data/window-system-interface";
import {ConfigurableAddon} from '../../../../../../window-designer/entities/ConfigurableAddon';
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {AddonsService} from "../../../../window-system/addons/addons.service";
import {EntranceModelService} from "../../../../window-system/entrance-model/entrance-model.service";
import {GateSystemService} from '../../../../window-system/gate-system/gate-system.service';
import {
    WindowSystemDefinitionService
} from "../../../../window-system/window-system-definition/window-system-definition.service";
import {AbstractPosition, PositionTarget, PositionType} from '../../../AbstractPosition';
import {GateData} from '../../../gate-editor/gate-data';
import {
    ProductionOrdersPositionService
} from '../../../production-orders/production-orders-position/production-orders-position.service';
import {EntranceDoorData} from '../../../window-editor/roof-window-editor/entrance-door-data';
import {RoofWindowData} from "../../../window-editor/roof-window-editor/RoofWindowData";
import {ConfigurableAddonsService} from '../config-addons.service';
import {BulkAddonData} from "../position-list/add-bulk-addon-position/bulk-addon-data";
import {PositionService} from "../position.service";
import {ConfigSystemService} from "../../../../window-system/config-system/config-system.service";

@Component({
    selector: 'app-picture',
    templateUrl: './picture.component.html',
    styleUrls: ['./picture.component.css', '../../../../shared-styles.css',
        '../../../window-editor/common/designer-length-unit-hider.directive.css'],
    providers: [PositionService, ProductionOrdersPositionService, AddonsService, DataServiceHelper,
        ConfigurableAddonsService, ConfigSystemService, WindowSystemDefinitionService, CommonErrorHandler, EntranceModelService, GateSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PictureComponent {

    static readonly noImagePlaceholder = 'assets/images/no-image-placeholder.png';

    @Output() pictureClicked: EventEmitter<AbstractPosition> = new EventEmitter<AbstractPosition>();

    imagePreview: any;
    imageFlipped: boolean;
    private previewedPosition: AbstractPosition;
    private imageLoadSubscription?: Subscription;
    private readonly updatePositionPictureThrottled: (selectedPosition: AbstractPosition, target: PositionTarget) => void;

    constructor(private positionService: PositionService,
                private productionOrdersPositionService: ProductionOrdersPositionService,
                private addonService: AddonsService,
                private configAddonService: ConfigurableAddonsService,
                private configSystemService: ConfigSystemService,
                private windowSystemDefinitionService: WindowSystemDefinitionService,
                private entranceModelService: EntranceModelService,
                private gateSystemService: GateSystemService,
                private sanitizer: DomSanitizer,
                private element: ElementRef<Element>,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        this.updatePositionPictureThrottled = _.throttle((selectedPosition, target) => {
            this.updatePositionPictureImmediate(selectedPosition, target);
        }, 500);
    }

    updatePositionPicture(selectedPosition: AbstractPosition, target: PositionTarget) {
        this.updatePositionPictureThrottled(selectedPosition, target);
    }

    private updatePositionPictureImmediate(selectedPosition: AbstractPosition, target: PositionTarget) {
        if (this.previewedPosition === selectedPosition) {
            return;
        }
        this.previewedPosition = selectedPosition;
        this.clearPreview();
        if (selectedPosition && selectedPosition.id) {
            let observable: Observable<string>;
            switch (selectedPosition.type) {
                case PositionType.SYSTEM:
                case PositionType.STANDALONE_GLAZING_PACKAGE:
                    observable = target === PositionTarget.PRODUCTION_ORDER ?
                        this.productionOrdersPositionService.getPreview(selectedPosition.id) :
                        this.positionService.getPreview(selectedPosition.id);
                    break;
                case PositionType.BULK_ADDON:
                    let positionData: BulkAddonData = JSON.parse(selectedPosition.data);
                    observable = this.addonService.getImageForItem(positionData.addonId);
                    break;
                case PositionType.CONFIGURABLE_ADDON:
                    let configData: ConfigurableAddon = JSON.parse(selectedPosition.data);
                    observable = this.configAddonService.getImageForItem(configData.definitionId);
                    break;
                case PositionType.CONFIG_SYSTEM:
                    let configDataNew: ConfigurableAddon = JSON.parse(selectedPosition.data);
                    observable = this.configSystemService.getImage(configDataNew.definitionId);
                    break;
                case PositionType.ROOF_SYSTEM:
                    let roofWindowData: RoofWindowData = JSON.parse(selectedPosition.data);
                    observable = this.windowSystemDefinitionService.getWindowEditorImage(roofWindowData.systemId);
                    break;
                case PositionType.ENTRANCE_DOOR_SYSTEM:
                    let entranceDoorData: EntranceDoorData = JSON.parse(selectedPosition.data);
                    observable = this.entranceModelService.getWindowEditorImage(entranceDoorData);
                    this.imageFlipped = entranceDoorData.side === DoorSide.LEFT;
                    break;
                case PositionType.GATE_SYSTEM:
                    let gateData: GateData = JSON.parse(selectedPosition.data);
                    observable = this.gateSystemService.getImage(gateData.gateSystemId);
                    break;
                default:
                    observable = of<string>(undefined);
                    break;
            }

            this.imageLoadSubscription = observable.subscribe({
                next: data => {
                    if (data) {
                        if (selectedPosition.type === PositionType.SYSTEM || selectedPosition.type === PositionType.STANDALONE_GLAZING_PACKAGE) {
                            let container = this.element.nativeElement.querySelector('#preview-container');
                            let fragment = Snap.parse(data);
                            container.appendChild(fragment.node);
                        } else {
                            this.setImagePreview(this.sanitizer.bypassSecurityTrustUrl(data));
                        }
                    } else {
                        this.setImagePreview(PictureComponent.noImagePlaceholder);
                    }
                },
                error: errorMessage => {
                    this.errors.handle(errorMessage);
                    this.setImagePreview(PictureComponent.noImagePlaceholder);
                }
            });
        }
    }

    private clearPreview(): void {
        let svg = this.element.nativeElement.querySelector('svg');
        if (svg != undefined) {
            svg.parentElement.removeChild(svg);
        }
        this.imagePreview = undefined;
        if (this.imageLoadSubscription != undefined) {
            this.imageLoadSubscription.unsubscribe();
            this.imageLoadSubscription = undefined;
        }
        this.imageFlipped = false;
        this.changeDetector.markForCheck();
    }

    private setImagePreview(image: any): void {
        this.imagePreview = image;
        this.changeDetector.markForCheck();
    }

    emitPictureClickedEvent(): void {
        if (this.previewedPosition != undefined) {
            this.pictureClicked.emit(this.previewedPosition);
        }
    }
}
