export class FieldWithPosition {
    positions: number[] = [];
    grillSegmentIds: number[] = [];

    constructor(positions: number[],
                grillSegmentIds?: number[]) {
        this.positions = positions;
        this.grillSegmentIds = grillSegmentIds || [];
    }
}
