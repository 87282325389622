import {AddonCategoryEnumGroup} from "../../../window-designer/enums/AddonCategoryEnum";
import {GrillTypes} from "../../../window-designer/enums/GrillTypes";
import {ColorType} from "../../ColorType";
import {
    AddonCategoryField,
    AddonCategoryGroupField,
    AddonField,
    BusinessTypeField,
    CatalogTab,
    ColorField,
    ConfigAddonField,
    ConfigDependentOptionField,
    ConfigDependentOptionTab,
    ConfigSystemField,
    DecorativeFillingField,
    DimensionsField,
    DistanceFrameField,
    EntranceGlazingPackageField,
    EntranceModelField,
    GateDependentOptionField,
    GateDependentOptionTab,
    GatePanelTypeField,
    GateSystemField,
    GateWallField,
    GlassField,
    GlazingBeadField,
    GraspDistanceFrameCategoryField,
    GraspGlazingCategoryField,
    GraspGlazingPackageField,
    GrillField,
    MaterialField,
    OtherFillingField,
    ProfileField,
    RackField,
    RailSystemField,
    RoofGlazingPackageField,
    RoofSystemField,
    SealField,
    SubwindowTypeField,
    SystemGlazingPackageField,
    WindowDependentOptionField,
    WindowDependentOptionTab,
    WindowSystemBasicField,
    WindowSystemField,
    WindowSystemTab
} from "../admin-panel/edit-catalog-permits/catalog-field.enum";
import {CatalogEditLimitation} from "../admin-panel/edit-catalog-permits/edit-catalog-permits.component";
import {FieldLimitation} from "../admin-panel/edit-catalog-permits/field-limitation";
import {AddonCategoryGroupComponent} from "./addon-category-group/addon-category-group.component";
import {AddonCategoryGroupFormComponent} from "./addon-category-group/form/addon-category-group-form.component";
import {AddonCategoryComponent} from "./addon-category/addon-category.component";
import {AddonCategoryFormComponent} from "./addon-category/form/addon-category-form.component";
import {Addon} from "./addons/addon";
import {BusinessTypeComponent} from "./business-type/business-type.component";
import {BusinessTypeFormComponent} from "./business-type/form/business-type-form.component";
import {AddonHelperInterface, ColorHelperInterface, WindowDependentOptionHelperInterface} from "./catalog-field-usage-helper-interfaces";
import {ConfigAddonsComponent} from "./config-addons/config-addons.component";
import {
    ConfigDesignerCatalogDependentOptionsSetComponent
} from "./config-designer-catalog-dependent-option/config-designer-catalog-dependent-options-set.component";
import {
    ConfigDesignerCatalogDependentOptionFormComponent
} from "./config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option-form.component";
import {ConfigSystemFormComponent} from "./config-system/config-system-form/config-system-form.component";
import {ConfigSystemComponent} from "./config-system/config-system.component";
import {DecorativeFillingFormComponent} from "./decorative-filling/decorative-filling-form/decorative-filling-form.component";
import {DecorativeFillingComponent} from "./decorative-filling/decorative-filling.component";
import {DistanceFrameFormComponent} from "./distance-frame/distance-frame-form/distance-frame-form.component";
import {DistanceFrameComponent} from "./distance-frame/distance-frame.component";
import {EntranceGlazingPackageComponent} from "./entrance-glazing-package/entrance-glazing-package.component";
import {EntranceGlazingPackageFormComponent} from "./entrance-glazing-package/general-data-form/entrance-glazing-package-form.component";
import {EntranceModelFormComponent} from "./entrance-model/entrance-model-form/entrance-model-form.component";
import {EntranceModelComponent} from "./entrance-model/entrance-model.component";
import {
    GateDesignerCatalogDependentOptionFormComponent
} from "./gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option-form.component";
import {
    GateDesignerCatalogDependentOptionsSetComponent
} from "./gate-designer-catalog-dependent-option/gate-designer-catalog-dependent-options-set.component";
import {GatePanelTypeFormComponent} from "./gate-panel-type/form/gate-panel-type-form.component";
import {GatePanelTypeComponent} from "./gate-panel-type/gate-panel-type.component";
import {GateSystemFormComponent} from "./gate-system/gate-system-form/gate-system-form.component";
import {GateSystemComponent} from "./gate-system/gate-system.component";
import {GateWallFormComponent} from "./gate-wall/gate-wall-form/gate-wall-form.component";
import {GateWallComponent} from "./gate-wall/gate-wall.component";
import {GlassFormComponent} from "./glass/glass-form/glass-form.component";
import {GlassComponent} from "./glass/glass.component";
import {GlazingBeadFormComponent} from "./glazing-bead/glazing-bead-form/glazing-bead-form.component";
import {GlazingBeadComponent} from "./glazing-bead/glazing-bead.component";
import {GlazingPackage} from "./glazing-package/glazing-package";
import {
    GlazingPackageGeneralDataFormComponent
} from "./glazing-package/glazing-package-general-data-form/glazing-package-general-data-form.component";
import {GlazingPackageComponent} from "./glazing-package/glazing-package.component";
import {WebshopGlazingPackageComponent} from "./glazing-package/webshop-glazing-package/webshop-glazing-package.component";
import {GraspDistanceFrameCategoryFormComponent} from "./grasp-distance-frame-category/grasp-distance-frame-category-form.component";
import {GraspDistanceFrameCategoryComponent} from "./grasp-distance-frame-category/grasp-distance-frame-category.component";
import {
    GraspGlazingCategoriesFormComponent
} from "./grasp-glazing-categories/grasp-glazing-categories-form/grasp-glazing-categories-form.component";
import {GraspGlazingCategoriesComponent} from "./grasp-glazing-categories/grasp-glazing-categories.component";
import {GraspGlazingPackageFormComponent} from "./grasp-glazing-package/grasp-glazing-package-form/grasp-glazing-package-form.component";
import {GraspGlazingPackageComponent} from "./grasp-glazing-package/grasp-glazing-package.component";
import {GrillFormComponent} from "./grill/grill-form/grill-form.component";
import {GrillComponent} from "./grill/grill.component";
import {MaterialFormComponent} from "./material/material-form/material-form.component";
import {MaterialComponent} from "./material/material.component";
import {OtherFillingFormComponent} from "./other-filling/other-filling-form/other-filling-form.component";
import {OtherFillingComponent} from "./other-filling/other-filling.component";
import {ProfileFormComponent} from "./profile/profile-form/profile-form.component";
import {ProfileComponent} from "./profile/profile.component";
import {RackFormComponent} from "./rack/form/rack-form.component";
import {RackComponent} from "./rack/rack.component";
import {RailSystemFormComponent} from "./rail-system/rail-system-form/rail-system-form.component";
import {RailSystemComponent} from "./rail-system/rail-system.component";
import {RoofGlazingPackageFormComponent} from "./roof-glazing-package/roof-glazing-package-form/roof-glazing-package-form.component";
import {RoofGlazingPackageComponent} from "./roof-glazing-package/roof-glazing-package.component";
import {SealFormComponent} from "./seal/seal-form/seal-form.component";
import {SealComponent} from "./seal/seal.component";
import {SubwindowTypeFormComponent} from "./subwindow-type/form/subwindow-type-form.component";
import {SubwindowTypeComponent} from "./subwindow-type/subwindow-type.component";
import {WindowDimensionsFormComponent} from "./window-dimensions/window-dimensions-form/window-dimensions-form.component";
import {WindowDimensionsComponent} from "./window-dimensions/window-dimensions.component";
import {
    WindowSystemDefinitionGeneralFormComponent
} from "./window-system-definition/window-system-definition-general-form/window-system-definition-general-form.component";
import {WindowSystemDefinitionComponent} from "./window-system-definition/window-system-definition.component";

function getPermitsForField(permits: FieldLimitation[], field: string): FieldLimitation {
    return permits ? permits.find(editPermits => editPermits.fieldName === field) : null;
}

export class AddonFieldUsage {

    constructor(private component: AddonHelperInterface) {
    }

    public show(field: CatalogTab | AddonField, addon?: Addon): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;

        switch (field) {
            case AddonField.SYMBOL:
                return this.component.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}) && showField;
            case AddonField.AVAILABLE_IN:
                return this.component.addon.addonFor !== 'BULK'
                    && this.component.filteredAvailableInOptions.length > 0
                    && this.component.permissions.isKoordynator() && showField
                    && !this.component.isConfigAddon();
            case AddonField.PRICE_TYPE:
                return this.component.permissions.isKoordynator() && showField && !this.component.isConfigAddon();
            case AddonField.VENSKA_SELL_NET_PRICE:
                return this.component.canHavePrice() && this.component.addon.price.type === 'RIGID_PRICE' && showField;
            case AddonField.PRICE:
                return this.component.canHavePrice() && showField && !this.component.isConfigAddon();
            case AddonField.COLOR_CHANGE:
                return this.component.permissions.isKoordynator() && this.component.addon.addonFor === 'BULK'
                    && !this.component.isGateAddon() && showField;
            case AddonField.SORT_INDEX:
                return this.component.addon.id != null && this.component.permissions.isKoordynator() && showField;
            case AddonField.PROFIT_MARGIN_WINDOW_SYSTEM:
                return this.component.permissions.isKoordynator()
                    && this.component.isWindowAddon() && showField;
            case AddonField.CUSTOM_VALUE:
            case AddonField.CUSTOM_STRING_VALUE:
                return this.component.permissions.isKoordynator()
                    && this.component.showCustomValueField() && showField;
            case AddonField.MOTLAWA_INTEGRATION:
                return this.component.permissions.isKoordynator() && showField && !this.component.isConfigAddon();
            case CatalogTab.LINKED_SYSTEMS:
                return addon.addonFor !== 'BULK' && showField;
            case AddonField.DEFAULT_QUANTITY:
            case AddonField.REMOVABLE:
            case AddonField.QUANTITY_CHANGE:
                return this.component.addon.category !== this.component.gateSidebarCategory && showField;
            case AddonField.COLORS_SELECTION:
                return this.component.permissions.isKoordynator() && this.component.addon.addonFor === 'BULK' && showField;
            case AddonField.SHOW_GATE_DISCLAIMER:
                return AddonCategoryEnumGroup.Gate.addonCategories.map(cat => cat.toString()).includes(this.component.addon.category) && showField;
            case AddonField.UNIT_WEIGHT:
                return showField && (this.component.permissions.isKoordynator() || this.component.permissions.isOpiekun());
            case AddonField.CATEGORY:
                return !this.component.isConfigAddon();
            case AddonField.PRINT_DATA:
                return !this.component.isConfigAddon();
            case AddonField.AUTO_OPTION:
            case AddonField.TILE_DATA:
                return this.component.isConfigAddon();
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | AddonField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case AddonField.DEFAULT_QUANTITY:
                return !this.component.isWindowAddon()
                    && this.component.isWingFunctionsGroup() || disabledField;
            case AddonField.REMOVABLE:
                return this.component.isRemovableDisabled() || disabledField;
            case AddonField.QUANTITY_CHANGE:
                return this.component.isChangeQuantityDisabled() || disabledField;
            case AddonField.PRICE_TYPE:
                return this.component.isWingFunctionsGroup() || disabledField;
            case AddonField.COLOR_CHANGE:
                return (this.component.addon.addonFor === 'BULK' && this.component.addon.price.type === 'PRICE_GROUP')
                    || disabledField;
            case AddonField.PROFIT_MARGIN_WINDOW_SYSTEM:
                return this.component.addon.supplier == undefined
                    || this.component.isWindowSystemSelectionDisabled()
                    || disabledField;
            case AddonField.COLORS_SELECTION:
                return !this.component.addon.chooseColor || disabledField;
            default:
                return disabledField;
        }
    }
}

export class AddonCategoryGroupFieldUsage {

    constructor(private component: AddonCategoryGroupComponent | AddonCategoryGroupFormComponent) {
    }

    public show(field: AddonCategoryGroupField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case AddonCategoryGroupField.SORT_INDEX:
                return this.component.item.id != null && showField;
            default:
                return showField;
        }
    }

    public disabled(field: AddonCategoryGroupField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class AddonCategoryFieldUsage {

    constructor(private component: AddonCategoryComponent | AddonCategoryFormComponent) {
    }

    public show(field: CatalogTab | AddonCategoryField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case AddonCategoryField.SORT_INDEX:
                return this.component.item.id != null && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | AddonCategoryField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;
        switch (field) {
            case AddonCategoryField.SYMBOL:
                return this.component.item.id != null || disabledField;
            default:
                return disabledField;
        }
    }
}

export class ConfigAddonFieldUsage {

    constructor(private component: ConfigAddonsComponent) {
    }

    public show(field: CatalogTab | ConfigAddonField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case ConfigAddonField.SUPPLIER:
               return this.component.suppliers && this.component.definition && showField;
            case ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL:
            case ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING:
            case ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING:
            case ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING:
            case ConfigAddonField.ACTIVE:
                return this.component.definition && showField;
            case ConfigAddonField.APPLICABLE:
            case ConfigAddonField.MESSAGES:
                return !this.component.definition.isOtherSystem && showField;
            case ConfigAddonField.MOTLAWA_INTEGRATION:
                return this.component.permissions.isKoordynator() && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | ConfigAddonField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case ConfigAddonField.APPLICABLE:
                return (this.component.definition.disabledApplicableTo || this.component.readOnlyMode) || disabledField;
            case ConfigAddonField.MESSAGES:
                return this.component.definition.isOtherSystem || disabledField;
            default:
                return this.component.readOnlyMode || disabledField;

        }
    }
}

export class ColorFieldUsage {

    constructor(private component: ColorHelperInterface) {
    }

    public show(field: CatalogTab | ColorField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case ColorField.RAL_HEX:
               return this.component.isPaletteCustom()
                   && this.component.item.type !== ColorType.GATE_CORE && showField;
            case ColorField.IMAGE_SECTION:
                return this.component.item.type !== ColorType.ADDON_MATERIAL && showField;
            case ColorField.CORE:
                return this.component.item.type !== ColorType.ADDON_MATERIAL
                    && this.component.item.type !== ColorType.GATE_CORE && showField;
            case ColorField.SORT_INDEX:
                return this.component.item.id != null && showField;
            case ColorField.WEBSHOP:
                return this.component.item.type !== ColorType.ADDON_MATERIAL
                    && this.component.item.type !== ColorType.GATE_CORE && showField;
            case ColorField.WEBSHOP_HEX:
                return this.component.isWebshopHexSelected() && showField;
            case ColorField.WEBSHOP_TEXTURE:
                return this.component.isTextureSelected() && showField;
            case ColorField.GATE_HEX:
                return this.component.item.type === ColorType.GATE_CORE && showField;
            case CatalogTab.COLOR_USAGE:
            case CatalogTab.SYSTEM_GROUP:
                return !this.component.hasConfigSystemLinks();
            case CatalogTab.LINKED_SYSTEMS:
                return (this.component.hasWindowSystemLinks() || this.component.hasGateSystemLinks() || this.component.hasConfigSystemLinks()) && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | ColorField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case ColorField.CORE:
                return this.component.shouldDisableCoreCheckbox() || disabledField;
            case ColorField.INSIDE:
            case ColorField.OUTSIDE:
            case ColorField.GRILL:
                return this.component.shouldForceCoreSelection()
                    || this.component.item.type === 'TERRACE'
                    || disabledField;
            case ColorField.MUST_BE_COVERED:
            case ColorField.CANNOT_BE_COVERED:
                return !this.component.item.core || disabledField;
            case ColorField.GRILL_MATCHING_COLOR:
                return this.component.item.type === 'TERRACE' || disabledField;
            case ColorField.WEBSHOP_HEX:
                return this.component.isPaletteCustom() || disabledField;
            default:
                return disabledField;

        }
    }
}

export class GlazingBeadFieldUsage {

    constructor(private component: GlazingBeadComponent | GlazingBeadFormComponent) {
    }

    public show(field: CatalogTab | GlazingBeadField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GlazingBeadField.SORT_INDEX:
               return this.component.item.id != null && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GlazingBeadField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class MaterialFieldUsage {

    constructor(private component: MaterialComponent | MaterialFormComponent) {
    }

    public show(field: CatalogTab | MaterialField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case MaterialField.SORT_INDEX:
               return this.component.item != null && this.component.item.id != null && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | MaterialField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class WindowDependentOptionFieldUsage {

    constructor(private component: WindowDependentOptionHelperInterface) {
    }

    public show(field: WindowDependentOptionTab | WindowDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case WindowDependentOptionField.SORT_INDEX:
               return this.component.item.id != null && showField;
            default:
                return showField;
        }
    }

    public disabled(field: WindowDependentOptionTab | WindowDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case WindowDependentOptionField.SYSTEM:
                return this.component.item.id != null || disabledField || this.component.nextStepsFilled;
            default:
                return disabledField;

        }
    }
}

export class GlazingPackageFieldUsage {

    constructor(private component: GlazingPackageComponent | WebshopGlazingPackageComponent | GlazingPackageGeneralDataFormComponent) {
    }

    public show(field: CatalogTab | SystemGlazingPackageField, glazingPackage?: GlazingPackage): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case SystemGlazingPackageField.WEBSHOP_DESCRIPTION:
            case SystemGlazingPackageField.IMAGE_SECTION:
               return this.component.isWebshopGlazingPackage(glazingPackage) && showField;
            case SystemGlazingPackageField.SORT_INDEX:
               return this.component.item.id != null && showField;
            case CatalogTab.LINKED_SYSTEMS:
                return this.component.target !== this.component.glazingPackageTargets.SYSTEM_DEFINITION
                    && this.component.target !== this.component.glazingPackageTargets.DECORATIVE_GLAZING && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | SystemGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            default:
                return disabledField;

        }
    }
}

export class ProfileFieldUsage {

    constructor(private component: ProfileComponent | ProfileFormComponent) {
    }

    public show(field: CatalogTab | ProfileField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case ProfileField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | ProfileField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case ProfileField.ACTIVE:
                return !this.component.permissions.isKoordynator() || disabledField;
            default:
                return disabledField;

        }
    }
}

export class DistanceFrameFieldUsage {

    constructor(private component: DistanceFrameComponent | DistanceFrameFormComponent) {
    }

    public show(field: CatalogTab | DistanceFrameField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = this.component.editPermits ? this.component.editPermits.find(editPermits => editPermits.fieldName === field) : null;
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case DistanceFrameField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            case DistanceFrameField.WARM_ALTERNATIVE:
                return this.component.warmFramesByThickness.has(this.component.item.thickness) && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | DistanceFrameField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case DistanceFrameField.WARM_FRAME:
            case DistanceFrameField.ACTIVE:
                return !this.component.permissions.isPermitted({roles: ['ROLE_CREATE_DISTANCE_FRAME', 'ROLE_EDIT_DISTANCE_FRAME']})
                    || disabledField;
            default:
                return disabledField;

        }
    }
}

export class WindowSystemFieldUsage {

    constructor(private component: WindowSystemDefinitionComponent | WindowSystemDefinitionGeneralFormComponent) {
    }

    public show(field: WindowSystemTab | WindowSystemBasicField | WindowSystemField | RoofSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case WindowSystemBasicField.POSSIBLE_OPENING:
                return !this.component.roofSystem && showField;
            case RoofSystemField.ROOF_SYSTEM_FUNCTION:
            case RoofSystemField.ROOF_SYSTEM_GLAZING_PACKAGE:
            case RoofSystemField.ROOF_SYSTEM_UW:
            case RoofSystemField.PRINTOUT_IMAGE:
                return this.component.roofSystem && showField;
            case RoofSystemField.WINDOW_EDITOR_IMAGE:
                return !this.component.entranceSystem && showField;
            case WindowSystemTab.DESIGNER_DESCRIPTION:
                return !this.component.roofSystem && !this.component.entranceSystem && showField;
            case WindowSystemBasicField.SORT_INDEX:
                return this.component.windowSystem.id != null && showField;
            case WindowSystemTab.ANGLES:
            case WindowSystemTab.ASSEMBLY:
            case WindowSystemTab.DRAWING_TOOLS:
            case WindowSystemTab.SPECIFICATION:
            case WindowSystemTab.SHAPES:
            case WindowSystemTab.WEB_SHOP:
                return !this.component.roofSystem && !this.component.entranceSystem && showField;
            case WindowSystemTab.DIMENSION_PRICE:
                return this.component.roofSystem && showField;
            case WindowSystemTab.GLAMOUR_PRINT_INFO:
                return !this.component.entranceSystem && showField;
            case WindowSystemBasicField.UNIT_WEIGHT:
                return showField && (this.component.roofSystem || this.component.entranceSystem);
            default:
                return showField;
        }
    }

    public disabled(field: WindowSystemTab | WindowSystemBasicField | WindowSystemField | RoofSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case WindowSystemField.COMBINE_BUSINESS_TYPES:
            case WindowSystemField.SYSTEM_TYPE:
                return this.component.windowSystem.id !== undefined || disabledField;
            default:
                return disabledField;

        }
    }
}

export class GrillFieldUsage {

    constructor(private component: GrillComponent | GrillFormComponent) {
    }

    public show(field: CatalogTab | GrillField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GrillField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            case GrillField.CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS:
                return this.component.item.type === GrillTypes.INNER && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GrillField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return  permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GlassFieldUsage {

    constructor(private component: GlassComponent | GlassFormComponent) {
    }

    public show(field: CatalogTab | GlassField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
    }

    public disabled(field: CatalogTab | GlassField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class BusinessTypeFieldUsage {

    constructor(private component: BusinessTypeComponent | BusinessTypeFormComponent) {
    }

    public show(field: CatalogTab | BusinessTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
    }

    public disabled(field: CatalogTab | BusinessTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case BusinessTypeField.WINDOW_TYPE_CODE:
                return true;
            default:
                return disabledField;

        }
    }
}

export class SubwindowTypeFieldUsage {

    constructor(private component: SubwindowTypeComponent | SubwindowTypeFormComponent) {
    }

    public show(field: CatalogTab | SubwindowTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
    }

    public disabled(field: CatalogTab | SubwindowTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case SubwindowTypeField.TYPE:
                return true;
            default:
                return disabledField;

        }
    }
}

export class SealFieldUsage {

    constructor(private component: SealComponent | SealFormComponent) {
    }

    public show(field: CatalogTab | SealField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case SealField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | SealField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class OtherFillingFieldUsage {

    constructor(private component: OtherFillingComponent | OtherFillingFormComponent) {
    }

    public show(field: CatalogTab | OtherFillingField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case OtherFillingField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | OtherFillingField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class DecorativeFillingFieldUsage {

    constructor(private component: DecorativeFillingComponent | DecorativeFillingFormComponent) {
    }

    public show(field: CatalogTab | DecorativeFillingField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case DecorativeFillingField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | DecorativeFillingField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class RoofGlazingPackageFieldUsage {

    constructor(private component: RoofGlazingPackageComponent | RoofGlazingPackageFormComponent) {
    }

    public show(field: CatalogTab | RoofGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case RoofGlazingPackageField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | RoofGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class WindowDimensionsFieldUsage {

    constructor(private component: WindowDimensionsComponent | WindowDimensionsFormComponent) {
    }

    public show(field: CatalogTab | DimensionsField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case DimensionsField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            case CatalogTab.LINKED_SYSTEMS:
                return this.component.showSystemLinks && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | DimensionsField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case DimensionsField.SURFACE:
                return true;
            default:
                return disabledField;

        }
    }
}

export class EntranceModelFieldUsage {

    constructor(private component: EntranceModelComponent | EntranceModelFormComponent) {
    }

    public show(field: CatalogTab | EntranceModelField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case EntranceModelField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | EntranceModelField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GraspGlazingCategoryFieldUsage {

    constructor(private component: GraspGlazingCategoriesComponent | GraspGlazingCategoriesFormComponent) {
    }

    public show(field: GraspGlazingCategoryField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GraspGlazingCategoryField.SORT_INDEX:
                return showField && (this.component.item == undefined || this.component.item.id != undefined);
            default:
                return showField;
        }
    }

    public disabled(field: GraspGlazingCategoryField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GraspDistanceFrameCategoryFieldUsage {

    constructor(private component: GraspDistanceFrameCategoryComponent | GraspDistanceFrameCategoryFormComponent) {
    }

    public show(field: GraspDistanceFrameCategoryField): boolean {
        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GraspDistanceFrameCategoryField.SORT_INDEX:
                return showField && (this.component.item == undefined || this.component.item.id != undefined);
            default:
                return showField;
        }
    }

    public disabled(field: GraspDistanceFrameCategoryField): boolean {
        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GraspGlazingPackageFieldUsage {
    constructor(private component: GraspGlazingPackageComponent | GraspGlazingPackageFormComponent) {
    }

    public show(field: CatalogTab | GraspGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GraspGlazingPackageField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GraspGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class EntranceGlazingPackageFieldUsage {

    constructor(private component: EntranceGlazingPackageComponent | EntranceGlazingPackageFormComponent) {
    }

    public show(field: CatalogTab | EntranceGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case EntranceGlazingPackageField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | EntranceGlazingPackageField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class ConfigSystemFieldUsage {
    constructor(private component: ConfigSystemComponent | ConfigSystemFormComponent) {
    }

    public show(field: CatalogTab | ConfigSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case ConfigSystemField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | ConfigSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class ConfigDependentOptionFieldUsage {

    constructor(private component: ConfigDesignerCatalogDependentOptionsSetComponent | ConfigDesignerCatalogDependentOptionFormComponent) {
    }

    public show(field: ConfigDependentOptionTab | ConfigDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case ConfigDependentOptionField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: ConfigDependentOptionTab | ConfigDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case ConfigDependentOptionField.SYSTEM:
                return this.component.item.id != null || this.component.nextStepsFilled || disabledField;
            default:
                return disabledField;

        }
    }
}

export class GateSystemFieldUsage {
    constructor(private component: GateSystemComponent | GateSystemFormComponent) {
    }

    public show(field: CatalogTab | GateSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GateSystemField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GateSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GateWallFieldUsage {

    constructor(private component: GateWallComponent | GateWallFormComponent) {
    }

    public show(field: CatalogTab | GateWallField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GateWallField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GateWallField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class GateDependentOptionFieldUsage {

    constructor(private component: GateDesignerCatalogDependentOptionsSetComponent | GateDesignerCatalogDependentOptionFormComponent) {
    }

    public show(field: GateDependentOptionTab | GateDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GateDependentOptionField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: GateDependentOptionTab | GateDependentOptionField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const disabledField = permits && permits.limitation === CatalogEditLimitation.DISABLED;

        switch (field) {
            case GateDependentOptionField.SYSTEM:
                return this.component.item.id != null || this.component.nextStepsFilled || disabledField;
            default:
                return disabledField;

        }
    }
}

export class GatePanelTypeFieldUsage {

    constructor(private component: GatePanelTypeComponent | GatePanelTypeFormComponent) {
    }

    public show(field: CatalogTab | GatePanelTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case GatePanelTypeField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | GatePanelTypeField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class RailSystemFieldUsage {

    constructor(private component: RailSystemComponent | RailSystemFormComponent) {
    }

    public show(field: CatalogTab | RailSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        const showField = !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
        switch (field) {
            case RailSystemField.SORT_INDEX:
                return this.component.item.id != undefined && showField;
            default:
                return showField;
        }
    }

    public disabled(field: CatalogTab | RailSystemField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}

export class RackFieldUsage {

    constructor(private component: RackComponent | RackFormComponent) {
    }

    public show(field: RackField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return !permits || permits.limitation !== CatalogEditLimitation.HIDDEN;
    }

    public disabled(field: RackField): boolean {
        if (!this.component) {
            return false;
        }

        const permits = getPermitsForField(this.component.editPermits, field);
        return permits && permits.limitation === CatalogEditLimitation.DISABLED;
    }
}
