import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {Permissions} from "../../../../auth/permission.service";
import {ItemForCatalogLinking} from '../../single-system-checkbox-crud/item-for-catalog-linking';
import {ProductTypeGroup} from '../../window-system-definition/product-type-group';
import {Addon} from "../addon";

@Component({
    selector: 'app-addon-systems-form',
    templateUrl: './addon-systems-form.component.html',
    styleUrls: ['../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonSystemsFormComponent {

    @Input()
    addon: Addon;

    @Input()
    windowSystems: ItemForCatalogLinking[];

    @Input()
    windowSystemTypeGroups: ProductTypeGroup[];

    @Input()
    linkedSystemIds: number[];

    @Input()
    disabled: boolean;

    @Output()
    readonly linkedSystemIdsChange = new EventEmitter<number[]>();

    constructor(public permissions: Permissions, public changeDetector: ChangeDetectorRef) {
    }

    onSelectedSystemsChange(linkedSystemIds: number[]) {
        this.linkedSystemIdsChange.emit(linkedSystemIds);
        this.linkedSystemIds = linkedSystemIds;
        this.changeDetector.markForCheck();
    }
}
