export class ErrorResponse {

    code: number;
    message: string;
    invalidFields: { [key: string]: string; };
    pricingFailedFields: PricingFailedField[] = [];
    errorCodesWithParams: ErrorCodeWithParams[] = [];

    constructor(json: any) {
        json = json || {};
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }
        this.code = json.code;
        this.message = json.message;
        this.invalidFields = json.invalidFields;
        this.pricingFailedFields = ErrorResponse.mapFailedFields(json.pricingFailedFields);
        this.errorCodesWithParams = json.errorCodesWithParams;
    }

    public static mapFailedFields(json: any): PricingFailedField[] {
        return json ? Object.keys(json).map(key => new PricingFailedField(key, json[key])) : [];
    }

    is500(): boolean {
        return (this.code >= 500 && this.code < 600);
    }

    is400(): boolean {
        return this.code === 400;
    }

    is404(): boolean {
        return this.code === 404;
    }

    is422(): boolean {
        return this.code === 422;
    }
}

export class PricingFailedField {
    failedField: string;
    message: string[];

    constructor(failedField: string, message: string[]) {
        this.failedField = failedField;
        this.message = message;
    }
}

export class ErrorCodeWithParams {
    errorCode: string;
    params: { [key: string]: string; };
}
