<p-dialog [visible]="true" [resizable]="false" (onHide)="close()" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ getHeader() | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-number inputId="margin" *ngIf="dialogData.isMarkup"
                              label="{{ 'PROFIT_MARGIN.FORM.PROFIT_MARGIN' | translate }}"
                              [(validationMessageKey)]="validationErrors['margin']" [min]="0" [max]="999999"
                              [(ngModel)]="dialogData.markup"></app-input-number>
            <app-input-number inputId="rabate" *ngIf="!dialogData.isMarkup"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.RABATE_PERCENT' | translate }}"
                              [(validationMessageKey)]="validationErrors['rabate']" [min]="0" [max]="100"
                              [(ngModel)]="dialogData.rabate"></app-input-number>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button *ngIf="dialogData.isMarkup"
                               label="{{ 'OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.RESTORE_DEFAULT' | translate }}"
                               type="action" [size]="40" (onClick)="resetManualMargin()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
