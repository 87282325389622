export enum WindowTypeCode {
    // Single
    F = 'F',
    FF = 'FF',
    U = 'U',
    RP = 'RP',
    RL = 'RL',
    URP = 'URP',
    URL = 'URL',
    UPP = 'UPP',
    UPL = 'UPL',
    OG = 'OG',
    OOG = 'OOG',
    OP = 'OP',
    OL = 'OL',
    WL = 'WL',
    WP = 'WP',
    PL = 'PL',
    PP = 'PP',
    A = 'A',
    SHL = 'SHL',
    SHP = 'SHP',

    // złożenia poziomie
    F__URP = 'F__URP',
    URL__F = 'URL__F',
    RL__URP = 'RL__URP',
    URL__RP = 'URL__RP',
    URL__URP = 'URL__URP',
    RL__URP_W$S = 'RL__URP_W$S',
    URL_W__RP$S = 'URL_W__RP$S',
    URL__F__URP = 'URL__F__URP',
    URL__RP__URP = 'URL__RP__URP',
    URL__RL__URP = 'URL__RL__URP',
    URL_W__RP__URP$S = 'URL_W__RP__URP$S',
    URL__RL__URP_W$S = 'URL__RL__URP_W$S',
    F__UPL = 'F__UPL',
    UPP__F = 'UPP__F',
    FF__UPL = 'FF__UPL',
    UPP__FF = 'UPP__FF',
    UPL_W__UPP$S = 'UPL_W__UPP$S',
    UPL__UPP_W$S = 'UPL__UPP_W$S',
    RL_W__RP$S = 'RL_W__RP$S',
    RL__RP_W$S = 'RL__RP_W$S',
    PP__F = 'PP__F',
    F__PL = 'F__PL',
    F__PL_W__PP__F = 'F__PL_W__PP__F',
    F__PL__PP_W__F = 'F__PL__PP_W__F',
    PL_W__PP = 'PL_W__PP',
    PL__PP_W = 'PL__PP_W',
    WL_W__WP = 'WL_W__WP',
    WL__WP_W = 'WL__WP_W',
    FF__HSL = 'FF__HSL',
    HSP__FF = 'HSP__FF',
    FF__HSL_W__HSP__FF = 'FF__HSL_W__HSP__FF',
    FF__HSL__HSP_W__FF = 'FF__HSL__HSP_W__FF',
    SHL__SHP = 'SHL__SHP',
    SHL__SHL__SHP = 'SHL__SHL__SHP',
    SHL__SHP__SHP = 'SHL__SHP__SHP',
    F__SDL = 'F__SDL',
    SDP__F = 'SDP__F',

    // złożenia poziomie - copal
    F__F = 'F__F',
    F__F__F = 'F__F__F',
    CPL__CPL = 'CPL__CPL',
    CPL__CPL__CPL = 'CPL__CPL__CPL',
    CPL__CPL__CPL__CPL = 'CPL__CPL__CPL__CPL',
    CPL__CPL__CPL__CPL__CPL = 'CPL__CPL__CPL__CPL__CPL',
    CPP__CPP = 'CPP__CPP',
    CPP__CPP__CPP = 'CPP__CPP__CPP',
    CPP__CPP__CPP__CPP = 'CPP__CPP__CPP__CPP',
    CPP__CPP__CPP__CPP__CPP = 'CPP__CPP__CPP__CPP__CPP',
    CPL__CPL__CPP__CPP = 'CPL__CPL__CPP__CPP',

    // złożenia pionowe
    F__RP$V = 'F__RP$V',
    F__RL$V = 'F__RL$V',
    F__URL$V = 'F__URL$V',
    F__URP$V = 'F__URP$V',
    RP__F$V = 'RP__F$V',
    RL__F$V = 'RL__F$V',
    URL__F$V = 'URL__F$V',
    URP__F$V = 'URP__F$V',
    RL__RL$V = 'RL__RL$V',
    RP__RP$V = 'RP__RP$V',
    RL__URL$V = 'RL__URL$V',
    RP__URP$V = 'RP__URP$V',
    URL__URL$V = 'URL__URL$V',
    URP__URP$V = 'URP__URP$V',
    F__PD$V = 'F__PD$V',

    // okrągłe
    F$O = 'F$O',
    FF$O = 'FF$O',
    U$O = 'U$O',
    U__F$VO = 'U__F$VO',

    // drutex 2022
    RL__RP = 'RL__RP',
    RL__FF__RP = 'RL__FF__RP',
    RL__RP_W__RP$S = 'RL__RP_W__RP$S',
    RL_W__RP__RP$S = 'RL_W__RP__RP$S',
    RL__RL__RP_W$S = 'RL__RL__RP_W$S',
    RL__RL_W__RP$S = 'RL__RL_W__RP$S',
    ROA = 'ROA',
    ROL = 'ROL',
    ROP = 'ROP',
    ROL__ROP = 'ROL__ROP',
    ROA__ROL$V = 'ROA__ROL$V',
    ROA__ROP$V = 'ROA__ROP$V',
    ROA__FF$V = 'ROA__FF$V',
    FF__ROL$V = 'FF__ROL$V',
    FF__ROP$V = 'FF__ROP$V',
    ROL__FF__ROP = 'ROL__FF__ROP',
    ROL__ROP__ROP = 'ROL__ROP__ROP',
    ROL__ROL__ROP = 'ROL__ROL__ROP',

    // monorail
    F__HSL = 'F__HSL',
    HSP__F = 'HSP__F',
    F__HSL__F = 'F__HSL__F',
    F__HSP__F = 'F__HSP__F',

    // harmonijki
    HL__HL__HL_W = 'HL__HL__HL_W', // FL 330
    HL__HL__RP = 'HL__HL__RP', // FL 321
    RL__HP__HP = 'RL__HP__HP', // FL 321
    HP_W__HP__HP = 'HP_W__HP__HP', // FL 303
    HL__HL__HL__RP = 'HL__HL__HL__RP', // FL 431
    RL__HP__HP__HP = 'RL__HP__HP__HP', // FL 413
    HL__HL__HL__HL__HL_W = 'HL__HL__HL__HL__HL_W', // FL 550
    HL__HL__HL__HL__RP = 'HL__HL__HL__HL__RP', // FL 541
    HL__HL__HL_W__HP__HP = 'HL__HL__HL_W__HP__HP', // FL 532
    HL__HL__HP_W__HP__HP = 'HL__HL__HP_W__HP__HP', // FL 523
    RL__HP__HP__HP__HP = 'RL__HP__HP__HP__HP', // FL 514
    HP_W__HP__HP__HP__HP = 'HP_W__HP__HP__HP__HP', // FL 505
    HL__HL__HL__HL__HL__RP = 'HL__HL__HL__HL__HL__RP', // FL 651
    HL__HL__HL_W__HP__HP__HP = 'HL__HL__HL_W__HP__HP__HP', // 63FL 3 L
    HL__HL__HL__HP_W__HP__HP = 'HL__HL__HL__HP_W__HP__HP', // 63FL 3 P
    RL__HP__HP__HP__HP__HP = 'RL__HP__HP__HP__HP__HP', // FL 615
    HL__HL__HL__HL__HL__HL__HL_W = 'HL__HL__HL__HL__HL__HL__HL_W', // FL 770
    HL__HL__HL__HL__HL__HL__RP = 'HL__HL__HL__HL__HL__HL__RP', // FL 761
    HL__HL__HL__HL__HL_W__HP__HP = 'HL__HL__HL__HL__HL_W__HP__HP', // FL 752
    HL__HL__HP_W__HP__HP__HP__HP = 'HL__HL__HP_W__HP__HP__HP__HP', // FL 725
    RL__HP__HP__HP__HP__HP__HP = 'RL__HP__HP__HP__HP__HP__HP', // FL 716
    HP_W__HP__HP__HP__HP__HP__HP = 'HP_W__HP__HP__HP__HP__HP__HP', // FL 707
}
