<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="catalogOptionTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column header="{{'CATALOG_OPTION.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="symbol"></p-column>
                <p-column header="{{'CATALOG_OPTION.FORM.TARGET' | translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" [filterValues]="filterTarget | async" field="target"></p-column>
                <p-column header="{{'CATALOG_OPTION.FORM.SORT_INDEX'|translate}}" [rowspan]="2" [sortable]="true" field="sortIndex"></p-column>
                <p-column header="{{'CATALOG_OPTION.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <p-column field="symbol" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.symbol}}</span>
            </ng-template>
        </p-column>

        <p-column field="target" [sortable]="true" [filter]="showFilters" [filterValues]="filterTarget | async">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{ item.target ? (('NAVIGATION.ITEMS.CATALOG_CREATOR.VIEWS.' + item.target) | translate) : '' }}</span>
            </ng-template>
        </p-column>

        <p-column field="sortIndex" [sortable]="true"></p-column>

        <p-column field="active" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'CATALOG_OPTION.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'CATALOG_OPTION.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step label="{{ 'CATALOG_OPTION.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-catalog-option-form [item]="item" [validationErrors]="validationErrors" [file]="file" [availableTargets]="filterTarget"
                                 (uploadedFileChange)="uploadedAmazonFile = $event"
                                 [amazonS3Files]="amazonFiles"
                                 [canUploadToAmazon]="canUploadToAmazon"
                                 (fileChange)="onFileChange($event)"
                                 (getFilePreview)="handlePhotoDownloadClick()"></app-catalog-option-form>
    </app-wizard-step>

</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.COPY' : 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'CATALOG_OPTION.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-catalog-option-form [item]="item" [validationErrors]="validationErrors" [file]="file" [availableTargets]="filterTarget"
                                 (uploadedFileChange)="uploadedAmazonFile = $event"
                                 [amazonS3Files]="amazonFiles"
                                 [canUploadToAmazon]="canUploadToAmazon"
                                 (fileChange)="onFileChange($event)"
                                 (getFilePreview)="handlePhotoDownloadClick()"></app-catalog-option-form>
    </app-wizard-step>

</app-wizard-dialog>
