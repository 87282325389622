import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {mapItemArrayToJson, mapItemToJson} from '../../../common/crud-common/crudItem';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {Pricelist} from '../pricelist';
import {PriceTable} from './priceTable';
import {PriceTableItem} from './priceTableItem';
import {Type2WindowSystem} from './type2windowSystem';
import {Type2WindowSystemList} from './type2windowSystemList';
import {Material2ConfigSystemList} from "./material2ConfigSystemList";
import {Material2ConfigSystem} from "./material2ConfigSystem";

@Injectable()
export class PriceTableService {

    private static readonly API_URL = 'pricetables';

    constructor(public http: HttpClient, public dataServiceHelper: DataServiceHelper) {
    }

    getPriceTable(id: number, withChildTables: boolean): Observable<PriceTable[]> {
        return this.http.get<object[]>(`${PriceTableService.API_URL}/${id}`, {params: {withChildTables: `${withChildTables}`}})
            .pipe(mapItemArrayToJson(PriceTable));
    }

    getType2WindowSystems(priceListId: number, offset?: number, pageSize?: number, filters?: { [filterProperty: string]: FilterMetadata },
                          sortColumn?: string, sortOrder?: number): Observable<Type2WindowSystemList> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Type2WindowSystemList>(`${PriceTableService.API_URL}/pricelist/${priceListId}`, {params: params});
    }

    getMaterial2ConfigSystems(priceListId: number, offset?: number, pageSize?: number, filters?: { [filterProperty: string]: FilterMetadata },
                          sortColumn?: string, sortOrder?: number): Observable<Material2ConfigSystemList> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Material2ConfigSystemList>(`${PriceTableService.API_URL}/pricelistConfig/${priceListId}`, {params: params});
    }

    savePricetable(pricelist: Pricelist, type2windowSystem: Type2WindowSystem, priceTable: PriceTable): Observable<void> {
        if (priceTable.id) {
            return this.http.put<void>(`${PriceTableService.API_URL}`, priceTable);
        } else {
            return this.http.post<void>(`${PriceTableService.API_URL}/${+pricelist.id}/${type2windowSystem.typeId}/${type2windowSystem.windowSystemId}`,
                priceTable);
        }
    }

    savePricetableConfigs(pricelist: Pricelist, material2ConfigSystem: Material2ConfigSystem, priceTable: PriceTable): Observable<void> {
        if (material2ConfigSystem.priceTableId) {
            return this.http.put<void>(`${PriceTableService.API_URL}`, priceTable);
        } else {
            return this.http.post<void>(`${PriceTableService.API_URL}/configs/${+pricelist.id}/${material2ConfigSystem.materialId}/${material2ConfigSystem.configSystemId}`, priceTable);
        }
    }

    savePricetableConfigsMultiple(pricetableIds: number[], priceTable: PriceTable): Observable<void> {
        const headers = this.dataServiceHelper.prepareHeaders({pricetableIds});
        return this.http.put<void>(`${PriceTableService.API_URL}/configsMultiple`, priceTable, {headers});
    }

    downloadExternalPriceTable(apiUrl: string, code: string, futurePricetable: boolean): Observable<PriceTableItem[]> {
        return this.http.get<object[]>(`${apiUrl}/${code}`, {params: {futurePricetable: `${futurePricetable}`}})
            .pipe(mapItemArrayToJson(PriceTableItem));
    }
}
