<div class="new-form-very-wide">
    <app-checkbox class="label"
                  [inputId]="'autoOption' + item" label="{{ 'ADDON_CATEGORY.FORM.AUTO_OPTION' | translate }}"
                  [ngModel]="item.hasAutoOption" (ngModelChange)="onAutoOptionChange($event)"></app-checkbox>

    <p-pickList id="addons" [source]="availableAddons" [target]="selectedAddons"
                sourceHeader="{{ 'ADDON_CATEGORY.FORM.ADDONS.AVAILABLE' | translate }}"
                targetHeader="{{ 'ADDON_CATEGORY.FORM.ADDONS.SELECTED' | translate }}" [responsive]="true"
                filterBy="{{'name.' + translate.currentLang + ',supplier.companyName'}}"
                [disabled]="disabled"
                [sourceStyle]="{'height': '100%'}" [targetStyle]="{'height': '100%'}"
                [showSourceControls]="false" [showTargetControls]="false"
                (onMoveToSource)="handleMoveToSource($event.items)"
                (onMoveAllToSource)="handleMoveToSource($event.items)"
                (onMoveToTarget)="handleMoveToTarget($event.items)"
                (onMoveAllToTarget)="handleMoveToTarget($event.items)">
        <ng-template let-addon pTemplate="item">
            <div class="addons-picklist-row" [ngClass]="{'addons-picklist-row-inactive': !addon.active}">
                <strong class="picklist-column-supplier">{{ addon.supplier.companyName }} </strong>
                <span class="picklist-column-name">{{ addon.symbol }}</span>
            </div>
        </ng-template>
    </p-pickList>
</div>
