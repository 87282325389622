import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api/selectitem';
import {CatalogItemName} from '../../../../common/crud-common/catalog-item-name';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {CatalogItemType} from '../../../offer/window-editor/sidebar/pricing/PricingItem';
import {DiscontinuedCatalogItemInfo} from '../discontinued-catalog-item-info';

@Component({
    selector: 'app-discontinued-catalog-item-info-form',
    templateUrl: './discontinued-catalog-item-info-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscontinuedCatalogItemInfoFormComponent implements OnChanges {

    @Input()
    isNew: boolean;

    @Input()
    discontinuedCatalogItemInfo: DiscontinuedCatalogItemInfo;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    catalogItemTypes: SelectItem[];

    @Input()
    catalogItems: CatalogItemName[];

    @Input()
    minDate: Date;

    @Input()
    editable: boolean;

    selectedCatalogItems: CatalogItemName[] = [];

    @Output()
    readonly selectedCatalogItemsChange = new EventEmitter<CatalogItemName[]>();

    catalogItemsOptions: SelectItem[];

    constructor(private translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const catalogItemsChange = changes['catalogItems'];
        if (catalogItemsChange != undefined) {
            if (catalogItemsChange.currentValue != undefined) {
                this.catalogItemsOptions = catalogItemsChange.currentValue.map(this.catalogItemOptionFormatter);
            } else {
                this.catalogItemsOptions = [];
            }
        }
    }

    handleCatalogItemTypeChange(catalogItemType: CatalogItemType): void {
        if (catalogItemType !== this.discontinuedCatalogItemInfo.catalogItemType) {
            ValidationErrorsHelper.clearErrors(this.validationErrors, 'catalogItemId');
            this.selectedCatalogItems = [];
        }
        this.discontinuedCatalogItemInfo.catalogItemType = catalogItemType;
        this.discontinuedCatalogItemInfo.catalogItemId = undefined;
    }

    catalogItemOptionFormatter = (item: CatalogItemName): SelectItem => {
        return {
            label: this.translate.instant(item.name[this.translate.currentLang]),
            value: item.id
        };
    }

    handleSelectedCatalogItemsChange(selectedCatalogItems: CatalogItemName[]): void {
        this.selectedCatalogItemsChange.emit(selectedCatalogItems);
        ValidationErrorsHelper.clearErrors(this.validationErrors, 'catalogItemId');
    }

    unavailableFromChange(date: Date) {
        this.discontinuedCatalogItemInfo.unavailableFrom = date;
        if (this.discontinuedCatalogItemInfo.nameModificationDate > date) {
            this.discontinuedCatalogItemInfo.nameModificationDate = date;
        }
    }
}
