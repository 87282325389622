import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {finalize, mergeMap, share} from 'rxjs/operators';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItemBuilder} from '../../../common/service/translated.select.item.builder';
import {AbstractProfitMarginComponent, HasSaveTable} from '../base-profit-margin.component';
import {CatalogChargeProfitMargin, CatalogChargeProfitMarginType} from './catalog-charge-profit-margin';
import {CatalogChargeProfitMarginService} from './catalog-charge-profit-margin.service';

@Component({
    selector: 'app-catalog-charge-profit-margin',
    templateUrl: './catalog-charge-profit-margin.component.html',
    styleUrls: ['../../../common/addon-profit-margin/base-addon-profit-margin.component.css'],
    providers: [CatalogChargeProfitMarginService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogChargeProfitMarginComponent
    extends AbstractProfitMarginComponent<CatalogChargeProfitMargin, CatalogChargeProfitMarginService>
    implements OnInit, OnChanges, HasSaveTable {

    @Input() subsystemId: number;
    @Input() subsystemGroupId: number;
    @Input() canEdit: boolean;
    @Input() globalMarginUpdateButtonId: string;
    @Input() globalMarginUpdateInputId: string;

    @ViewChild(DataTable)
    dataTable: DataTable;

    filterCatalogItemType: SelectItem[];
    editedList: CatalogChargeProfitMargin[];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, CatalogChargeProfitMarginService, '', 'CatalogChargeProfitMargin');
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterCatalogItemType = Object.keys(CatalogChargeProfitMarginType).filter(key => Number.isNaN(Number.parseInt(key, 10)))
            .reduce((builder, key) => {
                return builder.add('CATALOG_CHARGE_PROFIT_MARGIN.TYPES.' + key, CatalogChargeProfitMarginType[key]);
            }, TranslatedSelectItemBuilder.create().add('', '')).build();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('subsystemGroupId' in changes || 'subsystemId' in changes) {
            this.loadItems(true);
        }
    }

    private loadItems(keepProfitMargins = false) {
        this.itemService.getProfitMargins(this.subsystemId, this.subsystemGroupId, this.entityType)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    if (this.itemList != undefined && keepProfitMargins) {
                        data.forEach(record => {
                            let old = this.itemList.find(o => o.id === record.id);
                            record.value = old.value;
                            record.valueString = old.valueString;
                        });
                    }
                    this.itemList = data;
                    this.totalRecords = data.length;
                },
                complete: () => {
                    this.showTable = true;
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    loadEdited(event: { data: CatalogChargeProfitMargin }) {
        this.editedItem = this.getNewItem();
        let rowData = event ? event.data : this.editedRowDataRef;
        this.editedItem.id = rowData.id;
        this.editedItem.catalogItemType = CatalogChargeProfitMarginType[rowData.catalogItemType];
        rowData.value = undefined;
        if (rowData.valueString != undefined) {
            this.editedItem.valueString = rowData.valueString;
        }
    }

    getNewItem(): CatalogChargeProfitMargin {
        return new CatalogChargeProfitMargin();
    }

    formatCatalogItemTypeKey(catalogItemType: CatalogChargeProfitMarginType): string {
        return 'CATALOG_CHARGE_PROFIT_MARGIN.TYPES.' + CatalogChargeProfitMarginType[catalogItemType];
    }

    saveTable(entityId?: number): Observable<void> {
        let entityIdToSave = undefined;
        if (entityId != undefined) {
            if (this.entityType === 'subsystem') {
                this.subsystemId = entityId;
            }
            if (this.entityType === 'subsystemGroup') {
                this.subsystemGroupId = entityId;
            }
        }
        if (this.entityType === 'subsystem') {
            entityIdToSave = this.subsystemId;
        }
        if (this.entityType === 'subsystemGroup') {
            entityIdToSave = this.subsystemGroupId;
        }
        this.composeEditedList();
        const observable = this.itemService.saveProfitMargins(entityIdToSave, this.entityType, this.editedList).pipe(mergeMap(() => {
            if ((this.entityType === 'subsystemGroup') && this.pushToGroupMembers) {
                return this.itemService.overrideGroupMembersMargins(entityIdToSave, this.editedList);
            }
            return of(undefined);
        }), share());
        observable.subscribe({
            error: error => {
                console.info('CatalogChargeProfitMargin `saveTable` error!');
                this.setErrors(error);
            },
            complete: () => {
                console.info('CatalogChargeProfitMargin `saveTable` completed!');
                this.unselectRow();
            }
        });
        return observable;
    }
}
