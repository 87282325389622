import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class Material implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    group: string;
    active: boolean;
    sortIndex: number;

    constructor() {
        this.name = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.active = true;
        this.sortIndex = 1;
    }

    static fromJSON(jsonObject: any): Material {
        const item = new Material();
        item.id = jsonObject['id'];
        if (jsonObject['name'] != undefined) {
            item.name = MultilanguageField.fromJSON(jsonObject['name']);
        }
        if (jsonObject['shortcut'] != undefined) {
            item.shortcut = MultilanguageField.fromJSON(jsonObject['shortcut']);
        }
        item.symbol = jsonObject['symbol'];
        item.group = jsonObject['group'];
        item.active = jsonObject['active'];
        item.sortIndex = jsonObject['sortIndex'];
        return item;
    }
}
