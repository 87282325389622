import {Component, Input, OnInit} from '@angular/core';
import {SupplierService} from '../../supplier/supplier.service';
import {Subsystem} from '../subsystem';


class SupplierSelection {
    id: number;
    companyName: string;
    enabledForSubsystem: boolean;
}

@Component({
    selector: 'app-subsystem-inactive-suppliers-form',
    templateUrl: './subsystem-inactive-suppliers-form.component.html',
    providers: [SupplierService]
})
export class SubsystemInactiveSuppliersFormComponent implements OnInit {

    @Input()
    subsystem: Subsystem;

    @Input()
    disabled: boolean;

    activeSuppliers: SupplierSelection[] = [];

    constructor(private supplierService: SupplierService) {
    }

    ngOnInit() {
        this.supplierService.getSupplierNames()
            .subscribe(data => this.activeSuppliers = data.map(supplier => {
                return {
                    id: supplier.id,
                    companyName: supplier.companyName,
                    enabledForSubsystem: this.subsystem.inactiveSupplierIds.indexOf(supplier.id) === -1
                };
            }));
    }

    onSupplierSelectionChange(supplier: SupplierSelection, enabledForSubsystem: boolean): void {
        supplier.enabledForSubsystem = enabledForSubsystem;
        if (enabledForSubsystem) {
            this.subsystem.inactiveSupplierIds = this.subsystem.inactiveSupplierIds.filter(id => id !== supplier.id);
        } else {
            this.subsystem.inactiveSupplierIds.push(supplier.id);
        }
    }

    buildCheckboxId(supplier: SupplierSelection): string {
        return 'supplier_' + supplier.id;
    }

}
