<div class="new-form-60vw">
    <div class="new-form-row">
        <app-translation-fields inputId="name"
                                label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.NAME' | translate }}"
                                [validationErrors]="validationErrors" validationKeyPrefix="name"
                                [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                                [required]="true" [field]="item.name"></app-translation-fields>
    </div>
    <div class="new-form-row">
        <app-translation-fields inputId="info"
                                label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.INFO' | translate }}"
                                [validationErrors]="validationErrors" validationKeyPrefix="info"
                                [maxlength]="512" [field]="item.info"></app-translation-fields>
    </div>
    <div class="new-form-row" *ngIf="item.id != null">
        <app-input-number inputId="sortIndex"
                          label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="warmFrames"
                      label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.INCLUDE_WARM_FRAMES' | translate }}"
                      [(ngModel)]="item.warmFrames"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="availableToNewSubsystems"
                      label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.AVAILABLE_TO_NEW_SUBSYSTEMS' | translate }}"
                      [(ngModel)]="item.availableToNewSubsystems"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active"
                      label="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>
    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab header="{{ 'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.IMAGE_SECTION' | translate }}"
                            [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="100000" [maxWidth]="400" [maxHeight]="400"
                                 [addButtonLabel]="'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ADMIN_PANEL.UPSELLING_PROPOSITION.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
