<div class="new-form-600">
    <div class="new-form-row" *ngFor="let lang of supportedLanguages">
        <app-text-area inputId="{{'content_' + lang.code}}"
                       label="{{ 'SETTINGS.SECTION.TEMPLATES.FORM.CONTENT' | translate: {lang: lang.code} }}"
                       [(validationMessageKey)]="validationErrors['content_'+lang.code]" [maxlength]="500"
                       [required]="true" [(ngModel)]="item.content[lang.code]"></app-text-area>
    </div>
    <div class="new-form-row">
        <app-select inputId="type" label="{{ 'SETTINGS.SECTION.TEMPLATES.FORM.TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors['templateType']" [options]="templateTypeOptions"
                    [(ngModel)]="item.templateType" [required]="true"></app-select>
    </div>
</div>
