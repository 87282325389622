import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from "@angular/core";
import {DataTable} from 'primeng/datatable';
import {share} from 'rxjs/operators';
import * as _ from 'underscore';
import {CrudCommonComponent} from '../../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {WindowSystemDefinitionDimensionPrice} from '../window-system-definition-dimension-price';
import {WindowSystemDefinitionDimensionPriceService} from '../window-system-definition-dimension-price.service';

@Component({
    selector: 'app-window-system-definition-dimension-price',
    templateUrl: './window-system-definition-dimension-price-form.component.html',
    styleUrls: ['./window-system-definition-dimension-price-form.component.css'],
    providers: [DataServiceHelper, TranslatedSelectItemService, WindowSystemDefinitionDimensionPriceService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionDimensionPriceFormComponent extends CrudCommonComponent<WindowSystemDefinitionDimensionPrice, WindowSystemDefinitionDimensionPriceService> implements OnInit {

    @Input()
    windowSystemId: number;

    @Input()
    disabled: boolean;

    @ViewChild('dt') dt: DataTable;
    showTable: boolean;

    globalPrice: number;
    editedItem;
    backupedRowData: any;
    editedRowDataRef: any;
    editedList: any[];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, WindowSystemDefinitionDimensionPriceService, "WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE", "WindowSystemDefinitionDimensionPrice");
        this.showTable = false;
    }

    getDatatable(): DataTable {
        return this.dt;
    }

    ngOnInit() {
        this.fromRecord = 1;
        this.toRecord = this.getRowsPerPageValue();
        this.editedList = [];
        this.loadItems();
    }

    loadItems() {
        let filters = {};
        if (this.windowSystemId != undefined) {
            filters["windowSystemId"] = {value: this.windowSystemId.toString()};
        }
        this.itemService.getAllItems(filters).subscribe({
            next: data => {
                this.itemList = data.data;
                this.totalRecords = data.totalRecords;
                this.showTable = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                console.error('DimensionPrice' + ' `getAllItems` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('DimensionPrice' + ' `getAllItems` completed!');
                this.hideDataLoadingIndicator();
            }
        });
    }

    public getNewItem() {
        return new WindowSystemDefinitionDimensionPrice();
    }

    prepareItemForRequest(): WindowSystemDefinitionDimensionPrice {
        return this.editedItem;
    }

    public saveTable(id?: number) {
        if (id != undefined) {
            this.windowSystemId = id;
        }
        this.composeEditedList();
        const observable = this.itemService.save(this.editedList).pipe(share());
        observable.subscribe({
            error: error => {
                console.error('DimensionPrice `saveTable` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                this.unselectRow();
                console.info('DimensionPrice `saveTable` completed!');
            }
        });
        return observable;
    }

    composeEditedList() {
        let obj = {};
        for (let i = 0; i < this.itemList.length; i++) {
            if (typeof (this.itemList[i]) === "object") {
                obj['data'] = this.itemList[i];
                this.beforeEdit(obj);
                this.editDimensionPrice(obj);
                if (this.editedItem.id != undefined || this.editedItem.price != undefined) {
                    this.editedList.push(JSON.parse(JSON.stringify(this.editedItem)));
                }
            }
        }
    }

    beforeEdit(event) {
        // clone to save data before edit
        this.backupedRowData = _.clone(event.data);
        // assign to have reference to edited value (will contain current value after save)
        this.editedRowDataRef = event.data;
    }

    editDimensionPrice(event: any) {
        this.loadEdited(event);
        let price = this.editedItem.price;
        if (!this.isValid(this.editedItem.price)) {
            this.editedRowDataRef.price = this.backupedRowData.price;
        } else {
            this.editedItem.price = price;
            this.editedRowDataRef.price = price;
        }
        this.unselectRow();
    }

    loadEdited(event) {
        this.editedItem = this.getNewItem();
        let rowData = event ? event.data : this.editedRowDataRef;
        this.editedItem.id = rowData.id;
        this.editedItem.windowSystemId = rowData.id;
        if (this.editedItem.windowSystemId !== this.windowSystemId) {
            this.editedItem.windowSystemId = this.windowSystemId;
            this.editedItem.id = undefined;
        }
        this.editedItem.roofWindowsDimension = rowData.roofWindowsDimension;
        this.editedItem.price = rowData.price;
        rowData.price = undefined;
    }

    unselectRow() {
        this.getDatatable().selection = null;
        this.getDatatable().selectionChange.emit(null);
    }

    private isValid(value: number): boolean {
        if (value == undefined) {
            return true;
        }
        return this.isNotNegativeValue(Number(value));
    }

    private isNotNegativeValue(val: number): boolean {
        return !Number.isNaN(val) && val >= 0;
    }

    applyGlobalPrice() {
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);
        if (this.isValid(this.globalPrice)) {
            let listToApply;
            if (this.getDatatable().filteredValue) {
                listToApply = this.getDatatable().filteredValue;
            } else {
                listToApply = this.itemList;
            }
            for (let i = 0; i < listToApply.length; i++) {
                listToApply[i].price = this.globalPrice;
            }
            this.globalPrice = undefined;
        } else {
            this.validationErrors['globalDimensionPriceUpdateInput'] = 'WINDOW-SYSTEM-DEFINITION.DIMENSION_PRICE.INVALID_VALUE';
        }
        this.changeDetector.markForCheck();
    }

    setPages(event: any): void {
        this.fromRecord = event.first + 1;
        this.toRecord = this.getDatatable().rows + this.fromRecord - 1;
        if (this.toRecord > this.totalRecords) {
            this.toRecord = this.totalRecords;
        }
    }

    updateTotalPageCount() {
        this.totalRecords = this.getDatatable().totalRecords;
        if (this.fromRecord > this.totalRecords) {
            this.fromRecord = 1;
        }
        this.toRecord = this.getDatatable().rows + this.fromRecord - 1;
        if (this.toRecord > this.totalRecords) {
            this.toRecord = this.totalRecords;
        }
    }
}
