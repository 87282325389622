<ng-container *vLet="(profileType == undefined || profileType == profileTypes.FRAME) as canHaveOverrides">
    <div class="new-form-row" *ngIf="canHaveOverrides; else elseBlock">
        <p-accordion>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': isAnyErrorPresent(validationErrors)}">
                <p-header>
                    {{ label | translate }} <span style="float: right">{{ compositionDistances.defaultWidth }}</span>
                </p-header>
                <ng-container
                        *ngTemplateOutlet="defaultWidth; context: { canHaveOverrides: true }"></ng-container>

                <app-input-number [inputId]="fieldName + '_topWidth'"
                                  [disabled]="disabled"
                                  label="{{ 'COMPOSITION-DISTANCES.FORM.TOP_WIDTH' | translate }}"
                                  [(validationMessageKey)]="validationErrors[fieldName + '.topWidth']"
                                  [(ngModel)]="compositionDistances.topWidth"
                                  [min]="1" [max]="99999" [maxlength]="5">
                </app-input-number>

                <app-input-number [inputId]="fieldName + '_bottomWidth'"
                                  [disabled]="disabled"
                                  label="{{ 'COMPOSITION-DISTANCES.FORM.BOTTOM_WIDTH' | translate }}"
                                  [(validationMessageKey)]="validationErrors[fieldName + '.bottomWidth']"
                                  [(ngModel)]="compositionDistances.bottomWidth"
                                  [min]="1" [max]="99999" [maxlength]="5">
                </app-input-number>

                <app-input-number [inputId]="fieldName + '_hingedSideWidth'"
                                  [disabled]="disabled"
                                  label="{{ 'COMPOSITION-DISTANCES.FORM.HINGED_SIDE_WIDTH' | translate }}"
                                  [(validationMessageKey)]="validationErrors[fieldName + '.hingedSideWidth']"
                                  [(ngModel)]="compositionDistances.hingedSideWidth"
                                  [min]="1" [max]="99999" [maxlength]="5">
                </app-input-number>

                <app-input-number [inputId]="fieldName + '_hingelessSideWidth'"
                                  [disabled]="disabled"
                                  label="{{ 'COMPOSITION-DISTANCES.FORM.HINGLESS_SIDE_WIDTH' | translate }}"
                                  [(validationMessageKey)]="validationErrors[fieldName + '.hingelessSideWidth']"
                                  [(ngModel)]="compositionDistances.hingelessSideWidth"
                                  [min]="1" [max]="99999" [maxlength]="5">
                </app-input-number>
            </p-accordionTab>
        </p-accordion>
    </div>
</ng-container>

<ng-template #elseBlock>
    <div class="new-form-row">
        <ng-container
                *ngTemplateOutlet="defaultWidth; context: { canHaveOverrides: false }"></ng-container>
    </div>
</ng-template>

<ng-template #defaultWidth let-canHaveOverrides="canHaveOverrides">
    <app-input-number *ngIf="profileType != profileTypes.THRESHOLD" [inputId]="fieldName + '_defaultWidth'"
                      label="{{ ('COMPOSITION-DISTANCES.FORM.' + (canHaveOverrides ? 'DEFAULT_WIDTH' : 'WIDTH')) | translate }}"
                      [disabled]="disabled"
                      [(validationMessageKey)]="validationErrors[fieldName + '.defaultWidth']"
                      [(ngModel)]="compositionDistances.defaultWidth"
                      [min]="0" [max]="99999" [maxlength]="5" [required]="true">
    </app-input-number>

    <app-input-number #widthInput *ngIf="profileType == profileTypes.THRESHOLD" [inputId]="fieldName + '_defaultWidth'"
                      label="{{ ('COMPOSITION-DISTANCES.FORM.' + (canHaveOverrides ? 'DEFAULT_WIDTH' : 'WIDTH')) | translate }}"
                      [disabled]="disabled"
                      [(validationMessageKey)]="validationErrors[fieldName + '.defaultWidth']"
                      [(ngModel)]="compositionDistances.defaultWidth"
                      [min]="0" [max]="99999" [maxlength]="5" [required]="true"
                      appInputTooltip="{{ 'PROFILE.FORM.WIDTH_EXTERNAL_TOOLTIP' | translate }}"
                      [appInputTooltipComponentHost]="widthInput">
    </app-input-number>
</ng-template>
