import {MultilanguageFieldInterface} from './multilanguage-field-interface';

export interface ColorInterface {
    id: number;
    names: MultilanguageFieldInterface;
    group: string;
    mustBeCovered: boolean;
    cannotBeCovered: boolean;
    webshopHex: string;
    active: boolean;
}

export function getColorFormattedNameWithGroup(color: ColorInterface, userLanguage: any): string {
    if (color.names) {
        return (!color.group ? '' : color.group + ' - ') + color.names[userLanguage];
    }

    return '';
}
