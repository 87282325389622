<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.NAME)"
                            inputId="name" label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="50"
                            [disabled]="fieldUsage.disabled(GraspDistanceFrameCategoryField.NAME)"
                            [required]="true" [field]="item?.name"></app-translation-fields>

    <div *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GraspDistanceFrameCategoryField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GraspDistanceFrameCategoryField.SORT_INDEX)"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
