import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {getColorFormattedNameWithGroup} from '../../../../window-designer/catalog-data/color-interface';
import {GrillTypes} from '../../../../window-designer/enums/GrillTypes';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GrillField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {GrillFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {Grill} from './grill';
import {GrillService} from './grill.service';

@Component({
    selector: 'app-grill',
    templateUrl: './grill.component.html',
    styleUrls: ['./grill.component.css', '../../shared-styles.css'],
    providers: [GrillService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrillComponent extends SingleSystemCheckboxCrudComponent<Grill, GrillService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    item: Grill;
    availableColors: SelectItem[];
    filterType: Observable<SelectItem[]>;
    availableTypes: Observable<SelectItem[]>;
    @ViewChild('dt') datatable;

    validateDataStep: WizardStepValidator;

    editPermits: FieldLimitation[] = [];
    fieldUsage: GrillFieldUsage;
    CatalogTab = CatalogTab;
    GrillField = GrillField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, GrillService, 'GRILL', 'Grill');
        this.item = new Grill();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new GrillFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.loadAvailableColors();
        this.filterType = this.translatedSelectItemService.buildSortedDropdown(GrillTypes, 'GRILL.FORM.TYPE.', '');
        this.availableTypes = this.filterType.pipe(map(types => types.slice(1)));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GRILLS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'grills';
    }

    showDialogToAdd() {
        this.selectedWindowSystems = [];
        super.showDialogToAdd();
        this.loadAvailableColors();
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    onLanguageChange(): void {
        super.onLanguageChange();
        this.loadAvailableColors();
    }

    getItemWithImage(grillId: number) {
        forkJoin({
            item: this.itemService.getItem(grillId),
            file: this.itemService.getImageAsFile(grillId),
            linkedSystems: this.getLinkedWindowSystems(grillId)
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.file;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                console.debug('getGrill completed!');
                this.setDisplayDialog(true);
            }
        });
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            } else {
                observable = this.itemService.saveItem(this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        if (!this.item.name[this.userLang]) {
            this.validationErrors[`name[${this.userLang}]`] = `error.grillDto.name[${this.userLang}].not_empty`;
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.grillDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        if (!this.item.width) {
            this.validationErrors['width'] = 'error.grillDto.width.not_null';
        } else if (!this.hasWidthFormat(this.item.width)) {
            this.validationErrors['width'] = 'error.grillDto.width.below_min';
        }

        this.validationErrors['maxAllowedSegmentLength'] = MultiValidator.of('error.grillDto.maxAllowedSegmentLength')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9999).validate(this.item.maxAllowedSegmentLength);

        if (!this.item.colorIds || this.item.colorIds.length === 0) {
            this.validationErrors['colorIds'] = 'error.grillDto.colorIds.not_empty';
        }

        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.grillDto.symbol.not_empty';
        }

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));

    }

    hasWidthFormat(width) {
        let format = /^\d{1,3}$/g;
        return format.test(width);
    }

    loadAvailableColors() {
        this.availableColors = [];
        this.itemService.getColors().subscribe({
            next: response => {
                this.availableColors = response.data.map(color => {
                    return {label: getColorFormattedNameWithGroup(color, this.userLang), value: color.id};
                });
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    getNewItem(): Grill {
        return new Grill();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
