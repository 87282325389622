export enum ConfigurableAddonType {
    SHUTTER_HORIZONTAL = "SHUTTER_HORIZONTAL",
    SHUTTER_VERTICAL = "SHUTTER_VERTICAL",
    SHUTTER_PANEL_TRACK = "SHUTTER_PANEL_TRACK",
    PLEAT = "PLEAT",
    GATE = "GATE",
    MOSQUITO_NET = "MOSQUITO_NET",
    BLIND_FREE_HANGING = "BLIND_FREE_HANGING",
    BLIND_FREE_HANGING_DAY_NIGHT = "BLIND_FREE_HANGING_DAY_NIGHT",
    BLIND_CASSETTE = "BLIND_CASSETTE",
    BLIND_CASSETTE_DAY_NIGHT = "BLIND_CASSETTE_DAY_NIGHT"
}
