import {WindowSystemDefaults} from "../../../../window-designer/entities/window-system-defaults";
import {ConfigSystemDefaults} from "../config-system-defaults/config-system-defaults";
import {GateSystemDefaults} from "../gate-system-defaults/gate-system-defaults";

type SystemDefaults = GateSystemDefaults | ConfigSystemDefaults | WindowSystemDefaults;

export abstract class SystemDefaultsState<ValueType extends SystemDefaults> {
    value?: ValueType;
    offerDefaultExisting: boolean;
    clientDefaultExisting: boolean;
    clientGroupDefaultExisting: boolean;
    subsystemDefaultExisting: boolean;
    subsystemGroupDefaultExisting: boolean;
    globalDefaultExisting: boolean;

    static getLevel(defaults: SystemDefaultsState<any>): string {
        if (defaults) {
            if (defaults.offerDefaultExisting) {
                return 'OFFER';
            }

            if (defaults.clientDefaultExisting) {
                return 'CLIENT';
            }

            if (defaults.clientGroupDefaultExisting) {
                return 'CLIENT_GROUP';
            }

            if (defaults.subsystemDefaultExisting) {
                return 'SUBSYSTEM';
            }

            if (defaults.subsystemGroupDefaultExisting) {
                return 'SUBSYSTEM_GROUP';
            }

            if (defaults.globalDefaultExisting) {
                return 'GLOBAL';
            }
        }
        return '';
    }
}

export class WindowSystemDefaultsState extends SystemDefaultsState<WindowSystemDefaults> {
}

export class GateSystemDefaultsState extends SystemDefaultsState<GateSystemDefaults> {
}

export class ConfigSystemDefaultsState extends SystemDefaultsState<ConfigSystemDefaults> {
}
