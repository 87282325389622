import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {GateSystemField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {Supplier} from "../../../supplier/supplier";
import {GateSystemFieldUsage} from "../../catalog-field-usage";
import {GateSystem} from "../gate-system";

@Component({
    selector: 'app-gate-system-form',
    templateUrl: './gate-system-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateSystemFormComponent {

    @Input() item: GateSystem;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() suppliers: SelectItem[];
    @Input() editPermits: FieldLimitation[] = [];
    @Output() readonly fileChange: EventEmitter<File> = new EventEmitter<File>();

    fieldUsage: GateSystemFieldUsage;
    GateSystemField = GateSystemField;

    constructor() {
        this.fieldUsage = new GateSystemFieldUsage(this);
    }

    getSupplierKey = (supplier: Supplier) => supplier ? supplier.id : undefined;

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
