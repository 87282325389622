import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {DataTable} from 'primeng/datatable';
import {ButtonWithMenuElementSelectedEvent} from '../../../../common/button-with-menu/button-with-menu-event';
import {MenuElement, MenuElementBuilder} from '../../../../common/button-with-menu/MenuElement';
import {MenuType} from '../../../../common/button-with-menu/MenuType';
import {OfferStatus} from '../../../../common/enums/OfferStatus';
import {ValidationErrors} from '../../../../common/validation-errors';
import {Offer} from '../../offer';
import {OffersService} from '../../offer-service';
import {PaymentPackage} from '../payment-package';

@Component({
    selector: 'app-payment-package-picklist',
    templateUrl: './payment-package-picklist.component.html',
    styleUrls: ['./payment-package-picklist.component.css']
})
export class PaymentPackagePicklistComponent implements OnInit {

    private static readonly OFFER_NUMBER_DESC_IDENTIFIER = "OFFER_NUMBER_DESC";
    private static readonly OFFER_NUMBER_ASC_IDENTIFIER = "OFFER_NUMBER_ASC";
    private static readonly ALTERNATE_OFFER_NUMBER_DESC_IDENTIFIER = "ALTERNATE_OFFER_NUMBER_DESC";
    private static readonly ALTERNATE_OFFER_NUMBER_ASC_IDENTIFIER = "ALTERNATE_OFFER_NUMBER_ASC";
    private static readonly CREATION_DATE_DESC_IDENTIFIER = "CREATION_DATE_DESC";
    private static readonly CREATION_DATE_ASC_IDENTIFIER = "CREATION_DATE_ASC";

    @Input()
    paymentPackage: PaymentPackage;

    @Input()
    validationErrors: ValidationErrors = {};

    @ViewChild(DataTable)
    ordersDataTable: DataTable;

    availableOrders: Offer[];
    selectedOrders: Offer[];
    totalOrders: number;
    menuType = MenuType;
    availableSorting: MenuElement[] = [];

    constructor(private offersService: OffersService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.loadOrders();
        this.initSortingMenu();
    }

    loadOrders(): void {
        if (this.paymentPackage.subsystemId == undefined) {
            this.availableOrders = [];
            this.selectedOrders = [];
            this.totalOrders = 0;
            return;
        }

        const filters: { [field: string]: FilterMetadata } = {};
        filters['subsystemIds'] = {value: '' + this.paymentPackage.subsystemId};
        filters['status'] = {value: this.createOfferStatusFilter()};
        filters['hideCorrections'] = {value: 'true'};
        if (this.paymentPackage.id != undefined) {
            filters['paymentPackage'] = {value: '' + this.paymentPackage.id};
        } else {
            filters['hasNoPaymentPackage'] = {value: 'true'};
        }
        this.offersService.getItems(undefined, undefined, filters, undefined, undefined)
            .subscribe(
                offers => {
                    this.availableOrders = offers.data;
                    this.selectedOrders = [];
                    for (let selectedOrderId of this.paymentPackage.orders) {
                        let selectedIndex = this.availableOrders.findIndex(order => order.id === selectedOrderId);
                        if (selectedIndex !== -1) {
                            this.selectedOrders.push(...this.availableOrders.splice(selectedIndex, 1));
                        }
                    }

                    this.availableOrders = this.availableOrders.filter(offer => offer.status === OfferStatus.VERIFIED);
                    this.totalOrders = offers.totalRecords;
                    this.changeDetector.markForCheck();
                }
            );
    }

    private initSortingMenu() {
        const translationKey = "PAYMENT_PACKAGE.FORM.SORT_BY.";
        this.availableSorting = [];
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.OFFER_NUMBER_DESC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.OFFER_NUMBER_DESC_IDENTIFIER)
            .build());
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.OFFER_NUMBER_ASC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.OFFER_NUMBER_ASC_IDENTIFIER)
            .build());
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_DESC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_DESC_IDENTIFIER)
            .build());
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_ASC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_ASC_IDENTIFIER)
            .build());
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.CREATION_DATE_DESC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.CREATION_DATE_DESC_IDENTIFIER)
            .build());
        this.availableSorting.push(new MenuElementBuilder()
            .setTranslationKey(translationKey + PaymentPackagePicklistComponent.CREATION_DATE_ASC_IDENTIFIER)
            .setIdentifier(PaymentPackagePicklistComponent.CREATION_DATE_ASC_IDENTIFIER)
            .build());
    }

    private createOfferStatusFilter(): string {
        if (this.paymentPackage.id == null) {
            return OfferStatus[OfferStatus.VERIFIED];
        } else {
            return [OfferStatus[OfferStatus.VERIFIED], OfferStatus[OfferStatus.WAITING_FOR_PAYMENT], OfferStatus.SUSPENDED].join(';');
        }
    }

    handleMoveToSource(moved: Offer[]): void {
        for (let movedOrder of moved) {
            let selectedIndex = this.paymentPackage.orders.findIndex(orderId => orderId === movedOrder.id);
            if (selectedIndex !== -1) {
                this.paymentPackage.orders.splice(selectedIndex, 1);
            }
        }
    }

    handleMoveToTarget(moved: Offer[]): void {
        for (let movedOrder of moved) {
            this.paymentPackage.orders.push(movedOrder.id);
        }
    }

    handleTargetReorder(): void {
        this.paymentPackage.orders = [];
        for (let order of this.selectedOrders) {
            this.paymentPackage.orders.push(order.id);
        }
    }

    handleSortSourceMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.sortOrders(event, this.availableOrders);
    }

    handleSortTargetMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.sortOrders(event, this.selectedOrders);
        this.handleTargetReorder();
    }

    sortOrders(selectedSortingElement: ButtonWithMenuElementSelectedEvent, orders: Offer[]): void {
        let selectedSorting = this.availableSorting.find(item => item.identifier === selectedSortingElement.identifier);

        switch (selectedSorting.identifier) {
            case PaymentPackagePicklistComponent.OFFER_NUMBER_DESC_IDENTIFIER:
                orders.sort((a, b) => this.sortOfferNumbers(a.offerNumber, b.offerNumber, false));
                break;
            case PaymentPackagePicklistComponent.OFFER_NUMBER_ASC_IDENTIFIER:
                orders.sort((a, b) => this.sortOfferNumbers(a.offerNumber, b.offerNumber, true));
                break;
            case PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_DESC_IDENTIFIER:
                orders.sort((a, b) => this.sortStrings(a.alternateOfferNumber, b.alternateOfferNumber, false));
                break;
            case PaymentPackagePicklistComponent.ALTERNATE_OFFER_NUMBER_ASC_IDENTIFIER:
                orders.sort((a, b) => this.sortStrings(a.alternateOfferNumber, b.alternateOfferNumber, true));
                break;
            case PaymentPackagePicklistComponent.CREATION_DATE_DESC_IDENTIFIER:
                orders.sort((a, b) => -(this.getTime(a.createdDate) - this.getTime(b.createdDate)));
                break;
            case PaymentPackagePicklistComponent.CREATION_DATE_ASC_IDENTIFIER:
                orders.sort((a, b) => (this.getTime(a.createdDate) - this.getTime(b.createdDate)));
                break;
        }
    }

    sortOfferNumbers(a: string, b: string, ascending: boolean): number {
        let aParts = a.split("/");
        let aYear = parseInt(aParts[0], 10);
        let aSubsystemId = parseInt(aParts[1], 10);
        let aUserId = parseInt(aParts[2], 10);
        let aOfferNumber = parseInt(aParts[3], 10);

        let bParts = b.split("/");
        let bYear = parseInt(bParts[0], 10);
        let bSubsystemId = parseInt(bParts[1], 10);
        let bUserId = parseInt(bParts[2], 10);
        let bOfferNumber = parseInt(bParts[3], 10);

        if (aParts.length === 4 && bParts.length === 4) {
            let compareResult: number;
            if (aYear === bYear) {
                if (aSubsystemId === bSubsystemId) {
                    if (aUserId === bUserId) {
                        if (aOfferNumber === bOfferNumber) {
                            compareResult = 0;
                        } else {
                            compareResult = aOfferNumber - bOfferNumber;
                        }
                    } else {
                        compareResult = aUserId - bUserId;
                    }
                } else {
                    compareResult = aSubsystemId - bSubsystemId;
                }
            } else {
                compareResult = aYear - bYear;
            }

            return ascending ? compareResult : -compareResult;
        } else {
            return aParts.length - bParts.length;
        }
    }

    private sortStrings(a: string, b: string, ascending: boolean) {
        if (a === b) {
            return 0;
        }
        if (!a) {
            return 1;
        }
        if (!b) {
            return -1;
        }
        if (ascending) {
            return a < b ? -1 : 1;
        } else {
            return a < b ? 1 : -1;
        }
    }

    private getTime(date: Date) {
        return date != null ? date.getTime() : 0;
    }
}
