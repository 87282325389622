import {GatePanelStampingType} from '../../../../gate-painter/gate-panel-stamping-type';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class GatePanelType implements CrudItem {
    id: number;
    name = new MultilanguageField();
    shortcut = new MultilanguageField();
    symbol: string;
    height: number;
    stampingType: GatePanelStampingType;
    active = true;
    sortIndex = 1;
    additionalComment: MultilanguageField;
    designerComment: MultilanguageField;

    static fromJSON(jsonObject: any): GatePanelType {
        const gatePanelType = new GatePanelType();
        gatePanelType.id = jsonObject['id'];
        if (jsonObject['name'] != undefined) {
            gatePanelType.name = MultilanguageField.fromJSON(jsonObject['name']);
        }
        if (jsonObject['shortcut'] != undefined) {
            gatePanelType.shortcut = MultilanguageField.fromJSON(jsonObject['shortcut']);
        }
        gatePanelType.symbol = jsonObject['symbol'];
        gatePanelType.height = jsonObject['height'];
        gatePanelType.stampingType = jsonObject['stampingType'];
        gatePanelType.active = jsonObject['active'];
        gatePanelType.sortIndex = jsonObject['sortIndex'];
        gatePanelType.additionalComment = jsonObject['additionalComment'];
        gatePanelType.designerComment = jsonObject['designerComment'];
        return gatePanelType;
    }
}
