import * as _ from 'underscore';
import {CompositionType} from './drawing-data/CompositionType';
import {CutData} from "./drawing-data/CutData";
import {DefiningMullion} from "./drawing-data/DefiningMullion";
import {DrawingData} from "./drawing-data/drawing-data";
import {GrillSegment} from "./drawing-data/GrillSegment";
import {LineCutData} from "./drawing-data/LineCutData";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {WindowData} from "./drawing-data/WindowData";
import {ConvexHull, DrawingUtil, MinMaxXY} from "./drawing-util";
import {
    ProfilesCompositionDistances,
    ProfilesCompositionType
} from "./profiles-composition-distances";
import {SubwindowPolygons} from "./subwindow-polygons";
import {CutsUtil} from "./utils/cutUtils";
import {ErrorNames} from "./utils/ErrorNames";
import {MullionHelper} from "./utils/MullionHelper";
import {PolygonPoint, PolygonPointUtil} from "./utils/PolygonPoint";
import {TotalGlazingBeads} from "./utils/total-glazing-beads";
import {SubWindowDataTypeUtil} from "./window-types/subwindow-data-type-util";
import {SubWindowTypeCode} from "./window-types/subwindow-type-code";
import {ProfileCompositionUtils} from "./utils/profile-composition-utils";
import {CompositionDistances, CompositionDistancesUtils} from "./composition-distances";
import {OpeningDirectionUtils} from "./window-types/opening-direction-utils";
import {OpeningDirection} from "./window-types/opening-direction";
import {SubwindowSide} from "./enums/subwindow-side";
import {PainterParams} from "./painters/PainterParams";

export abstract class WindowCalculator {

    private static getPoints(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                             profileCompositionDistances: ProfilesCompositionDistances,
                             offsets: CompositionDistances, skipValidation: boolean, forceCalulationForFSubwindow = false): number[] {
        let results = WindowCalculator.calculateInnerPolygonAndOffset(subWindow, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation, forceCalulationForFSubwindow);
        return CutsUtil.applyCuts(results.polygon, cuts, results.offset);
    }

    private static getPointsFull(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                 profileCompositionDistances: ProfilesCompositionDistances,
                                 offsets: CompositionDistances, skipValidation: boolean): PolygonPoint[] {
        let results = WindowCalculator.calculateInnerPolygonAndOffset(subWindow, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation);
        return CutsUtil.applyCutsFull(results.polygon, cuts, results.offset);
    }

    public static getGlazingBeadPoints(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                       totalGlazingBeadPoints: number[],
                                       profileCompositionDistances: ProfilesCompositionDistances): number[] {
        return WindowCalculator.applyMullionCuts(subwindow, totalGlazingBeadPoints, definingMullions,
            profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD));
    }

    public static getGlazingBeadPointsFull(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                           totalGlazingBeadPoints: PolygonPoint[],
                                           profileCompositionDistances: ProfilesCompositionDistances): PolygonPoint[] {
        return WindowCalculator.applyMullionCutsFull(subwindow, totalGlazingBeadPoints, definingMullions,
            profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD));
    }

    public static getRealGlazingPackagePoints(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                              totalRealPackagePoints: number[],
                                              profileCompositionDistances: ProfilesCompositionDistances): number[] {
        return WindowCalculator.applyMullionCuts(subwindow, totalRealPackagePoints, definingMullions,
            profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD) -
            profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH));
    }

    public static getFrameInnerEdgePoints(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                          totalInnerFrame: number[]): number[] {
        return WindowCalculator.applyMullionCuts(subwindow, totalInnerFrame, definingMullions, 0);
    }

    public static getFrameInnerEdgePointsFull(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                              totalInnerFrame: PolygonPoint[]): PolygonPoint[] {
        return WindowCalculator.applyMullionCutsFull(subwindow, totalInnerFrame, definingMullions, 0);
    }

    public static getAreaSizePoint(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                   totalInnerEdgePoints: number[], forPricing?: boolean): number[] {
        return WindowCalculator.applyMullionCuts(subwindow, totalInnerEdgePoints, definingMullions, 0, true,
            forPricing);
    }

    public static getAreaSizePointFull(subwindow: SubWindowData, definingMullions: DefiningMullion[],
                                       totalInnerEdgePoints: PolygonPoint[], forPricing?: boolean): PolygonPoint[] {
        return WindowCalculator.applyMullionCutsFull(subwindow, totalInnerEdgePoints, definingMullions, 0, true,
            forPricing);
    }

    public static getTotalGlazingBeadPointsForcedForF(subWindow: SubWindowData, cuts: CutData[],
                                                      totalBoundingBox: MinMaxXY,
                                                      profileCompositionDistances: ProfilesCompositionDistances,
                                                      skipValidation: boolean): number[] {
        return this.getTotalGlazingBeadsPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, skipValidation, true);
    }

    public static getTotalGlazingBeadsPoints(subWindow: SubWindowData, cuts: CutData[],
                                             totalBoundingBox: MinMaxXY,
                                             profileCompositionDistances: ProfilesCompositionDistances,
                                             skipValidation: boolean,
                                             forceCalulationForFSubwindow = false): number[] {
        let isWinged = !forceCalulationForFSubwindow && !WindowCalculator.isSubWindowF(subWindow);
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing) => isWinged ? wing : 0), ProfilesCompositionType.WING);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation, forceCalulationForFSubwindow);
    }

    public static getTotalGlazingBeadsPointsFull(subWindow: SubWindowData, cuts: CutData[],
                                                 totalBoundingBox: MinMaxXY,
                                                 profileCompositionDistances: ProfilesCompositionDistances,
                                                 skipValidation: boolean): PolygonPoint[] {
        let isWinged = !WindowCalculator.isSubWindowF(subWindow);
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing) => isWinged ? wing : 0), ProfilesCompositionType.WING);
        return WindowCalculator.getPointsFull(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets, skipValidation);
    }

    public static getTotalGlazingBeads(subwindow: SubWindowData, cuts: CutData[],
                                       totalBoundingBox: MinMaxXY,
                                       profileCompositionDistances: ProfilesCompositionDistances,
                                       skipValidation: boolean): TotalGlazingBeads {
        let beads = new TotalGlazingBeads();
        beads.full = WindowCalculator.getTotalGlazingBeadsPointsFull(subwindow, cuts,
            totalBoundingBox, profileCompositionDistances, skipValidation);
        beads.regular = PolygonPointUtil.toNumbersArray(beads.full);
        beads.forcedForF = WindowCalculator.isSubWindowF(subwindow) ? beads.regular :
            WindowCalculator.getTotalGlazingBeadPointsForcedForF(subwindow, cuts,
                totalBoundingBox, profileCompositionDistances, skipValidation);
        return beads;
    }

    public static getTotalRealGlazingPackagePoints(subWindow: SubWindowData, cuts: CutData[],
                                                   totalBoundingBox: MinMaxXY,
                                                   profileCompositionDistances: ProfilesCompositionDistances): number[] {
        let isWinged = !WindowCalculator.isSubWindowF(subWindow);
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing, glazingSeating) => (isWinged ? wing : 0) - glazingSeating),
            ProfilesCompositionType.WING, ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets, true);
    }

    public static getTotalFrameInnerEdgePointsForcedForF(subWindow: SubWindowData, cuts: CutData[],
                                                         totalBoundingBox: MinMaxXY,
                                                         profileCompositionDistances: ProfilesCompositionDistances,
                                                         skipValidation: boolean): number[] {
        return this.getTotalFrameInnerEdgePoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, skipValidation, true);
    }

    public static getTotalFrameInnerEdgePoints(subWindow: SubWindowData, cuts: CutData[],
                                               totalBoundingBox: MinMaxXY,
                                               profileCompositionDistances: ProfilesCompositionDistances,
                                               skipValidation: boolean, forceForF = false): number[] {
        let isWinged = !forceForF && !WindowCalculator.isSubWindowF(subWindow);
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing, glazingBead) => (isWinged ? wing : 0) - glazingBead),
            ProfilesCompositionType.WING, ProfilesCompositionType.GLAZING_BEAD);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation, forceForF);
    }

    public static getTotalFrameInnerEdgePointsFull(subWindow: SubWindowData, cuts: CutData[],
                                                   totalBoundingBox: MinMaxXY,
                                                   profileCompositionDistances: ProfilesCompositionDistances,
                                                   skipValidation: boolean): PolygonPoint[] {
        let isWinged = !WindowCalculator.isSubWindowF(subWindow);
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing, glazingBead) => (isWinged ? wing : 0) - glazingBead),
                ProfilesCompositionType.WING, ProfilesCompositionType.GLAZING_BEAD);
        return WindowCalculator.getPointsFull(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets, skipValidation);
    }

    public static getFFWingOuterEdgePoints(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                           profileCompositionDistances: ProfilesCompositionDistances,
                                           skipValidation: boolean): number[] {
        let offsets = this.prepareOffsets(profileCompositionDistances, (() => 0));
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets, skipValidation);
    }

    public static getFFWingOuterEdgePointsFull(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                               profileCompositionDistances: ProfilesCompositionDistances,
                                               skipValidation: boolean): PolygonPoint[] {
        let offsets = this.prepareOffsets(profileCompositionDistances, (() => 0));
        return WindowCalculator.getPointsFull(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets, skipValidation);
    }

    public static getWingCenterPoints(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                      profileCompositionDistances: ProfilesCompositionDistances,
                                      skipValidation: boolean): number[] {
        return WindowCalculator.getWingPointsOffsetByFactor(subWindow, cuts, totalBoundingBox,
            profileCompositionDistances, skipValidation, 0.5);
    }

    public static getWingPointsOffsetByFactor(subWindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                              profileCompositionDistances: ProfilesCompositionDistances,
                                              skipValidation: boolean, factor: number): number[] {
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((wing, glazingBead) => (wing - glazingBead) * factor), ProfilesCompositionType.WING,
            ProfilesCompositionType.GLAZING_BEAD);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation);
    }

    public static getFrameCenterPointsForWingedWindow(subWindow: SubWindowData, cuts: CutData[],
                                        totalBoundingBox: MinMaxXY,
                                        profileCompositionDistances: ProfilesCompositionDistances,
                                        skipValidation: boolean): number[] {
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((frame, wingOverlap) => -1 * (frame - wingOverlap) / 2),
            ProfilesCompositionType.FRAME, ProfilesCompositionType.WING_OVERLAP);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation);
    }

    public static getFFrameCenterPoints(subWindow: SubWindowData, cuts: CutData[],
                                        totalBoundingBox: MinMaxXY,
                                        profileCompositionDistances: ProfilesCompositionDistances,
                                        skipValidation: boolean): number[] {
        let offsets = this.prepareOffsets(profileCompositionDistances,
            ((frame, glazingBead) => -1 * (frame + glazingBead) / 2),
            ProfilesCompositionType.FRAME, ProfilesCompositionType.GLAZING_BEAD);
        return WindowCalculator.getPoints(subWindow, cuts, totalBoundingBox, profileCompositionDistances, offsets,
            skipValidation);
    }

    public static getInnermostFrameCenterPoint(subwindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                               profileCompositionDistances: ProfilesCompositionDistances,
                                               skipValidation: boolean, params: PainterParams): number[] {
        if (WindowCalculator.isSubWindowF(subwindow)) {
           return WindowCalculator.getFFrameCenterPoints(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
               skipValidation);
        } else if (params.ventilationAlwaysOnFrame) {
            return WindowCalculator.getFrameCenterPointsForWingedWindow(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
                skipValidation);
        } else {
            return WindowCalculator.getWingCenterPoints(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
                skipValidation);
        }
    }

    public static isSubWindowF(subWindow: SubWindowData): boolean {
        return subWindow.typeCode === SubWindowTypeCode.F;
    }

    public static isSubWindowFixed(subWindow: SubWindowData): boolean {
        return subWindow.typeCode === SubWindowTypeCode.F || subWindow.typeCode === SubWindowTypeCode.FF;
    }

    public static isSubWindowCopalSliding(subWindow: SubWindowData): boolean {
        return SubWindowDataTypeUtil.getCopalSlidingTypes().includes(subWindow.typeCode);
    }

    public static isSubWindowAccordion(subWindow: SubWindowData) {
        return SubWindowDataTypeUtil.getAccordionTypes().includes(subWindow.typeCode);
    }

    public static isSubWindowActive(subWindow: SubWindowData): boolean {
        return !this.isSubWindowFixed(subWindow) &&
            (subWindow.isLeading || !ProfileCompositionUtils.isSubwindowAdjacentToMovableBar(subWindow));
    }

    public static hasHandle(subWindow: SubWindowData): boolean {
        return this.isSubWindowActive(subWindow)
            && !this.isSubWindowCopalSliding(subWindow)
            && (!this.isSubWindowAccordion(subWindow) || subWindow.isLeading);
    }

    public static getOuterFramePoints(windows: WindowData[], cuts: CutData[], noRounding = false): number[] {
        let framePoints = _.chain(windows)
            .map(window => window.subWindows)
            .flatten()
            .map(subWindow => CutsUtil.applyCuts(subWindow.points, cuts, 0, noRounding))
            .flatten()
            .value();
        return ConvexHull.performGrahamScan(framePoints);
    }

    public static getOuterFramePointsFull(data: DrawingData, noRounding = false): PolygonPoint[] {
        let points = _.chain(data.windows)
            .map(window => window.subWindows)
            .flatten()
            .map(subwindow =>
                WindowCalculator.getOuterSubwindowPointsFull(subwindow,
                    data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut)), noRounding))
            .flatten()
            .value();
        return ConvexHull.performGrahamScanFull(points);
    }

    public static getOuterSubwindowPoints(subWindow: SubWindowData, cuts: CutData[], noRounding = false): number[] {
        return CutsUtil.applyCuts(subWindow.points, cuts, 0, noRounding);
    }

    public static getOuterSubwindowPointsFull(subWindow: SubWindowData, cuts: CutData[], noRounding = false): PolygonPoint[] {
        return CutsUtil.applyCutsFull(subWindow.points, cuts, 0, noRounding);
    }

    public static hasNonFixedSubwindows(drawingData: DrawingData): boolean {
        return drawingData.windows.some(window => window.subWindows.some(subwindow => !WindowCalculator.isSubWindowFixed(subwindow)));
    }

    public static hasSubwindowsAllowingHandles(windows: WindowData[]): boolean {
        return windows.some(window =>
            window.subWindows.some(subwindow =>
                !WindowCalculator.isSubWindowFixed(subwindow) && !WindowCalculator.isSubWindowCopalSliding(subwindow)
            )
        );
    }

    public static getSubWindowCenterPoint(subWindow: SubWindowData, cuts: CutData[],
                                          totalBoundingBox: MinMaxXY,
                                          profileCompositionDistances: ProfilesCompositionDistances,
                                          skipValidation: boolean): number[] {
        let innermostFramePoints = WindowCalculator.getTotalGlazingBeadsPoints(subWindow, cuts, totalBoundingBox,
            profileCompositionDistances, skipValidation);
        let left = innermostFramePoints[0];
        let right = innermostFramePoints[0];
        let top = innermostFramePoints[1];
        let bottom = innermostFramePoints[1];
        for (let i = 2; i < innermostFramePoints.length - 1; i += 2) {
            if (left < innermostFramePoints[i]) {
                left = innermostFramePoints[i];
            }
            if (right > innermostFramePoints[i]) {
                right = innermostFramePoints[i];
            }
            if (top < innermostFramePoints[i + 1]) {
                top = innermostFramePoints[i + 1];
            }
            if (bottom > innermostFramePoints[i + 1]) {
                bottom = innermostFramePoints[i + 1];
            }
        }
        return [(left + right) / 2, (top + bottom) / 2];
    }

    private static applyMullionCuts(subwindow: SubWindowData, totalGlazingBeadPoints: number[],
                                    definingMullions: DefiningMullion[], distance: number, areaResizeMode?: boolean,
                                    forPricing?: boolean): number[] {
        return PolygonPointUtil.toNumbersArray(
            this.applyMullionCutsFull(subwindow, PolygonPointUtil.toPolygonPoints(totalGlazingBeadPoints),
                definingMullions, distance, areaResizeMode, forPricing));
    }

    private static applyMullionCutsFull(subwindow: SubWindowData, totalGlazingBeadPoints: PolygonPoint[],
                                        definingMullions: DefiningMullion[], distance: number, areaResizeMode?: boolean,
                                        forPricing?: boolean): PolygonPoint[] {
        let glazingBead = totalGlazingBeadPoints.slice();
        for (let definingMullion of definingMullions) {
            let mullion = MullionHelper.findMullionById(definingMullion.id, subwindow);
            if (areaResizeMode && !mullion.isConstructional && !forPricing) {
                continue;
            }
            let mullionSegment = mullion.drawingSegments[0];
            let mullionWidth = areaResizeMode ? 0 : mullion.width;
            if (GrillSegment.isLine(mullionSegment)) {
                let cut = new LineCutData(mullionSegment.points, definingMullion.side);
                glazingBead = CutsUtil.applyCutFull(glazingBead, cut, (mullionWidth / 2) + distance);
            } else {
                let err = new Error("Unsupported mullion segment type");
                err.name = ErrorNames.NOT_IMPLEMENTED;
                throw err;
            }
        }
        return glazingBead;
    }

    static calculateInnerPolygonAndOffset(subwindow: SubWindowData, totalBoundingBox: MinMaxXY,
                                          profileCompositionDistances: ProfilesCompositionDistances,
                                          offsets: CompositionDistances, skipValidation: boolean,
                                          forceCalulationForFSubwindow = false): { polygon: number[]; offset: number } {
        let framePoints = subwindow.points;
        let isWinged = !forceCalulationForFSubwindow && !WindowCalculator.isSubWindowF(subwindow);
        let box = DrawingUtil.calculateMinMaxFromPolygon(framePoints, false);
        let opening = OpeningDirectionUtils.getSubwindowOpening(subwindow.typeCode);

        this.shiftBoxSide(box, totalBoundingBox, profileCompositionDistances, offsets, opening, isWinged,
            forceCalulationForFSubwindow, subwindow, SubwindowSide.LEFT);
        this.shiftBoxSide(box, totalBoundingBox, profileCompositionDistances, offsets, opening, isWinged,
            forceCalulationForFSubwindow, subwindow, SubwindowSide.RIGHT);
        this.shiftBoxSide(box, totalBoundingBox, profileCompositionDistances, offsets, opening, isWinged,
            forceCalulationForFSubwindow, subwindow, SubwindowSide.TOP);
        this.shiftBoxSide(box, totalBoundingBox, profileCompositionDistances, offsets, opening, isWinged,
            forceCalulationForFSubwindow, subwindow, SubwindowSide.BOTTOM);

        if (!skipValidation) {
            if (box.minX >= box.maxX || box.minY >= box.maxY) {
                const err = new Error('WindowCalculator.calculateInnerPolygonAndOffset: Swiatlo szyby jest zbyt male');
                err.name = ErrorNames.FRAMES_TO_CLOSE;
                throw err;
            }
        }

        let polygon = [box.minX, box.minY, box.maxX, box.minY, box.maxX, box.maxY, box.minX, box.maxY];
        if (polygon.some(v => isNaN(v))) {
            polygon = [];
        }

        // always use default widths for cuts calculation
        let outerDistance = profileCompositionDistances.getDefault(ProfilesCompositionType.FRAME) -
            (isWinged ? profileCompositionDistances.getDefault(ProfilesCompositionType.WING_OVERLAP) : 0);
        return {polygon: polygon, offset: outerDistance + offsets.defaultWidth};
    }

    private static shiftBoxSide(box: MinMaxXY, totalBoundingBox: MinMaxXY,
                                profileCompositionDistances: ProfilesCompositionDistances,
                                offsets: CompositionDistances, opening: OpeningDirection, isWinged: boolean,
                                forceCalulationForFSubwindow: boolean,
                                subwindow: SubWindowData, side: SubwindowSide): void {
        let posParam;
        let signMultiplier = 1;
        let offset = CompositionDistancesUtils.get(offsets, side, opening);
        switch (side) {
            case SubwindowSide.LEFT:
                posParam = 'minX';
                break;
            case SubwindowSide.RIGHT:
                posParam = 'maxX';
                signMultiplier = -1;
                break;
            case SubwindowSide.TOP:
                posParam = 'minY';
                break;
            case SubwindowSide.BOTTOM:
                posParam = 'maxY';
                signMultiplier = -1;
                break;
        }
        if (box[posParam] === totalBoundingBox[posParam]) {
            box[posParam] = box[posParam] + signMultiplier *
                (this.getOuterDistance(profileCompositionDistances, opening, isWinged, forceCalulationForFSubwindow,
                    subwindow, side) + offset);
        } else {
            box[posParam] = box[posParam] + signMultiplier *
                (this.getInnerDistance(subwindow.profilesComposition[side], profileCompositionDistances, isWinged, side,
                    opening) + offset);
        }
    }

    private static getOuterDistance(profileCompositionDistances: ProfilesCompositionDistances,
                                    opening: OpeningDirection, isWinged: boolean, forceCalulationForFSubwindow: boolean,
                                    subwindow: SubWindowData, side: SubwindowSide): number {
        if (side === SubwindowSide.TOP && !forceCalulationForFSubwindow && !WindowCalculator.isSubWindowF(subwindow) &&
            profileCompositionDistances.get(ProfilesCompositionType.CHANNEL_SECTION)) {
            return profileCompositionDistances.get(ProfilesCompositionType.CHANNEL_SECTION);
        }
        if (side === SubwindowSide.BOTTOM && !forceCalulationForFSubwindow && !WindowCalculator.isSubWindowF(subwindow) &&
            profileCompositionDistances.get(ProfilesCompositionType.THRESHOLD)) {
            return profileCompositionDistances.get(ProfilesCompositionType.THRESHOLD);
        }
        return profileCompositionDistances.get(ProfilesCompositionType.FRAME, side, opening) -
            (isWinged ? profileCompositionDistances.get(ProfilesCompositionType.WING_OVERLAP, side, opening) : 0);
    }

    private static getInnerDistance(compType: CompositionType,
                                    profileCompositionDistances: ProfilesCompositionDistances, isWinged: boolean,
                                    side: SubwindowSide, opening: OpeningDirection) {
        switch (compType) {
            case CompositionType.REGULAR:
                return profileCompositionDistances.get(ProfilesCompositionType.MULLION) / 2 -
                    (isWinged ? profileCompositionDistances.get(ProfilesCompositionType.WING_OVERLAP, side, opening) : 0);
            case CompositionType.MOVABLE_POST:
                return profileCompositionDistances.get(ProfilesCompositionType.MOVABLE_POST) / 2 -
                    (isWinged ? profileCompositionDistances.get(ProfilesCompositionType.WING_OVERLAP, side, opening) : 0);
            case CompositionType.SLIDING_OVERLAP:
                return -profileCompositionDistances.get(ProfilesCompositionType.SLIDING_OVERLAP);
            case CompositionType.SLIDING_CONTACT:
                return profileCompositionDistances.get(ProfilesCompositionType.SLIDING_CONTACT) / 2;
            default:
                let err = new Error("Unsupported profile type: " + compType);
                err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
                throw err;
        }
    }

    public static getSubwindowPolygons(subwindow: SubWindowData, cuts: CutData[],
                                       totalBoundingBox: MinMaxXY,
                                       profileCompositionDistances: ProfilesCompositionDistances,
                                       skipValidation: boolean): SubwindowPolygons {
        let framePoints = WindowCalculator.getOuterSubwindowPoints(subwindow, cuts);
        let frameOpeningPoints = WindowCalculator.getTotalGlazingBeadPointsForcedForF(subwindow, cuts,
            totalBoundingBox, profileCompositionDistances, skipValidation);
        let wingPoints = this.isSubWindowF(subwindow) ? undefined :
            WindowCalculator.getFFWingOuterEdgePoints(subwindow, cuts, totalBoundingBox, profileCompositionDistances, skipValidation);
        let pluginPoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, cuts,
            totalBoundingBox, profileCompositionDistances, skipValidation);
        let glassPoints = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
            skipValidation);

        return new SubwindowPolygons(framePoints, frameOpeningPoints, wingPoints, pluginPoints, glassPoints);
    }

    public static constructionWingPointsOffsetByFactor(windows: WindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                                     profileCompositionDistances: ProfilesCompositionDistances, factor: number): number[] {
        let points = _.chain(windows)
            .map(window => window.subWindows)
            .flatten()
            .map(subWindow =>
                WindowCalculator.getWingPointsOffsetByFactor(subWindow, cuts, totalBoundingBox, profileCompositionDistances, true, factor))
            .flatten()
            .value();
        return ConvexHull.performGrahamScan(points);
    }

    public static prepareOffsets(profileCompositionDistances: ProfilesCompositionDistances,
                                  mappingFunction: (...funcParams) => number,
                                  ...types: ProfilesCompositionType[]): CompositionDistances {
        let result = new CompositionDistances();
        result.defaultWidth = mappingFunction(...types.map(t => profileCompositionDistances.getFull(t).defaultWidth));
        result.topWidth = mappingFunction(...types.map(t => profileCompositionDistances.getFull(t).topWidth ||
            profileCompositionDistances.getFull(t).defaultWidth));
        result.bottomWidth = mappingFunction(...types.map(t => profileCompositionDistances.getFull(t).bottomWidth ||
            profileCompositionDistances.getFull(t).defaultWidth));
        result.hingedSideWidth =
            mappingFunction(...types.map(t => profileCompositionDistances.getFull(t).hingedSideWidth ||
                profileCompositionDistances.getFull(t).defaultWidth));
        result.hingelessSideWidth =
            mappingFunction(...types.map(t => profileCompositionDistances.getFull(t).hingelessSideWidth ||
                profileCompositionDistances.getFull(t).defaultWidth));
        return result;
    }

    public static getConstructionFrameInnerPoints(outerFrame: number[],
                                                  profilesCompositionDistances: ProfilesCompositionDistances,
                                                  windows: WindowData[]): number[] {
        let outerBox = DrawingUtil.calculatePolygonTotalBoundingBox(outerFrame);
        let subwindows = [].concat.apply([], windows.map(window => window.subWindows));
        let frame = profilesCompositionDistances.getFull(ProfilesCompositionType.FRAME);
        let glazingBead = profilesCompositionDistances.getFull(ProfilesCompositionType.GLAZING_BEAD);

        let leftOpening = subwindows.filter(sw => sw.points[0] === outerBox.minX)
            .map(sw => OpeningDirectionUtils.getSubwindowOpening(sw.typeCode))
            .reduce((a, b) => a === b ? a : undefined);
        let rightOpening = subwindows.filter(sw => sw.points[2] === outerBox.maxX)
            .map(sw => OpeningDirectionUtils.getSubwindowOpening(sw.typeCode))
            .reduce((a, b) => a === b ? a : undefined);
        let topOpening = subwindows.filter(sw => sw.points[1] === outerBox.minY)
            .map(sw => OpeningDirectionUtils.getSubwindowOpening(sw.typeCode))
            .reduce((a, b) => a === b ? a : undefined);
        let bottomOpening = subwindows.filter(sw => sw.points[5] === outerBox.maxY)
            .map(sw => OpeningDirectionUtils.getSubwindowOpening(sw.typeCode))
            .reduce((a, b) => a === b ? a : undefined);

        return DrawingUtil.getPolygonFromBBox(new MinMaxXY(
            outerBox.minX + CompositionDistancesUtils.get(frame, SubwindowSide.LEFT, leftOpening) -
            CompositionDistancesUtils.get(glazingBead, SubwindowSide.LEFT, leftOpening),
            outerBox.maxX - CompositionDistancesUtils.get(frame, SubwindowSide.RIGHT, rightOpening) +
            CompositionDistancesUtils.get(glazingBead, SubwindowSide.RIGHT, leftOpening),
            outerBox.minY + CompositionDistancesUtils.get(frame, SubwindowSide.TOP, topOpening) -
            CompositionDistancesUtils.get(glazingBead, SubwindowSide.TOP, leftOpening),
            outerBox.maxY - CompositionDistancesUtils.get(frame, SubwindowSide.BOTTOM, bottomOpening) +
            CompositionDistancesUtils.get(glazingBead, SubwindowSide.BOTTOM, leftOpening),
        ));
    }
}
