<div class="new-form-60vw">
    <div class="rule-block" *ngFor="let mapKey of inputSelectedMap.keys();">
        <ng-container
                *ngTemplateOutlet="ruleBlockContentTemplate; context: { field: getInputIdFromKey(mapKey), whenMatched: getWhenMatchedFromKey(mapKey), added: true }"></ng-container>
        <div class="block-separator">&nbsp;</div>
    </div>
    <div class="rule-block">
        <ng-container
                *ngTemplateOutlet="ruleBlockContentTemplate; context: { field: selectedNewField, whenMatched: null, added: false }"></ng-container>
    </div>

    <div *ngFor="let validationError of isRequiredFieldsForm? [validationErrors['required']] : [validationErrors['dependent'], validationErrors['options']]"
         class="new-form-field-error-message">
        {{ validationError | translate }}
    </div>
</div>

<ng-template #ruleBlockContentTemplate let-field="field" let-whenMatched="whenMatched" let-added="added">
    <div style="display: flex">
        <div class="rule-selects">
            <div class="new-form-row" *ngIf="!isRequiredFieldsForm">
                <app-select [inputId]="'whenMatchedSelect_' + field"
                            label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.WHEN_MATCHED' | translate }}"
                            [options]="whenMatchedValues | async" [disabled]="added" [ngModel]="whenMatched"
                            (ngModelChange)="handleFieldInputWhenMatchedChange($event)"></app-select>
            </div>

            <div class="new-form-row">
                <app-select [inputId]="'inputIdSelect_' + field"
                            label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.REQUIRED_INPUT_ID' | translate }}"
                            [options]="fieldType | async" [ngModel]="field" [disabled]="added"
                            (ngModelChange)="handleFieldInputIdChange($event)"></app-select>
            </div>

            <div *ngIf="added" class="new-form-row">
                <ng-container [ngSwitch]="getValueInputType(field)">
                    <app-input-number *ngSwitchCase="GateEditorFieldInputType.NUMBER"
                                      [inputId]="'inputValueNumber_' + field" [min]="0" [step]="1" [allowOnlyIntegers]="true"
                                      label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.REQUIRED_INPUT_VALUE' | translate }}"
                                      [(ngModel)]="inputSelectedMap.get(getSelectedMapKey(field, whenMatched))[0]"></app-input-number>
                    <app-input-number *ngSwitchCase="GateEditorFieldInputType.FLOAT_NUMBER"
                                      [inputId]="'inputValueNumber_' + field" [min]="0"
                                      label="{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.REQUIRED_INPUT_VALUE' | translate }}"
                                      [(ngModel)]="inputSelectedMap.get(getSelectedMapKey(field, whenMatched))[0]"></app-input-number>

                    <div *ngSwitchCase="GateEditorFieldInputType.SELECT" class="new-form-field">
                        <div class="new-form-field-label-container">
                            <div>
                                <label>{{ 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.REQUIRED_INPUT_VALUE' | translate }}</label>
                            </div>
                        </div>
                        <div class="new-form-field-input-container">
                            <p-multiSelect [inputId]="'inputValueSelect_' + field"
                                           [options]="inputOptionsMap.get(field) | async" appendTo="body"
                                           [ngModel]="inputSelectedMap.get(getSelectedMapKey(field, whenMatched))"
                                           (ngModelChange)="setMultiValue(field, whenMatched, $event)">
                                <ng-template pTemplate="item" let-option>
                                    <label>{{ option.label | translate }}</label>
                                </ng-template>
                                <ng-template pTemplate="selectedItems" let-values>
                                    <div *ngFor="let optionValue of values" class="selected-item">
                                        {{ formatMultiselectValues(inputOptionsMap.get(field) | async, optionValue) | translate }}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="rule-actions">
            <ng-container *ngIf="added; else createNew">
                <app-simple-button [buttonId]="'remove_' + field" icon="remove" type="delete"
                                   (onClick)="remove(field, whenMatched)"></app-simple-button>
            </ng-container>
            <ng-template #createNew>
                <app-simple-button buttonId="add" icon="add" type="main-action"
                                   [disabled]="selectedNewField == null || (!isRequiredFieldsForm && selectedWhenMatched == null)"
                                   (onClick)="add()"></app-simple-button>
            </ng-template>
        </div>
    </div>
</ng-template>
