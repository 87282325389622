import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DiscontinuedCatalogItemInfo} from './discontinued-catalog-item-info';

@Injectable()
export class DiscontinuedCatalogItemInfoService implements CrudService<DiscontinuedCatalogItemInfo> {

    private static readonly API_URL = 'discontinuedCatalogItemInfo';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<DiscontinuedCatalogItemInfo>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<object>(`${DiscontinuedCatalogItemInfoService.API_URL}`, {params: params})
            .pipe(mapListingToJson(DiscontinuedCatalogItemInfo));
    }

    getItem(itemId: number): Observable<DiscontinuedCatalogItemInfo> {
        return this.http.get<object>(`${DiscontinuedCatalogItemInfoService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(DiscontinuedCatalogItemInfo));
    }

    addItem(item: DiscontinuedCatalogItemInfo): Observable<number> {
        return this.http.post<void>(`${DiscontinuedCatalogItemInfoService.API_URL}`, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(DiscontinuedCatalogItemInfoService.API_URL));
    }

    editItem(itemId: number, item: DiscontinuedCatalogItemInfo): Observable<number> {
        return this.http.put<void>(`${DiscontinuedCatalogItemInfoService.API_URL}`, item)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    deleteItem(itemId: number): Observable<void> {
        return this.http.delete<void>(`${DiscontinuedCatalogItemInfoService.API_URL}/${itemId}`);
    }
}
