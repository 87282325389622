import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {PositionService} from "../../position.service";
import {UpdateVatDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-update-vat',
    templateUrl: './update-vat.component.html',
    providers: [PositionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateVatComponent implements OnInit {

    @Input()
    dialogData: UpdateVatDialogData;

    @Output()
    onClose = new EventEmitter();

    @Output()
    onComplete = new EventEmitter<boolean>();

    validationErrors = {};
    submitEnabled = true;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private offerPositionService: PositionService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngOnInit(): void {
        this.submitEnabled = true;
    }

    public submit(): void {
        if (this.validate()) {
            this.submitEnabled = false;

            this.offerPositionService.updateSellVat(this.dialogData.offerId, this.dialogData.vatSell).subscribe({
                complete: () => {
                    this.emitOnSuccess();
                },
                error: error => {
                    this.emitOnError();
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                    this.submitEnabled = true;
                }
            });
        }
    }

    public close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    private validate() {
        this.validationErrors = {};
        if (this.dialogData.vatSell == null) {
            this.validationErrors['vat'] = 'error.offerPositionChangeVatDto.vat.not_null';
        } else if (this.dialogData.vatSell < 0) {
            this.validationErrors['vat'] = 'error.offerPositionChangeVatDto.vat.below_min';
        }

        return Object.keys(this.validationErrors).filter(field => this.validationErrors[field] != undefined).length === 0;
    }

    private emitOnSuccess(): void {
        this.dialogHideHelper.call(() => this.onComplete.emit(true));
    }

    private emitOnError(): void {
        this.dialogHideHelper.call(() => this.onComplete.emit(false));
    }
}
