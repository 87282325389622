import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {ButtonWithMenuElementSelectedEvent} from '../button-with-menu/button-with-menu-event';
import {MenuElement} from '../button-with-menu/MenuElement';
import {MenuType} from '../button-with-menu/MenuType';

@Component({
    selector: 'app-toolbar-button',
    templateUrl: './toolbar-button.component.html',
    styleUrls: ['./toolbar-button.component.css']
})
export class ToolbarButtonComponent implements  OnChanges {

    @Input() buttonId: string;
    @Input() label: string;
    @Input() icon: string;
    @Input() additionalTooltip: string;
    @Input() hotkey: any;
    @Input() disabled: boolean;

    @Input() menuElements: MenuElement[];
    @Output() menuElementSelected = new EventEmitter<ButtonWithMenuElementSelectedEvent>();

    @Output() onClick = new EventEmitter<MouseEvent>();

    formattedTooltip: string;
    menuType = MenuType;

    ngOnChanges(changes: SimpleChanges): void {
        if ('hotkey' in changes || 'label' in changes || 'additionalTooltip' in changes) {
            this.formattedTooltip = this.formatTooltip();
        }
    }

    handleClick(event: MouseEvent): void {
        this.onClick.emit(event);
    }

    handleMenuElementSelected(event: ButtonWithMenuElementSelectedEvent): void {
        this.menuElementSelected.emit(event);
    }

    private formatTooltip(): string {
        let tooltip = this.additionalTooltip ? `${this.label} - ${this.additionalTooltip}` : this.label;
        if (this.hotkey != undefined) {
            return `${tooltip} (${this.formatHotkey()})`;
        }
        return tooltip;
    }

    private formatHotkey(): string {
        // format hotkey combos with nonbreaking space
        if ('formatted' in this.hotkey) {
            return `${this.hotkey.formatted
                .map(comboText => comboText
                    .split(' + ')
                    .join(`${String.fromCharCode(160)}+${String.fromCharCode(160)}`))
                .join(', ')}`;
        }
        return this.hotkey.toString();
    }
}
