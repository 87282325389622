<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGES.LIST' | translate }}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles: ['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGE.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true" [lazyLoadOnInit]="false"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="id"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem" id="entranceGlazingTable">
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="fieldUsage.show(EntranceGlazingPackageField.NAME)" [pSortableColumn]="'name.' + translate.currentLang"
                    [ngClass]="columnHeaderClasses('name')">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.NAME' | translate }}</span>
                    <p-sortIcon [field]="'name.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="name"
                           (input)="table.filter(getInputEventValue($event), 'name', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(EntranceGlazingPackageField.SHORTCUT)" [pSortableColumn]="'shortcut.' + translate.currentLang"
                    [ngClass]="columnHeaderClasses('shortcut')">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SHORTCUT' | translate }}</span>
                    <p-sortIcon [field]="'shortcut.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="shortcut"
                           (input)="table.filter(getInputEventValue($event), 'shortcut', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(EntranceGlazingPackageField.SYMBOL)" [ngClass]="columnHeaderClasses('symbol')" pSortableColumn="symbol">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}</span>
                    <p-sortIcon field="symbol"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="symbol"
                           (input)="table.filter(getInputEventValue($event), 'symbol', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(EntranceGlazingPackageField.GLASSES_QUANTITY)" pSortableColumn="glassesQuantity" [ngClass]="columnHeaderClasses('glassesQuantity')">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.GLASSES_QUANTITY' | translate }}</span>
                    <p-sortIcon field="glassesQuantity"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }" type="number"
                           (click)="handleInputFilterClick($event)" name="glassesQuantity"
                           (input)="table.filter(getInputEventValue($event), 'glassesQuantity', 'equals')">
                </th>
                <th pSortableColumn="sortIndex" [ngClass]="columnHeaderClasses('sortIndex')">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}</span>
                    <p-sortIcon field="sortIndex"></p-sortIcon>
                </th>
                <th *ngIf="fieldUsage.show(EntranceGlazingPackageField.ACTIVE)" [ngClass]="columnHeaderClasses('active')" pSortableColumn="active">
                    <span>{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}</span>
                    <p-sortIcon field="active"></p-sortIcon>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="activeFilter" [options]="filterActiveItems | async"
                                    [ngModel]="filterActiveState" (ngModelChange)="handleFilterActiveChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-glazingPackage let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: glazingPackage, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="glazingPackage" [pSelectableRowIndex]="rowIndex"
                [app-table-keyboard-navigation]="glazingPackage"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: glazingPackage, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td *ngIf="fieldUsage.show(EntranceGlazingPackageField.NAME)">{{ glazingPackage.name[translate.currentLang] }}</td>
                <td *ngIf="fieldUsage.show(EntranceGlazingPackageField.SHORTCUT)">{{ glazingPackage.shortcut[translate.currentLang] }}</td>
                <td *ngIf="fieldUsage.show(EntranceGlazingPackageField.SYMBOL)">{{ glazingPackage.symbol }}</td>
                <td *ngIf="fieldUsage.show(EntranceGlazingPackageField.GLASSES_QUANTITY)">{{ glazingPackage.glassesQuantity }}</td>
                <td>{{ glazingPackage.sortIndex }}</td>
                <td *ngIf="fieldUsage.show(EntranceGlazingPackageField.ACTIVE)">{{ (glazingPackage.active ? 'ENTRANCE_GLAZING_PACKAGE.FORM.ACTIVE' : 'ENTRANCE_GLAZING_PACKAGE.FORM.INACTIVE') | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGE.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'ENTRANCE_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-entrance-glazing-package-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                                           (fileChange)="onFileChange($event)"></app-entrance-glazing-package-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ENTRANCE_GLAZING_PACKAGE.STEPS.MODELS' | translate }}" [id]="STEPS.MODELS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <app-entrance-glazing-package-models-form [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                                  [item]="item"
                                                  [(linkedModelIds)]="selectedModels"
                                                  [entranceModels]="models"
        ></app-entrance-glazing-package-models-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGE.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_GLAZING_PACKAGE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'ENTRANCE_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-entrance-glazing-package-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                                           (fileChange)="onFileChange($event)"></app-entrance-glazing-package-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ENTRANCE_GLAZING_PACKAGE.STEPS.MODELS' | translate }}" [id]="STEPS.MODELS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <app-entrance-glazing-package-models-form [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                                  [item]="item"
                                                  [(linkedModelIds)]="selectedModels"
                                                  [entranceModels]="models"
        ></app-entrance-glazing-package-models-form>
    </app-wizard-step>
</app-wizard-dialog>
