import {Handle} from "./drawing-data/Handle";
import {HandleDirection} from "./drawing-data/HandleDirection";
import {HandleState} from "./drawing-data/HandleState";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {WindowData} from "./drawing-data/WindowData";
import {WindowCalculator} from "./window-calculator";
import {OpeningDirection} from "./window-types/opening-direction";
import {OpeningDirectionUtils} from "./window-types/opening-direction-utils";

export abstract class HandleDataFactory {

    public static createStartingHandles(window: WindowData): void {
        window.subWindows.forEach(subWindow => {
            this.createStartingHandle(subWindow);
        });
    }

    public static createStartingHandle(subWindow: SubWindowData): void {
        if (WindowCalculator.hasHandle(subWindow)) {
            let positionAngle;
            let direction;
            let openingDirection = OpeningDirectionUtils.getSubwindowOpening(subWindow.typeCode);
            switch (openingDirection) {
                case OpeningDirection.G:
                    positionAngle = 270;
                    direction = HandleDirection.RIGHT;
                    break;
                case OpeningDirection.L:
                    positionAngle = 0;
                    direction = HandleDirection.DOWN;
                    break;
                case OpeningDirection.P:
                    positionAngle = 180;
                    direction = HandleDirection.DOWN;
                    break;
                case OpeningDirection.D:
                    positionAngle = 90;
                    direction = HandleDirection.RIGHT;
                    break;
                default:
                    throw new Error("Unsupported opening direction: " + OpeningDirection[openingDirection]);

            }
            subWindow.handle = new Handle(positionAngle, direction, HandleState.DEFAULT);
        }
    }
}
