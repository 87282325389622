import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {SelectItemImpl} from '../../../../common/service/select.item.impl';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ConfigurableAddonsService} from '../../../offer/offers/position/config-addons.service';
import {ConfigurableAddonDefinitionRoDto} from '../../../offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDefinitionRoDto';
import {configurableAddonDefinitionTypeToTranslationKey} from '../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {DealerDiscountComponent, DealerDiscountType} from '../dealer-discount.component';
import {DealerDiscountPerConfigurableAddon} from './dealer-discount-per-configurable-addon';
import {DealerDiscountPerConfigurableAddonService} from './dealer-discount-per-configurable-addon.service';
import {ConfigSystemService} from "../../../window-system/config-system/config-system.service";
import {map} from "rxjs/operators";
import {ConfigSystem} from "../../../window-system/config-system/config-system";

@Component({
    selector: 'app-dealer-discount-per-configurable-addon',
    templateUrl: '../dealer-discount.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [DealerDiscountPerConfigurableAddonService, DataServiceHelper, ConfigSystemService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerDiscountPerConfigurableAddonComponent
    extends DealerDiscountComponent<DealerDiscountPerConfigurableAddon, DealerDiscountPerConfigurableAddonService> implements OnInit {

    configSystems: ConfigSystem[] = [];
    availableDefinitions: SelectItem[] = [];

    item: DealerDiscountPerConfigurableAddon = new DealerDiscountPerConfigurableAddon();

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private configSystemService: ConfigSystemService) {
        super(injector, changeDetector, DealerDiscountPerConfigurableAddonService, 'DealerDiscountPerSystem',
            DealerDiscountType.PER_CONFIGURABLE_ADDON);
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadActiveConfigurableAddonDefinitions();
    }

    getFirstInputId() {
        return 'windowSystemId';
    }

    getNewItem(): DealerDiscountPerConfigurableAddon {
        return new DealerDiscountPerConfigurableAddon();
    }

    onLanguageChange(): void {
        super.onLanguageChange();
        this.rebuildDefinitions();
    }

    prepareItemForRequest(): DealerDiscountPerConfigurableAddon {
        let requestItem = this.getNewItem();
        requestItem.id = this.item.id;
        requestItem.systemId = this.item.systemId;
        requestItem.discountValue = this.item.discountValue;
        requestItem.active = this.item.active;
        requestItem.cascade = this.item.cascade;
        requestItem.validFrom = this.item.validFrom;
        return requestItem;
    }

    private loadActiveConfigurableAddonDefinitions() {
        let filters = {
            withImages: {value: false},
            active: {value: true},
        };
        return this.configSystemService.getItems(undefined, undefined, filters, 'sortIndex', 1)
            .pipe(map(listing => listing.data))
            .subscribe({
                next: data => {
                    this.configSystems = data;
                    this.rebuildDefinitions();
                },
                error: error => {
                    this.setErrors(error);
                },
                complete: () => {
                    console.info('DealerDiscountComponent `getWindowSystems` completed!');
                }
            });
    }

    private rebuildDefinitions() {
        this.availableDefinitions = this.configSystems
            .map(system => new SelectItemImpl(system.name[this.translate.currentLang], system.id));
        if (this.item.systemId == undefined && this.availableDefinitions.length > 0) {
            this.item.systemId = this.availableDefinitions[0].value;
        }
    }

    getTargetOptions(): SelectItem[] {
        return this.availableDefinitions;
    }
}
