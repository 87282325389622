<div class="new-form-400">
    <app-translation-fields inputId="name" label="{{ 'CATALOG_ITEM_TAG.FORM.TAG_TEXT' | translate }}"
                            validationKeyPrefix="tagText" [validationErrors]="validationErrors" [maxlength]="50"
                            [required]="true" [field]="item.tagText"></app-translation-fields>

    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'CATALOG_ITEM_TAG.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div class="new-form-row">
        <app-input-text inputId="color" label="{{ 'CATALOG_ITEM_TAG.FORM.COLOR' | translate }}"
                        [(validationMessageKey)]="validationErrors['color']" [maxlength]="7"
                        [(ngModel)]="item.color"></app-input-text>
    </div>

    <div class="new-form-row" *ngIf="item.id != null">
        <app-input-number inputId="sortIndex" label="{{ 'CATALOG_ITEM_TAG.FORM.SORT_INDEX' | translate }}"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab header="{{ 'CATALOG_ITEM_TAG.FORM.ICON_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['icon'] != undefined}">
                <app-file-upload inputId="image" [(validationMessageKey)]="validationErrors['icon']"
                                 addButtonLabel="{{ 'CATALOG_ITEM_TAG.FORM.SELECT_FILE_UPLOAD' | translate }}"
                                 changeButtonLabel="{{ 'CATALOG_ITEM_TAG.FORM.CHANGE_FILE_UPLOAD' | translate }}"
                                 [maxSize]="50000" [maxWidth]="400" [maxHeight]="400" [ngModel]="iconFile"
                                 [accept]="'.svg'" [validationFileType]="'image/svg+xml'"
                                 (ngModelChange)="handleIconFileChange($event)"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
