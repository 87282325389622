import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TranslationTarget} from '../../translations/ui-translations/translation-target.enum';
import {UiTranslation} from '../../translations/ui-translations/ui-translation';
import {WebshopUiDefaultTranslation} from './webshop-ui-default-translation';

@Injectable()
export class WebshopUiTranslationsService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getTranslations(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                    sortColumn: string, sortOrder: number): Observable<Listing<UiTranslation>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('translations-per-subsystem', {params: params})
            .pipe(mapListingToJson(UiTranslation));
    }

    saveTranslations(translations: UiTranslation): Observable<void> {
        return this.http.post<void>('translations-per-subsystem', translations);
    }

    deleteTranslation(key: string, target: TranslationTarget): Observable<void> {
        return this.http.delete<void>('translations-per-subsystem', {
            params: {
                key: key,
                target: target
            }
        });
    }

    getAvailableTranslationKeys(target: TranslationTarget, language: string): Observable<WebshopUiDefaultTranslation[]> {
        return this.http.get<WebshopUiDefaultTranslation[]>('translations-per-subsystem/keys', {
            params: {
                target: target,
                language: language
            }
        });
    }
}
