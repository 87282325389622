import {WindowSystemInterface} from '../catalog-data/window-system-interface';
import {DrawingDataSpecification} from "../drawing-data/drawing-data";
import {WindowAddon} from "../drawing-data/WindowAddon";
import {WindowData} from "../drawing-data/WindowData";
import {WindowCalculator} from "../window-calculator";

export enum DrawingDataFixedWindowContent {
    ONLY_F_WINDOWS_INCLUDED = 'ONLY_F_WINDOWS_INCLUDED',
    ONLY_FIXED_WINDOWS_INCLUDED = 'ONLY_FIXED_WINDOWS_INCLUDED',
    OPENABLE_WINDOWS_INCLUDED = 'OPENABLE_WINDOWS_INCLUDED',
}

export class DrawingDataSpecificationUtil {

    private static getFixedWindowContent(windows: WindowData[]): DrawingDataFixedWindowContent {
        if (windows.some(window => window.subWindows.some(subwindow => !WindowCalculator.isSubWindowFixed(subwindow)))) {
            return DrawingDataFixedWindowContent.OPENABLE_WINDOWS_INCLUDED;
        }
        if (windows.every(window => window.subWindows.every(subwindow => WindowCalculator.isSubWindowF(subwindow)))) {
            return DrawingDataFixedWindowContent.ONLY_F_WINDOWS_INCLUDED;
        }
        return DrawingDataFixedWindowContent.ONLY_FIXED_WINDOWS_INCLUDED;
    }

    public static clearUnnecessaryFields(specification: DrawingDataSpecification, windows: WindowData[],
        conditionParams: { windowSystem?: WindowSystemInterface } | undefined): DrawingDataFixedWindowContent {
        const content = DrawingDataSpecificationUtil.getFixedWindowContent(windows);
        if (content !== DrawingDataFixedWindowContent.OPENABLE_WINDOWS_INCLUDED) {
            specification.opening = null;
            specification.cover = new WindowAddon();
            specification.fittingAdditionalInsertion = new WindowAddon();
            specification.fittingBrake = new WindowAddon();
            specification.fittingEspagnoletteType = new WindowAddon();
            specification.fittingLock = new WindowAddon();
            specification.fittingLockTerrace = new WindowAddon();
            specification.fittingLockTerraceLocation = undefined;
            specification.fittingInsertion = new WindowAddon();
            specification.fittingMainInsertion = new WindowAddon();
            specification.fittingSliding = new WindowAddon();
            specification.fittingType = new WindowAddon();
            specification.fittingAutomaticDrive = new WindowAddon();
            specification.fittingVeranda = new WindowAddon();
            specification.fittingAutomaticDrive = new WindowAddon();
            specification.handleType = new WindowAddon();
            specification.terraceHandle = new WindowAddon();
            specification.terraceHandleLayout = null;
            if (conditionParams == undefined
                || conditionParams.windowSystem == undefined
                || !conditionParams.windowSystem.doorstepAllowedInFF
                || content === DrawingDataFixedWindowContent.ONLY_F_WINDOWS_INCLUDED) {
                specification.doorstepId = null;
                specification.channelSectionId = null;
            }
        }
        return content;
    }
}
