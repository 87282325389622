import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractSellDealerDiscountService} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.service';
import {SubsystemConfigurableAddonSellDealerDiscount} from './subsystem-configurable-addon-sell-dealer-discount';

@Injectable()
export class SubsystemConfigurableAddonSellDealerDiscountService
    extends AbstractSellDealerDiscountService<SubsystemConfigurableAddonSellDealerDiscount> {

    constructor(http: HttpClient) {
        super(http, SubsystemConfigurableAddonSellDealerDiscount);
    }

    getApiBaseUrl(): string {
        return 'sellDealerDiscount/subsystem/configurableAddon';
    }

    addSearchParams(params: { [param: string]: string | string[] }, id: number, groupId: number): void {
        if (id != undefined) {
            params['subsystemId'] = '' + id;
        }
        params['subsystemGroupId'] = '' + groupId;
    }
}
