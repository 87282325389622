<app-wizard-dialog #wizard [header]="getDialogHeader() | translate" (onCancel)="notifyParent('cancel')"
                   (onComplete)="submit()" dialogStyleClass="width-60vw-no-padding"
                   [contentStyleClass]="getDialogStyle()">
    <app-wizard-step label="{{ 'CLIENT-DETAILS.FORM.GENERAL' | translate }}" [id]="STEPS.GENERAL"
                     [validate]="generalStepValidator">
        <app-client-details-form *ngIf="client" [client]="client" [selectCurrencies]="selectCurrencies"
                                 [selectUsers]="selectUsers" [validationErrors]="validationErrors"
                                 [existingGroups]="existingGroups" [suppliers]="suppliers"
                                 [groupSimpleProfitMarginSource]="groupSimpleProfitMarginSource"
                                 [simpleProfitMarginSource]="simpleProfitMarginSource"
                                 (onClientGroupChanged)="reloadProfitMargins($event)"></app-client-details-form>
    </app-wizard-step>
    <app-wizard-step
            *ngIf="initialDataLoaded && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource"
            label="{{ 'CLIENT-DETAILS.FORM.PROFIT_MARGINS' | translate }}" [id]="STEPS.SYSTEM_MARGINS">
        <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                           entityType="client" [userCanEdit]="true"
                           [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
        <div *ngIf="client.groupId == undefined"
             class="new-form-600">{{ 'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate }}</div>
    </app-wizard-step>
    <app-wizard-step
            *ngIf="initialDataLoaded && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource"
            label="{{ 'CLIENT-DETAILS.FORM.ROOF_PROFIT_MARGINS' | translate }}" [id]="STEPS.ROOF_SYSTEM_MARGINS">
        <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                           entityType="client" [userCanEdit]="true"
                           [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
        <div *ngIf="client.groupId == undefined"
             class="new-form-600">{{ 'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate }}</div>
    </app-wizard-step>
    <app-wizard-step
            *ngIf="initialDataLoaded && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource"
            label="{{ 'CLIENT-DETAILS.FORM.ENTRANCE_PROFIT_MARGINS' | translate }}" [id]="STEPS.ENTRANCE_SYSTEM_MARGINS">
        <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                           entityType="client" [userCanEdit]="true"
                           [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
        <div *ngIf="client.groupId == undefined"
             class="new-form-600">{{ 'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate }}</div>
    </app-wizard-step>
    <app-wizard-step
            *ngIf="initialDataLoaded && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource"
            label="{{ 'CLIENT-GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}" [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                           entityType="client" [userCanEdit]="true" [target]="possibleTargets.CONFIG"
                           [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="confAddGlobalMarginInputId"></app-profit-margin>
        <div *ngIf="!client.groupId" class="new-form-600">{{ 'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate }}</div>
    </app-wizard-step>
    <app-wizard-step
            *ngIf="initialDataLoaded && isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource"
            label="{{ 'CLIENT-GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}" [id]="STEPS.GATES_MARGINS">
        <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                           entityType="client" [userCanEdit]="true" [target]="possibleTargets.GATE"
                           [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="gatesGlobalMarginInputId"></app-profit-margin>
        <div *ngIf="!client.groupId" class="new-form-600">{{ 'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate }}</div>
    </app-wizard-step>
</app-wizard-dialog>
