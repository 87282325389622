<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <ng-container *ngIf="sideNavItems.length > 0">
            <div class="title">
                <div class="text">{{ 'NAVIGATION.ITEMS.ADMIN_PANEL' | translate }}</div>
            </div>
            <div *ngFor="let item of sideNavItems" [id]="item.cssClass" [routerLink]="item.routerLink !== '' ? item.routerLink : item.cssClass"
                 class="navigation-element" routerLinkActive="active">
                <div class="name">{{ item.name | translate }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="upsellingNavItems.length > 0">
            <div class="title">
                <div class="text">{{ 'ADMIN_PANEL.UPSELLING.TITLE' | translate }}</div>
            </div>
            <div *ngFor="let item of upsellingNavItems" [id]="item.cssClass" [routerLink]="item.cssClass"
                 class="navigation-element" routerLinkActive="active">
                <div class="name">{{ item.name | translate }}</div>
            </div>
        </ng-container>
    </div>
    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <router-outlet></router-outlet>
    </div>
</div>
