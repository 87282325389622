import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {OfferStatus} from '../../../common/enums/OfferStatus';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {PaymentPackage} from './payment-package';

@Injectable()
export class PaymentPackageService implements CrudService<PaymentPackage> {

    private static readonly API_URL = 'paymentPackage';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [field: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<PaymentPackage>> {
        const searchParams = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(`${PaymentPackageService.API_URL}`, {params: searchParams}).pipe(
            map(response => Listing.fromJSON(PaymentPackage, response)));
    }

    getItem(itemId: number): Observable<PaymentPackage> {
        return this.http.get<object>(`${PaymentPackageService.API_URL}/${itemId}`).pipe(
            map(response => PaymentPackage.fromJSON(response)));
    }

    addItem(item: PaymentPackage): Observable<number> {
        return this.http.post<void>(`${PaymentPackageService.API_URL}`, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(PaymentPackageService.API_URL, response)));
    }

    editItem(itemId: number, item: PaymentPackage): Observable<number> {
        return this.http.put<void>(`${PaymentPackageService.API_URL}/${itemId}`, item).pipe(
            map(() => itemId));
    }

    setStatus(paymentPackageId: number, status: OfferStatus): Observable<void> {
        return this.http.post<void>(`${PaymentPackageService.API_URL}/setStatus/${paymentPackageId}/${status}`, undefined);
    }

    validateGeneralData(item: PaymentPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${PaymentPackageService.API_URL}/validateGeneralData`, item));
    }

    validateSubsystemData(item: PaymentPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${PaymentPackageService.API_URL}/validateSubsystemData`, item));
    }

    deletePackage(paymentPackageId: number): Observable<void> {
        return this.http.delete<void>(`${PaymentPackageService.API_URL}/${paymentPackageId}`);
    }
}
