import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {Country} from '../../../common/enums/country';
import {InputZipComponent} from '../../../common/input-zip/input-zip.component';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {FormHandler} from '../../../form-inputs/inputs/abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../../../form-inputs/inputs/form-handler-token';
import {Subsystem} from '../subsystem';
import {TabularAddress} from '../tabularAddress';

@Component({
    selector: 'app-subsystem-address-form',
    templateUrl: './subsystem-address-form.component.html',
    styleUrls: ['./subsystem-address-form.component.css'],
    providers: [TranslatedSelectItemService]
})
export class SubsystemAddressFormComponent implements OnInit, OnChanges {

    @Input()
    subsystem: Subsystem;

    @Input()
    addresses: TabularAddress[];

    @Input()
    correspondenceAddress: TabularAddress;

    @Output()
    readonly correspondenceAddressChange = new EventEmitter<TabularAddress>();

    @Input()
    deliveryAddress: TabularAddress;

    @Output()
    readonly deliveryAddressChange = new EventEmitter<TabularAddress>();

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    disabledFields = false;

    @ViewChild('correspondenceAddressZip', {static: true})
    correspondenceAddressZipInput: InputZipComponent;

    countries: Observable<SelectItem[]>;

    addressSelectionDialogType: 'none' | 'correspondence' | 'delivery' = 'none';

    constructor(private translatedSelectItemService: TranslatedSelectItemService,
                @Inject(FORM_HANDLER) @Optional() private form: FormHandler) {
    }

    ngOnInit(): void {
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
        this.updateSelectedAddresses(this.addresses);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('addresses' in changes) {
            const addresses = changes['addresses'];
            this.updateSelectedAddresses(addresses.currentValue);
        }
    }

    private updateSelectedAddresses(addresses: TabularAddress[]): void {
        if (this.subsystem.primaryCorrespondenceAddressId != undefined) {
            this.correspondenceAddress = addresses.find(address => address.id === this.subsystem.primaryCorrespondenceAddressId);
            if (this.correspondenceAddress == undefined) {
                this.subsystem.primaryCorrespondenceAddressId = undefined;
            }
            this.correspondenceAddressChange.emit(this.correspondenceAddress);
        }
        if (this.subsystem.primaryDeliveryAddressId != undefined) {
            this.deliveryAddress = addresses.find(address => address.id === this.subsystem.primaryDeliveryAddressId);
            if (this.deliveryAddress == undefined) {
                this.subsystem.primaryDeliveryAddressId = undefined;
            }
            this.deliveryAddressChange.emit(this.deliveryAddress);
        }
    }

    copyCorrespondenceAddressToDelivery(): void {
        this.subsystem.primaryDeliveryAddressId = this.subsystem.primaryCorrespondenceAddressId;
        this.deliveryAddressChange.emit(this.correspondenceAddress);
    }

    handleAddressSelected(address: TabularAddress): void {
        switch (this.addressSelectionDialogType) {
            case 'correspondence':
                delete this.validationErrors['correspondenceAddress'];
                // clear dialog tab marker
                this.form.onInputChange(address.address.zip, this.correspondenceAddressZipInput);
                this.correspondenceAddressChange.emit(address);
                this.subsystem.primaryCorrespondenceAddressId = address.id;
                break;
            case 'delivery':
                delete this.validationErrors['deliveryAddress'];
                this.deliveryAddressChange.emit(address);
                this.subsystem.primaryDeliveryAddressId = address.id;
                break;
        }
        this.addressSelectionDialogType = 'none';
    }

    handleAddressSelectionVisibilityChange(visible: boolean): void {
        if (!visible) {
            this.addressSelectionDialogType = 'none';
        }
    }
}
