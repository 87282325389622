import {AddonFor} from "../../../../window-designer/enums/AddonFor";
import {QuantityType} from "../../../../window-designer/enums/QuantityType";
import {AddonDefaultQuantityExpresionCalculator} from "../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityExpresionCalculator";
import {DecimalValidator, IntegerValidator, MultiValidator} from "../../../shared/validator/input-validator";
import {Addon} from "./addon";
import {COMMON_INPUT_LENGTH_LIMITS} from "../../../common/crud-common/common-input-length-limits";
import {AddonCustomFieldsUtil} from "./addon-custom-fields-util";
import {AddonCategoryEnum} from "../../../../window-designer/enums/AddonCategoryEnum";
import {SupportedLanguages} from "../../../supportedLanguages";

export class AddonsValidatorService {

    private validationErrors = {};

    constructor(validationErrors: any) {
        this.validationErrors = validationErrors;
    }

    public validateGeneralDataPage(addon: Addon, userLang: string, isSubsystemAddon: boolean): void {

        this.validationErrors[`name[${userLang}]`] = MultiValidator.of(`error.addonDto.name[${userLang}]`)
            .withNotNullValidator()
            .withNotBlankValidator()
            .validate(addon.name[userLang]);

        for (let lang of SupportedLanguages.languages) {
            if (addon.name[lang.code]) {
                this.validationErrors[`name[${lang.code}]`] = MultiValidator.of(`error.addonDto.name[${lang.code}]`)
                    .withNotBlankValidator()
                    .validate(addon.name[lang.code]);
            }
        }

        this.validationErrors['category'] = MultiValidator.of('error.addonDto.category')
            .withNotNullValidator()
            .validate(addon.category);
        this.validationErrors['addonFor'] = MultiValidator.of('error.addonDto.addonFor')
            .withNotNullValidator()
            .validate(addon.addonFor);
        this.validationErrors['supplier'] = MultiValidator.of('error.addonDto.supplier')
            .withNotNullValidator()
            .validate(addon.supplier);

        this.validateDefaultQuantity(addon);

        if (addon.addonFor !== AddonFor.CONFIG_ADDON) {
            this.validationErrors['quantityType'] = MultiValidator.of('error.addonDto.quantityType')
                .withNotNullValidator()
                .validate(addon.quantityType);
        }

        this.validatePrice(addon);

        if (addon.pcn) {
            let pattern = /PCN [0-9][0-9] [0-9][0-9] [0-9][0-9] [0-9][0-9]/g;
            if (!pattern.test(addon.pcn)) {
                this.validationErrors['pcn'] = 'error.addonDto.pcn.pattern_not_matched';
            }
        }
        if (addon.chooseColor && addon.coreColors.length === 0) {
            this.validationErrors['colors'] = 'error.addonDto.colors.not_empty';
        }

        if (!isSubsystemAddon) {
            this.validationErrors['unitWeight'] = MultiValidator.of('error.addonDto.unitWeight')
                .withNotNullValidator()
                .withDecimalValidator()
                .withRangeValidator(0, 9999)
                .validate(addon.unitWeight);
            this.validationErrors['sortIndex'] = MultiValidator.of('error.addonDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(addon.sortIndex);
            if (addon.addonFor !== AddonFor.BULK && addon.category !== AddonCategoryEnum.GATE_NOT_SIDEBAR) {
                this.validationErrors['availableIn'] = MultiValidator.of('error.addonDto.availableIn')
                    .withNotNullValidator()
                    .validate(addon.availableIn);
            }
        }

        this.validateCustomFields(addon);
    }

    private validateCustomFields(addon: Addon) {
        if (AddonCustomFieldsUtil.showCustomValueField(addon)) {
            this.validationErrors['customStringValue'] = MultiValidator.of('error.addonDto.customStringValue.CONNECTORS_EXTENDERS_PCV')
                                                                       .withSizeValidator(0, COMMON_INPUT_LENGTH_LIMITS.NAME_MAX_LENGTH)
                                                                       .withWidthsValidator(0, 1000, true, false)
                                                                       .validate(addon.customStringValue);
        }
    }
    private validateDefaultQuantity(addon: Addon) {
        if (addon.defaultQuantity || addon.defaultQuantity === 0) {

            if (addon.addonFor == AddonFor[AddonFor.WINDOW] && Number.isNaN(Number(addon.defaultQuantity))) {
                if (!AddonDefaultQuantityExpresionCalculator.isValid(addon.defaultQuantity)) {
                    this.validationErrors['defaultQuantity'] = 'error.addonDto.defaultQuantity.expresion_not_valid';
                }
                return;
            }

            if (AddonsValidatorService.usesMetersAsQuantityType(addon) || AddonsValidatorService.usesSquareMetersAsQuantityType(addon)) {
                if (!new DecimalValidator().isValid("" + addon.defaultQuantity) || !AddonsValidatorService.isDefaultQuantityInRange(addon)) {
                    this.validationErrors['defaultQuantity'] = 'error.addonDto.defaultQuantity.not_in_range';
                }
            } else if (!new IntegerValidator().isValid("" + addon.defaultQuantity) || !AddonsValidatorService.isDefaultQuantityInRange(addon)) {
                this.validationErrors['defaultQuantity'] = 'error.addonDto.defaultQuantity.integer_not_in_range';
            }
        } else {
            this.validationErrors['defaultQuantity'] = 'error.addonDto.defaultQuantity.not_empty';
        }
    }

    private static isDefaultQuantityInRange(addon: Addon) {
        let defaultQuantityAsNumber = Number(("" + addon.defaultQuantity).replace(",", "."));

        return defaultQuantityAsNumber > 0 && defaultQuantityAsNumber < 1000000;
    }

    private static usesMetersAsQuantityType(addon: Addon) {
        return addon.quantityType === QuantityType.METER;
    }

    private static usesSquareMetersAsQuantityType(addon: Addon) {
        return addon.quantityType === QuantityType.SQUARE_METER;
    }

    private static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private static isType(type: string, addon: Addon): boolean {
        if (addon) {
            return addon.price.type === type;
        }

        return false;
    }

    private validatePrice(addon: Addon) {
        if (!addon.price.type) {
            this.validationErrors['price.type'] = 'error.addonDto.price.type.not_empty';
        } else {
            if (AddonsValidatorService.isType('AMOUNT', addon)) {
                if (!addon.price.value && addon.price.value !== 0) {
                    this.validationErrors['price.value'] = 'error.addonDto.price.value.not_exists';
                } else if (!AddonsValidatorService.isNumeric(addon.price.value)) {
                    this.validationErrors['price.value'] = 'error.addonDto.price.value.pattern_not_matched';
                } else if (addon.addonFor === AddonFor[AddonFor.BULK] && addon.price.value < 0) {
                    this.validationErrors['price.value'] = 'error.addonDto.price.value.not_in_range';
                }
            }
            if (AddonsValidatorService.isType('PERCENT', addon)) {
                if (!addon.price.value && addon.price.value !== 0) {
                    this.validationErrors['price.value'] = 'error.addonDto.price.value.not_exists';
                } else if (!AddonsValidatorService.isNumeric(addon.price.value)) {
                    this.validationErrors['price.value'] = 'error.addonDto.price.value.pattern_not_matched';
                }
            }
            if (AddonsValidatorService.isType('PRICE_GROUP', addon)) {
                if (!addon.price.priceGroupDesc) {
                    this.validationErrors['price.priceGroupDesc'] = 'error.addonDto.price.priceGroupDesc.not_empty';
                }
            }
        }
    }
}
