<div class="list-content">
    <div class="table-header">
        <div class="title">{{'CLIENT-GROUP.TITLE' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="permissions.canCreateClientgroup()"
                                   buttonId="addClientGroup"
                                   label="{{ 'CLIENT-GROUP.FORM.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="clientGroups" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [seqNum]="2"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadClientGroupsLazy($event)" [responsive]="true"
                 [(selection)]="selectedClientGroup"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="clientGroupsTable">
        <!-- ACTIONS -->
        <p-column header="{{ 'CLIENT-GROUP.FORM.ACTIONS' | translate }}" [sortable]="false" [style]="{'width':'152px'}">
            <ng-template let-clientGroup="rowData" pTemplate="body">
                <div class="table-row-action-buttons">
                    <app-simple-button buttonId="show" icon="person_outline" type="main-action" [size]="36"
                                       (onClick)="actionOnClick('SHOW_CLIENTS', clientGroup)"
                                       pTooltip="{{ 'CLIENT-GROUP.FORM.SHOW_CLIENTS' | translate }}"></app-simple-button>
                </div>
            </ng-template>
        </p-column>

        <p-column field="name" header="{{ 'CLIENT-GROUP.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains"></p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-edit-client-group *ngIf="editedClientGroup" (onCancel)="handleCancel()" (onSave)="handleSave()" (onDelete)="handleDelete($event)"
    [clientGroup]="editedClientGroup"></app-edit-client-group>
