<div class="saved-filter-container second-level-menu">
    <div class="saved-filter-action-container">
        <div class="navigation-element action-element saved-filter-action-element" (click)="createFilter()">
            <div class="name">{{ 'SAVED_FILTER.CREATE_FILTER' | translate }}</div>
        </div>

        <div class="navigation-element action-element saved-filter-action-element" (click)="deleteFilter()">
            <div class="name" [ngClass]="{'saved-filter-action-element-disabled': filters.length == 0}">{{ 'SAVED_FILTER.DELETE_FILTER' | translate }}</div>
        </div>
    </div>

    <div class="title saved-filter-header">
        <div class="text">{{ 'SAVED_FILTER.FILTERS_HEADER' | translate }}</div>
    </div>

    <div *ngFor="let filter of filters"
         class="navigation-element saved-filter-navigation-element"
         [class.active-secondary]="isFilterSelected(filter)"
         (click)="selectFilter(filter)">

        <div class="name" pTooltip="{{getTooltipMessage(filter)}}">{{ filter.name }}</div>
    </div>

    <div *ngIf="showCreateFilterDialog" id="createFilterDialog" class="dialog-window">
        <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true"
                  (onHide)="hideCreateFilterDialog()" [modal]="true" [focusOnShow]="false">
            <p-header>
                <div class="p-dialog-title">
                    {{ 'SAVED_FILTER.CREATE_DIALOG.HEADER' | translate }}
                </div>
            </p-header>

            <div class="new-form-400">
                <div class="new-form-row">
                    <app-input-text inputId="newFilterName"
                                    label="{{ 'SAVED_FILTER.CREATE_DIALOG.FILTER_NAME' | translate }}"
                                    [(validationMessageKey)]="validationErrors['newFilterName']" [maxlength]="50"
                                    [(ngModel)]="newFilterName" [required]="true">
                    </app-input-text>
                </div>

                <div class="new-form-row">
                    <app-text-area inputId="newFilterDescription"
                                    label="{{ 'SAVED_FILTER.CREATE_DIALOG.FILTER_DESCRIPTION' | translate }}"
                                    [(validationMessageKey)]="validationErrors['newFilterDescription']" [maxlength]="512"
                                    [(ngModel)]="newFilterDescription">
                    </app-text-area>
                </div>
            </div>

            <p-footer>
                <div class="p-dialog-buttonpane">
                    <app-simple-button buttonId="confirm"
                                       [label]="'GENERAL.SAVE' | translate"
                                       type="main-action" [size]="40" (onClick)="confirmSaveFilter()"></app-simple-button>

                    <app-simple-button buttonId="cancel"
                                       [label]="'GENERAL.CANCEL' | translate"
                                       type="cancel" [size]="40" (onClick)="hideCreateFilterDialog()"></app-simple-button>
                </div>
            </p-footer>
        </p-dialog>
    </div>

    <div *ngIf="showDeleteFilterDialog" id="deleteFilterDialog" class="dialog-window">
        <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true"
                  (onHide)="hideDeleteFilterDialog()" [modal]="true" [focusOnShow]="false">

            <p-header>
                <div class="p-dialog-title">
                    {{ 'SAVED_FILTER.DELETE_DIALOG.HEADER' | translate }}
                </div>
            </p-header>
            <div class="new-form-400">
                <div class="scrolled-dialog">
                    <div *ngFor="let filter of filtersToDelete" class="new-form-row">
                        <app-checkbox [label]="filter.name"
                                      inputId="{{ 'filter_' + filter.id }}"
                                      [(ngModel)]="filter.selected"
                                      pTooltip="{{getTooltipMessage(filter)}}"
                                      tooltipPosition="left"></app-checkbox>
                    </div>
                </div>
            </div>

            <p-footer>
                <div class="p-dialog-buttonpane">
                    <app-simple-button buttonId="confirm"
                                       [label]="'GENERAL.DELETE' | translate"
                                       [disabled]="!isAnyFilterToDeleteSelected()"
                                       type="main-action" [size]="40" (onClick)="confirmDeleteFilter()"></app-simple-button>

                    <app-simple-button buttonId="cancel"
                                       [label]="'GENERAL.CANCEL' | translate"
                                       type="cancel" [size]="40" (onClick)="hideDeleteFilterDialog()"></app-simple-button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
</div>
