import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {PromotionInformation} from './promotion-information';

@Injectable()
export class PromotionInformationService implements CrudService<PromotionInformation> {

    private static readonly API_URL = 'promotionInformation';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<PromotionInformation>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(PromotionInformationService.API_URL, {params: params})
            .pipe(mapListingToJson(PromotionInformation));
    }

    getItem(itemId: number): Observable<PromotionInformation> {
        return this.http.get<object>(`${PromotionInformationService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(PromotionInformation));
    }

    addItem(item: PromotionInformation, file?: File): Observable<number> {
        return this.http.post<void>(PromotionInformationService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(PromotionInformationService.API_URL));
    }

    editItem(itemId: number, item: PromotionInformation, file?: File): Observable<number> {
        return this.http.put<void>(PromotionInformationService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    getActivePromotions(): Observable<PromotionInformation[]> {
        return this.http.get<object[]>(`${PromotionInformationService.API_URL}/active`)
            .pipe(map(result => result.map(PromotionInformation.fromJSON)));
    }
}
