import {TranslateService} from '@ngx-translate/core';
import {PriceWithTax} from '../../../offer';
import {Charge} from './Charge';

export class GroupedProductCharge {

    charge: Charge;
    count: number;

    constructor(charge: Charge, count: number) {
        this.charge = charge;
        this.count = count;
    }

    isChargeEqual(otherCharge: Charge, translateService: TranslateService): boolean {
        return otherCharge.type === this.charge.type && this.isChargeNamesEqual(otherCharge, translateService) &&
            this.isPriceWithTaxEqual(otherCharge.venskaBuyPrice, this.charge.venskaBuyPrice) &&
            this.isPriceWithTaxEqual(otherCharge.buyPrice, this.charge.buyPrice) &&
            this.isPriceWithTaxEqual(otherCharge.sellPrice, this.charge.sellPrice);
    }

    private isPriceWithTaxEqual(price1: PriceWithTax, price2: PriceWithTax): boolean {
        return (price1 == undefined && price2 == undefined) || (price1 && price2 && price1.grossValue === price2.grossValue &&
            price1.netValue === price2.netValue && price1.vatPercent === price2.vatPercent);
    }

    private isChargeNamesEqual(otherCharge: Charge, translateService: TranslateService): boolean {
        return otherCharge.name[translateService.currentLang] === this.charge.name[translateService.currentLang];
    }
}
