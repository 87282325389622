import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, mergeMap, shareReplay, take, tap} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {Permissions} from '../../auth/permission.service';
import {StorageService} from '../../auth/storage.service';
import {UserUiConfigService} from '../../auth/uiconfig/userUiConfig.service';
import {CommonErrorHandler} from '../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {UserRoleTypes} from '../../common/enums/UserRoleTypes';
import {SavedFilterService} from '../../common/saved-filter/saved-filter.service';
import {CrudComponent} from '../../common/service/crud.component';
import {DataTableColumnBuilder, SavedShownColumns} from '../../common/service/data.table.column.builder';
import {ExportComponent} from '../../common/service/export.component';
import {TranslatedSelectItemService} from '../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from '../../common/service/translated.select.item';
import {ValidationService} from '../../common/service/validation.service';
import {RoleService} from '../admin-panel/role/role.service';
import {IsAnotherUserLoggedService} from '../login/isAnotherUserLogged';
import {HasSavedFilter} from '../offer/has-saved-filter';
import {SubsystemService} from '../subsystem/subsystem.service';
import {SubsystemSelectionItem} from '../subsystem/SubsystemSelectionItem';
import {User} from './user';
import {UserService} from './user.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['../shared-styles.css'],
    providers: [UserService, DataServiceHelper, ValidationService, ExportComponent, StorageService, UserUiConfigService,
        TranslatedSelectItemService, SubsystemService, RoleService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent extends CrudComponent implements OnInit, OnDestroy, HasSavedFilter {

    readonly USERS_TABLE_ID: string = 'usersTable';

    users: User[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    roleSelectItems: Observable<SelectItem[]>;
    additionalRoleSelectItems: Observable<SelectItem[]>;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    subsystemsSelect: SelectItem[] = [];
    subsystems: SubsystemSelectionItem[] = [];
    selectedSubsystems: string[] = [];
    selectedAdditionalRoles: string[] = [];
    filterOwnUsersEnabled = false;

    selectedUser: User;
    isAnotherLogged: boolean;

    lastLoadEvent: LazyLoadEvent;
    file: File;
    editedUser: User;
    displaySubstitueUserDialog: boolean;
    displayDialog = false;
    substituteUsers: SelectItem[];

    @ViewChild('dt', {static: true}) dt: DataTable;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    constructor(public permissions: Permissions,
                private router: Router,
                private userService: UserService,
                private subsystemService: SubsystemService,
                private roleService: RoleService,
                private storage: StorageService,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private auth: AuthService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'UserComponent', false);
        this.filterActive = CrudCommonComponent.buildActiveDropdown('GENERAL.STATUS_SINGULAR');
        this.defaultActiveFilter = this.filterActive[1];
    }

    getDatatable(): DataTable {
        return this.dt;
    }

    ngOnInit(): void {
        if (this.permissions.canCreateUser()) {
            this.hotkeysService.add(this.newElementHotkey);
        }
        this.initShownColumns();
        this.roleSelectItems = this.translatedSelectItemService.buildSortedDropdown(UserRoleTypes, 'ROLE.', '');
        this.additionalRoleSelectItems = this.roleService.getItems(undefined, undefined, {primary: {value: 'false'}}, undefined, undefined)
            .pipe(
                map(listing => listing.data.map(role => ({label: role.roleName, value: `${role.id}`}))),
                shareReplay(),
                tap(() => this.changeDetector.markForCheck()));
        this.createTable();
        this.initSubsystems();
        this.resetDefaultFilterSelection();
        this.langTranslateSubscription.push(this.isAnotherUserLoggedService.item$.subscribe(isAnotherLogged => {
            this.isAnotherLogged = isAnotherLogged;
        }));
    }

    public reloadComponent(): void {
        let origin;
        if (this.lastLoadEvent) {
            origin = this.lastLoadEvent.origin;
        }
        this.createTable();
        this.resetDefaultFilterSelection();
        this.initSubsystems();
        if (this.lastLoadEvent) {
            this.lastLoadEvent.origin = origin;
            if (this.lastLoadEvent.origin) {
                this.loadItemsLazy(this.lastLoadEvent);
            }
        }
        this.changeDetector.markForCheck();
    }

    resetDefaultFilterSelection() {
        this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
        if (this.lastLoadEvent != undefined) {
            this.applySavedFilter(this.lastLoadEvent);
        }
    }

    private initSubsystems(): void {
        this.subsystemService.getNames().subscribe({
            next: subsystemList => {
                this.subsystems = subsystemList.data;
                this.subsystemsSelect = subsystemList.data.map(subsystem => {
                    return {
                        label: subsystem.name,
                        value: `${subsystem.id}`
                    };
                });
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    refreshTable(): void {
        if (this.dt) {
            this.loadItemsLazy(this.dt.createLazyLoadMetadata());
        }
    }

    isImpersonationAllowed(user: User): boolean {
        if (this.isAnotherLogged) {
            return false;
        } else if (!user.active || (user.accountExpirationDate != undefined && user.accountExpirationDate < new Date())) {
            return false;
        } else if (!this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return false;
        } else {
            if (user.roleName === 'KOORDYNATOR' || user.roleName === 'WEBSHOP') {
                return false;
            } else if (user.roleName === 'OPIEKUN') {
                return this.permissions.isKoordynator();
            } else {
                let subsystem = (this.subsystems || []).find(s => s.id === user.subsystemId);
                return subsystem != null && subsystem.impersonationAllowed;
            }
        }
    }

    private createTable(): void {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('id', 'USER-DETAILS.FORM.ID', true)
            .add('login', 'USER-DETAILS.FORM.LOGIN', true)
            .add('firstName', 'USER-DETAILS.FORM.FIRST_NAME', true)
            .add('lastName', 'USER-DETAILS.FORM.LAST_NAME', true)
            .add('email', 'USER-DETAILS.FORM.EMAIL', true)
            .add('interfaceLanguage', 'USER-DETAILS.FORM.INTERFACE_LANG', false)
            .add('otherInfo', 'USER-DETAILS.FORM.OTHER_INFO', false)
            .add('roleName', 'USER-DETAILS.FORM.ROLE', true).makeFilterable()
            .add('additionalRoles', 'USER-DETAILS.FORM.ADDITIONAL_ROLES', true).disableSorting().makeFilterable()
            .add('accountExpirationDate', 'USER-DETAILS.FORM.ACCOUNT_EXPIRATION', false).makeFilterable();

        if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})) {
            builder.add('subsystemIds', 'USER-DETAILS.FORM.SUBSYSTEM', true);
        }
        builder.add('active', 'USER-DETAILS.FORM.STATUS', true).setFilterValues(this.filterActive)
            .setDefaultFilterValue(this.defaultActiveFilter);

        super.init(builder.build());
        this.saveShownColumns();
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    prepareDataForUser(userId: number): void {
        forkJoin({
            user: (userId != undefined ? this.userService.getUser(userId) : of(new User())),
            signature: (userId != undefined ? this.userService.getUserSignature(userId) : of(undefined))
        }).subscribe({
            next: data => {
                this.editedUser = data.user;
                this.editedUser.repeatedPassword = this.editedUser.password;
                this.editedUser.accountExpirationDate = this.editedUser.accountExpirationDate != undefined ?
                    new Date(this.editedUser.accountExpirationDate) : undefined;
                this.file = data.signature;
                this.displayDialog = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    showDialogToAdd(): void {
        this.file = null;
        this.validationErrors = {};
        this.router.navigate(['features/create-user']);
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true) {
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            event.filters['showHidden'] = {value: 'true'};
        }
        super.loadItemsLazy(event);
        this.storeLazyLoadEvent(event, this.storage);
        this.lastLoadEvent = event;
        this.onDataLazyLoad.emit(this.lastLoadEvent);
        if (updateOriginalTableFilters) {
            this.onUpdateOriginalFilters.emit(this.lastLoadEvent);
        }
        return this.userService.getUsers(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('UserListComponent `getPage` success:');
                    this.users = data.data;
                    this.totalRecords = data.totalRecords;
                    this.totalRecordsChange.emit(this.totalRecords);
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedUser = this.restoreSelectionAfterLoad(this.selectedUser, this.users, event);
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
    }

    handleUserUpdated() {
        this.editedUser = undefined;
        this.showSuccessMessage();
        this.refreshTable();
    }

    handleCancel() {
        this.editedUser = undefined;
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedUser);
        this.setDisplayDialog(false);
        this.substituteUsers = [];
        this.setDisplaySubstitueUserDialog(false);
    }

    private setDisplaySubstitueUserDialog(display: boolean): void {
        if (this.displaySubstitueUserDialog !== display) {
            this.displaySubstitueUserDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }

    showSuccessMessage(): void {
        this.growlMessageController.info('USER-DETAILS.USER_UPDATED');
    }

    submit(): void {
    }

    doOnRowSelect(event) {
        if (this.permissions.canCreateUser() || this.permissions.canEditUserRoles() || this.permissions.isOperator()) {
            this.validationErrors = {};
            this.onRowSelect(event);
            this.focusOnElementWithId(this.getFirstInputId());
        }
    }

    onRowSelect(event): void {
        this.keepSelectedItemIndex(event);
        this.validationErrors = {};
        this.file = undefined;
        this.prepareDataForUser(event.data.id);
        this.displayDialog = true;
    }

    getExportData(): Observable<object[]> {
        return this.userService.getUsers(0, null, this.lastLoadEvent.filters, this.lastLoadEvent.sortField,
            this.lastLoadEvent.sortOrder).pipe(
            map(list => list.data),
            mergeMap(userData => forkJoin({
                userData: of(userData),
                roleSelectItems: this.roleSelectItems.pipe(take(1))
            })),
            map(userData => {
                userData.userData.forEach(user => {
                    user.roleName = userData.roleSelectItems.find(role => role.value === user.roleName).label;
                    (user as any).active = this.getTranslatedLabelForAttrValue(user, 'active');
                });
                return userData.userData;
            }));
    }

    impersonate(username: string): void {
        this.auth.switchUser(username);
    }

    getRoleTranslationKey(name: string): string {
        if (name) {
            return 'ROLE.' + name;
        } else {
            return '';
        }
    }

    protected getFirstInputId() {
        return 'password';
    }

    showNoSuitableSupplierError() {
        this.growlMessageController.error('USER-DETAILS.NO_SUBSTITUTE_USER');
        this.changeDetector.markForCheck();
    }

    showSelectNewUserDialog(substituteUsers: User[]) {
        this.setDisplayDialog(false);

        this.substituteUsers = substituteUsers.map(user => {
            return {label: `${user.firstName} ${user.lastName} (${user.login})`, value: user};
        });

        this.setDisplaySubstitueUserDialog(true);
    }

    onUpdateUserSuccess(): void {
        this.hideUserDetails();
        this.showSuccessMessage();
    }

    hideUserDetails() {
        this.loadItemsLazy(this.lastLoadEvent);
        this.setDisplayDialog(false);
        this.setDisplaySubstitueUserDialog(false);
        this.substituteUsers = [];
    }

    showFailedToMoveClientsMsg() {
        this.growlMessageController.info('USER-DETAILS.FAILED_TO_MOVE_CLIENTS');
    }

    showFailedToSaveUserMsg() {
        this.growlMessageController.error('USER-DETAILS.FAILED_TO_SAVE_SUPPLIER');
    }

    clearFilterOwnUsers(): void {
        this.filterOwnUsersEnabled = false;
        this.getDatatable().filter(undefined, 'filterByManagedSubsystems', 'equals');
    }

    enableFilterByOwnUsers(): void {
        this.filterOwnUsersEnabled = true;
        this.getDatatable().filter(true, 'filterByManagedSubsystems', 'equals');
    }

    isShowOwnUsersFilterEnabled(): boolean {
        return this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']});
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.getDatatable().filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    handleAdditionalRolesFilterChange(additionalRoles: string[]) {
        this.selectedAdditionalRoles = additionalRoles;
        this.getDatatable().filter(additionalRoles.join(), 'additionalRoleIds', 'in');
    }

    getSubsystemsName(id: number): string {
        let subsystem = this.subsystems.find(s => s.id === id);
        return subsystem == null ? '' : subsystem.name;
    }

    getAdditionalRoleNames(user: User): Observable<string[]> {
        return this.additionalRoleSelectItems.pipe(
            map(additionalRoleSelectItems => {
                const getRoleNameById = roleId => {
                    const role = additionalRoleSelectItems.find(selectItem => selectItem.value === `${roleId}`);
                    return role != undefined ? role.label : undefined;
                };
                return (user.roleIds || [])
                    .map(getRoleNameById)
                    .filter(label => label != undefined);
            })
        );
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().filters = {};
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applySelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const ownUsersFilter = filter.filters['filterByManagedSubsystems'];
        if (ownUsersFilter != undefined) {
            this.filterOwnUsersEnabled = ownUsersFilter.value;
        } else {
            this.filterOwnUsersEnabled = false;
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        SavedFilterService.applyMultiSelectFilters(this.selectedAdditionalRoles, filter.filters['additionalRoleIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
        this.getDatatable().setFilterValue(this.selectedAdditionalRoles.join(';'), 'additionalRoleIds', 'in');
    }

    applySelectFilters(filter: LazyLoadEvent): void {
        let activeFilter;
        let roleNameFilter;

        if (filter.filters['active'] != undefined) {
            activeFilter = this.filterActive.find(f => f.value.toString() === filter.filters['active'].value);
        }
        if (activeFilter == undefined) {
            activeFilter = this.filterActive[0];
        }
        this.defaultActiveFilter = activeFilter;

        if (filter.filters['roleName'] != undefined) {
            this.roleSelectItems.pipe(take(1)).subscribe(items => {
                roleNameFilter = items.find(item => item.value.toString() === filter.filters['roleName'].value);
                if (roleNameFilter == undefined) {
                    roleNameFilter = items[0];
                }
                if (roleNameFilter != undefined) {
                    this.dt.setFilterValue(roleNameFilter.value, 'roleName', 'equals');
                }
            });
        }
    }
}
