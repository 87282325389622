import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {SelectItemImpl} from '../../../../common/service/select.item.impl';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {WindowSystemDefinition} from "../../../window-system/window-system-definition/window-system-definition";
import {WindowSystemDefinitionService} from "../../../window-system/window-system-definition/window-system-definition.service";
import {DealerDiscountComponent, DealerDiscountType} from "../dealer-discount.component";
import {DealerDiscountPerSystem} from "./dealer-discount-per-system";
import {DealerDiscountPerSystemService} from "./dealer-discount-per-system.service";

@Component({
    selector: 'app-dealer-discount-per-system',
    templateUrl: '../dealer-discount.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [DealerDiscountPerSystemService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerDiscountPerSystemComponent extends DealerDiscountComponent<DealerDiscountPerSystem, DealerDiscountPerSystemService>
    implements OnInit {

    windowSystems: WindowSystemDefinition[] = [];
    availableSystems: SelectItem[] = [];

    item: DealerDiscountPerSystem = new DealerDiscountPerSystem();

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                public systemService: WindowSystemDefinitionService) {
        super(injector, changeDetector, DealerDiscountPerSystemService, 'DealerDiscountPerSystem', DealerDiscountType.PER_SYSTEM);
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadActiveWindowSystems();
    }

    getFirstInputId() {
        return "windowSystemId";
    }

    getNewItem(): DealerDiscountPerSystem {
        return new DealerDiscountPerSystem();
    }

    onLanguageChange(): void {
        super.onLanguageChange();
        this.rebuildSystems();
    }

    prepareItemForRequest(): DealerDiscountPerSystem {
        let requestItem = this.getNewItem();
        requestItem.id = this.item.id;
        requestItem.windowSystemId = this.item.windowSystemId;
        requestItem.discountValue = this.item.discountValue || '0';
        requestItem.active = this.item.active;
        requestItem.cascade = this.item.cascade;
        requestItem.validFrom = this.item.validFrom;
        requestItem.windowSystemName = undefined;
        return requestItem;
    }

    private loadActiveWindowSystems() {
        const filters = {active: {value: 'true'}, includeRoofSystems: {value: 'true'}};
        return this.systemService.getSystems(0, 0, filters, null, null).subscribe({
            next: data => {
                this.windowSystems = data.data;
                this.rebuildSystems();
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                console.info('DealerDiscountComponent `getWindowSystems` completed!');
            }
        });
    }

    private rebuildSystems() {
        this.availableSystems = this.windowSystems.map(system => new SelectItemImpl(system.names[this.userLang], system.id));
        if (this.availableSystems.length > 0 && this.item.windowSystemId == undefined) {
            this.item.windowSystemId = this.availableSystems[0].value;
        }
    }

    getTargetOptions(): SelectItem[] {
        return this.availableSystems;
    }
}
