<p-dialog [resizable]="false" [visible]="true" [modal]="true" (onHide)="close()" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.UPDATE_VAT.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-number inputId="vatSell" label="{{ 'OFFER.POSITIONS.DIALOGS.UPDATE_VAT.VAT_SELL' | translate }}"
                              [(validationMessageKey)]="validationErrors['vat']" [min]="0" [max]="999"
                              [(ngModel)]="dialogData.vatSell"></app-input-number>
        </div>

        <div>
            <p>{{'OFFER.POSITIONS.DIALOGS.UPDATE_VAT.MESSAGE' | translate}}</p>
        </div>

    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               [disabled]="!submitEnabled" (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               [disabled]="!submitEnabled" (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
