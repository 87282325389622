import {animate, state, style, transition, trigger} from "@angular/animations";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {forkJoin} from 'rxjs';
import {finalize, map} from "rxjs/operators";
import * as _ from 'underscore';
import {BlockUiController} from '../../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../../../../common/CommonErrorHandler';
import {UpsellingPropositionService} from '../../../../../../admin-panel/upselling-proposition/upselling-proposition-service';
import {UpsellingPropositionResult} from "../../UpsellingPropositionResult";
import {UpsellingPropositionResults} from "../../UpsellingPropositionResults";
import {WindowUpsellingDataService} from '../window-upselling-data.service';
import {WindowUpsellingData} from '../WindowUpsellingData';

@Component({
    selector: 'app-upselling-sidebar',
    templateUrl: './upselling-sidebar.component.html',
    styleUrls: ['./upselling-sidebar.component.css'],
    animations: [
        trigger('sidebarVisibilityState', [
            state('visible', style({
                width: '605px'
            })),
            state('hidden', style({
                width: '0'
            })),
            transition('visible => hidden', animate('300ms ease-out')),
            transition('hidden => visible', animate('300ms ease-in'))
        ])
    ],
    providers: [WindowUpsellingDataService, UpsellingPropositionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpsellingSidebarComponent implements OnInit, OnChanges {

    static readonly VISIBLE_STATE: string = 'visible';
    static readonly HIDDEN_STATE: string = 'hidden';
    static readonly BLOCK_UI_ID: string = 'UpsellingSidebarComponent';

    visibleState: string = UpsellingSidebarComponent.HIDDEN_STATE;
    visible = false;
    windowUpsellingData: WindowUpsellingData;
    showNetIncome: boolean;

    @Input()
    upsellingResults: UpsellingPropositionResults[] = [];

    @Input()
    formatPrice: (price: number) => string;

    @Input()
    offerId: number;

    @Output() onHide: EventEmitter<void> = new EventEmitter<void>();
    @Output() applyUpselling: EventEmitter<UpsellingPropositionResult> = new EventEmitter<UpsellingPropositionResult>();

    private upsellingImages: Map<number, string>;

    selectedPropositionTab: number;
    private selectedPropositionTrackingName: string;

    constructor(private windowUpsellingDataService: WindowUpsellingDataService,
                private upsellingPropositionService: UpsellingPropositionService,
                private changeDetector: ChangeDetectorRef,
                public translateService: TranslateService,
                private blockUiController: BlockUiController,
                private errors: CommonErrorHandler) {
    }

    ngOnInit(): void {
        this.refresh(this.offerId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('upsellingResults' in changes) {
            this.invalidateImages();
            const change = changes['upsellingResults'].currentValue as UpsellingPropositionResults[];
            const oldName = this.selectedPropositionTrackingName;
            this.selectedPropositionTab = undefined;
            this.selectedPropositionTrackingName = undefined;
            if (oldName != undefined) {
                const newTab = change.findIndex(result => result.tabName.pl === oldName);
                if (newTab >= 0) {
                    this.selectedPropositionTab = newTab;
                    this.selectedPropositionTrackingName = change[newTab].tabName.pl; // any language will do
                }
            }
            if (this.selectedPropositionTab == undefined && change.length > 0)  {
                this.selectedPropositionTab = 0;
                this.selectedPropositionTrackingName = change[0].tabName.pl; // any language will do
            }
            if (this.visible) {
                this.loadImages();
            }
        }
    }

    refresh(offerId: number) {
        if (offerId == undefined) {
            // production order
            return;
        }
        this.blockUiController.block(UpsellingSidebarComponent.BLOCK_UI_ID);
        this.windowUpsellingDataService.getData(offerId).pipe(
            finalize(() => this.blockUiController.unblock(UpsellingSidebarComponent.BLOCK_UI_ID))
        ).subscribe({
            next: data => this.windowUpsellingData = data,
            error: error => this.errors.handle(error)
        });
    }

    formatPriceInSelectedCurrency(price: number): string {
        return this.formatPrice(price);
    }

    hide(): void {
        this.blockUiController.block(UpsellingSidebarComponent.BLOCK_UI_ID);
        this.windowUpsellingDataService.saveData(this.windowUpsellingData).pipe(
            finalize(() => this.blockUiController.unblock(UpsellingSidebarComponent.BLOCK_UI_ID))
        ).subscribe({
            error: error => this.errors.handle(error)
        });

        this.visibleState = UpsellingSidebarComponent.HIDDEN_STATE;
        this.visible = false;
        this.changeDetector.markForCheck();
        this.onHide.emit();
    }

    show(): void {
        this.visibleState = UpsellingSidebarComponent.VISIBLE_STATE;
        this.loadImages();
        this.showNetIncome = false;
        this.changeDetector.markForCheck();
    }

    sidebarAnimationFinished(): void {
        if (this.visibleState === UpsellingSidebarComponent.VISIBLE_STATE) {
            this.visible = true;
            setTimeout(() => this.changeDetector.markForCheck(), 0);
        }
    }

    executeApplyUpselling(proposition: UpsellingPropositionResult) {
        this.applyUpselling.emit(proposition);
    }

    isSelectedItem(chargeId: number): boolean {
        return this.windowUpsellingData && this.windowUpsellingData.propositionIdsForPrint.indexOf(chargeId) > -1;
    }

    selectItem(id: number): void {
        let index = this.windowUpsellingData.propositionIdsForPrint.indexOf(id);
        if (index > -1) {
            this.windowUpsellingData.propositionIdsForPrint.splice(index, 1);
        } else {
            this.windowUpsellingData.propositionIdsForPrint.push(id);
        }
    }

    private invalidateImages(): void {
        this.upsellingImages = undefined;
    }

    private loadImages(): void {
        if (this.upsellingImages != undefined) {
            return;
        }
        forkJoin(_.chain(this.upsellingResults)
            .map(r => r.propositions)
            .flatten(true)
            .map(p => p.id)
            .uniq(false)
            .map(id => this.upsellingPropositionService.getImage(id)
                .pipe(map(image => ({id: id, image: image}))))
            .value())
            .subscribe(images => {
                this.upsellingImages = new Map<number, string>();
                for (let imageData of images) {
                    this.upsellingImages.set(imageData.id, imageData.image);
                }
                this.changeDetector.markForCheck();
            });
    }

    getImage(id: number): string {
        if (this.upsellingImages == undefined) {
            return undefined;
        }
        return this.upsellingImages.get(id);
    }

    handlePropositionTabChange(newTab: number): void {
        this.selectedPropositionTab = newTab;
        this.selectedPropositionTrackingName = this.upsellingResults[newTab].tabName.pl; // any language will do
    }
}
