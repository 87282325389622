import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

class LetContext<T> {
    $implicit: T | undefined;
    vLet: T | undefined;
}

@Directive({
    selector: '[vLet]'
})
export class LetDirective<T> implements OnInit {

    private context = new LetContext<T>();

    @Input()
    set vLet(value: T) {
        this.context.$implicit = this.context.vLet = value;
    }

    constructor(private vcr: ViewContainerRef,
                private templateRef: TemplateRef<LetContext<T>>) {
    }

    ngOnInit(): void {
        this.vcr.createEmbeddedView(this.templateRef, this.context);
    }
}
