<div class="profit-margin-container" [ngClass]="{'editable': canEdit, 'not-editable': !canEdit}">
    <!--Toolbar-->
    <div *ngIf="canEdit" class="table-header">
        <div class="table-header-buttons">
            <div class="flex-row">
                <div class="profit-margin-global-input-container">
                    <app-input-text [inputId]="globalMarginUpdateInputId"
                                    [(validationMessageKey)]="validationErrors['globalMarginUpdateInput']"
                                    [(ngModel)]="globalProfitMargin"
                                    (keydown.enter)="applyGlobalProfitMargin($event)">
                        <ng-template pTemplate="label"></ng-template>
                    </app-input-text>
                </div>
                <app-simple-button [buttonId]="globalMarginUpdateButtonId"
                                   label="{{ 'PROFIT_MARGIN.FORM.SET_ALL_VALUES' | translate }}" icon="beenhere"
                                   type="action" [size]="40" (onClick)="applyGlobalProfitMargin($event)"
                                   (keydown.enter)="applyGlobalProfitMargin($event)"></app-simple-button>
                <app-checkbox *ngIf="entityType === 'subsystemGroup' && subsystemGroupId != undefined"
                              inputId="overrideMargins"
                              label="{{ 'PROFIT_MARGIN.FORM.OVERRIDE_MARGIN_IN_SUBSYSTEMS' | translate }}"
                              [(ngModel)]="pushToGroupMembers"></app-checkbox>
            </div>
        </div>
    </div>

    <p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [editable]="true" [responsive]="true" [(selection)]="selectedItem"
                 (onEditComplete)="editProfitMargin($event)" *ngIf="showTable" (onEditInit)="beforeEdit($event)"
                 (onEditExit)="editProfitMargin()"
                 (onPage)="setPages($event)" (onFilter)="updateTotalPageCount()">
        <p-column field="catalogItemType" header="{{ 'CATALOG_CHARGE_PROFIT_MARGIN.FORM.TYPE' | translate }}"
                  [sortable]="false" [filter]="true" [editable]="false"
                  [filterValues]="filterCatalogItemType | selectItemTranslate">
            <ng-template let-profitMargin="rowData" pTemplate="body">
                {{ formatCatalogItemTypeKey(profitMargin.catalogItemType) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="entityType === 'client' || entityType === 'subsystem'" field="groupValue"
                  header="{{ 'PROFIT_MARGIN.FORM.GROUP_PROFIT_MARGIN' | translate }}" [editable]="false"
                  [style]="{'width':'15%'}">
            <ng-template pTemplate="body" let-item="rowData">
                <span [ngStyle]="{ 'text-decoration': item.valueString != undefined ? 'line-through' : 'none' }">{{ item.groupValue }}</span>
            </ng-template>
        </p-column>
        <p-column field="valueString" header="{{ 'PROFIT_MARGIN.FORM.PROFIT_MARGIN' | translate }}" [editable]="canEdit"
                  [style]="{'width':'15%'}">
            <ng-template let-item="rowData" pTemplate="body">
                <div><span class="editable-cell-value-span">{{ item.valueString }}</span>
                    <span *ngIf="canEdit" class="material-icons editable-cell-value-span-icon"></span></div>
                <div *ngIf="item.valueString != item.value && item.value != undefined">({{item.value}})</div>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate: { from: fromRecord, to: toRecord, total: totalRecords } }}</span>
        </ng-template>
    </p-dataTable>

    <p *ngIf="!showTable">{{ 'PROFIT_MARGIN.FORM.DATA_LOADING' | translate }}</p>
</div>
