<div class="new-form-60vw">
	<ng-template #summaryRowTemplate let-label="label" let-value="value" let-format="format">
		<div class="new-form-row">
			<div class="new-form-field">
				<div class="new-form-field-label-container">
					<label>{{ label | translate }}</label>
				</div>
				<div class="new-form-field-input-container">{{ value | momentCurrentLang | amDateFormat: format }}</div>
			</div>
		</div>
	</ng-template>

	<ng-container *ngTemplateOutlet="summaryRowTemplate; context: {
			label: 'SUBSYSTEM-DETAILS.FORM.LAST_USER_LOGIN_DATE',
			value: summary?.lastUserLoginDate,
			format: 'L LT' }"></ng-container>

	<ng-container *ngTemplateOutlet="summaryRowTemplate; context: {
			label: 'SUBSYSTEM-DETAILS.FORM.LAST_OFFER_CREATION_DATE',
			value: summary?.lastOfferCreationDate,
			format: 'L' }"></ng-container>

	<ng-container *ngTemplateOutlet="summaryRowTemplate; context: {
			label: 'SUBSYSTEM-DETAILS.FORM.LAST_OFFER_MODIFICATION_DATE',
			value: summary?.lastOfferModificationDate,
			format: 'L LT' }"></ng-container>

	<ng-container *ngTemplateOutlet="summaryRowTemplate; context: {
			label: 'SUBSYSTEM-DETAILS.FORM.LAST_ORDER_CREATION_DATE',
			value: summary?.lastOrderCreationDate,
			format: 'L' }"></ng-container>

	<p-table #table [paginator]="false" [rowsPerPageOptions]="rowsPerPageOptions" selectionMode="single" dataKey="userId" [lazy]="false"
			 [value]="usersReport" [first]="chosenPageNumber" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
			 (onPage)="handlePageChange($event)">
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="userLogin" [ngClass]="columnHeaderClasses('userLogin')">
					<span>{{ 'SUBSYSTEM-DETAILS.FORM.REPORT_USER_LOGIN' | translate }}</span>
					<p-sortIcon field="userLogin"></p-sortIcon>
					<input class="p-column-filter p-inputtext p-component"
						   (click)="handleInputFilterClick($event)"
						   (input)="table.filter(getInputEventValue($event), 'userLogin', 'contains')">
				</th>
				<th pSortableColumn="lastUserLoginDate" [ngClass]="columnHeaderClasses('lastUserLoginDate')">
					<span>{{ 'SUBSYSTEM-DETAILS.FORM.LAST_USER_LOGIN_DATE' | translate }}</span>
					<p-sortIcon field="lastUserLoginDate"></p-sortIcon>
					<div app-table-date-range-filter="lastUserLoginDate"></div>
				</th>
				<th pSortableColumn="lastOfferCreationDate" [ngClass]="columnHeaderClasses('lastOfferCreationDate')">
					<span>{{ 'SUBSYSTEM-DETAILS.FORM.LAST_OFFER_CREATION_DATE' | translate }}</span>
					<p-sortIcon field="lastOfferCreationDate"></p-sortIcon>
					<div app-table-date-range-filter="lastOfferCreationDate"></div>
				</th>
				<th pSortableColumn="lastOfferModificationDate" [ngClass]="columnHeaderClasses('lastOfferModificationDate')">
					<span>{{ 'SUBSYSTEM-DETAILS.FORM.LAST_OFFER_MODIFICATION_DATE' | translate }}</span>
					<p-sortIcon field="lastOfferModificationDate"></p-sortIcon>
					<div app-table-date-range-filter="lastOfferModificationDate"></div>
				</th>
				<th pSortableColumn="lastOrderCreationDate" [ngClass]="columnHeaderClasses('lastOrderCreationDate')">
					<span>{{ 'SUBSYSTEM-DETAILS.FORM.LAST_ORDER_CREATION_DATE' | translate }}</span>
					<p-sortIcon field="lastOrderCreationDate"></p-sortIcon>
					<div app-table-date-range-filter="lastOrderCreationDate"></div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-report let-rowIndex="rowIndex">
			<tr [attr.tabindex]="rowIndex" [pSelectableRow]="report" [pSelectableRowIndex]="rowIndex" class="datatable-data">
				<td class="column-value">{{ report.userLogin }}</td>
				<td class="column-value">{{ report.lastUserLoginDate | momentCurrentLang | amDateFormat: 'L LT' }}</td>
				<td class="column-value">{{ report.lastOfferCreationDate | momentCurrentLang | amDateFormat: 'L' }}</td>
				<td class="column-value">{{ report.lastOfferModificationDate | momentCurrentLang | amDateFormat: 'L LT' }}</td>
				<td class="column-value">{{ report.lastOrderCreationDate | momentCurrentLang | amDateFormat: 'L' }}</td>
			</tr>
		</ng-template>
	</p-table>
	<ng-template #paginatorPageInfoTemplate>
		<span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
	</ng-template>
	<p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
				 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
				 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
				 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
