import {ChangeDetectorRef, Directive, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {MultilanguageField, SupportedLanguages} from '../../../supportedLanguages';
import {TranslationsService} from '../../translations/translations.service';
import {TranslationTarget} from '../../translations/ui-translations/translation-target.enum';
import {MailBoxSslMode} from '../subsystem-webshop-mail-configuration/mail-box-configuration';
import {MailSettings} from './mail-settings';

@Directive()
export abstract class AbstractMailSettingsComponent implements OnInit {

    private static readonly BLOCK_UI_ID = 'OfferStatusChangeSettingsComponentBlock';

    readonly supportedLanguages = SupportedLanguages.languages;
    sslModes: Observable<SelectItem[]>;

    mailSettings = new MailSettings();
    mailSubject: MultilanguageFieldInterface = new MultilanguageField();
    mailBody: MultilanguageFieldInterface = new MultilanguageField();
    validationErrors: ValidationErrors = {};

    componentType: 'offerStatusChange' | 'passwordReset' | 'userActivityReport';

    protected constructor(private readonly translationsService: TranslationsService,
                          private readonly translateService: TranslateService,
                          private readonly translatedSelectItemService: TranslatedSelectItemService,
                          private readonly blockUiController: BlockUiController,
                          private readonly growlMessageController: GrowlMessageController,
                          private readonly errors: CommonErrorHandler,
                          public readonly formHeaderKey: string,
                          private readonly mailSubjectTranslationKey: string,
                          private readonly mailBodyTranslationKey: string,
                          public readonly showBccField: boolean,
                          private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.blockUiController.block(AbstractMailSettingsComponent.BLOCK_UI_ID);
        this.sslModes = this.translatedSelectItemService.buildUnsortedDropdown([MailBoxSslMode.SSL, MailBoxSslMode.STARTTLS,
            MailBoxSslMode.AZURE], '', undefined);
        forkJoin({
            mailSettings: this.loadMailSettings(),
            mailSubject: this.getUiTranslation(this.mailSubjectTranslationKey),
            mailBody: this.getUiTranslation(this.mailBodyTranslationKey)
        }).pipe(
            finalize(() => this.blockUiController.unblock(AbstractMailSettingsComponent.BLOCK_UI_ID))
        ).subscribe({
            next: data => {
                this.mailSettings = data.mailSettings;
                this.mailSubject = data.mailSubject;
                this.mailBody = data.mailBody;
            },
            error: error => this.errors.handle(error)
        });
    }

    save(): void {
        this.validateForm();
        if (this.componentType === 'passwordReset') {
            this.cleanPastedLink();
        }
        if (this.validationErrorsPresent(this.validationErrors)) {
            this.changeDetector.markForCheck();
        } else {
            this.blockUiController.block(AbstractMailSettingsComponent.BLOCK_UI_ID);
            forkJoin({
                mailSettings: this.saveMailSettings(this.mailSettings),
                mailSubject: this.translationsService.saveUiTranslations({
                    key: this.mailSubjectTranslationKey,
                    target: TranslationTarget.FRONTEND,
                    text: this.mailSubject
                }),
                mailBody: this.translationsService.saveUiTranslations({
                    key: this.mailBodyTranslationKey,
                    target: TranslationTarget.FRONTEND,
                    text: this.mailBody
                })
            }).pipe(
                finalize(() => this.blockUiController.unblock(AbstractMailSettingsComponent.BLOCK_UI_ID))
            ).subscribe({
                next: () => this.growlMessageController.info('MAIL_SETTINGS.MAIL_SETTINGS_UPDATED'),
                error: error => this.validationErrors = this.errors.handle(error)
            });
        }
    }

    private getUiTranslation(key: string): Observable<MultilanguageFieldInterface> {
        return this.translationsService.getUiTranslations(0, 1, {
            key: {value: key},
            target: {value: TranslationTarget.FRONTEND}
        }, undefined, undefined).pipe(map(translations => {
            return translations.data[0] ? translations.data[0].text : new MultilanguageField();
        }));
    }

    validateForm() {
        this.validationErrors = {};

        if (this.mailSettings.host == undefined) {
            this.validationErrors['host'] = 'error.mailSettingsDto.host.not_null';
        }
        if (this.mailSettings.port == undefined) {
            this.validationErrors['port'] = 'error.mailSettingsDto.port.not_null';
        }
        if (this.mailSettings.sslMode == undefined) {
            this.validationErrors['sslMode'] = 'error.mailSettingsDto.sslMode.not_null';
        }
        if (this.mailSettings.username == undefined) {
            this.validationErrors['username'] = 'error.mailSettingsDto.username.not_null';
        }
        if (this.mailSettings.password == undefined) {
            this.validationErrors['password'] = 'error.mailSettingsDto.password.not_null';
        }
        if (this.mailSettings.senderAddress == undefined) {
            this.validationErrors['senderAddress'] = 'error.mailSettingsDto.senderAddress.not_null';
        }

        this.supportedLanguages.forEach(lang => {
            if (this.mailSubject[lang.code] == undefined || this.mailSubject[lang.code].trim() === '') {
                this.validationErrors[`mailSubject[${lang.code}]`] = `error.mailSubject.title.not_empty`;
            }
        });
    }

    get isAzure() {
        return this.mailSettings.sslMode === MailBoxSslMode.AZURE;
    }

    validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        if (!validationErrors) {
            validationErrors = this.validationErrors;
        }
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }

    protected abstract loadMailSettings(): Observable<MailSettings>;

    protected abstract saveMailSettings(mailSettings: MailSettings): Observable<void>;

    private cleanPastedLink() {
        for (let lang of this.supportedLanguages) {
            if (this.mailBody[lang.code] == undefined) {
                return;
            }
            const body = this.mailBody[lang.code];
            const linkStart = body.indexOf("href=") + 6;
            const linkEnd = body.indexOf("rel") - 2;
            const linkPart = body.slice(linkStart, linkEnd);
            const correctLinkPart = `[($\{PASSWORD_RESET_LINK})]`;
            if (linkPart.includes("PASSWORD_RESET_LINK")) {
                this.mailBody[lang.code] = body.replace(linkPart, correctLinkPart);
            }
        }
    }
}
