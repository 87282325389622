import {JsonDeserializable} from './crud-common/crudItem';

@JsonDeserializable
export class MotlawaIntegrationInfo {
    symbol: string;
    description: string;

    static fromJSON(jsonObject: any): MotlawaIntegrationInfo {
        const motlawaIntegrationInfo = new MotlawaIntegrationInfo();
        motlawaIntegrationInfo.symbol = jsonObject.symbol;
        motlawaIntegrationInfo.description = jsonObject.description;
        return motlawaIntegrationInfo;
    }
}
