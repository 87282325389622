import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ConfigSliderElement} from "./config-slider-element";

@Injectable({
    providedIn: 'root'
})
export class ConfigSliderElementService implements CrudService<ConfigSliderElement> {

    private static readonly API_URL = 'configSliderElement';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: ConfigSliderElement): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: ConfigSliderElement): Observable<number> {
        return undefined;
    }

    getItem(itemId: number): Observable<ConfigSliderElement> {
        return this.http.get<ConfigSliderElement>(`${ConfigSliderElementService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<ConfigSliderElement>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<ConfigSliderElement>>(ConfigSliderElementService.API_URL, {params: params});
    }

    saveElement(item: ConfigSliderElement, imageLarge: File, itemId?: number): Observable<number> {
        const url = itemId ? `${ConfigSliderElementService.API_URL}/${itemId}` : ConfigSliderElementService.API_URL;

        let formData = new FormData();
        formData.append('dto', new Blob([JSON.stringify(item)], {type: 'application/json'}));
        if (imageLarge) {
            formData.append('imageLarge', imageLarge);
        }
        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(ConfigSliderElementService.API_URL, response)));
    }

    copyElement(id: number, item: ConfigSliderElement, imageLarge: File): Observable<number> {
        let formData = new FormData();
        formData.append('dto', new Blob([JSON.stringify(item)], {type: 'application/json'}));
        if (imageLarge) {
            formData.append('imageLarge', imageLarge);
        }
        return this.http.post(`${ConfigSliderElementService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(ConfigSliderElementService.API_URL, response)));
    }

    validate(item: ConfigSliderElement): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${ConfigSliderElementService.API_URL}/validate`, item));
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${ConfigSliderElementService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getElementsForSystem(systemId: number): Observable<ConfigSliderElement[]> {
        let filters: { [filterProperty: string]: FilterMetadata } = {};
        filters['configSystemId'] = {value: systemId};
        filters['withImages'] = {value: '' + true};
        filters['active'] = {value: '' + true};
        filters['sortBy'] = {value: 'sortIndex'};
        return this.getItems(null, null, filters, null, null).pipe(map(a => a.data));
    }
}
