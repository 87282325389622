import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ResponseError} from '../../../common/error.handler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationService} from "../../../common/service/validation.service";
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {ErrorResponse} from '../../errors/errorResponse';
import {SupplierFormValidator} from "../form/supplier-form.validator";
import {Supplier, SupplierMotlawaIntegrationInfo} from "../supplier";
import {SupplierService} from "../supplier.service";
import {Permissions} from "../../../auth/permission.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-create-supplier',
    templateUrl: './create-supplier.component.html',
    styleUrls: ['./create-supplier.component.css'],
    providers: [SupplierService, DataServiceHelper, ValidationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSupplierComponent implements OnInit {

    validationErrors: any = {};
    supplier: Supplier;
    motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo;

    readonly STEPS = {
        DETAILS: 'details',
        ADDRESS: 'address',
        ANNOTATIONS: 'annotations',
        MOTLAWA_INTEGRATION: 'motlawa_integration'
    };

    validateSupplierDetailsStep: WizardStepValidator;
    validateAnnotationsStep: WizardStepValidator;
    validateAddressStep: WizardStepValidator;

    private afterSaveCallback: (validationErrors: ValidationErrors) => void;
    private supplierFormValidator: SupplierFormValidator;
    private saveInProgress = false;

    constructor(private permissions: Permissions,
                private supplierService: SupplierService,
                private validationService: ValidationService,
                private growlMessageController: GrowlMessageController,
                private router: Router,
                private changeDetector: ChangeDetectorRef,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.supplier = new Supplier();
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
            this.motlawaIntegrationInfo = new SupplierMotlawaIntegrationInfo();
        }

        this.afterSaveCallback = validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            this.changeDetector.markForCheck();
        };

        this.initValidators();
    }

    exitWizard(): void {
        this.router.navigate(['/features/supplier']);
    }

    saveSupplier() {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.validationErrors = {};
        this.supplierService.saveSupplier(this.supplier, this.motlawaIntegrationInfo)
            .pipe(finalize(() => this.saveInProgress = false))
            .subscribe({
                next: () => {
                    this.router.navigate(['/features/supplier']);
                },
                error: (errorMessage: HttpErrorResponse) => {
                    let errorBody = new ErrorResponse(errorMessage.error);

                    if (errorBody.is400()) {
                        const validationErrors = {};
                        for (let property in errorBody.invalidFields) {
                            validationErrors[property] = errorBody.invalidFields[property];
                        }

                        this.growlMessageController.error('SUPPLIER-DETAILS.FAILED_TO_SAVE');
                        this.afterSaveCallback(validationErrors);
                    } else {
                        throw new ResponseError(errorMessage);
                    }
                }
            });
    }

    private initValidators(): void {
        this.supplierFormValidator = new SupplierFormValidator(this.supplier,
            this.validationService, this.supplierService, this.translate, this.permissions, this.afterSaveCallback);

        this.validateSupplierDetailsStep = () => {
            ValidationErrorsHelper.clearAllErrors(this.validationErrors);
            return this.supplierFormValidator.validateDetails();
        };

        this.validateAddressStep = () => {
            ValidationErrorsHelper.clearAllErrors(this.validationErrors);
            return this.supplierFormValidator.validateAddress();
        };

        this.validateAnnotationsStep = () => {
            return true;
        };
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }
}
