import {WindowData} from "../drawing-data/WindowData";
import {DrawingUtil} from "../drawing-util";

export class SortingUtil {

    public static sortWindows =
        (a: WindowData, b: WindowData) => SortingUtil.topToBottomLeftToRight(a.subWindows[0].points, b.subWindows[0].points)

    public static topToBottomLeftToRight = (a: number[], b: number[]) => {
        let yDiff = Math.min(...DrawingUtil.getYCoordinates(a)) - Math.min(...DrawingUtil.getYCoordinates(b));
        if (yDiff !== 0) {
            return yDiff;
        }
        return Math.min(...DrawingUtil.getXCoordinates(a)) - Math.min(...DrawingUtil.getXCoordinates(b));
    }
}
