<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.SUPPLIERS.LIST' | translate}}
        </div>
        <div class="table-header-buttons" *ngIf="permissions.isPermitted({roles:['ROLE_CREATE_SUPPLIER']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.SUPPLIERS.LIST')"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" [displaySelectedLabel]="false"
                               [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"
                               id="suppliersShownColumns"></p-multiSelect>
                <app-simple-button buttonId="addSupplier" label="{{ 'NAVIGATION.ITEMS.SUPPLIERS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="showDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="suppliers" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadSuppliersLazy($event)" [responsive]="true"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [(selection)]="selectedSupplier" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="suppliersTable">

        <!-- ACTIONS -->
        <p-column header="{{ 'SUPPLIER-DETAILS.FORM.ACTIONS' | translate }}" [sortable]="false">
            <ng-template let-supplier="rowData" pTemplate="body">
                <div class="table-row-action-buttons">
                    <app-simple-button label="{{ 'SUPPLIER-DETAILS.TOOLTIPS.SHOW_ADDONS' | translate }}"
                                       [inlineLabel]="false" icon="add_shopping_cart" type="action" [size]="36"
                                       (onClick)="actionOnClick('SHOW_ADDONS', supplier)"></app-simple-button>
                    <app-simple-button *ngIf="permissions.isPermitted({roles:['ROLE_KOORDYNATOR']})"
                                       label="{{ 'SUPPLIER-DETAILS.TOOLTIPS.SHOW_SYSTEMS' | translate }}"
                                       [inlineLabel]="false" icon="exit_to_app" type="action" [size]="36"
                                       (onClick)="actionOnClick('SHOW_SYSTEMS', supplier)"></app-simple-button>
                </div>
            </ng-template>
        </p-column>

        <ng-container *ngFor="let column of columns">
            <p-column *ngIf="column.field == 'id'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains"
                      [style]="{'width':'5%'}"></p-column>

            <p-column *ngIf="column.field == 'companyName'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains">

                <ng-template *ngIf="permissions.isPermitted({roles:['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                             let-supplier="rowData" pTemplate="body">
                    {{supplier.companyName}}  <span
                        class="secondary-text">{{ (supplier.name[translate.currentLang] == null || supplier.name[translate.currentLang] == '') ? '' : '(' + supplier.name[translate.currentLang] + ')'}}</span>
                </ng-template>
                <ng-template *ngIf="permissions.isPermitted({roles:['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})" let-supplier="rowData"
                             pTemplate="body">
                    {{supplier.companyName}}
                </ng-template>
            </p-column>

            <p-column *ngIf="column.field == 'address.street'
                          || column.field == 'address.city'
                          || column.field == 'address.zip'
                          || column.field == 'contact.name'
                          || column.field == 'contact.phone'
                          || column.field == 'contact.email'
                          || column.field == 'contact.fax'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains">
            </p-column>
            <p-column *ngIf="column.field == 'address.country'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains"
                      [filterValues]="filterCountry | async">
                <ng-template let-supplier="rowData" pTemplate="body">
                    {{ ('COUNTRIES.' + supplier.address.country) | translate }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field == 'active'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains"
                      [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
                <ng-template let-supplier="rowData" pTemplate="body">
                    {{(supplier.active ? 'SUPPLIER-DETAILS.FORM.ACTIVE' : 'SUPPLIER-DETAILS.FORM.INACTIVE') | translate}}
                </ng-template>
            </p-column>
        </ng-container>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<!-- EDIT SUPPLIER DIALOG -->
<app-edit-supplier-dialog *ngIf="displayDialog"
                          [supplier]="supplier"
                          [motlawaIntegrationInfo]="motlawaIntegrationInfo"
                          (onHide)="cancel()"
                          (onSuccess)="onUpdateSupplierSuccess()"
                          (onNoSuitableSuppliers)="showNoSuitableSupplierError()"
                          (onShowSubstituteSuppliersDialog)="showSelectNewSupplierDialog($event)">
</app-edit-supplier-dialog>

<!-- ADD SUBSTITUTE SUPPLIER DIALOG -->
<app-substitute-supplier-dialog *ngIf="displaySubstitueSupplierDialog"
                                [supplier]="supplier"
                                [motlawaIntegrationInfo]="motlawaIntegrationInfo"
                                [substituteSuppliers]="subsituteSuppliers"
                                (onHide)="cancel()"
                                (onSuccess)="onUpdateSupplierSuccess()"
                                (onSaveSupplierError)="showFailedToSaveSupplierMsg()"
                                (onMoveProductsError)="showFailedToMoveProductsMsg()">
</app-substitute-supplier-dialog>
