import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {GatePrintPainter} from '../../../../../gate-painter/gate-print-painter';
import {GatePainterService} from './gate-painter.service';
import {TranslateService} from "@ngx-translate/core";

@Directive({
    selector: 'svg[app-gate-print-painter]'
})
export class GatePrintPainterDirective extends GatePrintPainter implements OnInit, OnDestroy {

    @Output()
    readonly xmlOutput = new EventEmitter<string>();

    private readonly subscriptions = new Subscription();

    constructor(private readonly gatePainterService: GatePainterService,
                private readonly host: ElementRef<SVGSVGElement>,
                translationsService: TranslateService) {
        super(Snap(host.nativeElement), translationsService);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.gatePainterService.paintEvents.subscribe(variables => {
            this.paint(variables, true);
        }));
        this.subscriptions.add(this.gatePainterService.xmlRequests.subscribe((variables) => {
            this.paint(variables, false);
            this.xmlOutput.next(this.prepareXML());
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private prepareXML(): string {
        const node = this.host.nativeElement.cloneNode(true) as SVGSVGElement;

        // purge angular specific attributes from saved svg
        const attributesToRemove = [
            'app-gate-print-painter',
            'style'
        ];
        for (let i = 0; i < node.attributes.length; i++) {
            const attr = node.attributes.item(i);
            if (attr.name.startsWith('ng-') || attr.name.startsWith('_ng')) {
                attributesToRemove.push(attr.name);
            }
        }
        for (const attribute of attributesToRemove) {
            node.removeAttribute(attribute);
        }
        return new XMLSerializer().serializeToString(node);
    }
}
