import {Component, Input, OnInit} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WebshopCharge} from "../../../window-system/webshop-charge/WebshopCharge";
import {UpsellingProposition} from "../upselling-proposition";
import {UpsellingPickListCharge} from "./upselling-pick-list-charge";

@Component({
    selector: 'app-upselling-proposition-charges',
    templateUrl: './upselling-proposition-charges.component.html',
    styleUrls: ['./upselling-proposition-charges.component.css']
})
export class UpsellingPropositionChargesComponent implements OnInit {

    @Input() item: UpsellingProposition;
    @Input() validationErrors: ValidationErrors;
    @Input() allActiveCharges: WebshopCharge[];
    @Input() userLang: string;

    allMappedCharges: UpsellingPickListCharge[];
    availableCharges: UpsellingPickListCharge[] = [];
    selectedCharges: UpsellingPickListCharge[] = [];

    ngOnInit() {
        this.allMappedCharges =
            this.allActiveCharges.map(charge => new UpsellingPickListCharge(charge, this.userLang));
        this.selectedCharges =
            this.allMappedCharges.filter(charge => this.item.webshopChargeIds.includes(charge.id));
        this.availableCharges =
            this.allMappedCharges.filter(charge => !this.item.webshopChargeIds.includes(charge.id));
        this.countChargesPerSystem();
    }

    handleMoveToSource(moved: WebshopCharge[]): void {
        for (let movedOption of moved) {
            let selectedIndex = this.item.webshopChargeIds.findIndex(orderId => orderId === movedOption.id);
            if (selectedIndex !== -1) {
                this.item.webshopChargeIds.splice(selectedIndex, 1);
            }
        }
        this.sortCharges(this.availableCharges);
        this.countChargesPerSystem();
    }

    handleMoveToTarget(moved: WebshopCharge[]): void {
        for (let movedOption of moved) {
            this.item.webshopChargeIds.push(movedOption.id);
        }
        this.sortCharges(this.selectedCharges);
        this.countChargesPerSystem();
    }

    sortCharges(charges: UpsellingPickListCharge[]): void {
        charges.sort((a, b) => a.sortIndex = b.sortIndex);
    }

    countChargesPerSystem(): void {
        let selectedPerSystem = new Map<number, number>();
        this.selectedCharges.forEach(charge => {
            if (selectedPerSystem.has(charge.windowSystemId)) {
                selectedPerSystem.set(charge.windowSystemId, selectedPerSystem.get(charge.windowSystemId) + 1);
            } else {
                selectedPerSystem.set(charge.windowSystemId, 1);
            }
        });
        this.allMappedCharges.forEach(charge => {
            charge.warnInSource = (selectedPerSystem.get(charge.windowSystemId) || 0) > 0;
            charge.warnInTarget = (selectedPerSystem.get(charge.windowSystemId) || 0) > 1;
        });
        this.validateCharges();
    }

    validateCharges(): void {
        if (this.selectedCharges.some(charge => charge.warnInTarget)) {
            this.validationErrors['webshopChargeIds'] = 'error.upsellingProposition.webshopChargeIds.not_unique';
        } else {
            this.validationErrors['webshopChargeIds'] = undefined;
        }
    }
}
