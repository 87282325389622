import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing, mapScrollingListingToJson, ScrollingListing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {
    WebshopAreaBarsWithPrice,
    WebshopBusinessTypeWithPrice,
    WebshopChargeWithPrice,
    WebshopColorWithPrice,
    WebshopGlazingWithPrice,
    WebshopWindowSystemModelWithPrice
} from './webshop-pricing-interfaces';
import {WebshopProductConfiguration, WebshopRecommendedConfiguration} from './webshop-recommended-configuration';

@Injectable()
export class WebshopRecommendedConfigurationService {

    private static readonly API_URL = 'webshopRecommendedConfiguration';
    private static readonly WEBSHOP_API_URL = 'webshop';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopRecommendedConfiguration>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(WebshopRecommendedConfigurationService.API_URL, {params: params}).pipe(
            map(result => Listing.fromJSON(WebshopRecommendedConfiguration, result))
        );
    }

    saveItem(item: WebshopRecommendedConfiguration): Observable<number> {
        return this.http.post<void>(WebshopRecommendedConfigurationService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToItemId(WebshopRecommendedConfigurationService.API_URL, item.id));
    }

    getItem(itemId: number): Observable<WebshopRecommendedConfiguration> {
        return this.http.get<object>(`${WebshopRecommendedConfigurationService.API_URL}/${itemId}`).pipe(
            map(result => WebshopRecommendedConfiguration.fromJSON(result))
        );
    }

    getBusinessTypes(params: WebshopProductConfiguration): Observable<WebshopBusinessTypeWithPrice[]> {
        return this.http.post<object[]>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/businessTypes/offer`,
            params).pipe(map(items => items.map(WebshopBusinessTypeWithPrice.fromJSON)));
    }

    getModelsWithPrices(productConfiguration: WebshopProductConfiguration,
                        limit: number, offset: number): Observable<ScrollingListing<WebshopWindowSystemModelWithPrice>> {
        const params: { [param: string]: string | string[]; } = {
            limit: '' + limit,
            offset: '' + offset
        };
        return this.http.post<ScrollingListing<object>>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/windowModels`,
            productConfiguration, {params: params}).pipe(mapScrollingListingToJson(WebshopWindowSystemModelWithPrice));
    }

    getGlazings(params: WebshopProductConfiguration): Observable<WebshopGlazingWithPrice[]> {
        return this.http.post<object[]>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/glazings`, params)
            .pipe(map(items => items.map(WebshopGlazingWithPrice.fromJSON)));
    }

    getColors(params: WebshopProductConfiguration, bothSides: boolean): Observable<WebshopColorWithPrice[]> {
        return this.http.post<object[]>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/colors/offer`, params, {
            params: {bothSides: `${bothSides}`}
        }).pipe(map(items => items.map(WebshopColorWithPrice.fromJSON)));
    }

    getAreaBars(params: WebshopProductConfiguration): Observable<WebshopAreaBarsWithPrice[]> {
        return this.http.post<object[]>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/areaBars/offer`, params)
            .pipe(map(items => items.map(WebshopAreaBarsWithPrice.fromJSON)));
    }

    getWebshopCharges(params: WebshopProductConfiguration): Observable<WebshopChargeWithPrice[]> {
        return this.http.post<object[]>(`${WebshopRecommendedConfigurationService.WEBSHOP_API_URL}/webshopCharges`, params)
            .pipe(map(items => items.map(WebshopChargeWithPrice.fromJSON)));
    }
}
