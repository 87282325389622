import {InjectionToken} from '@angular/core';

/**
 * This interface is meant to be implemented by components/services that need to hide UI elements for fullscreen wizard
 * such as global navigation bar
 */
export interface WizardFullscreenController {

    /**
     * Prepares UI for fullscreen wizard - for example by hiding certain elements
     */
    setFullscreen(): void;

    /**
     * Restores all elements hidden by setFullscreen
     */
    unsetFullscreen(): void;
}

export const FULLSCREEN_CONTROLLER = new InjectionToken<WizardFullscreenController[]>('fullscreenController');
