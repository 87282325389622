import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ColorType} from '../../../../ColorType';
import {ValidationErrors} from '../../../../common/validation-errors';
import {GateSystemDefaults} from '../../../settings/gate-system-defaults/gate-system-defaults';
import {AddonCategoryGroup} from "../../../window-system/addon-category-group/addon-category-group";
import {Color} from '../../../window-system/color/color';
import {GateSystem} from '../../../window-system/gate-system/gate-system';
import {WindowComponentPreviewData} from '../../window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {WindowEditorPositionData} from '../../window-editor/window-editor-offer-interfaces';
import {GateData} from '../gate-data';
import {GateEditorField} from '../gate-editor-field';
import {GateEditorFieldContentProvider} from '../gate-editor-field-content-provider';
import {GateEditorFieldStates} from '../gate-editor-field-states';
import {GateSidebarFieldImageService} from './gate-sidebar-field-image.service';
import {Accordion} from "primeng/accordion";
import {ApplicationResourceService} from "../../../admin-panel/application-resource/application-resource.service";
import {KnownApplicationResources} from "../../../admin-panel/application-resource/application-resource";

export interface SidebarFieldChangeEvent {
    field: string;
    oldValue: any;
    newValue: any;
}

export type GateDataInput = GateSystemDefaults & Partial<Pick<GateData, 'width' | 'height' | 'lintelHeight' | 'wall1'  | 'wall2' | 'gateSystemId'
    | 'usedGlobalSettingsChanged' | 'usedGlobalSettingsLevel'>>;

@Component({
    selector: 'app-gate-sidebar',
    templateUrl: './gate-sidebar.component.html',
    styleUrls: ['../../window-editor/sidebar/sidebar.component.css'],
})
export class GateSidebarComponent implements OnChanges, OnInit {

    readonly Fields = GateEditorField;

    @ViewChild('mainAcc', {static: true}) mainAcc: Accordion;

    @Input()
    position: WindowEditorPositionData;

    @Input()
    data: GateDataInput;

    @Input()
    fields: GateEditorFieldStates;

    @Input()
    selectedGateSystem: GateSystem;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    readOnlyMode: boolean;

    @Input()
    colors: Color[];

    @Input()
    addonCategoryGroups: AddonCategoryGroup[];

    @Input()
    defaultsLevels: SelectItem[];

    @Input()
    defaultsLevel: 'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER';

    @Input()
    defaultsOverrideLowerLevel: boolean;

    @Output()
    readonly onGateSystemChange = new EventEmitter<void>();

    @Output()
    readonly beforeFieldChange = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly afterFieldChange = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly afterFieldBlur = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly onShowImage = new EventEmitter<WindowComponentPreviewData>();

    @Output()
    readonly onShowDescriptionDialog = new EventEmitter();

    @Output()
    readonly onShowAddonsDialog = new EventEmitter();

    @Output()
    readonly defaultsLevelChange = new EventEmitter<'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER'>();

    @Output()
    readonly defaultsOverrideLowerLevelChange = new EventEmitter<boolean>();

    @Output()
    readonly defaultsSavedClick = new EventEmitter<MouseEvent>();

    accordionTabState = {
        main: true,
        covering: false,
        description: false,
        defaults: false
    };

    readonly externalColors: Observable<SelectItem[]>;
    readonly internalColors: Observable<SelectItem[]>;
    private ralExternalColors: Color[];
    private ralInternalColors: Color[];
    selectingRalColorHeader: string;
    selectingRalColorField: GateEditorField;
    selectingRalColors: Color[];
    private colorBeforeRalSelection: Color;

    displayGatePanelTypeDialog = false;
    displayGateWall1Dialog = false;
    displayGateWall2Dialog = false;

    addonCategoryGroupName = 'ADDON_CATEGORY_GROUP_';

    constructor(public readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider,
                public readonly imageService: GateSidebarFieldImageService,
                public readonly translate: TranslateService) {
        const filterColorSelectItems = (colorSelectItem: SelectItem, selectedColorId: number) => {
            const color = this.colors.find(c => c.id === colorSelectItem.value);
            return color != undefined
                && (color.type !== ColorType.RAL_PALETTE_CUSTOM
                    || color.id === selectedColorId
                    || `${color.id}`.startsWith('PLACEHOLDER'));
        };
        this.externalColors = this.gateEditorFieldContentProvider.getItemsStream(GateEditorField.EXTERNAL_COLOR)
            .pipe(map(colorSelectItems => colorSelectItems
                .filter(colorSelectItem => filterColorSelectItems(colorSelectItem, this.data.externalColorId))));
        this.internalColors = this.gateEditorFieldContentProvider.getItemsStream(GateEditorField.INTERNAL_COLOR)
            .pipe(map(colorSelectItems => colorSelectItems
                .filter(colorSelectItem => filterColorSelectItems(colorSelectItem, this.data.internalColorId))));
    }

    trackByGroup = (index: number, group: AddonCategoryGroup) => group.id;

    ngOnInit() {
        this.accordionTabState = {
            main: true,
            covering: false,
            description: false,
            defaults: false
        };
        (this.addonCategoryGroups || []).forEach(group => this.accordionTabState[group.id] = false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const colorsChange = changes['colors'];
        if (colorsChange != undefined && colorsChange.currentValue != undefined) {
            this.ralExternalColors = colorsChange.currentValue
                .filter(color => color.type === ColorType.RAL_PALETTE_CUSTOM && !`${color.id}`.startsWith('PLACEHOLDER'));
            this.ralInternalColors = colorsChange.currentValue
                .filter(color => color.type === ColorType.RAL_PALETTE_CUSTOM && !`${color.id}`.startsWith('PLACEHOLDER'));
        }
    }

    handleFieldValueChange<K extends keyof GateDataInput>(field: string, dataField: K, value: GateDataInput[K]): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data[dataField], newValue: value};
        this.beforeFieldChange.emit(event);
        this.data[dataField] = value;
        this.afterFieldChange.emit(event);
        this.removeFieldFromUnavailable(field);
    }

    handleFieldBlur<K extends keyof GateDataInput>(field: string, dataField: K): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data[dataField], newValue: this.data[dataField]};
        this.afterFieldBlur.emit(event);
    }

    handleSidebarFieldValueChange(field: string, dataField: string, value: number): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data.sidebarAddons[dataField], newValue: value};
        this.beforeFieldChange.emit(event);
        this.data.sidebarAddons[dataField] = value;
        this.afterFieldChange.emit(event);
        this.removeFieldFromUnavailable(field);
    }

    handleColorChange<K extends 'externalColorId' | 'internalColorId'>(field: GateEditorField,
                                                                       dataField: K, colorId: GateDataInput[K] | 'PLACEHOLDER_RAL') {
        this.removeFieldFromUnavailable(field);
        const color = this.colors.find(c => c.id === colorId);
        if (color != undefined && typeof color.id !== 'number' && color.type === ColorType.RAL_PALETTE_CUSTOM) {
            const dependentOptionFilter = (selectableColor: Color) => this.gateEditorFieldContentProvider.getItems(field)
                .find(option => option.value === selectableColor.id);
            switch (field) {
                case GateEditorField.EXTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.colors.find(c => c.id === this.data.externalColorId);
                    this.selectingRalColors = this.ralExternalColors.filter(dependentOptionFilter);
                    break;
                case GateEditorField.INTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.colors.find(c => c.id === this.data.internalColorId);
                    this.selectingRalColors = this.ralInternalColors.filter(dependentOptionFilter);
                    break;
            }
            this.selectingRalColorHeader = 'OFFER.TABS.SECTION.COLOR.' + color.type;
            this.selectingRalColorField = field;
            this.handleFieldValueChange(field, dataField, undefined);
        } else {
            this.handleFieldValueChange(field, dataField, color != undefined ? color.id : undefined);
        }
    }

    handleRalColorSelected(color: Color): void {
        switch (this.selectingRalColorField) {
            case GateEditorField.EXTERNAL_COLOR:
                this.handleFieldValueChange(GateEditorField.EXTERNAL_COLOR, 'externalColorId',
                    color != undefined ? color.id : undefined);
                break;
            case GateEditorField.INTERNAL_COLOR:
                this.handleFieldValueChange(GateEditorField.INTERNAL_COLOR, 'internalColorId',
                    color != undefined ? color.id : undefined);
                break;
        }
        this.selectingRalColorHeader = undefined;
        this.selectingRalColorField = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    handleRalColorSelectionCancel(): void {
        switch (this.selectingRalColorField) {
            case GateEditorField.EXTERNAL_COLOR:
                this.handleFieldValueChange(GateEditorField.EXTERNAL_COLOR, 'externalColorId',
                    this.colorBeforeRalSelection != undefined ? this.colorBeforeRalSelection.id : undefined);
                break;
            case GateEditorField.INTERNAL_COLOR:
                this.handleFieldValueChange(GateEditorField.INTERNAL_COLOR, 'internalColorId',
                    this.colorBeforeRalSelection != undefined ? this.colorBeforeRalSelection.id : undefined);
                break;
        }
        this.selectingRalColorHeader = undefined;
        this.selectingRalColorField = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    handleGatePanelTypeSelectClick(event: MouseEvent): void {
        event.preventDefault();
        (event.target as HTMLSelectElement).blur();
        this.gateEditorFieldContentProvider.clearHasNewItemsMarker(GateEditorField.GATE_PANEL_TYPE);
        this.displayGatePanelTypeDialog = true;
    }

    handleGateWall1SelectClick(event: MouseEvent): void {
        event.preventDefault();
        (event.target as HTMLSelectElement).blur();
        this.gateEditorFieldContentProvider.clearHasNewItemsMarker(GateEditorField.GATE_WALL);
        this.displayGateWall1Dialog = true;
    }

    handleGateWall2SelectClick(event: MouseEvent): void {
        event.preventDefault();
        (event.target as HTMLSelectElement).blur();
        this.gateEditorFieldContentProvider.clearHasNewItemsMarker(GateEditorField.GATE_WALL);
        this.displayGateWall2Dialog = true;
    }

    handleGatePanelTypeSelected(gatePanelTypeId: number): void {
        this.validationErrors[GateEditorField.GATE_PANEL_TYPE] = undefined;
        this.handleFieldValueChange(GateEditorField.GATE_PANEL_TYPE, 'gatePanelTypeId', gatePanelTypeId);
    }

    handleGateWall1Selected(gateWallId: number): void {
        this.validationErrors[GateEditorField.GATE_WALL + '_1'] = undefined;
        this.handleFieldValueChange(GateEditorField.GATE_WALL, 'wall1', gateWallId);
    }

    handleGateWall2Selected(gateWallId: number): void {
        this.validationErrors[GateEditorField.GATE_WALL + '_2'] = undefined;
        this.handleFieldValueChange(GateEditorField.GATE_WALL, 'wall2', gateWallId);
    }

    handleShowImage(imageSource: Observable<string>, header: string): void {
        this.onShowImage.emit(new WindowComponentPreviewData(imageSource, header));
    }

    private removeFieldFromUnavailable(field: string): void {
        const unavailableFields = this.gateEditorFieldContentProvider.fieldsWithUnavailableValues;
        const index = unavailableFields.indexOf(field, 0);
        if (index > -1) {
            unavailableFields.splice(index, 1);
        }
    }

    showDescriptionDialog(): void {
        this.onShowDescriptionDialog.emit();
    }

    showAddonsDialog(): void {
        this.onShowAddonsDialog.emit();
    }

    get lintelImageUrl(): Observable<string> {
        return of(ApplicationResourceService.getItemUrlByName(KnownApplicationResources.GATE_LINTEL_IMAGE));
    }
}
