import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {EntranceGlazingPackage} from './entrance-glazing-package';
import {ListOfIds} from "../../ListOfIds";
import {map} from "rxjs/operators";

@Injectable()
export class EntranceGlazingPackageService implements CrudService<EntranceGlazingPackage> {

    private static readonly API_URL = 'entranceGlazingPackages';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<Listing<EntranceGlazingPackage>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<EntranceGlazingPackage>>(EntranceGlazingPackageService.API_URL, {params: params});
    }

    getItem(itemId: number): Observable<EntranceGlazingPackage> {
        return this.http.get<EntranceGlazingPackage>(`${EntranceGlazingPackageService.API_URL}/${itemId}`);
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${EntranceGlazingPackageService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImage(itemId: number): Observable<string> {
        return this.http.get(`${EntranceGlazingPackageService.API_URL}/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    saveItem(item: EntranceGlazingPackage, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('entranceGlazingPackageDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(`${EntranceGlazingPackageService.API_URL}`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToItemId(EntranceGlazingPackageService.API_URL, item.id));
    }

    addItem(item: EntranceGlazingPackage): Observable<number> {
        return this.saveItem(item, undefined);
    }

    editItem(itemId: number, item: EntranceGlazingPackage): Observable<number> {
        return this.saveItem(item, undefined);
    }

    copy(id: number, item: EntranceGlazingPackage, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('entranceGlazingPackageDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(`${EntranceGlazingPackageService.API_URL}/${id}/copy`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(EntranceGlazingPackageService.API_URL));
    }

    validate(item: EntranceGlazingPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${EntranceGlazingPackageService.API_URL}/validate`, item));
    }

    getPackagesForModel(modelId: number, selectedId: number = null): Observable<EntranceGlazingPackage[]> {
        let params: { [param: string]: string | string[] } = {};
        if (modelId) {
            params['modelId'] = modelId.toString();
        }
        if (selectedId) {
            params['selectedGlazingPackageId'] = selectedId.toString();
        }
        return this.http.get<object[]>(`${EntranceGlazingPackageService.API_URL}/forModel`, {params: params})
            .pipe(map(list => list.map(EntranceGlazingPackage.fromJSON)));
    }

    getLinkedModels(glazingPackageId: number): Observable<number[]> {
        return this.http.get<number[]>(`${EntranceGlazingPackageService.API_URL}/linked/${glazingPackageId}`);
    }

    editLinks(glazingPackageId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`${EntranceGlazingPackageService.API_URL}/link/${glazingPackageId}`, systemIds).pipe(map(() => 0));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${EntranceGlazingPackageService.API_URL}/names`, {params: params});
    }
}
