import {Injectable} from '@angular/core';
import {WizardFullscreenController} from '../../form-inputs/wizard/wizard-fullscreen-controller';
import {SidenavController} from '../../sidenav-controller';

@Injectable()
export class WizardSidenavAdapter implements WizardFullscreenController {

    constructor(private sidenavController: SidenavController) {
    }

    setFullscreen() {
        this.sidenavController.hide();
    }

    unsetFullscreen() {
        this.sidenavController.show();
    }
}
