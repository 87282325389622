export enum SubWindowTypeCode {
    F = "F",
    FF = "FF",
    U = "U",
    RP = "RP",
    RL = "RL",
    URP = "URP",
    URL = "URL",
    HSL = "HSL",
    HSP = "HSP",
    UPP = "UPP",
    UPL = "UPL",
    OG = "OG",
    OOG = "OOG",
    OP = "OP",
    OL = "OL",
    WL = "WL",
    WP = "WP",
    PL = "PL",
    PP = "PP",
    PD = "PD",
    A = "A",
    SHL = "SHL",
    SHP = "SHP",
    SDL = "SDL",
    SDP = "SDP",
    CPL = 'CPL',
    CPP = 'CPP',
    ROL = 'ROL',
    ROP = 'ROP',
    ROA = 'ROA',
    HL = 'HL',
    HP = 'HP',
}
