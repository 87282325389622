import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WebshopHelp, WebshopHelpType} from './webshop-help';

@Injectable()
export class WebshopHelpService {

    private static readonly API_URL = 'webshopHelp';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getAll(type: WebshopHelpType): Observable<WebshopHelp[]> {
        return this.http.get<object[]>(`${WebshopHelpService.API_URL}/list`, {params: {type: type}})
            .pipe(map(response => response.map(WebshopHelp.fromJSON)));
    }

    getHelp(id: number): Observable<WebshopHelp> {
        return this.http.get<object>(`${WebshopHelpService.API_URL}/${id}`).pipe(mapItemToJson(WebshopHelp));
    }

    getHelpImage(id: number): Observable<File> {
        return this.http.get(`${WebshopHelpService.API_URL}/${id}/image`, {
            observe: 'response',
            responseType: 'text'
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    saveHelp(newHelp: WebshopHelp, image: File | undefined,
             amazonS3File: File | string | undefined): Observable<number> {
        const formData = new FormData();
        formData.append('webshopHelpDto', new Blob([JSON.stringify(newHelp)], {type: 'application/json'}));
        if (image != undefined) {
            formData.append('image', image);
        }
        if (amazonS3File != undefined) {
            if (amazonS3File instanceof File) {
                formData.append('amazonS3File', amazonS3File);
            } else if (typeof amazonS3File === 'string') {
                formData.append('amazonS3FileName', amazonS3File);
            }
        }
        if (newHelp.id != undefined) {
            return this.http.put<void>(WebshopHelpService.API_URL, formData).pipe(this.dataServiceHelper.mapToExistingItemId(newHelp.id));
        }
        return this.http.post<void>(WebshopHelpService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(WebshopHelpService.API_URL));
    }

    canUploadToAmazonS3(): Observable<boolean> {
        return this.http.get<boolean>(`${WebshopHelpService.API_URL}/canUploadToAmazonS3`);
    }

    getUnusedAmazonS3Files(): Observable<string[]> {
        return this.http.get<string[]>(`${WebshopHelpService.API_URL}/unusedAmazonS3Files`);
    }
}
