<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.VENSKA_DATA.GENERAL' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">
                <div class="new-form-row">
                    <h3>{{ "SETTINGS.SECTION.VENSKA_DATA.GENERAL" | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="name" [(ngModel)]="venskaData.name"
                                    label="{{ 'SUBSYSTEM-DETAILS.FORM.NAME' | translate }}"
                                    [required]="true" [maxlength]="100"
                                    [(validationMessageKey)]="validationErrors['name']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="phoneNumber" [(ngModel)]="venskaData.phoneNumber"
                                    label="{{ 'SUBSYSTEM-DETAILS.FORM.PHONE_NUMBER' | translate }}"
                                    [required]="true" [maxlength]="50"
                                    [(validationMessageKey)]="validationErrors['phoneNumber']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="email" [(ngModel)]="venskaData.email"
                                    label="{{ 'SUBSYSTEM-DETAILS.FORM.EMAIL' | translate }}" [maxlength]="255"
                                    [(validationMessageKey)]="validationErrors['email']"></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="vatSell" [(ngModel)]="venskaData.vatSell"
                                      label="{{ 'SUBSYSTEM-DETAILS.FORM.VAT_SELL' | translate }}"
                                      [required]="true" [min]="0"
                                      [(validationMessageKey)]="validationErrors['vatSell']"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="vatBuy" [(ngModel)]="venskaData.vatBuy"
                                      label="{{ 'SUBSYSTEM-DETAILS.FORM.VAT_BUY' | translate }}"
                                      [required]="true" [min]="0"
                                      [(validationMessageKey)]="validationErrors['vatBuy']"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="defaultOfferDurationDays"
                                      label="{{ 'SUBSYSTEM-DETAILS.FORM.DEFAULT_OFFER_DURATION_DAYS' | translate }}"
                                      [(validationMessageKey)]="validationErrors['defaultOfferDurationDays']" [min]="1"
                                      [max]="31" [step]="1"
                                      [(ngModel)]="venskaData.defaultOfferDurationDays"></app-input-number>
                </div>
            </div>
        <div class="new-form-600">
                    <div class="new-form-row">
                        <h3>{{ "SUBSYSTEM-DETAILS.FORM.CORRESPONDENCE_ADDRESS" | translate }}</h3>
                    </div>

                    <div class="new-form-row">
                        <app-input-text inputId="correspondenceStreet" [(ngModel)]="venskaData.correspondenceAddress.street"
                                        label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}" [maxlength]="100"
                                        [(validationMessageKey)]="validationErrors['correspondenceAddress.street']"></app-input-text>
                    </div>

                    <div class="new-form-row">
                        <app-input-text inputId="correspondenceCity" [(ngModel)]="venskaData.correspondenceAddress.city"
                                        label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}" [maxlength]="50"
                                        [(validationMessageKey)]="validationErrors['correspondenceAddress.city']"></app-input-text>
                    </div>

                    <div class="new-form-row">
                        <app-select inputId="correspondenceCountry" [(ngModel)]="venskaData.correspondenceAddress.country"
                                    label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}" [options]="countries | async"
                                    [allowSelectingNone]="true"
                                    [(validationMessageKey)]="validationErrors['correspondenceAddress.country']"></app-select>
                    </div>

                    <div class="new-form-row">
                        <app-input-zip inputId="correspondenceZip" [(ngModel)]="venskaData.correspondenceAddress.zip"
                                       label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}"
                                       [country]="venskaData.correspondenceAddress.country"
                                       [disabled]="venskaData.correspondenceAddress.country == undefined"
                                       [(validationMessageKey)]="validationErrors['correspondenceAddress.zip']"></app-input-zip>
                    </div>


                <!-- DELIVERY ADDRESS -->
                    <div class="new-form-row">
                        <h3>{{ "SUBSYSTEM-DETAILS.FORM.DELIVERY_ADDRESS" | translate }}</h3>
                    </div>

                    <div class="new-form-row">
                        <app-input-text inputId="deliveryStreet" [(ngModel)]="venskaData.deliveryAddress.street"
                                        label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}" [maxlength]="100"
                                        [(validationMessageKey)]="validationErrors['deliveryAddress.street']"></app-input-text>
                    </div>

                    <div class="new-form-row">
                        <app-input-text inputId="deliveryCity" [(ngModel)]="venskaData.deliveryAddress.city"
                                        label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}" [maxlength]="50"
                                        [(validationMessageKey)]="validationErrors['deliveryAddress.city']"></app-input-text>
                    </div>

                    <div class="new-form-row">
                        <app-select inputId="deliveryCountry" [(ngModel)]="venskaData.deliveryAddress.country"
                                    label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}" [options]="countries | async"
                                    [allowSelectingNone]="true"
                                    [(validationMessageKey)]="validationErrors['deliveryAddress.country']"></app-select>
                    </div>

                    <div class="new-form-row">
                        <app-input-zip inputId="deliveryZip" [(ngModel)]="venskaData.deliveryAddress.zip"
                                       label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}"
                                       [country]="venskaData.deliveryAddress.country"
                                       [disabled]="venskaData.deliveryAddress.country == undefined"
                                       [(validationMessageKey)]="validationErrors['deliveryAddress.zip']"></app-input-zip>
                    </div>

                    <div class="new-form-row">
                        <app-file-upload inputId="image" [ngModel]="imageFile"
                                         (ngModelChange)="handleImageFileChange($event)"
                                         label="{{ 'SETTINGS.SECTION.VENSKA_DATA.LOGO' | translate }}"
                                         [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                         [addButtonLabel]="'SETTINGS.SECTION.VENSKA_DATA.SELECT_FILE_UPLOAD'"
                                         [changeButtonLabel]="'SETTINGS.SECTION.VENSKA_DATA.CHANGE_FILE_UPLOAD'"
                                         [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
                    </div>

                    <div class="new-form-row">
                        <app-file-upload inputId="logoGlamour" [ngModel]="logoGlamourFile"
                                         (ngModelChange)="handleLogoGlamourFileChange($event)"
                                         label="{{ 'SETTINGS.SECTION.VENSKA_DATA.LOGO_GLAMOUR' | translate }}"
                                         [maxSize]="150000" [maxWidth]="3000" [maxHeight]="3000"
                                         [widthToHeightRatio]="2.7"
                                         [addButtonLabel]="'SETTINGS.SECTION.VENSKA_DATA.SELECT_FILE_UPLOAD'"
                                         [changeButtonLabel]="'SETTINGS.SECTION.VENSKA_DATA.CHANGE_FILE_UPLOAD'"
                                         [(validationMessageKey)]="validationErrors['logoGlamour']"></app-file-upload>
                    </div>
                </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button buttonId="saveBtn" label="{{ 'GENERAL.SAVE' | translate }}" icon="check"
                               type="main-action" [size]="40" (onClick)="saveChanges()"></app-simple-button>
        </div>
    </div>
</div>

