<div class="new-form-400">
    <app-translation-fields inputId="name" label="{{ 'SUBSYSTEM_NOTES_CATEGORIES.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="50"
                            [required]="true" [field]="category?.name"></app-translation-fields>

    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'SUBSYSTEM_NOTES_CATEGORIES.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="category.active"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="category?.id != null">
        <app-input-number inputId="sortIndex" label="{{ 'SUBSYSTEM_NOTES_CATEGORIES.FORM.SORT_INDEX' | translate }}"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [(ngModel)]="category.sortIndex"></app-input-number>
    </div>
</div>
