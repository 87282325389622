import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {Permissions} from "../../auth/permission.service";
import {StorageKey, StorageService} from "../../auth/storage.service";
import {ComponentWithUserConfigAndPaginator} from "../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../common/dataServiceHelper";
import {ResponseError} from "../../common/error.handler";
import {AccessData} from "../AccessData";
import {ClientGroupService} from "./client-group.service";
import {ClientGroup} from "./ClientGroup";

@Component({
    selector: 'app-client-group',
    templateUrl: './client-group.component.html',
    styleUrls: ['./client-group.component.css', '../shared-styles.css'],
    providers: [ClientGroupService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientGroupComponent extends ComponentWithUserConfigAndPaginator implements OnDestroy {

    clientGroups: ClientGroup[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedClientGroup: ClientGroup;
    editedClientGroup: ClientGroup;
    lastEvent;

    @ViewChild('dt', {static: true}) datatable;

    constructor(public translate: TranslateService,
                public clientGroupService: ClientGroupService,
                public permissions: Permissions,
                public router: Router,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private storage: StorageService) {

        super(injector, changeDetector, 'ClientGroupComponent', false);
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.enterHotkey);
        super.ngOnDestroy();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    showDialogToAdd() {
        this.router.navigate(['/features/create-client-group']);
    }

    handleCancel() {
        this.editedClientGroup = undefined;
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedClientGroup);
    }

    doOnRowSelect(event) {
        this.validationErrors = {};
        this.onRowSelect(event);
        this.focusOnElementWithId(this.getFirstInputId());
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.editedClientGroup = this.cloneClientGroup(event.data);
        this.keepSelectedItemIndex(event);
    }

    cloneClientGroup(ug: ClientGroup): ClientGroup {
        let clientGroup = <ClientGroup>_.clone(ug);
        return clientGroup;
    }

    submit() {
        // do nothing
    }

    loadClientGroupsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.lastEvent = event;
        return this.clientGroupService.getClientGroups(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('ClientGroupListComponent `getPage` success:', data);
                    this.totalRecords = data.totalRecords;
                    this.clientGroups = data.data;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedClientGroup = this.restoreSelectionAfterLoad(this.selectedClientGroup, this.clientGroups, event);
                },
                error: error => {
                    console.error('ClientGroupListComponent `getPage` error:', error);
                    throw new ResponseError(error);
                },
                complete: () => {
                    console.info('ClientGroupListComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    handleDelete(success: boolean): void {
        if (success) {
            this.cleanUpAndReload();
            this.growlMessageController.info('CLIENT-GROUP.GROUP_REMOVED');
        } else {
            this.growlMessageController.error('CLIENT-GROUP.GROUP_CANNOT_BE_REMOVED');
        }
    }

    showSuccessMessage() {
        this.growlMessageController.info('CLIENT-GROUP.GROUP_UPDATED');
    }

    handleSave() {
        this.cleanUpAndReload();
        this.showSuccessMessage();
    }

    cleanUpAndReload() {
        this.editedClientGroup = undefined;
        this.loadClientGroupsLazy(this.lastEvent);
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    actionOnClick(action: string, clientGroup: ClientGroup): void {
        switch (action) {
            case 'SHOW_CLIENTS':
                this.goToClients(clientGroup);
                break;
            default:
                console.error("ClientGroupComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    goToClients(clientGroup: ClientGroup): void {
        this.router.navigate([AccessData.path.client(), {ignoreStoredFilter: true}], {queryParams: {clientGroupId: clientGroup.id}});
    }
}
