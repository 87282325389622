import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {WindowTypeCodeParser} from "../../../../../window-designer/utils/WindowTypeCodeParser";
import {WindowTypeCode} from "../../../../../window-designer/window-types/window-type-code";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {BusinessTypeField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {BusinessTypeFieldUsage} from "../../catalog-field-usage";
import {BusinessType} from "../BusinessType";

@Component({
    selector: 'app-business-type-form',
    templateUrl: './business-type-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessTypeFormComponent {

    @Input() item: BusinessType;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[] = [];

    fieldUsage: BusinessTypeFieldUsage;
    BusinessTypeField = BusinessTypeField;

    nameParser: (typeCode: WindowTypeCode) => string;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public translateService: TranslateService) {
        this.nameParser = WindowTypeCodeParser.getTypeName;
        this.fieldUsage = new BusinessTypeFieldUsage(this);
    }
}
