import {SubwindowTypeInterface} from '../../../../window-designer/catalog-data/subwindow-type-interface';
import {SubWindowTypeCode} from "../../../../window-designer/window-types/subwindow-type-code";
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class SubwindowType implements CrudItem, SubwindowTypeInterface {
    id: number;
    names: MultilanguageField;
    type: SubWindowTypeCode;
    functionsQuantity: number;

    constructor() {
        this.id = undefined;
        this.names = {};
    }
}
