import {Injectable} from '@angular/core';
import {Translation} from 'primeng/api';
import {LocaleSettings} from 'primeng/calendar';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';

const CalendarLocale: {
    [lang in keyof MultilanguageFieldInterface]: LocaleSettings & Translation
} = {
    en: {
        firstDayOfWeek: 1,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Sn', 'M', 'T', 'W', 'T', 'F', 'St'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
            'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Wk'
    },
    pl: {
        firstDayOfWeek: 1,
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['niedz', 'pon', 'wt', 'śr', 'czw', 'pt', 'sob'],
        dayNamesMin: ['N', 'PN', 'W', 'Ś', 'CZ', 'PT', 'S'],
        monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec',
            'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthNamesShort: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze',
            'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
        today: 'Dzisiaj',
        clear: 'Wyczyśc',
        dateFormat: 'dd.mm.yy',
        weekHeader: 'T'
    },
    no: {
        firstDayOfWeek: 1,
        dayNames: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
        dayNamesShort: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
        dayNamesMin: ['S', 'M', 'Ti', 'O', 'To', 'F', 'L'],
        monthNames: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober',
            'november', 'desember'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'],
        today: 'BRAK',
        clear: 'BRAK',
        dateFormat: 'd.m.yy',
        weekHeader: 'Uke'
    },
    se: {
        firstDayOfWeek: 1,
        dayNames: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
        dayNamesShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],
        dayNamesMin: ['S', 'M', 'Ti', 'O', 'To', 'F', 'L'],
        monthNames: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober',
            'november', 'december'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        today: 'BRAK',
        clear: 'BRAK',
        dateFormat: 'yy-mm-dd',
        weekHeader: 'V.'
    },
    da: {
        firstDayOfWeek: 1,
        dayNames: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
        dayNamesShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
        dayNamesMin: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
        monthNames: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober',
            'november', 'december'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        today: 'BRAK',
        clear: 'BRAK',
        dateFormat: 'yy-mm-dd',
        weekHeader: 'Uge'
    },
    de: {
        firstDayOfWeek: 1,
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan.', 'Feb.', 'März', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'],
        today: 'BRAK',
        clear: 'BRAK',
        dateFormat: 'dd.mm.yy',
        weekHeader: 'Kw'
    },
    ca: {
        firstDayOfWeek: 1,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Sn', 'M', 'T', 'W', 'T', 'F', 'St'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
            'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'yy-mm-dd',
        weekHeader: 'Wk'
    },
};

@Injectable({
    providedIn: 'root'
})
export class CalendarLocaleProvider {

    getPrimengCalendarSettings(lang: string): LocaleSettings {
        if (lang && CalendarLocale.hasOwnProperty(lang)) {
            return CalendarLocale[lang];
        }
        return CalendarLocale.en;
    }
}
