<div class="new-form-600">
    <div class="new-form-row">
        <app-composition-distances-form [compositionDistances]="windowSystem.wingWidth"
                                        [disabled]="disabled"
                                        [fieldName]="'wingWidth'"
                                        [label]="'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.WING_WIDTH'"
                                        [validationErrors]="validationErrors"></app-composition-distances-form>
    </div>
    <div class="new-form-row">
        <app-composition-distances-form [compositionDistances]="windowSystem.glazingBeadWidth"
                                        [disabled]="disabled"
                                        [fieldName]="'glazingBeadWidth'"
                                        [label]="'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.GLAZING_BEAD_WIDTH'"
                                        [validationErrors]="validationErrors"></app-composition-distances-form>
    </div>
    <div class="new-form-row">
        <app-composition-distances-form [compositionDistances]="windowSystem.wingImpositionWidth"
                                        [disabled]="disabled"
                                        [fieldName]="'wingImpositionWidth'"
                                        [label]="'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.WING_IMPOSITION_WIDTH'"
                                        [validationErrors]="validationErrors"></app-composition-distances-form>
    </div>
    <div class="new-form-row">
        <app-composition-distances-form [compositionDistances]="windowSystem.glazingPackageSeatingDepth"
                                        [disabled]="disabled"
                                        [fieldName]="'glazingPackageSeatingDepth'"
                                        [label]="'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.GLAZING_PACKAGE_SEATING_DEPTH'"
                                        [validationErrors]="validationErrors"></app-composition-distances-form>
    </div>
    <ng-container *ngIf="windowSystem.hsSystem">
        <div class="new-form-row">
            <app-input-number inputId="wingsImpositionWidth"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.WINGS_IMPOSITION_WIDTH' | translate }}"
                              [disabled]="disabled"
                              [(validationMessageKey)]="validationErrors['wingsImpositionWidth']" [min]="1"
                              [max]="99999" [required]="true"
                              [(ngModel)]="windowSystem.wingsImpositionWidth"></app-input-number>
        </div>
        <div class="new-form-row">
            <app-input-number inputId="wingsContactWidth"
                              label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.WINGS_CONTACT_WIDTH' | translate }}"
                              [disabled]="disabled"
                              [(validationMessageKey)]="validationErrors['wingsContactWidth']" [min]="1"
                              [max]="99999" [required]="true"
                              [(ngModel)]="windowSystem.wingsContactWidth"></app-input-number>
        </div>
    </ng-container>
    <div class="new-form-row">
        <app-input-number inputId="frameThickness"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.FRAME_THICKNESS' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['frameThickness']" [min]="1"
                          [max]="999" [required]="true"
                          [(ngModel)]="windowSystem.frameThickness"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="frameWingCompositionThickness"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.FRAME_WING_COMPOSITION_THICKNESS' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['frameWingCompositionThickness']" [min]="1"
                          [max]="999" [required]="true"
                          [(ngModel)]="windowSystem.frameWingCompositionThickness"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="systemThickness"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.ASSEMBLY.SYSTEM_THICKNESS' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['systemThickness']" [min]="1" [max]="999"
                          [allowOnlyIntegers]="true" [(ngModel)]="windowSystem.systemThickness"></app-input-number>
    </div>

    <div class="new-form-row">
        <app-checkbox inputId="standardDoorstepAvailable"
                      label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.STANDARD_DOORSTEP_AVAILABLE' | translate }}"
                      [(validationMessageKey)]="validationErrors['standardDoorstepAvailable']"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystem.standardDoorstepAvailable"></app-checkbox>
    </div>

    <div class="new-form-row">
        <app-checkbox inputId="doorstepAllowedInFF"
                      label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DOORSTEP_ALLOWED_IN_FF' | translate }}"
                      [(validationMessageKey)]="validationErrors['doorstepAllowedInFF']"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystem.doorstepAllowedInFF"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="isRegularWindow">
        <app-select inputId="windowSystemForStandaloneGlazingPackageId"
                    label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.STANDALONE_GLAZING_PACKAGE_FALLBACK_WINDOW_SYSTEM' | translate }}"
                    [(validationMessageKey)]="validationErrors['windowSystemForStandaloneGlazingPackageId']"
                    [options]="windowSystems | selectItemMultilanguageFieldTranslate" [allowSelectingNone]="true"
                    [disabled]="disabled"
                    [(ngModel)]="windowSystem.windowSystemForStandaloneGlazingPackageId"></app-select>
    </div>
</div>
