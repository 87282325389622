export class SubsystemNote {
    id: number;
    date: Date;
    creationDate: Date;
    categoryId: number;
    author: string;
    note: string;
    subsystemId: number;

    static fromJSON(jsonObject: any): SubsystemNote {
        const note = new SubsystemNote();
        note.id = jsonObject.id;
        note.date = jsonObject.date;
        note.creationDate = jsonObject.creationDate;
        note.categoryId = jsonObject.categoryId;
        note.author = jsonObject.author;
        note.note = jsonObject.note;
        note.subsystemId = jsonObject.subsystemId;
        return note;
    }
}
