<p-dialog [visible]="true" [resizable]="false" (onHide)="hideDialog()" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.EDIT_BULK_ADDON.TITLE' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-text inputId="name" label="{{ 'ADDONS.FORM.NAME' | translate }}" [disabled]="true"
                            [ngModel]="addon.name[translate.currentLang]"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="category" label="{{ 'ADDONS.FORM.CATEGORY' | translate }}" [disabled]="true"
                            [ngModel]="('ADDONS.CATEGORIES.' + addon.category) | translate"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="pcn" label="{{ 'ADDONS.FORM.PCN' | translate }}" [disabled]="true"
                            [ngModel]="addon.pcn"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="supplier" label="{{ 'ADDONS.FORM.SUPPLIER' | translate }}" [disabled]="true"
                            [ngModel]="formatSupplierName(addon)"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="price" label="{{ 'ADDONS.FORM.PRICE_TYPE.AMOUNT' | translate }}" [disabled]="true"
                            [ngModel]="formatPrice(addon, 1) | translate"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-number inputId="quantity" [(validationMessageKey)]="validationErrors[addon.id + '_quantity']"
                              [disabled]="readOnlyMode || !addon.changeQuantity" [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                              [(ngModel)]="addon.defaultQuantity" [allowOnlyIntegers]="isAllowOnlyIntegers()">
                <ng-template pTemplate="label">
                    <label for="quantity">
                        {{ 'OFFER.POSITIONS.DIALOGS.EDIT_BULK_ADDON.QUANTITY' | translate }}
                        ({{('ADDONS.QUANTITY_TYPE.FULL_NAME.' + addon.quantityType) | translate}})
                    </label>
                </ng-template>
            </app-input-number>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="name"
                            label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_BULK_ADDON.SUMMARY_PRICE' | translate }}"
                            [disabled]="true"
                            [ngModel]="formatPrice(addon, addon.defaultQuantity) | translate"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-text-area inputId="description" [(ngModel)]="dialogData.offerPosition.otherInfo" [disabled]="readOnlyMode"
                           [maxlength]="lengthLimit.OTHER_INFO_MAX_LENGTH"
                           label="{{ 'OFFER.POSITIONS.DIALOGS.SHOW_DESCRIPTION' | translate }}">
            </app-text-area>
        </div>

        <p-accordion>
            <p-accordionTab *ngIf="addon.chooseColor" header="{{ 'ADDONS.FORM.COLORS' | translate }}">
                <div class="new-form-row">
                    <app-select inputId="coreColor" label="{{ 'ADDONS.FORM.CORE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_coreColor']"
                                [options]="addon.availableCoreColors" [disabled]="readOnlyMode"
                                [(ngModel)]="addon.selectedCoreColor"></app-select>
                </div>
                <div class="new-form-row">
                    <app-select inputId="outsideColor" label="{{ 'ADDONS.FORM.OUTSIDE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_outsideCoverColor']"
                                [options]="addon.availableOutsideColors" [allowSelectingNone]="true"
                                [disabled]="readOnlyMode" [(ngModel)]="addon.selectedOutsideColor"></app-select>
                </div>
                <div class="new-form-row">
                    <app-select inputId="insideColor" label="{{ 'ADDONS.FORM.INSIDE_COLORS' | translate }}"
                                [(validationMessageKey)]="validationErrors[addon.id + '_insideCoverColor']"
                                [options]="addon.availableInsideColors" [allowSelectingNone]="true"
                                [disabled]="readOnlyMode" [(ngModel)]="addon.selectedInsideColor"></app-select>
                </div>
            </p-accordionTab>
            <p-accordionTab *ngIf="imagePreview" header="{{ 'ADDONS.FORM.PREVIEW_IMAGE' | translate }}">
                <div class="new-form-row">
                    <div class="new-form-field">
                        <label></label>
                        <div class="new-form-field-input-container">
                            <img id="imagePreview" [src]="imagePreview" class="max-height400 max-width400" />
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40" buttonId="saveBtn"
                               [disabled]="readOnlyMode" (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="hideDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
