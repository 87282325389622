import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {WindowSystemMaterialLinks} from '../single-system-checkbox-crud/window-system-material-links';
import {GlazingPackage, GlazingPackageTarget} from './glazing-package';

@Injectable()
export abstract class AbstractGlazingPackageService<Item extends GlazingPackage> extends SingleSystemCheckboxCrudService<Item> {

    static readonly COMMON_API_URL = 'glazingPackages';

    abstract getApiUrl(): string;

    private getCommonApiUrl(): string {
        return AbstractGlazingPackageService.COMMON_API_URL;
    }

    protected constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    addItem(item: Item): Observable<number> {
        return this.saveItem(item, undefined);
    }

    editItem(itemId: number, item: Item): Observable<number> {
        return undefined;
    }

    getItem(itemId: number): Observable<Item> {
        return this.http.get<Item>(`${this.getCommonApiUrl()}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Item & WindowSystemMaterialLinks>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<Item & WindowSystemMaterialLinks>>(this.getCommonApiUrl(), {params: params});
    }

    saveItem(item: Item, file?: File): Observable<number> {
        let formData = this.preparePackageFormData(item);

        if (file) {
            formData.append('file', file);
        }

        return this.http.post(this.getCommonApiUrl(), formData, {observe: 'response'}).pipe(
            map(response => item.id != undefined ? item.id : this.dataServiceHelper.getNewItemId(this.getCommonApiUrl(), response)));
    }

    getImage(glazingId: number): Observable<File> {
        return this.http.get(`${this.getApiUrl()}/${glazingId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    copy(id: number, item: Item, file?: File): Observable<number> {
        let formData = this.preparePackageFormData(item);

        if (file) {
            formData.append('file', file);
        }
        return this.http.post(`${this.getCommonApiUrl()}/${id}/copy`, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(this.getCommonApiUrl(), response)));
    }

    private preparePackageFormData(item: Item) {
        let body = JSON.stringify(item);

        let formData = new FormData();
        formData.append('glazingPackageDto', new Blob([body], {
            type: 'application/json'
        }));

        let body2 = JSON.stringify(item.glazingPackageForAreaRangeList);
        formData.append('glazingPackageForAreaRanges', new Blob([body2], {type: 'application/json'}));
        return formData;
    }

    validateGeneralData(item: Item): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${this.getCommonApiUrl()}/validateGeneralData`, item));
    }

    validateGlazingData(item: Item): Observable<ValidationErrors> {
        let formData = this.preparePackageFormData(item);
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${this.getCommonApiUrl()}/validateGlazingData`, formData));
    }

    getActiveGlazingForCurrentSubsystem(windowSystemId: number): Observable<Item[]> {
        return this.http.get<Item[]>(`${this.getApiUrl()}/glazingPackagesForCurrentSubsystem/forWindowSystem/${windowSystemId}`);
    }

    getActiveGlazingForTerrace(windowSystemId: number, selectedId: number, readOnly = false): Observable<Item[]> {
        const params = selectedId ? {selectedId: selectedId.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<Item[]>(`${this.getApiUrl()}/glazingPackagesForTerraceWindowSystem/${windowSystemId}`,
            {params: params});
    }

    setGlazingWindowSystemLinks(glazingId: number, linkedSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`${this.getCommonApiUrl()}/link/${glazingId}`, linkedSystemIds);
    }

    getAllActiveGlazingUpsellings(): Observable<Listing<Item>> {
        let filters = {
            target: {value: GlazingPackageTarget.UPSELLING_GLAZING},
            active: {value: 'true'}
        };
        let params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, 'sortIndex', 1);
        return this.http.get<Listing<Item>>(this.getCommonApiUrl(), {params: params});
    }
}
