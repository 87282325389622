import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Dialog} from 'primeng/dialog';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../../../block-ui/block-ui-controller';
import {PaginatorRowsPerPageOptions} from '../../../../common/crud-common/paginatorRowsPerPageOptions';
import {ErrorResponse} from '../../../errors/errorResponse';
import {ProductionOrderService} from '../production-order-service';
import {TemporaryWindowSystemDealerDiscountValue} from '../temporary-window-system-dealer-discount-value';

@Component({
    selector: 'app-change-system-dealer-discount',
    templateUrl: './change-system-dealer-discount.component.html',
    styleUrls: ['./change-system-dealer-discount.component.css'],
    providers: [ProductionOrderService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeSystemDealerDiscountComponent implements OnInit {

    readonly rowsPerPageOptions: number[] = PaginatorRowsPerPageOptions.values;
    chosenRowsPerPage = PaginatorRowsPerPageOptions.defaultValue;

    @Input()
    productionOrderId: number;

    @Output()
    onClose = new EventEmitter<boolean>();

    visible = false;

    dealerDiscountValues: TemporaryWindowSystemDealerDiscountValue[] = [];
    totalRecords = 0;
    selectedDealerDiscountValue: TemporaryWindowSystemDealerDiscountValue;
    closeWithSuccess = false;

    private readonly BLOCK_NAME = 'ChangeSystemDealerDiscountSave';

    constructor(public translate: TranslateService,
                private productionOrderService: ProductionOrderService,
                private blockUiController: BlockUiController,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.productionOrderService.getWindowSystemDealerDiscountValues(this.productionOrderId)
            .subscribe({
                next: dealerDiscountValues => {
                    this.dealerDiscountValues = dealerDiscountValues;
                    this.dealerDiscountValues.sort((a, b) => a.windowSystemSortIndex - b.windowSystemSortIndex);
                    this.totalRecords = dealerDiscountValues.length;
                },
                error: () => {
                    this.close();
                },
                complete: () => {
                    this.visible = true;
                    this.changeDetector.markForCheck();
                }
            });
    }

    handleVisibleChange(visible: boolean): void {
        if (!visible) {
            this.close();
        }
    }

    submit(dialog: Dialog, event: Event): void {
        this.blockUiController.block(this.BLOCK_NAME);
        this.productionOrderService.changeWindowSystemDealerDiscounts(this.productionOrderId, this.dealerDiscountValues)
            .pipe(finalize(() => this.blockUiController.unblock(this.BLOCK_NAME)))
            .subscribe({
                next: () => {
                    this.closeWithSuccess = true;
                    dialog.close(event);
                },
                error: (error: HttpErrorResponse) => {
                    const errorResponse = new ErrorResponse(error.error);
                    if (errorResponse.is400()) {
                        const errorKeys = Object.keys(errorResponse.invalidFields);
                        for (let errorKey of errorKeys) {
                            const index = +/values\[(\d+)]/.exec(errorKey)[1];
                            this.dealerDiscountValues[index]['error'] = errorResponse.invalidFields[errorKey]
                                .replace(new RegExp('changeTemporaryWindowSystemDealerDiscountValue\\.values\\[\\d+\\]'),
                                    'temporaryWindowSystemDealerDiscountValue');
                        }
                    }
                    this.changeDetector.markForCheck();
                }
            });
    }

    private close(): void {
        this.onClose.emit(this.closeWithSuccess);
    }

    handleFilter(event: { filters: { [s: string]: FilterMetadata; }, filteredValue: TemporaryWindowSystemDealerDiscountValue[] }): void {
        this.totalRecords = event.filteredValue.length;
        this.changeDetector.markForCheck();
    }
}
