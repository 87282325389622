import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class GlazingBead implements CrudItem {

    id: number;
    active: boolean;
    symbol: string;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    thickness: number;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    acceptableFillingWidth: string;
    sortIndex: number;

    constructor() {
        this.id = undefined;
        this.active = true;
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.pcv = "UNCHECKED";
        this.aluminium = "UNCHECKED";
        this.wood = "UNCHECKED";
        this.aluminiumWoodMix = "UNCHECKED";
        this.acceptableFillingWidth = undefined;
        this.sortIndex = 1;
    }

    static parseAcceptableFillingWidth(glazingBead: GlazingBead) {
        let fromToRange = glazingBead.acceptableFillingWidth.split("-");
        return {from: +fromToRange[0], to: +fromToRange[1]};
    }
}
