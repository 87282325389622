<ng-container *ngFor="let num of dimensionsRange">
    <div class="new-form-row">
        <ng-container *ngTemplateOutlet="checkbox; context: { num: num, bulk: dimensionsType === 'bulk' }"></ng-container>
    </div>
    <ng-container *ngIf="item[dimensionsType === 'bulk' ? ('hasDimension' + num)
                        : num <= 3 ? ('hasWidth' + num) : ('hasHeight' + (num - 3))]">
        <app-translation-fields [field]="dimensionLabelKeysMap.get(getLabelKey('name', num))"
                                [required]="true"
                                [validationErrors]="validationErrors" inputId="dimension_{{dimensionsType}}_{{num}}_name"
                                label="{{ 'CONFIG_SYSTEM.FORM.NAME' | translate }}"
                                validationKeyPrefix="name_{{dimensionsType}}_{{dimensionsType === 'bulk' ? 'dim'
                                    : (num <= 3 ? 'width' : 'height')}}_{{dimensionsType === 'window' && num > 3 ? (num - 3) : num}}"></app-translation-fields>
        <app-translation-fields [field]="dimensionLabelKeysMap.get(getLabelKey('print_name', num))"
                                [required]="true"
                                [validationErrors]="validationErrors" inputId="dimension_{{dimensionsType}}_{{num}}_printName"
                                label="{{ 'CONFIG_SYSTEM.FORM.PRINT_NAME' | translate }}"
                                validationKeyPrefix="print_name_{{dimensionsType}}_{{dimensionsType === 'bulk' ? 'dim'
                                    : (num <= 3 ? 'width' : 'height')}}_{{dimensionsType === 'window' && num > 3 ? (num - 3) : num}}"></app-translation-fields>
        <app-translation-fields *ngIf="dimensionsType === 'bulk'" [field]="dimensionLabelKeysMap.get(getLabelKey('tooltip', num))"
                                [validationErrors]="validationErrors" inputId="dimension_{{dimensionsType}}_{{num}}_tooltip"
                                label="{{ 'CONFIG_SYSTEM.FORM.TOOLTIP' | translate }}"
                                validationKeyPrefix="tooltip_{{dimensionsType}}_dim_{{num}}"></app-translation-fields>
    </ng-container>
</ng-container>

<ng-template #checkbox let-num="num" let-bulk="bulk">
    <ng-container *ngIf="bulk">
        <app-checkbox [(ngModel)]="item['hasDimension' + num]"
                      inputId="dimension_{{dimensionsType}}_{{num}}"
                      label="{{ ('CONFIG_SYSTEM.FORM.DIMENSION' | translate:{ num: num }) +
                        (num === 1 ? (' ' + ('CONFIG_SYSTEM.FORM.DIMENSION_PRICETABLE_WIDTH' | translate)) : '') +
                        (num === 2 ? (' ' + ('CONFIG_SYSTEM.FORM.DIMENSION_PRICETABLE_HEIGHT' | translate)) : '')
                      }}"
        ></app-checkbox>
    </ng-container>

    <ng-container *ngIf="!bulk">
        <app-checkbox [(ngModel)]="item[num <= 3 ? ('hasWidth' + num) : ('hasHeight' + (num - 3))]"
                      inputId="dimension_{{dimensionsType}}_{{num}}"
                      label="{{ (num <= 3 ? ('CONFIG_SYSTEM.FORM.WIDTH' | translate:{ num: num })
                                          : ('CONFIG_SYSTEM.FORM.HEIGHT' | translate:{ num: num - 3 })) +
                         (num === 1 || num === 4 ? (' ' + ('CONFIG_SYSTEM.FORM.PRICETABLE_DIMENSION' | translate)) : '')
                      }}"
        ></app-checkbox>
    </ng-container>
</ng-template>
