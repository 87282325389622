import {WindowAddon} from "../../../../../window-designer/drawing-data/WindowAddon";
import {WindowAddonData} from '../../../../../window-designer/drawing-data/WindowAddonData';
import {RandomIdGenerator} from "../../../../../window-designer/utils/RandomIdGenerator";

export class RoofWindowData implements WindowAddonData {
    generatedId: string;
    systemId: number;
    glazingPackageId: number;
    dimensionsId: number;
    flashing: WindowAddon;
    flashingAkp: WindowAddon;
    addons: WindowAddon[];

    constructor() {
        this.generatedId = RandomIdGenerator.generate();
        this.flashing = new WindowAddon();
        this.flashingAkp = new WindowAddon();
        this.addons = [];
    }
}
