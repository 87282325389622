import {ConfigAddonOpening} from '../entities/ConfigAddonOpening';
import {WindowSystemDefaults} from '../entities/window-system-defaults';
import {UfDataElement} from '../uf-calculator';
import {WindowSystemDefaultsUtil} from "../utils/WindowSystemDefaultsUtil";
import {CutData} from './CutData';
import {GuidesData} from './GuidesData';
import {RectWindowShape} from './RectWindowShape';
import {WindowAddon} from './WindowAddon';
import {WindowAddonData} from './WindowAddonData';
import {WindowData} from './WindowData';
import {WindowFunction} from './WindowFunction';
import {WindowShape} from './WindowShape';
import {WindowView} from './WindowView';
import {UpsellingChargeData} from "../entities/upselling-charge-data";
import {VentilationUtils} from "../utils/VentilationUtils";
import {ProfilesCompositionDistances} from "../profiles-composition-distances";
import {DrawingUtil} from "../drawing-util";
import {UpsellingUtils} from "../utils/UpsellingUtils";

export class DrawingDataSpecification {
    colorIdCore: number;
    colorIdExternal: number;
    colorOtherInfoExternal: string;
    colorIdInternal: number;
    colorOtherInfoInternal: string;
    doorstepId: number;
    frameProfileId: number;
    sealExternalId: number;
    sealInternalId: number;
    cover: WindowAddon;
    fittingBrake: WindowAddon;
    fittingSliding: WindowAddon;
    fittingType: WindowAddon;
    fittingEspagnoletteType: WindowAddon;
    fittingVeranda: WindowAddon;
    fittingInsertion: WindowAddon;
    fittingMainInsertion: WindowAddon;
    fittingAdditionalInsertion: WindowAddon;
    fittingLock: WindowAddon;
    fittingLockTerrace: WindowAddon;
    fittingLockTerraceLocation: string;
    fittingAutomaticDrive: WindowAddon;
    frameEnhancement: WindowAddon;
    handleType: WindowAddon;
    milling: WindowAddon;
    millingNorwegian: WindowAddon;
    underWindowBead: WindowAddon;
    underwindowProfile: WindowAddon;
    weldType: WindowAddon;
    terraceHandle: WindowAddon;
    terraceHandleLayout: string;
    ufData: UfDataElement[];
    opening: string;
    windowFunction: string;
    terraceGlazingPackageId: number;
    channelSectionId: number;
    constructionalMullionId: number;
    movablePostId: number;

    constructor() {
        this.colorIdCore = undefined;
        this.colorIdExternal = undefined;
        this.colorIdInternal = undefined;
        this.doorstepId = undefined;
        this.frameProfileId = undefined;
        this.sealExternalId = undefined;
        this.sealInternalId = undefined;
        this.cover = new WindowAddon();
        this.fittingBrake = new WindowAddon();
        this.fittingSliding = new WindowAddon();
        this.fittingType = new WindowAddon();
        this.fittingEspagnoletteType = new WindowAddon();
        this.fittingVeranda = new WindowAddon();
        this.fittingInsertion = new WindowAddon();
        this.fittingMainInsertion = new WindowAddon();
        this.fittingAdditionalInsertion = new WindowAddon();
        this.fittingLock = new WindowAddon();
        this.fittingLockTerrace = new WindowAddon();
        this.fittingLockTerraceLocation = undefined;
        this.fittingAutomaticDrive = new WindowAddon();
        this.frameEnhancement = new WindowAddon();
        this.handleType = new WindowAddon();
        this.milling = new WindowAddon();
        this.millingNorwegian = new WindowAddon();
        this.underWindowBead = new WindowAddon();
        this.underwindowProfile = new WindowAddon();
        this.weldType = new WindowAddon();
        this.terraceHandle = new WindowAddon();
        this.terraceHandleLayout = undefined;
        this.ufData = undefined;
        this.opening = undefined;
        this.windowFunction = WindowFunction[WindowFunction.EXTERNAL];
        this.terraceGlazingPackageId = undefined;
        this.channelSectionId = undefined;
        this.constructionalMullionId = undefined;
        this.movablePostId = undefined;
    }

    public static generateSpecificationFromDefaults(defaultsData: WindowSystemDefaults): DrawingDataSpecification {
        let specification = new DrawingDataSpecification();
        specification.colorIdCore = defaultsData.colorIdCore;
        specification.colorIdExternal = defaultsData.colorIdExternal;
        specification.colorIdInternal = defaultsData.colorIdInternal;
        specification.doorstepId = defaultsData.doorstepId;
        specification.frameProfileId = defaultsData.frameProfileId;
        specification.sealExternalId = defaultsData.sealColorExternalId;
        specification.sealInternalId = defaultsData.sealColorInternalId;
        specification.cover.addonId = defaultsData.coverId;
        specification.fittingBrake.addonId = defaultsData.fittingBrakeId;
        specification.fittingSliding.addonId = defaultsData.fittingSlidingId;
        specification.fittingType.addonId = defaultsData.fittingTypeId;
        specification.fittingEspagnoletteType.addonId = defaultsData.fittingEspagnoletteTypeId;
        specification.fittingVeranda.addonId = defaultsData.fittingVerandaId;
        specification.fittingInsertion.addonId = defaultsData.fittingInsertionId;
        specification.fittingMainInsertion.addonId = defaultsData.fittingMainInsertionId;
        specification.fittingAdditionalInsertion.addonId = defaultsData.fittingAdditionalInsertionId;
        specification.fittingLock.addonId = defaultsData.fittingLockId;
        specification.fittingLockTerrace.addonId = defaultsData.fittingLockTerraceId;
        specification.fittingLockTerraceLocation = defaultsData.fittingLockTerraceLocation;
        specification.fittingAutomaticDrive.addonId = defaultsData.fittingAutomaticDriveId;
        specification.frameEnhancement.addonId = defaultsData.frameEnhancementId;
        specification.handleType.addonId = defaultsData.handleTypeId;
        specification.milling.addonId = defaultsData.millingId;
        specification.millingNorwegian.addonId = defaultsData.millingNorwegianId;
        specification.underWindowBead.addonId = defaultsData.underWindowBeadId;
        specification.underwindowProfile.addonId = defaultsData.underwindowProfileId;
        specification.weldType.addonId = defaultsData.weldTypeId;
        specification.terraceHandle.addonId = defaultsData.terraceHandleId;
        specification.terraceHandleLayout = defaultsData.terraceHandleLayout;
        specification.opening = defaultsData.opening;
        specification.windowFunction = defaultsData.windowFunction;
        specification.terraceGlazingPackageId = defaultsData.terraceGlazingPackageId;
        specification.channelSectionId = defaultsData.channelSectionId;
        specification.constructionalMullionId = defaultsData.constructionalMullionId;
        specification.movablePostId = defaultsData.movablePostId;

        return specification;
    }
}

export class DrawingData implements WindowAddonData {
    windows: WindowData[];
    cuts: CutData[];
    nonstandardFrameCutAnglesCount: number;
    nonstandardWingCutAnglesCount: number;
    nonstandardMullionCutAnglesCount: number; // both "fake mullions" (frame between subwindows) and standard mullions
    addons: WindowAddon[];
    shape: WindowShape;
    totalPerimeter: number;
    configurableAddonIds: number[];
    guides: GuidesData;
    windowSystemId: number;
    specification: DrawingDataSpecification;
    view: string;
    usedGlobalSettingsLevel: string;
    usedGlobalSettingsChanged: boolean;

    wingedParent = true;
    openings: ConfigAddonOpening[] = [];

    glazingPackageForAreaId: string;

    constructor() {
        this.windows = [];
        this.cuts = [];
        this.addons = [];
        this.shape = new RectWindowShape();
        this.totalPerimeter = undefined;
        this.configurableAddonIds = [];
        this.guides = new GuidesData();
        this.windowSystemId = undefined;
        this.specification = new DrawingDataSpecification();
        this.view = WindowView[WindowView.INSIDE];
        this.usedGlobalSettingsLevel = undefined;
        this.usedGlobalSettingsChanged = false;
    }

    public static generateDrawingDataFromDefaults(windowSystemId: number, defaultsData: WindowSystemDefaults): DrawingData {
        let drawingData = new DrawingData();
        drawingData.windowSystemId = windowSystemId;
        drawingData.view = defaultsData.view;
        drawingData.addons = defaultsData.addons;
        drawingData.specification = DrawingDataSpecification.generateSpecificationFromDefaults(defaultsData);
        return drawingData;
    }

    public static applyChangesToDrawingData(drawingData: DrawingData, modificationData: UpsellingChargeData,
        profileCompositionDistances: ProfilesCompositionDistances): DrawingData {
        let totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(drawingData.windows);
        drawingData.view = modificationData.view;
        drawingData.addons = modificationData.addons;
        drawingData.specification = DrawingDataSpecification.generateSpecificationFromDefaults(modificationData);
        drawingData.windows.forEach(window => WindowSystemDefaultsUtil.applyWindowDefaults(modificationData, window, true));
        if (VentilationUtils.isVentilationPresent(modificationData)) {
            let validSubwindowForVentilation = VentilationUtils.findFirstVentableSubwindow(drawingData, drawingData.cuts, totalBoundingBox,
                profileCompositionDistances);
            if (validSubwindowForVentilation === undefined) {
                throw UpsellingUtils.noValidSubwindowForVentilationError();
            }
            WindowSystemDefaultsUtil.applyUpsellingChargeToSubwindow(validSubwindowForVentilation, modificationData);
        }
        return drawingData;
    }

    public static copy(data: DrawingData): DrawingData {
        return JSON.parse(JSON.stringify(data));
    }
}
