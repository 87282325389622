import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class BusinessType implements CrudItem {
    id: number;
    names: MultilanguageField;
    descriptions?: MultilanguageField;
    type: WindowTypeCode;
    active: boolean;
    sortIndex: number;
    minWidthInWebshop: number;
    maxWidthInWebshop: number;
    minHeightInWebshop: number;
    maxHeightInWebshop: number;
    numberOfRails: number;

    constructor() {
        this.id = undefined;
        this.names = {};
        this.descriptions = {};
        this.active = true;
        this.sortIndex = 1;
    }
}
