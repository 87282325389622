import {WindowSystemMaterialLinks} from '../../../../../window-system/single-system-checkbox-crud/window-system-material-links';
import {ConfigurableAddonDefinitionType} from "../../../../../../../window-designer/enums/ConfigurableAddonDefinitionType";
import {ConfigAddonApplication} from "../../../../../../../window-designer/enums/ConfigAddonApplication";
import {ConfigurableAddonType} from "./ConfigurableAddonType";
import {CrudItem} from "../../../../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../../../../supportedLanguages";

export class ConfigurableAddonDefinitionRoDto implements CrudItem, WindowSystemMaterialLinks {
    id: number;
    type: ConfigurableAddonDefinitionType;
    addonType: ConfigurableAddonType;
    name: MultilanguageField;
    isOtherSystem: boolean;
    active: boolean;
    applicableTo: ConfigAddonApplication[];
    sortIndex: number;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    canBeAddedToAreaWithOuterGrill: boolean;
    canBeAddedToAreaWithoutFilling: boolean;
    canBeAddedToAreaWithOtherFilling: boolean;
    canBeAddedToAreaWithDecorativeFilling: boolean;

    static fromJSON(jsonObject: any): ConfigurableAddonDefinitionRoDto {
        return jsonObject as ConfigurableAddonDefinitionRoDto;
    }
}
