import {SubsystemInterface} from '../../../window-designer/catalog-data/subsystem-interface';
import {JsonDeserializable} from '../../common/crud-common/crudItem';
import {Currencies} from '../../currencies';
import {User} from '../user/user';

export enum SubsystemType {
    STANDARD = 'STANDARD',
    INVESTMENT = 'INVESTMENT'
}

@JsonDeserializable
export class Subsystem implements SubsystemInterface {
    id: number;
    name: string;
    symbol: string;
    identificator: string;
    phoneNumber: string;
    email: string;
    defaultCurrency: Currencies;
    type: SubsystemType;
    account: string;
    clientManager: User;
    subClientManager: User;
    primaryCorrespondenceAddressId: number;
    primaryDeliveryAddressId: number;
    active: boolean;
    impersonationAllowed: boolean;
    webShopEnabled: boolean;
    vatSell: number;
    transportType: TransportType;
    euTransportPercent: number;
    nonEuTransportPercent: number;
    subsystemGroupId: number;
    subsystemGroupName: string;
    bulkAddonProfitMargin: string;
    inactiveSupplierIds: number[];
    manualExchangeRate: number;
    exchangeRateSafeChangeThreshold: number;
    enableUpselling: boolean;
    enableRot: boolean;
    enableWindowUpselling: boolean;
    defaultOfferDurationDays: number;
    currentYearSalesTarget: number;

    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.symbol = undefined;
        this.identificator = undefined;
        this.phoneNumber = undefined;
        this.email = undefined;
        this.defaultCurrency = Currencies.PLN;
        this.type = SubsystemType.STANDARD;
        this.account = undefined;
        this.clientManager = new User();
        this.subClientManager = new User();
        this.primaryCorrespondenceAddressId = undefined;
        this.primaryDeliveryAddressId = undefined;
        this.active = true;
        this.impersonationAllowed = true;
        this.webShopEnabled = false;
        this.transportType = TransportType.SINGULAR;
        this.subsystemGroupId = undefined;
        this.bulkAddonProfitMargin = undefined;
        this.inactiveSupplierIds = [];
        this.enableUpselling = true;
        this.enableRot = false;
        this.enableWindowUpselling = true;
        this.defaultOfferDurationDays = undefined;
        this.exchangeRateSafeChangeThreshold = 5;
        this.currentYearSalesTarget = undefined;
    }

    static fromJSON(jsonObject: any): Subsystem {
        const subsystem = new Subsystem();
        subsystem.id = jsonObject.id;
        subsystem.name = jsonObject.name;
        subsystem.symbol = jsonObject.symbol;
        subsystem.identificator = jsonObject.identificator;
        subsystem.phoneNumber = jsonObject.phoneNumber;
        subsystem.email = jsonObject.email;
        subsystem.defaultCurrency = Currencies[jsonObject.defaultCurrency as string];
        subsystem.type = SubsystemType[jsonObject.type as string];
        subsystem.account = jsonObject.account;
        subsystem.clientManager = jsonObject.clientManager;
        subsystem.subClientManager = jsonObject.subClientManager;
        subsystem.primaryCorrespondenceAddressId = jsonObject.primaryCorrespondenceAddressId;
        subsystem.primaryDeliveryAddressId = jsonObject.primaryDeliveryAddressId;
        subsystem.active = jsonObject.active;
        subsystem.impersonationAllowed = jsonObject.impersonationAllowed;
        subsystem.webShopEnabled = jsonObject.webShopEnabled;
        subsystem.vatSell = jsonObject.vatSell;
        subsystem.transportType = TransportType[<string>jsonObject.transportType];
        subsystem.euTransportPercent = jsonObject.euTransportPercent;
        subsystem.nonEuTransportPercent = jsonObject.nonEuTransportPercent;
        subsystem.subsystemGroupId = jsonObject.subsystemGroupId;
        subsystem.subsystemGroupName = jsonObject.subsystemGroupName;
        subsystem.bulkAddonProfitMargin = jsonObject.bulkAddonProfitMargin;
        subsystem.inactiveSupplierIds = jsonObject.inactiveSupplierIds || [];
        subsystem.manualExchangeRate = jsonObject.manualExchangeRate;
        subsystem.exchangeRateSafeChangeThreshold = jsonObject.exchangeRateSafeChangeThreshold;
        subsystem.enableUpselling = jsonObject.enableUpselling;
        subsystem.enableRot = jsonObject.enableRot;
        subsystem.enableWindowUpselling = jsonObject.enableWindowUpselling;
        subsystem.defaultOfferDurationDays = jsonObject.defaultOfferDurationDays;
        subsystem.currentYearSalesTarget = jsonObject.currentYearSalesTarget;
        return subsystem;
    }
}

@JsonDeserializable
export class SubsystemCurrencyData {
    defaultCurrency: Currencies;
    manualExchangeRate: number;
    exchangeRateSafeChangeThreshold: number;

    static fromJSON(jsonObject: any): SubsystemCurrencyData {
        const item = new SubsystemCurrencyData();
        item.defaultCurrency = Currencies[jsonObject.defaultCurrency as string];
        item.manualExchangeRate = jsonObject.manualExchangeRate;
        item.exchangeRateSafeChangeThreshold = jsonObject.exchangeRateSafeChangeThreshold;
        return item;
    }
}

export enum TransportType {
    SINGULAR = 'SINGULAR',
    SPLITTED = 'SPLITTED'
}
