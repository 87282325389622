import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TreeTableNode} from 'primeng/api/treetablenode';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {CSVColumn, CSVDataSource, ExportComponent} from '../../../common/service/export.component';
import {OfferCounterReportModel} from '../offer-counter-report.model';
import {OfferSubsystemCounterService} from './offer-subsystem-counter.service';

interface TreeNodeData {
    groupName: string;
    countByYear: {[key: number]: number};
}

@Component({
    selector: 'app-offer-subsystem-counter',
    templateUrl: './offer-subsystem-counter.component.html',
    styleUrls: ['../../shared-styles.css', './offer-subsystem-counter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OfferSubsystemCounterService, ExportComponent]
})
export class OfferSubsystemCounterComponent implements OnInit, CSVDataSource {

    offerCounts: OfferCounterReportModel[] = [];
    offerCountsTreeData: TreeTableNode<TreeNodeData>[] = [];
    years: string[] = [];
    selected: TreeTableNode<TreeNodeData> | TreeTableNode<TreeNodeData>[];

    constructor(private offerSubsystemCounterService: OfferSubsystemCounterService,
                private changeDetector: ChangeDetectorRef,
                private translateService: TranslateService,
                private exportComponent: ExportComponent) {
    }

    ngOnInit(): void {
        forkJoin({
            reportData: this.offerSubsystemCounterService.countOffersBySubsystemAndYear(),
            labels: this.translateService.get([
                'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.OFFERS',
                'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.ORDERS',
                'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.PRODUCTION_ORDERS',
                'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.COMPLAINTS'
            ])
        }).subscribe(responseData => {
            this.offerCounts = responseData.reportData.data;
            this.offerCountsTreeData = this.offerCounts.map(offerCount => this.mapReportCount(offerCount, responseData.labels));
            this.initYears();
            this.changeDetector.markForCheck();
        });
    }

    private mapReportCount(reportCount: OfferCounterReportModel, labels: { [key: string]: string }): TreeTableNode<TreeNodeData> {
        let reportChildren: TreeTableNode<TreeNodeData>[] = [];

        reportChildren.push({
            data: {
                groupName: labels['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.OFFERS'],
                countByYear: reportCount.offersCountByYear
            }
        });

        reportChildren.push({
            data: {
                groupName: labels['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.ORDERS'],
                countByYear: reportCount.ordersCountByYear
            }
        });

        reportChildren.push({
            data: {
                groupName: labels['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.PRODUCTION_ORDERS'],
                countByYear: reportCount.productionOrdersCountByYear
            }
        });

        if (this.isComplaintCountIncluded(reportCount)) {
            reportChildren.push({
                data: {
                    groupName: labels['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.COMPLAINTS'],
                    countByYear: reportCount.complaintsCountByYear
                }
            });
        }

        reportChildren.push(...reportCount.usersCount.map(userReport => this.mapReportCount(userReport, labels)));

        return {
            data: {
                groupName: reportCount.groupName,
                countByYear: reportCount.countByYear
            },
            children: reportChildren
        };
    }

    private isComplaintCountIncluded(reportCount: OfferCounterReportModel): boolean {
        return reportCount.complaintsCountByYear != null;
    }

    private initYears(): void {
        this.years.push(..._.chain(this.offerCounts)
            .map(offerCount => [...Object.keys(offerCount.offersCountByYear),
                ...Object.keys(offerCount.ordersCountByYear),
                ...Object.keys(offerCount.productionOrdersCountByYear),
                ...Object.keys(offerCount.complaintsCountByYear)])
            .flatten()
            .uniq()
            .sortBy(year => -1 * Number.parseInt(year, 10))
            .value());
    }

    nodeSelected(): void {
        if (Array.isArray(this.selected)) {
            this.selected.forEach(node => node.expanded = !node.expanded);
        } else {
            this.selected.expanded = !this.selected.expanded;
        }
        this.changeDetector.markForCheck();
    }

    public exportCSV(): void {
        this.exportComponent.exportCSV(this, 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FILE_NAME');
    }

    getExportData(): Observable<object[]> {
        let exportData = [];
        for (let count of this.offerCounts) {
            for (let year of this.years) {
                for (let user of count.usersCount) {
                    exportData.push({
                        subsystem: count.groupName,
                        year: year,
                        user: user.groupName,
                        offersCount: this.getExportCount(user.offersCountByYear, year),
                        ordersCount: this.getExportCount(user.ordersCountByYear, year),
                        productionOrdersCount: this.getExportCount(user.productionOrdersCountByYear, year),
                        complaintsCount: this.getExportCount(user.complaintsCountByYear, year)
                    });
                }
            }
        }
        return of(exportData);
    }

    getExportCount(countsByYear: {[key: number]: number}, year: string): number {
        return countsByYear == undefined ? 0 : countsByYear[year] || 0;
    }

    getExportColumns(): Observable<CSVColumn[]> {
        return this.translateService.get(['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.SUBSYSTEM',
            'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.YEAR', 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.USER',
            'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.OFFERS', 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.ORDERS',
            'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.PRODUCTION_ORDERS', 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.COMPLAINTS'
        ]).pipe(map(translations => [
            {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.SUBSYSTEM'],
                field: 'subsystem'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.YEAR'],
                field: 'year'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.USER'],
                field: 'user'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.OFFERS'],
                field: 'offersCount'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.ORDERS'],
                field: 'ordersCount'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.PRODUCTION_ORDERS'],
                field: 'productionOrdersCount'
            }, {
                label: translations['ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.COMPLAINTS'],
                field: 'complaintsCount'
            }
        ]));
    }
}
