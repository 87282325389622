import * as _ from 'underscore';
import {AutoAlignOption, WindowSystemInterface} from "../catalog-data/window-system-interface";
import {CutData} from "../drawing-data/CutData";
import {DrawingData} from "../drawing-data/drawing-data";
import {Mullion} from "../drawing-data/Mullion";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowShape} from "../drawing-data/WindowShape";
import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {Guides} from "../guides";
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {WindowCalculator} from "../window-calculator";
import {ErrorNames} from "./ErrorNames";
import {GrillHelper} from "./grill-helper";
import {MullionUtils} from "./MullionUtils";
import {OperationResult} from "./OperationResult";
import {PositionsHelper} from "./positions-helper";

export class AlignmentTool {

    public static autoAlign(windowSystem: WindowSystemInterface,
                            profileCompositionDistances: ProfilesCompositionDistances, data: DrawingData,
                            oldData?: DrawingData, oldProfileCompositionDistances?: ProfilesCompositionDistances): OperationResult {
        switch (windowSystem.autoAlign) {
            case AutoAlignOption.TO_FRAMES:
                // no need to do anything;
                break;
            case AutoAlignOption.TO_GLASSES:
                let newSubwindows: SubWindowData[] = _.flatten(data.windows.map(w => w.subWindows));
                let areSubwindowsInLine = AlignmentTool.areSubwindowsInOneRowOrColumn(newSubwindows, false);
                let horizontal = areSubwindowsInLine.x;
                let newSortedSubwindows = AlignmentTool.sortSubwindows(newSubwindows, horizontal);
                if (AlignmentTool.preValidate(areSubwindowsInLine, newSortedSubwindows)) {
                    let firstWindowBeingAdded = oldData == null;
                    let oldDistances = oldProfileCompositionDistances || profileCompositionDistances;
                    if (firstWindowBeingAdded || AlignmentTool.wereGlassesAlignedBefore(oldDistances, oldData, horizontal)) {
                        let totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
                        let newChanges = AlignmentTool.getRequiredChanges(true, horizontal, totalBoundingBox,
                            newSortedSubwindows, profileCompositionDistances, data.cuts);
                        if (!newChanges.areEmpty()) {
                            let operationResult = new OperationResult();
                            operationResult = AlignmentTool.moveFramesAndMullions(newSortedSubwindows, horizontal, newChanges,
                                data.cuts, totalBoundingBox, profileCompositionDistances, data.shape);
                            return operationResult;
                        }
                    }
                }
                break;
            default:
                throw new Error('Unsupported AutoAlignOption: ' + windowSystem.autoAlign);
        }
        return new OperationResult();
    }

    private static wereGlassesAlignedBefore(profileCompositionDistances: ProfilesCompositionDistances,
                                            oldData: DrawingData, horizontal: boolean): boolean {
        let oldSubwindows: SubWindowData[] = _.flatten(oldData.windows.map(w => w.subWindows));
        let oldSortedSubwindows = AlignmentTool.sortSubwindows(oldSubwindows, horizontal);
        let oldTotalBoundingBox = DrawingUtil.calculateTotalBoundingBox(oldData.windows);
        let oldChanges = AlignmentTool.getRequiredChanges(true, horizontal, oldTotalBoundingBox,
            oldSortedSubwindows, profileCompositionDistances, oldData.cuts);
        return oldChanges.areEmpty();
    }

    private static sortSubwindows(subwindows: SubWindowData[], horizontal: boolean): SubWindowData[] {
        return subwindows.sort((a, b) => a.points[horizontal ? 0 : 1] - b.points[horizontal ? 0 : 1]);
    }

    public static use(alignToGlasses: boolean, subwindows: SubWindowData[], guides: Guides, cuts: CutData[],
                      totalBoundingBox: MinMaxXY, profileCompositionDistances: ProfilesCompositionDistances,
                      forceVertical: boolean, shape: WindowShape): OperationResult {
        let inLine = AlignmentTool.areSubwindowsInOneRowOrColumn(subwindows, false);
        let horizontal = !forceVertical;
        let sortedSubwindows = subwindows.sort((a, b) => a.points[horizontal ? 0 : 1] - b.points[horizontal ? 0 : 1]);
        let error = AlignmentTool.getPreValidateError(inLine, sortedSubwindows, horizontal);
        if (error != null) {
            throw error;
        }

        let changes = AlignmentTool.getRequiredChanges(alignToGlasses, horizontal,
            DrawingUtil.calculateMultiplePolygonsTotalBoundingBox(subwindows.map(sw => sw.points)),
            sortedSubwindows, profileCompositionDistances, cuts);
        AlignmentTool.validateChanges(changes);
        let operationResult = AlignmentTool.moveFramesAndMullions(sortedSubwindows, horizontal, changes, cuts,
            totalBoundingBox, profileCompositionDistances, shape);
        guides.rebuildStructureGuides();
        return operationResult;
    }

    private static addToMapIfNotZero<TKey>(map: Map<TKey, number>, key: TKey, value: number): void {
        if (value !== 0) {
            map.set(key, value);
        }
    }

    private static filterMullions(mullions: Mullion[], sortHorizontally: boolean): Mullion[] {
        return mullions.filter(m => sortHorizontally ? GrillHelper.isVertical(m) : GrillHelper.isHorizontal(m));
    }

    private static filterAndSortMullions(mullions: Mullion[], sortHorizontally: boolean): Mullion[] {
        let positionIndex = sortHorizontally ? 0 : 1;
        return AlignmentTool.filterMullions(mullions, sortHorizontally)
            .sort((a, b) => MullionUtils.getSegmentPoints(a)[positionIndex] - MullionUtils.getSegmentPoints(b)[positionIndex]);
    }

    private static getRequiredChanges(alignToGlasses: boolean, horizontalAlign: boolean, totalBoundingBox: MinMaxXY,
                                      sortedSubwindows: SubWindowData[],
                                      profileCompositionDistances: ProfilesCompositionDistances,
                                      cuts: CutData[]): AlignmentChanges {
        let subwindowChanges = new Map<number, number>();
        let mullionChanges = new Map<Mullion, number>();
        let areaCount = AlignmentTool.countAreas(sortedSubwindows, horizontalAlign);
        if (alignToGlasses) {
            let glassSizeChanges = AlignmentTool.getGlassesSizeChages(sortedSubwindows, cuts, totalBoundingBox,
                profileCompositionDistances, horizontalAlign, areaCount);
            let changeIndex = 0;
            for (let i = 0; i < sortedSubwindows.length; i++) {
                if (i !== 0) {
                    AlignmentTool.addToMapIfNotZero(subwindowChanges,
                        sortedSubwindows[i].points[horizontalAlign ? 0 : 1], glassSizeChanges[changeIndex++]);
                }
                AlignmentTool.filterAndSortMullions(sortedSubwindows[i].mullions, horizontalAlign).forEach(m => {
                    AlignmentTool.addToMapIfNotZero(mullionChanges, m, glassSizeChanges[changeIndex++]);
                });
            }
        } else {
            let total = horizontalAlign ? totalBoundingBox.maxX - totalBoundingBox.minX :
                totalBoundingBox.maxY - totalBoundingBox.minY;
            let newPoint = horizontalAlign ? totalBoundingBox.minX : totalBoundingBox.minY;
            let step = Math.floor(total / areaCount);
            let rest = total % areaCount;
            for (let i = 0; i < sortedSubwindows.length; i++) {
                if (i !== 0) {
                    newPoint = newPoint + step + (rest-- > 0 ? 1 : 0);
                    let oldPoint = sortedSubwindows[i].points[horizontalAlign ? 0 : 1];
                    AlignmentTool.addToMapIfNotZero(subwindowChanges, oldPoint, newPoint - oldPoint);
                }
                AlignmentTool.filterAndSortMullions(sortedSubwindows[i].mullions, horizontalAlign).forEach(m => {
                    newPoint = newPoint + step + (rest-- > 0 ? 1 : 0);
                    let oldPoints = MullionUtils.getSegmentPoints(m);
                    AlignmentTool.addToMapIfNotZero(mullionChanges, m, newPoint - oldPoints[horizontalAlign ? 0 : 1]);
                });
            }
        }
        return new AlignmentChanges(subwindowChanges, mullionChanges);
    }

    private static areSubwindowsInOneRowOrColumn(subwindows: SubWindowData[], forceVertical: boolean): { x: boolean, y: boolean } {
        let bBox = DrawingUtil.calculateMultiplePolygonsTotalBoundingBox(subwindows.map(sw => sw.points));
        let areSubwindowsInLine = (vertical: boolean) => {
            return subwindows.every(sw => {
                return sw.points[vertical ? 1 : 0] === (vertical ? bBox.minY : bBox.minX)
                    && sw.points[vertical ? 5 : 2] === (vertical ? bBox.maxY : bBox.maxX);
            });
        };
        return {x: (forceVertical ? false : areSubwindowsInLine(true)), y: areSubwindowsInLine(false)};
    }

    private static areSubwindowsInLine(sortedSubwindows: SubWindowData[], horizontal: boolean): boolean {
        for (let i = 1; i < sortedSubwindows.length; i++) {
            if (sortedSubwindows[i - 1].points[horizontal ? 2 : 5] !== sortedSubwindows[i].points[horizontal ? 0 : 1]) {
                return false;
            }
        }
        return true;
    }

    private static calculateGlassesWidths(subwindows: SubWindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                                          profileCompositionDistances: ProfilesCompositionDistances,
                                          horizontal: boolean): number[] {
        let glassesSizes = [];
        let alreadyAddedGlassesPositions = [];
        subwindows.forEach(sw => {
            let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPoints(sw, cuts, totalBoundingBox,
                profileCompositionDistances, true);
            sw.areasSpecification.forEach(a => {
                let glazingBeadPoints = WindowCalculator.getGlazingBeadPoints(sw, a.definingMullions, totalGlazingBeadPoints,
                    profileCompositionDistances);
                let box = DrawingUtil.calculatePolygonTotalBoundingBox(glazingBeadPoints);
                if (alreadyAddedGlassesPositions.indexOf(horizontal ? box.minX : box.minY) === -1) {
                    glassesSizes.push({
                        start: horizontal ? box.minX : box.minY,
                        size: horizontal ? (box.maxX - box.minX) : (box.maxY - box.minY)
                    });
                    alreadyAddedGlassesPositions.push(horizontal ? box.minX : box.minY);
                }
            });
        });
        let sizes = [];
        glassesSizes.sort((a, b) => a.start - b.start);
        glassesSizes.forEach(g => sizes.push(g.size));
        return sizes;
    }

    private static getPreValidateError(inLine: { x: boolean; y: boolean }, subwindows: SubWindowData[],
                                       horizontal: boolean | undefined): Error | undefined {
        if (subwindows.length === 0 || (subwindows.length === 1 && subwindows[0].areasSpecification.length < 2)) {
            let err = new Error("error.alignmentTool.too_few_selected_quarters");
            err.name = ErrorNames.ALIGMENT_TOOL_TOO_FEW_SELECTED_ELEMENTS;
            return err;
        }
        if (horizontal != undefined ? !(horizontal ? inLine.x : inLine.y) : (!inLine.x && !inLine.y)) {
            let err = new Error("error.alignmentTool.quarters_not_in_row_or_column");
            err.name = ErrorNames.ALIGMENT_TOOL_SELECTED_ELEMENTS_NOT_IN_LINE;
            return err;
        }
        if (!AlignmentTool.areSubwindowsInLine(subwindows, horizontal != undefined ? horizontal : inLine.x)) {
            let err = new Error("error.alignmentTool.quarters_not_in_line");
            err.name = ErrorNames.ALIGMENT_TOOL_SELECTED_ELEMENTS_NOT_IN_LINE;
            return err;
        }
        return null;
    }

    private static preValidate(inLine: { x: boolean; y: boolean }, subwindows: SubWindowData[]): boolean {
        return AlignmentTool.getPreValidateError(inLine, subwindows, undefined) == null;
    }

    private static validateChanges(changes: AlignmentChanges): void {
        if (changes.areEmpty()) {
            let err = new Error("error.alignmentTool.already_aligned");
            err.name = ErrorNames.ALIGMENT_TOOL_ALREADY_ALIGNED;
            throw err;
        }
    }

    private static countAreas(sortedSubwindows: SubWindowData[], horizontalAlign: boolean): number {
        let count = 0;
        sortedSubwindows.forEach(sw => {
            count++;
            sw.mullions.forEach(m => {
                if (!GrillHelper.isVertical(m) && !GrillHelper.isHorizontal(m)) {
                    let err = new Error("error.alignmentTool.inclined_mullions_present");
                    err.name = ErrorNames.ALIGMENT_TOOL_INCLINED_MULLION_PRESENT;
                    throw err;
                } else if (horizontalAlign ? GrillHelper.isVertical(m) : GrillHelper.isHorizontal(m)) {
                    if (m.positions.some((v) => v.type !== PositionReferenceType.INNER_FRAME)) {
                        let err = new Error("error.alignmentTool.nonframe_mullions_present");
                        err.name = ErrorNames.ALIGMENT_TOOL_NONFRAME_MULLION_PRESENT;
                        throw err;
                    }
                    count++;
                }
            });
        });
        return count;
    }

    private static getGlassesSizeChages(subwindows: SubWindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                                        profileCompositionDistances: ProfilesCompositionDistances,
                                        horizontal: boolean, count: number): number[] {
        let changes = [];
        let oldGlassesWidths = AlignmentTool.calculateGlassesWidths(subwindows, cuts, totalBoundingBox, profileCompositionDistances,
            horizontal);
        let glassesTotal = oldGlassesWidths.reduce((a, b) => a + b, 0);
        let step = Math.floor(glassesTotal / count);
        let rest = glassesTotal % count;
        for (let i = 0; i < oldGlassesWidths.length; i++) {
            let newPoint = step + (rest-- > 0 ? 1 : 0);
            changes.push(newPoint - oldGlassesWidths[i] + (i === 0 ? 0 : changes[i - 1]));
        }
        return changes;
    }

    private static moveFramesAndMullions(sortedSubwindows: SubWindowData[], horizontal: boolean,
                                         changes: AlignmentChanges, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                         profileCompositionDistances: ProfilesCompositionDistances,
                                         shape: WindowShape): OperationResult {
        let operationResult = new OperationResult();
        sortedSubwindows.forEach(sw => {
            for (let i = 0; i < sw.points.length; i++) {
                if (i % 2 === (horizontal ? 0 : 1)) {
                    if (changes.subwindowChanges.get(sw.points[i])) {
                        sw.points[i] += changes.subwindowChanges.get(sw.points[i]);
                    }
                }
            }
            let innerFrame = WindowCalculator.getTotalFrameInnerEdgePoints(sw, cuts, totalBoundingBox,
                profileCompositionDistances, false);
            let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(sw, cuts, totalBoundingBox,
                profileCompositionDistances, false);
            let oppositeMullions = AlignmentTool.filterMullions(sw.mullions, !horizontal);
            AlignmentTool.filterMullions(sw.mullions, horizontal).forEach(m => {
                let oldPoints = MullionUtils.getSegmentPoints(m);
                if (!changes.mullionChanges.get(m)) {
                    return;
                }
                let newPosition = ((horizontal ? oldPoints[0] : oldPoints[1]) + changes.mullionChanges.get(m));
                let line = [
                    horizontal ? newPosition : 0,
                    horizontal ? 0 : newPosition,
                    horizontal ? newPosition : 1,
                    horizontal ? 1 : newPosition
                ];
                let intersections = DrawingUtil.polygonLineIntersections(innerFrame, line, true);
                if (intersections.length !== 2) {
                    console.error("AlignmentTool - błędna ilość punktów wspólnych przewiązki i ramy");
                    let err = new Error("error.alignmentTool.general_error");
                    err.name = ErrorNames.GENERAL_ERROR;
                    throw err;
                }
                m.positions = [];
                // new references need to be in the same order
                let relation = (horizontal ? (oldPoints[1] < oldPoints[3]) : (oldPoints[0] < oldPoints[2])) ? 1 : -1;
                let newPoints = [];
                intersections = intersections.sort((a, b) => (horizontal ? (a.y - b.y) : (a.x - b.x)) * relation);
                intersections.forEach(i => {
                    if (!i.onLine1) {
                        console.error("AlignmentTool - błędny punkt przecięcia przewiązki i ramy");
                        let err = new Error("error.alignmentTool.general_error");
                        err.name = ErrorNames.GENERAL_ERROR;
                        throw err;
                    }
                    newPoints.push(i.x, i.y);
                    m.positions.push(PositionsHelper.prepareInnerFrameReference(innerFrame, [i.x, i.y]));
                });
                if (!_.isEqual(oldPoints, newPoints)) {
                    oppositeMullions.forEach(om => {
                        om.positions.forEach(op => {
                            if (op.type === PositionReferenceType.MULLION && op.id === m.drawingSegments[0].id) {
                                let point = DrawingUtil.lineIntersection(newPoints, MullionUtils.getSegmentPoints(om));
                                op.percent = PositionsHelper.lineAbsToPercent(newPoints, [point.x, point.y]);
                            }
                        });
                    });
                }
            });
            operationResult.merge(MullionUtils.updateAbsoluteMullionPositions(sw, cuts, totalBoundingBox, profileCompositionDistances,
                shape, true));
            PositionsHelper.updateAbsolutePositions(sw, totalGlazingBeads, profileCompositionDistances);
        });
        return operationResult;
    }
}

class AlignmentChanges {
    subwindowChanges: Map<number, number>;
    mullionChanges: Map<Mullion, number>;

    constructor(subwindowChanges: Map<number, number>,
                mullionChanges: Map<Mullion, number>) {
        this.subwindowChanges = subwindowChanges;
        this.mullionChanges = mullionChanges;
    }

    areEmpty(): boolean {
        return this.subwindowChanges.size === 0 && this.mullionChanges.size === 0;
    }
}
