import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemArrayToJson} from "../../common/crud-common/crudItem";
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {ScopeValidator} from '../../shared/validator/input-validator';
import {ListOfIds} from '../ListOfIds';
import {
    Offer,
    OfferExhangeRates,
    OfferHistoryEntry,
    OrderDeliveryListNames,
    OrderProductionOrderNumbers,
    PrintHistoryEntry
} from './offer';
import {OfferVersion, OfferVersionStatus} from './OfferVersion';
import {SupplierInfo} from './SupplierInfo';
import {WindowEditorOfferData} from './window-editor/window-editor-offer-interfaces';

@Injectable()
export class OffersService {

    private static readonly SERVICE_PATH = 'offers';

    rangeFilterValidator: ScopeValidator;
    private readonly filterValidator: { [filterProperty: string]: (value: any) => boolean };

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        this.rangeFilterValidator = new ScopeValidator();
        this.filterValidator = {
            vatBuy: OffersService.isNumeric,
            vatSell: OffersService.isNumeric,
            ownAddonsNetCost: value => this.rangeFilterValidator.isValid(value),
            ownAddonsGrossCost: value => this.rangeFilterValidator.isValid(value),
            assemblyNetCost: value => this.rangeFilterValidator.isValid(value),
            assemblyGrossCost: value => this.rangeFilterValidator.isValid(value),
            transportNetCost: value => this.rangeFilterValidator.isValid(value),
            transportGrossCost: value => this.rangeFilterValidator.isValid(value)
        };
    }

    private static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private transformRangeFilterValues(filter: FilterMetadata): void {
        if (filter != undefined && this.rangeFilterValidator.isValid(filter.value)) {
            const {from, to} = this.rangeFilterValidator.getMatchedLimits(filter.value);
            filter.value = `(${from.toFixed(2)})-(${to.toFixed(2)})`;
        }
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<Offer>> {
        this.transformRangeFilterValues(filters['ownAddonsNetCost']);
        this.transformRangeFilterValues(filters['ownAddonsGrossCost']);
        this.transformRangeFilterValues(filters['assemblyNetCost']);
        this.transformRangeFilterValues(filters['assemblyGrossCost']);
        this.transformRangeFilterValues(filters['transportNetCost']);
        this.transformRangeFilterValues(filters['transportGrossCost']);
        this.transformRangeFilterValues(filters['windowsCount']);
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, this.filterValidator);
        return this.http.get<Listing<Offer>>(OffersService.SERVICE_PATH, {params: params})
            .pipe(map(result => this.mapOfferListing(result)));
    }

    private mapOfferListing(listing: Listing<Offer>): Listing<Offer> {
        if (listing != undefined && listing.hasOwnProperty('data')) {
            for (let i = 0; i < listing.data.length; i++) {
                if (listing.data[i].hasOwnProperty('createdDate') && listing.data[i].createdDate) {
                    listing.data[i].createdDate = new Date(listing.data[i].createdDate);
                }
                if (listing.data[i].hasOwnProperty('exchangeRateDate') && listing.data[i].exchangeRateDate) {
                    listing.data[i].exchangeRateDate = new Date(listing.data[i].exchangeRateDate);
                }
                if (listing.data[i].hasOwnProperty('validFrom') && listing.data[i].validFrom) {
                    listing.data[i].validFrom = new Date(listing.data[i].validFrom);
                }
                if (listing.data[i].hasOwnProperty('validTo') && listing.data[i].validTo) {
                    listing.data[i].validTo = new Date(listing.data[i].validTo);
                }
                if (listing.data[i].hasOwnProperty('lastStatusChange') && listing.data[i].lastStatusChange) {
                    listing.data[i].lastStatusChange = new Date(listing.data[i].lastStatusChange);
                }
                if (listing.data[i].hasOwnProperty('lastModifiedDate') && listing.data[i].lastModifiedDate) {
                    listing.data[i].lastModifiedDate = new Date(listing.data[i].lastModifiedDate);
                }
                if (listing.data[i].hasOwnProperty('orderCreationDate') && listing.data[i].orderCreationDate) {
                    listing.data[i].orderCreationDate = new Date(listing.data[i].orderCreationDate);
                }
            }
        }
        return listing;
    }

    getItem(itemId: number): Observable<Offer> {
        return this.http.get<Offer>(`${OffersService.SERVICE_PATH}/${itemId}`);
    }

    createOffer(offer: Offer): Observable<number> {
        return this.http.post<void>(OffersService.SERVICE_PATH, offer, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(OffersService.SERVICE_PATH));
    }

    editOffer(offer: Offer, updateExchangeRate: boolean): Observable<number> {
        let params = {};
        if (updateExchangeRate) {
            params['updateExchangeRate'] = `${updateExchangeRate}`;
        }
        return this.http.put(`${OffersService.SERVICE_PATH}/${offer.id}`, offer, {params: params})
            .pipe(map(() => offer.id));
    }

    getCurrentVersionStatus(offerId: number): Observable<OfferVersionStatus> {
        return this.http.get<OfferVersionStatus>(`${OffersService.SERVICE_PATH}/${offerId}/currentVersionStatus`);
    }

    getDefaultOfferDurationDays(offerId: number): Observable<number>;
    getDefaultOfferDurationDays(clientId: number, subsystemId: number): Observable<number>;
    getDefaultOfferDurationDays(...ids: number[]): Observable<number> {
        let params = new HttpParams({
            fromObject: ids.length === 1 ? {offerId: `${ids[0]}`} : {clientId: `${ids[0]}`}
        });
        if (ids[1] != undefined) {
            params = params.set('subsystemId', `${ids[1]}`);
        }
        return this.http.get<number>(`${OffersService.SERVICE_PATH}/defaultOfferDurationDays`, {params});
    }

    getOfferForWindowEditor(itemId: number): Observable<WindowEditorOfferData> {
        return this.http.get<WindowEditorOfferData>(`${OffersService.SERVICE_PATH}/${itemId}/forWindowEditor`);
    }

    // ------------------------------------ ACTIONS ------------------------------------

    copyOffer(modifiedOldOffer: Offer, oldOfferId: number, updateExchangeRate: boolean, copyToPartner: boolean): Observable<number> {
        let params = {};
        if (updateExchangeRate) {
            params['updateExchangeRate'] = `${updateExchangeRate}`;
        }
        if (copyToPartner) {
            params['copyToPartner'] = `${copyToPartner}`;
        }
        return this.http.post<void>(`${OffersService.SERVICE_PATH}/${oldOfferId}/copy`, modifiedOldOffer, {
            params: params,
            observe: 'response'
        }).pipe(this.dataServiceHelper.mapToNewItemId(OffersService.SERVICE_PATH));
    }

    createFixupDraft(oldOfferId: number): Observable<number> {
        return this.http.post<void>(`${OffersService.SERVICE_PATH}/${oldOfferId}/createFixupDraft`, undefined, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(OffersService.SERVICE_PATH));
    }

    deleteTheOffer(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/delete`, undefined);
    }

    hideDeletedOffer(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/hideDeletedOffer`, undefined);
    }

    acceptNewOffer(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/acceptNewOffer`, undefined);
    }

    acceptOffer(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/acceptOffer`, undefined);
    }

    sendTheOfferToReview(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/sendToReview`, undefined);
    }

    sendTheOfferToVerify(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/sendToVerify`, undefined);
    }

    sendTheOfferBackToOperator(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/sendBackToOperator`, undefined);
    }

    sendTheOfferBackToHandlowiec(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/sendBackToHandlowiec`, undefined);
    }

    acceptOfferChanges(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/acceptChanges`, undefined);
    }

    rejectOfferChanges(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/rejectChanges`, undefined);
    }

    revertStatusToSend(id: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/revertToSend`, undefined);
    }

    createCorrection(id: number): Observable<number> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/createCorrection`, undefined, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(OffersService.SERVICE_PATH));
    }

    confirmCorrection(id: number, generateOrdersForSupplierIds: number[]): Observable<void> {
        const headers = this.dataServiceHelper.prepareHeaders({generateOrdersForSupplierIds});
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${id}/confirmCorrection`, undefined, {headers});
    }

    revertStatusToNewOffer(offerId: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${offerId}/revertStatusToNewOffer`, undefined);
    }

    // --------------------------------- ACTIONS (END) ---------------------------------

    // ---------------------------------- ORDER LIST -----------------------------------
    getProductionOrderNumbersForOrderList(ids: number[]): Observable<OrderProductionOrderNumbers[]> {
        return this.http.post<OrderProductionOrderNumbers[]>('productionorders/foroffers', ids);
    }

    getDeliveryListsForOrderList(ids: ListOfIds): Observable<OrderDeliveryListNames[]> {
        return this.http.post<OrderDeliveryListNames[]>('deliveryList/forOffers', ids);
    }

    // ------------------------------- ORDER LIST (END) --------------------------------

    updateExchangeRate(offerId: number): Observable<OfferExhangeRates> {
        return this.http.put<OfferExhangeRates>(`${OffersService.SERVICE_PATH}/${offerId}/updateExchangeRate`, undefined);
    }

    blockOffer(offerId: number): Observable<boolean> {
        return this.http.put<boolean>(`${OffersService.SERVICE_PATH}/${offerId}/block`, undefined);
    }

    unblockOffer(offerId: number): Observable<void> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${offerId}/unblock`, undefined);
    }

    archiveOffer(offerId: number): Observable<void> {
        return this.http.post<void>(`${OffersService.SERVICE_PATH}/${offerId}/archive`, undefined);
    }

    getArchivedOfferVersions(offerId: number): Observable<OfferVersion[]> {
        return this.http.get<OfferVersion[]>(`${OffersService.SERVICE_PATH}/${offerId}/archive`);
    }

    restoreArchivedOfferVersion(offerId: number, archivedId: number): Observable<number> {
        return this.http.post<void>(`${OffersService.SERVICE_PATH}/${offerId}/restore/${archivedId}`, undefined, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(OffersService.SERVICE_PATH));
    }

    getOffersSuppliersList(offerId: number): Observable<SupplierInfo[]> {
        return this.http.get<SupplierInfo[]>(`${OffersService.SERVICE_PATH}/${offerId}/suppliers`);
    }

    canCreateCorrection(offerId: number): Observable<boolean> {
        return this.http.get<boolean>(`${OffersService.SERVICE_PATH}/${offerId}/canCreateCorrection`);
    }

    cancelOrder(offerId: number): Observable<any> {
        return this.http.put<void>(`${OffersService.SERVICE_PATH}/${offerId}/cancelOrder`, undefined);
    }

    getOffersSimple(searchedValue: string, offset: number, pageSize: number, sortColumn: string,
                    sortOrder: number): Observable<Listing<Offer>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, {searchedValue: {value: searchedValue}},
            sortColumn, sortOrder);
        return this.http.get<Listing<Offer>>(`${OffersService.SERVICE_PATH}/globalSearch`, {params: params})
            .pipe(map(result => this.mapOfferListing(result)));
    }

    checkForPartnerOfferResending(offerId: number): Observable<string[]> {
        return this.http.get<string[]>(`${OffersService.SERVICE_PATH}/${offerId}/checkForPartnerOfferResending`);
    }

    isRotEnabled(offerId: number): Observable<boolean> {
        return this.http.get<boolean>(`${OffersService.SERVICE_PATH}/${offerId}/isRotEnabled`);
    }

    getHistory(offerId: number): Observable<OfferHistoryEntry[]> {
        return this.http.get<object[]>(`offerHistory/${offerId}`)
            .pipe(mapItemArrayToJson(OfferHistoryEntry));
    }

    getPrintHistory(offerId: number): Observable<PrintHistoryEntry[]> {
        return this.http.get<object[]>(`printHistory/${offerId}`)
            .pipe(mapItemArrayToJson(PrintHistoryEntry));
    }

    getExportData(offerId: number, xml: boolean): Observable<File> {
        return this.http.get(`${OffersService.SERVICE_PATH}/${offerId}/export`, {
            responseType: "blob", observe: "response", headers: {
               Accept: [xml ? 'application/xml' : 'application/json', 'text/plain', '*/*']
            }
        }).pipe(map(response => {
            return new File([response.body], `EXPORTED_OFFER_${offerId}.${xml ? 'xml' : 'json'}`,
                {type: response.headers.get('Content-Type')});
        }));
    }

    getOfferLinkDurationHours(): Observable<number> {
        return this.http.get<number>(`${OffersService.SERVICE_PATH}/offerLinkDurationHours`);
    }

    getTokenWithAccessToOffer(offerId: number, expirationDate: Date): Observable<string> {
        return this.http.post(`${OffersService.SERVICE_PATH}/${offerId}/accessToken`,
            {tokenExpirationDate: expirationDate},
            {responseType: 'text'});
    }
}
