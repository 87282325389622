import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {ConfigDependentOptionField, ConfigDependentOptionTab} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ConfigEditorField} from '../../offer/config-editor/config-editor-field';
import {ConfigEditorFieldContentProvider} from '../../offer/config-editor/config-editor-field-content-provider';
import {AddonCategoryGroupService} from '../addon-category-group/addon-category-group.service';
import {AddonCategoryService} from '../addon-category/addon-category.service';
import {ConfigDependentOptionFieldUsage} from "../catalog-field-usage";
import {ColorService} from '../color/color.service';
import {ConfigSystem} from '../config-system/config-system';
import {ConfigSystemService} from '../config-system/config-system.service';
import {ConfigDesignerCatalogDependentOption} from './data-form/config-designer-catalog-dependent-option';
import {
    ConfigDesignerCatalogDependentOptionFormDependencyComponent
} from './data-form/config-designer-catalog-dependent-option-form-dependency.component';
import {ConfigDesignerCatalogDependentOptionService} from './data-form/config-designer-catalog-dependent-option.service';
import {ConfigDesignerCatalogDependentOptionsSet} from './config-designer-catalog-dependent-options-set';
import {ConfigDesignerCatalogDependentOptionsSetService} from './config-designer-catalog-dependent-options-set.service';
import {MaterialService} from "../material/material.service";
import {CatalogSection} from "../addons/CatalogSection";

@Component({
    selector: 'app-config-designer-catalog-dependent-options-set',
    templateUrl: './config-designer-catalog-dependent-options-set.component.html',
    providers: [ConfigDesignerCatalogDependentOptionsSetService, ConfigDesignerCatalogDependentOptionService, TranslatedSelectItemService,
        ConfigEditorFieldContentProvider, ConfigSystemService, ColorService, AddonCategoryGroupService,
        AddonCategoryService, MaterialService]
})
export class ConfigDesignerCatalogDependentOptionsSetComponent
    extends CrudCommonComponent<ConfigDesignerCatalogDependentOptionsSet, ConfigDesignerCatalogDependentOptionsSetService>
    implements OnInit {

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState: boolean;

    private readonly configDesignerCatalogDependentOptionService: ConfigDesignerCatalogDependentOptionService;
    private readonly configSystemService: ConfigSystemService;
    private readonly configEditorFieldContentProvider: ConfigEditorFieldContentProvider;
    private readonly addonCategoryService: AddonCategoryService;
    readonly allConfigEditorRequiredField: Subject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    readonly allConfigEditorDependentField: Subject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    inputConfigEditorRequiredField: Observable<SelectItem[]>;
    inputConfigEditorDependentField: Observable<SelectItem[]>;
    requiredInputSelectedMap: Map<string, string[]>;
    dependentInputSelectedMap: Map<string, string[]>;

    configSystems: Observable<ConfigSystem[]>;
    optionsSet: ConfigDesignerCatalogDependentOption[] = [];

    @ViewChild('table')
    table: Table;

    readonly STEPS = {
        DATA: 'DATA',
        REQUIRED: 'REQUIRED',
        DEPENDENT: 'DEPENDENT'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateData(),
        REQUIRED: () => this.validateRequired(),
        DEPENDENT: () => this.validateDependent()
    };

    editPermits: FieldLimitation[] = [];
    fieldUsage: ConfigDependentOptionFieldUsage;
    ConfigDependentOptionField = ConfigDependentOptionField;
    ConfigDependentOptionTab = ConfigDependentOptionTab;
    nextStepsFilled: boolean;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, false, ConfigDesignerCatalogDependentOptionsSetService, 'CONFIG_DESIGNER_CATALOG_DEPENDENT_OPTION',
            'ConfigDesignerCatalogDependentOption');
        this.configDesignerCatalogDependentOptionService = injector.get(ConfigDesignerCatalogDependentOptionService);
        this.configSystemService = injector.get(ConfigSystemService);
        this.configEditorFieldContentProvider = injector.get(ConfigEditorFieldContentProvider);
        this.addonCategoryService = injector.get(AddonCategoryService);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.inputConfigEditorRequiredField = this.allConfigEditorRequiredField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );
        this.inputConfigEditorDependentField = this.allConfigEditorDependentField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );
        this.fieldUsage = new ConfigDependentOptionFieldUsage(this);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const requiredFieldKeys = this.getAllowedConfigEditorRequiredFields().map(field => 'CONFIG_DESIGNER_FIELD.' + field);
        const dependentFieldKeys = this.getAllowedConfigEditorDependentFields().map(field => 'CONFIG_DESIGNER_FIELD.' + field);

        let filters = {};
        filters['target'] = {};
        filters['target'].value = CatalogSection.CONFIG;
        forkJoin({
            addonCategories: this.addonCategoryService.getItems(undefined, undefined, filters, 'sortIndex', 1),
            translations: this.translate.get([].concat(requiredFieldKeys, dependentFieldKeys))
        }).subscribe(data => {
            const requiredSelectItems = this.getAllowedConfigEditorRequiredFields().map(field => ({
                label: data.translations['CONFIG_DESIGNER_FIELD.' + field],
                value: field
            }));
            const dependentSelectItems = this.getAllowedConfigEditorDependentFields().map(field => ({
                label: data.translations['CONFIG_DESIGNER_FIELD.' + field],
                value: field
            }));
            const categorySelectItems = data.addonCategories.data.map(category => ({
                label: category.name[this.translate.currentLang],
                value: category.symbol
            }));
            const sortFunction = (a: SelectItem, b: SelectItem) => (a.label || "").localeCompare(b.label);

            this.configEditorFieldContentProvider.initializeProvider(categorySelectItems.map(selectItem => selectItem.value));
            this.allConfigEditorRequiredField.next([].concat(requiredSelectItems, categorySelectItems).sort(sortFunction));
            this.allConfigEditorDependentField.next([].concat(dependentSelectItems, categorySelectItems).sort(sortFunction));
        });

        this.configSystems = this.configSystemService.getItems(0, 0, {active: {value: 'true'}}, null, null).pipe(
            map(listing => listing.data),
            shareReplay(1));

        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.CONFIG_DEPENDENT_OPTIONS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    getNewItem(): ConfigDesignerCatalogDependentOptionsSet {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.optionsSet = [];
        return new ConfigDesignerCatalogDependentOptionsSet();
    }

    loadEditedItem(event: { data: ConfigDesignerCatalogDependentOptionsSet }): Observable<ConfigDesignerCatalogDependentOptionsSet> {
        return this.itemService.getItem(event.data.id).pipe(
            mergeMap(item => forkJoin({
                initConfigSystems: this.configSystems,
                ...this.configEditorFieldContentProvider.getCatalogDataSource(item.configSystemId, undefined, false)
            }).pipe(map(catalogData => {
                this.configEditorFieldContentProvider.initCatalog(catalogData);
                return item;
            })))
        );
    }

    doOnRowSelect(event) {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.configDesignerCatalogDependentOptionService.getItemsFromSet(event.data.id).subscribe(
            options => this.optionsSet = options
        );
        super.doOnRowSelect(event);
    }

    prepareItemForRequest() {
        let options = [];
        for (const requiredMapKey of this.requiredInputSelectedMap.keys()) {
            let {inputId: requiredInputId} = ConfigDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(requiredMapKey);
            let requiredValues = this.requiredInputSelectedMap.get(requiredMapKey);

            for (const requiredValue of requiredValues) {
                for (const dependentMapKey of this.dependentInputSelectedMap.keys()) {
                    let {
                        inputId: dependentInputId,
                        whenMatched
                    } = ConfigDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(dependentMapKey);
                    let dependentValues = this.dependentInputSelectedMap.get(dependentMapKey);
                    for (const dependentValue of dependentValues) {
                        let option = new ConfigDesignerCatalogDependentOption();
                        option.requiredInputId = requiredInputId;
                        option.requiredInputValue = requiredValue;
                        option.dependentInputId = dependentInputId;
                        option.dependentInputValue = dependentValue;
                        option.whenMatched = whenMatched;
                        options.push(option);
                    }
                }
            }
        }

        this.item.options = options;
        return this.item;
    }

    deleteItem(item: ConfigDesignerCatalogDependentOptionsSet): void {
        this.itemService.deleteItem(item.id).subscribe(
            () => {
                this.reloadDatatable();
                this.growlMessageController.info(`${this.translationKey}.${this.translationKey}_DELETED`);
            }
        );
    }

    validateData(): Observable<boolean> {
        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    validateRequired(): Observable<boolean> {
        const validationErrors = {};

        if (this.requiredInputSelectedMap == null || this.requiredInputSelectedMap.size === 0) {
            validationErrors[`required`] = `error.configDesignerCatalogDependentOptionsSetDto.required.not_empty`;
        } else if (Array.from(this.requiredInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`required`] = `error.configDesignerCatalogDependentOptionsSetDto.required.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return of(true);
    }

    validateDependent(): Observable<boolean> {
        const validationErrors = {};

        if (this.dependentInputSelectedMap == null || this.dependentInputSelectedMap.size === 0) {
            validationErrors[`dependent`] = `error.configDesignerCatalogDependentOptionsSetDto.dependent.not_empty`;
        } else if (Array.from(this.dependentInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`dependent`] = `error.configDesignerCatalogDependentOptionsSetDto.dependent.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }

        this.prepareItemForRequest();
        return this.itemService.validateDependentOptions(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    private getAllowedConfigEditorRequiredFields(): ConfigEditorField[] {
        return [
            ConfigEditorField.COLOR,
            ConfigEditorField.MATERIAL,
            ConfigEditorField.DIM_1_GREATER_THAN,
            ConfigEditorField.DIM_1_LESS_THAN,
            ConfigEditorField.DIM_2_GREATER_THAN,
            ConfigEditorField.DIM_2_LESS_THAN,
            ConfigEditorField.DIM_3_GREATER_THAN,
            ConfigEditorField.DIM_3_LESS_THAN,
            ConfigEditorField.DIM_4_GREATER_THAN,
            ConfigEditorField.DIM_4_LESS_THAN,
            ConfigEditorField.DIM_5_GREATER_THAN,
            ConfigEditorField.DIM_5_LESS_THAN,
            ConfigEditorField.DIM_6_GREATER_THAN,
            ConfigEditorField.DIM_6_LESS_THAN,
            ConfigEditorField.AREA_GREATER_THAN,
            ConfigEditorField.AREA_LESS_THAN,
        ];
    }

    private getAllowedConfigEditorDependentFields(): ConfigEditorField[] {
        return [
            ConfigEditorField.MATERIAL,
        ];
    }
}
