import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {GrowlMessageController} from '../../common/growl-message/growl-message-controller';
import {SavedFilter} from '../../common/saved-filter/saved-filter';
import {SavedFilterComponent} from '../../common/saved-filter/saved-filter.component';
import {SavedShownColumns} from '../../common/service/data.table.column.builder';
import {HasSavedFilter} from '../offer/has-saved-filter';
import {UserComponent} from './user.component';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['../../second-level-menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnDestroy {

    private readonly USERS_VIEW_ID = "user-view";

    navigationSubscription;
    counter: number;
    readonly currentTableFilters: { [component: string]: string } = {};
    readonly originalTableFilters: { [component: string]: string } = {};
    readonly currentTableVisibleColumns: { [component: string]: string } = {};
    readonly originalTableVisibleColumns: { [component: string]: string } = {};

    @ViewChild(UserComponent, {static: true}) userComponent: UserComponent;
    @ViewChild(SavedFilterComponent, {static: true}) savedFilterComponent: SavedFilterComponent;

    constructor(private changeDetector: ChangeDetectorRef,
                private router: Router,
                private route: ActivatedRoute,
                private growlMessageController: GrowlMessageController) {

        this.navigationSubscription = this.router.events.subscribe((e: Event) => {
            if (e instanceof NavigationEnd && this.route.snapshot.queryParamMap.get('reload') === 'true') {
                // refreshing the page with this param still there will trigger js errors
                // clear it to prevent that from happening
                this.router.navigate([], {
                    replaceUrl: true,
                    relativeTo: this.route,
                    queryParams: {reload: null},
                    queryParamsHandling: 'merge'
                });
                this.reloadInnerComponents();
            }
        });
    }

    reloadInnerComponents() {
        this.savedFilterComponent.ngOnInit();
        this.userComponent.reloadComponent();
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    updateCurrentTableFilters(event: LazyLoadEvent): void {
        let data = {
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filters: event.filters
        };

        this.currentTableFilters[this.getSavedFilterViewId()] = JSON.stringify(data);
    }

    updateOriginalTableFilters(event: LazyLoadEvent): void {
        let data = {
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filters: event.filters
        };

        this.originalTableFilters[this.getSavedFilterViewId()] = JSON.stringify(data);

        this.unselectFilterIfCurrentTableFiltersOrVisibleColumnsHaveBeenChanged();
    }

    unselectFilterIfCurrentTableFiltersOrVisibleColumnsHaveBeenChanged() {
        if (this.savedFilterComponent && this.savedFilterComponent.selectedFilter &&
            (this.currentTableFilters[this.getSavedFilterViewId()] !== this.savedFilterComponent.selectedFilter.filter ||
                this.currentTableVisibleColumns[this.getSavedFilterViewId()] !== this.savedFilterComponent.selectedFilter.visibleColumns)) {
            this.savedFilterComponent.selectedFilterModified();
        }
    }

    updateTableVisibleColumns(columns: SavedShownColumns): void {
        this.currentTableVisibleColumns[this.getSavedFilterViewId()] = JSON.stringify(columns);
        this.originalTableVisibleColumns[this.getSavedFilterViewId()] = JSON.stringify(columns);
    }

    updateCounter(counter: number): void {
        this.counter = counter;
    }

    tableFilterSelected(filter: SavedFilter): void {
        this.currentTableVisibleColumns[this.getSavedFilterViewId()] = filter.visibleColumns;
        this.getActiveComponent().applySavedFilterAndVisibleColumns(JSON.parse(filter.filter), JSON.parse(filter.visibleColumns));
    }

    tableFilterUnselected(): void {
        this.currentTableFilters[this.getSavedFilterViewId()] = this.originalTableFilters[this.getSavedFilterViewId()];
        this.currentTableVisibleColumns[this.getSavedFilterViewId()] = this.originalTableVisibleColumns[this.getSavedFilterViewId()];
        this.getActiveComponent()
            .applySavedFilterAndVisibleColumns(JSON.parse(this.originalTableFilters[this.getSavedFilterViewId()]),
                JSON.parse(this.originalTableVisibleColumns[this.getSavedFilterViewId()]));
    }

    private getActiveComponent(): HasSavedFilter {
        return this.userComponent;
    }

    tableFilterCreateSuccess(): void {
        this.growlMessageController.info('SAVED_FILTER.CREATE_DIALOG.SUCCESS');
    }

    tableFilterCreateError(): void {
        this.growlMessageController.error('SAVED_FILTER.CREATE_DIALOG.ERROR');
    }

    tableFilterDeleteSuccess(): void {
        this.growlMessageController.info('SAVED_FILTER.DELETE_DIALOG.SUCCESS');
    }

    tableFilterDeleteError(): void {
        this.growlMessageController.error('SAVED_FILTER.DELETE_DIALOG.SUCCESS');
    }

    getSavedFilterViewId(): string {
        return this.USERS_VIEW_ID;
    }
}
