<div class="new-form-60vw">
    <div class="new-form-row">
        <app-select [(ngModel)]="item.category" [(validationMessageKey)]="validationErrors['category']"
                    [optionKey]="getCategoryKey" [options]="categories" [required]="true"
                    inputId="category" label="{{ 'CONFIG_SYSTEM.FORM.CATEGORY' | translate }}"></app-select>
    </div>

    <div class="new-form-row">
        <app-select [(ngModel)]="item.function" [(validationMessageKey)]="validationErrors['function']"
                    [optionKey]="getFunctionKey" [options]="functions" [required]="true"
                    inputId="function" label="{{ 'CONFIG_SYSTEM.FORM.FUNCTION' | translate }}"></app-select>
    </div>

    <app-translation-fields [field]="item.name"
                    [maxlength]="100"
                    [required]="true" [validationErrors]="validationErrors" inputId="name"
                    label="{{ 'CONFIG_SYSTEM.FORM.NAME' | translate }}" validationKeyPrefix="name"></app-translation-fields>

    <app-translation-fields [field]="item.shortcut"
                    [maxlength]="100"
                    [required]="false" [validationErrors]="validationErrors" inputId="shortcut"
                    label="{{ 'CONFIG_SYSTEM.FORM.SHORTCUT' | translate }}" validationKeyPrefix="shortcut"></app-translation-fields>

    <div class="new-form-row">
        <app-input-text [(ngModel)]="item.symbol" [(validationMessageKey)]="validationErrors['symbol']"
                        [maxlength]="50" [required]="true"
                        inputId="symbol" label="{{ 'CONFIG_SYSTEM.FORM.SYMBOL' | translate }}">
        </app-input-text>
    </div>

    <div class="new-form-row">
        <app-select [(ngModel)]="item.supplier" [(validationMessageKey)]="validationErrors['supplier']"
                    [optionKey]="getSupplierKey" [options]="suppliers" [required]="true"
                    inputId="supplier" label="{{ 'CONFIG_SYSTEM.FORM.SUPPLIER' | translate }}"></app-select>
    </div>

    <div class="new-form-row">
        <app-input-number [(ngModel)]="item.dimensionStep" [(validationMessageKey)]="validationErrors['dimensionStep']"
                          [min]="1" [required]="true"
                          inputId="dimensionStep" label="{{ 'CONFIG_SYSTEM.FORM.DIMENSION_STEP' | translate }}"></app-input-number>
    </div>

    <div class="new-form-row">
        <app-input-text #unitWeightInput inputId="unitWeight" label="{{ 'CONFIG_SYSTEM.FORM.UNIT_WEIGHT' | translate }}"
                        [(validationMessageKey)]="validationErrors['unitWeight']" [required]="true"
                        [(ngModel)]="item.unitWeight"
                        appInputTooltip="{{ 'ADDONS.FORM.DEFAULT_QUANTITY_TOOLTIP' | translate }}"
                        [appInputTooltipComponentHost]="unitWeightInput"></app-input-text>
    </div>

    <div class="new-form-row">
        <app-checkbox [(ngModel)]="item.active" inputId="active"
                      label="{{ 'CONFIG_SYSTEM.FORM.ACTIVE' | translate }}"></app-checkbox>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number [(ngModel)]="item.sortIndex"
                          [(validationMessageKey)]="validationErrors['sortIndex']"
                          [allowOnlyIntegers]="true"
                          [max]="9999" [min]="1" [required]="true" [step]="1"
                          inputId="sortIndex" label="{{ 'CONFIG_SYSTEM.FORM.SORT_INDEX' | translate }}"></app-input-number>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab [selected]="false"
                            header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.APPLICABLE' | translate }}">
                <div class="new-form-row">
                    <p-listbox [(ngModel)]="item.applicableTo"
                               [multiple]="true"
                               [options]="availableApplicableTo | selectItemTranslate"></p-listbox>
                </div>
                <div *ngIf="validationErrors['applicableTo']" class="new-form-row">
                    <div *ngIf="validationErrors['applicableTo']" class="new-form-field">
                            <div class="new-form-field-error-message">
                                {{ validationErrors['applicableTo'] | translate }}
                            </div>
                    </div>
                </div>

                <div class="new-form-row">
                    <app-checkbox inputId="canBeAddedToAreaWithOuterGrill" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL' | translate }}"
                                  [(ngModel)]="item.canBeAddedToAreaWithOuterGrill"></app-checkbox>
                </div>
                <div class="new-form-row">
                    <app-checkbox inputId="canBeAddedToAreaWithoutFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING' | translate }}"
                                  [(ngModel)]="item.canBeAddedToAreaWithoutFilling"></app-checkbox>
                </div>
                <div class="new-form-row">
                    <app-checkbox inputId="canBeAddedToAreaWithOtherFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING' | translate }}"
                                  [(ngModel)]="item.canBeAddedToAreaWithOtherFilling"></app-checkbox>
                </div>
                <div class="new-form-row">
                    <app-checkbox inputId="canBeAddedToAreaWithDecorativeFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING' | translate }}"
                                  [(ngModel)]="item.canBeAddedToAreaWithDecorativeFilling"></app-checkbox>
                </div>
                <div class="new-form-row">
                    <app-checkbox inputId="canBeAddedToOpenableOnly" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_OPENABLE_ONLY' | translate }}"
                                  [(ngModel)]="item.canBeAddedToOpenableOnly"></app-checkbox>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.MESSAGES' | translate}}">
                <div class="cechy-visibility-grid">
                    <div class="offer-order-selection-header"></div>
                    <div class="offer-order-selection-header">
                        {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_OFFER' | translate }}
                    </div>
                    <div class="offer-order-selection-header">
                        {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_ORDER' | translate }}
                    </div>
                    <ng-container *ngFor="let messageSeverity of printableMessageSeverityLevels">
                        <div class="offer-order-selection-row new-form-field">
                            <label [htmlFor]="messageSeverity">
                                {{ ('TRANSLATIONS_FOR_PRINT_OUTS.MESSAGES.' + messageSeverity) | translate}}
                            </label>
                        </div>
                        <div class="offer-order-selection-row">
                            <app-checkbox (ngModelChange)="handleMessageLevelChange(messageLevelsOffer, messageSeverity, $event)"
                                          [ngModel]="messageLevelsOffer[messageSeverity]"
                                          inputId="{{'offerItem_'+ messageSeverity}}"
                            ></app-checkbox>
                        </div>
                        <div class="offer-order-selection-row">
                            <app-checkbox (ngModelChange)="handleMessageLevelChange(messageLevelsOrder, messageSeverity, $event)"
                                          [ngModel]="messageLevelsOrder[messageSeverity]"
                                          inputId="{{'orderItem'+ messageSeverity}}"
                            ></app-checkbox>
                        </div>
                    </ng-container>
                </div>
            </p-accordionTab>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['imageLarge']}" [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.IMAGE_LARGE_SECTION' | translate }}">
                <app-file-upload (ngModelChange)="onImageLargeChange($event)" [(validationMessageKey)]="validationErrors['imageLarge']"
                                 [addButtonLabel]="'CONFIG_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CONFIG_SYSTEM.FORM.CHANGE_FILE_UPLOAD'" [deleteButtonLabel]="'CONFIG_SYSTEM.FORM.DELETE_FILE_UPLOAD'"
                                 [maxHeight]="2000" [maxSize]="500000" [maxWidth]="2000" [ngModel]="imageLarge"
                                 inputId="imageLarge"></app-file-upload>
            </p-accordionTab>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['designerImage']}" [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.DESIGNER_IMAGE_SECTION' | translate }}">
                <app-file-upload (ngModelChange)="onDesignerImageChange($event)" [(validationMessageKey)]="validationErrors['designerImage']"
                                 [addButtonLabel]="'CONFIG_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CONFIG_SYSTEM.FORM.CHANGE_FILE_UPLOAD'" [deleteButtonLabel]="'CONFIG_SYSTEM.FORM.DELETE_FILE_UPLOAD'"
                                 [maxHeight]="2000" [maxSize]="500000" [maxWidth]="2000" [ngModel]="designerImage"
                                 inputId="designerImage"></app-file-upload>
            </p-accordionTab>

            <p-accordionTab [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.MATERIAL_AND_COLOR_PLACEMENT' | translate }}">

                <div class="new-form-row">
                    <app-select [ngModel]="item.materialColorCategoryGroupId" (ngModelChange)="onAddonCategoryGroupChange($event)"
                                [optionFormatter]="formatAddonCategoryGroupsOption" [options]="addonCategoryGroups" [required]="false"
                                [allowSelectingNone]="true"
                                inputId="materialColorCategoryGroupId" label="{{ 'CONFIG_SYSTEM.FORM.ACCORDION' | translate }}"></app-select>
                </div>

                <div class="new-form-row">
                    <app-select [(ngModel)]="item.materialColorPrevCategoryId" [optionFormatter]="formatAddonCategoryGroupsOption"
                                [options]="availableCategories" [required]="false" [allowSelectingNone]="true"
                                inputId="materialColorPrevCategoryId" label="{{ 'CONFIG_SYSTEM.FORM.PREV_CATEGORY' | translate }}"></app-select>
                </div>

                <div class="new-form-row">
                    <app-translation-fields [field]="labelKeysMap.get(getLabelKey('MATERIAL', 'FRONTEND'))"
                                            [maxlength]="100"
                                            [required]="false" [validationErrors]="validationErrors" inputId="materialLabel"
                                            label="{{ 'CONFIG_SYSTEM.FORM.MATERIAL_LABEL' | translate }}" validationKeyPrefix="materialLabel"></app-translation-fields>
                </div>

                <div class="new-form-row">
                    <app-translation-fields [field]="labelKeysMap.get(getLabelKey('MATERIAL', 'PRINT'))"
                                            [maxlength]="100"
                                            [required]="false" [validationErrors]="validationErrors" inputId="materialLabel"
                                            label="{{ 'CONFIG_SYSTEM.FORM.MATERIAL_PRINT_LABEL' | translate }}" validationKeyPrefix="materialLabel"></app-translation-fields>
                </div>

                <div class="new-form-row">
                    <app-translation-fields [field]="labelKeysMap.get(getLabelKey('COLOR', 'FRONTEND'))"
                                            [maxlength]="100"
                                            [required]="false" [validationErrors]="validationErrors" inputId="colorLabel"
                                            label="{{ 'CONFIG_SYSTEM.FORM.COLOR_LABEL' | translate }}" validationKeyPrefix="colorLabel"></app-translation-fields>
                </div>

                <div class="new-form-row">
                    <app-translation-fields [field]="labelKeysMap.get(getLabelKey('COLOR', 'PRINT'))"
                                            [maxlength]="100"
                                            [required]="false" [validationErrors]="validationErrors" inputId="colorLabel"
                                            label="{{ 'CONFIG_SYSTEM.FORM.COLOR_PRINT_LABEL' | translate }}" validationKeyPrefix="colorLabel"></app-translation-fields>
                </div>

                <div class="new-form-row">
                    <app-checkbox inputId="hideColorSelect" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.HIDE_COLOR_SELECT' | translate }}"
                                  [(ngModel)]="item.hideColorSelect"></app-checkbox>
                </div>

            </p-accordionTab>
        </p-accordion>
    </div>
</div>
