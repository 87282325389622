<div class="new-form-600">

    <app-translation-fields *ngIf="fieldUsage.show(MaterialField.NAME)"
                            inputId="name" label="{{ 'ADDON_MATERIAL.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(MaterialField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.name"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(MaterialField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'ADDON_MATERIAL.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(MaterialField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(MaterialField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'ADDON_MATERIAL.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(MaterialField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50" [required]="true"
                        [(ngModel)]="item.symbol"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(MaterialField.GROUP)" class="new-form-row">
        <app-input-text inputId="group" label="{{ 'ADDON_MATERIAL.FORM.GROUP' | translate }}"
                        [disabled]="fieldUsage.disabled(MaterialField.GROUP)"
                        [(validationMessageKey)]="validationErrors['group']" [maxlength]="100" [required]="false"
                        [(ngModel)]="item.group"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(MaterialField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'ADDON_MATERIAL.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(MaterialField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(MaterialField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'ADDON_MATERIAL.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(MaterialField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion *ngIf="fieldUsage.show(MaterialField.IMAGE_SECTION)">
        <p-accordionTab header="{{ 'GRILL.FORM.IMAGE_SECTION' | translate }}"
                        [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="false"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'GRILL.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GRILL.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GRILL.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
