import * as _ from 'underscore';
import {DecorativeFillingInterface} from "../catalog-data/decorative-filling-interface";
import {AreaSpecification} from "../drawing-data/AreaSpecification";
import {CompositionType} from "../drawing-data/CompositionType";
import {CutData} from "../drawing-data/CutData";
import {FillingType} from "../drawing-data/FillingType";
import {Handle} from "../drawing-data/Handle";
import {HandleState} from "../drawing-data/HandleState";
import {Mullion} from "../drawing-data/Mullion";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowData} from "../drawing-data/WindowData";
import {DataKeys, DrawingUtil, MinMaxXY} from "../drawing-util";
import {HandleHelper} from "../handle-helper";
import {ProfilesCompositionType} from "../profiles-composition-distances";
import {ShadingCalculator, Shadings} from "../ShadingCalculator";
import {AngleConverter} from '../utils/angle-converter';
import {AreaUtils} from "../utils/AreaUtils";
import {CutsUtil} from "../utils/cutUtils";
import {ErrorNames} from "../utils/ErrorNames";
import {FloatOps} from '../utils/float-ops';
import {MullionUtils} from "../utils/MullionUtils";
import {PolygonPoint, PolygonPointUtil} from "../utils/PolygonPoint";
import {SubwindowDependencies} from "../utils/SubwindowDependencies";
import {VentilationUtils} from "../utils/VentilationUtils";
import {WindowCalculator} from "../window-calculator";
import {Tool, WindowDesignerInterface} from '../window-designer-interface';
import {SubWindowTypeCode} from "../window-types/subwindow-type-code";
import {ConfigAddonIconPainter} from "./ConfigAddonIconPainter";
import {DecorativeFillingPainter} from "./DecorativeFillingPainter";
import {HandlePainter} from "./HandlePainter";
import {OpeningLines} from "./OpeningLines";
import {PainterMode} from "./PainterMode";
import {PainterParams} from "./PainterParams";
import {ScalingPainter} from "./ScalingPainter";
import {TextPainter} from "./TextPainter";
import {WindowParams} from "./WindowParams";

export class WindowPainter {

    protected offerComponent: WindowDesignerInterface;

    constructor(offerComponent: WindowDesignerInterface) {
        this.offerComponent = offerComponent;
    }

    public paint(window: WindowData, cuts: CutData[], clickableElements: Snap.Element[], params: PainterParams,
                 shadings?: Shadings): void {
        let dependencies = new SubwindowDependencies(window, this.offerComponent, cuts);
        let sortedSubwindows = this.sortSubwindows(window.subWindows, params, dependencies);
        sortedSubwindows.forEach(subwindow => {
            let relevantCuts = cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
            this.paintSubwindow(subwindow, relevantCuts, clickableElements, params, dependencies, shadings);
        });
    }

    private sortSubwindows(subwindows: SubWindowData[], params: PainterParams, dependencies: SubwindowDependencies): SubWindowData[] {
        let toSort = [...subwindows];
        let sorted = [];

        let index = 0;
        while (toSort.length > 0) {
            index = (index + toSort.length) % toSort.length;
            let subwindow = toSort[index];
            if (_.intersection(dependencies.get(subwindow), toSort).length > 0) {
                index++;
                continue;
            }
            sorted.push(subwindow);
            toSort.splice(index, 1);
        }

        // but still paint leading windows last (on top of everything else)
        let result = [
            ...sorted.filter(sw => !WindowCalculator.isSubWindowActive(sw)),
            ...sorted.filter(sw => WindowCalculator.isSubWindowActive(sw))
        ];

        if (params.viewFromOutside) {
            result = result.reverse();
        }
        return result;
    }

    private paintSubwindow(subWindow: SubWindowData, cuts: CutData[], clickableElements: Snap.Element[],
                           params: PainterParams, dependencies: SubwindowDependencies, shadings: Shadings): void {
        this.paintWingShadows(subWindow, params, shadings, dependencies, false);
        if (this.isSubWindowF(subWindow)) {
            this.paintFSubwindow(subWindow, cuts, clickableElements, params, dependencies, shadings);
        } else {
            this.paintFFSubwindow(subWindow, cuts, clickableElements, params, dependencies, shadings);
        }
        this.paintWingShadows(subWindow, params, shadings, dependencies, true);
    }

    private paintWingShadows(subwindow: SubWindowData, params: PainterParams, shadings: Shadings,
                             dependencies: SubwindowDependencies, after: boolean): void {
        if (params.isShaded() && params.viewFromOutside === after) {
            dependencies.get(subwindow).forEach(otherSubwindow => {
                let wingOuterEdge = WindowCalculator.getFFWingOuterEdgePoints(subwindow, this.offerComponent.data.cuts,
                    this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled());
                let otherWingOuterEdge = WindowCalculator.getFFWingOuterEdgePoints(otherSubwindow,
                    this.offerComponent.data.cuts,
                    this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled());
                let overlap = DrawingUtil.getPolygonsOverlapPoints(wingOuterEdge, otherWingOuterEdge);
                if (overlap.length > 2) {
                    let overlapShadow = ScalingPainter.path(this.offerComponent.svg, overlap,
                        WindowParams.wingShadow(ShadingCalculator.SHADOW_OPACITY), params);
                    overlapShadow.attr({filter: shadings.wingBlurFilter});
                }
            });
        }
    }

    private paintFSubwindow(subWindow: SubWindowData, cuts: CutData[], clickableElements: Snap.Element[],
                            params: PainterParams, dependencies: SubwindowDependencies, shadings: Shadings): void {
        let totalInnerFrameFull = WindowCalculator.getTotalFrameInnerEdgePointsFull(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let totalInnerFrame = PolygonPointUtil.toNumbersArray(totalInnerFrameFull);
        let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPointsFull(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        this.addSubwindowBox(subWindow, cuts, totalInnerFrameFull, clickableElements, params, dependencies);
        this.addFrameInnerEdges(subWindow, totalInnerFrame, PolygonPointUtil.toNumbersArray(totalGlazingBeadPoints),
            params, shadings);
        this.addMullions(subWindow, totalInnerFrameFull, clickableElements, params, dependencies);
        this.addGlazingBeads(subWindow, totalGlazingBeadPoints, clickableElements, params, shadings);
        this.paintConfigAddonsIcon(subWindow.configurableAddonIds, subWindow.points, params);
        this.addVentilation(subWindow, cuts, params);
    }

    private paintFFSubwindow(subWindow: SubWindowData, cuts: CutData[], clickableElements: Snap.Element[],
                             params: PainterParams, dependencies: SubwindowDependencies, shadings: Shadings): void {
        let wingOuterEdge = WindowCalculator.getFFWingOuterEdgePoints(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let totalInnerFrameFull = WindowCalculator.getTotalFrameInnerEdgePointsFull(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let totalInnerFrame = PolygonPointUtil.toNumbersArray(totalInnerFrameFull);
        let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPointsFull(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let frameOpeningPoints = WindowCalculator.getTotalGlazingBeadPointsForcedForF(subWindow, cuts,
            this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
            this.offerComponent.isValidationDisabled());
        let elements = [];
        elements.push(...this.addSubwindowBox(subWindow, cuts, totalInnerFrameFull, clickableElements, params, dependencies));
        elements.push(...this.addWingOuterEdge(subWindow, cuts, wingOuterEdge, totalInnerFrameFull, totalInnerFrame,
            clickableElements, params, dependencies, shadings, frameOpeningPoints));
        elements.push(
            ...this.addFrameInnerEdges(subWindow, totalInnerFrame,
                PolygonPointUtil.toNumbersArray(totalGlazingBeadPoints),
                params, shadings));
        elements.push(...this.addMullions(subWindow, totalInnerFrameFull, clickableElements, params, dependencies));
        elements.push(...this.addGlazingBeads(subWindow, totalGlazingBeadPoints, clickableElements, params, shadings));
        this.paintConfigAddonsIcon(subWindow.configurableAddonIds, wingOuterEdge, params);
        elements.push(
            HandlePainter.paint(this.offerComponent, subWindow, cuts, this.offerComponent.totalBoundingBox, params));
        elements.push(...this.paintMovableBar(subWindow, params));
        elements.push(...this.addVentilation(subWindow, cuts, params));

        if (params.viewFromOutside) {
            let hiddenOuterPart = ScalingPainter.path(this.offerComponent.svg, subWindow.points,
                WindowParams.OverlayMask.Hide, params);
            let shownCenter = ScalingPainter.path(this.offerComponent.svg, frameOpeningPoints,
                WindowParams.OverlayMask.Show, params);
            let mask = this.offerComponent.svg.group(hiddenOuterPart, shownCenter);
            let grouped = this.offerComponent.svg.g();
            grouped.attr({mask: mask, pointerEvents: 'none'});
            elements.forEach(el => grouped.add(el));

            let fInnerFrame = WindowCalculator.getTotalFrameInnerEdgePointsForcedForF(subWindow, cuts,
                this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                this.offerComponent.isValidationDisabled());
            this.addBentPartsShadings(fInnerFrame, frameOpeningPoints, params, shadings);
            ScalingPainter.path(this.offerComponent.svg, frameOpeningPoints, WindowParams.strokeAccent, params);
        }
    }

    public paintSubwindowOpenings(windowData: WindowData, subWindow: SubWindowData,
                                  outerSubwindowPoints: number[], glazingBeadPoints: number[],
                                  floatingElementsBoxes: number[][], params: PainterParams): Snap.Element[] {
        let svg = this.offerComponent.svg;
        let elements: Snap.Element[] = [];
        if (glazingBeadPoints.length >= 6) {
            switch (subWindow.typeCode) {
                case SubWindowTypeCode.F:
                case SubWindowTypeCode.FF:
                    break;
                case SubWindowTypeCode.U:
                    elements.push(...OpeningLines.drawUOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.RL:
                case SubWindowTypeCode.ROL:
                    elements.push(...OpeningLines.drawR_LOpeningLines(glazingBeadPoints, svg, params, false));
                    break;
                case SubWindowTypeCode.RP:
                case SubWindowTypeCode.ROP:
                    elements.push(...OpeningLines.drawR_ROpeningLines(glazingBeadPoints, svg, params, false));
                    break;
                case SubWindowTypeCode.URL:
                    elements.push(...OpeningLines.drawUOpeningLines(glazingBeadPoints, svg, params));
                    elements.push(...OpeningLines.drawR_LOpeningLines(glazingBeadPoints, svg, params, false));
                    break;
                case SubWindowTypeCode.URP:
                    elements.push(...OpeningLines.drawUOpeningLines(glazingBeadPoints, svg, params));
                    elements.push(...OpeningLines.drawR_ROpeningLines(glazingBeadPoints, svg, params, false));
                    break;
                case SubWindowTypeCode.HSL:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            false, true));
                    break;
                case SubWindowTypeCode.HSP:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            true, true));
                    break;
                case SubWindowTypeCode.UPL:
                    elements.push(...OpeningLines.drawUOpeningLines(glazingBeadPoints, svg, params));
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            false));
                    break;
                case SubWindowTypeCode.UPP:
                    elements.push(...OpeningLines.drawUOpeningLines(glazingBeadPoints, svg, params));
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            true));
                    break;
                case SubWindowTypeCode.OG:
                case SubWindowTypeCode.OOG:
                    elements.push(...OpeningLines.drawOGOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.OL:
                    elements.push(...OpeningLines.drawOLOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.OP:
                    elements.push(...OpeningLines.drawOPOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.WL:
                    elements.push(...OpeningLines.drawWLOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.WP:
                    elements.push(...OpeningLines.drawWPOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.PL:
                case SubWindowTypeCode.SDL:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            false));
                    break;
                case SubWindowTypeCode.CPL:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            false, false, true));
                    break;
                case SubWindowTypeCode.PP:
                case SubWindowTypeCode.SDP:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            true));
                    break;
                case SubWindowTypeCode.CPP:
                    elements.push(
                        ...OpeningLines.drawArrowOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes,
                            true, false, true));
                    break;
                case SubWindowTypeCode.PD:
                    elements.push(
                        ...OpeningLines.drawPDOpeningLines(glazingBeadPoints, svg, params, floatingElementsBoxes));
                    break;
                case SubWindowTypeCode.A:
                case SubWindowTypeCode.ROA:
                    elements.push(...OpeningLines.drawAOpeningLines(glazingBeadPoints, svg, params));
                    break;
                case SubWindowTypeCode.SHL:
                    elements.push(...OpeningLines.drawR_LOpeningLines(glazingBeadPoints, svg, params, true));
                    break;
                case SubWindowTypeCode.SHP:
                    elements.push(...OpeningLines.drawR_ROpeningLines(glazingBeadPoints, svg, params, true));
                    break;
                case SubWindowTypeCode.HL: {
                    if (subWindow.isLeading) {
                        elements.push(...OpeningLines.drawR_LOpeningLines(glazingBeadPoints, svg, params, false));
                    }
                    const myIndex = windowData.subWindows.indexOf(subWindow);
                    const firstHL = windowData.subWindows.findIndex(sw => sw.typeCode === SubWindowTypeCode.HL);
                    const lastHL = windowData.subWindows
                        .reduce((lastIndex, sw, i) => sw.typeCode === SubWindowTypeCode.HL ? i : lastIndex, 0);
                    const drawArrowHead = firstHL === myIndex;
                    const shorterLineEnd = lastHL === myIndex;
                    elements.push(...OpeningLines.drawHL_HPOpeningLines(outerSubwindowPoints, glazingBeadPoints, svg, params, false,
                        drawArrowHead, shorterLineEnd));
                    break;
                }
                case SubWindowTypeCode.HP: {
                    if (subWindow.isLeading) {
                        elements.push(...OpeningLines.drawR_ROpeningLines(glazingBeadPoints, svg, params, false));
                    }
                    const myIndex = windowData.subWindows.indexOf(subWindow);
                    const firstHP = windowData.subWindows.findIndex(sw => sw.typeCode === SubWindowTypeCode.HP);
                    const lastHP = windowData.subWindows
                        .reduce((lastIndex, sw, i) => sw.typeCode === SubWindowTypeCode.HP ? i : lastIndex, 0);
                    const drawArrowHead = lastHP === myIndex;
                    const shorterLineEnd = firstHP === myIndex;
                    elements.push(...OpeningLines.drawHL_HPOpeningLines(outerSubwindowPoints, glazingBeadPoints, svg, params, true,
                        drawArrowHead, shorterLineEnd));
                    break;
                }
                default:
                    let err = new Error(
                        "WindowPainter.paintSubwindowOpenings: Not implemented for type: " + subWindow.typeCode);
                    err.name = ErrorNames.NOT_IMPLEMENTED;
                    throw err;
            }
        } else {
            console.log("cannot draw opening lines because just two points of window are available!");
        }
        return elements;
    }

    private addGlazingBeads(subWindow: SubWindowData, totalGlazingBeadPoints: PolygonPoint[],
                            clickableElements: Snap.Element[], params: PainterParams,
                            shadings: Shadings): Snap.Element[] {
        let elements = [];
        for (let area of subWindow.areasSpecification) {
            let glazingBeadPoints = WindowCalculator.getGlazingBeadPointsFull(subWindow, area.definingMullions,
                totalGlazingBeadPoints, this.offerComponent.profileCompositionDistances);

            elements.push(
                ...this.addGlazingBead(glazingBeadPoints, subWindow, area, clickableElements, params, shadings));
            this.paintAngleValues(glazingBeadPoints, params);
        }
        return elements;
    }

    private renderFilling(glazingBeadPoints: number[], params: PainterParams): Snap.Element {
        return ScalingPainter.path(this.offerComponent.svg, glazingBeadPoints,
            {fill: WindowParams.getBackgroundColor(params)}, params);
    }

    private addGlazingBead(glazingBeadPointsFull: PolygonPoint[], subWindow: SubWindowData, area: AreaSpecification,
                           clickableElements: Snap.Element[], params: PainterParams,
                           shadings: Shadings): Snap.Element[] {
        let elements = [];
        let glazingBeadPoints = PolygonPointUtil.toNumbersArray(glazingBeadPointsFull);
        let attr = params.isShaded() ? WindowParams.white : WindowParams.smallerOnThumbnail(params);
        let glazingBead = this.offerComponent.svg.g().attr(attr);
        elements.push(glazingBead);
        glazingBead.add(ScalingPainter.path(this.offerComponent.svg, glazingBeadPoints, {}, params));
        if (params.isShaded()) {
            shadings.add(glazingBead, 0, 0);
            let glazingBeadPointsShrunk = DrawingUtil.getPolygonFromBBox(
                DrawingUtil.shrinkBoxBy(DrawingUtil.calculatePolygonTotalBoundingBox(glazingBeadPoints), 4));
            let clickable = ScalingPainter.path(this.offerComponent.svg, glazingBeadPoints, WindowParams.black, params);
            elements.push(clickable);
            clickable.attr(WindowParams.black);
            clickable.attr({
                mask: ScalingPainter.prepareHollowMask(this.offerComponent.svg, glazingBeadPoints,
                    glazingBeadPointsShrunk, params)
            });
            switch (area.filling.type) {
                case FillingType.GLASS:
                    let gradientString = ShadingCalculator.areaGlassesGradientString(glazingBeadPoints, params);
                    let gradient = this.offerComponent.svg.gradient(gradientString);
                    elements.push(
                        ScalingPainter.path(this.offerComponent.svg, glazingBeadPoints, {fill: gradient}, params));
                    break;
                case FillingType.DECORATIVE_FILLING:
                    elements.push(this.renderFilling(glazingBeadPoints, params));
                    DecorativeFillingPainter.paint(glazingBeadPoints, area, this.offerComponent.svg, params,
                        this.getDecorativeFillingForArea(area));
                    break;
                case FillingType.FILLING:
                    elements.push(this.renderFilling(glazingBeadPoints, params));
                    break;
                case FillingType.NO_FILLING:
                    elements.push(ScalingPainter.path(this.offerComponent.svg, glazingBeadPoints,
                        {fill: this.offerComponent.noFillingPattern}, params));
                    break;
            }
        } else {
            this.addFillingRepresentation(glazingBead, area, glazingBeadPoints, params);
        }
        if (params.isRegularMode()) {
            glazingBead.data(DataKeys.SUBWINDOW, subWindow);
            glazingBead.data(DataKeys.AREA, area);
            glazingBead.data(DataKeys.FILLING, area.filling);
            glazingBead.data(DataKeys.GLAZING_BEAD, glazingBeadPointsFull);
            glazingBead.addClass(WindowParams.GLASS_ELEM);
            this.offerComponent.addSelectionClickHandler(glazingBead, WindowParams.GLASS_ELEM);

            if (this.isToolActive(Tool.GRILL, area)) {
                let clickable = this.offerComponent.svg.path(
                    DrawingUtil.pathStringFromPolygonPoints(glazingBeadPoints, true));
                elements.push(clickable);
                clickable.data(DataKeys.SUBWINDOW, subWindow);
                clickable.data(DataKeys.AREA, area);
                clickable.data(DataKeys.GLAZING_BEAD, glazingBeadPointsFull);
                clickable.click(event => this.offerComponent.onClickLineGrillHandler(event));
                clickable.attr(WindowParams.GlazingBeadClickableModeGrill.Attributes);
                clickable.attr({
                    strokeWidth: (2 * this.offerComponent.profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD))
                });
                clickable.addClass(WindowParams.GLAZING_BEAD_ELEM);
                clickableElements.push(clickable);
            }
        }
        this.paintConfigAddonsIcon(area.configurableAddonIds, glazingBeadPoints, params);
        return elements;
    }

    private getDecorativeFillingForArea(area: AreaSpecification): DecorativeFillingInterface {
        let decorativeFilling = this.offerComponent.staticData.decorativeFillings.find(
            f => f.id === area.filling.decorativeFillingId);
        if (decorativeFilling == null) {
            console.error(
                `addFillingRepresentation: decorative filling not found for id: ${area.filling.decorativeFillingId}`);
        }
        return decorativeFilling;
    }

    private addFillingRepresentation(element: Snap.Element, area: AreaSpecification, glazingBeadPoints: number[],
                                     params: PainterParams): void {
        let oC = this.offerComponent;
        switch (area.filling.type) {
            case FillingType.NO_FILLING:
                this.paintNoFillingCross(glazingBeadPoints, element, params);
                break;
            case FillingType.FILLING:
                element.attr({fill: oC.stripedFillingPattern});
                break;
            case FillingType.DECORATIVE_FILLING:
                DecorativeFillingPainter.paint(glazingBeadPoints, area, oC.svg, params,
                    this.getDecorativeFillingForArea(area)).forEach(group => element.add(group));
                break;
            case FillingType.GLASS:
                if (this.offerComponent.isGlazingOrnament(area.glazing)) {
                    ScalingPainter.path(oC.svg, glazingBeadPoints,
                        {fill: oC.dottedOrnamentPattern, pointerEvents: 'none'}, params);
                }
                if (oC.isDefaultGlazing(area)) {
                    element.addClass('glass');
                } else {
                    element.addClass('glass-custom');
                }
                break;
            default:
                break;
        }
    }

    private paintNoFillingCross(glazingBeadPoints: number[], element: Snap.Element, params: PainterParams): void {
        glazingBeadPoints = ScalingPainter.scale(params, glazingBeadPoints);
        let centroid = DrawingUtil.getPolygonCentroid(glazingBeadPoints);
        let w = this.offerComponent.profileCompositionDistances.getDefault(ProfilesCompositionType.GLAZING_BEAD) * params.scale;
        let h = 1000 * params.scale;
        let x = centroid[0] - (w / 2);
        let y = centroid[1] - (h / 2);
        let scale = 0.85;
        let scaleString = "s" + scale + "," + scale + "," + centroid[0] + "," + centroid[1];
        let clipPath = this.offerComponent.svg.polygon(glazingBeadPoints).attr({id: 'nfcp'}).transform(scaleString);
        let leftHand = this.offerComponent.svg.rect(x, y, w, h).transform("r135");
        let rightHand = this.offerComponent.svg.rect(x, y, w, h).transform("r45");
        let g = this.offerComponent.svg.group(leftHand, rightHand);
        g.attr({
            clipPath: clipPath,
            fill: '#666'
        });
        element.add(g);
    }

    private isSubWindowF(subWindow: SubWindowData): boolean {
        return subWindow.typeCode === SubWindowTypeCode.F;
    }

    private createHandleAddingEvent(subWindow: SubWindowData, outerPath: string, innerPath: string,
                                    clickableElements: Snap.Element[]): void {
        let clickable = this.offerComponent.svg.path(outerPath);
        clickable.attr({mask: this.prepareHollowMask(outerPath, innerPath)});
        clickable.click(event => {
            let clicked = this.offerComponent.calculateSvgCoordsFromMouseEvent(event);
            clicked.x = FloatOps.round(clicked.x);
            clicked.y = FloatOps.round(clicked.y);
            if (DrawingUtil.isPointInPolygon([clicked.x, clicked.y], subWindow.points)) {
                this.offerComponent.saveStepInHistory();
                const center = WindowCalculator.getSubWindowCenterPoint(subWindow, this.offerComponent.data.cuts,
                    this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled());
                let angle = DrawingUtil.atan2normalized(clicked.y - center[1], clicked.x - center[0]) * 180 /
                    Math.PI;
                angle = (FloatOps.round(angle / 90) * 90) % 360;
                const newHandleState = subWindow.handle != undefined &&
                subWindow.handle.state === HandleState.DELETED
                    ? HandleState.MOVED
                    : HandleState.CUSTOM;
                subWindow.handle = new Handle(angle, this.offerComponent.pendingHandleDirection, newHandleState);
                this.offerComponent.redrawWindow(true, true);
            }
        });
        clickableElements.push(clickable);
    }

    private paintAngleValues(polygonPoints: PolygonPoint[], params: PainterParams): void {
        if (params.isMode(PainterMode.TECHNICAL) ||
            (params.isRegularMode() && this.offerComponent.visibilitySettings.angleValues)) {
            for (let i = 0; i < polygonPoints.length; i++) {
                let point1 = DrawingUtil.getPoint(polygonPoints, i);
                let midPoint = DrawingUtil.getPoint(polygonPoints, i + 1);
                let point2 = DrawingUtil.getPoint(polygonPoints, i + 2);

                if (midPoint.isArc) {
                    continue;
                }

                let angle1 = DrawingUtil.atan2normalized(midPoint.y - point1.y, midPoint.x - point1.x);
                let angle2 = DrawingUtil.atan2normalized(point2.y - midPoint.y, point2.x - midPoint.x);

                let angle = Math.abs(Math.PI - DrawingUtil.normalizeAngle(angle2 - angle1));

                let angleForText = FloatOps.round(AngleConverter.toDeg(angle));
                if ((angleForText % 90) !== 0) {
                    let textPainter = TextPainter.forDrawing(this.offerComponent, params);
                    let textElement = textPainter
                        .paintText(angleForText + '°', [midPoint.x, midPoint.y]);
                    let textBBox = textPainter.getBBox(textElement);
                    let textSize = Math.max(textBBox.w, textBBox.h);

                    let textDistance = textSize / Math.min(Math.tan(angle / 2), 0.85);
                    let textPosition = [
                        midPoint.x + Math.cos(angle2 + angle / 2) * textDistance,
                        midPoint.y + Math.sin(angle2 + angle / 2) * textDistance
                    ];
                    textPosition = ScalingPainter.scale(params, textPosition);
                    textElement.attr({
                        x: textPosition[0],
                        y: textPosition[1],
                        dy: '0.35em'
                    });
                }
            }
        }
    }

    addFrameInnerEdges(subWindow: SubWindowData, totalInnerFrame: number[], totalGlazingBeadPoints: number[],
                       params: PainterParams, shadings: Shadings): Snap.Element[] {
        let elements = [];
        for (let area of subWindow.areasSpecification) {
            let innerFrame = WindowCalculator.getFrameInnerEdgePoints(subWindow, area.definingMullions,
                totalInnerFrame);
            let glazingBead = WindowCalculator.getGlazingBeadPoints(subWindow, area.definingMullions,
                totalGlazingBeadPoints, this.offerComponent.profileCompositionDistances);
            elements.push(...this.addFrameInnerEdge(subWindow, area, innerFrame, glazingBead, params, shadings));
        }
        return elements;
    }

    private addBentPartsShadings(outerPolygon: number[], innerPolygon: number[], params: PainterParams,
                                 shadings: Shadings): void {
        let bentParts = ShadingCalculator.splitBentParts(outerPolygon, innerPolygon);
        let illuminatedSide = params.viewFromOutside ? bentParts.right : bentParts.left;
        let darkSide = params.viewFromOutside ? bentParts.left : bentParts.right;
        shadings.add(
            ScalingPainter.path(this.offerComponent.svg, bentParts.top, WindowParams.none, params), 0, 10);
        shadings.add(
            ScalingPainter.path(this.offerComponent.svg, darkSide, WindowParams.none, params), 0, 10);
        shadings.add(
            ScalingPainter.path(this.offerComponent.svg, illuminatedSide, WindowParams.none, params), 15, 0);
        shadings.add(
            ScalingPainter.path(this.offerComponent.svg, bentParts.bottom, WindowParams.none, params), 30, 0);
    }

    private addFrameInnerEdge(subWindow: SubWindowData, area: AreaSpecification, innerFramePoints: number[],
                              glazingBead: number[],
                              params: PainterParams, shadings: Shadings): Snap.Element[] {
        let elements = [];
        let attr = params.isShaded() ? WindowParams.regular(params, params.viewFromOutside) :
            WindowParams.smallerOnThumbnail(params);
        let frameInnerEdge = ScalingPainter.path(this.offerComponent.svg, innerFramePoints, attr, params);
        elements.push(frameInnerEdge);
        if (params.isShaded()) {
            this.addBentPartsShadings(innerFramePoints, glazingBead, params, shadings);
            elements.push(...shadings.addJoints(this.offerComponent.svg, innerFramePoints, glazingBead, params, 0.1));
        }
        if (params.isRegularMode()) {
            frameInnerEdge.addClass(WindowParams.GLAZING_BEAD_ELEM);
            frameInnerEdge.data(DataKeys.SUBWINDOW, subWindow);
            frameInnerEdge.data(DataKeys.AREA, area);
            frameInnerEdge.data(DataKeys.GLAZING_BEAD, area.glazingBead);
            this.offerComponent.addSelectionClickHandler(frameInnerEdge, WindowParams.GLAZING_BEAD_ELEM);
        }
        return elements;
    }

    private addWingOuterEdge(subWindow: SubWindowData, cuts: CutData[], wingOuterEdgePoints: number[],
                             totalInnerFrameFull: PolygonPoint[], totalInnerFrame: number[],
                             clickableElements: Snap.Element[], params: PainterParams, dependencies: SubwindowDependencies,
                             shadings: Shadings, frameOpeningPoints?: number[]): Snap.Element[] {
        let elements = [];
        let attr = WindowParams.regular(params);
        let wingOuterEdge = ScalingPainter.path(this.offerComponent.svg, wingOuterEdgePoints, attr, params);
        elements.push(wingOuterEdge);
        if (params.isShaded()) {
            elements.push(...shadings.addJoints(this.offerComponent.svg, wingOuterEdgePoints, totalInnerFrame, params));
            if (params.viewFromOutside) {
                let frameOpening = ScalingPainter.path(this.offerComponent.svg, frameOpeningPoints,
                    WindowParams.transparent, params);
                shadings.add(frameOpening, 18, 7);
                // // todo: inner shadow doesnt seem to work :(
            } else {
                shadings.add(wingOuterEdge, 20, 5);
                let wingGrooves = WindowCalculator.getWingPointsOffsetByFactor(subWindow, cuts,
                    this.offerComponent.totalBoundingBox, this.offerComponent.profileCompositionDistances,
                    this.offerComponent.isValidationDisabled(), ShadingCalculator.WINGS_GROOVES_POINT);
                elements.push(shadings.addWingGrooves(this.offerComponent.svg, wingGrooves, params));
            }
        }
        if (params.isRegularMode()) {
            let outerPath = DrawingUtil.pathStringFromPolygonPoints(wingOuterEdgePoints, true);
            let innerPath = DrawingUtil.pathStringFromPolygonPoints(totalInnerFrame, true);
            wingOuterEdge.data(DataKeys.SUBWINDOW, subWindow);
            wingOuterEdge.data(DataKeys.WING, subWindow.wing);
            wingOuterEdge.data(DataKeys.INNER_FRAME, totalInnerFrameFull);
            if (this.isToolActive(Tool.SELECT)) {
                wingOuterEdge.addClass(WindowParams.WING_ELEM);
            } else if (this.isToolActive(Tool.MULLION, subWindow) && this.isSubwindowValidForNewMullion(subWindow)) {
                let overlappingPoints = this.shouldClickableOverlaysBeTrimmed(subWindow) ?
                    dependencies.getOverlappingSubwindowsPoints(subWindow) : [];
                let clickable = this.cloneElement(wingOuterEdge);
                elements.push(clickable);
                clickable.attr({mask: this.prepareHollowMask(outerPath, innerPath, overlappingPoints)});
                clickable.addClass(WindowParams.INNER_FRAME_ELEM);
                clickable.click(event => this.offerComponent.onClickLineGrillHandler(event));
                clickableElements.push(clickable);
            } else if (this.isToolActive(Tool.HANDLE, subWindow)) {
                this.createHandleAddingEvent(subWindow, outerPath, innerPath, clickableElements);
            }
            this.offerComponent.addSelectionClickHandler(wingOuterEdge, WindowParams.WING_ELEM);
        }
        return elements;
    }

    private addMullions(subWindow: SubWindowData, totalInnerFrame: PolygonPoint[], clickableElements: Snap.Element[],
                        params: PainterParams, dependencies: SubwindowDependencies): Snap.Element[] {
        let elements = [];
        if (params.isRegularMode()) {
            let mullions = [];
            if (this.isToolActive(Tool.SELECT)) {
                mullions = subWindow.mullions;
            } else if (this.isToolActive(Tool.MULLION, subWindow)) {
                mullions = ((this.offerComponent.pendingGrillData.grill as Mullion).isConstructional) ?
                    subWindow.mullions.filter(m => m.isConstructional) : subWindow.mullions;
            }
            for (let mullion of mullions) {
                elements.push(this.addMullion(subWindow, totalInnerFrame, mullion, clickableElements, dependencies));
            }
        }
        return elements;
    }

    private addMullion(subWindow: SubWindowData, totalInnerFrame: PolygonPoint[], m: Mullion,
                       clickableElements: Snap.Element[], dependencies: SubwindowDependencies): Snap.Element {
        let includeGlazingBeads = this.isToolActive(Tool.MULLION, subWindow);
        let pathString = DrawingUtil.pathStringFromPolygonPoints(
            MullionUtils.getMullionPoints(m, PolygonPointUtil.toNumbersArray(totalInnerFrame), subWindow,
                this.offerComponent.profileCompositionDistances, includeGlazingBeads), true);
        let mullion = this.offerComponent.svg.path(pathString);
        mullion.attr({fill: '#ffffff'});
        mullion.addClass(WindowParams.MULLION_ELEM);
        mullion.addClass("clickable-overlay");
        mullion.data(DataKeys.SUBWINDOW, subWindow);
        mullion.data(DataKeys.INNER_FRAME, totalInnerFrame);
        mullion.data(DataKeys.MULLION, m);
        mullion.data(DataKeys.GRILL_SEGMENT, m.drawingSegments[0]);

        if (this.isToolActive(Tool.SELECT)) {
            this.offerComponent.addSelectionClickHandler(mullion, WindowParams.MULLION_ELEM);
            mullion.attr(WindowParams.clickableOverlay);
        } else if (this.isToolActive(Tool.MULLION, subWindow) && this.isSubwindowValidForNewMullion(subWindow)) {
            let overlappingPoints = this.shouldClickableOverlaysBeTrimmed(subWindow) ?
                dependencies.getOverlappingSubwindowsPoints(subWindow) : [];
            if (overlappingPoints.length > 0) {
                let shownPart = this.offerComponent.svg.path(pathString).attr(WindowParams.OverlayMask.Show);
                let overlaps = overlappingPoints.map(op => DrawingUtil.pathStringFromPolygonPoints(op, true))
                    .map(path => this.offerComponent.svg.path(path).attr(WindowParams.OverlayMask.Hide));
                let mask = this.offerComponent.svg.group(shownPart, ...overlaps);
                mullion.attr({mask: mask});
            }
            mullion.click(event => this.offerComponent.onClickLineGrillHandler(event));
            clickableElements.push(mullion);
        }
        return mullion;
    }

    private paintMovableBar(subWindow: SubWindowData, params: PainterParams): Snap.Element[] {
        let elements = [];
        if (!params.isShaded() && subWindow.profilesComposition.right === CompositionType.MOVABLE_POST) {
            let points = subWindow.points;
            let shift = (subWindow.isLeading ? 1 : -1) *
                (this.offerComponent.profileCompositionDistances.get(ProfilesCompositionType.MOVABLE_POST) * 0.25);
            let line = [points[2] + shift, points[3], points[4] + shift, points[5]];
            elements.push(ScalingPainter.line(this.offerComponent.svg, line, WindowParams.movableBar(params), params));
        }
        return elements;
    }

    private addVentilation(subwindow: SubWindowData, cuts: CutData[], params: PainterParams): Snap.Element[] {
        let elements = [];
        if (VentilationUtils.isVentilationPresent(subwindow) &&
            VentilationUtils.validate(subwindow, cuts, this.offerComponent.totalBoundingBox,
                this.offerComponent.profileCompositionDistances)) {
            let path = VentilationUtils.getPath(subwindow, cuts, this.offerComponent.totalBoundingBox,
                this.offerComponent.profileCompositionDistances, params);
            let ventilation = ScalingPainter.path(this.offerComponent.svg, path, WindowParams.Ventilation.Attributes,
                params);
            elements.push(ventilation);
            ventilation.addClass(WindowParams.VENTILATION_ELEM);
            ventilation.data(DataKeys.SUBWINDOW, subwindow);
            if (this.isToolActive(Tool.SELECT)) {
                this.offerComponent.addSelectionClickHandler(ventilation, WindowParams.VENTILATION_ELEM);
            } else {
                ventilation.attr({pointerEvents: 'none'});
            }
        }
        return elements;
    }

    private addSubwindowBox(subWindow: SubWindowData, cuts: CutData[], totalInnerFrame: PolygonPoint[],
                            clickableElements: Snap.Element[], params: PainterParams, dependencies: SubwindowDependencies): Snap.Element[] {
        let elements = [];
        if (params.isRegularMode() && (this.isToolActive(Tool.SELECT) || this.isToolActive(Tool.MULLION, subWindow))) {
            let outerSubwindowFrame = WindowCalculator.getOuterSubwindowPoints(subWindow, cuts);
            let path = DrawingUtil.pathStringFromPolygonPoints(outerSubwindowFrame, true);
            let outerFrame = this.offerComponent.svg.path(path).attr(WindowParams.clickableOverlay);
            elements.push(outerFrame);
            outerFrame.data(DataKeys.SUBWINDOW, subWindow);
            outerFrame.data(DataKeys.INNER_FRAME, totalInnerFrame);
            outerFrame.addClass(WindowParams.FRAME_ELEM);
            if (this.isToolActive(Tool.MULLION, subWindow) && WindowCalculator.isSubWindowF(subWindow) &&
                this.isSubwindowValidForNewMullion(subWindow)) {
                let clickable = this.cloneElement(outerFrame);
                elements.push(clickable);
                let innerPath = DrawingUtil.pathStringFromPolygonPoints(
                    PolygonPointUtil.toNumbersArray(totalInnerFrame), true);
                let overlappingPoints = this.shouldClickableOverlaysBeTrimmed(subWindow) ?
                    dependencies.getOverlappingSubwindowsPoints(subWindow) : [];
                clickable.attr({mask: this.prepareHollowMask(path, innerPath, overlappingPoints)});
                clickable.removeClass(WindowParams.FRAME_ELEM);
                clickable.addClass(WindowParams.INNER_FRAME_ELEM);
                clickable.click(event => this.offerComponent.onClickLineGrillHandler(event));
                clickableElements.push(clickable);
            }
            this.offerComponent.addSelectionClickHandler(outerFrame, WindowParams.FRAME_ELEM);
        }
        return elements;
    }

    public paintConfigAddonsIcon(configurableAddonIds: number[], points: number[], params: PainterParams,
                                 totalBox?: MinMaxXY): void {
        if (params.isRegularMode() && this.offerComponent.visibilitySettings.configAddonIcons &&
            configurableAddonIds.length) {
            let isGlobalAddon = totalBox != null;
            let configAddonIcon = ConfigAddonIconPainter.paint(totalBox ||
                DrawingUtil.calculatePolygonTotalBoundingBox(points),
                this.offerComponent.svg, this.offerComponent.getOnePercentOfCanvasSize(), isGlobalAddon);
            if (this.offerComponent.mode === Tool.SELECT) {
                configAddonIcon.click(() => this.offerComponent.configAddonIconClicked());
            }
        }
    }

    public isToolActive(tool: Tool, parent?: SubWindowData | AreaSpecification): boolean {
        if (!this.isModeActive(tool)) {
            return false;
        }
        switch (tool) {
            case Tool.SELECT:
                return true;
            case Tool.GRILL:
                return AreaUtils.canPendingGrillBeAdded(parent as AreaSpecification,
                    this.offerComponent.pendingGrillData, this.offerComponent.staticData.grills);
            case Tool.GRILL_TEMPLATE:
                return AreaUtils.canPendingGrillBeAdded(parent as AreaSpecification,
                    this.offerComponent.pendingGrillData, this.offerComponent.staticData.grills);
            case Tool.MULLION:
                return MullionUtils.canHaveMullions(parent as SubWindowData);
            case Tool.HANDLE:
                return !WindowCalculator.isSubWindowFixed(parent as SubWindowData) &&
                    !HandleHelper.isPresent((parent as SubWindowData).handle);
            default:
                throw new Error("Unsupported tool: " + tool);
        }
    }

    private isModeActive(tool: Tool): boolean {
        return this.offerComponent.mode === tool;
    }

    public paintOverlay(painterParams: PainterParams, clickableElements: any[]): void {
        if (clickableElements.length > 0) {
            let vBox = this.offerComponent.getViewBox(painterParams.mode);
            let box = [
                vBox.minX, vBox.minY,
                vBox.maxX, vBox.minY,
                vBox.maxX, vBox.maxY,
                vBox.minX, vBox.maxY
            ];
            let path = DrawingUtil.pathStringFromPolygonPoints(box, true);
            let overlay = this.offerComponent.svg.path(path).addClass('tool-overlay');
            let mask = this.offerComponent.svg.group(...clickableElements.map(element => this.prepareMask(element)));
            mask.attr(WindowParams.OverlayMask.Show);
            overlay.attr({mask: mask, pointerEvents: 'none'});
            clickableElements.forEach(el => el.addClass('clickable-overlay'));
        }
    }

    private prepareMask(originalElement: Snap.Element): Snap.Element {
        return originalElement.clone().attr(WindowParams.mask);
    }

    public cloneElement(originalElement: Snap.Element): Snap.Element {
        let newElement = originalElement.clone();
        Object.keys(DataKeys).forEach((key: keyof typeof DataKeys) => {
            let data = originalElement.data(DataKeys[key]);
            if (data != undefined) {
                newElement.data(DataKeys[key], data);
            }
        });
        return newElement;
    }

    private prepareHollowMask(outerPath: string, innerPath: string, overlappingPoints: number[][] = []): Snap.Element {
        let shownOuterFrame = this.offerComponent.svg.path(outerPath).attr(WindowParams.OverlayMask.Show);
        let hollowCenter = this.offerComponent.svg.path(innerPath).attr(WindowParams.OverlayMask.Hide);
        let overlaps = overlappingPoints.map(op => DrawingUtil.pathStringFromPolygonPoints(op, true))
            .map(path => this.offerComponent.svg.path(path).attr(WindowParams.OverlayMask.Hide));
        let mask = this.offerComponent.svg.group(shownOuterFrame, hollowCenter, ...overlaps);
        return mask;
    }

    private isSubwindowValidForNewMullion(subwindow: SubWindowData): boolean {
        return MullionUtils.isSubwindowValidForNewMullion(this.offerComponent.pendingOperationLineHelper, subwindow);
    }

    private shouldClickableOverlaysBeTrimmed(subwindow: SubWindowData): boolean {
        let targetSubwindow = this.offerComponent.pendingOperationLineHelper == undefined ? undefined :
            this.offerComponent.pendingOperationLineHelper.data(DataKeys.SUBWINDOW);
        return targetSubwindow !== subwindow;
    }
}
